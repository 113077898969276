import React, { useState } from "react";
import {
    TextField,
    Grid,
    InputLabel,
} from "@mui/material";
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ShiftSchedule = ({ onValueChange, props }) => {

   

    const [shiftTimings, setShiftTimings] = useState(JSON.parse(JSON.stringify(props)));

    const handleLabelChange = (index) => (event) => {
        const newTimings = [...shiftTimings];
        newTimings[index].label = event.target.value;
        setShiftTimings(newTimings);
        onValueChange(newTimings);
    };

    const handleStartChange = (labelIndex, timingIndex) => (event) => {
        const newTimings = [...shiftTimings];
        const newStartTime = event.target.value;
        const endTime = newTimings[labelIndex].timings[timingIndex].end;
        const [startHours, startMinutes, startAMPM] = parseTime(newStartTime);
        const [endHours, endMinutes, endAMPM] = parseTime(endTime);

        if (newStartTime && endTime) {
            if(
              startAMPM === endAMPM &&
              (startHours > endHours || (startHours === endHours && startMinutes >= endMinutes && endAMPM === startAMPM))
            ) {
                alert("Start Time must be less than End Time");
            } else {
                newTimings[labelIndex].timings[timingIndex].start = event.target.value;
                setShiftTimings(newTimings);
                onValueChange(newTimings);
            }
        }
        else {
            newTimings[labelIndex].timings[timingIndex].start = event.target.value;
            setShiftTimings(newTimings);
            onValueChange(newTimings);
        }
    };

    const handleEndChange = (labelIndex, timingIndex) => (event) => {
        const newTimings = [...shiftTimings];
        const newEndTime = event.target.value;
        const startTime = newTimings[labelIndex].timings[timingIndex].start;
        const [startHours, startMinutes, startAMPM] = parseTime(startTime);
        const [endHours, endMinutes, endAMPM] = parseTime(newEndTime);
        if (newEndTime && startTime) {
            if 
             ( endHours < startHours || ((endHours === startHours && endMinutes <= startMinutes && endAMPM === startAMPM))
            ) {
                alert("End Time must be greater than Start Time");
            } else {
                newTimings[labelIndex].timings[timingIndex].end = event.target.value;
                setShiftTimings(newTimings);
                onValueChange(newTimings);
            }
        } else {
            newTimings[labelIndex].timings[timingIndex].end = event.target.value;
            setShiftTimings(newTimings);
            onValueChange(newTimings);
        }
    };
    const parseTime = (timeString) => {
        const [time, ampm] = timeString.split(' ');
        const [hours, minutes] = time.split(':').map(Number);
        return [hours, minutes, ampm];
      };
    const handleAddTiming = (index) => () => {
        const newTimings = [...shiftTimings];
        const lastElement = shiftTimings[index]['timings'][shiftTimings[index]['timings'].length - 1];
        if (lastElement.start === "" || lastElement.end === "") {
            alert("Please fill previous shift first")
        }
        else {
            newTimings[index].timings.push({ start: "", end: "" });
            setShiftTimings(newTimings);
            onValueChange(newTimings);
        }
    };

    const handleRemoveTiming = (labelIndex, timingIndex) => () => {
        const newTimings = [...shiftTimings];
        newTimings[labelIndex].timings.splice(timingIndex, 1);
        setShiftTimings(newTimings);
        onValueChange(newTimings)
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission here
    };


    return (
        <Grid md={12} container pt={2} >
            <Grid container item md={12} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                {shiftTimings.map((shiftTiming, labelIndex) => (
                    <Grid md={6} container key={labelIndex} >
                        <Grid container item md={12}>
                            <InputLabel id="demo-simple-select-label" sx={{ fontWeight: 'bold' }}>{shiftTiming.label}</InputLabel>
                        </Grid>
                        {shiftTiming.timings.map((timing, timingIndex) => (
                            <Grid container item md={12} spacing={2} sx={{ alignItems: 'center', mt: 0.1 }} key={`${labelIndex}-${timingIndex}`}>
                                <Grid item xs={5}>
                                    <TextField
                                        id={`start-${labelIndex}-${timingIndex}`}
                                        label="Start Time"
                                        type="time"
                                        value={timing.start}
                                        onChange={handleStartChange(labelIndex, timingIndex)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        id={`end-${labelIndex}-${timingIndex}`}
                                        label="End Time"
                                        type="time"
                                        value={timing.end}
                                        onChange={handleEndChange(labelIndex, timingIndex)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    {timingIndex < 4 && labelIndex == 0 &&
                                        <AddCircleOutlineIcon fontSize="medium" onClick={handleAddTiming(labelIndex)} sx={{ cursor: 'pointer' }} />
                                    }
                                    {timingIndex < 1 && labelIndex == 1 &&
                                        <AddCircleOutlineIcon fontSize="medium"
                                            onClick={handleAddTiming(labelIndex)} sx={{ cursor: 'pointer' }}
                                        />
                                    }
                                    {timingIndex > 0 && (
                                        <DeleteOutline fontSize="medium" onClick={handleRemoveTiming(labelIndex, timingIndex)} sx={{ cursor: 'pointer' }} />
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default ShiftSchedule;
