import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box, FormControl, Grid, Button, InputLabel, OutlinedInput, Select, MenuItem
} from '@mui/material';
import { Typography} from '@mui/joy';
//MRT Imports
import MaterialReactTable from 'material-react-table';
import AuthUser from '../../../Components/Auth/AuthUser';
import Loader from '../../../Components/Loader/Loader';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import {  postData } from '../../../redux/apiSlice';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import { format, subDays, isAfter } from 'date-fns';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import CROLeadExpand from '../../../Components/PartnerAdmin/CROLead'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Badge from '@mui/material/Badge';
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';
import useMediaQuery from '@mui/material/useMediaQuery';
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];

function ContactTypeConfig(props) {
    const [open, setOpen] = React.useState(false);

    
    //dialog content end
    const dispatch = useDispatch();
    const [croLead, setCroLead] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
    const isMobile = useMediaQuery('(max-width:600px)');
    let des = [];
    const tablePreferencesArray = {

    }
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    useEffect(() => {
        getCroLead();
    }, [])

    const getCroLead = async () => {
        handleFreezStart();
        const apiUrl = '/partnercrcLead';
        const args = {
            fromDate: startDate,
            toDate: endDate
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        handleFreezStop();
        setCroLead(res);
        handleFreezStop();
    }

    useEffect(() => {
    }, [rowSelection]);
    ////////////////////////
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
    }, [rowSelection]);

    ////////////////////////

    const sideBar = calendarFilter();
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];




    const decodeBase64 = (str) => {
        try {
            var passValue = str = atob(str);
            var masked = str.substring(0, str.length - 4);
            masked = masked.replace(/./g, '*'); //The character is ASCII-7 (Press Alt+7 to type)
            var text = passValue.substring(passValue.length - 4);
            var newPass = masked + text;
            return newPass;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };

    const getPasswordString = (str) => {
        try {
            var passValue = str = atob(str);
            return passValue;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };
    const togglePasswordVisibility = (rowId) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [rowId]: !prevState[rowId],
        }));
    };
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (row) => `${row.parentInfo ? (row.parentInfo.aid ?row.parentInfo.aid: ''):''}`, 
                accessorKey: 'AID',
                header: 'AID',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (row.original.parentInfo ? (row.original.parentInfo.aid ?row.original.parentInfo.aid: 'N/A'):'N/A'),
            },
            {
                accessorKey: 'Total Enrolled Lead Count',
                accessorFn: (row) => `${row.parentInfo.membercount}`,
                header: 'Total Enrolled Lead Count',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <div style={{ textAlign: "center" }}>
                        <Badge badgeContent={`${row.original.parentInfo.membercount}`} color={row.original.parentInfo.membercount === 0 ? 'primary' : 'success'} overlap="circular" max={9999}></Badge>
                    </div>
                ),
            },

        ]
    )
    //csv option start here
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'CRO Leads Report',
        headers: ['AID', 'Total Enrolled Lead Count'],
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportData = (croLead) => {
        const exportedData = [];
        
        croLead.forEach((row) => {
            const mainTableRow = {
                aid: `${row.original.parentInfo.aid}` || 'N/A',
                total_enrolled_member: `${row.original.parentInfo.membercount}` || 'N/A',
                
            
            };
            exportedData.push(mainTableRow);
            const subheader = {
                'First Name': 'First Name',
                'Last Name': 'Last Name',
                'AID': 'AID',
                'Email': 'Email',
                'Mobile': 'Mobile',
                'SSN': 'SSN',
                'PID': 'PID',
                'Enrolled Date': 'Enrolled Date',

              };
              exportedData.push(subheader);
              if (row.original.parentInfo.childInfo) {
                row.original.parentInfo.childInfo.forEach((option) => {
                  const subRow = {
                    first_name: option.first_name || 'N/A',
                    last_name: option.last_name || 'N/A',
                    aid: option.aid || 'N/A',
                    email: option.email || 'N/A',
                    mobile: option.mobile || 'N/A',
                    SSN: option.ssn || 'N/A',
                    PID: option.pid || 'N/A',
                    enrolled_date: option.created_at? format(new Date(option.created_at), 'dd MMM yyyy'): 'N/A',
                    
                  };
                  exportedData.push(subRow);
                });
              } else {
                // If no child data available, add an empty row for subrows
                exportedData.push({});
              }
              exportedData.push({});
            });
       

        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const convertToCSV = (data) => {
        const headers = csvOptions.headers.join(',') + '\n';

        const rows = data.map((row) => {
            return Object.values(row).map((value) => {
                // Handle any necessary formatting or escaping of values
                // For simplicity, we assume all values are already properly formatted
                return value;
            }).join(',');
        }).join('\n');

        return headers + rows;
    };
    //csv end here
    const ContentLayout=
        
            <>
                <Grid container item lg={12} md={12} xs={12}>
                    <Typography level='h4' color='primary'>CRO Leads Report</Typography>
                </Grid>

                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                    <Grid item lg={3} md={3} sm={8} xs={8}>
                        <FormControl fullWidth size='small' margin='dense'>
                            <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value="date"
                                input={<OutlinedInput label="Select Date Range" />}
                            >
                                <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                                <DateRangePicker
                                    className='custom-date-picker'
                                    onChange={item => setSelectionRange([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={selectionRange}
                                    maxDate={new Date()}
                                    direction="horizontal"
                                    staticRanges={staticRanges}
                                />
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={4} xs={4} mt={1}>
                        <Button sx={{ minWidth: 150 }} variant="contained" onClick={getCroLead}>

                            Submit
                        </Button>
                    </Grid>
                </Grid>
                <MaterialReactTable
                    columns={columns}
                    data={croLead}
                    enableColumnFilterModes
                    filterFns={{
                        customSearchFilterFn: (row, id, filterValue) =>
                        row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                       }} 
                    globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                    enableRowSelection={false}
                    onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                    state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                    initialState={{ showColumnFilters: false, density: 'compact' }}
                    onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                    enableColumnFilters={config.DatatableColumnFilter}
                    positionToolbarAlertBanner="bottom"
                    enableDensityToggle={false}
                    muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                            backgroundColor: "#F6FAFF",
                        },
                    }}
                    renderDetailPanel={({ row }) => (
                        <CROLeadExpand props={row} />
                    )}
                    //top csv option
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                        >
                            <Button
                                color="primary"
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() =>
										handleExportData(table.getPrePaginationRowModel().rows)
									}
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                startIcon={<FileDownloadIcon />}
                                variant='contained'
                            >
                                CSV
                            </Button>
                        </Box>
                    )
                    }
                />
                {/* loader code start */}
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={freez}
                        onClick={handleFreezStop}
                    >
                        <Freez />
                    </Backdrop>
                </div>
                {/* loader code end */}

            </>
       
    return (
        <div>
            {isLoading ? <Loader /> : <></>}
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}


export default ContactTypeConfig;