import React from 'react';
import { Box,  Grid,  } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Typography } from '@mui/joy';
function OneBHeader(props) {
    return (
        <Box sx={{ flexGrow: 1, boxShadow: 0, justifyContent: "center", alignItems: "center", borderBottom: "3px #30BE2D solid" }}>
            <Grid container item lg={12} md={12} sm={12} xs={12} p={3}>
                <Grid item lg={3} md={3} sm={12} xs={12} className='header-register'>
                    <img alt="#" src={require('../../../assets/images/mainLogo.jpg')}/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "center"}}>
                    <Typography level="h3">For customer service call <a href='' style={{ color: "#33a6dc" }}> (888) 548-2008</a></Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12} sx={{ textAlign: "right"}}>
                <Typography level='h6'><span> <LogoutIcon /> Logout</span></Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
export default OneBHeader;