import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Typography } from '@mui/joy';
import BackIcon from '@mui/icons-material/HighlightOff';
function PrivacyPolicy(props) {
    const currentYear = new Date().getFullYear();
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const closeTab = () => {
            props.closeVal(false)       
    }
    return (
        <>
            <Box p={3} sx={{ minHeight: 300, maxHeight: 500, borderTop: "3px solid #5288db" }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} >
                    <Grid lg={6} md={6} sm={6} xs={6} item>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h3" sx={{ borderBottom: "3px solid #5288db" }}>Privacy Policy Of MyFreeScoreNow Inc.</Typography>
                        </Grid>
                    </Grid>
                    <Grid lg={6} md={6} sm={6} xs={6} item className='companyLogo'>
                    {parts[1]!='privacypolicy' && 
                        <Tooltip title="Close" >
                            <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={closeTab} />
                            
                        </Tooltip>
                    }
                    </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Last revised May 01, 2015</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">MyFreeScoreNow Inc. (“we” or “us” or “MFSN”) respects your concerns about privacy.
                        This Privacy Policy (“Policy”) applies to the website MyFreeScoreNow.com (“Site”) and discloses the types of information we collect when you use the Site,
                        how we use the information, with whom we may share it and the choices you have regarding the collection and use of the information you provide to us through the Site.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        By using the Site you agree to the terms of this Policy.
                        If you do not agree to the terms of this Policy, please do not use the Site.
                        We reserve the right to change this Policy without prior notice.
                        You agree to obtain any notices of, and changes to, this Policy electronically by visiting this Site.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">What information do we collect?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Generally, and in order to provide you with our services,
                        we gather two types of information: personally identifiable information (“Personal Information”) and technical and statistical information.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        Personal Information is information that you expressly provide when you register for a membership and/or enroll in MyFreeScoreNow.com.
                        This information includes your name, mailing address, email address, phone number, Social Security number and other unique personal identifiers (e.g. date of birth) that we can use to contact you,
                        if needed, about your order or ongoing services you receive, verify your identity in order to deliver your credit report and credit score securely to you and to confirm sales and billing information.
                        Occasionally we may request other information from you that may be used to confirm enrollment, update your account or confirm your order.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Technical and statistical information is collected automatically whenever you visit our Site through the use of tracking technologies such as cookies and pixel tags (see below).
                        This type of information collected may include things such as your IP address, operating system, type of browser and Site activity. We collect this data to help us improve the quality of our Site.
                        We do not link aggregate user data with personally identifiable information.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">Who is collecting the information?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">MyFreeScoreNow.com is marketed and operated by MyFreeScoreNow Inc.,
                        an independent provider to consumers only of their personal consumer credit information.
                        The information collected on the site is owned by us and/or our marketing partners depending on who offered you membership in the program.
                        How do you use the information collected?
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">We use the Personal Information you provide to service your membership.
                        We also may send you additional membership information such as updates about our program and special offers.
                        We use your credit card information to bill you for the membership fee and any benefits you may purchase.
                        We use your email address to deliver the services described in product literature on our Site and to notify you of program updates,
                        special offers and promotions. If you prefer not to receive marketing-related emails,
                        you may click on the unsubscribe link on any email that you may receive or you can call us on the toll-free phone number that can be found in the Contact Us section of the secure members area.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        The Personal Information that is collected may also be shared with other groups within the MFSN organization and with MFSN's affiliates and /or marketing partners to bring you additional information or that offer products and services that we think will be of interest to you.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        The technical and statistical information that is automatically collected from your computer whenever you visit www.MyFreeScoreNow.com is used to help us improve the site and the member experience.
                        From time to time we may disclose general statistical information about our Web site and its visitors such as the number of visitors,
                        the number and type of products purchased, etc.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">Do you share Personal Information with third parties?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">MFSN may share Personal Information with third parties whom we employ to perform functions on our behalf.
                        For example, MFSN uses a third party to verify and process your credit card information in order to charge you for requested benefits or services.
                        All third parties are contractually obligated to use your Personal Information only for the purpose for which it is given.
                        We are not responsible for the information practices of third parties.
                        The collection, use and disclosure of information by third parties are subject to their respective privacy policies that may differ from our policy.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        We may also share Personal Information with our affiliates and/or marketing partners who offer products and services that we think will be of interest to you.
                        If you prefer not to have your Personal Information shared with our affiliates and/or marketing partners, please call us at the toll-free number in the Contact Us section of the secure members area.
                        We may rent, sell, or otherwise transfer your Personal Information to others. If you are enrolling online, you may also opt-out of receiving emails from third parties outside of MyFreeScoreNow by de-selecting the check box on the registration page.
                        If you accept a third-party offer provided via or linked to from our Web site, you consent to our providing your Personal Information and/or billing information to the third-party marketer that offered the product or service so they may process your acceptance of the offer.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        We may also disclose your Personal Information when required by law or in the good faith belief that such action is necessary in order to Confirm to the edicts of the law or comply with legal process.
                    </Typography>
                </Grid>
                <Grid container item  lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        As we continue to develop our business, we may buy or sell assets. In such transactions, customer information generally is one of the transferred business assets.
                        Also, in the unlikely event that our company or substantially all of its assets are acquired, customer information will, of course, be one of the transferred assets
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">How do I change/modify my information?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        You can change, modify or update your member information by logging into the secure members area and going to the My Account section or to the Contact Us page.
                        You can choose at any time to inform us of your email preferences by calling the toll free number found on the Contact Us page of the secure members area.
                        What are Cookies and how do you use them?
                    </Typography>
                </Grid>
                <Grid container item  lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        We, our service providers, and/or non-affiliated third parties may use "cookies" or similar technologies on the Site.
                        A cookie is a small text file that is stored on a user's computer for record-keeping purposes.
                        Cookies are a common industry standard used by many websites and can facilitate your ongoing access to and use of a particular website; cookies do not cause damage to your computer systems or files.
                        Most Internet browsers will allow you to erase or block cookies. Instructions for disabling cookies may differ from one browser to another. Please refer to the Help section of your browser for instructions to assist you in these processes: preventing your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. However, cookies allow you to take full advantage of some of our Site features, so we recommend that you leave them turned on.
                        If you reject cookies, you may still use our Site, but your ability to use some areas of our site will be limited. We do not use cookies for the shopping cart.
                        We do not link the information we store in cookies to any personally identifiable information you submit while on our site.
                        Some of our business partners (e.g., advertisers) use cookies on our Site. We have no access to or control over these cookies.
                        This Privacy Policy covers the use of cookies by this Site only and does not cover the use of cookies by any advertisers.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">What are pixel tags and how do you use them?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">We, our service providers, and/or non-affiliated Third-parties, may also use “pixel tags” in connection with some Site pages and HTML-formatted email messages to, among other things, compile aggregate statistics about Site usage and response rates. “Pixels” or “pixel tags” are tiny graphic Images placed on certain pages of our Site but not on your computer.
                        When you access these pages, pixel tags generate a generic notice of that visit; at no time is your Personal Information collected by the pixel tag. Pixel tags allow us to measure and improve our understanding of visitor traffic and behavior on our Web site. We may also utilize pixel tags provided by our affiliates and/or marketing partners for the same purposes.
                        How do you secure member information?
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        We protect the security of your information during transmission by using Secure Socket Layer (SSL) technology.
                        When you place an order online, the SSL scrambles or encrypts your information before it is sent to us over the Internet.
                        This protects your credit card information from being read while it is transferred over the Internet.
                        When we receive your order, the information is kept encrypted until we are ready to process your request.
                        SSL is supported by Netscape 2.0+, Microsoft IE 3.0+ and most frame-enabled browsers.
                        You will see a small key or padlock in the bottom right corner of your browser if you are using Netscape or Microsoft browsers, indicating that your transaction is secure.
                        In addition, we meet all of the stringent requirements of the Verisign� security seal for all online transactions.
                        Internal access to member information is protected and can only be accessed by password.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">Do these same policies apply to third-party web sites that I access through MFSN?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        No. The Policy appearing on this Site relates to MFSN only.
                        You will notice that our Site may contain links to other sites such as our advertisers.
                        We cannot be responsible for the privacy and security practices of other web sites that you may access through our Site, even if displayed within a frame on our site. As an online consumer,
                        you should always review a particular web site's privacy and security policies when linking to that site before revealing any personal information to that site.
                        You should contact these advertisers directly if you have any questions about their use of the information they collect from you.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">How do you update your Privacy and Security Policy?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">It may be necessary or legally required that we update this Privacy Policy periodically, so please review it often.
                        When we have a change to our Privacy Policy, will post an updated Privacy Policy and update the "Last revised" date.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">How do I contact the web site?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">If you have any questions about this Privacy Policy, you may go to the Contact Us section of the secure members area for our toll-free number or write to us at:</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Membership Services <br />
                        MyFreeScoreNow Inc. <br />PO Box 4798 Seattle, WA 98194</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Thank you for using our site</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
                    <Typography level="body1" fontSize="lg">Copyright © {currentYear} MyFreeScore Now Inc. All Rights Reserved.</Typography>
                </Grid>
            </Box>
        </>
    );
}



export default PrivacyPolicy;