import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination, button } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import config from '../../../AppConfig';
import Sidebar from '../../../Components/Blogs/Sidebar';
function CreditInfo() {
    const [showMoreStates, setShowMoreStates] = useState({});

    const toggleText = (id) => (showMoreStates[id] ? 'Close' : 'Read more');

    const handleToggle = (id) => {
        setShowMoreStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };
    return (
        <>
            <LoginHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={2} md={2} sm={12} xs={12} >
                       <Sidebar/>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12} sx={{padding:"2%"}}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <img src={require("../../../assets/images/mfsn_banner_4.jpg")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className='responsiveImg' />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='h4'>ABC's of Credit</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>No one can deny the importance of consumer credit in today’s society. Whether you use credit out of necessity or for convenience, the fact is we have become a nation of credit consumers. By understanding how credit works you can learn to manage credit successfully and use it to your advantage.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Understanding/How to Manage Credit</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Credit is borrowed money used to purchase goods or services when you need them with a commitment to repay at an agreed-upon time under agreed-upon terms. Consumer credit is typically broken down into two categories: revolving and installment.</Typography>
                            {showMoreStates[1] && (
                                <Typography level='title-md'>
                                    Revolving credit allows you to borrow up to an established credit limit set by the card issuer. As payments are made, that amount becomes available to borrow again. The borrower usually requires a minimum monthly payment, but some consumers choose to pay the balance in full each month. Interest accrues on the unpaid balance. Credit cards are the most common type of revolving credit.
                                    <br /><br />
                                    Installment loans makes up the majority of consumer debt and includes loans for a specific purpose such as a home, automobile or student loan. The average American has four such credit obligations.
                                </Typography>
                            )}
                            <Button onClick={() => handleToggle(1)}>
                                {toggleText(1)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Pros and Cons of Consumer Credit</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>There are both good and bad sides to using credit. A credit history that reflects good credit habits can help pave the way for future major purchases such as a car or house. On the other hand, there’s no denying that credit cards can spiral some people into a debt hole that is hard to climb out of.</Typography>
                            {showMoreStates[2] && (
                                <Typography level='title-md'>
                                    Here are some of the advantages and disadvantages of buying now and paying later.
                                    <br /><br />
                                    <b>Advantages</b>
                                    <ul>
                                        <li>There is no need to carry large amounts of cash. </li>
                                        <li>Credit can get you through a crisis such as a period of unemployment or illness.</li>
                                        <li>Credit may be the only way to pay for large, unexpected expenses such as medical bills or automobile repairs.</li>
                                        <li>Credit allows you to take advantage of deals when you see them.</li>
                                        <li>Many credit card companies offer attractive rewards for using the card (such as airline miles).</li>
                                        <li>Responsible credit use helps establish a good credit history that is essential when making a large purchase such as a home.</li>
                                        <li>It is difficult to make travel arrangements without a credit card.</li>
                                        <li>Credit card statements summarize your purchases for easy record keeping.</li>
                                    </ul>
                                    <b>Disadvantages</b>
                                    <ul>
                                        <li>Studies show that people spend up to a third more when they pay by credit. This is true whether it’s at a retail store or a fast food restaurant.</li>
                                        <li>Finance and interest charges add significantly to the cost of goods and services when the balance is not paid in full quickly. A $500 credit card debt would take seven years to pay off at 18% interest making just the minimum payment - if nothing gets added to it! And, you would be paying $365 extra in interest!</li>
                                        <li>Some people find themselves over their heads in debt.</li>
                                    </ul>
                                </Typography>
                            )}
                            <Button onClick={() => handleToggle(2)}>
                                {toggleText(2)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>The Art of Getting Credit</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>A lack of credit history can be as financially crippling as a poor credit score. In a way, you may feel financially frozen in a vicious cycle-you can’t get credit because you’ve never had credit, and you’ve never had credit because you can’t get credit. There are some things you can do to build a good credit history from the ground up.</Typography>
                            {showMoreStates[3] && (
                                <Typography level='title-md'>
                                    <b>Open a checking account.</b> Opening a checking account is one of the fastest ways to establish financial credibility. Be especially careful not to overdraw your accounts or bounce checks.
                                    <br /><br />
                                    <b>Demonstrate financial responsibility.</b> If you rent an apartment, pay your rent and utilities in full and on time - every time.
                                    <br /><br />
                                    <b>Apply for a small line of credit.</b>Apply for a small line of credit or loan from your local bank. Or, obtain a low limit credit card from a local department store. Be sure to ask if they report to credit bureaus. If the credit goes unreported, it will do nothing to improve your credit history.
                                    <br /><br />
                                    <b>Apply for a small line of credit. </b>Apply for a small line of credit or loan from your local bank. Or, obtain a low limit credit card from a local department store. Be sure to ask if they report to credit bureaus. If the credit goes unreported, it will do nothing to improve your credit history.
                                    <br /><br />
                                    <b>Get a specific kind of credit card. </b>Some credit cards, such as gas cards, are relatively easy to get, even without established credit. Apply for a gas card and pay it off every month.
                                    <br /><br />
                                    <b>Don’t apply for numerous credit cards at once.</b> Whenever you apply for credit and the lender checks your credit report (which most of them do), an "inquiry" is recorded on your credit report. Too many inquiries within a short period of time can raise a red flag to potential lenders.
                                    <br /><br />
                                    <b>Find a co-signer.</b>If you are having trouble getting a gas or department store credit card on your own, ask someone you trust to co-sign the account for you. Make the payments in full and on time. Warning: The co-signer’s credit will be affected also.
                                    <br /><br />
                                    <b>If you apply for a loan, make a large down payment. </b>Making a significant down payment on a loan shows lenders that you are financially secure. If you don’t have the cash for a big down payment, consider borrowing some from a trusted friend or family member.
                                    <br /><br />
                                    <b>If possible, maintain a stable lifestyle.</b>Some creditors look at other factors in addition to credit history, including how often a person moves or changes jobs. The more changes you’ve made in your life, the more financially risky you will appear.
                                </Typography>
                            )}
                            <Button onClick={() => handleToggle(3)}>
                                {toggleText(3)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Steps to Good Credit</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Good credit can make a huge difference in your life. Following these tips can help you build a strong credit foundation that will be important as you make major financial decisions in your future.</Typography>
                            {showMoreStates[4] && (
                                <Typography level='title-md' mt={1}>
                                    <b>Step 1: Always pay your bills on time.</b>
                                    <br />
                                    This is one of the most important steps toward building good credit. Be sure you pay at least the minimum amount due before the due date every time. Paying bills on time seems like a no brainer but in reality it is very easy for consumers to fall behind on payment schedules. Set up automatic payments or reminders if necessary.
                                    <br /><br />
                                    <b>Step 2: Maintain a balance-to-available-credit ratio around 25%.</b>
                                    <br />
                                    Using only 25% of your available credit shows creditors that you that you are responsible and in control of your finances. This helps avoid the perception-real or not-that you are in financial trouble.
                                    <br /><br />
                                    <b>Step 3: Pay more than the minimum monthly payment.</b>
                                    <br />
                                    By paying more than the minimum, you help reduce the principle balance. This can save you money in the long run. Paying more than the minimum demonstrates that you are in control of your finances and that you are not maxed out and only able to afford the minimum payments.
                                    <br /><br />
                                    <b>Step 4: Review Your Credit Report</b>
                                    <br />
                                    About one in four credit reports has at least one error. Either a payment has not been recorded correctly or another billing company has posted incorrect non-payment information to your account. Since so many decisions are based on your credit report, it pays to review your report for accuracy.
                                    <br /><br />
                                    <b>Step 5: Do not apply for new lines of credit in a short amount of time.</b>
                                    <br />
                                    When you apply for new lines of credit, the potential creditor inquiries are recorded on your credit report. Having several inquiries on your report over a short amount of time may indicate to any potential lenders that you are having financial problems. However, inquiries of the same type within a short period of time, such as shopping for a car loan, will count as a single inquiry.
                                    <br /><br />
                                    <b>Step 6: Pay off your debt rather than transferring it.</b>
                                    <br />
                                    While it is important to shop around for the lowest interest rate, it is generally not a good idea to transfer credit card debt. Multiple debt transfers can negatively affect your credit because it puts new accounts on your credit report and your credit history appears shorter due to the new accounts. Additionally, your balance-to-available-credit ratio remains high.
                                    <br /><br />
                                    Fees are often associated with transferring your debt, which may cost more than just paying off the current balance at a higher interest rate.
                                </Typography>
                            )}
                            <Button onClick={() => handleToggle(4)}>
                                {toggleText(4)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Credit Information</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Good credit can make a huge difference in your life. Following these tips can help you build a strong credit foundation that will be important as you make major financial decisions in your future.</Typography>
                            {showMoreStates[5] && (
                                <>
                                    <Typography level='title-md' mt={1}>
                                        In our credit-oriented world bad credit can feel like the kiss of death. Lenders charge a premium for extending credit to someone with a poor credit report. The encouraging news is that today is a great day to start cleaning up your credit report. It takes time and perseverance, but it can be done.
                                    </Typography><br />
                                    <Typography level='title-md'>
                                        Sometimes the hardest part of a project is coming up with a plan. Once the plan is in place, it’s easier to stay focused on the goal. So it is with credit reports. If your credit isn’t what you’d like it to be, start with a plan and a commitment, and realize it will take time. Developing better credit habits needs to be a key part of your plan. Otherwise, you will likely end up in the same situation again.
                                    </Typography><br />
                                    <Typography level='title-md' mt={1}>
                                        When it comes to mending your credit, you have two choices: do it yourself or pay someone to help you. The Federal Trade Commission advises that self-help may be best when it comes to your credit report. If you are considering paying someone to help, do your homework. Start by taking a few minutes to read what the FTC says on the subject, including warning signs to watch for in credit repair organizations.
                                    </Typography>
                                    <br/><br />
                                    <p><b>Pitfalls to Avoid</b></p>
                                    <p>Rebuilding your credit takes time and commitment. There are a few pitfalls to avoid along the way:</p>
                                    <ul>
                                        <li>Consolidating debt is often not the best option. You may end up closing accounts which are contributing to the length of your credit history. And, you may raise the ratio of your debt-to-available-credit , a negative thing and one of the key factors considered in most credit scoring models.</li>
                                        <li>Stay clear of scams that offer to create a new credit file or credit identity. That is credit fraud and could result in serious legal repercussions.</li>
                                        <li>Don’t believe anyone who tells you your credit can be fixed overnight. Rebuilding credit takes time.</li>
                                    </ul>
                                    <br />
                                    <Typography><b>Bankruptcy</b></Typography>
                                    <br />
                                    <Typography>
                                        Sometimes dire circumstances call for dire measures. But when it comes to bankruptcy, think twice. A bankruptcy will remain on your credit report for seven to ten years (depending on the type of bankruptcy). Again, the Federal Trade Commission is an excellent resource for information on personal bankruptcy.
                                    </Typography>
                                </>
                            )}
                            <Button onClick={() => handleToggle(5)}>
                                {toggleText(5)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Are you monitoring your credit?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={4}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' style={{textDecoration:"none"}}><Typography level='title-md' sx={{color:"#1976d2"}}>Get your free credit score and score report now and protect your credit.</Typography></a>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' className='blog'>Get your free credit score and score report now and protect your credit.</a>
                        </Grid> */}
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <img src={require("../../../assets/images/lt_banner_5.png")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default CreditInfo;