import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import {Grid, FormControl, InputLabel, Select, TableBody, Table, MenuItem, TableRow, TableCell,Box,Button,Checkbox,ListItemIcon,ListItemText} from "@mui/material";
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import MaterialReactTable from 'material-react-table';
import { MenuProps, useStyles, options } from "./utils";
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import config from '../../../AppConfig';
//Mock Data
import { Chart } from "react-google-charts";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { fetchData, postData } from '../../../redux/apiSlice';
import {  useDispatch } from 'react-redux';
import Badge from '@mui/material/Badge';
import useMediaQuery from '@mui/material/useMediaQuery';
export const data1 = [

    ["Month", "2010 Population"],
    ["Jan", 8.0],
    ["Feb", 37.0],
    ["Mar", 26.00],
    ["Apr", 20.99000],
    ["May", 5.26000],
   
    ["Jun", 60.78],
    ["July", 10.00],
    ["Aug", 26.00],
    ["Sep", 26.00],
    ["Oct", 26.00],
    ["Nov", 26.00],
    ["Dec", 26.00],
];

export const options1 = {
    title: "Population of Largest U.S. Cities",
    colors: ["#76C044"],

};
function AdminDashboard(props) {
    const [age, setAge] = React.useState('');
    const dispatch = useDispatch();
    const [suspendedMemberData, setTotalsuspendedEnroll] = React.useState();
    const [memberRetentionData, setTotalRetentionEnroll] = React.useState();
    const [alldataList, AllDataList] = useState([]);
    const [AidListData, setAidList] = useState([]);
    const [totalActiveEnroll, setTotalActiveEnroll] = useState();
    const [last3Months, setLast3MonthsEnroll] = useState();
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [companylogo, setCompanyLogo] = useState();
    const [graphdeatail, setGraphDetails] = useState([]);
        useEffect(() => {
            if (Object.values(columnVisibility).length > 0) {
                setColumnVisibility(columnVisibility);
            }
        }, [rowSelection]);
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [aid, setAid] = useState([]);
    const isAllSelected = AidListData.length > 0 && selected.length === AidListData.length;
    const isMobile = useMediaQuery('(max-width:600px)');
    
   

    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const aidList = async page => {
        handleFreezStart();
        const apiUrl = '/fetchpatnercompany'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setAidList(res.data);
            setSelected(res.data);
        }
    }
   


    const PartnerDashboard = async() => {
         handleFreezStart();
        const apiUrl = '/partnerdashboard';
        const args = {
            aidList:aid,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        AllDataList(res.data.affiliDetails);
        setTotalActiveEnroll(res.data.totalActiveEnroll);
        setTotalsuspendedEnroll(res.data.suspendedActionCount);
        setTotalRetentionEnroll(res.data.MemberRetentionData);
        setLast3MonthsEnroll(res.data.enroll_last3months);
        setGraphDetails(res.data.graphDetails);
        setCompanyLogo(res.data.logo);
        handleFreezStop();
    }

    useEffect(() => {
        PartnerDashboard();
    }, [aid])

    useEffect(() => {
        aidList();
    },[])
    
    const handleChange1 = (event) => {
      const value = event.target.value;
      if (value[value.length - 1] === "all") {
        setSelected(selected.length === AidListData.length ? [] : AidListData);
        setAid(AidListData);
        setSelected(AidListData);
        handleFreezStop();
        return;
      }
     
      setAid(value);
      setSelected(value);
      handleFreezStop();
      
    };

    
  
    const columns = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column

                columns: [
                    {
                        
                        accessorFn: (row) => `${(row.aid_master.aid_user.aid_user_master) ? (row.aid_master.aid_user.aid_user_master.first_name ? row.aid_master.aid_user.aid_user_master.first_name : '' ): ''}`,
                        enableClickToCopy: true,
                        header: 'First Name',
                        size: 100,
                        Cell: ({ renderedCellValue, row }) => ((row.original.aid_master.aid_user.aid_user_master) ? (row.original.aid_master.aid_user.aid_user_master.first_name ? row.original.aid_master.aid_user.aid_user_master.first_name : 'N/A') : 'N/A'),
                    },
                    {
                        
                        accessorFn: (row) => `${(row.aid_master.aid_user.aid_user_master) ? (row.aid_master.aid_user.aid_user_master.last_name ? row.aid_master.aid_user.aid_user_master.last_name : '') : ''}`,
                        enableClickToCopy: true,
                        header: 'Last Name',
                        size: 100,
                        Cell: ({ renderedCellValue, row }) => ((row.original.aid_master.aid_user.aid_user_master) ? (row.original.aid_master.aid_user.aid_user_master.last_name ? row.original.aid_master.aid_user.aid_user_master.last_name : 'N/A') : 'N/A'),
                    },
                    {
                        
                        accessorFn: (row) => `${(row.aid_master.aid_user.aid_user_master) ? (row.aid_master.aid_user.aid_user_master.email ? row.aid_master.aid_user.aid_user_master.email : '') : ''}`,
                        enableClickToCopy: true,
                        header: 'Email',
                        size: 200,
                        Cell: ({ renderedCellValue, row }) => ((row.original.aid_master.aid_user.aid_user_master) ? (row.original.aid_master.aid_user.aid_user_master.email ? row.original.aid_master.aid_user.aid_user_master.email : 'N/A') : 'N/A'),
                    },
                    {
                        
                        accessorFn: (row) => `${(row.aid_master) ? (row.aid_master.aid ? row.aid_master.aid : '') : ''}`,
                        enableClickToCopy: true,
                        header: 'AID',
                        size: 200,
                        Cell: ({ renderedCellValue, row }) => ((row.original.aid_master) ? (row.original.aid_master.aid ? row.original.aid_master.aid : 'N/A' ): 'N/A'),
                    },
                    {
                        accessorFn: (row) =>`${row.enrollcount}`, //accessorFn used to join multiple data into a single cell
                        header: 'Active Enrollments',
                        filterVariant: 'range',
                         id: 'enrollcount',
                        size: 200,
                        sortingFn: (rowA, rowB) => {
                            const countA = rowA.original.enrollcount;
                            const countB = rowB.original.enrollcount;
                            return countA - countB;
                          },
                        Cell:({renderedCellValue,row}) => (
                          <div style={{textAlign:"center"}}>
                          <Badge badgeContent={`${row.original.enrollcount}`} color={row.original.enrollcount === 0 ? 'primary':'success'} overlap="circular" max={9999}></Badge>
                          </div>          
                        ),
                      },
                ],
            },

        ],
        [],
    );
//csv option start here
const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Top 10 CRO Report',
    headers: ['First Name','Last Name','Email','AID','Active Enrollments'],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (alldataList) => {
    const exportedData = alldataList.map((row) => {
      return {
        first_name: `${(row.original.aid_master.aid_user.aid_user_master) ? (row.original.aid_master.aid_user.aid_user_master.first_name) ? row.original.aid_master.aid_user.aid_user_master.first_name : 'N/A' : 'N/A'}`,
        last_name: `${(row.original.aid_master.aid_user.aid_user_master) ? (row.original.aid_master.aid_user.aid_user_master.last_name) ? row.original.aid_master.aid_user.aid_user_master.last_name : 'N/A' : 'N/A'}`,
       email: `${(row.original.aid_master.aid_user.aid_user_master) ? (row.original.aid_master.aid_user.aid_user_master.email) ? row.original.aid_master.aid_user.aid_user_master.email : 'N/A' : 'N/A'}`,
        aid: `${(row.original.aid_master) ? (row.original.aid_master.aid) ? row.original.aid_master.aid : 'N/A' : 'N/A'}`,
        active_enrollment: `${row.original.enrollcount}`
      };
    });

    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted
        if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
            }
        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };
  //csv end here
    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography color="primary" level='h4'>CRO Dashboard</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>

                        {
                            companylogo
                                ?
                                <img className="dashlogo" style={{height:90,width:300}} src={config.BACKEND_URL+"images/cobrandLogo/"+companylogo} />
                                :
                                <img className="dashlogo" style={{height:90,width:300}} src={require("../../../assets/images/uploadLogo.png")} />
                        }
                        </Grid>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Card variant='outlined'>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <Typography level='h6'>Enrollments Summary</Typography>
                                </Grid>
                                <Grid item lg={8} md={8} sm={12} xs={12}  sx={{ textAlign: isMobile ? "left" : "right",marginTop: isMobile ? "2%" : "0%"  }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} Row>
                                        <Grid item lg={6} md={6} sm={2} xs={2}>
                                            <Typography>Select CROs:&nbsp;&nbsp; </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={10} xs={10}>
                                            <FormControl className={classes.formControl} size='small'>
                                                <InputLabel id="mutiple-select-label">Select AID</InputLabel>
                                                <Select
                                                    labelId="mutiple-select-label"
                                                    multiple
                                                    label="Select AID"
                                                    options={[{ label: "All", value: "*" }, ...options]}
                                                    value={selected}
                                                    onChange={(e) => { handleChange1(e) }}
                                                    renderValue={(selected) => selected.join(", ")}
                                                    MenuProps={MenuProps}>
                                                    <MenuItem
                                                    value="all"
                                                    classes={{
                                                        root: isAllSelected ? classes.selectedAll : ""
                                                    }}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                        classes={{ indeterminate: classes.indeterminateColor }}
                                                        checked={isAllSelected}
                                                        indeterminate={
                                                            selected.length > 0 && selected.length < AidListData.length
                                                        } />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        classes={{ primary: classes.selectAllText }}
                                                        primary="Select All"
                                                    />
                                                    </MenuItem>
                                                    {AidListData.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        <ListItemIcon>
                                                        <Checkbox checked={selected.indexOf(option) > -1} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={option} />
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                                                                
                                            </FormControl>
                                         
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography level="h4" >
                                            {totalActiveEnroll}
                                        </Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography sx={{textAlign:"center"}}>
                                            Total Active Enrollments
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography level="h4" sx={{textAlign:"center"}}>
                                            {memberRetentionData}
                                        </Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography sx={{textAlign:"center"}}>
                                          Accounts Set to Cancel
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography level="h4" sx={{textAlign:"center"}} >
                                            {suspendedMemberData}
                                        </Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography sx={{textAlign:"center"}}>
                                            Accounts with Failed Payment
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography level="h4" sx={{textAlign:"center"}}>
                                            {last3Months}
                                        </Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography sx={{textAlign:"center"}}>
                                            Last 3 MonthEnrollments
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Card className='cardstyle'>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: "1%"
                            }}>
                            <Typography><b>Top 10 CROs</b></Typography>
                        </CardOverflow>
                        <CardContent>
                            <MaterialReactTable
                                columns={columns}
                                data={alldataList}
                                enableColumnFilterModes
                                filterFns={{
                                    customSearchFilterFn: (row, id, filterValue) => {
                                      const searchValues = filterValue.toLowerCase().split(' ');
                                      const firstName = (row.original.aid_master.aid_user.aid_user_master) ? (row.original.aid_master.aid_user.aid_user_master.first_name) ? row.original.aid_master.aid_user.aid_user_master.first_name.toLowerCase() : '' : '';
                                      const lastName = (row.original.aid_master.aid_user.aid_user_master) ? (row.original.aid_master.aid_user.aid_user_master.last_name) ? row.original.aid_master.aid_user.aid_user_master.last_name.toLowerCase() : '' : '';
                                      const nameMatches = searchValues.every(value =>
                                        firstName.includes(value) || lastName.includes(value)
                                      );
                                      return nameMatches || row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, ''));
                                    },
                                  }}
                                  globalFilterFn="customSearchFilterFn"//custom search function
                                enableColumnFilters={config.DatatableColumnFilter}
                                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                                state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                                initialState={{
                                    showColumnFilters: false,
                                    density: 'compact',
                                }}
                                
                                
                                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                                positionToolbarAlertBanner="bottom"
                                enableDensityToggle={false}
                                    //top csv option
                                    renderTopToolbarCustomActions={({ table }) => (
                                        <Box
                                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                        >
                                        <Button
                                            color="primary"
                                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                                            //export all rows, including from the next page, (still respects filtering and sorting)
                                            onClick={() =>
                                                handleExportData(table.getPrePaginationRowModel().rows)
                                            }
                                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                            startIcon={<FileDownloadIcon />}
                                            variant="contained"
                                            >
                                            CSV
                                            </Button>
                                            
                                        </Box>
                                    )
                                    }
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Card className='cardstyle'>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: "1%"
                            }}
                        >
                            <Typography><b>Active Enrollments Trend</b></Typography>
                        </CardOverflow>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="borderStyle">
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="400px"
                                            data={graphdeatail}
                                            options={options1}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Card>
                </Grid>
            </>
        )
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
             {/* loader code start */}
             <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={freez}
                        onClick={handleFreezStop}
                    >
                        <Freez />
                    </Backdrop>
                </div>
            {/* loader code end */}
        </div>
    );

}

export default AdminDashboard;