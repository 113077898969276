import * as React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Typography } from '@mui/joy';
import LoginHeader from "../../../Components/LoginHeader";
import RegisterFooter from "../../Auth/RegisterFooter";
import '../../../Css/Custom.css';
function CreditCardPage(props) {
    return (
        <>
            <LoginHeader />
            <Box mt={1} mb={3}>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center",padding:"1%" }}>
                    <Typography level="h2" className="font-4"> The billing address associated with your credit or debit card might be different from your physical address – causing your card to be declined.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Typography level="h4" className="font-2" > Let’s fix it:</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <img className="imgCenter" src={require("../../../assets/images/CREDITDEBITISSUE.png")} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography sx={{fontWeight:"bold",fontSize:"1.1em"}}>
                                <a className="font-1" href="https://member.myfreescorenow.com/login" style={{fontWeight:"bold", fontSize:"1.4em"}}>- <u>Click Here </u> </a> 
                                to login to the account you started and be returned to the checkout cart
                            </Typography>
                            <Typography sx={{fontWeight:"bold",fontSize:"1.1em"}}>
                            - and if the address your bank has on file for your card is different from your current address 
                            </Typography>
                            <Typography sx={{fontWeight:"bold",fontSize:"1.3em"}}>-uncheck the box and enter your billing address! Submit.</Typography>
                            <img className="bankimg" src={require("../../../assets/images/bank.png")} />
                            <Typography sx={{fontWeight: "bold", fontSize: "1.1em",marginTop:"1%"}} >- If you notice any pending charges on your account after the failed attempts – these are just pending authorizations that will drop off.</Typography>
                            <Typography sx={{fontSize:"1.1em", fontWeight:"bold",marginTop:"1%"}}>
                            - Questions? Call us: <a href="" style={{color:"blue", fontSize:"1.2em", fontWeight: "bold"}}><u>1-888-548-2008</u></a> and we’ll complete your registration together!
                            </Typography>
                           </Grid> 
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>

    );
}
export default CreditCardPage;