import React, { useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import config from '../../AppConfig';
import { Typography } from '@mui/joy';
import {Box, Grid} from '@mui/material';
import Moment from 'moment';

        
const CallHistory = ({ callLogs }) => {
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    
    const UserListColumns = useMemo(
  
      () => [
        {
          id: 'employee', //id used to define `group` column
         
          columns: [
            {
              accessorFn: (row) => `${(row.caller) ? (row.caller) : 'N/A'}`,
              id: 'Agent', //id is still required when using accessorFn instead of accessorKey
              header: 'Agent Name',
              size: 150,
  
            },
            {
              accessorFn: (row) => `${row.callType ? row.callType : 'N/A'}`, //accessorFn used to join multiple data into a single cell
              id: 'Call Type', //id is still required when using accessorFn instead of accessorKey
              header: 'Call Type',
              size: 150,
            },
            {
              accessorFn: (row) => `${row.to ? row.to : 'N/A'}`, //accessorFn used to join multiple data into a single cell
              id: 'Customer Number', //id is still required when using accessorFn instead of accessorKey
              header: 'Customer Number',
              size: 150,
            },
            {
              accessorFn: (row) => `${row.date ? row.date : 'N/A'}`, //accessorFn used to join multiple data into a single cell
              id: 'Date', //id is still required when using accessorFn instead of accessorKey
              header: 'Date',
              size: 150,
              Cell: ({ row }) => (
                <div>
                  {row.original.date ? Moment(row.original.date).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
                </div>
              ),
            },
            {
                accessorFn: (row) => `${row.callStatus ? row.callStatus : 'N/A'}`, //accessorFn used to join multiple data into a single cell
                id: 'Call Status', //id is still required when using accessorFn instead of accessorKey
                header: 'Call Status',
                size: 150,
            },
            {
              accessorFn: (row) => `${row.callDuration ? row.callDuration : 'N/A'}`, //accessorFn used to join multiple data into a single cell
              id: 'Call Duration', //id is still required when using accessorFn instead of accessorKey
              header: 'Call Duration',
              size: 150,
            },
            {
              accessorFn: (row) => `${row.awsRecordingUrl}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
              header: 'Recording',
              id: 'Recording',
              size: 150,
              Cell: ({ row }) => (
                <a href={row.original.awsRecordingUrl} target="_blank" rel="noopener noreferrer">
                  {row.original.awsRecordingUrl ? 'View Recording' : ''}
                </a>
              )
            },
          ],
        },
  
      ],
      [],
    );
  
    // CSV end here
  
    return (
    <div>
      <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography level="h4" color="primary">Call History</Typography>
        </Grid>
          <Box mt={2}>
            <MaterialReactTable
              columns={UserListColumns}
              data={callLogs}
              filterFns={{
                customSearchFilterFn: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
              }} 
              globalFilterFn="customSearchFilterFn" //exact serach filter functionality
              enableColumnFilterModes
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
              initialState={{ showColumnFilters: false, density: 'compact' }}
              enableColumnFilters={config.DatatableColumnFilter}
              onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
              positionToolbarAlertBanner="bottom"
              enableDensityToggle={false}
              muiTableHeadCellProps={{
                sx: {
                  backgroundColor: "#F6FAFF",
                },
              }}
            />
          </Box>
    </div>
          
    );
}

export default CallHistory;