import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box,  Grid,  FormControl, Dialog, DialogActions,  DialogTitle,  Button,
    DialogContent, FormHelperText
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography, Card, CardContent, CardOverflow,  Table } from '@mui/joy';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
//boostrap dialog 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

const markCompleteReasonArr = [
    "Walkthrough Completed",
    "Received Links But No Walkthrough",
    "Affiliate Doesn't need Walkthrough",
    "Automated Via Enrollment/CS/RP/CB",
    "Cleanup",
    "Other"
]

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function ReportIssueAudit(props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function ContentLayout() {
        return (
            <>

                <Box>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="h3" color="primary">Bank Issues Details Follow Up</Typography>
                    </Grid>

                    <Card>

                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                            }}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Typography level="h5" sx={{ mb: 0.5 }}>Bank Issues Details (AID- 2ndChancefreedom)</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}  mt={2}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Table borderAxis="both">

                                        <tbody>
                                            <tr >
                                                <th scope="row" style={{ borderTop: "1px solid #d8d8df" }} width="70">User Name</th>
                                                <td style={{ borderTop: "1px solid #d8d8df" }} width="30"></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Phone no</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">AID</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Bank Name</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Name on Account</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Bank Routing (ABA/ACH) Number</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Bank Account Number</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Type of Account</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">And is Account</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email Address to notify of transfers sent</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">ACH Info Added to Bank</th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Last ACH update</th>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Card variant="plain" size="sm" sx={{ boxShadow: 'none' }}>
                                        <CardOverflow
                                            sx={{
                                                bgcolor: '#F6FAFF',
                                                borderColor: '#F6FAFF',
                                                padding: '1%',
                                            }}>
                                            <Grid container item md={12} sx={{ justifyContent: 'space-between' }}>
                                                <Typography level="h4" color="primary">ACTIVITY LOG</Typography>
                                                <Button
                                                    variant="contained"
                                                    color='success'
                                                    onClick={handleClickOpen}
                                                >New Activity</Button>
                                            </Grid>
                                        </CardOverflow>
                                        <CardContent>
                                            <Typography>No followup available.</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                            <Card mt={2}>
                                <CardOverflow
                                    sx={{
                                        bgcolor: '#F6FAFF',
                                        borderColor: '#F6FAFF',
                                        padding: '1%',
                                    }}>
                                    <Typography level="h5" sx={{ mb: 0.5 }}>Issues List</Typography>
                                </CardOverflow>
                                <CardContent>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Table borderAxis="both" >
                                            <thead>
                                                <tr>
                                                    <th style={{ fontWeight: 'bold' }}>Raised Issues</th>
                                                    <th style={{ fontWeight: 'bold' }}>Closed Issues</th>
                                                    <th style={{ fontWeight: 'bold' }}>Status</th>
                                                    <th style={{ fontWeight: 'bold' }}>Raised Date</th>
                                                    <th style={{ fontWeight: 'bold' }}>Closed Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Please verify Account type and is account field with affiliate.</td>
                                                    <td>N/A</td>
                                                    <td>Pending</td>
                                                    <td>02-28-2022</td>
                                                    <td>N/A</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Grid>
                                </CardContent>
                            </Card>

                        </CardContent>
                    </Card>
                    {/* new activity start */}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle textAlign={'center'} p={5} sx={{ backgroundColor: "#F6FAFF" }}>
                            <Typography level="h4" color='primary'>Add Activity</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box mt={1}>
                                <Grid container item spacing={2}>
                                <Grid item xs >
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DemoContainer components={['DatePicker']} fullWidth size="small">
                                                <DatePicker label="Follow Up Date*"
                                                    margin="dense"
                                                    // onChange={handleChange} 
                                                    name="followupDate"
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        <FormHelperText></FormHelperText>
                                    </Grid>
                                    <Grid item xs sx={{'& .MuiTextField-root': { minWidth: "250px" }}}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker']} fullWidth size="small">
                                                <TimePicker label="Follow Up Time*"
                                                    margin="dense"
                                                    format="hh:mm:ss"
                                                    name="followupTime"
                                                    fullWidth
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        <FormHelperText></FormHelperText>
                                    </Grid>
                                </Grid>
                                    
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                                    <FormControl fullWidth size="small">
                                        <TextareaAutosize
                                            style={{ width: "100%" }}
                                            required
                                            margin="dense"
                                            aria-label="minimum height"
                                            minRows={6}
                                            placeholder="Enter Description*"
                                            name="followupNotes" />
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Grid>
                               
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button autoFocus variant="contained" sx={{ minWidth: 250 }} onClick={handleClose}>
                                        CLOSE
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" sx={{ minWidth: 250 }} color="success" type="submit" >
                                        SAVE
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    {/* new activity end */}
                </Box>
            </>
        );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default ReportIssueAudit;