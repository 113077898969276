import React, {useState,useEffect} from 'react';
import { Grid, TextField, InputLabel, Divider, IconButton } from '@mui/material';
import { Typography } from '@mui/joy';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AuthUser from '../Auth/AuthUser';

function OfferDetails(props) {
    const {http} = AuthUser();

    //chagers and subscription values functions and variable starts
    const [offerdetails, setOfferDetails] = useState();
    const getOfferDetails = () => {
        props.handleFreezCallback(true);
        http.post("/getofferdetailsofpid", {
            pid: props.pid,
        })
            .then((res) => {
                props.handleFreezCallback(false);
                if (res.data.success === true) {
                    setOfferDetails(res.data.data[0])
                } else {
                    props.errorfromcdCallback("Something went wrong please try after some time")
                }
            })
            .catch((error) => {
                props.errorfromcdCallback("Network Error")
            });
    }
    useEffect(() => {
        getOfferDetails();
    }, []);
    //chagers and subscription values functions and variable ends

    return (
        <Grid item lg={5} md={5} sm={12} xs={12}>
            {offerdetails && props.step < 4 ?
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    {
                        offerdetails.is_sponsored ?
                            <Typography level="h6"><b>{props.pid == 67902 ? "You are enrolling in a MyFreeScoreNow credit monitoring membership Sponsored by Cordova Financial."   : offerdetails.description}</b></Typography>
                        :
                    offerdetails.days == 0 ?
                        props.pid == 11233 ?
                        <Typography level="h6"><b>Get all 3 Reports and 3 Scores now!  A new 3-Bureau report included each month. $1.00 Upfront Activation Fee AND {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged at time of order. Payments non-refundable. Call 888-548-2008 to cancel to avoid future billings.</b></Typography>
                        :
                        <Typography level="h6"><b>Get all 3 Reports and 3 Scores now!  A new 3-Bureau report included each month. {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged at time of order. Payments non-refundable. Call 888-548-2008 to cancel to avoid future billings.</b></Typography>
                    :
                        offerdetails.hasFee == 'None' ?
                            <Typography level="h6"><b>Get all 3 Reports and 3 Scores now! {offerdetails.days}-Day Free Trial of credit monitoring. {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged after the {offerdetails.days}-Day Trial Period. Payments non-refundable. Call 1-888-548-2008 to cancel to avoid future billings.</b></Typography>
                        :
                            <Typography level="h6"><b>Get all 3 Reports and 3 Scores now! New 3-Bureau Report included monthly $1.00 Upfront Activation Fee. Monthly MyFreeScoreNow Membership of {offerdetails.price} automatically charged after {offerdetails.days}-Day Trial. Payments non-refundable. Call 888-548-2008 to cancel to avoid future billings.</b></Typography>                                                       
                    }
                </Grid>
            :
            null}
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                <Typography level="h6" color="primary" ><b>What Our Customers Say</b><IconButton><QuestionAnswerIcon color="primary" /></IconButton></Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                {props.step == 1 ?
                    <Typography>"Checking my credit on this site was safe and secure. When I called in with a question, customer service was fast and helpful." <span style={{float:'right'}}>-<i> Mary Claire,</i> May 2022</span> </Typography>                
                :
                    props.step == 4 ?
                        <Typography>"I love this web site because of ScoreBoost™, its user friendly, easy to navigate. Customer service was very professional and knowledgeable and helped me with a item impacting my score. Love the service!" <span style={{float:'right'}}>- <i>Keesha B</i> May 2022</span></Typography>
                    :
                        <Typography>"When I started using the site I immediately noticed what was different from other credit sites. This one actually gives you tips and things you can use to continue rebuilding your credit. It also offers a financial tab to assist with tracking spending and for budgeting." <span style={{float:'right'}}>- <i>Jared P.,</i> May 2022</span></Typography>
                }
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                <Typography level="h6" color="primary" ><b>Did You Know?</b></Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography>Checking your own credit history <b><i>does not</i></b> lower your credit score.</Typography>
            </Grid>
            {props.step == 4 ?
                offerdetails ?
                <>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h6" sx={{ color: "#ED7E2E" }}><b>Offer Details</b></Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    {
                        offerdetails.days == 0 ?
                            <Typography sx={{ color: "#ED7E2E" }}>By submitting your secure order<b> you will be charged {offerdetails.price ? offerdetails.price : "0"} immediately </b>for your membership in MyFreeScoreNow credit monitoring. You agree that your credit/debit card will automatically be charged {offerdetails.price ? offerdetails.price : "0"} on a monthly basis unless and until you call 1-888-548-2008 to cancel your membership. Payments are non-refundable.</Typography>    
                        :
                            <Typography sx={{ color: "#ED7E2E" }}>By submitting your secure order you will be {offerdetails.hasFee ? "immediately charged a $1.00 non-refundable fee for member activation, be" : ""} eligible to receive your free credit scores, and begin your {offerdetails.days?offerdetails.days:'0'}-day trial membership in MyFreeScoreNow credit monitoring. <b>At the end of the {offerdetails.days?offerdetails.days:'0'}-day trial period, you agree that your credit/debit card will automatically be charged {offerdetails.price ? offerdetails.price : "0"}</b> on a monthly basis unless and until you call 1-888-548-2008 to cancel your membership. Payments are non-refundable.</Typography>
                    }
                </Grid>
                </>
                : null
            : null            
            }
        </Grid>
    
    );
}

export default OfferDetails;