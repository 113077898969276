import {React, useState} from 'react';
import {
    Box, Grid, FormControl, TextField, Button, Card,  CardContent,
    Radio, RadioGroup, FormControlLabel, FormLabel,Typography,Stack
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, useNavigate} from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import InputMask from 'react-input-mask';
import RegisterFooter from './RegisterFooter';
import LanguageIcon from '@mui/icons-material/Language';
import AuthUser from "../../Components/Auth/AuthUser";
import swal from "sweetalert";
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': 'white',
            '--TextField-brandBorderHoverColor': 'white',
            '--TextField-brandBorderFocusedColor': 'white',
            '& label.Mui-focused': {
              color: 'black',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
            },
          },
        },
      },
    },
  });
function RegisterToWin(props) {
    const { http } = AuthUser();
    const[isDisabled, setIsDisabled] = useState(false);
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const theme = useTheme();
    const outerTheme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const checkUsaTLD = (email) => {
        if (email.includes('@')) {
            http.post("/checkUsaTLD", {
                email: email,
            })
            .then((res) => {
                if (res.data.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            })
        }
    }

    const SignupSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please Enter your First Name.'),

        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please Enter your Last Name.'),

        Email: Yup.string()
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/i, "Invalid Email Format.")
            .required('Please Enter your Email Address.'),

        PhoneNumber: Yup.string()
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid Phone Number Format.')
            .required('Please Enter your Mobile Number.'),

        isAffiliate: Yup.string()
            .required('Please Select Option'),

        creditconTicket: Yup.string()
        .required('Please Select Option'),
    });
    
    return (
        <>
            <Box id="primaryColor" sx={{ flexGrow: 1, boxShadow: 0, justifyContent: "center", alignItems: "center", borderBottomWidthWidth:'3px',borderBottomStyle:'solid',borderBottomColor:props.customColorArray?`${props.customColorArray.primaryColor}`: "#30BE2D" }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ padding: "0 2%", margin: "0" }} mb={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid container direction="row" justifyContent={isMobile ? "center" : "flex-start"} spacing={4} >
                        <Grid item  >
                            <Link to='/'>
                                <img alt="#" src={require('../../assets/images/mainLogo.jpg')} />
                            </Link>
                        </Grid>
                        <Grid item mt={2} mb={2}>
                            <Stack id="userwayBtn" tabIndex="0" direction="row" className="userwayLogin" >                                
                                <LanguageIcon />
                                <Typography>en/esp/fr/...</Typography>                                
                            </Stack> 
                        </Grid>
                       
                    </Grid>
                </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent={isMobile ? "center" : "flex-end"}>
                            <img alt="#" src={require('../../assets/images/creditcon_logo.jpg')}  className='headerLogoImg'/>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box className="credit-back" sx={{ padding: "2%" }}>
                <Grid container item direction="row" spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={3} sx={{justifyContent:"center",textAlign:"justify",width:"80%",marginLeft:"15%"}}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography variant="h5" gutterBottom className="creditcon-font" sx={{textAlign:"center",fontWeight: "600 !important",fontSize: "2.5rem !important"}}>
                                            Unlock your chance to win $1,500 cash </Typography>
                                            <Typography variant="h6" gutterBottom className="creditcon-font">  Register for our exclusive MyFreeScoreNow raffle! It's easy, simply sign up and then visit us at booth 15-16 to collect your raffle ticket and discover firsthand how we are revolutionizing
                                            the industry with our innovative complimentary solutions.  
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={4}>
                                        <Typography variant="h6" gutterBottom className="creditcon-font">Plus, come and roll the dice at booth 15-16, to boost your chances of winning. Don't miss this incredible opportunity, register now!  
                                        </Typography>
                                    </Grid>
                                </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Grid noValidate  >
                                <Formik
                                        initialValues={{
                                            firstName: '',
                                            lastName: '',
                                            Email: '',
                                            PhoneNumber: '',
                                            isAffiliate: '',
                                            creditconTicket: '',
                                        }}
                                        validationSchema={SignupSchema}
                                        onSubmit={(values, errors) => {
                                            setIsDisabled(true);
                                            http.post("/store-register-to-win", {
                                                firstName: values.firstName,
                                                lastName: values.lastName,
                                                email: values.Email,
                                                phoneNo: values.PhoneNumber,
                                                isAffiliate: values.isAffiliate,
                                                creditconTicket: values.creditconTicket,
                                            })
                                                .then(async (res) => {
                                                    if (res.data.success === true) {
                                                        // handleFreezStop();
                                                    navigate('/registration-thankyou', { state: { isAffilate: values.isAffiliate }})
                                                    } else {
                                                        swal({
                                                            title: "Failed",
                                                            text: res.data.message,
                                                            icon: "error",
                                                            button: "Ok",
                                                        })
                                                    }
                                                    setIsDisabled(false);
                                                })
                                               
                                        }}>
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                           
                                            <div className="input-white" style={{width:"80%",marginLeft:"15%"}}>
                                                 <ThemeProvider theme={customTheme(outerTheme)}>
                                                <Grid container >
                                               
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            required
                                                            name="firstName"
                                                            label="First Name"
                                                            margin="dense"
                                                            size="small"
                                                            value={values.firstName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            fullWidth
                                                            // className="input-white"
                                                            error={touched.firstName && !!errors.firstName}
                                                            helperText={touched.firstName && errors.firstName}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            required
                                                            name="lastName"
                                                            label="Last Name"
                                                            margin="dense"
                                                            size="small"
                                                            onChange={handleChange('lastName')}
                                                            value={values.lastName}
                                                            onBlur={handleBlur('lastName')}
                                                            fullWidth
                                                            // className="input-white"
                                                            error={touched.lastName && !!errors.lastName}
                                                            helperText={touched.lastName && errors.lastName}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            name="Email"
                                                            label="Email"
                                                            size="small"
                                                            margin="dense"
                                                            // onChange={handleChange('Email')}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                checkUsaTLD(e.target.value)
                                                            }}
                                                            value={values.Email}
                                                            onBlur={handleBlur('Email')}
                                                            fullWidth
                                                            // className="input-white"
                                                            error={touched.Email && !!errors.Email}
                                                            // helperText={touched.Email && errors.Email}
                                                        />
                                                         <p className="Errorp" style={{ color: 'white',fontWeight: 400, fontSize: "0.70rem",marginTop:0,marginBottom:"1%",marginLeft:"3%"}}>{touched.Email && errors.Email ? errors.Email : checkUsaTldStatus ? "Invalid email format." : ''}</p>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        
                                                        <InputMask
                                                            mask='(999) 999-9999'
                                                            maskChar={null}
                                                            type="text"
                                                            size="small"
                                                            required
                                                            fullWidth
                                                            id="PhoneNumber"
                                                            label="Phone Number"
                                                            name="PhoneNumber"
                                                            margin="dense"
                                                            // className="input-white"
                                                            onChange={handleChange('PhoneNumber')}
                                                            value={values.PhoneNumber}
                                                            onBlur={handleBlur('PhoneNumber')}
                                                            autoComplete="PhoneNumber"
                                                            error={touched.PhoneNumber && !!errors.PhoneNumber}
                                                            helperText={touched.PhoneNumber && errors.PhoneNumber}
                                                        >
                                                            {(inputProps) =>
                                                                <TextField
                                                                    label="Phone Number" variant="outlined"
                                                                    {...inputProps}

                                                                />
                                                            }
                                                        </InputMask>
                                                    </Grid>
                                                    <Grid item xs={12} mt={2}>
                                                        <Typography level="h5" color="white">Are You an existing MyFreeScoreNow Affiliate?</Typography>
                                                        <FormControl >
                                                            <RadioGroup
                                                            aria-labelledby='demo-radio-buttons-group-label'
                                                                row
                                                                name="isAffiliate"
                                                                value={values.isAffiliate}
                                                                onChange={handleChange}
                                                              className="input-whitess"
                                                            >
                                                                <FormControlLabel value="1" control={<Radio sx={{color:'white'}}/>} label="Yes"  sx={{color:'white'}}/>
                                                                <FormControlLabel value="0" control={<Radio  sx={{color:'white'}}/>} label="No" sx={{color:'white'}}/>
                                                            </RadioGroup>
                                                            {touched.isAffiliate && errors.isAffiliate ? <p className="Errorp"  style={{ color: 'white',fontWeight: 400, fontSize: "0.70rem",marginTop:0,marginBottom:"1%",marginLeft:"3%"}}>{errors.isAffiliate}</p> : null}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} mt={2}>
                                                        <Typography level="h5" color="white">Did you use MyFreeScoreNow's promo code to buy your CreditCon ticket?</Typography>
                                                        <FormControl >
                                                            <RadioGroup
                                                            aria-labelledby='demo-radio-buttons-group-label'
                                                                row
                                                                name="creditconTicket"
                                                                value={values.creditconTicket}
                                                                onChange={handleChange}
                                                              className="input-whitess"
                                                            >
                                                                <FormControlLabel value="1" control={<Radio sx={{color:'white'}}/>} label="Yes"  sx={{color:'white'}}/>
                                                                <FormControlLabel value="0" control={<Radio  sx={{color:'white'}}/>} label="No" sx={{color:'white'}}/>
                                                            </RadioGroup>
                                                            {touched.creditconTicket && errors.creditconTicket ? <p className="Errorp"  style={{ color: 'white',fontWeight: 400, fontSize: "0.70rem",marginTop:0,marginBottom:"1%",marginLeft:"3%"}}>{errors.creditconTicket}</p> : null}
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            onClick={handleSubmit}
                                                            disabled={isDisabled}
                                                            sx={{ minWidth: 200,color:"#0088ff",backgroundColor:"white" ,'&:hover':{backgroundColor:"white !important",color:'#0088ff'}}}
                                                        >   
                                                           <b> Register</b>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                </ThemeProvider>
                                            </div>
                                        )}
                                    </Formik>
                                </Grid>
                            
                    </Grid>
                </Grid>
                
            </Box>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3} p={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography className='creditcon-fonting'><b>Disclosures: </b>Registration on this form is required in order to enter the raffle and to become eligible to win the prize from the raffle, however, no purchase of any service or product from MyFreeScoreNow is required to register for the raffle.</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}><Typography  className='creditcon-fonting'> Prize will be awarded to one person on the winning raffle ticket only. The drawing will be held on Thursday May 2nd at 5:00pm at Booth 15.  Inaccurate contact information on the registration form will disqualify the raffle ticket from eligibility to win.
                     Offer Void where prohibited by law. </Typography></Grid>
                     <Grid item lg={12} md={12} sm={12} xs={12}><Typography  className='creditcon-fonting'>To be eligible to win the price you must have paid for a ticket to CreditCon 2024. Exhibitors and Sponsors of CreditCon 2024 are ineligible.</Typography></Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}><Typography  className='creditcon-fonting'>By entering the raffle contest, participants consent to the collection, use, and disclosure of their personal information to administer the contest and award prizes. Additionally, participants may be contacted directly for other internal promotional offers
                        or marketing purposes related to MyFreeScoreNow. Personal information will not be shared with third parties, except as necessary for the fulfillment of prizes or as required by law.</Typography></Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}><Typography  className='creditcon-fonting'>All participants agree that MyFreeScoreNow and its affiliates shall not be liable for any damages, losses, or injuries arising out of or in connection with the raffle contest, including but not limited to, 
                    participation in the contest, acceptance, possession,use, or misuse of any prize. MyFreeScoreNow reserves the right to modify, suspend, or cancel the contest at any time without prior notice. </Typography></Grid>

                </Grid>
            <RegisterFooter />
        </>
    );
}

export default RegisterToWin;