import React, { useMemo, useEffect, useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Typography, Box, Grid, Button, Paper, Stack,TableCell,TableRow,TableContainer } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useLocation } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { postData } from '../../../redux/apiSlice';
import Badge from '@mui/material/Badge';
import Table from '@mui/joy/Table';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { useNavigate } from 'react-router-dom';
import { 
	calculateTotalProductCost,
	calculateLicenseFees,
	calculateGrossProfitValue,
    calculateGrossProfitPercentage,
} from '../../../Components/CommonFormula';
function TotalCompanyPerformance() {
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [isLoading, setIsLoading] = useState(false);
    const {state} = useLocation();
    const { startDate,endDate,id } = state;
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [CompanyDetails,setCompanyDetails] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
    }, [rowSelection]);

    useEffect(() => {
        getCompanyPerformance();
    }, []);
    
    const getCompanyPerformance = async() => {
        handleFreezStart();
        const apiUrl = '/admin/childreferralReport';
        
        const args = {
            fromDate: startDate,
            toDate: endDate,
            companyId: id
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        
        if(res) {
            setData(res.childAffiliateDetails);
            setCompanyDetails(res.data);
            handleFreezStop();

        }
    } 

    //loader function and variable start
	const [freez, setFreez] = React.useState(false);
	const handleFreezStop = () => {
		setFreez(false);
	};
	const handleFreezStart = () => {
		setFreez(true);
	};
	//loader function and variable end
    const totalreferredCompanyCount = useMemo(() => data.reduce((total, row) => total + row.referredCompanyCount, 0), [data]);
	const allAidMeberCount = useMemo(() => data.reduce((total, row) => total + row.allAidMeberCount, 0), [data]);
	const toTotalNetRevenue = useMemo(() => data.reduce((total, row) => total + row.allAidfinalAmount, 0), [data]);
	const totalAllAidcomputedCommission = useMemo(() => data.reduce((total, row) => total + row.allAidcomputedCommission, 0), [data]);
	const totalAllAidfinalAmount1 = useMemo(() => parseFloat(toTotalNetRevenue) - parseFloat(totalAllAidcomputedCommission), [toTotalNetRevenue, totalAllAidcomputedCommission]);
	
	const calculateGrossProfitSum = useMemo(() => {
		let sum = 0;
		data.forEach((row) => {
		  const rowValue = calculateGrossProfitValue(row)
		  sum += parseFloat(rowValue);
		});
		return sum;
	}, [data]);

	const profitabilitySum = useMemo(() => {
		let sum = 0;
		sum = ((calculateGrossProfitSum / toTotalNetRevenue) * 100).toFixed(1);
		return sum ? sum : 0;
	  }, [data]);
      const columns = useMemo(
        () => [
            {
                accessorFn: (data) => `${data.company_name?data.company_name.trim(): ''}`, 
                filterVariant: 'range',
                id: 'CompanyName',
                header: 'Company Name',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (row.original.company_name ? row.original.company_name.trim() : "N/A"),
            },
            {
                accessorFn: (data) => `${data.aidListwithComma?data.aidListwithComma: ''}`, 
                filterVariant: 'range',
                id: 'aid',
                header: 'AID',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (row.original.aidListwithComma ? row.original.aidListwithComma : "N/A"),
            },
            {
              accessorFn: (data) => `${data.referredCompanyCount}`,
                filterVariant: 'range',
                id: 'memberCount',
                header: 'Affiliate Referred',
                size: 100,
                muiTableBodyCellCopyButtonProps: {
                fullWidth: true,
                sx: { justifyContent: 'flex-center' },
                },
                Cell: ({ renderedCellValue, row }) => (
                <div style={{ textAlign: "center" }}>
                    <Badge badgeContent={`${row.original.referredCompanyCount}`} color={row.original.referredCompanyCount === 0 ? 'primary' : 'success'} overlap="circular" max={99999}></Badge>
                </div>
                ),
            },
            {
                accessorFn: (data) => `${data.allAidMeberCount?data.allAidMeberCount:''}`, 
                filterVariant: 'range',
                id: 'newEnrollments',
                header: 'New Enrollments',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidMeberCount ==0 ? 0 :row.original.allAidMeberCount),
            },
            
            {
                accessorFn: (data) =>`${data.allAidpaymentAmount}`, 
                filterVariant: 'range',
                id: 'payment',
                header: 'Payments ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidpaymentAmount  == 0 ? 0 : (row.original.allAidpaymentAmount).toFixed(2)),
            },
            {
                accessorFn: (data) => `${data.allAidPaymentCount ? data.allAidPaymentCount:''}`,  
                filterVariant: 'range',
                id: 'noofpayment',
                header: 'No of Payments',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidPaymentCount ==0 ? 0 :row.original.allAidPaymentCount),

            },
            {
                accessorFn: (data) => `${data.allAidthreeBAmount}`, 
                filterVariant: 'range',
                id: '3bpayment',
                header: '3B Payments ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidthreeBAmount  == 0 ? 0 : (row.original.allAidthreeBAmount).toFixed(2) ),
            },
            {
                accessorFn: (data) => `${data.allAidchargeBackAmount}`, 
                filterVariant: 'range',
                id: 'chargebacks',
                header: 'Chargebacks ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidchargeBackAmount == 0 ? 0 : (row.original.allAidchargeBackAmount).toFixed(2) ),
            },
            {
                accessorFn: (data) => `${data.allAidrefundAmount}`, 
                filterVariant: 'range',
                id: 'Refunds',
                header: 'Refunds ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidrefundAmount == 0 ? 0 : (row.original.allAidrefundAmount).toFixed(2) ),
            },
            {
                accessorFn: (data) => `${data.allAidcomputedCommission}`, 
                filterVariant: 'range',
                id: 'commissions',
                header: 'Commissions ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidcomputedCommission == 0 ? 0 : (row.original.allAidcomputedCommission).toFixed(2) ),
            },
            {
                accessorFn: (data) => `${data.allAidtotalAmount}`, 
                filterVariant: 'range',
                id: 'Total Payments',
                header: 'Total Payments ($)',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (row.original.allAidtotalAmount == 0 ? 0 : (row.original.allAidtotalAmount).toFixed(2) ),
            },
            {
                accessorFn: (data) => `${data.allAidtotalrefundAmount}`, 
                filterVariant: 'range',
                id: 'Total Refunds',
                header: 'Total Refunds ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidtotalrefundAmount == 0 ? 0 : (row.original.allAidtotalrefundAmount).toFixed(2) ),
            },
            {
                accessorFn: (data) => `${data.allAidfinalAmount}`, 
                filterVariant: 'range',
                id: 'Net Revenue',
                header: 'Net Revenue ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidfinalAmount == 0 ? 0 : (row.original.allAidfinalAmount).toFixed(2)),
            },
            {
                accessorFn: (data) => `${data.allAidfinalAmount}`, 
                filterVariant: 'range',
                id: 'Revenue after Commissions',
                header: 'Revenue after Commissions ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.allAidfinalAmount ? (row.original.allAidfinalAmount - row.original.allAidcomputedCommission).toFixed(2)  : 0),

            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.ACTION ? data.costDetails.ACTION.total : 0 : ''}`, 
                filterVariant: 'range',
                id: 'Action',
                header: 'ACTION',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.ACTION ? row.original.costDetails.ACTION.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.CREDIT_MONITORING ? data.costDetails.CREDIT_MONITORING.total : 0 : '' }`, 
                filterVariant: 'range',
                id: 'CREDIT_MONITORING',
                header: 'CREDIT_MONITORING',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.CREDIT_MONITORING ? row.original.costDetails.CREDIT_MONITORING.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.CREDIT_REPORT ? data.costDetails.CREDIT_REPORT.total : 0 : ''}`, 
                filterVariant: 'range',
                id: 'CREDIT_REPORT',
                header: 'CREDIT_REPORT',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.CREDIT_REPORT ? row.original.costDetails.CREDIT_REPORT.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.CREDIT_SCORE ? data.costDetails.CREDIT_SCORE.total : 0 : '' }`, 
                filterVariant: 'range',
                id: 'CREDIT_SCORE',
                header: 'CREDIT_SCORE',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.CREDIT_SCORE ? row.original.costDetails.CREDIT_SCORE.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.ID_FRAUD_INSURANCE ? data.costDetails.ID_FRAUD_INSURANCE.total : 0 : ''}`, 
                filterVariant: 'range',
                id: 'ID_FRAUD_INSURANCE',
                header: 'ID_FRAUD_INSURANCE',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.ID_FRAUD_INSURANCE ? row.original.costDetails.ID_FRAUD_INSURANCE.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.IDMA ? data.costDetails.IDMA.total : 0 : '' }`, 
                filterVariant: 'range',
                id: 'IDMA',
                header: 'IDMA',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.IDMA ? row.original.costDetails.IDMA.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.MEMBER_SUPPORT_CALL ? data.costDetails.MEMBER_SUPPORT_CALL.total : 0 : '' }`, 
                filterVariant: 'range',
                id: 'MEMBER_SUPPORT_CALL',
                header: 'MEMBER_SUPPORT_CALL',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.MEMBER_SUPPORT_CALL ? row.original.costDetails.MEMBER_SUPPORT_CALL.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.MONEY_MANAGER ? data.costDetails.MONEY_MANAGER.total : 0 : '' }`, 
                filterVariant: 'range',
                id: 'MONEY_MANAGER',
                header: 'MONEY_MANAGER',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.MONEY_MANAGER ? row.original.costDetails.MONEY_MANAGER.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.PRIVACY ? data.costDetails.PRIVACY.total : 0 : '' }`, 
                filterVariant: 'range',
                id: 'PRIVACY',
                header: 'PRIVACY',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.PRIVACY ? row.original.costDetails.PRIVACY.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ?  data.costDetails.SSN_LOOKUP ? data.costDetails.SSN_LOOKUP.total : 0 : '' }`, 
                filterVariant: 'range',
                id: 'SSN_LOOKUP',
                header: 'SSN_LOOKUP',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
                Cell: ({ renderedCellValue, row }) => (row.original.costDetails ?  row.original.costDetails.SSN_LOOKUP ? row.original.costDetails.SSN_LOOKUP.total : 0 : '' ),
            },
            {
                accessorFn: (data) => `${data.costDetails ? data.costDetails.THREE_BUREAU_REPORT ? data.costDetails.THREE_BUREAU_REPORT.total : 0 : '' }`,
                filterVariant: 'range',
                id: 'THREE_BUREAU_REPORT',
                header: 'THREE_BUREAU_REPORT',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
            },
            {
                    
                accessorFn: (data) => `${ calculateTotalProductCost(data) }`,
                filterVariant: 'range',
                id: 'Total Product Cost',
                header: 'Total Product Cost ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
            },
            {
                accessorFn: (data) => `${ calculateLicenseFees(data) }`,
                filterVariant: 'range',
                id: 'License Fee',
                header: 'License Fee ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
            },
            {
                
                accessorFn: (data) => `${ calculateGrossProfitValue(data) }`,
                filterVariant: 'range',
                id: 'Gross Profit',
                header: 'Gross Profit ($)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
            },
            {
               
                
                accessorFn: (data) => `${ calculateGrossProfitPercentage(data) }`,

                    
                filterVariant: 'range',
                id: 'profitability',
                header: 'Gross Profit (%)',
                size: 50,
                muiTableBodyCellCopyButtonProps: {
                    fullWidth: true,
                    sx: { justifyContent: 'flex-center' },
                    },
            },       
        ],
        [],
    );
   
        //csv start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName:'Company Details- ${CompanyDetails.company_name}.csv',
    headers: [
        'Company Name',
        'First Name', 
		'Last Name', 
		'Email',
		'Phone no.',
		'Marketing Type',
		'Affiliate Referred','New Enrollments','Payments ($)','No of Payments','3B Payments ($)','Chargebacks ($)','Refunds ($)','Commissions ($)','Total Payments ($)','Total Refunds ($)','Net Revenue ($)','Revenue after Commissions ($)',
        'ACTION','CREDIT_MONITORING','CREDIT_REPORT', 'CREDIT_SCORE','ID_FRAUD_INSURANCE','IDMA', 'MEMBER_SUPPORT_CALL', 'MONEY_MANAGER',
        'PRIVACY', 'SSN_LOOKUP','THREE_BUREAU_REPORT','Total Product Cost ($)','License Fee ($)','Gross Profit ($)', 'Gross Profit (%)',
       
    ],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (rows) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    const exportedData = [];
    rows.forEach((row) => {
                const totalProductCost = calculateTotalProductCost(row.original);
                const license_fees = calculateLicenseFees(row.original);
                const grossProfits = calculateGrossProfitValue(row.original);
                const grossProfitPercentage = calculateGrossProfitPercentage(row.original) + '%';
      const mainTableRow = {
        company_name: `${row.original.company_name}` || "N/A",
        first_name: `${row.original.first_name}` || "N/A",
        last_name: `${row.original.last_name}` || "N/A",
        email: `${row.original.email}` || "N/A",
        phone: `${row.original.phone_no}` || "N/A",
        marketing_type: `${row.original.marketing_type ||'N/A'}`,
        affiliate_referred:`${row.original.referredCompanyCount}`||"N/A",
        new_enrollment:`${row.original.allAidMeberCount === 0 ? 0 : row.original.allAidMeberCount}`||"N/A",
        payments: `${(row.original.allAidpaymentAmount == 0 ? 0 : (row.original.allAidpaymentAmount).toFixed(2))}`,
        no_of_payment:`${(row.original.allAidPaymentCount == 0 ? 0 :(row.original.allAidPaymentCount).toFixed(2))}`,
        threeb_payment: `${row.original.allAidthreeBAmount == 0 ? 0 : parseFloat(row.original.allAidthreeBAmount.toFixed(2))}`,
        chargeback: `${(row.original.allAidchargeBackAmount == 0 ? 0 : (row.original.allAidchargeBackAmount).toFixed(2))}`,
        refunds: `${(row.original.allAidrefundAmount == 0 ? 0 : (row.original.allAidrefundAmount).toFixed(2))}`,
        commission: `${(row.original.allAidcomputedCommission == 0 ? 0 : (row.original.allAidcomputedCommission).toFixed(2))}`,
        total_payment: `${(row.original.allAidtotalAmount == 0 ? 0 : (row.original.allAidtotalAmount).toFixed(2))}`,
        total_refund: `${(row.original.allAidtotalrefundAmount == 0 ? 0 : (row.original.allAidtotalrefundAmount).toFixed(2))}`,
        net_revenu: `${(row.original.allAidfinalAmount == 0 ? 0 : (row.original.allAidfinalAmount).toFixed(2))}`,
        revenu_after_commission: `${(row.original.allAidfinalAmount ? (row.original.allAidfinalAmount - row.original.allAidcomputedCommission).toFixed(2)  : 0)}`,
        action: `${(row.original.costDetails ? row.original.costDetails.ACTION ? row.original.costDetails.ACTION.total : 0 : '')}`,
        credit_monitoring: `${(row.original.costDetails ? row.original.costDetails.CREDIT_MONITORING ? row.original.costDetails.CREDIT_MONITORING.total : 0 : '')}`,
        credit_report: `${(row.original.costDetails ? row.original.costDetails.CREDIT_REPORT ? row.original.costDetails.CREDIT_REPORT.total : 0 : '')}`,
        credit_score: `${(row.original.costDetails ? row.original.costDetails.CREDIT_SCORE ? row.original.costDetails.CREDIT_SCORE.total : 0 : '')}`,
        id_fraud_insurance: `${(row.original.costDetails ? row.original.costDetails.ID_FRAUD_INSURANCE ? row.original.costDetails.ID_FRAUD_INSURANCE.total : 0 : '')}`,
        idma: `${(row.costDetails ? row.original.costDetails.IDMA ? row.original.original.costDetails.IDMA.total : 0 : '')}`,
        member_support_call: `${(row.original.costDetails ? row.original.costDetails.MEMBER_SUPPORT_CALL ? row.original.costDetails.MEMBER_SUPPORT_CALL.total : 0 : '')}`,
        money_manager: `${(row.original.costDetails ? row.original.costDetails.MONEY_MANAGER ? row.original.costDetails.MONEY_MANAGER.total : 0 : '')}`,
        privacy: `${(row.original.costDetails ? row.original.costDetails.PRIVACY ? row.original.costDetails.PRIVACY.total : 0 : '')}`,
        ssn_lookup: `${(row.original.costDetails ? row.original.costDetails.SSN_LOOKUP ? row.original.costDetails.SSN_LOOKUP.total : 0 : '')}`,
        three_bureau_report: `${(row.original.costDetails ? row.original.costDetails.THREE_BUREAU_REPORT ? row.original.costDetails.THREE_BUREAU_REPORT.total : 0 : '')}`,
        total_product_cost:`${totalProductCost || 'N/A'}`,
        license_fee: `${license_fees || '0'}`,
        gross_profit: `${grossProfits || 'N/A' }`,
        profitability: `${grossProfitPercentage}`,
      };
  
      exportedData.push(mainTableRow);
      const subheader = {
        'AID': 'AID',
        'Total Enrolled': 'Total Enrolled',
        'Active Enrolled': 'Active Enrolled', 
        'Payments':'Payments',
       '3B Payment':'3B Payment',
       'Chargebacks':'Chargebacks',
       'Refund':'Refund',
       'Commissions':'Commissions',
       'Total Payments':'Total Payments',
        'Total Refunds':'Total Refunds',
        'Net Revenue':'Net Revenue'
    };
    exportedData.push(subheader);
    
      if ( row.original.commissionDetails && Object.values(row.original.commissionDetails).length>0) {
        row.original.commissionDetails.forEach((commissionDetail) => {
          const subRow = [
            commissionDetail.aid,
            commissionDetail.allMeberCount,
            commissionDetail.activeMeberCount,
            commissionDetail.paymentAmount,
            commissionDetail.threeBAmount,
            commissionDetail.chargeBackAmount,
            commissionDetail.refundAmount,
            commissionDetail.computedCommission,
            commissionDetail.totalAmount,
            commissionDetail.allRefundAmount,
            commissionDetail.finalAmount
          ];
          exportedData.push(subRow);
        });
      } else {
       
        exportedData.push({});
      }
      exportedData.push({});
    });
    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Company Details - ${CompanyDetails.company_name || 'Unknown'}.csv`;

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';
  
    const rows = data.map((row) => {
      // Extract main row values
      const mainRowValues = Object.values(row)
        .filter((value, index) => index < csvOptions.headers.length)
        .map((value) => {
          if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
          }
          return value;
        })
        .join(',');
  
      // Extract subrow values
      const subRowValues = Object.values(row)
        .filter((value, index) => index >= csvOptions.headers.length)
        .map((value) => {
          if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
          }
          return value;
        })
        .join(',');
  
      // Combine main and subrow values
      return mainRowValues + ',' + subRowValues;
    }).join('\n');
  
    return headers + rows;
  };
// csv end here
    const ContentLayout =
        <>
        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} sx={{ justifyContent: "space-between" }}>
				<Typography color="primary" level="h4">Company Details : {CompanyDetails.company_name ? CompanyDetails.company_name : ''}</Typography>
			</Grid>
            <MaterialReactTable
                columns={columns}
                data={data}
                filterFns={{
                    customSearchFilterFn: (row, id, filterValue) =>
                    row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                  }}   
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                enableColumnOrdering={false}
				enableClickToCopy={true}
				enableColumnFilterModes
				enableColumnFilters={config.DatatableColumnFilter}
				getRowId={(row) => row.id} //give each row a more useful id
				onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
				state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
				initialState={{
					showColumnFilters: false,
					density: 'compact',
				}}

                renderDetailPanel={({ row }) => (
					<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						alignItems: 'center',
					}}
					>
					<Stack>
						<TableContainer component={Paper}>
							<Table className='back'>
                                
								<TableRow>
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>First Name: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.first_name ? row.original.first_name.trim() : ''}</Typography>
									</Grid>
								</TableCell>
								</TableRow> 
								<TableRow>
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>Last Name: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.last_name ? row.original.last_name.trim() : ''}</Typography>
									</Grid>
								</TableCell>                    
								</TableRow> 
								<TableRow>
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>Email: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.email ? row.original.email.trim() : ''}</Typography>
									</Grid>
								</TableCell>                             
								</TableRow> 
								<TableRow> 
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>Phone No.: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.phone_no ? row.original.phone_no.trim() : ''}</Typography>
									</Grid>
								</TableCell>
								</TableRow> 
								<TableRow>
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>Marketing Type.: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.marketing_type ? row.original.marketing_type.trim() : ''}</Typography>
									</Grid>
								</TableCell>
								</TableRow>                                                                         
							</Table>
						</TableContainer>
						<TableContainer component={Paper}><Table><TableRow></TableRow></Table></TableContainer>
						<TableContainer component={Paper}>
						<Table>
							<tbody>
								<TableRow>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>AID</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Total Enrolled</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Active Enrolled</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Payments</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>3B Payment</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Chargebacks</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Refund</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}> Commissions</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Total Payments</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Total Refunds</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Net Revenue</TableCell>
								</TableRow>
									{row.original.commissionDetails.map((commissionDetail) => (
									<>
									<TableRow
										key={commissionDetail.aid}
									>
										<TableCell align="center">{commissionDetail.aid}</TableCell>
										<TableCell align="center">{commissionDetail.allMeberCount}</TableCell>
										<TableCell align="center">{commissionDetail.activeMeberCount}</TableCell>
										<TableCell align="center">{commissionDetail.paymentAmount}</TableCell>
										<TableCell align="center">{commissionDetail.threeBAmount}</TableCell>
										<TableCell align="center">{commissionDetail.chargeBackAmount}</TableCell>
										<TableCell align="center">{commissionDetail.refundAmount}</TableCell>
										<TableCell align="center">{commissionDetail.computedCommission}</TableCell>
										<TableCell align="center">{commissionDetail.totalAmount}</TableCell>
										<TableCell align="center">{commissionDetail.allRefundAmount}</TableCell>
										<TableCell align="center">{commissionDetail.finalAmount}</TableCell>
									</TableRow>
									
									</>
								))}
							</tbody>
						</Table>
						</TableContainer>
					</Stack>
					</Box>
				)}
				//my code for expanded row


                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                renderTopToolbarCustomActions={({ table }) => (
					<Box
					sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
					>
					<Button
						color="primary"
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() =>
										handleExportData(table.getPrePaginationRowModel().rows)
									}
						//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
						startIcon={<FileDownloadIcon />}
						variant="contained"
					>
						CSV
					</Button>


					</Box>
				)}
            />
        </>


    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            	{/* loader code start */}
                    <div>
                        <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={freez}
                        >
                        <Freez />
                        </Backdrop>
                    </div>
                {/* loader code end */}
        </div>
    );
}


export default TotalCompanyPerformance;