import React, { useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Grid, Button,Tooltip,FormControl } from '@mui/material';
import { Typography } from '@mui/joy';
// import { data } from './makeData';
import DefaultLayout from '../../../Components/DefaultLayout';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import Moment from 'moment';
import CheckIcon from '@mui/icons-material/CheckBox';
import {useNavigate } from 'react-router-dom';
import AuthUser from '../../../Components/Auth/AuthUser';
import Loader from '../../../Components/Loader/Loader';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import swal from "sweetalert";

function Tax1099(props) {
    const [rowSelection, setRowSelection] = useState({});
    const navigate = useNavigate();
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const[data,setData] = useState([]);
    const dispatch = useDispatch();
    const { user } = AuthUser();
    const [isLoading, setIsLoading] = useState(true);
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [currentDate, setCurrentDate] = useState("");
    const [selectedtDate, setSelectedtDate] = useState("");
    const [companyId, setCompanyId] = useState("");
    const tablePreferencesArray = {
    }
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
    }, [rowSelection]);

    useEffect(() => {
        if (user.roles_id <= 2) {
            taxInfo();
        }else{
            navigate('/login');
        }
    }, []);

    const openVerificationModal = (id) => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate());
        let todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        setCurrentDate(todaysDate);
        setCompanyId(id);

        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const finalDate = year+"/"+month+"/"+ day;             
        setSelectedtDate(finalDate);

        closeVerificationModal();
        setVerificationModalOpen(true);
    };

    const closeVerificationModal = () => {
        setVerificationModalOpen(false);
    };

    const submitForm = async() => {
        setVerificationModalOpen(false);
        setIsLoading(true);
        const apiUrl = '/verify-tax-details';
        const args = {
            companyId: companyId,
            verificationDate: selectedtDate
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if(res.success === true) {
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                  })
            }
        }
        taxInfo();
    }

    const getChangedDate = (pdate) => {
        const date = new Date(pdate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const finalDate = year+"/"+month+"/"+ day;
        setSelectedtDate(finalDate);
    };

    const customSortFirstNameFun = (rowA, rowB) => {
		const sortA = rowA.original.first_name  ? rowA.original.first_name != null || rowA.original.first_name != ''? rowA.original.first_name : "" : "";
		const sortB =rowB.original.first_name  ? rowB.original.first_name != null || rowB.original.first_name != ''? rowB.original.first_name : "" : "";
    	return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSortLastNameFun = (rowA, rowB) => {
		const sortA = rowA.original.last_name  ? rowA.original.last_name != null || rowA.original.last_name != ''? rowA.original.last_name : "" : "";
		const sortB =rowB.original.last_name  ? rowB.original.last_name != null || rowB.original.last_name != ''? rowB.original.last_name : "" : "";
    	return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSortCompanyFun = (rowA, rowB) => {
		const sortA = rowA.original.company_name  ? rowA.original.company_name != null || rowA.original.company_name != ''? rowA.original.company_name : "" : "";
		const sortB =rowB.original.company_name  ? rowB.original.company_name != null || rowB.original.company_name != ''? rowB.original.company_name : "" : "";
    	return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSortEmailFun = (rowA, rowB) => {
		const sortA = rowA.original.email  ? rowA.original.email != null || rowA.original.email != ''? rowA.original.email : "" : "";
		const sortB =rowB.original.email  ? rowB.original.email != null || rowB.original.email != ''? rowB.original.email : "" : "";
    	return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSortPhoneFun = (rowA, rowB) => {
        const sortA = rowA.original.phone_number ? rowA.original.phone_number != null || rowA.original.phone_number != '' ?  rowA.original.phone_number : "": "";
        const sortB = rowB.original.phone_number ? rowB.original.phone_number != null || rowB.original.phone_number != '' ?  rowB.original.phone_number : "": "";
        return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSortMobFun = (rowA, rowB) => {
        const sortA = rowA.original.mobile_number ? rowA.original.mobile_number != null || rowA.original.mobile_number != '' ?  rowA.original.mobile_number : "": "";
        const sortB = rowB.original.mobile_number ? rowB.original.mobile_number != null || rowB.original.mobile_number != '' ?  rowB.original.mobile_number : "": "";
        return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSortStreetFun = (rowA, rowB) => {
        const sortA = rowA.original.street_address ? rowA.original.street_address != null || rowA.original.street_address != '' ?  rowA.original.street_address : "": "";
        const sortB = rowB.original.street_address ? rowB.original.street_address != null || rowB.original.street_address != '' ?  rowB.original.street_address : "": "";
        return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSortCityFun = (rowA, rowB) => {
        const sortA = rowA.original.city ? rowA.original.city != null || rowA.original.city != '' ?  rowA.original.city : "": "";
        const sortB = rowB.original.city ? rowB.original.city != null || rowB.original.city != '' ?  rowB.original.city : "": "";
        return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSortStateFun = (rowA, rowB) => {
        const sortA = rowA.original.state_code ? rowA.original.state_code != null || rowA.original.state_code != '' ?  rowA.original.state_code : "": "";
        const sortB = rowB.original.state_code ? rowB.original.state_code != null || rowB.original.state_code != '' ?  rowB.original.state_code : "": "";
        return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};
    
    const customSortProofType = (rowA, rowB) => {
        const sortA = rowA.original.proof_type ? rowA.original.proof_type != null || rowA.original.proof_type != '' ?  rowA.original.proof_type : "": "";
        const sortB = rowB.original.proof_type ? rowB.original.proof_type != null || rowB.original.proof_type != '' ?  rowB.original.proof_type : "": "";
        return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

    const customSorSubmittedFun = (rowA, rowB) => {
        const flagPriority = {
          "": 0,
          "0" : 0,
          "1": 1,
        };
        const flagA = rowA.original.submitted_flag ? rowA.original.submitted_flag != null || rowA.original.submitted_flag != '' ? rowA.original.submitted_flag : "": "";
        const flagB = rowB.original.submitted_flag ? rowB.original.submitted_flag != null || rowB.original.submitted_flag != '' ? rowB.original.submitted_flag : "": "";
        const priorityA = flagPriority[flagA];
        const priorityB = flagPriority[flagB];
        return priorityA - priorityB;
    };

    const customSortverificationFun = (rowA, rowB) => {
        const flagPriority = {
          "": 0,
          "0" : 0,
          "1": 1,
        };
        const flagA = rowA.original.verification_flag ? rowA.original.verification_flag != null || rowA.original.verification_flag != '' ? rowA.original.verification_flag : "": "";
        const flagB = rowB.original.verification_flag ? rowB.original.verification_flag != null || rowB.original.verification_flag != '' ? rowB.original.verification_flag : "": "";
        const priorityA = flagPriority[flagA];
        const priorityB = flagPriority[flagB];
        return priorityA - priorityB;
    };

    const taxInfo = async() => {
        const apiUrl = '/companies-tax-details';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if(res.success == true) {
                setData(res.data);
            }
        }
        setIsLoading(false);
	}

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Tax Report',
        headers: [
                'Recipient Identification Number', 'Tax ID Type', 'Recipient First Name',  'Recipient Last Name',  'Recipient Name If Company',  'Recipient Address 1',
                 'Recipient City', 'Recipient State', 'Recipient Zip', 'Recipient Email Address','Recipient Phone', 'Amount Paid', 'Verified'],
    };

    const csvOptionsOfVerification = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Tax Verification Report',
        // headers: ['Payer Federal Identification Number', 'Payer Type Of TIN', 'Payer Name 1', 'Payer Name 2', 'Payer Address 1','Payer Address 2', 'Payer Foreign Country Code','Payer City', 'Payer State', 'Payer Zip', 'Payer Zip Ext', 'Payer Foreign State Name', 'Payer Foreign Postal Code', 'Payer Phone', 
        //           'Payer Phone Ext', 'Payer Misc Search One', 'Payer Misc Search Two', 'Payer Foreign Address Indicator',   
        //           'Recipient First Name', 'Recipient Middle Initial', 'Recipient Last Name', 'Recipient Suffix', 'Recipient Name If Company', 'Recipient Identification Number', 'Recipient Type Of TIN',  'Recipient Name 2', 'Recipient Address 1',
        //           'Recipient Address 2', 'Recipient Foreign Country Code', 'Recipient City', 'Recipient State', 'Recipient Zip', 'Recipient Zip Ext', 'Recipient Foreign State Name', 'Recipient Foreign Postal Code','Recipient Misc Search', 'Recipient Foreign Address Indicator', 'Reprint', 
        //           'Account Number', 'Nonemployee Compensation (Box 1)', 'Direct Sales of $5,000 or More for Resale (Box 2)', 'Federal Income Tax Withheld (Box 4)', '2nd TIN Notice','State Tax Withheld 1 (Box 5)', 'State Name 1 (Box 6)','State Id 1 (Box 6)','State Income 1 (Box 7)',
        //           'State Tax Withheld 2 (Box 5)','State Name 2 (Box 6)','State Id 2 (Box 6)','State Income 2 (Box 7)','Previously Reported Recipient Zip','Previously Reported Recipient State','Previously Reported Recipient Type Of TIN', 'Previously Reported Recipient Identification Number',
        //            'Previously Reported Recipient First Name', 'Previously Reported Recipient Middle Initial', 'Previously Reported Recipient Last Name', 'Previously Reported Recipient Suffix', 'Previously Reported Recipient Name 2','Previously Reported Recipient Name If Company',
        //            'Previously Reported Recipient Address 1','Previously Reported Recipient Address 2', 'Previously Reported Recipient City', 'Previously Reported Recipient Foreign Country Code','Previously Reported Recipient Foreign State Name', 'Previously Reported Recipient Foreign Postal Code'],

         headers: ['Payer Name 1', 'Payer Name 2', 'Payer Address 1','Payer Address 2', 'Payer City', 'Payer State', 'Payer Zip', 'Payer Foreign Address Indicator', 'Payer Foreign State Name', 'Payer Foreign Postal Code', 'Payer Foreign Country Code', 'Payer Phone', 'Payer Phone Ext', 'Payer Federal Identification Number',
                'Payer Type Of TIN', 'Payer Email', 'Payer Misc Search 1', 'Payer Misc Search 2', 'Payer 1095 Contact Phone', 'Payer 1095 Contact Phone Ext', 'Payer 1094 Contact First Name', 'Payer 1094 Contact Middle Initial', 'Payer 1094 Contact Last Name', 'Payer 1094 Contact Suffix', 'AK Payer E-file State ID',
                'AL Payer E-file State ID', 'AR Payer E-file State ID', 'AZ Payer E-file State ID', 'CA Payer E-file State ID', 'CO Payer E-file State ID', 'CT Payer E-file State ID', 'DC Payer E-file State ID', 'DE Payer E-file State ID', 'FL Payer E-file State ID', 'GA Payer E-file State ID', 'HI Payer E-file State ID',
                'IA Payer E-file State ID', 'ID Payer E-file State ID', 'IL Payer E-file State ID', 'IN Payer E-file State ID', 'KS Payer E-file State ID', 'KY Payer E-file State ID', 'LA Payer E-file State ID', 'MA Payer E-file State ID', 'MD Payer E-file State ID', 'ME Payer E-file State ID', 'MI Payer E-file State ID',
                'MN Payer E-file State ID', 'MO Payer E-file State ID', 'MS Payer E-file State ID', 'MT Payer E-file State ID', 'NC Payer E-file State ID', 'ND Payer E-file State ID', 'NE Payer E-file State ID', 'NH Payer E-file State ID', 'NJ Payer E-file State ID', 'NM Payer E-file State ID', 'NY Payer E-file State ID',
                'NV Payer E-file State ID', 'OH Payer E-file State ID', 'OK Payer E-file State ID', 'OR Payer E-file State ID', 'PA Payer E-file State ID', 'RI Payer E-file State ID', 'SC Payer E-file State ID', 'SD Payer E-file State ID', 'TN Payer E-file State ID', 'TX Payer E-file State ID', 'UT Payer E-file State ID',
                'VA Payer E-file State ID', 'VT Payer E-file State ID', 'WA Payer E-file State ID', 'WI Payer E-file State ID', 'WV Payer E-file State ID', 'WY Payer E-file State ID', 'Payer 940/941/944 Return Signer Name', 'Payer 940/941/944 Return Signer Title', 'Payer 940/941/944 Return Signer PIN', 'Payer 940/941/944 Return Signer Phone Number',
                'Payer 940/941/944 Return Signer Email Address','940/941/944 Name Control','940/941/944 Trade Name',
                'Recipient Identification Number', 'Recipient Type Of TIN', 'Recipient First Name', 'Recipient Middle Initial', 'Recipient Last Name', 'Recipient Suffix', 'Recipient Name If Company', 'Recipient Name 2', 'Recipient Address 1',
                'Recipient Address 2', 'Recipient City', 'Recipient State', 'Recipient Zip', 'Recipient Foreign Address Indicator', 'Recipient Foreign State Name', 'Recipient Foreign Postal Code', 'Recipient Foreign Country Code', 'Recipient Email Address',
                'Recipient Date of Birth', 'Recipient Misc Search'],
    };

    const csvOptionsOf1099NecImport = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: '1099 NEC Import',
        headers: ['Payer Name 1', 'Payer Name 2', 'Payer Address 1','Payer Address 2', 'Payer City', 'Payer State', 'Payer Zip', 'Payer Foreign Address Indicator', 'Payer Foreign State Name', 'Payer Foreign Postal Code', 'Payer Foreign Country Code', 'Payer Phone', 'Payer Phone Ext', 'Payer Federal Identification Number',
                'Payer Type Of TIN', 'Payer Misc Search One', 'Payer Misc Search Two', 
                'Recipient Identification Number', 'Recipient Type Of TIN', 'Recipient First Name', 'Recipient Middle Initial', 'Recipient Last Name', 'Recipient Suffix', 'Recipient Name If Company', 'Recipient Name 2', 'Recipient Address 1',
                'Recipient Address 2', 'Recipient City', 'Recipient State', 'Recipient Zip', 'Recipient Foreign Address Indicator', 'Recipient Foreign State Name', 'Recipient Foreign Postal Code', 'Recipient Foreign Country Code', 'Recipient Email (Online Retrieval)',
                'Recipient Misc Search', 'Account Number', '2nd TIN NOTICE', 'Nonemployee Compensation (Box 1)','Direct Sales of $5000 or More for Resale (Box 2)', 'Federal Income Tax Withheld (Box 4)',  'State Name 1 (Box 6)','State Id 1 (Box 6)','State Income 1 (Box 7)',
                'State Tax Withheld 1 (Box 5)','State Name 2 (Box 6)','State Id 2 (Box 6)','State Income 2 (Box 7)', 'State Tax Withheld 2 (Box 5)', 'Transaction Date', 'Reprint'],
    };

    const convertToCSV = (data) => {
        const headers = csvOptions.headers.join(',') + '\n';

        const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				}

				return value;
			}).join(',');
		}).join('\n');
        return headers + rows;
    };

    const convertToVerificationCSV = (data) => {
        const headers = csvOptionsOfVerification.headers.join(',') + '\n';

        const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				}

				return value;
			}).join(',');
		}).join('\n');
        return headers + rows;
    };

    const convertTo1099NecCSV = (data) => {
        const headers = csvOptionsOf1099NecImport.headers.join(',') + '\n';

        const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				}

				return value;
			}).join(',');
		}).join('\n');
        return headers + rows;
    };

    const handleExportData = (data) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 2) {
          navigate('/login');
          return false;
        }
        const exportedData = data.map((row) => {
          return {
            tax_id: `${row.original.tax_id ? row.original.tax_id : ''}`,
            type: `${row.original.proof_type ? row.original.proof_type == "ssn" ? "S" : row.original.proof_type == "tax" ? "E" : '': ''}`,
            recipient_first_name: `${row.original.first_name ? row.original.first_name : ''}`,
            recipient_last_name: `${row.original.last_name ? row.original.last_name : ''}`,
            recipient_company_name: `${row.original.company_name ? row.original.company_name.trim() : ''}`,
            recipient_address: `${row.original.street_address ? row.original.street_address : ''}`,
            recipient_city: `${row.original.city ? row.original.city : ''}`,
            recipient_state: `${row.original.state_code ? row.original.state_code : ''}`,
            recipient_zip: `${row.original.zip ? row.original.zip : ''}`,
            recipient_email_address:  `${row.original.email ? row.original.email.trim() : ''}`,
            recipient_phone:  `${row.original.phone_number ? row.original.phone_number : ''}`,
            recipient_amount_paid:  `${row.original.commission_payment_amount ? row.original.commission_payment_amount : ''}`,
            verification_status:  `${row.original.verification_flag ? 'Yes' : 'No'}`,
          };
        });
    
        // Generate CSV content
        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';
    
        // Append the link to the document body
        document.body.appendChild(link);
    
        // Programmatically click the link to trigger the file download
        link.click();
    
        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const handleExportVerificationCsv = (data) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 2) {
          navigate('/login');
          return false;
        }
        const exportedData = data.map((row) => {
          return {
            // payer_federal_identification_number:'900853476',
            // payer_tin_type : 'E',
            // payer_name_1 : 'MYFREESCORENOW INC',
            // payer_name2 : '',
            // Payer_address_1 : '506 2ND AVE',
            // Payer_address_2 : 'PH',
            // payer_foreign_country_code: '',
            // payer_city : 'SEATTLE',
            // payer_state : 'WA',
            // payer_zip : '98104',
            // payer_zip_ext : '',
            // payer_foreign_state_name: '',
            // payer_foreign_postal_code: '',
            // payer_phone: '8885482008',
            // payer_phone_ext: '',
            // payer_misc_search_1 : '',
            // payer_misc_search_2 : '',
            // payer_foreign_Address_indicator:'',
            // recipient_first_name: `${row.first_name ? row.first_name : ''}`,
            // recipient_middle_name: '',
            // recipient_last_name: `${row.last_name ? row.last_name : ''}`,
            // recipient_suffix : '',
            // recipient_company_name: `${row.company_name ? row.company_name.trim() : ''}`,
            // tax_id: `${row.tax_id ? row.tax_id : ''}`,
            // type_of_tin: `${row.proof_type ? row.proof_type == "ssn" ? "S" : row.proof_type == "tax" ? "E" : '': ''}`,
            // recipient_name2: '',
            // recipient_address: `${row.street_address ? row.street_address : ''}`,
            // recipient_address2: '',
            // recipient_foreign_country_code: '',
            // recipient_city: `${row.city ? row.city : ''}`,
            // recipient_state: `${row.state_code ? row.state_code : ''}`,
            // recipient_zip: `${row.zip ? row.zip : ''}`,
            // recipient_zip_ext: '',
            // recipient_foreign_state_name : '',
            // recipient_foreign_postal_code: '',
            // recipient_misc_search: '',
            // recipient_foreign_address_indicator : '',
            // Reprint : '',
            // account_Number: '',
            // payment_amount: '',
            // direct_sales_of_5000_or_more_for_resale_box_2:'',
            // federal_income_tax_withheld_box_4 :'',
            // second_tin_notice : '',
            // state_tax_withheld_1_box_5 : '',
            // state_name_1_box_6 : '',
            // state_id_1_box_6 : '',
            // state_income_1_box_7 : '',
            // state_tax_withheld_2_box_5 : '',
            // state_name_2_box_6 : '',
            // state_id_2_box_6 : '',
            // state_income_2_box_7 : '',
            // previously_reported_recipient_zip : '',
            // previously_reported_recipient_state : '',
            // previously_reported_recipient_type_of_tin : '',
            // previously_reported_recipient_identification_number : '',
            // previously_reported_recipient_first_name : '',
            // previously_reported_recipient_middle_name_initial : '',
            // previously_reported_recipient_last_name : '',
            // previously_reported_recipient_suffix : '',
            // previously_reported_recipient_name_2 : '',
            // previously_reported_recipient_company_name : '',
            // previously_reported_recipient_address_1 : '',
            // previously_reported_recipient_address_2 : '',
            // previously_reported_recipient_city : '',
            // previously_reported_recipient_foreign_country_code : '',
            // previously_reported_recipient_foreign_state_name : '',
            // previously_reported_recipient_foreign_postal_code : '',

            payer_name_1 : 'MYFREESCORENOW INC',
            payer_name2 : '',
            Payer_address_1 : '506 2ND AVE',
            Payer_address_2 : 'PH',
            payer_city : 'SEATTLE',
            payer_state : 'WA',
            payer_zip : '98104',
            payer_foreign_Address_indicator:'',
            payer_foreign_state_name: '',
            payer_foreign_postal_code: '',
            payer_foreign_country_code: '',
            payer_phone: '8885482008',
            payer_phone_ext: '',
            payer_federal_identification_number:'900853476',
            payer_tin_type : 'E',
            payer_email : '',
            payer_misc_search_1 : '',
            payer_misc_search_2 : '',
            payer_1095_contact_phone :'',
            payer_1095_contact_phone_ext :'',
            payer_1094_contact_first_name :'',
            payer_1094_contact_middle_initial: '',
            payer_1094_contact_last_name: '',
            payer_1094_contact_suffix: '',
            ak_payer_efile_state_id: '',
            al_payer_efile_state_id: '',
            ar_payer_efile_state_id: '',
            az_payer_efile_state_id: '',
            ca_payer_efile_state_id: '',
            co_payer_efile_state_id: '',
            ct_payer_efile_state_id: '',
            dc_payer_efile_state_id: '',
            de_payer_efile_state_id: '',
            fl_payer_efile_state_id: '',
            ga_payer_efile_state_id: '',
            hi_payer_efile_state_id: '',
            ia_payer_efile_state_id: '',
            id_payer_efile_state_id: '',
            il_payer_efile_state_id: '',
            in_payer_efile_state_id: '',
            ks_payer_efile_state_id: '',
            ky_payer_efile_state_id: '',
            la_payer_efile_state_id: '',
            ma_payer_efile_state_id: '',
            md_payer_efile_state_id: '',
            me_payer_efile_state_id: '',
            mi_payer_efile_state_id: '',
            mn_payer_efile_state_id: '',
            mo_payer_efile_state_id: '',
            ms_payer_efile_state_id: '',
            mt_payer_efile_state_id: '',
            nc_payer_efile_state_id: '',
            nd_payer_efile_state_id: '',
            ne_payer_efile_state_id: '',
            nh_payer_efile_state_id: '',
            nj_payer_efile_state_id: '',
            nm_payer_efile_state_id: '',
            ny_payer_efile_state_id: '',
            nv_payer_efile_state_id: '',
            oh_payer_efile_state_id: '',
            ok_payer_efile_state_id: '',
            or_payer_efile_state_id: '',
            pa_payer_efile_state_id: '',
            ri_payer_efile_state_id: '',
            sc_payer_efile_state_id: '',
            sd_payer_efile_state_id: '',
            tn_payer_efile_state_id: '',
            tx_payer_efile_state_id: '',
            ut_payer_efile_state_id: '',
            va_payer_efile_state_id: '',
            vt_payer_efile_state_id: '',
            wa_payer_efile_state_id: '',
            wi_payer_efile_state_id: '',
            wv_payer_efile_state_id: '',
            wy_payer_efile_state_id: '',
            payer_940_941_944_return_signer_name: '',
            payer_940_941_944_return_signer_title: '',
            payer_940_941_944_return_signer_pin: '',
            payer_940_941_944_return_signer_phone: '',
            payer_940_941_944_return_signer_email: '',
            payer_940_941_944_return_name_control: '',
            payer_940_941_944_trade_name:'',
            tax_id: `${row.original.tax_id ? row.original.tax_id : ''}`,
            type_of_tin: `${row.original.proof_type ? row.original.proof_type == "ssn" ? "S" : row.original.proof_type == "tax" ? "E" : '': ''}`,
            recipient_first_name: `${row.original.first_name ? row.original.first_name : ''}`,
            recipient_middle_name: '',
            recipient_last_name: `${row.original.last_name ? row.original.last_name : ''}`,
            recipient_suffix : '',
            recipient_company_name: `${row.original.company_name ? row.original.company_name.trim() : ''}`,
            recipient_name2: '',
            recipient_address: `${row.original.street_address ? row.original.street_address : ''}`,
            recipient_address2: '',
            recipient_city: `${row.original.city ? row.original.city : ''}`,
            recipient_state: `${row.original.state_code ? row.original.state_code : ''}`,
            recipient_zip: `${row.original.zip ? row.original.zip : ''}`,
            recipient_foreign_address_indicator : '',
            recipient_foreign_state_name : '',
            recipient_foreign_postal_code: '',
            recipient_foreign_country_code: '',
            recipient_email_address:  `${row.original.email ? row.original.email.trim() : ''}`,
            recipient_dob: '',
            recipient_misc_search: '',

          };
        });
    
        // Generate CSV content
        const csvContent = convertToVerificationCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptionsOfVerification.fileName + '.csv';
    
        // Append the link to the document body
        document.body.appendChild(link);
    
        // Programmatically click the link to trigger the file download
        link.click();
    
        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const handleExport1099NecCsv = (data) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 2) {
          navigate('/login');
          return false;
        }
        const exportedData = data.map((row) => {
          return {
            payer_name_1 : 'MYFREESCORENOW INC',
            payer_name2 : '',
            Payer_address_1 : '506 2ND AVE',
            Payer_address_2 : 'PH',
            payer_city : 'SEATTLE',
            payer_state : 'WA',
            payer_zip : '98104',
            payer_foreign_Address_indicator:'',
            payer_foreign_state_name: '',
            payer_foreign_postal_code: '',
            payer_foreign_country_code: '',
            payer_phone: '8885482008',
            payer_phone_ext: '',
            payer_federal_identification_number:'900853476',
            payer_tin_type : 'E',
            payer_misc_search_1 : '',
            payer_misc_search_2 : '',
            tax_id: `${row.original.tax_id ? row.original.tax_id : ''}`,
            type_of_tin: `${row.original.proof_type ? row.original.proof_type == "ssn" ? "S" : row.original.proof_type == "tax" ? "E" : '': ''}`,
            recipient_first_name: `${row.original.first_name ? row.original.first_name : ''}`,
            recipient_middle_name: '',
            recipient_last_name: `${row.original.last_name ? row.original.last_name : ''}`,
            recipient_suffix : '',
            recipient_company_name: `${row.original.company_name ? row.original.company_name.trim() : ''}`,
            recipient_name2: '',
            recipient_address: `${row.original.street_address ? row.original.street_address : ''}`,
            recipient_address2: '',
            recipient_city: `${row.original.city ? row.original.city : ''}`,
            recipient_state: `${row.original.state_code ? row.original.state_code : ''}`,
            recipient_zip: `${row.original.zip ? row.original.zip : ''}`,
            recipient_foreign_address_indicator : '',
            recipient_foreign_state_name : '',
            recipient_foreign_postal_code: '',
            recipient_foreign_country_code: '',
            recipient_email_address:  `${row.original.email ? row.original.email.trim() : ''}`,
            recipient_misc_search: '',
            account_Number: '',
            sec_tin_notice :'',
            recipient_amount_paid:  `${row.original.commission_payment_amount ? row.original.commission_payment_amount : ''}`,
            direct_sales_of_5000_or_more_for_resale_box_2:'',
            federal_income_tax_withheld_box_4 :'',
            state_name_1_box_6 : '',
            state_id_1_box_6 : '',
            state_income_1_box_7 : '',
            state_tax_withheld_1_box_5 : '',
            state_name_2_box_6 : '',
            state_id_2_box_6 : '',
            state_income_2_box_7 : '',
            state_tax_withheld_2_box_5 : '',
            transaction_date : '',
            reprint : '',
            verification_flag: `${row.original.verification_flag?'Yes':'no'}`,
          };
        });
        // console.log("exportedData"+exportedData)
        // Generate CSV content
        const filteredData = exportedData.filter(row => row.verification_flag === 'Yes');
        // console.log("filteredData"+filteredData)
        const filteredData1 = filteredData.map(row => {
            const { verification_flag, ...rest } = row;
            return rest;
        })
        
        const headersWithoutLastColumn = csvOptionsOf1099NecImport.headers.slice(0, -1);
        const csvOptionsWithoutLastColumn = { ...csvOptionsOf1099NecImport, headers: headersWithoutLastColumn };
        const csvContent = convertTo1099NecCSV(filteredData1);
        // const csvContent = convertTo1099NecCSV(filteredData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptionsOf1099NecImport.fileName + '.csv';
    
        // Append the link to the document body
        document.body.appendChild(link);
    
        // Programmatically click the link to trigger the file download
        link.click();
    
        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const columns = useMemo(
        () => [
            {
                id: 'employee',
                columns: [
                    {
                        id: 'srNo',
                        header: 'Sr. No',
                        size: 50,
                        Cell: ({ row }) => (
                          <Typography>{row.index + 1}</Typography>
                        ),
                      },
                    {
                        accessorFn: (data) => `${data.first_name}`,
                        id: 'first_name',
                        header: 'First Name',
                        size: 50,
                        sortingFn: customSortFirstNameFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.first_name ? row.original.first_name.trim() : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.last_name}`,
                        id: 'last_name',
                        header: 'Last Name',
                        size: 50,
                        sortingFn: customSortLastNameFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.last_name ? row.original.last_name.trim() : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.company_name}`,
                        id: 'company_name',
                        header: 'Company Name',
                        size: 50,
                        sortingFn: customSortCompanyFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.company_name ? row.original.company_name.trim() : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.email}`,
                        id: 'email',
                        header: 'Email',
                        size: 50,
                        sortingFn: customSortEmailFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.email ? row.original.email.trim() : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.phone_number}`,
                        filterVariant: 'range',
                        header: 'Phone No.',
                        size: 50,
                        sortingFn: customSortPhoneFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{(row.original.phone_number) && (row.original.phone_number !== null) ? (formatPhoneNumber(row.original.phone_number)) : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.mobile_number}`,
                        filterVariant: 'range',
                        header: 'Mobile No.',
                        size: 50,
                        sortingFn: customSortMobFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{(row.original.mobile_number) && (row.original.mobile_number !== null) ? (formatPhoneNumber(row.original.mobile_number)) : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.fax}`,
                        id: 'fax',
                        header: 'Fax',
                        size: 50,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.fax ? row.original.fax : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.street_address}`,
                        id: 'street_address',
                        header: 'Street Address',
                        size: 50,
                        sortingFn: customSortStreetFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.street_address ? row.original.street_address.trim() : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.city}`,
                        id: 'city',
                        header: 'City',
                        size: 50,
                        sortingFn: customSortCityFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.city ? (row.original.city !== null || row.original.city !== '') ? row.original.city :'N/A' :'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.state_code}`,
                        id: 'state',
                        header: 'state',
                        size: 50,
                        sortingFn: customSortStateFun,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.state_code ? (row.original.state_code !== null || row.original.state_code !== '') ? row.original.state_code :'N/A' :'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.zip}`,
                        id: 'zip',
                        header: 'Zip',
                        size: 50,
                        sortingFn: (rowA, rowB) => {
                            const sortA = rowA.original.zip ? (rowA.original.zip != '' ||  rowA.original.zip != 'null') ? rowA.original.zip : '' : '';
                            const sortB = rowB.original.zip ? (rowB.original.zip != '' ||  rowB.original.zip != 'null') ? rowB.original.zip : '' : '';
                            return sortA-sortB;
                        },
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.zip ?  (row.original.zip !== null || row.original.zip !== '') ? row.original.zip : 'N/A' : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.commission_payment_amount}`,
                        id: 'commission_payment_amount',
                        header: 'Opening Balance',
                        size: 50,
                        sortingFn: (rowA, rowB) => {
                            const sortA = rowA.original.commission_payment_amount;
                            const sortB = rowB.original.commission_payment_amount;
                            return sortA-sortB;
                        },
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.commission_payment_amount ? row.original.commission_payment_amount : ''}</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.proof_type}`,
                        id: 'proof_type',
                        header: 'Tax ID Type',
                        size: 50,
                        sortingFn: customSortProofType,
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.proof_type ? (row.original.proof_type != '' ||  row.original.proof_type != null) ?  row.original.proof_type == "ssn" ? "S" : row.original.proof_type == "tax" ? "E" : "N/A" : 'N/A': 'N/A' }</Typography>
                        ),
                    },
                    {
                        accessorFn: (data) => `${data.tax_id}`,
                        id: 'tax_id',
                        header: 'EIN / SSN',
                        size: 50,
                        sortingFn: (rowA, rowB) => {
                            const sortA = rowA.original.tax_id ? (rowA.original.tax_id != '' ||  rowA.original.tax_id != 'null') ? rowA.original.tax_id : '' : '';
                            const sortB = rowB.original.tax_id ? (rowB.original.tax_id != '' ||  rowB.original.tax_id != 'null') ? rowB.original.tax_id : '' : '';
                            return sortA-sortB;
                        },
                        Cell: ({ renderedCellValue, row }) => (
                            <Typography>{row.original.tax_id ? (row.original.tax_id != '' ||  row.original.tax_id != null) ? row.original.tax_id : 'N/A' : 'N/A'}</Typography>
                        ),
                    },
                    {
                        accessorKey: 'submitted_flag',
                        accessorFn: (row) => `${row.submitted_flag ? row.submitted_flag : ''}`,
                        header: 'Submmited Status',
                        size: 50,
                        sortingFn: customSorSubmittedFun,
                        Cell: ({ renderedCellValue, row }) => (
                          <div>
                            {row.original.submitted_flag ?  
                            <Tooltip title="" arrow><CheckIcon color="success" /></Tooltip>
                            : 
                            <Tooltip title="" arrow><CheckIcon color="disabled" /></Tooltip>
                            }
                          </div>
                        ),
                    },
                    {
                        accessorKey: 'submitted_date',
                        accessorFn: (row) => `${row.submitted_date ? row.submitted_date : ''}`,
                        header: 'Submitted Date',
                        size: 50,
                        Cell: ({ renderedCellValue, row }) => (
                          <div>
                            {row.original.submitted_date ? Moment(row.original.submitted_date).format('MM-DD-YYYY') : 'N/A'}
                            {/* 12-31-2023 */}
                          </div>
                        ),
                    },
                    {
                        accessorKey: 'verification_flag',
                        accessorFn: (row) => `${row.verification_flag ? row.verification_flag : ''}`,
                        header: 'Verification Status',
                        size: 50,
                        sortingFn: customSortverificationFun,
                        Cell: ({ renderedCellValue, row }) => (
                          <div>
                            {row.original.verification_flag ?  
                            <Tooltip title="" arrow><CheckIcon color="success" /></Tooltip>
                            : 
                            <Tooltip title="" arrow><CheckIcon color="disabled" /></Tooltip>
                            }
                          </div>
                        ),
                    },
                    {
                        accessorKey: 'verification_date',
                        accessorFn: (row) => `${row.verification_date ? row.verification_date : ''}`,
                        header: 'Verification Date',
                        size: 50,
                        Cell: ({ renderedCellValue, row }) => (
                          <div>
                            {row.original.verification_date ? Moment(row.original.verification_date).format('MM-DD-YYYY') : 'N/A'}
                          </div>
                        ),
                    },
                    {
                        accessorFn: (row) => `${row.verification_flag ? row.verification_flag : ''}`,
                        header: 'Action',
                        size: 50,
                        Cell: ({ renderedCellValue, row }) => (
                          <div>
                           {
                            <Switch checked={row.original.verification_flag ? row.original.verification_flag : 0}
                                onChange={()=> openVerificationModal(`${row.original.company_master_id}`)}
                                disabled={row.original.verification_flag ? true :row.original.submitted_flag ? false : true}
                                inputProps={{ 'aria-label': 'Switch A' }}
                            />
                           }
                          </div>
                        ),
                    },
                    
                ],
            },
        ],
        [],
    );
    
    const ContentLayout =
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
                    <Typography level="h4" color="primary">1099 Information</Typography>
                </Grid>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    filterFns={{
                        customSearchFilterFn: (row, id, filterValue) => {
                          const searchValues = filterValue.toLowerCase().split(' ');
                          const firstName = row.original.first_name ? row.original.first_name.toLowerCase() : '';
                          const lastName = row.original.last_name ? row.original.last_name.toLowerCase() : '';
                          const nameMatches = searchValues.every(value =>
                            firstName.includes(value) || lastName.includes(value)
                          );
                          return nameMatches || row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, ''));
                        },
                      }}
                    globalFilterFn="customSearchFilterFn"//custom search function
                    enableColumnFilters={false}
                    initialState={{ density: 'compact' }}
                    positionToolbarAlertBanner="bottom"
                    enableDensityToggle={false}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                            <Button
                                variant='contained'
                                sx={{ bgColor: "#2A68B3" }}
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                onClick={() =>
                                    handleExportData(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}>
                                Download CSV
                            </Button>
                            <Button
                                variant='contained'
                                sx={{ bgColor: "#2A68B3" }}
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                onClick={() =>
                                    handleExportVerificationCsv(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}>
                                Tax Verification Report CSV
                            </Button>
                            <Button
                                variant='contained'
                                sx={{ bgColor: "#2A68B3" }}
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                onClick={()=>
                                    handleExport1099NecCsv(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}>
                                1099 NEC Import
                            </Button>
                        </Box>
                    )}
                />
                <Dialog
                    open={verificationModalOpen}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            closeVerificationModal();
                        }
                    }}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                >
                    <DialogTitle variant="h6" color='primary'  style={{ textAlign: 'center' }}>Change Verification Status</DialogTitle>
                    <DialogContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            Are you sure, you want to {verificationStatus ? "disable" : "enable"} verification status? If Yes, Please select verified date, otherwise click on close button.

                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ width: "100%" }} spacing={2} mt={1}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                        <FormControl sx={{ width: 200 }} size='small' className='commission-year-selector'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        label="Verified Date"
                                                        disableFuture
                                                        name="verificationDate"
                                                        fullWidth
                                                        value={dayjs(selectedtDate)}
                                                        onChange={(e) => {getChangedDate(e)}}
                                                        maxDate={dayjs(currentDate)}
                                                    />
                                                    
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{  width: "100%" }} spacing={2} mt={1}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button variant="contained" onClick={submitForm} sx={{ minWidth: 200, backgroundColor: "#76C044", color: "white" }} color="success">
                                            Verify
                                            </Button>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button variant="contained" onClick={closeVerificationModal} sx={{ minWidth: 200, backgroundColor: "#2A68B3", color: "white" }} color="primary">
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        
    return (
        <div>
              {isLoading?<Loader/>:<></>}
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}
export default Tax1099;