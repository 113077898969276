import React, { useMemo, useRef, useState,useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup';
import { Formik } from 'formik';
import MaterialReactTable from 'material-react-table';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Box, Tooltip, Grid, Button, TextField, Divider, FormGroup, InputLabel, FormControl, OutlinedInput, InputAdornment,Stack,
    FormControlLabel, FormLabel, RadioGroup, Radio, Checkbox, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,ClickAwayListener
} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import AuthUser from '../Auth/AuthUser';
import useMediaQuery from '@mui/material/useMediaQuery';
import swal from "sweetalert";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import Moment from 'moment';
import Loader from '../Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import config from '../../AppConfig';
import InputMask from 'react-input-mask';
import tooltipImage from '../../assets/images/tooltip.png'; 
import iIcon from '../../assets/images/i_icon.png'; 
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
const ViewBankForm = ({bankDetails,updatedAttributes,filterhistory,fetchCompanyBankDetails,htmlData,btntext,currentDate,companyName,operation,bankName, routingNumberErr, routingNumberOriginal}) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { http, user } = AuthUser();
    const [isLoading, setIsLoading] = useState(false);
    const [bank_details, setBankDetails] = useState(bankDetails);
    const navigate = useNavigate();
    const inputRef = useRef();
    const inputRef1 = useRef();
    const [statusMessage, setStatusMesage] = useState("");
    const [loader, setLoader] = useState();
    const [value, setValue] = useState(); 
    const [rowSelection, setRowSelection] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paymentsNavigation = queryParams.get('payments');
    const page = queryParams.get('page');
    const pageSize = queryParams.get('pageSize');
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const isMobile = useMediaQuery('(max-width:600px)');
    const [bankNameReadonly, setBankNameReadonly] = useState(true);
    const [routingNumberError, setRoutingNumberError] = useState(routingNumberErr);
    const [defaultBankName, setDefaultBankName] = useState(bankName);
    const [routingNoErr, setRoutingNoErr] = useState("");
    const [defaultRoutingNumber, setDefaultRoutingNumber] = useState(routingNumberOriginal);
    const [viewBankHistory, setViewBankHistory] = useState([]);
    const [openbank, setOpenbank] = useState(false);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [mediumWidth, setMediumWidth] = React.useState('md');
    
    //report issue functions and variable start
    var [reportissue,setReportIssue] = useState();
    var [subject,setSubject] = useState("MFSN Bank details Incorrect (ACH Issue)");

    const handlebankClose = () => setOpenbank(false);
    const handlebankOpen = () => setOpenbank(true);

    useEffect(() => {
        setDefaultBankName(bankName);
    }, [bankName])

    useEffect(() => {
        setDefaultRoutingNumber(routingNumberOriginal);
    }, [routingNumberOriginal])

    const handleReportIssueChange = (value) => {
        reportissue = value;
    };
    const handleSubjectChange = (value) => {
        subject = value;
    };
    //report issue functions and variable end
    // tooltip popup start here
        const [openTooltip, setOpenTooltip] = useState(false);

        const handleTooltipOpen = () => {
            setOpenTooltip(true);
        };

        const handleTooltipClose = () => {
            setOpenTooltip(false);
        };
        const [openTooltip2, setOpenTooltip2] = useState(false);

        const handleTooltipOpen2 = () => {
            setOpenTooltip2(true);
        };

        const handleTooltipClose2 = () => {
            setOpenTooltip2(false);
        };

    // tooltip end here
    //modal variable and function start
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    const [firstlable, setFirstLable] = useState(false);
    const [statusAch, setStatusAch] = useState(bank_details.ach_info_added_to_bank == 1 ? "yes" : "no");
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [isAccount, setIsAccount] = useState(bank_details ? bank_details.account_category : null);
    const [firstNameOnAccount, setFirstNameOnAccount] = useState(bank_details ? bank_details.name_on_account ? bank_details.name_on_account.indexOf(':')  >= 0 && bank_details.account_category =="personal" ? bank_details.name_on_account.slice(0, bank_details.name_on_account.indexOf(':')) : "" : bank_details.name_on_account : '');
    const [lastNameOnAccount, setLastNameOnAccount] = useState(bank_details ? bank_details.name_on_account ? bank_details.name_on_account.indexOf(':')  >= 0 && bank_details.account_category =="personal" ? bank_details.name_on_account.slice(bank_details.name_on_account.indexOf(':') + 1) : "" : bank_details.name_on_account : '');

    const handleClickReport = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser){
            if(loginUser.roles_id  > 2) {
                navigate('/login');
                return false;
            }
        }else{
            navigate('/login');
            return false;
        }
        setOpen(true);
    };

    const handleCloseReport = () => {
        setOpen(false);
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser){
            if(loginUser.roles_id  > 2) {
                navigate('/login');
                return false;
            }
        }else{
            navigate('/login');
            return false;
        }
    };

    const handleCloseButton = (compnyId) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser){ 
            if(loginUser.roles_id  > 2) {
                navigate('/login');
                return false;
            }
        }else{
            navigate('/login');
            return false;
        }
        paymentsNavigation ? navigate(`/commission-payments?page=${page}`+ `&pageSize=${pageSize}`) : navigate('/companypanel');  
    }

    const changeBankNameProperty = () => {
        setBankNameReadonly(false);
    }
    
      //report bank details issue start
      const reportBankDetailsIssue = async(id) => 
      {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser){
            if(loginUser.roles_id  > 2) {
                navigate('/login');
                return false;
            }
        }else{
            navigate('/login');
            return false;
        }
          if(reportissue === undefined || reportissue == "") {
              swal({
                  title: "Alert",
                  text: "Please Add Report Issue Details",
                  icon: "error",
                  button: "Ok",
              })
              return false;
  
          }else if(subject == ""){
              swal({
                  title: "Alert",
                  text: "Please Fill the Content For Subject-line",
                  icon: "error",
                  button: "Ok",
              })
              return false;
          }
  
          setIsLoading(true);
          const apiUrl = '/reportBankDetailsIssue';
          const args = {
              company_bank_details_master_id: bank_details.id,
              ticket_reason: reportissue,
              subject: subject,
              mailBody: htmlData
          }
          const res = await dispatch(postData({apiUrl,args})).unwrap();
          if(res) {
                  if (res.success === true) {
                      setOpen(false);
                      fetchCompanyBankDetails(id);
                      swal({
                          title: "Success",
                          text: res.message,
                          icon: "success",
                          button: "Ok",
                      })
  
                  }else {
                              swal({
                                  title: "Alert",
                                  text: "Something went wrong please try again",
                                  icon: "error",
                                  button: "Ok",
                              })
                          }
          }else{
                  swal({
                  title: "Alert",
                  text: "Network Error",
                  icon: "error",
                  button: "Ok",
              })
          }
      }
      //report bank details issue end
  
      //resolve report bank details issue start
      const resolveBankDetailsIssue = async() => {
        setIsLoading(true);
          const apiUrl = '/solvedBankDetailsIssue';
          const args = {
              company_bank_details_master_id: bank_details.id,
              close_reason: "Details Updated and Issue Resolved"
          }
          const res = await dispatch(postData({apiUrl,args})).unwrap();
          if(res) {
            setIsLoading(false);
                 if (res.success === true) {
                  // alert("success resolve")
              } else {
                  // alert("not success resolve")
              }
          }else{
              alert("fail resolve")
          }
      }
      //resolve report bank details issue end
  
      //Create function start
      const CreateBankDetails = async(values) => {
        if(routingNumberError == false && user.roles_id >= 3) {
            swal({
                title: "Failed",
                text: "Please check entered routing number once and if needed contact support for help",
                icon: "error",
                button: "Ok",
            })
            return false;
        }
        setIsLoading(true);
          var check = 1;
          const loginUser = JSON.parse(localStorage.getItem('user'));
          if(loginUser.roles_id  > 2) {
              check = 0;
          } 
          if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
              if(loginUser.company_master_id != id) {
                  check = 0;
              }else{
                  check = 1;
              }
          }
          if(check == 0) {
              swal({
                  title: "Failed",
                  text: "This action is unauthorized.",
                  icon: "error",
                  button: "Ok",
              })
              setIsLoading(false);
              navigate('/login');
              return false;
          }

            if(checkUsaTldStatus == true){
                setIsLoading(false);
                return false;
            }
          var achDate;
          if(values.ACHdate1 != undefined || values.ACHdate1 != null){
              const date = new Date(values.ACHdate1);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = date.getFullYear();
              achDate = year+"-"+month+"-"+day;               //formatted ach date
              
              var date1 = new Date(currentDate);
              var date2 = new Date(achDate);
              var compDate = Moment(achDate).format('MM-DD-YY');
              var date2 = new Date(compDate);
              if(date1 < date2) {
                  swal({
                      title: "Alert",
                      text: "ACH date should be less than or equal to current date",
                      icon: "error",
                      button: "Ok",
                  })
                  setIsLoading(false);
                  return false;
              }
          }
        if(values.AccountCategory == "personal" && (values.BankName == (values.firstName + ":" + values.lastName))) {
            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            })
            setIsLoading(false);
            return false;
        }else if(values.AccountCategory == "business" && (values.BankName == values.NameonAccount)) {
            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            })
            setIsLoading(false);
            return false;
        } else if (values.ACHinfoaddedtobank == "yes" && values.ACHdate1 == null) {
              swal({
                  title: "Alert",
                  text: "If ACH is added, then ACH add date is required",
                  icon: "error",
                  button: "Ok",
              })
              inputRef1.current?.focus(); 
              setIsLoading(false);   
          } else {
              const apiUrl = '/add-company-bank-details';
              const loginUser = JSON.parse(localStorage.getItem('user'));
              const args = loginUser.roles_id < 3 ?
                {
                      company_master_id: id,
                      bank_name: values.BankName,
                      name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                      routing_number: values.BankRouting,
                      account_number: values.AccountNumber,
                      account_type: values.AccountType,
                      account_category: values.AccountCategory,
                      email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                      ach_info_added_to_bank: values.ACHinfoaddedtobank === 'yes' ? 1 : 0,
                      last_ach_updated: values.ACHinfoaddedtobank === 'yes' ? achDate : null,
                      routing_number_updated : (bankNameReadonly == false || routingNumberError == false ? 1 : 0)
               }
               :
                {
                      company_master_id: id,
                      bank_name: values.BankName,
                      name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                      routing_number: values.BankRouting,
                      account_number: values.AccountNumber,
                      account_type: values.AccountType,
                      account_category: values.AccountCategory,
                      email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                }
              const res = await dispatch(postData({apiUrl,args})).unwrap();
              if(res) {
                    if(res.success === true){
                      swal({
                      title: "Success",
                      text: res.message,
                      icon: "success",
                      button: "Ok",
                      })
                      operation="Update"; 
                      setIsLoading(false);   
                      fetchCompanyBankDetails();
                    }else{
                      swal({
                        title: "Failed",
                        text: res.message,
                        icon: "error",
                        button: "Ok",
                    })
                    setIsLoading(false);
                    }
              }
          }
      }
      //Create function end
      //Update function start
       const UpdateBankDetails = async(values) => {
        if(routingNumberError == false && user.roles_id >= 3) {
            swal({
                title: "Failed",
                text: "Please check entered routing number once and if needed contact support for help",
                icon: "error",
                button: "Ok",
            })
            return false;
        }
        setIsLoading(true);
          var check = 1;
          const loginUser = JSON.parse(localStorage.getItem('user'));
          if(loginUser.roles_id  > 2) {
              check = 0;
          } 
          if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
              if(loginUser.company_master_id != id || btntext == "Update And Resolve") {
                  check = 0;
              }else{
                  check = 1;
              }
          }
          if(check == 0) {
              navigate('/login');
              swal({
                  title: "Failed",
                  text: "This action is unauthorized.",
                  icon: "error",
                  button: "Ok",
              })
              setIsLoading(false);
              return false;
          }
          
        if(checkUsaTldStatus == true){
            setIsLoading(false);
            return false;
        }

          var achDate;
          if(values.ACHdate1 != undefined || values.ACHdate1 != null){
              const date = new Date(values.ACHdate1);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = date.getFullYear();
              achDate = year+"-"+month+"-"+day;               //formatted ach date
              var date1 = new Date(currentDate);
              var date2 = new Date(achDate);
              var compDate = Moment(achDate).format('MM-DD-YY');
              var date2 = new Date(compDate);
              
              if(date1 < date2) {
                  swal({
                      title: "Alert",
                      text: "ACH date should be less than or equal to current date",
                      icon: "error",
                      button: "Ok",
                  })
                  setIsLoading(false);
                  return false;
              }
          }
        if(values.AccountCategory == "personal" && (values.BankName == (values.firstName + ":" + values.lastName))) {
            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            })
            setIsLoading(false);
            return false;
        }else if(values.AccountCategory == "business" && (values.BankName == values.NameonAccount)) {
            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            })
            setIsLoading(false);
            return false;
        } else if (values.ACHinfoaddedtobank == "yes") {
              if (values.ACHdate1 == null && bank_details.last_ach_updated == null) {
                  swal({
                      title: "Alert",
                      text: "If ACH is added, then ACH add date is required",
                      icon: "error",
                      button: "Ok",
                  })
                  inputRef1.current?.focus();
                  setIsLoading(false);  
              } else {
                  if (values.ACHdate1 == null && values.achCheckValue) {
                      swal({
                          title: "Are you sure?",
                          text: "You want to continue with old ACH date!",
                          icon: "success",
                          buttons: [
                              'No, cancel it!',
                              'Yes, I am sure!'
                          ],
                          dangerMode: true,
                      }).then(async function (isConfirm) {
                          if (isConfirm) {
                              const apiUrl = '/update-company-bank-details';
                              const loginUser = JSON.parse(localStorage.getItem('user'));
                              const args = loginUser.roles_id < 3 ?
                               {
                                  id: id,
                                  bank_name: values.BankName,
                                  name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                                  routing_number: values.BankRouting,
                                  account_number: values.AccountNumber,
                                  account_type: values.AccountType,
                                  account_category: values.AccountCategory,
                                  ach_info_added_to_bank:values.ACHinfoaddedtobank === 'yes' ? 1 : 0,
                                  email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                                  last_ach_updated: (values.ACHinfoaddedtobank == 'yes') ? values.ACHdate1 == null ? bank_details.last_ach_updated :achDate : null,
                                  achflag: values.achCheckValue,
                                  manually_bank_name_updated : !bankNameReadonly,
                                  routing_number_updated : (bankNameReadonly == false || routingNumberError == false ? 1 : 0)
                              }
                              :
                              {
                                id: id,
                                bank_name: values.BankName,
                                name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                                routing_number: values.BankRouting,
                                account_number: values.AccountNumber,
                                account_type: values.AccountType,
                                account_category: values.AccountCategory,
                                email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                              }
                              const res = await dispatch(postData({apiUrl,args})).unwrap();
                              if(res) {
                                      if (res.success === true) {
                                        
                                      resolveBankDetailsIssue();
                                      swal({
                                          title: "Success",
                                          text: res.message,
                                          icon: "success",
                                          button: "Ok",
                                      })
                                      values.isFormDirty=true;
                                      setIsLoading(false);
                                      fetchCompanyBankDetails();     
                                  } else {
                                      swal({
                                          title: "Failed",
                                          text: res.message,
                                          icon: "error",
                                          button: "Ok",
                                      });
                                      setIsLoading(false);
                                      if(res.message == "Unauthenticated") {
                                          navigate('/login');
                                      }
                                  }
                              }else {
                                  setLoader('');
                                  document.getElementById("createbtn").classList.remove('d-none');
                                  setStatusMesage('');
                                  setLoader('');
                                  setIsLoading(false);
                                  swal({
                                      title: "Failed",
                                      text: res.message,
                                      icon: "error",
                                      button: "Ok",
                                  });
                                  if(res.message == "Unauthenticated") {
                                      navigate('/login');
                                  }
                              }
  
                          } else {
                              inputRef1.current?.focus();
                              setIsLoading(false);
                          }
                      })
  
                  } else {
                      const apiUrl = '/update-company-bank-details';
                      const loginUser = JSON.parse(localStorage.getItem('user'));
                      const args = loginUser.roles_id < 3 ?
                        {
                          id: id,
                          bank_name: values.BankName,
                          name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                          routing_number: values.BankRouting,
                          account_number: values.AccountNumber,
                          account_type: values.AccountType,
                          account_category: values.AccountCategory,
                          ach_info_added_to_bank: values.ACHinfoaddedtobank === 'yes' ? 1 : 0,
                          email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                          last_ach_updated: (values.ACHinfoaddedtobank == 'yes') ? values.ACHdate1 == null ? bank_details.last_ach_updated : achDate : null,
                          achflag: values.achCheckValue,
                          manually_bank_name_updated : !bankNameReadonly,
                          routing_number_updated : (bankNameReadonly == false || routingNumberError == false ? 1 : 0)
                        }
                      :
                        {
                          id: id,
                          bank_name: values.BankName,
                          name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                          routing_number: values.BankRouting,
                          account_number: values.AccountNumber,
                          account_type: values.AccountType,
                          account_category: values.AccountCategory,
                          email_to_notify: values.EmailAddresstonotifyofpaymenttransfers
                        }
                      const res =  await dispatch(postData({apiUrl,args})).unwrap();
                      if(res) {
                              if (res.success === true) {
                                
                              resolveBankDetailsIssue();
                              swal({
                                  title: "Success",
                                  text: res.message,
                                  icon: "success",
                                  button: "Ok",
                              })
                              values.isFormDirty=true;
                              setIsLoading(false);
                              fetchCompanyBankDetails();
                               
                          } else {
                            setIsLoading(false);
                              swal({
                                  title: "Failed",
                                  text: res.message,
                                  icon: "error",
                                  button: "Ok",
                              });
                              setIsLoading(false);
                              if(res.message == "Unauthenticated") {
                                  navigate('/login');
                              }
                          }
                      }else {
                          setLoader('');
                          document.getElementById("createbtn").classList.remove('d-none');
                          setStatusMesage('');
                          setLoader('');
                          setIsLoading(false);
                          swal({
                              title: "Failed",
                              text: res.message,
                              icon: "error",
                              button: "Ok",
                          });
                      }
                  }
              }
          } 
          else 
          {
              const apiUrl = '/update-company-bank-details';
              const loginUser = JSON.parse(localStorage.getItem('user'));
              const args = loginUser.roles_id < 3 ?
                {
                  id: id,
                  bank_name: values.BankName,
                  name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                  routing_number: values.BankRouting,
                  account_number: values.AccountNumber,
                  account_type: values.AccountType,
                  account_category: values.AccountCategory, 
                  ach_info_added_to_bank: values.ACHinfoaddedtobank === 'yes' ? 1 : 0,
                  email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                  last_ach_updated: (values.ACHinfoaddedtobank == 'yes') ? values.ACHdate1 == null ? bank_details.last_ach_updated : achDate : null,
                  achflag: values.achCheckValue,
                  manually_bank_name_updated : !bankNameReadonly,
                  routing_number_updated : (bankNameReadonly == false || routingNumberError == false ? 1 : 0)
                }
                :
                {
                    id: id,
                    bank_name: values.BankName,
                    name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                    routing_number: values.BankRouting,
                    account_number: values.AccountNumber,
                    account_type: values.AccountType,
                    account_category: values.AccountCategory, 
                    email_to_notify: values.EmailAddresstonotifyofpaymenttransfers  
                }
              const res =  await dispatch(postData({apiUrl,args})).unwrap();
              if(res) {
                      if (res.success === true) {
                        
                      resolveBankDetailsIssue();
                      swal({
                          title: "Success",
                          text: res.message,
                          icon: "success",
                          button: "Ok",
                      })
                      values.isFormDirty=true;
                      setIsLoading(false);
                      fetchCompanyBankDetails();
                      
                  } else {
                    setIsLoading(false);
                      swal({
                          title: "Failed",
                          text: res.message,
                          icon: "error",
                          button: "Ok",
                      });
                  }
              }
              else {
                  setLoader('');
                  document.getElementById("createbtn").classList.remove('d-none');
                  setStatusMesage('');
                  setLoader('');
                  setIsLoading(false);
                  swal({
                      title: "Failed",
                      text: res.message,
                      icon: "error",
                      button: "Ok",
                  });
              }
          }
    }
    //Update function end
  
      //Bank validation Schema start
      const BankSchema = Yup.object().shape({
        // BankName: Yup.string()
        //     .min(2, 'Too Short!')
        //     .max(100, 'Too Long!')
        //     .required('Please Enter Your Bank Name.'),

        AccountCategory: Yup.string()
            .required('Please Select Is Account.'),

        NameonAccount: 
            Yup.string().when("AccountCategory",{
                is: (AccountCategory) => AccountCategory == "business",
                then: () =>  
                Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Please Enter Business Name.')
            }),

        firstName: 
            Yup.string().when("AccountCategory",{
                is: (AccountCategory) => AccountCategory == "personal",
                then: () =>  
                Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Please Enter First Name.')
                .matches(/^[a-zA-Z. ]*$/, "Only alphabets space & . are allowed"),
            }),

        lastName: 
            Yup.string().when("AccountCategory",{
                is: (AccountCategory) => AccountCategory == "personal",
                then: () =>  
                Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Please Enter Last Name.')
                .matches(/^[a-zA-Z. ]*$/, "Only alphabets space & . are allowed"),
            }),

        BankRouting: Yup.string()
            .matches(/^\d+$/, 'Invalid Format')
            .min(9, 'At least 9 digit required')
            .max(9, 'maximum 9 digit')
            .required('Please Enter Your Bank Routing number.'),

        AccountNumber: Yup.string()
            .required('Please Enter Your Account Number.'),

        AccountType: Yup.string()
            .required('Please Select Account Type.'),

       

        EmailAddresstonotifyofpaymenttransfers: Yup.string()
            // .email('Invalid email format.')
            // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Please enter your email address to notify payment transfers.'),

    });
      //Bank validation Schema end
  
      const checkIfValueExists = (arr, value) => {
          return arr.some(item => item.attribute_name.attribute_name === value && item.attribute_new_value != null);
      };

      //validate email
    const checkUsaTLD = async(email) => {
        if (email.includes('@')) {
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            }
        }
    }
  
      //history table column array start
      const columns = useMemo(
          () => [
              {
                  accessorFn: (row) => `${row.id}`, //accessorFn used to join multiple data into a single cell
                  id: 'id', //id is still required when using accessorFn instead of accessorKey
                  header: 'ID',
                  numeric: true,
                  size: 50,
                  
                  Cell: ({ renderedCellValue, row }) => (
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                          }}
                      >
                          <span>{row.original.id}</span>
                      </Box>
                  ),
                  
              },
              {
                  accessorFn: (row) => `${row.name}`,
                  id: 'updated_by', //id is still required when using accessorFn instead of accessorKey
                  header: 'Updated By',
                  size: 150,
                  Cell: ({ renderedCellValue, row }) => (
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                          }}
                      >
                          <span>{row.original.name}</span>
                      </Box>
                  ),
              },
              {
                  accessorFn: (row) => `${row.role_name}`,
                  id: 'user_role', //id is still required when using accessorFn instead of accessorKey
                  header: 'User Role',
                  size: 150,
                  Cell: ({ renderedCellValue, row }) => (
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                          }}
                      >
                          <span>{row.original.role_name}</span>
                      </Box>
                  ),
              },
              {
                  accessorFn: (row) => `${row.attributes}`,
                  id: 'updated_fields', //id is still required when using accessorFn instead of accessorKey
                  header: 'Updated Fields',
                  size: 250,
                  Cell: ({ renderedCellValue, row }) => (
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                          }}
                      >
                          <span>{
                              row.original.attributes ?
                                  row.original.new_values ?
                                      row.original.new_values.split(',').length != row.original.attributes.split(',').length ?
                                          <>
                                          {row.original.attributes.replace(",ACH Date", '') } &nbsp;
                                          { (row.original.new_values && row.original.new_values !== '1') && row.original.bank_attribute_id ? <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span> : ''}
                                          </>
                                      :
                                      <>
                                        { row.original.attributes}&nbsp;
                                        { (row.original.attributes != "Ach Info Added To Bank") && row.original.bank_attribute_id ? <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span>: ''}
                                      </> 
                                  :
                                  <>
                                  { row.original.attributes }&nbsp;
                                  { (row.original.attributes != "Ach Info Added To Bank") && row.original.bank_attribute_id ?  <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span>: ''}
                                  </> 
                                 
                              : "No fields has been updated in this operation."}</span>
                      </Box>
                  ),
              },
              {
                  accessorFn: (row) => `${row.attributes ? row.attributes.includes("ACH Date") ? (row.new_values != null ? row.new_values.split(',').length == row.attributes.split(',').length ?  row.new_values.split(',')[row.new_values.split(',').length - 2] != 0 ? (row.new_values.split(',')[row.new_values.split(',').length - 1]) :'' :'' :''): '': ''}`,
                  id: 'last_ach_updated', //id is still required when using accessorFn instead of accessorKey
                  header: 'Added ACH Date',
                  size: 100,
                  Cell: ({ renderedCellValue, row }) => (
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                          }}
                      >
                          <span>{row.original.attributes ? row.original.attributes.includes("ACH Date") ? (row.original.new_values != null ? row.original.new_values.split(',').length == row.original.attributes.split(',').length ?  row.original.new_values.split(',')[row.original.new_values.split(',').length - 2] != 0 ? Moment(row.original.new_values.split(',')[row.original.new_values.split(',').length - 1]).format('MM-DD-YYYY') :'' :'' :''): '': '' }</span>
                      </Box>
                  ),
              },
              {
                  accessorFn: (row) => `${row.created_at}`,
                  id: 'updated_date', //id is still required when using accessorFn instead of accessorKey
                  header: 'Updated Date',
                  size: 150,
                  Cell: ({ renderedCellValue, row }) => (
                      <Box
                          sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem',
                          }}
                      >
                          <span>{row.original.created_at?Moment(row.original.created_at).format('MM-DD-YYYY'):'N/A'}</span>
                      </Box>
                  ),
              },
          ],
          [],
      );

      const filterHistoryColumns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.id}`, //accessorFn used to join multiple data into a single cell
                id: 'id', //id is still required when using accessorFn instead of accessorKey
                header: 'Attribute Name',
                numeric: true,
                size: 150,
                
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <span>{row.original.attribute_name ? row.original.attribute_name.display_name  : ''}</span>
                    </Box>
                ),
                
            },
            {
                accessorFn: (row) => `${row.attribute_old_value}`,
                id: 'attribute_old_value', //id is still required when using accessorFn instead of accessorKey
                header: 'Previous Value',
                size: 150,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <span>{row.original.attribute_name.attribute_name == "name_on_account" ? row.original.attribute_old_value !== null ? row.original.attribute_old_value.indexOf(':')  >= 0 ? row.original.attribute_old_value.replace(/:/g, " ") : row.original.attribute_old_value :  row.original.attribute_old_value :  row.original.attribute_old_value}</span>
                    </Box>
                ),
            },
            {
                accessorFn: (row) => `${row.attribute_new_value}`,
                id: 'attribute_new_value', //id is still required when using accessorFn instead of accessorKey
                header: 'Updated Value',
                size: 150,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <span>{row.original.attribute_name.attribute_name == "name_on_account" ? row.original.attribute_new_value !== null ? row.original.attribute_new_value.indexOf(':')  >= 0 ? row.original.attribute_new_value.replace(/:/g, " ") : row.original.attribute_new_value : row.original.attribute_new_value : row.original.attribute_new_value}</span>
                    </Box>
                ),
            },
        ],
        [],
    );
      //history table column array end
  
      //fetch bank name 
      const fetchBankName = async (routingNumber, values, setFieldValue) => {
          const apiUrl = "/bankname/"+routingNumber;
          setFieldValue('BankName','');
          if(routingNumber.length == 9)
            {
              setFieldValue('BankName','');
              setIsLoading(true);
              const res = await dispatch(fetchData(apiUrl)).unwrap();
              if(res.success)
                {
                  setIsLoading(false);
                  setFieldValue('BankName', res.data);
                  setRoutingNumberError(true);
                  setRoutingNoErr("");
                //   setBankNameReadonly(true);
                }
                else
                {
                    if(defaultRoutingNumber == routingNumber) {
                        setFieldValue('BankName', defaultBankName);
                        setRoutingNumberError(true);
                        setRoutingNoErr("");
                        // setBankNameReadonly(false);
                    }else{
                        setFieldValue('BankName', '');
                        setRoutingNumberError(false);
                        setRoutingNoErr("Please check entered routing number once and if needed contact support for help");
                    }
                    setIsLoading(false);
                }
            } 
        };

        const fetchHistoryDetails = async (bank_attribute_id) => {
            const apiUrl = "/fetch-bank-history-details/"+ bank_attribute_id;
            setIsLoading(true);
            const args = {}
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res.success)
            {
                setIsLoading(false);
                setViewBankHistory(res.data);
                handlebankOpen();
            }
            else
            {
                setIsLoading(false);
                setViewBankHistory([]);
            }
        };
  
        return (
            <>
            {isLoading?<Loader/>:<></>}
            <Box  sx={{mt:1.5}}>
            <Formik
              initialValues={{
                        // BankName: bank_details.bank_name || "",
                        BankName: defaultBankName,
                        NameonAccount: bank_details.account_category ? bank_details.account_category == "business" ? bank_details.name_on_account : "" : "",
                        firstName: firstNameOnAccount || "",
                        lastName: lastNameOnAccount || "",
                        BankRouting: bank_details.routing_number || "",
                        AccountNumber: bank_details.account_number || "",
                        AccountType: bank_details.account_type,
                        AccountCategory: bank_details.account_category ,
                        ACHinfoaddedtobank: statusAch ,
                        isFormDirty : true,
                        achCheckValue : false,
                        EmailAddresstonotifyofpaymenttransfers: bank_details.email_to_notify || "",
                        ACHdate1 : bank_details.last_ach_updated || null
                    }}
                    validateOnChange={true}  // Ensure this is true
                    validateOnBlur={true}
                    validationSchema={BankSchema}
                    onSubmit={(values, errors) => {
                        const loginUser = JSON.parse(localStorage.getItem('user'));
                        if(loginUser) {
                            if(loginUser.roles_id == 1 || loginUser.roles_id == 2 || loginUser.roles_id == 3){
                                operation == "create" ? CreateBankDetails(values) : UpdateBankDetails(values);
                            }else{
                                navigate('/login');
                            }
                        }else{
                            navigate('/login');
                        }
                    }}
            >
                
                {({ values, errors, touched, dirty,handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                    <Box>
                        <Card variant="outlined" size="lg">
                            <CardOverflow
                                sx={{
                                    bgcolor: '#F6FAFF',
                                    borderColor: '#F6FAFF',
                                    padding: '1%',
                                }}
                            >
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                                    <Typography level="h4" color="primary">
                                        Bank Details ({companyName})
                                    </Typography >
                                    <Typography level="h4" color="primary">Last updated: {filterhistory[0] ? Moment(filterhistory[0].created_at).format('MM-DD-YYYY ') : bank_details.updated_at ? Moment(bank_details.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY ') : 'N/A'}</Typography>

                                </Grid>
                            </CardOverflow>
                            <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                <Card variant="outlined" sx={{ width: isMobile? '100%' :'60%' }}>
                                    <FormControl  sx={{ width: '100%' }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <FormLabel sx={{ color: user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_category") ? "#ed6c02" : 'black' : 'black' : 'black' }}>Account is*</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="AccountCategory"
                                                id='AccountCategory
                                                '
                                                disabled={user.roles_id > 3 ? true : false}
                                                onChange={(e)=>{
                                                    handleChange(e);
                                                    setIsAccount(e.target.value);
                                                }}
                                                value={values.AccountCategory}
                                                onBlur={handleBlur('AccountCategory')}
                                                sx={{
                                                    marginLeft: 2,
                                                }}
                                                color={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_category") ? "warning" : '' : '' : '' }
                                                focused={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_category") ? "warning" : '' : '' : '' } 
                                            >
                                                <FormControlLabel value="personal" control={<Radio />} label="Personal" sx={{ fontWeight: "bold" }} />
                                                <FormControlLabel value="business" control={<Radio />} label="Business" sx={{ fontWeight: "bold" }} />
                                            </RadioGroup>
                                        </Box>
                                        {touched.AccountCategory && errors.AccountCategory ? <div className='error'>{errors.AccountCategory}</div> : null}
                                    </FormControl>
                                </Card>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} >
                            {
                                isAccount == "business" ?
                                <>
                                    <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: isMobile? '100%' :'60%' }}>   
                                        <TextField
                                            label="Business Name*"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            margin="dense"
                                            inputRef={inputRef}
                                            name='NameonAccount'
                                            autoComplete="NameonAccount"
                                            disabled={user.roles_id > 3 ? true : false}
                                            id='NameonAccount'
                                            onChange={(e)=>{
                                                handleChange(e)
                                                
                                            }}
                                            value={values.NameonAccount}
                                            onBlur={handleBlur('NameonAccount')}
                                            color={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "name_on_account") ? "warning" : '' : '' : ''}
                                            focused={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "name_on_account") ? "warning" : '' : '' : ''}      
                                        />
                                        {touched.NameonAccount && errors.NameonAccount ? <div className='error'>{errors.NameonAccount}</div> : null}
                                    </FormControl>     
                                </>
                                :
                                <>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}sx={{ justifyContent: "center"  }} mt={1}>
                                        <Stack direction="row" spacing={2}  sx={{ width: isMobile? '100%' :'60%' }} > 
                                            <Grid item lg={6} md={6} sm={12} xs={12} >
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense">
                                                    <TextField
                                                        label="First Name*"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='firstName'
                                                        autoComplete="firstName"
                                                        disabled={user.roles_id > 3 ? true : false}
                                                        id='firstName'
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            
                                                        }}
                                                        // InputLabelProps={{  shrink: Boolean(values.firstName ) }}
                                                        InputLabelProps={{ 
                                                            shrink: !!values.firstName || document.activeElement.id === 'firstName'
                                                        }}
                                                        onFocus={() => setFieldTouched('firstName', true)}
                                                        value={values.firstName}
                                                        onBlur={handleBlur('firstName')}
                                                        color={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "name_on_account") ? "warning" : '' : '' : ''}
                                                        focused={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "name_on_account") ? "warning" : '' : '' : ''}      
                                                    />
                                                    {touched.firstName && errors.firstName ? <div className='error'>{errors.firstName}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12} >
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense"  >
                                                    <TextField
                                                        label="Last Name*"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        inputRef={inputRef}
                                                        name='lastName'
                                                        autoComplete="lastName"
                                                        disabled={user.roles_id > 3 ? true : false}
                                                        id='lastName'
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            
                                                        }}
                                                        // InputLabelProps={{  shrink: Boolean(values.lastName) }}
                                                        InputLabelProps={{ 
                                                            shrink: !!values.lastName || document.activeElement.id === 'lastName'
                                                        }}
                                                        onFocus={() => setFieldTouched('lastName', true)}
                                                        value={values.lastName}
                                                        onBlur={handleBlur('lastName')}
                                                        color={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "name_on_account") ? "warning" : '' : '' : ''}
                                                        focused={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "name_on_account") ? "warning" : '' : '' : ''}      
                                                    />
                                                    {touched.lastName && errors.lastName ? <div className='error'>{errors.lastName}</div> : null}
                                                </FormControl>
                                            </Grid>
                                        </Stack> 
                                    </Grid>
                                </>
                            }
                            </Grid>                                
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                <Typography variant="subtitle1" gutterBottom className="font2" color="blue" >
                                    Enter the name of the Account held with the Bank.<span style={{color:"red"}}>(NOT YOUR BANK'S NAME)</span>
                                </Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: isMobile? '100%' :'60%' }}>   
                                    <InputLabel htmlFor="outlined-adornment-password"
                                        sx={{ color: user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "routing_number") ? "#ed6c02" : 'black' : 'black' : 'black'  }} 
                                        >Bank Routing (ABA / ACH) Number*
                                    </InputLabel>
                                    <InputMask
                                        mask='999999999'
                                        maskChar={null}
                                        required
                                        label="Bank Routing (ABA / ACH) Number*"                                                        
                                        size="small"
                                        margin="dense"
                                        fullWidth
                                        name='BankRouting'
                                        autoComplete='BankRouting'
                                        id='BankRouting'
                                        disabled={user.roles_id > 3 ? true : false}
                                        color={user.roles_id < 3 ? (updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "routing_number") ? "warning" : '' : '') : ''}
                                        focused={user.roles_id < 3 ? (updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "routing_number") ? true : false : false) : false}
                                        onChange={(e)=>{
                                            handleChange(e);
                                            fetchBankName(e.target.value,values, setFieldValue);
                                        }}
                                        value={values.BankRouting}
                                        onBlur={handleBlur('BankRouting')}
                                    >
                                        {(inputProps) =>
                                            <OutlinedInput
                                                label="Bank Routing (ABA / ACH) Number*" variant="outlined"
                                                {...inputProps}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                                        <div>
                                                        <Tooltip
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        onClose={handleTooltipClose}
                                                        open={openTooltip}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener

                                                            title={<div style={{ maxWidth: isMobile?"150px": '400px' }}><img src={tooltipImage}
                                                                style={{ width : "100%" , height: 'auto' }} /></div>} 
                                                                placement= "left-start"
                                                                // placement="bottom"
                                                                interactive={true.toString()} >
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                                onClick={handleTooltipOpen}
                                                            >
                                                                <img src={iIcon} alt="info"  />
                                                            </IconButton>
                                                        </Tooltip>
                                                        </div>
                                                        </ClickAwayListener>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    </InputMask>
                                    {touched.BankRouting && errors.BankRouting || routingNoErr ? <div className='error'>{errors.BankRouting ? errors.BankRouting : routingNoErr != '' ? routingNoErr : ''}</div> : null}
                                </FormControl>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                {/* <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: isMobile? '100%' :'60%' }}>   
                                        <TextField
                                            label="Bank Name"
                                            margin="dense"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='BankName'
                                            autoComplete='BankName'
                                            disabled={user.roles_id > 3 ? true : false}
                                            id='BankName'
                                            InputProps={{
                                                readOnly: bankNameReadonly, // This sets the field as read-only
                                                sx: {
                                                    backgroundColor: bankNameReadonly ? 'rgba(0, 0, 0, 0.1)' : 'white', 
                                                    color: bankNameReadonly ? 'gray' : 'black', 
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: Boolean(values.BankName)
                                            }}
                                            onChange={(e)=>{
                                                handleChange(e)
                                                
                                            }}
                                            value={values.BankName}
                                            onBlur={handleBlur('BankName')}
                                            color={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "bank_name") ? "warning" : '' : ''  : ''}
                                            focused={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "bank_name") ? "warning" : '' : '' : '' }                                                    
                                        />
                                        {touched.BankName && errors.BankName ? <div className='error'>{errors.BankName}</div> : null}
                                </FormControl> */}
                           
                            <br/>
                            <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: isMobile? '100%' :'60%' }}>   
                                <InputLabel htmlFor="bootstrap-input">Bank Name</InputLabel>
                                <OutlinedInput
                                    label="Bank Name"
                                    margin="dense"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    name="BankName"
                                    autoComplete="BankName"
                                    disabled={user.roles_id > 3 ? true : false}
                                    id="BankName"
                                    readOnly={bankNameReadonly} // Move this out of InputProps
                                    sx={{
                                        backgroundColor: bankNameReadonly ? 'rgba(0, 0, 0, 0.1)' : 'white', 
                                        color: bankNameReadonly ? 'gray' : 'black'
                                    }}
                                    InputLabelProps={{
                                        shrink: Boolean(values.BankName)
                                    }}
                                    onChange={(e) => handleChange(e)}
                                    value={values.BankName}
                                    onBlur={handleBlur('BankName')}
                                    endAdornment={
                                        user.roles_id < 3 ?
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" edge="end">
                                                <ModeEditOutlinedIcon onClick={changeBankNameProperty}/>
                                            </IconButton>
                                        </InputAdornment>
                                        : ''
                                    }
                                />
                            </FormControl>
                            </Grid>
                                
                               
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                    <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: isMobile? '100%' :'60%' }}>  
                                        <InputLabel htmlFor="outlined-adornment-password"
                                        sx={{ color: user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_number") ? "#ed6c02" : 'black' : 'black' : 'black'  }} 
                                        >Account Number*</InputLabel>
                                        <OutlinedInput
                                            label="Account Number*"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='AccountNumber'
                                            autoComplete='AccountNumber'
                                            margin="dense"
                                            id='AccountNumber'
                                            disabled={user.roles_id > 3 ? true : false}
                                            onChange={(e)=>{
                                                handleChange(e)
                                                
                                            }}
                                            value={values.AccountNumber}
                                            onBlur={handleBlur('AccountNumber')}
                                            color={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_number") ? "warning" : '' : '' : '' }
                                            focused={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_number") ? "warning" : '' : '' : '' }      
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <ClickAwayListener onClickAway={handleTooltipClose2}>
                                                            <div>
                                                    <Tooltip
                                                        PopperProps={{
                                                        disablePortal: true,
                                                        }}
                                                        onClose={handleTooltipClose2}
                                                        open={openTooltip2}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        title={<div style={{ maxWidth: isMobile?"150px": '400px' }}><img src={tooltipImage}
                                                            style={{ width: '100%', height: 'auto' }} /></div>}
                                                            placement="left-start"
                                                            interactive={true.toString()}>
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            onClick={handleTooltipOpen2}
                                                        >
                                                                <img src={iIcon} alt="info" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    </div>
                                                    </ClickAwayListener>
                                                </InputAdornment>
                                            }
                                        />
                                         {touched.AccountNumber && errors.AccountNumber ? <div className='error'>{errors.AccountNumber}</div> : null}
                                    </FormControl>
                            </Grid>
                            
                            <Grid container item lg={12} md={12} sm={12} xs={12}  sx={{ justifyContent: "center" }} mt={2} mb={1}>
                                <Card variant="outlined" sx={{ width: isMobile? '100%' :'60%' }}>
                                    <FormControl margin="dense" sx={{ width: '100%' }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <FormLabel sx={{ color: user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_type") ? "#ed6c02" : 'black' : 'black' : 'black' }} 
                                            >Type of Account*</FormLabel>
                                            <RadioGroup
                                                row
                                                name='AccountType'
                                                id='AccountType'
                                                disabled={user.roles_id > 3 ? true : false}
                                                onChange={(e)=>{
                                                    handleChange(e)  
                                                }}
                                                value={values.AccountType}
                                                onBlur={handleBlur('AccountType')}
                                                sx={{
                                                    marginLeft: 2,
                                                }}
                                                color={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_type") ? "warning" : '' : '' : '' }
                                                focused={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "account_type") ? "warning" : '' : '' : '' } 
                                            >
                                                <FormControlLabel value="checking" control={<Radio />} label="Checking" sx={{ fontWeight: "bold" }} />
                                                <FormControlLabel value="savings" control={<Radio />} label="Savings" sx={{ fontWeight: "bold" }} />
                                            </RadioGroup>
                                            </Box>
                                        {touched.AccountType && errors.AccountType ? <div className='error'>{errors.AccountType}</div> : null}
                                    </FormControl>
                                </Card>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                                <FormControl variant="outlined" size="small" fullWidth margin="dense" sx={{ width: isMobile? '100%' :'60%' }}>  
                                        <TextField
                                            label="Email Address to notify of payment transfers*"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            disabled={user.roles_id > 3 ? true : false}
                                            name='EmailAddresstonotifyofpaymenttransfers'
                                            autoComplete="EmailAddresstonotifyofpaymenttransfers"
                                            // onChange={handleChange('EmailAddresstonotifyofpaymenttransfers')}
                                            onChange={(e)=>{
                                                handleChange(e);  
                                                checkUsaTLD(e.target.value);
                                            }}
                                            value={values.EmailAddresstonotifyofpaymenttransfers}
                                            onBlur={handleBlur('EmailAddresstonotifyofpaymenttransfers')}
                                            color={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "email_to_notify") ? "warning" : '' : ''  : ''}
                                            focused={user.roles_id < 3 ? updatedAttributes.length > 0 ? checkIfValueExists(updatedAttributes, "email_to_notify") ? "warning" : '' : '' : '' } 
                                        />
                                        <div className='error'>{touched.EmailAddresstonotifyofpaymenttransfers && errors.EmailAddresstonotifyofpaymenttransfers ?errors.EmailAddresstonotifyofpaymenttransfers : checkUsaTldStatus ? "Invalid email format." : '' }</div>  
                                        {firstlable ? <Typography variant="subtitle1" gutterBottom className="font2" color="blue">
                                            This is email address with which {bank_details.company_name} has been registered with us. You can change this if you want another email for banking purpose.
                                        </Typography> : null}
                                </FormControl>
                            </Grid>

                            <Divider sx={{mt:2}}/>
                                
                                {/* Hide ACH update for affiliate start */}

                                {user.roles_id == 1 || user.roles_id == 2 ?
                                    <Box sx={{ marginLeft:"20%",  marginRight:"20%",border:"1px solid red",p:2}}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                            <Typography level="h5" color="primary" mt={2}>BACK OFFICE USE ONLY DO NOT ENTER ANY INFORMATION BELOW</Typography>
                                        </Grid>
                                        {operation == "Update" && bank_details.ach_info_added_to_bank == 1 ?
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                <FormGroup >
                                                    <FormControlLabel control={<Checkbox
                                                        checked={values.achCheckValue}                                                                
                                                        onChange={(e) => {   
                                                            setFieldValue('achCheckValue',!values.achCheckValue,true)
                                                            setFieldValue('ACHinfoaddedtobank', statusAch, true)
                                                            setFieldValue('ACHdate1',bank_details.last_ach_updated || null, true )   
                                                        }}

                                                    />} label="Have you also updated ACH details in the Bank?" />
                                                </FormGroup>
                                            </Grid>
                                            : 
                                            null
                                            }


                                        {values.achCheckValue || bank_details.ach_info_added_to_bank == 0 || operation == "create" ?
                                            <>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                    <FormControl>
                                                        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "black" }}>ACH Info Added to Bank</FormLabel>
                                                        <RadioGroup
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="ACHinfoaddedtobank"
                                                            id='ACHinfoaddedtobank'
                                                            disabled={user.roles_id > 3 ? true : false}
                                                            onChange={(e) => {  
                                                              handleChange(e) 
                                                              if (e.target.value == "yes" ) {
                                                                setFieldValue('achCheckValue',1)
                                                                }  
                                                                else{
                                                                    if(statusAch == 'no' && statusAch == e.target.value){
                                                                        setFieldValue('achCheckValue',!values.achCheckValue,true)
                                                                        if(operation == "create") {
                                                                            setFieldValue('ACHdate1', null, true )   
                                                                        }
                                                                    }                                                             
                                                                }
                                                            }                                                   
                                                            }
                                                            value={values.ACHinfoaddedtobank}
                                                            onBlur={handleBlur('ACHinfoaddedtobank')}
                                                        >
                                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" sx={{ fontWeight: "bold" }} />
                                                            <FormControlLabel value="no" control={<Radio />} label="No" sx={{ fontWeight: "bold" }} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid container item lg={7} md={7} sm={12} xs={12} mt={2}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                                <DatePicker label="Select ACH Date"
                                                                    margin="dense"
                                                                    name="LastACHupdatedate"
                                                                    id='LastACHupdatedate'
                                                                    inputRef={inputRef1}
                                                                    onChange={(value) => { setFieldValue("ACHdate1", value, true) }}
                                                                    maxDate={dayjs(currentDate)}
                                                                    defaultValue={values.ACHdate1 !=null ?  dayjs(values.ACHdate1) : dayjs(bank_details.last_ach_updated || '')}
                                                                />
                                                            </DemoContainer>
                                                    </LocalizationProvider>
                                                </Grid>

                                            </>
                                            : ""}
                                    </Box>
                                    : ""}
                                {/* ----Hide ACH update for affiliate end---- */}
                                
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2} mb={2}  spacing={2}>
                                    <Grid item>
                                        {user.roles_id<=3?
                                        <Button variant="contained" sx={{ minWidth: isMobile ? 150: 200, maxWidth: "100%" }} color="success" disabled={(!dirty)} onClick={handleSubmit}>
                                            {btntext}
                                        </Button>
                            :null   }
                                    </Grid>
                                    {user.roles_id < 3 ? 
                                    <>
                                    <Grid item>
                                        <Button variant="contained" color="primary" sx={{ minWidth: isMobile ? 150: 200, maxWidth: "100%" }} onClick={() => handleCloseButton()}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    </>
                                    : ''
                                    } 
                                    

                                    {/* hide report issue button on affiliate side start */}
                                    {user.roles_id == 1 || user.roles_id == 2 ?
                                        <>
                                            <Grid item>
                                                {operation == "Update" &&
                                                    (bank_details.report_issue != 1 ||
                                                        bank_details.report_issue == null) &&
                                                    bank_details.name_on_account != "" &&
                                                    bank_details.name_on_account != null &&
                                                    bank_details.routing_number != "" &&
                                                    bank_details.routing_number != null &&
                                                    bank_details.account_number != "" &&
                                                    bank_details.account_number != null &&
                                                    bank_details.account_type != "" &&
                                                    bank_details.account_type != null
                                                    ? <Button variant="contained" color="warning" sx={{ minWidth: isMobile ? 150: 200, maxWidth: "100%" }} onClick={() => handleClickReport()}>
                                                    Report Issue
                                                </Button>  : " "}
                                            </Grid>
                                        </>
                                        : ""}

                                    {/* hide report issue button on affiliate side end */}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>

                )}

            </Formik>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleCloseReport}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: "#F6FAFF" }}>
                    Report Banking Issue
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText> */}
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>

                        <FormControl fullWidth>

                            <TextareaAutosize
                                margin="dense"
                                aria-label="minimum height"
                                minRows={5}
                                placeholder="Report Issue"
                                onChange={(e) => {handleReportIssueChange(e.target.value)}}
                                fullWidth
                                sx={{ borderColor: "#D3D3D3" }}
                            />

                        {reportissue == "" ? <div className="error">Report issue reason</div> : null}
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <InputLabel htmlFor="bootstrap-input"><b>Send Mail to the Affiliate ↓*</b></InputLabel>
                        <TextField
                            fullWidth
                            
                            size="small"
                            defaultValue="MFSN Bank details Incorrect (ACH Issue)"
                            onChange={(e) => {handleSubjectChange(e.target.value)}}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <Grid container item md={12} >
                            <Grid item>
                                <InputLabel htmlFor="bootstrap-input" ><b>Message:*</b></InputLabel>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            
                        </Grid>

                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <CKEditor editor={ClassicEditor} id="mailBody" name="mailBody"
                        data= {htmlData}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                htmlData = data;
                            } }
                        />
                    </Grid>
                    {/* </DialogContentText> */}
                    <DialogActions sx={{marginTop: isMobile ? "2%" : "2%"}}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" sx={{ minWidth: 150 }}   onClick={handleCloseReport}>
                                CLOSE
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" sx={{ minWidth: 150 }} color="success" onClick={() => reportBankDetailsIssue(id)}>
                                SAVE & SEND EMAIL
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
                </DialogContent>
                
            </Dialog>

            
            <Dialog
              open={openbank}
              onClose={handlebankClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              maxWidth={mediumWidth}
            >
              <DialogTitle id="responsive-dialog-title">
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                    <Grid item lg={7} md={7} sm={7} xs={7}>
                    <Typography level="h5">
                    Bank Details Update History 
                    </Typography >
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={5} sx={{textAlign:"right"}}>
                    <span style={{fontWeight: '95%'}} ml={2} >Updated Date:{viewBankHistory[0] ? viewBankHistory[0].created_at? Moment(viewBankHistory[0].created_at).tz('America/Los_Angeles').format('MM-DD-YYYY'):'N/A' : 'N/A'}</span>
                       &nbsp;&nbsp; <CloseIcon onClick={handlebankClose} />
                    </Grid>
                  </Grid>
              </DialogTitle>
              <DialogContent>
                <div>
                        <MaterialReactTable

                            columns={filterHistoryColumns}
                            data={viewBankHistory}
                            enableColumnFilterModes
                            enableColumnOrdering={false}
                            enableGrouping={false}
                            enablePinning={false}
                            initialState={{ showColumnFilters: false,density: 'compact'}}
                            enableDensityToggle={false}
                            enableFullScreenToggle={ false}
                            enableHiding={false} 
                            enableColumnFilters={false}
                            enableSorting={true}
                            filterFns={{
                              customSearchFilterFn: (row, id, filterValue) =>
                              row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                            }}                                   
                            globalFilterFn="customSearchFilterFn"
                            positionToolbarAlertBanner="bottom"
                        />
                </div>
              </DialogContent>
            </Dialog>
             
        </Box>
        {operation == "create" ? "" : 
        user.roles_id < 3 ?
        
            <div>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Typography level="h5" color="primary" mt={2}>Bank Details Update History</Typography>
                </Grid>
                <MaterialReactTable
                    columns={columns}
                    data={filterhistory}
                    enableColumnFilterModes
                    enableColumnFilters={config.DatatableColumnFilter}
                    state={{ columnVisibility, rowSelection }} 
                    onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                    positionToolbarAlertBanner="bottom"
                    enableDensityToggle={false}
                />
            </div>
            : null
        }  
            </>
        );

        
    }

export default ViewBankForm;