
import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Typography } from '@mui/joy';
import {
    Box, Grid, TextField,FormControl, Select, Dialog, DialogActions,DialogTitle, InputLabel, Button,
    DialogContent, MenuItem, FormHelperText,TableContainer,Paper,TableHead, TableRow,Table, TableBody, TableCell,
} from '@mui/material';
import CommissionRate from '../../../Components/CommissionSummary/CommissionRate';
import AuthUser from "../../../Components/Auth/AuthUser";
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { useNavigate } from 'react-router-dom';
import { async } from 'q';
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import Moment from 'moment';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

let RequestFormInitialValues = {
    rateId: '',
    pid: '',
    description: '',
    amount: '',
    commission: '',
    effective_from: '',
    amountError: ''
};
let crateValues;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function CommissionRates(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [createDisabled, setCreateDisabled] = React.useState(false);
    const [editDisabled, setEditDisabled] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const { http, user } = AuthUser();
    const [effective_from, setFollowupDate] = useState(dayjs());
    const navigate = useNavigate();
    const [ratelist, setRateList] = useState([]);
    const [freez, setFreez] = React.useState(true);
    const [records, setRecords] = useState([]);
    const [open1, setOpen1] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);

    const handleFreezStop = () => {
        setFreez(false);
      };
      const handleFreezStart = () => {
        setFreez(true);
      };

    const handleClose1 = () => {
    setOpen1(false);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    const handleClickEditOpen = (data) => {
        RequestFormInitialValues.rateId = data.id;
        RequestFormInitialValues.pid = data.pid;
        RequestFormInitialValues.amount = data.amount;
        RequestFormInitialValues.description = data.description;
        RequestFormInitialValues.commission = data.commission;
        RequestFormInitialValues.effective_from = data.effective_from ? data.effective_from : '';
        setEditOpen(true);
    };

    const fetchCommissionRateDetails = async (id) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }
        setOpen1(false);
        const apiUrl = '/commission/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res.success === true) {
          handleClickEditOpen(res.commissionData);
        } else {
          alert("error in fetching commission rate details");
        }
    };

    const submitRequest = async (values) => {
        if((values.description == "Refund" || values.description == "chargeback associated to sale") && parseFloat(values.commission) > 0) {
          swal({
              title: "Failed",
              text: "Commission value for "+values.description + " should be negative",
              icon: "error",
              button: "Ok",
          })
          return false;
        }
        if((values.description == "Payment" || values.description == "3-Bureau Credit Report & Scores Payment") && parseFloat(values.commission) < 0) {
            swal({
                title: "Failed",
                text: "Commission value for "+values.description + " should be postive",
                icon: "error",
                button: "Ok",
            })
            return false;
        }
        var commissionValue = parseFloat(values.commission);
        var amountValue = parseFloat(values.amount);
        if(amountValue < commissionValue) {
        swal({
            title: "Failed",
            text: "Commission value should be less than amount",
            icon: "error",
            button: "Ok",
        })
        return false;
        }
        if(values.amountError != '') {
            return false;
        }
       
        setEditDisabled(true);
        const date = new Date(values.effective_from);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        var mydate = year + "/" + month + "/" + day;
        const apiUrl = '/update-commission';
        const args = {
          id: values.rateId,
          pid: values.pid,
          amount: values.amount,
          description: values.description,
          commission: values.commission,
          effective_from: mydate,
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
          if (res.success === true) {
            setEditOpen(false);
            swal({
              title: "Success",
              text: res.message,
              icon: "success",
              button: "Ok",
            })
            fetchRateList()
          } else {
            swal({
              title: "Failed",
              text: res.message,
              icon: "error",
              button: "Ok",
            })
    
            fetchRateList()
          }
        }
        setEditDisabled(false);
    }

    const createCommission = async () => {
        setOpen1(false);
        setCreateDisabled(true);
        console.log(crateValues.pid,crateValues.amount,crateValues.description,crateValues.commission);
        const date = new Date(crateValues.effective_from);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        var mydate = year+"/"+month+"/"+day;
        const apiUrl = '/create-commission';
        const args = {
            pid: crateValues.pid,
            amount: crateValues.amount,
            description: crateValues.description,
            commission: crateValues.commission,
            effective_from: mydate,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if (res.success === true) {
                // setOpen(false);
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
            } else {
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
            }
        }
        setCreateDisabled(false);
        crateValues = [];
        fetchRateList();
    }

    const fetchRateList = async() => {
        handleFreezStart();
        const apiUrl = '/commission-rates' ;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
        setRateList(res);
        handleFreezStop();
        }
    }

    useEffect(() => {
        if (user.roles_id < 3) {
          fetchRateList();
        } else {
          navigate('/login');
        }
      }, []);

    const handleClickOpen = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };
    const rateSchema = Yup.object().shape({
        pid: Yup.string()
            .min(5, 'Must be 5 digits.')
            .max(5, 'Must be 5 digits.')
            .required('Please enter PID'),

        amount: Yup.string()
            .required('Please enter Amount'),

        commission: Yup.string()
            .required('Please enter Commission'),

        effective_from: Yup.string()
            .required('Please enter Effective from date'),

        description: Yup.string()
            .required('Please select Description')
    })

    function ContentLayout() {
        return (
            <>
                <Box>
                    <Grid container item xs={12} mb={3} sx={{justifyContent:'space-between'}}>
                        <Grid item xs>
                            <Typography level="h4" color="primary">
                                Commission Rates
                            </Typography>
                            <Typography level="h5" mt={1}>Manage, edit, and create new commission rate.</Typography>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={handleClickOpen}>Add commission rate</Button>
                        </Grid>
                    </Grid>
                    <CommissionRate data={ratelist} fetchRateList={fetchRateList}/>
                    <Formik
                        initialValues={{
                            pid: '',
                            amount: '',
                            description: '',
                            commission: '',
                            effective_from: dayjs(),
                            amountError: ''
                        }}
                        validationSchema={rateSchema}
                        onSubmit={async(values, errors) => {
                            if((values.description == "Refund" || values.description == "chargeback associated to sale") && parseFloat(values.commission) > 0) {
                                swal({
                                    title: "Failed",
                                    text: "Commission value for "+values.description + " should be negative",
                                    icon: "error",
                                    button: "Ok",
                                })
                                return false;
                            }
                            if((values.description == "Payment" || values.description == "3-Bureau Credit Report & Scores Payment") && parseFloat(values.commission) < 0) {
                                swal({
                                    title: "Failed",
                                    text: "Commission value for "+values.description + " should be postive",
                                    icon: "error",
                                    button: "Ok",
                                })
                                return false;
                            }
                            var commissionValue = parseFloat(values.commission);
                            var amountValue = parseFloat(values.amount);
                            if(amountValue < commissionValue) {
                            swal({
                                title: "Failed",
                                text: "Commission value should be less than amount",
                                icon: "error",
                                button: "Ok",
                            })
                            return false;
                            }
                            if(values.amountError != '') {
                                return false;
                            }
                            crateValues = values;
                            setOpen(false);
                            const apiUrl = '/check-commission-rate';
                            const args = {
                                pid: values.pid,
                                amount: values.amount,
                                description: values.description
                            }
                            const res = await dispatch(postData({ apiUrl, args })).unwrap();
                            if (res) {
                                if (res.success === false) {
                                    setRecords(res.duplicateRecords);
                                    setOpen1(true);
                                }else{
                                    createCommission();
                                }
                            }
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth={fullWidth}
                                maxWidth={maxWidth}
                            >
                                <DialogTitle id="alert-dialog-title" className="back">
                                    Add New Commission Rate
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <TextField
                                            id="pid"
                                            type="number"
                                            name='pid'
                                            label="PID"
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            onChange={handleChange('pid')}
                                            value={values.pid}
                                            onBlur={handleBlur('pid')}
                                        />
                                        {touched.pid && errors.pid ? <div className='error'>{errors.pid}</div> : null}
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <TextField
                                            id="amount"
                                            type="number"
                                            name="amount"
                                            label="Amount"
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            onChange={(e) => {
                                                handleChange(e);
                                                if(parseFloat(e.target.value) < 0) {
                                                    setFieldValue("amountError","Amount should be positive value");
                                                  }else{
                                                    setFieldValue("amountError",'');
                                                  }
                                            }}
                                            value={values.amount}
                                            onBlur={handleBlur('amount')}
                                        />
                                       {((touched.amount && errors.amount) || values.amountError ) ? <div className='error'>{errors.amount ? errors.amount : values.amountError}</div> : null}
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl fullWidth size='small' margin="dense">
                                            <InputLabel id="demo-multiple-name-label">Description</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                name='description'
                                                id='description'
                                                value={values.description}
                                                label="Description"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value="Payment" >Payment</MenuItem>
                                                <MenuItem value="Refund" >Refund</MenuItem>
                                                <MenuItem value="3-Bureau Credit Report & Scores Payment">3-Bureau Credit Report & Scores Payment</MenuItem>
                                                <MenuItem value="chargeback associated to sale" >chargeback associated to sale</MenuItem>
                                            </Select>
                                            {touched.description && errors.description ? <div className='error'>{errors.description}</div> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <TextField
                                            id="commission"
                                            type="number"
                                            name="commission"
                                            label="Commission"
                                            fullWidth
                                            size="small"
                                            margin="dense"
                                            onChange={handleChange('commission')}
                                            value={values.commission}
                                            onBlur={handleBlur('commission')}
                                        />
                                        {touched.commission && errors.commission ? <div className='error'>{errors.commission}</div> : null}
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker label="Effective From Date*" fullWidth size="small" defaultValue={dayjs()} name="effective_from" onChange={(effective_from) => { setFieldValue("effective_from", effective_from, true) }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        <FormHelperText>{touched.effective_from && errors.effective_from ? errors.effective_from : ''}</FormHelperText>
                                    </Grid>
                                </DialogContent>
                                <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button onClick={handleClose} variant="contained" color="primary" sx={{ minWidth: 200 }}>Cancel</Button>
                                    <Button onClick={handleSubmit} disabled={createDisabled} variant="contained" color="success" sx={{ minWidth: 200 }}>Add commission rate</Button>
                                </DialogActions>
                            </Dialog>
                        )}
                    </Formik>

                    <Dialog
                        open={open1}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                handleClose1();
                            }
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                    >
                        <DialogTitle id="alert-dialog-title" className="back">
                        Add/Edit Commission Rate
                        </DialogTitle>
                        <DialogContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
                            {/* <Typography>This Combination already exist. Do you want to edit existing one or add new one ?</Typography> */}
                            <Typography>Combination of provided PID, amount & description for add, available multiple times as listed below.Do you want to edit existing one OR create new one?</Typography>
                        </Grid>
                        <TableContainer component={Paper} >
                            <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                <TableCell><b>PID</b></TableCell>
                                <TableCell><b>Amount</b></TableCell>
                                <TableCell><b>Description</b></TableCell>
                                <TableCell><b>Commission</b></TableCell>
                                <TableCell><b>Effective From Date</b></TableCell>
                                <TableCell><b>Edit</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {records.map((record) => (
                                <TableRow
                                    key={record.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    {record.pid}
                                    </TableCell>
                                    <TableCell>{record.amount}</TableCell>
                                    <TableCell>{record.description}</TableCell>
                                    <TableCell>{record.commission}</TableCell>
                                    <TableCell>{`${record.effective_from ? Moment(record.effective_from).format('MM-DD-YYYY') : 'N/A'}`}</TableCell>
                                    <TableCell>
                                    <ModeEditOutlineOutlinedIcon
                                        onClick={() => { fetchCommissionRateDetails(`${record.id}`) }}
                                    />
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                        </DialogContent>
                        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                        <Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={createCommission}>Create New</Button>
                        <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={handleClose1}>Close</Button>
                        </DialogActions>
                    </Dialog>

                     {/* edit popup start here */}
                    <>

                        <Dialog
                        open={editOpen}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                handleEditClose();
                            }
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        >
                        <DialogTitle id="alert-dialog-title" className="back">
                            Edit Commission Rate
                        </DialogTitle>
                        <Formik
                            initialValues={RequestFormInitialValues}
                            validationSchema={rateSchema}
                            onSubmit={submitRequest}
                        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
                            <DialogContent>
                            <Grid container item xs>
                                <TextField type='hidden' name="rateId" value={values.rateId} sx={{ display: 'none' }} />
                                <TextField
                                id="pid"
                                type="number"
                                name='pid'
                                label="PID"
                                fullWidth
                                size="small"
                                margin="dense"
                                value={values.pid}
                                onChange={handleChange('pid')}
                                onBlur={handleBlur('pid')}
                                />
                                {touched.pid && errors.pid ? <div className='error'>{errors.pid}</div> : null}
                            </Grid>
                            <Grid container item xs mt={2}>
                                <TextField
                                id="amount"
                                name="amount"
                                type="number"
                                label="Amount"
                                fullWidth
                                size="small"
                                margin="dense"
                                value={values.amount}
                                onChange={(e) => {
                                    handleChange(e);
                                    if(parseFloat(e.target.value) < 0) {
                                        setFieldValue("amountError","Amount should be positive value");
                                      }else{
                                        setFieldValue("amountError",'');
                                      }
                                }}
                                onBlur={handleBlur('amount')}
                                />
                              {((touched.amount && errors.amount) || values.amountError ) ? <div className='error'>{errors.amount ? errors.amount : values.amountError}</div> : null}
                            </Grid>
                            <Grid container item xs mt={2}>
                                <FormControl fullWidth size='small'>
                                <InputLabel id="demo-multiple-name-label">Description</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    name='description'
                                    id='description'
                                    label="Description"
                                    value={values.description}
                                    onChange={handleChange('description')}
                                    onBlur={handleBlur('description')}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="Payment" >Payment</MenuItem>
                                    <MenuItem value="Refund" >Refund</MenuItem>
                                    <MenuItem value="3-Bureau Credit Report & Scores Payment">3-Bureau Credit Report & Scores Payment</MenuItem>
                                    <MenuItem value="chargeback associated to sale" >chargeback associated to sale</MenuItem>
                                </Select>
                                {touched.description && errors.description ? <div className='error'>{errors.description}</div> : null}
                                </FormControl>
                            </Grid>
                            <Grid container item xs mt={2}>
                                <TextField
                                id="commission"
                                name="commission"
                                type="number"
                                label="Commission"
                                fullWidth
                                size="small"
                                margin="dense"
                                value={values.commission}
                                onChange={handleChange('commission')}
                                onBlur={handleBlur('commission')}
                                />
                                {touched.commission && errors.commission ? <div className='error'>{errors.commission}</div> : null}
                            </Grid>
                            <Grid container item xs mt={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker label="Effective From Date*" fullWidth size="small" name="effective_from" defaultValue={dayjs(values.effective_from)}
                                    onChange={(effective_from) => { setFieldValue("effective_from", effective_from, true) }}
                                    />
                                </DemoContainer>
                                </LocalizationProvider>
                                <FormHelperText sx={{ color: "#d32f2f;" }}>{touched.effective_from && errors.effective_from ? errors.effective_from : ''}</FormHelperText>
                            </Grid>
                            </DialogContent>
                            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={handleEditClose}>Close</Button>
                            <Button variant="contained" disabled={editDisabled} color="success" sx={{ minWidth: 200 }} onClick={handleSubmit}>Save</Button>
                            </DialogActions>
                        </>)}
                        </Formik>
                        </Dialog>
                    </>
{/* Edit Popup start here 1 */}
                </Box>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={freez}
            onClick={handleFreezStop}
          >
            <Freez />
          </Backdrop>
        </div>
    );
}

export default CommissionRates;