import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Button, FormControl, Alert, Stack } from '@mui/material';
import { Typography, Card, CardOverflow, CardContent, Divider } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../Components/Auth/AuthUser';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../Components/Loader/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LogoEdit from '../../../Components/Cobrand/LogoEdit';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import InputMask from 'react-input-mask';
import PhoneNumberValidation from '../../../Components/Formats/PhoneNumberValidation';


let logoURL = require("../../../assets/images/uploadLogo.png");
let logoFlag = 0;
let allocatedPID = '';
let plans = '';

function CobrandReview(props) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { user } = AuthUser();
    const [basePlans, setBasePlans] = useState([]);
    const [aidDetails, setAidDetails] = useState([]);
    const [cobrandObj, setCobrandObj] = useState([]);
    const [updatedAttributes, setUpdatedAttributes] = useState([]);
    const [uploadLogo, setUploadLogo] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [formikValues, setFormikValues] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [checked, setChecked] = React.useState(false);
    const [sponsorCode, setSponsorCode] = React.useState(0);
    const [isChecked, setIsChecked] = useState(true);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [logoName, setLogoName] = useState('');
    const [cobrandPid, setCobrandPid]= useState('');

    const queryParameters = new URLSearchParams(window.location.search);
    let updateFlag = queryParameters.has('updateFlag') ? queryParameters.get('updateFlag') : 1;

    const navigate = useNavigate();
    const handlesponsored = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked == true) {
            setSponsorCode(1);
        } else {
            setSponsorCode(0);
        }

    };

    const setValue = (values) =>{
        setFormikValues(values);  
    }

    const handleClose = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setOpen(false);
    };
    const handleUploadLogo = (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setFormikValues(values);
        setUploadLogo(true);
    }
    const handleCloseUploadLogo = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setUploadLogo(false);
    }

    // API Call
    const getAidDetails = async () => {
        const apiUrl = '/fetchAid/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setAidDetails(res.data);
            setIsLoading(false);
        }
    }
   

    const getCobrandData = async () => {
        const apiUrl = '/reviewCobrandDetails/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success) {
                setCobrandObj(res.data);
                // setCobrandPid(res.data.pid);
                if (res.data.status != 'Requested') {
                    if (res.data.aidMemberCredential != 1) {                        
                        setIsChecked(false);
                    }
                }
                setBasePlans(res.data.planObj);
                if (res.data.updatedAttributes) {
                    setUpdatedAttributes(res.data.updatedAttributes);
                }
                if (res.data) {
                    if (res.data.company_logo) {
                        logoURL = config.BACKEND_URL + "images/cobrandLogo/" + res.data.company_logo;
                        logoFlag = res.data.company_logo ? 1 : 0;
                    }
                    // if(res.data.status == 'Requested' || res.data.updatedAttributes.includes('company_logo') ){
                    if(res.data.status == 'Requested' || res.data.status == 'PlanUpdated' || res.data.updatedAttributes.includes('company_logo') ){
                        setLogoName(res.data.company_logo);
                    }
                    if (res.data.selected_cobrand_plan) {
                        plans = res.data.selected_cobrand_plan;
                    }
                    const initialValues = {
                        companyName: res.data.company_name,
                        email: res.data.email ? res.data.email : '',
                        email2: res.data.email2 ? res.data.email2 : '',
                        contactName: res.data.name ? res.data.name : '',
                        phoneNo: res.data.phone ? PhoneNumberValidation(res.data.phone) : '',
                        timezone: '',
                        operationalHrs: res.data.operational_hrs ? res.data.operational_hrs : '',
                        subtitle: res.data.subtitle ? res.data.subtitle : '',
                        companyWebsite: res.data.website_url ? res.data.website_url : '',
                        //plan: res.data.requested_cobrand_plan ? res.data.requested_cobrand_plan.base_pid_plans_id : '',
                        specialMsg1: res.data.special_message_1 ? res.data.special_message_1 : '',
                        specialMsg2: res.data.special_message_2 ? res.data.special_message_2 : '',
                        logoFlag: logoFlag,
                        sponsorCode: sponsorCode,
                        memberCredentialsFlag: isChecked,
                    }
                    setFormikValues(initialValues);
                    allocatedPID = res.data.allocatedPid ? res.data.allocatedPid : '';
                    setCobrandPid(res.data.pid ? res.data.pid : '');
                }
            } else {
                localStorage.setItem('error', res.message);
                navigate('/cobrand/pending');
            }
        }
    }

    // API Call ends
    
    var editor;
    ClassicEditor
        .create(document.querySelector('#edit-reply-modal'))
        .then(editor => {
            editor = editor;
        })

    useEffect(() => {
        if(user.roles_id < 3){
            getAidDetails();
            getCobrandData();
        }else {
            navigate('/login');
        }
     
    }, [])

    const [editorData, handleChangeEditor] = useState('');

    const CobrandSchema = Yup.object().shape({
        companyName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter Company Name.'),
        contactName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter Contact Name.'),
        email: Yup.string()
            .email('Invalid Email Format.')
            // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/i, "Invalid email format.")
            .required('Please enter your Email Address.'),
        phoneNo: Yup.string()
            .min(14, 'Must be 10 digits.')
            .max(14, 'Must be 10 digits.')
            .required('Please enter your mobile number.'),
        plan: Yup.string().when("updateFlag", {
            is: (updateFlag) => updateFlag == 0,
            then: () => Yup.string().required('Please select your Plan.'),
        }),
        // plan: Yup.string()
        //             .required('Please select your Plan.'),
    });

    const handleLogoFlag = (flag) => {
        logoFlag = flag;
    }
    const handleLogoURL = (image) => {
        logoURL = image;
    }

    const handleLogoName = (name) => {
    }



    function ContentLayout() {
        return (
            <Box p={2}>
                {isLoading ? <Loader /> : <></>}
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
                <Grid xs={12}>
                    <Card variant='outlined'>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                            }}
                        >
                            <Typography level="h4" color="primary">Cobrand Review Form for AID - {aidDetails.aid} </Typography>
                        </CardOverflow>
                        <Formik
                            initialValues={formikValues}
                            validationSchema={CobrandSchema}
                            onSubmit={async (values, error) => {  
                                setValue(values);    
                                const loginUser = JSON.parse(localStorage.getItem('user'));
                                if(loginUser.roles_id >= 3 ) {
                                    navigate('/login');
                                    return false;
                                }                          

                                    let allNewPlanStr = '';
                                    let currentPlanStr = plans;
                                    let k = 1;
                                    // console.log("currentPlanStr",plans,currentPlanStr);
                                    if (currentPlanStr.length > 0) {
                                        currentPlanStr.map((row) => {
                                            let newPlanStr = '';
                                            let clonePid = '';
                                            let PID = '';
                                            PID = row.pid;
                                            clonePid = row.base_plan.base_pid;
                                            const newPlan = row.base_plan.plan_name.split(';');
                                            for (let i = 0; i < newPlan.length; i++) {
                                                if (newPlan[i] !== '') {
                                                    const planNameStr = newPlan[i].split('-');
                                                    const j = i + 1;
                                                    
                                                    if(clonePid == '20081' || clonePid == '20085'|| clonePid == '20086'){
                                                        newPlanStr += ` ${planNameStr[0]?.trim()} - ${planNameStr[1]?.trim()}, No Trial`;
                                                    }else{
                                                        if(planNameStr[1]){
                                                            newPlanStr += ` ${planNameStr[0]?.trim()} - ${planNameStr[1]?.trim()}`;
                                                        }else{
                                                            newPlanStr += ` ${planNameStr[0]?.trim()}`;
                                                        }
                                                    }
                                                }
                                            }
                                            allNewPlanStr ? 
                                                allNewPlanStr = allNewPlanStr + '<tr><td><b>Plan:</b> ' + k + ') ' + newPlanStr + (clonePid ? '  - (Clone of PID ' + clonePid + ')' : '' )+' <b>Allocated Pid:</b> ' + PID + '</td></tr>'
                                            : allNewPlanStr = '<tr><td><b>Plan:</b> '+ k + ') ' + newPlanStr + (clonePid ? '  - (Clone of PID ' + clonePid + ')' : '' )+' <b>Allocated Pid:</b> ' + PID + '</td></tr>'
                                            k++;
                                        })
                                        
                                    }

                                    if (allocatedPID) {
                                        var bodytext = 'Co-Brand PID <b>' + allocatedPID + '</b> which has been assigned to ' + values.companyName + '  <b>(AID:' + aidDetails.aid + ')</b>. Please update the PID with the following enclosed details and materials.';
                                    } else if (cobrandObj.status == 'Updated') {
                                        var bodytext = 'updated';
                                    } else {
                                        var bodytext = 'new';
                                    }

                                    var newMsg = '<p style="margin-top: 3%;margin-bottom: 3%;">Hello Strategic Sales,</p>' +
                                        '<p>Enclosed and attached are the materials for this ' + bodytext + '</p>' +
                                        '<p>Co-Brand for: <b>' + values.companyName + '</b></p>';

                                    newMsg += '<p>Please verify the details: </p>' +
                                        '<table tableWidth="100%" style="width:100%;border:none;">' +
                                        '<tr><td><b>Name:</b> ' + (values.contactName ? values.contactName : formikValues.contactName) + ' </td></tr>' +
                                        '<tr><td><b>Company Name:</b> ' + (values.companyName ? values.companyName : formikValues.companyName) + ' </td></tr>' +
                                        '<tr><td><b>Email Address 1:</b> ' + (values.email ? values.email : formikValues.email) + ' </td></tr>' +
                                        '<tr><td><b>Email Address 2:</b> ' + (values.email2 ? values.email2 : formikValues.email2) + ' </td></tr>' +
                                        '<tr><td><b>Phone No.:</b> ' + (values.phoneNo ? values.phoneNo : formikValues.phoneNo) + ' </td></tr>' +
                                        '<tr><td><b>Co-brand Sub-title:</b> ' + (values.subtitle ? values.subtitle : formikValues.subtitle) + ' </td></tr>' +
                                        '<tr><td><b>Company Website:</b> ' + (values.companyWebsite ? values.companyWebsite : formikValues.companyWebsite) + ' </td></tr>' +
                                        '<tr><td><b>Hours of Operation:</b> ' + (values.operationalHrs ? values.operationalHrs : formikValues.operationalHrs) + '</td></tr>' +
                                         allNewPlanStr +
                                        '<tr><td ><b>Special Msg1:</b> ' + (values.specialMsg1 ? values.specialMsg1 : formikValues.specialMsg1) + '</td></tr>' +
                                        '<tr><td><b>Special Msg2:</b> ' + (values.specialMsg2 ? values.specialMsg2 : formikValues.specialMsg2) + '</td></tr>' +
                                        '</table>';                     
                               
                                newMsg += '<p>Contact us with any questions!</p>' +
                                    '<p>Thank You!</p>' +
                                    '<p>'+(user ? user.first_name : "Admin" )+'</p>';

                                handleChangeEditor(newMsg);                         
                               
                                setIsLoading(false);
                                setOpen(true);                                

                            }}
                        >
                            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <CardContent>
                                    <Box p={5}
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, },
                                            '& .MuiSelect-root': { m: 1, },
                                        }}
                                    >
                                        <Grid container item lg={12} md={12} sm={12} xs={12}  spacing={2}>
                                            <Grid item lg={4} md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box >
                                                    <Card variant='outlined' size='md' sx={{ height: 150, width: 300 }} color={updatedAttributes.includes('company_logo') ? "warning" : ''}>
                                                        <CardContent sx={{ textAlign: 'center', justifyContent: 'center' }}>
                                                            <img className="dashlogo" style={{ height: '90px' }} src={logoURL} />
                                                        </CardContent>
                                                    </Card>
                                                    <Box py={2} sx={{ textAlign: 'center' }}>
                                                        <Button variant="contained" size="sm" color="success" onClick={()=>{handleUploadLogo(values)}}>Edit</Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12} >
                                                <TextField
                                                    id="companyName"
                                                    name="companyName"
                                                    type="text"
                                                    label="Company Name*"
                                                    value={values.companyName}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    helperText={touched.companyName && errors.companyName ? errors.companyName : null}
                                                    error={touched.companyName && errors.companyName ? errors.companyName : null}
                                                    // onChange={(e) => {
                                                    //     handledcompanyName(e.target.value);
                                                    //     handleChange(e);
                                                    // }}
                                                    onChange={handleChange('companyName')}
                                                    onBlur={handleBlur('companyName')}
                                                    color={updatedAttributes.includes('company_name') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('company_name') ? true : false}
                                                />
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Email Address*"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.email}
                                                    helperText={touched.email && errors.email ? errors.email : null}
                                                    error={touched.email && errors.email ? errors.email : null}
                                                    onChange={handleChange('email')}
                                                    onBlur={handleBlur('email')}
                                                    color={updatedAttributes.includes('email') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('email') ? true : false}
                                                />
                                                <InputMask
                                                    id="outlined-start-adornment"
                                                    mask='(999) 999-9999'
                                                    maskChar={null}
                                                    type="text"
                                                    label="Phone No*"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.phoneNo}
                                                    helperText={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                    error={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                    onChange={handleChange('phoneNo')}
                                                    onBlur={handleBlur('phoneNo')}
                                                    color={updatedAttributes.includes('phone') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('phone') ? true : false}
                                                >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label="Phone Number" variant="outlined"
                                                            {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Contact Name*"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.contactName}
                                                    helperText={touched.contactName && errors.contactName ? errors.contactName : null}
                                                    error={touched.contactName && errors.contactName ? errors.contactName : null}
                                                    onChange={handleChange('contactName')}
                                                    onBlur={handleBlur('contactName')}
                                                    color={updatedAttributes.includes('name') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('name') ? true : false}
                                                />
                                                {/* <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Email Address 2"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.email2}
                                                    onChange={handleChange('email2')}
                                                    onBlur={handleBlur('email2')}
                                                    color={updatedAttributes.includes('email2') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('email2') ? true : false}
                                                /> */}
                                                <TextField
                                                    id="companyWebsite"
                                                    type="text"
                                                    label="Company Website(URL)"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.companyWebsite}
                                                    onChange={handleChange('companyWebsite')}
                                                    onBlur={handleBlur('companyWebsite')}
                                                    color={updatedAttributes.includes('website_url') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('website_url') ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {
                                            cobrandObj.selected_cobrand_plan
                                                ?
                                                Object.values(cobrandObj.selected_cobrand_plan).length > 0
                                                    ?
                                                    <>
                                                        <Grid md={12} container item pt={2} pl={1} >
                                                            <Typography level="h5" >Current Plan Details</Typography>
                                                        </Grid>
                                                        <Grid md={12}  item pl={2} >
                                                            {                                                                
                                                                cobrandObj.selected_cobrand_plan.map((row, idx) => {
                                                                    return (
                                                                        <>
                                                                            <Typography>{idx + 1}. PID : {row.pid}, Plan : {row.base_plan.plan_name ? row.base_plan.plan_name : 'N/A'}</Typography>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }

                                        {/* {
                                            updateFlag == 1
                                                ?
                                                <>
                                                </>
                                                :
                                                <>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} pl={1}>
                                                        <Typography level="h5" >Requested Plan Details</Typography>
                                                    </Grid>
                                                    <Grid container item spacing={4} lg={12} md={12} sm={12} xs={12} pl={1}>
                                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                                            <FormControl fullWidth size='small' margin="dense"
                                                                error={touched.plan && errors.plan ? errors.plan : null}
                                                            >
                                                                <InputLabel id="demo-multiple-name-label">Plan</InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    disabled
                                                                    margin="dense"
                                                                    labelId="demo-simple-select-label"
                                                                    value={values.plan}
                                                                    onChange={handleChange('plan')}
                                                                    onBlur={handleBlur('plan')}
                                                                    id="plan"
                                                                    input={<OutlinedInput label="Plan" />}
                                                                    name="plan"
                                                                    helperText={touched.plan && errors.plan ? errors.plan : null}
                                                                    error={touched.plan && errors.plan ? errors.plan : null}
                                                                >
                                                                    {basePlans.map((row) => {
                                                                        return (
                                                                            <MenuItem value={row.id}>{row.plan_name}</MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>
                                                                {touched.plan && errors.plan ? <FormHelperText>Please select Plan.</FormHelperText> : null}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                                            <FormControlLabel control={<Checkbox checked={checked} />} label="Is Sponsored"
                                                                 onChange={(e) => {
                                                                    handlesponsored(e);
                                                                    setValue(values);
                                                                }}
                                                            //    onChange={
                                                            //         handlesponsored
                                                            //         setvalue();

                                                            //     }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                        } */}
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Co-Branding Sub-Title (E.G. Could Be Your Tag Line, Motto, Your Slogan)"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                value={values.subtitle}
                                                onChange={handleChange('subtitle')}
                                                color={updatedAttributes.includes('subtitle') ? "warning" : ''}
                                                focused={updatedAttributes.includes('subtitle') ? true : false}
                                            />
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="operational Hrs"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                value={values.operationalHrs}
                                                onChange={handleChange('operationalHrs')}
                                                onBlur={handleBlur('operationalHrs')}
                                                color={updatedAttributes.includes('operational_hrs') ? "warning" : ''}
                                                focused={updatedAttributes.includes('operational_hrs') ? true : false}
                                            />
                                        </Grid>

                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Special Messaging - Paragraph 1"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                value={values.specialMsg1}
                                                onChange={handleChange('specialMsg1')}
                                                onBlur={handleBlur('specialMsg1')}
                                                color={updatedAttributes.includes('special_message_1') ? "warning" : ''}
                                                focused={updatedAttributes.includes('special_message_1') ? true : false}
                                            />
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Special Messaging - Paragraph 2"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                value={values.specialMsg2}
                                                onChange={handleChange('specialMsg2')}
                                                onBlur={handleBlur('specialMsg2')}
                                                color={updatedAttributes.includes('special_message_2') ? "warning" : ''}
                                                focused={updatedAttributes.includes('special_message_2') ? true : false}
                                            />
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            {/* <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    id="memberCredentialsFlag"
                                                    name="memberCredentialsFlag"
                                                    checked={values.memberCredentialsFlag}
                                                    onChange={(e) => {
                                                        setFieldValue('memberCredentialsFlag',e.target.checked)
                                                    }}
                                                    value={values.memberCredentialsFlag} />}
                                                label="Enable Fetching Member Credentials"
                                                labelPlacement="start"
                                            /> */}
                                            {/* <label className="control-label" htmlFor="enable-member-credentials">Enable Fetching Member Credentials</label>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                    id="memberCredentialsFlag"
                                                    name="memberCredentialsFlag"
                                                    checked={isChecked}
                                                    onChange={(e) => {
                                                        setIsChecked(e.target.checked);
                                                        handleChange(e);
                                                    }}
                                                    value={isChecked}
                                                    className="custom-control-input"
                                                  />
                                                }
                                            /> */}
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                                            <Grid md={6} p={2} textAlign="center">
                                                <Button variant="contained" sx={{ minWidth: "60%" }} color="success" type="submit" size='lg' disabled={(isSubmitting)} onClick={handleSubmit}>Send Email to CD</Button>
                                            </Grid>
                                            <Grid md={6} p={2} textAlign="center">
                                                <Button variant="contained" sx={{ minWidth: "60%" }} color="primary" size='lg' onClick={() => { navigate('/cobrand/pending') }} >CLOSE</Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography level="h5" >Need Help?</Typography>&nbsp;
                                        <Typography level="h5">Call 1-888-548-2008</Typography>
                                    </Grid>
                                </CardContent>
                            )}
                        </Formik>

                    </Card>
                </Grid>

                {/* Email To CD */}
                <Formik
                    initialValues={{
                        // formValues: formikValues,
                        subject: allocatedPID ?
                                'Co-Brand Pre-Built PID - ' + allocatedPID + ' Update Request- Assigned to AID: ' + aidDetails.aid
                                    :
                                'Co-Brand Update for AID: ' + aidDetails.aid,
                        emailEditor: editorData,
                    }}
                    onSubmit={async (values, error) => {
                        const loginUser = JSON.parse(localStorage.getItem('user'));
                        if(loginUser.roles_id >= 3 ) {
                            navigate('/login');
                            return false;
                        }                          
                        setButtonDisabled(true);
                        setIsLoading(true);
                        handleClose();
                     
                        formikValues.memberCredentialsFlag = isChecked;
                        const args = {
                            formValues: formikValues,
                            subject: values.subject,
                            emailBody: values.emailEditor,
                            aidMasterId: id,
                            allocatedPID: allocatedPID,
                            sponsorCode: sponsorCode,
                            url: config.BASE_URL,
                            logoName: logoName,
                            cobrandPid : cobrandPid,
                        }
                       
                        const apiUrl = '/sendCobrandMailToCD';
                        const res = await dispatch(postData({ apiUrl, args })).unwrap();
                        if (res) {
                            if (res.success) {
                                localStorage.setItem('success', res.message)
                                navigate('/cobrand/pending');
                                setIsLoading(false);
                            } else {
                                setIsLoading(false);
                                setErrorMessage(res.message);
                                setTimeout(() => {
                                    setErrorMessage('');
                                }, 3000);
                                setButtonDisabled(false);
                            }
                        }
                    }}
                >
                    {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <Dialog
                            maxWidth='md'
                            disableEscapeKeyDown={true}
                            open={open}
                            onClose={(_, reason) => {
                                if (reason !== "backdropClick") {
                                    handleClose();
                                }
                            }}                            
                        >
                            <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
                                <Typography level="h4" color='primary'>Email to CD</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Grid py={2} container item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        id="outlined-start-adornment"
                                        type="text"
                                        label="Subject*"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={values.subject}
                                        helperText={touched.subject && errors.subject ? errors.subject : null}
                                        error={touched.subject && errors.subject ? errors.subject : null}
                                        onChange={handleChange('subject')}
                                        onBlur={handleBlur('subject')}
                                    />

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    Massage:<Button variant='contained' color='success' 
                                    onClick={(e) => {
                                        setFieldValue('emailEditor',editorData);
                                    }}
                                    >Reset Default Message</Button>
                                    <FormControl fullWidth size="small">
                                        <div style={{ overflow: 'auto', maxHeight: '25vw' }}>
                                        <CKEditor                                                                                    
                                            editor={ClassicEditor}                                         
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                // values.emailEditor = data;
                                                setFieldValue('emailEditor',data);
                                            }}                                            
                                            data={values.emailEditor}
                                            value={values.emailEditor}
                                            required
                                            name="emailEditor"
                                        />
                                        </div>
                                    </FormControl>                                    
                                </Grid>
                                {
                                    logoName ? 
                                        <Grid lg={12} md={12}  sx={{backgroundColor: '#ebebeb',border: '1px solid #c1c1c1',borderRadius: '0 0 5px 5px'}}>
                                            <Stack direction={"row"} >
                                                <Grid lg={4} md={4} sx={{padding: '2% 0px 0px 2%'}}><Typography>Attached logo: </Typography></Grid>
                                                <Grid lg={8} md={8}><img className="dashlogo" style={{ height: '3vw', padding: '4% 0px 0px 10%' }} src={logoURL} /></Grid>
                                            </Stack>
                                        </Grid>     
                                    :
                                    null 
                                }
                            </DialogContent>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button autoFocus variant="contained" sx={{ minWidth: 250 }} onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{ minWidth: 250 }} color="success" type="submit" onClick={handleSubmit} disabled={isButtonDisabled}>
                                            SEND
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    )}
                </Formik>
                {/* Email To CD Ends */}


                <Dialog
                    open={uploadLogo}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseUploadLogo();
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/* <LogoEditor aidMasterId={id} pathType="cobrand" /> */}
                    <LogoEdit id={id} logoEditURL={handleLogoURL} logoFlagEdit={handleLogoFlag} logoName={handleLogoName} setOpen={handleCloseUploadLogo} />
                </Dialog>
            </Box>
        )

    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}

export default CobrandReview;