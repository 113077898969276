import React, { useState, } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Alert, Grid, Button, Dialog, DialogTitle, CardActions, TextField, Typography } from '@mui/material';
import { CardContent, Card, CardOverflow, } from '@mui/joy';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, } from 'react-router';
import AuthUser from '../Auth/AuthUser';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { fetchData, postData } from '../../redux/apiSlice';
import swal from "sweetalert";
const RequestFormInitialValues = {
  email: '',
  OTP: '',
  companyId: '',
  emailId: ''
};
//boostrap dialog 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function ChangeEmailDialog({ companyId, company_name, email, communication_email, ModalClose1, onValueChange, fetchData }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const theme = useTheme();
  const [disableSendOTPButton, setdisableSendOTPButton] = useState(false);
  const { http, user } = AuthUser();
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openBounceEmailEdit, setOpenBounceEmailEdit] = React.useState(false);
  const [data, setData] = useState([]);
  const [Email, setEmail] = useState(email);
  const [isLoading, setIsLoading] = useState(true);
  const [isOTPFieldVisible, setisOTPFieldVisible] = useState(false);
  const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
  const [emailButton, setEmailButton] = useState(false);

  RequestFormInitialValues.emailId = communication_email;
  RequestFormInitialValues.company_name = company_name;
  RequestFormInitialValues.email = email;
  RequestFormInitialValues.companyId = companyId;
  const setErrorMessageFun = (successMessage, errorMessage) => {
    setErrorMessage(errorMessage)
    setSuccessMessage(successMessage)
    setTimeout(function () {
      setSuccessMessage('')
      setErrorMessage('')
    }, 5000);

  }
  const fetchBounceEmails = async (page) => {
    // setFreez(true);
    http.get('/getBounceEmails/pending', {
    }).then((res) => {
      setData(res.data.data)
      setIsLoading(false)
      // handleFreezStop();
    }).catch((error) => {
      setErrorMessage(" went wrong.")
      navigate('/login');
      // handleFreezStop();
    });
  }
  const handleClickOpen1 = (company_name, companyId, email, communication_email) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
    }
    setisOTPFieldVisible(false)
    RequestFormInitialValues.companyId = companyId;
    RequestFormInitialValues.company_name = company_name;
    RequestFormInitialValues.email = email;
    RequestFormInitialValues.communication_email = communication_email;
    RequestFormInitialValues.OTP = "";
    setOpenBounceEmailEdit(true);
  };

  const handleClose1 = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
    }
    setOpenBounceEmailEdit(false);
  };
  const RequestFormSchema = Yup.object().shape({
    emailId: Yup.string()
      // .email('Invalid email format.')
      .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
      .required('Please enter email'),
  });

  RequestFormInitialValues.emailId = communication_email;
  RequestFormInitialValues.OTP = '';
  const submitRequest = async (values) => {
    // console.log("values",values);
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id > 3) {
      navigate('/login');
      return false;
    }
    setdisableSendOTPButton(true)
    RequestFormInitialValues.email = Email;
    RequestFormInitialValues.companyId = companyId;
    RequestFormInitialValues.emailId = values.emailId;
    RequestFormInitialValues.OTP = values.OTP;
    const apiUrl = '/sendOTPtobounceEmail';
    const args = {
      companyId: companyId,
      email: values.emailId,
      OTP: values.OTP,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == false) {
      onValueChange('', res.message)
      setdisableSendOTPButton(false)
      setErrorMessage(res.message)
      setTimeout(function () {
        setErrorMessage('')
      }, 5000);
    } else {
      if (res.changedMail == true) {
        RequestFormInitialValues.companyId = '';
        RequestFormInitialValues.email = '';
        RequestFormInitialValues.emailId = '';
        RequestFormInitialValues.OTP = '';
        onValueChange(res.message, '')
        //add swal
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        ModalClose1();
        fetchData();

      } else {
        var textField = document.getElementById("emailId");
        textField.readOnly = true;
        textField.style.backgroundColor = "#f0f0f0";
        setisOTPFieldVisible(true)
        setdisableSendOTPButton(false)
        setSuccessMessage(res.message)
        setTimeout(function () {
          setSuccessMessage('')
        }, 5000);
      }
    }
  }

  const checkUsaTLD = async(email) => {
    if (email.includes('@')) {
    setEmailButton(true);
    const apiUrl = '/checkUsaTLD';
    const args = {
        email: email,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
        if (res.success === true) {
            setCheckUsaTldStatus(true);
        } else {
            setCheckUsaTldStatus(false);
        }
    }
    setEmailButton(false);
    }
  }

  return (
    <>
      <Formik
        initialValues={RequestFormInitialValues}
        validationSchema={RequestFormSchema}
        onSubmit={submitRequest}
      >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <>
          {/* <Card> */}
          <DialogTitle id="alert-dialog-title" sx={{
                bgcolor: '#F6FAFF',
                borderColor: '#F6FAFF',
                padding: '1%',
              }}>
              
              {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
              {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography color="primary" variant='h5'><b>Update communication email</b></Typography>
              </Grid>
            </DialogTitle>
            <CardContent mt={3}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Typography variant="h6" gutterBottom>Company Name:
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <Typography variant="h6" gutterBottom><b>{RequestFormInitialValues.company_name}</b></Typography>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Typography variant="h6" gutterBottom>Registered Email:
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <Typography variant="h6" gutterBottom><b>{RequestFormInitialValues.email}</b></Typography>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12} style={{ alignItems: "center" }}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Typography variant="h6" gutterBottom>Communication Email:</Typography>
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  {/* <FormControl size="small" margin="dense"> */}
                  <TextField

                    style={{ width: "100%" }}
                    required
                    margin="dense"
                    aria-label="minimum height"
                    placeholder="Enter email"
                    name="Enter the email"
                    id="emailId"
                    size="small"
                    value={values.emailId}
                    onChange={(e)=>{
                      checkUsaTLD(e.target.value);   
                      handleChange('emailId');
                      setFieldValue('emailId', e.target.value, true)
                    }}

                    onBlur={handleBlur('emailId')}
                  />
                  <div className='error'>{touched.emailId && errors.emailId ?errors.emailId : checkUsaTldStatus ? "Invalid email format." : '' }</div> 
                  {/* </FormControl> */}
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                {isOTPFieldVisible ?
                  <Grid container item lg={12} md={12} sm={12} xs={12} style={{ alignItems: "center" }}>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography variant="h6" gutterBottom>Enter OTP: </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <TextField
                        style={{ width: "100%" }}
                        required
                        margin="dense"
                        aria-label="minimum height"
                        placeholder="Enter OTP"
                        name="Enter the OTP"
                        id="OTP"
                        size="small"
                        value={values.OTP}
                        onChange={handleChange('OTP')}
                        onBlur={handleBlur('OTP')}
                      />
                      {touched.OTP && errors.OTP ? <div className='error'>{errors.OTP}</div> : null}
                     
                    </Grid>
                  </Grid>
                  : ''}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                  {!isOTPFieldVisible ?
                    <Button variant="contained" sx={{ minWidth: isMobile ? 100 : 150 }} onClick={ModalClose1}>
                      Cancel
                    </Button>
                    :
                    ''}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                  {!isOTPFieldVisible ?
                    <Button variant="contained" sx={{ minWidth: isMobile ? 100 : 150 }} color="success" disabled={disableSendOTPButton} onClick={disableSendOTPButton === false && emailButton === false && checkUsaTldStatus == false? handleSubmit : null}>
                      SEND OTP
                    </Button>
                    :
                    <Button variant="contained" sx={{ minWidth: isMobile ? 100 : 150 }} color="success" disabled={disableSendOTPButton} onClick={disableSendOTPButton === false ? handleSubmit : null}>
                      Verify OTP
                    </Button>
                  }
                </Grid>
              </Grid>
            </CardActions>
          {/* </Card> */}
        </>
      )}
      </Formik>
    </>


  )
}
export default ChangeEmailDialog;