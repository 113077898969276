import React, { useState, useEffect  } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import SummaryStatistics from '../../../Components/CallHistory/SummaryStatistics';
import CallStatusDistribution from '../../../Components/CallHistory/CallStatusDistribution';
import CallDurationAnalysis from '../../../Components/CallHistory/CallDurationAnalysis';
import CallHistory from '../../../Components/CallHistory/CallHistory';
import { Button, Grid, FormControl, Select, MenuItem, InputLabel, OutlinedInput
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/joy';
import { fetchData,postData } from '../../../redux/apiSlice';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import { useNavigate } from 'react-router-dom';
import { format, subDays } from 'date-fns';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import AuthUser from '../../../Components/Auth/AuthUser';
import { MenuProps } from "../Dashboard/utils";
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';

const CallHistoryDashboard = () => {
    const dispatch = useDispatch();
    const { user } = AuthUser();
    const navigate = useNavigate();
  const [selectedCaller, setSelectedCaller] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [agentId, setAgentId] = useState('all');
  const [adminList, setAdminList] = React.useState([]);
  const [filterCompany, setData] = useState([]);
  const [freez, setFreez] = React.useState(false);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  

  const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
  const sideBar = calendarFilter();
  const staticRanges = [
      // ...defaultStaticRanges,
      ...createStaticRanges(sideBar)
  ];

  const handleChangeAdmin = (e) => {
    setAgentId(e.target.value);
    
  };

  const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const eventSource = new EventSource('http://54.193.66.206/newmfsn/backend/api/callHippoWebhook');
        eventSource.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
         setShowAlert(true);   
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, []);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };




  const fetchCallHistory = async page => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id != 1 && loginUser.roles_id != 2 && loginUser.roles_id != 6)
     {
      navigate('/login');
      return false;
    }
    setErrorMessage("")
    setIsLoading(true)

    const apiUrl = '/callhistory';
    const args = {
      fromDate: startDate,
      toDate: endDate,
      user:agentId
    }
    handleFreezStart();
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      setData(res.data.data.callLogs)
      setIsLoading(false);
      handleFreezStop();
    } else {
      setErrorMessage(" went wrong.")
      setIsLoading(false)
    }
  }
  const fetchAdminList = async () => {
    const apiUrl = '/getAgentName';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      console.log(res.data.data);
      setAdminList(res.data.data);
    
    }
  };
  useEffect(() => {
    if (user.roles_id < 3 || user.roles_id == 6) {
      fetchCallHistory();
      fetchAdminList();
    } else {
      navigate('/login');
    }
  }, []);


  const ContentLayout=
  <>
    <div>
      <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
          <Grid item xs>
            <Typography level="h4" color="primary">Call History Dashboard</Typography>
          </Grid>
         
      </Grid>

      {showAlert && (
                <div className="alert">
                    <p>Incoming call or SMS received!</p>
                    <button onClick={handleCloseAlert}>Close</button>
                </div>
            )}
      
      <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} spacing={2}>
          <Grid item xs>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value="date"
                input={<OutlinedInput label="Select Date Range" />}
              >
                <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                <DateRangePicker
                  className='custom-date-picker'
                  onChange={item => setSelectionRange([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectionRange}
                  direction="horizontal"
                  maxDate={new Date()}
                  staticRanges={staticRanges}
                />
                 
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Select Agent</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="agent"
                name="agent"
                value={agentId}
                label="Select Agent"
                MenuProps={MenuProps}
                onChange={(e) => { handleChangeAdmin(e) }}
              >
                <MenuItem
                  value='all'
                >
                  All
                </MenuItem>
                {adminList.map((row, idx) => (
                  <MenuItem key={row.id} value={row.id}>{row.fullName}</MenuItem> // Ensure each MenuItem has a unique key
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs >
            <Button color="primary" onClick={fetchCallHistory} variant="contained" sx={{ minWidth: 150 }}>
              Submit
            </Button>
          </Grid>
        </Grid>
        <>
           <SummaryStatistics callLogs={filterCompany} />
          <CallStatusDistribution callLogs={filterCompany} />
          <CallDurationAnalysis callLogs={filterCompany} />
          <CallHistory callLogs={filterCompany} />
          
        </>

    </div>
    </>

    return (
    <div>
      <DefaultLayout content={ContentLayout} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </div>
  );
 
};

export default CallHistoryDashboard;
