
import React from 'react';
import Table from '@mui/joy/Table';
import { Box, Grid,FormControl,Dialog ,DialogActions, DialogContentText ,DialogTitle ,InputLabel, Button ,
    DialogContent ,TextField,  } from '@mui/material';
import { Typography,Card,CardContent,CardOverflow } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextareaAutosize from '@mui/base/TextareaAutosize';
function BankDetailsPanel(props) {
    const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    
    function ContentLayout() {
    return (
        <>
        <Box>
            <Card variant='outlined'>
                <CardOverflow
                    sx={{
                        bgcolor:'#F6FAFF',
                        borderColor:'#F6FAFF',
                        padding: '1%',
                        justifyContent:"space-between",                            
                    }}
                >
                    <Grid container  item  sx={{justifyContent:'space-between'}}> 
                        <Typography level="h4" color="primary">Bank Details Panel</Typography>
                        <EditOutlinedIcon   onClick={handleClickOpen} />
                    </Grid>
                </CardOverflow>
                <CardContent>
                    <Table borderAxis="none" variant="plain" stripe="odd" >
                        <thead>
                            <tr>
                                <th>Bank Name</th>
                                <th>Name on Account</th>
                                <th>Bank Routing (ABA/SCH) Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Test Bank Name</td>
                                <td>Test Bank Test</td>
                                <td>123456789</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table borderAxis="none" variant="plain" stripe="odd" >
                        <thead>
                            <tr>
                                <th>Account No.</th>
                                <th>Account Type</th>
                                <th>Account Category </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>123456789</td>
                                <td>Checking</td>
                                <td>Personal</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table borderAxis="none" variant="plain" stripe="odd" >
                        <thead>
                            <tr>
                                <th>Email Address to notify of Payment Transfers</th>
                                <th>ACH Info added to bank?</th>
                                <th>Last ACH Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>dfd@yopmail.com</td>
                                <td>Yes</td>
                                <td>4/17/2022</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardContent>
            </Card>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{backgroundColor:"#F6FAFF"}}>
                    Report Banking Issue
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <FormControl fullWidth>
                            <TextareaAutosize
                            margin="dense"
                            aria-label="minimum height"
                            minRows={5}
                            placeholder="Report Issue"
                            fullWidth
                            sx={{borderColor:"#D3D3D3"}}
                                />
                            </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <InputLabel htmlFor="bootstrap-input">Send Mail to the Affiliate ↓ </InputLabel>
                            <TextField
                                fullWidth
                                id="outlined-error-helper-text"
                                label="Email Subject"
                                size="small"
                                />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <Grid container  item md={12} >
                            <Grid item>
                                <InputLabel htmlFor="bootstrap-input">Massage:</InputLabel>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color="success" >
                                    RESET DEFAULT MESSAGE
                                </Button>
                            </Grid>
                        </Grid>
                        <CKEditor editor={ ClassicEditor } data="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" />
                    </Grid>
                </DialogContentText>
                </DialogContent>
                    <DialogActions>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"center",width:"100%"}}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button  variant="contained" sx={{minWidth:150}} onClick={handleClose}>
                                    CLOSE
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" sx={{minWidth:150}} color="success">
                                    SAVE & SEND EMAIL
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
            </Dialog>
        </Box>
        </>
        );
    }
     
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}
    
export default BankDetailsPanel;