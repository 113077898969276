import React, { useMemo, useState,useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Typography, Grid, Button, DialogContent, DialogActions, DialogContentText, Dialog, DialogTitle,Tooltip } from '@mui/material';
import formatPhoneNumber from '../Formats/PhoneNumberValidation';
import config from '../../AppConfig';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { fetchData, postData } from '../../redux/apiSlice';
import Loader from '../../Components/Loader/Loader';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PropTypes from 'prop-types';
import swal from "sweetalert";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ApiUser(props) {
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [disableUserId, setDisableUserId] = useState();
  const [operation, setOperation] = useState("");
  

  const checkAdminoRAffiliate = () => {
    var check = 1;
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id != 3 && loginUser.roles_id != 9) {
      check = 0;
    }
    if (check == 0) {
      navigate('/login');
      return false;
    }
    return true;
  }

  const createUser = () => {
    var check = checkAdminoRAffiliate();
    if (check === true) {
      var userRole = JSON.parse(localStorage.getItem('user')).roles_id;
      var roleId = userRole == 3 ? 11 : 14 ;
      navigate(`/createuser?roleId=${roleId}`);
    }
  }

  useEffect(() => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id === 3 || loginUser.roles_id === 9) {
        getApiUser();
    } else {
        navigate('/login');
    }

  }, [])

  const getApiUser = async () => {

    setIsLoading(true)
    const apiUrl = '/fetch-api-user';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if(res.success == true){
          setData(res.data);
      }
    }
    setIsLoading(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const editUser = (id,companyId) => {
    var check = checkAdminoRAffiliate();
    if(check === true) {
      const loginUser = JSON.parse(localStorage.getItem('user'));
        navigate('/edituser/' + id + '?source=automation');
    }
  }

  const changeUserStatus = async (id, role, operation,companyId) => {
    
    var check = checkAdminoRAffiliate();
    if(check === true) {
      if (operation == "disable") {
        setOperation("disabled");
      } else {
        setOperation("active");
      }
      setDisableUserId(id);
      
      setOpen(true);
    }
  }

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Software API Users',
    headers: ['Name', 'Email', 'Phone No.'],
  };
  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows  = (rows) => {
    
    if(localStorage.getItem("admin") != null) {
      navigate('/login');
      return false;
    }
    const exportedData = rows.map((row) => {
      return {
        name: row.original.name || "N/A",
        email: row.original.email || "N/A",
        phone: formatPhoneNumber(row.original.phone_no) || "N/A",
      };
    });
    
      // Convert data to CSV format
      const csvContent = convertToCSV(exportedData);
  
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const loginUser = JSON.parse(localStorage.getItem('user'));
      
      link.download = loginUser.roles_id == 9 ? (csvOptions.fileName + '.csv') : "API Users.csv";
  
      // Append the link to the document body
      document.body.appendChild(link);
  
      // Programmatically click the link to trigger the file download
      link.click();
  
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
    };
  
    const convertToCSV = (data) => {
      const headers = csvOptions.headers.join(',') + '\n';
  
      const rows = data.map((row) => {
        return Object.values(row).map((value) => {
          // Handle any necessary formatting or escaping of values
          // For simplicity, we assume all values are already properly formatted
  
          // Wrap the value with double quotes if it contains a comma
          if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
          }
  
          return value;
        }).join(',');
      }).join('\n');
  
      return headers + rows;
    };

  const enableDisableUser = async () => {
    handleClose();
    var check = checkAdminoRAffiliate();
    if(check === true){
      const apiUrl = '/user-softdelete';
      const args = {
        id: disableUserId,
        requestType: operation,
      }
  
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if (res) {
        if (res.success === true) {
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            button: "Ok",
          })
        } else {
          swal({
            title: "Failed",
            text: res.message,
            icon: "error",
            button: "Ok",
          })
        }
      }
      getApiUser();
    }
   
  }

  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.name ? data.name : 'N/A'}`,
        id: 'Name',
        header: 'Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{row.original.name ? row.original.name : 'N/A'}</Typography>
        ),
      },
      {
        accessorFn: (data) => `${data.email ? data.email : ''}`,
        id: 'Email',
        header: 'Email',
        size: 50,
        Cell: ({ renderedCellValue, row }) =>
          <Box className="datatable">
            <>{row.original.email ? row.original.email : 'N/A'}</>
          </Box>,
      },
      {
        accessorFn: (data) => `${data.phone_no ? data.phone_no ? formatPhoneNumber(data.phone_no) : '' : ''}`,
        id: 'Phone No',
        header: 'Phone No',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (<>{row.original.phone_no ? formatPhoneNumber(row.original.phone_no) : 'N/A' }</>),
      },
      {
        accessorFn: (row) => `${row.id}`,
        id: 'actions',
        header: 'Actions',
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            {
             
                row.original.is_active ?
                  <>
                    <Tooltip title="Edit User" arrow><EditOutlinedIcon onClick={() => {editUser(renderedCellValue,row.original.company_master_id)}} /></Tooltip>
                    <Tooltip title="Deactivate" arrow>
                      <PersonRemoveOutlinedIcon
                        onClick={() => changeUserStatus(row.original.id, row.original.roles_id, 'disable',row.original.company_master_id != null ? row.original.company_master_id : null)}
                      />
                    </Tooltip>
                  </> 
                : 
                <Tooltip title="Activate" arrow>
                  <PersonAddAltIcon sx={{ ml: 3 }}
                    onClick={() => changeUserStatus(row.original.id, row.original.roles_id, 'active',row.original.company_master_id != null ? row.original.company_master_id : null)}
                  />
                </Tooltip>
              
            }
          </Box>
        ),

      },
    ]
  );
  return (
    <>
       {isLoading ? <Loader /> : <></>}
      <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ display: "flex", justifyContent: "right" }} mb={3}>
        <Button color="primary" variant="contained" onClick={createUser}>
          Create User
        </Button>
      </Grid>
      <MaterialReactTable
        columns={columns}
        data={data}
        globalFilterFn="customSearchFilterFn"//custom search function
        enableColumnFilterModes
        enableRowSelection={false}
        getRowId={(data) => data.id}
        // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ columnVisibility }} //pass our managed row selection state to the table to use
        initialState={{ showColumnFilters: false, density: 'compact' }}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        enableColumnFilters={config.DatatableColumnFilter}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            backgroundColor: "#F6FAFF",
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              sx={{ bgColor: "#2A68B3" }}
              disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
              className='export'
            >
              CSV
            </Button>
          </Box>
        )}
      />

      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography level="h3">{operation == "disabled" ? "Deactivate" : "Activate"} User</Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "black" }}>
            Are you sure you want to {operation == "disabled" ? "deactivate" : "activate"} this user?</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" sx={{ minWidth: 150 }} onClick={() => { handleClose() }}>
            No
          </Button>
          <Button variant="contained" color='success' sx={{ minWidth: 150 }} onClick={() => { enableDisableUser(); }}>
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );

}


export default ApiUser;