import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, TextField, InputLabel, Button, FormControl, Alert, AlertTitle, Stack } from '@mui/material';
import { Typography, Card, CardOverflow, CardContent, Divider, Option } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Dialog, DialogActions, DialogContent, FormHelperText, DialogTitle, OutlinedInput, MenuItem, Select, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LogoEditor from '../CreditSnapshot/LogoEditor';
import AuthUser from '../../../Components/Auth/AuthUser';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../../../Components/Loader/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LogoEdit from '../../../Components/Cobrand/LogoEdit';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import InputMask from 'react-input-mask';
import PhoneNumberValidation from '../../../Components/Formats/PhoneNumberValidation';


let logoURL = require("../../../assets/images/uploadLogo.png");
let logoFlag = 0;
let allocatedPID = 0;
let planName = '';
let clonePid = '';
let flag = '';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {

    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

function CobrandVerify(props) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { user } = AuthUser();
    const [basePlans, setBasePlans] = useState([]);
    const [aidDetails, setAidDetails] = useState([]);
    const [cobrandObj, setCobrandObj] = useState([]);
    const [updatedAttributes, setUpdatedAttributes] = useState([]);
    const [uploadLogo, setUploadLogo] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [pidStatus, setPidStatus] = React.useState(0);
    const [pidAvailbleStatus, setPidAvailbleStatus] = React.useState('');
    const [formikValues, setFormikValues] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [checked, setChecked] = React.useState(false);
    const [sponsorCode, setSponsorCode] = React.useState(0);
    const [isChecked, setIsChecked] = useState(true);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [logoName, setLogoName] = useState('');
    const [cobrandPid, setCobrandPid]= useState([]);
    const [onlyVerify, setonlyVerify] = useState(false);
// console.log(formikValues['companyName'])

    const queryParameters = new URLSearchParams(window.location.search);
    let updateFlag = queryParameters.has('updateFlag') ? queryParameters.get('updateFlag') : 0;
    let plans = cobrandObj.requested_cobrand_plan ? cobrandObj.requested_cobrand_plan.base_pid_plans_id : '';
    let checkUsaTldStatus = false;
    let checkUsaTldAddress2Status = false;

    const navigate = useNavigate();
    const handlesponsored = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked == true) {
            setSponsorCode(1);
        } else {
            setSponsorCode(0);
        }

    };

    const setValue = (values) =>{
        setFormikValues(values);  
    }

    const handleClose = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        // setIsLoading(true)
        setOpen(false);
        const apiUrl = '/inactiveDefaultPid';
        const args = {
            allocatedPID: allocatedPID
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success) {
                allocatedPID = 0;
                setPidStatus(0);
                setPidAvailbleStatus('');
                // setIsLoading(false);
            }
        }
    };
    const handleUploadLogo = (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setFormikValues(values);
        setUploadLogo(true);
    }
    const handleCloseUploadLogo = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setUploadLogo(false);
    }

    // API Call
    const getAidDetails = async () => {
        const apiUrl = '/fetchAid/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setAidDetails(res.data);
            setIsLoading(false);
        }
    }
   

    const getCobrandData = async () => {
        const apiUrl = '/cobrandVerify/show/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success) {
                setCobrandObj(res.data);
                setCobrandPid(res.data.pid);
                if (res.data.status != 'Requested') {
                    if (res.data.aidMemberCredential != 1) {                        
                        setIsChecked(false);
                    }
                }
                setBasePlans(res.data.planObj);
                if (res.data.updatedAttributes) {
                    setUpdatedAttributes(res.data.updatedAttributes);
                }
                if (res.data) {
                    if (res.data.company_logo) {
                        logoURL = config.BACKEND_URL + "images/cobrandLogo/" + res.data.company_logo;
                        logoFlag = res.data.company_logo ? 1 : 0;
                    }
                    // if(res.data.status == 'Requested' || res.data.updatedAttributes.includes('company_logo') ){
                    if(res.data.status == 'Requested' || res.data.status == 'PlanUpdated' || res.data.updatedAttributes.includes('company_logo') ){
                        setLogoName(res.data.company_logo);
                    }
                    if (res.data.requested_cobrand_plan) {
                        planName = res.data.requested_cobrand_plan.base_plan.plan_name;
                        clonePid = res.data.requested_cobrand_plan.base_plan.base_pid;
                    }
                    const initialValues = {
                        companyName: res.data.company_name,
                        email: res.data.email ? res.data.email : '',
                        email2: res.data.email2 ? res.data.email2 : '',
                        contactName: res.data.name ? res.data.name : '',
                        phoneNo: res.data.phone ? PhoneNumberValidation(res.data.phone) : '',
                        timezone: '',
                        operationalHrs: res.data.operational_hrs ? res.data.operational_hrs : '',
                        subtitle: res.data.subtitle ? res.data.subtitle : '',
                        companyWebsite: res.data.website_url ? res.data.website_url : '',
                        plan: res.data.requested_cobrand_plan ? res.data.requested_cobrand_plan.base_pid_plans_id : '',
                        specialMsg1: res.data.special_message_1 ? res.data.special_message_1 : '',
                        specialMsg2: res.data.special_message_2 ? res.data.special_message_2 : '',
                        logoFlag: logoFlag,
                        sponsorCode: sponsorCode,
                        memberCredentialsFlag: isChecked,
                    }
                    setFormikValues(initialValues);
                    setonlyVerify(res.data.onlyVerify);
                }
            } else {
                localStorage.setItem('error', res.message);
                navigate('/cobrand/pending');
            }
        }
    }

    const checkAvailablePID = async (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        if(checkUsaTldStatus == true || checkUsaTldAddress2Status == true){
            return false;
        }        
        setIsLoading(true)
      
        // console.log(formikValues)
        const apiUrl = '/checkAvailablePID/' + values.plan + '/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success) {
                setFormikValues(values)
                setPidStatus(1);
                allocatedPID = res.data;
                setPidAvailbleStatus(res.data + ' PID has been allocated.');
                setonlyVerify(res.onlyVerify);
                setIsLoading(false)
            } else {
                setPidStatus(2);
                setPidAvailbleStatus(res.message);
                setIsLoading(false)
            }
        }
    }

    const verifyCobrand = async (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        if(checkUsaTldStatus == true || checkUsaTldAddress2Status == true){
            return false;
        }
        setButtonDisabled(true);
        setIsLoading(true);
        handleClose();

        console.log(formikValues);
        const args = {
            formValues: formikValues,
            subject: values.subject,
            emailBody: values.emailEditor,
            aidMasterId: id,
            allocatedPID: allocatedPID,
            sponsorCode: sponsorCode,
            url: config.BASE_URL,
            logoName: logoName,
            onlyVerify:onlyVerify
        }

        const apiUrl = '/verifyCobrand';
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success) {
                localStorage.setItem('success', res.message)
                navigate('/cobrand/all' );
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setErrorMessage(res.message);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
                setButtonDisabled(false);
            }
        }
    }

    // API Call ends
    
    var editor;
    ClassicEditor
        .create(document.querySelector('#edit-reply-modal'))
        .then(editor => {
            editor = editor;
        })

    useEffect(() => {
        if(user.roles_id < 3){
            getAidDetails();
            getCobrandData();
        }else {
            navigate('/login');
        }
     
    }, [])

    const [editorData, handleChangeEditor] = useState('');

    const CobrandSchema = Yup.object().shape({
        companyName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter Company Name.'),
        contactName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter Contact Name.'),
        email: Yup.string()
            .email('Invalid Email Format.')
            // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Please enter your Email Address.'),
        phoneNo: Yup.string()
            .min(14, 'Must be 10 digits.')
            .max(14, 'Must be 10 digits.')
            .required('Please enter your mobile number.'),
        plan: Yup.string().when("updateFlag", {
            is: (updateFlag) => updateFlag == 0,
            then: () => Yup.string().required('Please select your Plan.'),
        }),
        // plan: Yup.string()
        //             .required('Please select your Plan.'),
    });

    const checkUsaTLD = async(email,callTime) => {
        if(email == '' && callTime == 2){
            checkUsaTldAddress2Status = false;
            return false;
        }
        var checkpattern = String(email)
        .match(
            /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/
        );
        if(checkpattern == null && callTime == 2) {
            checkUsaTldAddress2Status = true; 
        }
        if (email.includes('@')) {
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    if(callTime == 1) {
                        checkUsaTldStatus = true;
                    }else{
                        checkUsaTldAddress2Status = true; 
                    }
                    
                } else {
                    if(callTime == 1) {
                        checkUsaTldStatus =false;
                    }else{
                        checkUsaTldAddress2Status = false; 
                    }
                }
            }
        }
    }

    const handleLogoFlag = (flag) => {
        logoFlag = flag;
    }
    const handleLogoURL = (image) => {
        logoURL = image;
    }

    const handleLogoName = (name) => {
    }



    function ContentLayout() {
        return (
            <Box p={2}>
                {isLoading ? <Loader /> : <></>}
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
                <Grid xs={12}>
                    <Card variant='outlined'>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                            }}
                        >
                            <Typography level="h4" color="primary">Cobrand Verify Form for AID - {aidDetails.aid} </Typography>
                        </CardOverflow>
                        <Formik
                            initialValues={formikValues}
                            validationSchema={CobrandSchema}
                            onSubmit={async (values, error) => {      
                                const loginUser = JSON.parse(localStorage.getItem('user'));
                                if(loginUser.roles_id >= 3 ) {
                                    navigate('/login');
                                    return false;
                                }
                                if(checkUsaTldStatus == true || checkUsaTldAddress2Status == true){
                                    return false;
                                }
                                // setIsLoading(true);
                                if (allocatedPID) {
                                    var bodytext = 'Co-Brand PID <b>' + allocatedPID + '</b> which has been assigned to ' + values.companyName + '  <b>(AID:' + aidDetails.aid + ')</b>. Please update the PID with the following enclosed details and materials.';
                                } else if (cobrandObj.status == 'Updated') {
                                    var bodytext = 'updated';
                                } else {
                                    var bodytext = 'new';
                                   

                                }

                                var newMsg = '<p style="margin-top: 3%;margin-bottom: 3%;">Hello Strategic Sales,</p>' +
                                    '<p>Enclosed and attached are the materials for this ' + bodytext + '</p>' +
                                    '<p>Co-Brand for: <b>' + values.companyName + '</b></p>';


                                if (cobrandObj.status == 'Updated') {
                                    newMsg += '<table tableWidth="100%" style="width:100%;border:none;">';
                                    newMsg += '<tr><td><b> Pid :</b> ' + cobrandPid + ' </td></tr>';
                                    if (cobrandObj.updated_attributes.length > 0) {
                                        cobrandObj.updated_attributes.map((row) => {
                                            
                                            if(row.attribute_name.attribute_name == 'name'){
                                                if(cobrandObj.name == values.contactName){
                                                    newMsg += '<tr><td><b> Name :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.name != values.contactName){ flag = 0; }else{ flag = 1; }
                                            }
                                            if(row.attribute_name.attribute_name == 'company_name'){
                                                if(cobrandObj.company_name == values.companyName){
                                                    newMsg += '<tr><td><b> Company Name :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.company_name != values.companyName){ flag = 0; }else{ flag = 1; }
                                            }                                            
                                            if(row.attribute_name.attribute_name == 'email'){
                                                if(cobrandObj.email == values.email){
                                                    newMsg += '<tr><td><b> Email Address 1 :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.email != values.email){ flag = 0; }else{ flag = 1; }
                                            }
                                            if(row.attribute_name.attribute_name == 'email2'){
                                                if(cobrandObj.email2 == values.email2){
                                                    newMsg += '<tr><td><b> Email Address 2 :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.email2 != values.email2){ flag = 0; }else{ flag = 1; }
                                            }
                                            if(row.attribute_name.attribute_name == 'phone'){
                                                if(cobrandObj.phone == values.phoneNo){
                                                    newMsg += '<tr><td><b> Phone No. :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.phone != values.phoneNo){ flag = 0; }else{ flag = 1; }
                                            }
                                            if(row.attribute_name.attribute_name == 'subtitle'){
                                                if(cobrandObj.subtitle == values.subtitle){
                                                    newMsg += '<tr><td><b> Co-brand Sub-title :</b> ' + row.attribute_new_value + ' </td></tr>';                                                    
                                                }
                                                // if(cobrandObj.subtitle != values.subtitle){ flag = 0; }else{ flag = 1; }
                                            }
                                            if(row.attribute_name.attribute_name == 'website_url'){
                                                if(cobrandObj.website_url == values.companyWebsite){
                                                    newMsg += '<tr><td><b> Company Website :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.website_url != values.companyWebsite){ flag = 0; }else{ flag = 1; }
                                            }
                                            if(row.attribute_name.attribute_name == 'operational_hrs'){
                                                if(cobrandObj.operational_hrs == values.operationalHrs){
                                                    newMsg += '<tr><td><b> Hours of Operation :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.operational_hrs != values.operationalHrs){ flag = 0; }else{ flag = 1; }
                                            }
                                            
                                            if(row.attribute_name.attribute_name == 'special_message_1'){
                                                if(cobrandObj.special_message_1 == values.specialMsg1){
                                                    newMsg += '<tr><td><b> Special Msg 1 :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.special_message_1 != values.specialMsg1){ flag = 0; }else{ flag = 1; }
                                            }
                                            if(row.attribute_name.attribute_name == 'special_message_2'){
                                                if(cobrandObj.special_message_2 == values.specialMsg2){
                                                    newMsg += '<tr><td><b> Special Msg 2 :</b> ' + row.attribute_new_value + ' </td></tr>';
                                                }
                                                // if(cobrandObj.special_message_2 != values.specialMsg2){ flag = 0; }else{ flag = 1; }
                                            }   
                                            if(row.attribute_name.attribute_name == 'company_logo'){                                                
                                                newMsg += '<tr><td><b> Company Logo :</b> Attached Logo </td></tr>';                                                
                                            }                                       
                                                                                        
                                            // if(flag == 1){
                                            //     newMsg += '<tr><td><b>' + row.attribute_name.attribute_name + ':</b> ' + row.attribute_new_value + ' </td></tr>';
                                            // }
                                        })
                                    }
                                    if(cobrandObj.name != values.contactName){
                                        if(cobrandObj.name != null || values.contactName != ''){
                                            newMsg += '<tr><td><b> Name :</b> ' + values.contactName + ' </td></tr>';
                                        }
                                    }
                                    if(cobrandObj.company_name != values.companyName){
                                        if(cobrandObj.company_name != null || values.companyName != ''){
                                            newMsg += '<tr><td><b> Company Name :</b> ' + values.companyName + ' </td></tr>';
                                        }
                                    }
                                    if(cobrandObj.email != values.email){
                                        if(cobrandObj.email != null || values.email != ''){
                                            newMsg += '<tr><td><b> Email Address 1 :</b> ' + values.email + ' </td></tr>';
                                        }
                                    }
                                    if(cobrandObj.email2 != values.email2){
                                        if(cobrandObj.email2 != null || values.email2 != ''){
                                            newMsg += '<tr><td><b> Email Address 2 :</b> ' + values.email2 + ' </td></tr>';
                                        }
                                    }
                                    if(cobrandObj.phone != values.phoneNo){
                                        if(cobrandObj.phone != null || values.phoneNo != ''){
                                            newMsg += '<tr><td><b> Phone No. :</b> ' + values.phoneNo + ' </td></tr>';
                                        }
                                    }
                                    if(cobrandObj.subtitle != values.subtitle){
                                        if(cobrandObj.subtitle != null || values.subtitle != ''){
                                            newMsg += '<tr><td><b> Co-brand Sub-title :</b> ' + values.subtitle + ' </td></tr>';
                                        }                                        
                                    }
                                    if(cobrandObj.website_url != values.companyWebsite){
                                        if(cobrandObj.website_url != null || values.companyWebsite != ''){
                                            newMsg += '<tr><td><b> Company Website :</b> ' + values.companyWebsite + ' </td></tr>';
                                        }
                                    }
                                    if(cobrandObj.operational_hrs != values.operationalHrs){
                                        if(cobrandObj.operational_hrs != null || values.operationalHrs != ''){
                                            newMsg += '<tr><td><b> Hours of Operation :</b> ' + values.operationalHrs + ' </td></tr>';
                                        }
                                    }
                                    if(cobrandObj.special_message_1 != values.specialMsg1){
                                        if(cobrandObj.special_message_1 != null || values.specialMsg1 != ''){
                                            newMsg += '<tr><td><b> Special Msg 1 :</b> ' + values.specialMsg1 + ' </td></tr>';
                                        }
                                    }
                                    if(cobrandObj.special_message_2 != values.specialMsg2){
                                        if(cobrandObj.special_message_2 != null || values.specialMsg2 != ''){
                                            newMsg += '<tr><td><b> Special Msg 2 :</b> ' + values.specialMsg2 + ' </td></tr>';
                                        }                                            
                                    }                                    
                                    
                                    newMsg += '</table>';
                                    setFormikValues(values);  
                                        
                                } else {     
                                    
                                    let newPlanStr = '';
                                    let currentPlanStr = planName;
                                    if (currentPlanStr !== '') {
                                        const newPlan = currentPlanStr.split(';');
                                        for (let i = 0; i < newPlan.length; i++) {
                                            if (newPlan[i] !== '') {
                                                const planNameStr = newPlan[i].split('-');
                                                const j = i + 1;

                                                if(clonePid == '20081' || clonePid == '20085'|| clonePid == '20086'){
                                                    newPlanStr += `${j}) ${planNameStr[0]?.trim()} - ${planNameStr[1]?.trim()}, No Trial`;
                                                }else{
                                                    if(planNameStr[1]){
                                                        newPlanStr += `${j}) ${planNameStr[0]?.trim()} - ${planNameStr[1]?.trim()}`;
                                                    }else{
                                                        newPlanStr += `${j}) ${planNameStr[0]?.trim()}`;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    newMsg += '<p>Please verify the details: </p>' +
                                        '<table tableWidth="100%" style="width:100%;border:none;">' +
                                        '<tr><td><b>Name:</b> ' + (plans === 16 ? values.contactName : formikValues.contactName) + ' </td></tr>' +
                                        '<tr><td><b>Company Name:</b> ' + (plans === 16 ? values.companyName : formikValues.companyName) + ' </td></tr>' +
                                        '<tr><td><b>Email Address 1:</b> ' + (plans === 16 ? values.email : formikValues.email) + ' </td></tr>' +
                                        '<tr><td><b>Email Address 2:</b> ' + (plans === 16 ? values.email2 : formikValues.email2) + ' </td></tr>' +
                                        '<tr><td><b>Phone No.:</b> ' + (plans === 16 ? values.phoneNo : formikValues.phoneNo) + ' </td></tr>' +
                                        '<tr><td><b>Co-brand Sub-title:</b> ' + (plans === 16 ? values.subtitle : formikValues.subtitle) + ' </td></tr>' +
                                        '<tr><td><b>Company Website:</b> ' + (plans === 16 ? values.companyWebsite : formikValues.companyWebsite) + ' </td></tr>' +
                                        '<tr><td><b>Hours of Operation:</b> ' + (plans === 16 ? values.operationalHrs : formikValues.operationalHrs) + '</td></tr>' +
                                        '<tr><td><b>Plan:</b> ' + newPlanStr + (clonePid ? '  - (Clone of PID ' + clonePid + ')' : '' )+' </td></tr>' +
                                        (allocatedPID ? '<tr><td ><b>Pid:</b> ' + allocatedPID + '</td></tr>' : '' ) +
                                        '<tr><td ><b>Special Msg1:</b> ' + (plans === 16 ? values.specialMsg1 : formikValues.specialMsg1) + '</td></tr>' +
                                        '<tr><td><b>Special Msg2:</b> ' + (plans === 16 ? values.specialMsg2 : formikValues.specialMsg2) + '</td></tr>' +
                                        '</table>';

                                    if(plans === 16){
                                        setFormikValues(values);
                                    }
                                }                               
                               
                                newMsg += '<p>Contact us with any questions!</p>' +
                                    '<p>Thank You!</p>' +
                                    '<p>'+(user ? user.first_name : "Admin" )+'</p>';

                                handleChangeEditor(newMsg);                         
                               
                                setIsLoading(false);
                                setOpen(true);                                

                            }}
                        >
                            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <CardContent>
                                    <Box p={5}
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, },
                                            '& .MuiSelect-root': { m: 1, },
                                        }}
                                    >
                                        <Grid container item lg={12} md={12} sm={12} xs={12}  spacing={2}>
                                            <Grid item lg={4} md={4} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box >
                                                    <Card variant='outlined' size='md' sx={{ height: 150, width: 300 }} color={updatedAttributes.includes('company_logo') ? "warning" : ''}>
                                                        <CardContent sx={{ textAlign: 'center', justifyContent: 'center' }}>
                                                            <img className="dashlogo" style={{ height: '90px' }} src={logoURL} />
                                                        </CardContent>
                                                    </Card>
                                                    <Box py={2} sx={{ textAlign: 'center' }}>
                                                        <Button variant="contained" size="sm" color="success" onClick={()=>{handleUploadLogo(values)}}>Edit</Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12} >
                                                <TextField
                                                    id="companyName"
                                                    name="companyName"
                                                    type="text"
                                                    label="Company Name*"
                                                    value={values.companyName}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    helperText={touched.companyName && errors.companyName ? errors.companyName : null}
                                                    error={touched.companyName && errors.companyName ? errors.companyName : null}
                                                    // onChange={(e) => {
                                                    //     handledcompanyName(e.target.value);
                                                    //     handleChange(e);
                                                    // }}
                                                    onChange={handleChange('companyName')}
                                                    onBlur={handleBlur('companyName')}
                                                    color={updatedAttributes.includes('company_name') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('company_name') ? true : false}
                                                />
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Email Address*"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.email}
                                                    // helperText={touched.email && errors.email ? errors.email : null}
                                                    error={touched.email && errors.email ? errors.email : null}
                                                    // onChange={handleChange('email')}
                                                    onChange={(e)=>{
                                                        handleChange('email')
                                                        setFieldValue('email',e.target.value)
                                                        checkUsaTLD(e.target.value,1);
                                                    }}
                                                    onBlur={handleBlur('email')}
                                                    color={updatedAttributes.includes('email') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('email') ? true : false}
                                                    autoComplete="emails"
                                                />
                                                  <div className='error'>{touched.email && errors.email ?errors.email : checkUsaTldStatus ? "Invalid email format." : '' }</div> 
                                                <InputMask
                                                    id="outlined-start-adornment"
                                                    mask='(999) 999-9999'
                                                    maskChar={null}
                                                    type="text"
                                                    label="Phone No*"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.phoneNo}
                                                    helperText={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                    error={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                    onChange={handleChange('phoneNo')}
                                                    onBlur={handleBlur('phoneNo')}
                                                    color={updatedAttributes.includes('phone') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('phone') ? true : false}
                                                >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label="Phone Number" variant="outlined"
                                                            {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Contact Name*"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.contactName}
                                                    helperText={touched.contactName && errors.contactName ? errors.contactName : null}
                                                    error={touched.contactName && errors.contactName ? errors.contactName : null}
                                                    onChange={handleChange('contactName')}
                                                    onBlur={handleBlur('contactName')}
                                                    color={updatedAttributes.includes('name') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('name') ? true : false}
                                                    autoComplete="contactName"
                                                />
                                                {/* <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Email Address 2"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.email2}
                                                    // onChange={handleChange('email2')}
                                                    onChange={(e)=>{
                                                        handleChange('email2')
                                                        setFieldValue('email2',e.target.value)
                                                        checkUsaTLD(e.target.value,2);
                                                    }}
                                                    onBlur={handleBlur('email2')}
                                                    color={updatedAttributes.includes('email2') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('email2') ? true : false}
                                                    autoComplete="email2"
                                                />
                                                 <div className='error'>{checkUsaTldAddress2Status ? "Invalid email format." : '' }</div> */}
                                                <TextField
                                                    id="companyWebsite"
                                                    type="text"
                                                    label="Company Website(URL)"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.companyWebsite}
                                                    onChange={handleChange('companyWebsite')}
                                                    onBlur={handleBlur('companyWebsite')}
                                                    color={updatedAttributes.includes('website_url') ? "warning" : ''}
                                                    focused={updatedAttributes.includes('website_url') ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {
                                            cobrandObj.selected_cobrand_plan
                                                ?
                                                Object.values(cobrandObj.selected_cobrand_plan).length > 0
                                                    ?
                                                    <>
                                                        <Grid md={12} container item pt={2} pl={1} >
                                                            <Typography level="h5" >Current Plan Details</Typography>
                                                        </Grid>
                                                        <Grid md={12}  item pl={2} >
                                                            {                                                                
                                                                cobrandObj.selected_cobrand_plan.map((row, idx) => {
                                                                    return (
                                                                        <>
                                                                            <Typography>{idx + 1}. PID : {row.pid}, Plan : {row.base_plan.plan_name ? row.base_plan.plan_name : 'N/A'}</Typography>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }

                                        {
                                            updateFlag == 1
                                                ?
                                                <>
                                                </>
                                                :
                                                <>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} pl={1}>
                                                        <Typography level="h5" >Requested Plan Details</Typography>
                                                    </Grid>
                                                    <Grid container item spacing={4} lg={12} md={12} sm={12} xs={12} pl={1}>
                                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                                            <FormControl fullWidth size='small' margin="dense"
                                                                error={touched.plan && errors.plan ? errors.plan : null}
                                                            >
                                                                <InputLabel id="demo-multiple-name-label">Plan</InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    disabled
                                                                    margin="dense"
                                                                    labelId="demo-simple-select-label"
                                                                    value={values.plan}
                                                                    onChange={handleChange('plan')}
                                                                    onBlur={handleBlur('plan')}
                                                                    id="plan"
                                                                    input={<OutlinedInput label="Plan" />}
                                                                    name="plan"
                                                                    helperText={touched.plan && errors.plan ? errors.plan : null}
                                                                    error={touched.plan && errors.plan ? errors.plan : null}
                                                                >
                                                                    {basePlans.map((row) => {
                                                                        return (
                                                                            <MenuItem value={row.id}>{row.plan_name}</MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>
                                                                {touched.plan && errors.plan ? <FormHelperText>Please select Plan.</FormHelperText> : null}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                                            <FormControlLabel control={<Checkbox checked={checked} />} label="Is Sponsored"
                                                                 onChange={(e) => {
                                                                    handlesponsored(e);
                                                                    setValue(values);
                                                                }}
                                                            //    onChange={
                                                            //         handlesponsored
                                                            //         setvalue();

                                                            //     }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                        }
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Co-Branding Sub-Title (E.G. Could Be Your Tag Line, Motto, Your Slogan)"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                value={values.subtitle}
                                                onChange={handleChange('subtitle')}
                                                color={updatedAttributes.includes('subtitle') ? "warning" : ''}
                                                focused={updatedAttributes.includes('subtitle') ? true : false}
                                                autoComplete="subtitle"
                                            />
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="operational Hrs"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                value={values.operationalHrs}
                                                onChange={handleChange('operationalHrs')}
                                                onBlur={handleBlur('operationalHrs')}
                                                color={updatedAttributes.includes('operational_hrs') ? "warning" : ''}
                                                focused={updatedAttributes.includes('operational_hrs') ? true : false}
                                                autoComplete="operationalHrs"
                                            />
                                        </Grid>

                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Special Messaging - Paragraph 1"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                value={values.specialMsg1}
                                                onChange={handleChange('specialMsg1')}
                                                onBlur={handleBlur('specialMsg1')}
                                                color={updatedAttributes.includes('special_message_1') ? "warning" : ''}
                                                focused={updatedAttributes.includes('special_message_1') ? true : false}
                                                autoComplete="specialMsg1"
                                            />
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Special Messaging - Paragraph 2"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                value={values.specialMsg2}
                                                onChange={handleChange('specialMsg2')}
                                                onBlur={handleBlur('specialMsg2')}
                                                color={updatedAttributes.includes('special_message_2') ? "warning" : ''}
                                                focused={updatedAttributes.includes('special_message_2') ? true : false}
                                                autoComplete="specialMsg2"
                                                
                                            />
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    id="memberCredentialsFlag"
                                                    name="memberCredentialsFlag"
                                                    checked={values.memberCredentialsFlag}
                                                    onChange={(e) => {
                                                        console.log(e.target.checked)
                                                        setFieldValue('memberCredentialsFlag',e.target.checked)
                                                    }}
                                                    value={values.memberCredentialsFlag} />}
                                                label="Enable Fetching Member Credentials"
                                                labelPlacement="start"
                                            />
                                            {/* <label className="control-label" htmlFor="enable-member-credentials">Enable Fetching Member Credentials</label>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                    id="memberCredentialsFlag"
                                                    name="memberCredentialsFlag"
                                                    checked={isChecked}
                                                    onChange={(e) => {
                                                        setIsChecked(e.target.checked);
                                                        handleChange(e);
                                                    }}
                                                    value={isChecked}
                                                    className="custom-control-input"
                                                  />
                                                }
                                            /> */}
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                                            <Grid md={6} p={2} textAlign="center">
                                                { (cobrandObj.status == "Updated" && cobrandObj.onlyVerify == true) || onlyVerify
                                                    ?
                                                    <Button variant="contained" sx={{ minWidth: "60%" }} color="success" type="submit" size='lg' disabled={(isSubmitting)} onClick={() => { verifyCobrand(values) }}>Verify</Button>
                                                    :
                                                    pidStatus == 1 || cobrandObj.status == "Updated" // custom plan or updated info
                                                    ?
                                                    <Button variant="contained" sx={{ minWidth: "60%" }} color="success" type="submit" size='lg' disabled={(isSubmitting)} onClick={handleSubmit}>Send Email to CD</Button>
                                                    :
                                                    pidStatus == 2 || checked == true || plans === 16
                                                        ?
                                                        <Button variant="contained" sx={{ minWidth: "60%" }} color="success" type="submit" size='lg' disabled={(isSubmitting)} onClick={handleSubmit}>Request For New PID</Button>
                                                        :

                                                        <Button variant="contained" sx={{ minWidth: "60%" }} color="success" size='lg' disabled={(isSubmitting)} onClick={() => { checkAvailablePID(values) }}>Check Available PID</Button>
                                                }
                                                {pidAvailbleStatus ? <Typography>{pidAvailbleStatus}</Typography> : ''}

                                            </Grid>
                                            <Grid md={6} p={2} textAlign="center">
                                                <Button variant="contained" sx={{ minWidth: "60%" }} color="primary" size='lg' onClick={() => { navigate('/cobrand/all') }} >CLOSE</Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                        <Typography level="h5" >Need Help?</Typography>&nbsp;
                                        <Typography level="h5">Call 1-888-548-2008</Typography>
                                    </Grid>
                                </CardContent>
                            )}
                        </Formik>

                    </Card>
                </Grid>

                {/* Email To CD */}
                <Formik
                    initialValues={{
                        // formValues: formikValues,
                        subject: checked == true ? 'New Sponsored Co-Brand PID Request for AID: ' + aidDetails.aid
                            : allocatedPID ?
                                'Co-Brand Pre-Built PID-' + allocatedPID + ' Update Request- Assigned to AID: ' + aidDetails.aid
                                :
                                cobrandObj.status == 'Updated'
                                    ?
                                    'Co-Brand Update for AID: ' + aidDetails.aid
                                    :
                                    'New Co-brand Request for ' + aidDetails.aid,
                        emailEditor: editorData,
                    }}
                    onSubmit={async (values, error) => {
                        const loginUser = JSON.parse(localStorage.getItem('user'));
                        if(loginUser.roles_id >= 3 ) {
                            navigate('/login');
                            return false;
                        }
                        if(checkUsaTldStatus == true || checkUsaTldAddress2Status == true){
                            return false;
                        }                          
                        setButtonDisabled(true);
                        setIsLoading(true);
                        handleClose();
                     
                        console.log(formikValues);
                        const args = {
                            formValues: formikValues,
                            subject: values.subject,
                            emailBody: values.emailEditor,
                            aidMasterId: id,
                            allocatedPID: allocatedPID,
                            sponsorCode: sponsorCode,
                            url: config.BASE_URL,
                            logoName: logoName,
                        }
                       
                        const apiUrl = '/verifyCobrand';
                        const res = await dispatch(postData({ apiUrl, args })).unwrap();
                        if (res) {
                            if (res.success) {
                                localStorage.setItem('success', res.message)
                                navigate('/cobrand/all' );
                                // navigate(-1);
                                setIsLoading(false);
                            } else {
                                if(res.message == 'This PID is already allotted. Try for another PID'){
                                    setIsLoading(false);  
                                    allocatedPID = 0;
                                    setPidStatus(0);
                                    setPidAvailbleStatus('');                                 
                                    handleClose();
                                    setIsLoading(false)
                                    setErrorMessage(res.message);
                                    setTimeout(() => {
                                        setErrorMessage('');

                                    }, 10000);
                                    setButtonDisabled(false);

                                }else{
                                    setIsLoading(false);
                                    setErrorMessage(res.message);
                                    setTimeout(() => {
                                        setErrorMessage('');
                                    }, 3000);
                                    setButtonDisabled(false);
                                    
                                }
                               
                            }
                        }
                    }}

                >
                    {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <Dialog
                            maxWidth='md'
                            disableEscapeKeyDown={true}
                            open={open}
                            onClose={(_, reason) => {
                                if (reason !== "backdropClick") {
                                    handleClose();
                                }
                            }}                            
                        >
                            <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
                                <Typography level="h4" color='primary'>Email to CD</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Grid py={2} container item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        id="outlined-start-adornment"
                                        type="text"
                                        label="Subject*"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={values.subject}
                                        helperText={touched.subject && errors.subject ? errors.subject : null}
                                        error={touched.subject && errors.subject ? errors.subject : null}
                                        onChange={handleChange('subject')}
                                        onBlur={handleBlur('subject')}
                                    />

                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    Massage:<Button variant='contained' color='success' 
                                    onClick={(e) => {
                                        setFieldValue('emailEditor',editorData);
                                    }}
                                    >Reset Default Message</Button>
                                    <FormControl fullWidth size="small">
                                        <div style={{ overflow: 'auto', maxHeight: '25vw' }}>
                                        <CKEditor                                                                                    
                                            editor={ClassicEditor}                                         
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                // values.emailEditor = data;
                                                setFieldValue('emailEditor',data);
                                            }}                                            
                                            data={values.emailEditor}
                                            value={values.emailEditor}
                                            required
                                            name="emailEditor"
                                        />
                                        </div>
                                    </FormControl>                                    
                                </Grid>
                                {
                                    logoName ? 
                                        <Grid lg={12} md={12}  sx={{backgroundColor: '#ebebeb',border: '1px solid #c1c1c1',borderRadius: '0 0 5px 5px'}}>
                                            <Stack direction={"row"} >
                                                <Grid lg={4} md={4} sx={{padding: '2% 0px 0px 2%'}}><Typography>Attached logo: </Typography></Grid>
                                                <Grid lg={8} md={8}><img className="dashlogo" style={{ height: '3vw', padding: '4% 0px 0px 10%' }} src={logoURL} /></Grid>
                                            </Stack>
                                        </Grid>     
                                    :
                                    null 
                                }
                            </DialogContent>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button autoFocus variant="contained" sx={{ minWidth: 250 }} onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{ minWidth: 250 }} color="success" type="submit" onClick={handleSubmit} disabled={isButtonDisabled}>
                                            SEND
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    )}
                </Formik>
                {/* Email To CD Ends */}


                <Dialog
                    open={uploadLogo}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseUploadLogo();
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/* <LogoEditor aidMasterId={id} pathType="cobrand" /> */}
                    <LogoEdit id={id} logoEditURL={handleLogoURL} logoFlagEdit={handleLogoFlag} logoName={handleLogoName} setOpen={handleCloseUploadLogo} />
                </Dialog>
            </Box>
        )

    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}

export default CobrandVerify;