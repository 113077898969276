import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box, Grid, Alert } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/joy';
import Loader from '../../../Components/Loader/Loader';
import AbandonedMemberList from '../../../Components/AbandonedMember/AbandonedMemberList';


function AbandonedMember() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    //Fetch reports
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    //Submit Request Add Missing Member Form
    function ContentLayout() {
        return (
            <>
                <Box>
                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
                        <Typography level="h4" color="primary">Abandoned Members</Typography>
                    </Grid>
                    {isLoading ? <><Loader /></> : ''}
                    <AbandonedMemberList data={data} />
                    {/* add missing member report popup */}
                </Box>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default AbandonedMember;