import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {  Grid, Button, DialogActions, TextField,Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import AuthUser from '../Auth/AuthUser';
import { useDispatch } from 'react-redux';
import { postData } from '../../redux/apiSlice';

let RequestFormInitialValues = {
    emailId: '',
    email : '',
    OTP : '',
    companyId : '',
};

function BounceEmailDailog({ id, email , communication_email, onValueChange , closeModal , fetchData}) {
   
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isOTPFieldVisible, setisOTPFieldVisible] = useState(false);
    const [disableSendOTPButton, setdisableSendOTPButton] = useState(false);
    const navigate = useNavigate();
    const { http} = AuthUser();
    const [errorMessage, setErrorMessage] = useState('');
    const [companyId, setcompanyId] = useState(id);
    const [Email, setEmail] = useState(email);
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [emailButton, setEmailButton] = useState(false);
    const dispatch = useDispatch();
    const RequestFormSchema = Yup.object().shape({
        emailId: Yup.string()
        .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
        .required('Please enter email'),
    });

    RequestFormInitialValues.emailId = communication_email;
    RequestFormInitialValues.OTP = '';

    const submitRequest =async (values) => {
        console.log("values",values);
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id > 3) {
          navigate('/login');
          return false;
        }
        setdisableSendOTPButton(true)
        RequestFormInitialValues.email = Email;
        RequestFormInitialValues.companyId = companyId;
        RequestFormInitialValues.emailId = values.emailId;
        RequestFormInitialValues.OTP = values.OTP;
        const apiUrl = '/sendOTPtobounceEmail';
        const args = {
            companyId : companyId,
            email : values.emailId,
            OTP : values.OTP,
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();    
        if (res) {
            if (res.success === false) {
                onValueChange('',res.message)
                setdisableSendOTPButton(false)
            } else {
                if(res.changedMail == true){
                    RequestFormInitialValues.companyId = '';
                    RequestFormInitialValues.email = '';
                    RequestFormInitialValues.emailId = '';
                    RequestFormInitialValues.OTP = '';
                    onValueChange(res.message, '')
                    closeModal();
                    fetchData();
                }else{
                    var textField = document.getElementById("emailId");
                    // Make the text field non-editable
                    textField.readOnly = true;
                    textField.style.backgroundColor = "#f0f0f0";
                    setisOTPFieldVisible(true)
                    setdisableSendOTPButton(false)
                    onValueChange(res.message, '')
                }
            }
          }else {
                setErrorMessage("Something went wrong.")
                setTimeout(function () {
                    setErrorMessage('')
                }, 5000);
          }
      }

    const checkUsaTLD = async(email) => {
        if (email.includes('@')) {
        setEmailButton(true);
        const apiUrl = '/checkUsaTLD';
        const args = {
            email: email,
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();    
        if (res) {
            if (res.success === true) {
                setCheckUsaTldStatus(true);
            } else {
                setCheckUsaTldStatus(false);
            }
        }
        setEmailButton(false);
        }
    }
    

    return (
        <>
            <Formik
                            initialValues={RequestFormInitialValues}
                            validationSchema={RequestFormSchema}
                            onSubmit={submitRequest}
                            >{({ values, errors, touched, handleChange, handleBlur, handleSubmit,setFieldValue }) => (
                            <>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} style={{alignItems: "center"}}>
                                    <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <Typography variant="h6" gutterBottom>Communication Email:</Typography> 
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={6} xs={6}>
                                        <TextField
                                         
                                            style={{ width: "100%" }}
                                            required
                                            margin="dense"
                                            aria-label="minimum height"
                                            placeholder="Enter email"
                                            name="Enter the email"
                                            id="emailId"
                                            size="small"
                                            value={values.emailId}
                                            onChange={(e)=>{
                                                checkUsaTLD(e.target.value);   
                                                handleChange('emailId');
                                                setFieldValue('emailId', e.target.value, true)
                                            }}
                                            onBlur={handleBlur('emailId')}

                                        />
                                        <div className='error'>{touched.emailId && errors.emailId ?errors.emailId : checkUsaTldStatus ? "Invalid email format." : '' }</div> 
                                        </Grid>
                                        </Grid>
                                    
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    {isOTPFieldVisible ?
                                 <Grid container item lg={12} md={12} sm={12} xs={12} style={{alignItems: "center"}}>
                                    <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <Typography variant="h6" gutterBottom>Enter OTP: </Typography>
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={6} xs={6}>
                                    
                                        <TextField
                                            style={{ width: "100%" }}
                                            required
                                            margin="dense"
                                            aria-label="minimum height"
                                            placeholder="Enter OTP"
                                            name="Enter the OTP"
                                            id="OTP"
                                            size="small"
                                            value={values.OTP}
                                            onChange={handleChange('OTP')}
                                            onBlur={handleBlur('OTP')}

                                        />
                                        
                                        {touched.OTP && errors.OTP ? <div className='error'>{errors.OTP}</div> : null}
                                        </Grid>
                                  </Grid>
                                        : '' }
                                    </Grid>
                                    
                                <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                    <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                                    { !isOTPFieldVisible ?
                                        <Button variant="contained" sx={{ minWidth: isMobile ? 200 : 250 }} onClick={closeModal}>
                                            Cancel
                                        </Button>
                                        : 
                                        '' }
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                                        { !isOTPFieldVisible ?
                                            <Button variant="contained" sx={{ minWidth: isMobile ? 200 : 250 }} color="success" disabled={disableSendOTPButton} onClick={disableSendOTPButton === false && emailButton === false && checkUsaTldStatus == false ? handleSubmit : null}
                                            >
                                            SEND OTP
                                            </Button>
                                            : 
                                            <Button variant="contained" sx={{ minWidth: isMobile ? 200 : 250 }} color="success" disabled={disableSendOTPButton} onClick={disableSendOTPButton === false ? handleSubmit : null}>
                                            Verify OTP
                                            </Button>
                                        }
                                    </Grid>
                                    </Grid>
                                </DialogActions>
                                </>
                            )}
                        </Formik>  
                    </>
    )
}
export default BounceEmailDailog;