import React, {useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { Box, Tab, Grid, Tabs, Button, Stack, Backdrop, InputBase, TextField, Paper, ListItem, List } from '@mui/material';
import Typography from '@mui/joy/Typography';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import AdvancedCBReview from '../../../Components/CobrandCustomization/AdvancedCBReview';
import PublishedLog from '../../../Components/CobrandCustomization/PublishedLog';
function Index(props) {
  const dispatch = useDispatch();
  const { tab } = useParams();
  const [value, setValue] = React.useState(tab);
  const [freez, setFreez] = React.useState(false);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  useEffect(() => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser) {
      if (loginUser.roles_id >= 3) {
        navigate('/login');
        return () => { };
      }
    }
    else {
      navigate('/login');
      return () => { };
    }

  }, [])
  const ContentLayout =
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <Typography level="h4" color="primary">Advanced Customization</Typography>
        </Grid>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Grid container item lg={12} md={12} sm={12} xs={12} >
              <TabList onChange={handleChange} aria-label="lab API tabs example" >
                <Tab label="Advanced Cobrand Review" component={Link} to="/customizationReview/advancedCBReview" value="advancedCBReview" sx={{ color: 'black' }} />
                <Tab label="Published Log" component={Link} to="/customizationReview/publishedLog" value="publishedLog" sx={{ color: 'black' }} />

              </TabList>
            </Grid>
          </Box>
          <TabPanel value="advancedCBReview"><AdvancedCBReview tab={tab} /></TabPanel>
          <TabPanel value="publishedLog"><PublishedLog tab={tab} /></TabPanel>
        </TabContext>
      </Box>
    </>

  return (
    <div>
      <DefaultLayout content={ContentLayout} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </div>
  );
}

export default Index;