
import React from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box } from '@mui/material';
import { Typography } from 'material-ui';

function ReferenceAffiliateRequest(props) {
    function ContentLayout() {
        return (
            <Box>
               <Typography>
                <p>Hi Siddhivinayak-</p>
                <p>Thank you for your interest in MyFreeScoreNow’s Affiliate Program!</p>
                <p>It’s easy to get started as an affiliate and there is: <b><u>No cost to you to join</u>! <u> No set-up fees. No minimums.</u></b></p>
                <p><b><i>Get your affiliate links in minutes!</i></b></p>
                <p>Plus your company gets a<b> FREE </b> membership!</p>
                <p>Our affiliates receive the <b>HIGHEST </b>ongoing residual sales commissions <b>- $7.00-$16.00 every month.</b><br/>Plus - earn cash by referring other companies to us!</p>
                <p>Each of our affiliate membership levels <b>includes a 3-Bureau credit report </b>for your client<b> auto-pulled </b>upon enrollment - <br/>and immediately available for 1-Click Auto-Import and a report refresh every 30 days.</p>
                <p><b>Yes -</b> we offer 7-day trial period options!</p>
                <p><b>Yes -</b> we accept credit and debit cards from your clients! Ask us about our options for prepaid cards and cash apps.</p>
                <p><b>Yes -</b>  we have options for co-branding and wholesale</p>
                <p>So let’s get started!</p>
               </Typography>
               <Typography>
                <p style={{fontSize:"25px",color:"blue"}}>Next Step ➡ Please check your email!</p>
               </Typography>
               <Typography>
                <p style={{fontSize:"25px",color:"black",lineHeight:"1"}}>Your personalized MyFreeScoreNow affiliate program document is ready for your review.<br/>Don’t see it? Please check spam/junk folders. Thank you!</p>
               </Typography>
            </Box>
        );
    }
     
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default ReferenceAffiliateRequest;