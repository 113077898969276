import React, { useState, useEffect } from "react";
import { Box, Grid} from '@mui/material';
import { Typography, Card, CardContent, Button } from '@mui/joy';
import AuthUser from "../../../Components/Auth/AuthUser";
import moment from 'moment';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';

function Enrollment5(props) {
    const { http } = AuthUser();
    const currentDate = moment().format("MM/DD/YYYY");
    const [futureDate,setFutureDate] = useState(moment().add(7, 'days').format("MM/DD/YYYY"));
    

//loader function and variable start
const [freez, setFreez] = React.useState(false);
const handleFreezStop = () => {
    setFreez(false);
};
const handleFreezStart = () => {
    setFreez(true);
};
//loader function and variable end


    //chagers and subscription values functions and variable starts
const [offerdetails,setOfferDetails] = useState();

const pid = localStorage.getItem('pid');
const [acceptCheck,setAcceptCheck] = useState(true);
const [checkDisable,setCheckDisable] = useState(false);
const [credentialsStatus,setCredentialsStatus] =  useState(false);
const [acceptStatus,setAcceptStatus] =  useState(false);

const changeAcceptValue = () => {
    var acceptFlag = acceptCheck; 
    acceptFlag = !acceptFlag;
    setAcceptCheck(!acceptCheck);
    if(acceptFlag == true) {
        const element = document.getElementById("continueDiv");
        element.style.display = "none";

        const element2 = document.getElementById("continueDetailsDiv");
        element2.style.display = "none";

        const element3 = document.getElementById("acceptBtnDiv");
        element3.style.display = "block";
    }else{
        const element = document.getElementById("acceptBtnDiv");
        element.style.display = "none";

        const element2 = document.getElementById("continueDiv");
        element2.style.display = "block";

        const element3 = document.getElementById("continueDetailsDiv");
        element3.style.display = "block";
    }
}

const getOfferDetails =()=>{
  
    handleFreezStart();
    http.post("/getofferdetailsofpid", {
        pid: pid,
    })
    .then((res) => {
        handleFreezStop();
        console.log(res)
        if (res.data.success === true) {     
            setOfferDetails(res.data.data[0])
            setFutureDate(moment().add(res.data.data[0].days, 'days').format("MM/DD/YYYY"));
        } 
    })
}

const checkEnrollData =()=>{
    handleFreezStart();
    var password = btoa(localStorage.getItem('Main_password'))
    http.post("/checkEnrollData", {
        customerToken: localStorage.getItem('customerToken'),
        password: password,
    })
    .then((res) => {
        handleFreezStop();
            const element = document.getElementById("acceptBtnDiv");
            element.style.display = "none";

            const element2 = document.getElementById("continueDiv");
            element2.style.display = "block";

            const element3 = document.getElementById("continueDetailsDiv");
            element3.style.display = "block";

            setCheckDisable(true);
            setAcceptStatus(true);

            const acceptDiv = document.getElementById("acceptDiv");
            if(acceptDiv) {
                acceptDiv.style.fontWeight = "400";
                const offerDetailsDiv = document.getElementById("offerDetails");
                offerDetailsDiv.style.fontWeight = "400";

                const MembershipDiv = document.getElementById("MembershipDiv");
                MembershipDiv.style.fontWeight = "400";
                
                const webDiv = document.getElementById("webDiv");
                webDiv.style.fontWeight = "400";
                
                const moNoDiv = document.getElementById("moNoDiv");
                moNoDiv.style.fontWeight = "400";
                
                const SubscriptionDiv = document.getElementById("SubscriptionDiv");
                SubscriptionDiv.style.fontWeight = "400";

                const dateDiv = document.getElementById("dateDiv");
                dateDiv.style.fontWeight = "400";

                const trialDiv = document.getElementById("trialDiv");
                trialDiv.style.fontWeight = "400";

                const daysDiv = document.getElementById("daysDiv");
                daysDiv.style.fontWeight = "400";

                const futureDateDiv = document.getElementById("futureDateDiv");
                futureDateDiv.style.fontWeight = "400";

                const priceDiv = document.getElementById("priceDiv");
                priceDiv.style.fontWeight = "400";

                const questionDiv = document.getElementById("questionDiv");
                questionDiv.style.fontWeight = "400";
            }
    })
}

useEffect(() => {

    getOfferDetails();
    var status = false;
    if (localStorage.getItem("credentialsStatus") === null || localStorage.getItem("credentialsStatus") == 0) {
        setCredentialsStatus(false);
    }else{
        setCredentialsStatus(localStorage.getItem('credentialsStatus'));
        status = localStorage.getItem('credentialsStatus');
    }
    if(status == true) {
        const element = document.getElementById("continueDiv");
        element.style.display = "none";

        const element2 = document.getElementById("continueDetailsDiv");
        element2.style.display = "none";

        const element3 = document.getElementById("acceptBtnDiv");
        element3.style.display = "block";
    }else {

        const element2 = document.getElementById("continueDiv");
        element2.style.display = "block";

        const element3 = document.getElementById("continueDetailsDiv");
        element3.style.display = "block";

        const element = document.getElementById("acceptBtnDiv");
        element.style.display = "none";
    }
}, []);

//chagers and subscription values functions and variable ends
    return (
        <>
            <Box p={4}>
            <form method="post" action="https://member.myfreescorenow.com/external-login"  id="enrollCompletionForm">
                <Card variant="outlined">
                <input type="hidden" name="loginType" value="CUSTOMER"/>
                <input type="hidden" name="j_username" value={localStorage.getItem('Main_email')}/>
                <input type="hidden" name="j_password" value={localStorage.getItem('tempPassword')}/>
                {/* <input type="hidden" name="_token" value="{{ csrf_token() }}" /> */}
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Typography level="h4"><b>Thank You! Your Order is Complete.</b></Typography>
                                </Grid>
                              
                               

                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography level="h6"><b>Your Account: {localStorage.getItem('Main_firstName')}  {localStorage.getItem('Main_lastName')}<br />User Name: {localStorage.getItem('Main_email')}<br /></b></Typography>
                                </Grid>

                                {credentialsStatus ?
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} >
                                        <Typography level="h6"> 
                                            <input type="checkbox" checked={acceptCheck} disabled={checkDisable}
                                                onChange={e => {
                                                    // setAcceptCheck(!acceptCheck);
                                                    changeAcceptValue();
                                                }} /> 
                                            <span id="acceptDiv" style={{fontWeight: "700"}}> By clicking the “Accept” button below, I hereby authorize MyFreeScoreNow to submit the login credentials (user ID: email address and password) to {localStorage.getItem('cobrandCompanyName')}. I understand and agree that the information I'm authorizing to submit to {localStorage.getItem('cobrandCompanyName')} is to enable them to provide me a personalized consultation.</span></Typography>
                                    </Grid>
                                : ''}


                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="continueDetailsDiv">
                                    <Typography level="h6" ><b>Click the “Continue” button below to see your score and report.</b></Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id='acceptBtnDiv'>
                                    <Button type = "button" onClick={checkEnrollData} color="warning" sx={{ minWidth: 150, backgroundColor: "#ED7E2E", color: "white", borderRadius: "0%" }}>Accept</Button>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id='continueDiv'>
                                    <Button type = "submit" color="warning" sx={{ minWidth: 150, backgroundColor: "green", color: "white", borderRadius: "0%" }}>CONTINUE</Button>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} id='OrderdetailsDiv'>
                                    <Typography level="h6"><b>Order Details :</b>
                                    
                                        <br />
                                        {/* You have enrolled in a recurring paid membership for credit report monitoring and if you wish to avoid any recurring fees, please cancel your membership within your trial period (if a trial period is applicable to your membership plan). You may cancel anytime to avoid future billings */}
                                        {
                                           
                                            offerdetails ? offerdetails.is_sponsored ?
                                                <Typography level="h6" id="offerDetails" style={{fontWeight: "700"}}>{offerdetails.description}</Typography>
                                            :
                                            offerdetails.days === 0 ?
                                            props.pid === 11233 ?
                                            <Typography level="h6" id="offerDetails" style={{fontWeight: "700"}}>Get all 3 Reports and 3 Scores now!  A new 3-Bureau report included each month. $1.00 Upfront Activation Fee AND {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged at time of order. Payments non-refundable. Call 888-548-2008 to cancel to avoid future billings.</Typography>
                                            :
                                            <Typography level="h6" id="offerDetails" style={{fontWeight: "700"}}>Get all 3 Reports and 3 Scores now!  A new 3-Bureau report included each month. {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged at time of order. Payments non-refundable. Call 888-548-2008 to cancel to avoid future billings.</Typography>
                                            :
                                            offerdetails.hasFee === 'None' ?
                                                <Typography level="h6" id="offerDetails" style={{fontWeight: "700"}}>Get all 3 Reports and 3 Scores now! {offerdetails.days}-Day Free Trial of credit monitoring. {offerdetails.price} Monthly MyFreeScoreNow Membership automatically charged after the {offerdetails.days}-Day Trial Period. Payments non-refundable. Call 1-888-548-2008 to cancel to avoid future billings.</Typography>
                                            :
                                                <Typography level="h6" id="offerDetails" style={{fontWeight: "700"}}>Get all 3 Reports and 3 Scores now! New 3-Bureau Report included monthly $1.00 Upfront Activation Fee. Monthly MyFreeScoreNow Membership of {offerdetails.price} automatically charged after {offerdetails.days}-Day Trial. Payments non-refundable. Call 888-548-2008 to cancel to avoid future billings.</Typography>                                                       
                                        : '' }
                                    </Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Typography level="h6" id="MembershipDiv" style={{fontWeight: "700"}}>Membership Fees will appear as this on your bank debit or credit card statement as:</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Typography level="h6" id="webDiv" style={{fontWeight: "700"}}>MYFREESCORENOW.COM</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography level="h6" id="moNoDiv" style={{fontWeight: "700"}}>(800) 218-8306 CA</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Typography level="h6" id="SubscriptionDiv" style={{fontWeight: "700"}}>Subscription Start Date :	</Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Typography level="h6" id="dateDiv" style={{fontWeight: "700"}}>{currentDate}</Typography>
                                    </Grid>
                                </Grid>
                                {
                                offerdetails ? offerdetails.is_sponsored ? null :  
                                <>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography level="h6" id="trialDiv" sx={{ color: "#2A68B3", fontWeight: "700" }}>Trial Period :</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography level="h6" id="daysDiv" sx={{ color: "#2A68B3", fontWeight: "700" }}>{offerdetails ? offerdetails.days : ""} Days</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography level="h6" id="futureDateDiv" sx={{ fontWeight: "700" }}>Monthly fee starting on {futureDate}</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Typography level="h6" id="priceDiv" sx={{ color: "#ED7E2E", fontWeight: "700" }}>{offerdetails ? offerdetails.price : ""}</Typography>
                                        </Grid>
                                    </Grid>
                                    </>
                                : ''}    
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <Typography level="h6" id="questionDiv"  sx={{ fontWeight: "700" }}>For questions or to cancel,<br />just call 1-888 548-2008</Typography>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <img src={require("../../../assets/images/enrollmentimg.png")} alt="#" style={{width:"100%"}}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>
            </Box>
                    {/* loader code start */}
                    <div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={freez}
                            onClick={handleFreezStop}
                        >
                            <Freez />
                        </Backdrop>
                    </div>
                    {/* loader code end */}
        </>
    )
}
export default Enrollment5;