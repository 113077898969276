import React, { useState, useEffect } from 'react';
import LoginHeader from '../Components/LoginHeader';
import RegisterFooter from './Auth/RegisterFooter';
import { Box, Divider, Grid } from '@mui/material';
import { Typography, Card, CardContent } from '@mui/joy';
import config from '../AppConfig';
import '../Css/Custom.css';
import moment from 'moment';
import HomeTab from '../Components/Home/HomeTab';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../redux/apiSlice';

function Home(props) {
    const today = new Date();
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
    const styles = {
        /* list image */
        ulli: {
            listStyle: "url('../assets/images/iconul.png')",
        }
    }
    const dispatch = useDispatch();
    //url parameters start
    const queryParameters = new URLSearchParams(window.location.search);
    // let pid = "00017";
    const [pid, setPid] = useState('00017');

    const [aid, setAid] = useState('seo');
    let aidNew = aid;

    let pidNew = pid;

    // for (let key of queryParameters.keys()) {
    //     if (key.toLowerCase() === 'pid') {
    //         // pidNew = queryParameters.get(key); 
    //         // setPid(pidNew);
    //     } else if (key.toLowerCase() === 'aid') {
    //         aid = queryParameters.get(key);
    //     }
    // }

    //url parameters end
    //mcfee window function starts
    const getDefaultPid = async () => {
        const apiUrl = '/fetch/defaultPid';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setPid(res.data.pid);
            pidNew = res.data.pid
        }
        getOfferDetails()
    }
    const getOfferDetails = async () => {
        if (queryParameters == '') {
            // getDefaultPid();
        } else {
            for (let key of queryParameters.keys()) {
                if (key.toLowerCase() === 'pid') {
                    pidNew = queryParameters.get(key);
                    setPid(queryParameters.get(key));
                } else if (key.toLowerCase() === 'aid') {
                    aidNew = queryParameters.get(key);
                    setAid(aidNew)
                    // getDefaultPid();
                } else {
                    // getDefaultPid();
                }
            }
        }
        const apiUrl = '/getofferdetailsofpid';
        const args = {
            pid: pid,
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success === true) {
                setOfferDetails(res.data[0])
                setFutureDate(moment().add(res.data[0].days, 'days').format("MM/DD/YYYY"));
            }
        }
    }
    const handleClick = () => {
        window.open(
            'https://www.mcafeesecure.com/verify?host=www.myfreescorenow.com&lang=en',
            'vs',
            'menubar=no,scrollbars=1,height=500,width=600'
        );
    }
    //mcfee window function ends
    //chagers and subscription values functions and variable starts
    const [futureDate, setFutureDate] = useState();
    const [offerdetails, setOfferDetails] = useState();
    useEffect(() => {
        getDefaultPid();
    }, [pid]);
    //chagers and subscription values functions and variable ends

    return (
        <>
            <LoginHeader />
            <Box className="back">
                <HomeTab />
                <Box className="hero-index">
                    <Grid p={5} className='jumbotron'>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", display: "contents" }}>
                            <Typography level="h1" className="texthome">Get Your 3 Free Credit Scores</Typography>
                            <Typography level="h3" className="texthome1" color="primary">From All 3 Bureaus as of {formattedDate}</Typography>
                        </Grid>

                        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems='center' justifyContent="center" mt={4} className='cts-group '>
                            {/* <a
                                className="mcafee-seal"
                                target="_blank"
                                href="https://www.mcafeesecure.com/verify?host=www.myfreescorenow.com"
                                onClick={handleClick}
                                title="Click to Verify - This site has chosen an SSL Certificate to improve Web site security"
                                rel="noopener noreferrer">
                                <img src={require("../assets/images/mcafee.gif")} />&nbsp;&nbsp;&nbsp;
                            </a> */}
                            <a href={config.BASE_URL + 'enroll?pid=' + pid + '&aid=' + aid + '&sid=&tid=&adid='} target='_blank' rel="noopener noreferrer"><img alt="#" src={require("../assets/images/homebtn.png")} /></a>
                            {/* <ComodoSecureSeal /> */}
                            {/* <img src={require("../assets/images/mainsecure.png")} /> */}
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} pb={5} alignItems='center' sx={{ justifyContent: "center", margin: 0 }} className='display_block'>
                            <p className='check' style={{ textAlign: "center" }}><b>Checking your credit will not harm your score!</b></p>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "contents", textAlign: "center", lineHeight: 1.5 }} className='banner-text-hide'>
                            {/* <Typography level="body" className="bannerText"><b>❝IT IS ALWAYS THE RIGHT TIME TO DO THE RIGHT THING!❞</b></Typography> */}
                            {/* <Typography level="body" className="bannerText1"><b>- MARTIN LUTHER KING</b></Typography> */}

                            {/*<Typography level="body" sx={{color: "#01a49c", fontSize:'1.5rem'}}><b>NATIONAL</b></Typography>
                            <Typography level="body" sx={{color: "#d4ab03", fontSize:'2.5rem'}}><b>HISPANIC</b></Typography>
                            <Typography level="body" sx={{color: "#b40d73", fontSize:'1.5rem'}}><b>HERITAGE MONTH</b></Typography>
                            <Typography level="body" sx={{color: "#ffffff", fontSize:'1rem'}}><b>September 15 to October 15</b></Typography>*/}
                        </Grid>
                    </Grid>
                </Box>
                <Grid p={2}>
                    <Box sx={{
                        display: 'grid',
                        alignItems: 'center',
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: 0,
                        bgcolor: 'background.paper',
                        color: 'text.secondary',
                    }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={3} md={3} sm={12} xs={12} p={1}>
                                <Typography level="body1" fontSize="xl" sx={{ textAlign: "center" }}>
                                    {offerdetails ? offerdetails.days === 0 ? "No Free Trial period" : offerdetails.days + "-day trial ends " + futureDate : ""}
                                </Typography>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid item lg={5} md={5} sm={12} xs={12} p={1}>
                                <Typography level="body1" fontSize="xl" sx={{ textAlign: "center" }}>{offerdetails ? offerdetails.hasFee === "None" ? "" : offerdetails.hasFee + " non-refundable member activation fee charged at time of order." : ""} <br /><b>{offerdetails ? offerdetails.price : ""} per month charged automatically thereafter.</b></Typography>
                            </Grid>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid item lg={3} md={3} sm={12} xs={12} p={1}>
                                <Typography level="body1" fontSize="xl" sx={{ textAlign: "center" }}>For questions or to cancel,<br /> just call<b> 888 548-2008</b></Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} p={2}>
                    {/* <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'stretch',
                        gap: 7,
                        borderRadius: 1,
                        justifyContent: 'center',
                    }}
                    > */}
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Card variant="outlined" className="homeres1" sx={{ minHeight: 500, maxHeight: 600, borderRadius: "0%" }}>
                            <Typography level="h3" sx={{ textAlign: "center" }}>See Articles And News About:</Typography>
                            <Grid container item lg={12} md={12} sm={12} xs={12} className='ulli'>
                                {/* <Grid item lg={6} md={6} sm={6} xs={6}> */}
                                <ul style={styles.ulli}>
                                    <li><a href="/Blogs/Category/Credit Scores" style={{ textDecoration: "none" }} rel="noopener noreferrer"><Typography level="body1" fontSize="xl" sx={{ color: "#2A68B3", mb: 0.5 }}>Credit Scores</Typography></a></li>
                                    <li><a href="/Blogs/Category/Credit Reports" style={{ textDecoration: "none" }} rel="noopener noreferrer"><Typography level="body1" fontSize="xl" sx={{ color: "#2A68B3", mb: 0.5 }}>Credit Reports</Typography></a></li>
                                    <li><a href="/Blogs/Category/Credit Monitoring" style={{ textDecoration: "none" }} rel="noopener noreferrer"><Typography level="body1" fontSize="xl" sx={{ color: "#2A68B3", mb: 0.5 }}>Credit Monitoring</Typography></a></li>
                                    <li><a href="/Blogs/Category/Identity Theft" style={{ textDecoration: "none" }} rel="noopener noreferrer"><Typography level="body1" fontSize="xl" sx={{ color: "#2A68B3", mb: 0.5 }}>Identity Theft</Typography></a></li>
                                </ul>
                                {/* </Grid> */}

                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2} className='ulli'>
                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <img alt="#" src={require("../assets/images/homebook.png")} className="book" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                                </Grid>
                                <Grid item lg={8} md={8} sm={6} xs={6}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        <Typography level="h6" sx={{ fontWeight: 500 }}>Be An Educated Consumer</Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        <a href="/credit-info" style={{ textDecoration: "none" }} rel="noopener noreferrer"><Typography level="h6" sx={{ color: "#2A68B3" }}>Visit Our Learning Center »</Typography></a>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                                <Typography level="body1" fontSize="lg" sx={{ fontWeight: 500 }} >Connect With Us </Typography>&nbsp;
                                <img alt="#" src={require("../assets/images/facebook1.png")} />&nbsp;&nbsp;
                                <img alt="#" src={require("../assets/images/twitterNew.png")} />
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                                <Typography level="h6" sx={{ fontWeight: "bold", color: "#2A68B3" }}>3-Bureau 3-Score Report Option Is Available</Typography>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Card variant="outlined" className="homeres1" sx={{ minHeight: 500, maxHeight: 600, borderRadius: "0%" }}>
                            <Typography level="h3" sx={{ textAlign: "center" }}>Receive Instantly</Typography>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <ul>
                                    <li><Typography level="body1" fontSize="lg" sx={{ mb: 0.4 }}>Today’s credit Scores & credit Reports from All 3 Bureaus delivered in seconds!</Typography></li>
                                    <li><Typography level="body1" fontSize="lg" sx={{ mb: 0.4 }}>A personalized report of factors affecting your credit score</Typography></li>
                                    <li><Typography level="body1" fontSize="lg" sx={{ mb: 0.4 }}>Click to communicate with and resolve issues directly with your creditors.</Typography></li>
                                    <li><Typography level="body1" fontSize="lg" sx={{ mb: 0.4 }}>See who is looking at your credit</Typography></li>
                                    <li><Typography level="body1" fontSize="lg" sx={{ mb: 0.4 }}><i>Unlimited </i>daily updates of your TransUnion score and report</Typography></li>
                                </ul>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3} sx={{ justifyContent: "center" }}>
                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <img alt="#" src={require("../assets/images/chatbubble.png")} style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%", height: "auto" }} />
                                </Grid>
                                <Grid item lg={8} md={8} sm={6} xs={6}>
                                    <Typography level="h4">It Never Hurts To Look</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                                <Typography level="h6" sx={{ fontWeight: "bold", color: "#2A68B3" }}>Checking Your Credit Won't Lower Your Credit Score</Typography>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Card variant="outlined" className="homeres" sx={{ borderRadius: "0%" }}>
                            <Typography level="h3" sx={{ textAlign: "center" }}>Know Your Score</Typography>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                <img alt="#" src={require("../assets/images/range.png")} className='scoreimg' style={{ width: "auto", height: "70%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                                <Typography level="h4">How Do You Stack Up?</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                                <Typography level="h6" sx={{ fontWeight: "bold", color: "#2A68B3" }}>The Average US Credit Score Is 667</Typography>
                            </Grid>
                        </Card>
                    </Grid>
                    {/* </Box> */}
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} p={2}>
                    <Card>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg"><b>Free Credit Score Report</b></Typography>
                                <br />
                                <Typography level="body1" fontSize="lg" sx={{ mb: 0.9 }}>
                                    Get a <a href={config.BASE_URL + 'enroll?pid=' + pid + '&aid=seo&sid=&tid=&adid='} target='_blank' style={{ color: "#3097d1", textDecoration: "none" }} rel="noopener noreferrer">free credit score</a> with your free trial membership at MyFreeScoreNow.com.
                                    Your membership includes your free credit score based on your current <a href={config.BASE_URL + 'enroll?pid=' + pid + '&aid=seo&sid=&tid=&adid='} target='_blank' style={{ color: "#3097d1", textDecoration: "none" }} rel="noopener noreferrer">credit report.</a>
                                    Plus, you will receive email alerts when there are  significant changes to your credit report.
                                    And, you will get an updated credit score each month your membership is active.
                                </Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg"><b>Why Does Your Credit Score Matter?</b></Typography>
                                <br />
                                <Typography level="body1" fontSize="lg" sx={{ mb: 0.9 }}>
                                    Your free credit score and report can have a significant impact on your life.
                                    With today’s technology, scores can be used day and night to make an instant assessment of your creditworthiness.
                                    Knowing and understanding your credit score gives you a financial edge that could put money in your pocket through lower interest rates and lower monthly payments.
                                </Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg"><b>What Is a Good Credit Score?</b>
                                </Typography>
                                <br />
                                <Typography level="body1" fontSize="lg" sx={{ mb: 0.9 }}>
                                    Because so many different credit scoring models are used, there is no single "good" credit score.
                                    Far more important than the number is how you rank on the particular scale being used. Because scoring models in general take the same things into consideration (primarily the information on your free credit report), a good credit rating on one scale will usually translate to a good credit rating on another scale.
                                </Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg"><b>Who Can View My Credit Report?</b>
                                </Typography>
                                <br />
                                <Typography level="body1" fontSize="lg" sx={{ mb: 0.9 }}>
                                    The Fair Credit Reporting Act (FCRA) protects the privacy of your credit report.
                                    By law, however, your report may be released to persons or organizations which have a legitimate reason to view your report.
                                    In most instances, you must grant permission for your free credit report to be accessed.
                                    Granting such permission is often in the fine print of credit or employment applications.
                                </Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg"><b>About Annual Free Credit Reports</b>
                                </Typography>
                                <br />
                                <Typography level="body1" fontSize="lg" sx={{ mb: 0.9 }}>
                                    Under federal law you have the right to receive a free credit report from each of the three nationwide consumer reporting agencies once every 12 months from <a href="https://www.annualcreditreport.com/index.action" style={{ color: "#3097d1", textDecoration: "none" }} rel="noopener noreferrer">AnnualCreditReport.com</a> or by calling 877-322-8228. However,a free  <a href={config.BASE_URL + 'enroll?pid=' + pid + '&aid=seo&sid=&tid=&adid='} target='_blank' style={{ color: "#3097d1", textDecoration: "none" }} rel="noopener noreferrer">credit score</a> is not included.
                                    Since information in your credit file can change frequently, it can be a good investment to keep tabs on your credit score and credit report more frequently by using a credit monitoring service.
                                </Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg"><b>Order Your Free Credit Score</b>
                                </Typography>

                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg" sx={{ mb: 0.9 }}>
                                    Get a <a href={config.BASE_URL + 'enroll?pid=' + pid + '&aid=seo&sid=&tid=&adid='} target='_blank' style={{ color: "#3097d1", textDecoration: "none" }} rel="noopener noreferrer">free credit check </a> on yourself today.
                                    Order your <a href={config.BASE_URL + 'enroll?pid=' + pid + '&aid=seo&sid=&tid=&adid='} target='_blank' style={{ color: "#3097d1", textDecoration: "none" }} rel="noopener noreferrer">Free Credit Score</a> ,and you will be enrolled in our credit monitoring membership for a free trial period.
                                    You can cancel at any time.
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default Home;
