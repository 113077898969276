import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import config from '../../../AppConfig';
import { fetchData, postData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from "react-router-dom";
function CreditScoreChild() {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [blogData, setBlogs] = useState();
    const fetchBlogData = async () => {
        const apiUrl = '/blog/' + url ;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            // console.log(res);
            setBlogs([res.data]);
            // alert(JSON.stringify(res.data))
        }
    }
    useEffect(() => {
        fetchBlogData();
    }, [url])
    return (
        <>
            <LoginHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    {/* <a href="">Back to blog</a> */}
                </Grid>
                {/* <div>{blogData.map(item=>{
                   return( <p>{item.title}</p>)
                })}</div> */}
                {blogData && blogData.map((blog,index) => {
                    return(
                        <div key={index}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Typography level="h4" color='primary' >{blog.title}</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="title-md">Posted on {new Date(blog.created_at).toLocaleString()}</Typography>
                    </Grid>
                    <p dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                    </div>
                    )
                })}
            </Box>
            <RegisterFooter />
        </>
    );
}

export default CreditScoreChild;