
import React, { useState, useRef, useEffect } from "react";
import { Grid, Button, TextField, Box, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardOverflow } from '@mui/joy';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import SignatureModal from '../SignatureModal/SignatureModal';
function AffiAgreement13({ formData, handleFormChange, errors, handleBlur, validationSchema1, adminSignature, name, status, signature1, currentDate1 }) {

    const [showModal, setShowModal] = useState(false);
    const [signature2, setSignature2] = useState(null);
    const [signature3, setSignature3] = useState(null);
    const [signatureLabel, setSignatureLabel] = useState('Signature');
    const [currentDate, setCurrentDate] = useState("");
    const [firstName, setFirstName] = useState(name);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const [signature, setSignature] = useState(null);
    const handleSaveSignature2 = (sig) => {
        setSignature2(sig);
        handleFormChange('signature2', sig);
        closeModal();
    };
    const handleSaveSignature1 = (sig) => {
        // setSignature3(sig);
        handleFormChange('signature1', sig);
        closeModal();
    };
    useEffect(() => {
        setFirstName(name);
        setSignature2(adminSignature)
    }, [name, adminSignature])

    useEffect(() => {
        setSignature(signature1)
    }, [signature1])
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Grid item lg={12} md={12} sm={12} xs={12} mb={1}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        For MyFreeScoreNow, Inc.
                                    </Typography>
                                </Grid>

                                <Formik
                                    initialValues={formData}
                                    enableReinitialize={true} // This ensures that Formik updates the form values when formData changes
                                >

                                    {({ handleChange }) => (
                                        <>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }} pb={1}>
                                                    <Typography variant="h6">Signed By:</Typography>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} mb={1} className="padadoc-sign-field">

                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: status == 1 ? true : false,
                                                            style: {
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                padding: '0.5rem',
                                                                width: '100% !important',
                                                                borderRadius: '4px',
                                                                height: 'auto',
                                                            },
                                                            startAdornment: (
                                                                <Box display="flex" alignItems="center" gap={1} disabled={true}>
                                                                    {signature2 ? (
                                                                        <Box
                                                                            className="signature-preview"
                                                                            style={{ cursor: 'pointer' }}
                                                                            disabled={true}
                                                                        >
                                                                            <img
                                                                                src={signature2}
                                                                                alt="Signature"
                                                                                style={{ width: '100%', height: 'auto' }}
                                                                            />
                                                                        </Box>
                                                                    ) : (
                                                                        <Button
                                                                            className="signature-button"
                                                                            color="primary"
                                                                            startIcon={<span className="signature-icon">✍</span>}
                                                                            disabled={true}
                                                                        >
                                                                            {signatureLabel}
                                                                        </Button>
                                                                    )}
                                                                </Box>
                                                            ),
                                                        }}
                                                    />
                                                    {showModal && <SignatureModal onSave={handleSaveSignature2} onClose={closeModal} firstName={firstName} />}

                                                </Grid>
                                            </Grid>

                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                    <Typography variant="h6">Name: Bruce J Cornelius</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                    <Typography variant="h6">Title: President</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                    <Typography variant="h6">Date</Typography>
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                                                        <DemoContainer components={['DatePicker']} sx={{ width: '100%', display: 'grid' }}>
                                                            <DatePicker
                                                                //   disableOpenPicker={status==1?true:false }
                                                                disabled={true}
                                                                // label="Follow Up Date"
                                                                margin="dense"
                                                                name="date1"
                                                                fullWidth
                                                                value={formData.date1}
                                                                onChange={(e) => {
                                                                    // handleChange(e);
                                                                    handleFormChange('date1', e);
                                                                }}
                                                                onBlur={(e) => handleBlur('date1', e)}
                                                            />

                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {errors.date1 ? <div className='error'>{errors.date1}</div> : null}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Formik>
                            </Grid>
                            <Formik
                                initialValues={formData}
                                enableReinitialize={true} // This ensures that Formik updates the form formData when formData changes
                            >
                                {({ handleChange }) => (
                                    <>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={4} >
                                            <Grid item lg={12} md={12} sm={12} xs={12} mb={1}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                    Referrer
                                                </Typography>
                                            </Grid>

                                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }} pb={2}>
                                                    <Typography variant="h6">Name of Business:</Typography>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <TextField
                                                        inputProps={{ readOnly: true }}
                                                        label="Business Name*"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='BusinessName1'
                                                        sx={{ marginTop: '0' }}
                                                        value={formData.BusinessName1}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            handleFormChange('BusinessName1', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('BusinessName1', e.target.value)}
                                                        error={Boolean(errors.BusinessName1)}
                                                        helperText={errors.BusinessName1}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={1} spacing={1}>

                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }} pb={2}>
                                                        <Typography variant="h6">First Name:</Typography>
                                                    </Grid>
                                                    <TextField
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        label="First Name*"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='FirstName3'
                                                        sx={{ marginTop: '0' }}
                                                        value={formData.FirstName3}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            handleFormChange('FirstName3', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('FirstName3', e.target.value)}
                                                        error={Boolean(errors.FirstName3)}
                                                        helperText={errors.FirstName3}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }} pb={2}>
                                                        <Typography variant="h6">Last Name:</Typography>
                                                    </Grid>
                                                    <TextField
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        label="Last Name*"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='LastName3'
                                                        sx={{ marginTop: '0' }}
                                                        value={formData.LastName3}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            handleFormChange('LastName3', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('LastName3', e.target.value)}
                                                        error={Boolean(errors.LastName3)}
                                                        helperText={errors.LastName3}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }} pb={2}>
                                                    <Typography variant="h6">Title:</Typography>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} mb={1}>
                                                    <TextField
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        label="Title"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='Title'
                                                        sx={{ marginTop: '0' }}
                                                        value={formData.Title}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            handleFormChange('Title', e.target.value);
                                                        }}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    />

                                                </Grid>
                                            </Grid>

                                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }} pb={1}>
                                                    <Typography variant="h6">Phone:</Typography>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <InputMask
                                                    mask='(999) 999-9999'
                                                    maskChar={null}
                                                                inputProps={{ readOnly: status == 1 ? true : false }}
                                                                label="Phone*"
                                                                type="text"
                                                                size="small"
                                                                fullWidth
                                                                margin="dense"
                                                                name='Phone1'
                                                                value={formData.Phone1}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    const value = e.target.value;
                                                                    if (/^\d*$/.test(value)) { // Regex to allow only digits
                                                                        handleFormChange('Phone1', value);
                                                                    }
                                                                }}
                                                                onBlur={(e) => handleBlur('Phone1', e.target.value)}
                                                                error={Boolean(errors.Phone1)}
                                                                helperText={errors.Phone1}
                                                                InputLabelProps={{ className: 'label-to-hide' }}
                                                            >
                                                                   {(inputProps) =>
                                                        <TextField
                                                        label="Phone Number" variant="outlined"
                                                        {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>


                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                    <Typography variant="h6">Date</Typography>
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                                                        <DemoContainer components={['DatePicker']} sx={{ width: '100% !important', display: 'grid !important' }}>
                                                            <DatePicker
                                                                readOnly={status == 1 ? true : false}
                                                                disableOpenPicker={status == 1 ? true : false}
                                                                margin="dense"
                                                                name="date2"
                                                                fullWidth
                                                                value={formData.date2 ? dayjs(formData.date2) : null}
                                                                onChange={(e) => {
                                                                        handleFormChange('date2', e);
                                                                        handleFormChange('date1', e);
                                                                }}
                                                                onBlur={(e) =>{ 
                                                                    handleFormChange('date2', e);
                                                                    handleFormChange('date1', e);
                                                                }}
                                                                error={Boolean(errors.date2)}
                                                                slotProps={{
                                                                    textField: {
                                                                        name: 'date2',
                                                                    }
                                                                }}
                                                                minDate={dayjs(currentDate1)}
                                                            />

                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {errors.date2 && <div className='error'>{errors.date2}</div>}
                                                </Grid>
                                            </Grid>

                                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={1} mt={1} className="padadoc-sign-field">
                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 'auto' }} pb={1}>
                                                    <Typography variant="h6">Signed By:</Typography>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}   name="signature1">
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        aria-disabled={status == 1 ? true : false}
                                                        name="signature1"
                                                        error={Boolean(errors.signature1)}
                                                        helperText={errors.signature1}
                                                        InputProps={{
                                                            // readOnly: true,
                                                            style: {
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                padding: '0.5rem',
                                                                width: '100% !important',
                                                                borderRadius: '4px',
                                                                height: 'auto',
                                                            },
                                                            startAdornment: (
                                                                <Box display="flex" alignItems="center" gap={1} disabled={status == 1 ? true : false}>
                                                                    {formData.signature1 ? (
                                                                        <Box
                                                                            className="signature-preview"
                                                                            style={{ cursor: 'pointer' }}
                                                                            disabled={status == 1 ? true : false}
                                                                            onClick={() => {
                                                                                if (status != 1) {
                                                                                    openModal();
                                                                                }
                                                                            }} 
                                                                        >
                                                                            <img
                                                                                src={formData.signature1}
                                                                                alt="Signature"
                                                                                style={{ width: '100%', height: 'auto' }}
                                                                            />
                                                                        </Box>
                                                                    ) : (
                                                                        <Button
                                                                            disabled={status == 1 ? true : false}
                                                                            className="signature-button"
                                                                            color="primary"
                                                                            startIcon={<span className="signature-icon">✍</span>}
                                                                            onClick={() => {
                                                                                if (status != 1) {
                                                                                    openModal();
                                                                                }
                                                                            }} 
                                                                       >
                                                                            {signatureLabel}
                                                                        </Button>
                                                                    )}
                                                                </Box>
                                                            ),
                                                        }}
                                                    />
                                                     {showModal && <SignatureModal onSave={handleSaveSignature1} onClose={closeModal} firstName={firstName} />}
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </>
                                )}
                            </Formik>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement13;