import React, { useEffect, useState, useRef, } from 'react';
import { Grid, Step, Stepper, StepLabel, DialogContent, Divider, DialogContentText, Button, TextField, FormGroup, FormControlLabel, Checkbox, FormControl, FormLabel, Tooltip, RadioGroup, Radio, Table, FormHelperText, Box, Paper } from '@mui/material';
import SecureWebsite from "../../../Components/Enrollment/SecureWebsite";
const Step4 = ({ btnTextColor,primaryColor, textColorProp, popupFlage,hyperlinkColor }) => {
    const [textColor, setTextColor] = useState()

    useEffect(() => {
        setTextColor(textColorProp)
    }, [textColorProp])
    return (
        <>
            <div style={{ pointerEvents: 'none' }}>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <TextField size='small' label="Card Number" margin='dense' fullWidth sx={{
                       label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`, backgroundColor: 'white' }, // Styles the fieldset
                    }} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <TextField size='small' label="CVV" margin='dense' fullWidth sx={{
                       label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`, backgroundColor: 'white' }, // Styles the fieldset
                    }} />
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControl margin="dense" variant="outlined" fullWidth >
                            <TextField
                                label="Expiration Month"
                                sx={{
                                   label: { color: `${primaryColor}` },
                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`, backgroundColor: 'white' }, // Styles the fieldset
                                }}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControl margin="dense" variant="outlined" fullWidth >
                            <TextField
                                label="Expiration Year"
                                sx={{
                                    label: { color: `${primaryColor}` },
                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`, backgroundColor: 'white' }, // Styles the fieldset
                                }}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <div className="form-group">
                        <div className=" pull-right input-group checkbox">
                            <label htmlFor="isConfirmedTerms" style={{ color: `${textColor}` }}>
                                <input type="checkbox"
                                    style={{
                                        appearance: 'none', // Hide the default appearance
                                        width: '12px',
                                        height: '12px',
                                        border: `1px solid ${primaryColor}`, // Border color for unchecked state
                                        borderRadius: '2px',
                                        backgroundColor: `${primaryColor}`, // Background color when checked
                                        backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23fff\'><path d=\'M13.6 1.6L5 10.2 2.4 7.6 1 9l4 4 9-9-1.4-1.4z\'/></svg>")', // Checkmark background image when checked
                                        color: 'white',
                                        marginRight: '5px', // Adjust spacing
                                        cursor: 'pointer', // Show pointer cursor on hover
                                    }}
                                />
                                &nbsp;My billing address is same as current address
                            </label>
                        </div>
                    </div>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <div className="form-group">
                        <div className=" pull-right input-group checkbox">
                            <label htmlFor="isConfirmedTerms" style={{ color: `${textColor}` }}>
                                <input type="checkbox"
                                    style={{
                                        appearance: 'none', // Hide the default appearance
                                        width: '12px',
                                        height: '12px',
                                        border: `1px solid ${primaryColor}`, // Border color for unchecked state
                                        borderRadius: '2px',
                                        color: 'white',
                                        marginRight: '5px', // Adjust spacing
                                        cursor: 'pointer', // Show pointer cursor on hover
                                    }}
                                />
                                &nbsp;By checking this box: I acknowledge that I have read, understand, agree to and accept the &nbsp;
                                <a style={{ whiteSpace: 'nowrap', cursor: "pointer" ,color:`${hyperlinkColor}`}} href="#">
                                    Offer Details </a>&nbsp;,&nbsp;
                                    <a style={{ whiteSpace: 'nowrap', cursor: "pointer" ,color:`${hyperlinkColor}`}} href="#">
                                    Terms and Conditions </a>&nbsp;,&nbsp;
                                <a href="#" style={{ cursor: "pointer",color:`${hyperlinkColor}` }}>
                                    Cancellation Policy
                                </a>, and&nbsp;
                                <a href="#" style={{ cursor: "pointer",color:`${hyperlinkColor}` }}>
                                    Privacy Policy
                                </a> .
                            </label>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "right", display: "flex" }}>
               <Button variant='contained' size="large" sx={{
                        minWidth: "60%", backgroundColor: `${primaryColor}`, color: `${btnTextColor}`,
                        '&:hover': {
                            backgroundColor: `${primaryColor}`
                        }
                    }} className='popup-next-btn'>
                        Submit My Order<br />Take Me to My Scores
                    </Button>
                </Grid>
                <SecureWebsite textColor={textColorProp} hyperlinkColor={hyperlinkColor}/> 
            </div>
        </>
    );
}
export default Step4;