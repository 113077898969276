import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination, button } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import config from '../../../AppConfig';
import Sidebar from '../../../Components/Blogs/Sidebar';
function CreditMonitoring() {
    const [showMoreStates, setShowMoreStates] = useState({});

    const toggleText = (id) => (showMoreStates[id] ? 'Close' : 'Read more');

    const handleToggle = (id) => {
        setShowMoreStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };
    return (
        <>
            <LoginHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={2} md={2} sm={12} xs={12} >
                        <Sidebar />
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12} sx={{ padding: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <img src={require("../../../assets/images/mfsn_banner_4.jpg")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className='responsiveImg' />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='h4'>Credit Monitoring</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>A credit monitoring service enables consumers to track changes to their credit reports without continuously ordering new credit reports. While you go about the business of living your life, the credit monitoring service keeps tabs on your credit report and will alert you (usually by email) whenever there is a significant change to your report.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Credit Monitoring and Credit Management</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>It has been said that a chain is only as strong as its weakest link. So it is with credit management. Where does your focus need to be? What parts can you safely ignore or entrust to someone else? A credit monitoring service can handle the challenging task of keeping tabs on your credit report.</Typography>
                            {showMoreStates[1] && (
                                <div>
                                    <p>No one would deny that responsible credit management includes good credit habits. In fact, there is no single factor that impacts your credit score more than how you pay your bills. But, you can have the best credit habits and still be vulnerable if you aren’t monitoring your credit report.</p>
                                    <p>Information is constantly flowing in and out of your credit report. And that information is susceptible to human error. Your credit score, which is based on your credit report, is often used to determine the interest rate for home and automobile loans. A few points can sometimes make a big difference in your payment and the total amount of interest paid over the life of a loan. Responsible credit management demands staying on top of your credit report!</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(1)}>
                                {toggleText(1)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Credit Monitoring and Identity Theft</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Identity theft is, unfortunately, now a fact of life. You can no longer pretend it can’t happen to you. Credit monitoring is one of the most effective things you can do to protect yourself from the ravages of identity theft.</Typography>
                            {showMoreStates[2] && (
                                <div>
                                    <p>Early detection is key when it comes to fighting identity theft. One of the key findings in most any identity theft study is that the longer the identity theft goes undetected, the more damage is done, the greater the out-of-pocket expenses and the longer it takes to reverse the damage. Early fraud detection can save you money and time!</p>
                                    <p>A credit monitoring service alerts you within 24 hours whenever there are changes to your credit report that should be verified.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(2)}>
                                {toggleText(2)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Benefits of a Credit Monitoring Service</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>While ordering credit reports throughout the year is certainly better than nothing, it offers limited protection because your credit report is a snapshot of your credit at a particular moment in time.</Typography>
                            {showMoreStates[3] && (
                                <div>
                                    <p>You can scrutinize your credit report today, only to have it hit with fraud tomorrow. With a credit monitoring service, your credit report is monitored for changes every day of the year, and you are automatically notified when they occur - without any effort on your part. The key benefits to a credit monitoring service are:</p>
                                    <ul>
                                        <li>Convenience<br />A credit monitoring service gives you one less thing to think about and more time to do the things you really want to do without neglecting your credit. And, it is cost effective when you consider the cost of ordering additional credit reports throughout the year to truly stay on top of your credit report.</li>
                                        <li>Quick Detection of Fraudulent Activity<br />Credit monitoring is one of the most effective ways to protect yourself from the devastating effects of identity theft. Studies show that damage is most severe when fraudulent activity goes undetected for six months or more. In the case of identity theft, it often first shows up on your credit report. With the ability to notify you within 24 hours of something suspicious showing up on your credit report, a credit monitoring service can greatly minimize the potential damage.</li>
                                        <li>Accuracy<br />With credit monitoring, you know about important changes to your credit report as they happen, making it easy to detect and correct errors. Identifying and correcting these errors can help preserve your credit purchasing power.</li>
                                        <li>Peace of Mind<br />With a credit monitoring service, you have peace of mind from knowing your credit report is being watched around the clock and you will be alerted whenever there is a critical change to your report.</li>
                                    </ul>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(3)}>
                                {toggleText(3)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Why Choose MyFreeScoreNow</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>MyFreeScoreNow will alert you by email whenever significant changes occur on your credit report, including:</Typography>
                            {showMoreStates[4] && (
                                <div>
                                    <ul>
                                        <li>New accounts opened</li>
                                        <li>New public records</li>
                                        <li>Changes to public records</li>
                                        <li>Changes to account information</li>
                                        <li>Inquiries to your credit file</li>
                                        <li>Address changes</li>
                                    </ul>
                                    <p>Each email alert will link you to our secure members’ area where details of the change will be available.</p>
                                    <p>You can try MyFreeScoreNow free when you order your free credit score. You are under no obligation to continue the service.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(4)}>
                                {toggleText(4)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Is MyFreeScoreNow Safe?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>At MyFreeScoreNow.com, we value the trust consumers place in us. We take every precaution to ensure that your personal information is secure and available only to you. Our website is secured by 128-bit SSL encryption technology-the industry standard-for all personal and financial data input.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Are you monitoring your credit?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={4}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' style={{ textDecoration: "none" }}><Typography level='title-md' sx={{ color: "#1976d2" }}>Get your free credit score and score report now and protect your credit.</Typography></a>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' className='blog'>Get your free credit score and score report now and protect your credit.</a>
                        </Grid> */}
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <img src={require("../../../assets/images/lt_banner_5.png")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default CreditMonitoring;