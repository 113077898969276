
import React,{useMemo,useState,useEffect} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';

import DefaultLayout from '../../../Components/DefaultLayout';
import {Box,Grid,Table, TableBody, TableCell,Dialog,DialogTitle,DialogContent,
  TableContainer, TableHead, TableRow, Paper,Button,Tooltip} from '@mui/material';
import { Typography } from '@mui/joy';
import { useTheme } from '@mui/material/styles';
import AuthUser from '../../../Components/Auth/AuthUser';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import { useDispatch } from 'react-redux';
import { fetchData} from '../../../redux/apiSlice';
import MaterialReactTable from 'material-react-table';
import config from '../../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { currencyFormat } from '../../../Components/CommonFormula';
import Moment from 'moment';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/CheckBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';




function SoftwareCommissionPayments(props) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const {user } = AuthUser();
    const [freez, setFreez] = React.useState(true);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const navigate = useNavigate();
    const [softwareName, setSoftwareName] = useState();
    const [softwareLinkName, setSoftwareLinkName] = useState();
    const [bank_details, setBankDetails] = useState([]);
    const [openbank, setOpenbank] = useState(false);
    const handlebankClose = () => setOpenbank(false);
    const handlebankOpen = () => setOpenbank(true);
    const [filterhistory, setFilterHistory] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const location = useLocation();

    const customSortCommission = (rowA, rowB) => {
      const a = rowA.original.paidCommission;
      const b = rowB.original.paidCommission;
      return a - b;
    };

    const fetchCommissionPayments = async() => {
      setFreez(true);
      const apiUrl = '/software-commission-payments';
      const res = await dispatch(fetchData(apiUrl)).unwrap();
      if(res) {
          if(res.success == true) {
              setData(res.data);
              
          }
          handleFreezStop();
      }
                  
    } 

    useEffect(() => {      
      if(user.roles_id < 3) {
        fetchCommissionPayments();
      }else{
        navigate('/login');
      }
      
     
    }, []);

    const customSortFunction = (rowA, rowB) => {
      const colorPriority = {
        blue: 0,
        red: 1,
        null: 2,
        grey:2,
        green: 3,
      };
      const statusA = rowA.original.bankStatus;
      const statusB = rowB.original.bankStatus;
      const priorityA = colorPriority[statusA];
      const priorityB = colorPriority[statusB];
      return priorityA - priorityB;
    };

    const viewBankDetails = (linkName) => {
      const loginUser = JSON.parse(localStorage.getItem('user'));
      if(loginUser.roles_id  > 2) {
        navigate('/login');
        return false;
      }
      window.location.href= config.BASE_URL + 'software/bankdetails/' + linkName ;
    }

    const fetchCompanyBankDetails = async (id, softwareName,linkName) => {
      setSoftwareName(softwareName);
      setSoftwareLinkName(linkName);
      const loginUser = JSON.parse(localStorage.getItem('user'));
      if(loginUser.roles_id  > 2) {
          navigate('/login');
          return false;
      }
      const apiUrl = '/fetch-software-bank-details/' + linkName;
      const res = await dispatch(fetchData(apiUrl)).unwrap();
      if (res) {
        if (res.softwareBankDetails) {
          setBankDetails(res.softwareBankDetails);
          setFilterHistory(Object.values(res.updatedAllAttributes));
        } else {
          setBankDetails([]);
          setFilterHistory([]);
        }
      }
      handlebankOpen();
    };

    const columns = useMemo(
      //column definitions...
      () => [
        {
          accessorFn: (row) => `${row.softwareName ? row.softwareName : ''}`,
          id:'softwareName',
          header: 'Software Name',
          size: 50,
          Cell:({renderedCellValue,row}) =>(row.original.softwareName ),
        },
        {
          accessorFn: (row) => `${row.paidCommission}`,
          id:'commission',
          header: 'Total Paid ($)',
          sortingFn: customSortCommission, 
          size: 50,
          Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.paidCommission))),
        },
        {
          accessorFn: (data) => `${data.email ? data.email.trim() : ''} `,   
          enableClickToCopy: true,
          id: 'communicationEmail',
          header: 'Communication Email',
          size: 110,
          Cell: ({ renderedCellValue, row }) => (
            row.original.email ? row.original.email.trim() : "N/A"
          ),
        },
        {
          accessorKey: 'last_payment_date',
          accessorFn: (data) => `${data.lastPaymentDate }`,
          enableClickToCopy: true,
          id: 'last_payment_date',
          header: 'Last Payment Date',
          size: 100,
          Cell:({renderedCellValue,row}) => (
            <div>
                {row.original.lastPaymentDate != null ? Moment(row.original.lastPaymentDate).format('MM-DD-YYYY') : 'N/A'}
             </div>          
           ),
        },
        {
          accessorKey: 'last_captured_date',
          accessorFn: (data) => (`${data.lastCaptureDate ? data.lastCaptureDate : ''}`),
          enableClickToCopy: true,
          id: 'last_captured_date',
          header: 'Last Captured Date',
          size: 100,
          Cell:({renderedCellValue,row}) => (
            <div>
                {row.original.lastCaptureDate != null ? Moment(row.original.lastCaptureDate).format('MM-DD-YYYY') : 'N/A'}
             </div>          
           ),
        },
        {
          accessorFn: (row) => `${row.bankStatus}`,
          id: 'account_flag',
          header: 'Bank Details Submitted',
          size: 150,
          sortingFn: customSortFunction, 
          //custom conditional format and styling
          Cell: ({ row }) => (
            <>
              <Box>
                {`${row.original.bankStatus}` == "green" ?
                  <Tooltip title="Bank Details Confirmed" arrow><CheckIcon color="success" /></Tooltip> :
                  `${row.original.bankStatus}` == "gray" ?
                    <Tooltip title="Bank Deatils not Submitted." arrow><CheckIcon color="disabled" /></Tooltip> :
                    `${row.original.bankStatus}` == "red" ?
                      <Tooltip title="Incorrect Bank Details" arrow><CheckIcon htmlColor="red" /></Tooltip> :
                      `${row.original.bankStatus}` == "blue" ?
                        <Tooltip title={"Bank Details Updated on " + Moment(`${row.original.updatedAt}`).tz('America/Los_Angeles').format('MM-DD-YYYY')} arrow><CheckIcon htmlColor="gold"/></Tooltip> :
                        <Tooltip title="Bank Deatils not Submitted." arrow><CheckIcon color="disabled" /></Tooltip>}
              </Box>
            </>
          ),
        },
        {
          accessorKey: 'ach_info_added_to_bank',
          accessorFn: (row) => `${row.lastAchUpdated}`,
          id: 'ach_added_to_bank',
          header: 'ACH Added To Bank',
          size: 150,
          //custom conditional format and styling
          Cell: ({ row }) => (
            <>
              <div>
                {`${row.original.achInfoAddedToBank}` == 1 ?
                  (`${row.original.capturePaymentDate}` == 1 ? <div> <Tooltip title="ACH Added" arrow><CheckIcon color="success" /></Tooltip> &nbsp;&nbsp;{Moment(`${row.original.lastAchUpdated}`).format('MM-DD-YYYY')}</div>
                    : (`${row.original.achupdateflag}` == 1 ? <div><Tooltip title={"ACH Added"} arrow><AccessTimeIcon color="black" /></Tooltip> &nbsp;&nbsp;{Moment(`${row.original.lastAchUpdated}`).format('MM-DD-YYYY')}</div>
                      : <div><Tooltip title="ACH Added" arrow><CheckIcon htmlColor="purple" /></Tooltip> &nbsp;&nbsp;{Moment(`${row.original.lastAchUpdated}`).format('MM-DD-YYYY')}</div>))
                  : <Tooltip title={"ACH Not Added"} arrow><CheckIcon color="disabled" /></Tooltip>}
              </div>
            </>
          ),
        },
        {
          accessorKey: 'accountType',
          accessorFn: (row) => `${row.accountType}`,
          enableClickToCopy: true,
          id: 'account_type',
          header: 'Account Type',
          size: 100,
          Cell: ({ renderedCellValue,row }) => (
            <div>
                  {(row.original.accountType != null) ? row.original.accountType != "" ? row.original.accountType : 'N/A' : 'N/A'}
              
            </div>
          ),
        },
        {
          accessorKey: 'nameOnAccount',
          accessorFn: (row) => `${row.nameOnAccount !== null ? row.nameOnAccount.trim() !== "" ? row.nameOnAccount.trim() : '' : ''}`,
          enableClickToCopy: true,
          id: 'name_on_account',
          header: 'Name On Account',
          size: 100,
          Cell: ({ renderedCellValue,row }) => (
            <>
                  {row.original.nameOnAccount !== null ? row.original.nameOnAccount.trim() !== "" ? row.original.nameOnAccount.trim() : 'N/A' : 'N/A'}
              
            </>
          ),
        },
        {
          accessorFn: (row) => `${row}`,
          id: 'bank_action',
          header: 'Bank Action',
          size: 150,
          enableSorting: false, 
          enableColumnActions: false,
          //custom conditional format and styling
          Cell: ({ row }) => (
            <>
              <Box>
                  <>
                      <Tooltip title="View" arrow><VisibilityIcon color="primary" onClick={() => { fetchCompanyBankDetails(`${row.original.softwareId}`, `${row.original.softwareName}`,`${row.original.linkName}`) }} /></Tooltip>
                      {<Tooltip title="Edit" arrow><EditIcon color="primary" onClick={() => viewBankDetails(`${row.original.linkName}`)} /></Tooltip>}
                  </>
              </Box>
            </>
          ),
        },
        {
          accessorFn: (data) => `${data}`,
          id: 'action',
          header: 'Action',
          enableSorting: false,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Box>
              <>
                <Tooltip title="Details" arrow><a className='btn btn-success text-white' href={config.BASE_URL+"software-payment-details/"+row.original.softwareId} ><img src={require('../../../assets/images/details.png')} /></a></Tooltip>
              </>
            </Box>
          ),
  
        },
      ]);

    
    const columns1 = useMemo(
      () => [
          {
              accessorFn: (row) => `${row.id}`, //accessorFn used to join multiple data into a single cell
              id: 'id', //id is still required when using accessorFn instead of accessorKey
              header: 'ID',
              numeric: true,
              size: 150,
              Cell: ({ renderedCellValue, row }) => (
                  <Box
                      sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                      }}
                  >
                      <span>{row.original.id}</span>
                  </Box>
              ),
          },
          { 
            accessorFn: (row) => `${row.name}`,
  
              id: 'updated_by', //id is still required when using accessorFn instead of accessorKey
              header: 'Updated By',
              size: 150,
              Cell: ({ renderedCellValue, row }) => (
                  <Box
                      sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                      }}
                  >
                      <span>{row.original.name}</span>
                  </Box>
              ),
          },
          {
            accessorFn: (row) => `${row.role_name}`,
              id: 'user_role', //id is still required when using accessorFn instead of accessorKey
              header: 'User Role',
              size: 150,
              Cell: ({ renderedCellValue, row }) => (
                  <Box
                      sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                      }}
                  >
                      <span>{row.original.role_name}</span>
                  </Box>
              ),
          },
          {
            accessorFn: (row) => `${row.attributes}`,
              id: 'updated_fields', //id is still required when using accessorFn instead of accessorKey
              header: 'Updated Fields',
              size: 150,
              Cell: ({ renderedCellValue, row }) => (
                  <Box
                      sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                      }}
                      className="datatable"
                  >
                      <span>{
                      row.original.attributes ?
                          row.original.new_values ? 
                              row.original.new_values.split(',').length != row.original.attributes.split(',').length ? 
                                row.original.attributes.replace(",ACH Date", '') 
                              : row.original.attributes 
                          : row.original.attributes
                      : "No fields has been updated in this operation."}</span>
                  </Box>
              ),
          },
          {
            accessorFn: (row) => `${row.attributes}`,
            id: 'last_ach_updated', //id is still required when using accessorFn instead of accessorKey
            header: 'Added ACH Date',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                  <span>{
          row.original.attributes ? 
              row.original.new_values ? 
              row.original.attributes.includes("ACH Date") ? 
                row.original.new_values.split(',').length == row.original.attributes.split(',').length ? 
                  (row.original.new_values.split(',')[row.original.new_values.split(',').length - 2] != 0 ? 
                    Moment(row.original.new_values.split(',')[row.original.new_values.split(',').length - 1]).format('MM-DD-YYYY') 
                  :'')
                : ''
              : ''
            : ''
          : '' }</span>
                </Box>
            ),
          },
          {
            accessorFn: (row) => `${Moment(row.created_at).format('MM-DD-YYYY')}`,
              id: 'updated_date', //id is still required when using accessorFn instead of accessorKey
              header: 'Updated Date',
              size: 150,
              Cell: ({ renderedCellValue, row }) => (
                  <Box
                      sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                      }}
                  >
                      <span>{Moment(row.original.created_at).format('MM-DD-YYYY')}</span>
                  </Box>
              ),
          },
  
  
  
  
      ],
      [],
    );

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Software Commission Payments Report',
      headers: ['Software Name', 'Total Paid ($)','Communication Email', 'Last Payment Date', 'Last Captured Date','Bank Details Submitted','ACH Added To Bank','Account Type', 'Name On Account'],
    };

    const handleExportData = (data) => {
      const exportedData = data.map((row) => {
        let bankStatusText;
        if (row.original.bankStatus === "green") {
          bankStatusText = "Bank Details Confirmed";
        } else if (row.original.bankStatus === "gray") {
          bankStatusText = "Bank Details not Submitted";
        } else if (row.original.bankStatus === "red") {
          bankStatusText = "Incorrect Bank Details";
        } else if (row.original.bankStatus === "blue") {
          bankStatusText = `Bank Details Updated on ${Moment(row.original.updated_at).format('MM-DD-YYYY')}`;
        } else {
          bankStatusText = "Bank Details not Submitted";
        }
        let achAddedText;
        if (row.original.achInfoAddedToBank === 1) {
          if (row.original.capturePaymentDate === 1) {
            achAddedText = `ACH Added ${Moment(row.original.lastAchUpdated).format('MM-DD-YYYY')}`;
          } else if (row.original.achupdateflag === 1) {
            achAddedText = `ACH Added ${Moment(row.original.lastAchUpdated).format('MM-DD-YYYY')}`;
          } else {
            achAddedText = `ACH Added ${Moment(row.original.lastAchUpdated).format('MM-DD-YYYY')}`;
          }
        } else {
          achAddedText = "ACH Not Added";
        }
        return {
          companyName: `${row.original.softwareName }` || 'N/A',
          paid: `${row.original.paidCommission}` || 'N/A',
          email:  `${row.original.email != null ? row.original.email : "N/A"}` || 'N/A',
          lastPaymentDate:`${moment(row.original.lastPaymentDate, 'YYYY-MM-DD', true).isValid() ? row.original.lastPaymentDate  :'N/A'}`,
          paymentDate:`${row.original.lastCaptureDate != null ? Moment(row.original.lastCaptureDate).format('MM-DD-YYYY')  :'N/A'}`,
          account_flag: bankStatusText,
          ach_added_to_bank: achAddedText,
          account_type: (`${row.original.accountType != null ? row.original.accountType : 'N/A'}`),
          name_on_account: (`${row.original.nameOnAccount != null ? row.original.nameOnAccount : 'N/A'}`),
        };
      });
    
      const csvContent = convertToCSV(exportedData);
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
    
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Programmatically click the link to trigger the file download
      link.click();
    
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				}

				return value;
			}).join(',');
		}).join('\n');

    return headers + rows;
  };
    
    function ContentLayout() {
    return (
        <>
            <Box>
                <Typography level="h4" color="primary">Software Commission Payments</Typography>
                
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableColumnFilterModes
                        filterFns={{
                          customSearchFilterFn: (row, id, filterValue) =>
                          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                        }} 
                        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                        enableRowSelection={false}
                        positionToolbarAlertBanner="bottom"
                        enableDensityToggle={false}
                        enableColumnFilters={config.DatatableColumnFilter}
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                        initialState={{
                          showColumnFilters: false,
                          density: 'compact',
                        }}
                        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                        muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            backgroundColor: "#F6FAFF",
                          },
                        }}
                        renderDetailPanel={({ row }) => (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                            }}
                          >
                            <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Software Name</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Paid ($)</TableCell>
                                   
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow
                                    key={row.original.softwareId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell component="th" scope="row" align="center">{row.original.softwareName}</TableCell>
                                    <TableCell align="center">{currencyFormat(parseFloat(row.original.paidCommission))}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        )}
                        //top csv option
                        renderTopToolbarCustomActions={({ table }) => (
                          <Box
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                          >
                            <Button
                              color="primary"
                              disabled={table.getPrePaginationRowModel().rows.length === 0}
                              //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                  handleExportData(table.getPrePaginationRowModel().rows)
                                }
                              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                              startIcon={<FileDownloadIcon />}
                              variant="contained"
                            >
                              CSV
                            </Button>

                          </Box>
                        )
                        }
                      />
                <Dialog
              open={openbank}
              onClose={handlebankClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              maxWidth={maxWidth}
            >
              <DialogTitle id="responsive-dialog-title">
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography level="h5">
                      {softwareName} 's Bank Details
                    </Typography >
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} sx={{textAlign:"right"}}>
                    <EditIcon color="primary" 
                    onClick={() => viewBankDetails(softwareLinkName)}
                    />&nbsp;&nbsp;
                    <CloseIcon onClick={handlebankClose} />
                    </Grid>
                  </Grid>
              </DialogTitle>
              <DialogContent>
              <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" border="none">
                    <TableHead>
                      <TableRow>
                        <TableCell component="th"><b>Bank Name</b></TableCell>
                        <TableCell  component="th"><b>Name on Account</b></TableCell>
                        <TableCell  component="th"><b>Bank Routing (ABA/SCH) Number</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          <TableCell >
                          {bank_details.bank_name}
                          </TableCell >
                          <TableCell >{bank_details.name_on_account}</TableCell>
                          <TableCell >{bank_details.routing_number}</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell component="th"><b>Account No.</b></TableCell>
                        <TableCell  component="th"><b>Account Type</b></TableCell>
                        <TableCell  component="th"><b>Is Account</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          <TableCell >
                          {bank_details.account_number}
                          </TableCell >
                          <TableCell >{bank_details.account_type}</TableCell>
                          <TableCell >{bank_details.account_category}</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell component="th"><b>Email Address to notify of Payment Transfers</b></TableCell>
                        <TableCell  component="th"><b>ACH Info added to bank?</b></TableCell>
                        <TableCell  component="th"><b>Last ACH Update</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          <TableCell >
                          {bank_details.email_to_notify}
                          </TableCell >
                          <TableCell >{bank_details.ach_info_added_to_bank == 0 ? "Not Added" : "Added"}</TableCell>
                          <TableCell >{bank_details.last_ach_updated == null ? "NA" : Moment(bank_details.last_ach_updated).format('MM-DD-YYYY')}</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h5" color="primary" mt={2}>Bank Details Update History</Typography>
                        </Grid>
                        <MaterialReactTable

                            columns={columns1}
                            data={filterhistory}
                            enableColumnFilterModes
                            enableColumnOrdering={false}
                            enableGrouping={false}
                            enablePinning={false}
                            initialState={{ showColumnFilters: false,density: 'compact',sorting: [
                              {
                                  id: 'id', //sort by age by default on page load
                                  desc: true,
                                  numeric:true,
                              },
                          ], }}
                            enableDensityToggle={false}
                            enableFullScreenToggle={ false}
                            enableHiding={false} 
                            enableColumnFilters={false}
                            enableSorting={true}
                            filterFns={{
                              customSearchFilterFn: (row, id, filterValue) =>
                              row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                            }}                                   
                            globalFilterFn="customSearchFilterFn"
                            positionToolbarAlertBanner="bottom"

                           
                        />
                    </div>
              </DialogContent>

               
            </Dialog>
            </Box>
        </>
        );
    }
     
    
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
      <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={freez}
              onClick={handleFreezStop}
            >
              <Freez />
            </Backdrop>
          </div>
          {/* loader code end */}
        </div>
    );
}
    
export default SoftwareCommissionPayments;