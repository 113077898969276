import React,{useMemo,useState,useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid,InputLabel,MenuItem, FormControl,TextField,FormHelperText,Select,Box,Button,Dialog,DialogActions ,DialogTitle,DialogContentText,DialogContent,Tooltip,ListItemIcon, ListItemText,Checkbox,Tab } from '@mui/material';
import { Typography,Table, Option,} from '@mui/joy';
import AuthUser from "../../../Components/Auth/AuthUser";
import { useTheme } from '@mui/material/styles';
import Loader from '../../../Components/Loader/Loader';
import { useStyles, options } from "../Dashboard/utils";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { currencyFormat } from '../../../Components/CommonFormula';
import MaterialReactTable from 'material-react-table';
import config from '../../../AppConfig';
import Moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

let capturePaymentValues = {
    softwareId: '',
    referralCommission: '',
    bonus: '',
    totalCommission: '',
    paymentDate: '',
    paymentDetails: '',
};

const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
          const MenuProps = {
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
          };

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

function SoftwareCommissionViewDetails(props) {
    const navigate = useNavigate();
    const [freez, setFreez] = React.useState(true);
    const theme = useTheme();
    const {state} = useLocation();
    const { http, user } = AuthUser();
    const dispatch = useDispatch();
    const { softwareId,month,year} = state;
    const [softwareName, setSoftwareName] = useState();
    const [referralCommission, setReferralCommission] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [captured, setCaptured] = useState(false);
    const [referralCommissionDetails, setReferralCommissionDetails] = useState([]);
    const [bonusDetails, setBonusDetails] = useState([]);
    const [openCapturePayment, setOpenCapturePayment] = React.useState(false);
    const [capturePaymentBtn, setCapturePaymentBtn] = useState(false);
    const [todaysDate, setTodaysDate] = useState("");
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    
    const fetchCommissionViewDetails = async(softwareId,month,year) =>{
            const apiUrl = '/software-commission-details';
            const args = {
                softwareId:softwareId,
                month : month,
                year:year
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                if(res.success == true) {
                    setSoftwareName(res.data.softwareName);
                    setReferralCommission(res.data.referralCommission);
                    setBonus(res.data.totalBonus);
                    setTotalCommission(res.data.totalCommission);
                    setReferralCommissionDetails(res.data.referralCommissionDetails);
                    setBonusDetails(res.data.referralBonusDetails);
                    if(res.data.captured == false && parseFloat(res.data.totalCommission) <= 0) {
                        setCaptured(true);
                    }else{
                        setCaptured(res.data.captured);
                    }
                }
                handleFreezStop();
            }
    }

    const customSortReferralCommission = (rowA, rowB) => {
        const a = rowA.original.referral_commission;
        const b = rowB.original.referral_commission;
        return a - b;
    };

    const customSortCommission = (rowA, rowB) => {
        const a = rowA.original.commission;
        const b = rowB.original.commission;
        return a - b;
    };

    const captureSchema = Yup.object().shape({
        paymentDetails: Yup.string()
          .required('Please enter payment details'),
    
        paymentDate: Yup.string()
          .required('Please enter Date'),
        
      })

    const captureCommission = async (values) => {
        setOpenCapturePayment(false);
        setCapturePaymentBtn(true);
        handleFreezStart();
        const date = new Date(values.paymentDate);
        const day = String(date.getDate()).padStart(2, '0');
        const monthValue = String(date.getMonth() + 1).padStart(2, '0');
        const yearValue = date.getFullYear();
        var mydate = yearValue+"/"+monthValue+"/"+day;
  
        const apiUrl = '/capture-software-commission';
        const args = {
          id: values.softwareId,
          referralCommission: values.referralCommission,
          bonus: values.bonus,
          totalCommission: values.totalCommission,
          paymentDate: mydate,
          paymentDetails: values.paymentDetails,
          month:month,
          year:year
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
          if (res.success === true) {
            swal({
              title: "Success",
              text: res.message,
              icon: "success",
              button: "Ok",
            })
          } else {
            swal({
              title: "Failed",
              text: res.message,
              icon: "error",
              button: "Ok",
            })
          }
        }
        fetchCommissionViewDetails(softwareId,month,year);
        setCapturePaymentBtn(false);
    }  
    const handleCapturePayment = () => {
        capturePaymentValues.softwareId = softwareId;
        capturePaymentValues.referralCommission = referralCommission;
        capturePaymentValues.bonus = bonus;
        capturePaymentValues.totalCommission = totalCommission;
        const newDate = new Date();
        newDate.setDate(newDate.getDate());
        let tDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        setTodaysDate(tDate);
       
        tDate = new Date(tDate);
        const day = String(tDate.getDate()).padStart(2, '0');
        const month = String(tDate.getMonth() + 1).padStart(2, '0');
        const year = tDate.getFullYear();
        var mydate = year+"/"+month+"/"+day;
        capturePaymentValues.paymentDate = mydate;
        setOpenCapturePayment(true);
    };

    const handleCloseCapturePayment = () => {
        setOpenCapturePayment(false);
    };

    const columns = useMemo(
        //column definitions...
        () => [
          {
            accessorFn: (row) => `${row.company ? row.company.company_name : ''}`,
            id:'companyName',
            header: 'Company Name',
            size: 50,
            Cell:({renderedCellValue,row}) =>(row.original.company ? row.original.company.company_name : ''),
          },
          {
            accessorFn: (row) => `${row.commission}`,
            id:'commission',
            header: 'commission ($)',
            sortingFn: customSortCommission, 
            size: 50,
            Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.commission))),
          },
          {
            accessorFn: (row) => `${row.referral_commission}`,
            id:'ReferralCommission',
            header: 'Referral Commission ($)',
            size: 50,
            sortingFn: customSortReferralCommission, 
            Cell:({renderedCellValue,row}) => (
              <div>
                  {currencyFormat(parseFloat(row.original.referral_commission))}
               </div>          
             ),
          },
          
    ]);

    const handleExportData = (referralCommissionDetails) => {
        const exportedData = referralCommissionDetails.map((row) => {
          return {
            companyName: `${row.original.company.company_name }` || 'N/A',
            commission: `${row.original.commission}` || 'N/A',
            referralCommission:  parseFloat(row.original.referral_commission).toFixed(2) || 'N/A',
          };
        });
      
        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      
        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';
      
        // Append the link to the document body
        document.body.appendChild(link);
      
        // Programmatically click the link to trigger the file download
        link.click();
      
        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Referral Commission Details',
        headers: ['Company Name','Commission','Referral Commission'],
      };

    const convertToCSV = (data) => {
        const headers = csvOptions.headers.join(',') + '\n';
    
        const rows = data.map((row) => {
          return Object.values(row).map((value) => {
            // Handle any necessary formatting or escaping of values
            // For simplicity, we assume all values are already properly formatted
        
            // Wrap the value with double quotes if it contains a comma
            if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
            }
        
            return value;
          }).join(',');
          }).join('\n');
    
        return headers + rows;
    };


    const handleExportBonusData = (bonusDetails) => {
        const exportedData = bonusDetails.map((row) => {
          return {
            companyName: `${row.original.company.company_name }` || 'N/A',
            commission: `${row.original.bonus}` || 'N/A',
          };
        });
      
        const csvContent = convertToBonusCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      
        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = bonusCsvOptions.fileName + '.csv';
      
        // Append the link to the document body
        document.body.appendChild(link);
      
        // Programmatically click the link to trigger the file download
        link.click();
      
        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const convertToBonusCSV = (data) => {
        const headers = bonusCsvOptions.headers.join(',') + '\n';
      
        const rows = data.map((row) => {
          return Object.values(row).map((value) => {
            // Handle any necessary formatting or escaping of values
            // For simplicity, we assume all values are already properly formatted
            if (typeof value === 'string' && value.includes(',')) {
                      return `"${value}"`;
                      }
            return value;
          }).join(',');
        }).join('\n');
      
        return headers + rows;
    };

    const bonusCsvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Referral Bonus Details',
        headers: ['Company Name','Referral Bonus'],
      };

    
    const referralBonuscolumns = useMemo(
    //column definitions...
    () => [
        {
            accessorFn: (row) => `${row.company ? row.company.company_name : ''}`,
            id:'companyName',
            header: 'Company Name',
            size: 50,
            Cell:({renderedCellValue,row}) =>(row.original.company ? row.original.company.company_name : ''),
        },
        {
        accessorFn: (row) => `${row.bonus}`,
        id:'bonus',
        header: 'Referral Bonus ($)',
        size: 50,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.bonus))),
        },
    ]);

    useEffect(() => {
        if(user.roles_id == 1 || user.roles_id == 2 || user.roles_id == 9) {
            fetchCommissionViewDetails(softwareId,month,year);
        }else{
            navigate('/login');
        }
       
    }, []);

    const ContentLayout =
        <>
            <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={freez}
              onClick={handleFreezStop}
            >
              <Freez />
            </Backdrop>
          </div> 
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"space-between"}}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography level="h4" color="primary">Commission Details : {month} {year}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} sx={{textAlign:"right"}}>
                    <Tooltip title="Close" >
                    <CancelOutlinedIcon onClick={() => navigate(-1)} />
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                {
                    user.roles_id < 3 ? 
                    <Button variant="contained" color="warning" sx={{ minWidth: 150,marginRight:50 }} disabled={ captured } onClick={handleCapturePayment}>Capture Payment </Button>
                    : ''
                }
            </Grid>

            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                {
                     user.roles_id < 3 ? 
                    <Grid container item lg={3} md={3} sm={3} xs={3} >
                        <Typography>Software : <b>{softwareName ? softwareName : ''}</b></Typography>
                    </Grid>
                    :''
                }
                
                <Grid container item lg={3} md={3} sm={3} xs={3} >
                    <Typography>Referral Commission : <b> {referralCommission ? "$" + parseFloat(referralCommission).toFixed(2) : '$0.00'}</b></Typography>
                </Grid>
                <Grid container item lg={3} md={3} sm={3} xs={3} >
                    <Typography> Referral Bonus : <b>{bonus ? "$" + parseFloat(bonus).toFixed(2) : '$0.00'} </b></Typography>
                </Grid>
                <Grid container item lg={3} md={3} sm={3} xs={3} >
                    <Typography>Total Commission: <b>{totalCommission ? "$" + parseFloat(totalCommission).toFixed(2) : '$0.00'}</b></Typography>
                </Grid>
            </Grid>
            
            
           
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography level="h3" mb={2}  mt={2} align="center">
                    Referral Commission Details
                </Typography >
                <MaterialReactTable
                    columns={columns}
                    data={referralCommissionDetails}
                    filterFns={{
                        customSearchFilterFn: (row, id, filterValue) =>
                        row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                    }} 
                    globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                    enableColumnFilterModes
                    enableColumnFilters={config.DatatableColumnFilter}
                    enableRowSelection
                    initialState={{ showColumnFilters: false, density: 'compact' }}
                    positionToolbarAlertBanner="bottom"
                    enableDensityToggle={false}
                    muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                            backgroundColor:"#F6FAFF",
                        },
                        }}
                
                    //top csv option
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                        >
                        <Button
                            color="primary"
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            onClick={() =>
                                handleExportData(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon />}
                            variant='contained'
                        >
                            CSV
                        </Button>
                        </Box>
                    )
                    }
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                <Typography level="h3" mb={2} align="center">
                    Referral Bonus Details
                </Typography >
                <MaterialReactTable
                  columns={referralBonuscolumns}
                  data={bonusDetails}
                  enableColumnFilterModes
                  enableColumnFilters={config.DatatableColumnFilter}
                  enableRowSelection
                  initialState={{ showColumnFilters: false, density: 'compact' }}
                  positionToolbarAlertBanner="bottom"
                  enableDensityToggle={false}
                  muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        backgroundColor:"#F6FAFF",
                      },
                    }}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                    <Button
                        color="primary"
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                          onClick={() =>
                            handleExportBonusData(table.getPrePaginationRowModel().rows)
                          }
                       
                        startIcon={<FileDownloadIcon />}
                        variant='contained'
                    >
                    CSV
                    </Button>
                    </Box>
                )
                }
                />
            </Grid>
                {/* <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Commission" value="1" />
                                    <Tab label="Referral Commission & Bonus" value="2" />
                                </TabList>
                        </Grid>
                    </Box>
                    <TabPanel value="1"><CommissionView  itemList={itemList} onValueChange={setNewItem} enableSaveButton={enableSaveButton} setNewCommissionTotal={setNewCommissionTotal}/></TabPanel>
                    <TabPanel value="2"><ReferralCommissionView  bonusCommissionList={bonusCommissionList} referralCommissionList={referralCommissionList} /></TabPanel>
                </TabContext> */}
                {/* <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id='actionDiv'>
                    <Button variant="contained" color="success" disabled={saveDisable} onClick={saveChanges}> Save</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="success" disabled={approveDisable} onClick={() => handleClickOpen(1)}>Approve & Send</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="primary" disabled={approveDisable} onClick={() => handleClickOpen(0)}>Approve</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="warning" disabled={resetDisable} onClick={handleClickOpen1}>Reset to pending</Button>
                </Grid> */}
                {/* approve and send popup start */}
              
            <Dialog
                open={openCapturePayment}
                onClose={handleCloseCapturePayment}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
            >
                <DialogTitle id="alert-dialog-title" className="back">
                Capture Payment
                </DialogTitle>
                <Formik
                    initialValues={capturePaymentValues}
                    validationSchema={captureSchema}
                    onSubmit={captureCommission}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <>
                    <DialogContent>
                        <Grid container item xs>
                        <TextField type='hidden' name="softwareId" value={values.softwareId} sx={{ display: 'none' }} />
                        <TextField type='hidden' name="referralCommission" value={values.referralCommission} sx={{ display: 'none' }} />
                        <TextField type='hidden' name="bonus" value={values.bonus} sx={{ display: 'none' }} />
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <TextField margin="dense" size="small" id="outlined-basic" label="Payment Amount ($): *"
                            variant="outlined" fullWidth
                            type="number"
                            value={values.totalCommission}
                            // onChange={handleChange('totalCommission')}
                            readOnly={true}
                            // onBlur={handleBlur('totalCommission')} 
                            />
                        </Grid>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DemoContainer components={['DatePicker']} margin="dense" size="small" fullWidth>
                            <DatePicker label="Payment Date:*" name="paymentDate" id="paymentDate" defaultValue={dayjs(todaysDate)}
                                onChange={(value) => { setFieldValue("paymentDate", value, true) }} />
                            </DemoContainer>
                        </LocalizationProvider>
                        </Grid>
                        <FormHelperText>{touched.paymentDate && errors.paymentDate ? <div className='error'>{errors.paymentDate}</div> : null}</FormHelperText>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <FormControl fullWidth size="small">
                            <TextareaAutosize
                            margin="dense"
                            aria-label="minimum height"
                            minRows={3}
                            value={values.paymentDetails}
                            onChange={handleChange('paymentDetails')}
                            onBlur={handleBlur('paymentDetails')}
                            placeholder="Payment Details"
                            style={{ width: "100%" }}
                            />
                        </FormControl>
                        {touched.paymentDetails && errors.paymentDetails ? <div className='error'>{errors.paymentDetails}</div> : null}
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                        <Button variant="contained" disabled={capturePaymentBtn} color="success" sx={{ minWidth: 200 }} onClick={handleSubmit}>Capture Payment</Button>
                        <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={handleCloseCapturePayment}>Close</Button>
                    </DialogActions>
                    </>
                    )}
                </Formik>
            </Dialog>
        </>

    return(
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}

export default SoftwareCommissionViewDetails;