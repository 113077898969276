import React, {  useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid } from "@mui/material";
import { Card, CardOverflow, Typography,CardContent } from '@mui/joy';
import { makeStyles } from '@material-ui/core/styles';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import config from '../../../AppConfig';
import ShareIcons from '../ReferralProgram/ShareOnSocialMedia';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../redux/apiSlice';

function CROLink() {
const dispatch  = useDispatch();
const [croLink, setCroLink] = useState();
const [url, setUrl] = useState();
const [copySuccess, setCopySuccess] = useState('');
const getCroLink = async () =>{
    const apiUrl = '/croLink';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if(res){
        console.log(res)
        setCroLink(res.link);
        setUrl(config.BASE_URL + res.link);
    }
} 
  
useEffect(() => {
    getCroLink();
}, [])

        
   
    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Typography level="h4" color="primary">CRO Link</Typography>
                </Grid>
                <Card variant='outlined' sx={{marginTop:"1%"}}>
                    <CardOverflow
                     sx={{
                        bgcolor: '#F6FAFF',
                        borderColor: '#F6FAFF',
                        padding: "1%"
                    }}>
                        <Typography level="h5">Link URL</Typography>
                    </CardOverflow>
                    <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p className="rectangle" >
                            {croLink ? config.BASE_URL  + croLink + '=' : "Getting Link"}
                            <ContentCopyOutlinedIcon text={config.BASE_URL + croLink + '='} sx={{ fontSize: "15px", color: "blue" }} 
                             onClick={() => {navigator.clipboard.writeText(croLink ? config.BASE_URL  + croLink + '=' : "Getting Link")
                             setCopySuccess(croLink ? config.BASE_URL  + croLink + '=' : "Getting Link")
                             setTimeout(() => {
                                 setCopySuccess('');
                             }, 5000);
                         }} />{copySuccess ? "Copied!" : ''}
                            </p>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h5" mt={3}>Share Now:</Typography>
                            <span>
                                {url ? <ShareIcons url={url} /> : ""}</span>
                            </Grid>
                        </Grid>
                    </Grid>

                       
                    </CardContent>
                </Card>

            </>
        )
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
          
        </div>
    );

}

export default CROLink;