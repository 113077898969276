import React, { useEffect, useState } from 'react';
import { useParams} from "react-router-dom";
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Box,Grid,Card,CardContent,Typography,TableContainer,Paper,Table,TableHead,TableRow,TableCell,TableBody} from '@mui/material';

function CompanyDetails(props) {


    //variable declearation start

    const { http } = AuthUser();
    const { id } = useParams();


  
    const [loader, setLoader] = useState();
  
    const [company_details, setcompanyDetails] = useState([]);
    const [aids, setAids] = useState([]);
    const [pids, setPids] = useState([]);
    const [aid_users,setAidUsers]=useState([]);

    //variable declearation ends


    useEffect(() => {
        fetchCompanyDetails();
      }, []);


      // fectch function starts


      const fetchCompanyDetails = () => {
        
        http.get("/companyDetails/" + props.props.original.id).then((res) => {
          if(res.data){
            setcompanyDetails(res.data.company_details);
            setPids(res.data.pids);
            setAids(res.data.aids);
            setAidUsers(res.data.aid_users);
            setLoader('');
          }
          
        });
      };


      // fetch function ends
    function ContentLayout() {
        
        return (
          
            
            <Box>
                
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography sx={{ fontSize: 25 }} color="text.secondary" gutterBottom>
                                Company Name
                                </Typography>
                                <Typography variant="h5" component="div">
                                {company_details.company_name ? company_details.company_name : "N/A"}
                                </Typography>
                                
                            </CardContent>
                            
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Card>
                            <CardContent>
                                <Typography sx={{ fontSize: 25 }} color="text.secondary" gutterBottom>
                                Company PID's
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell align="right">   PID</TableCell>
                                            <TableCell align="right">   PID Type</TableCell>
                                            <TableCell align="right">PID Status</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>

                                        {pids.map((rows, idx) => (
                                            <>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}>
                                                <TableCell component="th" scope="row">{idx+1}</TableCell>
                                                <TableCell align="right">{rows.base_pid}</TableCell>
                                                <TableCell align="right">{rows.pid_type}</TableCell>
                                                <TableCell align="right">{rows.pid_status?<><span className="badge bg-success">Active</span></>:<><span className="badge bg-danger">Deactive</span></>}</TableCell>
                                            </TableRow>
                                            </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                            
                        </Card>
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={12} md={12} mt={2}>
                    <Typography sx={{ fontSize: 25 }} color="text.secondary" gutterBottom>
                    Affiliate Users
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table  aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell align="right">AID</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">Phone</TableCell>
                                <TableCell align="right">User Role</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell component="th" scope="row">
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        );
    }
     
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default CompanyDetails;