import { configureStore } from "@reduxjs/toolkit";
import authReducer from './authSlice';
import apiSlice from "./apiSlice";
import { loadState } from "./storePersist";

const persistedState = loadState();

export const store = configureStore({
    reducer: {
        auth: authReducer,
        api: apiSlice,
    },
    persistedState,
})