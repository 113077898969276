import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DefaultLayout from '../../Components/DefaultLayout';
import { Box, Grid, Tooltip, Button } from '@mui/material';
import { Typography, CardOverflow, Card, CardContent, } from '@mui/joy';
import BackIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoginHeader from '../../Components/LoginHeader';
import RegisterFooter from '../Auth/RegisterFooter';
function CancelPolicy(props) {
    const currentYear = new Date().getFullYear();
    const closeTab = () => {
        props.closeVal(false)
    }
    return (
        <>
         <LoginHeader />
            <Box sx={{ minHeight: 300, maxHeight: 500}} p={5}>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid lg={6} md={6} sm={6} xs={6} item>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h3" sx={{ borderBottom: "3px solid #5288db" }}>Cancellation Policy Of MyFreeScoreNow Inc.</Typography>
                        </Grid>
                    </Grid>
                    <Grid lg={6} md={6} sm={6} xs={6} item className='companyLogo'>
                        <Tooltip title="Close" >
                            <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={closeTab} />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Last revised April 08, 2016</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">To cancel your credit monitoring membership,
                        you must call our Customer Service Center toll-free at 1-888-548-2008 during the business hours listed on the web site and/or on the Contact Us page, or send a letter via US Postal Service to: MyFreeScoreNow Inc. PO Box 4798 Seattle, WA 98194.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        If you do not cancel before the expiration of the free trial period, your credit or debit card will automatically be charged the then current and applicable monthly fee.
                        You will NOT receive a notice from us that your free trial period has ended or that the paying portion of your membership has begun.
                        We will continue to bill your credit or debit card for your membership fee each month until you cancel.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        Payments are non-refundable and there are no partial refunds or credits for partially used monthly MyFreeScoreNow membership periods.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        Refer to the Terms and Conditions for additional membership information.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">If you have any questions about this Cancellation Policy,
                        you may go to the Contact Us section of the secure members area for our toll-free number or write to us at:
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Membership Services</Typography><br />
                    <Typography level="body1" fontSize="lg">MyFreeScoreNow Inc.</Typography><br />
                    <Typography level="body1" fontSize="lg">PO Box 4798 Seattle, WA 98194</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Thank you for using our site.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Copyright © {currentYear} MyFreeScore Now Inc. All Rights Reserved.</Typography>
                </Grid>
            </Box>
            <div > <RegisterFooter  /></div>
        </>
    );
}
export default CancelPolicy;