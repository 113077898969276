//ui imports
import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import { Box, Grid, TextField, Divider, InputAdornment, IconButton, FormControl, OutlinedInput, InputLabel, FormHelperText } from '@mui/material';
import { Typography, Card, CardContent, Button } from '@mui/joy';
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
// functional imports
import AuthUser from '../../../Components/Auth/AuthUser';
import { Formik, Form } from 'formik';
import swal from "sweetalert";
import OfferDetails from "../../../Components/Enrollment/OfferDetails";
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import SecureWebsite from "../../../Components/Enrollment/SecureWebsite";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const Enrollment1 = ({ onValueChange, props }) => {
    const { http } = AuthUser();
    const [errorfromcd, seterrorfromcd] = useState();
    const [loginLink, setloginLink] = useState();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    
    const queryParameters = new URLSearchParams(window.location.search);
    let pid = "00017";
    let aid = "seo";
    for (let key of queryParameters.keys()) {
        if (key.toLowerCase() === 'pid') {
            pid = queryParameters.get(key);
        } else if (key.toLowerCase() === 'aid') {
            aid = queryParameters.get(key);
        }
    }
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    // //chagers and subscription values functions and variable starts
    // const [offerdetails, setOfferDetails] = useState();
    // const getOfferDetails = () => {
    //     handleFreezStart();
    //     http.post("/getofferdetailsofpid", {
    //         pid: pid,
    //     })
    //         .then((res) => {
    //             handleFreezStop();
    //             if (res.data.success === true) {
    //                 setOfferDetails(res.data.data[0])
    //             } else {
    //                 seterrorfromcd("Something went wrong please try after some time")
    //             }
    //         })
    //         .catch((error) => {
    //             seterrorfromcd("Network Error")
    //         });
    // }
    // useEffect(() => {
    //     getOfferDetails();
    // }, []);
    //chagers and subscription values functions and variable ends
    //validation schema starts
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid First Name'
            )
            .required('First Name is required'),
        lastName: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid Last Name'
            )
            .required('Last Name is required'),
        email: Yup.string()
            .email('Invalid email')
            .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .required('Email is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
    });
    //MainEnroll function starts
    const MainErollStep1 = (values) => {
        setButtonDisabled(true);
        handleFreezStart();
        http.post("/registerEnrollment", {
            step: 1,
            firstName: values.firstName,
            middlename: values.middleName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            source: "default",
            aid: aid,
            pid: pid,
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    localStorage.setItem('customerToken', res.data.data.customerToken);
                    localStorage.setItem('trackingToken', res.data.data.trackingToken);
                    localStorage.setItem('Main_firstName', values.firstName);
                    localStorage.setItem('Main_lastName', values.lastName);
                    localStorage.setItem('Main_middleName', values.middleName);
                    localStorage.setItem('Main_email', values.email);
                    localStorage.setItem('Main_password', values.password);
                    localStorage.setItem('aid', aid);
                    localStorage.setItem('pid', pid);

                    if (res.data.data.nextstep == 3) {
                        localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
                    }
                    onValueChange(res.data.data.nextstep)
                    setButtonDisabled(false);

                } else {
                    const errorObj = res.data.data.errorByCD;
                    if (errorObj[0].message) {
                        seterrorfromcd(errorObj[0].message)
                    } else {
                        seterrorfromcd(res.data.data.errorByCD)
                    }
                    if(res.data.data.loginUrl){
                        setloginLink(res.data.data.loginUrl);
                    }
                    onValueChange(props);
                    setButtonDisabled(false);

                }
            })
            .catch((error) => {
                handleFreezStop();
                setButtonDisabled(false);
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })

            });
    }
    //MainEnroll function ends
    //validation schema ends
    const handleCallback = (data) => {
        seterrorfromcd(data);
    }
    const handleFreezCallback = (data) => {
        setFreez(data);
    }
    console.log(errorfromcd)

    return (
        <>
            <Box p={4} >
                <Card variant="outlined" >
                    <Formik
                        initialValues={{ firstName: '', lastName: '', email: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            // Handle form submission logic here
                            setSubmitting(false);
                            MainErollStep1(values);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form>
                                <CardContent className="enrollment-form-padding">
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={6} md={6} sm={12} xs={12} >
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                {/* <TextField
                                                    id="firstName"
                                                    name="firstName"
                                                    label="Name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    error={touched.firstName && !!errors.firstName}
                                                    helperText={touched.firstName && errors.firstName}
                                                    value={values.firstName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    sx={{ display: "flex", justifyContent: "center" }}
                                                /> */}
                                                <FormControl variant="outlined" fullWidth
                                                    margin="dense" size="small">
                                                    <InputLabel>First Name*</InputLabel>
                                                    <OutlinedInput
                                                        id="firstName"
                                                        name="firstName"
                                                        label="First Name*"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        autoComplete="firstName"
                                                        margin="dense"
                                                        error={touched.firstName && !!errors.firstName}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                >
                                                                    {touched.firstName && !errors.firstName ? (
                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                    ) : (
                                                                        touched.firstName && errors.firstName ? (
                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                        ) : null
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>
                                                        {touched.firstName && errors.firstName}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                                                <TextField
                                                    id="middleName"
                                                    name="middleName"
                                                    label="Middle Name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    autoComplete="middleName"
                                                    margin="dense"
                                                    error={touched.middleName && !!errors.middleName}
                                                    helperText={touched.middleName && errors.middleName}
                                                    value={values.middleName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                                                {/* <TextField
                                                    id="lastName"
                                                    name="lastName"
                                                    label="Last Name"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    error={touched.lastName && !!errors.lastName}
                                                    helperText={touched.lastName && errors.lastName}
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                                <FormControl variant="outlined" fullWidth
                                                    margin="dense" size="small">
                                                    <InputLabel>Last Name*</InputLabel>
                                                    <OutlinedInput
                                                        id="lastName"
                                                        name="lastName"
                                                        label="Last Name*"
                                                        autoComplete="lastName"
                                                        error={touched.lastName && !!errors.lastName}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                >
                                                                    {touched.lastName && !errors.lastName ? (
                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                    ) : (
                                                                        touched.lastName && errors.lastName ? (
                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                        ) : null
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>
                                                        {touched.lastName && errors.lastName}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                                                {/* <TextField
                                                    id="email"
                                                    name="email"
                                                    label="Email"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    error={touched.email && !!errors.email}
                                                    helperText={touched.email && errors.email}
                                                    defaultValue={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                                <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>Email*</InputLabel>
                                                            <OutlinedInput
                                                               id="email"
                                                               name="email"
                                                               autoComplete="email"
                                                               label="Email*"
                                                                error={touched.email && !!errors.email}
                                                                helperText={touched.email && errors.email}
                                                                // value={values.email}
                                                                defaultValue={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {touched.email && !errors.email ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.email && errors.email ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <FormHelperText style={{ color: 'red' }}>
                                                                {touched.email && errors.email}
                                                            </FormHelperText>
                                                        </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                                                <FormControl variant="outlined" size="small" fullWidth margin="dense">
                                                    <InputLabel htmlFor="outlined-adornment-password">Password*</InputLabel>
                                                    <OutlinedInput
                                                        id="password"
                                                        name="password"
                                                        // type="password"
                                                        label="Password*"
                                                        autoComplete="password"
                                                        error={touched.password && !!errors.password}
                                                        helperText={touched.password && errors.password}
                                                        defaultValue={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type={showPassword ? 'text' : 'password'}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {touched.password && !errors.password ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.password && errors.password ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>
                                                                {touched.password && errors.password}
                                                            </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            {/* error code display space start */}
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                {errorfromcd ? <Typography sx={{ fontWeight: "bold", color: "red" }}>
                                                    Error :- {errorfromcd === "An account already exists with the email you entered. Please login to view your credit report and scores." ? 
                                                    <>
                                                    An account already exists with the email you entered. Please <a href={loginLink} target="_blank">login</a> to view your credit report and scores.
                                                  </>
                                                : errorfromcd } 
                                                </Typography>
                                                    : ""}
                                            </Grid>
                                            {/* error code display space end */}
                                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "right", display: "flex" }}>
                                                <Button type="submit" color="warning" sx={{ minWidth: 150, backgroundColor: "#ED7E2E", color: "white", borderRadius: "0%" }} disabled={isButtonDisabled}>NEXT STEP</Button>
                                            </Grid>
                                            <SecureWebsite />
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={12} xs={12}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Divider orientation="vertical" style={{ height: '100%', width: '1px' }} />
                                        </Grid>

                                        {/* Right side panel for offer details  */}
                                        <OfferDetails pid={pid} step={1} errorfromcdCallback={handleCallback} handleFreezCallback={handleFreezCallback} />

                                    </Grid>
                                </CardContent>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </Box>

            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default Enrollment1;