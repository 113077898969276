import React from 'react';
import { Chart } from "react-google-charts";
import { Typography } from '@mui/joy';
import {Grid
} from '@mui/material';
const SummaryStatistics = ({ callLogs }) => {
  const totalCalls = callLogs.length;
  const outgoingCalls = callLogs.filter(log => log.callType === 'Outgoing').length;
  const incomingCalls = callLogs.filter(log => log.callType === 'Incoming').length;
  const missedCalls = callLogs.filter(log => log.callStatus === 'No Answer').length;

  

  const chartData = [
    ['Call Type', 'Number of Calls'],
    ['Outgoing', outgoingCalls],
    ['Incoming', incomingCalls],
    ['Missed', missedCalls],
  ];

  return (
    <div>
      <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography level="h4" color="primary">Summary Statistics</Typography>
        </Grid>
      <Chart
        width={'500px'}
        height={'300px'}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={{
          title: 'Call Distribution',
          is3D: true,
        }}
      />
    </div>
  );
};

export default SummaryStatistics;
