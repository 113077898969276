import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton,Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  
  largeIcon: {
    fontSize: '35px',
    marginTop: theme.spacing(1.5),
    color:"#ED7E2E" // Adjust the size as per your preference
  },
}));

function ShareIcons({ url }) {
  const classes = useStyles();

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${url}`;

  return (
    <div className={classes.root}>
      <Tooltip title="Share on Facebook">
        <IconButton
          
          component="a"
          href={facebookShareUrl}
          target="_blank"
          rel="noopener noreferrer"
         
        >
          <img src={require("../../../assets/images/facebook1.png")} alt="facebook" className={classes.largeIcon}/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on Twitter">
        <IconButton
          
          component="a"
          href={twitterShareUrl}
          target="_blank"
          rel="noopener noreferrer"
          
        >
          <img src={require("../../../assets/images/twitterNew.png")} alt="twitter" className={classes.largeIcon}/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on LinkedIn">
        <IconButton
         
          component="a"
          href={linkedinShareUrl+"="}
          target="_blank"
          rel="noopener noreferrer"
          
        >
          <img src={require("../../../assets/images/linkedin.png")} alt="linked in" className={classes.largeIcon}/>
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default ShareIcons;
