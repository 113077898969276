

export const data: Employee[] = [
  {
    firstName: 'Dusty',
    lastName: 'Kuvalis',
    email: 'Randy63@yahoo.com',
    jobTitle: 'Chief Creative Technician',
    salary: 52729,
    startDate: '3/20/2014',
    signatureCatchPhrase: 'Cross-platform disintermediate workforce',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/106.jpg',
  },
  {
    firstName: "D'angelo",
    lastName: 'Moen',
    email: 'Andrew88@hotmail.com',
    jobTitle: 'Forward Response Engineer',
    salary: 71964,
    startDate: '3/9/2018',
    signatureCatchPhrase: 'Virtual local support',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/420.jpg',
  },
  {
    firstName: 'Devan',
    lastName: 'Reinger',
    email: 'Melissa_Lockman@hotmail.com',
    jobTitle: 'Customer Intranet Consultant',
    salary: 72551,
    startDate: '8/12/2020',
    signatureCatchPhrase: 'Pre-emptive composite hierarchy',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1091.jpg',
  },
  {
    firstName: 'Leonardo',
    lastName: 'Langworth',
    email: 'Chadrick.Goldner87@gmail.com',
    jobTitle: 'Senior Security Manager',
    salary: 57801,
    startDate: '7/25/2017',
    signatureCatchPhrase: 'Progressive real-time core',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/639.jpg',
  },
  {
    firstName: 'Douglas',
    lastName: 'Denesik',
    email: 'Dante.Deckow@hotmail.com',
    jobTitle: 'Legacy Security Assistant',
    salary: 23792,
    startDate: '4/12/2020',
    signatureCatchPhrase: 'Operative well-modulated info-mediaries',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/394.jpg',
  },
  {
    firstName: 'Jameson',
    lastName: 'Mayer',
    email: 'Rosamond_Schuster@yahoo.com',
    jobTitle: 'Regional Division Planner',
    salary: 80916,
    startDate: '10/30/2017',
    signatureCatchPhrase: 'Front-line intermediate firmware',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1205.jpg',
  },
  {
    firstName: 'Madaline',
    lastName: 'Quitzon',
    email: 'Alex_Grimes82@hotmail.com',
    jobTitle: 'Corporate Paradigm Strategist',
    salary: 68052,
    startDate: '1/17/2018',
    signatureCatchPhrase: 'Right-sized high-level algorithm',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/432.jpg',
  },
  {
    firstName: 'Wilfrid',
    lastName: 'Vandervort',
    email: 'Buddy.Torphy@gmail.com',
    jobTitle: 'Legacy Functionality Specialist',
    salary: 85573,
    startDate: '8/4/2014',
    signatureCatchPhrase: 'Focused interactive secured line',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1131.jpg',
  },
  {
    firstName: 'Chelsie',
    lastName: 'Mraz',
    email: 'Ladarius_Thiel70@yahoo.com',
    jobTitle: 'Forward Infrastructure Representative',
    salary: 51062,
    startDate: '1/6/2021',
    signatureCatchPhrase: 'Diverse attitude-oriented migration',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1099.jpg',
  },
  {
    firstName: 'Hassie',
    lastName: 'Bruen',
    email: 'Clair76@gmail.com',
    jobTitle: 'Human Paradigm Designer',
    salary: 61196,
    startDate: '4/28/2016',
    signatureCatchPhrase: 'Upgradable composite methodology',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/401.jpg',
  },
  {
    firstName: 'Daisy',
    lastName: 'Hane',
    email: 'Alverta7@hotmail.com',
    jobTitle: 'National Configuration Manager',
    salary: 25394,
    startDate: '3/2/2020',
    signatureCatchPhrase: 'Exclusive next generation initiative',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/436.jpg',
  },
  {
    firstName: 'Wilbert',
    lastName: 'Monahan',
    email: 'Cydney.Jakubowski9@yahoo.com',
    jobTitle: 'Internal Interactions Associate',
    salary: 79355,
    startDate: '4/5/2017',
    signatureCatchPhrase: 'Total asynchronous strategy',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/74.jpg',
  },
  {
    firstName: 'Heloise',
    lastName: 'Purdy',
    email: 'Celestino.Kassulke@yahoo.com',
    jobTitle: 'Global Identity Architect',
    salary: 109257,
    startDate: '12/18/2020',
    signatureCatchPhrase: 'User-friendly tertiary service-desk',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1107.jpg',
  },
  {
    firstName: 'Moises',
    lastName: 'McClure',
    email: 'Arturo29@yahoo.com',
    jobTitle: 'Internal Marketing Orchestrator',
    salary: 155037,
    startDate: '9/23/2014',
    signatureCatchPhrase: 'Public-key exuding complexity',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/484.jpg',
  },
  {
    firstName: 'Rahsaan',
    lastName: 'Bauch',
    email: 'Angelita39@yahoo.com',
    jobTitle: 'Dynamic Data Planner',
    salary: 158595,
    startDate: '3/31/2015',
    signatureCatchPhrase: 'Decentralized 6th generation archive',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1015.jpg',
  },
  {
    firstName: 'Lorenzo',
    lastName: 'Moore',
    email: 'Emma_Becker33@yahoo.com',
    jobTitle: 'Customer Division Representative',
    salary: 73983,
    startDate: '8/22/2020',
    signatureCatchPhrase: 'Reactive fresh-thinking local area network',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/88.jpg',
  },
  {
    firstName: 'Richard',
    lastName: 'Bartoletti',
    email: 'Hayden84@gmail.com',
    jobTitle: 'Future Communications Technician',
    salary: 169001,
    startDate: '10/13/2018',
    signatureCatchPhrase: 'Streamlined logistical access',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/897.jpg',
  },
  {
    firstName: 'Ulises',
    lastName: 'Beatty',
    email: 'Jessie_Kirlin39@yahoo.com',
    jobTitle: 'Future Markets Associate',
    salary: 92008,
    startDate: '10/17/2017',
    signatureCatchPhrase: 'Monitored object-oriented interface',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/956.jpg',
  },
  {
    firstName: 'Hattie',
    lastName: 'Stehr',
    email: 'Betty78@hotmail.com',
    jobTitle: 'Internal Directives Orchestrator',
    salary: 120554,
    startDate: '12/17/2014',
    signatureCatchPhrase: 'Organic bi-directional groupware',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/448.jpg',
  },
  {
    firstName: 'Kane',
    lastName: 'Marquardt',
    email: 'Myron.Schaefer45@yahoo.com',
    jobTitle: 'Product Research Orchestrator',
    salary: 95231,
    startDate: '2/28/2020',
    signatureCatchPhrase: 'Stand-alone holistic strategy',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/461.jpg',
  },
  {
    firstName: 'Brittany',
    lastName: 'Legros',
    email: 'Rachelle44@yahoo.com',
    jobTitle: 'Chief Web Specialist',
    salary: 40908,
    startDate: '7/27/2016',
    signatureCatchPhrase: 'Reactive multi-tasking internet solution',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/862.jpg',
  },
  {
    firstName: 'Joana',
    lastName: 'Witting',
    email: 'Elyssa.Wiegand@hotmail.com',
    jobTitle: 'Legacy Quality Strategist',
    salary: 136966,
    startDate: '8/22/2017',
    signatureCatchPhrase: 'Extended asynchronous moderator',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/795.jpg',
  },
  {
    firstName: 'Marshall',
    lastName: 'Gottlieb',
    email: 'Myron53@gmail.com',
    jobTitle: 'Internal Web Designer',
    salary: 155176,
    startDate: '12/25/2020',
    signatureCatchPhrase: 'Business-focused bifurcated access',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/524.jpg',
  },

 
];
