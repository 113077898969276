import React, { useEffect, useState } from 'react';
import ReferralProgram from './ReferralProgram'
import TermsandCondition from './TermsAndCondition'
import AuthUser from '../../../Components/Auth/AuthUser';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import {  postData } from '../../../redux/apiSlice';


function ReferralProgramindex() {

    const { user } = AuthUser();
    const [flag,setFlag]= useState(null);
    const [resp,setResponse]=useState();
    const dispatch = useDispatch();



        //loader function and variable start
        const [freez, setFreez] = React.useState(false);
        const handleFreezStop = () => {
            setFreez(false);
        };
        const handleFreezStart = () => {
            setFreez(true);
        };
        //loader function and variable end
        const [bounce,setbounce]= React.useState(false);


    const GetReferalStatus = async() => {
        handleFreezStart();
            const apiUrl = '/fetchReferralInfo';
            const args = {
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                if (res.success === true) {
                    handleFreezStop();
                    setResponse(res.data);
                    if(res.data.referral_terms_and_conditions == 1){
                       
                        setFlag(true);
                    }else{
                        setFlag(false);
                    }
                    if(res.data.bounce_email_flag === 1){
                        setbounce(true)
                    }
                } else {
                    handleFreezStop();
                    swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                });
                
                }
            }else{
                handleFreezStop();
              
            }
       
    };    
    useEffect(() => {
      GetReferalStatus();
    }, []);

    if (flag === null) {
        return <Backdrop open={true}><Freez /></Backdrop>;
    }
    return (
        <>
        {user.roles_id === 9  ? <ReferralProgram/>
         : flag ?  <ReferralProgram/> : <TermsandCondition bounce = {bounce} resp = {resp}/> }
                    {/* loader code start */}
                    <div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={freez}
                        >
                            <Freez />
                        </Backdrop>
                    </div>
                    {/* loader code end */}
        </>
    );


}

export default ReferralProgramindex;