import React from 'react';
import { Box, Grid, Tooltip, } from '@mui/material';
import { Typography,} from '@mui/joy';
import BackIcon from '@mui/icons-material/HighlightOff';
import LoginHeader from '../../Components/LoginHeader';
import RegisterFooter from '../Auth/RegisterFooter';
function RefundPolicy(props) {
    const closeTab = () => {
        props.closeVal(false)
    }
    return (
        <>
        <LoginHeader />
            <Box p={4} sx={{ minHeight: 300, maxHeight: 500 }} >
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid lg={6} md={6} sm={6} xs={6} item>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h3" sx={{ borderBottom: "3px solid #5288db" }}>Refund Policy Of MyFreeScoreNow Inc.</Typography>
                        </Grid>
                    </Grid>
                   
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Last revised April 08, 2016</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Sorry, payments are non-refundable and there are no partial refunds or credits for partially used monthly MyFreeScoreNow membership periods.
                        Also, payments are non-refundable for the purchase of any Transactional Services including, but not limited to,
                        the purchase of a new or updated 3-Bureau Credit Report or for the purchase of a new or updated 1-Bureau Credit Report, if applicable.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        Following any cancellation, you may no longer be able to log in to the secure members area of the web site or be able to access the services.
                        Please contact customer service at 1-888-548-2008 to reinstate your Membership.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        At any time, and for any reason, we may provide a refund, discount, or other consideration to some or all of our members (“credits”).
                        The amount and form of such credits, and the decision to provide them, are at our sole and absolute discretion. The provision of credits in one instance does not entitle you to credits in the future for similar instances,
                        nor does it obligate us to provide credits in the future, under any circumstance.
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        If you have any questions about this Refund Policy, you may go to the Contact Us section of the web site, or call us toll free at 1-888-548-2008 or write to us at:
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">
                        Membership Services
                    </Typography><br />
                    <Typography level="body1" fontSize="lg">MyFreeScoreNow Inc.</Typography><br />
                    <Typography level="body1" fontSize="lg">PO Box 4798 Seattle, WA 98194.</Typography><br />
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Thank you for using our site.</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="body1" fontSize="lg">Copyright © 2012-2016 MyFreeScoreNow Inc. All Rights Reserved.</Typography>
                </Grid>
            </Box>
            <div> <RegisterFooter  /></div>
        </>
    );
}

export default RefundPolicy;