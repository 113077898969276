import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination, button } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import config from '../../../AppConfig';
import Sidebar from '../../../Components/Blogs/Sidebar';
function CreditReport() {
    const [showMoreStates, setShowMoreStates] = useState({});

    const toggleText = (id) => (showMoreStates[id] ? 'Close' : 'Read more');

    const handleToggle = (id) => {
        setShowMoreStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };
    return (
        <>
            <LoginHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={2} md={2} sm={12} xs={12} >
                    <Sidebar/>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12} sx={{ padding: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <img src={require("../../../assets/images/mfsn_banner_4.jpg")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className='responsiveImg' />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='h4'>Credit Reports</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Your credit report is a detailed record of your current and past credit obligations. In a sense, it tells the story of your financial life-past and present. Most of the information on your credit report comes from the businesses with which you have or had accounts or loans. Because your credit report can have a huge impact on your life, it makes sense to understand it and how it affects you.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>What Is Included in Your Credit Report</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Your credit report is a snapshot of your credit profile. It does not indicate whether or not you are a good credit risk, but presents data about your credit history and current credit obligations for a lender (or other entity) to review as part of the decision-making process.</Typography>
                            {showMoreStates[1] && (
                                <div>
                                    <p><b>1. Personal information</b> that identifies you including your:</p>
                                    <ul>
                                        <li>Full name</li>
                                        <li>Social Security number</li>
                                        <li>Current and previous addresses</li>
                                        <li>Phone number</li>
                                        <li>Date of birth</li>
                                        <li>Current and previous employers</li>
                                    </ul>
                                    <p><b>2. Credit History:</b> A record of your accounts with banks, retailers, credit card issuers and other lenders. These accounts are grouped by type of account such as mortgage, revolving credit or installment loans. The age of the account is usually noted as well as your credit payment history for each account.</p>
                                    <p><b>3. Inquiries: </b> A record of every time someone accesses your credit report. There are two types of inquiries: "hard inquiries" that count toward your credit score and "soft inquiries" that do not count.</p>
                                    <p>Hard inquiries result when you initiate a credit application and the potential creditor checks your credit report. The impact of hard inquiries on your credit score varies depending on the overall information in your report, but it is usually not one of the more significant factors.</p>
                                    <p>Soft inquiries have absolutely no impact on your credit score, but are recorded for your information. Viewing your own credit report is a soft inquiry. Other inquiries that you do not initiate such as an account review by an existing creditor or a promotional inquiry for the purpose of offering pre-approved credit are soft inquiries. Potential creditors do not see soft inquiries.</p>
                                    <p><b>4. Public Records: </b>A record of bankruptcies, court judgments and tax liens.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(1)}>
                                {toggleText(1)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>What Is Not Included in Your Credit Report</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>While your credit report tells a lot about you, there are certain things that are not included.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            {showMoreStates[2] && (
                                <div>
                                    <p>Your credit report does not include the following information:</p>
                                    <ul>
                                        <li>Race</li>
                                        <li>Political or religious preferences</li>
                                        <li>Sexual orientation</li>
                                        <li>National origin</li>
                                        <li>Medical history</li>
                                        <li>Checking or savings accounts</li>
                                        <li>Debit cards</li>
                                        <li>Criminal and driving records</li>
                                        <li>Credit score</li>
                                    </ul>
                                    <p>Your credit score is calculated based on the information in your credit report but is not part of the report.</p>
                                </div>
                            )}
                             <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Button onClick={() => handleToggle(2)}>
                                {toggleText(2)}
                            </Button>
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>How Long Negative Information Stays on Your Credit Report</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Federal law dictates how long negative information may remain on your credit report. Generally, negative information may remain on your file for seven years from the date of delinquency. Some bankruptcies may remain for ten years, and tax liens may remain seven years from the date they are paid.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Who Can Access Your Credit Report</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>The Fair Credit Reporting Act (FCRA) protects the privacy of your credit report. It dictates who can view your report and under what circumstances. A person or organization must have a permissible reason to view your credit report, and every time someone accesses your report it is noted on the report for you to see.</Typography>
                            {showMoreStates[3] && (
                                <div>
                                    <p>In most instances, you must grant permission for your credit report to be accessed. Granting such permission is often in the fine print of credit or employment applications. Examples of entities that might have a permissible reason to view your credit report include:</p>
                                    <ul>
                                        <li>Lenders (current and prospective)</li>
                                        <li>Insurance companies</li>
                                        <li>Landlords</li>
                                        <li>Credit card companies</li>
                                        <li>Employers</li>
                                        <li>State or local child support enforcement agencies</li>
                                        <li>Government agencies</li>
                                    </ul>
                                    <p>Undoubtedly, the most important person who has access to your credit report is you! No one is better able to spot inaccuracies on your credit report than you. Take advantage of this right and review your credit report often.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(3)}>
                                {toggleText(3)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Credit Report Errors</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Mistakes do happen, and when they happen on your credit report it can be costly. Studies show that up to 70% of consumer credit reports contain an error of some kind, and nearly one third have an error serious enough to result in credit denial. Even small errors can be costly in terms of higher interest rates.</Typography>
                            {showMoreStates[4] && (
                                <div>
                                    <p>Errors on credit reports can be the result of anything from a human mistake to a computer glitch to identity theft. The worst mistake on a credit report is the one you don’t know about! Since information is constantly flowing in and out of your credit report, it pays to review your credit report regularly to check for accuracy. A credit monitoring service will alert you to significant changes in your file.</p>
                                    <p>Common errors on credit reports include:</p>
                                    <ul>
                                        <li>Incorrect report of a payment (or nonpayment) on a loan or debt</li>
                                        <li>Misfiling by a creditor regarding a credit card account</li>
                                        <li>Confusion between persons with the same or similar name</li>
                                        <li>Accounts opened without approval by someone committing identity theft</li>
                                        
                                    </ul>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(4)}>
                                {toggleText(4)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Correcting Credit Report Errors</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>The Fair Credit Reporting Act (FCRA) states that both the credit bureau and the information provider (the person, company or organization that provided the disputed information) are responsible for correcting inaccurate information on your credit report. The two work together to accomplish this. However, the consumer must get the process started.</Typography>
                            {showMoreStates[5] && (
                                <div>
                                    <p>It is important to keep good records as you go through the process of disputing an item on your credit report. Always keep the original of all documents. When necessary, mail copies.</p>
                                    <p><b>Step One: Contact the Credit Bureau</b></p>
                                    <p>Some bureaus allow you to do this online. If that is not an option, contact the bureau in writing sending copies (not originals) of pertinent documents to support your dispute. If possible, include a copy of your credit report with the item(s) in dispute clearly identified.</p>
                                    <p><b>Credit Bureau Responsibilities</b></p>
                                    <ul>
                                        <li>Investigate your claim within 30 business days (unless it is deemed frivolous)</li>
                                        <li>Forward details to the information provider</li>
                                        <li>Provide you with contact details of the information provider</li>
                                        <li>Provide you with the results of the investigation in writing</li>
                                        <li>Provide you with a free copy of your credit report if the dispute resulted in a change to your credit report</li>
                                        <li>At your request, notify anyone who received your credit report within the past six months</li>
                                        <li>At your request, notify employers who received a copy of your report within the past two years</li>
                                        <li>At your request, add a brief statement to your report with your side of the story if the dispute is not resolved in your favor</li>
                                    </ul>
                                    <p><b>Step Two: Contact the Information Provider</b></p>
                                    <p>The credit bureau is required to provide you with contact details of the information provider. Although the bureau is also obligated to forward details of your dispute to the provider, you may also wish to contact the information provider directly to clearly document your dispute.</p>
                                    <p><b>Information Provider Responsibilities</b></p>
                                    <ul>
                                        <li>Investigate your claim</li>
                                        <li>Report the results back to the credit bureau</li>
                                        <li>Notify all three national credit bureaus if the disputed information was found to be inaccurate</li>
                                    </ul>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(5)}>
                                {toggleText(5)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Are you monitoring your credit?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={4}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' style={{ textDecoration: "none" }}><Typography level='title-md' sx={{ color: "#1976d2" }}>Get your free credit score and score report now and protect your credit.</Typography></a>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' className='blog'>Get your free credit score and score report now and protect your credit.</a>
                        </Grid> */}
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <img src={require("../../../assets/images/lt_banner_5.png")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default CreditReport;