import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AutoRedirects = () => {
    const location = useLocation();

    if (location.pathname === '/ZXZlbnRz') {
        window.location.href = "https://myfreescorenowgala.com";
        // return <Navigate to="/annualgala" />;
    }

    return null; // Render nothing if the condition is not met
};

export default AutoRedirects;