import React, { useState,useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Grid, Button, Dialog, DialogTitle, DialogActions, InputLabel, FormControl,TextField, RadioGroup, FormControlLabel, FormHelperText, MenuItem, Box
    , Select, FormLabel, Radio,Stack
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../redux/apiSlice';
import { useParams, useNavigate, Link } from 'react-router';
import AuthUser from '../Auth/AuthUser';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import InputMask from 'react-input-mask';
import swal from "sweetalert";
import Moment from 'moment';

//boostrap dialog 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
function NewActivity({ currentFollowUpDate,currentFollowUpTime, bounceId,ModalClose, dataRefresh }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const { id } = useParams();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currentDate, setCurrentDate] = useState("");
    const { http, user } = AuthUser();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:600px)');
    // const [currentFollowUpDate, setFollowUpDate] = useState("");
    // const [currentFollowUpTime, setFollowUpTime] = useState("");
    const [disable, setDisable] = useState(false);
    const [statusMessage, setStatusMesage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [companyId, setCompanyId] = useState();
    const [actionDropdown, setActionDropdown] = useState([]);
    const [formatedCurrentDate, setFormatedCurrentDate] = useState("");
    const addBouncedEmailActivity = (async () => {
        setDisable(true)
        setStatusMesage('Sending Agreement...');

        const apiUrl = '/addBounceEmailActivity';
        const args = {
            id: id
            
        }
        
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success === true) {
                // fetchLeadDetails();
                setStatusMesage(res.message.message);
                setTimeout(function () {
                    setStatusMesage('');
                    setDisable(false)
                }, 3000);
            } else {
                // fetchLeadDetails()
                setErrorMessage(res.message.message);
                setStatusMesage('');
                setTimeout(function () {
                    setErrorMessage('');
                    setDisable(false)
                }, 3000);
            }
        }
    });
    
    
    function followupChange(val,setFieldValue) {
        // console.log(val);
        if (val === 'No') {
            const element = document.getElementById("noFollowup");
            element.style.display = "none";
             // Set default value for followupDate to the current date
             setFieldValue("followupDate", dayjs(currentFollowUpDate));
        } else {
            const element = document.getElementById("noFollowup");
            element.style.display = "block";
        }
    }
    

    const activitySchema = Yup.object().shape({
        callType: Yup.string()
            .required('Please select Type'),
        followupNotes: Yup.string()
            .required('Please Note The Activity'),
        // followupTime: Yup.string().test(
        //     'is-valid-time',
        //     'Invalid time format. Please enter a valid HH:mm:ss time.',
        //     (value) => {
        //         const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
        //         return regex.test(value);
        //     }
        //     ),
        followupTime: Yup.string().when("followupRequired",{
            is: (followupRequired) => followupRequired == 'Yes',
            then: () => Yup.string().test(
                      'is-valid-time',
                      'Invalid time format. Please enter a valid HH:mm:ss time.',
                      (value) => {
                        const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
                        return regex.test(value);
                      }
                    ),
        }),
        followupDate: Yup.string().when("followupRequired",{
            is: (followupRequired) => followupRequired == 'Yes',
            then: () => Yup.string().test(
                'is-valid-year',
                      'Invalid Follow Up Date',
                      function (value) {
                        const date = new Date(value);
                        let formatedData1=Moment(date).format('MM-DD-YYYY');
                        // console.log('date'+date);
                        const currentDate = new Date();
                        const year = date.getFullYear();
                        const formattedCurrentDate = Moment(currentDate).format('MM-DD-YYYY');
                        // console.log('year'+year);
                        const splitYear = formatedCurrentDate.split('-')[2];
                        // console.log('splitYear'+splitYear);
                        // return  year >= splitYear
                         return Moment(formatedData1, 'MM-DD-YYYY').isSameOrAfter(formattedCurrentDate, 'day');
                      }
                    ),
        }),
    });
    const changeLabels = (values, statusValue) => {
        if (statusValue == "Deferred") {
            values.followUpDateLabel = "Deferred Till Date";
            values.followUpTimeLabel = "Deferred Till Time";
        } else {
            values.followUpDateLabel = "Follow Up Date";
            values.followUpTimeLabel = "Follow Up Time";
        }
    }
    useEffect(() => {
        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        // console.log("cDate :"+cDate)
        var CurrentFormatedDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YYYY'); 
        setCurrentDate(cDate);
        setFormatedCurrentDate(CurrentFormatedDate)
    },[])
    return (
        <>
            <Formik
                initialValues={{
                    callType: '',
                    action: '',
                    status: '',
                    followupNotes: '',
                    keepHotLeads: ['on'],
                    followupRequired: 'Yes',
                    followupDate: dayjs(currentFollowUpDate),
                    followupTime: (currentFollowUpTime),  
                    followUpDateLabel: 'Follow Up Date',
                    followUpTimeLabel: 'Follow Up Time'
                }}
                validationSchema={activitySchema}
                onSubmit={async (values, errors) => {
                    const date = new Date(values.followupDate);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = date.getFullYear();
                    var mydate = year + "/" + month + "/" + day;
                    var todaysTime = values.followupTime;
                    const apiUrl = '/addBounceEmailActivity';
                    const args = {
                        id: bounceId,
                        callType: values.callType,
                        followupDate: mydate,
                        followupNotes: values.followupNotes,
                        followupRequired: values.followupRequired,
                        followupTime: values.followupTime,
                    }
                    const res = await dispatch(postData({ apiUrl, args })).unwrap();
                    if (res) {
                        if (res.success === true) {
                            ModalClose();
                            dataRefresh();                                                   
                        } else {
                            swal({
                                title: "Failed",
                                text: res.message,
                                icon: "error",
                                button: "Ok",
                            })
                            if (res.message == "This action is unauthorized.") {
                                navigate('/login');
                            }

                        }
                    }
                }}
            >
                {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <Form>
                        <Box mt={1}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl fullWidth size="small"
                                        error={touched.callType && errors.callType ? errors.callType : ''}>
                                        <InputLabel>Select Type *</InputLabel>
                                        <Select
                                            name="callType"
                                            value={values.callType}
                                            label="Select Type *"
                                            onBlur={handleBlur('callType')}
                                            onChange={(e) => {
                                                // handleCallType(e.target.value);
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem value="Call">Call</MenuItem>
                                            <MenuItem value="Text">Text</MenuItem>
                                        </Select>
                                        <FormHelperText>{touched.callType && errors.callType ? errors.callType : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>   
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                                    <FormControl fullWidth size="small"
                                        error={touched.followupNotes && errors.followupNotes ? errors.followupNotes : ''} >
                                        <TextareaAutosize
                                            style={{ width: "100%" }}
                                            required
                                            margin="dense"
                                            aria-label="minimum height"
                                            minRows={6}
                                            placeholder="Enter Note*"
                                            name="followupNotes"
                                            onChange={handleChange}
                                            onBlur={handleBlur('followupNotes')}
                                            value={values.followupNotes}
                                        />
                                        <FormHelperText>{touched.followupNotes && errors.followupNotes ? errors.followupNotes : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl >
                                        <FormLabel id="">Follow up Required :</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="followupRequired"
                                            value={values.followupRequired}
                                            onChange={(e) => {
                                                followupChange(e.target.value,setFieldValue);
                                                handleChange(e)
                                            }}
                                            onBlur={handleBlur('followupRequired')}
                                        >
                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="No" control={<Radio />} label="No" />
                                            <FormHelperText>{touched.followupRequired && errors.followupRequired ? errors.followupRequired : ''}</FormHelperText>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid container item lg={12} md={12} xs={12} sm={12} id="noFollowup"> 
                                    <Stack direction="row" spacing={3}>
                                        <Grid item lg={6} md={6} xs={12} sm={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DatePicker']} fullWidth size="small">
                                                    <DatePicker label={values.followUpDateLabel + "*"}
                                                        margin="dense"
                                                        // onChange={handleChange} 
                                                        onChange={(value) => { setFieldValue("followupDate", value, true) }}
                                                        onBlur={handleBlur}
                                                        value={values.followupDate}
                                                        name="followupDate"
                                                        minDate={dayjs(currentDate)}
                                                        maxDate={dayjs().add(75, 'year')}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            <FormHelperText style={{ color: touched.followupDate && errors.followupDate ? 'red' : 'inherit' }}>
                                                {touched.followupDate && errors.followupDate ? errors.followupDate : ''}
                                            </FormHelperText>
                                            {/* <FormHelperText>{touched.followupDate && errors.followupDate ? errors.followupDate : ''}</FormHelperText> */}
                                        </Grid>
                                        <Grid item  lg={6} md={6} xs={12} sm={12}>
                                            <InputMask
                                                mask='99:99:99'
                                                label={values.followUpTimeLabel + "*"}
                                                maskChar={null}
                                                name='followupTime'
                                                fullWidth
                                                margin="dense"
                                                // size="small"
                                                id='followupTime'
                                                value={values.followupTime}
                                                onChange={handleChange}
                                                onBlur={handleBlur('followupTime')}
                                            >
                                                {(inputProps) =>
                                                    <TextField
                                                        label={values.followUpTimeLabel + "*"} variant="outlined"
                                                        {...inputProps}
                                                    />
                                                }
                                            </InputMask>
                                            {/* <FormHelperText>{touched.followupTime && errors.followupTime ? errors.followupTime : ''}</FormHelperText> */}
                                            {
                                                touched.followupTime && errors.followupTime ? (
                                                    <div className='error'>{errors.followupTime}</div>
                                                ) : (
                                                    ''
                                                )
                                                }
                                        
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button autoFocus variant="contained" sx={{ minWidth: isMobile ? 100 : 250 }}
                                        onClick={() => { ModalClose() }}>
                                        CLOSE
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" sx={{ minWidth: isMobile ? 100 : 250 }} color="success" type="submit"
                                        onClick={handleSubmit}
                                        disabled={(isSubmitting)}>
                                        SAVE
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    )
}
export default NewActivity;