import React, { useState } from 'react';
import {
    Box, Tooltip,
} from '@mui/material';
import {  Table } from '@mui/joy';
import AuthUser from "../Auth/AuthUser";
import config from '../../AppConfig';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import swal from "sweetalert";
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

function CROExpand(props) {
    const { http } = AuthUser();
    const [passwordVisibility, setPasswordVisibility] = useState({});
   
  

    //loader function and variable start

    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const decodeBase64 = (str) => {
        try {
            var passValue = str =  atob(str);
            var masked = str.substring(0, str.length - 4);
            masked = masked.replace(/./g, '*'); //The character is ASCII-7 (Press Alt+7 to type)
            var text = passValue.substring(passValue.length - 4);
            var newPass = masked + text;
            return newPass;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };
   
    const getPasswordString = (str) => {
        try {
            var passValue = str =  atob(str);
            return passValue;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };
    const togglePasswordVisibility = (rowId) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [rowId]: !prevState[rowId],
        }));
    };

    //loader function and variable end

    const MemberLogin = (option) => {
        handleFreezStart();
        console.log(option.password)
        //Call API for login
        http.post("/creditSnapshotEnrollLogin", {
            email:option.email,
            password: atob(option.password),
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {

                    if (res.data.step === "inCreditSuccess") {
                        localStorage.setItem("ScoreData", JSON.stringify(res.data.data));

                        // Get the base URL
                        const baseUrl = window.location.origin;

                        // Append "/score" to the base URL
                        const updatedUrl = config.BASE_URL + `score`;

                        // Navigate to the updated URL

                        window.open(updatedUrl, '_blank');

                    }

                } else {
                    swal({
                        title: "Failed",
                        text: "Something Went wrong please contact to admin",
                        icon: "error",
                        button: "Ok",
                    })

                }
            })

    }



    return (
        <><Box
        >
                <Table  borderAxis="both">
                <thead>
                    <tr>
                        <th sx={{ fontWeight: "bold" }}>First Name</th>
                        <th sx={{ fontWeight: "bold" }} align="center">Last Name</th>
                        <th sx={{ fontWeight: "bold" }}>AID</th>
                        <th sx={{ fontWeight: "bold" }} align="center">Email</th>
                        <th sx={{ fontWeight: "bold" }}>Mobile</th>
                        <th sx={{ fontWeight: "bold" }} align="center">SSN</th>
                        <th sx={{ fontWeight: "bold" }} align="center">PID</th>
                        <th sx={{ fontWeight: "bold" }}>Enrolled Date</th>
                        <th sx={{ fontWeight: "bold" }} align="center">Password</th>
                        <th sx={{ fontWeight: "bold" }} align="center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {props.props.original.parentInfo.childInfo.map((option) => (
                        <tr key={option.id}>
                            <td >
                                {option.first_name ? option.first_name : ''}
                            </td>
                            <td >
                                {option.last_name ? option.last_name : ''}
                            </td> 
                            <td className='datatable'>
                                {option.aid ? option.aid : ''}
                            </td>
                            <td  className="datatable">
                              {option.email ? option.email : ''}
                            </td>
                            <td >
                                {option.mobile ? option.mobile : ''}
                            </td>
                            <td >
                                {option.ssn ? option.ssn : ''}
                            </td>
                            <td >
                                {option.pid ? option.pid : ''}
                            </td>
                            <td >
                                {option.created_at ? format(new Date(option.created_at), 'dd MMM yyyy') : ''}
                            </td>
                            <td >
                                <Box>
                                    {passwordVisibility[option.id] ? getPasswordString(option.password) : decodeBase64(option.password) }
                                    {
                                   
                                        `${decodeBase64(option.password).length}` > 0 
                                        ?
                                            <>
                                            {passwordVisibility[option.id] ?
                                            <VisibilityOffIcon style={{marginLeft: "10%"}} onClick={() => togglePasswordVisibility(option.id) }/> 
                                            :
                                            <VisibilityIcon style={{marginLeft: "10%"}} onClick={() => togglePasswordVisibility(option.id) }/> }

                                
                                            </>
                                        : 
                                        'N/A'
                                   
                                    }
                                </Box>
                            </td>
                            <td>
                            <Tooltip title="Login" arrow><Link target="_blank"  to={"/score/"+option.user_id} style={{border:"none",background:"none",borderRadius:"0%"}}><img src={require('../../assets/icons/login.png')} /></Link></Tooltip>
                            </td>
                            
                        </tr>
                    ))}
                </tbody>
            </Table>
            
        </Box></>
    );
}


export default CROExpand;