import React, { useEffect, useState } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box,Grid,Divider,Table,TableCell,Dialog ,DialogTitle ,TableRow, Button ,
    DialogContent , Tooltip } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AuthUser from "../../../Components/Auth/AuthUser";
import { Typography,Card,CardContent,CardOverflow,  } from '@mui/joy';
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/HighlightOff';
import { useDispatch } from 'react-redux';
import { fetchData, } from '../../../redux/apiSlice';
import NewActivity from '../../../Components/CommonComponets/NewActivity';
import ChangeEmailDialog from '../../../Components/CommonComponets/ChangeEmailDialog';
const RequestFormInitialValues = {
    email : '',
    OTP : '',
};


  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];



function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function BounceEmailActivityLog(props,ModalClose) {
    const dispatch = useDispatch(); 
     const { id } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [freez, setFreez] = React.useState(true);
    const [currentFollowUpDate, setFollowUpDate] = useState("");
    const [currentFollowUpTime, setFollowUpTime] = useState("");
    const [companyName, setCompanyName] = useState();
    const [email,setEmail] = useState();
    const [communicationEmail, setCommunicationEmail] = useState();
    const [companyId, setCompanyId] = useState();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isDialogOpen1, setDialogOpen1] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
   
    // fetch company details 
   
    const fetchBounceEmails = async () => {
       
        handleFreezStart();
        const apiUrl = '/bounceEmailAcitivityLog/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            
            handleFreezStop();
            setData(res.data);
            setCompanyName(res.data.company.company_name);
            setCommunicationEmail(res.data.reference_affiliates.communication_email);
            setEmail(res.data.reference_affiliates.email);
            setCompanyId(res.data.reference_affiliates.company_master_id);

       }
    };


    useEffect(() => {
        fetchBounceEmails();
        
    }, []);

   
    const handleClickOpen = () => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY');
        const todaysTime = Moment(newDate).tz('America/Los_Angeles').format('hh:mm:ss');
        setFollowUpDate(todaysDate);
        setFollowUpTime(todaysTime);
        setDialogOpen(true);
      };
    
      const handleClose = () => {
        setDialogOpen(false);
      };
    //popup new activity end here
    //change email popup start here
    const handleClickOpen1 = () => {
        setDialogOpen1(true);
      };
    
      const handleClose1 = () => {
        setDialogOpen1(false);
      };
    //change email popup end here
    const setErrorMessageFun = (successMessage , errorMessage) => {

        // console.log("message: ", successMessage,errorMessage);
        setErrorMessage(errorMessage)
        setSuccessMessage(successMessage)
        setTimeout(function () {
            setSuccessMessage('')
            setErrorMessage('')
        }, 5000);
                    
      } 

    

    const ContentLayout=
    
    <>
    <Box>
        <Grid container lg={12} md={12} sm={12} xs={12} direction="row" sx={{justifyContent:'space-between'}}>
            <Grid item lg={5} md={5} sm={12} xs={12}><Typography level="h4" color="primary">Bounced Email Details & Follow Up</Typography></Grid>
            <Grid item container lg={7} md={7} sm={12} xs={12}>
                <Grid item xs={11}>
                    <Card variant="outlined" size="sm" fullWidth>
                        <CardContent>
                            <Grid container textAlign={'center'} item md={12}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography level="h5">Status: <Typography level='body1'>
                                    {data.status === 'change' ? 'Updated' : data.status ? data.status : 'N/A'}
                                        </Typography>&nbsp;
                                    </Typography>
                                </Grid> 
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography level="h5">Next Follow up: &nbsp;
                                        <Typography level='body1'>
                                        {data.bounce_email_activity && data.bounce_email_activity.next_followup
                                                ? Moment(data.bounce_email_activity.next_followup).format('MM-DD-YY hh:mm') + 'Hrs'
                                                : 'N/A'}
                                        </Typography>
                                    </Typography>
                                </Grid> 
                            </Grid>
                        </CardContent>
                    </Card>                            
                </Grid>
                <Grid item xs={1} textAlign="right" sx={{justifyContent:"center"}}>
                    <Tooltip title="Close" >
                        <BackIcon fontSize="large" sx={{cursor:'pointer'}} onClick={() => navigate(-1)} />                            
                    </Tooltip>                            
                </Grid>
            </Grid>                
        </Grid>
        <Grid container lg={12} md={12} sm={12} xs={12} direction="row" spacing={2} pt={2}>
            <Grid item  lg={5} md={5} sm={12} xs={12}>
                <Card variant="outlined" size="lg">                            
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',
                        }}
                    >
                        <Typography level="h5" sx={{ mb: 0.5 }}>Company Details</Typography>
                    </CardOverflow>
                    <CardContent>
                        <Table>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                <TableCell>{data.reference_affiliates ? data.reference_affiliates.first_name : "N/A"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                <TableCell>{data.reference_affiliates ? data.reference_affiliates.last_name : "N/A"}</TableCell>
                            </TableRow>
                            {data.verified_flag === 1 ? 
                                <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Updated Email</TableCell>
                                <TableCell>{data.changed_email ? data.changed_email : "N/A"}</TableCell>
                                </TableRow>
                            :
                                <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Bounced Email</TableCell>
                                <TableCell>{data.email ? data.email : "N/A"}</TableCell>
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Phone No.</TableCell>
                                <TableCell>{data.reference_affiliates ? data.reference_affiliates.phone_no : 'N/A' }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Company Name:</TableCell>
                                <TableCell>
                                {data.company ? data.company.company_name : "N/A"}
                                    </TableCell>
                            </TableRow>
                            
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Updated On:</TableCell>
                                <TableCell>{data.updated_at ? Moment(data.updated_at).format('MM-DD-YYYY') : "N/A"}</TableCell>
                            </TableRow>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
                <Card variant="plain" size="sm"  sx={{boxShadow:'none'}}>
                    <Grid container  item md={12} sx={{justifyContent:'space-between'}}>
                        <Typography level="h5" color="primary">ACTIVITY LOG</Typography>
                        <Button 
                            variant="contained" 
                            color='success' 
                            onClick={handleClickOpen} 
                        >New Activity</Button> 
                        <Button 
                            variant="contained" 
                            color='success' 
                            onClick={() => {
                                handleClickOpen1(
                                  data.company.company_name,
                                  data.company_master_id,
                                  data.email,
                                  data.reference_affiliates
                                    ? data.reference_affiliates.communication_email
                                      ? data.reference_affiliates.communication_email
                                      : ""
                                    : ""
                                );
                              }}
                     
                        >Update Email</Button> 
                    </Grid>                            
                </Card>
            {data.bounce_email_activity_all && data.bounce_email_activity_all.map((logs, idx, array)=>{
                    return(
                    <>
                    <Card variant="outlined" size="lg" sx={{marginTop:2}} >
                        <CardOverflow
                            sx={{
                                bgcolor:'#F6FAFF',
                                borderColor:'#F6FAFF',
                                padding: '1%',
                            }}
                        >
                            <Grid container  item md={12} sx={{justifyContent:'space-between'}}>
                                <Typography>
                                    <Typography level="h6" sx={{ mb: 0.5 }}>{logs.type?logs.type:'Staff'} {array.length - idx} : &nbsp;</Typography>
                                    <Typography > 
                                    {logs.user?logs.user.first_name+' '+logs.user.last_name:'N/A'}
                                    </Typography>                                            
                                </Typography>
                                {/* activity created date start here */}
                                <Typography>
                                    {logs.created_at ? Moment(logs.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm')+'Hrs' : 'N/A'} 
                                </Typography>
                                {/* activity created date end here */}
                            </Grid>
                        </CardOverflow>
                        <CardContent>
                           
                            {logs.previous_email && (
                                <>
                            <Grid container item md={12} p={2}>
                                <Typography level="h6">Previous Email: &nbsp;
                                        <Typography level="body1">
                                        {logs.previous_email}
                                        </Typography>
                                    </Typography>
                            </Grid>
                            <Divider />
                            </>
                            )}
                            {logs.updated_email && (
                                <>
                            <Grid container item md={12} p={2}>
                                <Typography level="h6">Updated Email: &nbsp;
                                        <Typography level="body1">
                                        {logs.updated_email}
                                        </Typography>
                                    </Typography>
                            </Grid>
                            <Divider />
                            </>
                            )}
                            <Grid container  item md={12} pt={2}>
                                <Typography level="h6">Note:</Typography>                                                                             
                            </Grid>
                            <Grid container  item md={12} pt={1}>
                                <Typography level="body1">{logs.notes}</Typography>  
                            </Grid>
                        </CardContent>
                    </Card>
                    </>
                 ); 
            })} 
            </Grid>
        </Grid>
        {/* new activity popup start here */}
        <Dialog
            open={isDialogOpen}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            fullWidth
            minWidth="lg"
        >
            <DialogTitle>
                <Typography level="h3" sx={{ mb: 0.5 }}>New Activity</Typography>
            </DialogTitle>
            <DialogContent>
                    <NewActivity currentFollowUpDate={currentFollowUpDate} currentFollowUpTime={currentFollowUpTime} bounceId={data.id} ModalClose={handleClose} dataRefresh ={fetchBounceEmails} />
            </DialogContent>
        </Dialog>
      {/* new activity popup end here */}
      {/* change email popup  start here */}
      <Dialog
            open={isDialogOpen1}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleClose1();
                }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            fullWidth
            minWidth="md"
        >
            
            <DialogContent>
            
            
                    <ChangeEmailDialog ModalClose1={handleClose1} company_name={companyName } email={email} communication_email={communicationEmail} companyId={companyId} onValueChange={setErrorMessageFun}  fetchData = {fetchBounceEmails} />
                    
            </DialogContent>
        </Dialog>
      {/* change email popup end here */}
    </Box>
    {/* :''} */}
    </>
     
    return(
        <div>
            <DefaultLayout content={ContentLayout}  />
        </div>
    );
}


export default BounceEmailActivityLog;