import React,{useMemo,useState,useEffect} from 'react';
import { TextField,Grid, CardActions,Button,Box,Collapse,Alert } from '@mui/material';
import { Typography, Card, CardOverflow, CardContent } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../Components/Auth/AuthUser';
import { useParams } from 'react-router';
import Loader from '../../../Components/Loader/Loader';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import InputMask from 'react-input-mask';

let dublicatePid = 0;

function UpdatePid(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [pidObj, setPidObj] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    let success = localStorage.getItem('success');
    let error = localStorage.getItem('error');
    const [sponsorCode, setsponsorCode] = useState();
    const [errors1, setErrors] = useState({});
    const [errors2, setErrors2] = useState({});

    const {user} = AuthUser();
    const [freez, setFreez] = React.useState(false);

    // API CAll 
    const getPidData = async () => {
        const apiUrl = '/setPID/show/'+id;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            setPidObj(res.data);
            setsponsorCode(res.data.sponsorCode);
            setIsLoading(false);
        }
    }
    // API Call ends here

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };  

    useEffect(()=>{
        if (user.roles_id < 3) {
            getPidData();
        }else{
            navigate('/login');
        }
    },[]);

    const pidValidate = Yup.object().shape({
        pid: Yup.string()
            .min(2,'Too SHort!')
            .max(5, 'PID must be 5 digits')
            .required('Please Enter PID to set.'),
    })
    const handledSponsorCode = (event) => {
        if (event == '') {
            errors1.sponsorCodes = 'Sponsor Code is required.';
            setErrors(errors1);
        } else {
            errors1.sponsorCodes = '';
            setErrors(errors1);
        }
    };
    const handledPidCheck = async (event) => {
        errors2.pid = '';
        if (event.length > 4 )  
        {
            handleFreezStart();
            const apiUrl = '/DublicatecheckPidsExist';
            const args = {
                pid: event,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                handleFreezStop();
                if(res.success == false){
                    errors2.pid = res.message;
                    setErrors2(errors2);
                    dublicatePid = 1;
                    setOpenError(true);
                    localStorage.setItem('error',res.message)
                    setTimeout(() => {
                        setOpenError(false);
                        localStorage.removeItem('error');
                    }, 5000);
                }else{
                    errors2.pid = '';
                    setErrors2(errors2);
                    dublicatePid = 0;
                }
            }

        }else if(event.length != 0){
            errors2.pid = 'PID must be 5 digits';
            setErrors2(errors2);
            dublicatePid = 1;
        }
    };

    

    console.log(pidObj);

    const ContentLayout=
    <>
    
        <Box>
            <Grid xs={12} p={5}>
                <Card variant='outlined' >
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',                            
                        }}
                    >
                        <Typography level="h4" color="primary">Set PID for AID - {pidObj.aid}</Typography>
                    </CardOverflow>
                    <CardContent >
                        {
                        success
                        ?
                            <Collapse in={openSuccess}>
                                <Alert fullWidth onClose={() => {localStorage.removeItem('success');setOpenSuccess(false)}}>{success}</Alert>
                            </Collapse>
                        :''
                        }  
                        {
							error
								?
								<Collapse in={openError}>
									<Alert severity='error' fullWidth onClose={() => { localStorage.removeItem('error'); setOpenError(false) }}>{error}</Alert>
								</Collapse>
								: ''
						} 	
                        <Formik
                            initialValues={{
                                pid: '',
                                sponsorCodes:'',

                            }}
                            validationSchema={pidValidate}
                            onSubmit={ async (values, error)=>{
                                const loginUser = JSON.parse(localStorage.getItem('user'));
                                if(loginUser.roles_id >= 3 ) {
                                    navigate('/login');
                                    return false;
                                }
                                const errors1 = {};
                                if (sponsorCode == 1 && values.sponsorCodes == '') {
                                    errors1.sponsorCodes = 'Sponsor Code is required.';
                                    setErrors(errors1);
                                    return false;
                                }
                                
                                    handleFreezStart();
                                    
                                    const apiUrl = '/updatePID';
                                    const args = {
                                        aidMasterId: id,
                                        cobrandId: pidObj.cobrandId,
                                        pid: values.pid,
                                        basePid: pidObj.basePid,  
                                        sponsorCodes:values.sponsorCodes,  
                                        url:config.BASE_URL,
                                    }
                                    const res = await dispatch(postData({apiUrl,args})).unwrap();
                                    if(res){  
                                        if(res.success){
                                            handleFreezStop();
                                            localStorage.setItem('success',res.message)
                                            setOpenSuccess(true);
                                            setTimeout(() => {
                                                setOpenSuccess(false);
                                                localStorage.removeItem('success');
                                            }, 6000);
                                            navigate(-1);
                                        }else{
                                            handleFreezStop();
                                            setOpenError(true);
                                            localStorage.setItem('error',res.message)
                                            setTimeout(() => {
                                                setOpenError(false);
                                                localStorage.removeItem('error');
                                            }, 5000);
                                        }
                                    } 
                                                         
                            }}
                        >
                            {({values, errors,touched,isSubmitting, handleChange, handleBlur, handleSubmit}) => (
                                <Box p={5}>
                                    {
                                        pidObj.selectedPlans
                                        ?
                                        Object.values(pidObj.selectedPlans).length > 0
                                        ?
                                        <>
                                        <Grid container item lg={12} md={12} xs={12} sm={12}>
                                            <Typography>Current Co-brand Plans :</Typography>
                                        </Grid>
                                        {pidObj.selectedPlans.map((row,idx)=>{
                                            return(
                                                <Grid container item lg={12} md={12} xs={12} sm={12}>
                                                    <Typography sx={{fontWeight:"bold"}}>{idx+1} PID : {row.pid}, Plan - {row.base_plan.plan_name ? row.base_plan.plan_name : 'N/A'}</Typography>
                                                </Grid>
                                            )
                                        })}
                                        
                                        </>
                                        : null
                                        : null
                                    }
                                    {
                                        pidObj.requestedPlan
                                        ?
                                        <>
                                        <Grid container item lg={12} md={12} xs={12} sm={12} pt={2}>
                                            <Typography>Requested co-brand plans :</Typography>
                                        </Grid>                                        
                                        <Grid container item lg={12} md={12} xs={12} sm={12}>                                            
                                            
                                            <Typography sx={{fontWeight:"bold"}}>{pidObj.requestedPlan.base_plan.plan_name}
                                            {
                                                pidObj.requestedPlan.base_plan.base_pid != 0 ? `-(Clone of PID ${pidObj.requestedPlan.base_plan.base_pid })` : ''
                                            }
                                            </Typography>
                                            
                                        </Grid>                                        
                                        </>
                                        :
                                        null
                                    }

                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}> 
                                                        <InputMask
                                                            mask='99999'
                                                            maskChar={null}
                                                            label="Set PID*"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            name='pid'
                                                            value={values.pid}
                                                            id='pid'
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                handledPidCheck(e.target.value);
                                                            }}
                                                            // onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            helperText={touched.pid && errors.pid ? errors.pid : ''}
                                                            error={touched.pid && errors.pid ? errors.pid : ''}
                                                            >
                                                                {(inputProps) =>
                                                                <TextField
                                                                    label="Set PID*" variant="outlined"
                                                                    {...inputProps}
                                                                />
                                                                
                                                            }
                                                        
                                                        </InputMask>                           
                                        {/* <TextField
                                            label="Set PID*"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='pid'
                                            value={values.pid}
                                            id='pid'
                                            onChange={(e) => {
                                                handleChange(e);
                                                handledPidCheck(e.target.value);
                                            }}
                                            // onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.pid && errors.pid ? errors.pid : ''}
                                            error={touched.pid && errors.pid ? errors.pid : ''}
                                        />
                                        {errors2.pid && <div className="error">{errors2.pid}</div>} */}

                                    </Grid>
                                   {sponsorCode == 1 ? <>
                                         <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                            <TextField
                                                label="Sponsor Code*"
                                                type="text"
                                                size="small"
                                                fullWidth
                                                name='sponsorCodes'
                                                value={values.sponsorCodes}
                                                id='sponsorCodes'
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handledSponsorCode(e.target.value);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        {errors1.sponsorCodes && <div className="error">{errors1.sponsorCodes}</div>}
                                        </>
                                       : null }
                                       
                                    <Grid container  item md={12}  mt={2}>                                       
                                        <Grid md={6} textAlign="center">
                                            <Button variant="contained" sx={{minWidth:"60%"}} color="success" type="submit" size='lg' onClick={handleSubmit}>UPDATE</Button>
                                        </Grid>      
                                        <Grid md={6} textAlign="center">
                                            <Button variant="contained" sx={{minWidth:"60%"}} color="primary" type="submit" size='lg' onClick={()=>{navigate(-1)}}>CLOSE</Button>
                                        </Grid>                       
                                    </Grid>
                                </Box>
                            )}
                        </Formik>
                    </CardContent>                 
                </Card>
            </Grid>
    </Box>

    {/* Step 4: Display the confirmation dialog */}
      
          {/* loader code start */}
          <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
                // onClick={handleFreezStop}
            >
                <Freez />
            </Backdrop>
        </div>
        {/* loader code end */}

    </>
   
 
return(
    <div>
        <DefaultLayout content={ContentLayout} />
    </div>

    
);
    

}
    
export default UpdatePid;