import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { styled, alpha } from '@mui/material/styles';
import { Box, Tab, Grid, FormControl, Button, InputLabel, MenuItem, OutlinedInput, Select,Alert,Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import MemberListData from '../../../Components/MemberList/MemberListData';
import { DateRangePicker } from 'react-date-range';
import { Typography } from '@mui/joy';
import { addDays, format, subDays } from 'date-fns';
import AddMissingMember from '../../../Components/ManageMembers/AddMissingMember';
function Index(props) {
    const { tab } = useParams();
    const [value, setValue] = React.useState(tab);
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const setErrorMsg = (msg) => {
        setErrorMessage(msg);
    }

    const setSuccessMsg = (msg) => {
        setSuccessMessage(msg);
    }

    useEffect(() => {
        setValue(tab);
    })
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //Date range select function start
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: subDays(new Date(), 7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    //Date range select function start
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    useEffect(() => {
        setStartDate(format(selectionRange[0].startDate, 'dd-MM-yyyy'))
        setEndDate(format(selectionRange[0].endDate, 'dd-MM-yyyy'))
    }, [selectionRange]);
    //Clear date ranges start
    const clearFilter = () => {
        setStartDate('');
        setEndDate('');
    }
    //Clear date ranges start
    //Get referal info function start
    const ContentLayout = <>
        <Grid container item lg={12} md={12} sm={12} xs={12} mb={1} mt={1.5} sx={{ justifyContent: "space-between" }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography level="h4" color="primary">Member List</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "right"}}>
                <Button  variant="contained" color="warning" onClick={handleClickOpen}>
                    Request/Add missing member
                </Button>
            </Grid>
        </Grid>

        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            fullWidth
            minWidth="md"
        >
            <DialogTitle>
                <Typography level="h3" sx={{ mb: 0.5 }}>Member Details</Typography>
            </DialogTitle>
            <DialogContent>
                {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                <AddMissingMember onError={setErrorMsg} onSuccess={setSuccessMsg} ModalClose={handleClose} />
            </DialogContent>
                    </Dialog>
       
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }}>
                        <Grid item lg={12} md={12} xs={12} sm={12}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                <Tab label="Active" component={Link} to="/memberlist/active" value="active" sx={{ color: 'black' }} />

                                <Tab label="Paused" component={Link} to="/memberlist/paused" value="paused" sx={{ color: 'black' }} />
                            </TabList>

                        </Grid>
                    </Grid>
                </Box>
                <TabPanel value="active"><MemberListData tab={tab} /></TabPanel>
                <TabPanel value="paused"><MemberListData tab={tab} /></TabPanel>
            </TabContext>
        </Box>
    </>

    return (
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}

export default Index;