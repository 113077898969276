import React, { useState, useEffect } from "react";


import 'zingchart/es6';
import ZingChart from 'zingchart-react';
// EXPLICITLY IMPORT MODULE
import 'zingchart/modules-es6/zingchart-depth.min.js';
// Configure ZingChart with your license key
// window.zingchart.LICENSE = ['your-license-key'];


const SpeedometerComponent = (props) => {




const [flag,setFlag]= useState(false);

    // window.feed = function(callback) {
    //     var tick = {};
    //     tick.plot0 = Math.ceil(350 + (Math.random() * 500));
    //      callback(JSON.stringify(tick));
       
    // };
    
    // var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
	// if(width < 770){
	//     console.log(width);
    //     var chart_height = 320;
	// }
	// else{
	//     console.log(width);
	//     var chart_height = 360;
	//    //  var plotly_width = 480;
	//    // var plotly_height = 280;
	// }


    var chart_height = 360;
	
    var border_color = 'black';
    var background_color = 'black';
    var centerTextColor = 'black';
    var centerTextSize = 30;
    var score = props.props;
    
    
    var scoreRanges = [{low: 300, high: 599, name: 'Poor'},
    {low: 600, high: 660, name: 'Fair'},
{low: 661, high: 715, name: 'Good'},
{low: 716, high: 747, name: 'Very Good'},
{low: 748, high: 850, name: 'Excellent'}]

    var plotRules = new Array();
    var dynamicLabels = new Array();
    var ringRules = new Array();

    var i;
    
    for(i=0; i<scoreRanges.length; i++){
        
        
        dynamicLabels.push(scoreRanges[i]["low"])
        
        if(scoreRanges[i]["name"] === 'Poor'){
            ringRules.push({
                "rule":"%v >= "+scoreRanges[i]["low"]+' && %v <= '+scoreRanges[i]["high"],
                "background-color":"#df240c"
            })
            
            if(score >= scoreRanges[i]["low"] && score <= scoreRanges[i]["high"]){
                background_color= '#df240c';
                centerTextColor="white";
            }
        }
        if(scoreRanges[i]["name"] === 'Fair'){
            ringRules.push({
                "rule":"%v >= "+scoreRanges[i]["low"]+' && %v <= '+scoreRanges[i]["high"],
                "background-color":"#fe992b"
            })
            if(score >= scoreRanges[i]["low"] && score <= scoreRanges[i]["high"]){
                background_color= '#fe992b';
                centerTextColor="white";
            }
        }
        if(scoreRanges[i]["name"] === 'Good'){
            ringRules.push({
                "rule":"%v >= "+scoreRanges[i]["low"]+' && %v <= '+scoreRanges[i]["high"],
                "background-color":"#eff307"
            })
            if(score >= scoreRanges[i]["low"] && score <= scoreRanges[i]["high"]){
                background_color= '#eff307';
            }
        }
        if(scoreRanges[i]["name"] === 'Very Good'){
            ringRules.push({
                "rule":"%v >= "+scoreRanges[i]["low"]+' && %v <= '+scoreRanges[i]["high"],
                "background-color":"#c9ff38"
            })
            if(score >= scoreRanges[i]["low"] && score <= scoreRanges[i]["high"]){
                background_color= '#c9ff38';
                centerTextSize = 20;
            }
        }
        if(scoreRanges[i]["name"] === 'Excellent'){
            ringRules.push({
                "rule":"%v >= "+scoreRanges[i]["low"]+' && %v <= '+scoreRanges[i]["high"],
                "background-color":"#01c201"
            })
            if(score >= scoreRanges[i]["low"] && score <= scoreRanges[i]["high"]){
                background_color= '#01c201';
                centerTextSize = 20;
                centerTextColor="white";
            }
            
        }
        
        plotRules.push({
            "rule": '%v >= '+scoreRanges[i]["low"]+' && %v <= '+scoreRanges[i]["high"],
            "text": '%v <br>'+scoreRanges[i]["name"],
            'fontSize': centerTextSize,
            'color':centerTextColor,
        });
        
    }
    dynamicLabels.push(scoreRanges[scoreRanges.length-1]["high"]);
    
    var highestScore=scoreRanges[scoreRanges.length-1]["high"];

    var myConfig = {
        
        type: "gauge",
        globals: {
            fontSize: 18,
        },
        gui:{
            contextMenu: {
                empty: true
            }
        },
        plot:{
            size:'100%',
            valueBox: {
                placement: 'center',
                text:'%v', //default
                color: "#000000",
                fontSize:35,
                rules:plotRules,
               
            }
        },
        tooltip:{
            borderRadius:3
        },
        
        scaleR:{
            aperture:270,
            // values: "300:900:120",
            values:dynamicLabels,
            
            "center":{
                "size": 65,
                "background-color":background_color,
                "border-color":border_color,
                
                "border-width":"3"
            },
            tick:{
                visible:false
            },
            item:{
                offsetR:0,
                rules:[
                {
                    rule:'%i == 9',
                    offsetX:15
                }
                ]
            },
           
            
            labels:dynamicLabels,
            ring:{
                size:40,
                rules:ringRules,
            }
        },
        refresh:{  
            type:"feed",
            transport:"js",
            url:"feed()",
            interval:1500,
            resetTimeout:1000
        },
        series : [
        {
            values : [0], // starting value
            indicator : [10,0,0,0,0.3],
            backgroundColor:'black',
            csize : "10%",
            size : "80%", 
            animation:{  
                effect:2,
                method:1,
                sequence:4,
                speed: 400
            },
            
		}
    	]
    };
    
    const [chartData, setChartData] = useState({});
    useEffect(() => {
        // Update chartData state with the chart configuration
        const tick = {};
        tick.plot0 = Math.ceil(350 + (Math.random() * 500));
      
        // Set chart configuration
        const config = {
          ...myConfig, // Replace myConfig with your chart configuration object
          series: [
            {
              ...myConfig.series[0], // Replace myConfig.series[0] with your series data object
              values: [props.props], // Update with your actual data
            },
          ],
        };
      
        setChartData(config); // Update the state with the chart configuration
        setFlag(true);
      
      }, []);
      
  return (
  <>
  {
     <ZingChart data={chartData} /> 
     
  }
    
    </>
  );
};

export default SpeedometerComponent;
