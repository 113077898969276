import React, {useState} from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {Grid,Button } from '@mui/material';
import Typography from '@mui/joy/Typography';
import swal from "sweetalert";
import {useDispatch } from 'react-redux';
import {postData } from '../../../redux/apiSlice';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
function CreditExpaired(props) {
    const dispatch = useDispatch();
    const [freez, setFreez] = React.useState(false);
    const [disabled, setDisabled] = React.useState(props.bounceFlag);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //Snapshot start
  const enrollSnapshot = async() => {
    setDisabled(true);
    handleFreezStart();
    const apiUrl = '/reGenerateAgreementForCRC';
    const args = {
       
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === true) {
        handleFreezStop();
        swal({
          title: "Success",
          text: res.message.message,
          icon: "success",
          button: "Ok",
        })
        .then(() => {
              window.location.reload();
        })
      } else {
        handleFreezStop();
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        });
      }
    }else {
      handleFreezStop();
      swal({
        title: "Failed",
        text: res.message,
        icon: "error",
        button: "Ok",
      });
    }
  }
  //Snapshot end
    function ContentLayout() {
        return (
            <>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography level="body-lg">Sorry! The validity of your original contract is over.</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                <Typography level="body-lg">To generate the contract and enroll to <b>MyFreeScoreNow Lead Generation Program.</b> click the button below.</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                <Button variant='contained'  onClick={enrollSnapshot} disabled={disabled}>Regenerate Contract</Button>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                {disabled ? <Typography sx={{mt:1,mb:1,color:"red"}}><b>Not able to re-generate your document as the communication email invalid. Please update it from company details panel</b></Typography> : ''}
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                <Typography level="body-lg">For any query call us at 1-888-548-2008</Typography>
            </Grid>
            </>
            );
    }
return(
    <div>
        <DefaultLayout content={<ContentLayout />} />   
        <div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={freez}
                            onClick={handleFreezStop}
                        >
                            <Freez />
                        </Backdrop>
                    </div>      
    </div>
);
}


export default CreditExpaired;