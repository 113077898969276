import * as React from 'react';
import DefaultLayout from '../DefaultLayout';
import { Typography, Card, CardContent, CardOverflow, } from '@mui/joy';
import { Button, Grid, RadioGroup, FormControlLabel, FormControl, FormLabel, Radio, Stack, TextField,Checkbox,FormGroup} from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
function ContactAdd(props) {
  
    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }} >
                        <CardOverflow sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                            padding: '1%',
                        }}>
                            <Typography level="h2" color='primary'>Select Data</Typography>
                        </CardOverflow>
                        <CardContent sx={{padding:"2%"}}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-row-radio-buttons-group-label"><Typography level="h6">Configuration For</Typography></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="female" control={<Radio />} label="Affiliate" />
                                        <FormControlLabel value="male" control={<Radio />} label="Member" />
                                        <FormControlLabel value="other" control={<Radio />} label="Both" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <FormControl fullWidth size="small">
                                    <TextareaAutosize
                                        style={{ width: "50%" }}
                                        required
                                        margin="dense"
                                        aria-label="minimum height"
                                        minRows={6}
                                        placeholder="Contact Reason*"
                                        name="followupNotes"
                                    />
                                </FormControl>
                            </Grid>
                           
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <FormControl>
                                        
                                        <TextField 
                                            id="outlined-basic" 
                                            label="Disposition"
                                            variant="outlined" 
                                            margin='dense'/>
                                        </FormControl>
                                        <DeleteOutline 
                                            fontSize="large" 
                                        />
                                        <AddCircleOutlineIcon 
                                            fontSize="large"    
                                        />
                                    
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <FormControl>
                                        
                                        <TextField 
                                            id="outlined-basic" 
                                            label="Outcome Reason"
                                            variant="outlined" 
                                            margin='dense'/>
                                         </FormControl>
                                        <DeleteOutline 
                                            fontSize="large" 
                                        />
                                        <AddCircleOutlineIcon 
                                            fontSize="large"    
                                        />
                                   
                                </Grid>
                            </Grid>
                               
                           
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Savable (only in case of retention)" />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Required :</FormLabel> 
                                        <Stack direction="row">
                                        <FormGroup>
                                            
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="AID" />
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="MID" />
                                        </FormGroup>
                                        </Stack>
                                    </FormControl> 
                                </Grid>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"center",width:"100%"}}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" sx={{minWidth:250}}>
                                    CLOSE
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" sx={{minWidth:250}} color="success" type="submit" >
                                    SAVE
                                </Button>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default ContactAdd;