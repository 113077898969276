import React from 'react';

const Loader = () => {
  return (
    <div className="load">
      <div >
        <img src={require("../../assets/images/loader.gif")} className='loaderimg'/>
      </div>
    </div>
  );
};

export default Loader;