import React, { useMemo, useState, useEffect } from 'react';
import AuthUser from "../Auth/AuthUser";
import { Box, Tooltip,  Typography, Alert, Stack,  Grid, Button,Dialog, } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useNavigate, Link } from 'react-router-dom';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import formatPhoneNumber from '../Formats/PhoneNumberValidation';
import Moment from 'moment';
import { Card,CardOverflow,CardContent } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import config from '../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BounceEmailDailog from '../BounceEmail/BounceEmailDailog';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../redux/apiSlice';
const RequestFormInitialValues = {
    email : '',
    OTP : '',
};

function Pending(props) {
    const { http} = AuthUser();
    const { userid, user } = AuthUser();
    const [file, setFile] = useState();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const companyId = queryParams.get('companyId');
    const rowsSeleted = Object.keys(rowSelection);
    const [openBounceEmailEdit, setOpenBounceEmailEdit] = React.useState(false);
    // const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const isMobile = useMediaQuery('(max-width:600px)');
    const [scrollX, setScrollX] = useState(0);
    const [isOTPFieldVisible, setisOTPFieldVisible] = useState(false);

   
    const tablePreferencesArray = {

    }

    const setErrorMessageFun = (successMessage , errorMessage) => {

        console.log("message: ", successMessage,errorMessage);
        setErrorMessage(errorMessage)
        setSuccessMessage(successMessage)
        setTimeout(function () {
            setSuccessMessage('')
            setErrorMessage('')
        }, 5000);
                    
      } 

    const fetchBounceEmails = async(page) => {
        const apiUrl = '/getBounceEmails/pending';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setData(res.data)
            setIsLoading(false)
        }else{
            setErrorMessage(" went wrong.")
            navigate('/login');
        };
    }


    const handleClickOpen1 = (company_name , company_master_id , email , communication_email) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
          navigate('/login');
          return false;
        }
        setisOTPFieldVisible(false)
        RequestFormInitialValues.company_master_id  = company_master_id ;
        RequestFormInitialValues.company_name  = company_name ;
        RequestFormInitialValues.email = email;
        RequestFormInitialValues.communication_email = communication_email;
        RequestFormInitialValues.OTP = "";
        setOpenBounceEmailEdit(true);
    };

    const handleClose1 = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
        setOpenBounceEmailEdit(false);
    };

    useEffect(() => {
        fetchBounceEmails();
      }, []);

    
    const mobileDialogModalStyles = {
        position: 'absolute',
        left: `${scrollX}px`,
    };

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);
    ////////////////////////

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            http.post('/saveTablePreferences', {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'bounceEmails'
            }).then((result) => {
            })
        }
    }, [columnVisibility])
    const tablePreferences = () => {
        http.get('/fetchTablePreferences/bounceemail')
            .then((res) => {
                if (Object.values(res.data).length == 0) {
                    setColumnVisibility(tablePreferencesArray);
                } else {
                    setColumnVisibility((JSON.parse(res.data.preferences)));
                }
            });
    }
    
    const customSortFun = (rowA, rowB) => {
        const phoneNoA = rowA.original.reference_affiliates.phone_no;
        const phoneNoB = rowB.original.reference_affiliates.phone_no;
        return phoneNoA - phoneNoB;
    }
     const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (data) => `${data.company ? data.company.company_name ? data.company.company_name : '' : ''}`,
                id: 'Company Name',
                header: 'Company Name',
                Cell: ({ renderedCellValue, row }) => (<>{row.original.company ? row.original.company.company_name ? row.original.company.company_name : 'N/A' : 'N/A'}</>),
            },
            {

                accessorFn: (data) => `${data.reference_affiliates ? data.reference_affiliates.first_name : ''}`,
                id: 'First Name',
                header: 'First Name',
                
            },
            {

                accessorFn: (data) => `${data.reference_affiliates? data.reference_affiliates.last_name : ''}`,
                id: 'Last Name',
                header: 'Last Name',
                
            },
            {

                accessorFn: (data) => `${data.email ? data.email : ''}`,
                id: 'Email',
                header: 'Email',
                Cell: ({ renderedCellValue, row }) =>
                <Box className="datatable">
                    <>{row.original.email ? row.original.email : 'N/A'}</>
                </Box>,
            },
            {

                accessorFn: (data) => `${data.phone_no ? formatPhoneNumber(data.phone_no) : data.reference_affiliates ? data.reference_affiliates.phone_no ?  formatPhoneNumber(data.reference_affiliates.phone_no) : '' : '' }`,
                id: 'Phone No',
                header: 'Phone No',
                filterVariant: 'range',
                sortingFn: customSortFun,
                Cell: ({ renderedCellValue, row }) =>(<>{row.original.phone_no ? formatPhoneNumber(row.original.phone_no) : row.original.reference_affiliates ? row.original.reference_affiliates.phone_no ? formatPhoneNumber(row.original.reference_affiliates.phone_no)  : 'N/A' : 'N/A'  }</>),
            },
            {
                accessorFn: (data) => `${data.source ? data.source : ''}`,
                id: 'Bounced From',
                header: 'Bounced From',
                Cell: ({ renderedCellValue, row }) => (<div className='datatable'>{row.original.source ? row.original.source : 'N/A'}</div>),
            },
            {
                accessorFn: (data) => `${data.status ? data.status : 'N/A'}`,
                id: 'Status',
                header: 'Status',
            },
            {
                accessorFn: (data) => `${data.created_at ?Moment(data.created_at).format('MM-DD-YYYY'): ''}`,
                id: 'Bounced On',
                header: 'Bounced On',
                Cell: ({ renderedCellValue, row }) => (<>{(row.original.created_at) ? Moment(row.original.created_at).format('MM-DD-YYYY') : 'N/A'}</>),
            },
            {
                accessorFn: (data) => `${(data.bounce_email_activity) ? (data.bounce_email_activity.notes) ? data.bounce_email_activity.notes : '' : ''}`,
                id: 'Last Activity Notes',
                header: 'Last Activity Notes',
                size: 50,
                Cell: ({ renderedCellValue, row }) => 
                <Box className="datatable">
                    <>{(row.original.bounce_email_activity) ? row.original.bounce_email_activity.notes ?  row.original.bounce_email_activity.notes : 'N/A' : 'N/A'}</>
                </Box>,
            },
            {
                accessorFn: (data) => `${(data.bounce_email_activity) ? (data.bounce_email_activity.created_at) ?Moment(data.bounce_email_activity.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm')+'Hrs' : 'N/A' : 'N/A'}`,
                id: 'Last Activity On',
                header: 'Last Activity On',
                Cell: ({ renderedCellValue, row }) => (<>{(row.original.bounce_email_activity) ? row.original.bounce_email_activity.created_at ?  Moment(row.original.bounce_email_activity.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm')+'Hrs' : 'N/A' : 'N/A'}</>),
            },
            {
                accessorFn: (data) => {
                return data.company_master_id != null ? (
                  <>
                  
                  <Stack direction="row">
						
                  
                    <Button
                      onClick={() => {
                        handleClickOpen1(
                          data.company.company_name,
                          data.company_master_id,
                          data.email,
                          data.reference_affiliates
                            ? data.reference_affiliates.communication_email
                              ? data.reference_affiliates.communication_email
                              : ""
                            : ""
                        );
                      }}
                    >
                      <Tooltip title="Edit" arrow>
                        <EditIcon/>
                      </Tooltip>
                    </Button>
                    <>
                    <Tooltip title="Bounced Email Activity Log" arrow><Link className='btn btn-sm btn-info text-white' to={{ pathname: "/bounceemailactivitylog/" + data.company_master_id }} ><img src={require('../../assets/images/details.png')} /></Link></Tooltip>
                    </>
                    </Stack>
                  </>
                ) : "";
              },
              id: 'actions',
              header: 'Actions',
              enableSorting: false,
            },
        ]
    );
  
    //csv option start here
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Pending Bounced Emails',
        headers: ['Company Name','First Name','Last Name','Email', 'Phone No','Bounced From', 'Status', 'Bounced On', 'Last Activity Notes', 'Last activity On'],
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportData = (rows) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        const exportedData = rows.map((row) => {

            return {
                'Company Name':row.original.company ? row.original.company.company_name ? row.original.company.company_name : 'N/A' : 'N/A',
                'First Name':row.original.reference_affiliates ? row.original.reference_affiliates.first_name : 'N/A',
                'Last Name':row.original.reference_affiliates ? row.original.reference_affiliates.last_name : 'N/A',
                'Email': row.original.email || 'N/A',
                'Phone No':row.original.phone_no ? formatPhoneNumber(row.original.phone_no) : row.original.reference_affiliates ? row.original.reference_affiliates.phone_no ? formatPhoneNumber(row.original.reference_affiliates.phone_no)  : 'N/A' : 'N/A' ,
                'Bounced From':row.original.source || 'N/A',
                'Status': row.original.status || 'N/A',
                'Bounced On':(row.original.created_at) ? Moment(row.original.created_at).format('MM-DD-YYYY') : 'N/A',
                'Last Activity Notes': `${row.original.bounce_email_activity ? row.original.bounce_email_activity.notes ? row.original.bounce_email_activity.notes : 'N/A' : 'N/A'}`,
                'Last activity On': `${(row.original.bounce_email_activity) ? row.original.bounce_email_activity.created_at ?  Moment(row.original.bounce_email_activity.created_at).format('MM-DD-YYYY') : 'N/A' : 'N/A'}`,
           };
        });

        // Convert data to CSV format
    const csvContent = convertToCSV(exportedData);

    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted

        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }

        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };

    //csv end here
    return (
		
<>
               
                {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
             
                <Box mt={2}>
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        
                        filterFns={{
                        customSearchFilterFn: (row, id, filterValue) => {
                          const searchValues = filterValue.toLowerCase().split(' ');
                          const firstName = row.original.reference_affiliates ?row.original.reference_affiliates.first_name? row.original.reference_affiliates.first_name.toLowerCase() : '': '';
                          const lastName = row.original.reference_affiliates ?row.original.reference_affiliates.last_name? row.original.reference_affiliates.last_name.toLowerCase() : '': '';
                          const nameMatches = searchValues.every(value =>
                            firstName.includes(value) || lastName.includes(value)
                          );
                          return nameMatches || row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, ''));;
                         }
                        }}
                        globalFilterFn="customSearchFilterFn"//custom search function
                        enableColumnFilterModes
                        enableRowSelection
                        getRowId={(data) => data.id}
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
                        initialState={{ showColumnFilters: false, density: 'compact' }}
                        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                        enableColumnFilters={config.DatatableColumnFilter}
                        positionToolbarAlertBanner="bottom"
                        enableDensityToggle={false}
                        muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                                backgroundColor: "#F6FAFF",
                            },
                        }}
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                <Button
                                    color="primary"
                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                    //export all rows, including from the next page, (still respects filtering and sorting)
                                        onClick={() =>
                                            handleExportData(table.getPrePaginationRowModel().rows)
                                        }
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    CSV
                                </Button>

                            </Box>
                        )}

                    />
                    
                     <Dialog
                      onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleClose1();
                        }
                      }}
                            // fullWidth={fullWidth}
                            maxWidth='md'
                            fullScreen={fullScreen}
                            open={openBounceEmailEdit}
                            aria-labelledby="responsive-dialog-title"
                            // style={isMobile ? mobileDialogModalStyles : null}
                        >
                            <Card>
                                <CardOverflow
                                    sx={{
                                        bgcolor:'#F6FAFF',
                                        borderColor:'#F6FAFF',
                                        padding: '1%',
                                    }}>
                                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Typography gutterBottom color="primary" variant='h5'><b>Update communication email</b></Typography> 
                                        </Grid>
                                </CardOverflow>
                            <CardContent>
                               
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={4} md={4} sm={6} xs={6}>
                                        <Typography  variant="h6" gutterBottom>Company Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={6} xs={6}>
                                        <Typography  variant="h6" gutterBottom><b>{RequestFormInitialValues.company_name }</b></Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Grid item lg={4} md={4} sm={6} xs={6}>
                                    <Typography  variant="h6" gutterBottom>Registered Email:
                                    </Typography>
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={6} xs={6}>
                                          <Typography  variant="h6" gutterBottom><b>{RequestFormInitialValues.email}</b></Typography>
                                    </Grid>
                                </Grid>
                                <BounceEmailDailog  id={RequestFormInitialValues.company_master_id } email={RequestFormInitialValues.email} communication_email = {RequestFormInitialValues.communication_email} onValueChange={setErrorMessageFun} closeModal = {handleClose1} fetchData = {fetchBounceEmails} />
                            </CardContent>
                            </Card>
                    </Dialog>
                </Box>
            </>
        
    
	);

}


export default Pending;