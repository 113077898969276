import React, { useState, useEffect, } from "react";
import {Select, FormControl, InputAdornment, FormHelperText, OutlinedInput, MenuItem, IconButton, Tooltip, Grid, TextField, InputLabel, useMediaQuery} from '@mui/material';
import { Typography, Button } from '@mui/joy';
import Freez from '../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
//functional import
import AuthUser from "../../Components/Auth/AuthUser";
import swal from "sweetalert";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import SecureWebsite from "../../Components/Enrollment/SecureWebsite";
import InputMask from 'react-input-mask';
import { useTheme } from '@mui/material/styles';
import TermsAndConditions from '../../views/FooterPages/TermsAndConditions';
import CancelPolicy from '../../views/FooterPages/CancelPolicy';
import PrivacyPolicy from '../../views/FooterPages/PrivacyPolicy';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useNavigate } from "react-router-dom";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import { FCMServerKey, FCMSendUrl } from "../../firbase/firstore";
import CheckIcon from '@mui/icons-material/Check';
import config from "../../AppConfig";
import { useDispatch } from 'react-redux';
import { fetchData } from '../../redux/apiSlice';

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};


function NewEnrollment4({ onValueChange, props, customColorArray ,textColor,btnTextColor}) {
    const dispatch = useDispatch();
    const { http, getToken } = AuthUser();
    const [states, setStates] = useState([]);
    const [billingAddCheck, setBillingAddCheck] = useState(false);
    const [tc, settc] = useState(false);
    const [tcflag, settcflag] = useState(false);
    const [zipflag, setzipflag] = useState(false);
    const [zipError, setZipError] = useState(false);
    const [errorfromcd, seterrorfromcd] = useState();
    const [selectedYear, setSelectedYear] = useState('');
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const navigate = useNavigate();
    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const handleTooltipClose4 = () => {
        setOpen4(false);
    };

    const handleTooltipOpen4 = () => {
        setOpen4(true);
    };
    const handleTooltipClose5 = () => {
        setOpen5(false);
    };

    const handleTooltipOpen5 = () => {
        setOpen5(true);
    };
    const toggleDrawer1 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen1(open);
    };
    const toggleDrawer2 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen2(open);
    };
    const toggleDrawer3 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen3(open);
    };


    const [amexValidation, setAmexValidation] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [cardInfo, setCardInfo] = useState('A credit or debit card is required to: verify your identity so we can provide you your credit scores and reports. By submitting your secure order you will be eligible to receive your credit scores and begin your membership in MyFreeScoreNow credit monitoring. You agree that your credit/debit card will automatically be charged at the time of your initial order and then on a monthly basis unless and until you call 1-888-548-2008 to cancel your membership. You may cancel at anytime; however, payments are non-refundable and partial refunds are not available.');
    const [offerDetailsString, setOfferDetailsString] = useState('');

    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    const fetchState = () => {
        handleFreezStart();
        http.get("/fetchStates").then((res) => {
            handleFreezStop();
            setStates(res.data.data);
        });
    }
    useEffect(() => {
        fetchState();
    }, [])
    const [aid, setAid] = useState(localStorage.getItem('aid'));
    const [pid, setPid] = useState(localStorage.getItem('pid'));
    //chagers and subscription values functions and variable starts
    const [offerdetails, setOfferDetails] = useState();
 const getOfferDetails = () => {
        handleFreezStart();
        http.post("/getofferdetailsofpid", {
            pid: pid,
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    setOfferDetails(res.data.data[0])
                    if (res.data.data[0]) {
                        let text = '';
                        if (res.data.data[0].days == 0) {
                            text = 'By submitting your secure order you will be charged ' + res.data.data[0].price + ' immediately for your membership in MyFreeScoreNow credit monitoring. You agree that your credit/debit card will automatically be charged ' + res.data.data[0].price + ' on a monthly basis unless and until you call 1-888-548-2008 to cancel your membership. Payments are non-refundable.';
                            setOfferDetailsString(text);
                        } else {
                            if (res.data.data[0].hasFee == 'None') {
                                text = 'By submitting your secure order you will be eligible to receive your free credit scores, and begin your ' + res.data.data[0].days + '-day trial membership in MyFreeScoreNow credit monitoring. At the end of the ' + res.data.data[0].days + '-day trial period, you agree that your credit/debit card will automatically be charged ' + res.data.data[0].price + ' on a monthly basis unless and until you call 1-888-548-2008 to cancel your membership. Payments are non-refundable.';
                            } else {
                                text = 'By submitting your secure order you will be immediately charged a $1.00 non-refundable fee for member activation, be eligible to receive your free credit scores, and begin your ' + res.data.data[0].days + '-day trial membership in MyFreeScoreNow credit monitoring. At the end of the ' + res.data.data[0].days + '-day trial period, you agree that your credit/debit card will automatically be charged ' + res.data.data[0].price + ' on a monthly basis unless and until you call 1-888-548-2008 to cancel your membership. Payments are non-refundable.';
                            }
                            setOfferDetailsString(text);
                            const cardInfoText = 'A credit or debit card is required to: verify your identity so we can provide you your credit score, for your trial membership and for a non-refundable membership activation fee. By submitting your secure order you will be eligble to recieve your credit score and begin your free trial membership in MyFreeScoreNow credit monitoring. At the end of the free trial period, you agree that your credit/debit card will automatically be charged on a monthly basis unless and until you call 1-888-548-2008 to cancel your membership. You may cancel at any time; however partial refunds are not available.';
                            setCardInfo(cardInfoText);
                        }

                    }
                } else {
                    seterrorfromcd("Something went wrong please try after some time")
                }
            })
            .catch((error) => {
                handleFreezStop();
                seterrorfromcd("Network Error")
            });
    }
    useEffect(() => {
        getOfferDetails();
    }, []);
    //enrollment step 2 function start
    const MainErollStep4 = (values) => {
        setButtonDisabled(true);
        handleFreezStart();
        http.post("/registerEnrollment", {
            step: 4,
            trackingToken: localStorage.getItem('trackingToken'),
            customerToken: localStorage.getItem('customerToken'),
            cardNumber: values.cardNumber,
            cardName: localStorage.getItem('Main_name'),
            cvv: values.cvc,
            cardMonth: values.expirationMonth,
            cardYear: values.expirationYear,
            isConfirmedTerms: tc,
            billingAddrChk: !billingAddCheck ? 1 : 0,
            billAddress: values.StreetAddress,
            billZip: values.ZipCode,
            billCity: values.City,
            billState: values.State
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    localStorage.setItem('tempPassword', res.data.data.tempPassword);
                    onValueChange(res.data.data.nextstep);
                    setButtonDisabled(false);
                } else {
                    if (res.data.data.failedAttempt) {
                        const memberData = res.data.data.memberData;
                        const fcmTokens = res.data.data.fcmTokens;

                        /** Send desktop notification */
                        var key = `${FCMServerKey}`;
                        var notification = {
                            'title': 'Abandoned Registrant Process',
                            'body': 'Abandoned At: Payment Details \nAID: ' + localStorage.getItem('aid') + ' \nName: ' + `${memberData.first_name ? memberData.first_name : 'N/A'}` + ' ' + `${memberData.last_name ? memberData.last_name : 'N/A'}` + ' \nEmail: ' + `${memberData.email ? memberData.email : 'N/A'}` + ' \nPhone Number: ' + `${memberData.phone_number ? memberData.phone_number : 'N/A'}` + '\n',
                            'icon': 'firebase-logo.png',
                            "click_action": `${config.BASE_URL}` + "AbandonedMembers",
                            "webpush": {
                                "notification": {
                                    "actions": [
                                        {
                                            "action": 'explore', // Action identifier
                                            "title": 'Explore', // Button text
                                            "icon": 'firebase-logo.png', // URL to the icon for the button
                                        },
                                    ],
                                },
                            },
                        };
                        const fetchFCM = fetch(FCMSendUrl, {
                            method: 'POST',
                            headers: {
                                'Authorization': 'key=' + key,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                'notification': notification,
                                'registration_ids': fcmTokens,
                            })
                        }).then((response) => {
                            console.log(response);
                        }).catch((error) => {
                            console.error(error);
                        });
                    }
                    seterrorfromcd(res.data.data.errorByCD[0].message.replace(/(<([^>]+)>)/ig, ''));
                    onValueChange(props);
                    setButtonDisabled(false);
                }
            })
            .catch((error) => {
                handleFreezStop();
                setButtonDisabled(false);

                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }

    const months = [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
    ];
    const getMonths = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const startMonth = selectedYear > currentYear ? '01' : currentMonth;
        const startMonthIndex = months.indexOf(startMonth);
        const filteredMonths = months.slice(startMonthIndex);

        return months.map((month, index) => {
            return (
                <MenuItem key={index} value={month}>
                    {month}
                </MenuItem>
            );
        });
    };
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, index) => currentYear + index);
    //Month Picker functions and variables end
    //validation schema starts

    const isExpirationDatePassed = (expirationYear, expirationMonth) => {
        return expirationYear < currentYear || (expirationYear === currentYear && expirationMonth < currentMonth);
    };

    const validationSchema = Yup.object().shape({
        cardNumber: Yup.string()
            .matches(/^[0-9]{15,16}$/, 'Invalid Card Number')
            .required('Card Number is required'),
        expirationMonth: Yup.string()
            .required('Expiration Month is required')
            .test('expirationMonth', 'Expiration month has passed', function(value) {
                const { expirationYear } = this.parent;
                if (!expirationYear) {
                    return true;
                }
                if (!value) {
                    return false;
                }
                return !isExpirationDatePassed(parseInt(expirationYear), parseInt(value));
            }),
        expirationYear: Yup.string().required('Expiration Year is required'),
        cvc: Yup.string()
            .when("cardNumber", {
                is: (cardNumber) => cardNumber && cardNumber.length == 15,
                then: () => Yup.string().matches(/^[0-9]{4}$/, 'Invalid CVV').required('CVV is required'),
                otherwise: () => Yup.string().matches(/^[0-9]{3}$/, 'Invalid CVV').required('CVV is required'),
            }),

        ...(billingAddCheck ? {
            StreetAddress: Yup.string()
                .matches(/^(?=[-0-9A-Za-z.#'"/\s]*$)/, 'Please enter a valid Street Address')
                .min(1, 'Too Short!')
                .required('Street address is required'),
            ZipCode: Yup.string()
                .min(5, 'ZipCode must be of 5 digits!')
                .max(5, 'ZipCode must be of maximum 5 digits!')
                .required('Zipcode is required'),
            City: Yup.string()
                .required('City is required'),
            State: Yup.string()
                .required('Please select state'),
        } : {}),
        tc: Yup.boolean()
        .oneOf([true], 'You need to accept terms & conditions to proceed further'),
    });
    //validation schema ends
    const handleCallback = (data) => {
        seterrorfromcd(data);
    }
    const handleFreezCallback = (data) => {
        setFreez(data);
    }

    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;
        const firstchar = value.substring(0, 1);
        // keep minus if entered by user
        if (firstchar == '3') {
            setAmexValidation(true);
        } else {
            setAmexValidation(false);
        }
        return {
            value,
            selection
        };
    }

    const fetchLocationInfo = async (zipcode, setFieldValue) => {
        const apiUrl = "/zipcode/" + zipcode;
        setFieldValue('City', '');
        setFieldValue('State', '');
        if (zipcode.length == 5) {
            setFieldValue('City', '...');
            setFieldValue('State', '...');
            handleFreezStart();
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if (res.success) {
                setFieldValue('City', res.data.places[0]['place name']);
                setFieldValue('State', res.data.places[0]['state abbreviation']);
                handleFreezStop();
            }
            else {
                setFieldValue('City', '');
                setFieldValue('State', '');
                handleFreezStop();
            }
        }
    };
    return (
        <>
            <Formik
                initialValues={{
                    cardNumber: '',
                    expirationMonth: '',
                    expirationYear: '',
                    cvc: '',
                    State: '',
                    ZipCode: '',
                    City: '',
                    StreetAddress: '',
                    tc: false
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    // Handle form submission logic here

                    setSubmitting(false);
                    MainErollStep4(values)
                }}

            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                <span style={{color:`${textColor}`}}><IconButton><img alt="#" src={require("../../assets/images/lock-icon.png")} style={{ width: "80%" }} /></IconButton>This Site is Secure</span>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                <FormControl fullWidth variant="outlined">
                                    <InputMask
                                        beforeMaskedValueChange={beforeMaskedValueChange}
                                        mask={amexValidation ? '999999999999999' : '9999999999999999'}
                                        maskChar={null}
                                        id="cardNumber"
                                        name="cardNumber"
                                        label="Card Number"
                                        size="small"
                                        margin="dense"
                                        autoComplete="cardNumber"
                                        value={values.cardNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.cardNumber && errors.cardNumber}
                                        sx={{
                                            label: { color: `${customColorArray.primaryColor} !important` },
                                            "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}`, background: 'white', zIndex: '-1' }, // Styles the fieldset
                                            " & .MuiFormHelperText-root": {
                                                backgroundColor: '#ffffffad',paddingLeft:'3px'
                                                }, "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                    borderColor: `${customColorArray?.primaryColor} !important`,
                                                }
                                        }}
                                    >
                                        {(inputProps) =>
                                            <TextField
                                                label="Card Number" variant="outlined"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <>
                                                            <IconButton edge="end">
                                                                {touched.cardNumber && !errors.cardNumber ? (
                                                                    <CheckIcon style={{ color: 'green' }} />
                                                                ) : (
                                                                    touched.cardNumber && errors.cardNumber ? (
                                                                        <CloseIcon style={{ color: '#d32f2f' }} />
                                                                    ) : null
                                                                )}
                                                            </IconButton>
                                                            <ClickAwayListener onClickAway={handleTooltipClose5}>
                                                                <Tooltip onClose={handleTooltipClose5}
                                                                    open={open5}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    placement="top"
                                                                    title={<> {cardInfo}
                                                                        <IconButton
                                                                            aria-label="close"
                                                                            size="small"
                                                                            onClick={handleTooltipClose5}
                                                                            style={{ position: 'absolute', top: 0, right: 0 }}
                                                                        >
                                                                            <CloseIcon fontSize="inherit" sx={{ color: "white" }} />
                                                                        </IconButton></>}>
                                                                    <IconButton edge="end" sx={{ cursor: "pointer" }}
                                                                        onClick={handleTooltipOpen5}>
                                                                        <img alt="#" src={require("../../assets/images/i_icon.png")} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </ClickAwayListener>
                                                        </>
                                                    </InputAdornment>,
                                                }}
                                                {...inputProps}
                                            />
                                        }
                                    </InputMask>
                                    <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                                {touched.cardNumber && errors.cardNumber}
                                            </FormHelperText>
                                  
                                </FormControl>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                <FormControl fullWidth variant="outlined">

                                    <InputMask
                                        mask={amexValidation ? '9999' : '999'}
                                        maskChar={null}
                                        id="cvc"
                                        name="cvc"
                                        label="CVV"
                                        size="small"
                                        autoComplete="cvc"
                                        margin="dense"
                                        value={values.cvc}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.cvc && errors.cvc}
                                        sx={{
                                            label: { color: `${customColorArray.primaryColor} !important` },
                                            "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}`, background: 'white', zIndex: '-1' }, // Styles the fieldset
                                            " & .MuiFormHelperText-root": {
                                                backgroundColor: '#ffffffad',paddingLeft:'3px'
                                                }, "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                    borderColor: `${customColorArray?.primaryColor} !important`,
                                                }
                                        }}
                                        
                                    >
                                        {(inputProps) =>
                                            <TextField
                                                label="CVV" variant="outlined"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <>
                                                            <IconButton edge="end">
                                                                {touched.cvc && !errors.cvc ? (
                                                                    <CheckIcon style={{ color: 'green' }} />
                                                                ) : (
                                                                    touched.cvc && errors.cvc ? (
                                                                        <CloseIcon style={{ color: '#d32f2f' }} />
                                                                    ) : null
                                                                )}
                                                            </IconButton>
                                                            <ClickAwayListener onClickAway={handleTooltipClose4}>
                                                                <Tooltip
                                                                    onClose={handleTooltipClose4}
                                                                    open={open4}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    placement="top"
                                                                    title=
                                                                    {<>On Visa, MasterCard and Discover the security code is the three digits to the right of the credit card number in the signature area on the back of the card. On American Express, the security code is four digits printed (not embossed) on the right front of the card above the credit card number.
                                                                        <IconButton
                                                                            aria-label="close"
                                                                            size="small"
                                                                            onClick={handleTooltipClose4}
                                                                            style={{ position: 'absolute', top: 0, right: 0 }}
                                                                        >
                                                                            <CloseIcon fontSize="inherit" sx={{ color: "white" }} />
                                                                        </IconButton>
                                                                    </>}
                                                                >
                                                                    <IconButton
                                                                        edge="end"
                                                                        sx={{ cursor: "pointer" }}
                                                                        onClick={handleTooltipOpen4}
                                                                    >
                                                                        <img alt="#" src={require("../../assets/images/i_icon.png")} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </ClickAwayListener>
                                                        </>
                                                    </InputAdornment>,
                                                }}
                                                {...inputProps}
                                            />
                                        }
                                    </InputMask>
                                    <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                                {touched.cvc && errors.cvc}
                                            </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} >
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <FormControl margin="dense" variant="outlined" fullWidth   error={touched.expirationMonth && errors.expirationMonth ? errors.expirationMonth : null}>
                                    <InputLabel id="demo-multiple-name-label" sx={{color:`${customColorArray.primaryColor} !important`}}>Expiration Month</InputLabel>
                                    <Select
                                            id="expirationMonth"
                                            name="expirationMonth"
                                            label="Expiration Month"
                                            autoComplete="expirationMonth"
                                            input={<OutlinedInput label="Expiration Month" />}
                                            value={values.expirationMonth}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className='cobrandTextField'
                                            sx={{
                                                label: { color: `${customColorArray.primaryColor} !important` },
                                                "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                                "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                    borderColor: `${customColorArray?.primaryColor} !important`,
                                                }
                                            }}
                                        >
                                            {getMonths()}
                                        </Select>
                                        <FormHelperText style={{ color: '#d32f2f',backgroundColor: '#ffffffad',paddingLeft:'3px' }}>
                                    {touched.expirationMonth && errors.expirationMonth}
                                </FormHelperText>
                                    </FormControl>
                                  
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <FormControl fullWidth variant="outlined" margin="dense" error={touched.expirationYear && errors.expirationYear ? errors.expirationYear : null}>
                                
                                        <InputLabel id="demo-multiple-name-label" sx={{color:`${customColorArray.primaryColor} !important`}}>Expiration Year</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="expirationYear"
                                            name="expirationYear"
                                            autoComplete="expirationYear"
                                            value={values.expirationYear}
                                            input={<OutlinedInput label="Expiration Year" />}
                                            MenuProps={MenuProps}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setSelectedYear(e.target.value)
                                            }}
                                            onBlur={handleBlur('expirationYear')}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                                "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                    borderColor: `${customColorArray?.primaryColor} !important`,
                                                }
                                            }}
                                            className='cobrandTextField'
                                        >

                                            {years ? years.map((year,index) => (
                                                <MenuItem
                                                    value={year}
                                                    key={index}
                                                >
                                                    {year}
                                                </MenuItem>
                                            )) : ""}
                                        </Select>
                                        {touched.expirationYear && errors.expirationYear && (
                                            <FormHelperText sx={{ color: "#d32f2f",backgroundColor: '#ffffffad' ,paddingLeft:'3px'}}>{errors.expirationYear}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>

                                <div className="form-group">
                                    <div className=" pull-right input-group checkbox">
                                        <label htmlFor="isConfirmedTerms" style={{color:`${textColor}`}}>
                                            <input type="checkbox" checked={!billingAddCheck}
                                                onClick={e => {
                                                    setBillingAddCheck(!billingAddCheck);
                                                }} 
                                                style={{
                                                    appearance: 'none', // Hide the default appearance
                                                    width: '12px',
                                                    height: '12px',
                                                    border: `1px solid ${customColorArray.primaryColor}`, // Border color for unchecked state
                                                    borderRadius: '2px',
                                                    backgroundColor: !billingAddCheck ? `${customColorArray.primaryColor}` : 'white', // Background color when checked
                                                    backgroundImage: !billingAddCheck ? 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23fff\'><path d=\'M13.6 1.6L5 10.2 2.4 7.6 1 9l4 4 9-9-1.4-1.4z\'/></svg>")' : 'none', // Checkmark background image when checked
                                                    color: 'white',
                                                    marginRight: '5px', // Adjust spacing
                                                    cursor: 'pointer', // Show pointer cursor on hover
                                                }}
                                                />
                                            &nbsp;My billing address is same as current address
                                        </label>
                                    </div>
                                </div>
                            </Grid>
                            {billingAddCheck ?
                                <>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} >
                                        <FormControl variant="outlined" fullWidth
                                            margin="dense" size="small">
                                            <InputLabel sx={{color:customColorArray.primaryColor}}>Street Address*</InputLabel>
                                            <OutlinedInput
                                                id="StreetAddress"
                                                name="StreetAddress"
                                                label="Street Address*"
                                                autoComplete="StreetAddress"
                                                error={touched.StreetAddress && !!errors.StreetAddress}
                                                value={values.StreetAddress}
                                                onChange={handleChange}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                }}
                                                className='cobrandTextField'
                                                sx={{
                                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray?.primaryColor}` }, // Styles the fieldset
                                                    "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                        borderColor: `${customColorArray?.primaryColor} !important`,
                                                    }
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                        >
                                                            {
                                                                touched.StreetAddress && errors.StreetAddress == undefined ? (
                                                                    null
                                                                ) :
                                                                    touched.StreetAddress && !errors.StreetAddress ? (
                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                    ) : (
                                                                        touched.StreetAddress && errors.StreetAddress ? (
                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                        ) : null
                                                                    )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }

                                            />
                                            <FormHelperText style={{color: "#d32f2f",backgroundColor: 'rgba(255, 255, 255, 0.98)',paddingLeft:'3px'}}>
                                                {touched.StreetAddress && errors.StreetAddress}
                                            </FormHelperText>
                                        </FormControl>

                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} >
                                        <FormControl fullWidth variant="outlined">
                                            <InputMask
                                                mask='99999'
                                                maskChar={null}
                                                id="ZipCode"
                                                name="ZipCode"
                                                label="Zip Code*"
                                                variant="outlined"
                                                size="small"
                                                autoComplete="ZipCode"
                                                fullWidth
                                                margin="dense"
                                                value={values.ZipCode}
                                                error={touched.ZipCode && !!errors.ZipCode}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    fetchLocationInfo(e.target.value, setFieldValue);
                                                }}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                    if (e.target.value.length < 0) {
                                                        setzipflag(true);
                                                        setZipError("Please enter Zip Code!");
                                                    } else if ((e.target.value.length > 0 && e.target.value.length != 5) || (/^\d+$/.test(e.target.value) == false)) {
                                                        setzipflag(true);
                                                        setZipError("ZipCode must be of 5 digit!");
                                                    } else {
                                                        setzipflag(false);
                                                    }
                                                }}

                                                className='cobrandTextField'
                                                sx={{
                                                    label: { color: `${customColorArray?.primaryColor}` },
                                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                                    "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                        borderColor: `${customColorArray?.primaryColor} !important`,
                                                    }
                                                }}
                                            >
                                                {(inputProps) =>
                                                    <TextField
                                                        label="Zip Code*" variant="outlined"
                                                        {...inputProps}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton edge="end">
                                                                        {
                                                                            touched.ZipCode && errors.ZipCode == undefined ? (
                                                                                null
                                                                            ) :
                                                                                touched.ZipCode && !errors.ZipCode ? (
                                                                                    <CheckIcon style={{ color: 'green' }} />
                                                                                ) : (
                                                                                    touched.ZipCode && errors.ZipCode ? (
                                                                                        <CloseIcon style={{ color: 'red' }} />
                                                                                    ) : null
                                                                                )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                }
                                            </InputMask>
                                            <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                                {touched.ZipCode && errors.ZipCode}
                                            </FormHelperText>
                                        </FormControl>

                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl variant="outlined" fullWidth
                                            margin="dense" size="small">
                                            <InputLabel sx={{color:customColorArray.primaryColor}}>City*</InputLabel>
                                            <OutlinedInput
                                                id="City"
                                                name="City"
                                                label="City*"
                                                autoComplete="City"
                                                className='cobrandTextField'
                                                sx={{
                                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                                    "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                        borderColor: `${customColorArray?.primaryColor} !important`,
                                                    }
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                        >
                                                            {
                                                                touched.City && errors.City == undefined ? (
                                                                    null
                                                                ) :
                                                                    touched.City && !errors.City ? (
                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                    ) : (
                                                                        touched.City && errors.City ? (
                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                        ) : null
                                                                    )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                error={touched.City && !!errors.City}
                                                value={values.City}
                                                onChange={(e) => {
                                                    // allow only alphanumeric characters
                                                    const inputValue = e.target.value;
                                                    const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
                                                    if (alphanumericRegex.test(inputValue)) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>
                                                {touched.City && errors.City}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl fullWidth size='small'
                                            margin="dense"
                                        >
                                            <InputLabel id="demo-multiple-name-label" sx={{color:customColorArray.primaryColor}}>State</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                name="State"
                                                id="State"
                                                size='small'
                                                margin="dense"
                                                autoComplete="State"
                                                value={values.State}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                input={<OutlinedInput label="State" />}
                                                MenuProps={MenuProps}
                                                sx={{
                                                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${customColorArray.primaryColor}` }, // Styles the fieldset
                                                    "&:hover .MuiOutlinedInput-notchedOutline": { // Changed selector to include hover state
                                                        borderColor: `${customColorArray?.primaryColor} !important`,
                                                    }
                                                }}
                                                className='cobrandTextField'
                                            >
                                                {states.map((state, id) => {
                                                    return (
                                                        <MenuItem value={state.state_code}>{state.state_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                        {touched.State && errors.State ? <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>Please select State.</FormHelperText> : null}
                                    </Grid>
                                </> : " "}
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                <div className="form-group">
                                    <div className=" pull-right input-group checkbox">
                                        <label htmlFor="isConfirmedTerms" style={{color:`${textColor}`}}>

                                            <Field type="checkbox"
                                                checked={tc}
                                                name="tc"
                                                onClick={() => {
                                                    settc(!tc);
                                                    settcflag(tc);
                                                }}
                                                style={{
                                                    appearance: 'none', // Hide the default appearance
                                                    width: '12px',
                                                    height: '12px',
                                                    border: `1px solid ${customColorArray.primaryColor}`, // Border color for unchecked state
                                                    borderRadius: '2px',
                                                    backgroundColor: tc ? `${customColorArray.primaryColor}` : 'white', // Background color when checked
                                                    backgroundImage: tc ? 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23fff\'><path d=\'M13.6 1.6L5 10.2 2.4 7.6 1 9l4 4 9-9-1.4-1.4z\'/></svg>")' : 'none', // Checkmark background image when checked
                                                    color: 'white',
                                                    marginRight: '5px', // Adjust spacing
                                                    cursor: 'pointer', // Show pointer cursor on hover
                                                }}
                                            />
                                            &nbsp;By checking this box: I acknowledge that I have read, understand, agree to and accept the&nbsp;
                                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                                <Tooltip
                                                    onClose={handleTooltipClose}
                                                    open={open}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    placement="top"
                                                    title={<>
                                                        {offerDetailsString}
                                                        {/* By submitting your secure order you will be immediately charged a $1.00 non-refundable fee for member activation, be eligible to receive your free credit score, and begin your 7-day trial membership in MyFreeScoreNow credit monitoring. At the end of the 7-day trial period, you agree that your credit/debit card will automatically be charged $29.95 on a monthly basis unless and until you call 1-888-548-2008 to cancel your membership. Payments are non-refundable. */}
                                                        <IconButton
                                                            aria-label="close"
                                                            size="small"
                                                            onClick={handleTooltipClose}
                                                            style={{ position: 'absolute', top: 0, right: 0 }}
                                                        >
                                                            <CloseIcon fontSize="inherit" sx={{ color: "white" }} />
                                                        </IconButton>
                                                    </>
                                                    }
                                                >
                                                    <a onClick={handleTooltipOpen} style={{ whiteSpace: 'nowrap', cursor: "pointer",color:customColorArray.hyperlinkColor}} href="#">
                                                        Offer Details </a>
                                                </Tooltip>
                                            </ClickAwayListener>
                                            &nbsp;,&nbsp;
                                            <a href="#" onClick={toggleDrawer1(true)} style={{ cursor: "pointer",color:customColorArray.hyperlinkColor  }}>Terms and Conditions</a>
                                            <SwipeableDrawer
                                                anchor={'bottom'}
                                                open={open1}
                                                onClose={toggleDrawer1(false)}
                                                onOpen={toggleDrawer1(true)}
                                            >
                                                <TermsAndConditions closeVal={toggleDrawer1(false)} />
                                            </SwipeableDrawer>
                                            ,&nbsp;
                                            <a href="#" onClick={toggleDrawer2(true)} style={{ cursor: "pointer",color:customColorArray.hyperlinkColor }}>
                                                Cancellation Policy
                                            </a>
                                            <SwipeableDrawer
                                                anchor={'bottom'}
                                                open={open2}
                                                onClose={toggleDrawer2(false)}
                                                onOpen={toggleDrawer2(true)}
                                            >
                                                <CancelPolicy closeVal={toggleDrawer2(false)} />
                                            </SwipeableDrawer>
                                            , and&nbsp;
                                            <a href="#" onClick={toggleDrawer3(true)} style={{ cursor: "pointer",color:customColorArray.hyperlinkColor }}>
                                                Privacy Policy
                                            </a>
                                            <SwipeableDrawer
                                                anchor={'bottom'}
                                                open={open3}
                                                onClose={toggleDrawer3(false)}
                                                onOpen={toggleDrawer3(true)}
                                            >
                                                <PrivacyPolicy closeVal={toggleDrawer3(false)} />
                                            </SwipeableDrawer>
                                            .
                                        </label>
                                    </div>
                                </div>
                            </Grid>
                            {errors.tc && errors.tc && (
                                <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>{errors.tc}</FormHelperText>
                            )}
                            {/* error code display space start */}
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                {errorfromcd ? <Typography sx={{ fontWeight: "bold", color: "red",backgroundColor: '#ffffffad',paddingLeft:'3px' }}>
                                    Error :- {errorfromcd}
                                </Typography>
                                    : ""}
                            </Grid>
                            {/* error code display space end */}
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "right" }} mt={2}>
                                <Button color="warning" type="submit" size="large" disabled={isButtonDisabled} sx={{
                                    minWidth: 190, minHeight: 60, fontSize: '15px', backgroundColor: `${customColorArray.primaryColor}`, color: btnTextColor, borderRadius: "0%", '&:hover': {
                                        backgroundColor: `${customColorArray.primaryColor}`, borderRadius: "1%"
                                    }
                                }}>Submit My Order<br />Take Me to My Scores</Button>
                            </Grid>
                            <SecureWebsite textColor={textColor} hyperlinkColor={customColorArray.hyperlinkColor}/>
                        </Grid>

                    </Form>
                )}
            </Formik>

            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default NewEnrollment4;