import React, { useState,useEffect } from 'react';
import { useNavigate,useParams } from "react-router-dom";
import { Box, Grid,TextField, Card, CardContent, Button } from '@mui/material';
import LoginHeader from '../../Components/LoginHeader';
import config from "../../AppConfig";
import RegisterFooter from './RegisterFooter';
import { Typography } from '@mui/joy';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { postData } from '../../redux/apiSlice';
import Loader from '../../Components/Loader/Loader';

let isLowercaseValid=false;
let isUppercaseValid=false;
let isNumberValid=false;
let isSpecialCharValid=false;
let isLengthValid=false;
let showPasswordValidationBlock=false;

function ForgetPassword(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {token} = useParams(); 
    //Error Message Variables
    const [error, setError]=useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [tokenStatus,setTokenStatus]=useState(false);
    const[passwordReseted,setPasswordReseted]=useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // Check token for password change request 
    const CheckToken = async()=>{
        // setSuccessMessage('pasword change successfully...');
        setIsLoading(true)
        const apiUrl = '/checkPasswordToken';
        const args = {
            token:token
        }
        
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            setSuccessMessage(res.message);
            setTokenStatus(res.success)
            if(res.success === false){
                setIsLoading(false)
                setPasswordReseted(true)
                // navigate('/Error')
            }
            else{
                setIsLoading(false)
                setPasswordReseted(false)
            }
        }
    }
    useEffect(()=>{
        CheckToken();
    },[])

    const SignupSchema = Yup.object().shape({
        password : Yup.string()
        .test(
            () => {
                return (
                    isLowercaseValid &&
                    isUppercaseValid &&
                    isNumberValid &&
                    isSpecialCharValid &&
                    isLengthValid
                );
            }
        )
        .required('Password is required.'),
        confirmpassword : Yup.string()
                .required('Please confirm your password.')
                .oneOf([Yup.ref('password'),null], 'Password must match')
    })

       //password suggestion box
       const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*]/;
        isLowercaseValid=lowercaseRegex.test(newPassword);
        isUppercaseValid=uppercaseRegex.test(newPassword);
        isNumberValid=numberRegex.test(newPassword);
        isSpecialCharValid=specialCharRegex.test(newPassword);
        isLengthValid=newPassword.length >= 8;
      };
  
      //hide show the password suggestion box
      const handlePasswordClick = () => {
          showPasswordValidationBlock=true;
      };
    return (
        <>
        <LoginHeader />
        {isLoading ? <><Loader /></> : ''}
        <Box className="bodycolor" sx={{padding:"2%"}}>
            <Card >
                <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{alignItems:'center',justifyContent:'center'}}>
                        <Grid item lg={6} md={6} sm={6} xs={6} textAlign="center">
                        <img src={require("../../assets/images/changePassword.png")}/>
                        </Grid>
                        {!passwordReseted ?
                      (
                        <Grid item lg={6} md={6} sm={6} xs={6} sx={{padding:"0 5% 0 5%" }} textAlign="center">
                            <Typography level='h2'>Create Password</Typography>
                            <Formik
                                initialValues={{
                                    password: '',
                                    confirmpassword:'',
                                }}
                                validationSchema={SignupSchema}
                                //Define Change Password Fucntion
                                onSubmit={async (values,errors) => {
                                    showPasswordValidationBlock=false;
                                    setSuccessMessage("Sending...");
                                    setError(null);
                                    const apiUrl = '/reset-password';
                                    const args = {
                                        token:token,
                                        url:config.BASE_URL,
                                        password: values.password,
                                        password_confirmation: values.confirmpassword,
                                    }
                                    const res = await dispatch(postData({apiUrl,args})).unwrap();
                                    if(res) {
                                        if (res.success === true) {
                                            setSuccessMessage(res.message.message);
                                            setError(null);
                                            navigate('/login');
                                        } else {
                                            setSuccessMessage(null);
                                            setError(res.message.error);
                                        }
                                    }
                                }}
                                //Close LoginSubmit Function
                            >
                                {({values,errors,touched,setFieldValue,handleChange,handleBlur,isSubmitting,handleSubmit}) =>(
                                    <div>
                                        <Box sx={{mt:5}}>
                                            <TextField
                                                id="outlined-textarea"
                                                label="Password"
                                                placeholder="Enter Password"
                                                // onChange = {handleChange('password')}
                                                onChange={(e)=>{
                                                    handleChange('password')
                                                    setFieldValue('password',e.target.value)
                                                    handlePasswordChange(e);                                                     
                                                  }}
                                                onKeyDown={handlePasswordClick} //hide show the password suggestion box
                                                onBlur={handleBlur('password')}
                                                value={values.password}
                                                fullWidth
                                                helperText={touched.password && errors.password ? errors.password : null}
                                                error={touched.password && errors.password ? errors.password : null}
                                            />
                                            {showPasswordValidationBlock && (
                                                <Box sx={{textAlign:'left'}}>
                                                           <div>
                                                            <span style={{ color: isLengthValid ? 'green' : 'red' }}>Minimum <b>8 characters</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isSpecialCharValid ? 'green' : 'red' }}>At least <b>one special character</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isUppercaseValid ? 'green' : 'red' }}>At least <b>one capital (uppercase)</b> letter</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isNumberValid ? 'green' : 'red' }}>At least <b>one number</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isLowercaseValid ? 'green' : 'red' }}>At least <b>one lowercase</b> letter </span>
                                                        </div>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box sx={{mt:2}}>
                                            <TextField
                                                id="outlined-textarea"
                                                label="Confirm Password"
                                                placeholder="Confirm Password"
                                                onChange = {handleChange('confirmpassword')}
                                                onBlur={handleBlur('confirmpassword')}
                                                value={values.confirmpassword}
                                                fullWidth
                                                helperText={touched.confirmpassword && errors.confirmpassword ? errors.confirmpassword : null}
                                                error={touched.confirmpassword && errors.confirmpassword ? errors.confirmpassword : null}
                                            />
                                        </Box>
                                        <Typography color={'success'}>{successMessage}</Typography>
                                        <Typography color={'danger'}>{error}</Typography>
                                        <Box sx={{mt:5}}>
                                            <Button variant="contained" type="submit" fullWidth onClick={handleSubmit} size="large"  >Submit</Button><br/>
                                        </Box>
                                        <Box sx={{paddingTop:'5%'}}>
                                            <Typography level="h4" color={'success'} >Need Help?</Typography>
                                            <p className="text-center text-black"><strong>We're Available 7 Days A Week Mon-Fri (8am-9pm ET)<br/>
                                            Sat-Sun (9am-7pm ET) 1-888-548-2008<br/>
                                            info@myfreescorenow.com
                                            </strong></p>
                                            
                                        </Box>
                                    </div>
                                )}
                            </Formik>
                        </Grid>):
                          (  
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{padding:"0 5% 0 5%" }} textAlign="center">
                                <Card sx={{border: '1px solid lightgray',textAlign:'center',padding:'30px 0px'}}  >
                                <CardContent>
                                <Typography level="h4"><b>You have already updated your password</b></Typography>

                                <Box pt={4}>
                                <Typography level="h6" color='primary' pb={1}>Click here to login</Typography>
                                <Button variant="contained" color="success"  sx={{ minWidth: 100 }} onClick={()=>navigate('/login')}>Login</Button>
                                </Box>
                                </CardContent>
                                </Card>
                            </Grid>
                        )
                        }
                        
                    </Grid>
                </CardContent>
            </Card>
        </Box>
        <RegisterFooter />
        </>
    );
}

export default ForgetPassword;