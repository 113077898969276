import React, { useMemo, useRef, useState,useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup';
import { Formik  } from 'formik';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Box, Tooltip, Grid, Button, TextField, FormGroup, InputLabel, FormControl, InputAdornment,
    FormControlLabel, FormLabel, RadioGroup, Radio, Checkbox, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,ClickAwayListener } from '@mui/material';
import { Typography } from '@mui/joy';
import AuthUser from '../../../../Components/Auth/AuthUser';
import useMediaQuery from '@mui/material/useMediaQuery';
import swal from "sweetalert";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import Moment from 'moment';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../../redux/apiSlice';
import InputMask from 'react-input-mask';
import tooltipImage from '../../../../assets/images/tooltip.png'; 
import iIcon from '../../../../assets/images/i_icon.png'; 
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CloseIcon from '@mui/icons-material/Close';
const UpdateBankForm = ({open1,handleClose,bankDetails,updatedAttributes,filterhistory,fetchCompanyBankDetails,htmlData,btntext,currentDate,companyName,operation,bankName, routingNumberErr, routingNumberOriginal,routingNumberPreviousValue,handleFreezStart,handleFreezStop,fetchCompanyDetails}) => {
    const dispatch = useDispatch();
    const id = bankDetails?.company_master_id || "";
    const { http, user } = AuthUser();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const inputRef = useRef();
    const inputRef1 = useRef();
    const [statusMessage, setStatusMesage] = useState("");
    const [loader, setLoader] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paymentsNavigation = queryParams.get('payments');
    const page = queryParams.get('page');
    const pageSize = queryParams.get('pageSize');
    const isMobile = useMediaQuery('(max-width:600px)');
    const [bankNameReadonly, setBankNameReadonly] = useState(true);
    const [routingNumberError, setRoutingNumberError] = useState(routingNumberErr);
    const [routingNumberUpdated, setRoutingNumberUpdated] = useState(routingNumberPreviousValue);
    const [defaultBankName, setDefaultBankName] = useState(bankName);
    const [routingNoErr, setRoutingNoErr] = useState("");
    const [defaultRoutingNumber, setDefaultRoutingNumber] = useState(routingNumberOriginal);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [mediumWidth, setMediumWidth] = React.useState('md');
    
    //report issue functions and variable start
    var [reportissue,setReportIssue] = useState();
    var [subject,setSubject] = useState("MFSN Bank details Incorrect (ACH Issue)");
    useEffect(() => {
        setDefaultBankName(bankName);
    }, [bankName])

    useEffect(() => {
        setDefaultRoutingNumber(routingNumberOriginal);
    }, [routingNumberOriginal])

    const handleReportIssueChange = (value) => {
        reportissue = value;
    };
    const handleSubjectChange = (value) => {
        subject = value;
    };
    //report issue functions and variable end
    // tooltip popup start here
    const [openTooltip, setOpenTooltip] = useState(false);

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };
    const [openTooltip2, setOpenTooltip2] = useState(false);

    const handleTooltipOpen2 = () => {
        setOpenTooltip2(true);
    };

    const handleTooltipClose2 = () => {
        setOpenTooltip2(false);
    };
    //modal variable and function start
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    const [firstlable, setFirstLable] = useState(false);
    const [statusAch, setStatusAch] = useState(bankDetails.ach_info_added_to_bank == 1 ? "yes" : "no");
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [isAccount, setIsAccount] = useState(null);
    const [firstNameOnAccount, setFirstNameOnAccount] = useState('');
    const [lastNameOnAccount, setLastNameOnAccount] = useState('');


    useEffect(() => {
        if (bankDetails?.name_on_account) {
            setIsAccount(bankDetails.account_category);
            if (bankDetails.account_category === "personal" && bankDetails.name_on_account.includes(":")) {
                setFirstNameOnAccount(bankDetails.name_on_account.split(":")[0]);
                setLastNameOnAccount(bankDetails.name_on_account.split(":")[1]);
            } else {
                setFirstNameOnAccount("");
                setLastNameOnAccount("");
            }
        }
    }, [bankDetails]);

    const handleClickReport = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser){
            if(loginUser.roles_id  > 2) {
                navigate('/login');
                return false;
            }
        }else{
            navigate('/login');
            return false;
        }
        setOpen(true);
    };

    const handleCloseReport = () => {
        setOpen(false);
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser){
            if(loginUser.roles_id  > 2) {
                navigate('/login');
                return false;
            }
        }else{
            navigate('/login');
            return false;
        }
    };

    const changeBankNameProperty = () => {
        setBankNameReadonly(false);
    }
    
      //report bank details issue start
    const reportBankDetailsIssue = async(id) => 
    {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser){
            if(loginUser.roles_id  > 2) {
                navigate('/login');
                return false;
            }
        }else{
            navigate('/login');
            return false;
        }
        if(reportissue === undefined || reportissue == "") {
            swal({
                title: "Alert",
                text: "Please Add Report Issue Details",
                icon: "error",
                button: "Ok",
            })
            return false;
        }else if(subject == ""){
            swal({
                title: "Alert",
                text: "Please Fill the Content For Subject-line",
                icon: "error",
                button: "Ok",
            })
            return false;
        }
        handleFreezStart();
        const apiUrl = '/reportBankDetailsIssue';
        const args = {
            company_bank_details_master_id: bankDetails.id,
            ticket_reason: reportissue,
            subject: subject,
            mailBody: htmlData
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if (res.success === true) {
                setOpen(false);
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                });
                handleFreezStop();
                handleClose();
                fetchCompanyBankDetails();
                fetchCompanyDetails();
            }else {
                swal({
                    title: "Alert",
                    text: "Something went wrong please try again",
                    icon: "error",
                    button: "Ok",
                });
                handleFreezStop();
            }
        }else{
                swal({
                title: "Alert",
                text: "Network Error",
                icon: "error",
                button: "Ok",
            });
            handleFreezStop();
        }
    }
    //report bank details issue end

    //resolve report bank details issue start
    const resolveBankDetailsIssue = async() => {
    handleFreezStart()
        const apiUrl = '/solvedBankDetailsIssue';
        const args = {
            company_bank_details_master_id: bankDetails.id,
            close_reason: "Details Updated and Issue Resolved"
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
        handleFreezStop()
                if (res.success === true) {
                // alert("success resolve")
            } else {
                // alert("not success resolve")
            }
        }else{
            alert("fail resolve")
        }
    }
    //resolve report bank details issue end

    //Create function start
    const CreateBankDetails = async(values) => {
        if(routingNumberError == false && user.roles_id >= 3) {
            swal({
                title: "Failed",
                text: "Please check entered routing number once and if needed contact support for help",
                icon: "error",
                button: "Ok",
            })
            return false;
        }
        handleFreezStart()
        var check = 1;
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
            check = 0;
        } 
        if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
            if(loginUser.company_master_id != id) {
                check = 0;
            }else{
                check = 1;
            }
        }
        if(check == 0) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            handleFreezStop()
            navigate('/login');
            return false;
        }
        if(checkUsaTldStatus == true){
            handleFreezStop()
            return false;
        }
        var achDate;
        if(values.ACHdate1 != undefined || values.ACHdate1 != null){
            const date = new Date(values.ACHdate1);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            achDate = year+"-"+month+"-"+day;               //formatted ach date
            var date1 = new Date(currentDate);
            var date2 = new Date(achDate);
            var compDate = Moment(achDate).format('MM-DD-YY');
            var date2 = new Date(compDate);
            if(date1 < date2) {
                swal({
                    title: "Alert",
                    text: "ACH date should be less than or equal to current date",
                    icon: "error",
                    button: "Ok",
                })
                handleFreezStop()
                return false;
            }
        }
        if(values.AccountCategory == "personal" && (values.BankName == (values.firstName + ":" + values.lastName))) {
            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            })
            handleFreezStop()
            return false;
        }else if(values.AccountCategory == "business" && (values.BankName == values.NameonAccount)) {
            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            })
            handleFreezStop()
            return false;
        } else if (values.ACHinfoaddedtobank == "yes" && values.ACHdate1 == null) {
            swal({
                title: "Alert",
                text: "If ACH is added, then ACH add date is required",
                icon: "error",
                button: "Ok",
            })
            inputRef1.current?.focus(); 
            handleFreezStop()   
        } else {
            const apiUrl = '/add-company-bank-details';
            const loginUser = JSON.parse(localStorage.getItem('user'));
            const args = loginUser.roles_id < 3 ?
            {
                company_master_id: id,
                bank_name: values.BankName,
                name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                routing_number: values.BankRouting,
                account_number: values.AccountNumber,
                account_type: values.AccountType,
                account_category: values.AccountCategory,
                email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                ach_info_added_to_bank: values.ACHinfoaddedtobank === 'yes' ? 1 : 0,
                last_ach_updated: values.ACHinfoaddedtobank === 'yes' ? achDate : null,
                routing_number_updated : (bankNameReadonly == false || routingNumberError == false ? 1 : 0 || routingNumberUpdated == true ? 1 : 0)
            }
            :
            {
                company_master_id: id,
                bank_name: values.BankName,
                name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                routing_number: values.BankRouting,
                account_number: values.AccountNumber,
                account_type: values.AccountType,
                account_category: values.AccountCategory,
                email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                if(res.success === true){
                    swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                    })
                    operation="Update"; 
                    handleFreezStop()
                    handleClose();
                    fetchCompanyDetails();   
                    fetchCompanyBankDetails();
                }else{
                    swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                handleFreezStop()
                }
            }
        }
    }
    //Create function end
    //Update function start
    const UpdateBankDetails = async(values) => {
        if(routingNumberError == false && user.roles_id >= 3) {
            swal({
                title: "Failed",
                text: "Please check entered routing number once and if needed contact support for help",
                icon: "error",
                button: "Ok",
            })
            return false;
        }
        handleFreezStart()
        var check = 1;
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
            check = 0;
        } 
        if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
            if(loginUser.company_master_id != id || btntext == "Update And Resolve") {
                check = 0;
            }else{
                check = 1;
            }
        }
        if(check == 0) {
            navigate('/login');
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            handleFreezStop()
            return false;
        }
        if(checkUsaTldStatus == true){
            handleFreezStop()
            return false;
        }
        var achDate;
        if(values.ACHdate1 != undefined || values.ACHdate1 != null){
            const date = new Date(values.ACHdate1);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            achDate = year+"-"+month+"-"+day;               //formatted ach date
            var date1 = new Date(currentDate);
            var date2 = new Date(achDate);
            var compDate = Moment(achDate).format('MM-DD-YY');
            var date2 = new Date(compDate);
            if(date1 < date2) {
                swal({
                    title: "Alert",
                    text: "ACH date should be less than or equal to current date",
                    icon: "error",
                    button: "Ok",
                })
                handleFreezStop()
                return false;
            }
        }
        if(values.AccountCategory == "personal" && (values.BankName == (values.firstName + ":" + values.lastName))) {
            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            })
            handleFreezStop()
            return false;
        }else if(values.AccountCategory == "business" && (values.BankName == values.NameonAccount)) {
            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            })
            handleFreezStop()
            return false;
        } else if (values.ACHinfoaddedtobank == "yes") {
            if (values.ACHdate1 == null && bankDetails.last_ach_updated == null) {
                swal({
                    title: "Alert",
                    text: "If ACH is added, then ACH add date is required",
                    icon: "error",
                    button: "Ok",
                })
                inputRef1.current?.focus();
                handleFreezStop()  
            } else {
                if (values.ACHdate1 == null && values.achCheckValue) {
                    swal({
                        title: "Are you sure?",
                        text: "You want to continue with old ACH date!",
                        icon: "success",
                        buttons: [
                            'No, cancel it!',
                            'Yes, I am sure!'
                        ],
                        dangerMode: true,
                    }).then(async function (isConfirm) {
                        if (isConfirm) {
                            const apiUrl = '/update-company-bank-details';
                            const loginUser = JSON.parse(localStorage.getItem('user'));
                            const args = loginUser.roles_id < 3 ?
                            {
                                id: id,
                                bank_name: values.BankName,
                                name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                                routing_number: values.BankRouting,
                                account_number: values.AccountNumber,
                                account_type: values.AccountType,
                                account_category: values.AccountCategory,
                                ach_info_added_to_bank:values.ACHinfoaddedtobank === 'yes' ? 1 : 0,
                                email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                                last_ach_updated: (values.ACHinfoaddedtobank == 'yes') ? values.ACHdate1 == null ? bankDetails.last_ach_updated :achDate : null,
                                achflag: values.achCheckValue,
                                manually_bank_name_updated : !bankNameReadonly,
                                routing_number_updated : (bankNameReadonly == false || routingNumberError == false ? 1 : 0 || routingNumberUpdated == true ? 1 : 0)
                            }
                            :
                            {
                            id: id,
                            bank_name: values.BankName,
                            name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                            routing_number: values.BankRouting,
                            account_number: values.AccountNumber,
                            account_type: values.AccountType,
                            account_category: values.AccountCategory,
                            email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                            }
                            const res = await dispatch(postData({apiUrl,args})).unwrap();
                            if(res) {
                                    if (res.success === true) {
                                    resolveBankDetailsIssue();
                                    swal({
                                        title: "Success",
                                        text: res.message,
                                        icon: "success",
                                        button: "Ok",
                                    })
                                    values.isFormDirty=true;
                                    handleClose();
                                    handleFreezStop();
                                    fetchCompanyDetails();
                                    fetchCompanyBankDetails();     
                                } else {
                                    swal({
                                        title: "Failed",
                                        text: res.message,
                                        icon: "error",
                                        button: "Ok",
                                    });
                                    handleFreezStop()
                                    if(res.message == "Unauthenticated") {
                                        navigate('/login');
                                    }
                                }
                            }else {
                                
                                document.getElementById("createbtn").classList.remove('d-none');
                                setStatusMesage('');
                                handleFreezStop()
                                swal({
                                    title: "Failed",
                                    text: res.message,
                                    icon: "error",
                                    button: "Ok",
                                });
                                if(res.message == "Unauthenticated") {
                                    navigate('/login');
                                }
                            }
                        } else {
                            inputRef1.current?.focus();
                            handleFreezStop()
                        }
                    })
                } else {
                    const apiUrl = '/update-company-bank-details';
                    const loginUser = JSON.parse(localStorage.getItem('user'));
                    const args = loginUser.roles_id < 3 ?
                    {
                        id: id,
                        bank_name: values.BankName,
                        name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                        routing_number: values.BankRouting,
                        account_number: values.AccountNumber,
                        account_type: values.AccountType,
                        account_category: values.AccountCategory,
                        ach_info_added_to_bank: values.ACHinfoaddedtobank === 'yes' ? 1 : 0,
                        email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                        last_ach_updated: (values.ACHinfoaddedtobank == 'yes') ? values.ACHdate1 == null ? bankDetails.last_ach_updated : achDate : null,
                        achflag: values.achCheckValue,
                        manually_bank_name_updated : !bankNameReadonly,
                        routing_number_updated : (bankNameReadonly == false || routingNumberError == false ? 1 : 0 || routingNumberUpdated == true ? 1 : 0)
                    }
                    :
                    {
                        id: id,
                        bank_name: values.BankName,
                        name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                        routing_number: values.BankRouting,
                        account_number: values.AccountNumber,
                        account_type: values.AccountType,
                        account_category: values.AccountCategory,
                        email_to_notify: values.EmailAddresstonotifyofpaymenttransfers
                    }
                    const res =  await dispatch(postData({apiUrl,args})).unwrap();
                    if(res) {
                            if (res.success === true) {
                            resolveBankDetailsIssue();
                            swal({
                                title: "Success",
                                text: res.message,
                                icon: "success",
                                button: "Ok",
                            })
                            values.isFormDirty=true;
                            handleClose();
                            handleFreezStop();
                            fetchCompanyDetails();
                            fetchCompanyBankDetails();
                        } else {
                            handleFreezStop()
                            swal({
                                title: "Failed",
                                text: res.message,
                                icon: "error",
                                button: "Ok",
                            });
                            handleFreezStop()
                            if(res.message == "Unauthenticated") {
                                navigate('/login');
                            }
                        }
                    }else {
                        document.getElementById("createbtn").classList.remove('d-none');
                        setStatusMesage('');
                        handleFreezStop()
                        swal({
                            title: "Failed",
                            text: res.message,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                }
            }
        } 
        else 
        {
            const apiUrl = '/update-company-bank-details';
            const loginUser = JSON.parse(localStorage.getItem('user'));
            const args = loginUser.roles_id < 3 ?
            {
                id: id,
                bank_name: values.BankName,
                name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                routing_number: values.BankRouting,
                account_number: values.AccountNumber,
                account_type: values.AccountType,
                account_category: values.AccountCategory, 
                ach_info_added_to_bank: values.ACHinfoaddedtobank === 'yes' ? 1 : 0,
                email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                last_ach_updated: (values.ACHinfoaddedtobank == 'yes') ? values.ACHdate1 == null ? bankDetails.last_ach_updated : achDate : null,
                achflag: values.achCheckValue,
                manually_bank_name_updated : !bankNameReadonly,
                routing_number_updated : (bankNameReadonly == false || routingNumberError == false ? 1 : 0 || routingNumberUpdated == true ? 1 : 0)
            }
            :
            {
                id: id,
                bank_name: values.BankName,
                name_on_account: values.AccountCategory == "personal" ? values.firstName + ":" + values.lastName : values.NameonAccount,
                routing_number: values.BankRouting,
                account_number: values.AccountNumber,
                account_type: values.AccountType,
                account_category: values.AccountCategory, 
                email_to_notify: values.EmailAddresstonotifyofpaymenttransfers  
            }
            const res =  await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                    if (res.success === true) {
                    resolveBankDetailsIssue();
                    swal({
                        title: "Success",
                        text: res.message,
                        icon: "success",
                        button: "Ok",
                    })
                    values.isFormDirty=true;
                    handleClose();
                    handleFreezStop();
                    fetchCompanyDetails();
                    fetchCompanyBankDetails();
                } else {
                handleFreezStop()
                    swal({
                        title: "Failed",
                        text: res.message,
                        icon: "error",
                        button: "Ok",
                    });
                }
            }
            else {
                document.getElementById("createbtn").classList.remove('d-none');
                setStatusMesage('');
                handleFreezStop()
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                });
            }
        }
    }
    //Update function end
  
    //Bank validation Schema start
    const BankSchema = Yup.object().shape({
        AccountCategory: Yup.string()
            .required('Please Select Account Category.'),
        NameonAccount: 
            Yup.string().when("AccountCategory",{
                is: (AccountCategory) => AccountCategory == "business",
                then: () =>  
                Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Please Enter Business Name.')
            }),
        firstName: 
            Yup.string().when("AccountCategory",{
                is: (AccountCategory) => AccountCategory == "personal",
                then: () =>  
                Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Please Enter First Name.')
                .matches(/^[a-zA-Z. ]*$/, "Only alphabets space & . are allowed"),
            }),
        lastName: 
            Yup.string().when("AccountCategory",{
                is: (AccountCategory) => AccountCategory == "personal",
                then: () =>  
                Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Please Enter Last Name.')
                .matches(/^[a-zA-Z. ]*$/, "Only alphabets space & . are allowed"),
            }),
        BankRouting: Yup.string()
            .matches(/^\d+$/, 'Invalid Format')
            .min(9, 'At least 9 digit required')
            .max(9, 'maximum 9 digit')
            .required('Please Enter Your Bank Routing number.'),
        AccountNumber: Yup.string()
            .required('Please Enter Your Account Number.'),
        AccountType: Yup.string()
            .required('Please Select Account Type.'),
        EmailAddresstonotifyofpaymenttransfers: Yup.string()
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Please enter your email address to notify payment transfers.'),
    });
    //Bank validation Schema end
  
    const checkIfValueExists = (arr, value) => {
        return arr.some(item => item.attribute_name.attribute_name === value && item.attribute_new_value != null);
    };

    //validate email
    const checkUsaTLD = async(email) => {
        if (email.includes('@')) {
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            }
        }
    }
  
    //fetch bank name 
    const fetchBankName = async (routingNumber, values, setFieldValue) => {
        const apiUrl = "/bankname/"+routingNumber;
        setFieldValue('BankName','');
        if(routingNumber.length == 9)
        {
            setFieldValue('BankName','');
            handleFreezStart()
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res.success)
            {
                handleFreezStop()
                setFieldValue('BankName', res.data);
                setRoutingNumberError(true);
                setRoutingNoErr("");
                setRoutingNumberUpdated(false);
            }
            else
            {
                if(defaultRoutingNumber == routingNumber) {
                    setFieldValue('BankName', defaultBankName);
                    setRoutingNumberError(true);
                    setRoutingNoErr("");
                    setRoutingNumberUpdated(true);
                }else{
                    setFieldValue('BankName', '');
                    setRoutingNumberError(false);
                    setRoutingNumberUpdated(false);
                    setRoutingNoErr("Please check entered routing number once and if needed contact support for help");
                }
                handleFreezStop()
            }
        } 
    };
  
    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleCloseReport}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "2px"
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title"className="dialog-title">
                    Report Banking Issue
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText> */}
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <FormControl fullWidth>
                            <TextareaAutosize
                                margin="dense"
                                aria-label="minimum height"
                                minRows={5}
                                placeholder="Report Issue"
                                onChange={(e) => {handleReportIssueChange(e.target.value)}}
                                sx={{ borderColor: "#D3D3D3" }}
                            />
                        {reportissue == "" ? <div className="error">Report issue reason</div> : null}
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <InputLabel htmlFor="bootstrap-input"><b>Send Mail to the Affiliate ↓*</b></InputLabel>
                        <TextField
                            fullWidth
                            defaultValue="MFSN Bank details Incorrect (ACH Issue)"
                            onChange={(e) => {handleSubjectChange(e.target.value)}}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <Grid container item md={12} >
                            <Grid item>
                                <InputLabel htmlFor="bootstrap-input" ><b>Message:*</b></InputLabel>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <CKEditor editor={ClassicEditor} id="mailBody" name="mailBody"
                        data= {htmlData}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                htmlData = data;
                            } }
                        />
                    </Grid>
                    {/* </DialogContentText> */}
                    <DialogActions sx={{marginTop: isMobile ? "2%" : "2%"}}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" sx={{ minWidth: 150 }}   onClick={handleCloseReport}>
                                CLOSE
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" sx={{ minWidth: 150 }} color="success" onClick={() => reportBankDetailsIssue(id)}>
                                SAVE & SEND EMAIL
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog 
                open={open1} 
                onClose={handleClose} 
                maxWidth="md" 
                fullWidth 
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "2px"
                    }
                }}
            >
                <DialogTitle className="dialog-title">
                    <Typography variant="h6" fontWeight="bold">Bank Details ({companyName})</Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" fontWeight="bold">Last Updated On {filterhistory[0] ? Moment(filterhistory[0].created_at).format('MM-DD-YYYY ') : bankDetails.updated_at ? Moment(bankDetails.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY ') : 'N/A'}</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Formik
                    initialValues={{
                        // BankName: bankDetails.bank_name || "",
                        BankName: defaultBankName,
                        NameonAccount: bankDetails.account_category ? bankDetails.account_category == "business" ? bankDetails.name_on_account : "" : "",
                        firstName: firstNameOnAccount || "",
                        lastName: lastNameOnAccount || "",
                        BankRouting: bankDetails.routing_number || "",
                        AccountNumber: bankDetails.account_number || "",
                        AccountType: bankDetails.account_type,
                        AccountCategory: bankDetails.account_category ,
                        ACHinfoaddedtobank: statusAch ,
                        isFormDirty : true,
                        achCheckValue : false,
                        EmailAddresstonotifyofpaymenttransfers: bankDetails.email_to_notify || "",
                        ACHdate1 : bankDetails.last_ach_updated || null
                    }}
                    validateOnChange={true}  // Ensure this is true
                    validateOnBlur={true}
                    validationSchema={BankSchema}
                    onSubmit={(values, errors) => {
                        const loginUser = JSON.parse(localStorage.getItem('user'));
                        if(loginUser) {
                            if(loginUser.roles_id == 1 || loginUser.roles_id == 2 || loginUser.roles_id == 3){
                                operation == "create" ? CreateBankDetails(values) : UpdateBankDetails(values);
                            }else{
                                navigate('/login');
                            }
                        }else{
                            navigate('/login');
                        }
                    }}
                >
                    {({ values, errors, touched, dirty,handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                        <form>
                            <DialogContent>
                                {/* Type of Account */}
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Box 
                                            sx={{
                                                border: "1px solid #2E67BC", // Border color
                                                borderRadius: "8px", // Rounded corners
                                                padding: "12px",
                                            }}
                                        >
                                            <FormControl margin="dense" component="fieldset" fullWidth>
                                                <FormLabel 
                                                    sx={{ 
                                                        color: user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "account_type") 
                                                            ? "#ed6c02" 
                                                            : "black" 
                                                    }}
                                                >
                                                    Type of Account*
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="AccountType"
                                                    value={values.AccountType}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur("AccountType")}
                                                    disabled={user.roles_id > 3}
                                                    sx={{ gap: 3 }}
                                                >
                                                    <FormControlLabel value="checking" control={<Radio />} label="Checking" sx={{ fontWeight: "bold" }} />
                                                    <FormControlLabel value="savings" control={<Radio />} label="Savings" sx={{ fontWeight: "bold" }} />
                                                </RadioGroup>
                                                {touched.AccountType && errors.AccountType && (
                                                    <Typography sx={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>
                                                        {errors.AccountType}
                                                    </Typography>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box 
                                            sx={{
                                                border: "1px solid #2E67BC", // Border color
                                                borderRadius: "8px", // Rounded corners
                                                padding: "12px",
                                            }}
                                        >
                                            <FormControl margin="dense" component="fieldset" fullWidth>
                                                <FormLabel 
                                                    sx={{ 
                                                        color: user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "account_category") 
                                                            ? "#ed6c02" 
                                                            : "black" 
                                                    }}
                                                >
                                                    Account Category*
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="AccountCategory"
                                                    value={values.AccountCategory}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setIsAccount(e.target.value);
                                                    }}
                                                    onBlur={handleBlur('AccountCategory')}
                                                    sx={{ gap: 3 }}
                                                    disabled={user.roles_id > 3}
                                                >
                                                    <FormControlLabel 
                                                        value="personal" 
                                                        control={<Radio />} 
                                                        label="Personal" 
                                                        sx={{ fontWeight: "bold" }} 
                                                    />
                                                    <FormControlLabel 
                                                        value="business" 
                                                        control={<Radio />} 
                                                        label="Business" 
                                                        sx={{ fontWeight: "bold" }} 
                                                    />
                                                </RadioGroup>
                                                {touched.AccountCategory && errors.AccountCategory && (
                                                    <Typography sx={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>
                                                        {errors.AccountCategory}
                                                    </Typography>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Account Number*"
                                            name="AccountNumber"
                                            id="AccountNumber"
                                            className="blue-border"
                                            autoComplete="off"
                                            disabled={user.roles_id > 3}
                                            value={values.AccountNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.AccountNumber && Boolean(errors.AccountNumber)}
                                            helperText={touched.AccountNumber && errors.AccountNumber}
                                            color={
                                                user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "account_number") 
                                                    ? "warning" 
                                                    : ""
                                            }
                                            focused={
                                                user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "account_number")
                                            }
                                            InputLabelProps={{
                                                sx: {
                                                    color: user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "account_number") 
                                                        ? "#ed6c02" 
                                                        : "black"
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ClickAwayListener onClickAway={handleTooltipClose2}>
                                                            <div>
                                                                <Tooltip
                                                                    PopperProps={{ disablePortal: true }}
                                                                    onClose={handleTooltipClose2}
                                                                    open={openTooltip2}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title={
                                                                        <div style={{ maxWidth: isMobile ? "150px" : "400px" }}>
                                                                            <img src={tooltipImage} style={{ width: "100%", height: "auto" }} alt="Tooltip" />
                                                                        </div>
                                                                    }
                                                                    placement="left-start"
                                                                    interactive
                                                                >
                                                                    <IconButton
                                                                        aria-label="toggle tooltip"
                                                                        edge="end"
                                                                        onClick={handleTooltipOpen2}
                                                                    >
                                                                        <img src={iIcon} alt="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        </ClickAwayListener>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {isAccount === "business" ? (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Business Name*"
                                                name="NameonAccount"
                                                autoComplete="off"
                                                className="blue-border"
                                                disabled={user.roles_id > 3}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.NameonAccount}
                                                color={
                                                    user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "name_on_account")
                                                        ? "warning"
                                                        : ""
                                                }
                                                focused={
                                                    user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "name_on_account")
                                                }
                                                helperText={
                                                    touched.NameonAccount && errors.NameonAccount
                                                        ? errors.NameonAccount
                                                        : ""
                                                }
                                                error={touched.NameonAccount && Boolean(errors.NameonAccount)}
                                            />
                                        ) : (
                                            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                                                {/* First Name */}
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label="First Name*"
                                                        name="firstName"
                                                        autoComplete="off"
                                                        className="blue-border"
                                                        disabled={user.roles_id > 3}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onFocus={() => setFieldTouched("firstName", true)}
                                                        value={values.firstName}
                                                        InputLabelProps={{
                                                            shrink: !!values.firstName || document.activeElement.id === "firstName",
                                                        }}
                                                        color={
                                                            user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "name_on_account")
                                                                ? "warning"
                                                                : ""
                                                        }
                                                        focused={
                                                            user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "name_on_account")
                                                        }
                                                        helperText={touched.firstName && errors.firstName ? errors.firstName : ""}
                                                        error={touched.firstName && Boolean(errors.firstName)}
                                                    />
                                                </Grid>
                                                {/* Last Name */}
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Last Name*"
                                                        name="lastName"
                                                        autoComplete="off"
                                                        className="blue-border"
                                                        disabled={user.roles_id > 3}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onFocus={() => setFieldTouched("lastName", true)}
                                                        value={values.lastName}
                                                        InputLabelProps={{
                                                            shrink: !!values.lastName || document.activeElement.id === "lastName",
                                                        }}
                                                        color={
                                                            user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "name_on_account")
                                                                ? "warning"
                                                                : ""
                                                        }
                                                        focused={
                                                            user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "name_on_account")
                                                        }
                                                        helperText={touched.lastName && errors.lastName ? errors.lastName : ""}
                                                        error={touched.lastName && Boolean(errors.lastName)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Typography variant="subtitle1" gutterBottom className="font2" color="blue" >
                                            Enter the name of the Account held with the Bank.<span style={{color:"red"}}>(NOT YOUR BANK'S NAME)</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputMask
                                            mask="999999999"
                                            maskChar={null}
                                            value={values.BankRouting}
                                            onChange={(e) => {
                                                handleChange(e);
                                                fetchBankName(e.target.value, values, setFieldValue);
                                            }}
                                            onBlur={handleBlur("BankRouting")}
                                            disabled={user.roles_id > 3} // Move disabled here
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    {...inputProps}
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Bank Routing (ABA / ACH) Number*"
                                                    name="BankRouting"
                                                    id="BankRouting"
                                                    className="blue-border"
                                                    error={touched.BankRouting && Boolean(errors.BankRouting) || Boolean(routingNoErr)}
                                                    helperText={touched.BankRouting && errors.BankRouting ? errors.BankRouting : routingNoErr || ""}
                                                    color={
                                                        user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "routing_number")
                                                            ? "warning"
                                                            : ""
                                                    }
                                                    focused={
                                                        user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "routing_number")
                                                    }
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: user.roles_id < 3 && updatedAttributes.length > 0 && checkIfValueExists(updatedAttributes, "routing_number")
                                                                ? "#ed6c02"
                                                                : "black"
                                                        }
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                                                    <div>
                                                                        <Tooltip
                                                                            PopperProps={{ disablePortal: true }}
                                                                            onClose={handleTooltipClose}
                                                                            open={openTooltip}
                                                                            disableFocusListener
                                                                            disableHoverListener
                                                                            disableTouchListener
                                                                            title={
                                                                                <div style={{ maxWidth: isMobile ? "150px" : "400px" }}>
                                                                                    <img src={tooltipImage} style={{ width: "100%", height: "auto" }} alt="Tooltip" />
                                                                                </div>
                                                                            }
                                                                            placement="left-start"
                                                                            interactive
                                                                        >
                                                                            <IconButton aria-label="toggle tooltip" edge="end" onClick={handleTooltipOpen}>
                                                                                <img src={iIcon} alt="info" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </ClickAwayListener>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* Text Fields */}
                                        <TextField
                                            fullWidth
                                            label="Bank Name"
                                            variant="outlined"
                                            name="BankName"
                                            id="BankName"
                                            autoComplete="off"
                                            className="blue-border"
                                            disabled={user.roles_id > 3}
                                            InputProps={{
                                                readOnly: bankNameReadonly, // Read-only control
                                                endAdornment: user.roles_id < 3 && (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={changeBankNameProperty}>
                                                            <ModeEditOutlinedIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            sx={{
                                                backgroundColor: bankNameReadonly ? "rgba(0, 0, 0, 0.1)" : "white",
                                                color: bankNameReadonly ? "gray" : "black"
                                            }}
                                            value={values.BankName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.BankName && Boolean(errors.BankName)}
                                            helperText={touched.BankName && errors.BankName}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Email Address to Notify of Payment Transfers*"
                                            name="EmailAddresstonotifyofpaymenttransfers"
                                            className="blue-border"
                                            autoComplete="off"
                                            disabled={user.roles_id > 3}
                                            value={values.EmailAddresstonotifyofpaymenttransfers}
                                            onChange={(e) => {
                                                handleChange(e);
                                                checkUsaTLD(e.target.value);
                                            }}
                                            onBlur={handleBlur}
                                            error={touched.EmailAddresstonotifyofpaymenttransfers && Boolean(errors.EmailAddresstonotifyofpaymenttransfers)}
                                            helperText={
                                                touched.EmailAddresstonotifyofpaymenttransfers && errors.EmailAddresstonotifyofpaymenttransfers
                                                    ? errors.EmailAddresstonotifyofpaymenttransfers
                                                    : checkUsaTldStatus
                                                    ? "Invalid email format."
                                                    : ""
                                            }
                                            color={
                                                user.roles_id < 3 &&
                                                updatedAttributes.length > 0 &&
                                                checkIfValueExists(updatedAttributes, "email_to_notify")
                                                    ? "warning"
                                                    : ""
                                            }
                                            focused={
                                                user.roles_id < 3 &&
                                                updatedAttributes.length > 0 &&
                                                checkIfValueExists(updatedAttributes, "email_to_notify")
                                            }
                                        />

                                        {/* Conditional Message */}
                                        {firstlable && (
                                            <Typography variant="subtitle1" gutterBottom sx={{ color: "blue", mt: 1 }}>
                                                This is the email address with which <b>{bankDetails.company_name}</b> has been registered with us.  
                                                You can change this if you want another email for banking purposes.
                                            </Typography>
                                        )}

                                    </Grid>
                                    <Grid item xs={12}>
                                        {user.roles_id == 1 || user.roles_id == 2 ?
                                            <Box sx={{border:"1px solid red",p:2}}>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                    <Typography level="h5" color="primary" mt={2}>BACK OFFICE USE ONLY DO NOT ENTER ANY INFORMATION BELOW</Typography>
                                                </Grid>
                                                {operation == "Update" && bankDetails.ach_info_added_to_bank == 1 ?
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                        <FormGroup >
                                                            <FormControlLabel control={<Checkbox
                                                                checked={values.achCheckValue}                                                                
                                                                onChange={(e) => {   
                                                                    setFieldValue('achCheckValue',!values.achCheckValue,true)
                                                                    setFieldValue('ACHinfoaddedtobank', statusAch, true)
                                                                    setFieldValue('ACHdate1',bankDetails.last_ach_updated || null, true )   
                                                                }}

                                                            />} label="Have you also updated ACH details in the Bank?" />
                                                        </FormGroup>
                                                    </Grid>
                                                : 
                                                null
                                                }
                                                {values.achCheckValue || bankDetails.ach_info_added_to_bank == 0 || operation == "create" ?
                                                    <>
                                                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                            <FormControl>
                                                                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "black" }}>ACH Info Added to Bank</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="ACHinfoaddedtobank"
                                                                    id='ACHinfoaddedtobank'
                                                                    disabled={user.roles_id > 3 ? true : false}
                                                                    onChange={(e) => {  
                                                                        handleChange(e) 
                                                                        if (e.target.value == "yes" ) {
                                                                        setFieldValue('achCheckValue',1)
                                                                        }  
                                                                        else{
                                                                            if(statusAch == 'no' && statusAch == e.target.value){
                                                                                if(operation == "create") {
                                                                                    setFieldValue('ACHdate1', null, true )   
                                                                                }
                                                                            }                                                             
                                                                        }
                                                                    }                                                   
                                                                    }
                                                                    value={values.ACHinfoaddedtobank}
                                                                    onBlur={handleBlur('ACHinfoaddedtobank')}
                                                                >
                                                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" sx={{ fontWeight: "bold" }} />
                                                                    <FormControlLabel value="no" control={<Radio />} label="No" sx={{ fontWeight: "bold" }} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid container item lg={7} md={7} sm={12} xs={12} mt={2}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['DatePicker']}>
                                                                        <DatePicker label="Select ACH Date"
                                                                            margin="dense"
                                                                            name="LastACHupdatedate"
                                                                            id='LastACHupdatedate'
                                                                            inputRef={inputRef1}
                                                                            onChange={(value) => { setFieldValue("ACHdate1", value, true) }}
                                                                            maxDate={dayjs(currentDate)}
                                                                            defaultValue={values.ACHdate1 !=null ?  dayjs(values.ACHdate1) : dayjs(bankDetails.last_ach_updated || '')}
                                                                        />
                                                                    </DemoContainer>
                                                            </LocalizationProvider>
                                                        </Grid>

                                                    </>
                                                    : ""}
                                            </Box>
                                        : ""}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Grid container justifyContent="center" spacing={2}  mb={2}>
                                    {/* Close Button - Only for roles_id < 3 */}
                                    {user.roles_id < 3 && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                sx={{ maxWidth: "100%" }}
                                                className="cancel-button"
                                                onClick={handleClose}
                                            >
                                                CLOSE
                                            </Button>
                                        </Grid>
                                    )}
                                    {/* Submit Button - Only for roles_id <= 3 */}
                                    {user.roles_id <= 3 && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                sx={{ maxWidth: "100%" }}
                                                disabled={!dirty}
                                                className="create-button"
                                                onClick={handleSubmit}
                                            >
                                                {btntext}
                                            </Button>
                                        </Grid>
                                    )}
                                    {/* Report Issue Button - Only for roles_id 1 or 2 */}
                                    {user.roles_id == 1 || user.roles_id == 2 ?
                                        <>
                                            <Grid item>
                                                {operation == "Update" &&
                                                    (bankDetails.report_issue != 1 ||
                                                        bankDetails.report_issue == null) &&
                                                    bankDetails.name_on_account != "" &&
                                                    bankDetails.name_on_account != null &&
                                                    bankDetails.routing_number != "" &&
                                                    bankDetails.routing_number != null &&
                                                    bankDetails.account_number != "" &&
                                                    bankDetails.account_number != null &&
                                                    bankDetails.account_type != "" &&
                                                    bankDetails.account_type != null
                                                    ? <Button variant="contained" color="warning" sx={{  maxWidth: "100%" }} onClick={() => handleClickReport()}>
                                                    Report Issue
                                                </Button>  : " "}
                                            </Grid>
                                        </>
                                    : ""}
                                </Grid>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}
export default UpdateBankForm;