import React, { useState, useEffect,useMemo } from 'react';
import LoginHeader from '../Components/LoginHeader';
import RegisterFooter from './Auth/RegisterFooter';
import { Box, Divider, Grid } from '@mui/material';
import { Typography, Card, CardContent,Button } from '@mui/joy';
import config from '../AppConfig';
import '../Css/Custom.css';
import moment from 'moment';
import AuthUser from "../Components/Auth/AuthUser";
import Moment from 'moment';
import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Components/Loader/Loader';

function DailySuspendedMembers(props) {
    const today = new Date();
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = useState({});
    const[data, setData] = useState([]);
    const [freez, setFreez] = React.useState(true);
    const { http } = AuthUser();
 
    useEffect(() => {
       fetchSuspendedMembers();
       console.log("hi");
    }, []);

    const fetchSuspendedMembers = ()=>{
        http.get('/getSuspendedMemberList').then((res) => {
            if (res.data.success === true) {
                setData(res.data.data);
            }
            setFreez(false);
        })  
    }

    const columns = useMemo(
        //column definitions...
        () => [
          {
            accessorKey: 'member_id',
            accessorFn: (data) => (`${data.member_id ? data.member_id :'N/A'}`),
            enableClickToCopy: true,
            id: 'member_id',
            header: 'Member Id',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.member_id ? row.original.member_id : 'N/A'}
               </div>          
             ),
          },
          {
            accessorFn: (data) => (`${data.member_id ? data.member_id :'N/A'}`),
            enableClickToCopy: true,
            id: 'link',
            header: 'Link',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                <a href={`https://supportlink.consumerdirect.app/member/collectBalance.htm?customerId=${row.original.member_id}`} target="_blank">
                 https://supportlink.consumerdirect.app/member/collectBalance.htm?customerId={row.original.member_id}
                </a>
               </div>          
             ),
          },
          {
            enableClickToCopy: true,
            id: 'status',
            header: 'Status',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                 No
               </div>          
             ),
          },
          {
            accessorKey: 'name',
            accessorFn: (data) => `${data.name }`,
            enableClickToCopy: true,
            id: 'name',
            header: 'Name',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.name != null ? (row.original.name) : 'N/A'}
               </div>          
             ),
          },
          {
            accessorKey: 'email',
            accessorFn: (data) => (`${data.email ? data.email :'N/A'}`),
            enableClickToCopy: true,
            id: 'email',
            header: 'Email',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.email ? row.original.email : 'N/A'}
               </div>          
             ),
          },
          {
            accessorFn: (row) => `${row.product_name ? row.product_name : ''}`,
            id:'product_name',
            header: 'Product Name',
            size: 50,
            Cell:({renderedCellValue,row}) =>(row.original.product_name ),
          },
          {
            accessorFn: (row) => `${row.publisher_id ? row.publisher_id : ''}`,
            id:'publisher_id',
            header: 'Publisher Id',
            size: 50,
            Cell:({renderedCellValue,row}) =>(row.original.publisher_id ),
          },
          {
            accessorFn: (row) => `${row.adid ? row.adid : ''}`,
            id:'adid',
            header: 'ADID',
            size: 50,
            Cell:({renderedCellValue,row}) =>(row.original.adid ),
          },
          {
            accessorFn: (row) => `${row.aid ? row.aid : ''}`,
            id:'aid',
            header: 'AID',
            size: 50,
            Cell:({renderedCellValue,row}) =>(row.original.aid ),
          },
          {
            accessorFn: (row) => `${row.sid ? row.sid : ''}`,
            id:'sid',
            header: 'SID',
            size: 50,
            Cell:({renderedCellValue,row}) =>(row.original.sid ),
          },
          {
            accessorFn: (row) => `${row.tid ? row.tid : ''}`,
            id:'tid',
            header: 'TID',
            size: 50,
            Cell:({renderedCellValue,row}) =>(row.original.tid ),
          },
          {
            accessorKey: 'date_range_filter',
            accessorFn: (data) => (`${data.date_range_filter ? Moment(`${data.date_range_filter}`).format('MM-DD-YYYY') : 'N/A'}`),
            enableClickToCopy: true,
            id: 'date_range_filter',
            header: 'Subscription Date time',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.date_range_filter ?  Moment(`${row.original.date_range_filter}`).format('MM-DD-YYYY HH:mm:ss') : 'N/A'}
               </div>          
             ),
          },
          {
            accessorKey: 'account_status',
            accessorFn: (data) => (`${data.account_status ? data.account_status :'N/A'}`),
            enableClickToCopy: true,
            id: 'account_status',
            header: 'Account Status',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.account_status ? row.original.account_status : 'N/A'}
               </div>          
             ),
          },
          {
            accessorKey: 'city',
            accessorFn: (data) => (`${data.city ? data.city :'N/A'}`),
            enableClickToCopy: true,
            id: 'city',
            header: 'City',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.city ? row.original.city : 'N/A'}
               </div>          
             ),
          },
          {
            accessorKey: 'state',
            accessorFn: (data) => (`${data.state ? data.state :'N/A'}`),
            enableClickToCopy: true,
            id: 'state',
            header: 'State',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.state ? row.original.state : 'N/A'}
               </div>          
             ),
          },
          {
            accessorKey: 'zip',
            accessorFn: (data) => (`${data.zip ? data.zip :'N/A'}`),
            enableClickToCopy: true,
            id: 'zip',
            header: 'Zip',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.zip ? row.original.zip : 'N/A'}
               </div>          
             ),
          },
            
    ]);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Suspended Member List',
        headers: ['Member Id','Link','Status', 'Name', 'Email','Product Name','Publisher Id','ADID','AID','SID','TID','Subscription Date & Time','Account Status','City','State','Zip'],
    };
      
    const handleExportData = (data) => {
        const exportedData = data.map((row) => {
            return {
            memberId: `${row.original.member_id }` || 'N/A',
            link:`https://supportlink.consumerdirect.app/member/collectBalance.htm?customerId=${row.original.member_id}`,
            status:'No',
            name:`${row.original.name}`|| 'N/A',
            email:`${row.original.email}`|| 'N/A',
            product_name:`${row.original.product_name}`|| 'N/A',
            publisher_id:`${row.original.publisher_id}`|| 'N/A',
            adid:`${row.original.adid}`|| 'N/A',
            aid:`${row.original.aid}`|| 'N/A',
            sid:`${row.original.sid}`|| 'N/A',
            tid:`${row.original.tid}`|| 'N/A',
            date_range_filter:`${row.original.date_range_filter}`|| 'N/A',
            account_status:`${row.original.account_status}`|| 'N/A',
            city:`${row.original.city}`|| 'N/A',
            state:`${row.original.state}`|| 'N/A',
            zip:`${row.original.zip}`|| 'N/A',
            };
        });
        
        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        
        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';
        
        // Append the link to the document body
        document.body.appendChild(link);
        
        // Programmatically click the link to trigger the file download
        link.click();
        
        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };
      
        const convertToCSV = (data) => {
          const headers = csvOptions.headers.join(',') + '\n';
      
          const rows = data.map((row) => {
                  return Object.values(row).map((value) => {
                      // Handle any necessary formatting or escaping of values
                      // For simplicity, we assume all values are already properly formatted
      
                      // Wrap the value with double quotes if it contains a comma
                      if (typeof value === 'string' && value.includes(',')) {
                          return `"${value}"`;
                      }
      
                      return value;
                  }).join(',');
              }).join('\n');
      
          return headers + rows;
        };

    return (
        <>
            <LoginHeader />
            <div>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
              >
                <Freez />
              </Backdrop>
            </div>
            <Grid container p={2} item xs={12} mt={3}>
                <MaterialReactTable
                            columns={columns}
                            data={data}
                            enableColumnFilterModes
                            filterFns={{
                            customSearchFilterFn: (row, id, filterValue) =>
                            row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                            }} 
                            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                            enableRowSelection={false}
                            positionToolbarAlertBanner="bottom"
                            enableDensityToggle={false}
                            enableColumnFilters={config.DatatableColumnFilter}
                            // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ columnVisibility }} //pass our managed row selection state to the table to use
                            initialState={{
                            showColumnFilters: false,
                            density: 'compact',
                            }}
                            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                            renderTopToolbarCustomActions={({ table }) => (
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                <Button
                                color="primary"
                                // disabled={table.getPrePaginationRowModel().rows.length === 0}
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                    onClick={() =>
                                    handleExportData(table.getPrePaginationRowModel().rows)
                                    }
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                                >
                                CSV
                                </Button>
                            </Box>
                            )
                            }
                        />
            </Grid>
            <RegisterFooter />
        </>
    );
}

export default DailySuspendedMembers;
