import React from 'react';
import { Typography, Container, Grid } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import CobrandHeader from './CobrandHeader';
import LoginHeader from "../Components/LoginHeader";


import RegisterFooter from '../views/Auth/RegisterFooter';
function NotFoundPage() {
    return (
      <>
        <LoginHeader />
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: "10% !important" }}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }}>
                    <WarningAmberIcon sx={{fontSize:"7rem",color:"red"}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }}>
                    <Typography variant="h2" gutterBottom>
                        404 - Page Not Found
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }}>
                    <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
                        The page you are looking for might have been removed or is temporarily unavailable.
                    </Typography>
                </Grid>
            </Grid>
            <div className='footer-block'> <RegisterFooter  /></div>
            </>
          
    );
};

export default NotFoundPage;