import React, { useMemo, useEffect, useState } from 'react';
import DefaultLayout from '../../../../Components/DefaultLayout';
import {  useNavigate,useParams,useLocation } from 'react-router-dom'
import { Typography,Box,Grid, Table, TableCell, TableRow, TextField, Tooltip, FormLabel,FormControl, Button,IconButton, Dialog, TableContainer, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputMask from 'react-input-mask';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';
import { fetchData, postData } from '../../../../redux/apiSlice';
import Freez from '../../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert";
import dayjs from 'dayjs';
import AuthUser from '../../../../Components/Auth/AuthUser';
import MaterialReactTable from "material-react-table";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";   
function FollowUp() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const location = useLocation(); // ✅ Get state data
    const flag = location.state?.flag || false;
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [bankDetails, setData] = useState('');
    const [followupData , setDataFollowup] = useState([]);
    const[BankDetailsIssue, setBankDetailsIssue] = useState([]);
    const [currentFollowUpDate, setFollowUpDate] = useState("");
    const [currentFollowUpTime, setFollowUpTime] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const { user } = AuthUser();
    const [formatedCurrentDate, setFormatedCurrentDate] = useState("");
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
   
    const handleClickOpen = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
            navigate('/login');
            return false;
        }
        const newDate = new Date();
        const myDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        myDate.setDate(myDate.getDate());
        var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        console.log("cDate :"+cDate)
        var todaysTime = Moment(newDate).tz('America/Los_Angeles').format('hh:mm:ss');
        setFollowUpDate(todaysDate);
        setFollowUpTime(todaysTime);
        setCurrentDate(cDate);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getBankDetails = async () => {
        handleFreezStart();
        const apiUrl = '/fetchBankDetails/'+id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            handleFreezStop();
            setData(res);
        }
    }
    const getBankDetailsFollowup = async () => {
        handleFreezStart();
        const apiUrl = '/fetchDetailsFollowup/'+id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            handleFreezStop();
            setDataFollowup(res.data);
            setBankDetailsIssue(res.bankDetailsIssue);
        }
    }
    useEffect(() => {
        if(user.roles_id < 3 ) {
            getBankDetails();
            getBankDetailsFollowup();
        }else{
            navigate('/login');
        }
        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var CurrentFormatedDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YYYY'); 
        setCurrentDate(cDate);
        setFormatedCurrentDate(CurrentFormatedDate)
    }, []);

    const newActivity = async (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
            navigate('/login');
            return false;
        }
        setOpen(false);
        handleFreezStart();
        const apiUrl = '/bankDetailsFollowup';
        const date = new Date(values.followupDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        var mydate = year+"/"+month+"/"+day;
        var todaysTime = values.followupTime;
        const args = {

            followupDate:mydate,
            followupTime:values.followupTime,
            note:values.note,
            id:id,
    
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res){
            handleFreezStop();
            swal({
                title: "Success",
                text: res.message,
                icon: "success",
                button: "Ok",
              })
              getBankDetailsFollowup();
        }
    }

    const RequestFormSchema = Yup.object().shape({
        followupDate: Yup.string()
        .test(
            'is-valid-year',
                    'Invalid Follow Up Date',
                    function (value) {
                    const date = new Date(value);
                    let formatedData1=Moment(date).format('MM-DD-YYYY');
                    const currentDate = new Date();
                    const year = date.getFullYear();
                    const formattedCurrentDate = Moment(currentDate).format('MM-DD-YYYY');
                    const splitYear = formatedCurrentDate.split('-')[2];
                    
                    return Moment(formatedData1, 'MM-DD-YYYY').isSameOrAfter(formattedCurrentDate, 'day');
                    
                    }
                )
                .required('Please enter followupDate'),
        followupTime: Yup.string()
        .test(
            'is-valid-time',
            'Invalid time format. Please enter a valid HH:mm:ss time.',
            (value) => {
                const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
                return regex.test(value);
            }
            )
            .required('Please enter followupTime'),

            note: Yup.string()
        .required('Please enter note'),
    })
      
    const columns = [
        { 
            accessorKey: "ticket_reason", 
            header: "Raised Issues", 
            Cell: ({ cell }) => cell.getValue() || "N/A"
        },
        { 
            accessorKey: "close_reason", 
            header: "Closed Issues", 
            Cell: ({ cell }) => cell.getValue() || "N/A"
        },
        { 
            accessorKey: "status", 
            header: "Status", 
            Cell: ({ cell }) => cell.getValue() || "N/A"
        },
        { 
            accessorKey: "created_at", 
            header: "Raised Date", 
            Cell: ({ cell }) => cell.getValue() ? Moment(cell.getValue()).format("MM-DD-YYYY") : "N/A"
        },
        { 
            accessorKey: "updated_at", 
            header: "Closed Date", 
            Cell: ({ row }) => 
                row.original.status === "Completed" && row.original.updated_at 
                    ? Moment(row.original.updated_at).format("MM-DD-YYYY") 
                    : "N/A"
        },
    ];

    const ContentLayout =
    <>
        <Box sx={{ padding: 3 }}>
            <Box display="flex" alignItems="center" gap={1} mb={2}>
                {flag && ( // ✅ Show back arrow only when flag is true
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <Typography variant="h5" fontWeight="bold">
                Bank Issues Details Follow Up
                </Typography>
            </Box>

            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h6" color="primary">Bank Issues Details For AID ({bankDetails.aid ? bankDetails.aid : 'N/A'})</Typography>
            </Grid>

            <Box sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 2,marginBottom: 2 }}>
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={6} sx={{ borderRight: '1px solid #ccc', paddingRight: 2 }}>
                        {[
                            { label: "User Name:", value: `${bankDetails.first_name} ${bankDetails.last_name}` },
                            { label: "Email:", value: bankDetails.email || "N/A" },
                            { label: "Phone no:", value: bankDetails.phone_no || "N/A" },
                            { label: "Bank Name:", value: bankDetails.bank_name || "N/A" },
                            { label: "Name on Account:", value: bankDetails.name_on_account || "N/A" },
                            { label: "Bank Routing (ABA/ACH) Number:", value: bankDetails.routing_number || "N/A" },
                        ].map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginTop: index > 0 ? 1 : 0 }}>
                                <Typography variant="body2" fontWeight="bold" sx={{ marginRight: 'auto' }}>
                                    {item.label}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ textAlign: 'right', flex: 1 }}>
                                    {item.value}
                                </Typography>
                            </Box>
                        ))}
                    </Grid>
                    <Grid item xs={6} sx={{ paddingLeft: 2 }}>
                        {[
                            { label: "Bank Account Number:", value: bankDetails.account_number || "N/A" },
                            { label: "Type of Account:", value: bankDetails.account_type || "N/A" },
                            { label: "And is Account:", value: bankDetails.account_category || "N/A" },
                            { label: "Email Address to notify of transfers sent:", value: bankDetails.email_to_notify || "N/A" },
                            { label: "ACH Info Added to Bank:", value: bankDetails.ach_info_added_to_bank == 1 ? "Yes" : "No" },
                            { label: "Last ACH update:", value: bankDetails.last_ach_updated ? Moment(bankDetails.last_ach_updated).format('MM-DD-YYYY') : "N/A" },
                        ].map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginTop: index > 0 ? 1 : 0 }}>
                                <Typography variant="body2" fontWeight="bold" sx={{ marginRight: 'auto' }}>
                                    {item.label}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ textAlign: 'right', flex: 1 }}>
                                    {item.value}
                                </Typography>
                            </Box>
                        ))}
                    </Grid>
                </Grid>

            </Box>
           
            <Grid container item lg={12} md={12} sm={12} xs={12} justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6" color="primary">Bank Follow Up Details</Typography>
                <Button
                    variant="contained"
                    color='success'
                    onClick={handleClickOpen}
                >New Activity
                </Button>
            </Grid>
            {followupData.map((logs, idx) => (
                <Box key={idx} sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 2, marginBottom: 2 }}>
                    <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                        <Grid item xs={6} sx={{ borderRight: '1px solid #ccc', paddingRight: 2 }}>
                            {[
                                { label: "Last Followup By:", value: logs.user ? `${logs.user.first_name} ${logs.user.last_name}` : 'N/A' },
                                { label: "Last Followup Date:", value: logs.created_at ? Moment(logs.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm') + ' Hrs' : 'N/A' },
                            ].map((item, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginTop: index > 0 ? 1 : 0 }}>
                                    <Typography variant="body2" fontWeight="bold" sx={{ marginRight: 'auto' }}>
                                        {item.label}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ textAlign: 'right', flex: 1 }}>
                                        {item.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Grid>
                        <Grid item xs={6} sx={{ paddingLeft: 2 }}>
                            {[
                                { label: "Next Follow Up Date:", value: logs.followup_date ? Moment(logs.followup_date).format('MM-DD-YYYY') : 'N/A' },
                                { label: "Next Follow Up Time:", value: logs.followup_time || 'N/A' },
                            ].map((item, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginTop: index > 0 ? 1 : 0 }}>
                                    <Typography variant="body2" fontWeight="bold" sx={{ marginRight: 'auto' }}>
                                        {item.label}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ textAlign: 'right', flex: 1 }}>
                                        {item.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                <Typography variant="body2" fontWeight="bold" sx={{ marginRight: 'auto' }}>
                                    Description:
                                </Typography>
                                <Typography variant="subtitle1" sx={{ flex: 1, textAlign: 'left' }}>
                                    {logs.description || 'N/A'}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ))}

            <Grid container item lg={12} md={12} sm={12} xs={12}  mb={2}>
                <Typography variant="h6" color="primary">Issues List</Typography>
            </Grid>

            <Box sx={{ width: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={BankDetailsIssue}
                    enablePagination
                    enableColumnFilters
                    enableSorting
                    enableGlobalFilter
                    enableRowSelection={false}
                    muiTablePaperProps={{
                        sx: { backgroundColor: "white" },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            backgroundColor: "#2A68B3",
                            color: "white",
                            fontWeight: "bold",
                        },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        sx: { backgroundColor: row.index % 2 === 0 ? "white" : "#EBF4FF" },
                    })}
                />
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "20px"
                    }
                }}
            >
                <Formik
                    initialValues={{
                    
                    followupDate: dayjs(currentFollowUpDate),
                    followupTime: (currentFollowUpTime), 
                    note: '' 
                    
                }}
                validationSchema={RequestFormSchema}
                onSubmit={newActivity}
                >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
                    <DialogTitle className="dialog-title">
                        <Typography variant="h6" fontWeight="bold">Add Activity</Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container item spacing={4}>
                        <Grid item xs>
                            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                                <DemoContainer components={['DatePicker']} >
                                    <DatePicker label="Follow Up Date"
                                        margin="dense"
                                        id="followupDate"
                                        name="followupDate"
                                        fullWidth
                                        value={values.followupDate}
                                        onChange={(value) => {setFieldValue("followupDate", value, true)}}
                                        minDate={dayjs(currentDate)}
                                        maxDate={dayjs().add(75, 'year')}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {touched.followupDate && errors.followupDate ? <div className='error'>{errors.followupDate}</div> : null}
                            </Grid>
                            <Grid item xs>
                                <InputMask
                                    mask='99:99:99'
                                    label="Follow Up Time*"
                                    maskChar={null}
                                    name='followupTime'
                                    fullWidth
                                    margin="dense"
                                    // size="small"
                                    id='followupTime'
                                    value={values.followupTime}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    >
                                    {(inputProps) =>
                                        <TextField
                                            label="Follow Up Time*" variant="outlined"
                                            {...inputProps}
                                        />
                                    }
                                </InputMask>
                                
                                {touched.followupTime && errors.followupTime ? <div className='error'>{errors.followupTime}</div> : null}
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <FormLabel>Description*</FormLabel>
                            <FormControl fullWidth size="small">
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={5}
                                    placeholder="Enter Description"
                                    fullWidth
                                    name="note"
                                    id="note"
                                    value={values.note}
                                    onChange={handleChange}
                                />
                                    {touched.note && errors.note ? <div className='error'>{errors.note}</div> : null}
                            </FormControl>
                            
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={handleClose} className="cancel-button">
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={handleSubmit}  className="save-button">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </>
                )}
                </Formik>
            </Dialog>
        </Box>
    </>

    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 10 }}
                    open={freez}
                // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default FollowUp;