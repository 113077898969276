import React, { useState,useEffect, useRef } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid, Button, FormControlLabel, Checkbox, FormHelperText, FormControl,Box,TextField } from '@mui/material';
import Typography from '@mui/joy/Typography';
import AuthUser from '../../../Components/Auth/AuthUser';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert";
import AppConfig from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import SignatureModal from '../../../Components/SignatureModal/SignatureModal';

function TermsAndCondition({ bounce, resp }) {
    const dispatch = useDispatch();
    const { user} = AuthUser();
    const inputRef = useRef();
    const [checked, setChecked] = useState(false);
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const [bouncedFlag, setbouncedFlag] = useState(bounce);
    const [showModal, setShowModal] = useState(false);
    const [signature, setSignature] = useState(null);
    const executive = resp.executive;
    const [signatureLabel, setSignatureLabel] = useState('Signature');
    const firstName = resp.first_name + " " + resp.last_name;
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const [signatureValidation, setSignatureValidation] = useState('');
    const [setDate, setFormattedDate] = useState('');
    const handleSaveSignature = (sig) => {
      setSignature(sig);
      setSignatureLabel('Sign'); // Change label to indicate signing is completed
      setSignatureValidation('')
      closeModal();
    };
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const AcceptCondition = async() => {
        if(!signature){
            setSignatureValidation('Please add signature')
        }
        if (checked && signature) {
            handleFreezStart();
            const apiUrl = '/updateReferralTerms';
            const args = {
                status:1,
                url:AppConfig.BASE_URL,
                image:signature,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                if (res.success === true) {
                    handleFreezStop();
                    swal({
                        title: "Success",
                        text: "Here we go for your Referral Program",
                        icon: "success",
                        button: "Ok",
                    }).then(() => {
                            window.location.reload();
                    });
                } else {
                    handleFreezStop();
                }
            }else{
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                });
            }
        } 
       
    };
    useEffect(()=>{
        setSignatureValidation(signatureValidation)
    },[signatureValidation])

    useEffect(() => {
        const today = new Date();
        const options = { timeZone: 'America/Los_Angeles', month: '2-digit', day: '2-digit', year: 'numeric' };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const [
          { value: month },,
          { value: day },,
          { value: year }
        ] = formatter.formatToParts(today);
    
        const formattedDate = `${month}/${day}/${year}`;
        setFormattedDate(formattedDate);
    }, []);
    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                    <Typography color="primary" level="h4"> Terms and Conditions - Affiliate Referrer Agreement</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                    <Typography level="body1">
                        This agreement (the "Agreement") is made by and between MyFreeScoreNow, Inc. (“MFSN”), and (“Referrer” or “You” or “Associate”) located at: (collectively, the “Parties”) as of the date You register for the Affiliate Referral Program (the “Effective Date”) .
                    </Typography>
                    <Typography level="body1" mt={1}>
                        WHEREAS, MFSN is in the business of distribution of credit reports, credit scores, credit monitoring and various other credit products; WHEREAS, Referrer wishes to obtain the right to refer companies that wish to become a Referrer of MFSN and market the Services which are provided by MFSN, and WHEREAS, MFSN wishes to grant certain rights to Referrer pursuant to the terms of this Agreement. NOW THEREFORE, for good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the Parties hereby agree as follows: Company, (‘You”) have been accepted to represent MyFreeScoreNow Inc (“MFSN”) and make commissions referring users. We ask that you represent the MFSN brand with honesty and integrity. You agreed to follow the referral program terms and marketing guidelines as provided by MFSN and updated from time to time. MFSN has the right to terminate this Agreement at any time and Void any future commissions permanently. You will be eligible receive the following commission tiers based on the Programs defined in the Referrer Agreement between You and MFSN. Referral commission to You for a company that You refer to us that enrolls in the MyFreeScoreNow affiliate program if the referral to MFSN is performed by Your company according the method specified to You by MyFreeScoreNow
                    </Typography>
                    <Typography level="body1" mt={2}>
                        Commission Structure to You for referring the new companies to join MFSNs Affiliate Program
                    </Typography><br />
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="h5">Upfront Commissions:</Typography><br />
                    </Grid>
                    <Typography level="body1">$100 one-time payment</Typography>
                   
                    <br />
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="h5">Ongoing Residual Commission:</Typography>
                    </Grid>
                    <br />
                    <Typography level="body1">5% of the commission earned by the qualified affiliates referred by you.</Typography>
                    <br />
                    
                    <Typography level="body1" mt={1}>
                        To earn commissions above, certain <b>qualifying criteria </b>
                        must be met: a) There have been at least five (5) eligible, payable Conversions (customer enrollments to MFSN’s services under your referral link and referral code within 60 days of the date of an referral from you that is accepted by MyFreeScoreNow and at least five (5) enrollments are Active, Paid, in good standing and that are eligible for payment to the affiliate. The five (5) minimum enrollments exclude the principals/owners of the Referred company) enrolling themselves under MFSN. Reports and statistics both on Referrer’s referrals and the enrollments generated from referred affiliates will be based solely by MFSN and You agree to accept them as true and correct.
                    </Typography><br />
                    <Typography level="body1" mt={1}>
                        Ongoing participation in the revenue share/commission of referred affiliates will be defined below and will be based solely on MyFreeScoreNow reports and You agree to accept them as true and correct. Any refunds or chargebacks may disqualify some or all of your ongoing commissions. Termination. Either Party may terminate this Agreement for any reason upon three (3) days written notice to the other Party. Compensation. All payments shall be made to Referrer by MFSN as follows: Payment Terms: Net 45. Website Content. Referrer represents and warrants that it does not feature any of the following types of products, services or content and acknowledges that no such products, services or content are permitted in conjunction with MFSN’s Services: (a) sexual paraphernalia; (b) drug paraphernalia; (c) adult films, recording or magazines; (d) materials which are defamatory, obscene, harmful to minors or pornographic, child or otherwise; (e) weapons; (f) other illicit or illegal activities; (g) computer viruses, so- called Trojan horses, time bombs, cancel-bots or other computer
                        programming routines that have the effect (whether by intent or not) of damaging, detrimentally interfering with, surreptitiously intercepting or expropriating any system, data, or personal information; (h) or materials which are false, misleading or intentionally inaccurate. Acceptable Uses. Referrer represents and warrants that all content displayed on Referrer’s website(s) is solely owned by Referrer or that Referrer’s use thereof is authorized by the appropriate third Party; does not infringe upon any third Party rights (including, but not limited to intellectual property rights); is not defamatory, libelous, unlawful or otherwise objectionable. Referrer shall not provide, promote, distribute, place or otherwise publish, as a provider of 3 Performance Marketing on behalf of MFSN, any content, or website that includes content, which is libelous, defamatory, obscene, pornographic, abusive, fraudulent or otherwise, violates any law. Since MFSN may not review all information provided by Referrer to the public, Referrer shall remain solely responsible for Referrer’s content and website(s).
                        As a Referrer, Referrer may not artificially inflate traffic counts to MFSN site(s) using any device, program, robot or other means, including, but not limited to, JavaScript pop-up windows and/or redirects.
                        Referrer shall not click on Referrer’s own banners and/or links. MFSN’s Privacy Policy and Terms & Conditions. Referrer acknowledges that MFSN’s Privacy Policy as well as its Terms & Conditions are agreements solely between the Visitor and MFSN and Referrer acknowledges that it shall not be deemed a third
                        party beneficiary to any such agreement. Notwithstanding, Referrer agrees that it will abide by the applicable terms respecting the Visitors rights related to MFSN’s Privacy Policy and Terms & Conditions. Limited Warranty. MFSN shall operate its website in good faith and in a commercially reasonable manner. MFSN shall make all commercially reasonable efforts to maintain an operational website during normal business hours. However, certain technical difficulties may, from time to time, result in temporary service interruptions. Referrer understands and acknowledges that system downtime may occur and agrees that MFSN is not liable for any consequences of such interruptions. MFSN shall have no liability for unauthorized access, alteration, theft or destruction of any website of Referrer or referrer customer data files or systems or programs by way of accident, third party fraud or third-party devices. To the fullest extent permissible pursuant to applicable law,
                        MFSN disclaims all warranties express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose, in relation to its service, its use and the results of such use. Without limiting the foregoing, MFSN specifically disclaims any warranty relating to its service (a) that it will be uninterrupted or error-free, (b) that defects will be corrected, (c) that there are no viruses or other harmful components, (d) that the security methods employed will be sufficient, or (e) as to MFSN’s correctness, accuracy, or reliability Limitation of Liability. MFSN shall not be liable to Referrer or any other person for: (a) any indirect, special, incidental or consequential damages arising out of the use or inability to use MFSN’s service or any information provided on the MFSN website or any other hyperlinked website, including, without limitation, damages for loss of goodwill, lost profits, business interruption, loss of programs or other data,
                        even if MFSN has been advised of the possibility of such damages, or (b) any claim attributable to errors, omissions or other inaccuracies in the MFSN website or any hyperlinked website.
                        Referrer shall not be liable to MFSN or any other person for:
                        (a) any indirect, special, incidental or consequential damages arising out of the use or inability to use the service or any information provided on the MFSN website or any other hyperlinked website, including, without limitation, 4 damages for loss of goodwill, lost profits, business interruption, loss of programs or other data, even if Referrer has been advised of the possibility of such damages, or (b) any claim attributable to errors, omissions or other inaccuracies in the MFSN website or any hyperlinked website. Hold Harmless. Referrer agrees to indemnify, defend, and hold harmless MFSN and its officers, directors, employees and agents from and against any and all liability, claims, losses, damages, injuries or expenses, arising from the negligent or intentional misconduct of Referrer. MFSN agrees to indemnify, defend, and hold harmless Referrer and its officers, directors, employees and agents from and against any and all liability, claims, losses,
                        damages, injuries or expenses arising from the negligent or intentional misconduct of MFSN. Trademark License. Subject to the provisions of this Agreement, MFSN hereby grants to Referrer, a limited, non-exclusive, non-transferable (except with limited respect to Subcontracted Referrers) revocable, royalty free, without the right to grant sublicenses, to use the Trademark only for the purposes set forth in this Agreement. All images, technology and content provided to Referrer by MFSN is and shall remain the sole and exclusive property of MFSN, and no part thereof shall be deemed assigned or licensed to Referrer except as explicitly provided herein. All intellectual property rights, including trademarks, tradedress, copyrights, patents, trade names and service marks related to the foregoing shall remain MFSN’s sole and exclusive property, including rights in and to any derivative works thereof. Referrer may display the Trademark only in a manner which is
                        acceptable and specifically approved by MFSN and for no other purpose whatsoever. Referrer may not modify the Trademark nor may it modify the banners or content or any images provided to Referrer by MFSN. Upon request, Referrer shall display notices of Trademark status as MFSN may require, adjacent to and with the first or most prominent use of the Trademark in each piece of advertising or printed materials in which such Trademark appears, and subject to such other requirements as MFSN may from time to time impose. MFSN may terminate Referrer’s license to use the Trademark if MFSN reasonably believes that such use dilutes, tarnishes or blurs the value of its mark. Referrer acknowledges that Referrer’s use of the Trademark will not create in Referrer, nor will Referrer represent that Referrer has any right, title or interest in or to the Trademark other than the license granted by MFSN as provided herein. Referrer will not challenge the validity of or attempt
                        to register any intellectual property, including but not limited to the Trademark owned by MFSN, nor will Referrer adopt any derivative or confusingly similar names, brands or marks or create any combination of marks with the Trademark. Confidential Information. The Parties, for their mutual benefit, may wish to disclose certain proprietary,
                        confidential information to the other Party on a confidential basis. Confidential Information shall mean, but is not limited to, any information, trade secrets, know-how, customers, ideas, plans, designs, drawings and processes, specifications and the like that a Party 5 considers to be confidential, and includes the fact and extent of the owner’s interest in the same. Confidential Information may be communicated orally, in writing, by electronic or magnetic media, or by visual observation and the information will be deemed confidential if a Party is advised of same, either orally or in writing. This Agreement and the terms and conditions herein, the compensation and commission structure, and the existence of the Agreement itself shall be considered as Confidential Information. Any information that is designated as Confidential Information in accordance with the terms of this Agreement shall be used only in furtherance of the purposes of this Agreement and shall not be disclosed to anyone outside of the receiving Party’s organization.
                        The receiving Party agrees that it will not use or exploit Confidential Information for its own benefit or that of any third party and will make only such use of the Confidential Information as is contemplated by this Agreement or specifically authorized in advance by the disclosing Party in writing.
                        The receiving Party may disseminate Confidential Information to its employees or subcontractors who have a reason to know or have access to such Confidential Information and only to the extent necessary to fulfill the intent and terms of this Agreement. To the extent necessary to protect the disclosing Party’s rights, the receiving Party will have appropriate written agreements with its employees and subcontractors sufficient to enable
                        it to comply with all the terms of this Agreement. This provision will not apply to Confidential Information that: i) after disclosure, lawfully enters the public domain other than as a result of the act or omission of the receiving Party; or ii) at the time of disclosure, is in the public domain; or iii) the receiving Party can demonstrate by written evidence that the same is already lawfully known to it, prior to receiving it from the other Party; or iv) was obtained or received from a third Party without restriction on disclosure; or v) the receiving Party can demonstrate by written evidence, the same was developed by the receiving Party independently of and without direct or indirect access to the Confidential Information disclosed by the disclosing Party; or vi) the receiving Party is required to disclose in response to a valid order of a court of competent jurisdiction, in which event the receiving Party agrees to promptly notify the disclosing Party of the requirement for such disclosure to give the disclosing Party the opportunity to contest the disclosure or obtain a protective order.
                        This provision shall survive the duration of this Agreement. Notices. Any notice required or permitted by this Agreement shall be in writing and shall be delivered as follows with notice deemed given as indicated: (a) by personal delivery; (b) by overnight courier upon written verification of receipt; (c) by telecopy or facsimile transmission upon acknowledgment of receipt or electronic transmission; or (d) by certified mail, return receipt requested, upon verification of receipt. MFSN (only) may also notify Referrer of changes 6 via email. Notices shall be sent to the Parties at the addresses described in the first paragraph of this Agreement or such other address as either Party may designated for itself in writing. Each Party shall have the right from time to time and at any time to change its address and shall have the right to specify as its address any other address by giving at least ten (10) days' written notice to the other Party. Agency. Nothing contained herein shall be construed as creating any agency, partnership, or other form of joint enterprise between the Parties.
                        Assignment. Either Party may assign this Agreement only upon prior written consent of the other, which consent shall not be unreasonably withheld. Force Majeure.
                        Neither Party shall be liable hereunder by reason of any failure or delay in the performance of its obligations hereunder on account of strikes, shortages, riots, insurrection, fires, flood, storm, explosions, acts of God, war, governmental action, labor conditions, earthquakes, Pandemics, Plagues, Economic and social and political unrest, or any other cause which is beyond the reasonable control of such Party.
                        Jurisdiction. This Agreement shall be governed by California law. The exclusive jurisdiction for any actions brought in connection with this Agreement shall be in the state and federal courts located in the State of California, County of Ventura, without regard to conflict of laws. Attorney’s Fees. In the event of any legal or equitable proceeding or lawsuit is brought by either Party in connection with this Agreement,
                        the prevailing Party shall be entitled to expert fees and costs, reasonable attorneys’ fees and costs and any other monetary award as the presiding Court deems appropriate. Counterparts. This may be executed in counterparts, which together shall constitute one and the same Agreement. The Parties may execute more than one copy of the Agreement, each of which shall constitute an original each of which alone and all of which together shall constitute one and the same instrument. This Agreement may be transmitted by facsimile and it is the intent of the Parties for the facsimile (or a photocopy thereof) of any autograph printed by a receiving facsimile machine to be an original signature and for the facsimile (or a photocopy thereof) and any complete photocopy of this Agreement to be deemed an original counterpart. Entire Agreement. This agreement, including all exhibits and/or attachments, hereto constitutes the complete and entire agreement of the Parties regarding
                        the subject matter and supercedes all prior proposals, agreements or other communications between the Parties, whether written or oral. This Agreement shall not be modified except by written amendment, signed by both Parties. Severability. If any provision of this Agreement is rendered or declared illegal or unenforceable by reason of any existing or subsequently enacted legislation or by decree of a court of 7 competent jurisdiction, all remaining provisions of this Agreement shall remain in full force and effect. IN WITNESS WHEREOF, the Parties, intending to be legally bound, have caused this Agreement to be executed by their duly authorized representatives as of the Effective Date
                    </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <FormControl error={!checked ? true : false}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputRef={inputRef}
                                    name="termsAndConditions"
                                    color="primary"
                                    disabled={bouncedFlag} 
                                />
                            }
                            label="I agree to the terms and conditions"
                        />
                        {!checked ? <><FormHelperText className='error'>{"Please select the checkbox and click the button below to accept terms and conditions."}</FormHelperText></> : null}
                    </FormControl>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Box display="flex" flexDirection="column" p={2}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start">
                            <p style={{ margin: 0, padding: '2px' }}>
                                <strong>Advertiser: {resp.company_name}</strong>
                            </p>
                            <Box component="p" display="flex" alignItems="center" margin={0} padding="2px">
                                <strong>Signature:&nbsp;</strong>
                                <TextField
                                    // Using TextField as a container
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                        style: {
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '0.5rem',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            width: '180px',
                                            minWidth: '',
                                            height: '60px',
                                        },
                                        startAdornment: (
                                            <Box display="flex" alignItems="center" gap={1}>
                                                {signature ? (
                                                    <Box
                                                        className="signature-preview"
                                                        onClick={() => openModal()} // Open modal on image click
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <img
                                                            src={signature}
                                                            alt="Signature"
                                                            style={{ width: '100%', height: 'auto' }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Button
                                                        className="signature-button"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={openModal}
                                                        startIcon={<span className="signature-icon">✍</span>}
                                                    >
                                                        {signatureLabel}
                                                    </Button>
                                                )}
                                            </Box>
                                        ),
                                    }}
                                    InputLabelProps={{ shrink: false }}
                                />
                                {showModal && <SignatureModal onSave={handleSaveSignature} onClose={closeModal} firstName={firstName} />}
                            </Box>
                            {signatureValidation && <div className="error">{signatureValidation}</div>}
                            <p style={{ margin: 0, padding: '2px' }}>
                                <strong>Print Name: {firstName}</strong>
                            </p>
                            <p style={{ margin: 0, padding: '2px' }}>
                                <strong>Title: {resp.title}</strong>
                            </p>
                            <p style={{ margin: 0, padding: '2px' }}>
                                <strong>Date: {setDate}</strong>
                            </p>
                            </Box>
                        </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Box display="flex" flexDirection="column" alignItems="flex-end" p={2}>
                                <Box display="flex" flexDirection="column" alignItems="flex-start">
                                    <p style={{ margin: 0, padding: '2px' }}>
                                        <strong>Vendor: MyFreeScoreNow Inc.</strong>
                                    </p>
                                    <p style={{ margin: 0, padding: '2px', display: 'flex', alignItems: 'center' }}>
                                        <strong>Signature:&nbsp;</strong>
                                        <img
                                            src={executive}
                                            alt="Executive"
                                            style={{ width: '40%', height: 'auto' }}
                                        />
                                    </p>
                                    <p style={{ margin: 0, padding: '2px' }}>
                                        <strong>Print Name: Bruce J. Cornelius</strong>
                                    </p>
                                    <p style={{ margin: 0, padding: '2px' }}>
                                        <strong>Title: President</strong>
                                    </p>
                                    <p style={{ margin: 0, padding: '2px' }}>
                                        <strong>Date: {setDate}</strong>
                                    </p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {user.roles_id<=3?
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Button color="success" variant="contained" size="medium" disabled={bouncedFlag} onClick={AcceptCondition}>I ACCEPT THE TERMS & CONDITIONS</Button>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                        {bouncedFlag ? <b style={{ color: 'red' }}>Emails are not getting delivered. Please update the communication email.</b> : ''}
                    </Grid>
                </Grid>
   :null }
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}
export default TermsAndCondition;