import React, { useState, useEffect } from "react";
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import { DialogContent, Grid, Select, FormControl, MenuItem, InputLabel, Box, TextField, Dialog, InputAdornment, DialogActions, OutlinedInput, Backdrop, DialogTitle } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Button, List, ListItem, ListItemDecorator, ListItemContent } from '@mui/joy';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import ShareIcons from '../ReferralProgram/ShareOnSocialMedia';
import Chip from '@material-ui/core/Chip';
import config from '../../../AppConfig';
import * as Yup from 'yup';
import { Formik, FieldArray } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import OperationalHours from '../../../Components/OperationalHours';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import swal from "sweetalert";
import LogoEdit from '../../../Components/Cobrand/LogoEdit';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import InputMask from 'react-input-mask';
import {  useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Freez from '../../../Components/Loader/Loader';

import ContentElement from "./ContentElement";
import PopupPreview from "../../../Components/CreditSnapshot/PopupPreview";
import { snapshot } from '../../../redux/authSlice';
import Moment from 'moment';
import jsPDF from 'jspdf';
import { exampleHTML } from './CreditTermsAndCondition';
import { styled } from '@mui/material/styles';

let logoFlag = 0;
let blankValueIndexes1 = [];
let blankValueIndexes2 = [];
let operationalhoursvalues = [];
let selectedaid;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};


let customColorArray = {};
function CreditSnapFirst(props) {
    //fetch snapshot details start
    const dispatch = useDispatch();
    const [open1, setOpen1] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [copySuccess, setCopySuccess] = useState('');
    const [snippetCopy, setSnippetCopy] = useState('Copy');
    const [open2, setOpen2] = React.useState(false);

    //coman variables
    const { http, user } = AuthUser();
    const [creditinfo, setCreditInfo] = useState([]);
    const [terms,setTermsData]=useState([]);
    const [pids, setPids] = useState([]);
    const [pid, setPid] = useState();

    //logo editor modal start
    const [uploadLogo, setUploadLogo] = React.useState(false);
    //logo editor modal end
    //pid varibles and function starts
    const [selectedPid, setSelectedPid] = useState('');
   

    const [firstlogoUrl, setFirstLogoUrl] = useState(null);
    const [defaultPhoneNumber, setdefaultPhoneNumber] = useState();
    const [receivedphone, setReceivedPhone] = useState();
    const [receivedpid, setReceivedPid] = useState();
    const [receivedaid, setReceivedAid] = useState([]);
    const [firstAid, setFirstAid] = useState([]);
    const [receivedshift, setReceivedShift] = useState([{ label: "Monday-Friday(Weekdays)", timings: [{ start: "09:00", end: "18:00" }] },
    { label: "Saturday-Sunday(Weekends)", timings: [{ start: "09:00", end: "18:00" }] },
    ]);

    const [link, setLink] = useState();
    const [companyId, setCompanyId] = useState();
    const [popupHtml, setPopupHtml] = useState();
    const [snapshotToken, setSnapshotToken] = useState();
    const [snapshotStr, setSnapshotStr] = useState();
    const CustomSelect = styled(Select)(({ theme }) => ({
        '& .MuiInput-underline:before': {
          borderBottom: 'none',
        },
        '& .MuiInput-underline:after': {
          borderBottom: 'none',
        },
      }));
      
      const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
        '&.Mui-focused': {
          color: theme.palette.text.primary,
        },
    }));
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
   
    const [openColorPicker, setOpenColorPicker] = useState(null);

    const toggleColorPicker = (blockName) => {
        setOpenColorPicker((prevBlockName) => (prevBlockName === blockName ? null : blockName));
    };

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handlePopupPreview = (data) => {
        handleClickOpen2();
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleLogoURL = (image) => {
        setFirstLogoUrl(image);

    }
    const handleClickOpen1 = () => {
        if (creditinfo.data) {
            const attr = JSON.parse(creditinfo.data.snapshot_popup_attribute_json);
            customColorArray = {
                popupHeaderBackground: attr ? attr.popupHeaderBackground : '#00BBD3',
                popupHeaderText: attr ? attr.popupHeaderText : '#000000',
                popupBodyBackground: attr ? attr.popupBodyBackground : '#ffffff',
                popupBodyText: attr ? attr.popupBodyText : '#000000',
                popupButtonBackground: attr ? attr.popupButtonBackground : '#FF9400',
                popupButtonText: attr ? attr.popupButtonText : '#FFFFFF',
                popupIconColor: attr ? attr.popupIconColor : '#ff9400'
            }
            setOpen1(true);
        }
    };

    const handleClose1 = () => {
        handleClickOpen1();
        setOpen1(false);
        setOpenColorPicker(null)
    };

    const handleCloseUploadLogo = (value) => {
        setUploadLogo(false);
    }

    const handleLogoFlag = (flag) => {
        logoFlag = flag;
    }

    //pid varibles and function starts

    //Busines name variable and functions start
    const [business, setBusiness] = useState();
    
    //Busines name variable and functions end
    //formik validationschema start


    //phone number variables and functions end


    //fetch Company AID list start

    const [aidlist1, setAidList1] = useState([]);
    const getAidlist = async (companyid) => {

        const apiUrl = '/fetchCompanyAIDList/' + companyid;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            const uniqueData = Array.from(new Set(res.data.map(item => item.id))).map(
                id => res.data.find(item => item.id === id)
            );
            setAidList1(uniqueData);
            setFirstAid(res.data ? res.data[0].id : '');
        }
    }

    //fetch Company AID list end



    //formik validationschema start

    const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

    const validationSchema = Yup.object().shape({
        phoneNumbers: Yup.array().of(
            Yup.object().shape({
                number: Yup.string()
                    .matches(phoneRegExp, 'Phone number must match the pattern (123) 456-7890')
                    // .min(14, 'Must be 10 digits...')
                    // .max(14, 'Must be 10 digits.')
                    .required('Phone number is required'),
            })
        ),
        PID1: Yup.string()
            .required('Please select PID.'),
        AID: Yup.string()

            .required('Please select AID.'),
        BusinessName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Business name is required.'),
    });

    //formik validation schemaend
    const [defaultshiftTimings, setdefaultShiftTimings] = useState([{ label: "Monday-Friday(Weekdays)", timings: [{ start: "09:00", end: "05:00" }] },
    { label: "Saturday-Sunday(Weekends)", timings: [{ start: "00:00", end: "00:00" }] },
    ]);


    //Update information code

    const CreateSnapInfo = async (values) => {
        if (blankValueIndexes1.length > 0) {
            swal({
                title: "Alert",
                text: "Some fields are missing in weekdays (Monday - Friday) shift either delete the shift or add its valus!",
                icon: "error",
                button: "Ok",
            })
        } else if (blankValueIndexes2.length > 0) {
            swal({
                title: "Alert",
                text: "Some fields are missing in weekends (Saturday - Sunday) shift either delete the shift or add its valus!",
                icon: "error",
                button: "Ok",
            })
        } else {
            console.log(values.phoneNumbers)
            const numArray = [];
            let errorFlag = 0;
            const numbers = values.phoneNumbers;
            if (Object.values(numbers).length > 0) {
                numbers.map((row, index) => {
                    if (numArray.includes(row.number.trim())) {
                        alert(row.number.trim() + ' is duplicate, Please check.')
                        errorFlag = 1;
                        return false;
                    } else {
                        numArray.push(row.number.trim());
                    }
                })
            }
            if (errorFlag === 1) {
                return false;
            } else {

                operationalhoursvalues = operationalhoursvalues.length == 0 ? receivedshift : operationalhoursvalues;
                const apiUrl = '/updateSnapshotDetails';
                const args = {
                    phone: values.phoneNumbers,
                    business: values.BusinessName,
                    pid: values.PID1,
                    aidid: values.AID,
                    operationalhours: operationalhoursvalues,
                }
                const res = await dispatch(postData({ apiUrl, args })).unwrap();
                if (res) {
                    if (res.success === true) {
                        fetchCreditInfo();
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        })
                    } else {
                        swal({
                            title: "Alert",
                            text: "Something went wrong please try again",
                            icon: "error",
                            button: "Ok",
                        })
                    }
                }
            }
        }
    }


    const CreateSnapInfoOld = async (values) => {
        if (operationalhoursvalues.length == 0) {
            swal({
                title: "It seems that you have not customized your operational hours!",
                text: "Do you want to go with default operational hours (Monday - Friday : 9 AM to 5 PM)?",
                icon: "info",
                buttons: [
                    'Continue with default!',
                    'Update It!'
                ],

                dangerMode: true,
            }).then(async function (isConfirm) {
                if (!isConfirm) {
                    operationalhoursvalues = receivedshift;
                    const apiUrl = '/updateSnapshotDetails';
                    const args = {
                        phone: values.phoneNumbers,
                        business: values.BusinessName,
                        pid: values.PID1,
                        aidid: values.AID,
                        operationalhours: operationalhoursvalues,
                    }
                    const res = await dispatch(postData({ apiUrl, args })).unwrap();
                    if (res) {
                        if (res.success === true) {
                            fetchCreditInfo();
                            swal({
                                title: "Success",
                                text: res.message,
                                icon: "success",
                                button: "Ok",
                            })
                        } else {
                            swal({
                                title: "Alert",
                                text: "Something went wrong please try again",
                                icon: "error",
                                button: "Ok",
                            })
                        }
                    }
                }
            })
        } else {
            if (blankValueIndexes1.length > 0) {

                swal({
                    title: "Alert",
                    text: "Some fields are missing in weekdays (Monday - Friday) shift either delete the shift or add its valus!",
                    icon: "error",
                    button: "Ok",
                })
            } else if (blankValueIndexes2.length > 0) {

                swal({
                    title: "Alert",
                    text: "Some fields are missing in weekends (Saturday - Sunday) shift either delete the shift or add its valus!",
                    icon: "error",
                    button: "Ok",
                })
            } else {

                const apiUrl = '/updateSnapshotDetails';
                const args = {
                    phone: values.phoneNumbers,
                    business: values.BusinessName,
                    pid: values.PID1,
                    aidid: values.AID,
                    operationalhours: operationalhoursvalues,
                }
                const res = await dispatch(postData({ apiUrl, args })).unwrap();
                if (res) {
                    if (res.success === true) {
                        fetchCreditInfo();
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        })

                    } else {
                        swal({
                            title: "Alert",
                            text: "Something went wrong please try again",
                            icon: "error",
                            button: "Ok",
                        })
                    }
                }
            }
        }
    }

    const handleChildValue = (value1) => {
        blankValueIndexes1 = []
        blankValueIndexes2 = []
        operationalhoursvalues = value1
        if(value1.length == 2) {
            value1[0]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes1.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes1.push(index);
                }
            });
            value1[1]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes2.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes2.push(index);
                }
            });
        }else{
            value1[0]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes1.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes1.push(index);
                }
            });
        }
        
    };

    //useeffect end


    const fetchCreditInfo = async () => {
        const apiUrl = '/fetchCreditInfo';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setFirstLogoUrl(res.data.company.company_logo_url);
            setCreditInfo(res);
            setTermsData(res.data);
            setPids(res.data.pid);
            setBusiness(res.data.company.company_name)
            setCompanyId(res.data.company.id)
            setdefaultPhoneNumber(res.data.reference_affiliates.phone_no);
            // setReceivedPhone(res.data.snapshot_phone_no);
            setReceivedPid(res.data.upgrade_pid);
            setReceivedAid(res.data.aidid);
            setSnapshotToken(res.data.snapshot_token);
            setSnapshotStr(`<script>window.user_token = '` + res.data.snapshot_token + `';var scriptTag  = document.createElement('script');
            scriptTag.type = 'text/javascript';scriptTag.src = 'https://api.myfreescorenow.com/public/js/snapshot.js';
            var s= document.getElementsByTagName('script')[0];s.parentNode.insertBefore(scriptTag,s);</script>`)

            setLink(config.BASE_URL + res.data.link);

            await dispatch(snapshot(res.data));
            localStorage.removeItem('SnapShotStatus');
            localStorage.setItem('SnapShotStatus', res.data.snapshot_status);

            getAidlist(res.data.company.id);

            if (res.data.working_hours != null) {
                setReceivedShift(res.data.working_hours);
            }
            getPopupHtml(res.data.company.id);
            if (res.data) {
                const attr = JSON.parse(res.data.snapshot_popup_attribute_json);
                customColorArray = {
                    popupHeaderBackground: attr ? attr.popupHeaderBackground : '#00BBD3',
                    popupHeaderText: attr ? attr.popupHeaderText : '#000000',
                    popupBodyBackground: attr ? attr.popupBodyBackground : '#ffffff',
                    popupBodyText: attr ? attr.popupBodyText : '#000000',
                    popupButtonBackground: attr ? attr.popupButtonBackground : '#FF9400',
                    popupButtonText: attr ? attr.popupButtonText : '#FFFFFF',
                    popupIconColor: attr ? attr.popupIconColor : '#ff9400'
                }
            }
            const phoneNumberArray = res.data.snapshot_phone_no;
            if (Object.values(phoneNumberArray).length > 0) {
                const formattedData = phoneNumberArray.map(item => ({
                    ...item,
                    "number": formatPhoneNumber(item.number)
                }));
                setReceivedPhone(formattedData);
            }
           
        }
    }

    //fetch snapshot details end
    //useeffect start
    useEffect(() => {
        fetchCreditInfo();
    }, [])

    const getPopupHtml = async (id) => {
        const apiUrl = '/getPopupHtml/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success) {
                setPopupHtml(res.data);
            }
        }
    }

    const handleValue = (colorArray) => {
        customColorArray = colorArray;
    }

    const savePopupAttribute = async () => {
        setOpenColorPicker(null)
        setOpen1(false);
        handleFreezStart();
        const apiUrl = "/saveCustomPopupAttr";
        const args = {
            attrColorArray: customColorArray,
            companyId: companyId
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            handleFreezStop();
            if (res.success) {
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                }).then((ok) => {
                    fetchCreditInfo();
                });
            } else {
                swal({
                    title: "Alert",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
            }
        }
    }


    const  handleResetColors=()=>{
        setOpen1(false);
        setOpenColorPicker(null)
        setTimeout(() => {
            handleClickOpen1();
            setOpen1(true);
        }, 600);

}

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-digits
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return original input if it doesn't match the expected format
    }

    	//pdf start here
    const handleDownloadPDF = async (data) => {
        // console.log(data);
    const firstName = data ? data.reference_affiliates.first_name ? data.reference_affiliates.first_name:"" : "";
    const lastName = data ? data.reference_affiliates.last_name ? data.reference_affiliates.last_name:"" : "";
    const fullName= firstName + " " +lastName;
    const companyName = data ? data.company.company_name ? data.company.company_name :"" : "";
    const title =  data ? data.company.title ? data.company.title :"" : "";
    const updateDate = data ? data.updated_at ? data.updated_at :"" : "";
    const sign = data ? data.sign_image : "" ;
    const executiveSign = data ? data.executive : "" ;
    const dateFormat = new Date(updateDate);
    const options = { timeZone: 'America/Los_Angeles', month: '2-digit', day: '2-digit', year: 'numeric' };
    const formatter = new Intl.DateTimeFormat('en-US', options);
        const [
          { value: month },,
          { value: day },,
          { value: year }
        ] = formatter.formatToParts(dateFormat);
    
    const formattedDate = `${month}/${day}/${year}`;
    const advertiser = {
        adverstise: companyName,
        signature: fullName,
        printName: fullName,
        title: title,
        date: formattedDate
    };

    const sample =` <div style="font-size:16px;padding: 5px 0px;width:750px;text-align:justify;"> 
    <p>
    <span style="font-size: 18px; text-align: center;"><b>Standard Terms and Conditions for Lead Generation Service</b></span>
    </p>
    <br><br>
        ${exampleHTML(advertiser)}
        <br>
        <div class="forMD-12" style="display: flex; flex-wrap: wrap; margin-top: 2px;">
        <div class="forMD-6" style="flex: 1; min-width: 65%;">
            <p style="margin: 0; padding: 2px;"><strong>Advertiser: ${companyName}</strong></p>
            <p style="margin: 0; padding: 2px; display: flex; align-items: center;">
                <strong>Signature:</strong>
                ${sign ? 
                    `<img src="${sign}" alt="Signature" style="width: 40%; height: auto;" />` 
                    : 
                    `<span style="margin-left: 10px;">${fullName}</span>`
                }
            </p>
            <p style="margin: 0; padding: 2px;"><strong>Print Name: ${fullName}</strong></p>
            <p style="margin: 0; padding: 2px;"><strong>Title: ${title}</strong></p>
            <p style="margin: 0; padding: 2px;"><strong>Date: ${formattedDate}</strong></p>
        </div>
        <div class="forMD-6" style="flex: 1; min-width: 35%;">
            <p style="margin: 0; padding: 2px;"><strong>Vendor: MyFreeScoreNow Inc.</strong></p>
            <p style="margin: 0; padding: 2px; display: flex; align-items: center;">
                <strong>Signature:</strong>
                <img src="${executiveSign}" alt="Executive Signature" style="width: 45%; height: auto; margin-left: 10px;" />
            </p>
            <p style="margin: 0; padding: 2px;"><strong>Print Name: Bruce J. Cornelius</strong></p>
            <p style="margin: 0; padding: 2px;"><strong>Title: President</strong></p>
            <p style="margin: 0; padding: 2px;"><strong>Date: ${formattedDate}</strong></p>
        </div>
      </div>
      
    </div>`
    var pdf = new jsPDF("p", "px", "a4");
		const paddingTop = 20;
    const paddingBottom = 30;
    pdf.html(sample, {
			margin: [ 0,10,30,10],
      callback: function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages();
				const totalPadding = paddingTop + paddingBottom;				
				for (let i = 0; i < totalPages; i++) {
					pdf.setPage(i + 1);
					
					pdf.text(
						`Page ${i + 1} of ${totalPages}`,
						pdf.internal.pageSize.width / 2,
						pdf.internal.pageSize.height - 10,
						{ align: 'center',fontSize: '12px' }
					);
					pdf.internal.pageSize.height += totalPadding; // Adjust the page height
				}
        pdf.save('Agreement_Copy.pdf');
      },
      x: 30,
      y: 30,
      autoPaging: 'text',
      html2canvas: { scale: 0.50 },
			width: pdf.internal.pageSize.getWidth() - 20,
    });
  };
  //pdf end here


    function ContentLayout() {
      return(
        <>
         <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"space-between"}} >
         <Grid item lg={6} md={6} sm={6} xs={6} >
            <Typography level="h4" color="primary">
                Get Started Now In Just 3 Easy Steps:
            </Typography>
          </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} sx={{textAlign:"center"}}>
            {user.roles_id == 3 ? 
             <Button variant="contained" color="success" sx={{ borderRadius: "0% !important", backgroundColor: "#76C044", color: "white"}} onClick={() => handleDownloadPDF(terms)}
             >DOWNLOAD AGREEMENT COPY</Button>
            :''
            }
          </Grid>
        </Grid> 

        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>

            <Card className='cardstyle'>
                <CardOverflow
                    sx={{
                        bgcolor: '#F6FAFF',
                        borderColor: '#F6FAFF',
                        padding: '1%',
                        mb: 0.5
                    }}
                >
                    <Typography level="h5" >Step 1. Enter Company Details</Typography>
                </CardOverflow>
                {/* formik start */}
                <Formik
                    initialValues={{
                        phoneNumbers: receivedphone ? receivedphone : [{ number: formatPhoneNumber(defaultPhoneNumber), notifications: true }],
                        PID1: receivedpid ? receivedpid : "",
                        BusinessName: business,
                        AID: receivedaid ? receivedaid : firstAid,
                    }}

                    validationSchema={validationSchema}
                    onSubmit={(values, errors) => {
                        CreateSnapInfo(values);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                        <div>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} >
                                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                                        <FieldArray
                                            name="phoneNumbers"
                                            render={({ push, remove }) => (
                                                <div>
                                                    {values.phoneNumbers.map((phoneNumber, index) => (
                                                        <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                                                            <FormControl fullWidth margin="dense" size="small" >
                                                                {/* <InputLabel htmlFor="outlined-adornment-password">Phone Number</InputLabel> */}
                                                                <InputMask
                                                                    mask="(999) 999-9999"
                                                                    maskChar={null}
                                                                    disabled={user.roles_id > 3 ? true : false}

                                                                    // label={`Phone Number`}
                                                                    // name={`phoneNumbers[${index}].number`}
                                                                    value={formatPhoneNumber(phoneNumber.number)}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}

                                                                    error={
                                                                        Boolean(phoneNumber.number) &&
                                                                        Boolean(errors.phoneNumbers) &&
                                                                        Boolean(errors.phoneNumbers[index]) &&
                                                                        Boolean(errors.phoneNumbers[index].number)
                                                                    }
                                                                    helperText={
                                                                        errors.phoneNumbers &&
                                                                        errors.phoneNumbers[index] &&
                                                                        errors.phoneNumbers[index].number
                                                                    }

                                                                >
                                                                    {() => <TextField
                                                                        size="small"
                                                                        margin="dense"
                                                                        disabled={user.roles_id > 3 ? true : false}
                                                                        label={`Phone Number`}
                                                                        name={`phoneNumbers[${index}].number`}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    {phoneNumber.notifications ? (
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                            onClick={() =>
                                                                                                setFieldValue(
                                                                                                    `phoneNumbers[${index}].notifications`,
                                                                                                    index === 0 ? true : !values.phoneNumbers[index].notifications
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <NotificationsIcon fontSize="small" style={{ color: "#76C044" }} />
                                                                                        </IconButton>
                                                                                    ) : (
                                                                                        <IconButton
                                                                                            color="secondary"
                                                                                            onClick={() =>
                                                                                                setFieldValue(
                                                                                                    `phoneNumbers[${index}].notifications`,
                                                                                                    !values.phoneNumbers[index].notifications
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <NotificationsOffIcon fontSize="small" style={{ color: "red" }} />
                                                                                        </IconButton>
                                                                                    )}
                                                                                    {index > 0 && (
                                                                                        <IconButton
                                                                                            color="secondary"
                                                                                            onClick={() => remove(index)}
                                                                                        >
                                                                                            {user.roles_id <= 3 ?
                                                                                                <DeleteOutline
                                                                                                    fontSize="small"

                                                                                                    style={{ color: "grey" }}
                                                                                                />
                                                                                                : null}
                                                                                        </IconButton>
                                                                                    )}
                                                                                    {user.roles_id <= 3 ?
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                            onClick={() => {
                                                                                                push({ number: '', notifications: false })
                                                                                            }}
                                                                                        >
                                                                                            <AddCircleOutlineIcon
                                                                                                fontSize="small"
                                                                                            />
                                                                                        </IconButton>
                                                                                        : null}
                                                                                </InputAdornment>
                                                                            )
                                                                        }}

                                                                    />}
                                                                </InputMask>
                
                                                                {errors.phoneNumbers &&
                                                                    errors.phoneNumbers[index] &&
                                                                    errors.phoneNumbers[index].number && (
                                                                        <FormHelperText error>
                                                                            {errors.phoneNumbers[index].number}
                                                                        </FormHelperText>
                                                                    )}
                                                            </FormControl>
                                                        </Grid>

                                                    ))}
                                                </div>
                                            )}
                                        />
                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                            <FormControl size="small" fullWidth margin="dense" error={touched.PID1 && Boolean(errors.PID1)}>
                                                <CustomInputLabel id="demo-simple-select-label">Paid plan for upgrade:</CustomInputLabel>
                                                <CustomSelect
                                                    labelId="demo-simple-select-label"
                                                    id="PID1"
                                                    name="PID1"
                                                    value={values.PID1}
                                                    label="Paid plan for upgrade:"
                                                    onChange={handleChange}
                                                    onBlur={handleChange}
                                                    style={{ width: '100%' }}
                                                    className="textWrap"
                                                    MenuProps={MenuProps}
                                                >
                                                    {pids.map((pid) => (
                                                        <MenuItem key={pid} value={pid}>
                                                            <Chip label={pid} style={{ margin: 2 }} size="small" />
                                                        </MenuItem>
                                                    ))}
                                                </CustomSelect>
                                                {touched.PID1 && errors.PID1 ? <div className='error'>{errors.PID1}</div> : null}
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                                            <FormControl fullWidth size='small'  error={touched.AID && Boolean(errors.AID)}>
                                                <CustomInputLabel id="demo-multiple-name-label">AID List</CustomInputLabel>
                                                <CustomSelect
                                                    labelId="demo-multiple-name-label"
                                                    name='AID'
                                                    id='AID'
                                                    input={<OutlinedInput label="AID List" />}
                                                    onChange={handleChange}
                                                    onBlur={handleChange}
                                                    style={{ width: '100%' }}
                                                    MenuProps={MenuProps}
                                                    value={values.AID}
                                                >

                                                    {aidlist1.map((aid) => (
                                                        <MenuItem key={aid.id} value={aid.id}>
                                                            <Chip label={aid.aid} style={{ margin: 2 }} size="small" />
                                                        </MenuItem>
                                                    ))}
                                                   
                                                </CustomSelect>
                                                {touched.AID && errors.AID ? <div className='error'>{errors.AID}</div> : null}
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                            <TextField
                                                label="Business Display Name:"
                                                variant="outlined"
                                                id="BusinessName"
                                                name="BusinessName"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                disabled
                                                value={values.BusinessName}
                                                onChange={handleChange}
                                                onBlur={handleChange}
                                            />
                                            {touched.BusinessName && errors.BusinessName ? <div className='error'>{errors.BusinessName}</div> : null}
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <p style={{fontSize:"16px"}}><i><b>(Note* : Don't Forget To Save Your Changes)</b></i> </p>
                                    </Grid>
                                    </Grid>
                                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                            <Typography level="h4" sx={{ fontWeight: "bold" }}>Hours of Operation : (Include Days of Operation) *</Typography>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                            <OperationalHours id="OperationalHours1" name="OperationalHours1" onValueChange={handleChildValue} props={receivedshift} />
                                        </Grid>

                                    </Grid>
                                    
                                    {user.roles_id <= 3 ?
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                            <Button color="success" sx={{ borderRadius: "0% !important", backgroundColor: "#76C044" }} onClick={handleSubmit}>
                                                SAVE CHANGES
                                            </Button>
                                        </Grid>
                                        : null}
                                </Grid>
                            </CardContent>
                        </div>
                    )}
                </Formik>
                {/* formik end */}
            </Card>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} spacing={2} >
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <Card className='cardstyle' sx={{ minHeight: 350, maxHeight: 200 }}>
                    <CardOverflow
                        sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                            padding: '1%',
                            mb: 0.5,
                        }}
                    >
                        <Typography level="h5" >Step 2. Upload Logo Or Choose The Default</Typography>
                    </CardOverflow>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box >
                                <Card variant='outlined' size='md' >
                                    <CardContent sx={{ textAlign: 'center', justifyContent: 'center' }}>
                                        {
                                            creditinfo
                                                ?
                                                firstlogoUrl
                                                    ?
                                                    <img className="dashlogo" alt="#" style={{ height: '90px' }} src={config.BACKEND_URL + "images/companyLogo/" + firstlogoUrl} />
                                                    :
                                                    <img className="dashlogo" alt="#" style={{ height: '90px' }} src={require("../../../assets/images/uploadLogo.png")} />
                                                :
                                                <img className="dashlogo" alt="#" style={{ height: '90px' }} src={require("../../../assets/images/uploadLogo.png")} />
                                        }
                                    </CardContent>
                                </Card>
                                <Box py={2} sx={{ textAlign: 'center' }}>
                                    {user.roles_id <= 3 ?
                                        <Button size="sm" color="success" sx={{
                                            minWidth: 150, bgcolor: '#76C044',
                                            borderColor: '#76C044', borderRadius: "2px"
                                        }} onClick={(e) => { setUploadLogo(true) }}>{firstlogoUrl ? "Edit" : "Upload"}</Button>
                                        : null}
                                    {!firstlogoUrl ? <p style={{ fontSize: '20px' }} className="error">Upload logo to complete step-2</p> : null}
                                </Box>
                            </Box>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid container item xl={6} lg={6} md={12} sm={12} xs={12} >
                {firstlogoUrl && receivedpid ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card className='cardstyle' sx={{ minHeight: 350, maxHeight: 200 }}>
                            <CardOverflow
                                sx={{
                                    bgcolor: '#F6FAFF',
                                    borderColor: '#F6FAFF',
                                    padding: '1%',
                                }}>
                                <Typography level="h5">Step 3. Copy Your Link To Post On Social Media Or To Email</Typography>
                            </CardOverflow>
                            <CardContent>

                            <Grid  container item lg={12} md={12} sm={12} xs={12} mt={2} spacing={2} sx={{ background: 'lightgray',display: 'flex' }}>
                                    <Grid item lg={9} md={9} sm={9} xs={9} className="link-word-wrap">
                                        {link}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: "right" }} pr={2}  >

                                       
                                         <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                navigator.clipboard.writeText(link)
                                                setCopySuccess(link)
                                                setTimeout(() => {
                                                    setCopySuccess('');
                                                }, 3000);
                                            }}
                                            disabled={copySuccess === link}
                                            sx={{
                                            opacity: copySuccess === link ? 0.0 : 1,
                                            }}
                                        >
                                            Copy
                                        </Button>
                                        {copySuccess == link ? "Copied!" : ''}      

                                    </Grid>
                        </Grid>



                               
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Typography level="h5" mt={3}>Share Now:</Typography>
                                        <span>
                                            {link ? <ShareIcons url={link} /> : ""}
                                        </span>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    : null}
            </Grid>
        </Grid>
        {firstlogoUrl && receivedpid ?
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Card className='cardstyle' sx={{ minHeight: 600, maxHeight: 350 }}>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                                mb: 0.5,
                            }}>
                            <Typography level="h5">Set Up Your Pop-up For Your Website</Typography>
                        </CardOverflow>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: 'center', justifyContent: 'center' }}>
                            <Grid item onClick={handleClickOpen2}>
                                <PopupPreview customColorArray={customColorArray} creditinfo={creditinfo} firstlogoUrl={firstlogoUrl} previewPopup={false} />
                            </Grid>
                        </Grid>
                       
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} spacing={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                {user.roles_id <= 3 ?
                                    <Button sx={{
                                        minWidth: 200, bgcolor: '#2A68B3',
                                        borderColor: '#2A68B3', borderRadius: "2px"
                                    }}
                                        onClick={handleClickOpen1}
                                    >
                                        Customize Pop-up
                                    </Button>
                                    : null}
                            </Grid>
                            <Grid item xs sx={{ textAlign: "right" }}>

                                <Button color="success" sx={{ borderRadius: "0% !important", backgroundColor: "#76C044", minWidth: 200, }}
                                    onClick={handleClickOpen2}
                                >
                                    Show Preview
                                </Button>

                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Card className='cardstyle' sx={{ minHeight: 600, maxHeight: 350 }}>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                                mb: 0.5,
                            }}
                        >
                            <Typography level="h5">Your snippet code to add pop-up to your site</Typography>
                        </CardOverflow>
                        <CardContent >
                            <p className="rectangle1" style={{wordBreak: "break-all"}}>
                               
                                {snapshotStr}
                            </p>
                            {user.roles_id >= 3 && user.roles_id <= 5 ?
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }} mt={2}>
                                    <Button color="success" sx={{ borderRadius: "0% !important", backgroundColor: "#76C044", minWidth: 150, }} onClick={() => {
                                        navigator.clipboard.writeText(snapshotStr)
                                        setSnippetCopy(snapshotStr)
                                        setTimeout(() => {
                                            setSnippetCopy('Copy');
                                        }, 3000);
                                    }} >{snippetCopy == snapshotStr ? "Copied!" : 'Copy'}
                                    </Button>
                                </Grid>
                                : null}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            : null}
        <Dialog
            open={uploadLogo}
            onClose={handleCloseUploadLogo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {/* <LogoEditor aidMasterId={id} pathType="cobrand" /> */}
            <LogoEdit id={companyId} logoEditURL={handleLogoURL} logoFlagEdit={handleLogoFlag} setOpen={handleCloseUploadLogo} source="company" />
        </Dialog>
        {/* customized dialog start here */}
      
        {/* show preview dialog start here */}
        <Dialog
            fullWidth={fullWidth}
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <PopupPreview customColorArray={customColorArray} creditinfo={creditinfo} firstlogoUrl={firstlogoUrl} handleClose={handleClose2}
                handleOpen={handlePopupPreview}
                previewPopup={true} link={link} />
           
        </Dialog>
    </>
      )
            }

    return (
        <div>
              <Dialog
            fullWidth={fullWidth}
            maxWidth={'md'}
            open={open1}
            onClose={handleClose1}
        >
            <DialogTitle>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Typography level="h4">Set Up Your Pop-up For Your Website</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose1}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} >
                    <Grid item lg={7} md={7} sm={7} xs={7} >
                        <Card sx={{ padding: 0 }} variant="outlined" >
                            <CardOverflow
                                sx={{
                                    bgcolor: `${customColorArray.popupHeaderBackground}`,
                                    borderColor: `${customColorArray.popupHeaderBackground}`,
                                    p: 3,
                                    margin: 0
                                }}
                                id="popupHeaderBackground"
                            >
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center", textAlign: 'center' }} >
                                        <p style={{ margin: 0, color: `${customColorArray.popupHeaderText}`, fontSize: "1.5em",fontWeight:'bold' }} id="popupHeaderText">
                                            You can improve your credit score
                                            <br />
                                            {
                                                creditinfo
                                                    ?
                                                    firstlogoUrl
                                                        ?
                                                        <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={config.BACKEND_URL + "images/companyLogo/" + firstlogoUrl} />
                                                        :
                                                        <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={require("../../../assets/images/uploadLogo.png")} />
                                                    :
                                                    <img alt="#" className="dashlogo" height="65" style={{ padding: '1%' }} src={require("../../../assets/images/uploadLogo.png")} />
                                            }
                                            <br />
                                            <span style={{ fontWeight: "bold", fontSize: "1em", }}>Check Your Credit For FREE</span>
                                        </p>
                                    </Grid>
                                </Grid>
                            </CardOverflow>
                            <CardContent sx={{ p: 4, borderRadius: '0px 0px 10px 10px', background: `${customColorArray.popupBodyBackground}` }} id="popupBodyBackground">
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    aria-label="contacts"
                                >
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get your FREE Credit score</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get your FREE Credit report summary</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>Get a credit consultation</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                            <TaskAltIcon className="popupIconColor" sx={{ color: `${customColorArray.popupIconColor}` }} />
                                        </ListItemDecorator>
                                        <ListItemContent sx={{ mr: 0.5, }}><Typography level="h6" >
                                            <span className="popupBodyText" style={{ color: `${customColorArray.popupBodyText}` }}>No credit card required</span>
                                        </Typography>
                                        </ListItemContent>
                                    </ListItem>
                                </List>

                                <Grid container item xs mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button color="warning" id="popupButton"
                                        sx={{
                                            backgroundColor: `${customColorArray.popupButtonBackground}`,
                                            borderRadius: "3px",
                                            color: `${customColorArray.popupButtonText}`,
                                            minWidth: 200,
                                            '&:hover': {
                                                backgroundColor: `${customColorArray.popupButtonBackground}`
                                            }
                                        }}
                                        size="lg">GET STARTED</Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-center" }}>
                            <ContentElement title="Header Background" blockId="popupHeaderBackground" blockName="popupHeaderBackground" colorType="background"
                                value={customColorArray.popupHeaderBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}
                            />
                            <ContentElement title="Header Text" blockId="popupHeaderText" blockName="popupHeaderText" colorType="text"
                                value={customColorArray.popupHeaderText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker} />
                            <ContentElement title="Body Background" blockId="popupBodyBackground" blockName="popupBodyBackground" colorType="background"
                                value={customColorArray.popupBodyBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue} 
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker} />
                            <ContentElement title="Body Text" blockId="popupBodyText" blockName="popupBodyText" colorType="bodyText"
                                value={customColorArray.popupBodyText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Button Background" blockId="popupButton" blockName="popupButtonBackground" colorType="background"
                                value={customColorArray.popupButtonBackground}
                                customColorArray={customColorArray}
                                handleValue={handleValue} 
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Button Text" blockId="popupButton" blockName="popupButtonText" colorType="text"
                                value={customColorArray.popupButtonText}
                                customColorArray={customColorArray}
                                handleValue={handleValue}  
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                            <ContentElement title="Highlight Colour" blockId="popupIconColor" blockName="popupIconColor" colorType="icon"
                                value={customColorArray.popupIconColor}
                                customColorArray={customColorArray}
                                handleValue={handleValue}
                                openColorPicker={openColorPicker}
                                toggleColorPicker={toggleColorPicker}/>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Button color="primary" sx={{ borderRadius: "0% !important", minWidth: 250, backgroundColor: "#2A68B3", color: "white", 
                      }}
                            onClick={handleResetColors} 
                        >Reset</Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                        <Button color="success"
                            sx={{ borderRadius: "0% !important", minWidth: 250, backgroundColor: "#76C044", color: "white" }}
                            onClick={savePopupAttribute}
                        >Save</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
            <DefaultLayout  content={<ContentLayout/>} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default CreditSnapFirst;