import React from 'react';
import { Grid} from '@mui/material';
import { Typography } from '@mui/joy';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import LockIcon from '@mui/icons-material/Lock';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
function SecureWebsite(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "right", display: "flex" }}>
                {/* <img src={require("../../../assets/images/secure.png")} /> */}
                <span style={{color:props.textColor?`${props.textColor}`:'black'}}><IconButton><LockIcon fontSize="small" style={{color:props.textColor?`${props.textColor}`:'black'}}/></IconButton>We are a secure website. <a style={{ color: props.hyperlinkColor, cursor: "pointer" }} onClick={handleClickOpen}><u>Learn More{">>"}</u></a></span>
            </Grid>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} sx={{ backgroundColor: "#F6FAFF" }}>
                    <Typography level="h3" sx={{ fontWeight: 300 }}> We are a secure website</Typography>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                        <Typography level="h5" color="primary" sx={{ fontWeight: 800 }}><u>We Take Online Security Seriously!</u></Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} sx={{ justifyContent: "center" }}>
                        <Typography level="h2" fontSize="xl">Your Data Encrypted</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <Typography level="body1">The information you provide to us is always encrypted, both in transit and when it is stored! We use the industry's strongest levels of encryption, so your data is always secure.
                        </Typography>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </>

    );
}

export default SecureWebsite;