import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import MaterialReactTable from 'material-react-table';
import { Box, Button, Tooltip,  IconButton,Grid,Stack,Typography,Switch } from "@mui/material";
import Moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import formatPhoneNumber from '../../../../Components/Formats/PhoneNumberValidation';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { fetchData, postData } from '../../../../redux/apiSlice';
import UserForm from "../../CommonComponent/User/UserForm";
import swal from "sweetalert";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';

const CompanyUsersTab = ({fetchCompanyDetails,company_details,aidusers,user,checkUserRole,userTypeList,aidNames,company_id,handleFreezStart,handleFreezStop}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [open, setOpen] = useState(false);
    const [openDeactive, setOpenDeactive] = useState(false);
    const [userData, setUserData] = useState(null);
    const [mode, setMode] = useState("create");
    const [operation, setOperation] = useState("");
    const [userId, setUserID]=useState();
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [companyName, setCompanyName]=useState(company_details.company_name);
    const [flag, setFlag] = useState(true);
    const [APIAccessOpen, setAPIAccess] = useState(false);
    const [UserId, setUserId] = useState();
    const [APIAccessFlag, setAPIAccessFlag] = useState('');
    const [Status, setStatus] = useState();
    const loginUser = JSON.parse(localStorage.getItem('user'));
    const [disableUserId, setDisableUserId] = useState();
    const [showActiveOnly, setShowActiveOnly] = useState(false);

    // Filter users based on switch toggle
    const filteredUsers = showActiveOnly
    ? aidusers.filter((user) => user.is_active === 1)
    : aidusers;

    const handleOpenCreate = () => {
        setUserData({
            companyId: company_id,
            companyName: companyName,
            userType: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            userAidList: [],
            timezone: company_details.reference_affiliates.timezone,
            password: "",
        });
        setMode("create"); 
        setOpen(true);
    };

    const checkUsaTLD =async (email) => {
        if (email.includes('@')) {
            try {
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if (res && res.data && typeof res.data.success === 'boolean') {
                setCheckUsaTldStatus(res.data.success);
            } else {
                // Handle unexpected response structure
                console.warn("Unexpected response structure:", res);
                setCheckUsaTldStatus(false);
            }
        } catch (error) {
                // Handle errors from the API call
                console.error("Error checking USA TLD:", error);
                setCheckUsaTldStatus(false);
            }
        }
    }

    const checkAdmin = () => {
        var check = 1;
        if (loginUser.roles_id != 1 && loginUser.roles_id != 2 && loginUser.roles_id != 3 && loginUser.roles_id != 9) {
            check = 0;
        } 
        if(check == 0) {
            navigate('/login');
            return false;
        }
        return true;
    }
    
    const handleEditClick =async (Id) => {
        if (!checkUserRole()) return;
        try {
            const apiUrl = `/fetchUserDetails/${Id}`;
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if (res?.success === true && res.details) {
                setUserID(Id);
                setMode("edit");
                setUserData(res.details);
                setOpen(true);
            } else {
                alert("User not found");
            }
        } catch (error) {
            alert("Failed to fetch user details. Please try again.");
        }
    };
    const handleClose = () => setOpen(false);

    const changeUserStatus = async (Id,role,operation) => {
        console.log("operation," + operation);
        if (!checkUserRole()) return;
        try 
        {
            const apiUrl = '/check-company-affiliates';
            const args = {
                id: Id,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                var Msg ="";
                if (res.success == true) {
                    var count = res.data.activeAffiliate;
                    if (count <= 1) {
                    if(res.data.company && res.data.company != ""){
                        if(res.data.company.is_disabled == 1) {
                        Msg = "To activate this user, please activate it's company first!";
                        }
                        else if (role == 3 && operation == "disable") {
                        Msg = "You need to have atleast one active affiliate for a company";
                        }
                        else if (role == 7 && operation == "disable") {
                        Msg = "You need to have atleast one active partner admin for a software type";
                        }
                    }
                    if(Msg != "") {
                        swal({
                        title: "Failed",
                        text: Msg,
                        icon: "error",
                        button: "Ok",
                        })
                        return false;
                    }
                    
                    }
                }
            }
            if (operation == "disable") {
                setOperation("disabled");
            } else {
                setOperation("active");
            }
            setDisableUserId(Id);
            setOpenDeactive(true);
        } catch (error) {
            alert("Failed to fetch user details. Please try again.");
        }
    }
    const handleCloseDeactive = () => setOpenDeactive(false);

    
    const handleSubmit = async (values) => {
        if (mode === "edit") {
            var check = checkAdmin();
            const loginUser = JSON.parse(localStorage.getItem('user'));
            if(loginUser.roles_id <= 3 ) {
                if(loginUser.roles_id == 3 && loginUser.company_master_id != userData.company_master_id) {
                    swal({
                        title: "Failed",
                        text: "This action is unauthorized.",
                        icon: "error",
                        button: "Ok",
                    })
                    navigate('/login');
                    return false;
                }
            }
            if(check === true) {
                handleFreezStart();
                const apiUrl = '/updateUser';
                const args = {
                    id: userId,
                    first_name: values.firstName,
                    last_name: values.lastName,
                    role_id: values.userType,
                    email: values.email,
                    phone_no: formatPhoneNumber(values.phone),
                    password: values.password,
                    aids: values.userAidList,
                    defaultTimezone: values.timezone,
                }
                const res = await dispatch(postData({apiUrl,args})).unwrap();
                if(res)
                {
                    if (res.success === true) {
                        handleFreezStop();
                        handleClose();
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        });
                        fetchCompanyDetails();
                    } else {
                        handleFreezStop();
                        handleClose();
                        swal({
                            title: "Failed",
                            text: res.message,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                }
            }
        } else {
            var check = checkAdmin();
            if(check === true) {
                const loginUser = JSON.parse(localStorage.getItem('user'));
                if(loginUser.roles_id <= 3 ) {
                    if(loginUser.roles_id == 3 && loginUser.company_master_id != values.companyID) {
                        swal({
                            title: "Failed",
                            text: "This action is unauthorized.",
                            icon: "error",
                            button: "Ok",
                        })
                        navigate('/login');
                        return false;
                    }
                }
                if(checkUsaTldStatus == true) {
                    setFlag(false);
                }else if ((values.UserType == 3 || values.UserType == 4 || values.UserType == 5)) {
                    if(values.UserType != 7){
                        swal({
                            title: "Failed",
                            text: "Plese Select Company",
                            icon: "error",
                            button: "Ok",
                        })
                        setFlag(false);
                    }
                }else if ((values.UserType == 3 || values.UserType == 4 || values.UserType == 5) && ( values.Aidarray.length == 0)) {
                        swal({
                            title: "Failed",
                            text: "Plese Select AID",
                            icon: "error",
                            button: "Ok",
                        })
                        setFlag(false);
                } else{
                    setFlag(true);
                }
                if (flag) {
                    handleFreezStart();
                    const apiUrl = '/createUser';
                    const args = {
                        first_name: values.firstName,
                        last_name: values.lastName,
                        role_id: values.userType,
                        email: values.email,
                        phone_no: formatPhoneNumber(values.phone),
                        password: values.password,
                        aids: values.userAidList,
                        defaultTimezone: values.timezone,
                        company_id: values.companyID,
                        softwaretype: values.softwaretype,
                    }
                    const res = await dispatch(postData({apiUrl,args})).unwrap();
                    if(res)
                    {
                        if (res.success === true) {
                            handleFreezStop();
                            handleClose(); 
                            swal({
                                title: "Success",
                                text: res.message,
                                icon: "success",
                                button: "Ok",
                            });
                            fetchCompanyDetails();
                        } else {
                            handleFreezStop();
                            handleClose(); 
                            swal({
                                title: "Failed",
                                text: "Email Already Exist",
                                icon: "error",
                                button: "Ok",
                            })
                        }
                    }else
                    {
                        handleFreezStop(); 
                        swal({
                            title: "Failed",
                            text: "Something went Wrong",
                            icon: "error",
                            button: "Ok",
                        })
                    }
                }
            }
        }
    };

     const enableDisableUser = async () => {
        handleCloseDeactive();
        if (!checkUserRole()) return;
        try
        {
            const apiUrl = '/user-softdelete';
            const args = {
                id: disableUserId,
                requestType: operation,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    swal({
                        title: "Success",
                        text: res.message,
                        icon: "success",
                        button: "Ok",
                    });
                    fetchCompanyDetails();
                } else {
                    swal({
                        title: "Failed",
                        text: res.message,
                        icon: "error",
                        button: "Ok",
                    })
                }
            }
        } catch (error) {
            alert("Something went wrong.");
        }
    }

    const handleChangeSwitch = (userId , status) => {
        setAPIAccessFlag(status == 1 ? false : true);
        setUserId(userId);
        setStatus(status);
        setAPIAccess(true);
    }
    const notificationHandleClose = () => {
        setAPIAccess(false);
    };

    const changeAPIAccessStatus = () => {
        setAPIAccess(false);
        handleFreezStart();
        const apiUrl = "/affiliate/APIAcess";
        const args = {
            id : UserId,
            status: Status
        }
        const res = dispatch(postData({ apiUrl, args })).unwrap()
        if (res) {
            var sta =  Status ? " Activated " : "De-activated " ;
            if (res) {
                handleFreezStop(false)
                swal({
                    title: "Success",
                    text: `API access ${sta} successfully.`,
                    icon: "success",
                    button: "Ok",
                });
                fetchCompanyDetails();
            } else {
                handleFreezStop(false)
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
            }
        }
    }

    const columns = [
        { accessorKey: "id", header: "ID", size: 70 },
        { accessorKey: "name", header: "Name", size: 150 },
        {
            accessorKey: "email",
            header: "Email Address",
            size: 200,
            Cell: ({ row }) => (
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    {row.original.email}
                    {row.original.email && row.original.bounce_email_flag && (
                        <Tooltip title="Emails may not be delivered to this address, but you can login using this email." arrow>
                            <ErrorOutlineIcon color="error" />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        { accessorKey: "phone_no", header: "Phone", size: 140 },
        {
            accessorKey: "role.role_name",
            header: "User Role",
            size: 130,
            Cell: ({ row }) => row.original.role?.role_name || "N/A",
        },
        {
            accessorKey: "is_active",
            header: "Status",
            size: 100,
            Cell: ({ row }) => (row.original.is_active === 1 ? "Active" : "Deactivated"),
        },
        ...(user.roles_id == 3
            ? [
                {
                    accessorFn: (row) => row.api_access,
                    id: 'API Access',
                    header: 'API Access',
                    Cell: ({ row }) => (
                    <Box>
                        <Grid item>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle1" gutterBottom>
                            Off
                            </Typography>
                            <Switch
                            checked={Boolean(row.original.api_access)}
                            onChange={() =>
                                handleChangeSwitch(row.original.id, row.original.api_access ? 0 : 1)
                            }
                            inputProps={{ 'aria-label': 'Switch API Access' }}
                            />
                            <Typography variant="subtitle1" gutterBottom>
                            On
                            </Typography>
                        </Stack>
                        </Grid>
                    </Box>
                    ),
                },
                ]
            : []),
        {
            accessorKey: "created_at",
            header: "Created Date",
            size: 150,
            Cell: ({ row }) => Moment(row.original.created_at).tz("America/Los_Angeles").format("MM-DD-YYYY"),
        },
        {
            accessorKey: "action",
            header: "Action",
            size: 100,
            enableSorting: false,
            enableColumnFilter: false,
            Cell: ({ row }) => (
                company_details.is_disabled === 0 &&
                user.roles_id <= 3 &&
                user.id !== row.original.id ? 
                // (
                //     <>
                //         <Tooltip title="Edit User" arrow>
                //             <IconButton
                //                 size="small"
                //                 onClick={() => handleEditClick(row.original.id)}
                //             >
                //                 <EditOutlinedIcon />
                //             </IconButton>
                //         </Tooltip>
                //         <Tooltip title="Deactivate" arrow>
                //             <IconButton
                //                 size="small"
                //                 onClick={() => changeUserStatus(row.original.id,'disable')}
                //             >
                //                 <PersonRemoveOutlinedIcon />
                //             </IconButton>
                //         </Tooltip>
                //     </>
                // ) 
                row.original.is_active ?
                     <>
                        <Tooltip title="Edit User" arrow>
                            <IconButton
                                size="small"
                                onClick={() => handleEditClick(row.original.id)}
                            >
                                <EditOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Deactivate" arrow>
                            <IconButton
                                size="small"
                                onClick={() => changeUserStatus(row.original.id,row.original.roles_id,'disable')}
                            >
                                <PersonRemoveOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                : 
               <>
                    <Tooltip title="Activate" arrow>
                            <IconButton
                                size="small"
                                onClick={() => changeUserStatus(row.original.id,row.original.roles_id,'active')}
                            >
                                <PersonRemoveOutlinedIcon />
                            </IconButton>
                        </Tooltip>
               </>
                : (
                    <></>
                )
            ),
        },
    ];

    const csvOptions = {
        fileName: "User-List",
        headers: [
            "ID",
            "Name",
            "Email Address",
            "Phone No.",
            "User Role",
            "API Access",
            "Status",
            "Created Date",
        ],
    };
      
    const handleExportRows = (rows) => {
        const exportedData = rows.map((row) => {
            return {
                ID: row.original.id || "N/A",
                Name: row.original.name?.trim() || "N/A",
                "Email Address": row.original.email?.trim() || "N/A",
                "Phone No.": row.original.phone_no?.trim() || "N/A",
                "User Role": row.original.role?.role_name?.trim() || "N/A",
                "API Access": row.original.api_access ? "Enabled" : "Disabled",
                Status: row.original.is_active === 1 ? "Active" : "Deactivated",
                "Created Date": row.original.created_at
                    ? Moment(row.original.created_at)
                          .tz("America/Los_Angeles")
                          .format("MM-DD-YYYY")
                    : "N/A",
            };
        });
      
        const csvContent = convertToCSV(exportedData);
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${csvOptions.fileName}.csv`;
      
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        link.remove();
    };
      
    const convertToCSV = (data) => {
        const headers = csvOptions.headers.join(",") + "\n";
        const rows = data
          .map((row) =>
            Object.values(row)
              .map((value) => {
                if (typeof value === "string") {
                  value = value.replace(/"/g, '""'); // Escape quotes
                  if (value.includes(",")) {
                    return `"${value}"`; // Wrap with double quotes if contains a comma
                  }
                }
                return value;
              })
              .join(",")
          )
          .join("\n");
      
        return headers + rows;
    };

    return(
        <>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
                {user.roles_id <= 3 && (
                    <Button
                        variant="contained"
                        onClick={handleOpenCreate}
                        className="create-button"
                    >
                        Create User
                    </Button>
                )}
                <Grid item>
                <Stack direction="row" alignItems="center">
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Show Active User
                    </Typography>
                    <Switch
                        onChange={() => setShowActiveOnly((prev) => !prev)}
                        inputProps={{ 'aria-label': 'Switch API Access' }}
                    />
                </Stack>
                </Grid>
            </Box>
            <Box sx={{ width: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={filteredUsers}
                    enablePagination
                    enableColumnFilters
                    enableSorting
                    enableGlobalFilter
                    enableRowSelection={false}
                    muiTablePaperProps={{
                        sx: { backgroundColor: "white" },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            backgroundColor: "#2A68B3",
                            color: "white",
                            fontWeight: "bold",
                        },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        sx: { backgroundColor: row.index % 2 === 0 ? "white" : "#EBF4FF" },
                    })}
                    renderTopToolbarCustomActions={({ table }) => (
						<Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between', // Keeps the layout consistent
                                gap: '1rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                                width: '100%',
                            }}
                        >
                            <Button
                                sx={{ bgColor: "#2A68B3" }}
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                                className='export'
                            >
                                CSV
                            </Button>
						</Box>
					)
					}
                />
            </Box>

            <UserForm
                open={open}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                userData={userData}
                setUserData={setUserData}
                userTypeList={userTypeList}
                names={aidNames}
                mode={mode}
                checkUsaTLD={checkUsaTLD}
            />

            <Dialog
                open={APIAccessOpen}
                onClose={notificationHandleClose}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "2px"
                    }
                }}
            >
                <DialogTitle variant="h6" color='primary'></DialogTitle>
                <DialogTitle className="dialog-title" >
                    <Typography variant="h6" fontWeight="bold">Change API Access</Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton onClick={notificationHandleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        {
                            APIAccessFlag ? 
                            <>
                                If you disable this, you will not access API.
                            </>
                            : 
                            <>
                            Continue to access API.
                            </>
                            
                        }
                        <br/><br/>
                        Are you sure, you want continue ?
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={notificationHandleClose} className="cancel-button">
                                No
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={changeAPIAccessStatus}  className="save-button">
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeactive}
                onClose={handleCloseDeactive}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "2px"
                    }
                }}
            >
                <DialogTitle variant="h6" color='primary'></DialogTitle>
                <DialogTitle className="dialog-title" >
                    <Typography variant="h6" fontWeight="bold">{operation == "disabled" ? "Deactivate" : "Activate"} User</Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton onClick={handleCloseDeactive}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <>
                            Are you sure you want to {operation == "disabled" ? "deactivate" : "activate"} this user?
                        </>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={handleCloseDeactive} className="cancel-button">
                                No
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={enableDisableUser}  className="save-button">
                                Yes
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CompanyUsersTab;