
import React, { useState, useRef, useEffect } from "react";
import { Grid, RadioGroup, Radio, Button, FormControl, FormControlLabel, TextField, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardOverflow } from '@mui/joy';
import { Formik } from 'formik';
import { fetchData, postData } from '../../redux/apiSlice';
import Loader from '../Loader/Loader';
import { useDispatch } from 'react-redux';
function AffiAgreement14({ formData, handleFormChange, errors, handleBlur, validationSchema1, signature1, role, status,sameBankName1 }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [signature, setSignature] = useState(null);
    const [categoryValue, setCategoryValue] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [sameBankName, setSameBankName] = useState(false);

    useEffect(() => {
        setSignature(signature1)
        setSameBankName(sameBankName1)
    }, [signature1,sameBankName1])

    const getCategoryValue = (e) => {
        setCategoryValue(e.target.value)
    }
    useEffect(() => {
        setCategoryValue(formData.AccountCategory ? formData.AccountCategory : null)
    }, [])
    const fetchBankName = async (routingNumber) => {
        handleFormChange("BankRouting", routingNumber)
        const apiUrl = "/bankname/" + routingNumber;
        if (routingNumber.length == 9) {
            setIsLoading(true);
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if (res.success) {
                setTimeout(() => {
                    handleFormChange('BankRouting', routingNumber)
                    handleFormChange("BankName", res.data)
                    setIsLoading(false)
                }, 2000)
            }
            else {
                setIsLoading(false);
                handleFormChange("BankName", '')
            }
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '2%' }}>
                                    Schedule A:
                                </Typography>

                                <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '2%' }}>
                                    Banking Information Sales Commission Payment
                                </Typography>


                                <Typography variant="h6">
                                    If Sales Commission is to be paid by ACH, please provide the following banking information below. Accrued commissions less than $100 will be paid out in the commission cycle month that the accrued balance reaches $100. If monthly Sales Commissions payable are less than $100, MFSN reserves the right to pay by check Sales Commissions to the Company Name and Address on listed by Referrer on this Agreement.

                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Sales Commissions are paid monthly.
                                </Typography><br /><br />
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>COMPLETING THE BELOW IS OPTIONAL.</Typography>

                                <Typography variant="h6">YOU CAN SUBMIT LATER BEFORE YOUR FIRST SALES COMMISSION!</Typography><br /><br />
                            </Grid>

                            {/* <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1} className="pandadoc-initialBlock" sx={{ margin: '4% 10%', alignItems: 'center' }} mt={3}>

                                <Grid item lg={3} md={3} sm={12} xs={12} className="padadoc-sign-field" sx={{ paddingTop: '0px !important', justifyContent: 'center' }}>
                                    <TextField
                                        size="small"
                                        // fullWidth
                                        sx={{ border: '0px !important', width: 'fit-content' }}
                                        InputProps={{
                                            readOnly: true,
                                            style: {
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100% !important',
                                                borderRadius: '4px',
                                                border: '0px !important'
                                            },
                                            startAdornment: (
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    {signature ? (
                                                        <Box
                                                            className="signature-preview"
                                                            style={{ cursor: 'pointer', padding: '10px 20px' }}
                                                        >
                                                            <img
                                                                src={signature}
                                                                alt="Initials"
                                                                style={{ width: '100%', height: 'auto' }}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <Button
                                                            className="signature-button"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            {signatureLabel}
                                                        </Button>
                                                    )}
                                                </Box>
                                            ),
                                        }}
                                    />

                                </Grid>
                                <Grid item lg={9} md={9} sm={12} xs={12} sx={{ alignSelf: 'center' }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        Sales Commissions are paid monthly.
                                    </Typography>
                                </Grid>


                            </Grid> */}

                            <Grid item lg={12} md={12} sm={12} xs={12}>

                                <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
                                    <Typography variant="h6">Banking Information:</Typography>
                                </Grid>

                                <Formik
                                    initialValues={formData}
                                    validationSchema={validationSchema1}
                                    enableReinitialize={true} // This ensures that Formik updates the form values when formData changes
                                >

                                    {({ handleChange }) => (
                                        <Grid item sx={{ pointerEvents: role === true ? 'none' : 'auto' }}>
                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%' }} mb={2}>
                                                <Grid>
                                                    <Typography variant="h6">Type of Account:</Typography>
                                                </Grid>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Grid>
                                                    <FormControl component="fieldset" error={Boolean(errors.AccountType)}>
                                                        <RadioGroup

                                                            name='AccountType'
                                                            onChange={(e) => {
                                                                // handleChange(e)
                                                                handleFormChange("AccountType", e.target.value)
                                                            }}
                                                            value={formData.AccountType}
                                                            onBlur={(e) => handleBlur('AccountType', e.target.value)}
                                                            sx={{ display: 'block' }}
                                                        >
                                                            <FormControlLabel value="checking" control={<Radio inputProps={{ readOnly: status == 1 ? true : false }} disabled={status == 1 ? true : false} />} label="Checking" sx={{ fontWeight: "bold" }} />
                                                            <FormControlLabel value="savings" control={<Radio inputProps={{ readOnly: status == 1 ? true : false }} disabled={status == 1 ? true : false} />} label="Savings" sx={{ fontWeight: "bold" }} />

                                                        </RadioGroup>
                                                        {errors.AccountType ? <div className='error'>{errors.AccountType}</div> : null}
                                                    </FormControl>

                                                </Grid>

                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%' }} mb={2}>
                                                <Grid ><Typography variant="h6"><span style={{ fontWeight: 'bold' }}>AND</span> is Account:​</Typography></Grid>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Grid >
                                                    <FormControl>
                                                        <RadioGroup
                                                            // aria-labelledby="demo-radio-buttons-group-label"
                                                            name="AccountCategory"
                                                            onChange={(e) => {
                                                                getCategoryValue(e)
                                                                handleFormChange("AccountCategory", e.target.value)
                                                            }}
                                                            value={formData.AccountCategory}
                                                            onBlur={(e) => handleBlur('AccountCategory', e.target.value)}
                                                            sx={{ display: 'block' }}
                                                        >
                                                            <FormControlLabel value="personal" control={<Radio inputProps={{ readOnly: status == 1 ? true : false }} disabled={status == 1 ? true : false} />} label="Personal" sx={{ fontWeight: "bold" }} />
                                                            <FormControlLabel value="business" control={<Radio inputProps={{ readOnly: status == 1 ? true : false }} disabled={status == 1 ? true : false} />} label="Business" sx={{ fontWeight: "bold" }} />
                                                        </RadioGroup>
                                                    </FormControl>

                                                    {errors.AccountCategory ? <div className='error'>{errors.AccountCategory}</div> : null}

                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%' }} mb={2}>
                                                <Grid className="textField-text-w2"><Typography variant="h6">Bank Routing (ABA/ACH) Number:</Typography></Grid>&nbsp;
                                                <Grid className="textField-w2">
                                                    <TextField
                                                        label="Bank Routing (ABA/ACH) Number"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='BankRouting'
                                                        sx={{ marginTop: '0' }}
                                                        value={formData.BankRouting}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*$/.test(value)) { // Regex to allow only digits
                                                                // handleFormChange('BankRouting', value);
                                                                fetchBankName(e.target.value);
                                                            }
                                                        }}
                                                        onBlur={(e) => handleBlur('BankRouting', e.target.value)}
                                                        error={Boolean(errors.BankRouting)}
                                                        helperText={errors.BankRouting}
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    /></Grid>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%' }} mb={2}>
                                                <Grid className="textField-text-w1"><Typography variant="h6">Bank Name:</Typography></Grid>&nbsp;
                                                <Grid className="textField-w1">
                                                    <TextField
                                                        label="Bank Name"
                                                        margin="dense"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        name='BankName'
                                                        id='BankName'
                                                        value={formData.BankName}
                                                        onChange={(e) => {
                                                            handleFormChange('BankName', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('BankName', e.target.value)}
                                                        error={Boolean(errors.BankName)}
                                                        helperText={errors.BankName}
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%' }} mb={2}>
                                                <Grid className="textField-text-w1"><Typography variant="h6">{categoryValue === 'personal' ? 'Your Name' : 'Business Name'}</Typography></Grid>&nbsp;
                                                <Grid className="textField-w1">
                                                    <TextField
                                                        label={`${categoryValue === 'personal' ? 'Your Name' : 'Business Name'}`}
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='NameonAccount'
                                                        sx={{ marginTop: '0' }}
                                                        value={formData.NameonAccount}
                                                        onChange={(e) => {
                                                            // handleChange(e);
                                                            handleFormChange('NameonAccount', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('NameonAccount', e.target.value)}
                                                        // error={Boolean(errors.NameonAccount)}
                                                        // helperText={errors.NameonAccount}
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    />
                                                   {formData.NameonAccount && sameBankName  
                                                    ? <div className='error'>Bank Name & Name on Account must be different</div> 
                                                    : errors.NameonAccount 
                                                        ? <div className='error'>{errors.NameonAccount}</div> 
                                                        : null
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%' }} mb={2}>
                                                <Grid className="textField-text-w3"><Typography variant="h6">Bank Account Number:</Typography></Grid>&nbsp;
                                                <Grid className="textField-w3">
                                                    <TextField
                                                        label="Bank Account Number"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='AccountNumber'
                                                        sx={{ marginTop: '0' }}
                                                        value={formData.AccountNumber}
                                                        onChange={(e) => {
                                                            // handleChange(e);
                                                            handleFormChange('AccountNumber', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('AccountNumber', e.target.value)}
                                                        error={Boolean(errors.AccountNumber)}
                                                        helperText={errors.AccountNumber}
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    /></Grid>
                                            </Grid>



                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
                                                <Grid className="textField-text-w4"><Typography variant="h6">Email address to notify of ACH transfers sent:</Typography></Grid>&nbsp;
                                                <Grid className="textField-w4">
                                                    <TextField
                                                        inputProps={{ readOnly: true }}
                                                        label="Email Address"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        // inputRef={inputRef}
                                                        name='Email'
                                                        sx={{ marginTop: '0' }}
                                                        value={formData.Email4}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            handleFormChange('Email4', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('Email4', e.target.value)}
                                                        error={Boolean(errors.Email4)}
                                                        helperText={errors.Email4}
                                                        InputLabelProps={{ className: 'label-to-hide' }}

                                                    /></Grid>
                                            </Grid>
                                        </Grid>
                                    )}

                                </Formik>
                                {/* <Grid item mt={2}>
                                    <Typography variant="h6" >
                                        Contact us if you need other payment options such as Zelle, Paypal, Venmo, CashApp or other services as an alternative payment method for remitting Referrer’s Sales Commission.
                                    </Typography><br />
                                    <Typography variant="h6" >
                                        It is in MFSN’s sole discretion to approve and utilize these alternate payment methods to pay Referrer’s Sales Commissions and the decision will be made by MFSN on a case by case basis.
                                    </Typography><br /><br />
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement14;