
import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import AboutUs from '../FooterPages/AboutUs';
import ContactUs from '../FooterPages/ContactUs';
import TermsAndConditions from '../FooterPages/TermsAndConditions';
import PrivacyPolicy from '../FooterPages/PrivacyPolicy';
import CancelPolicy from '../FooterPages/CancelPolicy';
import RefundPolicy from '../FooterPages/RefundPolicy';
import Faq from '../FooterPages/Faq';
import { useNavigate } from "react-router-dom";
import { Typography, Card, CardContent } from '@mui/joy';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/HighlightOff';
import config from '../../AppConfig';

function RegisterFooter(props) {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const toggleDrawer1 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen1(open);
  };
  const toggleDrawer2 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen2(open);
  };
  const toggleDrawer3 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen3(open);
  };
  const toggleDrawer4 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen4(open);
  };
  const toggleDrawer5 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen5(open);
  };
  const toggleDrawer6 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen6(open);
  };
  const toggleDrawer7 = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen7(open);
  };

  return (
    <>
      <Paper sx={{
        border: 'none',
        bottom: 0,
      }} component="footer" square variant="outlined">
        <Card id="primaryColor" sx={{ borderTopWidth: '2px', borderTopStyle: 'solid', borderRadius: "0%", borderTopColor: props.customColorArray ? `${props.customColorArray.primaryColor} !important` : "#30BE2D" }}>
          <CardContent>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mb={1}>
                <a onClick={toggleDrawer1(true)} style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }}>About Us</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <SwipeableDrawer
                  anchor={'bottom'}
                  open={open1}
                  onClose={toggleDrawer1(false)}
                  onOpen={toggleDrawer1(true)}
                >
                  <AboutUs closeVal={toggleDrawer1(false)} />
                </SwipeableDrawer>
                <a onClick={toggleDrawer2(true)} style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }}>Contact</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <SwipeableDrawer
                  anchor={'bottom'}
                  open={open2}
                  onClose={toggleDrawer2(false)}
                  onOpen={toggleDrawer2(true)}
                >
                  <ContactUs closeVal={toggleDrawer2(false)} />
                </SwipeableDrawer>
                <a onClick={toggleDrawer3(true)} style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }}>Terms & Conditions</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <SwipeableDrawer
                  anchor={'bottom'}
                  open={open3}
                  onClose={toggleDrawer3(false)}
                  onOpen={toggleDrawer3(true)}
                >
                  <TermsAndConditions closeVal={toggleDrawer3(false)} />
                </SwipeableDrawer>
                <a onClick={toggleDrawer4(true)} style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }}>Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <SwipeableDrawer
                  anchor={'bottom'}
                  open={open4}
                  onClose={toggleDrawer4(false)}
                  onOpen={toggleDrawer4(true)}
                >
                  <PrivacyPolicy closeVal={toggleDrawer4(false)} />
                </SwipeableDrawer>
                <a onClick={toggleDrawer5(true)} style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }}>Cancel Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <SwipeableDrawer
                  anchor={'bottom'}
                  open={open5}
                  onClose={toggleDrawer5(false)}
                  onOpen={toggleDrawer5(true)}
                >
                  <CancelPolicy closeVal={toggleDrawer5(false)} />
                </SwipeableDrawer>
                <a onClick={toggleDrawer6(true)} style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }}>Refund Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <SwipeableDrawer
                  anchor={'bottom'}
                  open={open6}
                  onClose={toggleDrawer6(false)}
                  onOpen={toggleDrawer6(true)}
                >
                  <RefundPolicy closeVal={toggleDrawer6(false)} />
                </SwipeableDrawer>
                <a style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }} onClick={() => {
                  localStorage.setItem('origin', "mfsnhomepage");
                  // navigate('/register');
                  window.location.href = config.BASE_URL + 'register';  
                }}>
                  Affiliates
                </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a style={{ cursor: "pointer", color: "#3097d1", textDecoration: "none", fontSize: "1.2rem" }} onClick={toggleDrawer7(true)}>FAQ</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <SwipeableDrawer
                  anchor={'bottom'}
                  open={open7}
                  onClose={toggleDrawer7(false)}
                  onOpen={toggleDrawer7(true)}
                >
                  <Faq closeVal={toggleDrawer7(false)} footer={true} />
                </SwipeableDrawer>
                {/* <p>
                  <Link to="/AboutUs" className='footerdesign'>About us</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a className='footerdesign' href="">Contact</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a className='footerdesign' href="">Terms & Conditions</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a className='footerdesign' href="">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a className='footerdesign' href="">Cancel Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a className='footerdesign' href="">Refund Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a className='footerdesign' href="">Affiliates</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a className='footerdesign' href="">FAQ</a>
                </p> */}
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                <Typography level="body1" fontSize="lg" sx={{ textAlign: "center" }}>Copyright © {currentYear} www.MyFreeScoreNow.com. All rights reserved. MyFreeScoreNow.com is operated by MyFreeScoreNow Inc.</Typography>
              </Grid>
              {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                <Typography level="body1" fontSize="lg" sx={{ mb: 0.9,textAlign: "center" }}>After verification of your identity, your 3 scores are available for secure online delivery in seconds.</Typography>
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
}
export default RegisterFooter;