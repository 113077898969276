import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box, Select, Grid, Button, TextField, InputLabel, MenuItem, FormControl, OutlinedInput,Autocomplete
} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate,useLocation } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from 'yup';
import { Formik} from 'formik';
import AuthUser from '../../../Components/Auth/AuthUser';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function CreateUser(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const [softwareType, setSoftwareList] = useState([]);
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const queryParams = new URLSearchParams(location.search);
    const roleId = queryParams.get('roleId') ? queryParams.get('roleId') : '';
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end   setSoftwareList

    const [AIDlistError, setAIDlistError] = useState(false);
    const [personName, setPersonName] = React.useState([]);
    const { http,user } = AuthUser();
    const [ userTypeList,setUserTypeList] = useState([]);
    const [ companyList, setCompanyList] = useState([]);
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [ userRoles, setuserRoles] = useState([]);
    const [isLowercaseValid, setIsLowercaseValid] = useState(false);
    const [isUppercaseValid, setIsUppercaseValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [showPasswordValidationBlock, setShowPasswordValidationBlock] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [aidlist1, setAidlist1] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedRole, setSelectedRole] = useState(roleId);
    const [flag, setFlag] = useState(true);
    useEffect(() => {
        if(roleId == 14) {
            const element = document.getElementById("Companydiv");
            element.style.display = "none";
            const element1 = document.getElementById("Timezonediv");

            element1.style.display = "none";
            const element3 = document.getElementById("Aidlistdiv");
            element3.style.display = "none";
            
            const partnerCode = document.getElementById("partnerAdminReferalcode");
            partnerCode.style.display = "block";
                
        }
        setShowPasswordValidationBlock(false);
        fetchUserTypes();
        fetchCompanyList();
        getSoftware();
    }, [])
    const [CompanyError, setCompanyError ] = useState(false);
    const [AIDListErrorRequired, setAIDListErrorRequired] = useState(false);
    const [ SoftTypeErrorRequired,setSoftwareTypeErrorRequired] = useState(false);
   
    const fetchCompanyList = async () => {
        handleFreezStart();
        const apiUrl = '/fetchCompanyList';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            handleFreezStop();
            setCompanyList(res.data);
            const updatedCompanies = res.data.map((option) => {
                const firstLetter = option.company_name[0].toUpperCase();
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                    ...option,
                };
            });
            const sortedCompanies = updatedCompanies.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
            setCompanies(sortedCompanies);
        }
    }

    const fetchUserTypes = async() => {
        handleFreezStart();
        const apiUrl = '/fetchUserTypes';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            handleFreezStop();
            setUserTypeList(res);
            setuserRoles(res);
        }
    }

    //fetch Patner Admin Code AID list start
    const getSoftware = async() => {
        handleFreezStart();
        const apiUrl = '/fetchSoftwareList';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            handleFreezStop(); 
            setSoftwareList(res);
        }
    }

    const getAidlist = async(companyid,values) => {
        aidlist1 = [];
        const apiUrl = '/fetchCompanyAIDList/' + companyid;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            const fetchedAidList = res.aidList;
            setAidlist1(fetchedAidList);
            if(selectedRole == 3) {
                values.Aidarray = fetchedAidList;
            }else{
                values.Aidarray = [];
            }
        }
    }

    const handleCompanyChange = async (value,values,setFieldValue) => {
        if (value) {
            const fetchedAidList = value.aidList;
            setAidlist1(fetchedAidList);
            if (selectedRole == 3 || selectedRole == 11) {
                values.Aidarray = fetchedAidList;
            } else {
                values.Aidarray = [];
            }
        } else {
            setAidlist1([]);
            values.Aidarray = [];
        }
        setCompanyId(value ? value.id : '');
        setFieldValue('Company', value ? value.id : '');
    };

    const changeUserType = (e,values) => {
        setSelectedRole(e);
        if (e == 1 || e == 2 || e == 6 || e == 7 || e == 8 || e == 9 || e == 10 || e == 12 || e == 13 || e == 14) {
            const element = document.getElementById("Companydiv");
            element.style.display = "none";
            const element1 = document.getElementById("Timezonediv");
            element1.style.display = "none";
            const element3 = document.getElementById("Aidlistdiv");
            element3.style.display = "none";
            const partnerCode = document.getElementById("partnerAdminReferalcode");
            partnerCode.style.display = "none";
            if(e == 7 || e == 8 || e == 9 || e == 14){
                const partnerCode = document.getElementById("partnerAdminReferalcode");
                partnerCode.style.display = "block";
                
            }
        } else {
            const element = document.getElementById("Companydiv");
            element.style.display = "block";
            const element1 = document.getElementById("Timezonediv");
            element1.style.display = "block";
            const element3 = document.getElementById("Aidlistdiv");
            element3.style.display = "block";
            const partnerCode = document.getElementById("partnerAdminReferalcode");
            partnerCode.style.display = "none";
        }

        if(e == 3 || e == 11) {
            values.Aidarray = aidlist1;
        }else{
            values.Aidarray = [];
        }
    }

     //User validation Schema start
     const UserSchema = Yup.object().shape({
        FirstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your full Name.'),
        LastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your last Name.'),
        PhoneNumber: Yup.string()
            .min(14, 'Phone number must be of 10 digit!')
            .max(14, 'Phone number must be of maximum 10 digit!')
            .required('Please enter your phone number.'),
        Email: Yup.string()
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Please enter your email address.'),
        UserType: Yup.string()
            .required('Please select type of user!.'),
        softwaretype: Yup.string().when("UserType", {
            is: (UserType) => ['7','8','9'].includes(UserType),
            then: (schema) => schema
                .required('Software Type is required.')
        }),
        Company: Yup.string().when("UserType", {
            is: (UserType) => ['3','4','5','11'].includes(UserType),
            then: (schema) => schema
                .required('Company Name is required.')
        }),
        DefaultTimezone: Yup.string().when("UserType", {
        is: (UserType) => ['3','4','5','11'].includes(UserType),
        then: (schema) => schema
            .required('user timezone need to be selected')
        }),    
        Password: Yup.string()
            .test(
                () => {
                    return (
                        isLowercaseValid &&
                        isUppercaseValid &&
                        isNumberValid &&
                        isSpecialCharValid &&
                        isLengthValid
                    );
                }
                
            )
            .required('Password is required.')
    });
    //User validation Schema end

    const checkAdmin = () => {
        var check = 1;
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 3 && loginUser.roles_id != 9) {
            check = 0;
        } 
        if(check == 0) {
            navigate('/login');
            return false;
        }
    
        return true;
    }

    const getUserList = () => {
        setShowPasswordValidationBlock(false);
        var check = checkAdmin();
        if(check === true) {
            if(queryParams.get('roleId') == 11 || queryParams.get('roleId') == 14) {
                navigate('/automations/api-user');
            }else{
                navigate('/userpanel');
            }
        }
    }

    const checkUsaTLD =async (email) => {
        if (email.includes('@')) {
            try {
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if (res && res.data && typeof res.data.success === 'boolean') {
                setCheckUsaTldStatus(res.data.success);
            } else {
                // Handle unexpected response structure
                console.warn("Unexpected response structure:", res);
                setCheckUsaTldStatus(false);
            }
        } catch (error) {
                        // Handle errors from the API call
                        console.error("Error checking USA TLD:", error);
                        setCheckUsaTldStatus(false);
                    }
        }
    }
    
    const Create =async (values) => {
        var check = checkAdmin();
        if(check === true) {
            const loginUser = JSON.parse(localStorage.getItem('user'));
            if(loginUser.roles_id <= 3 ) {
                if(loginUser.roles_id == 3 && loginUser.company_master_id != values.Company) {
                    swal({
                        title: "Failed",
                        text: "This action is unauthorized.",
                        icon: "error",
                        button: "Ok",
                    })
                    navigate('/login');
                    return false;
                }
            }
            if(checkUsaTldStatus == true) {
                setFlag(false);
            }else if ((values.UserType == 3 || values.UserType == 4 || values.UserType == 5) && (companyId == "" || companyId == undefined)) {
                if(values.UserType != 7){
                    swal({
                        title: "Failed",
                        text: "Plese Select Company",
                        icon: "error",
                        button: "Ok",
                    })
                    setFlag(false);
                }
            }else if ((values.UserType == 3 || values.UserType == 4 || values.UserType == 5) && ( values.Aidarray.length == 0)) {
                    swal({
                        title: "Failed",
                        text: "Plese Select AID",
                        icon: "error",
                        button: "Ok",
                    })
                    setFlag(false);
            } else{
                setFlag(true);
            }
            if (flag) {
                setShowPasswordValidationBlock(false);
                handleFreezStart();
                const apiUrl = '/createUser';
                const args = {
                    first_name: values.FirstName,
                    last_name: values.LastName,
                    email: values.Email,
                    phone_no: values.PhoneNumber,
                    company_id: values.Company,
                    role_id: values.UserType,
                    password: values.Password,
                    aids: values.Aidarray,
                    defaultTimezone: values.DefaultTimezone,
                    softwaretype: values.softwaretype,
                }
                const res = await dispatch(postData({apiUrl,args})).unwrap();
                if(res)
                {
                    if (res.success === true) {
                        handleFreezStop(); 
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        }).then(() => {
                                navigate(-1);
                        });
                    } else {
                        handleFreezStop(); 
                        swal({
                            title: "Failed",
                            text: "Email Already Exist",
                            icon: "error",
                            button: "Ok",
                        })
                    }
                }else
                {
                    handleFreezStop(); 
                    swal({
                        title: "Failed",
                        text: "Something went Wrong",
                        icon: "error",
                        button: "Ok",
                    })
                }
            }
        }
    }
    //password suggestion box
    const handlePasswordChange = (event) => {
      const newPassword = event.target.value;
      const lowercaseRegex = /[a-z]/;
      const uppercaseRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;
      const specialCharRegex = /[!@#$%^&*]/;
      setIsLowercaseValid(lowercaseRegex.test(newPassword));
      setIsUppercaseValid(uppercaseRegex.test(newPassword));
      setIsNumberValid(numberRegex.test(newPassword));
      setIsSpecialCharValid(specialCharRegex.test(newPassword));
      setIsLengthValid(newPassword.length >= 8);
    };

    const handlePasswordClick = () => {
        setShowPasswordValidationBlock(true);
    };

    const ContentLayout =
            <>
                <Formik
                    initialValues={{ 
                        FirstName: '',
                        LastName: '',
                        Email: '',
                        PhoneNumber: '',
                        UserType: roleId,
                        Company: '',
                        Aidarray: [],
                        DefaultTimezone:  '',
                        Password: '',
                        softwaretype: roleId == 14 ? user.software_name : ''
                    }}
                    validationSchema={UserSchema}
                    onSubmit={(values, errors) => {
                        Create(values);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                        <div>
                            <Box>
                                <Card variant="outlined" size="lg" mt={2}>
                                        <CardOverflow
                                            sx={{
                                                bgcolor: '#F6FAFF',
                                                borderColor: '#F6FAFF',
                                                padding: '1%',
                                            }}
                                        >
                                            <Typography level="h3" startDecorator={<AccountCircleIcon sx={{ fontSize: "35px" }} />} mb={2}>
                                                Create User
                                            </Typography>
                                        </CardOverflow>
                                        <CardContent>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="First Name"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='FirstName'
                                                    autoComplete='FirstName'
                                                    id='FirstName'
                                                    onChange={(e) => { handleChange(e); }}
                                                    value={values.FirstName}
                                                    onBlur={handleBlur('FirstName')}
                                                />
                                                {touched.FirstName && errors.FirstName ? <div className='error'>{errors.FirstName}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="Last Name"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='LastName'
                                                    autoComplete='LastName'
                                                    id='LastName'
                                                    onChange={(e) => { handleChange(e); }}
                                                    value={values.LastName}
                                                    onBlur={handleBlur('LastName')}
                                                />
                                                {touched.LastName && errors.LastName ? <div className='error'>{errors.LastName}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="E-mail Address"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='Email'
                                                    autoComplete='Emails'
                                                    id='Email'
                                                    onChange={(e) => 
                                                    { 
                                                        handleChange(e); 
                                                        checkUsaTLD(e.target.value);
                                                        setFieldValue('Email', e.target.value, true);
                                                    }}
                                                    value={values.Email}
                                                    onBlur={handleBlur('Email')}
                                                />
                                                 <div className='error'>{touched.Email && errors.Email ?errors.Email : checkUsaTldStatus ? "Invalid email format." : '' }</div>
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <InputMask
                                                    mask='(999) 999-9999'
                                                    maskChar={null}
                                                    name='PhoneNumber'
                                                    fullWidth
                                                    size="small"
                                                    id='PhoneNumber'
                                                    autoComplete='PhoneNumber'
                                                    onChange={(e) => { handleChange(e); }}
                                                    value={values.PhoneNumber}
                                                    onBlur={handleBlur('PhoneNumber')}
                                                   >
                                                    {(inputProps) =>
                                                        <TextField
                                                        label="Phone Number" variant="outlined"
                                                        {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                                {touched.PhoneNumber && errors.PhoneNumber ? <div className='error'>{errors.PhoneNumber}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="demo-multiple-name-label">User Type</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        name='UserType'
                                                        input={<OutlinedInput label="User Type" />}
                                                        id='UserType'
                                                        MenuProps={MenuProps}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            changeUserType(e.target.value,values);
                                                            values.softwaretype = '';
                                                        }}
                                                        value={values.UserType}
                                                        onBlur={handleBlur('UserType')}
                                                    >
                                                        {userRoles.map((usertype,index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={usertype.id}
                                                                style={getStyles(usertype, personName, theme)}
                                                            >
                                                                {usertype.role_name}
                                                            </MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                                {touched.UserType && errors.UserType ? <div className='error'>{errors.UserType}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12} id='Companydiv'>
                                                <Autocomplete
                                                    id="Company-autocomplete"
                                                    options={companies}
                                                    getOptionLabel={(option) => option.company_name || ""}
                                                    onChange={(e, value) => handleCompanyChange(value,values,setFieldValue)}
                                                    onOpen={handleBlur}
                                                    includeInputInList
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={Boolean(touched.CompanyError && errors.CompanyError)}
                                                            fullWidth
                                                            helperText={touched.CompanyError && errors.CompanyError}
                                                            label="Company List"
                                                            name="Company"
                                                            id="Company"
                                                            size='small'
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                                {/* {CompanyError ? <div className='error'>For {values.UserType == 3 ? "Affiliate " : values.UserType == 4 ? "AID " : values.UserType == 5 ? "Staff " : " "} user company need to be selected</div> : null} */}
                                                {touched.Company && errors.Company ? <div className='error'>{errors.Company}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12} id='partnerAdminReferalcode' sx={{display:'none'}}>
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="demo-multiple-name-label">Software Type</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        name='softwaretype'
                                                        input={<OutlinedInput label="SoftType" />}
                                                        id='softwaretype'
                                                        MenuProps={MenuProps}
                                                        onChange={(e) => {
                                                            handleChange(e); setSoftwareTypeErrorRequired(false);
                                                        }}
                                                        value={values.softwaretype}
                                                        onBlur={handleBlur('softwaretype')}
                                                        disabled={user.roles_id == 9 ? true : false}
                                                    >
                                                        {softwareType.map((software,index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={software.link_name}
                                                            >
                                                                {software.software_name}  
                                                            </MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                                {touched.softwaretype && errors.softwaretype ? <div className='error'>{errors.softwaretype}</div> : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={4} md={4} sm={12} xs={12} id='Aidlistdiv'>
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="demo-multiple-name-label">AID List</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        name='Aidarray'
                                                        id='Aidarray'
                                                        input={<OutlinedInput label="AID List" />}
                                                        multiple
                                                        // onFocus={(e) => { CheckCompany() }}
                                                        onChange={(e) => { handleChange(e); }}
                                                        value={values.Aidarray}
                                                        onBlur={(e) => { handleBlur('Aidarray'); }}
                                                        disabled={values.UserType == 3 || values.UserType == 11 ? true : false}
                                                    >
                                                        {
                                                            (aidlist1).length != 0 ?
                                                            (aidlist1).map((com,index) => (
                                                                    <MenuItem
                                                                        key={index}  
                                                                        value={com}
                                                                        style={getStyles(com, personName, theme)}
                                                                    >
                                                                        {com}
                                                                    </MenuItem>
                                                                )) : ""}
                                                    </Select>
                                                </FormControl>
                                               
                                                {AIDlistError ? <div className='error'>To fetch AID list you need to select company first</div> : null}
                                                {AIDListErrorRequired ? <div className='error'>For {values.UserType == 3 || values.UserType == 11? "Affiliate " : values.UserType == 4 ? "Affiliate User" : values.UserType == 5 ? " Affiliate Staff " : " "}  at least one AID need to be selected</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12} id='Timezonediv'>
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="demo-multiple-name-label">Default Timezone</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        name='DefaultTimezone'
                                                        id='DefaultTimezone'
                                                        input={<OutlinedInput label="Default Timezone" />}
                                                        onChange={(e) => { handleChange(e); }}
                                                        value={values.DefaultTimezone}
                                                        onBlur={handleBlur('DefaultTimezone')}
                                                    >
                                                        <MenuItem value="EDT" >Eastern Time</MenuItem>
                                                        <MenuItem value="HST" >Hawaii Time</MenuItem>
                                                        <MenuItem value="AKDT">Alaska Time</MenuItem>
                                                        <MenuItem value="PDT" >Pacific Time</MenuItem>
                                                        <MenuItem value="MDT" >Mountain Time</MenuItem>
                                                        <MenuItem value="CDT" >Central Time</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                
                                                {touched.DefaultTimezone && errors.DefaultTimezone ? <div className='error'>{errors.DefaultTimezone}</div> : null}

                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size='small' variant="outlined">
                                                    <InputLabel htmlFor="Password">Password</InputLabel>
                                                    <OutlinedInput
                                                    
                                                        type='text'
                                                        label="Password"
                                                        placeholder="Type here only if you want to change the password"
                                                        input={<OutlinedInput label="Password" />}
                                                        size="small"
                                                        fullWidth
                                                        name='Password'
                                                        id='Password'
                                                        autoComplete='Password'
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            handlePasswordChange(e); 
                                                          }}
                                                        onKeyDown={handlePasswordClick} //hide show the password suggestion box
                                                        value={values.Password}
                                                        onBlur={handleBlur('Password')}
                                                    />
                                                    {touched.Password && errors.Password ? <div className='error'>{errors.Password}</div> : null}
                                               
                                                    {showPasswordValidationBlock && (
                                                    <>
                                                           <div>
                                                            <span style={{ color: isLengthValid ? 'green' : 'red' }}>Minimum <b>8 characters</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isSpecialCharValid ? 'green' : 'red' }}>At least <b>one special character</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isUppercaseValid ? 'green' : 'red' }}>At least <b>one capital (uppercase)</b> letter</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isNumberValid ? 'green' : 'red' }}>At least <b>one number</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isLowercaseValid ? 'green' : 'red' }}>At least <b>one lowercase</b> letter </span>
                                                        </div>
                                                    </>
                                           )}
                                                </FormControl>

                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} sx={{ textAlign: "center", width: "100%" }}>
                                            <Grid item>
                                                <Button variant="contained" sx={{ minWidth: 200 }} color="success" 
                                                onClick={handleSubmit}
                                                >

                                                    CREATE USER
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" sx={{ minWidth: 200 }} onClick={() => getUserList()} >
                                                    CLOSE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        </CardContent>
                                </Card>
                            </Box>
                        </div>
                    )}

                </Formik>
            </>

    return (
        <>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    );
}

export default CreateUser;