import React ,{useEffect, useState}from 'react';
import { Grid, TextField,Button } from '@mui/material';
import SecureWebsite from "../../../Components/Enrollment/SecureWebsite";
const Step1 = ({btnTextColor,primaryColor,popupFlage,textColorProp,hyperlinkColor}) => {
  
    return (
        <>
        <div style={{ pointerEvents: 'none' }}>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                <TextField size='small' label="First Name*" margin='dense' fullWidth 
                    sx={{
                        label: { color: `${primaryColor}` },
                        "& .MuiInputLabel-root":{color: `${primaryColor}`},
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}
                 />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                <TextField size='small' label="Middle Name" margin='dense' fullWidth 
                    sx={{
                        label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}
                 />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} >
                <TextField size='small' label="Last Name*" margin='dense' fullWidth 
                    sx={{label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}
                 />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                <TextField size='small' label="Email*" margin='dense' fullWidth 
                    sx={{label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                      
                    }}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                <TextField size='small' label="Password*" margin='dense' fullWidth 
                    sx={{
                        label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "right", display: "flex" }}>
                <Button variant='contained' size="lg" sx={{ minWidth: "60%", backgroundColor: `${primaryColor}`, color: `${btnTextColor}`,
                        '&:hover': {
                            backgroundColor: `${primaryColor}`
                        } 
                    }} className='popup-next-btn'>NEXT STEP</Button>
            </Grid>
            
            <SecureWebsite textColor={textColorProp} hyperlinkColor={hyperlinkColor}/>
            </div>
        </>
    );
}
export default Step1;