
import React, { useMemo, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box, Grid, InputLabel, Select, Table, TableBody, TableCell, Dialog, DialogActions, DialogTitle, TextField, DialogContent, FormControl,
  TableContainer, TableHead, TableRow, Paper, Button, MenuItem, FormHelperText
} from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import MaterialReactTable from 'material-react-table';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams, useNavigate, Link } from 'react-router-dom';
import AuthUser from "../Auth/AuthUser";
//Icons Imports
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
//Mock Data
import { Tooltip, IconButton } from '@mui/material';
import * as Yup from 'yup';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import Loader from '../Loader/Loader';
import swal from "sweetalert";
import { Formik } from 'formik';
import config from '../../AppConfig';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import { async } from 'q';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Moment from 'moment';
import { Typography } from 'material-ui';
let RequestFormInitialValues = {
  rateId: '',
  pid: '',
  description: '',
  amount: '',
  commission: '',
  effective_from: '',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];
function CommissionRate(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [createDisabled, setCreateDisabled] = React.useState(false);
  const [amountError, setAmountError] = React.useState('');
  const navigate = useNavigate();

  const handleClickOpen = (data) => {
    console.log(data);
    RequestFormInitialValues.rateId = data.id;
    RequestFormInitialValues.pid = data.pid;
    RequestFormInitialValues.amount = data.amount;
    RequestFormInitialValues.description = data.description;
    RequestFormInitialValues.commission = data.commission;
    RequestFormInitialValues.effective_from = data.effective_from ? data.effective_from : '';
    setOpen(true);
  };

  const tablePreferencesArray = {};

  const rateSchema = Yup.object().shape({
    pid: Yup.string()
      .min(5, 'Must be 5 digits.')
      .max(5, 'Must be 5 digits.')
      .required('Please enter PID'),

    amount: Yup.string()
      .required('Please enter Amount'),

    commission: Yup.string()
      .required('Please enter Commission'),

    effective_from: Yup.string()
      .required('Please enter Effective from date'),

    description: Yup.string()
      .required('Please select Description')
  })

  const submitRequest = async (values) => {
    if((values.description == "Refund" || values.description == "chargeback associated to sale") && parseFloat(values.commission) > 0) {
      swal({
          title: "Failed",
          text: "Commission value for "+values.description + " should be negative",
          icon: "error",
          button: "Ok",
      })
      return false;
    }
    if((values.description == "Payment" || values.description == "3-Bureau Credit Report & Scores Payment") && parseFloat(values.commission) < 0) {
      swal({
          title: "Failed",
          text: "Commission value for "+values.description + " should be postive",
          icon: "error",
          button: "Ok",
      })
      return false;
    }
    var commissionValue = parseFloat(values.commission);
    var amountValue = parseFloat(values.amount);
    if(amountValue < commissionValue) {
      swal({
        title: "Failed",
        text: "Commission value should be less than amount",
        icon: "error",
        button: "Ok",
      })
      return false;
    }
    if(amountError != '') {
      return false;
    }
  
    setCreateDisabled(true);
    const date = new Date(values.effective_from);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    var mydate = year + "/" + month + "/" + day;
    const apiUrl = '/update-commission';
    const args = {
      id: values.rateId,
      pid: values.pid,
      amount: values.amount,
      description: values.description,
      commission: values.commission,
      effective_from: mydate,
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if (res.success === true) {
        setOpen(false);
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        props.fetchRateList()
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        })

        props.fetchRateList()
      }
    }
    setCreateDisabled(false);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const checkAmountValue =(value) => {
    if(parseFloat(value) < 0) {
      setAmountError("Amount should be positive value");
    }else{
      setAmountError('');
    }
  }
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const { http, user } = AuthUser();
  const [data, setData] = useState(props.data);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState("");
  const [perPage, setPerPage] = useState(1000000);
  const [records, setRecords] = useState([]);
  const q = search == '' ? 'null' : search;
  const [columnVisibility, setColumnVisibility] = React.useState({});


  //Fetch rate Details Start
  const fetchCommissionRateDetails = async (id) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        swal({
            title: "Failed",
            text: "This action is unauthorized.",
            icon: "error",
            button: "Ok",
        })
        navigate('/login');
        return false;
    }
    setOpen1(false);
    const apiUrl = '/commission/' + id;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res.success === true) {
      handleClickOpen(res.commissionData);
    } else {
      alert("error in fetching commission rate details");
    }
  };


  //Fetch rate Details End
  const handleClickOpen1 = async (id, pid, amount, description) => {
    const apiUrl = '/check-duplicate-commission-rate';
    const args = {
      pid: pid,
      amount: amount,
      description: description
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if (res.success === true) {
        fetchCommissionRateDetails(id);
      } else {
        setRecords(res.duplicateRecords);
        setOpen1(true);
      }
    }

  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
    // console.info({ rowSelection });
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, []);

  useEffect(() => {
    saveTablePreferences();
  }, [columnVisibility])
  ////////////////////////

  const saveTablePreferences = async () => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'commissionRate'
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
    }
  }

  //fetch table preferences from database
  const tablePreferences = async () => {
    const apiUrl = '/fetchTablePreferences/commissionRate';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
    }
  }

  const CommisionListColumns = useMemo(
    () => [
      // {
      //   accessorFn: (row) => `${row.id}`, //accessorFn used to join multiple data into a single cell
      //   id: 'id', //id is still required when using accessorFn instead of accessorKey
      //   header: 'ID',
      //   size: 200,
      // },
      {
        accessorFn: (row) => `${row.pid ? row.pid : ''}`, //accessorFn used to join multiple data into a single cell
        id: 'pid', //id is still required when using accessorFn instead of accessorKey
        header: 'PID',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (row.original.pid ? row.original.pid : "N/A"),
      },
      {
        accessorFn: (row) => `${row.amount ? row.amount : ''}`,//accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        id: 'amount', //id is still required when using accessorFn instead of accessorKey
        header: 'Amount',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (row.original.amount ? row.original.amount : "N/A"),
      },
      {
        accessorFn: (row) => `${row.description ? row.description.trim() : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        id: 'description', //id is still required when using accessorFn instead of accessorKey
        header: 'Description',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.description ? row.original.description.trim() : "N/A"}
          </div>
        ),
      },
      {
        accessorFn: (row) => `${row.commission ? row.commission : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        id: 'commission', //id is still required when using accessorFn instead of accessorKey
        header: 'Commission',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (row.original.commission ? row.original.commission : "N/A"),
      },
      {
        accessorKey: 'effective_from',
        accessorFn: (data) => (`${data.effective_from ? data.effective_from : ''}`),
        enableClickToCopy: true,
        id: 'effective_from',
        header: 'Effective From',
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.effective_from != null ? Moment(row.original.effective_from).format('MM-DD-YYYY') : 'N/A'}
          </div>
        ),
      },
      {
        accessorKey: 'email',
        id: 'actions',
        header: 'Actions',
        Cell: ({ row }) => (
          <Box>
            <>
              <ModeEditOutlineOutlinedIcon
                onClick={() => { handleClickOpen1(`${row.original.id}`, `${row.original.pid}`, `${row.original.amount}`, `${row.original.description}`) }}
              /> </>
          </Box>
        ),

      },
    ]
  );
  //csv option start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Commission Rates',
    headers: ['ID', 'PID', 'Amount', 'Description', 'Commission', 'Effective From',],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows = (rows) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      swal({
          title: "Failed",
          text: "This action is unauthorized.",
          icon: "error",
          button: "Ok",
      })
      navigate('/login');
      return false;
    }
    const exportedData = rows.map((row) => {

      return {
        id: `${row.original.id}`,
        pid: `${row.original.pid}`,
        amount: `${row.original.amount}`,
        description: `${row.original.description}`,
        commission: `${row.original.commission}`,
        effective_from: `${row.original.effective_from ? Moment(row.original.effective_from).format('MM-DD-YYYY') : 'N/A'}`,
      };
    });

    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };
  
  const convertToCSV = (data) => {
  
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted

        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }

        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };
  //csv end here

  return (
    <>
      <MaterialReactTable
        columns={CommisionListColumns}
        data={data}
        enableColumnFilterModes
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
        }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        enableRowSelection={false}
        state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
        initialState={{
          showColumnFilters: false,
          density: 'compact',
        }}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        enableColumnFilters={config.DatatableColumnFilter}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            backgroundColor: "#F6FAFF",
          },
        }}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>PID</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Amount</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Description</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Commission</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Effective From</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={row.original.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.original.id}
                    </TableCell>
                    <TableCell align="center">{row.original.pid}</TableCell>
                    <TableCell align="center">{row.original.amount}</TableCell>
                    <TableCell align="center">{row.original.description}</TableCell>
                    <TableCell align="center">{row.original.commission}</TableCell>
                    <TableCell align="center">{Moment(row.original.effective_from).format('MM-DD-YYYY')}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        //top csv option
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              color="primary"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              CSV
            </Button>

          </Box>
        )
        }
      />
      {/* edit popup start here */}
      <>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle id="alert-dialog-title" className="back">
            Edit Commission Rate
          </DialogTitle>
          <Formik
            initialValues={RequestFormInitialValues}
            validationSchema={rateSchema}
            onSubmit={submitRequest}
          >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
            <DialogContent>
              <Grid container item xs>
                <TextField type='hidden' name="rateId" value={values.rateId} sx={{ display: 'none' }} />
                <TextField
                  id="pid"
                  type="number"
                  name='pid'
                  label="PID"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={values.pid}
                  onChange={handleChange('pid')}
                  onBlur={handleBlur('pid')}
                />
                {touched.pid && errors.pid ? <div className='error'>{errors.pid}</div> : null}
              </Grid>
              <Grid container item xs mt={2}>
                <TextField
                  id="amount"
                  name="amount"
                  type="number"
                  label="Amount"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={values.amount}
                  onChange={(e) => {
                    handleChange(e);
                    checkAmountValue(e.target.value);
                  }}
                  onBlur={handleBlur('amount')}
                />
                {((touched.amount && errors.amount) || amountError ) ? <div className='error'>{errors.amount ? errors.amount : amountError}</div> : null}
              </Grid>
              <Grid container item xs mt={2}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="demo-multiple-name-label">Description</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    name='description'
                    id='description'
                    label="Description"
                    value={values.description}
                    onChange={handleChange('description')}
                    onBlur={handleBlur('description')}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="Payment" >Payment</MenuItem>
                    <MenuItem value="Refund" >Refund</MenuItem>
                    <MenuItem value="3-Bureau Credit Report & Scores Payment">3-Bureau Credit Report & Scores Payment</MenuItem>
                    <MenuItem value="chargeback associated to sale" >chargeback associated to sale</MenuItem>
                  </Select>
                  {touched.description && errors.description ? <div className='error'>{errors.description}</div> : null}
                </FormControl>
              </Grid>
              <Grid container item xs mt={2}>
                <TextField
                  id="commission"
                  name="commission"
                  type="number"
                  label="Commission"
                  fullWidth
                  size="small"
                  margin="dense"
                  value={values.commission}
                  onChange={handleChange('commission')}
                  onBlur={handleBlur('commission')}
                />
                {touched.commission && errors.commission ? <div className='error'>{errors.commission}</div> : null}
              </Grid>
              <Grid container item xs mt={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label="Effective From Date*" fullWidth size="small" name="effective_from" defaultValue={dayjs(values.effective_from)}
                      onChange={(effective_from) => { setFieldValue("effective_from", effective_from, true) }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <FormHelperText sx={{ color: "#d32f2f;" }}>{touched.effective_from && errors.effective_from ? errors.effective_from : ''}</FormHelperText>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={handleClose}>Close</Button>
              <Button variant="contained" disabled={createDisabled} color="success" sx={{ minWidth: 200 }} onClick={handleSubmit}>Save</Button>
            </DialogActions>
          </>)}</Formik>
        </Dialog>
      </>
      {/* Edit Popup start here 1 */}
      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="alert-dialog-title" className="back">
          Edit Commission Rate
        </DialogTitle>
        <DialogContent>
          <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
            {/* <Typography>This Combination already exist. Do you want to edit existing one or add new one ?</Typography> */}
            <Typography>Combination of selected PID, amount & description for edit, available multiple times as listed below.</Typography>
          </Grid>
          <TableContainer component={Paper} >
            <Table aria-label="simple table" >
              <TableHead>
                <TableRow>
                  <TableCell><b>PID</b></TableCell>
                  <TableCell><b>Amount</b></TableCell>
                  <TableCell><b>Description</b></TableCell>
                  <TableCell><b>Commission</b></TableCell>
                  <TableCell><b>Effective From Date</b></TableCell>
                  <TableCell><b>Edit</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((record) => (
                  <TableRow
                    key={record.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {record.pid}
                    </TableCell>
                    <TableCell>{record.amount}</TableCell>
                    <TableCell>{record.description}</TableCell>
                    <TableCell>{record.commission}</TableCell>
                    <TableCell>{`${record.effective_from ? Moment(record.effective_from).format('MM-DD-YYYY') : 'N/A'}`}</TableCell>
                    <TableCell>
                      <ModeEditOutlineOutlinedIcon
                        onClick={() => { fetchCommissionRateDetails(`${record.id}`) }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={handleClose1}>Close</Button>
        </DialogActions>
      </Dialog>

    </>
  );

}


export default CommissionRate;