import React, { useMemo, useState, useEffect } from 'react';
import {useParams, useNavigate, Link,useLocation} from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { CardContent, Grid, Box, Tab} from '@mui/material';
import { Card, CardOverflow, Typography, Button } from '@mui/joy';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import PropTypes from 'prop-types';
import Pending from '../../../Components/LeadApplication/Pending';
import RegisteredAffiliates from '../../../Components/LeadApplication/RegisteredAffiliates';
function LeadApplications(props) {
    const { tab } = useParams();
	
	const [value, setValue] = React.useState(tab);

	useEffect(()=>{
		setValue(tab);
	})
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography level="h2">Lead Applications</Typography>
                    <Button color="success" sx={{ borderRadius: "0% !important", backgroundColor: "#76C044" }}>REFRESH DATA</Button>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12} >
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                        <Link to="/leadapplication/pending" value="pending" style={{ textDecoration: 'none' }} >
                                            <Tab label="Pending Applications" value="pending" sx={{ color: 'black' }} />
                                        </Link>
                                        <Link to="/leadapplication/registeredaffiliates" value="registeredaffiliates" style={{ textDecoration: 'none' }} >
                                            <Tab label="Registered Affiliates" value="registeredaffiliates" sx={{ color: 'black' }} />
                                        </Link>
                                    </TabList>
                                </Grid>
                            </Box>
                            <TabPanel value="pending"><Pending tab={tab} /></TabPanel>
                            <TabPanel value="registeredaffiliates"><RegisteredAffiliates tab={tab} /></TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
            </>
        );
    }


    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}

export default LeadApplications;