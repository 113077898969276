import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    InputLabel, MenuItem, FormControl, Select, Box, Grid, TextField, Button, Alert,
     Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText
        } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/joy';
import CustomerDetails from '../../../Components/Reports/CustomerDetails';
import Registrants from '../../../Components/Reports/Registrants';
import SignUpByDay from '../../../Components/Reports/SignUpByDay';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Loader from '../../../Components/Loader/Loader';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import Moment from 'moment';
function ReportPanel(props) {
    const dispatch = useDispatch();
    const [reportOption, setReportoption] = useState(false);
    const {user } = AuthUser();
    const [AidList, SetAidList] = useState([]);
    const [data, setData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [currentDate, setCurrentDate] = useState("");
    const[formData,setFormData]=useState([]);
    const navigate = useNavigate();
   
  
const RequestFormInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    ssn: '',
    aid: AidList[0]
};
    //Fetch reports
    const fetchReport = async(values, { resetForm ,setFieldValue }) => {
       setFormData(values)
        setReportoption(values.reportId);
        setErrorMessage("")
        setIsLoading(true)

      

        const apiUrl = '/getMyReport';
        const args = {
            reportId: values.reportId,
            pid: values.pid,
            adminAid: values.adminAid,
            startDate: new Date(values.startDate).toLocaleDateString(),
            endDate: new Date(values.endDate).toLocaleDateString()
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
       
            if(res) {
               if(res.success==true){
                setData(res.data);
                setIsLoading(false);
                if(res.error != ''){
                    setErrorMessage(res.error);
                    setTimeout(() => {
                        setErrorMessage('');                    
                    }, 5000);               
                }
               }
             else{
                if(res.message == "Unauthenticated"){
                    swal({
                        title: "Alert",
                        text: res.message,
                        icon: "error",
                        button: "Ok",
                    })
                    navigate('/login');
                }else{
                    swal({
                        title: "Alert",
                        text: "Taking too much Time to Load Data,Please try with smaller Date Range to Fetch Records",
                        icon: "error",
                        button: "Ok",
                    })
                    setData([]);
                }
                
                setIsLoading(false);
             }
            }
        resetForm();
        
    }

    //Schema for formik
    const ReportSchema = Yup.object().shape({
        reportId: Yup.string().required('Please select report option'),
        startDate: Yup.string().required('Please select start date'),
        endDate: Yup.string().required('Please select end date'),
    })
    const fetchAidList = async(id) => {
        const apiUrl = '/fetchCompanyAidList/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            SetAidList(res.aidList);
            
        }    
        
         const myDate = new Date();
        
         myDate.setDate(myDate.getDate());
         var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        
         setCurrentDate(cDate);
         
      }
      useEffect(() => {
        fetchAidList(user.company_master_id);
      }, [])
    const handleChange = (event) => {
        setReportoption(event.target.value);
    };
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //Start Request Add Missing Member
    //Schema for formik form
    const RequestFormSchema = Yup.object().shape({
        email:Yup.string().email('Invalid email format.')
                .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
                .required('Please enter your email address.'),
       
    })

    //Submit Request Add Missing Member Form
    const submitRequest = async(values, { resetForm }) => {
        setErrorMessage("")
        setIsLoading(true)
        setDisable(true);
        RequestFormInitialValues.firstName = values.firstName;
        RequestFormInitialValues.lastName = values.lastName;
        RequestFormInitialValues.email = values.email;
        RequestFormInitialValues.ssn = values.ssn;
        RequestFormInitialValues.aid = values.aid;

        const apiUrl = '/createMissingMember';
        const args = {
            aid: values.aid,
            ssn: values.ssn,
            fname: values.firstName,
            lname: values.lastName,
            email: values.email,
            membernotexit: 0,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if (res.success === false) {
                alert(res.message);
                setErrorMessage(res.message)
                setIsLoading(false)
                setTimeout(function () {
                    setErrorMessage('')
                    setDisable(false)
                }, 3000);
                setDisable(false);
                resetForm();
            } else {
                if(res.message == 'Aid_null'){
                    swal({
                        title: "Info",
                        text: "This member has been now associated with the given AID. All set! Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                else if(res.message =='Exists'){
                    swal({
                        title: "Info",
                        text: "This member is already associated with the affiliate account. Nothing to be done. Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                else if(res.message == 'Not_Exists'){
                    swal({
                        title: "Info",
                        text: "This member is already associated with another Affiliate. Investigate and take action accordingly. Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                else if(res.message =='registrants'){
                    swal({
                        title: "Info",
                        text: "The member has not yet completed the enrollment process. Look for any possible duplicate registrations for this member using the last 4 digits of SSN, the first name and last name on support link. If needed, make changes SupportLink to ensure that there is a single valid entry for the registrant. Then ask the member/affiliate to get the registration completed. Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                else if(res.message =='fail')
                {
                    swal({
                        title: "Info",
                        text: "A Member with the given details does not exist! Search using the First Name, Last Name and SSN on SupportLink and look for any misspelt email address. Take a corrective action and then rerun this tool. Thank you.",
                        icon: "info",
                        button: "Ok",
                    })
                }
                RequestFormInitialValues.firstName = '';
                RequestFormInitialValues.lastName = '';
                RequestFormInitialValues.email = '';
                RequestFormInitialValues.ssn = '';
                setIsLoading(false)
                setTimeout(function () {
                    setSuccessMessage('')
                    setDisable(false)
                  }, 3000);
                setDisable(false);
                resetForm();
                handleClose();
            }
        }else{
            setErrorMessage("Something went wrong.")
            setIsLoading(false)
            setTimeout(function () {
                setErrorMessage('')
                setDisable(false)
            }, 3000);
            setDisable(false);
        }
        resetForm();
    }
    function ContentLayout() {
        return (
            <>
                <Box>
                    {successMessage?<Alert severity="success">{successMessage}</Alert>:''}
                    {errorMessage?<Alert severity="error">{errorMessage}</Alert>:''}
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} mb={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12} >
                            <Typography level="h4" color="primary">Reporting</Typography>
                        </Grid>
                        {user.roles_id == 3  || user.roles_id == 4  || user.roles_id == 5? <>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "right" }}>
                                <Button  disabled={user.roles_id > 4 ? true : false}  variant="contained" color="warning" onClick={handleClickOpen}>
                                    Request/Add missing member
                                </Button>

                            </Grid>
                        </> : <>

                        </>}

                    </Grid>
                    <Formik
                        initialValues={{
                            reportId: formData.reportId?formData.reportId:'141',
                            pid: formData.pid?formData.pid:'',
                            adminAid: formData.adminAid?formData.adminAid:AidList[0],
                            startDate: formData.startDate,
                            endDate: formData.endDate
                          }}
                        validationSchema={ReportSchema}
                        onSubmit={fetchReport}
                    >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Report Options</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="reportId"
                                        value={values.reportId}
                                        label="Report Options"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="141">Customer Details</MenuItem>
                                        <MenuItem value="8">Registrants</MenuItem>
                                        <MenuItem value="5">Signups by Day</MenuItem>
                                    </Select>
                                    <FormHelperText>{touched.reportId && errors.reportId ? errors.reportId : ''}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <TextField fullWidth size="small"
                                    id="outlined-basic"
                                    label="PID:"
                                    name='pid'
                                    value={values.pid}
                                    onChange={handleChange}
                                    variant="outlined" />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                {user.roles_id === 3 || user.roles_id === 4 || user.roles_id === 5?
                                    <>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">Select AID</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="adminAid"
                                                value={values.adminAid}
                                                label="Report Options"
                                                onChange={handleChange}
                                            >
                                                  {AidList.map((row, idx) => (
                                                        <MenuItem value={row} key={idx}>{row}</MenuItem>
                                                    ))}
                                                 
                                                

                                            </Select>
                                        </FormControl>
                                    </>
                                    : <>
                                        <TextField fullWidth size="small"
                                            id="outlined-basic"
                                            label="AID :"
                                            name='adminAid'
                                            value={values.adminAid}
                                            onChange={handleChange}
                                            variant="outlined" />
                                    </>
                                }

                            </Grid>
                        </Grid>  
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} >
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <FormControl fullWidth size='small'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                label="Start Date"
                                                disableFuture
                                                name="startDate"
                                                fullWidth
                                                onChange={(value) => {setFieldValue("startDate", value, true) }}
                                                value={values.startDate}
                                                maxDate={dayjs(currentDate)}
                                            />
                                            
                                        </DemoContainer>
                                        <FormHelperText sx={{color:"#d32f2f"}}>{touched.startDate && errors.startDate ? errors.startDate : ''}</FormHelperText>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <FormControl fullWidth size='small'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                label="End Date"
                                                name="endDate"
                                                fullWidth
                                                onChange={(value) => { setFieldValue("endDate", value, true) }}
                                                value={values.endDate}
                                                minDate={values.startDate}
                                                maxDate={dayjs(currentDate)} 
                                                />
                                        </DemoContainer>
                                    
                                        <FormHelperText sx={{color:"#d32f2f"}}>{touched.endDate && errors.endDate ? errors.endDate : ''}</FormHelperText>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item lg={2} md={2} sm={12} xs={12} mt={2}>
                                <Button variant="contained" color="success" type="submit" onClick={handleSubmit} sx={{ minWidth: 250 }}>SUBMIT</Button>
                            </Grid>
                        </Grid>
                    </>)}</Formik>
                    {isLoading ? <><Loader /></> :
                        <>
                            {reportOption === "141" && reportOption != false ? <><CustomerDetails data={data} /></> : ''}
                            {reportOption === "8" && reportOption != false ? <><Registrants data={data} /></> : ''}
                            {reportOption === "5" && reportOption != false ? <><SignUpByDay data={data} /></> : ''}
                        </>}
                    {/* add missing member report popup */}
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                handleClose();
                            }
                        }}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: "#F6FAFF" }}>
                            Member Details
                        </DialogTitle>
                        <Formik
                            initialValues={RequestFormInitialValues}
                            validationSchema={RequestFormSchema}
                            onSubmit={submitRequest}
                        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
                            <DialogContent>
                                <DialogContentText>
                                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                                    <Grid container item xs={12} spacing={2} mt={1}>
                                        <Grid item xs>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Select AID</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="aid"
                                                    value={values.aid}
                                                    label="Report Options"
                                                    onChange={handleChange}
                                                >
                                                    {AidList.map((row, idx) => (
                                                        <MenuItem value={row} key={idx}>{row}</MenuItem>
                                                    ))}

                                                </Select>
                                                <FormHelperText>{touched.aid && errors.aid ? errors.aid : ''}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField 
                                            fullWidth 
                                            size="small" 
                                            id="firstName" 
                                            label="First Name" 
                                            variant="outlined"
                                            name='firstName'
                                            value={values.firstName}
                                            onChange={handleChange}
                                           
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField 
                                            fullWidth 
                                            size="small" 
                                            id="outlined-basic" 
                                            label="Last Name" 
                                            variant="outlined" 
                                            name='lastName'
                                            value={values.lastName}
                                            onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2} mt={1}>
                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                id="outlined-basic"
                                                label="Email Id*"
                                                variant="outlined"
                                                name='email'
                                                value={values.email}
                                                onChange={handleChange}
                                                helperText={touched.email && errors.email ? errors.email : null}
                                                error={touched.email && errors.email ? errors.email : null}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TextField 
                                            fullWidth 
                                            size="small" 
                                            id="outlined-basic" 
                                            label="Last 4-digit SSN"
                                            name='ssn'
                                            value={values.ssn}
                                            onChange={handleChange}
                                           
                                            variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={3}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{ minWidth: 250 }} onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{ minWidth: 250 }} onClick={disable ? "" : handleSubmit} color="success" type="submit">
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>

                        </>)}</Formik>
                    </Dialog>
                </Box>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default ReportPanel;