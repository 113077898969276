import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box,Collapse, Grid,Divider,Table,TableCell,FormControl,Dialog ,DialogActions, Alert ,DialogContentText ,DialogTitle ,TableRow,InputLabel, Button ,OutlinedInput,
    DialogContent ,MenuItem,FormLabel, FormGroup,FormControlLabel,Checkbox,Radio,RadioGroup,FormHelperText, Stack,Select,Modal, Tooltip,TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import AuthUser from "../../../Components/Auth/AuthUser";
import { Typography,Card,CardContent,CardOverflow, Option,  } from '@mui/joy';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import config from '../../../AppConfig';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/HighlightOff';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import LinkIcon from '@mui/icons-material/Link';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';
import InputMask from 'react-input-mask';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import swal from "sweetalert";
import moment from 'moment';

//boostrap dialog 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  let callTypeId = '';
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const markCompleteReasonArr = [
    "Walkthrough Completed",
    "Received Links But No Walkthrough",
    "Affiliate Doesn't need Walkthrough",
    "Automated Via Enrollment/CS/RP/CB",
    "Cleanup",
    "Other"
]

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CrcReport(props) {  
    const navigate = useNavigate();

    let msg = '<p>Dear Credit Hero,</p>'+
            '<p>We are looking forward to working with you, but we could not successfully reach you by phone.</p>'+
            '<p>We are following up on the unique Affiliate links (URLs) we emailed you on ____. Have you been able to upload the link (s) into Credit Repair Cloud (CRC)? Remember to also add MyFreeScoreNow as a Primary Report Provider to make sure that your clients will see us as an option when they go to retrieve their 3-Bureau reports.</p>'+
            '<p>As a test, enroll yourself to make sure that your links are working and that you see MyFreeScoreNow as the Primary Report Provider.</p>'+
            '<p>If you have any questions about the process or if there is anything we can do to help you get started, please feel free to contact us:</p>'+
            '<p>info@myfreescorenow.com</p>'+
            '<p>888-548-2008</p>';
    
  
    const [personName, setPersonName] = React.useState([]);
    const [editorData, handleChangeEditor] = useState(msg);
    const dispatch = useDispatch();
    const [currentFollowUpDate, setFollowUpDate] = useState("");
    const [currentFollowUpTime, setFollowUpTime] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [contactTypeList, setCallTypeList] = useState([]);
    const [whoContactedVal, setWhoContactedVal] = useState("Affiliate");
    const [callTypeList, setCallTypeDataList] = useState([]);
    const [dispositionTypeList, setDispositionList] = useState([]);
    const [dispositionVal, setdisVal] = useState(false);
    const [errors1, setErrors] = useState({});
    const [outcomeList, setOutcomeList] = useState([]);
    const [formatedCurrentDate, setFormatedCurrentDate] = useState("");


    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
   
    
    function followupChange(val,setFieldValue) {
        // console.log(val);
        if(val === 'No'){
            const element = document.getElementById("noFollowup");
            element.style.display= "none";
              // Set default value for followupDate to the current date
            setFieldValue("followupDate", dayjs(currentFollowUpDate));
        }else{
            const element = document.getElementById("noFollowup");
            element.style.display= "block";
        }
    }
    const handleCallType = (event) => {
        if(event === 'Email'){
            const element = document.getElementById("emailEditor");
            element.style.display= "block";
        }else{
            const element = document.getElementById("emailEditor");
            element.style.display= "none";
        }
    };

    const handleComplete = (value) => {
        if(value == 'Walkthrough Completed'){
            const element = document.getElementById("ConatctReason");
            element.style.display= "block";
        }else{
            const element = document.getElementById("ConatctReason");
            element.style.display= "none";
        }

        if(value == 'Other'){
            const element = document.getElementById("otherReason");
            element.style.display= "block";
        }else{
            const element = document.getElementById("otherReason");
            element.style.display= "none";
        }
    }

    const activitySchema = Yup.object().shape({
        callType: Yup.string()
                .required('Please select Type'),
        followupNotes: Yup.string()
                    .required('Please Note The Activity'),
        followupRequired: Yup.string().required('Please enter reason to disable.'),
        followupTime: Yup.string().when("followupRequired",{
            is: (followupRequired) => followupRequired == 'Yes',
            then: () => Yup.string().test(
                      'is-valid-time',
                      'Invalid time format. Please enter a valid HH:mm:ss time.',
                      (value) => {
                        const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
                        return regex.test(value);
                      }
                    ),
        }),
        followupDate: Yup.string().when("followupRequired",{
            is: (followupRequired) => followupRequired == 'Yes',
            then: () => Yup.string().test(
                'is-valid-year',
                      'Invalid Follow Up Date',
                      function (value) {
                        const date = new Date(value);
                        // console.log("date"+date)
                        let formatedData1=Moment(date).format('MM-DD-YYYY');
                        // console.log("formatedData1"+formatedData1)
                        const currentDate = new Date();
                        const year = date.getFullYear();
                        const formattedCurrentDate = Moment(currentDate).format('MM-DD-YYYY');
                        // console.log("year"+year)
                        const splitYear = formatedCurrentDate.split('-')[2];
                        // console.log("splitYear"+formatedCurrentDate)
                        // return  formatedData1 >= splitYear
                        return Moment(formatedData1, 'MM-DD-YYYY').isSameOrAfter(formattedCurrentDate, 'day');
                        
                      }
                    ),
        }),

    });

    const shelveApplication = Yup.object().shape({
        disableReason: Yup.string()
                .required('Please enter reason to disable.')
    })

    const markComplete = Yup.object().shape({
        completeReason: Yup.string().required('Please enter reason to disable.'),
        note: Yup.string().when("completeReason",{
            is: (completeReason) => completeReason == 'Other',
            then: () => Yup.string().required('Please note completion reason.')
        }),
        calltype: Yup.string().when("completeReason",{
            is: (completeReason) => completeReason == 'Walkthrough Completed',
            then: () => Yup.string().default('Affiliate').required('Please enter Call Type.')
        }),
        disposition: Yup.string().when("completeReason",{
            is: (completeReason) => completeReason == 'Walkthrough Completed',
            then: () => Yup.string().required('Please enter Disposition.'),
        }),  
        outcome: Yup.string().when("completeReason",{
            is: (completeReason) => completeReason == 'Walkthrough Completed',
            then: () => Yup.string().required('Please enter Out come.'),
        }),   
        CallSource: Yup.string().when("completeReason",{
            is: (completeReason) => completeReason == 'Walkthrough Completed',
            then: () => Yup.string().required('Please enter Contact Type.'),
        }),
           
    });
    const deferredApplication = Yup.object().shape({
        deferredReason: Yup.string()
                .required('Please enter reason to defer.'),
        deferredDate: Yup.string()
                .required('Please Select Date'),
        deferredTime: Yup.string()
                .required('Please Select Time'),
    })
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [openMarkComplete, setOpenMarkComplete] = React.useState(false);
    const [openShelve, setOpenShelve] = React.useState(false);
    const [openDefer, setOpenDefer] = React.useState(false);
    const theme = useTheme();
    const { http, user } = AuthUser();
    const { id } = useParams();
    const [statusMessage, setStatusMesage] = useState('');
    const [companyId, setCompanyId] = useState();
    const [actionDropdown, setActionDropdown] = useState([]);
    const [openstatusMessage, setOpenStatusMessage] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [disable, setDisable] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [fullWidth, setFullWidth] = React.useState(true);
    const handleMaxWidthChange = (event) => {
        setMaxWidth(
          // @ts-expect-error autofill of arbitrary value is not handled.
          event.target.value,
        );
      };
    
      const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
      };
    const handleClickOpen = async() => {
        const newDate = new Date();
        const myDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        myDate.setDate(myDate.getDate());
        var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        // console.log("cDate :"+cDate)
        var todaysTime = Moment(newDate).tz('America/Los_Angeles').format('hh:mm:ss');
        setFollowUpDate(todaysDate);
        setFollowUpTime(todaysTime);
        setCurrentDate(cDate);
        
        const apiUrl = '/featureStatusCheck/' + companyId;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if(res.success == true) {
                if(Object.keys(res.data).length > 0) {
                    const arr = []; 
                    // const actions = res.data;
                    // arr['cobranding'] = actions.cobranding ?   actions.cobranding : '';
                    // arr['creditsnapshot'] =  actions.creditsnapshot ?  actions.creditsnapshot : '';
                    // arr['referralprogram'] = actions.referralprogram ?  actions.referralprogram : '';
    
                    const actionArray = Object.entries(res.data).map(([key, value]) => ({
                        key,
                        value
                      }));
                    setActionDropdown(actionArray);
                }

                setOpen(true);
            }else{
                swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
                if(res.message == "This action is unauthorized.") {
                    navigate('/login');
                }
            }
            
       
        }
       
      
    };



    const handleClose = () => {
        setOpen(false);
    };
    const refreshpop = () => {
        singleRefresh()
        setOpen2(true);
    };

    const refreshClose = () => {
        setOpen2(false);
    };
    const markascomplete = () => {
        setOpen3(true);
    };

    const markascompleteClose = () => {
        setOpen3(false);
    };
    const ShelveApplication = () => {
        setOpenShelve(true);
    };

    const ShelveApplicationClose = () => {
        setOpenShelve(false);
    };
    const DeferredApplication = () => {
        setOpenDefer(true);
    };

    const DeferredApplicationClose = () => {
        setOpenDefer(false);
    };

    //Fetch lead data
    const [leadDetails, setleadDetails] = useState([]);
    const [next_status, setNextStatus] = useState([]);
    
    const fetchLeadDetails = async() => {

        const apiUrl = '/leadDetails/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            setleadDetails(res.data);
            setNextStatus(res.next_status); 
            setCompanyId(res.data.company_master_id); 
        }
    }

    // Single Refresh To Get Details and Status From Pandadoc 
    const singleRefresh = (async() => {
        setErrorMessage('');
        setDisable(true)
        setOpenStatusMessage(true);
        setStatusMesage('Refreshing...');

        const apiUrl = '/signleRefresh';
        const args = {
            document_id: leadDetails.pandadoc_id, 
            affref_id: leadDetails.id, 
            company_id: leadDetails.company.id
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if (res.success === true) {
                fetchLeadDetails();
                setStatusMesage(res.message);
                setTimeout(function(){
                    setStatusMesage('');
                    setDisable(false)
                }, 3000);
            } else {
                if(res.message == "This action is unauthorized.") {
                    swal({
                        title: "",
                        text: res.message,
                        title: "Failed",
                        button: "Ok",
                    })
                    navigate('/login');
                }
                fetchLeadDetails();
                setStatusMesage(res.message);
                setTimeout(function(){
                    setErrorMessage('');
                    setDisable(false)
            }, 3000);
            }
        }
    });

    // Send Agreement To Affiliate Using Pandadoc 
    const sendAgreement = (async() => {
        setDisable(true)
        setStatusMesage('Sending Agreement...');

        const apiUrl = '/sendAgreement';
        const args = {
            id: id
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if(res.success === true){
                fetchLeadDetails();
                setStatusMesage(res.message.message);
                setTimeout(function(){
                    setStatusMesage('');
                    setDisable(false)
            }, 3000);
            }else{
                fetchLeadDetails()
                setErrorMessage(res.message.message);
                setStatusMesage('');
                setTimeout(function(){
                    setErrorMessage('');
                    setDisable(false)
            }, 3000);
            }
        }
    });

    const changeLabels =(values, statusValue) => {
        if(statusValue == "Deferred") {
            values.followUpDateLabel = "Deferred Till Date";
            values.followUpTimeLabel = "Deferred Till Time";
        }else{
            values.followUpDateLabel = "Follow Up Date";
            values.followUpTimeLabel = "Follow Up Time";
        }
    }
    useEffect(() => {
        if(user.roles_id < 3 || user.roles_id===13){
            fetchLeadDetails();
        }else{
            navigate('/login');
        }
        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var CurrentFormatedDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YYYY'); 
        setCurrentDate(cDate);
        setFormatedCurrentDate(CurrentFormatedDate)
    },[])

    let btnRef = useRef();
    const restoreLead = async(id) => {
        
        const apiUrl = '/restore/'+id;
        const args = {}
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if(res.success === true){
				navigate('/manageleads/hot-leads',{success: "Lead Restored Successfully"});
			}else{
				swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
				if(res.message == "This action is unauthorized.") {
					navigate('/login');
				}
			}
        }
	}

    const sendEmail = async (affrefId) =>{
        const apiUrl = '/sendWelcomeEmail';
        const args = {
            affrefId:affrefId,
            url:config.BASE_URL
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if(res.success === true){
                setOpenStatusMessage(true);
                setStatusMesage(res.message);
                setTimeout(function(){
                    setStatusMesage('');
                    setDisable(false)
                }, 3000);
			}else{
                swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
				if(res.message == "This action is unauthorized.") {
					navigate('/login');
				}
                setOpenStatusMessage(true);
                setErrorMessage('');
                setTimeout(function(){
                    setStatusMesage('');
                    setDisable(false)
                }, 3000);
            }
            
        }

    }

    const getPandaDoc = async (id) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
		window.open(
			config.BASE_URL+"AffiliateAgreement/"+id+"/YWZmaWxpYXRl"+"/2" ,
						'_blank' // <- This is what makes it open in a new window.
					);
		
	}
	const getPandaDocNew = async (name) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
		const apiUrl = `/get-pdf/${name}`;
		window.open(config.API_BASE_URL+apiUrl); 
		
	}
    const fetchCallTypeLists = async () => {
        const apiUrl = '/fetchContactLogCallType'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
          setCallTypeList(res);
        }
    }
    const fetchCallTypeList = async (contactType) => {
        handleFreezStart();
    
        const apiUrl = '/fetchCallTypeList';
        const args = {
          contactTypes: contactType,
        };
    
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
          handleFreezStop();
          setCallTypeDataList(res);
        }
      };
      //get Disposition
    const handleCallTypes = async(event) => {
        setdisVal(false);
        callTypeId = event;
        handleFreezStart();

        const apiUrl = '/fetchCallType';
        const args = {
            callType: callTypeId,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            setDispositionList(res);
            handleFreezStop();
            // setIsLoading(false)
        }
    };
     //get Outcome
    const getOutcome = async(id, disposition) => {
        // if (disposition == 'Cancelled' || disposition == 'Saved') {
        //     setdisVal(true);

        // } else {
        //     setdisVal(false);
        // }

        disposition = id;
        handleFreezStart();
        errors1.aid = '';
        setErrors(errors1);
    
        const apiUrl = '/getoutcome';
        const args = {
            disposition: disposition,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            setOutcomeList(res);
            handleFreezStop();
            // setIsLoading(false)
        }
    };
    
      useEffect(() => {
        fetchCallTypeList(whoContactedVal);
        fetchCallTypeLists();
        handleCallTypes(65);
        getOutcome(141)
      }, []);
     

    function ContentLayout() {
        return (
            <>
            {leadDetails.first_name ?
            <Box>
                <Grid container direction="row" sx={{justifyContent:'space-between'}}>
                    <Grid item lg={5} md={5} sm={12} xs={12}><Typography level="h4" color="primary">Leads Details & Follow Up</Typography></Grid>
                    <Grid item container lg={7} md={7} sm={12} xs={12}>
                        <Grid item xs={11}>
                            <Card variant="outlined" size="sm" fullWidth>
                                <CardContent>
                                    <Grid container textAlign={'center'} item md={12}>
                                        <Grid item xs={6}>
                                            <Typography level="h5">Status: <Typography level='body1'>{leadDetails ? leadDetails.status ? leadDetails.status.status_name : '': ''}</Typography>&nbsp;
                                            {
                                                leadDetails.is_active == 0
                                                ?
                                                <>
                                                    <Typography color="danger">Disabled</Typography>
                                                    <Tooltip title="Restore" arrow><img src={require('../../../assets/images/restore.png')} onClick={()=>{restoreLead(leadDetails.id)}} /></Tooltip>                                                    
                                                </>
                                                :
                                                null
                                            }
                                            </Typography>
                                        </Grid> 
                                        <Grid item xs={6}>
                                            <Typography level="h5">Next Follow up: 
                                                <Typography level='body1'> {leadDetails.last_activity_logs ? leadDetails.last_activity_logs.add_next_followup?Moment(leadDetails.last_activity_logs.add_next_followup).format('MM-DD-YY hh:mm')+'Hrs' : 'N/A' : 'N/A' }</Typography>
                                            </Typography>
                                        </Grid> 
                                    </Grid>
                                </CardContent>
                            </Card>                            
                        </Grid>
                        <Grid item xs={1} textAlign="right" sx={{justifyContent:"center"}}>
                            <Tooltip title="Close" >
                                <BackIcon fontSize="large" sx={{cursor:'pointer'}} onClick={() => navigate(-1)} />                            
                            </Tooltip>                            
                        </Grid>
                    </Grid>                
                </Grid>
                <Grid container direction="row" spacing={2} pt={2}>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <Card variant="outlined" size="lg">                            
                            <CardOverflow
                                sx={{
                                    bgcolor:'#F6FAFF',
                                    borderColor:'#F6FAFF',
                                    padding: '1%',
                                }}
                            >
                                <Typography level="h5" sx={{ mb: 0.5 }}>Company Details</Typography>
                            </CardOverflow>
                            <CardContent>
                                <Table>
                                    <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                            <TableCell>{leadDetails.first_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                        <TableCell>{leadDetails.last_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Company Name:</TableCell>
                                        <TableCell>{leadDetails.company.company_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Company Website:</TableCell>
                                        <TableCell>{leadDetails.company.company_website}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Title:</TableCell>
                                        <TableCell>{leadDetails.company.title}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Year Business Started:</TableCell>
                                        <TableCell>{leadDetails.company.business_started_year}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Email:</TableCell>
                                        <TableCell>{leadDetails.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
                                        <TableCell>{formatPhoneNumber(leadDetails.phone_no)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>State:</TableCell>
                                        <TableCell>{leadDetails.state_code}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>City:</TableCell>
                                        <TableCell>{leadDetails.city}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Zip Code:</TableCell>
                                        <TableCell>{leadDetails.zip_code}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Referred By:</TableCell>
                                        <TableCell>{leadDetails.company.referred_by_company ? leadDetails.company.referred_by_company.company_name : leadDetails.company.referred_by}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Software Used:</TableCell>
                                        <TableCell>{leadDetails.company.marketing_type}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Agreement Id: </TableCell>
                                        <TableCell>{leadDetails.pandadoc_id}
                                   {user.roles_id!=13?<>
                                    <LinkIcon color="primary" onClick={()=>{
                                            // {getPandaDoc(leadDetails.pandadoc_id) }
                                            {leadDetails.reference_affiliate_status_master_id==4||leadDetails.reference_affiliate_status_master_id==5?getPandaDocNew(leadDetails.company.company_name):getPandaDoc(leadDetails.pandadoc_id) }
                                        }}/></>:null}
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                       { user.roles_id!=13?
                        <Card variant="plain" size="lg" sx={{paddingX:"5%",minHeight: 120,boxShadow:'none'}}>
                            <Grid container  item md={12} sx={{justifyContent:'space-between'}}>
                                {
                                    leadDetails.is_active != 0
                                    ?
                                    <Button color="warning" variant="contained"  onClick={ShelveApplication}>SHELVE APPLICATION</Button>
                                    :
                                    null
                                }
                                {
                                    leadDetails.status ? leadDetails.status.id != 5
                                    ?
                                    <Button color="warning" variant="contained" disabled={leadDetails.is_active == 0 ? true : false} onClick={DeferredApplication}>DEFER APPLICATION</Button>
                                    :
                                    null  : null
                                }
                            </Grid>
                        </Card>:null}
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                        {statusMessage ? 
                        <Collapse in={openstatusMessage}>
                            <Alert
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenStatusMessage(false);
                                    }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >{statusMessage}</Alert> 
                        </Collapse>
                            : null
                        }

                        <Card variant="plain" size="sm"  sx={{boxShadow:'none'}}>
                            <Grid container  item md={12} sx={{justifyContent:'space-between'}}>
                            <Typography level="h5" color="primary">ACTIVITY LOG</Typography>
                      { user.roles_id!=13?
                               <>
                                <Button 
                                    variant="contained" 
                                    color='success' 
                                    onClick={handleClickOpen} 
                                    disabled={leadDetails.is_active == 0 ? true : false}
                                >New Activity</Button> 
                                {(() => {
                                    if (leadDetails.reference_affiliate_status_master_id === 1) {
                                    // return (
                                    //     <Button 
                                    //         color="success" 
                                    //         variant="contained"  
                                    //         onClick={sendAgreement}
                                    //         disabled={leadDetails.is_active == 0 ? true : false}
                                    //     >Send Agreement</Button>
                                    // )
                                    }
                                    //  else if(leadDetails.reference_affiliate_status_master_id === 2) {
                                    //     return(
                                    //         <Button 
                                    //             color="success" 
                                    //             variant="contained"  
                                    //             onClick={singleRefresh}
                                    //             disabled={leadDetails.is_active == 0 ? true : false}
                                    //         >Refresh</Button>
                                    //     )
                                    // }
                                    else if (leadDetails.reference_affiliate_status_master_id === 3) {
                                    return (
                                        <Button 
                                            href={"/crcreport/approve-now/"+leadDetails.id}  
                                            color="success" 
                                            variant="contained" 
                                            disabled={leadDetails.is_active == 0 ? true : false}
                                        >Approve now</Button>
                                        
                                    )
                                    } else if (leadDetails.reference_affiliate_status_master_id === 4) {
                                    return (
                                        <>
                                        <Button 
                                            color="success" 
                                            variant="contained"  
                                            onClick={()=>sendEmail(leadDetails.id)} 
                                            disabled={leadDetails.is_active == 0 ? true : false}
                                        >Send Email</Button>
                                        <Button 
                                            color="success" 
                                            className="text-white" 
                                            variant='contained' 
                                            onClick={()=>setOpenMarkComplete(true)}
                                            disabled={leadDetails.is_active == 0 ? true : false}
                                        >Mark As Completed</Button>
                                        </>                                        
                                    )
                                    }
                                })()}
                                </>:null}
                            </Grid>                            
                        </Card>
                    {leadDetails.activity_logs.map((logs, idx, array)=>{
                        return(
                            <>
                            <Card variant="outlined" size="lg" sx={{marginTop:2}} >
                                <CardOverflow
                                    sx={{
                                        bgcolor:'#F6FAFF',
                                        borderColor:'#F6FAFF',
                                        padding: '1%',
                                    }}
                                >
                                    <Grid container  item md={12} sx={{justifyContent:'space-between'}}>
                                        <Typography>
                                            <Typography level="h6" sx={{ mb: 0.5 }}>{logs.type?logs.type:'Staff'} {array.length - idx} : &nbsp;</Typography>
                                            <Typography >{logs.user?logs.user.first_name+' '+logs.user.last_name:'N/A'} </Typography>                                            
                                        </Typography>
                                        <Typography>{logs.created_at ?  Moment(logs.created_at).tz('America/Los_Angeles').format('MM-DD-YY hh:mm')+'Hrs' : 'N/A' }  </Typography>
                                    </Grid>
                                </CardOverflow>
                                <CardContent>
                                    <Grid container  item md={12} p={2}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Typography level="h6">Status: &nbsp;
                                                <Typography level="body1">{logs.status ? logs.status.status_name : 'N/A'}</Typography>
                                            </Typography>
                                        </Grid> 
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Typography level="h6">Next Status: &nbsp;
                                                <Typography level="body1">{logs.status ? logs.status.id != 5 ? next_status.find((item) => item.id === logs.status_id+1).status_name : 'N/A' : 'N/A'}</Typography>
                                            </Typography>
                                        </Grid> 
                                    </Grid>
                                    <Divider />
                                    <Grid container  item md={12} pt={2}>
                                        <Typography level="h6">Note:</Typography>                                                                             
                                    </Grid>
                                    <Grid container  item md={12} pt={1}>
                                        <Typography level="body1">{logs.notes}</Typography>  
                                    </Grid>
                                </CardContent>
                            </Card>
                            </>
                        );
                    })}
                    </Grid>
                </Grid>
                {/* new activity start */}
                <Formik
                    initialValues={{
                        callType: '',
                        action: '',
                        status: '',
                        emailEditor: editorData,
                        followupNotes: '',
                        keepHotLeads: ['on'],
                        followupRequired: 'Yes',
                        // followupDate: dayjs().add(1, 'day'),
                        // followupTime: dayjs(),  
                        followupDate: dayjs(currentFollowUpDate),
                        followupTime: (currentFollowUpTime),  
                        followUpDateLabel : 'Follow Up Date',
                        followUpTimeLabel : 'Follow Up Time'  
                    }}
                    validationSchema={activitySchema}
                    onSubmit={async(values,errors) => { 
                        const date = new Date(values.followupDate);
                        const day = String(date.getDate()).padStart(2, '0');
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const year = date.getFullYear();
                        var mydate = year+"/"+month+"/"+day;
                        var todaysTime = values.followupTime;
                        const apiUrl = '/addFollowupLead';
                        const args = {
                            affref_id : leadDetails.id,
                            followupRequired : values.followupRequired,
                            followupNotes : values.followupNotes,
                            followupDate : mydate,
                            followupTime : values.followupTime,
                            keepHotLeads : values.keepHotLeads,
                            callType : values.callType,
                            feature : values.action,
                            feature_status : values.status,
                            statusId : leadDetails.status.id,
                            inputEmail : leadDetails.email,
                            emailEditor: values.emailEditor,
                        }
                        const res = await dispatch(postData({apiUrl,args})).unwrap();
                        if(res) {
                            if(res.success === true){
                                setOpen(false);
                                fetchLeadDetails();                                                            
                            }else{
                                swal({
                                    title: "Failed",
                                    text: res.message,
                                    icon: "error",
                                    button: "Ok",
                                })
                                if(res.message == "This action is unauthorized.") {
                                    navigate('/login');
                                }
                                
                            }
                        }
                    }}
                >
                    {({values,errors,touched,isSubmitting,handleChange,handleBlur,handleSubmit,setFieldValue}) =>(
                        <Dialog
                            maxWidth='md'
                            disableEscapeKeyDown={true}
                            fullScreen={fullScreen}
                            open={open}
                            onClose={(_, reason) => {
                                if (reason !== "backdropClick") {
                                    handleClose();
                                }
                            }}                           
                            aria-labelledby="responsive-dialog-title"
                            >
                            <DialogTitle textAlign={'center'} id="responsive-dialog-title" sx={{backgroundColor:"#F6FAFF"}}>
                                <Typography level="h4" color='primary'>New Follow Up</Typography>
                            </DialogTitle>
                            <Form>
                            <DialogContent>
                                <Box mt={1}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>                                                                      
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControl fullWidth size="small"
                                            error={touched.callType && errors.callType ? errors.callType : ''}
                                            >
                                                <InputLabel>Select Type *</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="callType"
                                                    name="callType"
                                                    value={values.callType}
                                                    label="Select Type *"
                                                    onBlur={handleBlur('callType')}
                                                    onChange={(e)=>{
                                                        handleCallType(e.target.value);
                                                        handleChange(e);
                                                    }}
                                                >
                                                    <MenuItem value="Call">Call</MenuItem>
                                                    <MenuItem value="Email">Email</MenuItem>
                                                    <MenuItem value="Text">Text</MenuItem>
                                                </Select>
                                                <FormHelperText>{touched.callType && errors.callType ? errors.callType : ''}</FormHelperText>
                                            </FormControl>
                                        </Grid>  
                                            {
                                            leadDetails.status ? leadDetails.status.id == 5 && actionDropdown.length > 0 ? 
                                            <>
                                                <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                    <FormControl fullWidth size="small" margin="dense"
                                                        // error={touched.callType && errors.callType ? errors.callType : ''}
                                                        >
                                                        <InputLabel>Select Action</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="action"
                                                            name="action"
                                                            margin="dense"
                                                            value={values.action}
                                                            label="Select Action"
                                                            onBlur={handleBlur('action')}
                                                            onChange={handleChange('action')}
                                                        >
                                                            {actionDropdown.map((row) => (
                                                                <MenuItem value={row.key}>{row.value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {/* <FormHelperText>{touched.callType && errors.callType ? errors.callType : ''}</FormHelperText> */}
                                                    </FormControl>
                                                </Grid> 
                                            </> : ''  : ''
                                         } 

                                         {
                                            leadDetails.status ? leadDetails.status.id == 5 && actionDropdown.length > 0 ? 
                                            <>
                                                <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                    <FormControl fullWidth size="small" margin="dense"
                                                        // error={touched.callType && errors.callType ? errors.callType : ''}
                                                        >
                                                        <InputLabel>Select Status</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="status"
                                                            name="status"
                                                            margin="dense"
                                                            value={values.status}
                                                            label="Select status"
                                                            onBlur={handleBlur('status')}
                                                            // onChange={handleChange('status')}
                                                            onChange={(e)=>{
                                                                handleChange(e);
                                                                changeLabels(values, e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value="Initiated">Initiate</MenuItem>
                                                            <MenuItem value="Declined">Declined</MenuItem>
                                                            <MenuItem value="Deferred">Deferred</MenuItem>
                                                        </Select>
                                                        {/* <FormHelperText>{touched.callType && errors.callType ? errors.callType : ''}</FormHelperText> */}
                                                    </FormControl>
                                                </Grid> 
                                            </> : ''  : '' 
                                         }                                     
                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={1} id="emailEditor" sx={{display:'none'}}>
                                            <Button variant='contained' color='success' onClick={handleChangeEditor(msg)}>Email Reset To Default</Button>
                                            <FormControl fullWidth size="small"
                                                error={touched.emailEditor && errors.emailEditor ? errors.emailEditor : ''}
                                            >
                                                <CKEditor 
                                                    editor={ ClassicEditor }
                                                    required
                                                    name="emailEditor"
                                                    onChange={ ( event, editor ) => {
                                                        const data = editor.getData();
                                                        values.emailEditor = data;
                                                        handleChange('emailEditor');
                                                    } }
                                                    onBlur={ ( event, editor ) => {                                                        
                                                        handleBlur('emailEditor');
                                                    } }
                                                    data={values.emailEditor} 
                                                    value={values.emailEditor}
                                                />
                                                <FormHelperText>{touched.emailEditor && errors.emailEditor ? errors.emailEditor : ''}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                                            <FormControl fullWidth size="small"
                                                error={touched.followupNotes && errors.followupNotes ? errors.followupNotes : ''}
                                            >
                                                <TextareaAutosize
                                                    style={{ width: "100%" }}
                                                    required
                                                    margin="dense"
                                                    aria-label="minimum height"
                                                    minRows={6}
                                                    placeholder="Enter Note*"
                                                    name="followupNotes"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.followupNotes}
                                                />
                                                <FormHelperText>{touched.followupNotes && errors.followupNotes ? errors.followupNotes : ''}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>  
                                            <Stack direction="row">                                          
                                                <Grid item  lg={7} md={7} sm={12} xs={12}>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                        {/* <Stack direction="row"> */}
                                                        <FormControl >
                                                            <FormLabel id="">Follow up Required :</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="followupRequired"
                                                                value={values.followupRequired}
                                                                onChange={(e)=>{
                                                                    followupChange(e.target.value,setFieldValue);
                                                                    handleChange(e)
                                                                }}
                                                            >                                                                      
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                <FormHelperText>{touched.followupRequired && errors.followupRequired ? errors.followupRequired : ''}</FormHelperText>
                                                            </RadioGroup>
                                                        </FormControl>
                                                        {/* </Stack> */}
                                                    </Grid>                                                
                                                </Grid>
                                                {
                                                    leadDetails.status ? leadDetails.status.id == 1 || leadDetails.status.id == 2 || leadDetails.status_id == 3
                                                    ?
                                                    <Grid item  lg={5} md={5} sm={12} xs={12}>
                                                        <FormGroup>
                                                            <FormControlLabel 
                                                            control={<Checkbox defaultChecked onChange={handleChange} />} 
                                                            label="Keep in hot-leads:" name="keepHotLeads"  />
                                                            <FormHelperText>{touched.keepHotLeads && errors.keepHotLeads ? errors.keepHotLeads : ''}</FormHelperText>
                                                        </FormGroup>
                                                    </Grid>
                                                    :
                                                    null 
                                                    :
                                                    null
                                                } 
                                            </Stack>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} id="noFollowup">
                                            <Stack direction="row" spacing={3}>
                                            {/* <Grid item xs direction={'row'} sx={{justifyContent:'center'}}> */}
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DemoContainer components={['DatePicker']}  fullWidth>
                                                            <DatePicker label={values.followUpDateLabel + "*"} 
                                                                margin="dense"
                                                                // onChange={handleChange} 
                                                                onChange={(value) => {setFieldValue("followupDate", value, true)}}
                                                                onBlur={handleBlur}
                                                                value={values.followupDate} 
                                                                name="followupDate" 
                                                                minDate={dayjs(currentDate)}
                                                                maxDate={dayjs().add(75, 'year')}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    <FormHelperText style={{ color: touched.followupDate && errors.followupDate ? 'red' : 'inherit' }}>
                                                        {touched.followupDate && errors.followupDate ? errors.followupDate : ''}
                                                    </FormHelperText>
                                                    {/* <FormHelperText>{touched.followupDate && errors.followupDate ? errors.followupDate : ''}</FormHelperText> */}
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['TimePicker']}  fullWidth size="small">
                                                            <TimePicker label={values.followUpTimeLabel + "*"} 
                                                                margin="dense"
                                                                format="hh:mm:ss" 
                                                                value={values.followupTime} 
                                                                name="followupTime" 
                                                                onChange={(value) => {setFieldValue("followupTime", value, true)}}
                                                                onBlur={handleBlur}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    
                                                    */}
                                                    <InputMask
                                                    mask='99:99:99'
                                                    label={values.followUpTimeLabel + "*"}
                                                    maskChar={null}
                                                    name='followupTime'
                                                    fullWidth
                                                    margin="dense"
                                                    // size="small"
                                                    id='followupTime'
                                                    value={values.followupTime}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label={values.followUpTimeLabel + "*"} variant="outlined"
                                                            {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                                {
                                                touched.followupTime && errors.followupTime ? (
                                                    <div className='error'>{errors.followupTime}</div>
                                                ) : (
                                                    ''
                                                )
                                                }
                                        
                                                    {/* <FormHelperText>{touched.followupTime && errors.followupTime ? errors.followupTime : ''}</FormHelperText> */}
                                                </Grid>
                                            {/* </Grid> */}
                                            </Stack>
                                        </Grid>
                                    </Grid> 
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"center",width:"100%"}}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button  autoFocus variant="contained" sx={{minWidth:150}} onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{minWidth:150}} color="success" type="submit" onClick={handleSubmit} disabled={ (isSubmitting) }>
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                            </Form>
                        </Dialog>

                    )}

                </Formik>
                {/* new activity end */}

                {/* mark as cimpleted start */}
                <Formik
                    initialValues={{
                        completeReason: '',
                        note: '',
                        calltype:'65',
                        disposition:'141',
                        outcome:'320',
                        CallSource:'',
                    }}
                    validationSchema={markComplete}
                    onSubmit={async(values, error)=>{

                        const apiUrl = '/markAsComplete';
                        const args = {
                            affref_id : leadDetails.id,
                            completeReason : values.completeReason,
                            note : values.note,
                            statusId : leadDetails.status.id,
                            inputEmail : leadDetails.email,
                            calltype : values.calltype,
                            disposition : values.disposition,
                            outcome : values.outcome,
                            CallSource : values.CallSource,

                        }
                        const res = await dispatch(postData({apiUrl,args})).unwrap();
                        if(res) {
                            if(res.success === true){
                                setOpenMarkComplete(false);
                                fetchLeadDetails();                                                            
                            }else{
                                setOpenMarkComplete(false);
                                 swal({
                                    title: "Failed",
                                    text: res.message,
                                    icon: "error",
                                    button: "Ok",
                                })
                                if(res.message == "This action is unauthorized.") {
                                    navigate('/login');
                                }
                            }
                        }
                    }}
                >
                    {({values, errors,touched,isSubmitting, handleChange, handleBlur, handleSubmit}) => (
                        <Dialog
                            open={openMarkComplete}
                            onClose={(_, reason) => {
                                if (reason !== "backdropClick") {
                                    setOpenMarkComplete(false);
                                }
                            }}
                            aria-labelledby="responsive-dialog-title"
                            fullScreen={fullScreen}
                        >
                            <DialogTitle textAlign={'center'} id="responsive-dialog-title" sx={{backgroundColor:"#F6FAFF"}}>
                                <Typography color="primary">
                                    Mark As Completed
                                </Typography>
                            </DialogTitle>
                            <DialogContent >
                                <Grid container item lg={12} md={12} sm={12} xs={12} p={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormLabel>Completion Reason*</FormLabel>
                                        <FormControl fullWidth size="small"
                                        error={touched.completeReason && errors.completeReason ? errors.completeReason : ''}
                                        >                                           
                                            <InputLabel>Select Reason</InputLabel>                                            
                                            <Select
                                                id="completeReason"
                                                name="completeReason"
                                                label="Select Reason*"
                                                value={values.completeReason}
                                                onChange={(e)=>{
                                                    handleComplete(e.target.value);
                                                    handleChange(e)
                                                }}
                                                onBlur={handleBlur}
                                            >
                                            {markCompleteReasonArr.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                {name}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                            <FormHelperText>{touched.completeReason && errors.completeReason ? errors.completeReason : ''}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} p={2}  id="ConatctReason" sx={{display:'none'}}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} id='ContactType'>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">Contact Type*</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="CallSource"
                                                name="CallSource"
                                                onChange={handleChange('CallSource')}
                                                value={values.CallSource}
                                                MenuProps={MenuProps}
                                                label="Contact Type*"
                                            >
                                            
                                                {contactTypeList.map((array) => (
                                                    <MenuItem
                                                        value={array.call_type}
                                                    >
                                                        {array.call_type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {touched.CallSource && errors.CallSource ? <div className='error'>{errors.CallSource}</div> : null}
                                    </Grid>
                                
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Stack direction="row" spacing={2} style={{ width: '100%' }}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="demo-simple-select-label">Contact Reason*</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id='calltype'
                                                        name='calltype'
                                                        label="Contact Reason"
                                                        value={values.calltype || 65}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                        MenuProps={MenuProps}
                                                        defaultValue={65}
                                                    >
                                                    
                                                        {callTypeList.map((array) => (
                                                            <MenuItem
                                                                value={array.id}
                                                                key={array.id}
                                                                disabled={array.id !== 65}
                                                            >
                                                                
                                                                {array.call_type}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>
                                                {touched.calltype && errors.calltype ? <div className='error'>{errors.calltype}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size="small" id="DispositionDiv">
                                                    <InputLabel id="demo-simple-select-label">Disposition*</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="disposition"
                                                        name='disposition'
                                                        value={values.disposition || 141}
                                                        input={<OutlinedInput label="Disposition" />}
                                                        onChange={(e) => {
                                                            const selectedOption = dispositionTypeList.find((option) => option.id === e.target.value);
                                                            // getOutcome(e.target.value);
                                                            // getOutcome(selectedOption.id, selectedOption.disposition);
                                                            handleChange(e);
                                                        }}
                                                        MenuProps={MenuProps}
                                                        defaultValue={141}
                                                    >
                                                        {dispositionTypeList.map((dispositionlist) => (
                                                            <MenuItem
                                                                key={dispositionlist.id}
                                                                value={dispositionlist.id}
                                                                disabled={dispositionlist.id !== 141}
                                                            >
                                                                {dispositionlist.disposition}
                                                            </MenuItem>

                                                        ))}
                                                    </Select>
                                                    {touched.disposition && errors.disposition ? <div className='error'>{errors.disposition}</div> : null}
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size="small" id="OutcomeDiv">
                                                    <InputLabel id="demo-simple-select-label">Outcome*</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="outcome"
                                                        name="outcome"
                                                        value={values.outcome || 320}
                                                        input={<OutlinedInput label="Outcome" />}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                        MenuProps={MenuProps}
                                                        defaultValue={320}
                                                    >
                                                    {outcomeList.map((outcome) => (
                                                        outcome.reason === '' ? (
                                                            <MenuItem  value={outcome.id}>No OutCome available</MenuItem>
                                                        ) : (
                                                            <MenuItem value={outcome.id} 
                                                            disabled={outcome.id !== 320}>
                                                            {outcome.reason}
                                                            
                                                            </MenuItem>
                                                        )
                                                        ))}
                                                       
                                                    </Select>
                                                    {touched.outcome && errors.outcome ? <div className='error'>{errors.outcome}</div> : null}
                                                </FormControl>
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                        
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} px={2} id="otherReason" sx={{display:'none'}}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormLabel>Completed Reason*</FormLabel>
                                        <FormControl fullWidth size="small"
                                            error={touched.note && errors.note ? errors.note : ''}
                                        > 
                                            <TextareaAutosize
                                                aria-label="minimum height"
                                                minRows={5}
                                                placeholder="Enter Completed reason"
                                                fullWidth
                                                name="note"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.note}
                                            />
                                            <FormHelperText>{touched.note && errors.note ? errors.note : ''}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>                            
                            </DialogContent>
                            <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"center",width:"100%"}}>
                                    <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                                        <Button  autoFocus variant="contained" sx={{minWidth:250}} onClick={()=>setOpenMarkComplete(false)}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                                        <Button variant="contained" sx={{minWidth:250}} color="success" onClick={handleSubmit} disabled={ (isSubmitting) }>
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    )}
                </Formik>
                {/* mark as cimpleted end */}
                
                {/* sleve application start */}
                <Formik
                    initialValues={{
                        disableReason: ''
                    }}
                    validationSchema={shelveApplication}
                    onSubmit={async(values, error)=>{                        

                        const apiUrl = '/shelveApplication';
                        const args = {
                            affref_id : leadDetails.id,
                            disableReason : values.disableReason,
                        }
                        const res = await dispatch(postData({apiUrl,args})).unwrap();
                        if(res) {
                            if(res.success === true){
                                setOpenShelve(false);
                                fetchLeadDetails();                                             
                            }else{
                                // console.log('else');   
                                 swal({
                                    title: "Failed",
                                    text: res.message,
                                    icon: "error",
                                    button: "Ok",
                                })
                                if(res.message == "This action is unauthorized.") {
                                    navigate('/login');
                                }
                            } 
                        }
                    }}
                >
                    {({values, errors,touched,isSubmitting, handleChange, handleBlur, handleSubmit}) => (
                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            open={openShelve}
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            onClose={(_, reason) => {
                                if (reason !== "backdropClick") {
                                    ShelveApplicationClose();
                                }
                            }}
                        >
                            <DialogTitle id="customized-dialog-title" p={5} sx={{backgroundColor:"#F6FAFF"}} onClick={ShelveApplicationClose}>
                                Shelve application
                            </DialogTitle>
                            <DialogContent dividers>
                                <FormControl fullWidth size="small"
                                    error={touched.disableReason && errors.disableReason ? errors.disableReason : ''}
                                >
                                    <FormLabel id="">Disable Reason:*</FormLabel>   
                                    <TextareaAutosize
                                        margin="dense"
                                        aria-label="minimum height"
                                        minRows={5}
                                        size="md"
                                        name="disableReason"
                                        placeholder="Enter Reason"
                                        value={values.disableReason}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormHelperText>{touched.disableReason && errors.disableReason ? errors.disableReason : ''}</FormHelperText>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button sx={{minWidth:150}} variant="contained" color="primary" onClick={ShelveApplicationClose}>
                                    CLOSE
                                </Button>
                                <Button variant="contained" sx={{minWidth:150}} color="warning" onClick={handleSubmit} disabled={ (isSubmitting) }>
                                    SAVE
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Formik>
                {/* sleve application end */}
                
                {/* Deferred Application start */}
                <Formik
                    initialValues={{
                        deferredReason: '',
                        deferredDate: dayjs().add(1,'day'),
                        deferredTime: dayjs(),
                    }}
                    validationSchema={deferredApplication}
                    onSubmit={async(values, error)=>{

                        const apiUrl = '/deferApplication';
                        const args = {
                            affref_id : leadDetails.id,
                            deferredReason : values.deferredReason,
                            deferredDate: values.deferredDate,
                            deferredTime: values.deferredTime

                        }
                        const res = await dispatch(postData({apiUrl,args})).unwrap();
                        if(res) {
                            if(res.success === true){
                                setOpenDefer(false);
                                navigate('/manageleads/deferred-leads');
                                // fetchLeadDetails();                                             
                            }else{
                                 swal({
                                    title: "Failed",
                                    text: res.message,
                                    icon: "error",
                                    button: "Ok",
                                })
                                if(res.message == "This action is unauthorized.") {
                                    navigate('/login');
                                }
                            }
                        }
                    }}
                >
                    {({values, errors,touched,isSubmitting, handleChange, handleBlur, handleSubmit,setFieldValue}) => (
                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            open={openDefer}
                            onClose={(_, reason) => {
                                if (reason !== "backdropClick") {
                                    DeferredApplicationClose();
                                }
                            }}
                        >
                            <DialogTitle id="customized-dialog-title" sx={{backgroundColor:"#F6FAFF"}} onClick={DeferredApplicationClose}>
                                Deferred Application
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl fullWidth size="small"
                                        error={touched.deferredReason && errors.deferredReason ? errors.deferredReason : ''}
                                    >
                                        <FormLabel id="">Deferred Reason:*</FormLabel> 
                                            <TextareaAutosize
                                                margin="dense"
                                                aria-label="minimum height"
                                                minRows={5}
                                                placeholder="Enter Note"
                                                style={{width:"100%"}}
                                                name="deferredReason"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <FormHelperText>{touched.deferredReason && errors.deferredReason ? errors.deferredReason : ''}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Typography>Deferred  Till</Typography>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} sx={{display:"flex",justifyContent:"center"}}>
                                    <Grid item xs>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker label="Date:*" name="deferredDate" 
                                                    // onChange={handleChange}
                                                    onChange={(value) => {setFieldValue("deferredDate", value, true)}}
                                                    value={values.deferredDate}
                                                />
                                                <FormHelperText>{touched.deferredDate && errors.deferredDate ? errors.deferredDate : ''}</FormHelperText>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['TimePicker']}>
                                                <TimePicker label="Time:*" format="hh:mm:ss" name="deferredTime"  
                                                    // onChange={handleChange}
                                                    onChange={(value) => {setFieldValue("deferredTime", value, true)}}
                                                    value={values.deferredTime}
                                                />
                                                <FormHelperText>{touched.deferredTime && errors.deferredTime ? errors.deferredTime : ''}</FormHelperText>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions  sx={{display:"flex",justifyContent:"center"}}>
                                <Button sx={{minWidth:150}} variant="contained" color="primary" onClick={DeferredApplicationClose}>
                                    CLOSE
                                </Button>
                                <Button variant="contained" sx={{minWidth:150}} color="warning" onClick={handleSubmit} disabled={ (isSubmitting) }>
                                    SAVE
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Formik>
                {/* Deferred Application end */}
            </Box>
            :''}
            </>
        );
    }
     
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default CrcReport;