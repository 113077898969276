import React, { useMemo, useState, useEffect } from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, Tooltip, Grid, Slide, DialogTitle, TextareaAutosize, FormControl } from '@mui/material';
import { Typography } from '@mui/joy';
import MaterialReactTable from 'material-react-table';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Loader/Loader';
import swal from "sweetalert";
import {Link } from 'react-router-dom';
import config from '../../AppConfig';
import { useDispatch } from 'react-redux';
import AuthUser from "../../Components/Auth/AuthUser";
import { fetchData, postData } from '../../redux/apiSlice';
import CustomizeEnrollIndex from '../../Components/CobrandCustomization/CustomizeEnrollIndex'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';

import formatPhoneNumber from '../../Components/Formats/PhoneNumberValidation';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
let bannerImg1 = require("../../assets/images/defaultImg1.png")
let bannerImg2 = require("../../assets/images/defaultImg2.png")
let bannerImg3 = require("../../assets/images/defaultImg3.png")
let defaultCustomColor = [{ bodyBackground: '#FFFFFF', primaryColor: '#3470B1', hyperlinkColor: '#3470B1' }]
let RequestFormInitialValues = {
    comments: ''
};
function AdvancedCBReview(props) {
    const dispatch = useDispatch();
    const { http, user } = AuthUser();
    const [freez, setFreez] = React.useState(true);
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [cobrandCustomizeDetailsList, setCobrandCustomizeDetailsList] = useState([]);
    const [openPreviewPopup, setOpenPreviewPopup] = useState(false)
    const [croppedImages, setCroppedImages] = useState([]);
    const [customColorArrays, setCustomColorArrays] = useState(defaultCustomColor);
    const [textColor, setTextColor] = useState('black')
    const [templateValue, setTemplateValue] = useState(1)
    const [btnTextColor, setBtnTextColor] = useState('white')
    const [openResetPopup, setOpenResetPopup] = useState(false)
    const [openResetComment, setOpenResetComment] = useState(false)
    const [openApprovePopup, setOpenApprovePopup] = useState(false)
    const [selectedRowDetails, setSelectedRowDetails] = useState([])
    const [resetBtnFlag,setResetBtnFlag]= useState(false)
    const [disableApproveBtn, setDisableApproveBtn] = useState(false)
    const [disabledSaveBtn, setDisabledSaveBtn] = useState(false)
    const theme = useTheme();

    const validationFormSchema = Yup.object().shape({
        comments: Yup.string()
            .required('Please enter the reason'),
    });

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    useEffect(() => {
    }, [rowSelection]);

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }

    }, [rowSelection]);

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);

    const checkColumnVisibility = async () => {
        if (Object.values(columnVisibility).length > 0) {
            const apiUrl = '/saveTablePreferences';
            const args = {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'cobrandCustomization'
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
        }
    }

    useEffect(() => {
        checkColumnVisibility();
    }, [columnVisibility])

    const tablePreferences = async () => {
        const apiUrl = '/fetchTablePreferences/cobrandCustomization';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (Object.values(res).length == 0) {
                setColumnVisibility(tablePreferencesArray);
            } else {
                setColumnVisibility((JSON.parse(res.preferences)));
            }
        }
    }
    const tablePreferencesArray = {
    }
    const getOpenPreviewPopup = (row) => {
        handleFreezStart()
        localStorage.setItem("customizationReviewCompanyId", row)
        setTemplateValue(1)
        const list = cobrandCustomizeDetailsList.find(item => item.company_master_id === row);

        let banner1 = list.hero_image_1 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_1 : bannerImg1;
        let banner2 = list.hero_image_2 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_2 : bannerImg2;
        let banner3 = list.hero_image_3 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_3 : bannerImg3
        setCroppedImages(
            [
                { step: 1, url: banner1, },
                { step: 2, url: banner2, },
                { step: 4, url: banner3, }
            ]
        );

        setCustomColorArrays(
            [
                { bodyBackground: list.body_background_color ? list.body_background_color : customColorArrays[0].bodyBackground, primaryColor: list.primary_color ? list.primary_color : customColorArrays[0].primaryColor, hyperlinkColor: list.hyper_link_color ? list.hyper_link_color : customColorArrays[0].hyperlinkColor },
            ])
        setTextColor(list.text_color ? list.text_color : 'black')
        getContrastText(list.primary_color ? list.primary_color : '#3470B1')
        setOpenPreviewPopup(true)
        handleFreezStop()
    }
    const getContrastText = (color) => {
        // Convert background color to RGB
        const rgb = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
        const r = parseInt(rgb[1], 16);
        const g = parseInt(rgb[2], 16);
        const b = parseInt(rgb[3], 16);

        // Calculate brightness using the formula (r * 299 + g * 587 + b * 114) / 1000
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // If brightness is greater than 125, return black, else return white
        brightness > 125 ? setBtnTextColor('black') : setBtnTextColor('white');

    };
    const getResetPopup = (row) => {
        setOpenResetPopup(true)
        setSelectedRowDetails(row)
    }
    const getApprovePopup = (row) => {
        setOpenApprovePopup(true)
        setSelectedRowDetails(row)
    }
    const approveDetails = () => {
        reviewCobrandCustomize('approve','' )
    }

    const submitResetReson = (values) => {
        reviewCobrandCustomize('reset',values)
    }
    const reviewCobrandCustomize=async (flag,value)=>{
       let args;
        if (flag=='reset') {
            args = {
                companyId: selectedRowDetails.company_master_id,
                status:flag,
                comment:value.comments,
                bodyBackground:"#FFFFFF",
                primaryColor:"#3470B1",
                hyperlinkColor:"#3470B1",
                textColor: 'black',
             
            };
        }
        else{
            args = {
                companyId: selectedRowDetails.company_master_id,
                status:flag,
            };
        }
        const apiUrl = '/reviewCobrandCustomize';
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success === true) {
                  setOpenApprovePopup(false);
                  setOpenResetComment(false);
                  setDisableApproveBtn(false);
                setDisabledSaveBtn(false)
                handleFreezStop()
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
                fetchCorandDetails()
            } else {
                alert("something went wrong !");
                handleFreezStop()
            }
        }
    }
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorKey: 'aidName',
                accessorFn: (row) => `${row.aidList ? row.aidList.trim() : ''}`,
                header: 'AID',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.aidList ? row.original.aidList.trim() : 'N/A'}

                    </>
                ),
            },
            {
                accessorKey: 'companyName',
                accessorFn: (row) => `${row.company_name ? row.company_name.trim() : ''}`,
                header: 'Company Name',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.company_name ? row.original.company_name.trim() : 'N/A'}

                    </>
                ),
            },
            {
                accessorKey: 'email',
                accessorFn: (row) => `${row.email ? row.email.trim() : ''}`,
                header: 'Email',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.email ? row.original.email.trim() : 'N/A'}

                    </>
                ),
            },
            {
                accessorKey: 'phone',
                accessorFn: (row) => `${row.phone_no ? formatPhoneNumber(row.phone_no.trim()) : 'N/A'}`,
                header: 'Phone No',
                size: 50,
            },
            {
                accessorFn: (row) =>
                    <Box>
                        <Tooltip title="Preview" arrow>
                            <Link onClick={() => getOpenPreviewPopup(row.company_master_id)}><img src={require('../../assets/icons/preview-icon.png')} /></Link>
                        </Tooltip>

                        <>
                            <Tooltip title="Reset and Publish" arrow>
                                <Link onClick={() => getResetPopup(row)}><img src={require('../../assets/icons/reset-icon.png')} /></Link>
                            </Tooltip>
                        </>
                        <>
                            <Tooltip title="Approve" arrow>
                                <Link onClick={() => { getApprovePopup(row) }}><img src={require('../../assets/icons/approved-icon.png')} /></Link>
                            </Tooltip>
                        </>
                    </Box>,
                accessorKey: 'Actions',
                id: 'actions',
                header: 'Actions',

            },
        ]
    )
    useEffect(() => {
        fetchCorandDetails()
    }, [])
    const fetchCorandDetails = async () => {
        handleFreezStart()
        const apiUrl = '/cobrandCustomizeDetails'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setIsLoading(false);
            handleFreezStop();
            setCobrandCustomizeDetailsList(res.data);
        }
    }
    const previewNextBtn = () => {
        setTemplateValue(templateValue + 1)
    }
    const previewPreviousBtn = () => {
        setTemplateValue(templateValue - 1)
    }
    return (
        <>
            <Box sx={{ mt: 1.5 }} >
                <div>
                    <Grid mt={3}>
                        <MaterialReactTable
                            columns={columns}
                            data={cobrandCustomizeDetailsList}
                            enableColumnFilterModes
                            filterFns={{
                                customSearchFilterFn: (row, id, filterValue) =>
                                    row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                            }}
                            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                            enableColumnFilters={config.DatatableColumnFilter}
                            initialState={{ showColumnFilters: false, density: 'compact' }}
                            positionToolbarAlertBanner="bottom"
                            enableDensityToggle={false}
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ columnVisibility, rowSelection, isLoading: isLoading }}
                            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                            muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                    backgroundColor: "#F6FAFF",
                                },
                            }}
                        />
                    </Grid>
                    <Dialog
                        fullScreen
                        open={openPreviewPopup}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                            <Grid sx={{ display: 'flex', justifyContent: 'center' }} className='preview-box-wrapper'>
                                <Grid >
                                    <Button variant="contained" onClick={previewPreviousBtn} disabled={templateValue == 1 ? true : false} color="success" sx={{ minWidth: 250 }}>
                                        Previous
                                    </Button>
                                </Grid>
                                <Typography level="h4" sx={{ textAlign: "center", padding: '0% 4%' }}>Page Preview</Typography>
                                <Grid  >
                                    <Button variant="contained" onClick={previewNextBtn} disabled={templateValue == 5 ? true : false} color="success" sx={{ minWidth: 250 }}>
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                            <Button variant='contained' color="primary" onClick={() => setOpenPreviewPopup(false)} className='preview-close-btn'>Close</Button>
                        </DialogTitle>
                        <DialogContent sx={{ margin: 'auto', marginTop: '10px' }} >
                            <CustomizeEnrollIndex templateValue={templateValue} cobrandFlag={openPreviewPopup ? true : false} croppedImages={croppedImages} customColorArrays={customColorArrays} textColor={textColor} btnTextColor={btnTextColor} />
                        </DialogContent>
                        <DialogActions sx={{ margin: 'auto' }}>

                            <Grid container item lg={12} md={12} sm={12} xs={12} columnSpacing={3}>


                            </Grid>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openResetPopup}
                        fullWidth
                        maxWidth={'sm'}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                setOpenResetPopup(false);
                            }
                        }}
                    >
                        <DialogTitle >
                            <Typography level="h4" sx={{ textAlign: "center" }}>Do you want to restore the default settings?</Typography>

                        </DialogTitle>

                        <DialogActions sx={{ margin: 'auto' }} >
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={() => { setOpenResetComment(true); setOpenResetPopup(false) }} color="success" sx={{ minWidth: 250 }} >
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={() => setOpenResetPopup(false)} color="primary" sx={{ minWidth: 250 }}>
                                    Cancel
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                                fullWidth
                                maxWidth={'sm'}
                                open={openResetComment}
                                // onClose={handleClose}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        setOpenResetComment(false);
                                    }
                                }}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                    <Formik
                        initialValues={RequestFormInitialValues}
                        validationSchema={validationFormSchema}
                        onSubmit={submitResetReson}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                         <>
                                <DialogTitle textAlign={'center'} p={5} sx={{ backgroundColor: "#F6FAFF" }}>
                                    <Typography level="h4" color='primary'>Reset Note</Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <Box mt={1}>
                                        <Typography level='h4'>Enter the reason to reset the settings</Typography>

                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                                            <FormControl fullWidth size="small">
                                                <TextareaAutosize
                                                    style={{ width: "100%" }}
                                                    required
                                                    margin="dense"
                                                    aria-label="minimum height"
                                                    minRows={6}
                                                    placeholder="Enter Note*"
                                                    name="comments"
                                                    id="comments"
                                                    value={values.comments}
                                                    onChange={handleChange('comments')}
                                                    onBlur={handleBlur('comments')}


                                                />
                                                <div className='error'>{touched.comments && errors.comments ? errors.comments : ''}</div>
                                            </FormControl>
                                        </Grid>

                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ margin: 'auto' }} >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={()=>{setDisabledSaveBtn(true);handleSubmit()}} color="success" sx={{ minWidth: 250 }} disabled={disabledSaveBtn}>
                                            Save
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={() => setOpenResetComment(false)} color="primary" sx={{ minWidth: 250 }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </DialogActions>
                                </>
                        )}

                    </Formik>
                    </Dialog>
                    <Dialog
                        open={openApprovePopup}
                        fullWidth
                        maxWidth={'sm'}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                setOpenApprovePopup(false);
                            }
                        }}
                    >
                        <DialogTitle >
                            <Typography level="h4" sx={{ textAlign: "center" }}>Do you want to approve customization?</Typography>

                        </DialogTitle>

                        <DialogActions sx={{ margin: 'auto' }} >
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={()=>{setDisableApproveBtn(true);approveDetails()}} color="success" sx={{ minWidth: 250 }} disabled={disableApproveBtn}>
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={() => setOpenApprovePopup(false)} color="primary" sx={{ minWidth: 250 }}>
                                    Cancel
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
        </>
    );

}


export default AdvancedCBReview;