import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box, Collapse, Grid, Divider, Table, TableCell, FormControl, Dialog, DialogActions, Alert,  DialogTitle, TableRow, InputLabel, Button, 
    DialogContent, MenuItem, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio, RadioGroup,  Select, Tooltip,Stack,TextField,FormHelperText
} from '@mui/material';
import AuthUser from "../../../Components/Auth/AuthUser";
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/HighlightOff';
import { Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import Loader from '../../../Components/Loader/Loader';
import InputMask from 'react-input-mask';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function MemberActivity(props) {
    const { memberId } = useParams();
    const {http} = AuthUser();
    const navigate = useNavigate();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const [currentDate, setCurrentDate] = useState("");
    const [open, setOpen] = React.useState(false);
    const [memberData,setMemberData] = useState([]);
    const [followupDate,setFollowupDate] = useState([]);
    const [activities,setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentFollowUpTime, setFollowUpTime] = useState("");
    const [formatedCurrentDate, setFormatedCurrentDate] = useState("");
    const [currentFollowUpDate, setFollowUpDate] = useState("");
    
    const activitySchema = Yup.object().shape({
        callType: Yup.string()
                .required('Please select Type'),
        followupNotes: Yup.string()
                    .required('Please Note The Activity'),
        followupRequired: Yup.string().required('Please enter reason to disable.'),
        followupTime: Yup.string().when("followupRequired",{
            is: (followupRequired) => followupRequired == 'Yes',
            then: () => Yup.string().test(
                      'is-valid-time',
                      'Invalid time format. Please enter a valid HH:mm:ss time.',
                      (value) => {
                        const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
                        return regex.test(value);
                      }
                    ),
        }),
        followupDate: Yup.string().when("followupRequired",{
            is: (followupRequired) => followupRequired == 'Yes',
            then: () => Yup.string().test(
                'is-valid-year',
                      'Invalid Follow Up Date',
                      function (value) {
                        const date = new Date(value);
                        let formatedData1=Moment(date).format('MM-DD-YYYY');
                        const currentDate = new Date();
                        const year = date.getFullYear();
                        const formattedCurrentDate = Moment(currentDate).format('MM-DD-YYYY');
                        const splitYear = formatedCurrentDate.split('-')[2];
                        return Moment(formatedData1, 'MM-DD-YYYY').isSameOrAfter(formattedCurrentDate, 'day');
                        
                      }
                    ),
        }),
    });

    function followupChange(val,setFieldValue) {
        if(val === 'No'){
            const element = document.getElementById("noFollowup");
            element.style.display= "none";
            // Set default value for followupDate to the current date
            setFieldValue("followupDate", dayjs(currentFollowUpDate));
        }else{
            const element = document.getElementById("noFollowup");
            element.style.display= "block";
        }
    }

    const fetchRetentionActivity = () => {
        setIsLoading(true);
        http.get('/retention-activity/'+ memberId).then((res)=>{
        setMemberData(res.data.data.memberData);   
        setFollowupDate(res.data.data.followDate);   
        setActivities(res.data.data.memberData.activity_logs);   
        setIsLoading(false);    
        
       
        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        setCurrentDate(cDate);
        })
    }
  
    useEffect(() => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id < 3 ) {
            fetchRetentionActivity();
        }else {
            navigate('/login');
        }
        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var CurrentFormatedDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YYYY'); 
        setCurrentDate(cDate);
        setFormatedCurrentDate(CurrentFormatedDate)
    }, []);

    const checkAdmin = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id < 3 ) {
            return true;
        }else{
            navigate('/login');
            return false;
        }
    };
    const handleClickOpen = () => {
        var check = checkAdmin();
        if(check === true){
            setOpen(true);
        }


        const newDate = new Date();
        const myDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        myDate.setDate(myDate.getDate());
        var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        // console.log("cDate :"+cDate)
        var todaysTime = Moment(newDate).tz('America/Los_Angeles').format('hh:mm:ss');
        setFollowUpDate(todaysDate);
        setFollowUpTime(todaysTime);
        setCurrentDate(cDate);

    };

    const handleClose = () => {
        setOpen(false);
        checkAdmin();
        
    };

    function ContentLayout() {
        return (
            <>
                {isLoading?<Loader/>:<></>}
                <Box>
                    <Grid container direction="row" sx={{ justifyContent: 'space-between' }}>
                        <Grid item xs={5}><Typography level="h3" color="primary">Member Retention Activity</Typography></Grid>
                        <Grid item container xs={7}>
                            <Grid item xs={11}>
                                <Card variant="outlined" size="sm" fullWidth>
                                    <CardContent>
                                        <Grid container textAlign={'center'} item md={12}>
                                            <Grid item xs={6}>
                                                <Typography level="h5">Status: <Typography level='body1'></Typography>&nbsp;
                                                    <Typography>{memberData.status == 1 ? "Mark As Retained" : "Closed/Pending"}</Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography level="h5">Next Follow up:
                                                    <Typography level='body1'>{followupDate ? followupDate.add_next_followup != null ? (followupDate.add_next_followup != "0000-00-00 00:00:00" ? Moment(followupDate.add_next_followup).format('MM-DD-YYYY hh:mm') +"Hrs" : ' N/A') : 'N/A' : 'N/A'} </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={1} textAlign="right" sx={{ justifyContent: "center" }}>
                                <Tooltip title="Close" >
                                    <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2} pt={2}>
                        <Grid item xs={5}>
                            <Card variant="outlined" size="lg">
                                <CardOverflow
                                    sx={{
                                        bgcolor: '#F6FAFF',
                                        borderColor: '#F6FAFF',
                                        padding: '1%',
                                    }}
                                >
                                    <Typography level="h3" sx={{ mb: 0.5 }}>Member Details</Typography>
                                </CardOverflow>
                                <CardContent>
                                    <Table>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>Assigned To:</TableCell>
                                            <TableCell>{memberData ?( memberData.user ?  memberData.user.first_name +" "+ memberData.user.last_name : '') : ''}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>Name:</TableCell>
                                            <TableCell>{memberData.name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>Email:</TableCell>
                                            <TableCell>{memberData.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
                                            <TableCell>{memberData.phone_no}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>PID:</TableCell>
                                            <TableCell>{memberData.PID}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>AID:</TableCell>
                                            <TableCell>{memberData.AID}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>Plan:</TableCell>
                                            <TableCell>{memberData.plan}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>Subscription Date:</TableCell>
                                            <TableCell>{Moment(memberData.subscription_date).format('MM-DD-YYYY hh:mm') + " Hrs"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>Close Date:</TableCell>
                                            <TableCell>{Moment(memberData.close_date).format('MM-DD-YYYY')}</TableCell>
                                        </TableRow>
                                    </Table>
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item xs={7}>
                            <Collapse >
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                ></Alert>
                            </Collapse>
                            <Card variant="plain" size="sm" sx={{ boxShadow: 'none' }}>
                                <Grid container item md={12} sx={{ justifyContent: 'space-between' }}>
                                    <Typography level="h4" color="primary">ACTIVITY LOG</Typography>
                                    <Button
                                        variant="contained"
                                        color='success'
                                        onClick={handleClickOpen}
                                    >New Activity</Button>
                                </Grid>
                            </Card>
                            <>
                                {activities.map((activity, idx, array)=>{
                                    return(
                                        <>
                                        <Card variant="outlined" size="lg" sx={{marginTop:2}} >
                                            <CardOverflow
                                                sx={{
                                                    bgcolor:'#F6FAFF',
                                                    borderColor:'#F6FAFF',
                                                    padding: '1%',
                                                }}
                                            >
                                                <Grid container  item md={12} sx={{justifyContent:'space-between'}}>
                                                    <Typography>
                                                        <Typography level="h6" sx={{ mb: 0.5 }}>{activity.type?activity.type:'Staff'} : &nbsp;</Typography>
                                                        <Typography >{activity.user?activity.user.first_name+' '+ activity.user.last_name:'N/A'} </Typography>                                            
                                                    </Typography>
                                                    <Typography>{activity.created_at ?  Moment(activity.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm')+'Hrs' : 'N/A' }  </Typography>
                                                </Grid>
                                            </CardOverflow>
                                            <CardContent>
                                                <Grid container  item md={12} p={2}>
                                                    <Grid item xs={6}>
                                                        <Typography level="h6">Status: &nbsp;
                                                            <Typography level="body1">{activity.account_status == 1 ? 'Retained' : 'Closed/Pending'}</Typography>
                                                        </Typography>
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <Typography level="h6">Next Status: &nbsp;
                                                            <Typography level="body1">{activity.account_status == 1 ? 'N/A' : 'Retained'}</Typography>
                                                        </Typography>
                                                    </Grid> 
                                                </Grid>
                                                <Divider />
                                                <Grid container  item md={12} pt={2}>
                                                    <Typography level="h6">Note:</Typography>                                                                             
                                                </Grid>
                                                <Grid container  item md={12} pt={1}>
                                                    <Typography level="body1">{activity.notes}</Typography>  
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                        </>
                                    );
                    })}
                            </>
                        </Grid>
                    </Grid>
                    {/* new activity start */}
                    <Formik
                    initialValues={{
                        callType: '',
                        followupNotes: '',
                        markAsRetention: ['off'],
                        followupRequired: 'Yes',
                        // followupDate: dayjs().add(1, 'day'),
                        // followupTime: dayjs(),  
                        followupDate: dayjs(currentFollowUpDate),
                        followupTime: (currentFollowUpTime),
                    }}
                    validationSchema={activitySchema}
                    onSubmit={async(values,errors) => { 
                        var check = checkAdmin();
                        if(check === true) {
                        //     http.post('/save-retention-activity',{
                            const date = new Date(values.followupDate);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            var mydate = year+"/"+month+"/"+day;
                            var todaysTime = values.followupTime;
                            const apiUrl = '/save-retention-activity';
                            const args = {
                                retentionId : memberData.id,
                                followupRequired : values.followupRequired,
                                followupNotes : values.followupNotes,
                                followupDate : mydate,
                                followupTime : values.followupTime,
                                markAsRetention : values.markAsRetention,
                                callType : values.callType,
                                status : memberData.status,
                            }
                            const res = await dispatch(postData({apiUrl,args})).unwrap();
                            if(res) {
                                if(res.success === true){
                                    setOpen(false);
                                    fetchRetentionActivity();                                                            
                                }else{
                                    swal({
                                        title: "Failed",
                                        text: res.message,
                                        icon: "error",
                                        button: "Ok",
                                    })
                                    if(res.message == "This action is unauthorized.") {
                                        navigate('/login');
                                    }
                                    
                                }
                            }
                        }}
                    }
                    //         }).then((res) => {                            
                    //             if(res.data.success === true){
                    //                 setOpen(false);
                    //                 fetchRetentionActivity();                                                            
                    //             }else{
                    //                 console.log('else');   
                    //             }
                    //         })
                    //     }
                        
                    // }}
                >
                    {({values,errors,touched,isSubmitting,handleChange,handleBlur,handleSubmit,setFieldValue}) =>(
                    <Dialog
                        maxWidth='md'
                        disableEscapeKeyDown={true}
                        fullScreen={fullScreen}
                        open={open}
                        // onClose={handleClose}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                handleClose();
                            }
                        }}    
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle textAlign={'center'} p={5} sx={{ backgroundColor: "#F6FAFF" }}>
                            <Typography level="h4" color='primary'>New Follow Up</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box mt={1}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Select Type *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="callType"
                                            name="callType"
                                            label="Select Type *"
                                            value={values.callType}
                                            onBlur={handleBlur('callType')}
                                            onChange={(e)=>{
                                                handleChange(e);
                                            }}
                                        >
                                            <MenuItem value="Call">Call</MenuItem>
                                            <MenuItem value="Text">Text</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div className='error'>{touched.callType && errors.callType ? errors.callType : ''}</div>
                                </Grid>
                                
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                                    <FormControl fullWidth size="small">
                                        <TextareaAutosize
                                            style={{ width: "100%" }}
                                            required
                                            margin="dense"
                                            aria-label="minimum height"
                                            minRows={6}
                                            placeholder="Enter Note*"
                                            name="followupNotes" 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.followupNotes}
                                            />
                                        <div className='error'>{touched.followupNotes && errors.followupNotes ? errors.followupNotes : ''}</div>
                                    </FormControl>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={7} md={7} sm={12} xs={12}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            {/* <Stack direction="row"> */}
                                            <FormControl>
                                                <FormLabel id="">Followup Required :</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="followupRequired"
                                                    value={values.followupRequired}
                                                    onChange={(e)=>{
                                                        followupChange(e.target.value,setFieldValue)
                                                        handleChange(e)
                                                    }}>
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                                <div>{touched.followupRequired && errors.followupRequired ? errors.followupRequired : ''}</div>
                                            </FormControl>
                                            {/* </Stack> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={12} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox onChange={handleChange} />}
                                                label="Mark as Retained" name="markAsRetention" />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid container lg={12} md={12} sm={12} xs={12} item id="noFollowup">
                                    <Stack direction="row" spacing={2}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DatePicker']} fullWidth size="small">
                                                    <DatePicker label="Follow Up Date*"
                                                        margin="dense"
                                                        onChange={(value) => {setFieldValue("followupDate", value, true)}}
                                                        onBlur={handleBlur}
                                                        value={values.followupDate} 
                                                        name="followupDate" 
                                                        minDate={dayjs(currentDate)}
                                                        maxDate={dayjs().add(75, 'year')}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                                <FormHelperText style={{ color: touched.followupDate && errors.followupDate ? 'red' : 'inherit' }}>
                                                    {touched.followupDate && errors.followupDate ? errors.followupDate : ''}
                                                </FormHelperText>
                                            {/* <div>{touched.followupDate && errors.followupDate ? errors.followupDate : ''}</div> */}
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <InputMask
                                                    mask='99:99:99'
                                                    label="Follow Up Time*"
                                                    maskChar={null}
                                                    name='followupTime'
                                                    fullWidth
                                                    margin="dense"
                                                    // size="small"
                                                    id='followupTime'
                                                    value={values.followupTime}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label="Follow Up Time*" variant="outlined"
                                                            {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                                {
                                                touched.followupTime && errors.followupTime ? (
                                                    <div className='error'>{errors.followupTime}</div>
                                                ) : (
                                                    ''
                                                )
                                                }
                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['TimePicker']} fullWidth size="small">
                                                    <TimePicker label="Follow Up Time*"
                                                        margin="dense"
                                                        format="hh:mm:ss"
                                                        value={values.followupTime} 
                                                        name="followupTime" 
                                                        onChange={(value) => {setFieldValue("followupTime", value, true)}}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            <div>{touched.followupTime && errors.followupTime ? errors.followupTime : ''}</div> */}
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"center",width:"100%"}}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button  autoFocus variant="contained" sx={{minWidth:150}} onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" sx={{minWidth:150}} color="success" type="submit" onClick={handleSubmit}>
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                        </DialogActions>
                    </Dialog>
                       )}

                       </Formik>
                    {/* new activity end */}
                </Box>
            </>
        );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default MemberActivity;