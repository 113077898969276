import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Box } from '@mui/material';
import { Typography } from '@mui/joy';
import MaterialReactTable from 'material-react-table';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../redux/apiSlice';
import formatPhoneNumber from '../../Components/Formats/PhoneNumberValidation';
import Moment from 'moment';

function ContestUsers(props) {
    const dispatch = useDispatch();
    const [ userData, setUserData] = useState([]);

    const fetchRegistrantsData = async () => {
        const apiUrl = '/fetch-register-to-win';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if(res.success){
                setUserData(res.data)
            }
        }
    }

    useEffect(() => {
        fetchRegistrantsData();
    }, []);

    console.log(userData)

    //columns array start
    const UserListColumns = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (row) => `${row.first_name? row.first_name.trim():''}`,
                header: 'First Name',
                size: 50,
                id: 'first_name',
                Cell: ({ renderedCellValue, row }) => (row.original.first_name ? row.original.first_name.trim() : 'N/A'),
            },
            {
                accessorFn: (row) => `${row.last_name? row.last_name.trim():''}`,
                header: 'Last Name',
                size: 50,
                id: 'last_name',
                Cell: ({ renderedCellValue, row }) => (row.original.last_name ? row.original.last_name.trim() : 'N/A'),
            },
            {
                accessorFn: (row) => `${row.email? row.email.trim():''}`,
                accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Email Address',
                size: 50,
                id: 'email',
                Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
            },
            {
                accessorKey: 'phone_no',
                accessorFn: (row) => `${(row.phone_no) || (row.phone_no !== '') ? (formatPhoneNumber(row.phone_no)) : 'N/A'}`,
                filterVariant: 'range',
                header: 'Phone no.',
                size: 50,
                id: 'phone_no',
                //custom conditional format and styling
            },
            {
                accessorFn: (row) => `${row.is_affiliate? row.is_affiliate :''}`,
                accessorKey: 'is_affiliate',
                header: 'Is Affiliate',
                size: 50,
                id: 'is_affiliate',
                Cell: ({ renderedCellValue, row }) => (row.original.is_affiliate ? 'Yes' : 'No'),
            },
            {
                accessorFn: (row) => `${row.is_apply_promocode? row.is_apply_promocode:''}`,
                accessorKey: 'is_apply_promocode',
                header: 'Used Promocode',
                size: 50,
                id: 'is_apply_promocode',
                Cell: ({ renderedCellValue, row }) => (row.original.is_apply_promocode? 'Yes' : 'No'),
            },  
            {
                accessorFn: (row) => `${row.created_at? row.created_at:''}`,
                accessorKey: 'created_at',
                id: 'created_at',
                header: 'Registered Date',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <div >
                      {row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm:ss') : "N/A"}
                    </div>
                ),
            },      
        ]
    )

    return (
        <>
        <Grid container px={10} py={5}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
                <Typography level="h4" color="primary">CreditCon 2024 - Contest Registrants</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <MaterialReactTable
                    columns={UserListColumns}
                    data={userData}
                    initialState={{
                        sorting: [
                            {
                                id: 'created_at',
                                desc: true
                            },
                        ]
                    }}
                />
            </Grid>
        </Grid>
        </>
    );
}

export default ContestUsers;