

export const data: Employee[] = [

  {
    firstName: 'Troy',
    lastName: 'Prohaska',
    email: 'Tania_McLaughlin70@hotmail.com',
    jobTitle: 'Principal Directives Analyst',
    salary: 95202,
    startDate: '7/25/2014',
    signatureCatchPhrase: 'Virtual zero tolerance moratorium',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/89.jpg',
  },
  {
    firstName: 'Jadyn',
    lastName: 'Tromp',
    email: 'Margarette.Abshire24@yahoo.com',
    jobTitle: 'Product Assurance Manager',
    salary: 21766,
    startDate: '7/1/2019',
    signatureCatchPhrase: 'Re-contextualized mission-critical challenge',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1136.jpg',
  },
  {
    firstName: 'Jerald',
    lastName: 'Heller',
    email: 'Maximo28@yahoo.com',
    jobTitle: 'Regional Identity Orchestrator',
    salary: 116164,
    startDate: '7/10/2017',
    signatureCatchPhrase: 'Customizable even-keeled time-frame',
    avatar:
      'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/654.jpg',
  },
];
