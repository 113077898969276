import React, { useState, useEffect } from "react";
import DefaultLayout from '../Components/DefaultLayout';
import { Typography, Card, CardContent, CardOverflow, Button } from '@mui/joy';
function ShowMassage(props) {
    function ContentLayout() {
        return (
            <>
           <Typography level="h6"><b>Credit Snapshot Not Enabled for this company</b></Typography>
           <Typography level="h6"><b>Referral Program Not Enabled for this company</b></Typography>
            </>
           
            );
        }
        return (
            <div>
                <DefaultLayout content={<ContentLayout />} />
            </div>
        );
    }
    
    export default ShowMassage;