
import React,{useMemo} from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box, Card,CardContent,Grid,TextField,FormLabel,Tooltip,RadioGroup,Button,FormControlLabel,Radio,FormControl,Checkbox, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MaterialReactTable from 'material-react-table';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { data } from '../CompanyPanel/makeData';
function AffilateBankdetails(props) {
    const [value, setValue] = React.useState(null);
    const HotLeadsColumns = useMemo(
        () => [
          {
            id: 'employee', //id used to define `group` column
            header: 'Employee',
            columns: [
              {
                accessorFn: (row) => `${row.firstName} ${row.lastName}`, //accessorFn used to join multiple data into a single cell
                id: 'name', //id is still required when using accessorFn instead of accessorKey
                header: 'Name',
                size: 200,
              },
              {
                accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Email',
                size: 200,
              },
            ],
          },
         
        ],
        [],
      );
    function ContentLayout() {
        return (
            <Box>
                <Card>
                    <Grid container item lg={12} spacing={2} mt={1} >
                        <Grid item md={6}>
                            <h4 style={{color:"black"}}>Bank Details Panel (AID - 100PercentElites)</h4>
                        </Grid>
                        <Grid item md={6}>
                            <h4 style={{color:"black"}}>Last updated: 02-03-2023</h4>
                        </Grid>
                    </Grid>
                    <CardContent>
                        <Grid container item lg={12} spacing={2} mt={1}>
                            <Grid item md={3}>
                                <FormLabel>Bank Name</FormLabel>
                            </Grid>
                            <Grid item md={9}>
                                <TextField
                                    type="text"
                                    size="small"
                                    fullWidth
                                    autoComplete='bankName'
                                />
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} spacing={2} mt={1}>
                            <Grid item md={3}>
                                <FormLabel>Name on Account*</FormLabel>
                            </Grid>
                            <Grid item md={9}>
                                <TextField
                                    type="text"
                                    size="small"
                                    fullWidth
                                    autoComplete='nameOnAccount'
                                />
                                <span style={{color:"blue"}}>Enter the name of the Account held with the Bank. (Do not enter the Name of the Bank Here)</span>
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} spacing={2} mt={1}>
                            <Grid item md={3}>
                                <FormLabel>Bank Routing (ABA/ACH) Number<Tooltip title="Delete"><img src={require("../../../assets/images/info-icon.png")}/></Tooltip></FormLabel>
                            </Grid>
                            <Grid item md={9}>
                                <TextField
                                    type="text"
                                    size="small"
                                    fullWidth
                                    autoComplete='bankRoute'
                                />
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} spacing={2} mt={1}>
                            <Grid item md={3}>
                                <FormLabel>Bank Account Number *<Tooltip title="Delete"><img src={require("../../../assets/images/info-icon.png")}/></Tooltip></FormLabel>
                            </Grid>
                            <Grid item md={9}>
                                <TextField
                                    type="text"
                                    size="small"
                                    fullWidth
                                    autoComplete='bankaccountno'
                                />
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} spacing={2} mt={1}>
                            <Grid item md={3}>
                                <FormLabel>Type of Account*</FormLabel>
                            </Grid>
                            <Grid item md={9}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="Checking" control={<Radio />} label="Checking" />
                                        <FormControlLabel value="Savings" control={<Radio />} label="Savings" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} spacing={2} mt={1}>
                            <Grid item md={3}>
                                <FormLabel>And is Account*</FormLabel>
                            </Grid>
                            <Grid item md={9}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="Personal" control={<Radio />} label="Personal" />
                                        <FormControlLabel value="Business" control={<Radio />} label="Business" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} spacing={2} mt={1}>
                            <Grid item md={3}>
                                <FormLabel>Email Address to notify of transfers sent*</FormLabel>
                            </Grid>
                            <Grid item md={9}>
                                <TextField
                                    type="text"
                                    size="small"
                                    fullWidth
                                    autoComplete='emailsaddress'
                                />
                            </Grid>
                        </Grid>
                        <h4>BACK OFFICE USE ONLY DO NOT ENTER ANY INFORMATION BELOW</h4>
                        <Card>
                            <CardContent>
                                <Grid container item lg={12}>
                                    <Grid item md={3}>
                                        <FormLabel>Have you also updated ACH details in the Bank?</FormLabel>
                                    </Grid>
                                    <Grid item md={9}>
                                        <FormControlLabel
                                            value="start"
                                            control={<Checkbox />}
                                            labelPlacement="start"
                                            />
                                    </Grid>
                                    
                                </Grid>
                                <Grid container item lg={12} spacing={2} mt={1}>
                                    <Grid item md={3}>
                                        <FormLabel>ACH Info Added to Bank</FormLabel>
                                    </Grid>
                                    <Grid item md={9}>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} spacing={2} mt={1}>
                                    <Grid item md={3}>
                                        <FormLabel>Last ACH update</FormLabel>
                                    </Grid>
                                    <Grid item md={9}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Basic example"
                                                value={value}
                                                onChange={(newValue) => {
                                                setValue(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Grid container style={{ marginTop: 16 }} spacing="2" >
                            <Grid item sm={12} >
                                <Box display="flex" justifyContent="flex-center">
                                    <Button variant="contained" color="warning" type="submit" > Close
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="contained" color="success" type="submit" > Update
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="contained" color="secondary" type="submit" > Report Issue
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            
            <MaterialReactTable
            columns={HotLeadsColumns}
            data={data}
            enableColumnFilterModes
            
            enableGrouping={false}
            enablePinning={false}
            enableRowSelection
            initialState={{ showColumnFilters: false }}
            positionToolbarAlertBanner="bottom"
            renderDetailPanel={({ row }) => (
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4">Signature Catch Phrase:</Typography>
                <Typography variant="h6">
                    &quot;{row.original.signatureCatchPhrase}&quot;
                </Typography>
                </Box>
            </Box>
            )}
            
        />
        </Box>
        );
    }
     
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default AffilateBankdetails;