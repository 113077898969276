import React, { useState, useEffect } from 'react';
import config from '../../../AppConfig';
import axios from 'axios';
import AuthUser from '../../../Components/Auth/AuthUser'
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
function OneBreport() {
  const [html, setHtml] = useState('');
  const { http, user } = AuthUser();


  //loader function and variable start

  const [freez, setFreez] = React.useState(false);
  const handleFreezStop = () => {
   setFreez(false);
  };
  const handleFreezStart = () => {
   setFreez(true);
  };

//loader function and variable end

useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substr(1);
      console.log(hash); // Output: updated hash value

    
    };

    // Add event listener for hash change
    window.addEventListener('hashchange', handleHashChange);

    // Call handleHashChange immediately to capture the initial hash value
    handleHashChange();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  useEffect(() => {
    handleFreezStart();
    axios({
      method: 'get',
      url: config.API_BASE_URL+'/1breport/c2213471-ea47-4f7a-8dcf-2ebd852cfcc6',
      withCredentials: false,
    })
      .then((response) => {
        handleFreezStop();
        setHtml(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
  <>
     <div dangerouslySetInnerHTML={{ __html: html }}></div>
      {/* loader code start */}
      <div>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
      >
          <Freez />
      </Backdrop>
      </div>
      {/* loader code end */}

  </>
   
  );
}

export default OneBreport;
