
import React,{useMemo,useState,useEffect} from 'react';
import {useParams, useNavigate, Link,useLocation} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import HotLeads from '../../../Components/ManageLeads/HotLeads';
import Active from '../../../Components/ManageLeads/Active';
import ApprovedNow from '../../../Components/ManageLeads/Approved';
import Completed from '../../../Components/ManageLeads/Completed';
import Deferred from '../../../Components/ManageLeads/Deferred';
import Disabled from '../../../Components/ManageLeads/Disabled';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import {Box,Tab,Grid,Tabs, Button, Stack,Backdrop,InputBase,TextField,Paper,ListItem,List } from '@mui/material';
import Typography from '@mui/joy/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { messaging, FCMServerKey, FCMSendUrl } from '../../../firbase/firstore';
import { getToken } from 'firebase/messaging';
import swal from 'sweetalert';
import { getMessaging, onMessage } from 'firebase/messaging';
import axios from 'axios';
import config from '../../../AppConfig';

let fcmToken='';

function Index(props) {
	const dispatch = useDispatch();
	const { tab } = useParams();

	const [value, setValue] = React.useState(tab);
  	const [freez, setFreez] = React.useState(true);
	const navigate = useNavigate();
  	const handleFreezStop = () => {
		// console.log('handleFreezStop')
      	setFreez(false);
  	};
  	const handleFreezStart = () => {
      	setFreez(true);
  	};

	const storeFcm = async (token) => {
		// console.log(token)
		const apiUrl = '/fcm/token/store';
		const args = {
			token: token
		}
		const res = await dispatch(postData({apiUrl,args})).unwrap();
		if(res){
			// console.log(res)
		}
	}

	/** Request permission for FCM Send Notifications */
    async function requestPermission() {
		if(Notification.permission != 'granted'){
			Notification.requestPermission().then((permission)=>{
				if(permission === 'granted'){
					/** Generate Token and stored it in db */
					getToken(messaging, { vapidKey: 'BJKKFZnMtbWQNOhRrCaSD_N2XCdveUTnHD_VethTAp1eIOp1WjTuxeSnzhhfXXkaDHu24RvTUKZzw4eMcW9H41g' }).then(async (currentToken) => {
						if (currentToken) {
							fcmToken = currentToken;   
							storeFcm(currentToken);                                                
						} else {
							// Show permission request UI
							// console.log('No registration token available. Request permission to generate one.');
							const permission = Notification.requestPermission();
							if(permission === 'granted'){
								/** Generate Token and stored it in browser */
								getToken(messaging, { vapidKey: 'BJKKFZnMtbWQNOhRrCaSD_N2XCdveUTnHD_VethTAp1eIOp1WjTuxeSnzhhfXXkaDHu24RvTUKZzw4eMcW9H41g' })
								.then((currentToken) => {
									if (currentToken) {
										fcmToken = currentToken; 
										storeFcm(currentToken);                                                          
									}
								});
							}
						}						
					}).catch((err) => {
						swal({
							title: "Alert",
							text: "An error occurred while retrieving token. "+ err,
							icon: "warning",
							button: "Ok",
						});                                                      
					});
				}else  {
					swal({
						title: "Alert",
						text: "For recieving notifications from MFSN you can enable it anytime from your browser's notification setting. Thank You",
						icon: "warning",
						buttons: {
							confirm: {
								text: "Ok",
								className: "swal-button--green-text",
							},
							cancel: {
								text: "Cancel",
								value: null,
								visible: true,
								className: "swal-button--white-text", // Add this class for red color
							  },// Add a cancel button
						  },
					}); 
				}
			});
		}else{
			getToken(messaging, { vapidKey: 'BJKKFZnMtbWQNOhRrCaSD_N2XCdveUTnHD_VethTAp1eIOp1WjTuxeSnzhhfXXkaDHu24RvTUKZzw4eMcW9H41g' }).then(async (currentToken) => {
				if (currentToken) { 
					// console.log(currentToken);
					fcmToken = currentToken;          
					storeFcm(currentToken);                          
				}
			});
			
		}
    }
	useEffect(()=>{
		handleFreezStart();
	},[])
	
	useEffect(()=>{
		const hasLoggedIn = localStorage.getItem('hasLoggedIn');
		setValue(tab);
		if(hasLoggedIn){
			requestPermission();
			localStorage.removeItem('hasLoggedIn');
		}
	});

	// console.log('freez',freez)
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

	const [data,setData] = useState();
	const [search, setSearch] = useState(null);
 
	//Fetch All Leads
	const globalSearch = async ()=>{
		const apiUrl = '/fetchAllLeads/list?search='+search;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res){
			if (res.success === true){
				setData(res.data);
				document.getElementById("globalSearchList").style.display = "block";
			}else{
				swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
				if(res.message == "This action is unauthorized.") {
					navigate('/login');
				}
			}
			
		}      
	}
	const resetData = () => {
		// setData();
		document.getElementById("globalSearchList").style.display = "none";
	}


	const ContentLayout = 
    // function ContentLayout() {
		
    // 	return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography level="h4" color="primary">Manage Leads</Typography>
              </Grid>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <Grid item lg={9} md={9} xs={9} sm={9}>
							<TabList onChange={handleChange} aria-label="scrollable auto tabs example" variant="scrollable"
        scrollButtons="auto">
                                    	<Tab label="HOT LEADS" component={Link} to="/manageleads/hot-leads" value="hot-leads" sx={{color:'black'}} />
									
                                    	<Tab label="ACTIVE" component={Link} to="/manageleads/active-leads" value="active-leads" sx={{color:'black'}} />
								
                                    	<Tab label="APPROVED" component={Link} to="/manageleads/approved-leads" value="approved-leads" sx={{color:'black'}} />
									
                                    	<Tab label="COMPLETED" component={Link} to="/manageleads/completed-leads" value="completed-leads" sx={{color:'black'}} />
									
                                    	<Tab label="DEFERRED" component={Link} to="/manageleads/deferred-leads" value="deferred-leads" sx={{color:'black'}} />
								
                                    	<Tab label="DISABLED" component={Link} to="/manageleads/disabled-leads" value="disabled-leads" sx={{color:'black'}} />
						
                                </TabList>
                            </Grid>
                            <Grid item lg={3} md={3} xs={3} sm={3}>
								<Stack direction={'row'} justifyContent={'space-between'} >
									<TextField
									id="globalSearch"
									name="globalSearch"
									value={search}
									size='small'
									fullWidth
									label="Search Here..."
									onChange={(e)=>setSearch(e.target.value)}
									onBlur={()=>resetData()}
									/>
									<Button size="small" variant="contained" onClick={globalSearch}><SearchIcon /></Button>
								</Stack>
								<List sx={{ width: '100%', maxWidth: 380,border:'1px solid #b2b8c3', overflow: 'auto', maxHeight: 300,borderRadius: 'sm', bgcolor: 'background.paper', display:'none', position:'absolute', zIndex:'5' }}  id="globalSearchList" >
									{data?data.map((row) => (
										<ListItem height={400}><Link className='btn btn-sm btn-info text-white' to={{ pathname: "/crcreport/"+row.id }} >{row.first_name} ({row.email}) ({row.phone_no}) ({row.pandadoc_id}) ({row.status?row.status.status_name:null}) </Link></ListItem>
									)):''} 
								</List>
                            </Grid>
                        </Grid>
                    </Box>
                    <TabPanel value="hot-leads"><HotLeads tab={tab} freezStop={handleFreezStop} freezStart={handleFreezStart}/></TabPanel>
                    <TabPanel value="active-leads"><Active tab={tab} freezStop={handleFreezStop} freezStart={handleFreezStart}/></TabPanel>
                    <TabPanel value="approved-leads"><ApprovedNow tab={tab} freezStop={handleFreezStop} freezStart={handleFreezStart}/></TabPanel>
                    <TabPanel value="completed-leads"><Completed tab={tab} freezStop={handleFreezStop} freezStart={handleFreezStart}/></TabPanel>
                    <TabPanel value="deferred-leads"><Deferred tab={tab} freezStop={handleFreezStop} freezStart={handleFreezStart}/></TabPanel>
                    <TabPanel value="disabled-leads"><Disabled tab={tab} freezStop={handleFreezStop} freezStart={handleFreezStart}/></TabPanel>
                </TabContext>
            </Box>
        </>
    //     );
    // }
     
    return(
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                  >
                    <Freez />
                  </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}
    
export default Index;