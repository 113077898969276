import React, { useState, useEffect } from 'react'
import { Grid, Card, TextField, Checkbox, Button, CardContent, FormControlLabel, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import DefaultLayout from '../../../Components/DefaultLayout';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CardOverflow } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../redux/apiSlice';
import { useNavigate } from "react-router-dom";
import AuthUser from '../../../Components/Auth/AuthUser';
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
function BlogDashboardEdit(props) {
    const dispatch = useDispatch();
    const { http, user } = AuthUser();
    const { id } = useParams();
    const navigate = useNavigate();
    const [blogList, setBlogList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [category, setCategoryList] = useState([]);
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const fetchBlogDetails = async () => {
        handleFreezStart();
        const apiUrl = '/admin/viewBlog/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success == true) {
                setBlogList(res.post)
                setCategoryList(res.categories);
                handleFreezStop();
            }
        }
    };
    useEffect(() => {
        fetchBlogDetails()
    }, [])
    const BlogValidation = Yup.object().shape({
        Tittle: Yup.string()
            .required('The Title field is required.'),
        Url: Yup.string()
            .required('The Url field is required.'),
        Category: Yup.string()
            .required('The Category field is required.'),

    });
    const UpdateBlog = (values, { resetForm }) => {
        http.post("admin/editBlog", {
            id: id,
            title: values.Tittle,
            url: values.Url,
            category: values.Category,
            content: values.message,
            description: values.Description,
        })
            .then((res) => {
                if (res.data.success === true) {
                    swal({
                        title: "Success",
                        text: res.data.message,
                        icon: "success",
                        button: "Ok",
                    })
                        .then((ok) => {
                            if (ok) {
                                navigate(-1);
                            }
                        });
                } else {
                    swal({
                        title: "Failed",
                        text: res.data.message,
                        icon: "error",
                        button: "Ok",
                    })
                }
            })
            .catch((error) => {
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                }).then((ok) => {
                    if (ok) {
                        fetchBlogDetails()
                    }
                });
            });

    }
    const handleDeleteBlog = async () => {
        const apiUrl = '/admin/deleteBlog/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success === true) {
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                }).then((ok) => {
                    if (ok) {
                        navigate(-1);
                    }
                });
            } else {
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
            }
        }

    }
    function ContentLayout() {
        return (
            <>
                {/* {isLoading ? <><Loader /></> : ''} */}
                <Card>
                    <CardOverflow sx={{ backgroundColor: "#2A68B3", padding: "1%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h4" sx={{ color: "white" }}>Edit Blog Post</Typography>
                        </Grid>
                    </CardOverflow>
                    <CardContent sx={{ padding: "0% 8% 0%", justifyContent: "center" }}>
                        <Formik
                            initialValues={{
                                Tittle: blogList.title,
                                Description: blogList.description,
                                Url: blogList.url,
                                Category: blogList.category?.id,
                                message: blogList.content,
                            }}
                            validationSchema={BlogValidation}
                            onSubmit={UpdateBlog}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                                <div>
                                    <Grid container item lg={8} md={8} sm={12} xs={12} mt={2}>
                                        <TextField label="Tittle" variant="outlined" margin='dense' size='small' fullWidth
                                            name='Tittle'
                                            id='Tittle'
                                            onChange={(e) => { handleChange(e); }}
                                            value={values.Tittle}
                                            onBlur={handleBlur('Tittle')} />
                                        {touched.Tittle && errors.Tittle ? <div className='error'>{errors.Tittle}</div> : null}
                                    </Grid>
                                    <Grid container item lg={8} md={8} sm={12} xs={12} mt={1}>
                                        <TextField label="Description" variant="outlined" margin='dense' size='small' fullWidth
                                            id='Description'
                                            onChange={(e) => { handleChange(e); }}
                                            value={values.Description}
                                            onBlur={handleBlur('Description')}
                                        />
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                        <Grid item lg={8} md={8} sm={8} xs={8} mt={1}>
                                            <TextField label="URL Slug" variant="outlined" margin='dense' size='small' fullWidth
                                                name='Url'
                                                id='Url'
                                                onChange={(e) => { handleChange(e); }}
                                                value={values.Url}
                                                onBlur={handleBlur('Url')}
                                                disabled={disabled}
                                            />
                                            {touched.Url && errors.Url ? <div className='error'>{errors.Url}</div> : null}
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={4} mt={1}>
                                            <FormControlLabel control={<Checkbox
                                                checked={!disabled}
                                                onClick={() => setDisabled(!disabled)}
                                            />} label="Edit" />
                                        </Grid>
                                    </Grid>
                                    <Grid container item lg={8} md={8} sm={12} xs={12} mt={1}>
                                        <FormControl fullWidth margin='dense' size='small'>
                                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                name='Category'
                                                id='Category'
                                                onChange={(e) => { handleChange(e); }}
                                                value={values.Category}
                                                onBlur={handleBlur('Category')}
                                                label="Category"

                                            // onChange={handleChanges}
                                            >
                                                {category.map((category) => (
                                                    <MenuItem
                                                        value={category.id}
                                                    >
                                                        {category.category}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={8} md={8} sm={12} xs={12} mt={1}>
                                        <FormControl fullWidth size="small" margin='dense'>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                required
                                                name="message"
                                                row="4"
                                                type="text"
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    values.message = data;
                                                    handleChange('message');
                                                }}
                                                onBlur={(event, editor) => {
                                                    handleBlur('message');
                                                }}
                                                data={values.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={8} md={8} sm={12} xs={12} sx={{ justifyContent: "right" }} mt={1}>
                                        <Button variant="contained" sx={{ minWidth: 150 }} onClick={handleSubmit}>Edit Post</Button>&nbsp;
                                        <Button variant="contained" sx={{ minWidth: 150 }} color="error" onClick={() => handleDeleteBlog()}>Delete Post</Button>&nbsp;
                                        <Button variant="contained" sx={{ minWidth: 150 }} color="success" onClick={() => navigate(-1)}>Close</Button>
                                    </Grid>
                                </div>
                            )}

                        </Formik>
                    </CardContent>
                </Card>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}


export default BlogDashboardEdit;