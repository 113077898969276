import React from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import {Box,Tab,Grid,Backdrop } from '@mui/material';
import Typography from '@mui/joy/Typography';
import Freez from '../../../Components/Loader/Loader';
import Pending from '../../../Components/BounceEmail/Pending';
import ChangeRequest from '../../../Components/BounceEmail/ChangeRequest';
function Index(props) {
	const { tab } = useParams();
    const [value, setValue] = React.useState(tab);
  	const [freez, setFreez] = React.useState(false);
  	
      const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const ContentLayout = 
    <>
    <Box sx={{ width: '100%', typography: 'body1' }}>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography level="h4" color="primary">Bounced Emails</Typography>
              </Grid>
              <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
							<TabList onChange={handleChange} aria-label="lab API tabs example" >
                                <Tab label="Pending" component={Link} to="/bounceemail/pending" value="pending" sx={{color:'black'}} />
                            
                                <Tab label="Updated Emails" component={Link} to="/bounceemail/change-request" value="change-request" sx={{color:'black'}} />
									
                            </TabList>
                        </Grid>
                    </Box>
                    <TabPanel value="pending"><Pending tab={tab} /></TabPanel>
                    <TabPanel value="change-request"><ChangeRequest tab={tab} /></TabPanel>
                    
                </TabContext>
    </Box>
        </>
    
     
    return(
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                  >
                    <Freez />
                  </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}
    
export default Index;