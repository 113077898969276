import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid, Box, Tab, Tabs, Button, Alert} from '@mui/material';
import { Typography } from '@mui/joy';
import MaterialReactTable from 'material-react-table';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import AuthUser from '../../../Components/Auth/AuthUser';
import config from '../../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {  useDispatch } from 'react-redux';
import { postData } from '../../../redux/apiSlice';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AssignCRO(props) {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const { id } = useParams();
    const [assign, setAssign] = useState([]);
    const [assigned, setAssigned] = useState([]);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [name, setName] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [rowSelection, setRowSelection] = useState({});
    const [rowSelection1, setRowSelection1] = useState({});

    
    const rowsSeleted = Object.keys(rowSelection);
    const rowsSeleted1 = Object.keys(rowSelection1);


    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [columnVisibility1, setColumnVisibility1] = React.useState({});

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
    }, [rowSelection]);
    useEffect(() => {
        if (Object.values(columnVisibility1).length > 0) {
            setColumnVisibility1(columnVisibility1);
        }
        //do something when the row selection changes...
    }, [rowSelection1]);

    const getAssignData = async() => {
        const apiUrl = '/getAssignData';
        const args = {
            id: id,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        setAssign(res.aidData);
        setName(res.name);
       
    }
    const getAssignedData = async() => {
        const apiUrl = '/getAssignedData';
        const args = {
            id: id,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        setAssigned(res);
       
    }

    useEffect(() => {
        getAssignData();
        getAssignedData();
    }, [])

    const assignUser = async() => {
        if (rowsSeleted.length > 0) {
            const apiUrl = '/assignData';
            const args = {
                id: id,
                aid: rowsSeleted,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setSuccessMessage(res.message)
            getAssignData();
            getAssignedData();
            setTimeout(function () {
                setSuccessMessage('')
                setRowSelection([]);

            }, 3000);
        } else {
            alert('Please select at least one company');
          }
       
       
    }
   
    
    const assignedUser = async() => {
        if (rowsSeleted.length > 0) {
            const apiUrl = '/assignedUser';
            const args = {
                id: id,
                aid: rowsSeleted,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setSuccessMessage(res.message)
            getAssignData();
            getAssignedData();
            setTimeout(function () {
                setSuccessMessage('')
                setRowSelection([]);

            }, 3000);
        } else {
            alert('Please select at least one company');
          }    
    }
   
    
    
    const columns = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column
                columns: [
                    {
                        accessorFn: (data) => `${data.aids_company.company_name}`, //accessorFn used to join multiple data into a single cell
                        id: 'name', //id is still required when using accessorFn instead of accessorKey
                        header: 'CRO Company',
                        size: 250,

                    },
                    {
                        accessorFn: (data) => `${data.aid}`, //accessorFn used to join multiple data into a single cell
                        enableClickToCopy: true,
                        header: 'Aid',
                        size: 300,
                    },
                ],
            },

        ],
        [],
    );
    const columns2 = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column
                columns: [
                    {
                        accessorFn: (data) => `${data.aids_company.company_name}`, //accessorFn used to join multiple data into a single cell
                        id: 'name', //id is still required when using accessorFn instead of accessorKey
                        header: 'CRO Company',
                        size: 250,

                    },
                    {
                        accessorFn: (data) => `${data.aid}`, //accessorFn used to join multiple data into a single cell
                        enableClickToCopy: true,
                        header: 'Aid',
                        size: 300,
                    },
                ],
            },

        ],
        [],
    );
     //csv option start here
     const handleExportData = () => {
        let exportedData;
        let fileName;
      
        if (value === 0) {
          exportedData = assign.map((row) => ({
            'CRO Company': row.aids_company?.company_name || 'N/A',
            'AID': row.aid || 'N/A',
          }));
      
          fileName = 'Assign.csv';
        } else if (value === 1) {
          exportedData = assigned.map((row) => ({
            'CRO Company': row.aids_company?.company_name || 'N/A',
            'AID': row.aid || 'N/A',
          }));
      
          fileName = 'Assigned.csv';
        }
      
        const csvContent = convertToCSV(exportedData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(blob);
      
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      
        URL.revokeObjectURL(url);
      };
      
      const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(',') + '\n';
        const rows = data
          .map((row) => Object.values(row).map((value) => escapeValue(value)).join(','))
          .join('\n');
      
        return headers + rows;
      };
      
      const escapeValue = (value) => {
        // If the value contains commas or double quotes, wrap it in double quotes
        if (/,|"/.test(value)) {
          value = '"' + value.replace(/"/g, '""') + '"';
        }
        return value;
      };
  //csv end here
  const ContentLayout = (
            <>
                {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                <Grid lg={12} md={12} sm={12} xs={12}>
                    <Typography level="h4" color='primary'>Assign CRO To User: {name}</Typography>
                </Grid>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs  value={value} onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Assign"   {...a11yProps(0)}/>
                                <Tab label="Assigned"   {...a11yProps(1)}/>
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <MaterialReactTable
                                columns={columns}
                                data={assign}
                                enableColumnFilterModes
                                enableColumnFilters={config.DatatableColumnFilter}
                                getRowId={(data) => data.aid} //give each row a more useful id
                                enableRowSelection
                                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                                state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                                initialState={{
                                    showColumnFilters: false,
                                    density: 'compact',
                                }}
                                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                                positionToolbarAlertBanner="bottom"
                                enableDensityToggle={false}
                                //top csv option
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Box
                                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                    >
                                    <Button
                                        color="primary"
                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                        onClick={handleExportData}
                                        startIcon={<FileDownloadIcon />}
                                    variant='contained'
                                        >
                                        CSV
                                        </Button>
                                    </Box>
                                )
                                }
                            />
                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} sx={{ textAlign: "center", width: "100%" }}>
                                <Grid item>
                                    <Button variant="contained" sx={{ minWidth: 200 }} color="success" onClick={() => assignUser()}>
                                        Assign USER
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" sx={{ minWidth: 200 }} onClick={() =>   navigate('/partner-admin/administration')}>
                                        CLOSE
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                        <MaterialReactTable
                                columns={columns2}
                                data={assigned}
                                enableColumnFilterModes
                                enableColumnOrdering
                                
                                enableRowSelection
                                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                                state={{ columnVisibility, rowSelection }}
                                getRowId={(data) => data.aid} //give each row a more useful id
                                
                                initialState={{
                                    showColumnFilters: false,
                                    density: 'compact',
                                }}
                                onColumnVisibilityChange={(state) => { setColumnVisibility1(state) }}
                                positionToolbarAlertBanner="bottom"
                                enableDensityToggle={false}
                                //top csv option
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Box
                                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                    >
                                    <Button
                                        color="primary"
                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                        onClick={handleExportData}
                                        startIcon={<FileDownloadIcon />}
                                    variant='contained'
                                        >
                                        CSV
                                        </Button>
                                    </Box>
                                )
                                }
                            />
                             <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} sx={{ textAlign: "center", width: "100%" }}>
                                <Grid item>
                                    <Button variant="contained" sx={{ minWidth: 200 }} color="success" onClick={() => assignedUser()} >
                                        Unassigned USER
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" sx={{ minWidth: 200 }} onClick={() =>   navigate('/partner-admin/administration')} >
                                        CLOSE
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                </Box>
               
            </>
        )
    return (
        <div>
            <DefaultLayout content={ContentLayout} />
           
        </div>
    );

}

export default AssignCRO;