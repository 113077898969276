
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import {Button, Grid, Box ,Tooltip,Dialog, DialogActions,DialogTitle,DialogContent,Slide} from '@mui/material';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import Loader from '../../../Components/Loader/Loader';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import Moment from 'moment';
import BackIcon from '@mui/icons-material/HighlightOff';
import CustomizeEnrollIndex from '../../../Components/CobrandCustomization/CustomizeEnrollIndex'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let bannerImg1 = require("../../../assets/images/defaultImg1.png")
let bannerImg2 = require("../../../assets/images/defaultImg2.png")
let bannerImg3 = require("../../../assets/images/defaultImg3.png")
let defaultCustomColor = [{ bodyBackground: '#FFFFFF', primaryColor: '#3470B1', hyperlinkColor: '#3470B1' }]

function CustomizationDetails(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { user } = AuthUser();
    const { id, name } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [logList, setLogList] = useState([]);
    const [openPreviewPopup, setOpenPreviewPopup] = useState(false)
    const [croppedImages, setCroppedImages] = useState([]);
    const [customColorArrays, setCustomColorArrays] = useState(defaultCustomColor);
    const [textColor, setTextColor] = useState('black')
    const [templateValue, setTemplateValue] = useState(1)
    const [btnTextColor, setBtnTextColor] = useState('white')

    useEffect(() => {
    }, [rowSelection]);

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }

    }, [rowSelection]);

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);

    const checkColumnVisibility = async () => {
        if (Object.values(columnVisibility).length > 0) {
            const apiUrl = '/saveTablePreferences';
            const args = {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'cobrandCustomization'
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
        }
    }

    useEffect(() => {
        checkColumnVisibility();
    }, [columnVisibility])

    const tablePreferences = async () => {
        const apiUrl = '/fetchTablePreferences/cobrandCustomization';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (Object.values(res).length == 0) {
                setColumnVisibility(tablePreferencesArray);
            } else {
                setColumnVisibility((JSON.parse(res.preferences)));
            }
        }
    }
    const tablePreferencesArray = {
    }
    useEffect(() => {
        fetchCustomizationDetails()
    }, [])

    const fetchCustomizationDetails = async () => {
        const apiUrl = '/cobrandCustomizeLog/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setLogList(res.data.requestDetails)
            setIsLoading(false)
        }
    };
    const getOpenPreviewPopup = (id,customerId) => {
        setIsLoading(true)
        localStorage.setItem("customizationReviewCompanyId", customerId)
        setTemplateValue(1)
        const list = logList.find(item => item.id === id);
        let banner1 = list.hero_image_1 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_1 : bannerImg1;
        let banner2 = list.hero_image_2 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_2 : bannerImg2;
        let banner3 = list.hero_image_3 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_3 : bannerImg3
        setCroppedImages(
            [
                { step: 1, url: banner1, },
                { step: 2, url: banner2, },
                { step: 4, url: banner3, }
            ]
        );
        setCustomColorArrays(
            [
                { bodyBackground: list.body_background_color ? list.body_background_color : customColorArrays[0].bodyBackground, primaryColor: list.primary_color ? list.primary_color : customColorArrays[0].primaryColor, hyperlinkColor: list.hyper_link_color ? list.hyper_link_color : customColorArrays[0].hyperlinkColor },
            ])
        setTextColor(list.text_color ? list.text_color : 'black')
        getContrastText(list.primary_color ? list.primary_color : '#3470B1')
        setOpenPreviewPopup(true)
        setIsLoading(false)
    }
    const getContrastText = (color) => {
        // Convert background color to RGB
        const rgb = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
        const r = parseInt(rgb[1], 16);
        const g = parseInt(rgb[2], 16);
        const b = parseInt(rgb[3], 16);

        // Calculate brightness using the formula (r * 299 + g * 587 + b * 114) / 1000
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // If brightness is greater than 125, return black, else return white
        brightness > 125 ? setBtnTextColor('black') : setBtnTextColor('white');

    };
    const previewNextBtn = () => {
        setTemplateValue(templateValue + 1)
    }
    const previewPreviousBtn = () => {
        setTemplateValue(templateValue - 1)
    }
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorKey: 'status',
                accessorFn: (row) => `${row.cobrand_customize_activity_log?row.cobrand_customize_activity_log.type ? row.cobrand_customize_activity_log.type.trim() : '':''}`,
                header: 'Status',
                size: 50,
                Cell: ({ row }) => {
                    const type = row.original.cobrand_customize_activity_log ? row.original.cobrand_customize_activity_log.type : '';
                    const trimmedType = type ? type.trim() : 'N/A';
                    return (
                        <>
                            {trimmedType === 're-setted' ? 'Reset' : 'Approved'}
                        </>
                    );
                },
            },
            {
                accessorKey: 'name',
                accessorFn: (row) => `${row.cobrand_customize_activity_log?row.cobrand_customize_activity_log.user?row.cobrand_customize_activity_log.user.name ? row.cobrand_customize_activity_log.user.name.trim() : '': '': ''}`,
                header: 'Agent Name',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.cobrand_customize_activity_log?row.original.cobrand_customize_activity_log.user?row.original.cobrand_customize_activity_log.user.name ? row.original.cobrand_customize_activity_log.user.name.trim() : 'N/A': 'N/A': 'N/A'}

                    </>
                ),
            },
            {
                accessorKey: 'Comments',
                accessorFn: (row) => `${row.cobrand_customize_activity_log?row.cobrand_customize_activity_log.notes ? row.cobrand_customize_activity_log.notes.trim() : '':''}`,
                header: 'Comments',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <Box className="datatable">
                        <Typography>{row.original.cobrand_customize_activity_log?row.original.cobrand_customize_activity_log.notes ? row.original.cobrand_customize_activity_log.notes.trim() : 'N/A': 'N/A'}</Typography>
                    </Box>
                ),
            },
            {
                accessorKey: 'Created Date',
                accessorFn: (row) => `${row.cobrand_customize_activity_log?row.cobrand_customize_activity_log.created_at ? row.cobrand_customize_activity_log.created_at : '': ''}`,
                header: 'Created Date',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <div>
                        {row.original.cobrand_customize_activity_log?row.original.cobrand_customize_activity_log.created_at ? Moment(row.original.cobrand_customize_activity_log.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A': 'N/A'}
                    </div>
                ),
            },
         
            {
                accessorFn: (row) =>
                    <Box>
                        <Tooltip title="Preview" arrow>
                            <Link onClick={() => getOpenPreviewPopup(row.id, row.company_master_id)}><img src={require('../../../assets/icons/preview-icon.png')} /></Link>
                        </Tooltip>
                    </Box>,
                accessorKey: 'Actions',
                id: 'actions',
                header: 'Actions',

            },
        ]
    )

    function ContentLayout() {
        return (
            <>
                {isLoading ? <Loader /> : <></>}
                <Grid item container xs={12}>
                    <Grid item xs={11}>
                        <Typography level="h4" color="primary">Log Details</Typography>
                    </Grid>
                    <Grid item xs={1} textAlign="right" sx={{ justifyContent: "center" }}>
                        <Tooltip title="Close" >
                            <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                    <Typography level="h6" color="primary">Comapny Name :- {name}</Typography>
                </Grid>


                <Grid mt={3}>
                    <MaterialReactTable
                        columns={columns}
                        data={logList}
                        enableColumnFilterModes
                        filterFns={{
                            customSearchFilterFn: (row, id, filterValue) =>
                                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                        }}
                        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                        enableColumnFilters={config.DatatableColumnFilter}
                        initialState={{ showColumnFilters: false, density: 'compact' }}
                        positionToolbarAlertBanner="bottom"
                        enableDensityToggle={false}
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        state={{ columnVisibility, rowSelection, isLoading: isLoading }}
                        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                        muiTableHeadCellProps={{
                            sx: {
                                backgroundColor: "#F6FAFF",
                            },
                        }}
                    />
                </Grid>
                <Dialog
                        fullScreen
                        open={openPreviewPopup}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                            <Grid sx={{ display: 'flex', justifyContent: 'center' }} className='preview-box-wrapper'>
                                <Grid >
                                    <Button variant="contained" onClick={previewPreviousBtn} disabled={templateValue == 1 ? true : false} color="success" sx={{ minWidth: 250 }}>
                                        Previous
                                    </Button>
                                </Grid>
                                <Typography level="h4" sx={{ textAlign: "center", padding: '0% 4%' }}>Page Preview</Typography>
                                <Grid  >
                                    <Button variant="contained" onClick={previewNextBtn} disabled={templateValue == 5 ? true : false} color="success" sx={{ minWidth: 250 }}>
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                            <Button variant='contained' color="primary" onClick={() => setOpenPreviewPopup(false)} className='preview-close-btn'>Close</Button>
                        </DialogTitle>
                        <DialogContent sx={{ margin: 'auto', marginTop: '10px' }} >
                            <CustomizeEnrollIndex templateValue={templateValue} cobrandFlag={openPreviewPopup ? true : false} croppedImages={croppedImages} customColorArrays={customColorArrays} textColor={textColor} btnTextColor={btnTextColor} />
                        </DialogContent>
                        <DialogActions sx={{ margin: 'auto' }}>

                            <Grid container item lg={12} md={12} sm={12} xs={12} columnSpacing={3}>


                            </Grid>
                        </DialogActions>
                    </Dialog>
            </>
        );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}

export default CustomizationDetails;