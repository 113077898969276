import React, { useMemo, useState, useEffect } from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, Tooltip, Grid, Slide, DialogTitle} from '@mui/material';
import { Typography } from '@mui/joy';
import MaterialReactTable from 'material-react-table';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Loader/Loader';
import { Link } from 'react-router-dom';
import config from '../../AppConfig';
import { useDispatch } from 'react-redux';
import AuthUser from "../../Components/Auth/AuthUser";
import { fetchData, postData } from '../../redux/apiSlice';
import CustomizeEnrollIndex from '../../Components/CobrandCustomization/CustomizeEnrollIndex'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
let bannerImg1 = require("../../assets/images/defaultImg1.png")
let bannerImg2 = require("../../assets/images/defaultImg2.png")
let bannerImg3 = require("../../assets/images/defaultImg3.png")
let defaultCustomColor = [{ bodyBackground: '#FFFFFF', primaryColor: '#3470B1', hyperlinkColor: '#3470B1' }]
function PublishedLog(props) {
    const dispatch = useDispatch();
    const {user } = AuthUser();
    const [freez, setFreez] = React.useState(true);
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [customizeDetailsLogList, setCustomizeDetailsLogList] = useState([]);
    const [openPreviewPopup, setOpenPreviewPopup] = useState(false)
    const [croppedImages, setCroppedImages] = useState([]);
    const [customColorArrays, setCustomColorArrays] = useState(defaultCustomColor);
    const [textColor, setTextColor] = useState('black')
    const [templateValue, setTemplateValue] = useState(1)
    const [btnTextColor, setBtnTextColor] = useState('white')


    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    useEffect(() => {
    }, [rowSelection]);

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }

    }, [rowSelection]);

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);

    const checkColumnVisibility = async () => {
        if (Object.values(columnVisibility).length > 0) {
            const apiUrl = '/saveTablePreferences';
            const args = {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'cobrandCustomization'
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
        }
    }

    useEffect(() => {
        checkColumnVisibility();
    }, [columnVisibility])

    const tablePreferences = async () => {
        const apiUrl = '/fetchTablePreferences/cobrandCustomization';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (Object.values(res).length == 0) {
                setColumnVisibility(tablePreferencesArray);
            } else {
                setColumnVisibility((JSON.parse(res.preferences)));
            }
        }
    }
    const tablePreferencesArray = {
    }
    useEffect(() => {
        fetchCorandDetails()
    }, [])
    const fetchCorandDetails = async () => {
        handleFreezStart()
        const apiUrl = '/admin/cobrandCustomizeDetails'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setIsLoading(false);
            handleFreezStop();
            setCustomizeDetailsLogList(res.data);
        }
    }
    const getOpenPreviewPopup = (row) => {
        handleFreezStart()
        localStorage.setItem("customizationReviewCompanyId", row)
        setTemplateValue(1)
        const list = customizeDetailsLogList.find(item => item.company_master_id === row);

        let banner1 = list.hero_image_1 && list.default_setting == 0 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_1 : bannerImg1;
        let banner2 = list.hero_image_2 && list.default_setting == 0 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_2 : bannerImg2;
        let banner3 = list.hero_image_3 && list.default_setting == 0 ? config.BACKEND_URL + "images/bannerImages/" + list.hero_image_3 : bannerImg3
        setCroppedImages(
            [
                { step: 1, url: banner1, },
                { step: 2, url: banner2, },
                { step: 4, url: banner3, }
            ]
        );
        setCustomColorArrays(
            [
                { bodyBackground: list.body_background_color&& list.default_setting == 0 ? list.body_background_color : customColorArrays[0].bodyBackground, primaryColor: list.primary_color && list.default_setting == 0 ? list.primary_color : customColorArrays[0].primaryColor, hyperlinkColor: list.hyper_link_color && list.default_setting == 0 ? list.hyper_link_color : customColorArrays[0].hyperlinkColor },
            ])
        setTextColor(list.text_color && list.default_setting == 0 ? list.text_color : 'black')
        getContrastText(list.primary_color&& list.default_setting == 0 ? list.primary_color : '#3470B1')
        setOpenPreviewPopup(true)
        handleFreezStop()
    }
    const getContrastText = (color) => {
        // Convert background color to RGB
        const rgb = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
        const r = parseInt(rgb[1], 16);
        const g = parseInt(rgb[2], 16);
        const b = parseInt(rgb[3], 16);

        // Calculate brightness using the formula (r * 299 + g * 587 + b * 114) / 1000
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // If brightness is greater than 125, return black, else return white
        brightness > 125 ? setBtnTextColor('black') : setBtnTextColor('white');

    };
    const previewNextBtn = () => {
        setTemplateValue(templateValue + 1)
    }
    const previewPreviousBtn = () => {
        setTemplateValue(templateValue - 1)
    }
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorKey: 'aidName',
                accessorFn: (row) => `${row.aidList ? row.aidList.trim() : ''}`,
                header: 'AID',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.aidList ? row.original.aidList.trim() : 'N/A'}

                    </>
                ),
            },
            {
                accessorKey: 'companyName',
                accessorFn: (row) => `${row.company_name ? row.company_name.trim() : ''}`,
                header: 'Company Name',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.company_name ? row.original.company_name.trim() : 'N/A'}

                    </>
                ),
            },
          
            {
                accessorFn: (row) =>
                    <Box>
                        <Tooltip title="View Details" arrow>
							<Link className='btn btn-success text-white' 
                            to={"/customization-details/show/" + row.company_master_id +"/"+ row.company_name}
                             ><img src={require('../../assets/images/details.png')} /></Link>
						</Tooltip>

                        <Tooltip title="Show this setting" arrow>
                            <Link onClick={() => getOpenPreviewPopup(row.company_master_id)}><img src={require('../../assets/icons/preview-icon.png')} /></Link>
                        </Tooltip>
                    </Box>,
                accessorKey: 'Actions',
                id: 'actions',
                header: 'Actions',
            },
        ]
    )
  
  
    return (
        <>
            <Box sx={{ mt: 1.5 }} >
                <div>
                    <Grid mt={3}>
                        <MaterialReactTable
                            columns={columns}
                            data={customizeDetailsLogList}
                            enableColumnFilterModes
                            filterFns={{
                                customSearchFilterFn: (row, id, filterValue) =>
                                    row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                            }}
                            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                            enableColumnFilters={config.DatatableColumnFilter}
                            initialState={{ showColumnFilters: false, density: 'compact' }}
                            positionToolbarAlertBanner="bottom"
                            enableDensityToggle={false}
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ columnVisibility, rowSelection, isLoading: isLoading }}
                            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                            muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                    backgroundColor: "#F6FAFF",
                                },
                            }}
                        />
                    </Grid>


                    <Dialog
                        fullScreen
                        open={openPreviewPopup}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                            <Grid sx={{ display: 'flex', justifyContent: 'center' }} className='preview-box-wrapper'>
                                <Grid >
                                    <Button variant="contained" onClick={previewPreviousBtn} disabled={templateValue == 1 ? true : false} color="success" sx={{ minWidth: 250 }}>
                                        Previous
                                    </Button>
                                </Grid>
                                <Typography level="h4" sx={{ textAlign: "center", padding: '0% 4%' }}>Page Preview</Typography>
                                <Grid  >
                                    <Button variant="contained" onClick={previewNextBtn} disabled={templateValue == 5 ? true : false} color="success" sx={{ minWidth: 250 }}>
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                            <Button variant='contained' color="primary" onClick={() => setOpenPreviewPopup(false)} className='preview-close-btn'>Close</Button>
                        </DialogTitle>
                        <DialogContent sx={{ margin: 'auto', marginTop: '10px' }} >
                            <CustomizeEnrollIndex templateValue={templateValue} cobrandFlag={openPreviewPopup ? true : false} croppedImages={croppedImages} customColorArrays={customColorArrays} textColor={textColor} btnTextColor={btnTextColor} />
                        </DialogContent>
                        <DialogActions sx={{ margin: 'auto' }}>

                            <Grid container item lg={12} md={12} sm={12} xs={12} columnSpacing={3}>


                            </Grid>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
        </>
    );

}


export default PublishedLog;