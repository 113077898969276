import React, { useState,useEffect } from 'react';
import { Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import AuthUser from '../../../Components/Auth/AuthUser';
import swal from "sweetalert";
import config from '../../../AppConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  imageContainer: {
    position: 'relative',
   
    marginBottom: theme.spacing(2),
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  stamp: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    padding: theme.spacing(1),
    fontSize: '22px Arial',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

let imageUrl1 = "";
function App() {
  const { http } = AuthUser();
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState(config.BASE_URL+'genericlogo.png');
  const [stampText, setStampText] = useState('');
  const [stampedImageUrl, setstampedImageUrl] = useState();
  const [value, setValue] = React.useState('option1');
  const fileInputRef = React.useRef(null);
 




  const handleImageChange = (event) => {

    
   
    setImageUrl(URL.createObjectURL(event.target.files[0]));
    imageUrl1 =event.target.files[0];
    
    const img = new Image();
    img.src = URL.createObjectURL(event.target.files[0])


    img.onload = () => {
      
        
        if (img.width != 300 || img.height != 90) {
            alert('Image must be 300px wide and 90px high.');
            fileInputRef.current.value = null;
            setImageUrl(null);
          }
      };
  };

  const handleStampChange = (event) => {
    setStampText(event.target.value);
    handleSaveImage(stampText);
  };


  const handleChange = (event) => {
    setValue(event.target.value);
    
    

    if(value=="option2"){
        setImageUrl('http://localhost:3001/genericlogo.png');
    }else{
        setImageUrl(null);
    }
  };


  const handleSaveImage = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = new Image();

    img.crossOrigin = 'anonymous';
img.src = imageUrl
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);
      context.font = '22px Arial';
      context.fillStyle = 'black';
      context.fillText(stampText, img.width - 200, img.height - 50);
      setstampedImageUrl(canvas.toDataURL('image/png'));
      
      // Save stampedImageUrl to database or use it as the source of an <img> tag
    };
    img.src = imageUrl;


  };

  const UploadLogo = () => {



    if(value=="option1"){
        alert("generic logo saved");

        http.post('/uploadCompanyLogo',{
            companyLogo:stampedImageUrl
          
          }).then((res)=>{
            if(res.data.success === true){
              
            
             swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                button: "Ok",
                })
  
            }else{
                swal({
                    title: "Alert",
                    text: "Something went wrong please try again",
                    icon: "error",
                    button: "Ok",
                })
            }
            
          }).catch(error => {
            swal({
                title: "Alert",
                text: "Network Error",
                icon: "error",
                button: "Ok",
            })
          })


    }else{
       

        const formData = new FormData(); // Create a FormData object
    formData.append('companyLogo', imageUrl1);
     
        http.post('/uploadCompanyLogo',formData).then((res)=>{
            if(res.data.success === true){
              
            
             swal({
                title: "Success",
                text: res.data.message,
                icon: "success",
                button: "Ok",
                })
  
            }else{
                swal({
                    title: "Alert",
                    text: "Something went wrong please try again",
                    icon: "error",
                    button: "Ok",
                })
            }
            
          }).catch(error => {
            swal({
                title: "Alert",
                text: "Network Error",
                icon: "error",
                button: "Ok",
            })
          })
    }
  }
  return (
    <>
     <RadioGroup aria-label="options" name="options" value={value} onChange={handleChange}>
      
      
    
   
    <div className={classes.root}>
    <FormControlLabel value="option1" control={<Radio />} label="Use generic logo with company name" />
    {value === 'option1' && <div id="generic">
      <div className={classes.imageContainer}>
        {!stampedImageUrl && imageUrl && (
          <>
            <img src={imageUrl} alt="Image" className={classes.image} />
            <Typography variant="subtitle1" fontSize={12} className={classes.stamp}>
              {stampText}
            </Typography>
          </>
        )}
      </div>

      {stampedImageUrl && (
      <img src={stampedImageUrl} alt="Image" className={classes.image} />
      )}
      <TextField
        label="Stamp Text"
        variant="outlined"
        value={stampText}
        
        onChange={handleStampChange}
      />
      <br />
      {imageUrl && stampText && (
        <Button variant="contained" color="primary" className={classes.button} onClick={UploadLogo}>
          Save Image
        </Button>
      )}
      </div>}

      <FormControlLabel value="option2" control={<Radio />} label="Upload your company logo" />
      {value === 'option2' && <div id="logouplod">

      <input type="file" accept="image/*" ref={fileInputRef} onChange={handleImageChange} />
      <br />
      {imageUrl && (
          
            <img src={imageUrl} alt="Image" className={classes.image} /> 
            )} 
      {imageUrl && (
        <Button variant="contained" color="primary" className={classes.button} onClick={UploadLogo}>
          Save Image
        </Button>
      )}
      
        </div>}
    </div>
    </RadioGroup>
    </>
  );
}

export default App;
