import React,{useMemo,useState,useEffect} from 'react';
import { TextField,Grid,Button,Box,InputLabel ,InputAdornment ,OutlinedInput ,Alert,FormControlLabel ,FormControl ,Checkbox,Tooltip } from '@mui/material';
import { Typography, Card, CardOverflow, CardContent } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import { useNavigate, useParams } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../Components/Auth/AuthUser';
import IconButton from '@mui/material/IconButton';
import { fetchData, postData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';

import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import MaterialReactTable from 'material-react-table';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

let price = '$29.95';

function UpdatePid(props) {
    const navigate = useNavigate();
    const { user } = AuthUser();
    const dispatch = useDispatch();
    const [pidObj, setPidObj] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fee,setFee] = useState(0);
    const [freez, setFreez] = React.useState(false);
    const [columnVisibility, setColumnVisibility] = React.useState({});



    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };


    const getPidData = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        }
        const apiUrl = '/admin/pid-manager';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            setPidObj(res.data);
        }
    }

    useEffect(()=>{
        if(user.roles_id < 2) {
            getPidData();
        }else{
            navigate('/login');
        }
        
    },[]);

    const handleFee = (event) => {
        if (event.target.checked == true) {
            setFee(1);
        } else {
            setFee(0);
        }

    };

    const createPidManager = async (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        }
        handleFreezStart();
        const apiUrl = '/admin/createPID';     
        const args = {
            pid: values.pid,
            price: values.Price,
            days: values.trialLength,
            testLastName: values.lastName,
            description: values.description,
            hasFee: fee,
          }  
          const res = await dispatch(postData({ apiUrl, args })).unwrap();
          if(res){
            if(res.success == true){
                handleFreezStop();
                getPidData();
                setSuccessMessage(res.message);
                setTimeout(() => {
                    setSuccessMessage('')
                }, 3000);
            }else{
                handleFreezStop();
                getPidData();
                setErrorMessage(res.message);
                setTimeout(() => {
                    setErrorMessage('')
                }, 3000);
            }

          }
    };

    const editPid = (id) => {
          const loginUser = JSON.parse(localStorage.getItem('user'));
            if(loginUser.roles_id > 1) {
                navigate('/login');
                return false;
            }
            navigate('/EditPidManager/' + id);
          
          
      }



    const pidValidate = Yup.object().shape({
        pid: Yup.string()
            .matches(/^[0-9]+$/, 'PID must contain only numbers')
            .min(2,'Too SHort!')
            .max(5, 'PID must be 5 digits')
            .required('Please Enter PID to set.'),
        Price: Yup.string()
            .matches(/^\$?[0-9]+(\.[0-9]{1,2})?$/, 'Please enter a valid Price')
            .required('Please Enter Price.'),    
        trialLength: Yup.string()
            .matches(/^[0-9]+$/, 'Days must contain only numbers')
            .required('Please Enter TrialLength.'),
        lastName: Yup.string()
            .required('Please Enter LastName.'), 
    })

    const columns = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column
                // header: 'Employee',
                columns: [
                    {
                        accessorFn: (row) => `${row.pid}`, //accessorFn used to join multiple data into a single cell
                        id: 'PID', //id is still required when using accessorFn instead of accessorKey
                        header: 'PID',
                        size: 250,
                    },
                    {
                        accessorFn: (row) => `${row.price}`, //accessorFn used to join multiple data into a single cell
                        id: 'Price', //id is still required when using accessorFn instead of accessorKey
                        header: 'Price',
                        size: 250,
                    },
                    {
                        accessorFn: (row) => `${row.days} Days`, //accessorFn used to join multiple data into a single cell
                        id: 'TrialLength', //id is still required when using accessorFn instead of accessorKey
                        header: 'Trial Length',
                        size: 250,
                    },
                    {

                        accessorFn: (row) => `${row.hasFee !== 'TRUE' ? 'false' : 'true'}`, //accessorFn used to join multiple data into a single cell
                        id: 'hasfee', //id is still required when using accessorFn instead of accessorKey
                        header: 'Has $1 Fee',
                        size: 250,
                    },
                    {
                        accessorFn: (row) => `${row.test_last_name}`, //accessorFn used to join multiple data into a single cell
                        id: 'lastname', //id is still required when using accessorFn instead of accessorKey
                        header: 'Test Last Name	',
                        size: 250,
                    },
                    {
                        accessorFn: (row) => <>
                            <Tooltip title="Edit PID" arrow><EditOutlinedIcon onClick={() => {editPid(row.id)}} /></Tooltip>
                        </>,
                        id: 'actions',
                        header: 'Actions',
                        enableSorting: false,
                        enableColumnFilter: false,
                      },
                   
                   
                ],
            },
        ],
        [],
    );

//  csv code start here

const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Pid Manager',
    headers: ['PID','Price','Trial Length','Has $1 Fee','Test Last Name	',],
  };
  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows  = (rows) => {
    
    if(localStorage.getItem("admin") != null) {
      navigate('/login');
      return false;
    }
    const exportedData = rows.map((row) => {
      
      return {
        pid: row.original.pid || "N/A",
        price: row.original.price || "N/A",
        trialLength:`${row.original.days} Days`,
        hasfee:row.original.hasFee !== 'TRUE' ? 'false' : 'true',
        testLastName:row.original.test_last_name,

      };
    });
    
  // csvExporter.generateCsv(exportedData);
  
      // Convert data to CSV format
      const csvContent = convertToCSV(exportedData);
  
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
  
      // Append the link to the document body
      document.body.appendChild(link);
  
      // Programmatically click the link to trigger the file download
      link.click();
  
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
    };
  
    const convertToCSV = (data) => {
      const headers = csvOptions.headers.join(',') + '\n';
  
      const rows = data.map((row) => {
        return Object.values(row).map((value) => {
          // Handle any necessary formatting or escaping of values
          // For simplicity, we assume all values are already properly formatted
  
          // Wrap the value with double quotes if it contains a comma
          if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
          }
  
          return value;
        }).join(',');
      }).join('\n');
  
      return headers + rows;
    };
  
  // csv code end here

    const ContentLayout =
  
        <>
        <Box>
            <Typography level="h3" color="warning">**This PID Functionality is for Management Team Only** </Typography>
            <Grid item xs={12} p={20} sx={{padding:"50px 50px"}}>
                <Card variant='outlined' >
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',                            
                        }}
                    >
                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''} 
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''} 
                        <Typography level="h4" color="primary">PID Manager </Typography>
                        <Typography level="h6">Create, edit, and delete PID's. </Typography>

                    </CardOverflow>
                    <CardContent >
                   
                          	
                        <Formik
                            initialValues={{
                                pid: '',
                                Price: '',
                                trialLength: '',
                                lastName: '',
                                description: '',

                            }}
                            validationSchema={pidValidate}
                            onSubmit={createPidManager}
                        >
                            {({values, errors,touched,isSubmitting, handleChange, handleBlur, handleSubmit}) => (
                                <Box p={5}>
                                    

                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="PID"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='pid'
                                            value={values.pid}
                                            id='pid'
                                           
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                maxLength: 5,
                                                pattern: '[0-9]*', // Enforce numeric input
                                              }}
                                            helperText={touched.pid && errors.pid ? errors.pid : ''}
                                            error={touched.pid && errors.pid ? errors.pid : null}
                                        />

                                    </Grid>
                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="Price"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='Price'
                                            value={values.Price}
                                            placeholder={price}
                                            id='Price'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.Price && errors.Price ? errors.Price : ''}
                                            error={touched.Price && errors.Price ? errors.Price : null}
                                        />

                                    </Grid>
                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                    
                                        <FormControl  variant="outlined" fullWidth
                                            size='small' margin='dense'>
                                            <InputLabel htmlFor="trialLength">Trial Length</InputLabel>
                                            <OutlinedInput
                                                label="Trial Length"
                                                id="trialLength" 
                                                fullWidth
                                                size='small'
                                                type="text"
                                                margin='dense'
                                                name='trialLength'
                                                value={values.trialLength}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{
                                                    maxLength: 5,
                                                    pattern: '[0-9]*', // Enforce numeric input
                                                  }}
                                                
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    >
                                                    <Typography>days</Typography>
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                                // helperText={touched.trialLength && errors.trialLength ? errors.trialLength : ''}
                                                error={touched.trialLength && errors.trialLength ? errors.trialLength : null}
                                            />
                                             {touched.trialLength && errors.trialLength ?  <div className='error'>{errors.trialLength}</div> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="Test Last Name"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='lastName'
                                            value={values.lastName}
                                            id='lastName'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                                            error={touched.lastName && errors.lastName ? errors.lastName : null}
                                        />
                                    </Grid>
                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                    <Grid item xs>
                                        <FormControlLabel label="Has $1 Fee" control={<Checkbox  name='hasFeeCheckbox'/>}   
                                        onChange={(e) => {
                                            handleFee(e);
                                        }}  />
                                    </Grid>
                                    </Grid>
                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="Description"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='description'
                                            value={values.description}
                                            id='description'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.description && errors.description ? errors.description : ''}
                                            error={touched.description && errors.description ? errors.description : null}
                                        />
                                    </Grid>
                                    <Grid container  item md={12}  mt={2}>                                       
                                        <Grid item md={6} textAlign="center">
                                            <Button variant="contained" sx={{minWidth:"60%"}} color="success" type="submit" size='lg' onClick={handleSubmit}>Create PID</Button>
                                        </Grid>             
                                    </Grid>
                                </Box>
                            )}
                        </Formik>
                    </CardContent>                 
                </Card>
            </Grid>
        </Box>

        <MaterialReactTable
            className="retentiona-table"
                columns={columns}
                data={pidObj}
                filterFns={{
                    customSearchFilterFn: (row, id, filterValue) =>
                    row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                }}   
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                enableColumnFilterModes
                enableColumnFilters={false}
                
                state={{ columnVisibility}} //pass our managed row selection state to the table to use
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                   
                }}
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                      <Button
                        sx={{ bgColor: "#2A68B3" }}
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                          handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                        className='export'
                      >
                        CSV
                      </Button>
                    </Box>
                  )}
                    
        />

         {/* loader code start */}
         <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
       
     
    return(
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}
    
export default UpdatePid;