import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import RegisterFooter from '../views/Auth/RegisterFooter';

import AppSidebar from './AppSidebar';
import CobrandHeader from './CobrandHeader';
import { useNavigate } from 'react-router-dom';
import AuthUser from './Auth/AuthUser';
import Logout from '../views/Auth/logout';
const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

function DefaultLayout(props) {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  	const navigate = useNavigate();
	const { http, getToken, setToken, getUser,user } = AuthUser();
	// //Check Login
	  
	const checkLogin = () =>{
		if(!isAuthenticated){
			navigate('/login');
		}			
	}
	
    useEffect(()=>{
		checkLogin()
    })

	return (
		<>
		<div>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				{window.location.pathname==='/affiliate/initialcobrandsetup'?<CobrandHeader/>:<AppSidebar />}
				
				<Box >
					<Box component="main" sx={{ flexGrow: 1, p: 3, minHeight:"80vh",marginTop:`${localStorage.getItem("appBarHeight")}px !important`}} >
						{/* <DrawerHeader /> */}
						{props.content}
					</Box>
					<Box>
						<RegisterFooter />
					</Box>          
				</Box>
			</Box>
		</div>
		</>
	);
}
export default DefaultLayout;