import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Box, Grid, Tooltip } from '@mui/material';
import { Typography, CardOverflow, Card, CardContent, } from '@mui/joy';
import BackIcon from '@mui/icons-material/HighlightOff';
function AboutUs(props) {
  const closeTab = () => {
    props.closeVal(false)
  }
  const navigate = useNavigate();
  return (
    <>
      <Box p={1} sx={{ minHeight: 300, maxHeight: 500, borderTop: "3px solid #5288db" }}>
        <Card>
          <CardContent>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Grid lg={6} md={6} sm={6} xs={6} item>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                  <Typography level="h3" sx={{ borderBottom: "3px solid #5288db" }}>About MyFreeScoreNow</Typography>
                </Grid>
              </Grid>
              <Grid lg={6} md={6} sm={6} xs={6} item className='companyLogo'>
                <Tooltip title="Close" >
                  <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={closeTab} />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography level="body1" fontSize="lg">Day in and day out, we all get asked for personal information - when we apply for credit, rent an apartment or buy a home, seek new employment, apply for insurance or even enroll in classes at the local college.
                Generally, we give little thought to the fact that our credit scores and files are often accessed at these times too.
              </Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography level="body1" fontSize="lg">
                The reality is that computers have increasingly replaced human beings in making decisions about important things in our lives. With today’s technology, credit scores can be used day and night to make an instant assessment of your creditworthiness, then used to make yes/no decisions or to determine the terms of credit offered to you. With so much depending on your credit, it makes good sense to understand credit and your credit score.
              </Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography level="h3" sx={{ borderBottom: "3px solid #5288db" }}>Our Mission</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography level="body1" fontSize="lg">Our mission as an independent provider is to empower consumers by providing information and tools to help them better understand, monitor and protect their credit scores and credit information. We further strive to educate consumers on the close link between credit and identity theft, a silent epidemic where thieves work quickly until the crime is detected--often when it shows up on a credit report.</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography level="body1" fontSize="lg">Thank you for considering our services. Start benefitingtoday, get your free score now.</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography level="h3" sx={{ borderBottom: "3px solid #5288db" }}>About MyFreeScoreNow, Inc.</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
              <Typography level="body1" fontSize="lg">MyFreeScoreNow.com is a product of MyFreeScoreNow, Inc. MyFreeScoreNow is headquartered in the Greater Los Angeles area.</Typography>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
export default AboutUs;