import React, { useMemo, useState, useEffect } from 'react';
//MRT Imports
import MaterialReactTable from 'material-react-table';
import AuthUser from '../Auth/AuthUser';
//Material-UI Imports
import { Box, Table, TableBody, TableCell, TableHead, TableRow,Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../Components/Loader/Loader'
import config from '../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Badge from '@mui/material/Badge';
import {useNavigate} from 'react-router-dom';

function Upgraded({ props, startDate1, endDate1 }) {
  const dispatch = useDispatch();
  const { http, user } = AuthUser();
  const [data, setData] = useState([]);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const tablePreferencesArray = {

  }
  // function usestate variables
  const [open, setOpen] = React.useState(false);

  const [freez, setFreez] = React.useState(false);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };

  const fetchUserList = async page => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    handleFreezStart();
    const apiUrl = '/admin/fetchLeadReport';
    const args = {
      fromDate: startDate1,
      toDate: endDate1
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      setData(res.data.upgraded.upgradedDetails)
      
      handleFreezStop();
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if(user.roles_id < 3) {
      fetchUserList();
    }else{
      navigate('/login');
    }
  }, [rowSelection,startDate1, endDate1]);

  useEffect(()=>{  
    saveTablePreferences();
},[columnVisibility])

  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
    // console.info({ rowSelection });
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);
  ////////////////////////

  const saveTablePreferences = async() => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
          columnArray: columnVisibility,
          userId: user.id,
          tableId: 'leadreportupgraded'
      }
      const res = await dispatch(postData({apiUrl,args})).unwrap();
    } 
  }

  const tablePreferences = async() => {
    const apiUrl = '/fetchTablePreferences/leadreportupgraded';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
          if (Object.values(res).length == 0) {
            setColumnVisibility(tablePreferencesArray);
          }else {
            setColumnVisibility((JSON.parse(res.preferences)));
          }
    }
  }
  const columns = useMemo(
    () => [
      {
        id: 'leadreport', //id used to define `group` column
        header: '',
        columns: [
          {
            accessorFn: (row) => `${row.aid ? row.aid.trim() : ''}`,  //accessorFn used to join multiple data into a single cell
            id: 'aid', //id is still required when using accessorFn instead of accessorKey
            header: 'AID',
            size: 250,
            Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : "N/A"),
          },
          {
            accessorFn: (row) =>`${row.enrolledCount}`, //accessorFn used to join multiple data into a single cell
            id: 'Total_Enrollments',
            header: 'Total Enrollments',
            size: 100,
            Cell:({renderedCellValue,row}) => (
              <div style={{paddingLeft:"20%"}}>
              <Badge badgeContent={`${row.original.enrolledCount}`} color={row.original.enrolledCount === 0 ? 'primary':'success'} overlap="circular" max={9999}></Badge>
              </div>          
            ),
          },
          {
            accessorFn: (row) => `${row.upgaradedCount}`, //accessorFn used to join multiple data into a single cell
            id: 'TotalFullReportPurchased', //id is still required when using accessorFn instead of accessorKey
            header: 'Total Full Report Purchased',
            size: 200,
            Cell:({renderedCellValue,row}) => (
              <div style={{paddingLeft:"15%"}}>
              <Badge badgeContent={`${row.original.upgaradedCount}`} color={row.original.upgaradedCount === 0 ? 'primary':'success'} overlap="circular" max={9999}></Badge>
              </div>          
            ),
          },
        ],
      },

    ],
    [],
  );
   //csv option start here
   const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Lead Enrollments(Upgraded)',
    headers: ['AID','Total Enrollments','Total Full Report Purchased'],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (data) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    const exportedData = [];
    data.forEach((row) => {
      
      const mainTableRow = {
        aid: `${row.original.aid}` || 'N/A',
        Total_Enrollments:`${row.original.enrolledCount}` || 'N/A',
        TotalFullReportPurchased: `${row.original.upgaradedCount}` || 'N/A',
       
      };
      exportedData.push(mainTableRow);
      const subheader = {
				'Source': 'Source', // Subheader 1
				'Name': 'Name', // Subheader 2
				'Email': 'Email', // Subheader 3
				'AID':'AID', // Subheader 4
				'PID': 'PID', // Subheader 5
        'Phone':  'Phone', // Subheader 6
        'Full Report Purchased':'Full Report Purchased',
        'Enroll Date':'Enroll Date'
			};
			exportedData.push(subheader);
      if (row.original.upgradedmemberList) {
        row.original.upgradedmemberList.map((item) => {
          const subRow = {
            'Source': item.source,
            'Name': item.full_name,
            'Email': item.email,
            'AID': item.aid,
            'PID': item.pid ,
            'Phone': item.mobile ,
            'Full Report Purchased': item.get1b,
            'Enroll Date': item.enroll_date
          };
          exportedData.push(subRow);
        });
      } else {
        // If no subrows available, add an empty row for subrows
        exportedData.push({});
      }
      exportedData.push({});
    });
    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
			return Object.values(row).map((value) => {
			  // Handle any necessary formatting or escaping of values
			  // For simplicity, we assume all values are already properly formatted
		
			  // Wrap the value with double quotes if it contains a comma
			  if (typeof value === 'string' && value.includes(',')) {
				return `"${value}"`;
			  }
		
			  return value;
			}).join(',');
		  }).join('\n');

    return headers + rows;
  };
  //csv end here
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilterModes
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
        }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        enableColumnFilters={config.DatatableColumnFilter}
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ columnVisibility, rowSelection,isLoading:isLoading }}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        initialState={{
          showColumnFilters: false,
          density: 'compact',
        }}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              color="primary"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportData(table.getPrePaginationRowModel().rows)
                }
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              // onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              CSV
            </Button>
            
            
          </Box>
        )}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: 'grid',
              margin: 'auto',

              width: '100%',
            }}
          >
            {/* <TableContainer component={Paper}> */}
            <Table className='back'>
              {
                (row.original.upgradedmemberList)
                  ?
                  <>
                    <TableHead>
                      <TableRow>
                        {/* <TableCell sx={{fontWeight:"bold"}}>AID</TableCell> */}
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Source</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Name</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Email</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>AID</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>PID</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Phone</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Full Report Purchased</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Enroll Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.original.upgradedmemberList ? row.original.upgradedmemberList.map((item, idx, array) => (
                        <TableRow
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {/* <TableCell align="center">{row.original.aid}</TableCell> */}
                          <TableCell align="center">{item.source ? item.source : 'N/A'}</TableCell>
                          <TableCell align="center">{item.full_name ? item.full_name : 'N/A'}</TableCell>
                          <TableCell align="center">{item.email ? item.email : 'N/A'}</TableCell>
                          <TableCell align="center">{item.aid ? item.aid : 'N/A'}</TableCell>
                          <TableCell align="center">{item.pid ? item.pid : 'N/A'}</TableCell>
                          <TableCell align="center">{item.mobile ? item.mobile : 'N/A'}</TableCell>
                          <TableCell align="center">{item.get1b ? item.get1b : 'N/A'}</TableCell>
                          <TableCell align="center">{item.enroll_date ? item.enroll_date : 'N/A'}</TableCell>

                        </TableRow>
                      )) : ''}
                    </TableBody>
                  </>
                  :
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Lead Report not available</TableCell>
                  </TableRow>
              }
            </Table>
            {/* </TableContainer> */}
          </Box>
        )}
      />
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>

    </>
  );
}


export default Upgraded;