import React from 'react';
import { Card, CardContent } from '@mui/joy';
import { Grid, Button } from '@mui/material';

function ApiDocumentation(props) {
    return (
        <>
            <Card variant="outlined" size="lg">
                <CardContent>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }} mt={1}>
                        <Button variant="contained" color="success" sx={{ minWidth: 250 }}
                            onClick={() => {
                                window.open("https://documenter.getpostman.com/view/14348250/2s9Xxzts4Q", "_blank");
                            }}>
                            API Documentation
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );

}


export default ApiDocumentation;