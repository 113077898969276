import React,{useMemo,useState,useEffect} from 'react';
import { TextField,Grid,Button,Box,Alert,Tooltip } from '@mui/material';
import { Typography, Card, CardOverflow, CardContent } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import { useNavigate, useParams } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../Components/Auth/AuthUser';
import { fetchData, postData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import MaterialReactTable from 'material-react-table';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

const RequestForm = {
    pid : '',
  }

let dublicatePid = 0;
function UpdatePid(props) {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [defaultPid, setDefaultPid] = useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [openConfirmpopup, setConfirmPopup] = useState(false);
    const [status, setStatus] = useState();
    const [pidId, setPidId] = useState();
    const [errors1, setErrors] = useState({});
    const [freez, setFreez] = React.useState(false);

    const getPidData = async () => {
        handleFreezStart();
        const apiUrl = '/fetchDefaultPid';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            handleFreezStop();
            setDefaultPid(res.data);
        }
    }

    const defaultPidData = async (status,id) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        }
        setPidId(id);
        if(status == 1){
            setStatus('deactivate');
        }else{
            setStatus('activate');
        }
        setConfirmPopup(true);
        
    } 
    const defaultPidAction = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        } 
        setConfirmPopup(false);
        const apiUrl = '/defaultPidActive';
        const args = {
            id: pidId,
          }
          const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res){
           if(res.success == true){
                if(status == 1){
                    setSuccessMessage('PID deactivate Successfully');
                    getPidData();
                    setTimeout(() => {
                        setSuccessMessage('')
                    }, 3000);
                }else{
                    setSuccessMessage('PID activate Successfully');
                    getPidData();
                    setTimeout(() => {
                        setSuccessMessage('')
                    }, 3000);
                }

           }else{
            setErrorMessage(res.message);
            getPidData();
            setTimeout(() => {
                setErrorMessage('')
            }, 3000);
           }
        }
    }
    const handleClose = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        }
        setConfirmPopup(false);
      };

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };  

    const handledPidCheck = async (event) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        }
        errors1.pid = '';
        if (event.length > 4 )  
        {
            handleFreezStart();
            const apiUrl = '/checkDublicatePid';
            const args = {
                pid: event,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                handleFreezStop();

                if(res.success == false){
                    errors1.pid = 'PID already exists';
                    setErrors(errors1);
                    dublicatePid = 1;
                 
                }else{
                    handleFreezStop();
                    errors1.pid = '';
                    setErrors(errors1);
                    dublicatePid = 0;
                    
                }
            }

        }else if(event.length != 0){
            errors1.pid = 'PID must be 5 digits';
            setErrors(errors1);
            dublicatePid = 1;
        }
    };


    useEffect(()=>{
        if(user.roles_id < 2) {
            getPidData();
        }else{
            navigate('/login');
        }
        
    },[]);

    

    const pidValidate = Yup.object().shape({
        pid: Yup.string()
            .matches(/^[0-9]+$/, 'PID must contain only numbers')
            .max(5, 'PID must be 5 digits')
            .required('Please Enter PID to set.'),
    })

    const columns = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column
                // header: 'Employee',
                columns: [
                    {
                        accessorFn: (row) => `${row.pid}`, //accessorFn used to join multiple data into a single cell
                        id: 'PID', //id is still required when using accessorFn instead of accessorKey
                        header: 'PID',
                        size: 250,
                        
                    },
                    {
                        accessorFn: (row) => `${row.status == 1 ? "Active" : "Deactivate"}`,
                        id: 'Status',
                        header: 'Status',
                        Cell: ({ renderedCellValue, row }) => (
                          <Box>
                            <span>{row.original.status == 1 ? "Active" : "Deactivate"}</span>
                          </Box>
                        ),
                      },
                    {
                        accessorFn: (row) => <>
                          {row.status == 1 ? <>
                            <Button onClick={() => {defaultPidData(row.status,row.id) }}><Tooltip title="Deactivate" arrow><img src={require('../../../assets/images/defaultpidinactive.png')} /></Tooltip></Button>
                          </> : <Button onClick={() => {defaultPidData(row.status,row.id)  }}><Tooltip title="Activate" arrow><img src={require('../../../assets/images/defaultpidactive.png')} /></Tooltip></Button>}
                        </>,
                        id: 'actions',
                        header: 'Actions',
                        enableSorting: false,
                        enableColumnFilter: false,
                      },
                   
                ],
            },
        ],
        [],
    );
 //csv option start here
 const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Set Default PID',
    headers: ['PID','Status'],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (rows) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
    }
    const exportedData = rows.map((row) => {
      
      return {
        PID: `${row.original.pid ||"N/A"}`,
        Status:`${row.original.status == 1 ? "Active" : "Deactivate"}`,
       
      };
    });

    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted
        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };
  //csv end here

    const ContentLayout=
        <>
       
        
        <Box>
            <Typography level="h3" color="warning">**This PID Functionality is for Management Team Only** </Typography>
            <Grid item xs={12} p={5}>
                <Card variant='outlined' >
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',                            
                        }}
                    >
                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''} 
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''} 
                        <Typography level="h4" color="primary">Set Default PID </Typography>
                        {defaultPid.map((item, index) => ( 
                            item.status === 1 ? 
                        <Typography level="h5">Current Default PID : { item.pid }</Typography> : '' ))}

                    </CardOverflow>
                    <CardContent >
                   
                          	
                        <Formik
                            initialValues={RequestForm}
                            validationSchema={pidValidate}
                            onSubmit={(values, error)=>{
                                const loginUser = JSON.parse(localStorage.getItem('user'));
                                if(loginUser.roles_id > 1 ) {
                                    navigate('/login');
                                    return false;
                                } 
                                setIsLoading(true);  
                                if(dublicatePid == 0){
                                    http.post('/createDefaultPid',{
                                        pid: values.pid,
                                    }).then((res)=>{
                                        if(res.data.success == true){
                                            setSuccessMessage(res.data.message)
                                            getPidData();
                                            values.pid = '';
                                            setTimeout(() => {
                                                setOpenSuccess(false);
                                                setSuccessMessage('')
                                            }, 5000);
                                        }else{
                                            setIsLoading(false);    
                                            setOpenSuccess(true);
                                            setErrorMessage(res.data.message)
                                            setTimeout(() => {
                                                setOpenSuccess(false);
                                                setErrorMessage('')
                                               
                                            }, 5000);
                                        }
                                    })
                                }                              
                                
                            }}
                        >
                            {({values, errors,touched,isSubmitting, handleChange, handleBlur, handleSubmit}) => (
                                <Box p={5}>
                                    

                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="Set New Default Pid"
                                            type="text"
                                            size="small"
                                            // placeholder={pidObj}
                                            fullWidth
                                            name='pid'
                                            value={values.pid}
                                            id='pid'
                                            onChange={(e) => {
                                                handleChange(e);
                                                handledPidCheck(e.target.value);
    
                                            }}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                maxLength: 5,
                                                pattern: '[0-9]*', // Enforce numeric input
                                              }}
                                            helperText={touched.pid && errors.pid ? errors.pid : null}
                                            error={touched.pid && errors.pid ? errors.pid : null}

                                            
                                        />
                                        {errors1.pid && <div className="error">{errors1.pid}</div>}


                                    </Grid>
                                    <Grid container  item md={12}  mt={2}>                                       
                                        <Grid item lg={6} md={6} sm={6} xs={6} textAlign="center">
                                            <Button variant="contained" sx={{minWidth:"60%"}} color="success" type="submit" size='lg' onClick={handleSubmit}>Set PID</Button>
                                        </Grid>      
                                        <Grid item lg={6} md={6} sm={6} xs={6} textAlign="center">
                                            <Button variant="contained" sx={{minWidth:"60%"}} color="primary" type="submit" size='lg' onClick={()=>{navigate(-1)}}>CLOSE</Button>
                                        </Grid>                       
                                    </Grid>
                                </Box>
                            )}
                        </Formik>
                    </CardContent>                 
                </Card>
            </Grid>
        </Box>

        <MaterialReactTable
            className="retentiona-table"
                columns={columns}
                data={defaultPid}
                // enableColumnOrdering
                enableColumnFilterModes
                enableColumnFilters={false}
                filterFns={{
                    customSearchFilterFn: (row, id, filterValue) =>
                    row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                  }} 
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                // enableRowSelection
                // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ columnVisibility}} //pass our managed row selection state to the table to use
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                    sorting: [
                        {
                            id: 'name',
                            desc: false
                        },
                    ]
                }}
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                      <Button
                        color="primary"
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                                          //export all rows, including from the next page, (still respects filtering and sorting)
                            onClick={() =>
                              handleExportData(table.getPrePaginationRowModel().rows)
                            }
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        // onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        CSV
                      </Button>
                      
                      
                    </Box>
                  )}
          
                    
        />

        {/* Step 4: Display the confirmation dialog */}
            <Dialog
                open={openConfirmpopup}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setConfirmPopup(false)
                    }
                    }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"> Default PID</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to {status} this PID?
                </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button autoFocus variant="contained" sx={{ minWidth: 100 }}  onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={defaultPidAction}  autoFocus style={{ backgroundColor: 'red', color: 'white',minWidth: 100 }}>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>
              {/* loader code start */}
              <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}

        </>
       
     
    return(
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>

        
    );
}
    
export default UpdatePid;