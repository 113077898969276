
import React, { useState, useEffect } from 'react';
import { useParams, Link,  useNavigate } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import Pending from '../../../Components/PendingClosedPanel/Pending';
import Retained from '../../../Components/PendingClosedPanel/Retained';
import Closed from '../../../Components/PendingClosedPanel/Closed';
import Accepted from '../../../Components/PendingClosedPanel/Accepted';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import {
  Alert,
  Box, Tab, Grid, InputLabel, MenuItem, FormControl, Select, Button, Dialog, DialogActions,
  DialogTitle, DialogContent, OutlinedInput, Stack, ListItemIcon, Checkbox, ListItemText,
} from '@mui/material';
import AuthUser from "../../../Components/Auth/AuthUser";
import Typography from '@mui/joy/Typography';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { fetchData ,postData} from '../../../redux/apiSlice';
import swal from "sweetalert";
import { useStyles } from "../../../Components/SuspendedMembers/utils";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
}; 
// let assingedToUsers = [1,2,3];
let rowsSelected = '';
function PendingIndex(props) {
  const dispatch = useDispatch();
  const { tab } = useParams();
  const { http, user } = AuthUser();
  const [value, setValue] = React.useState(tab);
  const [assingedToUsers, setAssingedToUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assignTo, setAssignTo] = useState((JSON.parse(localStorage.getItem('user'))).roles_id===2?(JSON.parse(localStorage.getItem('user'))).id:'unAssigned');
  const [assignedOpen, setAssignedOpen] = useState(false);
  const [assignedToAction, setAssignedToAction] = useState('Action');
  //   const [rowsSeleted, setRowsSeleted] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [companyId, setCompanyId] = useState('');
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [refresh, doRefresh] = useState(0);
  const [submitEnable, setSubmitEnable] = useState(false);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setValue(tab);
    handleFreezStart();
    
  }, [tab])

  useEffect(() => {
    setUserList();
  }, [])

  const [freez, setFreez] = React.useState(true);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
   
  };
 
  const getData = async () => {
    // alert(tab)
    setIsLoading(true);
    handleFreezStart()
    const apiUrl = '/pendingData/' + tab;
    const args = {
      assignedTo: assignTo,
      companyId: companyId
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      setData(res.data.MemberData);
      setUsers(res.data.users);
      setCompanyId(res.data.companyId);
      setIsLoading(false);
      handleFreezStop()

    }
  }
  useEffect(() => {
    getData();
  }, [assignTo,refresh,tab]);
  
  const assignedToStaffSchema = Yup.object().shape({
    staffId: Yup.array()
      .required('Please select User to Assign.'),
  });

  const handleClickOpenAssigned = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
    } else {
      setAssignedOpen(true);
    }

  };

  const handleActionChange =async (event) => {
    if (rowsSelected.length > 0) {
      setAssignedToAction(event.target.value);
      if (event.target.value === "assignToStaff") {
        handleClickOpenAssigned()
      }
      else if (event.target.value === "assignToNone") {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
          navigate('/login');
          return false;
        }
        setIsLoading(true);
        const apiUrl = '/assignToNoneRetentionMembers';
        const args = {
          memberId: rowsSelected,
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            swal({
              title: "Success",
              text: res.message,
              icon: "success",
              button: "Ok",
            })
            doRefresh(prev => prev + 1);
            setAssignedToAction('Action');
            setIsLoading(false);
        }
      }
    } else {
      alert('Please select at least one lead');
    }
  }

  const isAllSelected =
    assingedToUsers.length > 0 && selected.length === assingedToUsers.length;
  const handleChange2 = async (event, obj) => {

    const {
      target: { value }
    } = event;

    const extractedArray = assingedToUsers.map(arr => arr.id);
    setSelectedID(value);

    if (value[value.length - 1] === "all" || value[0] === "all") {
      setSelected(selected.length === assingedToUsers.length ? [] : extractedArray);
      setSelectedID(extractedArray);
      return;
    }
    setSelected(value);
  };
  const setRows = (rows) => {
    rowsSelected = Object.keys(rows);
   
  };


  const setUserList = async () => {
    const apiUrl = '/fetchOnlyAdmins';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (res) {
        setAssingedToUsers(res);
      } else {
        console.log('else');
      }
    }
  };

  const handleCloseAssigned = () => {
    setAssignedOpen(false);
   

  };

  const checkAdmin = () => {
    var check = 0;
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id <= 5 || loginUser.roles_id == 7) {
      check = 1;
    }
    if (check == 0) {
      navigate('/login');
      return false;
    }

    return true;
  }

  const changeAssignedTo = (value) => {
    var check = checkAdmin();
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id < 3) {
      setAssignTo(value);
      setFreez(true);
    } else {
      navigate('/login');
      return false;
    }

  }

  const RefreshStatusForAll = async () => {
    var checkResult = checkAdmin();
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id <= 3) {
      if (loginUser.roles_id == 3 && loginUser.company_master_id != companyId) {
        navigate('/login');
        return false;
      }
    }

    if (checkResult === true) {
      setFreez(true);
      const apiUrl = '/retain-all-members';
      const res = await dispatch(fetchData(apiUrl)).unwrap();
      if (res) {
        setFreez(false);
        if (res.success === true) {

          setSuccessMessage(res.message);
          setTimeout(function () {
            setSuccessMessage('');
            // window.location.reload();
          }, 3000);

        } else {
          console.log('else');
        }
      }
    } else {
      navigate('/login');
    }
  }

  const ContentLayout =
    <>
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
      {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}

      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          {user.roles_id != 7 ?
            <>
              <Typography level="h4" color="primary">Pending Close Members</Typography>
            </>
            : <Typography level="h4" color="primary">Members with Account Set to Cancel</Typography>
          }

        </Grid>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={5} md={5} xs={12} sm={12}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example" >
                    <Tab label="Pending"  value="pending"  component={Link} to="/pendingclosepanel/pending" sx={{ color: 'black' }} style={{ borderBottom: value === 'pending' ? '2px solid #096bde' : 'none' }} onClick={()=>setAssignTo((JSON.parse(localStorage.getItem('user'))).roles_id===2?(JSON.parse(localStorage.getItem('user'))).id:'unAssigned')}/>

                    {user.roles_id <= 4 ? [
                      <>
    <Tab key="closed" label="Closed" value="closed" component={Link} to="/pendingclosepanel/closed" sx={{ color: 'black' }} style={{ borderBottom: value === 'closed' ? '2px solid #096bde' : 'none' }} onClick={() => setAssignTo((JSON.parse(localStorage.getItem('user'))).roles_id === 2 ? (JSON.parse(localStorage.getItem('user'))).id : 'unAssigned')} />
    <Tab key="retained" label="Retained" value="retained" component={Link} to="/pendingclosepanel/retained" sx={{ color: 'black' }} style={{ borderBottom: value === 'retained' ? '2px solid #096bde' : 'none' }} onClick={() => setAssignTo((JSON.parse(localStorage.getItem('user'))).roles_id === 2 ? (JSON.parse(localStorage.getItem('user'))).id : 'unAssigned')} />
    {user.roles_id < 3 ?
    <Tab key="accepted" label="Accepted" value="accepted" component={Link} to="/pendingclosepanel/accepted" sx={{ color: 'black' }} style={{ borderBottom: value === 'accepted' ? '2px solid #096bde' : 'none' }} onClick={() => setAssignTo((JSON.parse(localStorage.getItem('user'))).roles_id === 2 ? (JSON.parse(localStorage.getItem('user'))).id : 'all')} />
    : ''}
    </>
] : null}  
                </TabList>
              </Grid>
              <Grid item lg={7} md={7} xs={12} sm={12} sx={{ justifyContent: "right" }}>
                <Grid item lg={12} md={12} xs={12} sm={12} >
                  <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
                    {user.roles_id < 3 ?
                      <>
                        <Grid item lg={4} md={4} xs={12} sm={12}>
                          <FormControl fullWidth size="small" margin='dense'>
                            <InputLabel id="demo-simple-select-label">Assigned To:</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Assigned To:"
                              value={assignTo}
                              onChange={(e) => { changeAssignedTo(e.target.value) }}
                              MenuProps={MenuProps}
                            >
                                <MenuItem
                  value='unAssigned'
                >
                  Unassigned
                </MenuItem>
                              <MenuItem
                                value='all'
                              >
                                All
                              </MenuItem>
                              {assingedToUsers ?
                                assingedToUsers.map((assingedToUser) => (
                                  <MenuItem key={assingedToUser.name} value={assingedToUser.id}>{assingedToUser.name}</MenuItem>
                                )) : ''}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12} sm={12} sx={{ display: tab == 'pending' ? 'flex' : 'none' }}>
                          <FormControl fullWidth size="small" margin='dense'>
                            <InputLabel id="demo-simple-select-label">Action</InputLabel>
                            <Select
                              id="assignedToAction"
                              value={assignedToAction}
                              onChange={handleActionChange}
                              input={<OutlinedInput label="Action" />}
                              MenuProps={MenuProps}
                            >
                              <MenuItem key="Action" value="Action" disabled={true}>
                                Action
                              </MenuItem>
                              <MenuItem key="assignToStaff" value="assignToStaff">
                                Assign to Staff
                              </MenuItem>
                              <MenuItem key="assignToNone" value="assignToNone">
                                Assign to None
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                      : ''}
                    <Grid item lg={4} md={4} xs={12} sm={12} sx={{ margin: "1% !important", justifyContent: "right" }}>
                      <Button variant='contained' sx={{ minWidth: 250 }} onClick={RefreshStatusForAll}>Refresh Status for All</Button>
                    </Grid>
                  </Stack>
                </Grid>

              </Grid>
            </Grid>
          </Box>
          <TabPanel value="pending"><Pending tab={tab} assignTo={assignTo} companyId={companyId} setRows={setRows} refresh={refresh} freezStop={handleFreezStop} freezStart={handleFreezStart} tableData={data} getData={getData} user={users}/> </TabPanel>
          <TabPanel value="closed"> <Closed tab={tab} assignTo={assignTo} companyId={companyId} freezStop={handleFreezStop} freezStart={handleFreezStart} tableData={data}/> </TabPanel>
          <TabPanel value="retained"> <Retained tab={tab} assignTo={assignTo} companyId={companyId} freezStop={handleFreezStop} freezStart={handleFreezStart} tableData={data}/></TabPanel>
          <TabPanel value="accepted"> <Accepted tab={tab} assignTo={assignTo} companyId={companyId} freezStop={handleFreezStop} freezStart={handleFreezStart} tableData={data} refresh={refresh} getData={getData}/></TabPanel>

        </TabContext>
      </Box>
      <Formik
        initialValues={{
          staffId: selected,
        }}
        validationSchema={assignedToStaffSchema}
        onSubmit={async (values, error) => {
          if (selectedID.length == 0) {
            alert("Please select agent to assign");
            return false;
          }
          setSubmitEnable(true);
          const apiUrl = '/assignToMultipleRetensionMembersToAgent';
          const args = {
            assignedTo: selectedID,
            memberId: rowsSelected,
          };
          const res = await dispatch(postData({ apiUrl, args })).unwrap();
          if (res) 
          {
            handleCloseAssigned();
            if(res.RetentionMessage == 1){
              swal({
                title: "Success",
                text: res.message,
                icon: "success",
                button: "Ok",
              })
            }else{
              swal({
                title: "Error",
                text: res.message,
                icon: "error",
                button: "Ok",
              })
            }
            
            setSelectedID([]);
            setSelected([]);
            setAssignedToAction('Action');
            setSubmitEnable(false);
          }

          doRefresh(prev => prev + 1);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Dialog
            open={assignedOpen}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                handleCloseAssigned();
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
              <Typography level="h4" color='primary'>Assign Selected Leads To Staff</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container item lg={12} md={12} sm={12} xs={12} pt={2}>
                <FormControl fullWidth size="small"
                >
                  <InputLabel>Select Type*</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    id="staffId"
                    name="staffId"
                    input={<OutlinedInput label="Action" />}
                    value={selected}
                    onChange={handleChange2}
                    renderValue={(select) =>
                      select
                        .map((id) => {
                          const selectedOption = assingedToUsers.find((option) => option.id === id);
                          return selectedOption ? selectedOption.name : '';
                        })
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          indeterminate={
                            selected.length > 0 && selected.length < assingedToUsers.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {assingedToUsers.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option.id) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.staffId && errors.staffId ? <div className='error'>{errors.staffId}</div> : null}
                </FormControl>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                  <Button autoFocus variant="contained" sx={{ minWidth: 250 }} onClick={handleCloseAssigned}>
                    CLOSE
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                  <Button variant="contained" sx={{ minWidth: 250 }} color="success" type="submit" disabled={submitEnable} onClick={handleSubmit}>
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  

  return (
    <div>
      <>
        <DefaultLayout content={ContentLayout} />



        {/* loader code start */}
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={freez}
            onClick={handleFreezStop}
          >
            <Freez />
          </Backdrop>
        </div>
        {/* loader code end */}
      </>
    </div>
  );
}

export default PendingIndex;