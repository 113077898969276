import React, { useState, useEffect } from 'react';
import config from '../../../AppConfig';
import axios from 'axios';
import AuthUser from '../../../Components/Auth/AuthUser'
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
function ThreeBreport() {
  const [html, setHtml] = useState('');
  const { http, user } = AuthUser();


  //loader function and variable start

  const [freez, setFreez] = React.useState(false);
  const handleFreezStop = () => {
   setFreez(false);
  };
  const handleFreezStart = () => {
   setFreez(true);
  };

//loader function and variable end
const username = localStorage.getItem('username');
const password = localStorage.getItem('password');

  useEffect(() => {
    handleFreezStart();
    axios({
      method: 'get',
      url: config.API_BASE_URL+'/3breport/'+username+'/'+password,
      withCredentials: false,
    })
      .then((response) => {
        handleFreezStop();
        setHtml(response.data.html);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
  <>
     <div dangerouslySetInnerHTML={{ __html: html }}></div>
      {/* loader code start */}
      <div>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
      >
          <Freez />
      </Backdrop>
      </div>
      {/* loader code end */}

  </>
   
  );
}

export default ThreeBreport;
