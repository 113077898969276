import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './Header';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../../../Components/Auth/AuthUser';
import Logout from '../../Auth/logout';
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
function Layout(props) {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const navigate = useNavigate();
    const { http, getToken, setToken, getUser, user } = AuthUser();
    // //Check Login
    const checkLogin = () => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }
    useEffect(() => {
        checkLogin()
    })

    return (
        <>
            <div>
                {/* <Box sx={{ display: 'flex' }}> */}
                    {/* <CssBaseline />
                    <Sidebar /> */}
                    <Box>
                        <Box sx={{  minHeight: "80vh" }}>
                            <Header />
                            {/* <DrawerHeader /> */}
                            {props.content}
                        </Box>
                    </Box>
                {/* </Box> */}
            </div>
        </>
    );
}
export default Layout;