import React from "react";
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { Link, useNavigate } from "react-router-dom";
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import LogoutIcon from '@mui/icons-material/Logout';
import InstagramIcon from "../assets/icons/instagram.svg";
import messangerIcon from "../assets/icons/messenger.svg";
import wstpImgIcon from "../assets/icons/wstpImg.svg";
import LanguageIcon from '@mui/icons-material/Language';
import { logout } from "../redux/authSlice";
import {
    Avatar,
    Menu,
    MenuItem,
    Badge,
    ListItemIcon,
    IconButton,
    Divider,
    CssBaseline,
    Toolbar,
    Typography,
} from '@mui/material';
import AuthUser from './Auth/AuthUser';
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
const drawerWidth = 297;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: 'white',
    boxShadow: "none",
    borderBottom: "3px #30BE2D solid",
    paddingBottom: '0.5%',
}));

function CobrandHeader() {
    const dispatch = useDispatch();
    const isAuthenticated = localStorage.getItem('isAuthenticated')
    const { user, validateRoles } = AuthUser();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenu = Boolean(anchorEl);


    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    // Logout function start
    if (isAuthenticated == null) {
        dispatch(logout());
        localStorage.clear();
        // console.log(isAuthenticated)
        navigate('/login');
    }
    const handleLogout = () => {
        dispatch(logout());
        localStorage.clear();
        // console.log(isAuthenticated)
        navigate('/login');
    }
    // Logout function end
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        // alert(user.roles_id);
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <div className={window.location.pathname === '/affiliate/initialcobrandsetup' ? 'drawerHide' : ''} >
                <MenuItem onClick={() => { navigate('/my-profile') }}>
                    <Avatar /> My Profile
                </MenuItem>
                <Divider />
            </div>
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    );


    const switchedAccount = localStorage.getItem('switchedAccount');
    const admin = JSON.parse(localStorage.getItem('admin'));

    return (
        <div>
            <AppBar position="fixed" open={true} sx={{ paddingRight: "0% !important" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="fixed" sx={{ paddingBottom: "0% !important" }}>
                        <Toolbar sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Box>
                                    <Box><img src={require("../assets/images/myfreescorenowlogo.jpg")} className="mfsnlogo" /></Box>

                                    <Grid container id="userwayBtn" sx={{ display: { md: 'none' }, justifyContent: 'space-evenly', alignSelf: "center", border: '1px solid gray', padding: '0.5% 0', width: '8em', borderRadius: '5px', cursor: 'pointer', }}>
                                        <LanguageIcon sx={{ color: "black" }} />
                                        <Typography sx={{ color: "black" }}>en/esp/fr/...</Typography>
                                    </Grid>
                                </Box>

                                <Box sx={{ display: { xs: 'none', md: 'flex' } }} className="mobileLogoNone">
                                    {user.roles_id >= 3 ?
                                        <>
                                            <Box className="socialMediaIcons" pl={7} sx={{ display: 'flex' }}>

                                                <Grid item lg={12} md={6} sm={12} xs={12} sx={{ mt: 0.5 }}><Typography sx={{ color: "green", display: 'block', alignItems: 'center' }} level="h4" spacing={1} className="iconsimp"><b>Affiliate support also available on</b> &nbsp;&nbsp;

                                                    <a href='https://m.me/myfreescorenow' target='_blank'><img src={messangerIcon} alt='messenger img' /></a>&nbsp;&nbsp;
                                                    <a href='https://api-wa.co/JrzyQs' target='_blank'><img src={wstpImgIcon} alt='whatsapp img' /></a>&nbsp;&nbsp;
                                                    <a href='https://ig.me/m/myfreescorenow' target='_blank'><img src={InstagramIcon} alt='instagram img' /></a></Typography>
                                                </Grid>

                                            </Box>
                                        </>
                                        : ''}
                                </Box>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>

                                <Grid container id="userwayBtn" tabIndex="0" direction="row" sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-evenly', alignSelf: "center", border: '1px solid gray', padding: '0.5% 0', width: '8em', borderRadius: '5px', cursor: 'pointer', }}>
                                    <LanguageIcon sx={{ color: "black" }} />
                                    <Typography sx={{ color: "black" }}>en/esp/fr/...</Typography>
                                </Grid>


                                <Box sx={{ display: 'flex' }}>
                                    <IconButton
                                        size="large"
                                        edge="end"
                                        aria-label="account of current user"
                                        aria-haspopup="true"
                                        onClick={handleProfileMenuOpen}
                                        color="color">
                                        <StyledBadge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot">
                                            <AccountCircleSharpIcon sx={{ fontSize: 40 }} />
                                        </StyledBadge>
                                        <Typography>
                                            {
                                                switchedAccount
                                                    ?
                                                    admin.first_name + " " + admin.last_name
                                                    :
                                                    user.first_name + " " + user.last_name
                                            }
                                        </Typography>
                                    </IconButton>
                                </Box>
                            </Box>



                        </Toolbar>
                        <Box sx={{ display: { xs: 'flex', md: 'none' }, alignSelf: 'center' }}>
                            {user.roles_id >= 3 ?
                                <>
                                    <Box className="socialMediaIcons" sx={{ display: 'flex' }}>

                                        <Grid item lg={12} md={6} sm={12} xs={12} sx={{ mt: 0.5 }}>
                                            <Typography sx={{ color: "green", display: 'flex', alignItems: 'center' }} level="h4" spacing={1} className="iconsimp">
                                                <b>Affiliate support also available on</b>
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <a href='https://m.me/myfreescorenow' target='_blank'><img src={messangerIcon} alt='messenger img' /></a>&nbsp;&nbsp;
                                                <a href='https://api-wa.co/JrzyQs' target='_blank'><img src={wstpImgIcon} alt='whatsapp img' /></a>&nbsp;&nbsp;
                                                <a href='https://ig.me/m/myfreescorenow' target='_blank'><img src={InstagramIcon} alt='instagram img' /></a>
                                            </Box>
                                        </Grid>

                                    </Box>
                                </>
                                : ''}
                        </Box>
                    </AppBar>
                    {renderMenu}
                </Box>
            </AppBar>
        </div >
    );
}
export default CobrandHeader;
