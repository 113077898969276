import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
//MRT Imports
import MaterialReactTable from 'material-react-table';
import swal from "sweetalert";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Loader1 from '../../../Components/Loader/Loader';
import Moment from 'moment';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/joy/Table';
import { Box, Grid, Button, Tooltip,TableCell,TableRow,Paper,TableContainer,DialogContent, DialogActions, DialogContentText, Dialog, DialogTitle } from '@mui/material';
import { Typography } from '@mui/joy';
import CheckIcon from '@mui/icons-material/CheckBox';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/DoneOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import EnrollIcon from '@mui/icons-material/NotStartedOutlined';
import ReSendIcon from '@mui/icons-material/SendToMobileOutlined';
import { useTheme,styled } from '@mui/material/styles';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

var basePlanStr = "";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
//modal style end
function CompanyPanel() {
  //style
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  //style
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user } = AuthUser();
  //Neccessary variables declearation start
  const [companylist, setCompanyList] = useState([]);
  const [loader, setLoader] = useState();
  const [filterCompany, setFilterCompany] = useState([]);
  const [search, setSearch] = useState(null);
  const [bank_details, setBankDetails] = useState([]);
  //Neccessary variables declearation end
  //Add view modal variable and function start
  const [openbank, setOpenbank] = useState(false);
  const handlebankOpen = () => setOpenbank(true);
  const handlebankClose = () => setOpenbank(false);
  const [companyname, setCompanyName] = useState();
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [type, setType] = useState("active");
  const [showBtnText, setShowBtnText] = React.useState("Show All Companies");
  const [operation, setOperation] = useState("");
  const [disableCompanyId, setDisableCompanyId] = useState();
  const tablePreferencesArray = {

  }
  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);
  ////////////////////////

  useEffect(() => {
    saveTablePreferences();
  }, [columnVisibility])

  const viewBankDetails = (compnyId) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
    navigate('/BankDetails/' + compnyId);
    // window.location.href= config.BASE_URL + 'BankDetails/' + compnyId ;
  }

  const checkbankdetailsfollowup = (compnyId) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
    navigate('/checkbankdetailsfollowup/' + compnyId) ;
  }

  const tablePreferences = async () => {
    const apiUrl = '/fetchTablePreferences/companypanel';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
    }
  }

  const saveTablePreferences = async () => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'companypanel'
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
    }
  }

  const handleClickReport = () => {
    setOpen(true);
  };
  const handleCloseReport = () => {
    setOpen(false);
  };
  //Add view modal variable and function end
  //loader function and variable start
  const [open, setOpen] = React.useState(false);
  const [openCompanyDisable, setOpenCompanyDisable] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  //loader function and variable end
  const handleCloseOfDisableCompany = () => {
    setOpenCompanyDisable(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const checkUserRole = (id) => {
    var check = 1;
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id  > 2) {
        check = 0;
    } 
    if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
        if(loginUser.company_master_id != id) {
            check = 0;
        }else{
            check = 1;
        }
    }
    if(check == 0) {
        navigate('/login');
        return false;
    }
    navigate('/companydetail/'+ id);
  };

  //Fetch Company Bank Details Start
  const fetchCompanyBankDetails = async (id, companyname) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id  > 2) {
        navigate('/login');
        return false;
    }
    setCompanyName(companyname);
    const apiUrl = '/fetch-company-bank-details/' + id;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if(res.success === false){
      setBankDetails([]); 
    }else{
      setBankDetails(res.companyBankDetails);
    }
  };
  //Fetch Company Bank Details End
  //fetchcompany list function start
  const fetchCompanyList = async() => {
    handleToggle();
    var companyType = type ? type : "active";
    const apiUrl = '/fetchCompanyList/' + companyType ;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      setCompanyList(res);
      setFilterCompany(res);
      setIsLoading(false);
      handleClose();
    }
  }

  const fetchUserBasePlan = async() => {
    const apiUrl = '/fetchUserBasePlan';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      var str = res.data.map((item) => item.toString());
      str = str.join(', ');
      basePlanStr = str;
    }
   
  }

  const changeCompanyType = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id  > 2) {
        navigate('/login');
        return false;
    }
    if (type == "active") {
      setType("all");
      setShowBtnText("Show Active Companies");
    } else {
      setType("active");
      setShowBtnText("Show All Companies");
    }

  };

  const handlOpenOfDisableCompany = (id, status) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id  > 2) {
      navigate('/login');
      return false;
    }
    if (status == "disabled") {
      setOperation("disabled");
    } else {
      setOperation("active");
    }
    setDisableCompanyId(id);
    setOpenCompanyDisable(true);
  };

  const enableDisableCompany = async () => {
    setOpenCompanyDisable(false);
    const apiUrl = '/change-company-disable-status';
    const args = {
      id: disableCompanyId,
      requestType: operation,
    }

    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if (res.success === true) {
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        })
      }
    }
    fetchCompanyList();
  }

  
  //enroll api starts
  //Resend Referal start
  const resendRefereal = async (id) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
    handleToggle();
    const apiUrl = '/regenerateAgreement';
    const args = {
      id: id
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if (res.success === true) {
        handleClose();
        swal({
          title: "Success",
          text: res.message.message,
          icon: "success",
          button: "Ok",
        }).then((ok) => {
          if (ok) {
            fetchCompanyList();
          }
        });
      } else {
        handleClose();
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        });
      }
    }
  }
  //Resend Referal end
  //Refresh button start here
  const getData = async (company_id) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
    const apiUrl = '/checkSnapshotPandadoc/' + company_id;
   
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    let shouldClosePopup = false;
    if (res.success === true)  {
      swal({
       
        text: res.message,
        icon: "success",
        button: "Ok",
        showBackdrop: true,
        closeOnClickOutside: false,
      }).then((ok) => {
        if (ok) {
          shouldClosePopup = true;
          window.location.reload();
        }
      });
    } else {
      
      swal({
        title: "Failed",
        text: res.message,
        icon: "error",
        button: "Ok",
        showBackdrop: true,
      });
    }
    document.querySelector(".swal-modal").addEventListener("click", (event) => {
      event.stopPropagation();
    });
  
    // Listen for clicks outside the popup and close it if needed
    document.body.addEventListener("click", () => {
      if (shouldClosePopup) {
        swal.close();
      }
    });
  }
 
  //Refresh button end here
  //Referal start
  const enrollRefereal = async (id) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
    handleToggle();
    const apiUrl = '/generateAgreement';
    const args = {
      id: id
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if (res.success === true) {
        handleClose();
        swal({
          title: "Success",
          text: res.message.message,
          icon: "success",
          button: "Ok",
        }).then((ok) => {
          if (ok) {
            fetchCompanyList();
          }
        });
      } else {
        handleClose();
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        });
      }
    }
  }
  //Referal end
  //Snapshot start
  const enrollSnapshot = async (id) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
    handleToggle();
    const apiUrl = '/generateAgreementForCRC';
    const args = {
      id: id
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    let shouldClosePopup = false;
    if (res) {
      if (res.success === true) {
        handleClose();
        swal({
          title: "Success",
          text: res.message.message,
          icon: "success",
          button: "Ok",
          showBackdrop: true,
        closeOnClickOutside: false,
        }).then((ok) => {
          if (ok) {
            
            fetchCompanyList();
            shouldClosePopup = true;
          }
        });
      } else {
        handleClose();
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
          showBackdrop: true,
        });
      }
    }
    document.querySelector(".swal-modal").addEventListener("click", (event) => {
      event.stopPropagation();
    });
  
    // Listen for clicks outside the popup and close it if needed
    document.body.addEventListener("click", () => {
      if (shouldClosePopup) {
        swal.close();
      }
    });
  }
  //Snapshot end
  //Resend Snapshot start
  const resendCreditSnapShot = async (id) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
    handleToggle();
    const apiUrl = '/reGenerateAgreementForCRC';
    const args = {
      id: id
    };
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    let shouldClosePopup = false;
    if (res) {
      if (res.success === true) {
        handleClose();
        swal({
          title: "Success",
          text: res.message.message,
          icon: "success",
          button: "Ok",
          showBackdrop: true,
        closeOnClickOutside: false,
        }).then((ok) => {
          if (ok) {
            fetchCompanyList();
            shouldClosePopup = true;
            window.location.reload();
          }
        });
      } else {
        handleClose();
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
          showBackdrop: true,
        });
      }
    }
    document.querySelector(".swal-modal").addEventListener("click", (event) => {
      event.stopPropagation();
    });
  // Listen for clicks outside the popup and close it if needed
  document.body.addEventListener("click", () => {
    if (shouldClosePopup) {
      swal.close();
    }
  });

  }
  //Resend Snapshot end
  //enroll api ends
  useEffect(() => {
    if(user.roles_id < 3) {
      fetchCompanyList(1);
    }else{
      navigate('/login');
    }
    
  }, [])
  useEffect(() => {
    const result = fetchCompanyList()
    setFilterCompany(result);
  }, [search,type])

  const customSortFunction = (rowA, rowB) => {
    const colorPriority = {
      blue: 0,
      red: 1,
      null: 2,
      grey:2,
      green: 3,
    };
    const statusA = rowA.original.bankDetailsFlag;
    const statusB = rowB.original.bankDetailsFlag;
    const priorityA = colorPriority[statusA];
    const priorityB = colorPriority[statusB];
    return priorityA - priorityB;
  };

  const customSortFunctionACH= (rowA, rowB) => {
    const colorPriority = {
      grey:0,
      green: 1,
    };
    const statusA = rowA.original.achDetailsFlag;
    const statusB = rowB.original.achDetailsFlag;
    const priorityA = colorPriority[statusA];
    const priorityB = colorPriority[statusB];
    return priorityA - priorityB;
  };
  const customSortFunctionReferal = (rowA, rowB) => {
    const referralPriority = {
      '1-1': 4, // Terms and Conditions Accepted
      '1-0': 3, // Referral Flag 1, Not Yet Accepted
      '0-null': 2, // Referral Flag 0, Enrolled Date null
      '0-other': 1, // Referral Flag 0, Enrolled Date not null
    };
  
    const getPriorityValue = (row) => {
      if (row.referral_flag === 1 && row.referral_terms_and_conditions === 1) {
        return '1-1';
      } else if (row.referral_flag === 1) {
        return '1-0';
      } else if (row.referral_flag === 0 && row.referral_enrolled_date === null) {
        return '0-null';
      } else {
        return '0-other';
      }
    };
  
    const keyA = getPriorityValue(rowA.original);
    const keyB = getPriorityValue(rowB.original);
  
    const priorityA = referralPriority[keyA];
    const priorityB = referralPriority[keyB];
  
    return priorityA - priorityB;
  };
  const columns = useMemo(
    () => [
      {
        id: 'Company_Details', //id used to define `group` column
        header: 'Affiliate Details',
        columns: [
          {
            accessorFn: (row) => `${row.company_name ? row.company_name.trim() : ''}`,//accessorFn used to join multiple data into a single cell
            id: 'company_name', //id is still required when using accessorFn instead of accessorKey
            header: 'Company Name',
            size: 150,
            enablePinning: true,
            // Cell: ({ renderedCellValue, row }) => (row.original.company_name ? row.original.company_name.trim() : "N/A"),
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >{row.original.company_name ? row.original.company_name.trim() : "N/A"}
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.id}`, //accessorFn used to join multiple data into a single cell
            id: 'company_id', //id is still required when using accessorFn instead of accessorKey
            header: 'Company ID',
            size: 50,
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <span>{renderedCellValue}</span>
              </Box>
            ),
          },
         
          {
            accessorFn: (row) => `${row.communication_email ? row.communication_email : ''}`,
            id: 'communication_email', 
            header: 'Communication Email',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {row.original.communication_email ? row.original.communication_email : "N/A"}
                {row.original.bounce_email_flag ? row.original.bounce_email_flag === 1 ?
                <Tooltip title="Emails are not getting delivered to this address. Please update the communication email" arrow>
                <ErrorOutlineIcon color={'error'} />
                </Tooltip> 
                : '' : ''}
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.aid}`!="null" ? `${row.aid.trim()}` : "", //accessorFn used to join multiple data into a single cell
            id: 'aid', //id is still required when using accessorFn instead of accessorKey
            header: 'AID',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <Box className="datatable"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >{row.original.aid ? row.original.aid.trim() : "N/A"}
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.PID != null ?  row.PID : "N/A"}`, //accessorFn used to join multiple data into a single cell
            id: 'PID', //id is still required when using accessorFn instead of accessorKey
            header: 'PID',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <Box className="datatable"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >{row.original.PID != null ? row.original.PID : "N/A"}
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.company_name}`, //accessorFn used to join multiple data into a single cell
            id: 'Company_Action', //id is still required when using accessorFn instead of accessorKey
            header: 'Action',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                {<Button onClick={() => checkUserRole(row.original.id)} ><Tooltip title="Affiliate Details" arrow><img src={require('../../../assets/images/details.png')} /></Tooltip></Button> }
              </Box>
            ),
          },
        ],
      },
      {
        id: 'Banking_Details',
        header: 'Banking Details',
        columns: [
          {
            accessorFn: (row) => `${row.account_active_flag} ${row.account_flag}`,
            id: 'account_flag',
            header: 'Submitted',
            size: 150,
            sortingFn: customSortFunction, 
            //custom conditional format and styling
            Cell: ({ row }) => (
              <>
                <Box>
                  {`${row.original.bankDetailsFlag}` == "green" ?
                    <Tooltip title="Bank Details Confirmed" arrow><CheckIcon color="success" /></Tooltip> :
                    `${row.original.bankDetailsFlag}` == "gray" ?
                      <Tooltip title="Bank Deatils not Submitted." arrow><CheckIcon color="disabled" /></Tooltip> :
                      `${row.original.bankDetailsFlag}` == "red" ?
                        <>
                        <Tooltip title="Incorrect Bank Details" arrow><CheckIcon htmlColor="red" /></Tooltip>
                          <Tooltip title="Details" arrow>
                            <InsertDriveFileIcon
                              color="primary"
                              onClick={() => checkbankdetailsfollowup(`${row.original.id}`)}
                            />
                          </Tooltip>
                          </>
                         :
                        `${row.original.bankDetailsFlag}` == "blue" ?
                          <Tooltip title={"Bank Details Updated on " + Moment(`${row.original.updated_at}`).format('MM-DD-YYYY')} arrow><CheckIcon htmlColor="gold" /></Tooltip> :
                          <Tooltip title="Bank Deatils not Submitted." arrow><CheckIcon color="disabled" /></Tooltip>}
                </Box>
              </>
            ),
          },
          {
            accessorKey: 'ach_info_added_to_bank', //hey a simple column for once
            accessorFn: (row) => `${row.ach_info_added_to_bank}`,
            header: 'ACH Added',
            size: 150,
            sortingFn: customSortFunctionACH, 
            Cell: ({ row }) => (
              <Box>
                {`${row.original.achDetailsFlag}` == "green" ? <Tooltip title="ACH Updated" arrow><CheckIcon color="success" /></Tooltip> : `${row.original.achDetailsFlag}` == "grey" ? <Tooltip title="ACH not Added" arrow><CheckIcon color="disabled" /></Tooltip> : ""}
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.account_active_flag} ${row.account_flag} ${row.ach_info_added_to_bank}`,
            accessorKey: 'ach_info_added_to_bank', //hey a simple column for once
            id: 'actions',
            header: 'Actions',
            Cell: ({ row }) => (
              <Box>

                {`${row.original.account_flag}` == "Added" && `${row.original.account_active_flag}` == "true" && `${row.original.ach_info_added_to_bank}` == "true" ? <><Tooltip title="View" arrow><VisibilityIcon onClick={() => window.location.href = viewBankDetails(`${row.original.id}`)} /></Tooltip></>
                  :
                  <>
                    <Tooltip title="View" arrow><VisibilityIcon color="primary" onClick={() => { fetchCompanyBankDetails(`${row.original.id}`, `${row.original.company_name}`); handlebankOpen(); }} /></Tooltip>
                    <Tooltip title="Edit" arrow><EditIcon color="primary" onClick={() => viewBankDetails(`${row.original.id}`)} /></Tooltip>
                  </>
                }
              </Box>
            ),
          },
        ],
      },
      {
        id: 'Programe_Details',
        header: 'Enabled Features',
        columns: [
          {
            accessorFn: (row) => `${row.referral_flag}`,
            accessorKey: 'referral_flag', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            header: 'Referral Program',
            size: 150,
            sortingFn:customSortFunctionReferal,
            Cell: ({ row }) => (
              <Box>
                {`${row.original.referral_flag}` == 1 && `${row.original.referral_terms_and_conditions}` == 1 ? <Tooltip title="Terms and Conditions Accepted" arrow><CheckIcon color="success" /></Tooltip> :
                  `${row.original.referral_flag}` == 1 ? <Tooltip title="Not Yet Accepted" arrow><CheckIcon color="disabled" /></Tooltip> :
                    (`${row.original.referral_flag}` == 0) && (`${row.original.referral_enrolled_date}` == "null") ? <EnrollIcon color="primary" onClick={() => enrollRefereal(`${row.original.id}`)} /> :
                      <>
                        <ReSendIcon htmlColor="orange" onClick={() => resendRefereal(`${row.original.id}`)} />
                      </>}
              </Box>
            ),
          },

          {
            accessorFn: (row) => `${row.snapshot_flag}`,
            accessorKey: 'snapshot_flag', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            header: 'CreditSnapShot',
            id: 'CreditSnapShot',
            size: 150,
            Cell: ({ row }) => (
              <Box>
                <>

                {
                   `${row.original.snapshot_status}` == "2" ? (
                      <Tooltip title="Terms and Conditions Accepted" arrow>
                        <CheckIcon color="success" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Not Yet Accepted" arrow>
                        <CheckIcon color="disabled" />
                      </Tooltip>
                    )
                }
                  {/* {`${row.original.bounce_email_flag}` ? 
                  
                   `${row.original.bounce_email_flag}` === "1" && `${row.original.is_pandadoc_expired}` == "1" ?
                   <> 
                   <Tooltip><ReSendIcon color="disabled" htmlColor="orange"/></Tooltip> 
                   </>
                   : `${row.original.bounce_email_flag}` === "1" && `${row.original.snapshot_steps}` == "1" 
                   ? 
                    <> 
                      <Tooltip><ShareOutlinedIcon color="disabled"/></Tooltip>
                      <Tooltip><RefreshIcon color="disabled" className='btn btn-success text-white'/></Tooltip>
                    </>
                   :
                   `${row.original.snapshot_steps}` == "2" ? <Tooltip title="CreditSnapShot Enabled" arrow><CheckCircleIcon color="success" /></Tooltip> 
                   : `${row.original.bounce_email_flag}` == "1" ?
                      <>
                      <Tooltip><EnrollIcon color="disabled"/></Tooltip>
                      </>
                   :
                  `${row.original.is_pandadoc_expired}` == "1" ? 
                  <> 
                    <Tooltip title="Regenerate And Resend CreditSnapShot Document" arrow><ReSendIcon htmlColor="orange" sx={{ cursor: 'pointer' }} onClick={() => resendCreditSnapShot(`${row.original.id}`)} /></Tooltip> 
                  </>
                  :
                  `${row.original.snapshot_steps}` == "1" ? 
                  <> 
                    <Tooltip title="Resend CreditSnapShot Document" arrow><ShareOutlinedIcon onClick={() => resendCreditSnapShot(`${row.original.id}`)} /></Tooltip>
                    <Tooltip title="Refresh" arrow ><RefreshIcon className='btn btn-success text-white' sx={{ cursor: 'pointer' }}  onClick={() => { getData(`${row.original.id}`) }}/></Tooltip>
                  </>
                    :
                    `${row.original.snapshot_steps}` == "2" ? <Tooltip title="CreditSnapShot Enabled" arrow><CheckCircleIcon color="success" /></Tooltip> 
                    :
                      <Tooltip title="Initiate CreditSnapShot" arrow><EnrollIcon color="primary" onClick={() => enrollSnapshot(`${row.original.id}`)} /></Tooltip>
                      :
                      <Tooltip title="Initiate CreditSnapShot" arrow><EnrollIcon color="primary" onClick={() => enrollSnapshot(`${row.original.id}`)} /></Tooltip>
                  } */}
                  
                </>
              </Box>
            ),
          },
          {
            accessorFn: (row) => `${row.is_disabled}`,
            accessorKey: 'is_disabled', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            header: 'Action',
            id: 'action',
            size: 100,
            Cell: ({ renderedCellValue, row }) => (
              <Box>
                {
                   row.original.is_disabled ?
                    <>(Deactivated)&nbsp;&nbsp;
                      <Tooltip title="Activate" arrow>
                        <PersonAddAltIcon 
                          onClick={() => handlOpenOfDisableCompany(row.original.id, 'active')}
                        />
                      </Tooltip>
                    </>
    
                    :
                    <>(Active)&nbsp;&nbsp;
                      <Tooltip title="Deactivate" arrow>
                        <PersonRemoveOutlinedIcon
                          onClick={() => handlOpenOfDisableCompany(row.original.id, 'disabled')}
                        />
                      </Tooltip>
                    </>
                }
              </Box>
            ),
          },
        ],
      },
    ],
    [],
  );
  //csv option start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Company Details',
    headers: ['Company ID', 'Company Name', 'Submitted', 'ACH Added', 'Referral Program', 'CreditSnapShot',
    'Company Website','Business Start Year','Agreement ID','Aid List','PID List','Referral Code','Referred By','Referral Terms Accepted','Company Status'
  ],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (filterCompany) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id  > 2) {
        navigate('/login');
        return false;
    }
    const exportedData = filterCompany.map((row) => {
      let accountFlag;
      if (row.original.bankDetailsFlag === "green") {
        accountFlag = "Bank Details Confirmed";
      } else if (row.original.bankDetailsFlag === "gray") {
        accountFlag = "Bank Deatils not Submitted.";
      } else if (row.original.bankDetailsFlag === "red") {
        accountFlag = "Incorrect Bank Details";
      } else if (row.original.bankDetailsFlag === "blue") {
        accountFlag = `Bank Details Updated on ${Moment(row.original.updated_at).format('MM-DD-YYYY')}`;
      } else {
        accountFlag = "Bank Details not Submitted";
      }

      let achAddedText;
      if (row.original.achDetailsFlag === "green") {
        achAddedText = "ACH Updated";
      } else if (row.original.achDetailsFlag === "grey") {
        achAddedText = "ACH not Added";
      }

      const referralFlag = row.original.referral_flag;
      const termsAndConditions = row.original.referral_terms_and_conditions;
      const enrolledDate = row.original.referral_enrolled_date;
      let cellContent;
      if (referralFlag == 1 && termsAndConditions == 1) {
        cellContent = "Terms and Conditions Accepted";
      } else if (referralFlag == 1) {
        cellContent = "Not Yet Accepted";
      }

      let snapshotSteps;
      if (row.original.snapshot_steps === "1") {
        snapshotSteps = "Resend CreditSnapShot Document";
      }
      else if (row.original.snapshot_steps === "2") {
        snapshotSteps = "CreditSnapShot Enabled";
      }
      else {
        snapshotSteps = "Initiate CreditSnapShot";
      }

      return {
        company_id: `${row.original.id}` || 'N/A',
        company_name: `${row.original.company_name}` || 'N/A',
        account_flag: accountFlag || 'N/A',
        ach_info_added_to_bank: achAddedText || 'N/A',
        referral_flag: cellContent || 'N/A',
        snapshot_flag: snapshotSteps || 'N/A',
        company_website : `${row.original.company_website ? row.original.company_website : "Not Available"}`,
        business_started_year:`${row.original.business_started_year ? row.original.business_started_year : "Not Available"}`,
        pandadoc_id:`${row.original.referral_pandadoc_id ? row.original.referral_pandadoc_id : 'N/A'}`,
        aid_list: `${row.original.aid ? row.original.aid : 'N/A'}`,
        pid_list:`${row.original.PID != null ?  row.original.PID : "N/A"}`,
        referral_code :`${row.original.referral_code ? row.original.referral_code : "N/A"}`,
        referral_by:`${row.original.referred_by ? row.original.referred_by : "N/A"}`,
        referral_terms_and_conditions:`${row.original.referral_terms_and_conditions == 1 ? "Yes" : "No"}`,
        status:`${row.original.is_disabled == 1 ? "Deactivated" : "Activate"}`,
      };
    });

    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted

        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }

        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };
  //csv end here

  function ContentLayout() {
    if (fetchCompanyList) {
      return (
        <>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ mb: 0.5 }}>
            <Typography level='h4' color="primary">Company Details</Typography>
            <Grid>
            &nbsp;&nbsp;
            <Button variant="contained" 
              color={showBtnText == "Show All Companies" ? "primary" : "success"}
              onClick={() => { changeCompanyType() }}
            >
              {showBtnText}
            </Button>
          </Grid>
          </Grid>
          <MaterialReactTable
            columns={columns}
            data={filterCompany}
            filterFns={{
              customSearchFilterFn: (row, id, filterValue) =>
            	row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }} 
            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                backgroundColor: "#F6FAFF",
              },
            }}
            
            muiTableBodyCellProps={({ row, column }) => {
              const columnIndex = column.id;
              return {
                sx: {
                  opacity:  (columnIndex != "action") && row && row.original.is_disabled == 1  ? 0.6  : 1,
                  pointerEvents:  (columnIndex != "action") && row && row.original.is_disabled == 1  ? 'none' : 'auto',
                },
              };
            }}

            muiExpandButtonProps={({ row }) => ({                
                                      sx: {                 
                                        opacity: 1,                 
                                        pointerEvents: 'auto',                
                                      },               
                                    })}
            enableColumnFilterModes
            enablePinning
            enableDensityToggle={false}
            initialState={{ showColumnFilters: false, density: 'compact', columnVisibility: { company_id: false },
            columnPinning: { left: ['mrt-row-expand','company_name'] },
            sorting: [
              {
                  id: 'company_id',
                  desc: true
              },
          ] }}
            positionToolbarAlertBanner="bottom"
            enableColumnFilters={config.DatatableColumnFilter}
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection, search,isLoading:isLoading }}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}

            //top csv option
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <Button
                  sx={{ bgColor: "#2A68B3" }}
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                      handleExportData(table.getPrePaginationRowModel().rows)
                    }
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  className='export'
                >
                  CSV
                </Button>
              </Box>
            )
            }
            //csv end here

            renderDetailPanel={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                
                <>
                  <TableContainer component={Paper}>
                    <Table className="back">
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>Company Name:</TableCell>
                        <TableCell>{row.original.company_name}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Company Website:</TableCell>
                        <TableCell className='datatable'>{row.original.company_website ? row.original.company_website : "Not Available"}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Business Start Year:</TableCell>
                        <TableCell>{row.original.business_started_year ? row.original.business_started_year : "Not Available"}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>Agreement Id:</TableCell>
                        <TableCell>{row.original.referral_pandadoc_id ? row.original.referral_pandadoc_id : 'N/A'}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Aid List:</TableCell>
                        <TableCell style={{ width: 100 }}>
                        {row.original.aid?row.original.aid:"N/A" }
                         
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>PID List:</TableCell>
                        <TableCell>
                        {row.original.PID != null ?  row.original.PID: "N/A"}
                         
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>Referral Code:</TableCell>
                        <TableCell>{row.original.referral_code ? row.original.referral_code : "N/A"}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Referred By:</TableCell>
                        <TableCell>{row.original.referred_by ? row.original.referred_by : "N/A"}</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}> Referral Terms Accepted:</TableCell>
                        <TableCell>{row.original.referral_terms_and_conditions == 1 ? "Yes" : "No"}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}> CreditSnapShot staus:</TableCell>
                        <TableCell>{row.original.snapshot_flag == 1 ? "Active" : "Inactive"}</TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </>
              </Box>
            )}
          />
        </>
      );

    } else {
      return (
        <>Loading...</>
      )
    }
  }
  return (
    <div>
      <>
        <DefaultLayout content={<ContentLayout />} />
        {/* loader code start */}
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <Loader1 />
          </Backdrop>
        </div>
        {/* loader code end */}
        <BootstrapDialog
          open={openCompanyDisable}
          onClose={handleCloseOfDisableCompany}
          aria-labelledby="responsive-dialog-title"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseOfDisableCompany}>
            <Typography level="h3">{operation == "disabled" ? "Deactivate" : "Activate"} Company</Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "black" }}>
              Are you sure you want to {operation == "disabled" ? "deactivate" : "activate"} this company?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" color="primary" sx={{ minWidth: 150 }} onClick={() => { handleCloseOfDisableCompany() }}>
              No
            </Button>
            <Button variant="contained" color='success' sx={{ minWidth: 150 }} 
              onClick={() => { enableDisableCompany(); }}
            >
              Yes
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </>
      
      <Modal
        open={openbank}
        onClose={handlebankClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography level="h4" color="primary">{companyname} 's Bank Details</Typography>
            <CloseIcon onClick={handlebankClose} />
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Table borderAxis="none" variant="plain" stripe="odd" >
              <tr>
                <th>Bank Name</th>
                <th>Name on Account</th>
                <th>Bank Routing (ABA/SCH) Number</th>
              </tr>
              <tr>
                <td>{bank_details.bank_name}</td>
                <td>{bank_details.account_category == "personal" ? bank_details.name_on_account.replace(/:/g, ' ') : bank_details.name_on_account}</td>
                <td>{bank_details.routing_number}</td>
              </tr>
              <tr>
                <th>Account No.</th>
                <th>Account Type</th>
                <th>Is Account</th>
              </tr>
              <tr>
                <td>{bank_details.account_number}</td>
                <td>{bank_details.account_type}</td>
                <td>{bank_details.account_category}</td>
              </tr>
              <tr>
                <th>Email Address to notify of Payment Transfers</th>
                <th>ACH Info added to bank?</th>
                <th>Last ACH Update</th>
              </tr>
              <tr>
                <td>{bank_details.email_to_notify}</td>
                <td>{bank_details.ach_info_added_to_bank == 0 ? "Not Added" : "Added"}</td>
                <td>{bank_details.last_ach_updated == null ? "NA" : Moment(bank_details.last_ach_updated).format('MM-DD-YYYY')}</td>
              </tr>
            </Table>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default CompanyPanel; 