
import React from "react";
import { Grid, TextField, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardOverflow } from '@mui/joy';
import * as Yup from 'yup';
import { Formik } from 'formik';

function AffiAgreement4({ formData, handleFormChange, errors, handleBlur, validationSchema1, status }) {
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold',textAlign:'center' }}>Affiliate Partner Referrer Agreement</Typography>
                                <Typography variant="h6"  sx={{ fontWeight: 'bold' }}>Terms and Conditions</Typography>
                                <Typography variant="h6">
                                    This Referrer Agreement (the "Agreement") is made by and between MyFreeScoreNow, Inc. (“MFSN”), having its principal place of business at 506 2nd Ave PH, Seattle, WA 98104 and
                                </Typography>
                            </Grid>

                            <Formik
                                initialValues={formData}
                                enableReinitialize={true} // This ensures that Formik updates the form formData when formData changes
                            >

                                {({ handleChange }) => (

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                            <TextField
                                                inputProps={{ readOnly: true }}
                                                label="Business Name*"
                                                type="text"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                // inputRef={inputRef}
                                                name='BusinessName1'
                                                value={formData.BusinessName1}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handleFormChange('BusinessName1', e.target.value);
                                                }}
                                                onBlur={(e) => handleBlur('BusinessName1', e.target.value)}
                                                error={Boolean(errors.BusinessName1)}
                                                helperText={errors.BusinessName1}
                                                InputLabelProps={{ className: 'label-to-hide' }}
                                            />

                                        </Grid>

                                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                            <Grid item lg={3} md={3} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                <Typography variant="h6">(“Referrer”) located at:</Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                                <TextField
                                                    inputProps={{ readOnly: status == 1 ? true : false }}
                                                    label="Street Address*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    name='StreetAddress1'
                                                    value={formData.StreetAddress1}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleFormChange('StreetAddress1', e.target.value);
                                                    }}
                                                    onBlur={(e) => handleBlur('StreetAddress1', e.target.value)}
                                                    error={Boolean(errors.StreetAddress1)}
                                                    helperText={errors.StreetAddress1}
                                                    InputLabelProps={{ className: 'label-to-hide' }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <TextField
                                                    inputProps={{ readOnly: status == 1 ? true : false }}
                                                    label="Zip Code*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    name='ZipCode1'
                                                    value={formData.ZipCode1}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleFormChange('ZipCode1', e.target.value);
                                                    }}
                                                    onBlur={(e) => handleBlur('ZipCode1', e.target.value)}
                                                    error={Boolean(errors.ZipCode1)}
                                                    helperText={errors.ZipCode1}
                                                    InputLabelProps={{ className: 'label-to-hide' }}
                                                />
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <TextField
                                                    inputProps={{ readOnly: status == 1 ? true : false }}
                                                    label="City*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    name='City1'
                                                    value={formData.City1}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleFormChange('City1', e.target.value);
                                                    }}
                                                    onBlur={(e) => handleBlur('City1', e.target.value)}
                                                    error={Boolean(errors.City1)}
                                                    helperText={errors.City1}
                                                    InputLabelProps={{ className: 'label-to-hide' }}
                                                />
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <TextField
                                                    inputProps={{ readOnly: status == 1 ? true : false }}
                                                    label="State*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    name='State1'
                                                    value={formData.State1}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleFormChange('State1', e.target.value);
                                                    }}
                                                    onBlur={(e) => handleBlur('State1', e.target.value)}
                                                    error={Boolean(errors.State1)}
                                                    helperText={errors.State1}
                                                    InputLabelProps={{ className: 'label-to-hide' }}
                                                />
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                )}

                            </Formik>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h6">(collectively, the “Parties”) as of (the “Effective Date”).</Typography>
                                <Typography variant="h6">WHEREAS, MFSN is in the business of distribution of 3-bureau and 1-bureau credit reports, credit scores, credit monitoring and various other credit products;</Typography>
                                <Typography variant="h6">WHEREAS, Referrer wishes to obtain the right to market the Services (as defined herein below) which are provided by MFSN, and</Typography>
                                <Typography variant="h6">WHEREAS, MFSN wishes to grant certain rights to Referrer pursuant to the terms of this Agreement.
                                    NOW THEREFORE, for good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the Parties hereby agree as follows:</Typography><br />
                                <Typography variant="h6"> 1. Definitions:<br/>
                                    “Account” shall refer to the balance at any time of Acquisitions, payments of Sales Commissions, Voids and resulting credits for voided transactions that is maintained by MFSN pursuant to this Agreement.<br /><br />
                                    “Affiliate” is synonymous with, and carries the same definition and meaning as, the term “Referrer” that is defined herein.<br /><br />
                                    “Acquisition” shall refer to instances in which as a result of Referrer’s Performance Marketing, a Visitor completes the registration process on MFSN’s website for one or more of MFSN’s products and/or services, and the Visitors order results in a Conversion (as defined herein below).<br /><br />
                                    “Conversion” shall refer to the event when a qualified prospective customer is converted into an Active customer. Qualification as set forth herein shall include a customer: 1) completing MFSN’s registration form, 2) entering a valid credit card number which is subsequently authorized by MFSN, 3) providing a valid email address, credit card and social security number as determined by the MFSN’s processing servers and that is not later subject to a chargeback, or refund demand from the customer or customer’s card issuer, or otherwise found to be Fraudulent by MFSN and 4) the customer does not rescind their order or account with MFSN for any reason and (5) there is no declination of the credit or debit card used by the customer that occurs upon the first billing after the expiration of the free trial period, or upon the second billing (i.e., the second month’s membership fee) after the expiration of the free trial period, or (6) after billing of product purchase transactions and (7) the customer does not initiate a dispute of any charge, make a refund request or initiate a charge back. MFSN will not pay Sales Commission for any Conversions that are deemed to be Fraudulent, and will Void Acquisitions later determined to be generated from Fraudulent orders (Fraud).<br />
                                    Any violation of the qualifications set forth above shall disqualify the Referrer from receiving any Sales Commission from the Conversion generated from the Referrer. In addition, if in MFSN’s sole judgment, there are excessive violations of the qualifying criteria set forth herein by the Referrer, MFSN reserves the right to </Typography>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement4;