import { Grid } from "@mui/material";
import React, { useState,useEffect } from "react";
import { debounce } from "lodash"; 

function CobrandColorPicker({ title, blockId, blockName, value, colorType, handleValue, customColorArray, openColorPicker, toggleColorPicker }) {
    const [color, setColor] = useState(value);

    const debouncedOnChange = debounce((selectedColor) => {
        setColor(selectedColor);
        handleValue([{ ...customColorArray, [blockName]: selectedColor }]);
        const block = document.getElementById(blockId);
            block.style.borderColor = selectedColor;
    }, 100); // 100ms debounce delay, adjust as needed

    const onColorChange = (event) => {
        const selectedColor = event.target.value;
        debouncedOnChange(selectedColor); // Call the debounced function
    };

    useEffect(() => {
        setColor(value);
    }, [value]);

    return (
        <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} sx={{ alignItems: 'center' }}>
            <Grid item lg={7} md={7} sm={7} xs={7}>
                <label htmlFor={blockId} style={{ fontWeight: "bold", marginRight: "10px" }}>
                    {title}
                </label></Grid>
            <Grid item lg={5} md={5} sm={5} xs={5}>
                <input
                    type="color"
                    id={blockId}
                    name={blockName}
                    value={color}
                    onChange={onColorChange}
                    style={{ height: "2vw", width: "2vw" }}
                    className="color-picker-size"
                /></Grid>
        </Grid>
    );
}

export default CobrandColorPicker;