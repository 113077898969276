
import React,{useMemo,useState,useEffect} from 'react';
import { useTheme } from '@mui/material/styles';
import {Box,Table ,TableBody ,TableCell ,Dialog,DialogActions ,DialogTitle,TextField,DialogContent,FormControl,FormHelperText,
  TableContainer,TableHead,TableRow,Paper,Button,Grid,Tooltip  } from '@mui/material';
  import TextareaAutosize from '@mui/base/TextareaAutosize';
import MaterialReactTable from 'material-react-table';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
//Date Picker Imports
import AuthUser from "../Auth/AuthUser";
//Icons Imports
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import config from '../../AppConfig';
import swal from "sweetalert";
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import {currencyFormat} from '../../Components/CommonFormula';

let RequestFormInitialValues = {
  new_commission: '',
  commission_note : '',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  ];


const CommissionView = ({ itemList, onValueChange, enableSaveButton,setNewCommissionTotal }) => {
  const [requestItemList, setIRequestItemList] = useState(JSON.stringify(itemList));
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (rownumber) => {
    var itemDetails = JSON.parse(requestItemList);
    if(itemDetails[rownumber].commission_note != "undefined") {
      setCommissionNote(itemDetails[rownumber].commission_note);
      setNewCommission(itemDetails[rownumber].new_commission);
      RequestFormInitialValues.new_commission = itemDetails[rownumber].new_commission;
      RequestFormInitialValues.commission_note = itemDetails[rownumber].commission_note;
    }else {
      setCommissionNote("");
      setNewCommission();
    }
    setOpen(true);
    setRowNumber(rownumber);
};

  const handleClose = () => {
      setOpen(false);
  };

  useEffect(() => {
    setIRequestItemList(JSON.stringify(itemList));
}, [itemList])

  const theme = useTheme();
  const navigate = useNavigate();
  const [personName, setPersonName] = React.useState([]);
  const { http, user } = AuthUser();
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const [statusMessage, setStatusMesage] = useState('');
  const [errorMessage,setErrorMessage] = useState('');
  const [disable, setDisable] = useState(false);
  const [leadDetails, setleadDetails] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [loader, setLoader] = useState("");
  const [perPage, setPerPage] = useState(100000);
  const [isLoading, setIsLoading] = useState(false);
  const q = search == '' ? 'null' : search;
  const [new_commission, setNewCommission] = useState();
  const [commission_note, setCommissionNote] = useState();
  const [rownumber, setRowNumber] = useState();

  const detailsSchema = Yup.object().shape({
    new_commission: Yup.string()
            .required('Please  Enter Commission'),
    commission_note: Yup.string()
                .required('Please Enter Note'),
  });


  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (row) => `${row.aid}`,
        id:'aid',
        header: 'AID',
        size: 50,
      },
      {
        accessorFn: (row) => `${row.customerid}`,
        id:'customer_id',
        header: 'Customer ID',
        size: 50,
      },
      {
        accessorFn: (row) => `${row.firstname} `,
        id:'first_name',
        header: 'First Name',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.lastname}`,
        id:'last_name',
        header: 'Last Name',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.effectivedate ? Moment(row.effectivedate).format('MM-DD-YYYY') : ''}`,
        id:'effective_date',
        header: 'Effective Date',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.pid}`,
        id:'pid',
        header: 'PID',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.amount}`,
        id:'amount',
        header: 'Amount($)',
        size: 50,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.amount))),
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.description}`,
        id:'description',
        header: 'Description',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.commission}`,
        id:'commission',
        header: 'Commission($)',
        size: 50,
        Cell:({renderedCellValue,row}) =>row.original.commission != null ?(currencyFormat(parseFloat(row.original.commission))) : '$0.00',
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.new_commission?row.new_commission:row.approvedcommission}`,
        id:'approved_commission',
        header: 'Approved Commission($)',
        size: 50,
        Cell:({renderedCellValue,row}) => row.original.new_commission? currencyFormat(parseFloat(row.original.new_commission)) : row.original.approvedcommission != null ? currencyFormat(parseFloat(row.original.approvedcommission)) : '$0.00',
        //custom conditional format and styling
      },
      {
        accessorKey: 'action',
        id: 'actions',
        header: 'Actions',
        Cell: ({ row }) => (
          <Box>
            <>
            <Tooltip title="Edit" arrow><ModeEditOutlineOutlinedIcon onClick={()=>{handleClickOpen(row.index)}}/></Tooltip></>
          </Box>
        ),

      },
    ]
  )
//csv option start here
const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  fileName: 'Commission View Details',
  headers: ['AID','Customer ID','First Name','Last Name','Effective Date','PID','Amount($)','Description','Commission($)','Approved Commission($)'],
};

const csvExporter = new ExportToCsv(csvOptions);
const handleExportData = (itemList) => {
  const loginUser = JSON.parse(localStorage.getItem('user'));
  if(loginUser.roles_id >= 3 ) {
    swal({
        title: "Failed",
        text: "This action is unauthorized.",
        icon: "error",
        button: "Ok",
    })
    navigate('/login');
    return false;
  }
  const exportedData = itemList.map((row) => {
    return {
      aid: `${row.original.aid}` || 'N/A',
      customer_id: `${row.original.customerid}` || 'N/A',
      first_name:`${row.original.firstname} ` || 'N/A',
      last_name : `${row.original.lastname}` || 'N/A',
      effective_date: `${row.original.effectivedate ? Moment(row.original.effectivedate).format('MM-DD-YYYY') : ''}` ,
      pid : `${row.original.pid}` || 'N/A',
      amount : `${row.original.amount}` || 'N/A',
      description:`${row.original.description}` || 'N/A',
      commission : `${row.original.commission}` || 'N/A',
      approved_commission : `${row.original.new_commission?row.original.new_commission:row.original.approvedcommission}` || 'N/A'
    };
  });

  const csvContent = convertToCSV(exportedData);
  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = csvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

const convertToCSV = (data) => {
  const headers = csvOptions.headers.join(',') + '\n';

  const rows = data.map((row) => {
    return Object.values(row).map((value) => {
      // Handle any necessary formatting or escaping of values
      // For simplicity, we assume all values are already properly formatted
      return value;
    }).join(',');
  }).join('\n');

  return headers + rows;
};
//csv end here

        return (
            <>

            <MaterialReactTable
                columns={columns}
                data={itemList}
                enableColumnFilterModes
                filterFns={{
                  customSearchFilterFn: (row, id, filterValue) => {
                    const searchValues = filterValue.toLowerCase().split(' ');
                    const firstName = row.original.firstname ? row.original.firstname.toLowerCase() : '';
                    const lastName = row.original.lastname ? row.original.lastname.toLowerCase() : '';
                    const nameMatches = searchValues.every(value =>
                      firstName.includes(value) || lastName.includes(value)
                    );
                    return nameMatches || row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, ''));
                  },
                }}
                globalFilterFn="customSearchFilterFn"//custom search function
                enableColumnFilters={config.DatatableColumnFilter}
                enableRowSelection
                initialState={{ showColumnFilters: false, density: 'compact' }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      backgroundColor:"#F6FAFF",
                    },
                  }}
                renderDetailPanel={({ row }) => (
                  <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                              <TableHead>
                                  <TableRow>
                                    <TableCell sx={{fontWeight:"bold"}}>AID</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>First Name</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Last Name</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Effective Date</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>PID</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Amount</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Description</TableCell>
                                    <TableCell align="center" sx={{fontWeight:"bold"}}>Commission</TableCell>
                                     <TableCell align="center" sx={{fontWeight:"bold"}}>Approved Commission</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                      <TableRow
                                        key={row.original.aid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.original.aid}
                                        </TableCell>
                                        <TableCell align="center">{row.original.firstname}</TableCell>
                                        <TableCell align="center">{row.original.lastname}</TableCell>
                                        <TableCell align="center">{row.original.effectivedate}</TableCell>
                                        <TableCell align="center">{row.original.pid}</TableCell>
                                        <TableCell align="center">{row.original.amount}</TableCell>
                                        <TableCell align="center">{row.original.description}</TableCell>
                                        <TableCell align="center">{row.original.commission}</TableCell>
                                        <TableCell align="center">{row.original.approvedcommission}</TableCell>
                                      </TableRow>
                              </TableBody>
                      </Table>
                    </TableContainer>
              </Box>
              )}
                //top csv option
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                  sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                  >
                  <Button
                      color="primary"//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                      disabled={table.getPrePaginationRowModel().rows.length === 0}
                      onClick={() =>
                        handleExportData(table.getPrePaginationRowModel().rows)
                      }
                      // onClick={handleExportData}
                      startIcon={<FileDownloadIcon />}
                  variant='contained'
                      >
                      CSV
                      </Button>
                  </Box>
              )
              }
                />
                {/* edit popup start here */}
                <Formik
                    initialValues={RequestFormInitialValues}
                    validationSchema={detailsSchema}
                    onSubmit={(values, { resetForm }) => {
                          var itemDetails = JSON.parse(requestItemList);
                          itemDetails[rownumber].new_commission = parseFloat(values.new_commission).toFixed(2);
                          itemDetails[rownumber].commission_note = values.commission_note;
                          var sum = 0;
                          itemDetails.forEach(subData => sum += parseFloat(subData.new_commission ? subData.new_commission: (subData.approvedcommission != null ? subData.approvedcommission : 0)));
                          setIRequestItemList(JSON.stringify(itemDetails));
                          setOpen(false);
                          onValueChange(itemDetails);
                          enableSaveButton();
                          setNewCommissionTotal(sum);
                          resetForm();
                    }}
                >
              {({values,errors,touched,isSubmitting,handleChange,handleBlur,handleSubmit,setFieldValue}) =>(
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" className="back">
                      Edit Commission
                    </DialogTitle>
                    <DialogContent>
                      <Grid container item xs mb={3} mt={1}>
                        <FormControl fullWidth>
                          <TextField label="New Commission:*" variant="outlined" fullWidth size="small"
                            id='new_commission'
                            name='new_commission'
                            value={values.new_commission}
                            type="number"
                            onChange={handleChange('new_commission')}
                            onBlur={handleBlur('new_commission')}
                            />
                          <FormHelperText>{touched.new_commission && errors.new_commission ? <div className='error'>{errors.new_commission}</div> : null}</FormHelperText>
                        </FormControl>
                      </Grid>
                    
                      <input id="rownumber"
                        name='rownumber'
                        type="hidden"
                        value={rownumber}/>

                      <Grid container item xs>
                        <FormControl fullWidth>
                          <TextareaAutosize
                            name='commission_note'
                            id='commission_note'
                            value={values.commission_note}
                            onChange={handleChange('commission_note')}
                            onBlur={handleBlur('commission_note')}
                            margin="dense"
                            aria-label="minimum height"
                            minRows={5}
                            placeholder="Enter Note"
                            style={{width:"100%"}}
                          />
                          <FormHelperText>{touched.commission_note && errors.commission_note ? <div className='error'>{errors.commission_note}</div> : null}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"center",width:"100%"}}>
                        <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                            <Button variant="contained" sx={{minWidth:250}} 
                            onClick={handleClose}
                            >
                                CLOSE
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                            <Button variant="contained" sx={{minWidth:250}} color="success" type="submit" onClick={handleSubmit}>
                                SAVE
                            </Button>
                        </Grid>
                      </Grid>
                    </DialogActions>
                </Dialog>
                 )}
                </Formik>
            </>
            );

    }


    export default CommissionView;