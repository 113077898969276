import React, { useState, useEffect } from "react";
import RegisterFooter from '../../Auth/RegisterFooter';
import LoginHeader from "../../../Components/LoginHeader";
import AuthUser from '../../../Components/Auth/AuthUser';
import { Box, Stepper, Step, StepLabel, Grid, InputAdornment, FormControl, InputLabel, OutlinedInput, IconButton, FormHelperText } from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import { useParams, useNavigate } from "react-router-dom";
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import { Typography, Card, CardContent, Button } from '@mui/joy';
import { Formik, Form } from 'formik';
import swal from "sweetalert";
import * as Yup from 'yup';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import PrivacyPolicy from '../../FooterPages/PrivacyPolicy';
import TermsAndConditions from '../../FooterPages/TermsAndConditions';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
const steps = [
    'Step 1',
    'Step 2',
];

function EnrollStep1(props) {
    const navigate = useNavigate();
    const { http } = AuthUser();
    const { id } = useParams();
    const [aid, setAID] = useState();
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [emailButton, setEmailButton] = useState(false);
    // Function to decode base64 string

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid First Name'
            )
            .required('First Name is required'),
        lastName: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid Last Name'
            )
            .required('Last Name is required'),
        email: Yup.string()
           
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Email is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .matches(
                /^(?=[-a-zA-Z0-9@_*.]*$)/,
                'Please enter a valid Password. *.-_@ are allowed special characters'
            )
            .required('Password is required'),
    });
    //Step1 Function start
    const [Dtoken, setDtoken] = useState();
    const [rtoken, setRtoken] = useState();
    const [paToken, setpaToken] = useState();
    const [userid, setUserId] = useState();
    const [errormsg, setErrorMsg] = useState();
    const [phonenumber, setPhoneNumber] = useState();
    const [companyName, setCompanyName] = useState('');
    const [enrolledStatus, setEnrolledStatus] = useState(0);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    //Get Phone number by AID API Starts
    const GetPhoneNumber = (receivedaid) => {
        handleFreezStart();
        http.post("/getProfileOnAID", {
            aid: receivedaid
        })
            .then((res) => {
                handleFreezStop();
                setPhoneNumber( res.data.data.phone ?  res.data.data.phone : '');
                setCompanyName( res.data.data.company ?  res.data.data.company : '');
                localStorage.setItem('SnapShot_phone', res.data.data.phone);
            })
    }
    //Get Phone number by AID API Ends
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    const toggleDrawer3 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen3(open);
    };
    const toggleDrawer4 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen4(open);
    };
    const SnapShotErollStep1 = (values) => {
        handleFreezStart();
        http.post("/creditSnapshotEnrollStep1", {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            source: "default",
            aid: aid
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    setDtoken(res.data.data.dtoken)
                    setRtoken(res.data.data.rtoken)
                    setUserId(res.data.data.userId)
                    setpaToken(res.data.data.paToken)
                    localStorage.setItem('Dtoken', res.data.data.dtoken);
                    localStorage.setItem('rtoken', res.data.data.rtoken);
                    localStorage.setItem('paToken', res.data.data.paToken);
                    localStorage.setItem('UserId', res.data.data.userId);
                    localStorage.setItem('SnapShot_firstName', values.firstName);
                    localStorage.setItem('SnapShot_lastName', values.lastName);
                    localStorage.setItem('SnapShot_email', values.email);
                    localStorage.setItem('SnapShot_password', values.password);
                    localStorage.setItem('SnapShot_aid', aid);
                    navigate('/en/creditsnapshot/user/register/'+aid+'/enrollstep2');
                } else {
                    if (res.data.success === false) {
                        if (res.data.data.enrolledStatus == true) {
                            setEnrolledStatus(1);
                        }
                    }
                    handleFreezStop();
                    setErrorMsg(res.data.data.errorByStichCredit)
                }
            })
            .catch((error) => {
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    //create function end
    //get Snapshot AID function and variable starts
    const loginLink = () => {
        navigate('/snapshotlogin');
    }
    const getSnapAID = () => {
        handleFreezStart();
        http.post("/getsnapshotaid", {
            id: id
        })
            .then((res) => {
                handleFreezStop();
                setAID(res.data.data)
                GetPhoneNumber(res.data.data);
            })
    }
    //get Snapshot AID function and variable ends
    useEffect(() => {
        getSnapAID()
    }, [])

    const checkUsaTLD = (email) => {
        if (email.includes('@')) {
            setEmailButton(true);
            http.post("/checkUsaTLD", {
                email: email,
            })
            .then((res) => {
                if (res.data.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            })
            setEmailButton(false);
        }
    }

    return (
        <>
            <Box>
                <LoginHeader aid={aid} logoName={'creditSnapShot'} />
            </Box>
            <Box mt={3} mb={3} sx={{ paddingLeft: "5%", paddingRight: "5%" }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stepper activeStep={1} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                    {/* <Grid item lg={8} md={6} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}> */}
                            {/* <PhoneInTalkOutlinedIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography>
                                Call a Credit Specialist Now at {formatPhoneNumber(phonenumber)} We can help!
                            </Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          

                    {/* </Grid> */}
                </Grid>

                
                <Card variant="outlined">
                    <CardContent >
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Grid container item lg={12} md={12} sm={12} xs={12} >
                                    <p style={{ textAlign: "center" }}>
                                        <span className="accountFont1" style={{ fontWeight: 600, fontSize: "1.5rem" }}>Smart.</span> <span className="font4" style={{ fontWeight: 600, fontSize: "1.1rem" }}>It’s always a good idea to stay on top of your credit.
                                            We provide score evaluations and a game plan every day.</span>
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <img src={require("../../../assets/images/enroll1.png")} style={{ marginLeft: "auto", marginRight: "auto", display: "block", height: "70%", width: "auto" }} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: "block", justifyContent: "center" }} mt={2}>
                                        <Typography sx={{ color: "#2A68B3" }}>
                                            Get your FREE online credit evaluation
                                            now in 3 easy steps.
                                        </Typography>
                                        <ul >
                                            <li style={{ marginTop: "2%" }}>Free credit score</li>
                                            <li style={{ marginTop: "2%" }}>Free credit report summary</li>
                                            <li style={{ marginTop: "2%" }}>No Credit Card Required</li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <p className="font4" style={{ fontWeight: 600, fontSize: "1.1rem", textAlign: "center" }}>
                                        First we need some details to help you retrieve your credit information.
                                    </p>
                                </Grid>
                                <Formik
                                    initialValues={{ firstName: '', lastName: '', email: '', password: '' }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        // Handle form submission logic here
                                        if(emailButton === true || checkUsaTldStatus === true) {
                                            return false;
                                        }
                                        setSubmitting(false);
                                        SnapShotErollStep1(values);
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                        <Form>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <p style={{ textAlign: "center" }}>
                                                    Your Information is Transmitted Securely And <span style={{ fontWeight: 700 }}> Your Own Credit Won’t Hurt Your Score.</span>
                                                </p>
                                                <p style={{ fontWeight: 700, display: "flex", justifyContent: "center" }}>
                                                    No credit card required. Yes we said it’s FREE.
                                                </p>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        
                                                        <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>First Name*</InputLabel>
                                                            <OutlinedInput
                                                                id="firstName"
                                                                name="firstName"
                                                                label="First Name*"
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                margin="dense"
                                                                autoComplete="firstName"
                                                                error={touched.firstName && !!errors.firstName}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {touched.firstName && !errors.firstName ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.firstName && errors.firstName ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                value={values.firstName}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <FormHelperText style={{ color: 'red' }}>
                                                                {touched.firstName && errors.firstName}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        
                                                        <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>Last Name*</InputLabel>
                                                            <OutlinedInput
                                                                id="lastName"
                                                                name="lastName"
                                                                label="Last Name*"
                                                                autoComplete="lastName"
                                                                error={touched.lastName && !!errors.lastName}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {touched.lastName && !errors.lastName ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.lastName && errors.lastName ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                value={values.lastName}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <FormHelperText style={{ color: 'red' }}>
                                                                {touched.lastName && errors.lastName}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                       
                                                        <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>Email*</InputLabel>
                                                            <OutlinedInput
                                                                id="email"
                                                                name="email"
                                                                label="Email*"
                                                                autoComplete="emails"
                                                                error={touched.email && !!errors.email}
                                                                helperText={touched.email && errors.email}
                                                                defaultValue={values.email}
                                                                onChange={(e)=>{
                                                                    checkUsaTLD(e.target.value);   
                                                                    handleChange('email');
                                                                    setFieldValue('email', e.target.value, true)
                                                                }}
                                                                onBlur={handleBlur}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {(touched.email && !errors.email) &&  checkUsaTldStatus == false ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                               ( touched.email && errors.email) || checkUsaTldStatus == true ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <FormHelperText style={{ color: 'red'}}>
                                                                {touched.email && errors.email ?errors.email : checkUsaTldStatus ? "Invalid email format." : '' }
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                       
                                                        <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>Password*</InputLabel>
                                                            <OutlinedInput
                                                                id="password"
                                                                name="password"
                                                                label="Password*"
                                                                autoComplete="password"
                                                                type={showPassword ? 'text' : 'password'}
                                                                error={touched.password && !!errors.password}
                                                                helperText={touched.password && errors.password}
                                                                defaultValue={values.password}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {touched.password && !errors.password ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.password && errors.password ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? (
                                                                            <VisibilityOffIcon />
                                                                            ) : (
                                                                            <VisibilityIcon />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <FormHelperText style={{ color: 'red' }}>
                                                                {touched.password && errors.password}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                {errormsg ? <>
                                                    <Typography sx={{ color: "red" }} ><b>
                                                        Error : - {errormsg}  {enrolledStatus == 1 ? <><Button color="success" onClick={loginLink} >Login</Button></> : null}</b>
                                                    </Typography>
                                                </>
                                                    : null}
                                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                    <Typography>By clicking 'NEXT' I agree to: : (1) be contacted by <b>{companyName ? companyName : ''}</b> by email, at my residential or cellular number, or by SMS text and (2) the
                                                        &nbsp;<a href="#" onClick={toggleDrawer3(true)} style={{ cursor: "pointer" }}>Privacy Policy</a>
                                                        <SwipeableDrawer
                                                            anchor={'bottom'}
                                                            open={open3}
                                                            onClose={toggleDrawer3(false)}
                                                            onOpen={toggleDrawer3(true)}
                                                        >
                                                            <PrivacyPolicy closeVal={toggleDrawer3(false)} />
                                                        </SwipeableDrawer>
                                                        &nbsp;and&nbsp;
                                                        <a href="#" onClick={toggleDrawer4(true)} style={{ cursor: "pointer" }}>Terms & Conditions</a>
                                                        <SwipeableDrawer
                                                            anchor={'bottom'}
                                                            open={open4}
                                                            onClose={toggleDrawer4(false)}
                                                            onOpen={toggleDrawer4(true)}
                                                        >
                                                            <TermsAndConditions closeVal={toggleDrawer4(false)} />
                                                        </SwipeableDrawer>.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "right" }}>
                                                <Button color="warning" type="submit" disabled={isSubmitting} sx={{ backgroundColor: "#ED7E2E", color: "white", minWidth: 200, borderRadius: "0%" }}>Next</Button>
                                            </Grid>
                                        </Form>
                                    )}

                                </Formik>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Box>
                <RegisterFooter />
            </Box>
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default EnrollStep1;