import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Grid, Divider, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, TextField, FormGroup, FormControlLabel, Checkbox, FormControl, FormLabel, Tooltip, RadioGroup, Radio, Table, FormHelperText, Box, Paper } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Stack } from '@mui/joy';
import AuthUser from '../../Components/Auth/AuthUser';
import CobrandColorPicker from "../../Components/CobrandCustomization/CobrandColorPicker";
import LoginHeader from '../LoginHeader';
import DefaultLayout from '../DefaultLayout';
import { TextareaAutosize } from '@mui/base';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import * as Yup from 'yup';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SecureWebsite from "../../Components/Enrollment/SecureWebsite";
import InputMask from 'react-input-mask';
import CustomizeEnrollIndex from '../../Components/CobrandCustomization/CustomizeEnrollIndex'
import swal from "sweetalert";
import config from '../../AppConfig'
import Freez from '../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';

let cobrandFlag = true;
function CobrandIndex() {
    const dispatch = useDispatch();
    let bannerImg1 = require("../../assets/images/Mainlogo.png")
    const { http } = AuthUser();
    const [openColorPicker, setOpenColorPicker] = useState(null);
    const [stepcount, setStepCount] = useState(1);
    const [freez, setFreez] = React.useState(false);
    const [offerdetails, setOfferDetails] = useState();
    const [checkBoxValue, setCheckBoxValue] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [colorPickerCount, setColorPickerCount] = useState(1);
    const [bannerImgPlanFlag, setBannerImgPlanFlag] = useState(false);
    const [formCount, setFormCount] = useState(1)
    const [btnName, setBtnName] = useState('Submit')
    const [currentFormIndex, setCurrentFormIndex] = useState(1);
    const [openPaymentPopup, setOpenPaymentPopup] = useState(false)
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false)
    const [openReview, setOpenReview] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [flagvalue, setFlagValue] = useState(false);
    const [successfulPayment, SetSuccessfulPayment] = useState(false)
    const [openImgPopup, setOpenImgPopup] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [purchaseBannerCheck, setPurchaseBannerCheck] = useState(false)
    const [publishBannerBtn, setPublishBannerBtn] = useState(false)
    const [aid, setAid] = useState();
    const [pid, setPid] = useState();
    const [companyId, setCompanyId] = useState();
    const [aidId, setAidId] = useState()
    const [disabledCroppedBtn, setDisabledCroppedBtn] = useState(true)
    const [croppedUrl, setCroppedUrl] = useState()
    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 25,
        y: 25,
    })
    const [completedCrop, setCompletedCrop] = useState([])
    const [croppedImages, setCroppedImages] = useState([]);
    const [customColorArrays, setCustomColorArrays] = useState([]);
    const [activeColor, setActiveColor] = useState(true)
    const [gridRef, setGridRef] = useState()
    const [templateValue, setTemplateValue] = useState(1)
    const [originalImgsArray, setOriginalImgsArray] = useState([])
    const [gridWidth, setGridWidth] = useState()
    const [gridHeight, setGridHeight] = useState()
    const [gridAspectRatio, setGridAspectRatio] = useState()
    const [tempImg, setTemImg] = useState()
    const [textColor, setTextColor] = useState('black')
    const [customFormColorArray, setCustomFormColorArray] = useState({
        customColor_1: '#9A973C' // Initial color for customColor_1
    });

    useEffect(() => {
        getCobrandSubmitedDetails()
    }, [])

    const getCobrandSubmitedDetails = async () => {
        let retrievedCustomColor = localStorage.getItem('defaultColorValues');
        let retrievedCroppedImages = localStorage.getItem('defaultCroppedImages');

        retrievedCustomColor = JSON.parse(retrievedCustomColor);
        retrievedCroppedImages = JSON.parse(retrievedCroppedImages)

        handleFreezStart()
        const apiUrl = '/cobrandCustomizeDetails';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.data) {
                handleFreezStop()
                if (res.data.is_publish == 0) {
                    setPublishBannerBtn(true)
                }
                else {
                    setPublishBannerBtn(false)
                }
                let banner1 = res.data.hero_image_1 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_1 : retrievedCroppedImages[0].url;
                let banner2 = res.data.hero_image_2 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_2 : retrievedCroppedImages[1].url;
                let banner3 = res.data.hero_image_3 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_3 : retrievedCroppedImages[2].url
                setCroppedImages(
                    [
                        { step: 1, url: banner1, },
                        { step: 2, url: banner2, },
                        { step: 4, url: banner3, }]
                );
                setOriginalImgsArray([
                    { step: 1, url: banner1, },
                    { step: 2, url: banner2, },
                    { step: 4, url: banner3, }])
                setCustomColorArrays(
                    [
                        { bodyBackground: res.data.body_background_color, primaryColor: res.data.primary_color }, // Color array
                    ])

                setTextColor(res.data.text_color ? res.data.text_color : 'black')
            }
            else {
                // alert("something went wrong !");
                handleFreezStop()
                setCustomColorArrays(retrievedCustomColor)
                setCroppedImages(retrievedCroppedImages)
                setOriginalImgsArray(retrievedCroppedImages)
                setTextColor('black')
            }
        }
    }
    const imgRef = useRef(null)

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const onColorValueChange = (value) => {
        localStorage.setItem('defaultColorValues', JSON.stringify(value));
        setCustomColorArrays(value)
    }

    const onValueChange = (croppedImages, stepcount) => {
        localStorage.setItem('defaultCroppedImages', JSON.stringify(croppedImages));
        setCroppedImages(croppedImages)
        setStepCount(stepcount)
    }

    // const setTextColorChange=(color)=>{
    //     setTextColor(color)
    // }

    let initialFormValues = {
        idea: '',
        details: '',
        message1: '',
        message2: '',
        colorTheme: customFormColorArray,
        referenceImage: '',
        bannerPlanes: ''
    }

    const toggleColorPicker = (blockName) => {
        setOpenColorPicker((prevBlockName) => (prevBlockName === blockName ? null : blockName));
    };

    const handleBannerImgChange = (event) => {
        const file = event.target.files[0]; // Get the first selected file

        // Check if a file is selected
        if (file) {
            // Check if the selected file is an image
            if (file.type.startsWith('image/')) {
                const reader = new FileReader(); // Create a file reader
                reader.onload = () => {
                    // Update the banner image with the data URL of the selected file
                    setImgSrc(reader.result?.toString() || '')
                    setOpenImgPopup(true)
                };
                // Read the selected file as a data URL
                reader.readAsDataURL(file);
            } else {
                // Show an error alert if the selected file is not an image
                alert("Please select an image file.");
            }
        }
    };

    const handleValue = (colorArray) => {
        let updatedColorArrays = colorArray;
        setCustomColorArrays(colorArray);
        // console.log("updatedColorArrays" + JSON.stringify(updatedColorArrays))
    }

    const onSubmitStyleChanges =async () => {
        handleFreezStart();
        const apiUrl = '/createCobrandCustomize';
        const args = {
			companyId: companyId,
            aidId: aidId,
            defaultCustomBanner: croppedImages,
            defaultCustomColor: customColorArrays,
            textColor: textColor
		}
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if (res.success === true) {
                handleFreezStop()
                swal({
                    title: "Success",
                    text: 'Details Saved Successfully',
                    icon: "success",
                    button: "Ok",
                })
                setPublishBannerBtn(true)

            } else {
                alert("something went wrong !");
                handleFreezStop()
            }
        }
    };

    const publishCobrandDetails = async () => {
        handleFreezStart()
        const apiUrl = '/publishCobrandCustomize/' + companyId + '/' + aidId;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success === true) {
                handleFreezStop()
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
                setPublishBannerBtn(false)
            }
            else {
                alert("something went wrong !");
            }
        }
    }


    const validationFormSchema = Yup.object().shape({
        idea: Yup.string()
            .required('Please add idea.'),
    });

    const closeCobrandCustmizationForm = () => {
        setOpenPopup(false)
    }

    //submit form for Purchasing bannerimg
    const submitCustomFormvalues = (values, { resetForm }) => {
        // alert(Object.keys(customFormColorArray).length)
        // const colorValues = Object.values(customFormColorArray).map(color => color);
        const colorValues = Object.keys(customFormColorArray).map(colorKey => {
            if (typeof customFormColorArray[colorKey] === "object") {
                return customFormColorArray[colorKey][colorKey];
            } else {
                return customFormColorArray[colorKey];
            }
        });
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(JSON.stringify(values))
        console.log("customFormColorArray" + JSON.stringify(customFormColorArray))

        let formDataArray = JSON.parse(localStorage.getItem("formDataArray")) || [];

        // Create an object representing the current form data
        let formData = {
            id: user.id,
            name: user.name,
            idea: values.idea,
            details: values.details,
            message1: values.message1,
            message2: values.message2,
            colorTheme: colorValues,
            referenceImage: values.referenceImage,
            bannerPlanes: values.bannerPlanes
        };

        // Add the current form data to the array
        formDataArray.push(formData);

        // Store the updated form data array in local storage
        localStorage.setItem("formDataArray", JSON.stringify(formDataArray));
        if (currentFormIndex == formCount) {
            setOpenPopup(false)
        }
        else {
            setCurrentFormIndex(currentFormIndex + 1);
            resetForm(initialFormValues)
            setCustomFormColorArray({ customColor_1: '#9A973C' })
            console.log("Form reset");
        }
    }

    const handleTooltipClick = () => {
        const newColorPickerCount = colorPickerCount + 1;
        setCustomFormColorArray(prevState => ({
            ...prevState,
            [`customColor_${newColorPickerCount}`]: '#ffffff'
        }));
        setColorPickerCount(newColorPickerCount);
    };

    const handleRemoveColorPicker = (colorKey) => {
        // Remove the latest added color picker
        const updatedColors = { ...customFormColorArray };
        const lastKey = `customColor_${colorPickerCount}`;
        delete updatedColors[lastKey];
        setCustomFormColorArray(updatedColors);
        setColorPickerCount(prevCount => prevCount - 1);
    };

    const handleBannerPlans = (e) => {
        localStorage.removeItem("formDataArray");
        // alert(e.target.value)

        setBannerImgPlanFlag(true)
        if (e.target.value === '$49') {
            setFormCount(1);
            // setBtnName('Submit')
        } else if (e.target.value === '$89') {
            setFormCount(2);
            // setBtnName('Next')
        } else if (e.target.value === '$129') {
            setFormCount(3);
        }
        // setCurrentFormIndex(1);
    }
    let savedGridRef;
    // useEffect(() => {
    //     savedGridRef = localStorage.getItem("gridRef");
    //     console.log(savedGridRef)
    //     if (savedGridRef) {
    //         setCrop({
    //             unit: 'px',
    //             x: 0,
    //             y: 0,
    //             width: savedGridRef.width,
    //             height: savedGridRef.height
    //         });
    //     }

    // }, []);

    // const handleResize = () => {
    //     if (gridRef.current) {
    //         setWidth(gridRef.current.offsetWidth);
    //         setHeight(gridRef.current.offsetHeight);
    //         console.log("gridRef.current.offsetWidth" + gridRef.current.offsetWidth);
    //         console.log("gridRef.current.offsetHeight" + gridRef.current.offsetHeight);
    //         setCrop({
    //             unit: 'px',
    //             x: 0,
    //             y: 0,
    //             width: gridRef.current.offsetWidth ,
    //             height: gridRef.current.offsetHeight
    //         })
    //     }
    // };

    useEffect(() => {
        const indexToUpdate = stepcount === 4 ? stepcount - 2 : stepcount - 1;
        setTemImg(originalImgsArray[indexToUpdate])
        if (completedCrop && imgRef.current) {
            setDisabledCroppedBtn(false)
            const canvas = document.createElement('canvas');
            const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
            const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
            canvas.width = completedCrop.width * scaleX;
            canvas.height = completedCrop.height * scaleY;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                imgRef.current,
                completedCrop.x * scaleX,
                completedCrop.y * scaleY,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY,
                0,
                0,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY
            );
            let croppedImageUrl = canvas.toDataURL('image/jpeg');
            const updatedCroppedImagesArrays = [...croppedImages];
            const updatedCroppedImageArray = { step: stepcount, url: croppedImageUrl, flag: 1 };
            updatedCroppedImagesArrays[indexToUpdate] = updatedCroppedImageArray;
            setCroppedImages(updatedCroppedImagesArrays);
        }
    }, [completedCrop]);

    const getPopupClosed = () => {
        setOpenImgPopup(false);
        const indexToUpdate = stepcount === 4 ? stepcount - 2 : stepcount - 1;
        // Make a copy of the current cropped images array
        const updatedCroppedImagesArrays1 = [...originalImgsArray];

        // Replace the newly added image at the specified index with the original image
        updatedCroppedImagesArrays1[indexToUpdate] = tempImg;

        // Update the state with the modified array of cropped images
        setCroppedImages(updatedCroppedImagesArrays1);
    }
    const getCroppedImg = () => {
        setOpenImgPopup(false)
        setCroppedImages(croppedImages);
        setOriginalImgsArray(croppedImages)
    }

    const getApiRequiredParameters = (aid, pid, companyId, AidId) => {
        setAid(aid)
        setPid(pid)
        setCompanyId(companyId)
        setAidId(AidId)
    }

    const getTemplate = (value) => {
        setTemplateValue(value)
        setActiveColor(true)
    }


    const onImageLoad = () => {
        setCrop({
            unit: 'px',
            x: 0,
            y: 0,
            width: imgRef.current.width,
            height: imgRef.current.height
        })
        setCompletedCrop({
            unit: 'px',
            x: 0,
            y: 0,
            width: imgRef.current.width,
            height: imgRef.current.height
        });
    }


    const onGridRefChange = (gridRef) => {
        // console.log(JSON.stringify(gridRef))
        setGridWidth(gridRef.width)
        setGridHeight(gridRef.height)
        const aspectRatio = gridRef.width / gridRef.height;
        setGridAspectRatio(aspectRatio);
        // setCrop({
        //     unit: 'px',
        //     x: 0,
        //     y: 0,
        //     width: gridRef.width,
        //     height: gridRef.height
        // });
    }
    const setTextColorValue = (e) => {
        setTextColor(e.target.value)
    }
    const ContentLayout =
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography level="h4" color="primary" pb={2}>Cobrand Customization</Typography>
                </Grid>


                <Grid container item lg={12} md={12} sm={12} xs={12} className='row-reverse' wrap="nowrap" columnSpacing={2}>
                    <Grid container item lg={9} md={9} sm={12} xs={12} sx={{ width: '100%', display: 'flex' ,height:'100%'}}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} pl={2} sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '1%' }} spacing={2} className='thumbnil-outer-view'>
                            <Grid item lg={2} md={3} sm={3} xs={6} className='cobrand-thumbnil'>
                                <Paper elevation={6} onClick={() => getTemplate(1)} sx={{ position: 'relative', pointerEvents: 'auto', cursor: 'pointer', width: '50%' }} className={stepcount == 1 && activeColor ? 'active-color' : ''} >
                                    <img src={require("../../assets/images/Step1.jpg")}></img>
                                    <div className={stepcount == 1 && activeColor ? 'overlayDiv display_block' : 'display_none'} >
                                        <div className='overlayText'>Step 1</div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item lg={2} md={3} sm={3} xs={6} className='cobrand-thumbnil'>
                                <Paper elevation={6} onClick={() => getTemplate(2)} sx={{ position: 'relative', pointerEvents: 'auto', cursor: 'pointer', width: '50%' }} className={stepcount == 2 && activeColor ? 'active-color' : ''}>
                                    <img src={require("../../assets/images/Step2.jpg")}></img>
                                    <div className={stepcount == 2 && activeColor ? 'overlayDiv display_block' : 'display_none'} >
                                        <div className='overlayText'>Step 2</div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item lg={2} md={3} sm={3} xs={6} className='cobrand-thumbnil'>
                                <Paper elevation={6} onClick={() => getTemplate(3)} sx={{ position: 'relative', pointerEvents: 'auto', cursor: 'pointer', width: '50%' }} className={stepcount == 3 && activeColor ? 'active-color' : ''}>
                                    <img src={require("../../assets/images/Step3.jpg")}></img>
                                    <div className={stepcount == 3 && activeColor ? 'overlayDiv display_block' : 'display_none'} >
                                        <div className='overlayText'>Step 3</div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item lg={2} md={3} sm={3} xs={6} className='cobrand-thumbnil'>
                                <Paper elevation={6} onClick={() => getTemplate(4)} sx={{ position: 'relative', pointerEvents: 'auto', cursor: 'pointer', width: '50%' }} className={stepcount == 4 && activeColor ? 'active-color' : ''}>
                                    <img src={require("../../assets/images/Step4.jpg")}></img>
                                    <div className={stepcount == 4 && activeColor ? 'overlayDiv display_block' : 'display_none'} >
                                        <div className='overlayText'>Step 4</div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item lg={2} md={3} sm={3} xs={6} className='cobrand-thumbnil'>
                                <Paper elevation={6} onClick={() => getTemplate(5)} sx={{ position: 'relative', pointerEvents: 'auto', cursor: 'pointer', width: '50%' }} className={stepcount == 5 && activeColor ? 'active-color' : ''}>
                                    <img src={require("../../assets/images/Step5.jpg")}></img>
                                    <div className={stepcount == 5 && activeColor ? 'overlayDiv display_block' : 'display_none'} >
                                        <div className='overlayText'>Step 5</div>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }} spacing={3}>
                            <Grid item lg={2} md={3} sm={3} xs={6} ><Box sx={{ border: '1px solid gray', padding: '5px', pointerEvents: 'auto', opacity: (stepcount !== 1 || !activeColor) ? 0.5 : 1 }} onClick={() => getTemplate(1)} className={stepcount == 1 && activeColor ? 'active-color' : ''}>Step 1</Box></Grid>
                            <Grid item lg={2} md={3} sm={3} xs={6} ><Box sx={{ border: '1px solid gray', padding: '5px', pointerEvents: 'auto', opacity: (stepcount !== 2 || !activeColor) ? 0.5 : 1 }} onClick={() => getTemplate(2)} className={stepcount == 2 && activeColor ? 'active-color' : ''}>Step 2</Box></Grid>
                            <Grid item lg={2} md={3} sm={3} xs={6} ><Box sx={{ border: '1px solid gray', padding: '5px', pointerEvents: 'auto', opacity: (stepcount !== 3 || !activeColor) ? 0.5 : 1 }} onClick={() => getTemplate(3)} className={stepcount == 3 && activeColor ? 'active-color' : ''}>Step 3</Box></Grid>
                            <Grid item lg={2} md={3} sm={3} xs={6} ><Box sx={{ border: '1px solid gray', padding: '5px', pointerEvents: 'auto', opacity: (stepcount !== 4 || !activeColor) ? 0.5 : 1 }} onClick={() => getTemplate(4)} className={stepcount == 4 && activeColor ? 'active-color' : ''}>Step 4</Box></Grid>
                            <Grid item lg={2} md={3} sm={3} xs={6} ><Box sx={{ border: '1px solid gray', padding: '5px', pointerEvents: 'auto', opacity: (stepcount !== 5 || !activeColor) ? 0.5 : 1 }} onClick={() => getTemplate(5)} className={stepcount == 5 && activeColor ? 'active-color' : ''}>Step 5</Box></Grid>

                        </Grid> */}
                        <Card sx={{ padding: '0px' }}>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ width: '100%' }} >
                                    <Card className='cardstyle' style={{ marginTop: "1%", width: '100%', padding: '0px' }} >
                                        <CardContent style={{}}>
                                            {/* <Grid sx={{ pointerEvents: 'none !important' }}>
                                                <LoginHeader aid={aid} logoName={'cobranding'} customColorArray={customColorArrays[0]} />
                                            </Grid> */}
                                            <CustomizeEnrollIndex textColor={textColor} templateValue={templateValue} cobrandFlag={cobrandFlag} getApiRequiredParameters={getApiRequiredParameters} croppedImages={croppedImages} customColorArrays={customColorArrays} offerdetails={offerdetails} pid={pid} onValueChange={onValueChange} onColorValueChange={onColorValueChange} onGridRefChange={onGridRefChange} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={3} md={3} sm={12} xs={12} sx={{ marginBottom: { xs: '20px', md: '0px' } }}>
                        <Card sx={{  boxShadow: '0px 0px 10px #888888' }}>
                            <CardOverflow sx={{ bgcolor: '#F6FAFF', borderColor: '#F6FAFF', padding: '1% !imoportant', }}>
                                <Typography gutterBottom color="primary" variant='h5'><b>Set Up Your Custom Page</b></Typography>
                            </CardOverflow>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'block', width: '100%' }} pt={3} pb={2}>

                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        {customColorArrays?.map((colorArray, index) => (

                                            <div key={index}>
                                                <CobrandColorPicker
                                                    title="Body Background"
                                                    blockId="bodyBackground"
                                                    blockName="bodyBackground"
                                                    value={colorArray.bodyBackground}
                                                    customColorArray={colorArray}
                                                    handleValue={(colorArray) => handleValue(colorArray)}
                                                    openColorPicker={openColorPicker}
                                                    toggleColorPicker={toggleColorPicker}
                                                />

                                                <CobrandColorPicker
                                                    title="Primary Color"
                                                    blockId="primaryColor"
                                                    blockName="primaryColor"
                                                    value={colorArray.primaryColor}
                                                    customColorArray={colorArray}
                                                    handleValue={(colorArray) => handleValue(colorArray)}
                                                    openColorPicker={openColorPicker}
                                                    toggleColorPicker={toggleColorPicker}
                                                />
                                            </div>
                                        ))}
                                    </Grid>

                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={7} md={7} sm={7} xs={7}>
                                            <label htmlFor='textColor1' style={{ fontWeight: "bold", marginRight: "10px" }}>
                                                Text Color
                                            </label>
                                        </Grid>
                                        <Grid item lg={5} md={5} sm={5} xs={5}>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                id="textColor1"
                                                // name="textColor"
                                                value={textColor}
                                                onChange={setTextColorValue}
                                            >
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControlLabel value="black" control={<Radio />} label="Black" />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControlLabel value="white" control={<Radio />} label="White" />
                                                    </Grid>
                                                </Grid>




                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <div style={{ borderRadius: '5px' }}>

                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2} >
                                                <Button size='md' variant="contained" color="primary" disabled={stepcount == 3 || stepcount == 5 ? true : false} sx={{
                                                    width: '100%',
                                                    borderRadius: "5px",

                                                }} onClick={() => { document.querySelector('.upload_file').click(); }}>
                                                    Update Hero Image {stepcount === 4 ? stepcount - 1 : stepcount}
                                                </Button>

                                            </Grid>
                                            <input accept="image/*" type="file" className="upload_file" onClick={(e) => e.target.value = null} onChange={handleBannerImgChange} style={{ display: 'none' }} />
                                        </div>

                                        {/* <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} >
                                            <Typography level="h6" sx={{ display: 'flex' }}>
                                                <Grid mb={2}>
                                                    <input type="checkbox" checked={purchaseBannerCheck}
                                                        onChange={e => {
                                                            setPurchaseBannerCheck(!purchaseBannerCheck);
                                                        }} /></Grid>
                                                <Grid><span style={{ fontSize: '0.8em', textAlign: 'center' }}> If you don't have any Hero Image you can Purchase from here.</span></Grid>
                                            </Typography>
                                        </Grid> */}
                                        {/* {purchaseBannerCheck ? <div>
                                            <Typography variant='h5'><b>Purchase Hero Image</b></Typography>
                                            <FormControl className='radio-group-css'>

                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    id="BannerPlanes"
                                                    name="bannerPlanes"
                                                    onChange={(e) => handleBannerPlans(e)}
                                                >
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', width: '100%' }}>
                                                        <FormControlLabel style={{ display: 'flex', width: '100%' }} value="$49" control={<Radio />} label={
                                                            <Typography sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                width: '100%'
                                                            }}>
                                                                <Grid row item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', width: '100%' }} columnSpacing={3}>
                                                                    <Grid item lg={9} md={9} sm={9} xs={9}> <Typography>For Single Hero Image</Typography></Grid>
                                                                    <Grid item lg={3} md={3} sm={3} xs={3}> <Typography component="span" style={{ fontWeight: 'bold', float: 'right' }}>$49</Typography></Grid>
                                                                </Grid>
                                                            </Typography>
                                                        } />
                                                    </Grid>

                                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', width: '100%' }}>
                                                        <FormControlLabel style={{ display: 'flex', width: '100%' }} value="$89" control={<Radio />} label={
                                                            <Typography sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                width: '100%'
                                                            }}>
                                                                <Grid row item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', width: '100%' }} columnSpacing={3}>
                                                                    <Grid item lg={9} md={9} sm={9} xs={9}> <Typography>For Two Hero Images </Typography></Grid>
                                                                    <Grid item lg={3} md={3} sm={3} xs={3}> <Typography component="span" style={{ fontWeight: 'bold', float: 'right' }}>$89</Typography></Grid>
                                                                </Grid>
                                                            </Typography>
                                                        } />
                                                    </Grid>

                                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', width: '100%' }}>
                                                        <FormControlLabel style={{ display: 'flex', width: '100%' }} value="$129" control={<Radio />} label={
                                                            <Typography sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                width: '100%'
                                                            }}>
                                                                <Grid row item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', width: '100%' }} columnSpacing={3}>
                                                                    <Grid item lg={9} md={9} sm={9} xs={9}> <Typography>  For Three Hero Images  </Typography></Grid>
                                                                    <Grid item lg={3} md={3} sm={3} xs={3}> <Typography component="span" style={{ fontWeight: 'bold', float: 'right' }}>$129</Typography></Grid>
                                                                </Grid>
                                                            </Typography>
                                                        } />

                                                    </Grid>
                                                </RadioGroup>

                                            </FormControl>
                                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                <Button size='md' variant="contained" color='success' sx={{

                                                    width: ' 100%',
                                                    // background: 'transparent !important',
                                                    // color: 'black',
                                                    // border: '1px solid lightgray',
                                                    // borderRadius: "5px",
                                                    marginTop: '10px'
                                                }} onClick={() => { setOpenPopup(true); setCurrentFormIndex(1) }} disabled={!bannerImgPlanFlag}>Create Hero Image For Me</Button>
                                            </Grid>
                                        </div> : null} */}

                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }} mt={4} mb={3}>
                                        <Grid item lg={8} md={8} sm={8} xs={8}>
                                            <Button type="submit" color="primary" variant="contained" disabled={purchaseBannerCheck} onClick={onSubmitStyleChanges} sx={{
                                                borderRadius: "5px"
                                            }} >Submit Changes</Button>
                                        </Grid>

                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                            <Button variant="contained" color="success"
                                                disabled={!publishBannerBtn}
                                                onClick={publishCobrandDetails}
                                            // onClick={() => setOpenPaymentPopup(true)}
                                            >
                                                Publish
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </CardContent>
                        </Card>

                        <Card sx={{boxShadow: '0px 0px 10px #888888',marginTop:'2%' }}>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {/* <Typography level='h4' sx={{ fontWeight: 'bold' }}>
                                            LIMITED PERIOD OFFER
                                        </Typography> */}
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Typography level='h4' sx={{ fontWeight: 'bold' }}>
                                            ADVANCED COBRAND CUSTOMIZATION
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        <Typography level='h4' style={{ fontFamily: 'sans-serif' }}>
                                            WORTH <br></br>
                                            <Typography level='h2' sx={{ color: 'green', fontWeight: 'bold' }}>
                                                $350
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        <Typography level='h4' style={{ fontFamily: 'sans-serif' }}>
                                            ABSOLUTELY <br></br>
                                        </Typography>
                                        <Typography level='h2' sx={{ color: '#FF9400', fontWeight: 'bold' }}>
                                            FREE!
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid >


                <Dialog
                    scroll='paper'
                    open={openPopup}
                    // onClose={(_, reason) => {
                    //   if (reason !== "backdropClick") {
                    //     handleCloseAssigned();
                    //   }
                    // }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                // maxWidth={'lg'}
                >
                    <Grid container item lg={12} md={12} sm={12} xs={12} pt={2}>
                        <>
                            <Formik
                                initialValues={initialFormValues}
                                validationSchema={validationFormSchema}
                                // onSubmit={submitCustomFormvalues}
                                onSubmit={submitCustomFormvalues}
                            >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) => (

                                <>
                                    <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} mb={2} sx={{ backgroundColor: "#F6FAFF", width: '100%' }} >
                                        <Typography variant='h3' color='primary'>Details For Creating Hero Image{currentFormIndex}</Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} >

                                            <Grid container lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <FormControl size='small' fullWidth>
                                                        <FormLabel>Idea* :</FormLabel>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <FormControl size='small' fullWidth sx={{ marginTop: '8px' }}>
                                                        <TextareaAutosize
                                                            style={{
                                                                width: "100%", border: '1px solid #c4c4c4',
                                                                borderRadius: '4px', padding: '4.5px 14px'
                                                            }}
                                                            required
                                                            color="neutral"
                                                            // margin="dense"
                                                            //   aria-label="minimum height"
                                                            minRows={2}
                                                            placeholder="describe your idea"
                                                            name="idea"
                                                            id="Idea"
                                                            value={values.idea}
                                                            onChange={handleChange('idea')}
                                                        />
                                                        {touched.idea && errors.idea ? <div className='error'>{errors.idea}</div> : null}

                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >


                                                <Grid container lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth>
                                                            <FormLabel>Details :</FormLabel>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth sx={{ marginTop: '8px' }}>
                                                            <TextareaAutosize
                                                                style={{
                                                                    width: "100%", border: '1px solid #c4c4c4',
                                                                    borderRadius: '4px', padding: '4.5px 14px'
                                                                }}
                                                                required
                                                                minRows={2}
                                                                color="neutral"
                                                                // margin="dense"
                                                                //   aria-label="minimum height"
                                                                //   minRows={6}
                                                                placeholder="provide more details"
                                                                name="details"
                                                                id="Details"
                                                                onChange={handleChange('details')}
                                                                value={values.details}
                                                            //   onChange={handleChange}
                                                            />

                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth>
                                                            <FormLabel>Message1 :</FormLabel>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth>
                                                            <TextField fullWidth
                                                                margin="dense"
                                                                size="small"
                                                                name='message1'
                                                                placeholder="add message"
                                                                value={values.message1}
                                                                onChange={handleChange('message1')}></TextField>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth>
                                                            <FormLabel>Message2 :</FormLabel>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth>
                                                            <TextField fullWidth
                                                                margin="dense"
                                                                size="small"
                                                                name="message2"
                                                                placeholder="add message"
                                                                value={values.message2}
                                                                onChange={handleChange('message2')}></TextField>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mb={2} columnSpacing={1}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth>
                                                            <FormLabel>Select color(select multiple colors that you want to use) :</FormLabel>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        {/* {Object.keys(customFormColorArray).map(colorKey => (
                                                        <Typography key={colorKey}>{JSON.stringify(customFormColorArray[colorKey])}</Typography>
                                                    ))} */}
                                                        <div>
                                                            {Object.keys(customFormColorArray).map((colorKey, index) => (
                                                                <div key={index}>
                                                                    {typeof customFormColorArray[colorKey] === "object" ?
                                                                        `${colorKey}: ${customFormColorArray[colorKey][colorKey]}` :
                                                                        `${colorKey}: ${customFormColorArray[colorKey]}`
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                <FormControl size='small' fullWidth sx={{ width: '0%' }}>
                                                                    {Object.keys(customFormColorArray).map((colorKey, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <CobrandColorPicker
                                                                                name="colorTheme"
                                                                                blockId={colorKey}
                                                                                blockName={colorKey}
                                                                                colorType="colorForm"
                                                                                value={customFormColorArray[colorKey]}
                                                                                customColorArray={customFormColorArray}
                                                                                handleValue={(color) => {
                                                                                    setCustomFormColorArray(prevState => ({
                                                                                        ...prevState,
                                                                                        [colorKey]: color
                                                                                    }));
                                                                                }}
                                                                                openColorPicker={openColorPicker}
                                                                                toggleColorPicker={toggleColorPicker}
                                                                            />

                                                                        </React.Fragment>
                                                                    ))}


                                                                </FormControl>
                                                            </Grid>

                                                            <Grid lg={6} md={6} sm={6} xs={6} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                <Tooltip title="Add color" arrow>
                                                                
                                                                    <img src={require('../../assets/icons/addicon.png')} onClick={handleTooltipClick} />
                                                                </Tooltip>
                                                                {Object.keys(customFormColorArray).length !== 1 ?
                                                                    <Tooltip title="Remove color" arrow>
                                                                       
                                                                        <img src={require('../../assets/images/delete.png')} onClick={handleRemoveColorPicker} />
                                                                       
                                                                    </Tooltip>
                                                                    : null}
                                                            </Grid>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid container lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', alignItems: 'center' }} mb={2}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth>
                                                            <FormLabel>Reference Image(if u have any) :</FormLabel>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <FormControl size='small' fullWidth>
                                                            <input accept="image/*" type="file" name='referenceImage' onChange={handleChange('referenceImage')} />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </DialogContent>

                                    <DialogActions sx={{ margin: 'auto' }}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} columnSpacing={2} className='customizationFormBtn'>
                                            <Grid item lg={6} md={6} sm={6} xs={6} >
                                                <Button variant="contained" onClick={closeCobrandCustmizationForm} sx={{ minWidth: 250 }} >
                                                    Close
                                                </Button>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Button variant="contained" onClick={() => {
                                                    handleSubmit();
                                                }} color="success" sx={{ minWidth: 250 }}>
                                                    {/* {btnName} */}
                                                    {currentFormIndex != formCount ? 'Next' : 'Submit'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </>
                            )}
                            </Formik>
                        </>
                    </Grid>

                </Dialog>
                <div>
                    <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={openPaymentPopup}
                        // onClose={(_, reason) => {
                        //     if (reason !== "backdropClick") {
                        //         handleClose();
                        //     }
                        // }}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                            <Typography level="h4" sx={{ textAlign: "center" }}>Purchase Order Confirmation</Typography>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenPaymentPopup(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent p={2}>
                            <DialogContentText sx={{ textAlign: "center" }} mt={2}>
                                <Typography level="h5">Your Customize Banner Image</Typography>
                            </DialogContentText>

                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                <FormControlLabel control={<Checkbox value={flagvalue} onClick={() => setFlagValue(!flagvalue)} />} label={<Typography variant="body2" color="textSecondary">Agree to purchase banner image for a non-refundable <Typography sx={{ fontSize: '20px' }}><b>$89</b></Typography></Typography>} />

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="warning" onClick={() => setOpenPaymentPopup(false)} sx={{ backgroundColor: "#ED7E2E", color: "white", borderRadius: "3px" }}>
                                Close
                            </Button>
                            <Button onClick={() => setOpenPaymentMethod(true)} disabled={!flagvalue} variant="contained" color="success" sx={{
                                backgroundColor: "#5cb85c",
                                color: "#fff", borderRadius: "3px", minWidth: 200
                            }}>
                                Continue
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullWidth={fullWidth}
                        maxWidth={'md'}
                        open={openPaymentMethod}

                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                            <Stack direction="row">
                                <Grid item lg={4} md={4} xs={4} sm={4}>
                                    <img alt="#" src={require("../../assets/images/myfreescorenowlogo.jpg")} width="50%" height="auto" />
                                </Grid>
                                <Grid item lg={6} md={6} xs={6} sm={6} sx={{ textAlign: "center", }}>
                                    <Stack direction={'row'} justifyContent={'space-evenly'}>
                                        <Grid><Typography level="h4">Payment Details</Typography></Grid>
                                        <Grid pl={5}><Typography px={2} id="timeExpPayment" className="text-center" style={{ fontSize: '1.3em', backgroundColor: 'red', color: 'white' }} ></Typography></Grid>
                                    </Stack>
                                    {/* <Typography id="timeExp">test</Typography> */}
                                </Grid>
                                <Grid item lg={2} md={2} xs={2} sm={2}>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setOpenPaymentMethod(false)}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Stack>
                        </DialogTitle>
                        {/* formik code starts here */}
                        <Formik
                            initialValues={{
                                FirstName: "",
                                LastName: "",
                                Address: "",
                                Zip: "",
                                CardNumber: "",
                                ExpirationDate: "",
                                CVV: "",
                            }}
                            // validationSchema={validationSchema}
                            onSubmit={(values, errors) => {
                                // checkoutData.FirstName = values.FirstName;
                                // checkoutData.LastName = values.LastName;
                                // checkoutData.Address = values.Address;
                                // checkoutData.Zip = values.Zip;
                                // checkoutData.CardNumber = values.CardNumber;
                                // checkoutData.ExpirationDate = values.ExpirationDate;
                                // checkoutData.CVV = values.CVV;

                                setOpenPaymentMethod(false);
                                // handleClose();
                                // ReviewOrder();
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <DialogContent>
                                    <DialogContentText sx={{ textAlign: "center" }} py={2}>
                                        <Typography level="h6">Enter secure checkout details. Click Continue to go to the next page.</Typography>
                                    </DialogContentText>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                        <Grid container item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="FirstName"
                                                name="FirstName"
                                                onChange={handleChange}
                                                label="Card Holder's First Name*"
                                                variant="outlined"
                                                size="small"
                                                value={values.FirstName}
                                            // helperText={touched.FirstName && errors.FirstName ? errors.FirstName : null}
                                            // error={touched.FirstName && errors.FirstName ? errors.FirstName : null}
                                            />
                                        </Grid>
                                        <Grid container item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="LastName"
                                                name="LastName"
                                                onChange={handleChange}
                                                label="Card Holder's Last Name*"
                                                variant="outlined"
                                                size="small"
                                                value={values.LastName}
                                            // helperText={touched.LastName && errors.LastName ? errors.LastName : null}
                                            // error={touched.LastName && errors.LastName ? errors.LastName : null}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                        <Grid container item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="Address"
                                                name="Address"
                                                onChange={handleChange}
                                                label="Card Holder's Address*"
                                                variant="outlined"
                                                size="small"
                                                value={values.Address}
                                            // helperText={touched.Address && errors.Address ? errors.Address : null}
                                            // error={touched.Address && errors.Address ? errors.Address : null}
                                            />
                                        </Grid>
                                        <Grid container item lg={6} md={6} sm={12} xs={12}>
                                            <InputMask
                                                mask='99999'
                                                maskChar={null}
                                                id="Zip"
                                                name="Zip"
                                                label="Card Holder's Zip*"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                error={touched.Zip && !!errors.Zip}
                                                helperText={touched.Zip && errors.Zip}
                                                value={values.Zip}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {(inputProps) =>
                                                    <TextField
                                                        label="Zip Code*" variant="outlined"
                                                        {...inputProps}
                                                    />
                                                }
                                            </InputMask>
                                        </Grid>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputMask
                                                // beforeMaskedValueChange={beforeMaskedValueChange}
                                                // mask={amexValidation ? '999999999999999' : '9999999999999999'}
                                                maskChar={null}
                                                id="CardNumber"
                                                name="CardNumber"
                                                label="Card Number*"
                                                size="small"
                                                margin="dense"
                                                value={values.CardNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            // error={touched.CardNumber && errors.CardNumber}
                                            // helperText={touched.CardNumber && errors.CardNumber}
                                            >
                                                {(inputProps) =>
                                                    <TextField
                                                        label="Card Number" variant="outlined"
                                                        {...inputProps}
                                                    />
                                                }
                                            </InputMask>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputMask
                                                    mask={'99/99'}
                                                    maskChar={null}
                                                    id="ExpirationDate"
                                                    name="ExpirationDate"
                                                    label="Expiration Date(MM/YY)*"
                                                    size="small"
                                                    margin="dense"
                                                    value={values.ExpirationDate}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                // error={touched.ExpirationDate && errors.ExpirationDate}
                                                // helperText={touched.ExpirationDate && errors.ExpirationDate}
                                                >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label="Expiration Date(MM/YY)*" variant="outlined"
                                                            {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                            </FormControl>
                                            {/* <TextField
                                        fullWidth
                                        margin="dense"
                                        id="ExpirationDate"
                                        name="ExpirationDate"
                                        onChange={handleChange}
                                        label="Expiration Date(MM/YY)*"
                                        variant="outlined"
                                        size="small"
                                        value={values.ExpirationDate}
                                        helperText={touched.ExpirationDate && errors.ExpirationDate ? errors.ExpirationDate : expErrorMsg ? expErrorMsg : null}
                                        error={touched.ExpirationDate && errors.ExpirationDate ? errors.ExpirationDate : expErrorMsg ? expErrorMsg : null}
                                    />                                     */}
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputMask
                                                    // mask={amexValidation ? '9999' : '999'}
                                                    maskChar={null}
                                                    id="CVV"
                                                    name="CVV"
                                                    label="CVV*"
                                                    size="small"
                                                    margin="dense"
                                                    value={values.CVV}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                // error={touched.CVV && errors.CVV}
                                                // helperText={touched.CVV && errors.CVV}
                                                >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label="CVV" variant="outlined"
                                                            // InputProps={{
                                                            //     endAdornment: <InputAdornment position="end">
                                                            //             <ClickAwayListener onClickAway={handleTooltipClose4}>
                                                            //             <Tooltip 
                                                            //             onClose={handleTooltipClose4}
                                                            //             open={open4}
                                                            //             disableFocusListener
                                                            //             disableHoverListener
                                                            //             disableTouchListener
                                                            //             placement="top"
                                                            //     title=
                                                            //     {<>On Visa, MasterCard and Discover the security code is the three digits to the right of the credit card number in the signature area on the back of the card. On American Express, the security code is four digits printed (not embossed) on the right front of the card above the credit card number.
                                                            //     <IconButton
                                                            //             aria-label="close"
                                                            //             size="small"
                                                            //             onClick={handleTooltipClose4}
                                                            //             style={{ position: 'absolute', top: 0, right: 0 }}
                                                            //         >
                                                            //             <CloseIcon fontSize="inherit" sx={{ color: "white" }} />
                                                            //         </IconButton>
                                                            //     </>}
                                                            //     >
                                                            //     <IconButton
                                                            //         edge="end"
                                                            //         sx={{ cursor: "pointer"}}
                                                            //         onClick={handleTooltipOpen4}
                                                            //     >
                                                            //         <img alt="#" src={require("../../../assets/images/i_icon.png")} />
                                                            //     </IconButton>
                                                            // </Tooltip>
                                                            // </ClickAwayListener>
                                                            //     </InputAdornment>,
                                                            // }}
                                                            {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6} className="squarepayment">
                                            <Typography>On Your statement the charges will appear as:</Typography>
                                            <br />
                                            <Typography>MYFREESCORENOW.COM<br />
                                                (888) 548-2008</Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} >
                                                <Grid item lg={6} md={6} sm={6} xs={6} sx={{ justifyContent: "right", display: "flex" }}>
                                                    <Button variant="contained" color="warning" onClick={() => setOpenPaymentMethod(false)} sx={{ backgroundColor: "#ED7E2E", color: "white", borderRadius: "3px" }}>
                                                        Back</Button>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={6} sx={{ justifyContent: "right", display: "flex" }}>
                                                    <Button
                                                        // disabled={orderDisabled}
                                                        onClick={() => { setOpenReview(true); setOpenPaymentMethod(false) }} variant="contained" color="success" sx={{
                                                            backgroundColor: "#5cb85c",
                                                            color: "#fff", borderRadius: "3px"
                                                        }}>
                                                        Continue
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <SecureWebsite />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            )}
                        </Formik>
                        {/* formik code ends here */}
                    </Dialog>

                    <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={openReview}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                            <Stack direction="row" justifyContent={'space-evenly'}>
                                <Grid item >
                                    <img alt="#" src={require("../../assets/images/myfreescorenowlogo.jpg")} width="50%" height="auto" />
                                </Grid>
                                <Grid item sx={{ textAlign: "center" }}>
                                    <Grid><Typography level="h4">Review Order</Typography></Grid>

                                </Grid>
                                <Grid item sx={{ textAlign: "center" }}>
                                    <Typography px={2} id="timeExpReview" className="text-center" style={{ fontSize: '1.3em', backgroundColor: 'red', color: 'white' }} ></Typography>
                                </Grid>
                                <Grid item >
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setOpenReview(false)}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Stack>
                        </DialogTitle>
                        <DialogContent p={2}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Table borderAxis="none">
                                    <tr>
                                        <td><Typography level="h5">Item</Typography></td>
                                        <td><Typography level="h5">Price</Typography></td>
                                    </tr>
                                    <tr>
                                        <td>Your Banner Image cost </td>
                                        <td>$89</td>
                                    </tr>
                                    <hr />
                                    <tr>
                                        <td><Typography level="h5">TOTAL:</Typography></td>
                                        <td><Typography level="h5">$89</Typography></td>
                                    </tr>
                                </Table>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'center' }}>
                                <Button variant="contained" color="success" sx={{
                                    backgroundColor: "#5cb85c",
                                    color: "#fff", borderRadius: "3px"
                                }}
                                    onClick={() => { setOpenReview(false); SetSuccessfulPayment(true); setOpenPaymentPopup(false) }}

                                >
                                    Pay Now
                                </Button>
                            </Grid>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={successfulPayment}

                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                            <Stack direction="row" justifyContent={'space-evenly'}>
                                <Grid item sx={{ textAlign: "center" }}>
                                    <Grid><Typography level="h4">Thank You for purchasing Banner!</Typography></Grid>
                                </Grid>
                                <Grid item >
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => SetSuccessfulPayment(false)}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Stack>
                        </DialogTitle>

                    </Dialog>
                </div>
                {
                    imgSrc &&
                    <Dialog
                        open={openImgPopup}
                        fullWidth
                        maxWidth={'sm'}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                setOpenImgPopup(false);
                            }
                        }}
                    >
                        <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                            <Typography level="h4" sx={{ textAlign: "center" }}>Crop the selected Image</Typography>
                            <IconButton
                                aria-label="close"
                                onClick={getPopupClosed}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={{ margin: 'auto', marginTop: '10px' }} >
                            {imgSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(crop, percentCrop) => setCrop(crop)}
                                    onComplete={(c) => setCompletedCrop(c)}

                                // aspect={gridAspectRatio}
                                // maxWidth={gridWidth}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            )}
                        </DialogContent>
                        <DialogActions sx={{ margin: 'auto' }}>

                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={getCroppedImg} color="success" sx={{ minWidth: 250 }}>
                                    Apply
                                </Button>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                }

            </Grid >
        </>

    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
        </div>
    );
}
export default CobrandIndex;