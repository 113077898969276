import React, { useMemo, useState, useEffect } from 'react';
import AuthUser from "../../../Components/Auth/AuthUser";
import { Alert,OutlinedInput,  Grid, Button, Dialog, DialogActions, FormControl, Box, InputLabel, Select, MenuItem, DialogContent, DialogTitle, Tooltip,TableRow, TableBody, Table, TableCell, Avatar } from '@mui/material';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import MaterialReactTable from 'material-react-table';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Chart } from "react-google-charts";
import { Typography,Card, CardContent,CardOverflow  } from '@mui/joy';
import CheckIcon from '@mui/icons-material/CheckBoxOutlined';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import config from '../../../AppConfig';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { Link } from 'react-router-dom';
import DialogContentText from '@mui/material/DialogContentText';
import { DateRangePicker } from 'react-date-range';
import { format, subDays, isAfter } from 'date-fns';
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    input: {
        display: "none",
    },
    uploadButton: {
        marginTop: theme.spacing(2),
        backgroundColor: "#2A68B3",
        color: "white",
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const UploadCsv = () => {
    const { http, httpFormData, token } = AuthUser();
    const { user } = AuthUser();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [file, setFile] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');
    const [suspendeddata, setData] = useState([]);
    const [availablePids, setAvailablePids] = useState([]);
    const [disable, setDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState('');
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [freez, setFreez] = React.useState(true);
    const [assignedToPid, setAssignedTo] = useState('all');
    const [adminList, setAdminList] = React.useState([]);
    const[assignedToSource,setAssignedToSource]= useState('all') 
    const loginUser = JSON.parse(localStorage.getItem('user'));
    const [openDeletepopup, setDeleteOpen] = useState(false);
    const [pidDelete, setDeleteId] = useState();
    const [pidGraph, setGraphData] = useState([]);
    const [totalPidUploaded, setTotalPidUploaded] = useState([]);
    const [totalPidAssigned, setTotalPidAssigned] = useState([]);
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: subDays(new Date(), 6),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [startDate, setStartDate] = useState(format(selectionRange[0].startDate, 'dd-MM-yyyy'));
    const [endDate, setEndDate] = useState(format(selectionRange[0].endDate, 'dd-MM-yyyy'));
    useEffect(() => {
        setStartDate(format(selectionRange[0].startDate, 'dd-MM-yyyy'))
        setEndDate(format(selectionRange[0].endDate, 'dd-MM-yyyy'))
    }, [selectionRange]);

   const options1 = {
    colors: ["#2A68B3", "#76C044"],
    seriesType: "bars",
    legend: { position: 'none' },
    series: {
        0: { type: "bars" },
        1: { type: "bars" }
    },
    hAxis: {
        textStyle: {
            fontSize: 10 // Adjust the font size as needed
        },
        format: '####'
    },
    vAxis: {
        minValue: 0, // Set minimum value to 0
        viewWindow: {
            min: 0,
            max: Math.max(...pidGraph.slice(1).map(row => row[1])) * 1.3 // Increase max value by 20%
        }
    },
    focusTarget: 'category',
    tooltip: { isHtml: true },
    bar: { groupWidth: "50%" },
    chartArea: {
        width: "80%", // Adjust as needed
    },
    annotations: {
        alwaysOutside: true,
        textStyle: {
            fontSize: 14,
            auraColor: 'none',
            color: '#555',
        },
    },
};

    let pidValue = 'all';
    let statusVal = 'all';

    const navigate = useNavigate();

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const tablePreferencesArray = {

    }
    const fetchBasePlanList = async () => {
        const apiUrl = '/poolPid/fetchBasePlan'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
          setAdminList(res.data);
        }
    }
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value; // Get the selected value from event
        setAssignedTo(selectedValue); // Set the selected value to state  
        pidValue = selectedValue;
        statusVal= assignedToSource;
        fetchSuspendedReport();
    
    }
    const handleSourceChange = (event) => {
        const selectedValue1 = event.target.value; // Get the selected value from event
        setAssignedToSource(selectedValue1); // Set the selected value to state  
        statusVal = selectedValue1;
        pidValue = assignedToPid;
        fetchSuspendedReport();
    };

    const fetchSuspendedReport = async page => {
        setFreez(true);
        const apiUrl = '/getPoolPid';
        const args = {
            basePid: pidValue,
            status: statusVal,
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res.success == true){
            setData(res.data.poolPidData)
            setAvailablePids(res.data.availablepids)
            setIsLoading(false)
            handleFreezStop();
        }else{
            setErrorMessage(" went wrong.")
            handleFreezStop();
        }
        
    }
    useEffect(() => {
        if (user.roles_id < 3 || user.roles_id == 12 || user.roles_id == 13) {
            fetchSuspendedReport();
            fetchBasePlanList();
            getPoolPid();
        } else {
            navigate('/login');
        }

    }, [])

    const handleFileChange = (event) => {
        setSelectedFileName('')
        setFile('');
        const file = event.target.files[0]; // Get the first file from the array

        // Check if a file is selected
        if (file) {
        const fileSizeInBytes = file.size;
        const fileSizeInKB = fileSizeInBytes / 1024; // Convert bytes to KB
        const fileSizeInMB = fileSizeInKB / 1024; // Convert KB to MB
        if(fileSizeInMB.toFixed(2) > 5){
            alert('Number Of Rows Exceeded')
            return false;
        }
        // You can perform actions based on the file size here
        }
        setFile(event.target.files[0]);

        setSelectedFileName(event.target.files[0].name?event.target.files[0].name:'');
    };
    useEffect(() => {
    }, [rowSelection]);
    ////////////////////////
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
    }, [rowSelection]);

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);
    ////////////////////////

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            http.post('/saveTablePreferences', {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'poolpid'
            }).then((result) => {
            })
        }
    }, [columnVisibility])
    const tablePreferences = () => {
        http.get('/fetchTablePreferences/poolpid')
            .then((res) => {
                if (Object.values(res.data).length == 0) {
                    setColumnVisibility(tablePreferencesArray);
                } else {
                    setColumnVisibility((JSON.parse(res.data.preferences)));
                }
            });
    }

    const handleUpload = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3 && loginUser.roles_id != 12 && user.roles_id == 13) {
        // if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        // setIsLoading(true);
        setFreez(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("csvFile", file)
        httpFormData.post("/createPoolPid", formData)
            .then((res) => {
                if (res.data.success === false) {
                    setErrorMessage(res.data.data['message'])
                    
                    handleFreezStop();
                    if(res.data.data['invalidPidCount'] > 0){
                        setErrorMessage('Invalid PID: ' + res.data.data['invalidPidCount']);
                    }
                    if(res.data.data['count'] > 0){
                        setErrorMessage(res.data.data['messageDuplicatePid'])
                    }
                   
                    setFile('')
                    setSelectedFileName('')
                    setAssignedTo('all')
                    setAssignedToSource('all')
                    setTimeout(function () {
                        setErrorMessage('')
                        setDisable(false)
                    }, 6000);
                    fetchSuspendedReport();
                } else {
                    console.log(res.data.data['message'])
                    setErrorMessage('')
                    setFile('')
                    setSelectedFileName('')
                    setAssignedTo('all')
                    setAssignedToSource('all')
                    handleFreezStop();
                    setSuccessMessage(res.data.data['message'])
                    if(res.data.data['invalidPidCount'] > 0){
                        setErrorMessage('Invalid PID: ' + res.data.data['invalidPidCount']);
                    }
                    if(res.data.data['count'] > 0){
                        setErrorMessage(res.data.data['messageDuplicatePid'])
                    }
                   
                    setTimeout(function () {
                        setSuccessMessage('')
                        setErrorMessage('')
                        setDisable(false)
                    }, 3000);
                    fetchSuspendedReport();
                }
                // Handle successful upload response
            })
            .catch((error) => {
                // Handle upload error
                console.error(error);
            });
    };

    const getPoolPid = async () => { 
        handleFreezStart();
        const apiUrl = '/pidCount';
        const args = {
            fromDate: startDate,
            toDate: endDate,
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        setIsLoading(false);
        handleFreezStop();
        setGraphData(res.data.graphData);
        setTotalPidUploaded(res.data.totalPidUploaded);
        setTotalPidAssigned(res.data.totalPidAssigned);
        // setAbandonedTotalData(res.data.abandonedtotal);
        
    }

    const customSortFunction = (rowA, rowB) => {
        const statusFlag = {
            0: 0,
            1: 1,
        };
        const flagA = rowA.original.status;
        const flagB = rowB.original.status;
        const priorityA = statusFlag[flagA];
        const priorityB = statusFlag[flagB];
        return priorityA - priorityB;
    };
    const showActionColumn = loginUser.roles_id === 13;

    const columns = useMemo(
        //column definitions...
        () => [
            {

                accessorFn: (data) => `${data.plan_type ? data.plan_type.trim() : ''}`,
                id: 'Plan Name',
                header: 'Plan Name',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (<>{row.original.plan_type ? row.original.plan_type.trim() : 'N/A'}</>),
            },
            {
                accessorFn: (data) => `${data.base_pid ? data.base_pid : ''}`,
                id: 'Base Pid',
                header: 'Base Pid',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (<>{row.original.base_pid ? row.original.base_pid : 'N/A'}</>),
            },
            {
                accessorFn: (data) => `${data.assigned_pid ? data.assigned_pid : 'N/A'}`,
                id: 'Assigned Pid',
                header: 'Assigned Pid',
                size: 50,
            },
            {
               
                accessorFn: (data) => `${(data.aid_master) ? (data.aid_master.aid) ? data.aid_master.aid.trim() : '' : ''}`,
                id: 'Aid',
                header: 'Aid',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (<>{(row.original.aid_master) ? (row.original.aid_master.aid) ? row.original.aid_master.aid.trim() : 'N/A' : 'N/A'}</>),
            },
            {
                accessorFn: (data) => <>
                    {`${data.status}` == 1 ? <Tooltip title="PID Assigned" arrow><CheckIcon color="success" /></Tooltip> : <Tooltip title=" PID Not Assigned." arrow><CheckIcon color="disabled" /></Tooltip>}
                </>,
                id: 'Status',
                header: 'Status',
                size: 50,
                sortingFn: customSortFunction,
            },
            ...(showActionColumn ? [{
                accessorFn: (data) => (
                    loginUser.roles_id == 13 && data.status == 1 ? (
                        <Tooltip title="Delete" arrow>
                            <Link className='btn btn-success text-white' onClick={() => deletePid(data.assigned_pid)}>
                                <img src={require('../../../assets/images/delete.png')} />
                            </Link>
                        </Tooltip>
                    ) : ''
                ),
                id: 'Action',
                header: 'Action',
                size: 20,
            }] : []),
        ]
    );

    
    //csv option start here
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Pid Pool',
        headers: ['Plan Name', 'Base Pid', 'Assigned Pid', 'Aid', 'Status'],
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportData = (suspendeddata) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3 && loginUser.roles_id != 12 && loginUser.roles_id != 13) {
            navigate('/login');
            return false;
        }
        const exportedData = suspendeddata.map((row) => {

            return {
                'Plan Name': row.original.plan_type || 'N/A',
                'Base Pid': row.original.base_pid || 'N/A',
                'Assigned Pid': row.original.assigned_pid || 'N/A',
                'Aid': row.original.aid_master?.aid || 'N/A',
                'Status': `${row.original.status === 1 ? 'PID Assigned' : 'PID Not Assigned'}`,

            };
        });

        const csvContent = convertToCSV(exportedData);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    const convertToCSV = (data) => {
        const headers = csvOptions.headers.join(',') + '\n';

        const rows = data.map((row) => {
            return Object.values(row).map((value) => {
                // Handle any necessary formatting or escaping of values
                // For simplicity, we assume all values are already properly formatted
                return value;
            }).join(',');
        }).join('\n');

        return headers + rows;
    };
    //csv end here

    const downloadCSV = () => {
        http.get('/fetch/downloadPoolPidCSV', {
        }).then((res) => {
            const blob = new Blob([res.data], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Prebuilts.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            setErrorMessage(" went wrong.")
            // setIsLoading(false)
            handleFreezStop();
        });
      };

    const deletePid = async (pid) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id != 13 ) {
            window.location.href= config.BASE_URL + 'login' ;
            return false;
        }
		setDeleteId(pid);
		setDeleteOpen(true);
	}  
    const closeDeletePlan = () => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id != 13 ) {
            navigate('/login');
            return false;
        }
		setDeleteOpen(false)
	}
    const deleteCobrandPlan = async () => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id != 13 ) {
            navigate('/login');
            return false;
        }
		handleFreezStart();
		setDeleteOpen(false);
		const apiUrl = '/pid-Delete/' + pidDelete;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if (res) {
			if (res.success) {
				await fetchSuspendedReport();
				handleFreezStop();
                setSuccessMessage(res.message)
				setTimeout(() => {
					setSuccessMessage('');
				}, 3000);
			}
		}
	}

    const ContentLayout=
  
            <>
                {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                
                <Grid container item lg={12} md={12} xs={12}>
                    <Typography color="primary" level="h4"> Pid Pool</Typography>
                </Grid>
                <Grid mb={2} mt={2}>
                <Card sx={{ width: "100%",border: '1px solid lightgray'}} >
                 <CardContent>
                    <Grid container item lg={12} md={12} xs={12} sm={12} sx={{display:'flex'}} spacing={1}>
                    <Grid item lg={4} md={4} xs={12} sm={12} sx={{display:'flex',gap:'20px'}}>
                    <Grid >
                        <Typography level="h6" sx={{ fontWeight: "bold",MarginRight:'2%'}} pb={3}>Refer sample csv to upload PIDs</Typography>
                        <Button variant="contained" color="primary" onClick={downloadCSV}>
                            Sample CSV
                        </Button>
                    </Grid>
                
                    </Grid>
                    <Grid item lg={5} md={5} xs={12} sm={12} sx={{display:'flex'}}>
                        <Typography level="h6" sx={{ fontWeight: "bold",paddingRight:'8px'}}pb={3}> Upload file here</Typography>
                        
                        <Box sx={{display:'flex',gap:'27px'}}>
                        <Grid >
                           
                                <input
                                    accept=".csv"
                                    className={classes.input}
                                    id="csv-file"
                                    type="file"
                                    onChange={handleFileChange}
                                    onClick={(event) => event.target.value = null}
                                    sx={{marginTop: "0%"}}
                                />
                                <label htmlFor="csv-file" className='upload-csv'>
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                        className={classes.uploadButton}
                                        color='primary'
                                        sx={{marginTop: "0%"}}
                                    >
                                        Choose File
                                    </Button>
                                </label>
                                <br/>
                                <Typography>{selectedFileName}</Typography>
                           
                        </Grid>
                        <Grid >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleUpload}
                                    disabled={!file}
                                    className={classes.uploadButton}
                                >
                                    Upload
                                </Button>
                        </Grid>
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={3} xs={12} sm={12} >
                    <Typography level="h6">Note: Expected file format: .csv</Typography>
                    <Typography level="h6">Note: Maximum number of rows/PIDs should be: 100</Typography>
                
                    </Grid>
                    </Grid>
                 </CardContent>
                </Card>
                <Card>
                    <CardContent>
                    <Grid container item lg={12} md={12} xs={12}>
                        <Typography color="primary" level="h4"> Available PID</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} xs={12}>
                        <Typography color="primary" level="h4"> 
                        {availablePids.map((array) => (
                            <>
                          
                                <span style={{color:"black"}}>{array.base_pid} : </span>{array.available} &nbsp;&nbsp;&nbsp;
                                </>
                           
                        ))}
                                
                        </Typography>
                    </Grid>
                    </CardContent>
                </Card>
                </Grid>


                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                <Grid item lg={3} md={3} sm={12} xs={12} mb={2}>
                    <FormControl fullWidth margin='dense' size="small">
                    <InputLabel id="assignedto">Base Pid</InputLabel>
                    <Select
                        labelId="assignedto"
                        name="assignedto"
                        input={<OutlinedInput label="Base Pid" />}
                        value={assignedToPid}
                        onChange={handleSelectChange}
                        MenuProps={MenuProps}
                        size='small'
                    >
                        <MenuItem
                        value='all'
                        >
                        All
                        </MenuItem>
                        {adminList.map((array) => (
                        <MenuItem
                        key={array.base_pid} // Ensure each MenuItem has a unique key
                            value={array.base_pid}
                        >
                            {array.base_pid}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
            
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <FormControl fullWidth size='small' margin='dense'>
                    <InputLabel id="assignedToSource">Status</InputLabel>
                    <Select
                        labelId="assignedToSource"
                        value={assignedToSource}
                        onChange={handleSourceChange}
                        MenuProps={MenuProps}
                        input={<OutlinedInput label="Status" />}
                    >
                        <MenuItem key="Action" value="all" >
                        All
                        </MenuItem>
                        <MenuItem key="1" value="1">
                        Allocated
                        </MenuItem>
                        <MenuItem key="0" value="0">
                        Available
                        </MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
                </Grid>
                <Dialog
                        open={openDeletepopup}
                        onClose={(_, reason) => {
                            if (reason !== "backdropClick") {
                                setDeleteOpen(false)
                            }
                            }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Delete Pid</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this Pid?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closeDeletePlan} >
                            Cancel
                        </Button>
                        <Button onClick={deleteCobrandPlan}  autoFocus style={{ backgroundColor: 'red', color: 'white',minWidth: 100 }}>
                            Delete
                        </Button>
                        </DialogActions>
                </Dialog>
                
                
                <Box mt={2}>
                    <MaterialReactTable
                        columns={columns}
                        data={suspendeddata}
                        enableColumnFilterModes
                        enableRowSelection
                        filterFns={{
                            customSearchFilterFn: (row, id, filterValue) =>
                            row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                        }}                                   
                        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
                        initialState={{ showColumnFilters: false, density: 'compact' }}
                        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                        enableColumnFilters={config.DatatableColumnFilter}
                        positionToolbarAlertBanner="bottom"
                        enableDensityToggle={false}
                        muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                                backgroundColor: "#F6FAFF",
                            },
                        }}
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                <Button
                                    color="primary"
                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                    //export all rows, including from the next page, (still respects filtering and sorting)
                                    onClick={() =>
                                        handleExportData(table.getPrePaginationRowModel().rows)
                                    }
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    CSV
                                </Button>
                               
                            </Box>
                        )}

                    />

                </Box>
                
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Card className='cardstyle' sx={{ minHeight: 430, maxHeight: 300 }}>
                    <CardOverflow
                sx={{
                    bgcolor: '#F6FAFF',
                    borderColor: '#F6FAFF',
                    padding: '1%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Last 7 days PIDs Uploaded and Assigned
                </Typography>
            </CardOverflow>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ padding: "2% 2% 2% 2%", verticalAlign: 'top' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '16px' }}>
                                        <Avatar sx={{ bgcolor: '#2A68B3', marginRight: '8px' }}>{totalPidUploaded}</Avatar>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Pid Uploaded</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '16px' }}>
                                        <Avatar sx={{ bgcolor: '#76C044', marginRight: '8px' }}>{totalPidAssigned}</Avatar>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Pid Assigned</Typography>
                                    </Box>
                                    </TableCell>

                                    <TableCell className="borderStyle" sx={{ padding: "2% 2% 2% 2%" }}>
                                        <Chart
                                            chartType="ComboChart"
                                            width="100%"
                                            height="300px"
                                            data={pidGraph}
                                            options={options1}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Card>
                </Grid>

            </>
     

    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
        </div>
    );

};

export default UploadCsv;
