//UI imports starts
import React, { useState, useEffect, useRef } from "react";
import { Box, Select, FormControl, MenuItem, Grid, TextField, InputLabel, Divider, OutlinedInput, FormHelperText,InputAdornment, IconButton, } from '@mui/material';
import { Typography, Card, CardContent, Button } from '@mui/joy';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
//UI imports ends
//functional imports starts
import { Formik, Form } from 'formik';
import AuthUser from "../../../Components/Auth/AuthUser";
import swal from "sweetalert";
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import OfferDetails from "../../../Components/Enrollment/OfferDetails";
import SecureWebsite from "../../../Components/Enrollment/SecureWebsite";
import { parse, isBefore, subYears } from 'date-fns';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    return formattedDate;
};


//functional imports ends
function Enrollment2({ onValueChange, props }) {
    const dispatch = useDispatch();
    const { http } = AuthUser();
    const [states, setStates] = useState([]);
    const inputRef1 = useRef(null);
    const [errorfromcd, seterrorfromcd] = useState();
    const [fullssn, setFullSsn] = useState(false);
    const [ssnlabel, setssnlabel] = useState("Last Four Digits of SSN*");
    const aid = localStorage.getItem('aid');
    const pid = localStorage.getItem('pid');
    const [ssnFlag, setSsnFlag] = useState(false);
    const [ssnError, setSsnError] = useState(false);
    const [isDateValid, setDateValid] = useState(true);
    const [isValidFormat, setIsValidFormat] = useState(true);
    const [lessthanErrorMsg, setLessthanErrorMsg] = useState("");
    const [dateFormatErrorMsg, setDateFormatErrorMsg] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [BlackboxObj, setBlackboxObj] = useState([]);

    // var BlackboxObj;
    // document.addEventListener('DOMContentLoaded', function () {
    //     BlackboxObj = window.IGLOO ? window.IGLOO.getBlackbox() : '';
    // });
    // let BlackboxObj = window.IGLOO ? window.IGLOO.getBlackbox():'';
    // console.log("blackbox",BlackboxObj.blackbox);
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end

    //enrollment step 2 function start
    const MainErollStep2 = (values) => {
        // setButtonDisabled(true);
        if (values.DateofBirth1 == "" || values.DateofBirth1 == undefined || values.DateofBirth1 == null) {
            setButtonDisabled(false);
            swal({
                title: "Alert",
                text: "Date of Birth is required",
                icon: "error",
                button: "Ok",
            }).then((ok) => {
                if (ok) {
                    inputRef1.current?.focus();
                }
            });
            return null;

        }
        var submitFlag = false;
        if (ssnlabel == "Full SSN*" && (!(values.SocialSecurityNumber.match(/^\d{9}$/)))) {
            setButtonDisabled(false);
            setSsnError("Full 9 digit of SSN reqired");
            setSsnFlag(true);
            submitFlag = true;
        } else if (ssnlabel == "Last Four Digits of SSN*" && (!(values.SocialSecurityNumber.match(/^\d{4}$/)))) {
            setButtonDisabled(false);
            setSsnError("Only last 4 digit of SSN reqired");
            setSsnFlag(true);
            submitFlag = true;
        } else {
            setSsnFlag(false);
        }

        if (submitFlag == true) {
            return false;
        }

        if(isDateValid == false || isValidFormat == false) {
            return false;
        }
        handleFreezStart();
        setButtonDisabled(true);
        var mobile = values.MobileNumber.replace(/[^0-9]/g, '');
        http.post("/registerEnrollment", {
            streetAddress: values.StreetAddress,
            city: values.City,
            state: values.State,
            phoneNumber: mobile,
            dob: formatDate(values.DateofBirth1),
            ssn: values.SocialSecurityNumber,
            zip: values.ZipCode,
            firstName: localStorage.getItem('Main_firstName'),
            middlename: localStorage.getItem('Main_middleName'),
            lastName: localStorage.getItem('Main_lastName'),
            email: localStorage.getItem('Main_email'),
            password: localStorage.getItem('Main_password'),
            source: "default",
            pid: pid,
            aid: aid,
            trackingToken: localStorage.getItem('trackingToken'),
            customerToken: localStorage.getItem('customerToken'),
            fullSSN: fullssn,
            blackboxCode : BlackboxObj ? BlackboxObj.blackbox : "", 
            step: 2,
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
                    onValueChange(res.data.data.nextstep);
                } else {
                    seterrorfromcd(res.data.data.errorByCD[0].message);
                    if (res.data.data.fullSSNRequired === true) {
                        setButtonDisabled(false);
                        setFullSsn(true);
                        setssnlabel("Full SSN*");
                    } else {
                        setButtonDisabled(false);
                        setFullSsn(false);
                        setssnlabel("Last Four Digits of SSN*");
                    }
                    let firstStep=props;
                    res.data.data.errorByCD.forEach(function (errorByCD) {      
                        if(errorByCD.code === "EXCEEDED_MAX_TRIES"){
                            firstStep = 1;
                            swal({
                                title: "Failed",
                                text: errorByCD.message,
                                icon: "error",
                                button: "Ok",
                            });                            
                        }                  
                    });
                    onValueChange(firstStep?firstStep:props);
                }
            })
            .catch((error) => {
                handleFreezStop();
                setButtonDisabled(false);
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    //enrollment step 2 function end
    //validation schema starts
    const validationSchema = Yup.object().shape({
        StreetAddress: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'"/\s]*$)/,
                'Please enter a valid Street Address'
            )
            .min(1, 'Too Short!')
            .required('Street address is required'),
        City: Yup.string()
            .required('City is required'),
        State: Yup.string()
            .required('Please select state'),
        ZipCode: Yup.string()
            .min(5, 'ZipCode must be of 5 digits!')
            .max(5, 'ZipCode must be of maximum 5 digits!')
            .required('Zipcode is required'),
        MobileNumber: Yup.string()
            .min(14, 'Mobile number must be of 10 digits!')
            .max(14, 'Mobile number must be of maximum 10 digits!')
            .required('Mobile number is required'),

        SocialSecurityNumber: Yup.string()
            .max(9, 'SSN must be of maximum 9 digits!')
            .matches(
                /^(?=[0-9\-\. ]*$)/,
                'only digits are allowed'
            )
            .required('Social Security Number is required'),

        DateofBirth1: Yup.string()
            .required('Please enter Date of Birth'),
    });
    //validation schema ends
    //useeffect to fetch state array starts
    const fetchState = () => {
        handleFreezStart();
        http.get("/fetchStates").then((res) => {
            handleFreezStop();
            setStates(res.data.data);
        });
    }
    useEffect(() => {
        fetchState();
        if (document.readyState !== 'loading') {
            var BlackboxObj1 = window.IGLOO ? window.IGLOO.getBlackbox() : '';
            setBlackboxObj(BlackboxObj1);
        } else {
            document.addEventListener('DOMContentLoaded', function () {
                var BlackboxObj1 = window.IGLOO ? window.IGLOO.getBlackbox() : '';
                setBlackboxObj(BlackboxObj1);
            });
        }
        console.log("BlackboxObj: "+ JSON.stringify(BlackboxObj));
    }, [])
    //useeffect to fetch state array end
    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;
        // console.log(value)

        // keep minus if entered by user
        if (value === '(') {
            value = value.slice(0, -1);
        }


        return {
            value,
            selection
        };
    }
    const handleCallback = (data) => {
        seterrorfromcd(data);
    }
    const handleFreezCallback = (data) => {
        setFreez(data);
    }

    const handleDateChange = (event) => {
        const { value } = event.target;
        // setInputDate(value);

        //check date format
        const regexPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        const checkformat = regexPattern.test(value);
        if (checkformat) {
           
            const [month, day, year] = value.split('/');
            const parsedDate = new Date(`${year}-${month}-${day}`);

            // Check if the parsed date is valid
            const isValidDate = !isNaN(parsedDate) && parsedDate.toISOString().slice(0, 10) === `${year}-${month}-${day}`;
            setIsValidFormat(isValidDate);
            if(!isValidDate) {
                setDateFormatErrorMsg("The birth date must be formatted as MM/DD/YYYY");
                return;
            }
        
        } else {
            // console.log("checkformat in else: " + checkformat);
            setIsValidFormat(false);
            setDateFormatErrorMsg("The birth date must be formatted as MM/DD/YYYY");
            return ;
        }
        //check less than 18 years old
        const inputDateParsed = parse(value, 'MM/dd/yyyy', new Date());
        const eighteenYearsAgo = subYears(new Date(), 18);
        const isValid = isBefore(inputDateParsed, eighteenYearsAgo);
      const currentDate = new Date();
        setDateValid(isValid);
        if(currentDate<=inputDateParsed){
            setLessthanErrorMsg("Invalid date of birth");
        }
        else if(!isValid) {
            setLessthanErrorMsg("You must be at least 18 to be a member");
        }else{
            setLessthanErrorMsg("");
        }
      };
    //fetch city and state from zipcode
    const fetchLocationInfo = async (zipcode, setFieldValue) => {
        const apiUrl = "/zipcode/"+zipcode;
        setFieldValue('City','');
        setFieldValue('State','');
        if(zipcode.length == 5)
        {
            setFieldValue('City','...');
            setFieldValue('State','...');
            handleFreezStart();
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res.success)
            {
                setFieldValue('City', res.data.places[0]['place name']);
                setFieldValue('State', res.data.places[0]['state abbreviation']);
                handleFreezStop();
            }
            else
            {
                setFieldValue('City','');
                setFieldValue('State','');
                handleFreezStop();
            }
        }
    };
    return (
        <>
            <Box p={4}>
                <Formik
                    initialValues={{
                        StreetAddress: '',
                        StreetAddress2: '',
                        City: '',
                        State: '',
                        ZipCode: '',
                        MobileNumber: '',
                        DateofBirth: '',
                        DateofBirth1: '',
                        SocialSecurityNumber: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        // Handle form submission logic here
                        setSubmitting(false);
                        MainErollStep2(values)
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <Form>
                            <Card variant="outlined">
                                <CardContent className="enrollment-form-padding">
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                {/* <TextField
                                                    id="StreetAddress"
                                                    name="StreetAddress"
                                                    label="Street Address*"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    error={touched.StreetAddress && !!errors.StreetAddress}
                                                    helperText={touched.StreetAddress && errors.StreetAddress}
                                                    value={values.StreetAddress}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                                <FormControl variant="outlined" fullWidth
                                                        margin="dense" size="small">
                                                        <InputLabel>Street Address*</InputLabel>
                                                        <OutlinedInput
                                                            id="StreetAddress"
                                                            name="StreetAddress"
                                                            label="Street Address*"
                                                            error={touched.StreetAddress && !!errors.StreetAddress}
                                                            helperText={touched.StreetAddress && errors.StreetAddress}
                                                            value={values.StreetAddress}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoComplete="StreetAddress"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                    >
                                                                        {touched.StreetAddress && !errors.StreetAddress ? (
                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            touched.StreetAddress && errors.StreetAddress ? (
                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }

                                                        />
                                                        <FormHelperText style={{ color: '#d32f2f' }}>
                                                            {touched.StreetAddress && errors.StreetAddress}
                                                        </FormHelperText>
                                                    </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                {/* <TextField
                                                    id="ZipCode"
                                                    name="ZipCode"
                                                    label="Zip Code*"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    error={touched.ZipCode && !!errors.ZipCode}
                                                    helperText={touched.ZipCode && errors.ZipCode}
                                                    value={values.ZipCode}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                                <InputMask
                                                    mask='99999'
                                                    maskChar={null}
                                                    id="ZipCode"
                                                    name="ZipCode"
                                                    label="Zip Code*"
                                                    variant="outlined"
                                                    autoComplete="ZipCode"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    error={touched.ZipCode && !!errors.ZipCode}
                                                    helperText={touched.ZipCode && errors.ZipCode}
                                                    value={values.ZipCode}
                                                    //onChange={handleChange}
                                                    onChange={(e) => {
                                                     handleChange(e);
                                                     fetchLocationInfo(e.target.value, setFieldValue);
                                                     }}
                                                     onBlur={handleBlur}
                                                >
                                                     {(inputProps) =>
                                                        <TextField
                                                            label="Phone Number*" variant="outlined"
                                                            {...inputProps}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end">
                                                                        {touched.ZipCode && !errors.ZipCode ? (
                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            touched.ZipCode && errors.ZipCode ? (
                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    }
                                                </InputMask>
                                                 {/* <FormControl variant="outlined" fullWidth
                                                        margin="dense" size="small">
                                                        <InputLabel>Zip Code*</InputLabel>
                                                        <OutlinedInput
                                                           id="ZipCode"
                                                           name="ZipCode"
                                                           label="Zip Code*"
                                                           variant="outlined"
                                                           size="small"
                                                           fullWidth
                                                           margin="dense"
                                                           error={touched.ZipCode && !!errors.ZipCode}
                                                           helperText={touched.ZipCode && errors.ZipCode}
                                                           value={values.ZipCode}
                                                           //onChange={handleChange}
                                                           onChange={(e) => {
                                                            handleChange(e);
                                                            fetchLocationInfo(e.target.value, setFieldValue);
                                                            }}
                                                            onBlur={handleBlur}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                    >
                                                                        {touched.ZipCode && !errors.ZipCode ? (
                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            touched.ZipCode && errors.ZipCode ? (
                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                        <FormHelperText style={{ color: '#d32f2f' }}>
                                                            {touched.ZipCode && errors.ZipCode}
                                                        </FormHelperText>
                                                    </FormControl> */}
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                {/* <TextField
                                                    id="City"
                                                    name="City"
                                                    label="City*"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    error={touched.City && !!errors.City}
                                                    helperText={touched.City && errors.City}
                                                    value={values.City}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                                <FormControl variant="outlined" fullWidth
                                                        margin="dense" size="small">
                                                        <InputLabel>City*</InputLabel>
                                                        <OutlinedInput
                                                            id="City"
                                                            name="City"
                                                            label="City*"
                                                            autoComplete="City"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                    >
                                                                        {touched.City && !errors.City ? (
                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            touched.City && errors.City ? (
                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            error={touched.City && !!errors.City}
                                                            helperText={touched.City && errors.City}
                                                            value={values.City}
                                                            onChange={(e) => {
                                                                // allow only alphanumeric characters
                                                                const inputValue = e.target.value;
                                                                const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;
                                                                if (alphanumericRegex.test(inputValue)) {
                                                                    handleChange(e);
                                                                }
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        <FormHelperText style={{ color: '#d32f2f' }}>
                                                            {touched.City && errors.City}
                                                        </FormHelperText>
                                                    </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <FormControl fullWidth size='small' margin="dense"
                                                    error={touched.State && errors.State ? errors.State : null}>
                                                    <InputLabel id="demo-multiple-name-label">State*</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        name="State"
                                                        error={touched.State && !!errors.State}
                                                        helperText={touched.State && errors.State}
                                                        value={values.State}
                                                        autoComplete="State"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        id="State"
                                                        input={<OutlinedInput label="State*" />}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {states.map((state, id) => {
                                                            return (
                                                                <MenuItem value={state.state_code}>{state.state_name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                    {touched.State && errors.State ? <FormHelperText>Please select State.</FormHelperText> : null}

                                                </FormControl>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                {/* <TextField
                                                    id="MobileNumber"
                                                    name="MobileNumber"
                                                    label="Mobile Number*"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    error={touched.MobileNumber && !!errors.MobileNumber}
                                                    helperText={touched.MobileNumber && errors.MobileNumber}
                                                    value={values.MobileNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                                {/* main mobile number start here */}
                                                <InputMask
                                                    // mask="(#00) 000-0000"
                                                    beforeMaskedValueChange={beforeMaskedValueChange}
                                                    mask='(999) 999-9999'
                                                    maskChar={null}
                                                    name='MobileNumber'
                                                    fullWidth
                                                    margin="dense"
                                                    autoComplete="MobileNumber"
                                                    size="small"
                                                    id='MobileNumber'
                                                    error={touched.MobileNumber && !!errors.MobileNumber}
                                                    helperText={touched.MobileNumber && errors.MobileNumber}
                                                    value={values.MobileNumber}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label="Phone Number*" variant="outlined"
                                                            {...inputProps}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end">
                                                                            {touched.MobileNumber && !errors.MobileNumber ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.MobileNumber && errors.MobileNumber ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    }
                                                </InputMask>
                                                {/* main mobile number end here */}

                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <InputMask
                                                    mask={ssnlabel == "Full SSN*" ? '999999999' : '9999'}
                                                    maskChar={null}
                                                    id="SocialSecurityNumber"
                                                    name="SocialSecurityNumber"
                                                    fullWidth
                                                    margin="dense"
                                                    size="small"
                                                    autoComplete="SocialSecurityNumber"
                                                    value={values.SocialSecurityNumber}
                                                    error={touched.SocialSecurityNumber && !!errors.SocialSecurityNumber}
                                                    helperText={touched.SocialSecurityNumber && errors.SocialSecurityNumber}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        if (ssnlabel === "Full SSN*" && (!(e.target.value.match(/^\d{9}$/)))) {
                                                            setSsnError("Full 9 digits of SSN required");
                                                            setSsnFlag(true);
                                                        } else if (ssnlabel == "Last Four Digits of SSN*" && (!(e.target.value.match(/^\d{4}$/)))) {
                                                            setSsnError("Only last 4 digits of SSN required");
                                                            setSsnFlag(true);
                                                        } else {
                                                            setSsnFlag(false);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);
                                                        if (ssnlabel === "Full SSN*" && (!(e.target.value.match(/^\d{9}$/)))) {
                                                            setSsnError("Full 9 digits of SSN required");
                                                            setSsnFlag(true);
                                                        } else if (ssnlabel == "Last Four Digits of SSN*" && (!(e.target.value.match(/^\d{4}$/)))) {
                                                            setSsnError("Only last 4 digits of SSN required");
                                                            setSsnFlag(true);
                                                        } else {
                                                            setSsnFlag(false);
                                                        }
                                                    }}
                                                >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label={ssnlabel} variant="outlined"
                                                            {...inputProps}
                                                            InputProps={{
                                                            endAdornment:(
                                                                values.SocialSecurityNumber?
                                                                <InputAdornment position="end">
                                                                    <IconButton edge="end">
                                                                        {!ssnFlag ? (
                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            ssnFlag ? (
                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                        
                                                                    </IconButton>
                                                                </InputAdornment>:null
                                                            ),
                                                        }}
                                                        />
                                                    }
                                                    {/* {(inputProps) =>
                                                        <TextField
                                                            label="SocialSecurityNumber*" variant="outlined"
                                                            {...inputProps}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    values.SocialSecurityNumber?
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end">
                                                                            {!ssnFlag ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                ssnFlag ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>:null
                                                                ),
                                                            }}
                                                        />
                                                    } */}
                                                </InputMask>
                                            </Grid>
                                            {ssnFlag ? <div className='error'>{ssnError}</div> : null}
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                               
                                             {/* <FormControl fullWidth size='small'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer
                                                            components={['DatePicker']}
                                                        >
                                                            <DemoItem label="Date of Birth*">
                                                                <DatePicker
                                                                    name="DateofBirth1"
                                                                    id='DateofBirth1'
                                                                    inputRef={inputRef1}
                                                                    value={values.DateofBirth1}
                                                                    maxDate={maxDate}
                                                                    minDate={minDate}
                                                                    views={['year', 'month', 'day']}
                                                                    onChange={(value) => { setFieldValue("DateofBirth1", value, true) }}
                                                                />
                                                            </DemoItem>
                                                        </DemoContainer>
                                                        <FormHelperText sx={{ color: "#d32f2f" }}>{touched.DateofBirth1 && errors.DateofBirth1 ? errors.DateofBirth1 : ''}</FormHelperText>
                                                    </LocalizationProvider>
                                                </FormControl> */}

                                            <FormControl fullWidth size='small'>
                                               <InputMask
                                                    name="DateofBirth1"
                                                    id='DateofBirth1'
                                                    value={values.DateofBirth1}
                                                    error={touched.DateofBirth1 && !!errors.DateofBirth1}
                                                    helperText={touched.DateofBirth1 && errors.DateofBirth1}
                                                    // onChange={handleChange}
                                                    onChange={(e) => {
                                                        handleDateChange(e);
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    mask='99/99/9999'
                                                    maskChar={null}
                                                    label="Date of Birth*"
                                                    variant="outlined"
                                                    size="small"
                                                    autoComplete="DateofBirth1"
                                                    fullWidth
                                                    margin="dense"
                                                    placeholder="MM/DD/YYYY"
                                                   >
                                                    {(inputProps) =>
                                                        <TextField
                                                        label="Date of Birth*" variant="outlined"
                                                        {...inputProps}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton edge="end">
                                                                    {touched.DateofBirth1 && !errors.DateofBirth1  && isValidFormat && isDateValid ?  (
                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            (touched.DateofBirth1 && errors.DateofBirth1  || !isValidFormat || !isDateValid) ?  (
                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        />
                                                    }
                                                </InputMask>  
                                                </FormControl>
                                            </Grid>
                                            <FormHelperText sx={{color:"#d32f2f"}}>{ !isValidFormat ? dateFormatErrorMsg : !isDateValid ? lessthanErrorMsg: ''}</FormHelperText>
                                            {/* error code display space start */}
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                {errorfromcd ? <Typography sx={{ fontWeight: "bold", color: "red" }}>
                                                    Error :- {errorfromcd}
                                                </Typography>
                                                    : ""}
                                            </Grid>
                                            {/* error code display space end */}
                                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "right", display: "flex" }}>
                                                <Button type="submit" color="warning" sx={{ minWidth: 150, backgroundColor: "#ED7E2E", color: "white", borderRadius: "0%" }}  disabled={isButtonDisabled} >NEXT STEP</Button>
                                            </Grid>
                                            <SecureWebsite />
                                        </Grid>
                                        <Grid item lg={1} md={1} sm={12} xs={12}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Divider orientation="vertical" style={{ height: '100%', width: '1px' }} />
                                        </Grid>

                                        {/* Right side panel for offer details  */}
                                        <OfferDetails pid={pid} step={2} errorfromcdCallback={handleCallback} handleFreezCallback={handleFreezCallback} />

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    )}
                </Formik>
            </Box>
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default Enrollment2;