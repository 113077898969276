import React, {useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box,TextField,Grid,Alert, FormLabel,Button,InputLabel,MenuItem,Select,FormControl,Stack,OutlinedInput } from '@mui/material';
// import { FormControl } from 'material-ui';
import {Card, CardContent, CardOverflow, Typography, Table,Radio, RadioGroup} from '@mui/joy';
import AppConfig from '../../../AppConfig';
import Moment from 'moment';
import {Formik} from 'formik';
import * as Yup from 'yup';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';
import AuthUser from "../../../Components/Auth/AuthUser";
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
let aid = '';

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
let callTypeId = '';

function ApprovedNow(props) {

    const { user } = AuthUser();
    const { id } = useParams();
    const [pid,setPid] = useState([]);
    // const [aid,setAid] = useState('');
    const [statusMessage, setStatusMesage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [defaultTimezone, setDefaultTimezone] = useState('PDT');
    const navigate = useNavigate();
    const [disable,setDisable] = useState(false);
    const [report_delivery, setReportDelivery] = useState('both');
   
    const [personName, setPersonName] = React.useState([]);
    //Fetch lead data
    const [leadDetails, setleadDetails] = useState([]);
    const [next_status, setNextStatus] = useState([]);
    const [checkAidStatus, setCheckAidStatus] = useState(false);
    const [logoImg, setLogoImg] = useState();
    const [imageUrl, setImageUrl] = useState(AppConfig.BASE_URL+'genericlogo.png');
    const dispatch = useDispatch();
    const [contactTypeList, setCallTypeList] = useState([]);
    const [callTypeList, setCallTypeDataList] = useState([]);
    const [dispositionTypeList, setDispositionList] = useState([]);
    const [outcomeList, setOutcomeList] = useState([]);
    const [dispositionValue, setdispositionval] = useState(0);
    const [outcomeVal, setoutcomeVal] = useState(0);
    const [dispositionVal, setdisVal] = useState(false);
    const [errors1, setErrors] = useState({});
    const RequestFormInitialValues = {
        aid: aid,
        pid: pid,
        report_delivery:report_delivery,
        defaultTimezone:defaultTimezone,
        checkAidStatus:false,
        calltype:'63',
        disposition:'',
        outcome:'',
        CallSource:'',
      }

    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const fetchLeadDetails = async() => {

        const apiUrl = '/leadDetails/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            setleadDetails(res.data);
         if(res.data.company.company_logo_url){
            setLogoImg(res.data.company.company_logo_url)
         }
         else{
            getDefaultLog(res.data.company.company_name)
         }
            setNextStatus(res.next_status);       
            setPid(res.pid); 
            if(res.data.first_name) {
                var name = res.data.company.company_name.replace(/ /g, '');
                aid = name.slice(0, 60);
                // setAid(name.slice(0, 60))
            }
        }
    }
   const getDefaultLog=(name)=>{
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var img = new Image();  
        
        // clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);
        
        var regex = /\s*(?:(\S{16})|([\s\S]{1,16})(?!\S))/g;
        var cname2= name.replace(regex, function($0,$1,$2) { return $1 ? $1 + "\n" : $2 + "\n"; } );

        var line=cname2.split("\n");
        var linelength = line.length;

        img.crossOrigin = 'anonymous';
        img.src = AppConfig.BASE_URL+'genericlogo.png';
        img.onload = async() => {
            canvas.width = "300";
            canvas.height = "90";
            
            context.drawImage(img, 0, 0);
            context.font = '22px Arial';
            context.fillStyle = 'black';

            var count = 100 / linelength;               
            for(var i=0; i < linelength-1; i++){
                context.fillText(line[i], img.width - 200, (count + (count*i)));
            }
            // context.fillText(stampText, img.width - 200, img.height - 50);
            setImageUrl(canvas.toDataURL('image/png')); 
    }
}
    const setAidValue = (e) => {
        // setCheckAidStatus(false);                  
        document.getElementById("aid").value = e.target.value;
        aid = e.target.value;     
    }
    

    useEffect(() => {
        if(user.roles_id < 3 || user.roles_id == 6) {
            fetchLeadDetails();
            fetchCallTypeLists();
            fetchCallTypeList();
            handleCallType(63)
        }else{
            navigate('/login');
        }
        
    }, [])
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    
    const ContactSchema = Yup.object().shape({
        aid: Yup.string()
        .min(2, 'Too Short!')
        .max(60, 'Length Of AID can be maximum 60 Chars/digits!')
        .matches(/^[^,\s]*$/, 'No commas or spaces allowed')
        .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters allowed')
        .required('Please enter AID.'),

        CallSource: Yup.string()
        .required('Please enter Contact Type'),

        calltype: Yup.string()
        .required('Please enter calltype'),

        disposition: Yup.string()
        .required('Please enter Disposition'),

        outcome: Yup.string()
        .required('Please enter outcome'),

      

        
    });
    const fetchCallTypeLists = async () => {
        const apiUrl = '/fetchContactLogCallType'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
          setCallTypeList(res);
        }
    }
    const fetchCallTypeList = async () => {
        handleFreezStart();
    
        const apiUrl = '/fetchCallTypeList';
        const args = {
          contactTypes: 'Affiliate',
        };
    
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
          handleFreezStop();
          setCallTypeDataList(res);
        }
    };
    const handleCallTypes = async(event) => {
        setdisVal(false);
        callTypeId = event;
        handleFreezStart();
    
        const apiUrl = '/fetchCallType';
        const args = {
            callType: callTypeId,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            setDispositionList(res);
            handleFreezStop();
            // setIsLoading(false)
        }
    };
         //get Outcome
    const getOutcome = async(id, disposition) => {
        if (disposition == 'Cancelled' || disposition == 'Saved') {
            setdisVal(true);

        } else {
            setdisVal(false);
        }
        errors1.disposition = '';
        setErrors(errors1);
        setdispositionval(1);

        disposition = id;
        handleFreezStart();
        errors1.aid = '';
        setErrors(errors1);
    
        const apiUrl = '/getoutcome';
        const args = {
            disposition: disposition,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            setOutcomeList(res);
            handleFreezStop();
            
            setoutcomeVal(0);
            // setIsLoading(false)
        }
    };
    const handleCallType = async(event) => {
       
        setdisVal(false);
        callTypeId = event;
        handleFreezStart();

        const apiUrl = '/fetchCallType';
        const args = {
            callType: callTypeId,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            setDispositionList(res);
            setOutcomeList([]);
            handleFreezStop();
            setdispositionval(0);
            setoutcomeVal(0);
            // setIsLoading(false)
        }
    };
    const handleOutComeVal = async(event) =>{
        errors1.outcome = '';
        setErrors(errors1);
        setoutcomeVal(1);
    }
   

    

    
    const ContentLayout=
    // function ContentLayout() {
    //     return (
            <>
            {leadDetails.first_name ?
            <Box>
                <Card variant='outlined'>
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',                            
                        }}
                    >
                        <Typography level="h4" color="primary">Approve Agreement ({leadDetails.company.company_name}) </Typography>
                    </CardOverflow>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid lg={4} md={4} sm={12} xs={12} p={2}>
                                <Table borderAxis="none" variant="plain" stripe="odd" >
                                    <tr>
                                        <th>First Name:</th>
                                        <td>{leadDetails.first_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Company Website (URL):</th>
                                        <td>{leadDetails.company.company_website}</td>
                                    </tr>
                                    <tr>
                                        <th>Email:</th>
                                        <td>{leadDetails.email}</td>
                                    </tr>
                                    <tr>
                                        <th>State:</th>
                                        <td>{leadDetails.state_code}</td>
                                    </tr>
                                    <tr>
                                        <th>Referral Name:</th>
                                        <td>{leadDetails.company.referred_by_company ? leadDetails.company.referred_by_company.company_name :  leadDetails.company.referred_by}</td>
                                    </tr>
                                    <tr>
                                        <th>Software Used:</th>
                                        <td>{leadDetails.company.marketing_type}</td>
                                    </tr>
                                </Table>
                            </Grid>
                            <Grid lg={4} md={4} sm={12} xs={12} p={2}>
                                <Table borderAxis="none" variant="plain" stripe="odd" >
                                    <tr>
                                        <th>Last Name:</th>
                                        <td>{leadDetails.last_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Title:</th>
                                        <td>{leadDetails.company.title}</td>
                                    </tr>
                                    <tr>
                                        <th>Phone No:</th>
                                        <td>{leadDetails.phone_no}</td>
                                    </tr>
                                    <tr>
                                        <th>City:</th>
                                        <td>{leadDetails.city}</td>
                                    </tr>
                                    <tr>
                                        <th>Referred By:</th>
                                        <td>{leadDetails.company.referred_by_company ? leadDetails.company.referred_by_company.company_name :  leadDetails.company.referred_by}</td>
                                    </tr>
                                    <tr>
                                        <th>Questions:</th>
                                        <td>--</td>
                                    </tr>
                                    
                                </Table>
                                
                            </Grid>
                            <Grid lg={4} md={4} sm={12} xs={12} p={2}>
                                <Table borderAxis="none"  stripe="odd">
                                    <tr>
                                        <th>Name of Business:</th>
                                        <td>{leadDetails.company.company_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Year Business Started:</th>
                                        <td>{leadDetails.company.business_started_year}</td>
                                    </tr>
                                    <tr>
                                        <th>Address:</th>
                                        <td>{leadDetails.address}</td>
                                    </tr>
                                    <tr>
                                        <th>Zip Code:</th>
                                        <td>{leadDetails.zip_code}</td>
                                    </tr>
                                    <tr>
                                        <th>Agreement Date:</th>
                                        <td>{Moment(leadDetails.agreement_completion_date).format('MMM Do YY')}</td>
                                    </tr>
                                </Table>
                            </Grid>
                        </Grid>
                        
                        <Formik
                        initialValues={RequestFormInitialValues}
                        
                        validationSchema={ContactSchema}
                        onSubmit={async(values, errors) => {

                            if(dispositionValue == 0){
                                errors1.disposition = 'Please enter Disposition';
                                setErrors(errors1);
                                return false;
                            }
                            if(outcomeVal == 0){
                                errors1.outcome = 'Please enter outcome';
                                setErrors(errors1);
                                return false;
                            }

                            const apiUrl = '/aidCheck';
                            const args = {
                                aid: aid,
                            }
                            const res = await dispatch(postData({apiUrl,args})).unwrap();
                            if(res == 0 || res == 1) {
                                console.log(res);         
                                if (res === 1) {
                                    // setCheckAidStatus(true);
                                    swal({
                                        title: "Failed",
                                        text: "AID Already Exists.",
                                        icon: "error",
                                        button: "Ok",
                                    });
                                    return false;
                                    
                                }else {
                                    // setCheckAidStatus(false);  
                                    console.log("true");                              
                                    setDisable(true)
                                    setErrorMessage('')
                                    setStatusMesage('Approving...');
                                
                                    const apiUrl = '/approveNow';
                                    let args = {};
                                    if(logoImg){
                                        args = {
                                            affref_id:id,
                                        aid:aid,
                                        report_delivery:report_delivery,
                                        defaultTimezone:defaultTimezone,
                                        calltype: values.calltype,
                                        disposition: values.disposition,
                                        outcome: values.outcome,
                                        CallSource: values.CallSource,        
                                        url:AppConfig.BASE_URL,
                                          }
                                    }
                                    else{
                                        args = {
                                            affref_id:id,
                                        aid:aid,
                                        report_delivery:report_delivery,
                                        defaultTimezone:defaultTimezone,
                                        calltype: values.calltype,
                                        disposition: values.disposition,
                                        outcome: values.outcome,
                                        CallSource: values.CallSource,        
                                        url:AppConfig.BASE_URL,
                                        uploadedLogo:imageUrl,
                                        logoType:"defaultLogo"
                                          }
                                    }
                                    const res = await dispatch(postData({apiUrl,args})).unwrap();
                                    if(res) {
                                        if(res.success === true){
                                       
                                            setStatusMesage(res.message);
                                            fetchLeadDetails()
                                            
                                            setTimeout(function() {
                                                setStatusMesage('');
                                                setDisable(false)
                                                navigate('/manageleads/approved-leads');
                                            }, 3000);

                                        }else{
                                            setDisable(false)
                                            setStatusMesage('');
                                            setErrorMessage(res.message);
                                            setTimeout(function(){
                                                setErrorMessage('');
                                            }, 3000);
                                        }
                                    }
                                }
                            }
                        }}
                    
                       
                       
                    > 
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <>
                        <Grid container p={2} item lg={12} md={12} sm={12} xs={12}>
                           
                                <Grid item lg={4} md={4} sm={12} xs={12} p={1}>
                                <FormLabel>AID</FormLabel>
                                    <TextField
                                        type="text"
                                        size="small"
                                        fullWidth
                                        margin='dense'
                                        name='aid'
                                        id='aid'
                                        defaultValue={values.aid}
                                        onBlur={handleBlur('aid')}

                                        onChange={(e) => {
                                            handleChange(e);
                                            setAidValue(e); 
                                        }}
                                       
                                        helperText={touched.aid && errors.aid ? errors.aid : null}
										error={touched.aid && errors.aid ? errors.aid : null}
                                       
                                    />
                                    {/* {errors1.aid && <div className="error">{errors1.aid}</div>} */}

                                    <p style={{ color: '#d32f2f', fontFamily: "Roboto", fontWeight: 400, fontSize: "0.90rem" }}>{checkAidStatus ? "AID already exists." : ''}</p>


                                </Grid>
                            {/* <Grid item md={4} p={2}>
                                <FormLabel>AID</FormLabel>
                                <TextField
                                    id="outlined-start-adornment"
                                    size='small'
                                    type="text"
                                    // disabled='true'
                                    value={aid}
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    // onChange={(e) => {setAidValue(e.target.value); }}
                                />                    
                            </Grid>                         */}
                            <Grid item lg={4} md={4} sm={12} xs={12} p={2}>
                                <FormLabel>PID</FormLabel>
                                <TextField
                                    name='pid'
                                    id='pid'
                                    size='small'
                                    type="text"
                                    disabled='true'
                                    value={values.pid}
                                    fullWidth
                                    variant="outlined"
                                />                    
                            </Grid>                        
                            <Grid item lg={4} md={4} sm={12} xs={12} p={2} mt={2}>
                                <FormControl fullWidth size='small' margin="dense" mt={4}>
                                    <InputLabel id="demo-multiple-name-label">Default Timezone</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        name='defaultTimezone'
                                        id='defaultTimezone'
                                        value={values.defaultTimezone}
                                        label="Default Timezone"
                                        onChange={(e) => {
                                            handleChange(e);
                                            setDefaultTimezone(e.target.value);
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value="EDT" >Eastern Time</MenuItem>
                                        <MenuItem value="HST" >Hawaii Time</MenuItem>
                                        <MenuItem value="AKDT">Alaska Time</MenuItem>
                                        <MenuItem value="PDT" >Pacific Time</MenuItem>
                                        <MenuItem value="MDT" >Mountain Time</MenuItem>
                                        <MenuItem value="CDT" >Central Time</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container p={2} item lg={12} md={12} sm={12} xs={12}>
                            <Grid container item lg={4} md={4} sm={4} xs={4} id='ContactType'>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Contact Type*</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="CallSource"
                                        name="CallSource"
                                        onChange={handleChange('CallSource')}
                                        value={values.CallSource}
                                        MenuProps={MenuProps}
                                        label="Contact Type*"
                                    >
                                    
                                        {contactTypeList.map((array) => (
                                            <MenuItem
                                                value={array.call_type}
                                            >
                                                {array.call_type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {touched.CallSource && errors.CallSource ? <div className='error'>{errors.CallSource}</div> : null}
                            </Grid>
                        </Grid>
                        <Grid container p={2} item lg={12} md={12} sm={12} xs={12}>
                            <Stack direction="row" spacing={2} style={{ width: '100%' }}>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Contact Reason*</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id='calltype'
                                            name='calltype'
                                            label="Contact Reason"
                                            value={values.calltype || 63}
                                            onChange={(e) => {
                                                handleCallType(e.target.value);
                                                handleChange(e);
                                            }}
                                            MenuProps={MenuProps}
                                            defaultValue={63}
                                        >
                                        
                                            {callTypeList.map((array) => (
                                                <MenuItem
                                                    value={array.id}
                                                    key={array.id}
                                                    disabled={array.id !== 63 && array.id !== 64}
                                                >
                                                    
                                                    {array.call_type}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                    {touched.calltype && errors.calltype ? <div className='error'>{errors.calltype}</div> : null}
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <FormControl fullWidth size="small" id="DispositionDiv">
                                        <InputLabel id="demo-simple-select-label">Disposition*</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="disposition"
                                            name='disposition'
                                            value={values.disposition}
                                            input={<OutlinedInput label="Disposition" />}
                                            onChange={(e) => {
                                                const selectedOption = dispositionTypeList.find((option) => option.id === e.target.value);
                                                // getOutcome(e.target.value);
                                                getOutcome(selectedOption.id, selectedOption.disposition);
                                                handleChange(e);
                                            }}
                                            MenuProps={MenuProps}
                                            // defaultValue={141}
                                        >
                                            {dispositionTypeList.map((dispositionlist) => (
                                                <MenuItem
                                                    key={dispositionlist.id}
                                                    value={dispositionlist.id}
                                                    disabled={dispositionlist.id !== 134 && dispositionlist.id !== 138}
                                                >
                                                    {dispositionlist.disposition}
                                                </MenuItem>

                                            ))}
                                        </Select>
                                        {touched.disposition && errors.disposition ? <div className='error'>{errors.disposition}</div> : null}
                                        {errors1.disposition && <div className="error">{errors1.disposition}</div>}
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <FormControl fullWidth size="small" id="OutcomeDiv">
                                        <InputLabel id="demo-simple-select-label">Outcome*</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="outcome"
                                            name="outcome"
                                            value={values.outcome }
                                            input={<OutlinedInput label="Outcome" />}
                                            // onChange={handleChange}
                                            // onChange={handleChange}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleOutComeVal(e.target.value);
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                        {outcomeList.map((outcome) => (
                                            outcome.reason === '' ? (
                                                <MenuItem  value={outcome.id}>No OutCome available</MenuItem>
                                            ) : (
                                                <MenuItem value={outcome.id} 
                                                >
                                                {outcome.reason}
                                                
                                                </MenuItem>
                                            )
                                            ))}
                                            
                                        </Select>
                                        {touched.outcome && errors.outcome ? <div className='error'>{errors.outcome}</div> : null}
                                        {errors1.outcome && <div className="error">{errors1.outcome}</div>}
                                    </FormControl>
                                </Grid>
                            </Stack>
                        </Grid>


                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                             <Grid container p={3} lg={7} md={7} sm={12} xs={12} sx={{display:'block'}}>
                                <Typography level="h6" sx={{ fontWeight: "500",color:'rgba(0, 0, 0, 0.6)'}} pb={1}>Cobrand default plans :</Typography>
                               <Box>
                               {leadDetails.cobrandBasePids.map((item,index)=>{
                                    return(
                                        <Typography>{item.base_pid} : {item.plan_name}</Typography>
                                    )
                                })}
                               
                               </Box>
                                        
                            </Grid>
                            <Grid  p={3} lg={5} md={5} sm={12} xs={12} >
                            <Typography level="h6" sx={{ fontWeight: "500",color:'rgba(0, 0, 0, 0.6)'}}>Company logo :</Typography>
                           <Box sx={{display:'flex',alignItems:'center',paddingTop:'20px'}}>
                           <img style={{height: 90, width: 300}} src={logoImg? AppConfig.BACKEND_URL + "images/companyLogo/" + leadDetails.company.company_logo_url:imageUrl} />
                           </Box>
                            </Grid>
                        </Grid> */}
                        <Grid container p={3} item lg={12} md={12} sm={12} xs={12}>
                            {/* <Grid item lg={2} md={2} sm={12} xs={12}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Enabled" />
                            </Grid> */}
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                            <FormControl>
                            
                            <RadioGroup
                                row
                                orientation='horizontal' 
                                name='report_delivery'
                                id='report_delivery'
                                color="primary" 
                                defaultValue={values.report_delivery}
                                onChange={(e)  => {setReportDelivery(e.target.value)}}
                                >
                                    <FormLabel id="demo-row-radio-buttons-group-label" ><b>Report Delivery:</b></FormLabel>
                                <Radio variant="outlined" value="dashboard" label="Only Dashboard"  />
                                <Radio variant="outlined" value="email" label="Only Email"  />
                                <Radio variant="outlined" value="both" label="Both"  />
                            </RadioGroup>
                            </FormControl>
                            </Grid>
                            <Grid item xs></Grid>
                            {/* <FormLabel>Report Delivery</FormLabel>                             */}
                        </Grid>
                        {/* <Grid container p={2} item xs={12}>
                            <RadioGroup 
                                orientation='horizontal' 
                                name='report_delivery'
                                id='report_delivery'
                                color="primary" 
                                defaultValue={values.report_delivery}
                                onChange={(e)  => {setReportDelivery(e.target.value)}}
                                >
                                <Radio variant="outlined" value="dashboard" label="Only Dashboard"  />
                                <Radio variant="outlined" value="email" label="Only Email"  />
                                <Radio variant="outlined" value="both" label="Both"  />
                            </RadioGroup>
                        </Grid> */}
                        
                        <Grid container p={2} item xs={12}>
                                
                            <Grid container  item md={6} sx={{justifyContent:'space-between'}}>   
                                <Grid item sm={12} >
                                    <Box display="flex">
                                        {statusMessage?<Alert severity="success">{statusMessage}</Alert>:''}
                                        {errorMessage?<Alert severity="error">{errorMessage}</Alert>:''}
                                    </Box>
                                </Grid>
                                <Grid md={6} p={2}>
                                    
                                    <Button variant="contained" sx={{minWidth:"100%"}} color="success" disabled={disable} onClick={ disable === false ? handleSubmit : ''} type="submit">APPROVE</Button>
                                </Grid>      
                                <Grid md={6} p={2}>
                                    <Button variant="contained" sx={{minWidth:"100%"}} color="primary" type="submit" disabled={disable} onClick={() => navigate(-1)} >CLOSE</Button>
                                </Grid>                       
                            </Grid>
                        </Grid>
                        </>
                        )}
                    </Formik>    
                    </CardContent>
                </Card>
            </Box>
            :''}
            </>
    //     );
    // }
     
    return(
        <div>
            <DefaultLayout content={ContentLayout} />
            <div>
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
                onClick={handleFreezStop}
                >
                <Freez />
                </Backdrop>
            </div>
        </div>
    );
}


export default ApprovedNow;