import React, { useState,useEffect } from 'react';
import { Typography, Grid, Table, Button, Dialog, DialogContent, IconButton, TextField,useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Moment from 'moment';

function AnnualGala(props) {
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [daysDifference, setDaysDifference] = useState(0);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        const eventDate = Moment.tz('2024-12-12', 'America/Los_Angeles');
        const today = Moment.tz('America/Los_Angeles');
        const differenceInDays = eventDate.diff(today, 'days');
    
        setDaysDifference(differenceInDays);


        const interval = setInterval(() => {
          setIsVisible((prev) => !prev); // Toggle visibility
        }, 500);
    
        return () => clearInterval(interval); // Clear interval on component unmount
      }, []);
    return (
        <>
            <div className="backgroundAnnual" >
                <Grid container item lg={12} md={12} sm={12} xs={12} marginTop={isMobile?"12%":"0%"}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} >
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={7} sx={{ textAlign: "center !important" }}>
                            <Typography className="annual"><span className="gala">M</span>Y<span className="gala">F</span>REE<span className="gala">S</span>CORE<span className="gala">N</span>OW</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }}>
                            <Typography className="invite" fontSize={ isMobile ? "0.9rem":"1.2rem"}>INVITES YOU TO THEIR<br />ANNUAL GALA, CONFERENCE AND AWARDS CEREMONY </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }} mt={2}>
                            <img src={require("../assets/images/daysby.png")} alt="design" width={isMobile ? "35%" : "15%"}  />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important",visibility: isVisible ? 'visible' : 'hidden' }} >
                           
                            <Typography sx={{fontWeight:600}} fontSize={isMobile?"1rem":"1.5rem"}>{daysDifference} Days To Go </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }} mb={1}>
                            <img src={require("../assets/images/dayson.png")} alt="design1"  width={isMobile ? "35%" : "15%"} />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }}>
                            <Typography className="Righteous" fontSize={isMobile ? "1.3rem" : "2.4rem"} > 
                                <span><img src={require("../assets/images/gatsby.png")} alt="design3" width={isMobile ? "50px" : "100px"} /> </span> A NIGHT AT GATSBY’S <span><img src={require("../assets/images/gatsby.png")} alt="design3" width={isMobile ? "50px" : "100px"}/></span>
                            </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important", display: "flex", justifyContent: "center" }}>
                            <Table sx={{ width: "0% !important;", borderSpacing: 0 }}>
                                <tr>
                                    <td style={{ padding: "0px 7px", lineHeight: 0 }}>
                                        <p className="annualgala">DEC</p>
                                        <p className="MonkeyFont" >12</p>
                                    </td>
                                    <td rowspan="2" style={{ padding: "0px 7px", lineHeight: 0 }}>
                                        <span className='test2 annualgala'>2024</span>
                                    </td>
                                </tr>
                            </Table>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Table sx={{ width: "0% !important;", borderSpacing: 0 }}>
                                <tr >
                                    <td style={{ padding: "0px 7px", lineHeight: 0 }}>
                                        <p className="annualgala">DEC</p>
                                        <p className="MonkeyFont">13</p>
                                    </td>
                                    <td rowspan="2" style={{ padding: "0px 7px", lineHeight: 0 }}>
                                        <span className='test2 annualgala'>2024</span>
                                    </td>
                                </tr>
                            </Table>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }}>
                            <span
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{ display: "inline-block" }}>
                                <img src={isHovered ?
                                    require("../assets/images/PropertyHover.png")
                                    :
                                    require("../assets/images/Property.png")} alt="#" style={{ width: "115px", height: "auto" }} />
                            </span>
                            <span
                                onMouseEnter={() => setIsHovered1(true)}
                                onMouseLeave={() => setIsHovered1(false)}
                                style={{ display: "inline-block" }}>
                                <img src={isHovered1 ?
                                    require("../assets/images/Property1Hover.png")
                                    :
                                    require("../assets/images/Property1.png")} alt="#" style={{ width: "115px", height: "auto" }}
                                />
                            </span>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center !important" }} mt={3}>
                            <Button variant="contained" sx={{ backgroundColor: "black", color: "white", minWidth: 150 ,
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",          
                                },
                            }}
                                onClick={handleClickOpen}
                            >RSVP</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={fullWidth}
                maxWidth={'sm'}
                sx={{
                    '& .MuiDialogContent-root': {
                        backgroundImage: `url(${require("../assets/images/PopupBack.png")})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: 'auto',
                        backgroundSize: '100% 100%',
                        //   padding: '10px 20px',
                    },
                    '& .MuiDialog-paper':{
                        borderRadius:"5% !important"
                    }
                   
                }}
            >
                <DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid item container lg={12} md={12} sm={12} xs={12} px={4} py={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <TextField label="First Name" fullWidth margin="dense" size="small" />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <TextField label="Last Name" fullWidth margin="dense" size="small" />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <TextField label="Registered Email" fullWidth margin="dense" size="small" />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <TextField label="Contact Number" fullWidth margin="dense" size="small" />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <TextField label="Company Name" fullWidth margin="dense" size="small" />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }} mb={2} mt={2}>
                            <Button variant="contained" sx={{ backgroundColor: "black", color: "white", minWidth: 150,
                                 "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",          
                                },
                             }} onClick={handleClose}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AnnualGala;