
import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import Enrolled from '../../../Components/LeadReports/Enrolled';
import Upgraded from '../../../Components/LeadReports/Upgraded';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import AuthUser from "../../../Components/Auth/AuthUser";
import InputBase from '@mui/material/InputBase';
import { Typography } from '@mui/joy';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import { format, subDays } from 'date-fns';
import {
  Box, Button, Tab,
  Grid, FormControl, Select, MenuItem, InputLabel, OutlinedInput
} from '@mui/material';
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },

}));
function LeadReportIndex(props) {
  const { tab } = useParams();
  const { http } = AuthUser();
  const [data, setData] = useState([]);
  const [value, setValue] = React.useState(tab);
  const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
  //getdata function start

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setValue(tab);
  })
  
  const sideBar = calendarFilter();
  const staticRanges = [
      // ...defaultStaticRanges,
      ...createStaticRanges(sideBar)
  ];

  const ContentLayout=
      <>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }}>
            <Typography level="h4" color="primary">Lead Enrollments</Typography>
          </Grid>
          <Grid container item lg={6} md={6} sm={6} xs={6} mb={2} spacing={2} mt={2}>

            <Grid item xs>
              <FormControl fullWidth size='small'>
                <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value="date"
                  input={<OutlinedInput label="Select Date Range" />}
                >
                  <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                  <DateRangePicker
                    className='custom-date-picker'
                    onChange={item => setSelectionRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={selectionRange}
                    direction="horizontal"
                    maxDate={new Date()}
                    staticRanges={staticRanges}
                  />
                  {/* <div style={{display:"flex",justifyContent:"right"}}>
                            <Button className="btn btn-transparent text-danger">
                                Close
                            </Button>
                        </div> */}
                </Select>
              </FormControl>
            </Grid>


          </Grid>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                {/* <TabList onChange={handleChange} aria-label="lab API tabs example" >
                  <Link to="/leadreports/enrolled" value="enrolled" style={{ textDecoration: 'none' }} >
                    <Tab label="Enrolled" value="enrolled" sx={{ color: 'black' }} />
                  </Link>
                  <Link to="/leadreports/upgraded" value="upgraded" style={{ textDecoration: 'none' }} >
                    <Tab label="Upgraded" value="upgraded" sx={{ color: 'black' }} />
                  </Link>
                </TabList> */}
                <TabList onChange={handleChange} aria-label="lab API tabs example" >
                    <Tab label="Enrolled" component={Link} to="/leadreports/enrolled" value="enrolled" sx={{ color: 'black' }} />
                    <Tab label="Upgraded"  component={Link} to="/leadreports/upgraded" value="upgraded" sx={{ color: 'black' }} />
                </TabList>
              </Grid>
            </Box>
            <TabPanel value="enrolled"><Enrolled tab={tab} startDate1={startDate} endDate1={endDate} /></TabPanel>
            <TabPanel value="upgraded"><Upgraded tab={tab} startDate1={startDate} endDate1={endDate} /> </TabPanel>
          </TabContext>
        </Box>
      </>
  

  return (
    <div>
      <DefaultLayout content={ContentLayout} />

    </div>
  );
}

export default LeadReportIndex;