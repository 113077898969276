import React, { useState } from 'react';
import {
    OutlinedInput, Grid, InputLabel , FormControl , IconButton,InputAdornment,Button,TextField
} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik} from 'formik';
import swal from "sweetalert";
import AuthUser from '../Auth/AuthUser';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../Components/Loader/Loader'
import { logout } from '../../redux/authSlice';
let RequestFormInitialValues = {
    OldPassword: '',
    NewPassword: '',
    ConfirmPassword: '',
};
let isLowercaseValid=false;
let isUppercaseValid=false;
let isNumberValid=false;
let isSpecialCharValid=false;
let isLengthValid=false;
function Repassword() {
    const dispatch = useDispatch();
    const isAuthenticated = localStorage.getItem('isAuthenticated')
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPasswordValidationBlock, SetShowPasswordValidationBlock] = useState(false);
    const { http } = AuthUser();
    const { user, validateRoles } = AuthUser();
    const navigate = useNavigate();
    const handleToggleOldPassword = () => setShowOldPassword((show) => !show);
    const handleToggleNewPassword = () => setShowNewPassword((show) => !show);
    const handleToggleConfirmPassword = () => setShowConfirmPassword((show) => !show);
    
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //Blog validation Schema start
    const ResetPassword = Yup.object().shape({
        OldPassword: Yup.string()
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                'Must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character'
            )
            .required('The Old Password field is required.'),

        NewPassword: Yup.string()
        .test(
            () => {
                return (
                    isLowercaseValid &&
                    isUppercaseValid &&
                    isNumberValid &&
                    isSpecialCharValid &&
                    isLengthValid
                );
            }
            
        )
        .required('Password is required.'),

        ConfirmPassword: Yup.string()
            .oneOf([Yup.ref('NewPassword'), null], 'Passwords must match')
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                'Must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character'
            )
            .required('The Confirm Password is required.'),
    });
    //Blog validation Schema end
    const Password = (values, { resetForm }) => {
        // alert(JSON.stringify(values));
        SetShowPasswordValidationBlock(false);
        if (values.NewPassword !== values.ConfirmPassword) {
            swal({
                title: "Failed",
                text: "New Password and Confirm Password do not match",
                icon: "error",
                button: "Ok",
            });
            return;
        }
        handleFreezStart();

            // Old password is valid, proceed to update the password
            http.post("updatePassword", {
                oldPassword: values.OldPassword,
                newPassword: values.NewPassword,
            })
            .then((res) => {
                if (res.data.success === true) {
                    console.log(res)
                    swal({
                        title: "Success",
                        text: res.data.message,
                        icon: "success",
                        button: "Ok",
                    })
                    .then(() => {
                        
                            dispatch(logout());
                            localStorage.clear();
                            handleFreezStop();
                            navigate('/login');
                        
                    });
                } else {
                    swal({
                        title: "Failed",
                        text: res.data.message,
                        icon: "error",
                        button: "Ok",
                    });
                    handleFreezStop();

                }
            })
            .catch((error) => {
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                });
                handleFreezStop();

            });
    };

    
    //password suggestion box
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*]/;
        isLowercaseValid=lowercaseRegex.test(newPassword);
        isUppercaseValid=uppercaseRegex.test(newPassword);
        isNumberValid=numberRegex.test(newPassword);
        isSpecialCharValid=specialCharRegex.test(newPassword);
        isLengthValid=newPassword.length >= 8;
      };
  
      //hide show the password suggestion box
      const handlePasswordClick = () => {
        SetShowPasswordValidationBlock(true);
      };
  return (
    <>
      <Card>
                    <CardOverflow
                        sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                            padding: '1%',
                        }}>
                        <Typography level='h4'>Change Password</Typography>
                    </CardOverflow>
                    <CardContent sx={{ padding: "0px 70px 0px" }}>
                        <Formik
                            initialValues={RequestFormInitialValues}
                            validationSchema={ResetPassword}
                            onSubmit={Password}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                                <div>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <TextField
                                            label="Name"
                                            defaultValue={user ? user.name : ''}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            margin="dense"
                                            fullWidth
                                            size='small'
                                            name='name'
                                            id='name'
                                        />
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl variant="outlined" margin="dense" size='small'
                                            fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
                                            <OutlinedInput
                                                id="OldPassword"
                                                type={showOldPassword ? 'text' : 'password'}
                                                name='OldPassword'
                                                onChange={(e) => { handleChange(e); }}
                                                value={values.OldPassword}
                                                onBlur={handleBlur('OldPassword')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleToggleOldPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                           {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Old Password"
                                            />
                                            {touched.OldPassword && errors.OldPassword ? <div className='error'>{errors.OldPassword}</div> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl variant="outlined" margin="dense" size='small'
                                            fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                            <OutlinedInput
                                                id="NewPassword"
                                                type={showNewPassword ? 'text' : 'password'}
                                                name='NewPassword'
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handlePasswordChange(e); 
                                                  }}
                                                onKeyDown={handlePasswordClick} //hide show the password suggestion box
                                                value={values.NewPassword}
                                                onBlur={handleBlur('NewPassword')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleToggleNewPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="New Password"
                                            />
                                            {touched.NewPassword && errors.NewPassword ? <div className='error'>{errors.NewPassword}</div> : null}
                                            {showPasswordValidationBlock && (
                                                    <>
                                                           <div>
                                                            <span style={{ color: isLengthValid ? 'green' : 'red' }}>Minimum <b>8 characters</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isSpecialCharValid ? 'green' : 'red' }}>At least <b>one special character</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isUppercaseValid ? 'green' : 'red' }}>At least <b>one capital (uppercase)</b> letter</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isNumberValid ? 'green' : 'red' }}>At least <b>one number</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isLowercaseValid ? 'green' : 'red' }}>At least <b>one lowercase</b> letter </span>
                                                        </div>
                                                    </>
                                           )}
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl variant="outlined" margin="dense" size='small'
                                            fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                id="ConfirmPassword"
                                                name="ConfirmPassword"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                onChange={(e) => { handleChange(e); }}
                                                value={values.ConfirmPassword}
                                                onBlur={handleBlur('ConfirmPassword')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleToggleConfirmPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                             {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Confirm Password"
                                            />
                                            {touched.ConfirmPassword && errors.ConfirmPassword ? <div className='error'>{errors.ConfirmPassword}</div> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} >
                                        <Grid item xs sx={{ textAlign: "center" }}>
                                            <Button variant='contained' color="primary" sx={{ minWidth: 300 }} onClick={handleSubmit}>Update</Button>
                                        </Grid>
                                        <Grid item xs sx={{ textAlign: "center" }}>
                                            <Button variant='contained' color="success" sx={{ minWidth: 300 }}  onClick={() => navigate(-1)}>Close</Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Formik>
                    </CardContent>
                </Card>

                 {/* loader code start */}
                 <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={freez}
                    >
                        <Freez />
                    </Backdrop>
                </div>
                {/* loader code end */}
           
    </>
  )
}
export default Repassword;