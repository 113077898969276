import React,{useMemo,useState,useEffect} from 'react';
//MRT Imports
import MaterialReactTable from 'material-react-table';
//Material-UI Imports
import {
  Box,
  Table ,Tooltip ,TableCell ,Paper,TableContainer,TableRow,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
//Mock Data
function Upgraded(props) {
    const [rowSelection, setRowSelection] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
    }, [rowSelection]);
    const columns = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column
                header: 'Employee',
                columns: [
                    {
                        accessorFn: (row) => `${row.firstName} ${row.lastName}`, //accessorFn used to join multiple data into a single cell
                        id: 'name', //id is still required when using accessorFn instead of accessorKey
                        header: 'Name',
                        size: 250,
                        Cell: ({ renderedCellValue, row }) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                }}
                            >
                                <img
                                    alt="avatar"
                                    height={30}
                                    src={row.original.avatar}
                                    loading="lazy"
                                    style={{ borderRadius: '50%' }}
                                />
                                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                                <span>{renderedCellValue}</span>
                            </Box>
                        ),
                    },
                    {
                        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        header: 'Email',
                        size: 300,
                    },
                ],
            },
            {
                accessorFn: (data) =>
                    <>
                        <><Tooltip title="Send Agreement" arrow><SendIcon /></Tooltip> </>
                    </>,
                id: 'actions',
                header: 'Actions',
            },
        ],
        [],
    );
    //csv option start here
	const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
        };
    
        const csvExporter = new ExportToCsv(csvOptions);
        //csv option end here
          //csv start here
        const handleExportRows = (rows) => {
            csvExporter.generateCsv(rows.map((row) => row.original));
        };
    
        const handleExportData = () => {
            csvExporter.generateCsv(data);
        };
    
        const handleReferredList = (data) =>{
            setData(data);
            setIsLoading(false);
        }
        const handleTablePreferences = (data) =>{
            if(Object.values(data).length>0){
                setColumnVisibility(data);
            }
            setIsLoading(false);
        }
     
    return (
        <>
        <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnFilterModes
            
            enableGrouping={false}
            enablePinning={false}
            enableRowSelection={false}
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
            initialState={{
                showColumnFilters: false,
                density: 'compact',
            }}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
            renderDetailPanel={({ row }) => (
                <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
                >
                <TableContainer component={Paper}>
                    <Table className='back'>
                        <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Name:</TableCell>
                                <TableCell>{row.original.first_name+' '+row.original.last_name}</TableCell>							
                                <TableCell style={{ fontWeight: 'bold' }}>Email:</TableCell>
                                <TableCell>{row.original.email}</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
                                <TableCell>{row.original.phone_no}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Agreement ID:</TableCell>
                            <TableCell>{row.original.pandadoc_id}
                                <Tooltip title="Open Pandadoc" arrow><LinkIcon color="primary" /></Tooltip>
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Status:</TableCell>
                            <TableCell>{row.original.status.status_name}</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Last Updated:</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Lead Generated Date:</TableCell>
                            <TableCell></TableCell>						
                            <TableCell style={{ fontWeight: 'bold' }}>Followup:</TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Lead Type:</TableCell>
                            <TableCell></TableCell>
                        </TableRow>							
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Software Used:</TableCell>
                            <TableCell></TableCell>							
                            <TableCell style={{ fontWeight: 'bold' }}>Comments:</TableCell>
                            <TableCell></TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Next Followup:</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Assigned To:</TableCell>
                            <TableCell></TableCell>
                        </TableRow>	
                    </Table>
                </TableContainer>
            </Box>
            )}
            
        />
        </>
    );
}

export default Upgraded;