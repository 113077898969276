import React, {useState} from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box,Grid } from '@mui/material';
import { Typography,Card,CardContent, Button } from '@mui/joy';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { postData } from '../../../redux/apiSlice';

function CreditThanks(props) {
  const dispatch = useDispatch();
//Neccessary variables declearation start


  //loader function and variable start
  const [freez, setFreez] = React.useState(false);
  const [bounce, setBounce] = useState(props.bounceFlag);
  const handleFreezStop = () => {
      setFreez(false);
  };
  const handleFreezStart = () => {
      setFreez(true);
  };
  //loader function and variable end


  //Resend Snapshot start
  const resendCreditSnapShot = async() => {
    handleFreezStart();
    const apiUrl = '/reGenerateAgreementForCRC';
    const args = {
     
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === true) {
        handleFreezStop();
        swal({
          title: "Success",
          text: res.message.message,
          icon: "success",
          button: "Ok",
        }).then((ok) => {
          if (ok) {
            window.location.reload();
          }
        });
      } else {
        handleFreezStop();
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        });
      }
    }
  }
  //Resend Snapshot end

    function ContentLayout() {
        return (
            <>
               <Box className="back">
            <Card >
                <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4}>
                        <Grid  item lg={6} md={6} sm={12} xs={12}>
                            <Typography>
                                Thanks you for your interest in Credit Snapshot!
                            </Typography>
                            <p>
                                Next steps: You will receive the program document in less than 24 hours - please continue to check your email.
                            </p>
                            <p>
                            After your document has been completed, signed and approved you will be able to click on the “Credit Snapshot” tab in your affiliate portal. Copy and post your link immediately on your website, Facebook, Twitter, LinkedIn, Instagram or other social media. Use your link in email campaigns or in any other marketing communications. Create an interactive pop-up for your website with the easy to use code provided.
                            </p>
                            <p>
                            You will be able to see and manage all of your Credit Snapshot leads in the “My Leads” tab in your affiliate portal.
                            </p>
                            <p>
                            If you haven't received the document in 24 hrs, you may click on "Resend" below.
                            </p>
                            <Button  color="success" disabled={bounce} SX={{minWidth:200,color:"white",bgColor:"#76C044",borderRadius:"0%"}}onClick={resendCreditSnapShot}>Resend</Button>
                            {bounce ? <Typography sx={{mt:1,mb:1,color:"red"}}><b>Not able to send your document as the communication email is invalid. Please update it from company details panel</b></Typography> : ''}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} >   
                            <img src={require("../../../assets/images/thankyou.png")} sx={{ marginRight: "auto", marginLeft: "auto", display: "block",height:"auto",width:"100%"}}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box> 
            </>
        );
    }
return(
    <div>
        <DefaultLayout content={<ContentLayout />} />
                    {/* loader code start */}
                    <div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={freez}
                            onClick={handleFreezStop}
                        >
                            <Freez />
                        </Backdrop>
                    </div>
                    {/* loader code end */}
    </div>
);
}


export default CreditThanks;