import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box,  Select, Grid, Button, TextField,  InputLabel, MenuItem, FormControl, OutlinedInput,Alert,
   
} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';

import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from 'yup';
import { Formik } from 'formik';
import AuthUser from '../../../Components/Auth/AuthUser';
import Backdrop from '@mui/material/Backdrop';
import InputMask from 'react-input-mask';
import Freez from '../../../Components/Loader/Loader'
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
let aidlist1 = [];
let referedby1;
let companyname;
let isLowercaseValid=false;
let isUppercaseValid=false;
let isNumberValid=false;
let isSpecialCharValid=false;
let isLengthValid=false;
let showPasswordValidationBlock=false;
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
function EditUser(props) {
    const dispatch = useDispatch();
    
    const theme = useTheme();
    const navigate = useNavigate();
  
   
    const [personName, setPersonName] = React.useState([]);
    const [software, setSoftware] = useState([]);
    //variable declearation start
    //Common Variables
    const { id } = useParams();
    const [userTypeList, setUserTypeList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [aidlist, setAIDlist] = useState([]);
    //userdetails array
    const [userDetails, setuserDetails] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    //variable declearation ends
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    //fetch user details start
    const fetchUserDetails = async() => {
        const apiUrl = '/fetchPartnerUserDetails/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if (res.success == true) {
                setuserDetails(res.details);
                setPersonName(res.details.aidList);
               
            } else {
                alert("not found");
            }
        }
    };
    //fetch user details end
    //fetch user types start
    const fetchUserTypes = async() => {
            handleFreezStart();
            const apiUrl = '/fetchUserTypes';
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res) {
                handleFreezStop();
                setUserTypeList(res);
            }
    }

    const fetchSoftware = async() => {
        const apiUrl = '/getSoftwareInfo';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if (res.success === true) {
                setSoftware(res.data);
            }
        }
    }
    //fetch user types end
    //fetch Company AID list start
    
   
    //fetch Company AID list end
    //hide menus according to usertype start
    const changeUserType = (e,values) => {
    }
   
    
    const deleteUser = async() => {
        handleFreezStart();

        const apiUrl = '/deletePartnerUser';
        const args = {
            id: id,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if (res.success === true) {
            handleFreezStop();
            setSuccessMessage(res.message);
            setTimeout(function () {
                setSuccessMessage('')  
                navigate('/partner-admin/administration');      
            }, 3000);
          
        } else {
            handleFreezStop();
        }

    }
    //hide menu according to usertype end
    //fetchcompany list start
    const fetchCompanyList = async() => {
        handleFreezStart();
        const apiUrl = '/fetchCompanyList';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            handleFreezStop();
            setCompanyList(res);
        }
    }
    //fetchcompany list end
    //useEffect start
    useEffect(() => {
        showPasswordValidationBlock=false;
        fetchUserTypes();
        fetchCompanyList();
        fetchSoftware();
        fetchUserDetails();
    }, [])
    useEffect(() => {
        changeUserType(userDetails.roles_id,[]);
    });
    //useEffect end
    //create function start
    const Update = async(values) => {
        showPasswordValidationBlock=false;
        handleFreezStart();

        const apiUrl = '/updateUser';
        const args = {
            id: id,
                first_name: values.FirstName,
                last_name: values.LastName,
                role_id: values.UserType,
                email: values.Email,
                phone_no: values.PhoneNumber,
                password: values.Password,
                software_name:values.softwareName,
                aids: values.userAidList,
                defaultTimezone: values.DefaultTimezone,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if (res.success === true) {
            fetchUserDetails();
            handleFreezStop();
            setSuccessMessage(res.message);
            setTimeout(function () {
                setSuccessMessage('')  
                navigate('/partner-admin/administration');      
            }, 3000);
          
        } else {
            handleFreezStop();
        }

    }
    //create function end
    //User validation Schema start
    const UserSchema = Yup.object().shape({
        FirstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your full Name.'),
        LastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your last Name.'),
        PhoneNumber: Yup.string()
            .min(14, 'Phone number must be of 10 digit!')
            .max(14, 'Phone number must be of maximum 10 digit!')
            .required('Please enter your phone number.'),
        Email: Yup.string()
            .email('Invalid email format.')
            .required('Please enter your email address.'),
        Password: Yup.string()
        .test(
            (value) => {
                return (
                    value?
                    isLowercaseValid &&
                    isUppercaseValid &&
                    isNumberValid &&
                    isSpecialCharValid &&
                    isLengthValid
                    :true
                );
            }
            
        )
    });
    //User validation Schema end
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*]/;
        isLowercaseValid=lowercaseRegex.test(newPassword);
        isUppercaseValid=uppercaseRegex.test(newPassword);
        isNumberValid=numberRegex.test(newPassword);
        isSpecialCharValid=specialCharRegex.test(newPassword);
        isLengthValid=newPassword.length >= 8;
      };
    
      //hide show the password suggestion box
      const handlePasswordClick = () => {
          showPasswordValidationBlock=true;
      };
    function ContentLayout() {
        return (
            <>
                {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}

                <Formik
                    initialValues={{
                        FirstName: userDetails.first_name,
                        LastName: userDetails.last_name,
                        Email: userDetails.email,
                        PhoneNumber: userDetails.phone_no ?  (!(userDetails.phone_no.includes("("))  ? "(" + userDetails.phone_no.substring(0,4) + ") " + userDetails.phone_no.substring(4,7) + "-" + userDetails.phone_no.substring(7): userDetails.phone_no)  : '',
                        UserType: userDetails.roles_id,
                        CompanyName: companyname,
                        Aidarray: aidlist,
                        userAidList: personName,
                        softwareName: userDetails.software_name,
                       
                        DefaultTimezone: userDetails.default_timezone,
                        ReportDelivery: userDetails.report_delivery,
                        Password: ''
                    }}
                    validationSchema={UserSchema}
                    onSubmit={(values, errors) => {
                        if((values.UserType == 4 || values.UserType == 5) && (values.userAidList.length == 0)){
                            swal({
                                title: "Alert",
                                text: "Please Select atleast one AID",
                                icon: "error",
                                button: "Ok",
                            });
                            return false;
                        }
                        Update(values);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                        <div>
                            <Box>
                                <div>
                                    <Typography color="primary" sx={{ fontSize: "30px" }}>{userDetails.name}</Typography>
                                </div>
                                <Card variant="outlined" size="lg" mt={2}>
                                    <CardOverflow
                                        sx={{
                                            bgcolor: '#F6FAFF',
                                            borderColor: '#F6FAFF',
                                            padding: '1%',
                                        }}
                                    >
                                        <Typography level="h3" startDecorator={<AccountCircleIcon sx={{ fontSize: "35px" }} />} mb={2}>
                                            Edit User
                                        </Typography>
                                    </CardOverflow>
                                    <CardContent>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="First Name"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='FirstName'
                                                    id='FirstName'
                                                    onChange={handleChange('FirstName')}
                                                    value={values.FirstName}
                                                    onBlur={handleBlur('FirstName')}
                                                />
                                                {touched.FirstName && errors.FirstName ? <div className='error'>{errors.FirstName}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="Last Name"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='LastName'
                                                    id='LastName'
                                                    onChange={handleChange('LastName')}
                                                    value={values.LastName}
                                                    onBlur={handleBlur('LastName')}
                                                />
                                                {touched.LastName && errors.LastName ? <div className='error'>{errors.LastName}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="E-mail Address"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='Email'
                                                    id='Email'
                                                    onChange={handleChange('Email')}
                                                    value={values.Email}
                                                    onBlur={handleBlur('Email')}
                                                    disabled
                                                />
                                                {touched.Email && errors.Email ? <div className='error'>{errors.Email}</div> : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                
                                                <InputMask
                                                    mask='(999) 999-9999'
                                                    maskChar={null}
                                                    name='PhoneNumber'
                                                    fullWidth
                                                    size="small"
                                                    id='PhoneNumber'
                                                    onChange={(e) => { handleChange(e); }}
                                                    value={values.PhoneNumber}
                                                    onBlur={handleBlur('PhoneNumber')}
                                                   >
                                                    {(inputProps) =>
                                                        <TextField
                                                        label="Phone Number" variant="outlined"
                                                        {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                                {touched.PhoneNumber && errors.PhoneNumber ? <div className='error'>{errors.PhoneNumber}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="demo-multiple-name-label">User Type</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        name='UserType'
                                                        input={<OutlinedInput label="User Type" />}
                                                        id='UserType'
                                                        onBlur={handleBlur('UserType')}

                                                        onChange={(e) => {
                                                            changeUserType(e.target.value, values);
                                                            handleChange(e);
                                                        }}
                                                        value={values.UserType}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {userTypeList.map((usertype) => (
                                                            <MenuItem

                                                                value={usertype.id}
                                                                style={getStyles(usertype, personName, theme)}
                                                            >
                                                                {usertype.role_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>
                          
                                            </Grid>
                                            
                                            
                                            
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size='small' variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                    <OutlinedInput
                                                        type="text"
                                                        label="Password"
                                                        placeholder="Password"
                                                        input={<OutlinedInput label="Password" />}
                                                        size="small"
                                                        fullWidth
                                                        name='Password'
                                                        id='Password'
                                                        onChange={(e) => {
                                                            handlePasswordChange(e); 
                                                            handleChange(e);
                                                          }}
                                                        onKeyDown={handlePasswordClick} //hide show the password suggestion box
                                                        value={values.Password}
                                                        onBlur={handleBlur('Password')}
                                                        

                                                    />
                                                    {touched.Password && errors.Password ? <div className='error'>{errors.Password}</div> : null}
                                                    {showPasswordValidationBlock && (
                                                    <>
                                                          <div>
                                                            <span style={{ color: isLengthValid ? 'green' : 'red' }}>Minimum <b>8 characters</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isSpecialCharValid ? 'green' : 'red' }}>At least <b>one special character</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isUppercaseValid ? 'green' : 'red' }}>At least <b>one capital (uppercase)</b> letter</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isNumberValid ? 'green' : 'red' }}>At least <b>one number</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isLowercaseValid ? 'green' : 'red' }}>At least <b>one lowercase</b> letter </span>
                                                        </div>
                                                    </>
                                           )}
                                                </FormControl>
                                                <div>
                                                    <Typography sx={{ fontSize: "15px" }}>Leave password field blank to leave it unchanged</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                       
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} sx={{ justifyContent:"center",textAlign: "center", width: "100%" }}>
                                            <Grid item>
                                                <Button variant="contained" sx={{ minWidth: 200 }} color="success" onClick={handleSubmit}>
                                                    SAVE CHANGES
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" sx={{ minWidth: 200 }} onClick={() => {showPasswordValidationBlock=false;navigate(-1)}} >
                                                    CLOSE
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" sx={{ minWidth: 200 , backgroundColor : "red"}}  onClick={() => deleteUser()} >
                                                    Delete USER
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </div>
                    )}

                </Formik>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}


export default EditUser;