import React, { useMemo, useEffect, useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Typography,Box, Grid, Button,Tooltip, FormControl, MenuItem, InputLabel, Select, OutlinedInput, TableCell,ListItemText,ListItemIcon,Checkbox,TableRow, TableContainer, Paper, Stack } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import AuthUser from '../../../Components/Auth/AuthUser';
import config from '../../../AppConfig';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import { format, subDays } from 'date-fns';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Badge from '@mui/material/Badge';
import Table from '@mui/joy/Table';
import { Card,CardContent } from '@mui/joy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useStyles } from "../../../Components/SuspendedMembers/utils";
import useMediaQuery from '@mui/material/useMediaQuery';
import { 
	calculateNetRevenue, 
	calculateRevenueAfterCommission,
	calculateTotalProductCost,
	calculateLicenseFees,
	calculateGrossProfitValue,
	calculateGrossProfitPercentage,calendarFilter,useDateSelectionRange
} from '../../../Components/CommonFormula';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function CompanyReferralReport() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = useStyles();
	const { user } = AuthUser();
	const [data, setData] = useState([]);
	const [referalinfo, setReferalInfo] = useState([]);
	const [rowSelection, setRowSelection] = useState({});
	const [search, setSearch] = useState("");
	const q = search == '' ? 'null' : search;
	const [isLoading, setIsLoading] = useState(false);
	const [columnVisibility, setColumnVisibility] = React.useState({});
	const [softwareTypes, setSoftwareTypes] = React.useState([]);
	const [software, setSoftware] = React.useState("all");
	const [selected, setSelected] = useState([]);
	const [filter, setFilter] = useState("All Referral");
	const option = ["Software Referral", "All Referral"];
	const [btntext, setBtn] = useState("Switch To All Software");
	const [errorMessage, setErrorMessage] = useState('');
	const isMobile = useMediaQuery('(max-width:600px)');
	const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
	const tablePreferencesArray = {

	}
	useEffect(() => {
	}, [rowSelection]);

	////////////////////////
	useEffect(() => {
		if (Object.values(columnVisibility).length > 0) {
		setColumnVisibility(columnVisibility);
		}
		//do something when the row selection changes...

	}, [rowSelection]);

	useEffect(() => {
		tablePreferences();
	}, [rowSelection]);

	useEffect(() => {
		saveTablePreferences();
	}, [columnVisibility])
	////////////////////////

	const tablePreferences = async () => {
		const apiUrl = '/fetchTablePreferences/company-referrals';
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if (res) {
			if (Object.values(res).length == 0) {
				setColumnVisibility(tablePreferencesArray);
			} else {
				setColumnVisibility((JSON.parse(res.preferences)));
			}
		}
	}

	const saveTablePreferences = async () => {
		if (Object.values(columnVisibility).length > 0) {
		const apiUrl = '/saveTablePreferences';
		const args = {
			columnArray: columnVisibility,
			userId: user.id,
			tableId: 'company-referrals'
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		}
	}
	//loader function and variable start
	const [freez, setFreez] = React.useState(false);
	const handleFreezStop = () => {
		setFreez(false);
	};
	const handleFreezStart = () => {
		setFreez(true);
	};
	//loader function and variable end

	const fetchSoftware = async() => {
		const apiUrl = '/getSoftwareInfo?status=0';
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res){
			if (res.success === true) {
				setSoftwareTypes(res.data);
			}
		}
	}

	//Date range select function start
	const  ChangeFilter  = async (event) => {
		var value = event.target.value;
		setFilter(value);
	};

	
	//Clear date ranges start
	const sideBar = calendarFilter();
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];

	//Get referal info function start

	//fetchcompany list function start
	const GetReferalinfo = async (software) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id >= 2 ) {
			navigate('/login');
			return false;
		}
		handleFreezStart();
		const apiUrl = '/admin/referralReport';
		const args = {
		fromDate: startDate,
		toDate: endDate,
		softwareType: software,
		dataType: filter,

		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res.success == true) {
			setReferalInfo(res.data);
			setData(res.data);
			setIsLoading(false);
			handleFreezStop();
		}else{
			handleFreezStop();
		}
		
	};

	const showSoftware= async page =>{
		if(btntext == "Switch To All Software")
		{
			setBtn("Switch To All Company");
			const extractedArray = softwareTypes.map(arr => arr.link_name);
			setSoftware(extractedArray);
			setSelected(extractedArray);
			GetReferalinfo(extractedArray);
		}
		else
		{
			setBtn("Switch To All Software");
			setSoftware("all"); // Set the software back to "all" when switching back
       		GetReferalinfo("all");
		}

	}
	
  	//fetchcompany list function end

	const viewDetails = (id)=> {
		navigate('/totalcompanyperformance', { state: { startDate: startDate, endDate:endDate, id: id} });
	}
	const isAllSelected =
    softwareTypes.length > 0 && selected.length === softwareTypes.length;
	const handleSelectChange = (event) => {
		const {
			target: { value }
		  } = event;
		//   alert(value)

		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id < 2) {
			const selectedValue = event.target.value; // Get the selected value from event
			const extractedArray = softwareTypes.map(arr => arr.link_name);
			if (value.includes("allSoftware")) {
				setSelected(selected.length === softwareTypes.length ? [] : extractedArray);
				setSoftware(extractedArray);
				return;
			  }
			  setSelected(value);
			  setSoftware(selectedValue); 
		} else {
			navigate('/login');
			return false;
		}
	}
	
 
	useEffect(() => {
		if (user.roles_id < 3) {
			fetchSoftware();
			GetReferalinfo("all");
		} else {
			navigate('/login');
		}
	}, []);

	const totalreferredCompanyCount = useMemo(() => data.reduce((total, row) => total + row.referredCompanyCount, 0), [data]);
	const allAidMeberCount = useMemo(() => data.reduce((total, row) => total + row.allAidMeberCount, 0), [data]);
	const toTotalNetRevenue = useMemo(() => data.reduce((total, row) => total + row.allAidfinalAmount, 0), [data]);
	const totalAllAidcomputedCommission = useMemo(() => data.reduce((total, row) => total + row.allAidcomputedCommission, 0), [data]);
	const totalAllAidfinalAmount1 = useMemo(() => parseFloat(toTotalNetRevenue) - parseFloat(totalAllAidcomputedCommission), [toTotalNetRevenue, totalAllAidcomputedCommission]);
	
	const calculateGrossProfitSum = useMemo(() => {
		let sum = 0;
		data.forEach((row) => {
		  const rowValue = calculateGrossProfitValue(row)
		  sum += parseFloat(rowValue);
		});
		return sum;
	}, [data]);

	const profitabilitySum = useMemo(() => {
		let sum = 0;
		sum = ((calculateGrossProfitSum / toTotalNetRevenue) * 100).toFixed(1);
		return sum ? sum : 0;
	  }, [data]);

	//Get referal info function start
	const columns = useMemo(
    //column definitions...
    () => [
		{
			accessorFn: (data) => `${data.company_name ? data.company_name.trim() : ''}`, 
			filterVariant: 'range',
			id: 'CompanyName',
			header: 'Company Name',
			size: 50,
			enablePinning: true,
			Cell: ({ renderedCellValue, row }) => (row.original.company_name ? row.original.company_name.trim() : "N/A"),
		},
		{
		  accessorFn: (data) => `${data.marketing_type ? data.marketing_type.trim() : ''}`, 
		  filterVariant: 'range',
		  id: 'marketingType',
		  header: 'Marketing Type',
		  size: 50,
		  Cell: ({ renderedCellValue, row }) => (row.original.marketing_type ? row.original.marketing_type.trim() : "N/A"),
		},
		{
			accessorFn: (data) => `${data.aidListwithComma ? data.aidListwithComma.trim() : ''}`, 
			filterVariant: 'range',
			id: 'aid',
			header: 'AID',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.aidListwithComma ? row.original.aidListwithComma : "N/A"),
		},
		{
			accessorFn: (data) => `${data.referredCompanyCount}`,
			filterVariant: 'range',
			id: 'memberCount',
			header: 'Affiliate Referred',
			size: 100,
			muiTableBodyCellCopyButtonProps: {
			fullWidth: true,
			sx: { justifyContent: 'flex-center' },
			},
			Cell: ({ renderedCellValue, row }) => (
			<div style={{ textAlign: "center" }}>
				<Badge badgeContent={`${row.original.referredCompanyCount}`} color={row.original.referredCompanyCount === 0 ? 'primary' : 'success'} overlap="circular" max={99999}></Badge>
			</div>
			),
		},
		{
			accessorFn: (data) => `${data.allAidMeberCount ? data.allAidMeberCount: ''}`, 
			filterVariant: 'range',
			id: 'newEnrollments',
			header: 'New Enrollments',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.allAidMeberCount ==0 ? 0 :row.original.allAidMeberCount),
		},
		
		{
			accessorFn: (data) => `${data.allAidpaymentAmount ? data.allAidpaymentAmount : ''}`, 
			filterVariant: 'range',
			id: 'payment',
			header: 'Payments ($)',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.allAidpaymentAmount  == 0 ? 0 : (row.original.allAidpaymentAmount).toFixed(2)),
		},
		{
			accessorFn: (data) => `${data.allAidPaymentCount ? data.allAidPaymentCount : 0}`, 
			filterVariant: 'range',
			id: 'noofpayment',
			header: 'No of Payments',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.allAidthreeBAmount ? data.allAidthreeBAmount : ''}`, 
			filterVariant: 'range',
			id: '3bpayment',
			header: '3B Payments ($)',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.allAidthreeBAmount  == 0 ? 0 : (row.original.allAidthreeBAmount).toFixed(2) ),
		},
		{
			accessorFn: (data) => `${data.allAidchargeBackAmount ? data.allAidchargeBackAmount : ''}`, 
			filterVariant: 'range',
			id: 'chargebacks',
			header: 'Chargebacks ($)',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.allAidchargeBackAmount == 0 ? 0 : (row.original.allAidchargeBackAmount).toFixed(2) ),
		},
		{
			accessorFn: (data) => `${data.allAidrefundAmount ? data.allAidrefundAmount : ''}`, 
			filterVariant: 'range',
			id: 'Refunds',
			header: 'Refunds ($)',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.allAidrefundAmount == 0 ? 0 : (row.original.allAidrefundAmount).toFixed(2) ),
		},
		{
			accessorFn: (data) => `${data.allAidcomputedCommission ? data.allAidcomputedCommission : ''}`, 
			filterVariant: 'range',
			id: 'commissions',
			header: 'Commissions ($)',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.allAidcomputedCommission == 0 ? 0 : (row.original.allAidcomputedCommission).toFixed(2) ),
		},
		{
			accessorFn: (data) => `${data.allAidtotalAmount ? data.allAidtotalAmount : ''}`, 
			filterVariant: 'range',
			id: 'Total Payments',
			header: 'Total Payments ($)',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.allAidtotalAmount == 0 ? 0 : (row.original.allAidtotalAmount).toFixed(2) ),
		},
		{
			accessorFn: (data) => `${data.allAidtotalrefundAmount ? data.allAidtotalrefundAmount : ''}`, 
			filterVariant: 'range',
			id: 'Total Refunds',
			header: 'Total Refunds ($)',
			size: 50,
			Cell: ({ renderedCellValue, row }) => (row.original.allAidtotalrefundAmount == 0 ? 0 : (row.original.allAidtotalrefundAmount).toFixed(2) ),
		},
		{
			accessorFn: (data) => `${data.NetRevenue ? data.NetRevenue : 0}`, //`${ calculateNetRevenue(data) }`, 
			filterVariant: 'range',
			id: 'Net Revenue',
			header: 'Net Revenue ($)',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.revenueAfterCommission ? data.revenueAfterCommission : 0}`, //`${ calculateRevenueAfterCommission(data) }`, 
			filterVariant: 'range',
			id: 'Revenue after Commissions',
			header: 'Revenue after Commissions ($)',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.ACTION ? data.costDetails.ACTION.total : 0 : '' }`, 
			filterVariant: 'range',
			id: 'Action',
			header: 'ACTION',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.CREDIT_MONITORING ? data.costDetails.CREDIT_MONITORING.total : 0 : '' }`,  
			filterVariant: 'range',
			id: 'CREDIT_MONITORING',
			header: 'CREDIT_MONITORING',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.CREDIT_REPORT ? data.costDetails.CREDIT_REPORT.total : 0 : '' }`, 
			filterVariant: 'range',
			id: 'CREDIT_REPORT',
			header: 'CREDIT_REPORT',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.CREDIT_SCORE ? data.costDetails.CREDIT_SCORE.total : 0 : '' }`, 
			filterVariant: 'range',
			id: 'CREDIT_SCORE',
			header: 'CREDIT_SCORE',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.ID_FRAUD_INSURANCE ? data.costDetails.ID_FRAUD_INSURANCE.total : 0 : '' }`,
			filterVariant: 'range',
			id: 'ID_FRAUD_INSURANCE',
			header: 'ID_FRAUD_INSURANCE',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.IDMA ? data.costDetails.IDMA.total : 0 : '' }`,
			filterVariant: 'range',
			id: 'IDMA',
			header: 'IDMA',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.MEMBER_SUPPORT_CALL ? data.costDetails.MEMBER_SUPPORT_CALL.total : 0 : '' }`,
			filterVariant: 'range',
			id: 'MEMBER_SUPPORT_CALL',
			header: 'MEMBER_SUPPORT_CALL',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.MONEY_MANAGER ? data.costDetails.MONEY_MANAGER.total : 0 : '' }`,
			filterVariant: 'range',
			id: 'MONEY_MANAGER',
			header: 'MONEY_MANAGER',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.PRIVACY ? data.costDetails.PRIVACY.total : 0 : '' }`,
			filterVariant: 'range',
			id: 'PRIVACY',
			header: 'PRIVACY',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.SSN_LOOKUP ? data.costDetails.SSN_LOOKUP.total : 0 : '' }`,
			filterVariant: 'range',
			id: 'SSN_LOOKUP',
			header: 'SSN_LOOKUP',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.costDetails ? data.costDetails.THREE_BUREAU_REPORT ? data.costDetails.THREE_BUREAU_REPORT.total : 0 : '' }`,
			filterVariant: 'range',
			id: 'THREE_BUREAU_REPORT',
			header: 'THREE_BUREAU_REPORT',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.totalProductCost ? data.totalProductCost : 0 }`,          //`${ calculateTotalProductCost(data) }`,
			filterVariant: 'range',
			id: 'Total Product Cost',
			header: 'Total Product Cost ($)',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.licenseFees ? data.licenseFees : 0 }`,  //`${ calculateLicenseFees(data) }`,
			filterVariant: 'range',
			id: 'License Fee',
			header: 'License Fee ($)',
			size: 50,
		},
		{
			accessorFn: (data) => `${data.grossProfitValue ? data.grossProfitValue : 0 }`, //`${ calculateGrossProfitValue(data) }`, 
			filterVariant: 'range',
			id: 'Gross Profit',
			header: 'Gross Profit ($)',
			size: 50,
			field: 'grossProfit',
		},
		{
			accessorFn: (data) => `${data.grossProfitPercentage ? data.grossProfitPercentage : 0 }`, //`${ calculateGrossProfitPercentage(data) }`,
			filterVariant: 'range',
			id: 'profitability',
			header: 'Gross Profit (%)',
			size: 50,
		},    
		{
			accessorFn: (data) => ``,
			filterVariant: 'range',
			
			header: 'View Details',
			size: 50,
			Cell: ({ row }) => (
				<Box>
				{row.original.referredCompanyCount === 0 ? '' :  
					<>  
					<Tooltip title="Details" arrow><Button className='btn btn-sm btn-info text-white'  onClick={()=> viewDetails(`${row.original.id}`)} ><VisibilityIcon color="primary"/></Button></Tooltip>&nbsp;
					</> 
				}
			  </Box>
			  ),
		},

		]
	)
	
	//csv start here
	const csvOptions = {
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		fileName: 'Company Performance Report',
		headers: ['Company Name', 
		'First Name', 
		'Last Name', 
		'Email',
		'Phone no.',
		'Marketing Type',
		'Active Enrolled',
		'Aid',
		'Total Enrolled',
		'Affiliate Referred',
		'Cobrand Status',
		'Epic Pro Status',
		'New Enrollments',
		'Payments ($)',
		'No of Payments',
		'3B Payments ($)',
		'Chargebacks ($)',
		'Refunds ($)',
		'Commissions ($)',
		'Total Payments ($)',
		'Total Refunds ($)',
		'Net Revenue ($)',
		'Revenue after Commissions ($)',
		'ACTION',
		'CREDIT_MONITORING',
		'CREDIT_REPORT',
		'CREDIT_SCORE',
		'ID_FRAUD_INSURANCE',
		'IDMA',
		'MEMBER_SUPPORT_CALL',
		'MONEY_MANAGER',
		'PRIVACY',
		'SSN_LOOKUP',
		'THREE_BUREAU_REPORT',
		'Total Product Cost ($)',
		'License Fee ($)',
		'Gross Profit ($)',
		'Gross Profit (%)',
	],

	};

	const csvExporter = new ExportToCsv(csvOptions);
	const handleExportRows = (rows) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id >= 2 ) {
			navigate('/login');
			return false;
		}
		const exportedData = rows.map((row) => {
			const totalProductCost = calculateTotalProductCost(row);
			const licenseFee = calculateLicenseFees(row);
			const grossProfitValue  = calculateGrossProfitValue(row);
			const numerator = row.original.allAidfinalAmount - row.original.allAidcomputedCommission;
			const profitability =
			(
				(calculateNetRevenue(row.original)) !== 0 ?
					( ( calculateGrossProfitValue(row.original) / calculateNetRevenue(row.original) ) * 100 ).toFixed(1)
				: 0 
			);
				
			return {
				company_name: `${row.original.company_name}` || "N/A",
				first_name: `${row.original.first_name}` || "N/A",
				last_name: `${row.original.last_name}` || "N/A",
				email: `${row.original.email}` || "N/A",
				phone: `${row.original.phone_no}` || "N/A",
				marketing_type: `${row.original.marketing_type ||'N/A'}`,
				active_enrolled:`${row.original.allAidactiveMeberCount || '0'}`,
				aid: `${row.original.aidListwithComma ||'N/A'}`,
				total_enrolled:`${row.original.allAidMeberCount||'0'}`,
				affiliate_referred: `${row.original.referredCompanyCount||'0' }`,
				cobrand_status:`${row.original.cobranding_status||'0' }`,
				epic_pro_status:`${row.original.epic_pro_status||'0' }`,
				new_enrollment:`${row.original.allAidMeberCount ==0 ? 0 :row.original.allAidMeberCount}`,
				payment:`${row.original.allAidpaymentAmount}`,
				no_payment:`${row.original.allAidPaymentCount}`,
				threeb_payment:`${row.original.allAidthreeBAmount}`,
				chargebacks:`${row.original.allAidchargeBackAmount}`,
				refund:`${row.original.allAidrefundAmount}`,
				commission:`${row.original.allAidcomputedCommission}`,
				total_payment:`${row.original.allAidtotalAmount}`,
				total_refund:`${row.original.allAidtotalrefundAmount}`,
				net_revenu:`${row.original.allAidfinalAmount}`,
				revenue_after_commission: `${(row.original.allAidfinalAmount - row.original.allAidcomputedCommission).toFixed(2)}`,
				action: `${row.original.costDetails?.ACTION?.total || '0' }`,
				credit_monitoring: `${row.original.costDetails && row.original.costDetails.CREDIT_MONITORING ? parseFloat(row.original.costDetails.CREDIT_MONITORING.total) : '0'}`,
				credit_report: `${row.original.costDetails && row.original.costDetails.CREDIT_REPORT ? parseFloat(row.original.costDetails.CREDIT_REPORT.total) : '0' }`,
				credit_score: `${row.original.costDetails && row.original.costDetails.CREDIT_SCORE ? parseFloat(row.original.costDetails.CREDIT_SCORE.total)  : '0'}`,
				id_fraud_insurance: `${row.original.costDetails && row.original.costDetails.ID_FRAUD_INSURANCE ? parseFloat(row.original.costDetails.ID_FRAUD_INSURANCE.total) : '0'}`,
				idma: `${row.original.costDetails && row.original.costDetails.IDMA ? parseFloat(row.original.costDetails.IDMA.total)  : '0'}`,
				member_support_call: `${row.original.costDetails && row.original.costDetails.MEMBER_SUPPORT_CALL ? parseFloat(row.original.costDetails.MEMBER_SUPPORT_CALL.total) : '0'}`,
				money_manager: `${row.original.costDetails && row.original.costDetails.MONEY_MANAGER ? parseFloat(row.original.costDetails.MONEY_MANAGER.total) : '0'}`,
				privacy: `${row.original.costDetails && row.original.costDetails.PRIVACY ? parseFloat(row.original.costDetails.PRIVACY.total) : '0'}`,
				ssn_lookup: `${row.original.costDetails && row.original.costDetails.SSN_LOOKUP ? parseFloat(row.original.costDetails.SSN_LOOKUP.total) : '0'}`,
				three_bureau_report: `${row.original.costDetails && row.original.costDetails.THREE_BUREAU_REPORT ? parseFloat(row.original.costDetails.THREE_BUREAU_REPORT.total)  : '0'}`,
				totalProductCost: 
				`${row.original.costDetails ? (
					(
					  (row.original.costDetails.ACTION ? (row.original.costDetails.ACTION.total * 0.3) : 0) +
					  (row.original.costDetails.CREDIT_MONITORING ? (row.original.costDetails.CREDIT_MONITORING.total * 0.1) : 0) +
					  (row.original.costDetails.CREDIT_REPORT ? (row.original.costDetails.CREDIT_REPORT.total * 0.3) : 0) +
					  (row.original.costDetails.CREDIT_SCORE ? (row.original.costDetails.CREDIT_SCORE.total * 0.06) : 0) +
					  (row.original.costDetails.ID_FRAUD_INSURANCE ? (row.original.costDetails.ID_FRAUD_INSURANCE.total * 0.25) : 0) +
					  (row.original.costDetails.IDMA ? (row.original.costDetails.IDMA.total * 0.5) : 0) +
					  (row.original.costDetails.MONEY_MANAGER ? (row.original.costDetails.MONEY_MANAGER.total * 0.75) : 0) +
					  (row.original.costDetails.PRIVACY ? (row.original.costDetails.PRIVACY.total * 0.15) : 0) +
					  (row.original.costDetails.SSN_LOOKUP ? (row.original.costDetails.SSN_LOOKUP.total * 0.15) : 0) +
					  (row.original.costDetails.THREE_BUREAU_REPORT ? (row.original.costDetails.THREE_BUREAU_REPORT.total * 9.00) : 0)
					).toFixed(2)
				  ) : 0}`,
				licenseFee: 
				   `${row.original.allAidPaymentCount ? (row.original.allAidPaymentCount * 1.75).toFixed(2) : 0}`,
				  
				grossProfitValue: 
				  `${
					(
					  (
						(row.original.allAidfinalAmount ? (row.original.allAidfinalAmount - row.original.allAidcomputedCommission).toFixed(2) : 0) -
						(row.original.allAidPaymentCount ? (row.original.allAidPaymentCount * 1.75).toFixed(2) : 0) -
						(row.original.costDetails ? (
						  (row.original.costDetails.ACTION ? (row.original.costDetails.ACTION.total * 0.3) : 0) +
						  (row.original.costDetails.CREDIT_MONITORING ? (row.original.costDetails.CREDIT_MONITORING.total * 0.1) : 0) +
						  (row.original.costDetails.CREDIT_REPORT ? (row.original.costDetails.CREDIT_REPORT.total * 0.3) : 0) +
						  (row.original.costDetails.CREDIT_SCORE ? (row.original.costDetails.CREDIT_SCORE.total * 0.06) : 0) +
						  (row.original.costDetails.ID_FRAUD_INSURANCE ? (row.original.costDetails.ID_FRAUD_INSURANCE.total * 0.25) : 0) +
						  (row.original.costDetails.IDMA ? (row.original.costDetails.IDMA.total * 0.5) : 0) +
						  (row.original.costDetails.MONEY_MANAGER ? (row.original.costDetails.MONEY_MANAGER.total * 0.75) : 0) +
						  (row.original.costDetails.PRIVACY ? (row.original.costDetails.PRIVACY.total * 0.15) : 0) +
						  (row.original.costDetails.SSN_LOOKUP ? (row.original.costDetails.SSN_LOOKUP.total * 0.15) : 0) +
						  (row.original.costDetails.THREE_BUREAU_REPORT ? (row.original.costDetails.THREE_BUREAU_REPORT.total * 9.00) : 0)
						) : 0)
					  ).toFixed(2)
					)
				  }`,  // Make sure there is a closing backtick here
				  profitability: profitability,
			};
		});
		const csvContent = convertToCSV(exportedData);
		// Create a Blob object from the CSV data
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

		// Create a download link element
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = csvOptions.fileName + '.csv';

		// Append the link to the document body
		document.body.appendChild(link);

		// Programmatically click the link to trigger the file download
		link.click();

		// Clean up the temporary URL and remove the link from the document
		URL.revokeObjectURL(link.href);
		link.remove();
  	};

	const convertToCSV = (data) => {
		const headers = csvOptions.headers.join(',') + '\n';

		const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string') {
					value = value.replace(/[\r\n]+/g, ' ');
				  }
			
				  // Wrap the value with double quotes if it contains a comma or special characters
				  if (typeof value === 'string' && /[,"]/.test(value)) {
					return `"${value.replace(/"/g, '""')}"`;
				  }

				return value;
			}).join(',');
		}).join('\n');

		return headers + rows;
	};
	
	//csv end here
	const ContentLayout=
		<>
			{errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
			<Grid container item lg={12} md={12} sm={12} xs={12} mb={2} sx={{ justifyContent: "space-between" }}>
				<Typography color="primary" level="h4">Company Performance</Typography>
			</Grid>
			
			<Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} sx={{minWidth:"1400px !important",maxWidth:"1400px !important"}}>
				<Grid item lg={3} md={3} sm={3} xs={3} mt={1}>
					<FormControl fullWidth size='small'>
					<InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
					<Select
						labelId="demo-multiple-name-label"
						id="demo-multiple-name"
						value="date"
						input={<OutlinedInput label="Select Date Range" />}
					>
						<MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
						<DateRangePicker
						className='custom-date-picker'
						onChange={item => setSelectionRange([item.selection])}
						showSelectionPreview={true}
						moveRangeOnFirstSelection={false}
						months={2}
						ranges={selectionRange}
						direction="horizontal"
						maxDate={new Date()}
						staticRanges={staticRanges}
						/>
					</Select>
					</FormControl>
				</Grid>
				{btntext == "Switch To All Company" ? (
					 <>
				<Grid item lg={3} md={3} sm={3} xs={3}>
					
					<FormControl fullWidth size="small" margin="dense"
                >
                  <InputLabel>Software Used</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    id="software"
                    name="software"
                    input={<OutlinedInput label="Software Used"  />}
                    value={selected}
                    onChange={handleSelectChange}
                    renderValue={(select) => {
						if (select.includes("allSoftware") || select.length === softwareTypes.length) {
							return "All Software";
						} else {
							return select
								.map((link_name) => {
									const selectedOption = softwareTypes.find((option) => option.link_name === link_name);
									return selectedOption ? selectedOption.link_name : '';
								})
								.join(", ");
						}
					}}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
					value="allSoftware"
					classes={{
						root: isAllSelected ? classes.selectedAll : "All Software"
					}}
				>
					<ListItemIcon>
						<Checkbox
							classes={{ indeterminate: classes.indeterminateColor }}
							checked={isAllSelected}
							indeterminate={false} // No need for indeterminate state when "Select All Software" is checked
						/>
					</ListItemIcon>
					<ListItemText
						classes={{ primary: classes.selectAllText }}
						primary="Select All Software"
					/>
				</MenuItem>

				{softwareTypes.map((option) => (
					<MenuItem key={option.link_name} value={option.link_name}>
						<ListItemIcon>
							<Checkbox checked={selected.indexOf(option.link_name) > -1} />
						</ListItemIcon>
						<ListItemText primary={option.software_name} />
					</MenuItem>
				))}
			</Select>
                 
                </FormControl>
				</Grid>
				<Grid item lg={3} md={3} sm={3} xs={3}>
					<FormControl fullWidth margin='dense' size="small" >
						<InputLabel id="demo-multiple-name-label">Lead Type</InputLabel>
						<Select
							margin="dense"
							labelId="demo-multiple-name-label"
							id="demo-multiple-name"
							value={filter}
							onChange={(e) => {ChangeFilter(e)}}
							input={<OutlinedInput label="Select Filter" />}
							>
							{option.map((name) => (
								<MenuItem
								key={name}
								value={name}
								>
								{name}
								</MenuItem>
							))}
							</Select>
					</FormControl>
				</Grid>
				</>
				 ) : 
				 null
				 }
			
				<Grid item lg={2} md={2} sm={2} xs={2}  mt={1}>
					<Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={() => {
					if (btntext === "Switch To All Company") {
            			if (selected.length === 0) {
                		setErrorMessage("Please select at least one software.");
                		setTimeout(() => {
						setErrorMessage("");
                		}, 3000);
						} else {
						GetReferalinfo(software);
						setErrorMessage("");
						}
					} else {
            		GetReferalinfo(software);
        			}
					}} > Submit</Button>
				</Grid>
				<Grid item lg={3} md={3} sm={12} xs={12}  mt={1}>
					<Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={showSoftware} >{btntext}</Button>
				</Grid>
			</Grid>
			<Grid item container lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
				<Card className='cardstyle' sx={{ minWidth:isMobile ?"100%":1300,maxWidth:isMobile ?"100%":1300}}>
					<CardContent>
						<Table borderAxis="none" size="sm" variant="plain">
							<thead>
								<tr>
									<th className="borderStyle custom-table-cell"><Typography variant="subtitle1" gutterBottom>Total Affiliate Referred</Typography></th>
									<th className="borderStyle custom-table-cell"><Typography variant="subtitle1" gutterBottom>Total New Enrollments</Typography></th>
									<th className="borderStyle custom-table-cell"><Typography variant="subtitle1" gutterBottom>Total Net Revenue</Typography></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="borderStyle details custom-table-cell"><Typography variant="h5" gutterBottom><b>{totalreferredCompanyCount ? (totalreferredCompanyCount) : 0 }</b></Typography></td>
									<td className="borderStyle datatable custom-table-cell"><Typography variant="h5" gutterBottom><b>{allAidMeberCount ? (allAidMeberCount) : 0 }</b></Typography></td>
									<td className="borderStyle datatable custom-table-cell"><Typography variant="h5" gutterBottom><b>{toTotalNetRevenue ? (toTotalNetRevenue).toFixed(2) : 0 } </b></Typography></td>
								</tr>
							</tbody>
							<thead>
								<tr>
									<th className="borderStyle custom-table-cell"><Typography variant="subtitle1" gutterBottom>Total Revenue after Commissions ($)</Typography></th>
									<th className="borderStyle custom-table-cell"><Typography variant="subtitle1" gutterBottom>Gross Profit ($)</Typography></th>
									<th className="borderStyle custom-table-cell"><Typography variant="subtitle1" gutterBottom>Gross Profit (%)</Typography></th> 
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="borderStyle details custom-table-cell"><Typography variant="h5" gutterBottom><b>{totalAllAidfinalAmount1 ? (totalAllAidfinalAmount1).toFixed(2) : 0 }</b></Typography></td>
									<td className="borderStyle custom-table-cell"><Typography variant="h5" gutterBottom><b>{calculateGrossProfitSum ? (calculateGrossProfitSum).toFixed(2) : 0 }</b></Typography></td>
									<td className="borderStyle custom-table-cell"><Typography variant="h5" gutterBottom><b>{profitabilitySum}%</b></Typography></td>
								</tr>
							</tbody>
						</Table>
					</CardContent>
				</Card>
			</Grid>
			<MaterialReactTable
				columns={columns}
				data={referalinfo}
				filterFns={{
					customSearchFilterFn: (row, id, filterValue) =>
					row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
				}}                                   
				globalFilterFn="customSearchFilterFn" //exact serach filter functionality
				enableColumnOrdering={false}
				enableClickToCopy={true}
				enableColumnFilterModes
				enablePinning
				enableColumnFilters={config.DatatableColumnFilter}
				getRowId={(row) => row.id} //give each row a more useful id
				onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
				state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
				initialState={{
					showColumnFilters: false,
					density: 'compact',
					columnPinning: { left: ['mrt-row-expand','CompanyName'] }
				}}

				renderDetailPanel={({ row }) => (
					<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						alignItems: 'center',
					}}
					>
					<Stack>
						<TableContainer component={Paper}>
							<Table className='back'>
								<TableRow>
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>First Name: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.first_name ? row.original.first_name.trim() : ''}</Typography>
									</Grid>
								</TableCell>
								</TableRow> 
								<TableRow>
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>Last Name: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.last_name ? row.original.last_name.trim() : ''}</Typography>
									</Grid>
								</TableCell>                    
								</TableRow> 
								<TableRow>
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>Email: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.email ? row.original.email.trim() : ''}</Typography>
									</Grid>
								</TableCell>                             
								</TableRow> 
								<TableRow> 
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>Phone No.: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.phone_no ? row.original.phone_no.trim() : ''}</Typography>
									</Grid>
								</TableCell>
								</TableRow> 
								<TableRow>
								<TableCell>
									<Grid container direction="row" justifyContent="flex-start" alignContent="center">
									<Typography item level='title-sm' sx={{ fontWeight: 'bold' }}>Marketing Type.: &nbsp;&nbsp;</Typography>
									<Typography item>{row.original.marketing_type ? row.original.marketing_type.trim() : ''}</Typography>
									</Grid>
								</TableCell>
								</TableRow>                                                                         
							</Table>
						</TableContainer>
						<TableContainer component={Paper}><Table><TableRow></TableRow></Table></TableContainer>
						<TableContainer component={Paper}>
						<Table>
							<tbody>
								<TableRow>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>AID</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Total Enrolled</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Active Enrolled</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Payments</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>3B Payment</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Chargebacks</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Refund</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}> Commissions</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Total Payments</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Total Refunds</TableCell>
									<TableCell align="center" sx={{ fontWeight: "bold" }}>Net Revenue</TableCell>
								</TableRow>
									{row.original.commissionDetails.map((commissionDetail) => (
									<>
									<TableRow key={commissionDetail.aid}>
										<TableCell align="center">{commissionDetail.aid}</TableCell>
										<TableCell align="center">{commissionDetail.allMeberCount}</TableCell>
										<TableCell align="center">{commissionDetail.activeMeberCount}</TableCell>
										<TableCell align="center">{commissionDetail.paymentAmount}</TableCell>
										<TableCell align="center">{commissionDetail.threeBAmount}</TableCell>
										<TableCell align="center">{commissionDetail.chargeBackAmount}</TableCell>
										<TableCell align="center">{commissionDetail.refundAmount}</TableCell>
										<TableCell align="center">{commissionDetail.computedCommission}</TableCell>
										<TableCell align="center">{commissionDetail.totalAmount}</TableCell>
										<TableCell align="center">{commissionDetail.allRefundAmount}</TableCell>
										<TableCell align="center">{commissionDetail.finalAmount}</TableCell>
									</TableRow>
									</>
								))}
							</tbody>
						</Table>
						</TableContainer>
					</Stack>
					</Box>
				)}
				//my code for expanded row
				onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
				positionToolbarAlertBanner="bottom"
				enableDensityToggle={false}
				renderTopToolbarCustomActions={({ table }) => (
					<Box
					sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
					>
					<Button
						color="primary"
						//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
						disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() =>
										handleExportRows(table.getPrePaginationRowModel().rows)
									}
						startIcon={<FileDownloadIcon />}
						variant="contained"
					>
						CSV
					</Button>


					</Box>
				)}

				
			/>
		</>
 
	return (
		<div>
		<DefaultLayout content={ContentLayout} />
		{/* loader code start */}
		<div>
			<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={freez}
			>
			<Freez />
			</Backdrop>
		</div>
		{/* loader code end */}
		</div>
	);
}


export default CompanyReferralReport;