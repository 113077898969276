import React,{useMemo,useState,useEffect} from 'react';
import {useParams, useNavigate, Link,useLocation} from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid,InputLabel,MenuItem, FormControl, Select,Box,Button,Dialog,DialogActions ,DialogTitle,DialogContentText,DialogContent,Tooltip,ListItemIcon, ListItemText,Checkbox,Tab } from '@mui/material';
import { Typography,Table, Option,} from '@mui/joy';
import { Stack } from '@mui/system';
import { styled } from '@mui/material/styles';
import CommissionView from '../../../Components/CommissionSummary/CommisionView';
import ReferralCommissionView from '../../../Components/CommissionSummary/ReferralCommissionView';
import AuthUser from "../../../Components/Auth/AuthUser";
import { useTheme } from '@mui/material/styles';
import Loader from '../../../Components/Loader/Loader';
import { useStyles, options } from "../Dashboard/utils";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { postData } from '../../../redux/apiSlice';

const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
          const MenuProps = {
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
          };

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

function CommissionViewDetails(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [personName, setPersonName] = React.useState([]);
    const {state} = useLocation();
    const { http, user } = AuthUser();
    const { companyId, totalbilling,commision,referralCommission,referredCompanyCommissionDetailsArr,initialBonus,bonusDetails,finalTotalCommission,status,itemDetails,aids,targetCommission,month,year,monthValue} = state;
    const[companyName, setCompanyName] = React.useState();
    const[totalBillLabel, setTotalBillLabel] = React.useState();
    const[totalCommissionLabel, setTotalCommissionLabel] = React.useState();
    const[statusLabel, setStatus] = React.useState();
    const[referralCommissionLabel, setReferralCommissionLabel] = React.useState();
    const[initialBonusLabel, setInitialBonusLabel] = React.useState();
    const[finalTotalCommissionLabel, setFinalTotalCommissionLabel] = React.useState();
    const[referralCommissionList, setReferralCommissionList] = React.useState([]);
    const[bonusCommissionList, setBonusCommissionList] = React.useState([]);
    const [aidList, setAidList]=useState([]);
    const [itemList, setItemList]=useState([]);
    const [requestItemList, setRequestItemList]=useState([]);
    const [approveDisable, setApproveDisable] = React.useState(true);
    const [resetDisable, setResetDisable] = React.useState(true);
    const [saveDisable, setSaveDisable] = React.useState(true);
    const [refreshText, setRefreshText] = React.useState('');
    const [aid, setAid] = useState('all');
    const [selected, setSelected] = useState(aids);
    const classes = useStyles();
    const isAllSelected = aidList.length > 0 && selected.length === aidList.length;
    const [value, setValue] = React.useState('1');
    const[targetBonusLabel, setTargetBonusLabel] = React.useState();
    const[enrollmentTargetLabel, setEnrollmentTargetLabel] = React.useState('0');
    const[enrollmentAchievedLabel, setEnrollmentAchievedLabel] = React.useState('0');
    const[totalEnrollmentTargetLabel, setTotalEnrollmentTargetLabel] = React.useState('0');
    const[totalEnrollmentAchievedLabel, setTotalEnrollmentAchievedLabel] = React.useState('0');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const fetchCommissionViewDetails =async (itemDetails, billing, totalcommission,referralCommission,referredCompanyCommissionDetailsArr,initialBonus,bonusDetails,targetCommission,finalTotalCommission) =>{
             
        const apiUrl = '/commission-view-details';
        const args = {
            companyId : companyId,
            itemTotalbilling:billing,
            itemTotalcommission : totalcommission,
            referralCommission : referralCommission,
            referredCompanyCommissionDetails : JSON.stringify(referredCompanyCommissionDetailsArr),
            initialBonus : initialBonus,
            bonusDetails : JSON.stringify(bonusDetails),
            finalTotalCommission : finalTotalCommission,
            itemReportStatusName : status,
            itemDetails : JSON.stringify(itemDetails),
            targetCommission : targetCommission,
            aids : JSON.stringify({aids}),
            dateData: JSON.stringify({year :year,monthvalue:monthValue,month: month }),
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
                if(res.success === true){
                    setCompanyName(res.companyName ? res.companyName.company_name : '');
                    setTotalBillLabel(res.itemTotalbilling ? res.itemTotalbilling : '');
                    setTotalCommissionLabel(res.itemTotalcommission ? res.itemTotalcommission : '');
                    setStatus(res.status ? res.status : '');
                    setReferralCommissionLabel(res.itemReferralCommission ? res.itemReferralCommission : '0');
                    setInitialBonusLabel(res.itemInitialBonus ? res.itemInitialBonus : '0');
                    setFinalTotalCommissionLabel(res.itemFinalTotalCommission ? res.itemFinalTotalCommission : '0');
                    setAidList(res.otherAids ? res.otherAids : []);
                    setItemList(res.itemDetails ? res.itemDetails : []);
                    setReferralCommissionList(res.itemDetails ? res.itemReferredCompanyCommissionDetails : []);
                    setBonusCommissionList(res.itemBonusDetails ? res.itemBonusDetails : []);
                    setRequestItemList(res.itemDetails ? res.itemDetails : []);
                    setApproveDisable(res.status ? (res.status == "Pending" ? false : (res.status == "Saved" ?  false : true)) : true);
                    setResetDisable(res.status ? (res.status == "Approved" ? false : true) : true);
                    setTargetBonusLabel(res.itemTargetBonus ? res.itemTargetBonus : '0');
                    setEnrollmentTargetLabel(res.enrollmentTarget ? res.enrollmentTarget : '0');
                    setEnrollmentAchievedLabel(res.enrollmentTarget ? res.enrollmentAchieved : '0');
                    setTotalEnrollmentTargetLabel(res.totalEnrollmentTarget ? res.totalEnrollmentTarget : '0');
                    setTotalEnrollmentAchievedLabel(res.totalEnrollmentAchieved ? res.totalEnrollmentAchieved : '0');
                    
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                }
            }

      }

    useEffect(() => {
        if(user.roles_id < 3) {
            fetchCommissionViewDetails(itemDetails,totalbilling,commision,referralCommission,referredCompanyCommissionDetailsArr,initialBonus,bonusDetails,targetCommission,finalTotalCommission);
        }else{
            navigate('/login');
        }
       
    }, [itemDetails,totalbilling,commision]);


    const setNewItem = (newItemList) => {
        setItemList(newItemList);
    };

    const setNewCommissionTotal = (total) => {
        setTotalCommissionLabel(total);
        var targetFlag = false;
        const tBonus = (parseFloat(total) * 25/100).toFixed(2);
        if(parseFloat(targetBonusLabel) > 0) {
            targetFlag = true;
            setTargetBonusLabel(tBonus)
        }

        var total = parseFloat(total) + parseFloat(referralCommissionLabel) + parseFloat(initialBonusLabel);
        if(targetFlag == true){
            total = parseFloat(total) + parseFloat(tBonus);
        }
        setFinalTotalCommissionLabel(total.toFixed(2));
    };

    const enableSaveButton = () => {
        if(statusLabel != "Approved") {
            setSaveDisable(false);
        }else{
            setSaveDisable(true);
        }
      
    }

    const checkUserRole = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }else{
            return true;
        }
    };

    const handleClickOpen =async (sendMailFlag) => {
        var check = checkUserRole();
        if(check === true) {
            setIsLoading(true);
            const apiUrl = '/approve-send-commission';
            const args = {
                aids : JSON.stringify({aids}),
                itemTotalbilling:totalbilling,
                itemTotalcommission : commision,
                itemReportStatusName : status,
                itemDetails : JSON.stringify(itemList),
                // initialBonus : initialBonusLabel,
                // bonusDetails : JSON.stringify(bonusCommissionList),
                // referralCommission : referralCommissionLabel,
                // referredCommissionDetails : JSON.stringify(referralCommissionList),
                // finalTotalCommission : finalTotalCommissionLabel,
                dateData: JSON.stringify({year :year,monthvalue:monthValue,month: month }),
                sendMailFlag: sendMailFlag
            };
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if(res.success === true){
                    setIsLoading(false);
                    if(res.dataArray.status === "approved"){
                        setRefreshText("Commission details approved.")
                    }else if(res.dataArray.status === "approvedsend"){
                        setRefreshText("Commission details approved and sent notification.")
                    }
                
                    setTotalCommissionLabel(res.dataArray ? res.dataArray.total_monthly_commission : '');
                    setTotalBillLabel(res.dataArray ? res.dataArray.total_monthly_billing : '');
                    setOpen(true);
                    fetchCommissionViewDetails(itemList,totalBillLabel,totalCommissionLabel,referralCommissionLabel,referralCommissionList,initialBonusLabel,bonusCommissionList,targetBonusLabel,finalTotalCommissionLabel);
                    setSaveDisable(true);
                }else{
                    setIsLoading(false);
                }

            }
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen1 = () => {
        var check = checkUserRole();
        if(check == true) {
            setOpen1(true);
        }
        
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const  ChangeAids  = async (event) => {
        var check = checkUserRole();
        if(check === true) {
            var value = event.target.value;
            var allAids = Array();
            aidList.forEach(element => {
                allAids.push(element['aid']);
            });
            if (value[value.length - 1] === "all") {
                setSelected(selected.length === aidList.length ? [] : allAids);
            }else {
                setSelected(value);
            }
         
            if(value.includes("all")) {
                value = allAids;
                setSelected(value);
            }
           
            setIsLoading(true);
            var sendAid = JSON.stringify({value});
            sendAid = sendAid.replace(/"value":/g, '"aids":');

            const apiUrl = '/commission-view-details';
            const args = {
                companyId : companyId,
                itemTotalbilling:totalBillLabel,
                itemTotalcommission : totalCommissionLabel,
                referralCommission : referralCommissionLabel,
                referredCompanyCommissionDetails : JSON.stringify(referredCompanyCommissionDetailsArr),
                initialBonus : initialBonus,
                bonusDetails : JSON.stringify(bonusDetails),
                finalTotalCommission : finalTotalCommissionLabel,
                itemReportStatusName : status,
                itemDetails : JSON.stringify(itemDetails),
                targetCommission : targetBonusLabel,
                aids : sendAid,
                dateData: JSON.stringify({year :year,monthvalue:monthValue,month: month}),
            };
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if(res.success === true){
                    setCompanyName(res.companyName ? res.companyName.company_name : '');
                    setTotalBillLabel(res.itemTotalbilling ? res.itemTotalbilling : '');
                    setTotalCommissionLabel(res.itemTotalcommission ? res.itemTotalcommission : '');
                    setStatus(res.status ? res.status : '');
                    setReferralCommissionLabel(res.itemReferralCommission ? res.itemReferralCommission : '0');
                    setInitialBonusLabel(res.itemInitialBonus ? res.itemInitialBonus : '0');
                    setFinalTotalCommissionLabel(res.itemFinalTotalCommission ? res.itemFinalTotalCommission : '0');
                    setAidList(res.otherAids ? res.otherAids : []);
                    setItemList(res.itemDetails ? res.itemDetails : []);
                    setReferralCommissionList(res.itemDetails ? res.itemReferredCompanyCommissionDetails : []);
                    setBonusCommissionList(res.itemBonusDetails ? res.itemBonusDetails : []);
                    setRequestItemList(res.itemDetails ? res.itemDetails : []);
                    setApproveDisable(res.status ? (res.status == "Pending" ? false : true) : true);
                    setResetDisable(res.status ? (res.status == "Approved" ? false : true) : true);
                    setTargetBonusLabel(res.itemTargetBonus ? res.itemTargetBonus : '0');
                    setEnrollmentTargetLabel(res.enrollmentTarget ? res.enrollmentTarget : '0');
                    setEnrollmentAchievedLabel(res.enrollmentTarget ? res.enrollmentAchieved : '0');
                    setTotalEnrollmentTargetLabel(res.totalEnrollmentTarget ? res.totalEnrollmentTarget : '0');
                    setTotalEnrollmentAchievedLabel(res.totalEnrollmentAchieved ? res.totalEnrollmentAchieved : '0');

                    if(aidList.length != value.length) {
                        setResetDisable(true);
                        setApproveDisable(true);
                        setSaveDisable(true);
                    }else {
                        switch(statusLabel)
                        {
                            case "Approved" : setResetDisable(false);
                                              setApproveDisable(true);
                                              break;
                            case "Pending" : setResetDisable(true);
                                             setApproveDisable(false);
                                             break;
                            case "Saved" : setResetDisable(false);
                                           setApproveDisable(true);
                                           break;
                        }
                    }
    
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                }
            }
        }
       
    };

   

    const resetToPending = async page => {
        setOpen1(false);
        setIsLoading(true);

        const apiUrl = '/unapprove-commission';
        const args = {
            aids : JSON.stringify({aids}),
            itemTotalbilling:totalbilling,
            itemTotalcommission : commision,
            itemReportStatusName : status,
            itemDetails : JSON.stringify(requestItemList),
            dateData: JSON.stringify({year :year,monthvalue:monthValue,month: month }),
        };
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            if(res.success === true){
                setIsLoading(false);
                setTotalCommissionLabel(res.total_monthly_commission ? res.total_monthly_commission : '');
                setTotalBillLabel(res.total_monthly_billing ? res.total_monthly_billing : '');
                if(res.data.status === "unapproved"){
                    setRefreshText("Commission details has been reset to pending.")
                }else if(res.data.status === "refreshed"){
                    setRefreshText("Commission details has been refreshed.")
                }

                setOpen(true);
                fetchCommissionViewDetails(itemList,totalBillLabel,totalCommissionLabel,referralCommissionLabel,referralCommissionList,initialBonusLabel,bonusCommissionList,targetBonusLabel,finalTotalCommissionLabel);
            }else{
                setIsLoading(false);
            }
        }
    };

    const saveChanges =async () => {
        var check = checkUserRole();
        if(check === true) {
            setIsLoading(true);
            const apiUrl = '/save-commission-changes';
            const args = {
                aids : JSON.stringify({selected}),
                itemDetails : JSON.stringify(itemList),
                dateData: JSON.stringify({year :year,monthvalue:monthValue,month: month }),
            };
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if(res.success === true){
                    setIsLoading(false);
                    setSaveDisable(true);
                    setResetDisable(true);
                    setRefreshText("Changes Saved");
                    setOpen(true);
                    
                }else{
                    setIsLoading(false);
                }
            }
        }
    };

    const ContentLayout =
        <>
            {isLoading?<Loader/>:<></>}
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"space-between"}}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography level="h4" color="primary">Commission View Details : {month} {year}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} sx={{textAlign:"right"}}>
                <Tooltip title="Close" >
                    <CancelOutlinedIcon onClick={() => navigate(-1)} />
                </Tooltip>
                </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                <Grid container item lg={3} md={3} sm={3} xs={3} >
                    <Typography>Company Name : <b>{companyName ? companyName : ''}</b></Typography>
                </Grid>
                <Grid container item lg={3} md={3} sm={3} xs={3} >
                    <Typography>Total Billing : <b>{totalBillLabel ? "$" + parseFloat(totalBillLabel).toFixed(2) : '$0.00'}</b></Typography>
                </Grid>
                <Grid container item lg={3} md={3} sm={3} xs={3} >
                    <Typography> Commission : <b>{totalCommissionLabel ? "$" + parseFloat(totalCommissionLabel).toFixed(2) : '$0.00'} </b></Typography>
                </Grid>
                <Grid container item lg={3} md={3} sm={3} xs={3} >
                    <Typography>Referral Commission: <b>{referralCommissionLabel ? "$" + parseFloat(referralCommissionLabel).toFixed(2) : '$0.00'}</b></Typography>
                </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                <Grid container item lg={3} md={3} sm={3} xs={3} >
                    <Typography>Referral Bonus : <b>{initialBonusLabel ? "$" + parseFloat(initialBonusLabel).toFixed(2) : '$0.00'}</b></Typography>
                </Grid>
                {
                    enrollmentTargetLabel != 0 ?
                    <>
                        <Grid container item lg={3} md={3} sm={3} xs={3} >
                            <Typography>Target Incentive : <b>{targetBonusLabel ? "$" + parseFloat(targetBonusLabel).toFixed(2) : '$0.00'} </b></Typography>
                        </Grid>
                        <Grid container item lg={3} md={3} sm={3} xs={3} >
                            <Typography> {parseInt(totalEnrollmentTargetLabel) != 0 ? "Total Target Enrollment" : 'New Target Enrollment'}   : <b> {totalEnrollmentTargetLabel != 0 ?  totalEnrollmentTargetLabel : enrollmentTargetLabel} </b></Typography>
                        </Grid>
                        <Grid container item lg={3} md={3} sm={3} xs={3} >
                            <Typography> {parseInt(totalEnrollmentTargetLabel) != 0 ? "Total Enrollment Achieved" : 'New Enrollment Achieved'}  : <b> {totalEnrollmentTargetLabel != 0 ?  totalEnrollmentAchievedLabel : enrollmentAchievedLabel}</b></Typography>
                        </Grid>
                        </>
                    : 
                    <>
                        <Grid container item lg={3} md={3} sm={3} xs={3} >
                            <Typography> Total Commission : <b>{finalTotalCommissionLabel ? "$" + parseFloat(finalTotalCommissionLabel).toFixed(2) : '$0.00'} ( Status: {statusLabel ? statusLabel : ''} )</b></Typography>
                        </Grid>
                    </>
                }
            </Grid>
            {
                enrollmentTargetLabel != 0 ?
                <>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Grid container item lg={3} md={3} sm={3} xs={3} >
                            <Typography> Total Commission : <b>{finalTotalCommissionLabel ? "$" + parseFloat(finalTotalCommissionLabel).toFixed(2) : '$0.00'} ( Status: {statusLabel ? statusLabel : ''} )</b></Typography>
                        </Grid>
                    </Grid>
                </>
                 : ''
            }
           
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                    <FormControl className={classes.formControl} fullWidth size="small">
                            <InputLabel id="mutiple-select-label">Select AID</InputLabel>
                            <Select
                                labelId="mutiple-select-label"
                                multiple
                                label="Select AID"
                                value={selected}
                                onChange={(e) => {ChangeAids(e)}}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}>
                                <MenuItem
                                value="all"
                                classes={{
                                    root: isAllSelected ? classes.selectedAll : ""
                                }}

                                >
                                <ListItemIcon>
                                    <Checkbox
                                    classes={{ indeterminate: classes.indeterminateColor }}
                                    checked={isAllSelected}
                                    indeterminate={
                                        selected.length > 0 && selected.length < aidList.length
                                    }
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.selectAllText }}
                                    primary="Select All"
                                />
                                </MenuItem>
                                {aidList.map((aidObj) => (
                                <MenuItem key={aidObj.aid} value={aidObj.aid}>
                                    <ListItemIcon>
                                    <Checkbox checked={selected.indexOf(aidObj.aid) > -1} />
                                    </ListItemIcon>
                                    <ListItemText primary={aidObj.aid} />
                                </MenuItem>
                                ))}
                            </Select>
                    </FormControl>
                </Grid>
            </Grid>
                {/* <CommissionView  itemList={itemList} onValueChange={setNewItem} enableSaveButton={enableSaveButton} setNewCommissionTotal={setNewCommissionTotal}/> */}
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Commission" value="1" />
                                    <Tab label="Referral Commission & Bonus" value="2" />
                                </TabList>
                        </Grid>
                    </Box>
                    <TabPanel value="1"><CommissionView  itemList={itemList} onValueChange={setNewItem} enableSaveButton={enableSaveButton} setNewCommissionTotal={setNewCommissionTotal}/></TabPanel>
                    <TabPanel value="2"><ReferralCommissionView  bonusCommissionList={bonusCommissionList} referralCommissionList={referralCommissionList} /></TabPanel>
                </TabContext>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id='actionDiv'>
                    <Button variant="contained" color="success" disabled={saveDisable} onClick={saveChanges}> Save</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="success" disabled={approveDisable} onClick={() => handleClickOpen(1)}>Approve & Send</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="primary" disabled={approveDisable} onClick={() => handleClickOpen(0)}>Approve</Button>&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="warning" disabled={resetDisable} onClick={handleClickOpen1}>Reset to pending</Button>
                </Grid>
                {/* approve and send popup start */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" className="back">
                        <b>Success</b>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{color:"black"}}>
                            {refreshText}
                        {/* Commission details approved and sent notification. */}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions  sx={{display:"flex",justifyContent:"center"}}>
                        <Button onClick={handleClose} variant="contained" color="success"  sx={{minWidth:150}}>Refresh</Button>
                    </DialogActions>
                </Dialog>
                {/* Reset and pending popup start here */}
                <Dialog
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" className="back">
                        <b>Reset to Pending commission?</b>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{color:"black",fontWeight:"bold"}}>
                            Reset to Pending commission?
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description" sx={{color:"black"}}>
                        Do you want to revert any changes and reset the commission status to pending?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions  sx={{display:"flex",justifyContent:"center"}}>
                        <Button onClick={handleClose1} variant="contained" color="primary" sx={{minWidth:150}}>Cancel</Button>
                        <Button onClick={resetToPending} variant="contained" color="success" sx={{minWidth:150}}>Continue</Button>
                    </DialogActions>
                </Dialog>
        </>

    return(
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}

export default CommissionViewDetails;