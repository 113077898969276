
import React, { useMemo, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box, Table, TableBody, TableCell, Dialog, DialogActions, DialogTitle, TextField, DialogContent, FormControl,
  TableContainer, TableHead, TableRow, Paper, Button, Grid, FormControlLabel, FormLabel, Stack, Checkbox, FormGroup, FormHelperText, Tooltip
} from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import MaterialReactTable from 'material-react-table';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams, useNavigate, Link, useHistory,useLocation } from 'react-router-dom';
import AuthUser from "../Auth/AuthUser";
//Icons Imports
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
//Mock Data
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/CheckBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import swal from "sweetalert";
import Moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import config from '../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {currencyFormat} from '../CommonFormula';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoIcon from '@mui/icons-material/Info';

// import CommonFliter from './CommonFliter';
// let paymentNote = '';
let RequestFormInitialValues = {
  paymentDetail: '',
  totalCapturePayment: 0,
  additionalPayment: 0,
  paymentDate: dayjs(),
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];
// function CommissionPayments(props) {
const CommissionPayments = ({ payments, onValueChange, freezStart, freezStop, filterPage,filterStatus }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const captureSchema = Yup.object().shape({
    // paymentDetail: Yup.string()
    //   .required('Please enter payment details'),

    paymentDate: Yup.string()
      .required('Please enter Commission'),

    additionalPayment: Yup.number()
    .test('is-decimal', 'Must have up to 2 decimal places', value => {
      if (value === undefined || value === null) return true;
      // return /^\d+(\.\d{1,2})?$/.test(value.toString()) || Number.isInteger(value);
      return /^-?\d+(\.\d{1,2})?$/.test(value.toString());
    }),
      // .moreThan(-1, 'additional commision should not be less than zero'),

    // totalCapturePayment: Yup.number()
    //   .moreThan(0, 'payment amount should be greater than zero'),
  })


  const handleClose = (resetForm) => {
    resetForm();
    setOpen(false);
  };
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const { http, user } = AuthUser();
  const [data, setData] = useState(payments ? payments : []);
  const [status, setStatus] = useState(filterStatus ? filterStatus : '');
  // const [columns, setColumns] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const [statusMessage, setStatusMesage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disable, setDisable] = useState(false);
  const [filterUsers, setFilterUsers] = useState([]);
  const [loader, setLoader] = useState("");
  const [perPage, setPerPage] = useState(100000);
  const [isLoading, setIsLoading] = useState(false);
  const [PaymentDate, setPaymentDate] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState();
  const [pendingCommissionData, setPendingCommissions] = useState([]);
  const [balance, setBalance] = useState(0);
  const [companyId, setCompanyId] = useState();
  const [totalCapturePayment, setPayment] = useState(0);
  const [capturePaymentBtn, setCapturePaymentBtn] = useState(false);
  const [captureMonthYear, setCaptureMonthYear] = useState([]);
  const [filterhistory, setFilterHistory] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');
  const [maxWidth1, setMaxWidth1] = React.useState('sm');
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [bank_details, setBankDetails] = useState([]);
  const [companyname, setCompanyName] = useState();
  const [openbank, setOpenbank] = useState(false);
  const handlebankClose = () => setOpenbank(false);
  const handlebankOpen = () => setOpenbank(true);
  const [currentDate, setCurrentDate] = useState("");
  const [paymentNote, setPaymentNote] = useState('');
  const [isSlectAll, setIsSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [balanceMatch, setBalanceMatch] = useState();
  const [finalTotalCapturePayment, setFinalTotalCapturePayment] = useState(0);
  const [updatedFieldHistory, setUpdatedFieldHistory] = useState(false);
  const handleUpdatedFieldHistoryClose = () => setUpdatedFieldHistory(false);
  const handleUpdatedFieldHistoryOpen = () => setUpdatedFieldHistory(true);
  const [viewBankHistory, setViewBankHistory] = useState([]);
  const [mediumWidth, setMediumWidth] = React.useState('md');

  //pagination code start
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = filterPage === 0 ? filterPage : queryParams.get('page') ? queryParams.get('page') : 0;
  const paraPageSize = queryParams.get('pageSize') ? queryParams.get('pageSize') : 10;
 //pagination code end
  const [pagination, setPagination] = useState({
    pageIndex: (page)? parseFloat(page):0,
    pageSize: paraPageSize? parseFloat(paraPageSize) :10, //customize the default page size
  });
  const [bankDetailsCompanyId, setBankDetailsCompanyId] = useState();
  const tablePreferencesArray = {

  }
  
  const style = {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  };


  const navigate = useNavigate();

  const handleClickOpen = async (id, balance) => {
    freezStart();
    const apiUrl = '/get-company-pending-commission/' + id;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    var pendingCommissions;
    
    if (res) {
      if(res.success == true) {
        pendingCommissions = res.data;
      }else{
        swal({
					title: "Failed",
					text: "Something went wrong!",
					icon: "error",
					button: "Ok",
				})
        return false;
      }
    }
    freezStop();
    setPaymentNote('');
    // paymentNote = '';
    setCompanyId(id);
    setPayment(0);
    setCaptureMonthYear([]);
    setBalance(balance);
    var sum = 0;
    pendingCommissions.forEach(subData => sum += parseFloat(subData.monthlyCommission ? subData.monthlyCommission : 0));
    var matchValue = ((parseFloat(balance) - parseFloat(sum)).toFixed(2));
    setBalanceMatch(matchValue);
    setFinalTotalCapturePayment(matchValue);
    RequestFormInitialValues.additionalPayment = matchValue;

    RequestFormInitialValues.totalCapturePayment = balance;

    const newDate = new Date();
    newDate.setDate(newDate.getDate());
    let todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
    setCurrentDate(todaysDate);

    setPendingCommissions(pendingCommissions);
    setSelectedItems([]);
    setIsSelectAll(false);
    setOpen(true);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };


  //fetch table preferences from database
  const tablePreferences = async () => {
    const apiUrl = '/fetchTablePreferences/commissionPayment';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
    }
  }
  const changePaymentDetails = (e) => {
    setPaymentNote(e.target.value);
  }

  const ChangeFinalTotalPayment = (value) => {
    var amount = value ? parseFloat(value).toFixed(2) : 0;
    setBalanceMatch(amount);
    setFinalTotalCapturePayment((parseFloat(totalCapturePayment) + parseFloat(amount)).toFixed(2));
  } 

  const changeCapturePayment = (month, year, monthlyCommission, event) => {
    var captured = 0;
    var note = paymentNote;
    let updatedSelectedItems = [...selectedItems];
    if(event.target.checked == true) {
      captured = parseFloat(totalCapturePayment) +  parseFloat(monthlyCommission);
      setPayment(parseFloat(captured).toFixed(2));
      setFinalTotalCapturePayment((parseFloat(captured) + parseFloat(balanceMatch)).toFixed(2));
      setCaptureMonthYear(prevArray => [...prevArray, [month,year]]);
      if(paymentNote == '') {
        setPaymentNote( month + ' ' + year);
      }else{
        setPaymentNote( paymentNote + "," + month + ' ' + year);
      }
      updatedSelectedItems.push({ month, year });
      // if(paymentNote == '') {
      //   paymentNote =  month + ' ' + year;
      // }else{
      //   paymentNote = paymentNote + "," + month + ' ' + year;
      // }
    }else{
      var index;
      captured = parseFloat(totalCapturePayment) -  parseFloat(monthlyCommission);
      for(var k = 0; k < captureMonthYear.length; k++) {
        if(captureMonthYear[k][0] == month && captureMonthYear[k][1] == year){
            index = k;
            break;
        }
      }
      setPayment(parseFloat(captured).toFixed(2));
      setFinalTotalCapturePayment((parseFloat(captured) + parseFloat(balanceMatch)).toFixed(2));
      setCaptureMonthYear((monthAndYear) => monthAndYear.filter((_, i) => i !== index));

      let substringToRemove = month + ' ' + year;
      note = note.replace(substringToRemove, '');
      if (note.charAt(note.length - 1) === ',') {
        note = note.slice(0, -1);
        setPaymentNote(note);
      }else{
        setPaymentNote(note);
      }
      updatedSelectedItems = updatedSelectedItems.filter(item => !(item.month === month && item.year === year));
     
      // setPaymentNote( paymentNote.replace(substringToRemove, ''));
      // if (paymentNote.charAt(paymentNote.length - 1) === ',') {
      //   setPaymentNote(paymentNote.slice(0, -1));
      // }

      // let substringToRemove = month + ' ' + year;
      // paymentNote = paymentNote.replace(substringToRemove, '');
      // if (paymentNote.charAt(paymentNote.length - 1) === ',') {
      //   paymentNote = paymentNote.slice(0, -1);
      // }
    }
    setSelectedItems(updatedSelectedItems);
    setIsSelectAll(updatedSelectedItems.length === pendingCommissionData.length);
  };

  const selectAllFun = (event) => {
    const isChecked = event.target.checked;
    setIsSelectAll(isChecked);
    if(event.target.checked == true) {
      const commissionSum = pendingCommissionData.reduce((total, commission) => {
        return total + commission.monthlyCommission;
      }, 0);
      var note = '';
      note = pendingCommissionData.reduce((paymentNote, commission) => {
        if(paymentNote == '')
          return commission.month + ' ' + commission.year;
        else
          return paymentNote + "," + commission.month + ' ' + commission.year;
      }, 0);
      setPayment(parseFloat(commissionSum).toFixed(2));
      setFinalTotalCapturePayment((parseFloat(commissionSum) + parseFloat(balanceMatch)).toFixed(2));
      setPaymentNote(note);
      const monthYearArray = pendingCommissionData.map(commission => {
       return [commission.month,commission.year]
      });
      setCaptureMonthYear(monthYearArray);
      
    }else{
      setPayment(0);
      setFinalTotalCapturePayment(balanceMatch);
      setPaymentNote('');
      setCaptureMonthYear([]);
    }
    const selected = isChecked ? pendingCommissionData.map(item => ({ month: item.month, year: item.year })) : [];
    setSelectedItems(selected);
    document.getElementById("inputCapturePayment").value = parseFloat(totalCapturePayment).toFixed(2);
    document.getElementById("paymentDetail").value = paymentNote;
   
  };

  const fetchCompanyBankDetails = async (id, companyname) => {
    setCompanyName(companyname);
    setBankDetailsCompanyId(id);
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id  > 2) {
        navigate('/login');
        return false;
    }
    const apiUrl = '/fetch-company-bank-details/' + id;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (res.companyBankDetails.company_master_id != null) {
        setBankDetails(res.companyBankDetails);
        setFilterHistory(Object.values(res.updatedAllAttributes));
      } else {
        alert("error in fetching bank details")
      }
    }
  };

  const viewBankDetails = (compnyId) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
    window.location.href= config.BASE_URL + 'BankDetails/' + compnyId + '?payments=true' + `&page=${pagination.pageIndex}` + `&pageSize=${pagination.pageSize}`;
  }

  const capturePayment = async (values) => {
    if(totalCapturePayment == 0) {
      alert("Please select atleast one month and year");
      return false;
    }
    if (paymentNote == '') {
      alert("Please add payment details");
      return false;
    }
    setCapturePaymentBtn(true);
    freezStart();
    const date = new Date(values.paymentDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    var mydate = year+"/"+month+"/"+day;
    const apiUrl = '/capture-payment';
    const args = {
      companyId: companyId,
      monthsYears : captureMonthYear,
      paymentDate: mydate,
      totalCapturePayment: totalCapturePayment,
      // paymentDetails: values.paymentDetail,
      paymentDetails: paymentNote,
      additionalPayment: values.additionalPayment == "" ? 0 : values.additionalPayment
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if (res.success === true) {
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
       
            setOpen(false);
            freezStop();
            setCapturePaymentBtn(false);
            onValueChange();
            navigate("/commission-payments?page="+pagination.pageIndex  + `&pageSize=${pagination.pageSize}`);

      } else {
        setOpen(false);
        swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
        if(res.message == "This action is unauthorized.") {
          navigate('/login');
        }else{
          freezStop();
          setCapturePaymentBtn(false);
        }
       
      }
    }
  };
  ////////////////////////
  
  useEffect(() => {
    setData(payments)
  }, [payments]);

  useEffect(() => {
    setStatus(filterStatus);
  }, [filterStatus]);
  
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);
  ////////////////////////

  useEffect(() => {
    saveTablePreferences();
  }, [columnVisibility])

  const saveTablePreferences = async () => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'commissionPayment'
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const customSortFunction = (rowA, rowB) => {
    const colorPriority = {
      blue: 0,
      red: 1,
      null: 2,
      grey:2,
      green: 3,
    };
    const statusA = rowA.original.bankStatus;
    const statusB = rowB.original.bankStatus;
    const priorityA = colorPriority[statusA];
    const priorityB = colorPriority[statusB];
    return priorityA - priorityB;
  };
  const customSortFunctionAcceptCommission = (rowA, rowB) => {
    const flagPriority = {
      "": 0,
      "Paid": 0,
      "Approved": 0,
      "Accepted": 1,
    };
    const flagA = rowA.original.acceptFlag;
    const flagB = rowB.original.acceptFlag;
    const priorityA = flagPriority[flagA];
    const priorityB = flagPriority[flagB];
    return priorityA - priorityB;
  };

  const fetchHistoryDetails = async (bank_attribute_id) => {
    const apiUrl = "/fetch-bank-history-details/"+ bank_attribute_id;
    freezStart()
    const args = {}
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res.success)
    {
        freezStop()
        setViewBankHistory(res.data);
        handleUpdatedFieldHistoryOpen();
    }
    else
    {
        freezStop()
        setViewBankHistory([]);
    }
};

  const columns = useMemo(
    //column definitions...
    () => [
        {
          accessorFn: (data) => `${data.companyName}`,
          header: 'Company Name',
          size: 50,
          enablePinning: true,
        },
        {
          accessorFn: (data) => parseFloat(`${data.totalApprovedCommission}`).toFixed(2),
          enableClickToCopy: true,
          id: 'total_approved_commission',
          header: 'Total Approved Commission ($)',
          size: 100,
          sortingFn: (rowA, rowB) => {
            const sortA = rowA.original.totalApprovedCommission;
            const sortB = rowB.original.totalApprovedCommission;
            return sortA-sortB;
          },
          Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.totalApprovedCommission))),
        },
        {
          accessorFn: (data) => parseFloat(`${data.totalPaid}`).toFixed(2),
          enableClickToCopy: true,
          id: 'total_paid',
          header: 'Total Paid ($)',
          size: 100,
          sortingFn: (rowA, rowB) => {
            const sortA = rowA.original.totalPaid;
            const sortB = rowB.original.totalPaid;
            return sortA-sortB;
          },
          Cell:({renderedCellValue,row}) => (currencyFormat(parseFloat(row.original.totalPaid))),
        },
        {
          accessorFn: (data) => parseFloat(`${data.balance}`).toFixed(2),
          enableClickToCopy: true,
          id: 'balance',
          header:`Balance ($)`,
          size: 100,
          sortingFn: (rowA, rowB) => {
            const sortA = rowA.original.balance;
            const sortB = rowB.original.balance;
            return sortA-sortB;
          },
          Cell:({renderedCellValue,row}) => (
            <div style={{ color: 'green' }} >
                { currencyFormat(parseFloat(row.original.balance)) }
             </div>          
           ),
        },
        {
          accessorFn: (data) => `${data.email ? data.email.trim() : ''} `,   
          enableClickToCopy: true,
          id: 'communicationEmail',
          header: 'Communication Email',
          size: 110,
          Cell: ({ renderedCellValue, row }) => (
              row.original.email ? 
              <>
                {row.original.email.trim()}
                
                {row.original.isBounce == '1' ? 
                
                  <Tooltip title="Emails are not getting delivered to this address. Please update the communication email" arrow>
                    <ErrorOutlineIcon color={'error'}/>
                  </Tooltip> 
                  : null}
              </>
              :'N/A'
            
          ),
        },
        {
          accessorKey: 'last_payment_date',
          accessorFn: (data) => `${data.lastPaymentDate ? data.lastPaymentDate : '0000-00-00 00:00:00'}`,
          enableClickToCopy: true,
          id: 'last_payment_date',
          header: 'Last Payment Date',
          size: 100,
          Cell:({renderedCellValue,row}) => (
            <div>
                {row.original.lastPaymentDate != null ? Moment(row.original.lastPaymentDate).format('MM-DD-YYYY') : 'N/A'}
             </div>          
           ),
        },
        {
          accessorKey: 'last_captured_date',
          accessorFn: (data) => (`${data.lastCapturedDate ? data.lastCapturedDate : ''}`),
          enableClickToCopy: true,
          id: 'last_captured_date',
          header: 'Last Captured Date',
          size: 100,
          Cell:({renderedCellValue,row}) => (
            <div>
                {row.original.lastCapturedDate != null ? Moment(row.original.lastCapturedDate).format('MM-DD-YYYY') : 'N/A'}
             </div>          
           ),
        },
        {
          accessorKey: 'bank_details_last_updated',
            accessorFn: (data) => (`${data.updated_at ? data.updated_at : ''}`),
            enableClickToCopy: true,
            id: 'bank_details_last_updated',
            header: 'Bank Details Updated Date',
            size: 150,
            Cell:({renderedCellValue,row}) => (
              <div>
                  {row.original.updated_at != null ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY') : 'N/A'}
              </div>          
            ),
        },
        {
          accessorFn: (row) => `${row.bankStatus}`,
          id: 'account_flag',
          header: 'Bank Details Submitted',
          size: 150,
          sortingFn: customSortFunction, 
          //custom conditional format and styling
          Cell: ({ row }) => (
            <>
              <Box>
                {`${row.original.bankStatus}` == "green" ?
                  <Tooltip title="Bank Details Confirmed" arrow><CheckIcon color="success" /></Tooltip> :
                  `${row.original.bankStatus}` == "gray" ?
                    <Tooltip title="Bank Deatils not Submitted." arrow><CheckIcon color="disabled" /></Tooltip> :
                    `${row.original.bankStatus}` == "red" ?
                      <Tooltip title="Incorrect Bank Details" arrow><CheckIcon htmlColor="red" /></Tooltip> :
                      `${row.original.bankStatus}` == "blue" ?
                        <Tooltip title={"Bank Details Updated on " + Moment(`${row.original.updated_at}`).tz('America/Los_Angeles').format('MM-DD-YYYY')} arrow><CheckIcon htmlColor="gold"/></Tooltip> :
                        <Tooltip title="Bank Deatils not Submitted." arrow><CheckIcon color="disabled" /></Tooltip>}
              </Box>
            </>
          ),
        },
        {
          accessorKey: 'ach_info_added_to_bank',
          accessorFn: (row) => `${row.last_ach_updated}`,
          id: 'ach_added_to_bank',
          header: 'ACH Added To Bank',
          size: 150,
          //custom conditional format and styling
          Cell: ({ row }) => (
            <>
              <div>
                {`${row.original.ach_info_added_to_bank}` == 1 ?
                  (`${row.original.capturepaymentdate}` == 1 ? <div> <Tooltip title="ACH Added" arrow><CheckIcon color="success" /></Tooltip> &nbsp;&nbsp;{Moment(`${row.original.last_ach_updated}`).format('MM-DD-YYYY')}</div>
                    : (`${row.original.achupdateflag}` == 1 ? <div><Tooltip title={"ACH Added"} arrow><AccessTimeIcon color="black" /></Tooltip> &nbsp;&nbsp;{Moment(`${row.original.last_ach_updated}`).format('MM-DD-YYYY')}</div>
                      : <div><Tooltip title="ACH Added" arrow><CheckIcon htmlColor="purple" /></Tooltip> &nbsp;&nbsp;{Moment(`${row.original.last_ach_updated}`).format('MM-DD-YYYY')}</div>))
                  : <Tooltip title={"ACH Not Added"} arrow><CheckIcon color="disabled" /></Tooltip>}
              </div>
            </>
          ),
        },
        {
          accessorFn: (row) => `${row.acceptFlag}`,
          id: 'accepted_commission',
          header: 'Accepted Commission',
          size: 150,
          sortingFn: customSortFunctionAcceptCommission,
          //custom conditional format and styling
          Cell: ({ row }) => (
            <>
              <Box>
                {`${row.original.acceptFlag}` == "Accepted" ?
                  <Tooltip title="Commission has been Accepted" arrow><CheckIcon color="success" /></Tooltip> :
                  <Tooltip title="Commission Not Yet Accepted" arrow><CheckIcon color="disabled" /></Tooltip>}
              </Box>
            </>
          ),
        },
        {
          accessorKey: 'commissionAcceptedDate',
          accessorFn: (row) => (`${row.commissionAcceptedDate}`),
          enableClickToCopy: true,
          id: 'commission_accepted_date',
          header: 'Commission Accepted Date',
          size: 100,
          Cell:({renderedCellValue,row}) => (
               <div>
                {row.original.acceptFlag == "Accepted" && row.original.commissionAcceptedDate!= "" ? Moment(row.original.commissionAcceptedDate).format('MM-DD-YYYY') : 'N/A' }
            </div>        
           ),
        },
        {
          accessorFn: (data) => "N/A",
          enableClickToCopy: true,
          id: 'commission_1099',
          header: 'Commission 1099',
          size: 100,
        },
    
        {
          accessorKey: 'accountType',
          accessorFn: (row) => `${row.accountType}`,
          enableClickToCopy: true,
          id: 'account_type',
          header: 'Account Type',
          size: 100,
          Cell: ({ renderedCellValue,row }) => (
            <div>
                  {(row.original.accountType != null) ? row.original.accountType != "" ? row.original.accountType : 'N/A' : 'N/A'}
              
            </div>
          ),
        },
        {
          accessorKey: 'nameOnAccount',
          accessorFn: (row) => `${row.nameOnAccount !== null ? row.nameOnAccount.trim() !== "" ? row.nameOnAccount.trim() : '' : ''}`,
          enableClickToCopy: true,
          id: 'name_on_account',
          header: 'Name On Account',
          size: 100,
          Cell: ({ renderedCellValue,row }) => (
            <>
                 {row.original.nameOnAccount !== null ? row.original.nameOnAccount.trim() !== "" ? row.original.accountType == "Individual" ?  row.original.nameOnAccount.replace(/:/g, ' ') : row.original.nameOnAccount.trim() : 'N/A' : 'N/A'}
              
            </>
          ),
        },
    
        {
          accessorFn: (row) => `${row}`,
          id: 'bank_action',
          header: 'Bank Action',
          size: 150,
          enableSorting: false, 
          enableColumnActions: false,
          //custom conditional format and styling
          Cell: ({ row }) => (
            <>
              <Box>
                  <>
                      <Tooltip title="View" arrow><VisibilityIcon color="primary" onClick={() => { fetchCompanyBankDetails(`${row.original.companyId}`, `${row.original.companyName}`); handlebankOpen(); }} /></Tooltip>
                      {<Tooltip title="Edit" arrow><EditIcon color="primary" onClick={() => viewBankDetails(`${row.original.companyId}`)} /></Tooltip>}
                  </>
              </Box>
            </>
          ),
        },
        {
          accessorFn: (data) => `${data}`,
          id: 'action',
          header: 'Action',
          enableSorting: false,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Box>
              <>
                {`${row.original.balance}` > 0 ?
                  <Tooltip title="Capture Payment" arrow><MonetizationOnIcon onClick={() => handleClickOpen(row.original.companyId, row.original.balance)} /></Tooltip>
                  : ''}&nbsp;
                <Tooltip title="Details" arrow><a className='btn btn-success text-white' href={config.BASE_URL+"payment-details/"+row.original.companyId + "?page="+pagination.pageIndex + `&pageSize=${pagination.pageSize}`} ><img src={require('../../assets/images/details.png')} /></a></Tooltip>
              </>
            </Box>
          ),
    
        }
    ]
  );

  const columns1 = useMemo(
    () => [
        {
            accessorFn: (row) => `${row.id}`, //accessorFn used to join multiple data into a single cell
            id: 'id', //id is still required when using accessorFn instead of accessorKey
            header: 'ID',
            numeric: true,
            size: 50,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.id}</span>
                </Box>
            ),
        },
        { 
          accessorFn: (row) => `${row.name}`,

            id: 'updated_by', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated By',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.name}</span>
                </Box>
            ),
        },
        {
          accessorFn: (row) => `${row.role_name}`,
            id: 'user_role', //id is still required when using accessorFn instead of accessorKey
            header: 'User Role',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.role_name}</span>
                </Box>
            ),
        },
        {
          accessorFn: (row) => `${row.attributes}`,
            id: 'updated_fields', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated Fields',
            size: 200,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                    className="datatable"
                >
                     <span>{
                              row.original.attributes ?
                                  row.original.new_values ?
                                      row.original.new_values.split(',').length != row.original.attributes.split(',').length ?
                                          <>
                                          {row.original.attributes.replace(",ACH Date", '') } &nbsp;
                                          { (row.original.new_values && row.original.new_values !== '1') && row.original.bank_attribute_id ? <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span> : ''}
                                          </>
                                      :
                                      <>
                                        { row.original.attributes}&nbsp;
                                        { (row.original.attributes != "Ach Info Added To Bank") && row.original.bank_attribute_id ? <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span>: ''}
                                      </> 
                                  :
                                  <>
                                  { row.original.attributes }&nbsp;
                                  { (row.original.attributes != "Ach Info Added To Bank") && row.original.bank_attribute_id ?  <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span>: ''}
                                  </> 
                                 
                              : "No fields has been updated in this operation."}</span>
                </Box>
            ),
        },
        {
          accessorFn: (row) => `${row.attributes ? row.attributes.includes("ACH Date") ? (row.new_values != null ? row.new_values.split(',').length == row.attributes.split(',').length ?  row.new_values.split(',')[row.new_values.split(',').length - 2] != 0 ? (row.new_values.split(',')[row.new_values.split(',').length - 1]) :'' :'' :''): '': ''}`,
          id: 'last_ach_updated', //id is still required when using accessorFn instead of accessorKey
          header: 'Added ACH Date',
          size: 100,
          Cell: ({ renderedCellValue, row }) => (
              <Box
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                  }}
              >
                <span>{
				row.original.attributes ? 
				  	row.original.new_values ? 
						row.original.attributes.includes("ACH Date") ? 
							row.original.new_values.split(',').length == row.original.attributes.split(',').length ? 
								(row.original.new_values.split(',')[row.original.new_values.split(',').length - 2] != 0 ? 
									Moment(row.original.new_values.split(',')[row.original.new_values.split(',').length - 1]).format('MM-DD-YYYY') 
								:'')
							: ''
						: ''
					: ''
				: '' }</span>
              </Box>
          ),
        },
        {
          accessorFn: (row) => `${Moment(row.created_at).format('MM-DD-YYYY')}`,
            id: 'updated_date', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated Date',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{Moment(row.original.created_at).format('MM-DD-YYYY')}</span>
                </Box>
            ),
        },




    ],
    [],
);

const filterHistoryColumns = useMemo(
  () => [
      {
          accessorFn: (row) => `${row.id}`, //accessorFn used to join multiple data into a single cell
          id: 'id', //id is still required when using accessorFn instead of accessorKey
          header: 'Attribute Name',
          numeric: true,
          size: 150,
          
          Cell: ({ renderedCellValue, row }) => (
              <Box
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                  }}
              >
                  <span>{row.original.attribute_name ? row.original.attribute_name.display_name  : ''}</span>
              </Box>
          ),
          
      },
      {
          accessorFn: (row) => `${row.attribute_old_value}`,
          id: 'attribute_old_value', //id is still required when using accessorFn instead of accessorKey
          header: 'Previous Value',
          size: 150,
          Cell: ({ renderedCellValue, row }) => (
              <Box
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                  }}
              >
                   <span>{row.original.attribute_name.attribute_name == "name_on_account" ? row.original.attribute_old_value !== null ? row.original.attribute_old_value.indexOf(':')  >= 0 ? row.original.attribute_old_value.replace(/:/g, " ") : row.original.attribute_old_value :  row.original.attribute_old_value :  row.original.attribute_old_value}</span>
              </Box>
          ),
      },
      {
          accessorFn: (row) => `${row.attribute_new_value}`,
          id: 'attribute_new_value', //id is still required when using accessorFn instead of accessorKey
          header: 'Updated Value',
          size: 150,
          Cell: ({ renderedCellValue, row }) => (
              <Box
                  sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                  }}
              >
                   <span>{row.original.attribute_name.attribute_name == "name_on_account" ? row.original.attribute_new_value !== null ? row.original.attribute_new_value.indexOf(':')  >= 0 ? row.original.attribute_new_value.replace(/:/g, " ") : row.original.attribute_new_value : row.original.attribute_new_value : row.original.attribute_new_value}</span>
              </Box>
          ),
      },
  ],
  [],
);
  //csv option start here
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Commission Payments Report',
    headers: ['Company Name', 'Total Approved Commission ($)', 'Total Paid ($)', 'Balance ($)','Communication Email', 'Last Payment Date', 'Last Captured Date', 'Bank Details Updated Date', 'Bank Details Submitted', 'ACH Added To Bank','Ready To Pay','Ready To Pay NBD', 'Accepted Commission', 'Commission Accepted Date', 'Commission 1099', 'Account Type', 'Name On Account',],
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportData = (data) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id  > 2) {
        navigate('/login');
        return false;
    }
    const exportedData = data.map((row) => {
      let bankStatusText;
        if (row.bankStatus === "green") {
          bankStatusText = "Bank Details Confirmed";
        } else if (row.bankStatus === "gray") {
          bankStatusText = "Bank Details not Submitted";
        } else if (row.bankStatus === "red") {
          bankStatusText = "Incorrect Bank Details";
        } else if (row.bankStatus === "blue") {
          bankStatusText = `Bank Details Updated on ${Moment(row.original.updated_at).format('MM-DD-YYYY')}`;
        } else {
          bankStatusText = "Bank Details not Submitted";
        }
        let achAddedText;
        if (row.original.ach_info_added_to_bank === 1) {
          if (row.original.capturepaymentdate === 1) {
            achAddedText = `ACH Added ${Moment(row.original.last_ach_updated).format('MM-DD-YYYY')}`;
          } else if (row.original.achupdateflag === 1) {
            achAddedText = `ACH Added ${Moment(row.original.last_ach_updated).format('MM-DD-YYYY')}`;
          } else {
            achAddedText = `ACH Added ${Moment(row.original.last_ach_updated).format('MM-DD-YYYY')}`;
          }
        } else {
          achAddedText = "ACH Not Added";
        }
      return {
        companyName: `${row.original.companyName}` || 'N/A',
        total_approved_commission: parseFloat(`${row.original.totalApprovedCommission}`).toFixed(2),
        total_paid: parseFloat(`${row.original.totalPaid}`).toFixed(2),
        balance: parseFloat(`${row.original.balance}`).toFixed(2),
        email: `${row.original.email}`,
        last_payment_date: (`${row.original.lastPaymentDate ? row.original.lastPaymentDate : 'N/A'}`),
        last_captured_date: (`${row.original.lastCapturedDate ? row.original.lastCapturedDate : 'N/A'}`),
        last_updated: (`${row.original.updated_at != null ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY') : 'N/A'}`),
        account_flag: bankStatusText,
        ach_added_to_bank: achAddedText,
        readyToPay: `${row.original.ReadyToPay == 1 || row.original.ReadyToPay == "1" ? "Yes" : "No"}`,
        readyToPayNBD: `${row.original.ReadyToPayNBD == 1 || row.original.ReadyToPayNBD == "1" ? "Yes" : "No"}`,
        accepted_commission: `${row.original.acceptFlag === "Accepted" ? "Commission has been Accepted" : "Commission Not Yet Accepted"}`,
        commission_accepted_date: (`${row.original.commissionAcceptedDate}`),
        commission_1099: "N/A",
        account_type: (`${row.original.accountType}`||"N/A"),
        name_on_account: (`${row.original.nameOnAccount}`||"N/A"),
      };
    });

    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				}

				return value;
			}).join(',');
		}).join('\n');

    return headers + rows;
  };
  //csv end here

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilterModes
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
         }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        enableRowSelection={false}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        onPaginationChange={setPagination}
        enablePinning
        enableColumnFilters={config.DatatableColumnFilter}
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ columnVisibility, rowSelection, pagination }} //pass our managed row selection state to the table to use
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          pagination: { pageSize: pagination.pageSize, pageIndex: pagination.pageIndex },
          sorting: [
            // Set default sorting for the specified column
          {
           id: 'commission_accepted_date', // Replace with the actual column ID
           desc: false, // Set to true if you want default descending sorting
           },
           ],
           columnPinning: { left: ['mrt-row-expand','Company Name'] }
        }}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            backgroundColor: "#F6FAFF",
          },
        }}
        renderDetailPanel={({ row }) => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Company Name</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Approved Commission ($)</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Paid ($)</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Balance ($)</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Bank Details Submitted</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Account Type</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>Name On Account</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={row.original.companyId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.original.companyName}
                    </TableCell>
                    <TableCell align="center">{currencyFormat(parseFloat(row.original.totalApprovedCommission))}</TableCell>
                    <TableCell align="center">{currencyFormat(parseFloat(row.original.totalPaid))}</TableCell>
                    <TableCell align="center">{currencyFormat(parseFloat(row.original.balance))}</TableCell>
                    <TableCell align="center">
                      <>
                        <Box>
                          {`${row.original.bankStatus}` == "green" ?
                            <Tooltip title="Bank Details Confirmed" arrow><CheckIcon color="success" /></Tooltip> :
                            `${row.original.bankStatus}` == "gray" ?
                              <Tooltip title="Bank Deatils not Submitted." arrow><CheckIcon color="disabled" /></Tooltip> :
                              `${row.original.bankStatus}` == "red" ?
                                <Tooltip title="Incorrect Bank Details" arrow><CheckIcon htmlColor="red" /></Tooltip> :
                                `${row.original.bankStatus}` == "blue" ?
                                  <Tooltip title={"Bank Details Updated on " + Moment(`${row.original.updated_at}`).format('MM-DD-YYYY')} arrow><CheckIcon htmlColor="gold" /></Tooltip> :
                                  <Tooltip title="Bank Deatils not Submitted." arrow><CheckIcon color="disabled" /></Tooltip>}
                        </Box>
                      </>
                    </TableCell>
                    <TableCell align="center">{row.original.accountType}</TableCell>
                    <TableCell align="center">{row.original.accountType == "Individual" ?  row.original.nameOnAccount.replace(/:/g, ' ') : row.original.nameOnAccount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        //top csv option
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              color="primary"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportData(table.getPrePaginationRowModel().rows)
                }
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              // onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              CSV
            </Button>

          </Box>
        )
        }
      />
      <Formik
        initialValues={RequestFormInitialValues}
        validationSchema={captureSchema}
        onSubmit={(values, { resetForm }) => {
          capturePayment(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError,resetForm  }) => (
          <>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              maxWidth={maxWidth1}
              style={{height : isMobile? "100%" : "100vh" }}
            >
              <DialogContent>

                <Grid container item lg={12} md={12} sm={12} xs={12}>
                  <Typography level="h3">
                    Capture Payment
                  </Typography >
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <span sx={{fontsize : "12px"}}>(Balance : ${balance ? parseFloat(balance).toFixed(2) : ''})</span>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                  <TextField margin="dense" size="small" id="outlined-basic" label="Payment Amount ($): *"
                    variant="outlined" fullWidth
                    type="number"
                    // value={values.totalCapturePayment}
                    value={totalCapturePayment}
                    onChange={handleChange('totalCapturePayment')}
                    readOnly={true}
                    onBlur={handleBlur('totalCapturePayment')} />
                  {/* value={balance}/> */}
                </Grid>
                {/* {touched.totalCapturePayment && errors.totalCapturePayment ? <div className='error'>{errors.totalCapturePayment}</div> : null} */}
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                  <TextField margin="dense" size="small" id="additionalPayment" name='additionalPayment' label="Additional Payment ($): *"
                    type="number"
                    variant="outlined" fullWidth
                    value={values.additionalPayment}
                    // onChange={handleChange('additionalPayment')}
                    onChange={(e) => {
                      handleChange(e);
                      ChangeFinalTotalPayment(e.target.value);
                    }}
                    onBlur={handleBlur('additionalPayment')}
                    placeholder="0" />
                </Grid>
                {touched.additionalPayment && errors.additionalPayment ? <div className='error'>{errors.additionalPayment}</div> : null}
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                      <TextField margin="dense" size="small" id="outlined-basic" label="Total Payment Amount ($): *"
                        variant="outlined" fullWidth
                        type="number"
                        value={finalTotalCapturePayment}
                        onChange={handleChange('finalTotalCapturePayment')}
                        readOnly={true}
                        onBlur={handleBlur('finalTotalCapturePayment')} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{minHeight:200,maxHeight:200,overflowX : 'auto'}}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Select Month & Year:</FormLabel> 
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={isSlectAll}/>} label="Select All"
                                                onChange={(event) => selectAllFun(event)}
                                            />
                                            {pendingCommissionData.map((pending) => (
                                              <FormControlLabel control={<Checkbox checked={selectedItems.some(item => item.month === pending.month && item.year === pending.year)}/>} label={pending.month + " " + pending.year} 
                                                onChange={(event) => changeCapturePayment(pending.month, pending.year, pending.monthlyCommission, event)}
                                              />
                                            ))}
                                        </FormGroup>
                                    </FormControl> 
                  </Grid>

                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <FormControl fullWidth size="small">
                    <TextareaAutosize
                      margin="dense"
                      aria-label="minimum height"
                      minRows={3}
                      // value={values.paymentDetail || ''}
                      value={paymentNote}
                      // onChange={handleChange('paymentDetail')}
                      onChange={changePaymentDetails}
                      onBlur={handleBlur('paymentDetail')}
                      placeholder="Payment Details"
                      style={{ width: "100%" }}
                    />
                  </FormControl>
                  {touched.paymentDetail && errors.paymentDetail ? <div className='error'>{errors.paymentDetail}</div> : null}
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer components={['DatePicker']} margin="dense" size="small" fullWidth>
                      <DatePicker label="Payment Date:*" name="paymentDate" id="paymentDate" defaultValue={dayjs(currentDate)}
                        onChange={(value) => { setFieldValue("paymentDate", value, true) }} />
                    </DemoContainer>
                  </LocalizationProvider>
                  <FormHelperText>{touched.paymentDate && errors.paymentDate ? errors.paymentDate : ''}</FormHelperText>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" color="primary" 
                onClick={(e)=>{
                  handleClose(resetForm);
                }}
                sx={{ minWidth: 200 }}>CLOSE</Button>
                <Button disabled={capturePaymentBtn} variant="contained" color="success" onClick={handleSubmit} sx={{ minWidth: 200 }}>Capture Payment</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>

      <>
            <Dialog
              open={openbank}
              onClose={handlebankClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              maxWidth={maxWidth}
            >
              <DialogTitle id="responsive-dialog-title">
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                    <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography level="h5">
                      {companyname} 's Bank Details
                    </Typography >
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2} sx={{textAlign:"right"}}>
                    <EditIcon color="primary" onClick={() => viewBankDetails(`${bankDetailsCompanyId}`)}/>&nbsp;&nbsp;
                    <CloseIcon onClick={handlebankClose} />
                    </Grid>
                  </Grid>
              </DialogTitle>
              <DialogContent>
              <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" border="none">
                    <TableHead>
                      <TableRow>
                        <TableCell component="th"><b>Bank Name</b></TableCell>
                        <TableCell  component="th"><b>Name on Account</b></TableCell>
                        <TableCell  component="th"><b>Bank Routing (ABA/SCH) Number</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          <TableCell >
                          {bank_details.bank_name}
                          </TableCell >
                          <TableCell >{bank_details.account_category == "personal" ? bank_details.name_on_account.replace(/:/g, ' ') : bank_details.name_on_account}</TableCell>
                          <TableCell >{bank_details.routing_number}</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell component="th"><b>Account No.</b></TableCell>
                        <TableCell  component="th"><b>Account Type</b></TableCell>
                        <TableCell  component="th"><b>Is Account</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          <TableCell >
                          {bank_details.account_number}
                          </TableCell >
                          <TableCell >{bank_details.account_type}</TableCell>
                          <TableCell >{bank_details.account_category}</TableCell>
                        </TableRow>
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell component="th"><b>Email Address to notify of Payment Transfers</b></TableCell>
                        <TableCell  component="th"><b>ACH Info added to bank?</b></TableCell>
                        <TableCell  component="th"><b>Last ACH Update</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          <TableCell >
                          {bank_details.email_to_notify}
                          </TableCell >
                          <TableCell >{bank_details.ach_info_added_to_bank == 0 ? "Not Added" : "Added"}</TableCell>
                          <TableCell >{bank_details.last_ach_updated == null ? "NA" : Moment(bank_details.last_ach_updated).format('MM-DD-YYYY')}</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h5" color="primary" mt={2}>Bank Details Update History</Typography>
                        </Grid>
                        <MaterialReactTable

                            columns={columns1}
                            data={filterhistory}
                            enableColumnFilterModes
                            enableColumnOrdering={false}
                            enableGrouping={false}
                            enablePinning={false}
                            initialState={{ showColumnFilters: false,density: 'compact'}}
                            enableDensityToggle={false}
                            enableFullScreenToggle={ false}
                            enableHiding={false} 
                            enableColumnFilters={false}
                            enableSorting={true}
                            filterFns={{
                              customSearchFilterFn: (row, id, filterValue) =>
                              row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                            }}                                   
                            globalFilterFn="customSearchFilterFn"
                            positionToolbarAlertBanner="bottom"

                            // renderDetailPanel={({ row }) => (
                            //     <BankHistory props={row} />
                            // )}
                        />
                    </div>
                  
                  {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                   
                  <Table borderAxis="none" variant="plain" stripe="odd" >
                      <tr>
                        <th>Bank Name</th>
                        <th>Name on Account</th>
                        <th>Bank Routing (ABA/SCH) Number</th>
                      </tr>
                      <tr>
                        <td>{bank_details.bank_name}</td>
                        <td>{bank_details.name_on_account}</td>
                        <td>{bank_details.routing_number}</td>
                      </tr>
                      <tr>
                        <th>Account No.</th>
                        <th>Account Type</th>
                        <th>Is Account</th>
                      </tr>
                      <tr>
                        <td>{bank_details.account_number}</td>
                        <td>{bank_details.account_type}</td>
                        <td>{bank_details.account_category}</td>
                      </tr>
                      <tr>
                        <th>Email Address to notify of Payment Transfers</th>
                        <th>ACH Info added to bank?</th>
                        <th>Last ACH Update</th>
                      </tr>
                      <tr>
                        <td>{bank_details.email_to_notify}</td>
                        <td>{bank_details.ach_info_added_to_bank == 0 ? "Not Added" : "Added"}</td>
                        <td>{bank_details.last_ach_updated == null ? "NA" : Moment(bank_details.last_ach_updated).format('MM-DD-YYYY')}</td>
                      </tr>
                      </Table>
                  </Grid> */}
                 
              </DialogContent>

               
            </Dialog>
          </> 
          <>
          <Dialog
              open={updatedFieldHistory}
              onClose={handleUpdatedFieldHistoryClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              maxWidth={mediumWidth}
            >
              <DialogTitle id="responsive-dialog-title">
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                    <Grid item lg={7} md={7} sm={7} xs={7}>
                    <Typography level="h5">
                    Bank Details Update History 
                    </Typography >
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={5} sx={{textAlign:"right"}}>
                    <span style={{fontWeight: '95%'}} ml={2} >Updated Date:{viewBankHistory[0] ? viewBankHistory[0].created_at? Moment(viewBankHistory[0].created_at).tz('America/Los_Angeles').format('MM-DD-YYYY'):'N/A' : 'N/A'}</span>
                       &nbsp;&nbsp; <CloseIcon onClick={handleUpdatedFieldHistoryClose} />
                    </Grid>
                  </Grid>
              </DialogTitle>
              <DialogContent>
                <div>
                        <MaterialReactTable

                            columns={filterHistoryColumns}
                            data={viewBankHistory}
                            enableColumnFilterModes
                            enableColumnOrdering={false}
                            enableGrouping={false}
                            enablePinning={false}
                            initialState={{ showColumnFilters: false,density: 'compact'}}
                            enableDensityToggle={false}
                            enableFullScreenToggle={ false}
                            enableHiding={false} 
                            enableColumnFilters={false}
                            enableSorting={true}
                            filterFns={{
                              customSearchFilterFn: (row, id, filterValue) =>
                              row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                            }}                                   
                            globalFilterFn="customSearchFilterFn"
                            positionToolbarAlertBanner="bottom"
                        />
                </div>
              </DialogContent>
            </Dialog>        
          </>                     
    </>
  );

}


export default CommissionPayments;