import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {CardContent, Collapse ,TextField,DialogTitle,IconButton,FormGroup,FormControlLabel,FormHelperText, Grid, MenuItem, Select, FormControl, InputLabel, Button, Dialog, DialogActions, DialogContent, RadioGroup, Radio,Table,TableBody ,TableCell,TableContainer,TableHead ,TableRow ,Paper } from '@mui/material';
import { Card, CardOverflow, Typography, List, ListItem, ListItemDecorator, ListItemContent,Box } from '@mui/joy';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';
import swal from "sweetalert";
import AppConfig from '../../../AppConfig';
import { Formik } from 'formik';
import * as Yup from 'yup';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  })); 

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));


let aidVal = '';
let logoURL = '';
let logoFlag = 0;
let logoUpdated = 0;
function MyLink(props) {
    const dispatch = useDispatch();
    const [dataObj, setDataObj] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [aid, setAid] = useState('');
    const [aidId, setAidId] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [imageUrl, setImageUrl] = useState(AppConfig.BASE_URL+'genericlogo.png');
    const [defaultCobrandFlag, setdefaultCobrandFlag] = useState(true);
    const [planAdd, setplanAdd] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [basePlansArray, setBasePlans] = useState([]);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [formikValues, setFormikValues] = useState({});
    const [cobrandStatus, setcobrandStatus] = useState('');
    const [PlanAvaliable, setPlanAvaliable] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [errors, setErrors] = useState({});
    //copied function
    const [copySuccess, setCopySuccess] = useState('');
    const [freez, setFreez] = React.useState(true);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


    const getLinks = async() => {
        const apiUrl = '/getAffiliateLinks?aid=' + aidVal ; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setDataObj(res.data);
            
            setCompanyId(res.data.companyId);
            handleFreezStop();
            setAid(res.data.selectedAid);
            setAidId(res.data.aidId);
            setPlanAvaliable(res.data.addMorePlans);
            setcobrandStatus(res.data.cobrandStatus);
            setdefaultCobrandFlag(res.data.defaultCobrandFlag);
            if((res.data.cobrandLinks).length == 0 ){
                
                setdefaultCobrandFlag(true);
                getCustomLog(res.data.companyName);
            }
        }
    }
    useEffect(() => {
        getLinks();
    }, [])

    const handleChange = (event) => {
        handleFreezStart();
        aidVal = event.target.value;
        setAid(aidVal);
        getLinks();
    }

    const getAvailablePlansArray = async() =>{
        handleFreezStart();
        const apiUrl = '/remainingCobrandBasePID/' + companyId + '/' + aidId;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res){
            if(res.success === true) {
                setBasePlans(res.data);
                handleFreezStop();
                setplanAdd(true);

              
                
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                window.location.href= AppConfig.BASE_URL + 'login' ;
                return false;
            }            			
		}
	} 


    const getCustomLog = async(name)=>{
            
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var img = new Image();  
    
        // clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);
    
        var regex = /\s*(?:(\S{16})|([\s\S]{1,16})(?!\S))/g;
        var cname2= name.replace(regex, function($0,$1,$2) { return $1 ? $1 + "\n" : $2 + "\n"; } );

        var line=cname2.split("\n");
        var linelength = line.length;

        img.crossOrigin = 'anonymous';
        img.src = AppConfig.BASE_URL+'genericlogo.png';
        img.onload = async() => {
            canvas.width = "300";
            canvas.height = "90";
            
            context.drawImage(img, 0, 0);
            context.font = '22px Arial';
            context.fillStyle = 'black';

            var count = 100 / linelength;               
            for(var i=0; i < linelength-1; i++){
                context.fillText(line[i], img.width - 200, (count + (count*i)));
            }
            setImageUrl(canvas.toDataURL('image/png')); 
        }
    }
    const openPlanAddDialog = () => {
        getCobrandData();
        
    };
   

    const RequestFormSchema = Yup.object().shape({
        selectedPlanValues: Yup.string()
          .required('Please selete Plan')
    })
    const addCobrandPlan = async (values) => {
        setplanAdd(false);
        let updateFlag = 1;
      
        let plan = values.selectedPlanValues;
        localStorage.setItem('planId', plan);
        const url = `${AppConfig.BASE_URL}cobrand-request/${aidId}?updateFlag=1&planId=${plan}`;
        window.location.href = url;
       
    };
    const getCobrandData = async() =>{
        const apiUrl = '/cobrandRequest/show/'+aidId;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res){
            if(res.success === true) {
                getAvailablePlansArray();
                console.log(JSON.stringify(res))
               
                logoURL = require("../../../assets/images/uploadLogo.png");
                if(res.data.cobrandObj){
                    if(res.data.cobrandObj.company_logo){
                        logoURL = AppConfig.BACKEND_URL+"images/cobrandLogo/"+res.data.cobrandObj.company_logo;
                        logoFlag = res.data.cobrandObj.company_logo ? 1 : 0;
                    }
                  

                    const initialValues = {
                        updateFlag: 0,
                        companyName: res.data.cobrandObj ? res.data.cobrandObj.company_name:'',
                        email: res.data.cobrandObj ? res.data.cobrandObj.email : '',
                        email2: res.data.cobrandObj ? res.data.cobrandObj.email2 : '',
                        contactName: res.data.cobrandObj ? res.data.cobrandObj.name : '',
                        phoneNo: res.data.cobrandObj ? res.data.cobrandObj.phone ? formatPhoneNumber(res.data.cobrandObj.phone) : '' : '',                                
                        timezone: 'PDT',
                        workingHours: res.data.operational_hrs,
                        subtitle: res.data.cobrandObj ? res.data.cobrandObj.subtitle : '',            
                        companywebsite: res.data.cobrandObj ? res.data.cobrandObj.website_url : '',
                        plan: '',
                        specialMsg1: res.data.cobrandObj ? res.data.cobrandObj.special_message_1 : '<Company Name> offers a comprehensive program that enables customers to improve their credit and better manage their finances.',
                        specialMsg2: res.data.cobrandObj ? res.data.cobrandObj.special_message_2 : '',                                
                        logoFlag: logoFlag,
                        logo: res.data.cobrandObj ? res.data.cobrandObj.company_logo : '',
                        operationalHrs: res.data.cobrandObj ? res.data.cobrandObj.operational_hrs : '',
                        
                    }
                    setFormikValues(initialValues);
                }
			    setIsLoading(false);
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                window.location.href= AppConfig.BASE_URL + 'login' ;
                return false;
            }
            			
		}
	} 
    


    const handleFreeMember = async(memberId) => {
        handleFreezStart();
        const apiUrl = '/addFreeMemberInMyLink';
        const args = {
            memberId: memberId,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
            if (res.success === true) {
                handleFreezStop();
                
                // window.location.reload();
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
                
                .then(() => {
                    window.location.reload(); 
                });
            }else {
                handleFreezStop();
                
                swal({
                    title: "Alert",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
              
                .then(() => {
                    window.location.reload(); 
                });
            }
        }
        


    const validationSchemas = Yup.object().shape({
        memberId:Yup.string()
        .required(
             'Please enter a Member ID'
        )
        .matches(/^\d{7}$/, 'Member ID must be 7 digits')

    });

    function ContentLayout() {
        return (
            <>
               
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} mt={1}>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        <Typography level="h4" color="primary">Your MyFreeScoreNow Affiliate Link(s): (AID - {dataObj ? dataObj.selectedAid : ''})</Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12} className='marginsres'>
                        <FormControl fullWidth size="small">
                            <InputLabel id="mutiple-select-label">Select AID</InputLabel>
                            <Select
                                fullWidth
                                labelId="mutiple-select-label"
                                label="Select AID"
                                value={aid}
                                onChange={(e) => { handleChange(e) }}
                                MenuProps={MenuProps}
                            >
                                {
                                    dataObj
                                        ?
                                        dataObj.aidList
                                            ?
                                            dataObj.aidList.map((row,index) => {
                                                return (
                                                    <MenuItem value={row} key={index}>{row}</MenuItem>
                                                )
                                            })
                                            :
                                            <MenuItem value="none" >None</MenuItem>
                                        : ''
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <p ><b>Thank you</b> for choosing MyFreeScoreNow. Below are your Affiliate link(s). </p>
                <p>*The URL(s) below are unique to your company and <i>MUST BE USED EXACTLY</i> as shown below to receive your sales commission!*</p>
                <p style={{ color: "black", fontSize: "1em" }}>Make sure your clients know to keep their credit monitoring subscription active during the credit repair period and beyond so that they, AND YOU, have access to their 3-bureau reports and any changes to their report and/or scores over time. Plus you'll continue to earn residual commissions too!</p>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }}>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                            }}
                        >
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="h4">Your Cobranding Link(s): Required for Member Credentials Pass </Typography>
                            </Grid>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>

                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                {
                                    dataObj
                                        ?
                                        Object.values(dataObj.cobrandLinks).length > 0
                                            ?
                                            <>
                                            <List
                                                aria-labelledby="ellipsis-list-demo"
                                                sx={{
                                                    "--ListItemDecorator-size": "1px",
                                                }}>
                                                {dataObj.cobrandLinks.map((row, index) => {
                                                    return (
                                                        <ListItem key={row.id || index}>
                                                            <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 0.5 }}>
                                                                <img src={require("../../../assets/images/dollarbullet.png")} />
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ mr: 0.5 }}><Typography><strong>{row.description}</strong><br />
                                                                <a href={row.href} target='_blank' style={{ color: "2D6DB4", textDecoration: "none" }}>{row.href}</a>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ContentCopyOutlinedIcon sx={{ fontSize: "15px" }} onClick={() => {
                                                                    navigator.clipboard.writeText(row.href)
                                                                    setCopySuccess(row.pid)
                                                                    setTimeout(() => {
                                                                        setCopySuccess('');
                                                                    }, 3000);
                                                                }} />  {copySuccess == row.pid ? "Copied!" : ''}
                                                            </Typography>
                                                            </ListItemContent>
                                                        </ListItem>
                                                    );
                                                })
                                                }
                                            </List>
                                             <Box sx={{paddingLeft:'27px'}}>
                                             {PlanAvaliable == true ? cobrandStatus == 'Completed' ? 
                                         
                                                 <Button color="primary" variant="contained" sx={{ minWidth: 200 }} onClick={openPlanAddDialog}>Add More Plans</Button>
                                                 : <Typography sx={{ color: "black" }} level="h6">New Link Under Verification</Typography>
                                                :'' }
                                             </Box>
                                             </>
                                            :
                                            <>
                                            <Typography>
                                                Cobrand yet to setup, Please <a href="/cobrandpanel">Click here</a> to setup it now
                                            </Typography>
                                               
                                             </>
                                        :
                                        null
                                }
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Grid item lg={8} md={8} sm={12} xs={12}>
                                            <Typography variant="h3" sx={{ color:"black",fontWeight:500}}>
                                                <ExpandMore
                                                    expand={expanded}
                                                    onClick={handleExpandClick}
                                                    aria-expanded={expanded}
                                                    aria-label="show more"
                                                >
                                                <ExpandMoreIcon sx={{fontWeight:"bold"}}/>
                                                </ExpandMore>
                                                    <b>Your Free Member Details ({dataObj ? dataObj.countOfFreeMembers:''})</b>
                                            </Typography>
                                        </Grid> 
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <Button variant="contained"  color="success" sx={{minWidth : isMobile? 100 : 150}} onClick={handleClickOpen}>Add Free Member</Button>
                                        </Grid>
                                    </Grid>
                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><b>Member ID</b></TableCell>
                                                        <TableCell><b>Name</b></TableCell>
                                                        <TableCell><b>Email</b></TableCell>
                                                        <TableCell><b>Status</b></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {
                                                    dataObj && dataObj.freeMemberDetails && dataObj.freeMemberDetails.length > 0 ? (
                                                        <TableBody>
                                                            {dataObj.freeMemberDetails.map((row) => (
                                                                <TableRow key={row.id}>
                                                                    <TableCell>{row.member_id}</TableCell>
                                                                    <TableCell>{row.name}</TableCell>
                                                                    <TableCell>{row.email}</TableCell>
                                                                    <TableCell>{row.account_status}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    ) : null
                                                }
                                            </Table>
                                        </TableContainer>
                                    </Collapse>
                            </Grid> 
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <img src={require("../../../assets/images/mylink.png")} style={{width:"100%",height:"auto",display:"block",marginLeft:"auto",marginRight:"auto"}}/>
                            </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Dialog
                    open={planAdd}
                    onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setplanAdd();
                    }
                    }}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth={fullWidth}
                    maxWidth={'sm'}
                >

                    <Formik
                                initialValues={formikValues}
                                validationSchema={RequestFormSchema}
                                onSubmit={addCobrandPlan}
                               
                            >     
                            {({values, errors,touched,isSubmitting,setFieldValue, handleChange, handleBlur, handleSubmit}) => (    
                                <>  
                              
                               <DialogContent>
                               <Card sx={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                               <CardOverflow
                            sx={{
                                bgcolor:'#F6FAFF',
                                borderColor:'#F6FAFF',
                                padding: '1%',
                                textAlign:"center"
                                
                            }}>
                               
                                   <Typography variant="h5" sx={{ color:"black",fontWeight:500}}>Add Plan</Typography>
                                  
                        </CardOverflow>
                       
                        <CardContent>
                              
                               <Grid container item lg={12} md={12} sm={12} xs={12}>
                                   <FormControl required 
                                       component="fieldset" variant="standard">
                                       <FormGroup>
                                       <RadioGroup
                                            name="selectedPlanValues"
                                            id="selectedPlanValues"
                                            value={values.selectedPlanValues}
                                            onChange={(event) => {
                                                setFieldValue('selectedPlanValues', event.target.value);
                                            }}
                                            >
                                            {basePlansArray.map((row) => (
                                                <FormControlLabel
                                                key={row.base_pid}
                                                value={row.id}
                                                control={<Radio />}
                                                label={row.plan_name}
                                                />
                                            ))}
                                        </RadioGroup>
                                    
                                        </FormGroup>
                                       <FormHelperText style={{ color: errors.selectedPlanValues ? 'red' : 'inherit' }}>{errors.selectedPlanValues ? errors.selectedPlanValues : null}</FormHelperText>
                                   </FormControl>
                               </Grid>
                               </CardContent>
                            
                               </Card>
                               </DialogContent>
                                    <DialogActions>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button variant="contained"  sx={{ minWidth: 250 }} onClick={() => { setplanAdd() }}>
                                                Cancel
                                            </Button>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Button variant="contained"  color="success" sx={{ minWidth: 250 }} onClick={handleSubmit}>
                                                Continue
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                               </>   
                            )}
                            </Formik> 


                    
                </Dialog>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            <Typography  variant="h3" sx={{ color:"black",fontWeight:700}}>Add Free Member</Typography>
                    </DialogTitle>
                        <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                        <CloseIcon />
                        </IconButton>
                        <Formik
                            initialValues={{ memberId: '' }}
                            validationSchema={validationSchemas}
                            onSubmit={(values) => {
                                handleFreeMember(values);
                                setOpen(false);
                                // alert(values.memberId);
                            }}
                        >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit,setFieldValue }) => (
                   
                    <div>
                        <DialogContent dividers>
                                <FormControl fullWidth size='small'>
                                    <TextField
                                        name='memberId'
                                        id='memberId'
                                        label="Member ID"
                                        // inputProps={{ type: "number" }}
                                        onChange={(e) => { handleChange(e); }}
                                        error={touched.memberId && !!errors.memberId}
                                        value={values.memberId}
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                    <FormHelperText style={{ color: 'red',backgroundColor: '#ffffffad',paddingLeft:'3px' }}>
                                    {touched.memberId && errors.memberId}
                                </FormHelperText>
                                </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12}  sx={{ textAlign: "center", width: "100%" }}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained"  color="success" sx={{minWidth : isMobile? 100 : 150}}  type="submit"  onClick={handleSubmit}>
                               Submit
                            </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" sx={{minWidth : isMobile? 100 : 150}} onClick={handleClose} >
                               Close
                            </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                        </div>
                        
                         )}
                        
                          </Formik>
            </BootstrapDialog>
              
            </>
        );


    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
             {/* loader code start */}
      <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={freez}
            >
              <Freez />
            </Backdrop>
          </div>
          {/* loader code end */}
        </div>
    );
}
export default MyLink;