import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, } from '@mui/material';
import Moment from 'moment';


const AffAgreementCertificate = ({ formData, currentDate1,ipAddress,referenceId }) => {

  return (
    <Box sx={{ position: 'relative', padding: '20px', margin: '20px auto', minHeight: '1056px' }}>
      <img
        src={require('../../assets/images/border-certificate.png')}
        alt="Certificate Border"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',

        }}
      />
      <Box
        sx={{
          padding: '3.5%',
        }}
      >
        <Paper elevation={3} style={{
          padding: '20px',
          borderRadius: '10px',
          background: `url(${require("../../assets/images/Certificatebackground.jpg")}) no-repeat center center`,
          backgroundSize: 'cover',
          border: '2px solid #69a1cf',
          minHeight: '1009px' // Optional: Ensures the image covers the whole Paper component
        }}>
          <Typography variant="h4" textAlign="center" sx={{ fontWeight: 'bold' }} mb={4}>
            Signature Certificate
          </Typography>
          <Typography variant="body1">
            Reference Number: <span style={{ fontWeight: 'bold' }}>{referenceId}</span>
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Table>
                <TableHead className='certificate-table-head'>
                  <TableRow>
                    <TableCell sx={{ width: '33%' }}>Signer</TableCell>
                    <TableCell>Timestamp</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>Signature</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className='certificate-table-row'>
                  <>
                    <TableRow sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                      <TableCell colSpan={2}>
                        <Typography sx={{ textWrap: 'nowrap', fontWeight: 'bold' }}>{formData.FirstName1 + " " + formData.LastName1}</Typography>
                        <Typography>{formData.Email4}</Typography>
                      </TableCell>
                      <TableCell rowSpan={3} sx={{ paddingLeft: '7%' }}>
                        <img
                          src={formData.signature1}
                          alt="Signature"
                          style={{ border: '2px solid lightgray', maxWidth: '230px', padding: '20px 10px', marginBottom: '7px' }}
                        />
                        <Typography style={{ color: 'black' }}>IP Address: {ipAddress}</Typography>
                        {/* <Typography style={{ color: 'black' }}>location: Pune</Typography> */}
                      </TableCell>
                      <TableCell>

                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                      <TableCell colSpan={1}>
                        <Typography>Sent</Typography>
                        <Typography>Signed</Typography>
                      </TableCell>

                      <TableCell colSpan={1}>
                        <Typography>{Moment(formData.sentDate).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm')}</Typography>
                        <Typography>{currentDate1}</Typography>
                      </TableCell>
                      <TableCell>

                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderBottom: '1px solid black' }}>
                      <TableCell colSpan={1}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Receipt Verification</Typography>
                        <Typography> {'\u2713'} Email verified</Typography>
                      </TableCell>
                      <TableCell colSpan={1}>
                        <Typography>{Moment(formData.sentDate).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm')}</Typography>
                      </TableCell>
                      <TableCell colSpan={2} sx={{ paddingLeft: '7%' }}>
                      </TableCell>
                    </TableRow>

                    <>
                      <TableRow sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                        <TableCell colSpan={2}>
                          <Typography sx={{ textWrap: 'nowrap', fontWeight: 'bold' }}>Bruce Cornelius</Typography>
                          <Typography>info@myfreescorenow.com</Typography>
                        </TableCell>
                        <TableCell rowSpan={3} sx={{ paddingLeft: '7%', color: 'black' }}>
                          <div>
                            <img
                              src={formData.signature2}
                              alt="Signature"
                              style={{ border: '2px solid lightgray', minWidth: '230px', width: '230px', marginBottom: '7px' }}
                            />
                          </div>
                          {/* <Typography style={{ color: 'black !important' }}>IP Address: 115.13.99.45</Typography> */}
                          {/* <Typography style={{ color: 'black !important' }}>location: Pune</Typography> */}
                        </TableCell>
                        <TableCell>

                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderBottom: '1px solid black' }}>
                        <TableCell colSpan={1}>
                          <Typography>Sent</Typography>
                          <Typography>Signed</Typography>
                        </TableCell>

                        <TableCell colSpan={1}>
                          <Typography>{currentDate1}</Typography>
                          <Typography>{currentDate1}</Typography>
                        </TableCell>
                        <TableCell>

                        </TableCell>
                      </TableRow>
                    </>
                  </>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Box mt={4}>
            <img
              alt="#"
              src={require('../../assets/images/mfsn_logo.png')}
              className="pandadoc-certificate-img"
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AffAgreementCertificate;
