import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { CardOverflow, Card, CardContent, Typography } from '@mui/joy';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Formik } from 'formik';
import swal from "sweetalert";
import InputMask from 'react-input-mask';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';


import * as Yup from 'yup';
import { Grid, TextField, Button, Select, FormControl, MenuItem, InputLabel } from '@mui/material';

let values1 = [];
let isLowercaseValid=false;
let isUppercaseValid=false;
let isNumberValid=false;
let isSpecialCharValid=false;
let isLengthValid=false;
let showPasswordValidationBlock=false;



function AddUser(props) {
    const dispatch = useDispatch();
    const [age, setAge] = React.useState('');
    const [userTypeList, setuserTypeList] = useState([]);
    const navigate = useNavigate();


    const fetchuserTypes = async page => {
        const apiUrl = '/fetchUserTypes'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setuserTypeList(res);
        }
    }

    useEffect(() => {
        showPasswordValidationBlock=false;
        fetchuserTypes();
    }, [])
    const Create = async (values) => {
        showPasswordValidationBlock=false;
        const apiUrl = '/partnercreateuser';
        const args = {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            phone_no: values.phoneNumber,
            userType: values.userType,
            defaultTimezone: values.defaultTimezone,
            password: values.password,
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res.success === true) {
            swal({
                title: "Success",
                text: res.message,
                icon: "success",
                button: "Ok",
            }).then((ok) => {
                if (ok) {
                    navigate(-1);
                }
            });
        } else {
            swal({
                title: "Failed",
                text: "email Already Exist",
                icon: "error",
                button: "Ok",
            });
        }
    }



    const UserSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your full Name.'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your last Name.'),
        phoneNumber: Yup.string()
            .min(14, 'Phone number must be of 10 digit!')
            .max(14, 'Phone number must be of maximum 10 digit!')
            .required('Please enter your phone number.'),
        email: Yup.string()
            .email('Invalid email format.')
            .required('Please enter your email address.'),
        defaultTimezone: Yup.string()
            .required('user timezone need to be selected'),
        userType: Yup.string()
            .required('select user type'),
        password: Yup.string()
        .test(
            () => {
                return (
                    isLowercaseValid &&
                    isUppercaseValid &&
                    isNumberValid &&
                    isSpecialCharValid &&
                    isLengthValid
                );
            }
            
        )
        .required('Password is required.')
    });
        //password suggestion box
        const handlePasswordChange = (event) => {
            const newPassword = event.target.value;
            const lowercaseRegex = /[a-z]/;
            const uppercaseRegex = /[A-Z]/;
            const numberRegex = /[0-9]/;
            const specialCharRegex = /[!@#$%^&*]/;
            isLowercaseValid=lowercaseRegex.test(newPassword);
            isUppercaseValid=uppercaseRegex.test(newPassword);
            isNumberValid=numberRegex.test(newPassword);
            isSpecialCharValid=specialCharRegex.test(newPassword);
            isLengthValid=newPassword.length >= 8;
          };
      
          //hide show the password suggestion box
          const handlePasswordClick = () => {
              showPasswordValidationBlock=true;
          };
    function ContentLayout() {
        return (
            <>
                <Formik
                    initialValues={{
                        firstName: values1.firstName,
                        lastName: values1.lastName,
                        email: values1.email,
                        phoneNumber: values1.phoneNumber,
                        userType: values1.userType,
                        defaultTimezone: values1.defaultTimezone,
                        password: values1.password
                    }}
                    validationSchema={UserSchema}
                    onSubmit={(values, errors) => {
                        Create(values);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                        <Card variant='outlined'>
                            <CardOverflow
                                sx={{
                                    bgcolor: '#F6FAFF',
                                    borderColor: '#F6FAFF',
                                    padding: "1%"
                                }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography level="h4"><b>Create New User</b></Typography>
                                </Grid>
                            </CardOverflow>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField label="First Name"
                                            name='firstName'
                                            id='firstName'
                                            onChange={(e) => { handleChange(e); }}
                                            value={values.firstName}
                                            onBlur={handleBlur('firstName')}
                                            variant="outlined" fullWidth size="small" margin='dense'
                                        />
                                        {touched.firstName && errors.firstName ? <div className='error'>{errors.firstName}</div> : null}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField label="Last Name"
                                            name='lastName'
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            margin='dense'
                                            id='lastName'
                                            onChange={(e) => { handleChange(e); }}
                                            value={values.lastName}
                                            onBlur={handleBlur('lastName')}
                                        />
                                        {touched.lastName && errors.lastName ? <div className='error'>{errors.lastName}</div> : null}
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField label="E-Mail Address"
                                            id='email'
                                            name='email'
                                            onChange={(e) => { handleChange(e); }}
                                            value={values.email}
                                            onBlur={handleBlur('email')}
                                            variant="outlined" fullWidth size="small" margin='dense' />
                                        {touched.email && errors.email ? <div className='error'>{errors.email}</div> : null}

                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <InputMask
                                            mask='(999) 999-9999'
                                            maskChar={null}
                                            name='phoneNumber'
                                            fullWidth
                                            size="small"
                                            margin='dense'
                                            id='phoneNumber'
                                            onChange={(e) => { handleChange(e); }}
                                            value={values.phoneNumber}
                                            onBlur={handleBlur('phoneNumber')}
                                        >
                                            {(inputProps) =>
                                                <TextField
                                                    label="Phone Number" variant="outlined"
                                                    {...inputProps}
                                                />
                                            }
                                        </InputMask>
                                        {touched.phoneNumber && errors.phoneNumber ? <div className='error'>{errors.phoneNumber}</div> : null}
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormControl fullWidth size="small" margin='dense'>
                                            <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                name='userType'
                                                id='userType'
                                                label="userType"
                                                onChange={(e) => { handleChange(e); }}
                                                value={values.userType}
                                                onBlur={handleBlur('userType')}
                                            >
                                                {userTypeList.map((userType) => (
                                                    <MenuItem
                                                        value={userType.id}
                                                    >
                                                        {userType.role_name}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                            {touched.userType && errors.userType ? <div className='error'>{errors.userType}</div> : null}


                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormControl fullWidth size="small" margin='dense'>
                                            <InputLabel id="demo-simple-select-label">Default Timezone</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                name='defaultTimezone'
                                                id='defaultTimezone'
                                                label="defaultTimezone"
                                                onChange={(e) => { handleChange(e); }}
                                                value={values.defaultTimezone}
                                                onBlur={handleBlur('defaultTimezone')}
                                            >
                                                <MenuItem value="EDT" >Eastern Time</MenuItem>
                                                <MenuItem value="HST" >Hawaii Time</MenuItem>
                                                <MenuItem value="AKDT">Alaska Time</MenuItem>
                                                <MenuItem value="PDT" >Pacific Time</MenuItem>
                                                <MenuItem value="MDT" >Mountain Time</MenuItem>
                                                <MenuItem value="CDT" >Central Time</MenuItem>
                                            </Select>
                                            {touched.defaultTimezone && errors.defaultTimezone ? <div className='error'>{errors.defaultTimezone}</div> : null}

                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField label="password"
                                            name='password'
                                            fullWidth
                                            size="small"
                                            margin='dense'
                                            id='password'
                                            onChange={(e) => {
                                                handlePasswordChange(e); 
                                                handleChange(e);
                                              }}
                                            onKeyDown={handlePasswordClick} //hide show the password suggestion box
                                            value={values.password}
                                            onBlur={ handleBlur('password')}
                                        />
                                        {touched.password && errors.password ? <div className='error'>{errors.password}</div> : null}
                                        {showPasswordValidationBlock && (
                                                    <>
                                                           <div>
                                                            <span style={{ color: isLengthValid ? 'green' : 'red' }}>Minimum <b>8 characters</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isSpecialCharValid ? 'green' : 'red' }}>At least <b>one special character</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isUppercaseValid ? 'green' : 'red' }}>At least <b>one capital (uppercase)</b> letter</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isNumberValid ? 'green' : 'red' }}>At least <b>one number</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isLowercaseValid ? 'green' : 'red' }}>At least <b>one lowercase</b> letter </span>
                                                        </div>
                                                    </>
                                           )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                                            <Grid item lg={6} md={6} sm={6} xs={12} mt={1} sx={{ mt: 1, pr: { lg: 1, xl: 1, md: 1, sm: 1, xs: 0 } }}>
                                                <Button variant='contained' color='success' margin="dense" fullWidth className="buttons" sx={{ backgroundColor: "#76C044", color: "white" }} onClick={handleSubmit}>Submit</Button>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12} mt={1} sx={{ mt: 1, pl: { lg: 1, xl: 1, md: 1, sm: 1, xs: 0 } }}>
                                                <Button variant="contained" margin="dense" fullWidth onClick={() => {showPasswordValidationBlock=false;navigate(-1)}} >
                                                    CLOSE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}

                </Formik>
            </>
        )
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
           
        </div>
    );

}

export default AddUser;