import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination, button } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import config from '../../../AppConfig';
import Sidebar from '../../../Components/Blogs/Sidebar';
function CreditScoreLink() {
    const [showMoreStates, setShowMoreStates] = useState({});

    const toggleText = (id) => (showMoreStates[id] ? 'Close' : 'Read more');

    const handleToggle = (id) => {
        setShowMoreStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };
    return (
        <>
            <LoginHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={2} md={2} sm={12} xs={12} >
                        <Sidebar />
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12} sx={{ padding: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <img src={require("../../../assets/images/mfsn_banner_4.jpg")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className='responsiveImg' />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='h4'>Credit Scores</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Your credit score can have a significant impact on your life. With today’s technology, credit scores can be used day and night to make an instant assessment of your creditworthiness. Knowing and understanding your credit score gives you a financial edge that could put money in your pocket through lower interest rates and lower monthly payments.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>What is a Credit Score?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>A credit score is a computer-generated three-digit number that summarizes the information in your credit report based on complex mathematical algorithms. It is intended to be a prediction of how you will handle future credit obligations.</Typography>
                            {showMoreStates[1] && (
                                <Typography level='title-md'>
                                    The higher your credit score, the lower the risk a lender takes in providing you credit and the better your chance to obtain credit and low interest rates. The lower your credit score, the higher the risk you pose to the lender. In return the lender may offer you a smaller loan and/or ask you to pay a higher interest rate or require a larger down payment or deposit.
                                </Typography>
                            )}
                            <Button onClick={() => handleToggle(1)}>
                                {toggleText(1)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Understanding Credit Scores</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>No one would deny that confusion abounds when it comes to credit scores. The confusion comes largely from the myth that there is just one credit score used by all lenders. There isn’t! A creditor may use its own credit scoring model or one of hundreds of scoring models available to predict your creditworthiness. The same creditor may even use different scoring models for different types of applications.</Typography>
                            {showMoreStates[2] && (
                                <div>
                                    <p>
                                        There are many credit scoring models in use today and different scales as well. One scoring model may go from 300 to 850 while another may go from 500 to 990. Because so many different scoring models are used, there is no single "good" score. Far more important is how you rank on the particular scale being used. Because scoring models in general take the same things into consideration, a good score on one scale will usually translate to a good score on another scale.
                                    </p>
                                    <p>
                                        Even with the same scoring model, every company sets its own criteria. What one lender calls a good score, another may call fair. Or, the standard may vary depending on the situation. For example, there may be a higher standard for a mortgage loan than for an automobile loan or credit card application.
                                    </p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(2)}>
                                {toggleText(2)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Factors That Affect Your Credit Score</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Although there are many credit scoring models in use today, most use similar criteria to quickly and objectively predict your creditworthiness. The actual formulas used for credit scoring are complex and tightly-held secrets, but certain key factors are considered in most scoring models.</Typography>
                            {showMoreStates[3] && (
                                <div>
                                    <p><b>Payment History</b></p>
                                    <p>More than anything else, lenders want to know how you pay your bills. So, your payment history usually carries the most weight with any scoring model. While a single late payment may have little impact on your score, a trend of late payments will not go unnoticed. Delinquent accounts and bankruptcies can cause your credit score to drop significantly.</p>
                                    <p><b>Amount Owed</b></p>
                                    <p>How much you owe is another factor that typically carries a lot of weight with most scoring models. Creditors are interested in how much credit you have already committed to, especially in relation to your credit limits. If you are near or over your credit limits, prospective lenders may be hesitant to issue more credit. Even if you pay your bills in full each month, your credit score may factor in a large balance that you have not yet paid.</p>
                                    <p><b>Available Credit</b></p>
                                    <p>Creditors care about how much credit is available to you - to a point. They know that the credit available to you today could move to the "Amount Owed" category tomorrow. More important, however, is how you are managing your available credit over time.</p>
                                    <p><b>Length of Credit History</b></p>
                                    <p>Lenders view a longer credit history favorably. For that reason, it is rarely a good idea to close old accounts, even if you aren’t using them. However, idle accounts will not help your score as much as using an account and making timely payments.</p>
                                    <p><b>Types of Credit</b></p>
                                    <p>Having a proven track record with a variety of types of credit (mortgage, car loan, credit cards, etc.) is viewed positively by creditors. However, this factor does not weigh as heavily on your score as your payment history or the amounts you owe. It is seldom wise to apply for credit you don’t need just for the variety.</p>
                                    <p><b>New Credit</b></p>
                                    <p>Many scoring models factor in applications for new credit by looking at "hard inquiries" on your report - those that result from an application you initiate. Unless they are excessive, inquiries usually have a minimal impact on your credit score. However, it is something to think about when you fill out a credit application in return for 10% off today’s purchases. When you are rate shopping for a mortgage or auto loan, multiple inquiries within a short period of time are counted as a single inquiry. New accounts will also lower the average age of all of your accounts which could negatively affect your score.</p>
                                    <p><b>Time</b></p>
                                    <p>Negative information can legally remain on your credit report for seven years (or ten years for bankruptcies), but most credit scoring models take into consideration how old negative data is. A more recent late payment will count against your score more than an older one. As delinquencies and bankruptcies get older, they also carry less weight.</p>
                                    <p><b>Overall File</b></p>
                                    <p>How much certain factors affect your score depends greatly on the rest of the information in your credit report. For example, a late or missed payment will have a larger negative effect on the score of someone with an otherwise good credit report than it would for someone with an already poor credit report. As the information in your credit report changes, the importance of certain factors on your credit report also changes.</p>
                                    <p><b>Accuracy of the Information in Your Credit Report</b></p>
                                    <p>Since credit scores are calculated based on the information in your credit report, it goes without saying that inaccurate information can affect your credit score.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(3)}>
                                {toggleText(3)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Factors That Do Not Affect Your Credit Score</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>The Federal Reserve Board’s Regulation B includes the Equal Credit Opportunity Act (ECOA) which prohibits a credit scoring model from considering:</Typography>
                            {showMoreStates[4] && (
                                <div>
                                <p>Sex Marital status Race or skin color Religion National origin Age may be included in a scoring model as long as it gives equal or better consideration to the elderly.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(4)}>
                                {toggleText(4)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Improving Your Credit Score</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>The best advice for improving your credit score can be condensed to "Manage your credit responsibly over time." Scores are designed to evaluate trends in your credit history. Responsible use of credit over time should result in a higher credit score.</Typography>
                            {showMoreStates[5] && (
                                <div>
                                    <p>Here are some specific tips to consider:</p>
                                    <p>Pay your bills on time, every time. Your payment history is the single most important factor affecting your credit score. If you are forgetful, consider automatic payments. Even unpaid parking tickets and library fines can be reported to the credit bureaus.</p>
                                    <p>Keep credit card balances low in proportion to credit limits. Aim to keep balances at or below 25% of your credit limit - even if you pay your balance in full every month.</p>
                                    <p>Minimize new credit applications. This is especially important when you have a "thin" credit file without a lot of credit history.</p>
                                    <p>Keep and use old accounts. Older accounts can help establish the length of your credit history; however, a dormant account doesn’t help the way an active account does. Rotate your credit cards to keep your older accounts active.</p>
                                    <p>Use credit sparingly. Studies show that those with the highest credit scores use very little credit. Don’t open accounts you don't need.</p>
                                    <p>Avoid bankruptcy. Declaring bankruptcy is one of the worst things you can do for your credit score. It will stay on your credit report for seven to ten years.</p>
                                    <p>Check your credit report for accuracy.Studies show that nearly 70% of credit reports contain an error. Since credit scores are calculated based on the information in your credit report, inaccurate information can affect your credit score.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(5)}>
                                {toggleText(5)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Credit Score Myths</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>There is no shortage of myths when it comes to credit scores. One of the most popular myths is that ordering your own credit report will hurt your credit score. There is absolutely no truth to that!</Typography>
                            <Typography level='title-md'>Ordering your own credit report counts as a "soft inquiry." Creditors do not see soft inquiries, nor are they factored into your credit score. Period.</Typography>
                            {showMoreStates[6] && (
                                <div>
                                    <p><b>Here are some other popular credit score myths.</b></p>
                                    <ul>
                                        <li>There is only one "real" credit score.<br/>This is simply not true. There are hundreds of different credit scores in use today and the score ranges may vary. One lender might even use a<br/> different scoring model for each type of loan it offers.</li>
                                        <li>Closing accounts will improve my credit score.<br/>This is outdated advice. We now know two good reasons not to close accounts, especially older accounts. When you close an account, it raises the<br/> ratio of your debt to your available credit. Closing an older account can also shorten your credit history.</li>
                                        <li>Co-signing for a loan will not affect your credit score.<br/>Whenever you co-sign for a loan, you are accepting full responsibility for the loan. Details of the account will probably appear on both individual’s <br/>credit reports, and therefore be factored into both individual's credit score.</li>
                                        <li>I can boost my credit score by having negative information removed.<br/>Generally, negative information may remain on your file for seven years from the date of delinquency. Bankruptcies may remain for ten years and tax<br/> liens may remain seven years from the date they are paid. There is no legal way to remove accurate negative information.</li>
                                        <li>Shopping for the best credit rate will hurt my credit score.<br/>While it is true that too many inquiries can raise a red flag that you are going on a credit binge, most credit score models can recognize when you are<br/> rate shopping for a mortgage or auto loan and will count it as a single inquiry that will have minimal impact on your score.</li>
                                        <li>Credit counseling is as bad or worse than filing for bankruptcy.<br/>While credit counseling may alert potential lenders of a credit concern, most lenders look at the bigger picture. Some even see consumer counseling <br/> as a positive sign of a commitment to making lasting changes in your credit habits. Bankruptcy, on the other hand, should be avoided if at all possible.</li>
                                        <li>My spouse’s good credit score will help my credit score.<br/>The reality is credit reports are kept on individuals - not on couples. Your credit score is based on what is in your credit report only. However, cards<br/> that are in both names may appear on both individual’s report.</li>
                                    </ul>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(6)}>
                                {toggleText(6)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Are you monitoring your credit?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={4}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' style={{ textDecoration: "none" }}><Typography level='title-md' sx={{ color: "#1976d2" }}>Get your free credit score and score report now and protect your credit.</Typography></a>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' className='blog'>Get your free credit score and score report now and protect your credit.</a>
                        </Grid> */}
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <img src={require("../../../assets/images/lt_banner_5.png")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default CreditScoreLink;