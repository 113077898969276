
import React, { useState } from "react";
import { Grid, TextField, Typography } from '@mui/material';
import { Card, CardContent, CardOverflow } from '@mui/joy';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import Loader from '../Loader/Loader';

function AffiAgreement2({ formData, handleFormChange, errors, handleBlur, status }) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const fetchLocationInfo = async (zipcode) => {
        const apiUrl = "/zipcode/" + zipcode;
        handleFormChange("ZipCode2", zipcode)
        if (zipcode.length == 5) {
            setIsLoading(true)
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if (res.success) {

                setTimeout(() => {
                  
                    handleFormChange("City2", res.data.places[0]['place name'])
                    handleFormChange("State2", res.data.places[0]['state abbreviation'])
                    setIsLoading(false)
                }, 2000)
            }
            else {
                handleFormChange("City2", '')
                handleFormChange("State2", '')
                setIsLoading(false)
            }
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card" sx={{ width: '100%' }}>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'block' }}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} justifyContent="center">
                                <Typography variant="h6" color="primary" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    Affiliate Program Document
                                </Typography>
                            </Grid>

                            <Formik
                                initialValues={formData}
                                enableReinitialize={true} // This ensures that Formik updates the form values when formData changes
                            >

                                {({ handleChange }) => (
                                    <Grid item >
                                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                            <Grid item lg={3} md={3} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                <Typography variant="h6">Business Name:</Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                                <TextField
                                                    inputProps={{ readOnly: true }}
                                                    label="Business Name*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    name='BusinessName1'
                                                    value={formData.BusinessName1}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleFormChange('BusinessName1', e.target.value);
                                                    }}
                                                    error={errors.BusinessName1}
                                                    onBlur={(e) => handleBlur('BusinessName1', e.target.value)}
                                                    helperText={errors.BusinessName1}
                                                    InputLabelProps={{ className: 'label-to-hide' }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                            <Grid item lg={3} md={3} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                <Typography variant="h6">Contact Name:</Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <TextField
                                                            inputProps={{ readOnly: status == 1 ? true : false }}
                                                            label="First Name*"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            name='FirstName1'
                                                            value={formData.FirstName1}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                handleFormChange('FirstName1', e.target.value);
                                                            }}
                                                            onBlur={(e) => handleBlur('FirstName1', e.target.value)}
                                                            error={Boolean(errors.FirstName1)}
                                                            helperText={errors.FirstName1}
                                                            InputLabelProps={{ className: 'label-to-hide' }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <TextField
                                                            inputProps={{ readOnly: status == 1 ? true : false }}
                                                            label="Last Name*"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            // inputRef={inputRef}
                                                            name='LastName1'
                                                            value={formData.LastName1}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                handleFormChange('LastName1', e.target.value);
                                                            }}
                                                            onBlur={(e) => handleBlur('LastName1', e.target.value)}
                                                            error={Boolean(errors.LastName1)}
                                                            helperText={errors.LastName1}
                                                            InputLabelProps={{ className: 'label-to-hide' }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                            <Grid item lg={3} md={3} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                <Typography variant="h6">Physical Address:</Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <TextField
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        label="Street Address"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        name='StreetAddress1'
                                                        value={formData.StreetAddress1}
                                                        onChange={(e) => {
                                                            // handleChange(e);
                                                            handleFormChange('StreetAddress1', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('StreetAddress1', e.target.value)}
                                                        error={Boolean(errors.StreetAddress1)}
                                                        helperText={errors.StreetAddress1}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    />
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                        <TextField
                                                            inputProps={{ readOnly: status == 1 ? true : false }}
                                                            label="Zip Code"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            name='ZipCode1'
                                                            value={formData.ZipCode1 ? formData.ZipCode1 : ''}
                                                            onChange={(e) => {
                                                                // handleChange(e);
                                                                handleFormChange('ZipCode1', e.target.value);
                                                            }}
                                                            onBlur={(e) => handleBlur('ZipCode1', e.target.value)}
                                                            error={Boolean(errors.ZipCode1)}
                                                            helperText={errors.ZipCode1}
                                                            InputLabelProps={{ className: 'label-to-hide' }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                        <TextField
                                                            inputProps={{ readOnly: status == 1 ? true : false }}
                                                            label="State"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            // inputRef={inputRef}
                                                            name='State1'
                                                            value={formData.State1}
                                                            onChange={(e) => {
                                                                // handleChange(e);
                                                                handleFormChange('State1', e.target.value);
                                                            }}
                                                            onBlur={(e) => handleBlur('State1', e.target.value)}
                                                            error={Boolean(errors.State1)}
                                                            helperText={errors.State1}
                                                            InputLabelProps={{ className: 'label-to-hide' }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                        <TextField
                                                            inputProps={{ readOnly: status == 1 ? true : false }}
                                                            label="City*"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            name='City1'
                                                            value={formData.City1}
                                                            onChange={(e) => {
                                                                // handleChange(e);
                                                                handleFormChange('City1', e.target.value);
                                                            }}
                                                            onBlur={(e) => handleBlur('City1', e.target.value)}
                                                            error={Boolean(errors.City1)}
                                                            helperText={errors.City1}
                                                            InputLabelProps={{ className: 'label-to-hide' }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                            <Grid item lg={3} md={3} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                <Typography variant="h6">Mailing Address <br />(If Different):</Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <TextField
                                                        inputProps={{ readOnly: status == 1 ? true : false }}
                                                        label="Street Address"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        name='StreetAddress2'
                                                        value={formData.StreetAddress2}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            handleFormChange('StreetAddress2', e.target.value);
                                                        }}
                                                        onBlur={(e) => handleBlur('StreetAddress2', e.target.value)}
                                                        error={Boolean(errors.StreetAddress2)}
                                                        helperText={errors.StreetAddress2}
                                                        InputLabelProps={{ className: 'label-to-hide' }}
                                                    />
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                        <TextField
                                                            inputProps={{ readOnly: status == 1 ? true : false }}
                                                            label="Zip Code"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            name='ZipCode2'
                                                            value={formData.ZipCode2}
                                                            onChange={(e) => {
                                                                // handleChange(e);
                                                                fetchLocationInfo(e.target.value);
                                                                // handleFormChange('ZipCode2', e.target.value);
                                                            }}
                                                            onBlur={(e) => handleBlur('ZipCode2', e.target.value)}
                                                            error={Boolean(errors.ZipCode2)}
                                                            helperText={errors.ZipCode2}
                                                            InputLabelProps={{ className: 'label-to-hide' }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                        <TextField
                                                            inputProps={{ readOnly: status == 1 ? true : false }}
                                                            label="State"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            // inputRef={inputRef}
                                                            name='State2'
                                                            value={formData.State2 || ''}
                                                            onChange={(e) => {
                                                                // handleChange(e);
                                                                handleFormChange('State2', e.target.value);
                                                            }}
                                                            onBlur={(e) => handleBlur('State2', e.target.value)}
                                                            error={Boolean(errors.State2)}
                                                            helperText={errors.State2}
                                                            InputLabelProps={{ className: 'label-to-hide', shrink: Boolean(formData.State2) }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                        <TextField
                                                            inputProps={{ readOnly: status == 1 ? true : false }}
                                                            label="City"
                                                            type="text"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            name='City2'
                                                            value={formData.City2 || ''}
                                                            onChange={(e) => {
                                                                // handleChange(e);
                                                                handleFormChange('City2', e.target.value);
                                                            }}

                                                            onBlur={(e) => handleBlur('City2', e.target.value)}
                                                            error={Boolean(errors.City2)}
                                                            helperText={errors.City2}
                                                            InputLabelProps={{ className: 'label-to-hide', shrink: Boolean(formData.City2) }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                            <Grid item lg={3} md={3} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                <Typography variant="h6">Email:</Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                                <TextField
                                                    inputProps={{ readOnly: true }}
                                                    label="Email Address*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    name='Email1'
                                                    value={formData.Email1}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleFormChange('Email1', e.target.value);
                                                    }}
                                                    onBlur={(e) => handleBlur('Email1', e.target.value)}
                                                    error={Boolean(errors.Email1)}
                                                    helperText={errors.Email1}
                                                    InputLabelProps={{ className: 'label-to-hide' }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                            <Grid item lg={3} md={3} sm={12} xs={12} sx={{ margin: 'auto' }}>
                                                <Typography variant="h6">Phone:</Typography>
                                            </Grid>
                                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                                <InputMask
                                                    mask='(999) 999-9999'
                                                    maskChar={null}
                                                    // inputProps={{ readOnly: status == 1 ? true : false }}
                                                    label="Phone Number*"
                                                    autoComplete="Phone1"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    id='Phone1'
                                                    name='Phone1'
                                                    value={formData.Phone1}
                                                    onChange={(e) => {
                                                        // handleChange(e);
                                                        handleFormChange('Phone1', e.target.value);
                                                    }}
                                                    onBlur={(e) => { handleBlur('Phone1', e.target.value) }}
                                                    error={Boolean(errors.Phone1)}
                                                    helperText={errors.Phone1}
                                                    InputLabelProps={{ className: 'label-to-hide' }}
                                                >
                                                    {(inputProps) =>
                                                        <TextField
                                                            label="Phone Number" variant="outlined"
                                                            {...inputProps}

                                                        // sx={isSubmitted ? { backgroundColor: '#e8f0fe', color: '#D3D3D3', cursor: 'not-allowed' } : null}
                                                        />
                                                    }
                                                </InputMask>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Formik>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement2;