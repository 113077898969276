import React,{ useState, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import {Box,Grid,Card,CardContent,FormControlLabel,Checkbox,FormHelperText,FormControl} from '@mui/material';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../../views/Auth/RegisterFooter';
import { format  } from 'date-fns';


import { Typography,Button } from '@mui/joy';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../redux/apiSlice';
function PendingOffer() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [closeDate, setCloseDate] = useState('');
    const [offer,setOffer]=useState('FREE MONTH & $19.95 monthly thereafter');
    const [id,setId]=useState('');
    const [checked, setChecked] = useState(false);
    const [disable, setDisable] = useState(0);
    const [acceptedFlag , setAcceptedFlag] = useState(1);
    const [freez, setFreez] = React.useState(true);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };

    useEffect(() => {
        const pathname = window.location.pathname; // Get current URL path
        const idNew = pathname.split('/').pop();
        setId(atob(idNew));
        fetchMemberDetails(atob(idNew));
    }, [])

    const fetchMemberDetails = async(id) => {
        handleFreezStart();
        const apiUrl = `/getmember/`+ id;

        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res)
        {
            setName(res.data['name']);
            const dateFormat = new Date(res.data['close_date']);
            const formattedDate = format(dateFormat, 'MMMM dd, yyyy');
            setCloseDate(formattedDate)
            if(res.data['offer_send']=="B")
            {
                setOffer('FREE MONTH') 
            }
            if(res.data['offer_accepted'] == 1)
            {
                navigate('/accept?id='+btoa(id)+'&flag=0');
            }
            handleFreezStop();
        } 
        handleFreezStop();
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
 

    const handleAccept = async () => {
        if(checked == true){
            setDisable(0);
            handleFreezStart();
            const ReactButton = 1;
            const apiUrl = `/offerAccepted/`+id+`/`+ReactButton+'/'+acceptedFlag;
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            console.log(res);
            // Handle redirect if response contains redirect URL
            
            if (res.redirectUrl) {
                handleFreezStop();
                window.location.href = res.redirectUrl;
            }
            handleFreezStop();
        }else{
            setDisable(1);
        }
        
     }

  return (
    <>
    <LoginHeader />

    <Box className="back" sx={{ padding: "2%" }}>
        <Card sx={{padding:" 0% 0% 4% 4% "}}>
        <CardContent>
        <div>
            <table>
                <tbody>
                    <tr>
                        <td style={{ padding: '0 5px 10px', height: '40px', textAlign: 'center' }}>
                            <h2>
                            <strong> Urgent: {name}, Your Financial Health at Risk! Special Offer to Reinstate Your Subscription!</strong>
                            </h2>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '0 35px' }}>
                            <Typography variant="body1">
                                Dear<strong> {name},</strong>
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '0 35px' }}>
                            <Typography variant="body1">
                                Your recent decision to cancel your subscription to our 24/7 credit monitoring service could have chilling consequences for your financial future. 
                            </Typography>
                            <Typography variant="body1">
                                <strong>Here's why:</strong>
                            </Typography>
                            <ul>
                                <Typography variant="body1" component="li">
                                    <strong>Blind to Injustice:</strong> Errors in your credit report can go undetected, silently sabotaging your financial standing.
                                </Typography>
                                <Typography variant="body1" component="li">
                                    <strong>Financial Paralysis:</strong> A neglected credit score can lead to being denied credit when you need it most.
                                </Typography>
                                <Typography variant="body1" component="li">
                                    <strong>Missed Opportunities:</strong> Neglecting your credit health means missing out on crucial opportunities like buying a car, a home, or landing a dream job.
                                </Typography>
                            </ul>
                            <Typography variant="body1">
                                Don't let these risks become your reality. Take action now to safeguard your financial well-being.
                            </Typography>
                            <Typography variant="body1">
                                Click the button below to reinstate your subscription and access our 
                            </Typography>
                            <Typography variant="body1">
                                <strong style={{ backgroundColor: 'yellow' }}>Exclusive Offer: {offer}</strong>.
                            </Typography>
                            <Typography variant="body1">
                                This offer expires by {closeDate}
                            </Typography>
                            <Typography variant="body1">
                                Your financial future hangs in the balance - make the choice that secures it.
                            </Typography>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <FormControl error={!checked ? true : false}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    name="termsAndConditions"
                                    color="primary"
                                />
                            }
                            label="I agree to the terms and conditions"
                        />
                        {disable == 1 ?
                        !checked ? <><FormHelperText className='error'>{"Please select the checkbox and click the button below to accept terms and conditions."}</FormHelperText></> : null : null}
                    </FormControl>
                </Grid>
                            <div style={{ textAlign: 'center' }}>
                            <Button
                                    onClick={handleAccept}
                                    style={{
                                        background: '#20e277',
                                        textDecoration: 'none !important',
                                        fontWeight: 500,
                                        marginTop: '35px',
                                        color: '#fff',
                                        textTransform: 'uppercase',
                                        fontSize: '14px',
                                        padding: '10px 24px',
                                        display: 'inline-block',
                                        borderRadius: '50px',
                                        border: 'none',
                                        cursor: 'pointer'
                                    }}
                                >
                                    REDEEM OFFER NOW
                                </Button>
                            </div>
                            <div style={{ backgroundColor: '#f0f0f0', padding: '15px', marginTop: '35px', borderRadius: '10px', fontSize: '14px' }}>
                                <Typography variant="body1" component="h4">
                                    <i>Special Offer Terms and Conditions:</i>
                                </Typography>
                                <Typography variant="body1" style={{ fontSize: '14px' }}>
                                    <strong>Offer Redemption:  By clicking the button "REDEEM OFFER NOW", you consent to the reactivation of your subscription account.</strong>
                                </Typography>
                               
                                <Typography variant="body1" style={{ fontSize: '12px' }}>
                                    This special offer is valid for a limited time only and is subject to change or withdrawal without prior notice. Eligibility: This offer is available to customers who have previously subscribed to our 24/7 credit monitoring service and have expressed intent to cancel their subscription. Offer Redemption: To redeem this offer, customers must click on the provided link and follow the instructions for reinstating their subscription. Limitations: This offer cannot be combined with any other promotions, discounts, or offers. It is limited to one redemption per customer. Exclusions: The special offer does not apply to any additional services or features beyond the standard subscription package. Disclaimer: We reserve the right to revoke or modify this offer at any time, for any reason, without liability. No Cash Value: This offer has no cash value and is non-transferable. Governing Law: These terms and conditions are governed by Washington laws. Please note that by accepting this offer, you acknowledge and agree to all the terms and conditions outlined above. If you have any questions or concerns, please contact our customer support team for assistance.
                                </Typography>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </CardContent>
        </Card>
    </Box>

   
    <RegisterFooter />

   


        {/* loader code start */}
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={freez}
          >
            <Freez />
          </Backdrop>
        </div>
        {/* loader code end */}
      </>
  
  );
}

export default PendingOffer;
