import React from 'react';
import { useNavigate,} from 'react-router-dom';
import { Box, Grid, Tooltip, } from '@mui/material';
import { Typography, } from '@mui/joy';
import BackIcon from '@mui/icons-material/HighlightOff';
function Faq(props) {
    const navigate = useNavigate();
    const closeTab = () => {
        if (props.footer == true) {
            props.closeVal(false)
        }
        else {
            navigate(-1);
        }

    }
    return (
        <>
            <Box sx={{ minHeight: 300, maxHeight: 500, borderTop: "3px solid #5288db" }}>
                <Box p={4}>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Grid lg={6} md={6} sm={6} xs={6} item>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="h2" sx={{ borderBottom: "3px solid #5288db" }}>Frequently Asked Questions (FAQ)</Typography>
                            </Grid>
                        </Grid>
                        <Grid lg={6} md={6} sm={6} xs={6} item className='companyLogo'>
                            <Tooltip title="Close" sx={{position:'fixed'}}>
                                <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={closeTab} />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Box sx={{ marginLeft: "10%", marginRight: "10%", justifyContent: "center" }} className="faq-list">
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Grid lg={6} md={6} sm={6} xs={6} item>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography level="h4">General Questions</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <ul>
                                        <a id='question-membership1' href="#question-membership"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">What do I get with a MyFreeScoreNow.com membership?</Typography></li></a>
                                        <a id='question-creditScore1' href="#question-creditScore"> <li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Will viewing my credit report hurt my credit score?</Typography></li></a>
                                        <a id='question-creditScore-report1' href="#question-creditScore-report"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Is it safe to order my credit score and view my report online?</Typography></li></a>
                                        <a id='question-information1' href="#question-information"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">How long will my information be available to me online?</Typography></li></a>
                                        <a id='question-monitoringMembership1' href="#question-monitoringMembership"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Who is included in my credit monitoring membership?</Typography></li></a>
                                        <a  id='question-print-creditScore-report1'href="#question-print-creditScore-report"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">How do I print my credit report or credit score analysis?</Typography></li></a>
                                        <a id='question-logReport1' href="#question-logReport"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">I forgot my Username and/or Password. How do I log in to see my report?</Typography></li></a>
                                        <a id='question-offer1' href="#question-offer"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">If I was once a member, can I get a second free trial offer?</Typography></li></a>
                                    </ul>
                                </Grid>
                            </Grid>
                            <Grid lg={6} md={6} sm={6} xs={6} item>
                                <Grid container item lg={12} md={12} sm={12} xs={12} >
                                    <Typography level="h4">Credit Scores</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <ul>
                                    <a id='creditScore-use1' href="#creditScore-use"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">What is a credit score and who uses them? </Typography></li></a>
                                    <a id='creditScore-calculated1' href="#creditScore-calculated"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">How are credit scores calculated?</Typography></li></a>
                                    <a id='creditScore-more-creditScore1' href="#creditScore-more-creditScore"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Is there more than one credit score?</Typography></li></a>
                                    <a id='creditScore-change-score1' href="#creditScore-change-score"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Why does my credit score change so often?</Typography></li></a>
                                    <a id='creditScore-free-credit1' href="#creditScore-free-credit"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Isn’t everyone entitled to a free credit score?</Typography></li></a>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Grid lg={6} md={6} sm={6} xs={6} item>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography level="h4">Credit Reports</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <ul>
                                    <a id='creditReport-use-for1' href="#creditReport-use-for"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">What is a credit report, and what is it used for? </Typography></li></a>
                                    <a id='creditReport-info1' href="#creditReport-info"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">How long does information stay on my credit report?</Typography></li></a>
                                    <a id='creditReport-negative-info1' href="#creditReport-negative-info"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">How do I get negative information off my credit report?</Typography></li></a>
                                    <a id='creditReport-correct-info1' href="#creditReport-correct-info"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">How do I correct inaccurate information on my credit report?</Typography></li></a>
                                    <a id='creditReport-apper-info1' href="#creditReport-apper-info"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Will my spouse’s information appear on my credit report?</Typography></li></a>
                                    <a id='creditReport-account-list1' href="#creditReport-account-list"> <li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Why are some of my accounts not listed on my credit report?</Typography></li></a>
                                    <a id='creditReport-view-report1' href="#creditReport-view-report"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Why can’t I view my credit report online?</Typography></li></a>
                                    <a id='creditReport-get-report1' href="#creditReport-get-report"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Can I get a credit report on someone else?</Typography></li></a>
                                    </ul>
                                </Grid>
                            </Grid>
                            <Grid lg={6} md={6} sm={6} xs={6} item>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography level="h4">Credit Monitoring</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <ul>
                                    <a id='creditMonitoring1' href="#creditMonitoring"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">What is credit monitoring?</Typography></li></a>
                                    <a id='creditMonitoring-imp1' href="#creditMonitoring-imp"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Why is credit monitoring important?</Typography></li></a>
                                    <a id='creditMonitoring-alert-type1' href="#creditMonitoring-alert-type"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">What types of alerts can I expect from MyFreeScoreNow.com?</Typography></li></a>
                                    <a id='creditMonitoring-alerts1' href="#creditMonitoring-alerts"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">How will you send alerts?</Typography></li></a>
                                    <a id='creditMonitoring-view-details1' href="#creditMonitoring-view-details"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Once I receive an alert, how do I view the details?</Typography></li></a>
                                    <a id='creditMonitoring-no-change1' href="#creditMonitoring-no-change"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Will I hear from you if there are no significant changes to my credit file?</Typography></li></a>
                                    <a id='creditMonitoring-no-alert1' href="#creditMonitoring-no-alert"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">I'm not getting alerts from MyFreeScoreNow.com.</Typography></li></a>
                                    <a id='creditMonitoring-poor-credit1' href="#creditMonitoring-poor-credit"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Is it important to monitor my credit report if I have poor credit?</Typography></li></a>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Grid lg={6} md={6} sm={6} xs={6} item>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography level="h4">Score Tracking</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <ul>
                                    <a id='scoreTracking-diff1' href="#scoreTracking-diff"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">What is the difference between Credit Monitoring and Credit Score Tracking? </Typography></li></a>
                                    <a id='scoreTracking-notify1' href="#scoreTracking-notify"> <li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Will you notify me of every change to my credit score?</Typography></li></a>
                                    <a id='scoreTracking-track-score1' href="#scoreTracking-track-score"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Why is it important to track my credit score?</Typography></li></a>
                                    </ul>
                                </Grid>
                            </Grid>
                            <Grid lg={6} md={6} sm={6} xs={6} item>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography level="h4">Billing</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <ul>
                                    <a id='billing-billed-company1' href="#billing-billed-company"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Why am I being billed by your company?</Typography></li></a>
                                    <a id='billing-creditCard-number1' href="#billing-creditCard-number"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Why do I need to provide a credit card number for a free trial?</Typography></li></a>
                                    <a id='billing-view-report1' href="#billing-view-report"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">I don’t have a credit card. Can I still view my credit report online?</Typography></li></a>
                                    <a id='billing-bureau-report1' href="#billing-bureau-report"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">Which credit bureau’s report will I receive?</Typography></li></a>
                                    <a id='billing-cancel-membership1' href="#billing-cancel-membership"><li><Typography sx={{ color: "#2A68B3" }} level="body1" fontSize="lg">How do I cancel my credit monitoring membership?</Typography></li></a>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="h2">General Questions</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="question-membership">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>What do I get with a MyFreeScoreNow.com membership?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">As a MyFreeScoreNow.com member you will enjoy the following benefits:</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <ul>
                                <li><Typography level="body1" fontSize="lg">A free credit score and score report</Typography></li>
                                <li><Typography level="body1" fontSize="lg">A personalized report of factors affecting your credit score</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Email alerts of significant changes to your credit report</Typography></li>
                                <li><Typography level="body1" fontSize="lg">An updated credit score each month</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Email notice when someone views your credit report</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Peace of mind knowing your credit report is being monitored every day</Typography></li>
                            </ul>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href="#question-membership1">To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="question-creditScore">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Will viewing my credit report hurt my credit score?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">No, it won’t. When you pull your own credit report, it’s called a "soft inquiry" or "self-inquiry". Self inquiries do not impact your credit at all, no matter how many times you review your own credit report and score, and your self-inquiries don’t even show up on your credit report to lenders.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href="#question-creditScore1">To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="question-creditScore-report">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Is it safe to order my credit score and view my report online?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Yes! Our website is secured by 128 bit SSL encryption technology. Encryption codes the information in a file so that if it is intercepted by a third party, it cannot be read. To review our website’s Privacy policy, click <span style={{ color: "#3097d1" }}>here</span></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href="#question-creditScore-report1">To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="question-information">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>How long will my information be available to me online?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">As a member, you will have access to your credit score and report for 30 days from the date of your first payment. We recommend printing a copy for your records. You may order a new report at anytime for a nominal fee. Your credit score will be updated monthly at no additional charge.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href="#question-information1">To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="question-monitoringMembership">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Who is included in my credit monitoring membership?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Since credit reports are based on individuals, your credit monitoring membership will only include your credit report. MyFreeScoreNow.com is not able to accept and process joint registration for two or more adults. Neither your spouse or any other adult will be enrolled in any service pursuant to your order.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#question-monitoringMembership1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="question-print-creditScore-report">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>How do I print my credit report or credit score analysis?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <Typography level="body1" fontSize="lg">While viewing your credit report or credit score, use your browser’s print option.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#question-print-creditScore-report1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="question-logReport">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>I forgot my Username and/or Password. How do I log in to see my report?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Our one-step security procedure will help you gain access to your membership. From the Member Login page, click the "Forgot Username or Password?" option. You will be prompted to enter your name, the last four digits of your Social Security number and your date of birth. If the information you enter matches our files, you will be given immediate access to your membership. If the information you enter during the security procedure does not match our files, you will be prompted to call our Customer Service Specialists.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#question-logReport1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="question-offer">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>If I was once a member, can I get a second free trial offer?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} >
                            <Typography level="body1" fontSize="lg">If you have ever been a member and received a free trial, we generally do not give you another free trial offer. Returning members who want to reactivate their service will be billed the membership fee immediately upon renewal and/or reactivation.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg" >We hope that you will appreciate the benefits of credit report and credit score monitoring and join the consumers who trust MyFreeScoreNow.com to help them with this important part of credit management. If you have cancelled your membership and wish to reactive it, please call our Customer Service Specialists.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#question-offer1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="h2" >Credit Scores</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditScore-use">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>What is a credit score and who uses them?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="body1" fontSize="lg">A credit score is a number that summarizes your creditworthiness (how likely you are to repay a financial obligation) based on the information in your credit report compared to millions of others.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="body1" fontSize="lg">Credit scores have stood the test of time for being powerful predictors of a consumer’s future credit performance. Lenders routinely use credit scores to make quick yes/no decisions (such as instant credit approvals) or to determine the terms of a loan. Employers, insurers, landlords and other businesses also routinely use credit scores.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="body1" fontSize="lg">The credit scores on MyFreeScoreNow.com, ranging from 300 to 900, are user-friendly credit score models developed by the credit bureau to help you see and understand how lenders view your credit worthiness. It is not used by lenders, but it is indicative of your overall credit risk. Higher scores represent a greater likelihood that you will pay back your debts so you are viewed as being a lower credit risk to lenders. A lower score indicates to lenders that you may be a higher credit risk. The credit scores on MyFreeScoreNow.com are not FICO scores.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="body1" fontSize="lg">Your credit score is based on the information in your credit file at the time it is requested. And since the information in your file can change over time, your credit score may be different from day-to-day.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="body1" fontSize="lg">Lenders and insurers use several different credit scoring models so don’t be surprised if your lender gives you a score that’s different from the credit score you receive online. Just remember that your associated risk level is generally the same even if the number is not. If the lender’s score is lower than your online score, it is possible that this difference can lead to higher interest rates and sometimes credit denial.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href='#creditScore-use1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditScore-calculated">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>How are credit scores calculated?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">The formulas used for credit scoring are complex and tightly held secrets, but certain key factors are considered in most scoring models:</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <ul>
                                <li><Typography level="body1" fontSize="lg">Payment history</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Amount owed</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Available credit</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Length of credit history</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Types of credit uses</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Amount of new credit</Typography></li>
                            </ul>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">How much certain factors affect your score depends greatly on the rest of the information in your credit report. Time is a factor also; the older negative information gets, typically the less weight it carries.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Lenders and insurers use several different credit scoring models so don’t be surprised if your lender gives you a score that’s different from the credit score you receive online.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditScore-calculated1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditScore-more-creditScore">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Is there more than one credit score?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Absolutely! There are many scoring models in use today as well as different scales. Because scoring models in general take the same things into consideration (primarily the information on your credit report), a good score on one scale will usually translate to a good score on another scale.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Lenders and insurers use several different credit scoring models so don’t be surprised if your lender gives you a score that’s different from the credit score you receive online.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditScore-more-creditScore1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditScore-change-score">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Why does my credit score change so often?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Your credit score is based on the information in your credit report. As the information in your credit report changes (sometimes daily), it is natural for your credit score to change. Significant changes in your score usually reflect a trend change in your credit behavior, whether positive or negative, or a change in your credit score may indicate that you've been a victim of identity theft.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditScore-change-score1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditScore-free-credit">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Isn’t everyone entitled to a free credit score?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">No. Free credit scores are not mandated by law. The Fair and Accurate Credit Transactions Act (FACT Act) of 2003 allows consumers to request a credit score for a fee. MyFreeScoreNow.com, however, offers a free credit score just for trying our credit monitoring service.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditScore-free-credit1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="h2" >Credit Reports</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditReport-use-for">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>What is a credit report, and what is it used for?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">A credit report is a record of a consumer’s credit history and current credit standing. Your credit report will indicate how you have handled past credit obligations, how much credit is currently available to you and your current outstanding debt. Lenders review your credit report to help decide if you are a good credit risk. Prospective employers, insurers and landlords may also review your report to make decisions about you. Your credit report is also used to calculate your credit score.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditReport-use-for1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditReport-info">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>How long does information stay on my credit report?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Positive information may stay on your report indefinitely or for ten years after closing a paid account. Negative information generally stays on your report for seven years; however, there are exceptions. Certain bankruptcies (Chapters 7, 11 and 12) remain for ten years. Unpaid tax liens remain for fifteen years from the filing date while paid tax liens remain for seven years from the date paid. Inquiries from credit applications you initiate remain on your report for two years.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditReport-info1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditReport-negative-info">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>How do I get negative information off my credit report?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">If the negative information on your credit is accurate, it just takes time. (See the previous question for more details.) If the information is inaccurate, you have the right to dispute it with the credit bureau that is reporting the information.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditReport-negative-info1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditReport-correct-info">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>How do I correct inaccurate information on my credit report?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="h3" sx={{ color: "#3097d1" }}>MyFreeScoreNow.com is not a credit bureau; we cannot investigate or correct inaccurate information for you. Inaccurate information should be disputed directly with the credit bureau that is reporting the information.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <ul>
                                <li><Typography level="body1" fontSize="lg">Equifax Information Service Center <br />800.203.7843<br />P.O. Box 740241, Atlanta, GA 30374-0241</Typography></li>
                                <li><Typography level="body1" fontSize="lg">TransUnion Corporation<br />1800.888.4213<br />P.O. Box 34012, Fullerton, CA 92834</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Experian Information Solutions, Inc.<br /> 888.397.3742<br /> 714.830.7000<br />P.O. Box 2002, Allen, TX 75013</Typography></li>
                            </ul>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditReport-correct-info1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditReport-apper-info">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Will my spouse’s name or information appear on my credit report?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">No. Credit files are maintained on individuals, not couples. Although joint accounts may (and probably will) appear on your credit report, you and your spouse will each have a separate and unique credit report.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditReport-apper-info1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditReport-account-list">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Why are some of my accounts not listed on my credit report?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Creditors are not obligated to report to the credit bureaus. Some report to only one or two bureaus while some report to none. If you are trying to establish credit and have an account that is not listed on your credit report or if you just want to have an account with a good credit history added, you may ask a credit bureau to add the account. Although they are not obligated to do so, some will add information that can be verified.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditReport-account-list1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditReport-view-report">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Why can’t I view my credit report online?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">For your protection, we will not make your credit report available online unless we are able to verify your identity through a series of security questions. Sometimes the problem is as simple as your credit report having inaccurate or outdated information. If we cannot verify your identity, you will be prompted to call our Customer Service Specialists for assistance.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditReport-view-report1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditReport-get-report">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Can I get a credit report on someone else?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">No. We are not authorized to provide a credit report to anyone except the consumer. We cannot provide a report for an ex-spouse, a friend or for business purposes such as tenant screening. Under federal law, any person who knowingly and willfully obtains information on a consumer from a consumer reporting agency (credit bureau) under false pretenses shall be fined under title 18, United States Code, imprisoned for not more than 2 years, or both.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditReport-get-report1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="h2" >Credit Monitoring</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditMonitoring">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>What is credit monitoring?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Credit monitoring means keeping tabs on your credit report. A credit monitoring service works behind the scenes, alerting you to significant changes as they happen. MyFreeScoreNow.com monitors your credit report daily and alerts you within 24 hours whenever there are significant changes.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#creditMonitoring1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditMonitoring-imp">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Why is credit monitoring important?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Information on your credit report is constantly changing. Credit monitoring is often used to detect errors or unauthorized use of your credit or identity before significant damage is done. It can be a useful tool for maintaining an accurate credit report. Credit monitoring can and should be a key strategy to good credit management.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href='#creditMonitoring-imp1'>To The Top</a>
                        </Grid>
                        <Grid container  item lg={12} md={12} sm={12} xs={12} mt={2} id="creditMonitoring-alert-type">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>What types of alerts can I expect from MyFreeScoreNow.com?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">MyFreeScoreNow.com will send an email alert when any of the following changes occur on your credit report:</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <ul>
                                <li><Typography level="body1" fontSize="lg">New inquiries</Typography></li>
                                <li><Typography level="body1" fontSize="lg">New accounts opened in your name</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Late payments</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Bankruptcies and other public records</Typography></li>
                                <li><Typography level="body1" fontSize="lg">New address</Typography></li>
                                <li><Typography level="body1" fontSize="lg">New employer</Typography></li>
                                <li><Typography level="body1" fontSize="lg">Name change</Typography></li>
                            </ul>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href='#creditMonitoring-alert-type1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditMonitoring-alerts">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>How will you send alerts?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">All credit monitoring alerts are sent by email. You must have an email address and a Java-compatible browser to receive your credit score and report online. For addition information, refer to the Terms and Conditions section on MyFreeScoreNow.com. You are obligated to update the email address on file when your email address changes. In the event MFSN is unable to deliver email messages to you, you agree that the service will be fulfilled at the price agreed upon when you placed your order.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href='#creditMonitoring-alerts1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditMonitoring-view-details">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Once I receive an alert, how do I view the details?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">The email alert will include a link to the member login page. Alternatively, you can log in from our home page at www.MyFreeScoreNow.com to view your alerts.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href='#creditMonitoring-view-details1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditMonitoring-no-change">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Will I hear from you if there are no significant changes to my credit file?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Yes. We will send periodic emails to assure you that there have been no significant changes to your file.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href='#creditMonitoring-no-change1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditMonitoring-no-alert">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>I'm not getting alerts from MyFreeScoreNow.com.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Please be sure MyFreeScoreNow.com is on your "safe sender" list so our emails don’t end up being intercepted by a spam filter. You may also want to verify that your email address is accurate on file by logging in and viewing your account settings.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href='#creditMonitoring-no-alert1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="creditMonitoring-poor-credit">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Is it important to monitor my credit report if I have poor credit?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Yes. Whether you have good or bad credit, mistakes still happen. When they happen to your credit report, it can be costly. Identity theft is also a real threat that can seriously impact your credit if not detected and stopped early.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }}  href='#creditMonitoring-poor-credit1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="h3" >Score Tracking</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="scoreTracking-diff">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>What is the difference between Credit Monitoring and Credit Score Tracking?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Credit monitoring watches for specific changes to your credit report that should be brought to your attention for verification. Things such as an address change, new credit or an inquiry will trigger an email alert. Credit monitoring helps you maintain an accurate credit report which is essential to your overall credit profile and credit score. It can also be effective for early detection of identity theft.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Credit score tracking looks specifically at your credit score and once per month updates your score for you automatically. Credit Score tracking helps you see the impact of your credit habits over time. And charting the history may also put you in a better position to negotiate credit terms - at just the right time you need them.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#scoreTracking-diff1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="scoreTracking-notify">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Will you notify me of every change to my credit score?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">No. We will provide you an updated score monthly and a chart showing the history of your score during you membership. If you cancel your membership and later reactivate, the credit score history will be lost and your score will be charted over again. Slight fluctuations to your credit score are to be expected. When your score is updated monthly, we will send you an email alert.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#scoreTracking-notify1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="scoreTracking-track-score">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Why is it important to track my credit score?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Tracking your credit score helps you gauge how your credit habits are affecting your credit score over time. It also puts you in a better position to leverage your score for better credit terms - something that may not happen unless you initiate it. Your credit score also affects a lot more than your credit. Credit scores are widely used to make decisions that affect your everyday life.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#scoreTracking-track-score1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="h3" >Billing</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="billing-billed-company">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Why am I being billed by your company?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">Be assured that we do not randomly charge accounts. Please call our Customer Service Specialists at 1-888-548-2008 so we can research and explain the charge to you. You may also refer to the Terms and Condition section of MyFreeScoreNow.com for additional information on our billing practices and policies.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">If you are being billing billed for our credit monitoring service, you may cancel the service at anytime, and all future billings will be stopped</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#billing-billed-company1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="billing-creditCard-number">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Why do I need to provide a credit card number for a free trial?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">We need to request a valid credit or debit card from you for a few reasons. First, you will be asked for valid credit card information when you sign up for the free credit monitoring trial. MyFreeScoreNow will verify your credit card information before processing your order. It is one part of the process to verify your identity before delivering your credit score and report to you securely online.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">And secondly, because the credit monitoring service will continue uninterrupted after the free trial period (unless you cancel), we require a valid credit card number before we process your order. Your credit card will not be billed during a free trial period. However, an "authorization" for a nominal dollar amount will be performed on your credit card to make sure it is valid and in good standing, but we are not actually billing your card until the free trial period has passed. However, the nominal dollar authorization may count against your credit or debit limit. If you purchase additional credit reports and/or scores during your trial period, they will be billed to the credit or debit card provided to us during enrollment. In the event that you purchase additional products from the MyFreeScoreNow web site using a different credit or debit card, or if you update your payment information with us, we will charge the latest card provided by you. For more information regarding how your credit card information is used, please refer to the Terms and Conditions.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#billing-creditCard-number1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="billing-view-report">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>I don’t have a credit card. Can I still view my credit report online?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">No. You must have a valid credit or debit card to order through our site.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#billing-view-report1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="billing-bureau-report">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>Which credit bureau’s report will I receive?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">If you order a single bureau report, it will be from one of the three major credit bureaus (Experian, Equifax or TransUnion) determined at our sole discretion.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">However, you have the option to purchase a 3-bureau report with 3 scores. A 3-bureau report contains your credit information from all three of the major credit reporting agencies (Experian, Equifax and TransUnion) in one easy-to-read report.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} mb={2}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#billing-bureau-report1'>To The Top</a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} id="billing-cancel-membership">
                            <Typography level="h3" sx={{ color: "#3097d1" }}>How do I cancel my credit monitoring membership?</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">To cancel your credit monitoring membership, you must call our Customer Service Center toll free at 1-888-548-2008 or by sending a letter via US Postal Service to: MyFreeScoreNow Inc. PO Box 4798 Seattle, WA 98194.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography level="body1" fontSize="lg">In order to protect the security of your account, cancellations are not allowed to be sent via email, fax, or courier. You must call Customer Service during the business hours listed on the<span style={{ color: "#3097d1" }}> Contact Us </span>section of this web site in order to cancel your membership. If you do not cancel before the expiration of the free trial period, your credit or debit card will automatically be charged the then current and applicable monthly fee and again for each month you continue the service. No refunds or credits for partial months. Any Transaction Services that you purchased on the site are non-refundable. Refer to the<span style={{ color: "#3097d1" }}> Terms and Conditions</span> for additional membership information.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} mb={2}>
                            <img alt="#" src={require("../../assets/images/toparrowicon.png")} /><a style={{ color: "#3097d1" }} href='#billing-cancel-membership1'>To The Top</a>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Faq;