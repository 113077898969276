import React, { useEffect, useState, useRef } from 'react';
import {
    FormHelperText, Box, Grid, FormControl, TextField, Button, Card, InputLabel, CardContent,
    Select, OutlinedInput, Divider, Radio, RadioGroup, FormControlLabel, FormLabel, Avatar, Typography, Stack
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link,useNavigate,useLocation} from "react-router-dom";
import LanguageIcon from '@mui/icons-material/Language';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function ThanksToWin(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [autoplay, setAutoplay] = useState(true); 
    const {state} = useLocation();
    const { isAffilate } = state ? state : 0;
    const navigate = useNavigate();
    const redirectFunction = () => {
        if(isAffilate == 1){
            navigate('/login');
        }
        else{
            // navigate('/register');
            window.location.href = 'https://myfreescorenow.com/referenceAffiliateRequest/Q3JlZGl0Q29uMjAyNA==';
        }
    }
    const handlePrev = () => {
        // Logic to move to the previous slide
        sliderRef.slickPrev(); // Assuming sliderRef is a reference to your Slider component
    };
    const handleSliderHover = () => {
        setAutoplay(false); // Pause autoplay when slider is hovered
    };

    const handleSliderLeave = () => {
        setAutoplay(true); // Resume autoplay when mouse leaves the slider
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed: 3000,
        arrows: true,
        prevArrow: <button className="slick-prev" onClick={handlePrev}>Previous</button>,
        nextArrow: <button className="slick-next">Next</button>,
        onMouseEnter: handleSliderHover, // Pause autoplay on hover
        onMouseLeave: handleSliderLeave, // Resume autoplay when mouse leaves
    };
    let sliderRef; // Reference to the Slider component
    return (
        <>
            <Box id="primaryColor" sx={{ flexGrow: 1, boxShadow: 0, justifyContent: "center", alignItems: "center", borderBottomWidthWidth: '3px', borderBottomStyle: 'solid', borderBottomColor: props.customColorArray ? `${props.customColorArray.primaryColor}` : "#30BE2D" }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ padding: "0 2%", margin: "0" }} mb={2}>
                    <Grid container direction="row" justifyContent={isMobile ? "center" : "flex-start"} spacing={4} >
                        <Grid item  >
                            <Link to='/'>
                                <img alt="#" src={require('../../assets/images/mainLogo.jpg')} />
                            </Link>
                        </Grid>
                        <Grid item mt={2} mb={2}>
                            <Stack id="userwayBtn" tabIndex="0" direction="row" className="userwayLogin" >
                                <LanguageIcon />
                                <Typography>en/esp/fr/...</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"center",backgroundColor:"#2D6DB4 ",color:"white"}} p={2}>
                    <Typography sx={{fontSize:"40px",textAlign:"center"}}><b>THE NEW 2024 LINEUP... ALL FREE TO YOU!</b></Typography>
                </Grid>
               
                    <div className='features-slick-slider'>
                    <Slider {...settings} ref={slider => (sliderRef = slider)}>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2} className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>LEAD GEN TOOL - <br/>CREDIT SNAPSHOT 2.0</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/1.png")} className='sliderHeightImg' />
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}  className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>BIGGER COMMISSIONS - EXTRA MONTHLY INCENTIVES</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/2.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}  className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>CO-BRANDED MOBILE APP</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/3.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>AUTOMATIONS! <br/>INSTANT CLIENT SETUP</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/4.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>NEXT GENERATION<br/> CO-BRANDED WEBSITES</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/5.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>ADVANCED CUSTOMER REPORTING PORTAL</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/6.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting' >EPIC PRO REPORT <br/> FOR CREDIT PROS</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/7.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>LIVE SUPPORT ON MESSENGER,WHATSAPP AND INSTAGRAM</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/8.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>YOUR CREDIT REPORTS AND WEBSITES IN 50+ LANGUAGES</Typography>
                                    </Grid> 
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/9.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2} className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>NEW AND IMPOROVED REFERRAL PROGRAM</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/10.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}  className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>ZAPIER INTEGRATION</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/11.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}  className='row-reverse'>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ paddingLeft: "8%" }}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="sliding">
                                        <Typography variant="h2" sx={{ color: "#2d6db4" }} className='slider_fonting centerTexting'>APIs TO BUILD CREDIT MONITORING INTO YOUR PRODUCT OFFERING</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}  sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                    <img src={require("../../assets/creditcon_image/12.png")} className='sliderHeightImg'/>
                                </Grid>
                            </Grid>
                        </div>
                    </Slider>
                    </div>
                    <div mt={3}>
                    <Divider/>
                    </div>
                    
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={5} sx={{justifyContent:"center",textAlign:"center"}}>
                        {isAffilate?isAffilate==1?<Button variant='contained'  sx={{ minWidth: 300,color:"white",backgroundColor:"#ED7014" ,padding:"12px",fontSize:"20px",'&:hover':{backgroundColor:"#ED7014 !important",color:'white'}}} onClick={redirectFunction}><b>Login</b></Button>:<Button variant='contained'  sx={{ minWidth: 300,fontSize:"20px",color:"white",backgroundColor:"#ED7014" ,'&:hover':{backgroundColor:"#ED7014 !important",color:'white',padding:"12px",}}} onClick={redirectFunction}><b>JOIN INDUSTRY'S BEST <br/> AFFILIATE PROGRAM NOW!</b></Button>:''}
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={5} sx={{justifyContent:"center",textAlign:"center"}}>
                        <Typography variant="h6">For more details call our customer support at <a href="tel:8885482008">1-888-548-2008</a>.
                            {isAffilate
                            ? ''
                            :
                            <Typography variant="h6">Login to your portal <a href="https://myfreescorenow.com/login">here</a>.</Typography>
                            }
                        </Typography>
                    </Grid>
                    {/* hide the disclosures */}
                    
                    {/* <Grid container item lg={12} md={12} sm={12} xs={12} mt={3} p={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <Typography className='creditcon-fonting'><b>Disclosures: </b>Registration on this form is required in order to enter the raffle and to become eligible to win the prize from the raffle, however, no purchase of any service or product from MyFreeScoreNow is required to register for the raffle.</Typography>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}><Typography  className='creditcon-fonting'> Prize will be awarded to one person on the winning raffle ticket only. The drawing will be held on Thursday May 2nd at 5:00pm at Booth 15.  Inaccurate contact information on the registration form will disqualify the raffle ticket from eligibility to win.
                        Offer Void where prohibited by law. </Typography></Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}><Typography  className='creditcon-fonting'>To be eligible to win the price you must have attended CreditCon2024.</Typography></Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}><Typography  className='creditcon-fonting'>By entering the raffle contest, participants consent to the collection, use, and disclosure of their personal information to administer the contest and award prizes. Additionally, participants may be contacted directly for other internal promotional offers
                            or marketing purposes related to MyFreeScoreNow. Personal information will not be shared with third parties, except as necessary for the fulfillment of prizes or as required by law.</Typography></Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}><Typography  className='creditcon-fonting'>All participants agree that MyFreeScoreNow and its affiliates shall not be liable for any damages, losses, or injuries arising out of or in connection with the raffle contest, including but not limited to, 
                        participation in the contest, acceptance, possession,use, or misuse of any prize. MyFreeScoreNow reserves the right to modify, suspend, or cancel the contest at any time without prior notice. </Typography></Grid>

                    </Grid> */}
            </Box>
        </>
    );
}

export default ThanksToWin;