import React, { useEffect, useState, useMemo } from 'react';
import { Box, Grid, Tooltip, Divider, Dialog,DialogContent, DialogActions, Button, Table, TableRow, TableCell } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import DefaultLayout from '../DefaultLayout';
import MaterialReactTable from 'material-react-table';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function CustomizationRequest() {
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    let data =
        [
            {
                id: 9466,
                name: 'Rose Credit Repair',
                email: 'roekeichab@gmail.com',
            },
            {
                id: 9467,
                name: 'HLP CREDIT SOLUTIONS CORPORATION',
                email: 'chekelah@hlpcredit.com',
            }
        ]

    let data1 =
        [{
            id: 9466,
            bannerDetails: [
                {
                    name: 'Rose Credit Repair1',
                    idea: 'Rose Credit Repair1',
                    details: 'Rose Credit Repair description1',
                    message1: 'Rose Credit Repair1',
                    message2: '',
                    colorTheme: '#ff0000',
                    referenceImage: '../../assets/images/Mainlogo.png'
                },
                {
                    name: 'Rose Credit Repair2',
                    idea: 'Rose Credit Repair2',
                    details: 'Rose Credit Repair description2',
                    message1: 'Rose Credit Repair2',
                    message2: '',
                    colorTheme: '#ff0000',
                    referenceImage: '../../assets/images/Mainlogo.png'
                }
            ]
        },
        {
            id: 9467,
            bannerDetails: [
                {
                    name: 'HLP CREDIT SOLUTIONS CORPORATION',
                    idea: 'HLP CREDIT SOLUTIONS CORPORATION',
                    details: 'HLP CREDIT SOLUTIONS CORPORATION description ',
                    message1: 'HLP CREDIT SOLUTIONS CORPORATION',
                    message2: '',
                    colorTheme: '#ffff00',
                    referenceImage: '../../assets/images/Mainlogo.png'
                }
            ]

        }]

    const handleRowClick = (id) => {
        const selected = data1.find(item => item.id === id);
        console.log("11111" + JSON.stringify(selected))
        setSelectedData(selected);
        setOpenPopup(true);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                accessorFn: (row) => `${row.id ? row.id : ''}`,
                header: 'ID',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.id ? row.original.id : 'N/A'}

                    </>
                ),
            },
            {
                accessorKey: 'name',
                accessorFn: (row) => `${row.name ? row.name : ''}`,
                header: 'Company Name',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <>
                        {row.original.name ? row.original.name : 'N/A'}

                    </>
                ),
            },
            {
                accessorKey: 'email',
                accessorFn: (row) => `${row.email ? row.email : ''}`,
                header: 'Email',
                size: 50,
            },
            {
                accessorFn: (row) =>
                    <Tooltip title="View Details" arrow>
                        <img src={require('../../assets/images/details.png')} onClick={() => handleRowClick(row.id)} />
                    </Tooltip>,
                accessorKey: 'Actions',
                id: 'actions',
                header: 'Actions',

            },
        ]
    )

    const ContentLayout =
        <>
            <Typography level="h4" color="primary" pb={2}>Cobrand Customization Request</Typography>

            <Grid mt={3}>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableColumnFilterModes
                    filterFns={{
                        customSearchFilterFn: (row, id, filterValue) =>
                            row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                    }}
                    globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                    // enableColumnFilters={config.DatatableColumnFilter}
                    initialState={{ showColumnFilters: false, density: 'compact' }}
                    positionToolbarAlertBanner="bottom"
                    enableDensityToggle={false}
                    // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                    // state={{ columnVisibility, rowSelection, isLoading: isLoading }}
                    // onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                    muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                            backgroundColor: "#F6FAFF",
                        },
                    }}
                />
            </Grid>


            <Dialog
                open={openPopup}
                // maxWidth={'lg'}
                // onClose={(_, reason) => {
                //   if (reason !== "backdropClick") {
                //     handleCloseAssigned();
                //   }
                // }}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
               
                <DialogContent className="requestSlider">
                <div className="slider-container"> 
                <Slider {...settings} >
                    {selectedData && selectedData.bannerDetails && selectedData.bannerDetails.map((item, index) => {
                        return (
                            <>
                                <Card sx={{border: '1px solid #e7e2e2'}}>
                                    <CardOverflow sx={{ bgcolor: '#F6FAFF', borderColor: '#F6FAFF', padding: '1% !imoportant', }}>
                                        <Typography gutterBottom color="primary" variant='h4'><b>Details</b></Typography>
                                    </CardOverflow>
                                    <CardContent>
                                        <Table>
                                            <TableRow>
                                                <TableCell><Typography>Idea :</Typography></TableCell>
                                                <TableCell className="cobrandText">
                                                    <Typography >{item.idea}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell><Typography>Details :</Typography></TableCell>
                                                <TableCell className="cobrandText">
                                                    <Typography >{item.details}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell><Typography>Message1 :</Typography></TableCell>
                                                <TableCell className="cobrandText">
                                                    <Typography >{item.message1}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell><Typography>Message2 :</Typography></TableCell>
                                                <TableCell className="cobrandText">
                                                    <Typography >{item.message2}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell><Typography>Color Theme :</Typography></TableCell>
                                                <TableCell className="cobrandText" sx={{ display: 'flex' }}>
                                                    <Box sx={{ background: `${item.colorTheme}`, width: '16%', height: '5vh', borderRadius: '5px' }} />
                                                    <Typography sx={{ paddingLeft: '2px' }}>{item.colorTheme}</Typography>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell><Typography>Reference Image :</Typography></TableCell>
                                                <TableCell className="cobrandText">
                                                    <img src={require('../../assets/images/Mainlogo.png')} />
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                        {/* <DialogActions sx={{ margin: 'auto' }}> */}
                                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} mb={2} sx={{ textAlign: "center", width: "100%" }} columnSpacing={3}>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Button variant="contained" onClick={() => setOpenPopup(false)} sx={{ minWidth: 200 }}>
                                                    close
                                                </Button>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Button variant="contained" onClick={() => setOpenPopup(false)} color="success" sx={{ minWidth: 200 }}>
                                                    Upload
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {/* </DialogActions> */}
                                    </CardContent>
                                </Card>

                            </>
                        );
                    })}
                </Slider>
                </div>
                </DialogContent>
            </Dialog>
        </>

    return (
        <div>
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}
export default CustomizationRequest;