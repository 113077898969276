import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Grid, Card, CardContent } from "@mui/material";
import { CardOverflow } from '@mui/joy';
import { Typography } from '@mui/joy';
const CallStatusDistribution = ({ callLogs }) => {
  const statuses = callLogs.map(log => log.callStatus);
  const uniqueStatuses = [...new Set(statuses)];
  const circulateGraphPercentage = 75;
  const statusCounts = uniqueStatuses.map(status => ({
    status,
    count: statuses.filter(s => s === status).length,
  }));

  const totalCalls = statuses.length;

  return (
    <div>
      <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography level="h4" color="primary">Call Status Distribution</Typography>
        </Grid>
        <Card>
            <CardContent>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12} >
                        <Card variant='outlined'>
                            <CardOverflow
                                sx={{
                                    backgroundColor: "#F6FAFF", padding: '1%',
                                }}>
                                <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>Completed</Typography>
                            </CardOverflow>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    {/* <Stack direction={'row'} justifyContent={'space-between'}> */}
                                    <Grid item lg={4} md={4} sm={4} xs={4} sx={{ alignSelf: 'center' }}>
                                        
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={8} sx={{ alignSelf: 'center' }}>
                                    
                                        <div style={{ width: 120, height: 120 }}>
                                            <CircularProgressbar
                                                value={(statusCounts.find(item => item.status === 'Completed')?.count / totalCalls) * 100 || 0}
                                                text={`${statusCounts.find(item => item.status === 'Completed')?.count || 0}`}
                                                styles={buildStyles({
                                                    rotation: 0.25,
                                                    strokeLinecap: 'butt',
                                                    textSize: '20px',
                                                    strokeWidth: "9",
                                                    pathTransitionDuration: 0.5,

                                                    // Colors
                                                    pathColor: `rgba(118, 192, 68, ${circulateGraphPercentage / 100})`,
                                                    textColor: '#76C044',
                                                    trailColor: '#d6d6d6',
                                                    backgroundColor: '#3e98c7',

                                                })}
                                            />
                                        </div>
                                    </Grid>
                                    {/* </Stack> */}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} >
                        <Card variant='outlined'>
                            <CardOverflow
                                sx={{
                                    backgroundColor: "#F6FAFF", padding: '1%',
                                }}>
                                <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>Failed</Typography>
                            </CardOverflow>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    {/* <Stack direction={'row'} justifyContent={'space-between'}> */}
                                    <Grid item lg={4} md={4} sm={4} xs={4} sx={{ alignSelf: 'center' }}>
                                        
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={8} sx={{ alignSelf: 'center' }}>
                                
                                        <div style={{ width: 120, height: 120 }}>
                                            <CircularProgressbar
                                                value={(statusCounts.find(item => item.status === 'Failed')?.count / totalCalls) * 100 || 0}
                                                text={`${statusCounts.find(item => item.status === 'Failed')?.count || 0}`}
                                                styles={buildStyles({
                                                    rotation: 0.25,
                                                    strokeLinecap: 'butt',
                                                    textSize: '20px',
                                                    strokeWidth: "9",
                                                    pathTransitionDuration: 0.5,

                                                    // Colors
                                                    pathColor: `rgba(118, 192, 68, ${circulateGraphPercentage / 100})`,
                                                    textColor: '#76C044',
                                                    trailColor: '#d6d6d6',
                                                    backgroundColor: '#3e98c7',

                                                })}
                                            />
                                        </div>
                                    </Grid>
                                    {/* </Stack> */}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} >
                        <Card variant='outlined'>
                            <CardOverflow
                                sx={{
                                    backgroundColor: "#F6FAFF", padding: '1%',
                                }}>
                                <Typography level="h4" sx={{ textAlign: "center", fontWeight: "500" }}>No Answer</Typography>
                            </CardOverflow>
                            <CardContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={4} md={4} sm={4} xs={4} sx={{ alignSelf: 'center' }}>
                                        
                                    </Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={8} sx={{ alignSelf: 'center' }}>
                                        <div style={{ width: 120, height: 120 }}>
                                            <CircularProgressbar
                                                value={(statusCounts.find(item => item.status === 'No Answer')?.count / totalCalls) * 100 || 0}
                                                text={`${statusCounts.find(item => item.status === 'No Answer')?.count || 0}`}
                                                styles={buildStyles({
                                                    rotation: 0.25,
                                                    strokeLinecap: 'butt',
                                                    textSize: '20px',
                                                    strokeWidth: "9",
                                                    pathTransitionDuration: 0.5,

                                                    pathColor: `rgba(118, 192, 68, ${circulateGraphPercentage / 100})`,
                                                    textColor: '#76C044',
                                                    trailColor: '#d6d6d6',
                                                    backgroundColor: '#3e98c7',

                                                })}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                </Grid>
            </CardContent>
        </Card>
    </div>
  );
};

export default CallStatusDistribution;
