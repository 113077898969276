import React,{useMemo,useState,useEffect} from 'react';
import {Box,Table ,TableBody,Tooltip,IconButton ,TableCell ,Typography,Button,MenuItem,FormControl,Select,Grid,TableContainer,TableHead,TableRow,Paper  } from '@mui/material';
import AuthUser from '../Auth/AuthUser';

import {currencyFormat} from '../../Components/CommonFormula';

let companyname;
function  SummaryExpandRow(props) {

    const {http} = AuthUser();
    const [row, setRow]=useState(props.row);

//useeffect start

useEffect(()=>{
   
},[]) 

//useeffect end

return (
<>

    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{fontWeight:"bold"}}>AID</TableCell>
                    <TableCell align="center" sx={{fontWeight:"bold"}}>Total Monthly Billing</TableCell>
                    <TableCell align="center" sx={{fontWeight:"bold"}}>Monthly Commission</TableCell>
                    <TableCell align="center" sx={{fontWeight:"bold"}}>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                      {row.original.companyAids.map((companyAids) => (
                                <TableRow
                                key={companyAids.aid}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {companyAids.aid}
                                </TableCell>
                                <TableCell align="center">{currencyFormat(parseFloat(companyAids.totalbilling))}</TableCell>
                                <TableCell align="center">{currencyFormat(parseFloat(companyAids.totalcommission))}</TableCell>
                                <TableCell align="center">{companyAids.reportStatus? companyAids.reportStatus:'N/A'}</TableCell>
                                </TableRow>
                      ))}
            </TableBody>
          </Table>
          </TableContainer> 

               

</>
    );
}


export default SummaryExpandRow;