import React, { useState } from "react";
import { Grid, Button } from '@mui/material';
import { Typography, Table } from '@mui/joy';
function SnapShotLogout(props) {
    return (
        <>
            <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ justifyContent: "center" }} mt={4}>
                <Typography level="h2" sx={{ color: "#76C044", fontWeight: 700 }}>Thank you for visiting! You have been logged out successfully.</Typography>
            </Grid>
            <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ justifyContent: "center" }} mt={4}>
                <Typography level="h3" sx={{ color: "#0A2A3F", fontWeight: 900 }}>Come back to order your <i>NEW, UPDATED </i> <span><img src={require("../../../assets/images/equifax.png")} /></span> Credit Report ad Score <i>ANY TIME!</i></Typography>
            </Grid>
            <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ justifyContent: "center" }} mt={4}>
                <Typography level="h4">Log in using this link: <a href="" style={{ color: "#2A68B3" }}>http://clone.myfreescorenow.com/en/creditsnapshot/user/login</a></Typography>
            </Grid>
            <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ justifyContent: "center" }} mt={4}>
                <Table borderAxis="both" >
                    <tr>
                        <td style={{ width: '20%', textAlign: "right" }}>
                            <Typography level="h4">Username</Typography>
                        </td>
                        <td style={{ width: '20%' }}>
                            <Typography level="h6">sonali3718@yopmail.com</Typography>
                        </td>
                    </tr>
                </Table>

            </Grid>
            <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ justifyContent: "center" }} mt={4}>
                <Typography level="h4">Your user name and password were emailed in your welcome letter</Typography>
            </Grid>
            <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ justifyContent: "center" }} mt={4}>
                <Typography level="h1" sx={{ fontWeight: 700 }}>Remember,<span style={{ color: "#2A68B3" }}>SCORES CAN VERY BY BUREAU. CHECK YOUR SCORES FROM</span> </Typography>
                <Typography level="h1"><span><a href="" style={{ justifyContent: "center", color: "#2A68B3", fontWeight: 700 }}>ALL 3 BUREAUS NOW!</a></span></Typography>
            </Grid>
            <Grid container item lg={12} md={12} xs={12} sm={12} sx={{ justifyContent: "center" }} mt={4}>
                <Button variant="contained" size="large" color="warning" sx={{ backgroundColor: "#FF9400", borderColor: "#FF9400", minWidth: 300, minHeight: 60 }}>Order My 3 BUREAU Report Now</Button>
            </Grid>
        </>
    )
}
export default SnapShotLogout;