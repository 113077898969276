import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent,CardOverflow } from '@mui/joy';
import {
    Grid, Button, TextField, Dialog, DialogContent, DialogActions,
    Alert, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip,
    FormControl, FormControlLabel, RadioGroup, Radio
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import swal from "sweetalert";
import CheckIcon from '@mui/icons-material/Check';
import Moment from 'moment';
import AuthUser from '../../Components/Auth/AuthUser';
import Loader from '../../Components/Loader/Loader';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import config from '../../AppConfig';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: theme.palette.common.black,
        fontWeight: 'bold',
        fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function ApiDetails(props) {
    const { company, user } = AuthUser();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [data, setData] = useState([]);
    const [secretKeydata, setSecretKeyData] = useState([]);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [generated, setGenerated] = useState();
    const [isKeyGenerated, setIsKeyGenerated] = useState(false);
    const [selectedOption, setSelectedOption] = useState("immediate");
    const [alertVisible, setAlertVisible] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [isBtnDisable, setIsBtnDisable] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const isMobile = useMediaQuery('(max-width:600px)');

    let expired = false 

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickClose = () => {
        setOpen(true);
    };

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };
    const handleClickClose2 = () => {
        setOpen2(false);
    };

    //    post api start here
    const generatePrivateKey = async () => {
        setIsLoading(true);
        const apiUrl = '/affiliate/createPrivateKey';
        const args = {
            expired: expired
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap(); // Assuming no data payload is needed
        if (res) {
            if (res.success == true) {
                setIsLoading(false)
                setIsKeyGenerated(true);
                setSecretKeyData(res);
                handleClickOpen();
            }
            else {
                setIsLoading(false)
                setIsKeyGenerated(false);
                swal({
                    title: "Failed",
                    text: res.message || 'Failed to generate private key',
                    icon: "error",
                    button: "Ok",
                })
            }

        } else {
            setIsLoading(false)
            swal({
                title: "Failed",
                text: res?.message || 'Failed to generate private key',
                icon: "error",
                button: "Ok",
            })
        }
        fetchPrivateKey();
        expired = false
    };
    useEffect(() => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id === 3) {
            fetchPrivateKey();
        } else {
            navigate('/login');
        }

    }, [])

    //post api end here
    //fetch api start here
    const fetchPrivateKey = async () => {

        setIsLoading(true)
        const apiUrl = '/affiliate/privateKey';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.data != null) {
                setData(res.data);
                setIsLoading(false)
            }
            setGenerated(res.generated);
        }
    }
    //fetch api end here
    const handleRegeneratePrivateKey = () => {
        // expired = false
        setGenerated(true);
        generatePrivateKey();

    };

    // Function to handle radio button change
    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // Function to handle dialog confirm action
    const handleConfirmDeactivate = () => {
        setIsBtnDisable(true);
        setOpen2(false);
        if (selectedOption === "immediate") {
            handleRegeneratePrivateKey();
        } else {
            expired = true
            setGenerated(true);
            generatePrivateKey();
        }
    };
    // Email send function start here
    const handleEmail = async () => {
        const apiUrl = '/affiliate/emailPrivateKey';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res && res.success == true) {
            setEmailSuccess(true);
            setButtonDisabled(true);
        }
    }
    // Email send function end here

    const handleDeleteKey = async (id) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id != 3) {
			window.location.href = config.BASE_URL + 'login';
			return false;
		}
		setDeleteId(id)
		setDeleteOpen(true);
	}

    const closeDeleteKey = () => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id != 3) {
			navigate('/login');
			return false;
		}
		setDeleteOpen(false)
	}
    
    const handleOk = () => {
        setOpen1(false); 
        setOpen(false); 
	}

    const deleteKey = async() => {
        closeDeleteKey();
        setIsLoading(true);
        const apiUrl = '/delete-client-key';
        const args = {
          id: deleteId,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
          if (res.success === true) {
            swal({
              title: "Success",
              text: res.message,
              icon: "success",
              button: "Ok",
            })
            setIsLoading(false);
            fetchPrivateKey();
         
          } else {
            swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
            if(res.message == "This action is unauthorized.") {
              navigate('/login');
            }else{
                fetchPrivateKey();
                setIsLoading(false);
            }
          }
        }
        setDeleteId('');
      };
    //    csv start here
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Company Client Key and Secret',
        headers: ['Client Key', 'Client Secret'],
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = () => {
        const exportedData = [
            {
                'Client Key': secretKeydata.seceretId,
                'Client Secret': secretKeydata.seceretKey,
            }
        ];
        const csvContent = convertToCSV(exportedData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvOptions.fileName + '.csv';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        link.remove();
        setAlertVisible(true);
    };

    const convertToCSV = (data) => {
        const headers = csvOptions.headers.join(',') + '\n';
        const rows = data.map((row) => Object.values(row).join(',')).join('\n');
        return headers + rows;
    };

    // csv end here
    return (
        <>
            {isLoading ? <Loader /> : <></>}
            <Card variant="outlined" size="lg">
                <CardOverflow 
                sx={{
                        bgcolor: '#F6FAFF',
                        borderColor: '#F6FAFF',
                        padding: '1%',
                    }}
                >
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display:"flex",justifyContent: "right" }} mt={1}>
                        <Button variant="contained" color="success" sx={{ minWidth: 250,textAlign:"right" }}
                            onClick={() => {
                                window.open("https://documenter.getpostman.com/view/14348250/2s9Xxzts4Q", "_blank");
                            }}>
                            API Documentation
                        </Button>
                    </Grid>
                </CardOverflow>
                <CardContent>
                    {generated == false && data.length == 0 ?
                        (
                            <>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} >
                                    <Typography sx={{ textAlign: "center" }}><b>MyFreeScoreNow APIs help you to automate your platform to fetch relevant information and data about your clients. </b></Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} sx={{ justifyContent: "center" }}>
                                    <Typography sx={{ textAlign: "center" }}> <b>Using MyFreeScoreNow APIs requires a unique client key to establish your identity. This key can be generated here. Note that the key is displayed only once for security purposes and you must download and store the same at a safe location.
                                    </b> </Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} sx={{ justifyContent: "center" }}>
                                    <Typography sx={{ textAlign: "center" }}> <b>The API documentation provides details about the MyFreeScoreNow APIs.
                                    </b> </Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2} sx={{ justifyContent: "center" }}>
                                    <Button variant="contained" color="success" sx={{ minWidth: 250, textAlign: "center" }}
                                        onClick={generatePrivateKey}

                                    >
                                        Generate Client Key
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            ''
                        )}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell >Client Key</StyledTableCell>
                                    <StyledTableCell >Created At</StyledTableCell>
                                    <StyledTableCell>Expiry Date</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell >Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.length > 0 && data.map((row) => (
                                    <TableRow key={row.id}>
                                        <StyledTableCell>{row.key_id}</StyledTableCell>
                                        <StyledTableCell>{Moment(row.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm') + " Hrs" || "N/A"}</StyledTableCell>
                                        <StyledTableCell>{(row.expires_on) ? Moment(row.expires_on).format('MM-DD-YYYY hh:mm') + " Hrs" : "Never"}</StyledTableCell>
                                        <StyledTableCell>{row.status == 1 ? "Active" : "Deactivated"}</StyledTableCell>
                                        <StyledTableCell>
                                            {
                                                <Tooltip title="Delete Client Key" arrow>
                                                <DeleteIcon color={'error'} sx={{marginRight:'15px'}}
                                                  onClick={() => { handleDeleteKey(`${row.id}`) }}
                                              /></Tooltip>
                                            }
                                            {(generated === true) ?
                                                (
                                                    <Button variant="contained" color="success"  sx={{ minWidth: isMobile ? 100 : 250 }}
                                                        onClick={() => { handleClickOpen2(); }}
                                                    >
                                                        Regenerate Client Key</Button>
                                                )
                                                :
                                                (
                                                    <Tooltip title="You can only generate one client key within a 24 hour period" followCursor>
                                                        <span>
                                                            <Button variant="contained" color="success" sx={{ minWidth: isMobile ? 100 : 250 }}
                                                                disabled
                                                            >
                                                                Regenerate Client Key
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                )

                                                
                                            }
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table >
                    </TableContainer>
                    {(generated === false) ?
                        <Grid container item lg={12} md={12} xs={12} sm={12} mt={2} sx={{ justifyContent: "center" }}>
                            <Typography sx={{ textAlign: "center" }}><b>* You can only generate one client key within a 24 hour period.</b></Typography>
                        </Grid>
                        : ''
                    }
                </CardContent>
            </Card>
            {/* first popup start here */}
            <React.Fragment>
                <BootstrapDialog
                    onClick={handleClickClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth={fullWidth}>
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h4">Client Key {company ? 'for ' + company.company_name : "N/A"}</Typography>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            {isKeyGenerated == true ?
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" onClose={() => setIsKeyGenerated(false)}>
                                        Client Key & Client Secret  has been generated successfully.
                                    </Alert>
                                </Stack>
                                :
                                ''
                            }
                        </Grid>
                        <Grid>
                            {alertVisible == true ?
                                <Stack sx={{ width: '100%' }} spacing={2} mt={1}>
                                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" onClose={() => setAlertVisible(false)}>
                                        Downloaded successfully.
                                    </Alert>
                                </Stack>
                                :
                                ''
                            }
                        </Grid>
                        <Grid>
                            {emailSuccess && (
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                                        Email sent successfully.
                                    </Alert>
                                </Stack>
                            )}
                        </Grid>
                        <Grid item container xs={12} mt={2}>
                            <TextField
                                variant="outlined"
                                name='keyId'
                                fullWidth
                                label="Client Key"
                                margin="dense"
                                size="large"
                                value={secretKeydata.seceretId}
                                disabled
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: {
                                            userSelect: 'none', // Disable text selection
                                        },
                                        onCopy: (event) => {
                                            event.preventDefault(); // Prevent copying
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid container item md={12} lg={12} xs={12} sm={12}>
                            <TextField
                                variant="outlined"
                                name='keySecret'
                                fullWidth
                                label="Client Secret"
                                margin="dense"
                                size="large"
                                value={secretKeydata.seceretKey}
                                disabled
                                InputProps={{
                                    readOnly: true,
                                    inputProps: {
                                        style: {
                                            userSelect: 'none', // Disable text selection
                                        },
                                        onCopy: (event) => {
                                            event.preventDefault(); // Prevent copying
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid container item md={12} lg={12} xs={12} sm={12} mb={2}>
                            <Typography><b>* This Client Secret can only be downloaded once to keep your account secure.</b> </Typography>
                        </Grid>
                        <Grid conatiner item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ textAlign: "center" }}>
                            <a
                                onClick={
                                    handleExportRows
                                } style={{ color: "rgb(48, 151, 209)", cursor: "pointer", fontSize: "1.2rem" }}>
                                Download Client Key Details
                            </a>
                            <Typography><b>OR</b></Typography>
                            {/* <Button disabled={buttonDisabled}> */}
                            {emailSuccess ? (
                                <a style={{ color: "grey", cursor: "pointer", fontSize: "1.2rem" }}>Email Sent</a>
                            ) :
                                (
                                    <a
                                        onClick={handleEmail}
                                        style={{ color: "rgb(48, 151, 209)", cursor: "pointer", fontSize: "1.2rem" }}>

                                        Email</a>)

                            }
                            {/* </Button> */}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ width: "100%", justifyContent: "center" }}>
                            <Button variant="contained" onClick={() => handleClickOpen1()} sx={{ minWidth: 100 }}>
                                OK
                            </Button>
                        </Grid>
                    </DialogActions>
                </BootstrapDialog>
            </React.Fragment>
            {/* first popup end here */}

            {/* second popup start here */}
            <Dialog
                open={open1}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setOpen1(false);
                    }
                }}
                disableEscapeKeyDown={true}
                aria-labelledby="responsive-dialog-title"
                fullWidth={fullWidth}
                maxWidth={'sm'}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Typography level="h4">Alert</Typography>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography> This Client Secret can only be downloaded once to keep your account secure.
                            This is the last time we still show you the Client Secret.</Typography>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained"
                                onClick={() => setOpen1(false)}
                                sx={{ minWidth: 250 }}>
                                CANCEL
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" color="success" sx={{ minWidth: 250 }}
                                onClick={handleOk}
                            >
                                OK
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {/* second popup end here */}

            {/* third popup start here */}
            <Dialog
                open={open2}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setOpen2(false);
                    }
                }}
                disableEscapeKeyDown={true}
                aria-labelledby="responsive-dialog-title"
                fullWidth={fullWidth}
                maxWidth={'sm'} >
                <DialogTitle id="responsive-dialog-title">
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <Typography level="h4">Confirm and deactivate current key</Typography>
                    </Grid>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose2}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }} >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="h6"><b>This is required to get a new key.</b></Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={selectedOption}
                                onChange={handleRadioChange}
                                name="radio-buttons-group">
                                <FormControlLabel value="immediate" control={<Radio />} label="Deactivate old key immediately" />
                                <FormControlLabel value="24hours" control={<Radio />} label="Deactivate old key in 24 hours" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained"
                                onClick={() => setOpen2(false)}
                                sx={{ minWidth: 250 }}>
                                CANCEL
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" color="success" sx={{ minWidth: 250 }} onClick={handleConfirmDeactivate} disabled={isBtnDisable}>
                                Confirm and Deactivate
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {/* third popup end here */}

            <Dialog
                open={deleteOpen}
                onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    setDeleteOpen(false)
                }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
			>
                <DialogTitle id="alert-dialog-title">Delete Client Key</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this client key?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closeDeleteKey} >
                    Cancel
                </Button>
                <Button autoFocus style={{ backgroundColor: 'red', color: 'white', minWidth: 100 }} onClick={deleteKey}>
                    Delete
                </Button>
                </DialogActions>
			    </Dialog>

        </>

    );

}


export default ApiDetails;