import React, { useMemo, useState, useEffect } from 'react';
//MRT Imports
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
import swal from "sweetalert";
import AuthUser from '../../../Components/Auth/AuthUser';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
//Material-UI Imports
import {
    Box,
    Tooltip,
    Button
} from '@mui/material';
//Mock Data
import { Link } from 'react-router-dom';
import Freez from '../../Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
function Upgraded(props) {
    const dispatch = useDispatch();
    const {user } = AuthUser();
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const tablePreferencesArray = { 

    }
  //save preference function start here
  useEffect(() => {
}, [rowSelection]);
 ////////////////////////
 useEffect(() => {
  if(Object.values(columnVisibility).length>0){
    setColumnVisibility(columnVisibility);
  }
  //do something when the row selection changes...
}, [rowSelection]);

useEffect(() => {
  tablePreferences();
}, [rowSelection]);
////////////////////////

useEffect(()=>{  
    saveTablePreferences();
},[columnVisibility])

const tablePreferences = async() => {
    const apiUrl = '/fetchTablePreferences/myleadupgraded';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
          if (Object.values(res).length == 0) {
            setColumnVisibility(tablePreferencesArray);
          }else {
            setColumnVisibility((JSON.parse(res.preferences)));
          }
    }
}

const saveTablePreferences = async() => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
          columnArray: columnVisibility,
          userId: user.id,
          tableId: 'myleadupgraded'
      }
      const res = await dispatch(postData({apiUrl,args})).unwrap();
    } 
}

//save preference function end here
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
    }, [rowSelection]);

    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end


    //Member login function by affiliate start

    const MemberLogin = async(row) => {
        handleFreezStart();
        //Call API for login
        const apiUrl = '/creditSnapshotEnrollLogin';
        const args = {
            email: row.original.email,
            password: decodeBase64(row.original.password),
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            handleFreezStop();
            if (res.success === true) {
                if (res.step === "inCreditSuccess") {
                    localStorage.setItem("ScoreData", JSON.stringify(res.data));

                    // Get the base URL
                    const baseUrl = window.location.origin;

                    // Append "/score" to the base URL
                    const updatedUrl = config.BASE_URL + `score`;

                    // Navigate to the updated URL
                    window.open(updatedUrl, '_blank');
                }

            }else {
                swal({
                    title: "Failed",
                    text: "Something Went wrong please contact to admin",
                    icon: "error",
                    button: "Ok",
                })

            }
        }

    }

    //Member login function by affiliate ends



    // Function to decode base64 string starts
    const decodeBase64 = (str) => {
        try {
            var passValue = str =  atob(str);
            var masked = str.substring(0, str.length - 4);
            masked = masked.replace(/./g, '*'); //The character is ASCII-7 (Press Alt+7 to type)
            var text = passValue.substring(passValue.length - 4);
            var newPass = masked + text;
            return newPass;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };
    const getPasswordString = (str) => {
        try {
            var passValue = str =  atob(str);
            return passValue;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };

    const togglePasswordVisibility = (rowId) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [rowId]: !prevState[rowId],
        }));
    };
    // Function to decode base64 string ends
    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.first_name} ${row.last_name}`, //accessorFn used to join multiple data into a single cell
                id: 'name', //id is still required when using accessorFn instead of accessorKey
                header: 'Name',
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        <span>{renderedCellValue}</span>
                    </Box>
                ),
            },
            {
                accessorFn: (row) => `${row.source? row.source:''}`,
                accessorKey: 'source', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Source',
                Cell: ({ renderedCellValue, row }) => (row.original.source ? row.original.source : 'N/A'),
            },
            {
                accessorFn: (row) => `${row.email? row.email.trim():''}`,
                accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Email',
                Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
            },
            {
                accessorFn: (row) => `${row.password}`, //accessorFn used to join multiple data into a single cell
                id: 'password', //id is still required when using accessorFn instead of accessorKey
                header: 'Password',
                enableGlobalFilter: false,
               
                Cell: ({ renderedCellValue, row }) => (
                    <Box className="datatable-column">
                    {passwordVisibility[row.id] ? getPasswordString(row.original.password) : decodeBase64(row.original.password) }
                        {

                            `${decodeBase64(row.original.password).length}` > 0 
                            ?
                                <>                                
                                {passwordVisibility[row.id] ?
                                <VisibilityIcon style={{marginRight: "10%"}} onClick={() => togglePasswordVisibility(row.id) }/> 
                                :
                                <VisibilityOffIcon style={{marginRight: "10%"}} onClick={() => togglePasswordVisibility(row.id) }/> }
                                </>
                            : 
                            ''
                        }
                </Box>
                ),
            },
          
            {
                accessorFn: (row) => `${formatPhoneNumber(row.mobile)}`, 
                accessorKey: 'mobile', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Phone No.',
            },
            {
                accessorFn: (row) => `${row.get1b}`, //accessorFn used to join multiple data into a single cell
                id: 'get1b', //id is still required when using accessorFn instead of accessorKey
                header: 'Full Report Purchased',
                size: 300,
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        <span>{renderedCellValue == 1 ? "Yes" : "No"}</span>
                    </Box>
                ),
            },
            {
                accessorFn: (row) => `${row.created_at?row.created_at:''}`, //accessorFn used to join multiple data into a single cell
                id: 'created_at', //id is still required when using accessorFn instead of accessorKey
                header: 'Enrolled Date',
                Cell:({renderedCellValue,row}) => (
                    <div>
                    {row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY'):"N/A"}
                    </div>          
                  ),
            },
            {

                header: 'Action',
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                       
                        <Tooltip title="Login" arrow><Link target="_blank"  to={"/score/"+row.original.user_id} style={{border:"none",background:"none",borderRadius:"0%"}}><img src={require('../../../assets/icons/login.png')} /></Link></Tooltip>
                                          
                              
                             </Box>
                ),
            },
        ],
       
    );
       //csv option start here
       const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Credit Snapshot Leads(Upgraded)',
        headers: ['Name','Source','Email','Phone No.','Full Report Purchased','Enrolled Date'],
      };
    
      const csvExporter = new ExportToCsv(csvOptions);
      const handleExportData = (props) => {
        const exportedData = props.map((row) => {
          
          return {
            'Name': `${row.original.first_name} ${row.original.last_name}`,
            'Source': row.original.source || '',
            'Email': row.original.email || '',
            'Phone No.': row.original.mobile || '',
            'Full Report Purchased': row.original.get1b === 1 ? 'Yes' : 'No',
            'Enrolled Date': Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY') || '',
           
          };
        });
        // csvExporter.generateCsv(exportedData);
          // Convert data to CSV format
      const csvContent = convertToCSV(exportedData);
    
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
    
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Programmatically click the link to trigger the file download
      link.click();
    
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
    };
    
    const convertToCSV = (data) => {
      const headers = csvOptions.headers.join(',') + '\n';
    
      const rows = data.map((row) => {
        return Object.values(row).map((value) => {
          // Handle any necessary formatting or escaping of values
          // For simplicity, we assume all values are already properly formatted
          return value;
        }).join(',');
      }).join('\n');
    
      return headers + rows;
      };
      //csv end here
    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={props.props}
                enableColumnFilterModes
                filterFns={{
                    customSearchFilterFn: (row, id, filterValue) =>
                    row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                   }} 
                globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                enableColumnFilters={config.DatatableColumnFilter}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                }}
                onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                      <Button
                        color="primary"
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportData(table.getPrePaginationRowModel().rows)
                        }
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        CSV
                      </Button>
                    </Box>
                  )}
            />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    );
}

export default Upgraded;