import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box,  Select, Grid, Button, TextField,  InputLabel, MenuItem, FormControl, OutlinedInput,
} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import { useTheme } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useParams, useNavigate,useLocation } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from 'yup';
import { Formik } from 'formik';
import AuthUser from '../../../Components/Auth/AuthUser';
import Backdrop from '@mui/material/Backdrop';
import InputMask from 'react-input-mask';
import Freez from '../../../Components/Loader/Loader'
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
function EditUser(props) {
    const dispatch = useDispatch();
   
    const theme = useTheme();
    const navigate = useNavigate();
   
    const handleChange22 = (event, values) => {
        const {
            target: { value },
        } = event;
        values.userAidList = (typeof value === 'string' ? value.split(',') : value);

    };
    const [personName, setPersonName] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [software, setSoftware] = useState([]);
    //variable declearation start
    //Common Variables
    const { http, user } = AuthUser();
    const { id } = useParams();
    const location = useLocation();
    const [userTypeList, setUserTypeList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [aidlist, setAIDlist] = useState([]);
    const [caidlist, setCAIDlist] = useState([]);
    //userdetails array
    const [userDetails, setuserDetails] = useState([]);
    //variable declearation ends
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const [aidlist1, setAidlist1] = useState([]);
    const [companyname, setCompanyName] = useState('');
    const [isLowercaseValid, setIsLowercaseValid] = useState(false);
    const [isUppercaseValid, setIsUppercaseValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [showPasswordValidationBlock, setShowPasswordValidationBlock] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get('source') ? queryParams.get('source') : '';

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    
    //loader function and variable end
    //fetch user details start
    const fetchUserDetails = async() => {
        const apiUrl = '/fetchUserDetails/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if (res.success == true) {
                getAidlist(res.details.company_master_id);
                setuserDetails(res.details);
                setCAIDlist(res.details.aid_master);
                setPersonName(res.details.aidList);
                
                const companyName = res.details.company ? res.details.company.company_name : '';
               
                setCompanyName(companyName);
            } else {
                alert("not found");
            }
        }
    };
    //fetch user details end
    //fetch user types start
    const fetchUserTypes = async() => {
        handleFreezStart();
        const apiUrl = '/fetchUserTypes';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            handleFreezStop();
            setUserTypeList(res);
        }
    }

    const fetchSoftware = async() => {
        const apiUrl = '/getSoftwareInfo';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if (res.success === true) {
                setSoftware(res.data);
            }
        }
    }
    //fetch user types end
    const loginUser = JSON.parse(localStorage.getItem('user'));
    const isUserTypeDisabled = ![3].includes(loginUser.roles_id);
    const checkAdmin = () => {
        var check = 1;
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id != 1 && loginUser.roles_id != 2 && loginUser.roles_id != 3 && loginUser.roles_id != 9) {
            check = 0;
        } 
        if(check == 0) {
            navigate('/login');
            return false;
        }
      
    
        return true;
    }

    const getUserList = () => {
        setShowPasswordValidationBlock(false);
        var check = checkAdmin();
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id <= 3 ) {
            if(loginUser.roles_id == 3 && loginUser.company_master_id != userDetails.company_master_id) {
                navigate('/login');
                return false;
            }
        }
        if(check === true) {
            if(source == "automation") {
                navigate('/automations/api-user');
            }else{
                navigate('/userpanel');
            }
        }
    }

    //fetch Company AID list start
    const getAidlist = async(companyid) => {
        handleFreezStart();
        const apiUrl = '/fetchCompanyAIDList/' + companyid;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
           
            setAIDlist(res);
            setNames(res.aidList);
            setAidlist1(res);
        }
        handleFreezStop();
    }
    //fetch Company AID list end
    //hide menus according to usertype start
    const changeUserType = (e,values) => {
        if (e == 1 || e == 2 || e == 6 || e == 7 || e == 8 || e == 9 || e == 10 || e == 12 || e == 13 || e == 14) {
            const element = document.getElementById("Companydiv");
            element.style.display = "none";
            const element1 = document.getElementById("Timezonediv");
            element1.style.display = "none";
            const element3 = document.getElementById("Aidlist");
            element3.style.display = "none";
            const partnerCode = document.getElementById("ReferralCode");
            partnerCode.style.display = "none";
            if(e == 7){
                const partnerCode = document.getElementById("ReferralCode");
                partnerCode.style.display = "block";
            }
        } else {
            const element = document.getElementById("Companydiv");
            element.style.display = "block";
            const element1 = document.getElementById("Timezonediv");
            element1.style.display = "block";
            const element3 = document.getElementById("Aidlist");
            element3.style.display = "block";
            const partnerCode = document.getElementById("ReferralCode");
            partnerCode.style.display = "none";
        }
        
        if(e == 3 || e == 11) {
            values.userAidList = names;
        }else{
            values.userAidList = [];
        }
    }
    //hide menu according to usertype end
    //fetchcompany list start
    const fetchCompanyList = async() => {
        handleFreezStart();
        const apiUrl = '/fetchCompanyList';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            handleFreezStop();
            setCompanyList(res);
        }
    }
    //fetchcompany list end
    //useEffect start
    useEffect(() => {
        setShowPasswordValidationBlock(false);
        fetchUserTypes();
        fetchCompanyList();
        fetchSoftware();
        fetchUserDetails();
    }, [])
    useEffect(() => {
        changeUserType(userDetails.roles_id,[]);
    });
    //useEffect end
    //create function start
    const Update = async (values) => {
        var check = checkAdmin();
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id <= 3 ) {
            if(loginUser.roles_id == 3 && loginUser.company_master_id != userDetails.company_master_id) {
                swal({
                    title: "Failed",
                    text: "This action is unauthorized.",
                    icon: "error",
                    button: "Ok",
                })
                navigate('/login');
                return false;
            }
        }
        if(check === true) {
            setShowPasswordValidationBlock(false);
            handleFreezStart();

            const apiUrl = '/updateUser';
            const args = {
                id: id,
                first_name: values.FirstName,
                last_name: values.LastName,
                role_id: values.UserType,
                email: values.Email,
                phone_no: values.PhoneNumber,
                password: values.Password,
                software_name:values.softwareName,
                aids: values.userAidList,
                defaultTimezone: values.DefaultTimezone,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res)
            {
                if (res.success === true) {
                    handleFreezStop();
                    swal({
                        title: "Success",
                        text: res.message,
                        icon: "success",
                        button: "Ok",
                    }).then((ok) => {
                        if(source == "automation") {
                            navigate('/automations/api-user');
                        }else{
                            navigate('/userpanel');
                        }
                    });
                } else {
                    handleFreezStop();
                    swal({
                        title: "Failed",
                        text: res.message,
                        icon: "error",
                        button: "Ok",
                    }).then((ok) => {
                        if (ok) {
                            fetchUserDetails()
                        }
                    });
                }
            }
        }
        
    }
    //create function end
    //User validation Schema start
    const UserSchema = Yup.object().shape({
        FirstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your full Name.'),
        LastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your last Name.'),
        PhoneNumber: Yup.string()
            .min(14, 'Phone number must be of 10 digit!')
            .max(14, 'Phone number must be of maximum 10 digit!')
            .required('Please enter your phone number.'),
        Email: Yup.string()
            .email('Invalid email format.')
            .required('Please enter your email address.'),
        Password: Yup.string()
        .test(
            (value) => {
                return (
                    value?
                    isLowercaseValid &&
                    isUppercaseValid &&
                    isNumberValid &&
                    isSpecialCharValid &&
                    isLengthValid
                    :true
                );
            }
        )
    });
    //User validation Schema end


    //password suggestion box
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
    
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*]/;
        setIsLowercaseValid(lowercaseRegex.test(newPassword));
        setIsUppercaseValid(uppercaseRegex.test(newPassword));
        setIsNumberValid(numberRegex.test(newPassword));
        setIsSpecialCharValid(specialCharRegex.test(newPassword));
        setIsLengthValid(newPassword.length >= 8);
      };

      //hide show the password suggestion box
      const handlePasswordClick = () => {
        setShowPasswordValidationBlock(true);
      };
    const ContentLayout=
            <>
                <Formik
                    initialValues={{
                        FirstName: userDetails.first_name,
                        LastName: userDetails.last_name,
                        Email: userDetails.email,
                        PhoneNumber: userDetails.phone_no ?  (!(userDetails.phone_no.includes("("))  ? "(" + userDetails.phone_no.substring(0,4) + ") " + userDetails.phone_no.substring(4,7) + "-" + userDetails.phone_no.substring(7): userDetails.phone_no)  : '',
                        UserType: userDetails.roles_id,
                        CompanyName: companyname,
                        Aidarray: aidlist,
                        userAidList: personName,
                        softwareName: userDetails.software_name,
                       

                        DefaultTimezone: userDetails.default_timezone,
                        ReportDelivery: userDetails.report_delivery,
                        Password: ''
                    }}
                    validationSchema={UserSchema}
                    onSubmit={(values, errors) => {
                        if((values.UserType == 4 || values.UserType == 5) && (values.userAidList.length == 0)){
                            swal({
                                title: "Alert",
                                text: "Please Select atleast one AID",
                                icon: "error",
                                button: "Ok",
                            });
                            return false;
                        }
                        Update(values);
                    }}
                    enableReinitialize={true}
                >
                    {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                        <div>
                            <Box>
                                <div>
                                    <Typography color="primary" sx={{ fontSize: "30px" }}>{userDetails.name}</Typography>
                                </div>
                                <Card variant="outlined" size="lg" mt={2}>
                                    <CardOverflow
                                        sx={{
                                            bgcolor: '#F6FAFF',
                                            borderColor: '#F6FAFF',
                                            padding: '1%',
                                        }}
                                    >
                                        <Typography level="h3" startDecorator={<AccountCircleIcon sx={{ fontSize: "35px" }} />} mb={2}>
                                            Edit User
                                        </Typography>
                                    </CardOverflow>
                                    <CardContent>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="First Name"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='FirstName'
                                                    id='FirstName'
                                                    autoComplete='FirstName'
                                                    onChange={handleChange('FirstName')}
                                                    value={values.FirstName || ""}
                                                    onBlur={handleBlur('FirstName')}
                                                    InputLabelProps={{
                                                        shrink: Boolean(values.FirstName)
                                                    }}
                                                />
                                                {touched.FirstName && errors.FirstName ? <div className='error'>{errors.FirstName}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="Last Name"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='LastName'
                                                    autoComplete='LastName'
                                                    id='LastName'
                                                    onChange={handleChange('LastName')}
                                                    value={values.LastName || ""}
                                                    onBlur={handleBlur('LastName')}
                                                    InputLabelProps={{
                                                        shrink: Boolean(values.LastName)
                                                    }}
                                                />
                                                {touched.LastName && errors.LastName ? <div className='error'>{errors.LastName}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="E-mail Address"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='Email'
                                                    id='Email'
                                                    autoComplete='Emails'
                                                    onChange={handleChange('Email')}
                                                    value={values.Email || ""}
                                                    onBlur={handleBlur('Email')}
                                                    disabled
                                                    InputLabelProps={{
                                                        shrink: Boolean(values.Email)
                                                    }}
                                                />
                                                {touched.Email && errors.Email ? <div className='error'>{errors.Email}</div> : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                               
                                                <InputMask
                                                    mask='(999) 999-9999'
                                                    maskChar={null}
                                                    name='PhoneNumber'
                                                    fullWidth
                                                    size="small"
                                                    id='PhoneNumber'
                                                    autoComplete='PhoneNumber'
                                                    onChange={(e) => { handleChange(e); }}
                                                    value={values.PhoneNumber || ""}
                                                    onBlur={handleBlur('PhoneNumber')}
                                                   >
                                                    {(inputProps) =>
                                                        <TextField
                                                        label="Phone Number" variant="outlined"
                                                        {...inputProps}
                                                        />
                                                    }
                                                </InputMask>
                                                {touched.PhoneNumber && errors.PhoneNumber ? <div className='error'>{errors.PhoneNumber}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="UserType">User Type</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        name='UserType'
                                                        input={<OutlinedInput label="User Type" />}
                                                        id='UserType'
                                                        onBlur={handleBlur('UserType')}

                                                        onChange={(e) => {
                                                            changeUserType(e.target.value, values);
                                                            handleChange(e);
                                                        }}
                                                        value={values.UserType?values.UserType:''}
                                                        MenuProps={MenuProps}
                                                        disabled={isUserTypeDisabled}
                                                        // disabled={true}
                                                    >
                                                        {userTypeList.map((usertype,index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={usertype.id}
                                                                style={getStyles(usertype, personName, theme)}
                                                            >
                                                                {usertype.role_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </FormControl>
                          
                                            </Grid>
                                            
                                            <Grid item lg={4} md={4} sm={12} xs={12} id='Companydiv'>
                                               
                                                <TextField
                                                    label="Company Name"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='CompanyName'
                                                    id='CompanyName'
                                                    autoComplete='CompanyName'
                                                    disabled
                                                    value={companyname || ""}
                                                    InputLabelProps={{
                                                        shrink: Boolean(companyname)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={4} md={4} sm={12} xs={12} id='Aidlist'>
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="AidList">AID List</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"

                                                        name='Aidarray'
                                                        id='Aidarray'

                                                        multiple
                                                        value={values.userAidList || ""}
                                                        onChange={(e) => {
                                                            handleChange22(e, values);
                                                            handleChange(e);

                                                        }}
                                                        input={<OutlinedInput label="AID List" />}
                                                        MenuProps={MenuProps}
                                                    >

                                                        {names ?
                                                            names.map((name) => (
                                                                <MenuItem
                                                                    key={name}
                                                                    value={name}
                                                                    disabled={values.UserType == 3 || values.UserType == 11 ? true : false}
                                                                >
                                                                    {name}
                                                                </MenuItem>
                                                            ))
                                                            : ""}
                                                    </Select>

                                                </FormControl>
                                            </Grid>
                                            

                                <Grid item lg={4} md={4} sm={12} xs={12} id='ReferralCode'>
                                <FormControl fullWidth size='small'>
                                        <InputLabel id="ReferralCode">Referral Code</InputLabel>
                                        <Select
                                                    labelId="demo-simple-select-label"
                                                    id="softwareName"
                                                    name="softwareName"
                                                   
                                                    label="Report Options"
                                                    onChange={handleChange('softwareName')}
                                                    value={values.softwareName  || ""}
                                                    onBlur={handleBlur('softwareName')}
                                                >
                                                    {software.map((row, idx) => (
                                                        <MenuItem value={row.link_name} key={idx}>{row.software_name}</MenuItem>
                                                    ))}

                                                </Select>
                                            
                                    </FormControl>
                                   
                                </Grid>
                                            
                                            <Grid item lg={4} md={4} sm={12} xs={12} id='Timezonediv'>
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="DefaultTimeZone">Default Timezone</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-name-label"
                                                        name='DefaultTimezone'
                                                        id='DefaultTimezone'
                                                        input={<OutlinedInput label="Default Timezone" />}
                                                        onChange={handleChange('DefaultTimezone')}
                                                        value={values.DefaultTimezone?values.DefaultTimezone:''}
                                                        onBlur={handleBlur('DefaultTimezone')}
                                                    >
                                                        <MenuItem value="EDT" >Eastern Time</MenuItem>
                                                        <MenuItem value="HST" >Hawaii Time</MenuItem>
                                                        <MenuItem value="AKDT">Alaska Time</MenuItem>
                                                        <MenuItem value="PDT" >Pacific Time</MenuItem>
                                                        <MenuItem value="MDT" >Mountain Time</MenuItem>
                                                        <MenuItem value="CDT" >Central Time</MenuItem>
                                                    </Select>

                                                </FormControl>
                                            </Grid>
                                          
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <FormControl fullWidth size='small' variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                    <OutlinedInput
                                                        type="text"
                                                        
                                                        label="Password"
                                                        placeholder="Password"
                                                        input={<OutlinedInput label="Password" />}
                                                        size="small"
                                                        fullWidth
                                                        name='Password'
                                                        autoComplete='Password'
                                                        id='Password'
                                                        onChange={(e) => {
                                                            handlePasswordChange(e);
                                                            handleChange(e); // Assuming handleChange('Password') expects an event parameter
                                                        }}
                                                        onKeyDown={handlePasswordClick}
                                                        value={values.Password || ""}
                                                        onBlur={handleBlur('Password')}
                                                        

                                                    />
                                                    {touched.Password && errors.Password ? <div className='error'>{errors.Password}</div> : null}
                                                    {showPasswordValidationBlock && (
                                                <>
                                                       <div>
                                                            <span style={{ color: isLengthValid ? 'green' : 'red' }}>Minimum <b>8 characters</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isSpecialCharValid ? 'green' : 'red' }}>At least <b>one special character</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isUppercaseValid ? 'green' : 'red' }}>At least <b>one capital (uppercase)</b> letter</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isNumberValid ? 'green' : 'red' }}>At least <b>one number</b></span>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: isLowercaseValid ? 'green' : 'red' }}>At least <b>one lowercase</b> letter </span>
                                                        </div>
                                                </>
                                                )}
                                                </FormControl>
                                                <div>
                                                <Typography sx={{ fontSize: "15px" }}>Leave password field blank to leave it unchanged</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                      
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} sx={{ textAlign: "center", width: "100%" }}>
                                            <Grid item>
                                                <Button variant="contained" sx={{ minWidth: 200 }} color="success" onClick={handleSubmit}>
                                                    SAVE CHANGES
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" sx={{ minWidth: 200 }} onClick={() => getUserList()} >
                                                    CLOSE
                                                </Button>
                                            </Grid>
                                           
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </div>
                    )}

                </Formik>
            </>
   
    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}


export default EditUser;