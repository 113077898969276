import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination, button } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import config from '../../../AppConfig';
import Sidebar from '../../../Components/Blogs/Sidebar';
function CreditGlossary() {
    return (
        <>
            <LoginHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={2} md={2} sm={12} xs={12} >
                       <Sidebar/>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12} sx={{padding:"2%"}}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <img src={require("../../../assets/images/mfsn_banner_4.jpg")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className='responsiveImg' />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='h4'>Credit Glossary</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <div>
                            <p><b>Account</b></p>
                            <p>An account is the relationship between a consumer and a company. An open account means the relationship is ongoing, while a closed account indicates the relationship has ended.</p>
                            <p><b>Account Number</b></p>
                            <p>An account number is a unique number that identifies the account a consumer has with a company. Adverse Action An adverse action is an unfavorable action, which could include being rejected for a line of credit. The reason for an adverse action must be divulged by the creditor.</p>
                            <p><b>Bank Card</b></p>
                            <p>A bank card is a card issued by a bank which is linked directly to a bank account. Bankruptcy Bankruptcy is a legal process that allows a person or business to alleviate some debts, sometimes at the expense of the creditor.</p>
                            <p><b>Billing Cycle</b></p>
                            <p>The billing cycle is the time between your latest bill and your previous bill.</p>
                            <p><b>Chapter 7 Bankruptcy</b></p>
                            <p>Chapter 7 bankruptcy issues court-administered liquidation of the assets of an individual or business that is not capable of repaying debts.</p>
                            <p><b>Chapter 11 Bankruptcy</b></p>
                            <p>Chapter 11 bankruptcy allows for a troubled business to reorganize as an alternative to liquidation.</p>
                            <p><b>Chapter 12 Bankruptcy</b></p>
                            <p>Chapter 12 bankruptcy is bankruptcy that applies to the farming industry.</p>
                            <p><b>Chapter 13 Bankruptcy</b></p>
                            <p>Chapter 13 bankruptcy results in the debtor repaying the debts on a schedule agreed upon by the debtor, creditors and court.</p>
                            <p><b>Charge Card</b></p>
                            <p>A charge card is a credit card that must be paid in full every month, with no interest rate.</p>
                            <p><b>Collateral</b></p>
                            <p>Collateral is property owned by the consumer that is to be forfeited by the consumer if the loan is not repaid.</p>
                            <p><b>Credit</b></p>
                            <p>Credit is the ability to borrow money or services on the agreement that it will be repaid.</p>
                            <p><b>Credit Bureau</b></p>
                            <p>A credit bureau is a company that gathers information on consumers and their credit, and then distributes that history at a cost to other potential creditors.</p>
                            <p><b>Credit Card</b></p>
                            <p>A credit card is a card issued by a creditor with a maximum amount of credit that may be placed on the account. This account requires the consumer to pay a monthly payment until the balance is paid off.</p>
                            <p><b>Credit Limit</b></p>
                            <p>Your credit limit is the maximum amount you may charge on a credit card.</p>
                            <p><b>Credit Report</b></p>
                            <p>A credit report is a record of your present and past credit use. Under the Fair Credit Reporting Act, you have the right to see your credit history.</p>
                            <p><b>Credit Risk</b></p>
                            <p>An assessment of the likelihood a consumer will be able to fulfill the credit agreement.</p>
                            <p><b>Credit Score</b></p>
                            <p>A credit score is a calculated score determined from a number of factors that indicates a consumer’s credit worthiness.</p>
                            <p><b>Creditor</b></p>
                            <p>A creditor is any company or individual that issues credit to consumers.</p>
                            <p><b>Default</b></p>
                            <p>A default is a failure to meet the repayments agreed upon in the credit agreement.</p>
                            <p><b>Dispute</b></p>
                            <p>A dispute is a questioning of the accuracy of information in a credit report.</p>
                            <p><b>Fraud</b></p>
                            <p>Fraud is intentional misuse of information in order to cause someone to give up something of value.</p>
                            <p><b>Identity</b></p>
                            <p>The characteristics of an individual consumer. Identity information includes any information that can help identify a consumer, including social security number, date of birth, etc.</p>
                            <p><b>Inquiry</b></p>
                            <p>An examination of a consumer’s credit history.</p>
                            <p><b>Installment Loan</b></p>
                            <p>An installment loan is a loan in which the repayment is divided into amounts to be repaid at specific intervals as defined in the terms of the loan.</p>
                            <p><b>Lien</b></p>
                            <p>Lien is interest that a creditor maintains in the consumer’s property until the debt has been paid.</p>
                            <p><b>Mortgage</b></p>
                            <p>A mortgage is an agreement where the owner places a real property as collateral for a loan.</p>
                            <p><b>Permissible Purpose</b></p>
                            <p>A permissible purpose is a particular circumstance under which a potential creditor may access a consumer credit report from a credit bureau under the Fair Credit Reporting Act.</p>
                            <p><b>Public Record</b></p>
                            <p>Public record refers to information available to the general public, including court judgments and bankruptcy.</p>
                            <b>Retail Card</b>
                            <p>A retail card is a credit card issued by a retail store.</p>
                            </div>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Are you monitoring your credit?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={4}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' style={{textDecoration:"none"}}><Typography level='title-md' sx={{color:"#1976d2"}}>Get your free credit score and score report now and protect your credit.</Typography></a>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' className='blog'>Get your free credit score and score report now and protect your credit.</a>
                        </Grid> */}
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <img src={require("../../../assets/images/lt_banner_5.png")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default CreditGlossary;