
import React from "react";
import { Grid,  Typography } from '@mui/material';
import { Card, CardContent, CardOverflow } from '@mui/joy';

function AffiAgreement12() {

    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h6">
                                23. Severability. If any provision of this Agreement is rendered or declared illegal or unenforceable by reason of any existing or subsequently enacted legislation or by decree of a court of competent jurisdiction, all remaining provisions of this Agreement shall remain in full force and effect.<br/><br/>
                                24.Right of Offset: In event Company is past due on amounts payable to MFSN under any other Agreement entered into between the Parties, MFSN reserves the right to obtain and satisfy thru offset any past due amounts owed by the Company to MFSN under that Agreement.<br/><br/>
                                25. Modifications and Changes. MFSN reserves the right to change any part of its affiliate program and the services provided therein and this Agreement at any time with or without notice to Referrer.<br/><br/>
                                IN WITNESS WHEREOF, the Parties, intending to be legally bound, have caused this Agreement to be executed by their duly authorized representatives as of the Effective Date.
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement12;