import React,{ useState } from "react";
import {
    Dialog, DialogTitle, DialogContent, DialogActions,InputAdornment,
    TextField, Grid, Button, IconButton, Typography,Select,InputLabel, MenuItem, FormControl, OutlinedInput,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from 'formik';
import { useTheme } from '@mui/material/styles';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const convertToCamelCase = (data) => {
    return {
        name:data?.name || "",
        firstName: data?.firstName || data?.first_name || "",
        lastName: data?.lastName || data?.last_name || "",
        phone: data?.phone || data?.phone_number || data?.phone_no || "",
        userType: data?.roles_id || data?.role?.roles_id || "",
        userAidList:data?.aidList|| data?.aid_list,
        aidList: data?.aidList || data?.aid_list || "",
        companyID: data?.companyId || data?.company_id || "" ,
        companyName: data?.companyName || data?.company?.company_name || "",
        email: data?.email || "",
        password: "", 
        timezone: data?.timezone || data?.default_timezone || "",
    };
};

const UserForm = ({ open, handleClose,handleSubmit, userData, setUserData, userTypeList,names,mode,checkUsaTLD }) => {
    const formattedUserData = convertToCamelCase(userData);
    const [isLowercaseValid, setIsLowercaseValid] = useState(false);
    const [isUppercaseValid, setIsUppercaseValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [showCompany, setShowCompany] = useState(true);
    const [showAID, setShowAID] = useState(true);
    const [showTimezone, setShowTimezone] = useState(true);
    const [showPasswordValidation, setShowPasswordValidation] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    const theme = useTheme();
    //password suggestion box
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setShowPasswordValidation(newPassword.length > 0);
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*]/;
        setIsLowercaseValid(lowercaseRegex.test(newPassword));
        setIsUppercaseValid(uppercaseRegex.test(newPassword));
        setIsNumberValid(numberRegex.test(newPassword));
        setIsSpecialCharValid(specialCharRegex.test(newPassword));
        setIsLengthValid(newPassword.length >= 8);
    };
    function getStyles(name, personName = [], theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    //hide menus according to usertype start
    const changeUserType = (roleId, setFieldValue) => {
        // ✅ Hide Company and Timezone for specific roles
        if ([1, 2, 6, 7, 8, 9, 10, 12, 13, 14].includes(roleId)) {
            setShowCompany(false);
            setShowTimezone(false);
            setShowAID(false);
            
        } else {
            setShowCompany(true);
            setShowTimezone(true);
            setShowAID(true);
        }
        // ✅ Update AID List
        if (roleId === 3 || roleId === 11) {
            setFieldValue("userAidList", names);  // ✅ Correct way to update Formik value
        } else {
            setFieldValue("userAidList", []);
        }
    }
    //hide menu according to usertype end

    //User validation Schema start
    const UserSchema =(mode)=> Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your full Name.'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your last Name.'),
        phone: Yup.string()
            .transform((value) => value.replace(/\D/g, ""))
            .matches(/^\d{10}$/, "Phone number must be exactly 10 digits!")
            .required("Please enter your phone number."),
        email: Yup.string()
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Please enter your email address.'),
        password:mode === "create" 
            ? Yup.string().required("Password is required.")
            : Yup.string().test("password-validation", "Invalid password", function (value) {
            if (mode !== "create" && !value) return true; // ✅ Skip validation in edit mode if empty
            return (
                isLowercaseValid &&
                isUppercaseValid &&
                isNumberValid &&
                isSpecialCharValid &&
                isLengthValid
            );
        }),
        userType: Yup.string()
            .required('Please select type of user!.'),
        userAidList: Yup.array()
            .when("userType", {
            is: (userType) => ['3', '4', '5', '11'].includes(userType),
            then: (schema) => schema
                .min(1, 'AID is required.') // Ensures at least one item is selected
                .required('AID is required.'), // Keeps required validation
        }),
        companyName: Yup.string().when("userType", {
            is: (userType) => ['3','4','5','11'].includes(userType),
            then: (schema) => schema
            .required('Company Name is required.')
        }),
        timezone: Yup.string().when("userType", {
            is: (userType) => ['3','4','5','11'].includes(userType),
            then: (schema) => schema
                .required('user timezone need to be selected')
            }),    
    }); 
    //User validation Schema end
    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            {/* ✅ Title with Close Button */}
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h6">
                    {mode === "edit" ? `Edit User - ${formattedUserData.name || "User Name"}` : "Create User"}
                </Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Formik
                initialValues={formattedUserData}
                validationSchema={UserSchema(mode)}
                onSubmit={(values) => handleSubmit(values, mode)}
                enableReinitialize
            >
                {({ values, errors, touched, handleChange, handleBlur, setFieldTouched,setFieldValue, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        {/* ✅ Form Fields */}
                        <DialogContent>
                            <Grid container spacing={3}>
                                {/* ✅ First Name */}
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth label="First Name" variant="outlined"
                                        name="firstName"
                                        value={values.firstName}
                                        className="blue-border"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.firstName && Boolean(errors.firstName)}
                                        helperText={touched.firstName && errors.firstName}
                                    />
                                </Grid>
                                {/* ✅ Last Name */}
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth label="Last Name" variant="outlined"
                                        name="lastName"
                                        value={values.lastName}
                                        className="blue-border"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.lastName && Boolean(errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                    />
                                </Grid>
                                {/* ✅ Email (Disabled) */}
                                <Grid item xs={6}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        type="text"
                                        fullWidth
                                        name='Email'
                                        className="blue-border"
                                        id='Email'
                                        value={values.email}
                                        onChange={(e) =>{ setFieldValue("email", e.target.value); checkUsaTLD(e.target.value);}}
                                        onBlur={handleBlur}
                                        disabled={mode === "edit"}
                                    />
                                    {touched.email && errors.email ? <div className='error'>{errors.email}</div> : null}
                                </Grid>
                                {/* ✅ Phone Number with Mask */}
                                <Grid item xs={6}>
                                <InputMask
                                    mask="(999) 999-9999"
                                    maskChar={null}
                                    value={values.phone}
                                    onChange={(e) => setFieldValue('phone', e.target.value)}
                                    onBlur={handleBlur("phone")}
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            fullWidth label="Phone Number" variant="outlined"
                                            name="phone"
                                            className="blue-border"
                                            error={touched.phone && Boolean(errors.phone)}
                                            helperText={touched.phone && errors.phone}
                                        />
                                    )}
                                </InputMask>
                                </Grid>
                                {/* ✅ User Type */}
                                <Grid item xs={6}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>User Type</InputLabel>
                                        <Select
                                            name="userType"
                                            value={values.userType}
                                            className="blue-border"
                                            onChange={(e) => {
                                                changeUserType(e.target.value,setFieldValue);
                                                handleChange(e);
                                            }}
                                            onBlur={() => setFieldTouched("userType", true)}
                                            input={<OutlinedInput label="User Type" />}
                                        >
                                            {userTypeList.map((usertype,index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={usertype.id}
                                                    style={getStyles(usertype, values.userAidList, theme)}
                                                >
                                                    {usertype.role_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.userType && errors.userType ? <div className='error'>{errors.userType}</div> : null}
                                    </FormControl>
                                </Grid>
                                {/* ✅ Company Name (Disabled) */}
                                {showCompany && (
                                    <Grid item xs={6} id='Companydiv'>
                                        <TextField
                                            fullWidth label="Company Name" variant="outlined"
                                            name="companyName"
                                            className="blue-border"
                                            value={values.companyName}
                                            disabled
                                            error={touched.companyName && Boolean(errors.companyName)}
                                            helperText={touched.companyName && errors.companyName}
                                        />
                                    </Grid>
                                )}
                                {/* ✅ AID List */}
                                {showAID && (
                                    <Grid item xs={6}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>AID List</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                name="userAidList"
                                                id="Aidarray"
                                                className="blue-border"
                                                multiple
                                                value={values.userAidList || []}  // ✅ Ensure it's an array
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                input={<OutlinedInput label="AID List" />}
                                                MenuProps={MenuProps}
                                            >
                                                {names &&
                                                    names.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                            disabled={values.userType == 3 || values.userType == 11}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        {touched.userAidList && errors.userAidList ? <div className='error'>{errors.userAidList}</div> : null}
                                    </Grid>
                                )}
                                {/* ✅ Default Timezone */}
                                {showTimezone && (
                                    <Grid item xs={6} id='Timezonediv'>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Default Timezone</InputLabel>
                                            <Select
                                                name="timezone"
                                                value={values.timezone}
                                                className="blue-border"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                input={<OutlinedInput label="Default Timezone" />}
                                            >
                                                <MenuItem value="PST">PST - Pacific Standard Time</MenuItem>
                                                {/* <MenuItem value="CT">CT - Central Time</MenuItem> */}
                                                <MenuItem value="EST">EST - Eastern Standard Time</MenuItem>
                                                <MenuItem value="IST">IST - Indian Standard Time</MenuItem>
                                                <MenuItem value="EDT">EDT - Eastern Daylight Time</MenuItem>
                                                <MenuItem value="UTC">UTC - Coordinated Universal Time</MenuItem>
                                                <MenuItem value="PDT">PDT - Pacific Daylight Time</MenuItem>
                                                <MenuItem value="WET">WET - Western European Time</MenuItem>
                                                <MenuItem value="CDT">CDT - Central Daylight Time</MenuItem>
                                                <MenuItem value="WST">WST - Western Standard Time</MenuItem>
                                                <MenuItem value="CST">CST - Central Standard Time</MenuItem>
                                                <MenuItem value="MST">MST - Mountain Standard Time</MenuItem>
                                                <MenuItem value="HST">HST - Hawaii Standard Time</MenuItem>
                                            </Select>
                                            {touched.timezone && errors.timezone ? <div className='error'>{errors.timezone}</div> : null}
                                        </FormControl>
                                    </Grid>
                                )}
                                {/* ✅ Password */}
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth 
                                        label="Password" 
                                        variant="outlined" 
                                        type={showPassword ? "text" : "password"} 
                                        name="password"
                                        value={values.password || ""}
                                        className="blue-border"
                                        onChange={(e) => {
                                            handleChange(e);
                                            handlePasswordChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        autoComplete="new-password" // ✅ Prevent browser autofill
                                        inputProps={{ autoComplete: "off" }}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {showPasswordValidation && (
                                         <>
                                            <div>
                                                <span style={{ color: isLengthValid ? 'green' : 'red' }}>Minimum <b>8 characters</b></span>
                                            </div>
                                            <div>
                                                <span style={{ color: isSpecialCharValid ? 'green' : 'red' }}>At least <b>one special character</b></span>
                                            </div>
                                            <div>
                                                <span style={{ color: isUppercaseValid ? 'green' : 'red' }}>At least <b>one capital (uppercase)</b> letter</span>
                                            </div>
                                            <div>
                                                <span style={{ color: isNumberValid ? 'green' : 'red' }}>At least <b>one number</b></span>
                                            </div>
                                            <div>
                                                <span style={{ color: isLowercaseValid ? 'green' : 'red' }}>At least <b>one lowercase</b> letter </span>
                                            </div>
                                        </>
                                    )}
                                        {mode === "edit" && (
                                            <div>
                                                <Typography sx={{ fontSize: "15px" }}>
                                                    Leave password field blank to leave it unchanged
                                                </Typography>
                                            </div>
                                        )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        {/* ✅ Action Buttons */}
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" className="cancel-button">
                                CANCEL
                            </Button>
                            <Button onClick={handleSubmit} variant="contained" className="save-button">
                                {mode === "edit" ? "SAVE CHANGES" : "CREATE USER"}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default UserForm;
