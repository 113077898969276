import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box, Tooltip, FormControl, Grid, Button, InputLabel, OutlinedInput, Select, MenuItem
} from '@mui/material';
import { Typography, Table } from '@mui/joy';
import { Link } from 'react-router-dom';
//MRT Imports
import MaterialReactTable from 'material-react-table';
import AuthUser from '../../../Components/Auth/AuthUser';
import Loader from '../../../Components/Loader/Loader';
import config from '../../../AppConfig';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import swal from "sweetalert";
import { format, subDays, isAfter } from 'date-fns';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { useParams } from "react-router-dom";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { postData } from '../../../redux/apiSlice';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Badge from '@mui/material/Badge';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';
// import ContactEdit from './ContactEdit';
let partnerCheck = 1;


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];

function ContactTypeConfig(props) {
    const [open, setOpen] = React.useState(false);

    
    //dialog content end
    const { http, user } = AuthUser();
    const dispatch = useDispatch();
    const [croLead, setCroLead] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [reasonError, setReasonError] = useState('');
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
    let UserId = user.id;

    let des = [];
    const tablePreferencesArray = {

    }
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const getEnrolledMember = async () => {
        handleFreezStart();
        const apiUrl = '/enrolledMember';
        const args = {
            fromDate: startDate,
            toDate: endDate
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        setCroLead(res);
        handleFreezStop();


    }
    useEffect(() => {
        getEnrolledMember();
    }, [])

    const view3b = (option) => {
        localStorage.setItem('username', option.email);
        localStorage.setItem('username', option.signup_reports.password);
        // Append "/score" to the base URL
        const updatedUrl = config.BASE_URL + `3breport`;
        // Navigate to the updated URL
        window.open(updatedUrl, '_blank');

    }
   
   
    const getPasswordString = (str) => {
        try {
            var passValue = str =  atob(str);
            return passValue;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };
    const togglePasswordVisibility = (rowId) => {
        setPasswordVisibility((prevState) => ({
            ...prevState,
            [rowId]: !prevState[rowId],
        }));
    };

    const sideBar = calendarFilter();
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];

    useEffect(() => {
    }, [rowSelection]);
    ////////////////////////
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
    }, [rowSelection]);

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);
    ////////////////////////

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            http.post('/saveTablePreferences', {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'contacttypeconfig'
            }).then((result) => {
            })
        }
    }, [columnVisibility])
    const tablePreferences = () => {
        http.get('/fetchTablePreferences/contacttypeconfig')
            .then((res) => {
                if (Object.values(res.data).length == 0) {
                    setColumnVisibility(tablePreferencesArray);
                } else {
                    setColumnVisibility((JSON.parse(res.data.preferences)));
                }
            });
    }

    const MemberLogin = (option) => {
        handleFreezStart();
        console.log(option.password)
        http.post("/creditSnapshotEnrollLogin", {
            email: option.email,
            password: decodeBase64(option.password),
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {

                    if (res.data.step === "inCreditSuccess") {
                        localStorage.setItem("ScoreData", JSON.stringify(res.data.data));

                        // Get the base URL
                        const baseUrl = window.location.origin;

                        // Append "/score" to the base URL
                        const updatedUrl = config.BASE_URL + `score`;

                        // Navigate to the updated URL

                        window.open(updatedUrl, '_blank');

                    }

                } else {
                    swal({
                        title: "Failed",
                        text: "Something Went wrong please contact to admin",
                        icon: "error",
                        button: "Ok",
                    })

                }
            })

    }
    const decodeBase64 = (str) => {
        try {
            var passValue = str = atob(str);
            var masked = str.substring(0, str.length - 4);
            masked = masked.replace(/./g, '*'); //The character is ASCII-7 (Press Alt+7 to type)
            var text = passValue.substring(passValue.length - 4);
            var newPass = masked + text;
            return newPass;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };
    const showPassword = (str) => {
        try {
            var passValue = str = atob(str);
            return passValue;
        } catch (error) {
            console.error('Error decoding base64:', error);
            return '';
        }
    };

    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (row) => `${row.parentInfo ? (row.parentInfo.aid ?row.parentInfo.aid: ''):''}`, 
                accessorKey: 'AID',
                header: 'AID',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (row.original.parentInfo ? (row.original.parentInfo.aid ?row.original.parentInfo.aid: 'N/A'):'N/A'),
            },
            {
                accessorKey: 'Total Enrolled Members',
                accessorFn: (row) => `${row.parentInfo.membercount}`,
                header: 'Total Enrolled Members',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <div style={{ textAlign: "center" }}>
                        <Badge badgeContent={`${row.original.parentInfo.membercount}`} color={row.original.parentInfo.membercount === 0 ? 'primary' : 'success'} overlap="circular" max={9999}></Badge>
                    </div>
                ),
            },
            {
                accessorKey: 'Members With Failed Payments',
                accessorFn: (row) => `${row.parentInfo.suspendedMemberCount}`,
                header: 'Members With Failed Payments',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <div style={{ textAlign: "center" }}>
                        <Badge badgeContent={`${row.original.parentInfo.suspendedMemberCount}`} color={row.original.parentInfo.suspendedMemberCount === 0 ? 'primary' : 'success'} overlap="circular" max={9999}></Badge>
                    </div>
                ),
            },
            {
                accessorKey: 'Members Set to Cancel',
                accessorFn: (row) => `${row.parentInfo.retentionCount}`,
                header: 'Members Set to Cancel',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <div style={{ textAlign: "center" }}>
                        <Badge badgeContent={`${row.original.parentInfo.retentionCount}`} color={row.original.parentInfo.retentionCount === 0 ? 'primary' : 'success'} overlap="circular" max={9999}></Badge>
                    </div>
                ),
            },

        ]
    )
    //csv option start here
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Enrolled Members Report',
        headers: ['AID', 'Total Enrolled Members', 'Members With Failed Payments', 'Members Set to Cancel'],
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportData = (croLead) => {
        const exportedData = [];
        
        croLead.forEach((row) => {
          const mainTableRow = {
            aid: `${row.original.parentInfo.aid}` || 'N/A',
            total_enrolled_member: `${row.original.parentInfo.membercount}` || 'N/A',
            member_failed: `${row.original.parentInfo.suspendedMemberCount}` || 'N/A',
            member_set: `${row.original.parentInfo.retentionCount}` || 'N/A',
          };
      
          // Expanded Table Data (Referred Affiliate)
          exportedData.push(mainTableRow);
      
          // Add subheaders for child data
          const subheader = {
            'Name': 'Name',
            'Status': 'Status',
            'Phone': 'Phone',
            'Email': 'Email',
          };
          exportedData.push(subheader);
      
          if (row.original.parentInfo.childInfo) {
            row.original.parentInfo.childInfo.forEach((option) => {
              const subRow = {
                name: option.name || 'N/A',
                status: option.account_status || 'N/A',
                phone_number: option.signup_reports ? option.signup_reports.phone_number || 'N/A' : 'N/A',
                email: option.email || 'N/A',
              };
              exportedData.push(subRow);
            });
          } else {
            // If no child data available, add an empty row for subrows
            exportedData.push({});
          }
          exportedData.push({});
        });

        const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted

        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }
        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };
    //csv end here
    const ContentLayout= <>
                <Grid container item lg={12} md={12} xs={12}>
                    <Typography level='h4' color='primary'>Enrolled Members Report</Typography>
                </Grid>

                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                        <FormControl fullWidth size='small' margin='dense'>
                            <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value="date"
                                input={<OutlinedInput label="Select Date Range" />}
                            >
                                <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                                <DateRangePicker
                                    className='custom-date-picker'
                                    onChange={item => setSelectionRange([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={selectionRange}
                                    maxDate={new Date()}
                                    direction="horizontal"
                                    staticRanges={staticRanges}
                                />
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6} mt={1}>
                        <Button variant="contained" sx={{ minWidth: 150 }} onClick={getEnrolledMember}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
                <MaterialReactTable
                    columns={columns}
                    data={croLead}
                    enableColumnFilterModes
                    enableRowSelection={false}
                    filterFns={{
                        customSearchFilterFn: (row, id, filterValue) =>
                        row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                       }} 
                    globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                    onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                    state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                    initialState={{ showColumnFilters: false, density: 'compact' }}
                    onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                    enableColumnFilters={config.DatatableColumnFilter}
                    positionToolbarAlertBanner="bottom"
                    enableDensityToggle={false}
                    muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                            backgroundColor: "#F6FAFF",
                        },
                    }}
                    //top csv option
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                        >
                            <Button
                                color="primary"
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() =>
										handleExportData(table.getPrePaginationRowModel().rows)
									}
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                startIcon={<FileDownloadIcon />}
                                variant='contained'
                            >
                                CSV
                            </Button>

                        </Box>
                    )
                    }
                    renderDetailPanel={({ row }) => (
                        <Table  borderAxis="both">
                        <thead>
                            <tr>
                                <th sx={{ fontWeight: "bold" }}>Name</th>
                                <th sx={{ fontWeight: "bold" }} align="center">Status</th>
                                <th sx={{ fontWeight: "bold" }}>Phone</th>
                                <th sx={{ fontWeight: "bold" }} align="center">Email</th>
                                <th sx={{ fontWeight: "bold" }} align="center">Password</th>
                                <th sx={{ fontWeight: "bold" }} align="center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {row.original.parentInfo.childInfo.map((option) => (
                                <tr key={option.id}>
                                    <td>
                                        {option.name ? option.name : ''}
                                    </td>
                                    <td>
                                        {option.account_status ? option.account_status : ''}
                                    </td> 
                                    <td>
                                        {option.signup_reports ? option.signup_reports.phone_number : ''}                                        </td>
                                    <td>
                                        {option.email ? option.email : ''}
                                    </td>
                                    <td>
                                    {
                                         option.signup_reports != null ? 
                                         <>
                                          <Box>
                                            {passwordVisibility[option.signup_reports.id] ? getPasswordString(option.signup_reports.password) : decodeBase64(option.signup_reports.password) }
                                            {
                                           
                                                `${decodeBase64(option.signup_reports.password).length}` > 0 
                                                ?
                                                    <>
                                                    {passwordVisibility[option.signup_reports.id] ?
                                                    <VisibilityOffIcon style={{marginRight: "10%"}} onClick={() => togglePasswordVisibility(option.signup_reports.id) }/> 
                                                    :
                                                    <VisibilityIcon style={{marginRight: "10%"}} onClick={() => togglePasswordVisibility(option.signup_reports.id) }/> }
                                                    </>
                                                : 
                                                ''
                                           
                                            }
                                        </Box>
                                        
                                         </>
                                                :
                                                null                                                
                                        }
        
                                    </td>
                                    <td>
                                    
                                        
                                        {
                                         option.signup_reports != null ? 
                                            option.signup_reports.password ?
                                            <>
                                                <form method="POST" action={config.API_URL+'/api/member/view3B'} target="_blank">
                                                    <input type="hidden" name="username" value={btoa(option.email)} />
                                                    <input type="hidden" name="password" value={option.signup_reports.password} />   
                                                    <Tooltip title="View 3B" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../../assets/icons/view3B.png')} /></button></Tooltip>
                                                </form>
                                            </>
                                            // <Tooltip title="View 3B" arrow>
                                            // <Link
                                            //     to={config.API_URL+'/member/view3B/'+btoa(option.email)+'/'+option.signup_reports.password}
                                            //     target="_blank"
                                            //     >
                                            //     <img src={require('../../../assets/icons/view3B.png')} alt="View 3B" />
                                            //     </Link>
                                            //     </Tooltip>
                                                :
                                                null
                                                :
                                                null                                                
                                        }
                                         {
                                         option.signup_reports != null ? 
                                            option.signup_reports.password ?
                                            <>
                                                <form method="POST" action={config.API_URL+'/api/member/epicproreport'} target="_blank">
                                                    <input type="hidden" name="companyId" value={UserId} />
                                                    <input type="hidden" name="username" value={btoa(option.email)} />
                                                    <input type="hidden" name="password" value={option.signup_reports.password} />   
                                                    <Tooltip title="View Epic Pro Report" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../../assets/icons/EPR.png')} /></button></Tooltip>
                                                </form>
                                            </>
                                            // <Tooltip title="View Epic Pro Report" arrow>
        
                                            // <Link
                                            //     to={config.API_URL+'/member/epicproreport/'+UserId+'/'+btoa(option.email)+'/'+option.signup_reports.password+'?partnerCheck='+partnerCheck} 
                                            //     target="_blank"
                                            //     >
                                            // <img src={require('../../../assets/icons/EPR.png')} />
                                            // </Link>
                                            //     </Tooltip>
                                                :
                                                null
                                                :
                                                null                                                
                                        }
                                    
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    )}
                />
                {/* loader code start */}
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={freez}
                        onClick={handleFreezStop}
                    >
                        <Freez />
                    </Backdrop>
                </div>
                {/* loader code end */}

            </>
       
    return (
        <div>
            {isLoading ? <Loader /> : <></>}
            <DefaultLayout content={ContentLayout} />
        </div>
    );
}


export default ContactTypeConfig;