import React from 'react';
import {  Link } from 'react-router-dom';
import { Box, Grid, Tooltip, Button } from '@mui/material';
import { Typography } from '@mui/joy';
import BackIcon from '@mui/icons-material/HighlightOff';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailIcon from '@mui/icons-material/Email';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
function ContactUs(props) {
    const closeTab = () => {
        props.closeVal(false)
    }
    return (
        <>
            <Box sx={{ minHeight: 300, maxHeight: 500, borderTop: "3px solid #5288db" }}>
                <Box>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "flex-end" }} p={2}>
                        <Tooltip title="Close" >
                            <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={closeTab} />
                        </Tooltip>
                    </Grid>
                </Box>
                <Box sx={{ justifyContent: "center", marginLeft: "10%", marginRight: "10%" }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="h2" sx={{ borderBottom: "3px solid #5288db" }}>Contact Us</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <Typography level="h4">We are here to help!</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Grid item lg={6} md={6} sm={6} xs={6} >
                            <LocalPhoneRoundedIcon color="primary" sx={{ fontSize: "4rem" ,verticalAlign: "top"  }} />&nbsp;&nbsp;&nbsp;
                            <Typography level="body1" fontSize="lg" sx={{ display: "inline-block" }}>
                                <b>Call Us:</b>
                                <br />
                                1-888-548-2008
                                <br />
                                Monday thru Friday, 8am to 9pm (ET)
                                <br />
                                Saturday and Sunday, 9am to 6pm (ET)
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                        <a href="mailto:customersupport@myfreescorenow.com"><EmailIcon color="primary" sx={{ justifyContent: "center", fontSize: "4rem", verticalAlign: "top"  }} mt={2} /></a>&nbsp;&nbsp;&nbsp;
                            <Typography level="body1" fontSize="lg" sx={{ display: "inline-block" }}>
                                <b><a href="mailto:customersupport@myfreescorenow.com"  style={{color:"black"}}>Email Us:</a></b>
                                <br />
                                customersupport@myfreescorenow.com
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <MailOutlineIcon color="primary" sx={{ fontSize: "4rem" ,verticalAlign: "top" }} />&nbsp;&nbsp;&nbsp;
                            <Typography level="body1" fontSize="lg" sx={{ display: "inline-block" }}>
                                <b>Write Us:</b>
                                <br/>
                                MyFreeScoreNow Inc.
                                <br />
                                PO Box 4798
                                <br />
                                Seattle, WA 98194
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                       <Box sx={{display:'flex'}}>
                       <a href="/faq" ><ForumRoundedIcon color="primary" sx={{ justifyContent: "center",fontSize: "4rem" ,verticalAlign: "top",marginTop:'10px' }} /></a>&nbsp;&nbsp;&nbsp;
                            <p style={{ display: "inline-block"}}>
                                <b><a href="/faq" style={{color:"black"}}>FAQ:</a></b>
                                <br />
                                Please check Frequently Asked Questions to find answers to
                                <br />
                                your questions about our services, benefits, billing questions,
                                how to cancel,
                                <br />
                                and much more.
                            </p>
                       </Box>
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                        <a href="/register"><img src={require("../../assets/images/affilcontact.png")} sx={{ fontSize: "4rem" }} /></a>&nbsp;&nbsp;&nbsp;
                            <Typography level="body1" fontSize="lg" sx={{ display: "inline-block" }}>
                                <b><a href="/register" style={{color:"black"}}>Affiliate Program</a></b>
                                <br />
                                It's easy. Refer and earn commission when visitors
                                <br />
                                try and/or sign-up for our valuable services and programs.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <Link to='https://member.myfreescorenow.com/login/' style={{ textDecoration: 'none' }}>
                            <Button variant='contained' color="primary" size="large" sx={{ minWidth: 150 }}>Login to Your Membership</Button>
                        </Link>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <Typography level="body1" fontSize="lg">MyFreeScoreNow cannot correct inaccuracies on your credit report. Please contact the credit bureaus directly.</Typography>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={3} mb={5}>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <img src={require("../../assets/images/equifax.png")} />
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                <Typography level="body1" fontSize="lg" >Equifax Information Service Center</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg" >(800) 203-7843 <br />P.O. Box 740241 <br />Atlanta, GA 30374</Typography>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <img src={require("../../assets/images/transunion.png")} />
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                <Typography level="body1" fontSize="lg" >TransUnion Corporation <br />(800) 916-8800 (Option 3)</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg" >PO Box 2000 <br />Chester, PA 19016-2000</Typography>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <img src={require("../../assets/images/experian.png")} />
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                <Typography level="body1" fontSize="lg" >Experian Information Solutions, Inc. <br />(888) 397-3742 or (714) 830-7000</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="body1" fontSize="lg" >P.O. Box 2002 <br />Allen, TX 75013</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}
export default ContactUs;
