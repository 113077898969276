// IMPORTANT :- *****Do not change the config array set mode according to envoirnment*****

var mode = "production";
// var mode = "staging";
// var mode = "dev";
// var mode = "localProduction";
// var mode = "local";

const configObj =
mode == "dev" ? 
{
	BASE_URL:'http://54.67.80.194:3000/',
	API_BASE_URL: 'http://54.67.80.194/newmfsn/backend/api',
	API_URL: 'http://54.67.80.194/newmfsn/backend',
	BACKEND_URL: 'http://54.67.80.194/newmfsn/backend/public/',
}
: 
mode == "staging" ?
{
    BASE_URL:'http://staging.myfreescorenow.com:3000/',
    API_BASE_URL: 'http://54.193.66.206/newmfsn/backend/api',
    API_URL: 'http://54.193.66.206/newmfsn/backend',
    BACKEND_URL: 'http://54.193.66.206/newmfsn/backend/public/',
}
:
mode == "production" ?
{
    BASE_URL:'https://myfreescorenow.com/',
    API_BASE_URL: 'https://api.myfreescorenow.com/api',
    API_URL: 'https://api.myfreescorenow.com',
    BACKEND_URL: 'https://api.myfreescorenow.com/public/',
}
:
mode == "localProduction" ?
{
    BASE_URL:'http://localhost:3000/',
    API_BASE_URL: 'https://api.myfreescorenow.com/api',
    API_URL: 'http://localhost:8000',
    BACKEND_URL: 'https://api.myfreescorenow.com/public/',
}
:
{
    BASE_URL:'http://localhost:3000/',
    API_BASE_URL: 'http://localhost:8000/api',
    API_URL: 'http://localhost:8000',
    BACKEND_URL: 'http://localhost:8000/',
} 

const config = {
    BASE_URL: configObj.BASE_URL,
	API_BASE_URL: configObj.API_BASE_URL,
	API_URL: configObj.API_URL,
	BACKEND_URL: configObj.BACKEND_URL,
    MEMBER_URL:'https://member.myfreescorenow.com/',
    DatatableColumnFilter: false,
    MasterPassword: 'Mfsn1234!',
    CallHippoDialer: 'https://dialer.callhippo.com/dial?to=',
}


export default config;