import React, { useState, useEffect } from "react";
import Layout from '../EpicProReport/Layout';
import { Typography, Card, CardContent, CardOverflow, Table, Sheet } from '@mui/joy';
import { Button, Grid, Chip, FormControlLabel, Box, InputAdornment, Radio, InputLabel, TextField, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import SearchIcon from "@mui/icons-material/Search";
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Drawer from '@mui/material/Drawer';
import Header from "./Header";
//collapsable table start here
const drawerWidth = 300;
function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        // protein,
        // price,
        history: [
            {
                date: 'PACIFICUNION',
                customerId: '8363101248343345',
            },
            {
                date: 'PACIFICUNION',
                customerId: '8363101248343345',
            },
        ],
    };
}
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(props.initialOpen || false);
    return (
        <React.Fragment>
            <tr>
                <td>
                    <IconButton
                        aria-label="expand row"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </td>
                <th scope="row">{row.name}</th>
                <td>{row.calories}</td>
                <td>{row.fat}</td>
                <td>{row.carbs}</td>
            </tr>
            <tr>
                <td style={{ height: 0, padding: 0 }} colSpan={5}>
                    {open && (
                        <Sheet
                            variant="soft"
                            sx={{ p: 1, pl: 6, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)' }}
                        >
                            <Table
                                borderAxis="bothBetween"
                                size="sm"
                                aria-label="purchases"
                                sx={{
                                    '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                        { textAlign: 'right' },
                                    '--TableCell-paddingX': '0.5rem',
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th><b>Creditor</b></th>
                                        <th><b>Account Id</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {row.history.map((historyRow) => (
                                        <tr key={historyRow.date}>
                                            <th scope="row">{historyRow.date}</th>
                                            <td>{historyRow.customerId}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Sheet>
                    )}
                </td>
            </tr>
        </React.Fragment>
    );
}

Row.propTypes = {
    initialOpen: PropTypes.bool,
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

const rows = [
    createData(<><span className='accountFont1'>Positive</span> <span className='accountFont2'>AutoLoan</span> <span><Chip label="open accounts" size="small" color="success" className='chipepic' /></span></>, 1, 1, 1),
    createData(<><span className='accountFont1'>Positive</span> <span className='accountFont3'>Educational</span><span><Chip label="open accounts" size="small" color="success"  className='chipepic'/></span></>, 3, 3, 3),
    createData(<><span className='accountFont1'>Positive</span> <span className='accountFont4'>Other</span><span><Chip label="open accounts" size="small" color="primary" className='chipepic'/></span></>, 3, 3, 3),
    createData(<><span className='accountFont1'>Positive</span> <span className='accountFont5'>Mortgage</span><span><Chip label="open accounts" size="small" className='chipwarn chipepic'/></span></>, 3, 3, 3),
];
//end here
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
function EpicProreport(props) {
    const [searchTerm, setSearchTerm] = useState("");
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const [expanded, setExpanded] = React.useState([]);
    // const [selected, setSelected] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    // const handleSelect = (event, nodeIds) => {
    //     setSelected(nodeIds);
    // };

    const handleExpandClick = () => {
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? ['1', '2', '6', '10', '11', '12', '15', '18', '19', '21', '23', '27', '28', '30', '32'] : [],
        );
    };

    // const handleSelectClick = () => {
    //     setSelected((oldSelected) =>
    //         oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : [],
    //     );
    // };
    // const [expanded, setExpanded] = React.useState(false);

    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };
    function EpicProLayout() {
        return (
            <>
            <div id="app" style={({ height: "100vh" }, { display: "flex" })}>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            padding: "2%",
                        },
                    }}
                    variant="permanent"
                    anchor="left">
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={{ mt: 1 }}>
                        <Button>
                            GO To Top
                        </Button>
                    </Box>
                    <Box sx={{ mb: 1 }}>

                        <Button onClick={handleExpandClick}>
                            {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
                        </Button>
                        {/* <Button onClick={handleSelectClick}>
                        {selected.length === 0 ? 'Select all' : 'Unselect all'}
                    </Button> */}
                    </Box>
                    <TreeView
                        aria-label="controlled"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={expanded}
                        // selected={selected}
                        onNodeToggle={handleToggle}
                        // onNodeSelect={handleSelect}
                        multiSelect>
                        <TreeItem label="Personal Information">
                      
                        </TreeItem>
                        <TreeItem label="Report Summary">
                        </TreeItem>
                        <TreeItem label="Account Summary">
                        </TreeItem>
                        <TreeItem nodeId="1" label="Positive Accounts(16)">
                            <TreeItem nodeId="2" label="open" >
                                <TreeItem nodeId="3" label="Automative" color='secondary'/>
                                <TreeItem nodeId="4" label="Educational" />
                                <TreeItem nodeId="5" label="Others" />
                            </TreeItem>
                            <TreeItem nodeId="6" label="Closed" >
                                <TreeItem nodeId="7" label="Mortgage" />
                                <TreeItem nodeId="8" label="educational" />
                                <TreeItem nodeId="9" label="Others" />
                            </TreeItem>
                        </TreeItem>
                        <TreeItem nodeId="10" label="Negative Accounts(23)">
                            <TreeItem nodeId="11" label="open(7)" >
                                <TreeItem nodeId="12" label="Educational (3)">
                                    <TreeItem nodeId="13" label="Derogatory(1)" />
                                    <TreeItem nodeId="14" label="Delinquent(2)" />
                                </TreeItem>
                                <TreeItem nodeId="15" label="Others (4)">
                                    <TreeItem nodeId="16" label="coll/chargeOff(3)" />
                                    <TreeItem nodeId="17" label="Delinquent(1)" />
                                </TreeItem>
                            </TreeItem>
                            <TreeItem nodeId="18" label="Closed(13)">
                                <TreeItem nodeId="19" label="Mortgage">
                                    <TreeItem nodeId="20" label="Coll/ChargeOff (1)" />
                                </TreeItem>
                                <TreeItem nodeId="21" label="Automotive">
                                    <TreeItem nodeId="22" label="Coll/ChargeOff (1)"></TreeItem>
                                </TreeItem>
                                <TreeItem nodeId="23" label="Others (10)">
                                    <TreeItem nodeId="24" label="Coll/ChargeOff (8)" />
                                    <TreeItem nodeId="25" label="Derogatory(1)" />
                                    <TreeItem nodeId="26" label="Delinquent(1)" />
                                </TreeItem>
                            </TreeItem>
                            <TreeItem nodeId="27" label="Mixed(3)">
                                <TreeItem nodeId="28" label="Medical(2)">
                                    <TreeItem nodeId="29" label="Coll/ChargeOff (8)" />
                                </TreeItem>
                                <TreeItem nodeId="30" label="Others(1)">
                                    <TreeItem nodeId="31" label="Delinquent(1)" />
                                </TreeItem>
                            </TreeItem>
                        </TreeItem>
                        <TreeItem label="Public Records">
                        </TreeItem>
                        <TreeItem nodeId="32" label="Inquiries(18)">
                            <TreeItem nodeId="33" label="Bad(17)" />
                            <TreeItem nodeId="34" label="Good(1)" />
                        </TreeItem>
                        <TreeItem label="Creditor Contacts">
                        </TreeItem>
                    </TreeView>
                    <Box sx={{ mb: 1 }}>
                    <Button variant="contained" color="success" sx={{ backgroundColor: "#76C044", color: "white",minWidth:150,maxWidth:350 }}>
                        Show Negatives Only
                    </Button>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                    <Button variant="contained" color="primary" sx={{ backgroundColor: "#2A68B3", color: "white",minWidth:"210px !important",maxWidth:350}}>
                        View Comments
                    </Button>
                    </Box>
                </Drawer>
                <main>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }}>
                        <CardOverflow sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                            padding: '1%',
                        }}
                        >
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                            <Typography level='h2' color="primary">
                                Your 3-Bureau Credit Report & Scores is 186 days old.
                            </Typography>
                            <span style={{ color: "black" }}>Print<LocalPrintshopOutlinedIcon /></span>
                        </Grid>
                        </CardOverflow>
                        <CardContent>
                            <Typography level="h6">Vantage Score® 3.0 credit score</Typography>
                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={6}>                            
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Card sx={{ justifyContent: "center"}}>
                                        <CardOverflow sx={{ backgroundColor: "#00A6CA" }}>
                                            <Typography sx={{ textAlign: "center",color:"white" }}>TransUnion®</Typography>
                                        </CardOverflow>
                                        <CardContent>
                                            <Typography sx={{ textAlign: "center" }}>607</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Card sx={{ justifyContent: "center" }}>
                                        <CardOverflow sx={{ backgroundColor: "#044993" }}>
                                            <Typography sx={{ textAlign: "center",color:"white" }}>Experian®</Typography>
                                        </CardOverflow>
                                        <CardContent>
                                            <Typography sx={{ textAlign: "center" }}>607</Typography>
                                        </CardContent>

                                    </Card>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}>
                                    <Card sx={{ justifyContent: "center" }}>
                                        <CardOverflow sx={{ backgroundColor: "#971D31" }}>
                                            <Typography sx={{ textAlign: "center",color:"white" }}>Equifax®</Typography>
                                        </CardOverflow>
                                        <CardContent>
                                            <Typography sx={{ textAlign: "center" }}>607</Typography>
                                        </CardContent>

                                    </Card>
                                </Grid>
                            </Grid>    
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }} variant="outlined">
                        <CardOverflow sx={{  backgroundColor: "#0073EF", padding: '1%' }}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography sx={{ color: "white" }}>
                                Personal Identifier Information
                            </Typography>
                            </Grid>
                        </CardOverflow>
                        <Table aria-label="basic table">
                            <thead>
                                <tr>
                                    <th style={{ width: '40%' }}></th>
                                    <th  className="epicgreen">TransUnion</th>
                                    <th className='epicblue'>Experian</th>
                                    <th className='epicred'>Equifax</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>Date of Credit Report :</b></td>
                                    <td>09/23/2022</td>
                                    <td>09/23/2022</td>
                                    <td>09/23/2022</td>
                                </tr>
                                <tr>
                                    <td><b>Consumer's Primary Name</b></td>
                                    <td>JOHN SMITH</td>
                                    <td>JOHN SMITH</td>
                                    <td>JOHN SMITH</td>

                                </tr>
                                <tr>
                                    <td><b>Consumer's Other Name(s):</b></td>
                                    <td>SMITH JOHN</td>
                                    <td>SMITH JOHN</td>
                                    <td>..</td>

                                </tr>
                                <tr>
                                    <td><b>Consumer's Current Residence:   </b></td>
                                    <td>443 PINE GARDEN LANE<br /> ATLANTA, GA 30339</td>
                                    <td>2353 MUSGRAVE STREET <br /> ATLANTA, GA 303121279 <br />Date Updated: 09/2022</td>
                                    <td>2324 CLEMENT STREET <br />ATLANTA, GA 30303</td>
                                </tr>
                                <tr>
                                    <td><b>Consumer's Previous Residence:</b></td>
                                    <td>30336<br />30336</td>
                                    <td>303121279<br />Date Updated: 09/2022<br />303121279<br />Date Updated: 09/2022</td>
                                    <td>GA</td>

                                </tr>
                                <tr>
                                    <td><b>Consumer's Reported Employer(s):</b></td>
                                    <td>HOME DEPOT<br />Date Updated: 07/2017<br />ERNST & YOUNG LLP<br />Date Updated: 02/2015</td>
                                    <td>-</td>
                                    <td>-</td>

                                </tr>
                                <tr>
                                    <td><b>Consumer's Reported SSN:</b></td>
                                    <td>4107</td>
                                    <td>4107</td>
                                    <td>4107</td>

                                </tr>
                            </tbody>
                        </Table>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }}>
                    <Card sx={{ width: "100%" }} variant="outlined"  >
                        <CardOverflow
                            sx={{
                                bgcolor: '#0073EF',
                                borderColor: '#0073EF',
                                padding: '1%',
                                color: "white"
                            }}
                        >
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={{ color: "white" }} > Consumer Statement</Typography>
                                <ExpandMore
                                    expand={expanded}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon sx={{ color: "white" }} />
                                </ExpandMore>
                            </Grid>
                        </CardOverflow>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Grid container>
                                    <Table aria-label="basic table">
                                        <tr>
                                            <td>TransUnion</td>
                                            <td>#HK#EFCRA EXTENDED FRAUD ALERT: ACTION MAY BE REQUIRED UNDER FCRA BEFORE OPENING OR MODIFYING AN ACCOUNT. CONTACT CONSUMER AT (470) 421-5485 (727) 313-1723</td>
                                        </tr>
                                        <tr>
                                            <td>Experian</td>
                                            <td>06& 08-15-22 0000000 ID FRAUD VICTIM ALERT FRAUDULENT APPLICATIONS MAY BE SUBMITTED IN MY NAME OR MY IDENTITY MAY HAVE BEEN USED WITHOUT MY CONSENT TO FRAUDULENTLY OBTAIN GOODS OR SERVICES. DO NOT EXTEND CREDIT WITHOUT FIRST CONTACTING ME PERSONALLY AND VERIFYING ALL APPLICATION INFORMATION AT DAY 727-804-9797. THIS VICTIM ALERT WILL BE MAINTAINED FOR SEVEN YEARS BEGINNING 08-15-22.</td>
                                        </tr>
                                        <tr>
                                            <td>Equifax</td>
                                            <td>FRAUD VICTIM. 'EXTENDED ALERT'. CONSUMER HAS REQUESTED AN ALERT BE PLACED ON THEIR CREDIT FILE. DAYTIME 00001 7273131723 EX-00000 EVENING 00001 4704215485 EX-00000</td>
                                        </tr>
                                    </Table>
                                </Grid>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }} variant="outlined">
                    <CardOverflow
                     sx={{backgroundColor: "#0073EF",padding: '1%', }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography sx={{ color: "white" }}>
                                Report Summary
                            </Typography>
                        </Grid>
                    </CardOverflow>
                        <Table aria-label="basic table">

                            <thead>
                                <tr>
                                    <th style={{ width: '40%' }}></th>
                                    <th className="epicgreen">TransUnion</th>
                                    <th className='epicblue'>Experian</th>
                                    <th className='epicred'>Equifax</th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td><b>TOTAL ACCOUNTS:</b></td>
                                    <td>14</td>
                                    <td>22</td>
                                    <td>10</td>
                                </tr>
                                <tr>
                                    <td><b>CLOSED ACCOUNTS:</b></td>
                                    <td>6</td>
                                    <td>13</td>
                                    <td>6</td>

                                </tr>
                                <tr>
                                    <td><b>CLOSED ACCOUNTS:</b></td>
                                    <td>0</td>
                                    <td>2</td>
                                    <td>1</td>

                                </tr>
                                <tr>
                                    <td><b>DEROGATORY:</b></td>
                                    <td>2</td>
                                    <td>8</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td><b>BALANCES:</b></td>
                                    <td>$56766</td>
                                    <td>$128180</td>
                                    <td>$120169</td>

                                </tr>
                                <tr>
                                    <td><b>PAYMENTS:</b></td>
                                    <td>$1189</td>
                                    <td>$2146</td>
                                    <td>$1150</td>

                                </tr>
                                <tr>
                                    <td><b>PUBLIC RECORDS:</b></td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>1</td>

                                </tr>
                                <tr>
                                    <td><b>INQUIRIES(2 years):</b></td>
                                    <td>8</td>
                                    <td>4</td>
                                    <td>1</td>

                                </tr>
                            </tbody>
                        </Table>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }} variant="outlined"  >
                        <CardOverflow
                            sx={{
                                bgcolor: '#0073EF',
                                borderColor: '#0073EF',
                                padding: '1%',
                                color: "white"
                            }}
                        >
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={{ color: "white" }} > Accounts Summary</Typography>
                                <a href="" style={{ color: "white" }}>Show all accounts</a>
                            </Grid>
                        </CardOverflow>
                        <CardContent>
                            <Sheet>
                                <Table
                                    aria-label="collapsible table"
                                    sx={{
                                        '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                                            { textAlign: 'right' },
                                        '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
                                        {
                                            borderBottom: 0,
                                        },
                                    }}
                                >
                                    <thead>
                                        <tr>
                                            <th aria-label="empty" />
                                            <th ></th>
                                            <th className='epicgreen'>TransUnion</th>
                                            <th className='epicblue'>Experian</th>
                                            <th className='epicred'>Equifax</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <Row key={row.name} row={row} initialOpen={index === 0} />
                                        ))}
                                    </tbody>
                                </Table>
                            </Sheet>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }} variant="outlined" >
                        <CardOverflow
                            sx={{
                                bgcolor: '#0073EF',
                                borderColor: '#0073EF',
                                padding: '1%',
                                color: "white"
                            }}
                        >
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography sx={{ color: "white" }} > Account History</Typography>

                            </Grid>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography level="h6">At-a-glance viewing of your payment history</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <img src={require('../../../assets/images/EPRLogo.png')} />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }}>
                        <CardOverflow sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                            padding: '1%',
                        }}
                        >
                            <Typography><Chip label="open" color="success" size="small" className='chipepic'/><span className='accountFont1'>Positive</span> <span> Accounts:</span></Typography>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                                <Typography sx={{ color: "#9400D3" }}>AUTO/VEHICLE-related account</Typography>
                                <FormControlLabel control={<Checkbox defaultChecked />} label="ENABLE COMMENTS" />
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Table>
                                    <tr>
                                        <td><b>Data Reporter Name</b></td>
                                        <td><b>CREDIT PREMIER BANK</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Data Reporter Street Address</b></td>
                                        <td><b>PO BOX 291234</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Data Reporter City & Zip</b></td>
                                        <td><b>PHOENIX 85123</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Data Reporter Telephone No.</b></td>
                                        <td><b>8008434321</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Data Reporter Identification No.</b></td>
                                        <td><b>01SFX004</b></td>
                                    </tr>
                                </Table>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td className='epicgreen'>TransUnion</td>
                                            <td className='epicblue'>Experian</td>
                                            <td className='epicred'>Equifax</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>Account Name #:</b></td>
                                            <td>20016123****</td>
                                            <td>20016123****</td>
                                            <td>20016123****</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Cycle Identifier:</b></td>
                                            <td>Monthly (every month)</td>
                                            <td>..</td>
                                            <td>..</td>
                                        </tr>
                                        <tr>
                                            <td><b>Date Account Reported:</b></td>
                                            <td>08/31/2022</td>
                                            <td>08/31/2022</td>
                                            <td>08/31/2022</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Portfolio Type:</b></td>
                                            <td>Auto Loan</td>
                                            <td>Auto Loan</td>
                                            <td>Auto Loan</td>
                                        </tr>
                                        <tr>
                                            <td><b>Current Account Balance:</b></td>
                                            <td>$49173</td>
                                            <td>$49173</td>
                                            <td>$49173</td>
                                        </tr>
                                        <tr>
                                            <td><b>Date of Current Account Balance:</b></td>
                                            <td>08/31/2022</td>
                                            <td>08/31/2022</td>
                                            <td>08/31/2022</td>
                                        </tr>
                                        <tr>
                                            <td><b>Date of Account Opening:</b></td>
                                            <td>03/09/2022</td>
                                            <td>03/09/2022</td>
                                            <td>03/09/2022</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Ownership Type:</b></td>
                                            <td>Individual</td>
                                            <td>Individual</td>
                                            <td>Individual</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Status Information:</b></td>
                                            <td>Open</td>
                                            <td>Open</td>
                                            <td>Open</td>
                                        </tr>
                                        <tr>
                                            <td><b>Date of Account Status Information:</b></td>
                                            <td>08/31/2022</td>
                                            <td>08/31/2022</td>
                                            <td>08/31/2022</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Type:</b></td>
                                            <td>Auto Loan</td>
                                            <td>Auto Loan</td>
                                            <td>Auto Loan</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Dispute Status:</b></td>
                                            <td>Account not disputed</td>
                                            <td>Account not disputed</td>
                                            <td>Account not disputed</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Condition:</b></td>
                                            <td>Open</td>
                                            <td>Open</td>
                                            <td>Open</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Highest Balance Reported:</b></td>
                                            <td>$49,287</td>
                                            <td>$49,287</td>
                                            <td>$49,287</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Schedule Payment Amount:</b></td>
                                            <td>$49,287</td>
                                            <td>$49,287</td>
                                            <td>$49,287</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Last Payment Amount Reported Received:</b></td>
                                            <td>$49,287</td>
                                            <td>$49,287</td>
                                            <td>$49,287</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Last Payment Date Reported:</b></td>
                                            <td>07/28/2022</td>
                                            <td>07/28/2022</td>
                                            <td>07/28/2022</td>
                                        </tr>
                                        <tr>
                                            <td><b>Last Verified:</b></td>
                                            <td>07/28/2022</td>
                                            <td>07/28/2022</td>
                                            <td>07/28/2022</td>
                                        </tr>
                                        <tr>
                                            <td><b>Date of Last Activity:</b></td>
                                            <td>07/28/2022</td>
                                            <td>07/28/2022</td>
                                            <td>07/28/2022</td>
                                        </tr>
                                        <tr>
                                            <td><b>Closed Date:</b></td>
                                            <td>...</td>
                                            <td>...</td>
                                            <td>...</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Rating:</b></td>
                                            <td>Open</td>
                                            <td>Open</td>
                                            <td>Open</td>
                                        </tr>
                                        <tr>
                                            <td><b>Creditor Type:</b></td>
                                            <td>Finance/Personal</td>
                                            <td>Auto Financing</td>
                                            <td>Auto Dealers, Used</td>
                                        </tr>
                                        <tr>
                                            <td><b>Account Status:</b></td>
                                            <td>Open</td>
                                            <td>Open</td>
                                            <td>Open</td>
                                        </tr>
                                        <tr>
                                            <td><b>Payment Status:</b></td>
                                            <td>Current</td>
                                            <td>Current</td>
                                            <td>Current</td>
                                        </tr>
                                        <tr>
                                            <td><b>Creditor Remarks:</b></td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td><b>Term Length: </b></td>
                                            <td>72</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td><b>Payment Frequency:</b></td>
                                            <td>Monthly (every month)</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td><b>Credit Limit:</b></td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }}>
                        <CardOverflow sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                            padding: '1%',
                        }}
                        >
                            <Typography>Two-Year Payment History</Typography>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography className="epicgreen">TransUnion</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Table borderAxis="none">
                                    <tr>
                                        <td>Aug 2020</td>
                                        <td>Sep2020</td>
                                        <td>oct 2020</td>
                                        <td>nov 2020</td>
                                        <td>dec 2020</td>
                                        <td>jan 2020</td>
                                        <td>feb 2020</td>
                                        <td>mar 2020</td>
                                        <td>apr 2020</td>
                                        <td>may 2020</td>
                                        <td>jun 2020</td>
                                        <td>jul 2020</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                    </tr>
                                    <tr>
                                        <td>Aug 2020</td>
                                        <td>Sep2020</td>
                                        <td>oct 2020</td>
                                        <td>nov 2020</td>
                                        <td>dec 2020</td>
                                        <td>jan 2020</td>
                                        <td>feb 2020</td>
                                        <td>mar 2020</td>
                                        <td>apr 2020</td>
                                        <td>may 2020</td>
                                        <td>jun 2020</td>
                                        <td>jul 2020</td>
                                    </tr>
                                </Table>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography className="epicblue">Experian</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Table borderAxis="none">
                                    <tr>
                                        <td>Aug 2020</td>
                                        <td>Sep2020</td>
                                        <td>oct 2020</td>
                                        <td>nov 2020</td>
                                        <td>dec 2020</td>
                                        <td>jan 2020</td>
                                        <td>feb 2020</td>
                                        <td>mar 2020</td>
                                        <td>apr 2020</td>
                                        <td>may 2020</td>
                                        <td>jun 2020</td>
                                        <td>jul 2020</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                    </tr>
                                    <tr>
                                        <td>Aug 2020</td>
                                        <td>Sep2020</td>
                                        <td>oct 2020</td>
                                        <td>nov 2020</td>
                                        <td>dec 2020</td>
                                        <td>jan 2020</td>
                                        <td>feb 2020</td>
                                        <td>mar 2020</td>
                                        <td>apr 2020</td>
                                        <td>may 2020</td>
                                        <td>jun 2020</td>
                                        <td>jul 2020</td>
                                    </tr>
                                </Table>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Typography className="epicred">Equifax</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Table borderAxis="none">
                                    <tr>
                                        <td>Aug 2020</td>
                                        <td>Sep2020</td>
                                        <td>oct 2020</td>
                                        <td>nov 2020</td>
                                        <td>dec 2020</td>
                                        <td>jan 2020</td>
                                        <td>feb 2020</td>
                                        <td>mar 2020</td>
                                        <td>apr 2020</td>
                                        <td>may 2020</td>
                                        <td>jun 2020</td>
                                        <td>jul 2020</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                        <td><Button variant="contained" color="success" size="small">OK</Button></td>
                                    </tr>
                                    <tr>
                                        <td>Aug 2020</td>
                                        <td>Sep2020</td>
                                        <td>oct 2020</td>
                                        <td>nov 2020</td>
                                        <td>dec 2020</td>
                                        <td>jan 2020</td>
                                        <td>feb 2020</td>
                                        <td>mar 2020</td>
                                        <td>apr 2020</td>
                                        <td>may 2020</td>
                                        <td>jun 2020</td>
                                        <td>jul 2020</td>
                                    </tr>
                                </Table>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Card sx={{ width: "100%" }} variant="outlined">
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',

                            }}>
                            <Typography>Days Late - 7 Year History 30/60/90 count</Typography>
                        </CardOverflow>
                        <CardContent>
                            <Table borderAxis="none">
                                <tr>
                                    <td colSpan={3} className='epicgreen'>TransUnion</td>
                                    <td colSpan={3} className='epicblue'>Experian</td>
                                    <td colSpan={3} className='epicred'>Equifax</td>
                                </tr>
                                <tr>
                                    <td>30: 0</td>
                                    <td>60: 0</td>
                                    <td>90: 0</td>
                                    <td>30: 0</td>
                                    <td>60: 0</td>
                                    <td>90: 0</td>
                                    <td>30: 0</td>
                                    <td>60: 0</td>
                                    <td>90: 0</td>
                                </tr>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} >
                    <Card>
                        <CardOverflow
                            sx={{
                                bgcolor: '#0073EF',
                                borderColor: '#0073EF',
                                padding: '1%',
                                color: "white"
                            }}>
                            <Typography  sx={{ color: "white" }}>Public Records</Typography>
                        </CardOverflow>
                        <CardContent>
                            <Table>
                                <thead>
                                    <tr>
                                        <td><b>Bankruptcy</b></td>
                                        <td className='epicgreen'>TransUnion</td>
                                        <td className='epicblue'>Experian</td>
                                        <td className='epicred'>Equifax</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>Name:</b></td>
                                        <td>U.S. Bankruptcy Court</td>
                                        <td>US BKPT CT GA ATLANTA</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Type:</b></td>
                                        <td>Chapter 13 Bankruptcy</td>
                                        <td>Chapter 13 Bankruptcy</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Status:</b></td>
                                        <td>Dismissed</td>
                                        <td>Dismissed</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Date Filed/Reported:</b></td>
                                        <td>2021-06-17</td>
                                        <td>2021-06-17</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Reference#:</b></td>
                                        <td>2154603</td>
                                        <td>2154603</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Closing Date:</b></td>
                                        <td>2021-12-30</td>
                                        <td>2021-12-30</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Asset Amount:</b></td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Court:</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Liability:</b></td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Exempt Amount:</b></td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><b>Remarks:</b></td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} >
                    <Card>
                        <CardOverflow
                            sx={{
                                bgcolor: '#0073EF',
                                borderColor: '#0073EF',
                                padding: '1%',
                                color: "white"
                            }}>
                            <Typography  sx={{ color: "white" }}>Inquiries (18)</Typography>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                                <Typography sx={{ color: "#8B0000" }}>
                                    Bad Inquiries (3)
                                </Typography>
                                <Typography>What does this mean?</Typography>
                            </Grid>
                            <Table>
                                <thead>
                                    <tr>
                                        <td><b>DR Name</b></td>
                                        <td><b>DR Address</b></td>
                                        <td><b>DR Contact No.</b></td>
                                        <td><b>DR Identification No.</b></td>
                                        <td><b>Date of Inquiry</b></td>
                                        <td><b>Permissible Purpose</b></td>
                                        <td><b>DR Industry Type</b></td>
                                        <td><b>Credit Bureau</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>DISCOVER FINANCIAL SER</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                        <td>B6</td>
                                        <td>09/05/2022</td>
                                        <td></td>
                                        <td>Bank Credit Cards</td>
                                        <td>Experian</td>
                                    </tr>
                                    <tr>
                                        <td>BK OF AMER</td>
                                        <td>PO BOX 982228EL<br />PASO TX 79919</td>
                                        <td>80042129881</td>
                                        <td>06199555</td>
                                        <td>06/02/2022</td>
                                        <td></td>
                                        <td>Bank Credit Cards</td>
                                        <td>TransUnion</td>
                                    </tr>
                                    <tr>
                                        <td>TRUIST</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                        <td>456BB12441</td>
                                        <td>06/02/2022</td>
                                        <td></td>
                                        <td>Bank Credit Cards</td>
                                        <td>Experian</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} >
                    <Card>
                        <CardOverflow
                            sx={{
                                bgcolor: '#0073EF',
                                borderColor: '#0073EF',
                                padding: '1%',
                                color: "white"
                            }}>
                            <Typography  sx={{ color: "white" }}>Creditor Contacts</Typography>
                        </CardOverflow>
                        <CardContent>
                            <Table>
                                <thead>
                                    <tr>
                                        <td><b>Creditor Name</b></td>
                                        <td><b>Address</b></td>
                                        <td><b>Phone Number</b></td>
                                        <td><b>Identification Number</b></td>
                                        <td><b>Industry Type</b></td>
                                        <td><b>Subscriber Numbers</b></td>
                                    </tr>
                                </thead> 
                                <tbody>
                                    <tr>
                                        <td>FIRST PREMIER BANK</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>6053573123</td>
                                        <td>00020123</td>
                                        <td>Bank</td>
                                        <td>00020123</td>
                                    </tr>
                                    <tr>
                                        <td>CREDIT PLUS</td>
                                        <td>2324 CLEMENT STREET<br/>ATLANTA, GA 30339</td>
                                        <td>8002583422</td>
                                        <td>00049997</td>
                                        <td>Miscellaneous</td>
                                        <td>00049997</td>
                                    </tr>
                                    <tr>
                                        <td>CREDIT PLUS/PANORAMA MOR</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>7025885431</td>
                                        <td>06335039</td>
                                        <td>Credit Union</td>
                                        <td>00020123</td>
                                    </tr>
                                    <tr>
                                        <td>FIRST PREMIER BANK</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>6053573123</td>
                                        <td>00020123</td>
                                        <td>Bank</td>
                                        <td>00020123</td>
                                    </tr>
                                    <tr>
                                        <td>FIRST PREMIER BANK</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>6053573123</td>
                                        <td>00020123</td>
                                        <td>Bank</td>
                                        <td>00020123</td>
                                    </tr>
                                    <tr>
                                        <td>CREDIT PLUS</td>
                                        <td>2324 CLEMENT STREET<br/>ATLANTA, GA 30339</td>
                                        <td>8002583422</td>
                                        <td>00049997</td>
                                        <td>Miscellaneous</td>
                                        <td>00049997</td>
                                    </tr>
                                    <tr>
                                        <td>CREDIT PLUS/PANORAMA MOR</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>7025885431</td>
                                        <td>06335039</td>
                                        <td>Credit Union</td>
                                        <td>00020123</td>
                                    </tr>
                                    <tr>
                                        <td>FIRST PREMIER BANK</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>6053573123</td>
                                        <td>00020123</td>
                                        <td>Bank</td>
                                        <td>00020123</td>
                                    </tr>
                                    <tr>
                                        <td>FIRST PREMIER BANK</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>6053573123</td>
                                        <td>00020123</td>
                                        <td>Bank</td>
                                        <td>00020123</td>
                                    </tr>
                                    <tr>
                                        <td>CREDIT PLUS</td>
                                        <td>2324 CLEMENT STREET<br/>ATLANTA, GA 30339</td>
                                        <td>8002583422</td>
                                        <td>00049997</td>
                                        <td>Miscellaneous</td>
                                        <td>00049997</td>
                                    </tr>
                                    <tr>
                                        <td>CREDIT PLUS/PANORAMA MOR</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>7025885431</td>
                                        <td>06335039</td>
                                        <td>Credit Union</td>
                                        <td>00020123</td>
                                    </tr>
                                    <tr>
                                        <td>FIRST PREMIER BANK</td>
                                        <td>443 PINE GARDEN LANE<br/>ATLANTA, GA 30339</td>
                                        <td>6053573123</td>
                                        <td>00020123</td>
                                        <td>Bank</td>
                                        <td>00020123</td>
                                    </tr>
                                </tbody> 
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                </main>
                </div>
            </>
        );
    }
    return (
        <div>
            <Layout content={<EpicProLayout />} />
        </div>
    );
}


export default EpicProreport;