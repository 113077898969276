
import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery, Grid, Checkbox, FormControlLabel, Button, TextField, Box, Typography, TableCell } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent } from '@mui/joy';
import { Table, TableBody, TableRow, TableHead } from "material-ui";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Formik } from 'formik';
import SignatureModal from '../SignatureModal/SignatureModal';
function AffiAgreement3({ formData, handleFormChange, errors, handleBlur, validationSchema1, signature, name, status }) {
    const theme = useTheme();
    const [showModal, setShowModal] = useState(false);
    const [signature1, setSignature1] = useState(null);
    const [signatureLabel, setSignatureLabel] = useState('Initials');
    const [firstName, setFirstName] = useState(name);
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const handleSaveSignature1 = (sig) => {
        handleFormChange('signature1', sig);
        setSignature1(sig);
        handleBlur('signature1', sig)
        closeModal();
    };
    useEffect(() => {
        setFirstName(name)
        setSignature1(formData.signature1)
    }, [name, signature])
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card padadoc-card1">
                    <CardContent>
                        <Typography variant="h6" mb={3}><u><b>Program Summary:</b></u></Typography>

                        <Typography variant="h6">
                            All Membership plans below include: 7-day Trial AND No Trial options,<span style={{ fontWeight: 'bold' }}>automatic order of your client’s 3-Bureau/3-Score Report (3B) </span>upon enrollment; daily credit monitoring; and a refresh of the 3B report every 30 days.
                        </Typography>

                        <Typography variant="h6">
                            Clients can purchase 3-Bureau Reports before their monthly refresh and in most cases, you’ll earn<span style={{ fontWeight: 'bold' }}>an extra $5 commission for each sale!</span>
                        </Typography><br />

                        <Typography variant="h6">
                            <span style={{ fontWeight: 'bold' }}>Don’t see what you’re looking for below?</span> Talk to us about creating custom plans.
                        </Typography><br />

                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            Below are the Membership Commission Plan(s) for the plans offered by MyFreeScoreNow! You will receive links for each of these six plans after successful registration which can be used to onboard your clients to the appropriate MyFreeScoreNow plan. </Typography><br />

                        <Grid item lg={12} md={12} sm={12} xs={12} mb={2} className="pandadoc-table-wrapper">
                            <Table className="pandadoc-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>STANDARD MEMBERSHIP PLANS</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>CHARGE TO CLIENT</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>AFFILIATE COMMISSIONS PAID TO YOU</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>INCLUDES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ color: '#FF0808', fontWeight: 'bold' }}>BRONZE</TableCell>
                                        <TableCell>$24.97/month</TableCell>
                                        <TableCell>
                                            <Grid sx={{ display: 'flex', margin: 'auto', fontWeight: 'bold', marginBottom: '10px' }}>  <CheckBoxIcon color="success" />
                                                <span style={{ paddingLeft: '10px' }}> $7.00/month with a $1.00 upfront charge and 7-day trial</span></Grid>
                                            <Grid sx={{ display: 'flex', margin: 'auto', fontWeight: 'bold' }}>  <CheckBoxIcon color="success" />
                                                <span style={{ paddingLeft: '10px' }}> $8.25/month With NO Trial*</span></Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6">- A 3-Bureau Report upfront and a refresh every 30 days</Typography>

                                            <Typography variant="h6">- 1 FREE membership for you!*</Typography>

                                            {/* <Typography variant="h6"> - Unlimited daily pulls of a 1-Bureau TransUnion Report and Score</Typography> */}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: 'rgb(136, 136, 136)', fontWeight: 'bold' }}>SILVER</TableCell>
                                        <TableCell>$29.90/month</TableCell>
                                        <TableCell>
                                            <Grid sx={{ display: 'flex', margin: 'auto', fontWeight: 'bold', marginBottom: '10px' }}>  <CheckBoxIcon color="success" />
                                                <span style={{ paddingLeft: '10px' }}> $11.00/month with a $1.00 upfront charge and 7-day trial</span></Grid>
                                            <Grid sx={{ display: 'flex', margin: 'auto', fontWeight: 'bold' }}>  <CheckBoxIcon color="success" />
                                                <span style={{ paddingLeft: '10px' }}> $12.25/month With NO Trial*</span></Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6">- A 3-Bureau Report upfront and a refresh every 30 days</Typography>

                                            <Typography variant="h6">- 1 FREE membership for you!*</Typography>

                                            {/* <Typography variant="h6"> - Unlimited daily pulls of a 1-Bureau TransUnion Report and Score</Typography> */}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: 'rgb(209, 181, 15)', fontWeight: 'bold' }}>GOLD</TableCell>
                                        <TableCell>$39.90/month</TableCell>
                                        <TableCell>
                                            <Grid sx={{ display: 'flex', margin: 'auto', fontWeight: 'bold', fontWeight: 'bold', marginBottom: '10px' }}>  <CheckBoxIcon color="success" />
                                                <span style={{ paddingLeft: '10px' }}> $15.00/month with a $1.00 upfront charge and 7-day trial</span></Grid>
                                            <Grid sx={{ display: 'flex', margin: 'auto', fontWeight: 'bold' }}>   <CheckBoxIcon color="success" />
                                                <span style={{ paddingLeft: '10px' }}> $16.00/month With NO Trial*</span></Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6">- A 3-Bureau Report upfront and a refresh every 30 days</Typography>

                                            <Typography variant="h6">- 1 FREE membership for you!*</Typography>

                                            {/* <Typography variant="h6"> - Unlimited daily pulls of a 1-Bureau TransUnion Report and Score</Typography> */}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ color: 'rgb(62, 102, 17)', fontWeight: 'bold' }}>ECONOMIC RECOVERY</TableCell>
                                        <TableCell>$19.95/month</TableCell>
                                        <TableCell>
                                            <Grid sx={{ display: 'flex', margin: 'auto', fontWeight: 'bold', marginBottom: '10px' }}>  <CheckBoxIcon color="success" />
                                                <span style={{ paddingLeft: '10px' }}> $0.00/month with a $1.00 upfront charge and 7-day trial</span></Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6">- A 3-Bureau Report upfront and a refresh every 30 days</Typography>

                                            <Typography variant="h6">- 1 FREE membership for you!*</Typography>

                                            {/* <Typography variant="h6"> - Unlimited daily pulls of a 1-Bureau TransUnion Report and Score</Typography> */}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Formik
                            initialValues={formData}
                            enableReinitialize={true} // This ensures that Formik updates the form values when formData changes
                        >

                            {({ handleChange }) => (
                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }} className="pandadoc-initialBlock">
                                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ alignSelf: 'center' }}>
                                        <Typography  sx={{ fontWeight: 'bold',color:'#1565c0',textAlign:'center' }}>TALK TO US ABOUT CREATING CUSTOM PLANS!</Typography>
                                        <FormControlLabel control={<Checkbox
                                            disabled={status == 1 ? true : false}
                                            name="planCheckbox"
                                            checked={formData.planCheckbox}
                                            onChange={(e) => {
                                                handleFormChange('planCheckbox', !formData.planCheckbox);
                                            }}
                                            onBlur={(e) => handleBlur('planCheckbox', !!formData.planCheckbox)}
                                            sx={{ fontWeight: 'bold' }}
                                        />} label={<Typography variant="h6" sx={{ fontWeight: 'bold' }}>I have reviewed the above membership plans and commission structure.</Typography>} />

                                        {errors.planCheckbox && (
                                            <Grid>
                                                <div className="error">{errors.planCheckbox}</div>
                                            </Grid>
                                        )}
                                    </Grid >
                                    {/* <Grid item lg={3} md={3} sm={12} xs={12} sx={{ alignSelf: 'center' }} className="padadoc-sign-field">
                                        <TextField
                                            name="signature1"
                                            error={Boolean(errors.signature1)}
                                            helperText={errors.signature1}
                                            size="small"
                                            sx={{ border: '0px !important', paddingLeft: '0px !important' }}
                                            InputProps={{
                                                readOnly: true,
                                                style: {
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100% !important',
                                                    borderRadius: '4px',
                                                    border: '0px !important',
                                                    paddingLeft: '0px !important'
                                                },
                                                startAdornment: (
                                                    <Box display="grid" width="100%" alignItems="center" gap={1}>
                                                        {formData.signature1 ? (
                                                            <Box
                                                                className="signature-preview"
                                                                onClick={() => {
                                                                    if (status != 1) {
                                                                        openModal();
                                                                    }
                                                                }} // Open modal on image click
                                                                style={{ cursor: 'pointer', padding: '5px' }}
                                                            >
                                                                <img
                                                                    src={formData.signature1}
                                                                    alt="Initials"
                                                                    style={{ width: '100%', height: 'auto' }}
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <Button
                                                                className="signature-button"
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                    if (status != 1) {
                                                                        openModal();
                                                                    }
                                                                }}
                                                            >
                                                                {signatureLabel}
                                                            </Button>
                                                        )}
                                                    </Box>
                                                ),
                                            }}
                                        />
                                        {showModal && <SignatureModal onSave={handleSaveSignature1} onClose={closeModal} firstName={firstName} />}
                                    </Grid> */}
                                </Grid>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Grid >
        </>
    );
}

export default AffiAgreement3;