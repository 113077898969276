import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Box, Grid, Button, Divider, Paper } from "@mui/material";
import LoginHeader from '../../Components/LoginHeader';
import { Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function Features() {
    const [autoplay, setAutoplay] = useState(true); 
    const handlePrev = () => {
        // Logic to move to the previous slide
        sliderRef.slickPrev(); // Assuming sliderRef is a reference to your Slider component
    };
    const handleSliderHover = () => {
        setAutoplay(false); // Pause autoplay when slider is hovered
    };

    const handleSliderLeave = () => {
        setAutoplay(true); // Resume autoplay when mouse leaves the slider
    };
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed: 3000,
        arrows: true,
        prevArrow: <button className="slick-prev" onClick={handlePrev}>Previous</button>,
        nextArrow: <button className="slick-next">Next</button>,
        onMouseEnter: handleSliderHover, // Pause autoplay on hover
        onMouseLeave: handleSliderLeave, // Resume autoplay when mouse leaves
    };
    let sliderRef; // Reference to the Slider component
    // scroll function start here
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    window.addEventListener('scroll', handleScroll);
    // scroll function end here
    // link though passing
    const [highlightedButton, setHighlightedButton] = useState(null);
    const btn1Ref = useRef(null);
    const btn2Ref = useRef(null);
    const btn3Ref = useRef(null);
    const btn4Ref = useRef(null);
    const btn5Ref = useRef(null);
    const btn6Ref = useRef(null);
    const btn7Ref = useRef(null);
    const btn8Ref = useRef(null);
    const btn9Ref = useRef(null);
    const btn10Ref = useRef(null);
    const btn11Ref = useRef(null);
    const btn12Ref = useRef(null);

    const handleButtonClick = (btnId) => {
        let targetRef;
        switch (btnId) {
            case 'btn1':
                targetRef = btn1Ref;
                break;
            case 'btn2':
                targetRef = btn2Ref;
                break;
            case 'btn3':
                targetRef = btn3Ref;
                break;
            case 'btn4':
                targetRef = btn4Ref;
                break;
            case 'btn5':
                targetRef = btn5Ref;
                break;
            case 'btn6':
                targetRef = btn6Ref;
                break;
            case 'btn7':
                targetRef = btn7Ref;
                break;
            case 'btn8':
                targetRef = btn8Ref;
                break;
            case 'btn9':
                targetRef = btn9Ref;
                break;
            case 'btn10':
                targetRef = btn10Ref;
                break;
            case 'btn11':
                targetRef = btn11Ref;
                break;
            case 'btn12':
                targetRef = btn12Ref;
                break;
            default:
                targetRef = null;
        }
        if (targetRef) {
            let yOffset = -220;
            const windowWidth = window.innerWidth;
            console.log(windowWidth)
            if (windowWidth < 800) {
              yOffset = -350;
            } else {
              yOffset = -220;
            }
      
            const y = targetRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            setHighlightedButton(btnId);
          }
    };
    // useeffect start here
    useEffect(() => {
        const handleScroll = () => {
          const btn1Top = btn1Ref.current.getBoundingClientRect().top;
          const btn2Top = btn2Ref.current.getBoundingClientRect().top;
          const btn3Top = btn3Ref.current.getBoundingClientRect().top;
          const btn4Top = btn4Ref.current.getBoundingClientRect().top;
          const btn5Top = btn5Ref.current.getBoundingClientRect().top;
          const btn6Top = btn6Ref.current.getBoundingClientRect().top;
          const btn7Top = btn7Ref.current.getBoundingClientRect().top;
          const btn8Top = btn8Ref.current.getBoundingClientRect().top;
          const btn9Top = btn9Ref.current.getBoundingClientRect().top;
          const btn10Top = btn10Ref.current.getBoundingClientRect().top;
          const btn11Top = btn11Ref.current.getBoundingClientRect().top;
          const btn12Top = btn12Ref.current.getBoundingClientRect().top;
    
    
          if (btn1Top >= 0 && btn1Top < window.innerHeight) {
            setHighlightedButton("btn1");
          } else if (btn2Top >= 0 && btn2Top < window.innerHeight) {
            setHighlightedButton("btn2");
          }
          else if (btn3Top >= 0 && btn3Top < window.innerHeight) {
            setHighlightedButton("btn3");
          }
          else if (btn4Top >= 0 && btn4Top < window.innerHeight) {
            setHighlightedButton("btn4");
          }
          else if (btn5Top >= 0 && btn5Top < window.innerHeight) {
            setHighlightedButton("btn5");
          }
          else if (btn6Top >= 0 && btn6Top < window.innerHeight) {
            setHighlightedButton("btn6");
          }
          else if (btn7Top >= 0 && btn7Top < window.innerHeight) {
            setHighlightedButton("btn7");
          }
          else if (btn8Top >= 0 && btn8Top < window.innerHeight) {
            setHighlightedButton("btn8");
          }
          else if (btn9Top >= 0 && btn9Top < window.innerHeight) {
            setHighlightedButton("btn9");
          }
          else if (btn10Top >= 0 && btn10Top < window.innerHeight) {
            setHighlightedButton("btn10");
          }
          else if (btn11Top >= 0 && btn11Top < window.innerHeight) {
            setHighlightedButton("btn11");
          }
          else if (btn12Top >= 0 && btn12Top < window.innerHeight) {
            setHighlightedButton("btn12");
          }
          else {
            setHighlightedButton(null);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener when component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    // useEffect end here
    return (
        <>
            <div className='headerSticky'>
                <LoginHeader />
            </div>
            <div className='features-slick-slider hidingSlider'>
                <Slider {...settings} ref={slider => (sliderRef = slider)}>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 1</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn1" onClick={() => handleButtonClick("btn1")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 2</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING2</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn2" onClick={() => handleButtonClick("btn2")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 3</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING3</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn3" onClick={() => handleButtonClick("btn3")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 4</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING4</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn4" onClick={() => handleButtonClick("btn4")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 5</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING5</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn5" onClick={() => handleButtonClick("btn5")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 6</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING6</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn6" onClick={() => handleButtonClick("btn6")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 7</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING 7</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn7" onClick={() => handleButtonClick("btn7")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 8</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING8</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn8" onClick={() => handleButtonClick("btn8")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 9</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING 9</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn9" onClick={() => handleButtonClick("btn9")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 10</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING10</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn10" onClick={() => handleButtonClick("btn10")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 11</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING11</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn11" onClick={() => handleButtonClick("btn11")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={3} mt={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ paddingLeft: "8%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h2" sx={{ color: "blue" }}>heading 12</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING12</Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        It has survived ns with the release.
                                    </p>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Button variant='contained' id="btn12" onClick={() => handleButtonClick("btn12")}>Know More</Button>
                                </Grid>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                                <img src={require("../../assets/images/range.png")} />
                            </Grid>
                        </Grid>
                    </div>
                </Slider>
            </div>
            <Divider />
            <div className='category' style={{ marginTop: "3%" }}>
                <Grid container item lg={12} md={12} xs={12} sm={12} sx={{
                    justifyContent: "center",
                    textAlign: "center", backgroundColor: "lightgray"
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                marginTop: 2,
                                marginLeft: 2,
                                marginRight: 2,
                                width: 170,
                                height: 50,
                            },
                        }}>
                        <Paper
                            sx={{
                                color: highlightedButton === "btn1" ? "white" : "black",
                                backgroundColor: highlightedButton === "btn1" ? "#69BD37" : "white",
                                textAlign: "center"
                            }}
                            onClick={() => handleButtonClick("btn1")}
                        >
                            <h3>FEATURES1</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn2" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn2" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn2")}
                        >
                            <h3>FEATURES2</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn3" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn3" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn3")}
                        >
                            <h3>FEATURES3</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn4" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn4" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn4")}
                        >
                            <h3>FEATURES4</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn5" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn5" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn5")}
                        >
                            <h3>FEATURES5</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn6" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn6" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn6")}
                        >
                            <h3>FEATURES6</h3>
                        </Paper>
                    </Box>
                </Grid>
                <Grid container item lg={12} md={12} xs={12} sm={12} sx={{
                    justifyContent: "center",
                    textAlign: "center", backgroundColor: "lightgray"
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                marginLeft: 2,
                                marginRight: 2,
                                marginBottom: 2,
                                marginTop: 1,
                                width: 170,
                                height: 50,
                            },
                        }}>
                        <Paper sx={{
                            color: highlightedButton === "btn7" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn7" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn7")}
                        >
                            <h3>FEATURES7</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn8" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn8" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn8")}>
                            <h3>FEATURES8</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn9" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn9" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn9")}>
                            <h3>FEATURES9</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn10" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn10" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn10")}>
                            <h3>FEATURES10</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn11" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn11" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn11")}>
                            <h3>FEATURES11</h3>
                        </Paper>
                        <Paper sx={{
                            color: highlightedButton === "btn12" ? "white" : "black",
                            backgroundColor: highlightedButton === "btn12" ? "#69BD37" : "white",
                            textAlign: "center"
                        }}
                            onClick={() => handleButtonClick("btn12")}>
                            <h3>FEATURES12</h3>
                        </Paper>
                    </Box>
                </Grid>
            </div>
            <div id="btn1" className='mr' ref={btn1Ref} style={{ marginTop: 150 }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>Lead GenTool</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>Credit Snapshot 2.0</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn2" ref={btn2Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} className='row-reverse'>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/BiggerCommission.jpg")} style={{ width: "64%" }} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingRight: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>Bigger Commission</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>Extra Monthly Incentives</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>
                <Divider />
            </div>
            <div id="btn3" ref={btn3Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>Co-Branded</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>Mobile App</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn4" ref={btn4Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} className='row-reverse'>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingRight: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 4</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn5" ref={btn5Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 5</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn6" ref={btn6Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} className='row-reverse'>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingRight: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 6</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn7" ref={btn7Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 7</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn8" ref={btn8Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} className='row-reverse'>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingRight: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 8</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn9" ref={btn9Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 9</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn10" ref={btn10Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} className='row-reverse'>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/scorefeature.png")} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingRight: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 10</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn11" ref={btn11Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingLeft: "8%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 11</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/range.png")} />
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div id="btn12" ref={btn12Ref}>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} className='row-reverse'>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ dispaly: "flex", justifyContent: "center", textAlign: "center" }}>
                        <img src={require("../../assets/images/range.png")} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ paddingRight: "8%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h2" sx={{ color: "blue" }}>heading 12</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="h4" sx={{ color: "#69BD37" }}>SUB HEADING</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <p className='fontSizing'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived ns with the release.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <button
                className={`bottom-to-top-btn ${isVisible ? 'show' : 'hide'}`}
                onClick={scrollToTop}
            >
                <KeyboardArrowUpIcon className='arrow-up' sx={{ fontSize: "60px" }} />
            </button>
        </>
    );
}
export default Features;