import React, { useMemo, useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import PropTypes from 'prop-types';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Grid, CardContent, Button,  Divider, Tab, Box, Tabs, Tooltip,Alert } from "@mui/material";
import { Dialog } from '@mui/material';

import { Card, CardOverflow, Typography } from '@mui/joy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import MaterialReactTable from 'material-react-table';
import LogoEdit from '../../../Components/Cobrand/LogoEdit';

import { useDispatch } from 'react-redux';
import { fetchData ,postData } from '../../../redux/apiSlice';
import config from '../../../AppConfig';
import swal from "sweetalert";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import useMediaQuery from '@mui/material/useMediaQuery';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function Administration(props) {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState(false);
    const { id } = useParams();
    const [user, setUser] = useState([]);
    const [disableUser, setDisableUser] = useState([]);
    const [uploadLogo, setUploadLogo] = React.useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)');

    const [logoURL, setlogoURL] = useState('');
    let logoFlag = 0;


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    let aidString = '';
    const handleClickOpen = async(id, value) => {
        swal({
            title: "Info",
            text: 'Are you sure you want to ' + value + ' this user?',
            icon: "info",
            button: "Ok",
        }).then(async(ok) => {
            if (ok) {
                const apiUrl = '/softDeleteUser';
                const args = {
                   id: id,
                }
                const res = await dispatch(postData({apiUrl,args})).unwrap();
                setSuccessMessage(res.message);
                fetchUserList();
                fetchDisableUserList();
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setTimeout(function () {
                    setSuccessMessage('')        
                }, 3000);
              

            }
        });


       

    };
    const fetchUserList = async page => {
        const apiUrl = '/partnerAdminUser'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setUser(res);
        }
    }
    const fetchDisableUserList = async page => {
        const apiUrl = '/fetchDisableUsers'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setDisableUser(res);
        }

    }
    const getSoftWareLogo = async () => {
        setlogoURL(require("../../../assets/images/uploadLogo.png"));
        const apiUrl = '/softwarelogo';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {

            if (res.logo != '') {
                setlogoURL(config.BACKEND_URL + "images/cobrandLogo/" + res.logo);
                logoFlag = res.logo ? 1 : 0;
            }
        }
    }
    useEffect(() => {
        fetchUserList();
        fetchDisableUserList();
        getSoftWareLogo();
    }, [])
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
       
    }, [rowSelection]);

   
    const handleCloseUploadLogo = (value) => {
        setUploadLogo(false);
    }
    const handleLogoFlag = (flag) => {
        logoFlag = flag;
    }
    const handleLogoURL = (image) => {
        logoURL = image;
    }
    const columns = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column
                // header: 'Employee',
                columns: [
                    {
                        accessorFn: (row) => `${row.first_name} ${row.last_name}`, //accessorFn used to join multiple data into a single cell
                        id: 'name', //id is still required when using accessorFn instead of accessorKey
                        header: 'Name',
                        size: 150,
                    },
                    {
                        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`, 
                        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        header: 'Email',
                        size: 150,
                        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : "N/A"),
                    },
                    {
                        accessorFn: (data) => `${data.phone_no ? formatPhoneNumber(data.phone_no) : 'N/A'}`,
                        accessorKey: 'phone_no', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        header: 'Phone No',
                        size: 150,
                    },
                    {
                        accessorFn: (row) => {
                          let aidString = '';
                        
                          if (row.aid_user) {
                            row.aid_user.map((aid_user) => {
                              if (row.id === aid_user.users_id) {
                                aid_user.mapped_user_aids.map((data) => {
                                  if (aidString === '') {
                                    aidString = data.aid;
                                  } else {
                                    aidString = aidString + ', ' + data.aid;
                                  }
                                });
                              }
                            });
                          }
                                           
                          const formattedContent = aidString || '';
                        
                          return formattedContent;
                        },
                        accessorKey: 'aids',
                        enableClickToCopy: true,
                        header: 'aids',
                        size: 18, 
                        Cell: ({ renderedCellValue, row }) => {
                          let aidString = '';
                        
                          if (row.original.aid_user) {
                            row.original.aid_user.map((aid_user) => {
                              if (row.original.id === aid_user.users_id) {
                                aid_user.mapped_user_aids.map((data) => {
                                  if (aidString === '') {
                                    aidString = data.aid;
                                  } else {
                                    aidString = aidString + ', ' + data.aid;
                                  }
                                });
                              }
                            });
                          }
                        
                          const formattedContent = aidString || 'N/A';
                        
                          return (
                            <span className={` ${aidString ? 'datatable' : ''}`}>
                              {formattedContent}
                            </span>
                          );
                        }
                      },
                    {
                        accessorFn: (row) => `${row.role.role_name}`, //accessorFn used to join multiple data into a single cell
                        enableClickToCopy: true,
                        header: 'User Type',
                        size: 150,
                    },
                    {
                        accessorFn: (row) =>
                            <Box>
                                {
                                    row.is_active === 1
                                        ?
                                        row.role.role_name === 'Partner User'
                                            ?
                                            <>
                                                <Tooltip title="Edit User" arrow><EditOutlinedIcon onClick={() => window.location.href = '/editpartneruser/' + row.id} /></Tooltip>
                                                <Tooltip title="Disable" arrow>
                                                    <img src={require('../../../assets/icons/Disable.png')} onClick={() => handleClickOpen(row.id, 'Disable')} />
                                                </Tooltip>

                                                <Tooltip title="Assign" arrow>
                                                    <img src={require('../../../assets/icons/Assign.png')} onClick={() => window.location.href = '/partner-admin/assigncro/' + row.id} />
                                                </Tooltip>


                                            </>
                                            :
                                            <>
                                                <Tooltip title="Edit User" arrow><EditOutlinedIcon onClick={() => window.location.href = '/editpartneruser/' + row.id} /></Tooltip>
                                                <Tooltip title="Disable" arrow>
                                                    <img src={require('../../../assets/icons/Disable.png')} onClick={() => handleClickOpen(row.id)} />
                                                </Tooltip>

                                            </>
                                        :
                                        <>
                                            <Tooltip title="Enable" arrow>
                                                <img src={require('../../../assets/icons/Enable.png')} onClick={() => handleClickOpen(row.id, 'Enable')} />
                                            </Tooltip>

                                        </>
                                }
                            </Box>,
                        accessorKey: 'Actions',
                        id: 'actions',
                        header: 'Actions',
                        size: 150,
                    },

                ],
            },

        ],
        [],
    );
//csv option start here
const userCombinedData = [...user];
  const disableUserCombinedData = [...disableUser];
  // ... (abandoned CSV options start)
  const userCsvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Active', // Change the file name
    headers: [ 'Name', 'Email', 'Phone No.', 'aids','User Type',]
  };
  // ... (abandoned CSV options end)
  // ... (declined CSV options start)
  const disableUserCsvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Disabled', // Change the file name
    headers: ['Name', 'Email', 'Phone No.', 'aids','User Type',]
  };
  // ... (declined CSV options end)
  // Define the convertToCSV function start
  const convertToCSV = (data, headers) => {
    const csvContent = headers.join(',') + '\n' +
      data.map((row) => (
        Object.values(row).map((value) => (
          typeof value === 'string' && value.includes(',') ? `"${value}"` : value
        )).join(',')
      )).join('\n');

    return csvContent;
  };
  // Define the convertToCSV function end
//   getaid start here
const getAidString = (row) => {
    let aidString = '';
    if (row.original.aid_user) {
      row.original.aid_user.forEach((aid_user) => {
        if (row.original.id === aid_user.users_id) {
          aid_user.mapped_user_aids.forEach((data) => {
            if (aidString === '') {
              aidString = data.aid;
            } else {
              aidString = aidString + ', ' + data.aid;
            }
          });
        }
      });
    }
    return aidString;
  };
//   getaid end here
  // Define export functions for  abandoned start
  const handleExportUserData = (userCombinedData) => {
   
    const exportedData = userCombinedData.map((row) => {
      return {
        'Name': `${row.original.first_name} ${row.original.last_name}` || 'N/A',
        'Email': `${row.original. email}` || 'N/A',
        'Phone No': `${row.original.phone_no}` || 'N/A',
        'AIDs':getAidString(row) || 'N/A',
        'User Type':`${row.original.role.role_name}` || 'N/A',
      };
    });

    // Generate CSV content
    const csvContent = convertToCSV(exportedData, userCsvOptions.headers);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = userCsvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };
  // Define export functions for  abandoned end
   // Define export functions for  Declined start
   const handleExportdisableUserData = (disableUserCombinedData) => {
   
    const exportedData = disableUserCombinedData.map((row) => {
      
      return {
        'Name': `${row.original.first_name} ${row.original.last_name}` || 'N/A',
        'Email': `${row.original.email}` || 'N/A',
        'Phone No': `${row.original.phone_no}` || 'N/A',
        'AIDs':getAidString(row) || 'N/A',
        'User Type':`${row.original.role.role_name}` || 'N/A',
      };
    });
  
    // Generate CSV content
    const csvContent = convertToCSV(exportedData, disableUserCsvOptions.headers);
  
     // Create a Blob object from the CSV data
     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

     // Create a download link element
     const link = document.createElement('a');
     link.href = URL.createObjectURL(blob);
     link.download = disableUserCsvOptions.fileName + '.csv';
 
     // Append the link to the document body
     document.body.appendChild(link);
 
     // Programmatically click the link to trigger the file download
     link.click();
 
     // Clean up the temporary URL and remove the link from the document
     URL.revokeObjectURL(link.href);
     link.remove();
  };
  // Define export functions for  Declined end
//csv end here
    function ContentLayout() {
        return (
            <>
             {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                <Card variant='outlined'>
                    <CardOverflow
                        sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                            padding: "1%"
                        }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                            <Grid item lg={6} md={6} sm={6} xs={6} >
                                <Typography level="h5" color="primary">Administration </Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                                <ExpandMore
                                    expand={expanded}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Grid>
                        </Grid>
                    </CardOverflow>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Box >
                                        <Card variant='outlined' size='lg' sx={{ height: 150, width: 400 }} >
                                            <CardContent sx={{  justifyContent: 'center' }}>
                                                <img className="dashlogo" style={{ height: 90, width: 300 }} src={logoURL} />
                                            </CardContent>
                                        </Card>
                                        <Box py={2} sx={{ textAlign: 'center' }}>
                                            <Button variant="contained" size="sm" color="success" onClick={(e) => { setUploadLogo(true) }}>Edit</Button>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={1} md={1} sm={12} xs={12}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Divider orientation="vertical" style={{ height: '100%', width: '1px' }} />
                                </Grid>
                                <Grid item lg={5} md={5} sm={12} xs={12}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Typography sx={{ color: "red" }}>
                                            Notes
                                        </Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <ol>
                                            <li>
                                                Logo Should be 1 to 100 KB in Size
                                            </li>
                                            <li>The dimension of logo must be 300 * 90 px</li>
                                            <li>Remove white spaces around logo (if any)</li>
                                        </ol>
                                    </Grid>
                                   
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse>
                </Card>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }} mt={4}>
                    <Grid item lg={6} md={6} sm={6} xs={6} >
                        <Typography level="h5" color="primary">Manage Users</Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: "right" }}>
                        <Tooltip title="Add User" arrow><Link className='btn btn-sm btn-primary text-white' to={{ pathname: "/partner-admin/adduser" }}><AddCircleOutlineIcon  fontSize="large" /></Link></Tooltip>
                    </Grid>
                </Grid>
                
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Active" {...a11yProps(0)} />
                            <Tab label="Disabled" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                    
                        <MaterialReactTable
                            columns={columns}
                            data={user}
                            enableColumnFilterModes
                            filterFns={{
                                customSearchFilterFn: (row, id, filterValue) =>
                                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                               }} 
                              globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                            enableColumnFilters={config.DatatableColumnFilter}
                            enableRowSelection
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                            initialState={{
                                showColumnFilters: false,
                                density: 'compact',
                            }}
                            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                            positionToolbarAlertBanner="bottom"
                            enableDensityToggle={false}
                            renderTopToolbarCustomActions={({ table }) => (
                                <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                >
                                <Button
                                    color="primary"
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                    onClick={() =>
                                        handleExportUserData(table.getPrePaginationRowModel().rows)
                                    }
                                    startIcon={<FileDownloadIcon />}
                                    variant='contained'
                                    >
                                    CSV
                                    </Button>
                                </Box>
                            )
                            }
                        />
                       
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        
                        <MaterialReactTable
                            columns={columns}
                            data={disableUser}
                            enableColumnFilterModes
                            filterFns={{
                                customSearchFilterFn: (row, id, filterValue) =>
                                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                               }} 
                              globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                            enableColumnFilters={config.DatatableColumnFilter}
                            enableRowSelection
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                            initialState={{
                                showColumnFilters: false,
                                density: 'compact',
                            }}
                            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                            positionToolbarAlertBanner="bottom"
                            enableDensityToggle={false}
                            renderTopToolbarCustomActions={({ table }) => (
                                <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                >
                                <Button
                                    color="primary"
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                    onClick={() =>
                                        handleExportdisableUserData(table.getPrePaginationRowModel().rows)
                                    }
                                    startIcon={<FileDownloadIcon />}
                                variant='contained'
                                    >
                                    CSV
                                    </Button>
                                </Box>
                            )
                            }
                        />
                    </TabPanel>

                </Box>
                <Dialog
                    open={uploadLogo}
                    onClose={handleCloseUploadLogo}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <LogoEdit id={id} logoEditURL={handleLogoURL} logoFlagEdit={handleLogoFlag} setOpen={handleCloseUploadLogo} />
                </Dialog>
            </>
        )
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
           
        </div>
    );

}

export default Administration;