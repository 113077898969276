import React, { useMemo, useEffect, useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {  useNavigate,useParams } from 'react-router-dom'
import { Grid, Table, TableCell, TableRow, TextField, Tooltip, FormLabel,FormControl, Button, Dialog, TableContainer, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, } from '@mui/joy';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputMask from 'react-input-mask';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';
import { fetchData, postData } from '../../../redux/apiSlice';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import swal from "sweetalert";
import dayjs from 'dayjs';
import AuthUser from '../../../Components/Auth/AuthUser';


      
function CheckBankDetailsFollowup() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [bankDetails, setData] = useState('');
    const [followupData , setDataFollowup] = useState([]);
    const[BankDetailsIssue, setBankDetailsIssue] = useState([]);
    const [currentFollowUpDate, setFollowUpDate] = useState("");
    const [currentFollowUpTime, setFollowUpTime] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const { user } = AuthUser();
    const [formatedCurrentDate, setFormatedCurrentDate] = useState("");
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    const getCompanyPanel = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}else{
            navigate('/companypanel');
        }
    };
    const handleClickOpen = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
        const newDate = new Date();
        const myDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        myDate.setDate(myDate.getDate());
        var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        console.log("cDate :"+cDate)
        var todaysTime = Moment(newDate).tz('America/Los_Angeles').format('hh:mm:ss');
        setFollowUpDate(todaysDate);
        setFollowUpTime(todaysTime);
        setCurrentDate(cDate);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getBankDetails = async () => {
        handleFreezStart();
        const apiUrl = '/fetchBankDetails/'+id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            handleFreezStop();
            setData(res);
        }
    }
    const getBankDetailsFollowup = async () => {
        handleFreezStart();
        const apiUrl = '/fetchDetailsFollowup/'+id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            handleFreezStop();
            setDataFollowup(res.data);
            setBankDetailsIssue(res.bankDetailsIssue);
        }
    }
    useEffect(() => {
        if(user.roles_id < 3 ) {
            getBankDetails();
            getBankDetailsFollowup();
        }else{
            navigate('/login');
        }
        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var CurrentFormatedDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YYYY'); 
        setCurrentDate(cDate);
        setFormatedCurrentDate(CurrentFormatedDate)
    }, []);

    const newActivity = async (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
        setOpen(false);
        handleFreezStart();
        const apiUrl = '/bankDetailsFollowup';
        const date = new Date(values.followupDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        var mydate = year+"/"+month+"/"+day;
        var todaysTime = values.followupTime;
        const args = {

            followupDate:mydate,
            followupTime:values.followupTime,
            note:values.note,
            id:id,
    
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res){
            handleFreezStop();
            swal({
                title: "Success",
                text: res.message,
                icon: "success",
                button: "Ok",
              })
              getBankDetailsFollowup();
        }

      

    }

    const RequestFormSchema = Yup.object().shape({
        followupDate: Yup.string()
          
        .test(
            'is-valid-year',
                  'Invalid Follow Up Date',
                  function (value) {
                    const date = new Date(value);
                    let formatedData1=Moment(date).format('MM-DD-YYYY');
                    const currentDate = new Date();
                    const year = date.getFullYear();
                    const formattedCurrentDate = Moment(currentDate).format('MM-DD-YYYY');
                    const splitYear = formatedCurrentDate.split('-')[2];
                   
                    return Moment(formatedData1, 'MM-DD-YYYY').isSameOrAfter(formattedCurrentDate, 'day');
                    
                  }
                )
                .required('Please enter followupDate'),
        followupTime: Yup.string()
        .test(
            'is-valid-time',
            'Invalid time format. Please enter a valid HH:mm:ss time.',
            (value) => {
              const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
              return regex.test(value);
            }
          )
          .required('Please enter followupTime'),

          note: Yup.string()
        .required('Please enter note'),
      })
   

    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs>
                        <Typography level="h4" color="primary">Bank Issues Details Follow Up</Typography>
                    </Grid>
                    <Grid item xs sx={{ textAlign: "right" }}>
                        <Tooltip title="Close" >
                            <HighlightOffIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={getCompanyPanel} />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Typography level="h5" >Bank Issues Details AID ({bankDetails.aid ? bankDetails.aid : 'N/A'})</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Card variant="outlined" size="lg">
                            <Table borderAxis="bothBetween">
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>User Name</TableCell>
                                    <TableCell >{bankDetails.first_name} {bankDetails.last_name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                    <TableCell>{bankDetails.email ? bankDetails.email : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Phone no</TableCell>
                                    <TableCell>{bankDetails.phone_no ? bankDetails.phone_no : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>AID</TableCell>
                                    <TableCell>{bankDetails.aid ? bankDetails.aid : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Bank Name</TableCell>
                                    <TableCell>{bankDetails.bank_name ? bankDetails.bank_name : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Name on Account</TableCell>
                                    <TableCell>{bankDetails.name_on_account ? bankDetails.name_on_account : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Bank Routing (ABA/ACH) Number</TableCell>
                                    <TableCell>{bankDetails.routing_number ? bankDetails.routing_number : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Bank Account Number</TableCell>
                                    <TableCell>{bankDetails.account_number ? bankDetails.account_number : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Type of Account</TableCell>
                                    <TableCell>{bankDetails.account_type ? bankDetails.account_type : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>And is Account</TableCell>
                                    <TableCell>{bankDetails.account_category ? bankDetails.account_category : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Email Address to notify of transfers sent</TableCell>
                                    <TableCell>{bankDetails.email_to_notify ? bankDetails.email_to_notify : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>ACH Info Added to Bank</TableCell>
                                    <TableCell>{bankDetails.ach_info_added_to_bank == 1 ? "Yes" : "No"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Last ACH update</TableCell>
                                    <TableCell>  {bankDetails.last_ach_updated ?  Moment(bankDetails.last_ach_updated).format('MM-DD-YYYY') : 'N/A' } </TableCell>
                                   
                                </TableRow>
                            </Table>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Card variant="plain" size="sm" sx={{ boxShadow: 'none' }}>
                            <Grid container item md={12} sx={{ justifyContent: 'space-between' }}>
                                <Typography level="h5" color="primary">Bank Follow Up Details</Typography>
                                <Button
                                    variant="contained"
                                    color='success'
                                    onClick={handleClickOpen}
                                >New Activity</Button>
                            </Grid>
                        </Card>
                        {followupData.map((logs, idx, array)=>{
                            return(
                            <>
                            <Card variant="outlined" size="lg" sx={{ marginTop: 2 }} >
                                <CardOverflow
                                    sx={{
                                        bgcolor: '#F6FAFF',
                                        borderColor: '#F6FAFF',
                                        padding: '1%',
                                    }}
                                >
                                    <Grid container item md={12} sx={{ justifyContent: 'space-between' }}>
                                        <Typography>
                                            <Typography level="h6" sx={{ mb: 0.5 }}>Last Followup By:  </Typography>
                                            <Typography >{logs.user?logs.user.first_name+' '+logs.user.last_name:'N/A'} </Typography>
                                        </Typography>
                                        <Typography>
                                            <Typography level="h6" sx={{ mb: 0.5 }}>Last Followup date: </Typography>
                                            <Typography> {logs.created_at ?  Moment(logs.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm')+'Hrs' : 'N/A' } </Typography>
                                        </Typography>
                                    </Grid>
                                </CardOverflow>
                                <CardContent>
                                    <Grid container item md={12} mt={1}>
                                        <Typography><b> Next Follow Up Date: </b> {logs.followup_date ?  Moment(logs.followup_date).format('MM-DD-YYYY') : 'N/A' }</Typography>
                                    </Grid>
                                    <Grid container item md={12} mt={1}>
                                        <Typography><b> Next Follow Up Time: </b>
                                        {logs.followup_time ? logs.followup_time : 'N/A'}</Typography>
                                    </Grid>
                                    <Grid container item md={12} mt={1}>
                                        <Typography><b>Description: </b> {logs.description ? logs.description : 'N/A'}</Typography>
                                    </Grid>
                                </CardContent>
                            </Card>
                            </>
                            );
                        })}

                    </Grid>
                </Grid>
                <Grid mt={2}>
                    <Card variant="outlined" size="lg" >
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                            }}>
                            <Typography level="h5" >Issues List</Typography>
                        </CardOverflow>
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Raised Issues</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Closed Issues</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Raised Date</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Closed Date</TableCell>
                                    </TableRow>
                                   
                                    {BankDetailsIssue.map((logs, idx, array)=>{
                                        return(
                                        <>
                                            <TableRow>
                                                <TableCell>{logs.ticket_reason ? logs.ticket_reason : 'N/A'}</TableCell>
                                                <TableCell>{logs.close_reason ? logs.close_reason : 'N/A'}</TableCell>
                                                <TableCell>{logs.status ? logs.status : 'N/A'}</TableCell>
                                                <TableCell>{logs.created_at ?  Moment(logs.created_at).format('MM-DD-YYYY') : 'N/A' }</TableCell>
                                                <TableCell>{logs.status == 'Completed' ? logs.updated_at ?  Moment(logs.updated_at).format('MM-DD-YYYY') : 'N/A' : 'N/A' }</TableCell>
                                            </TableRow>
                                        </>
                                        );
                                    })}
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <Formik
                     initialValues={{
                     
                        followupDate: dayjs(currentFollowUpDate),
                        followupTime: (currentFollowUpTime), 
                        note: '' 
                        
                    }}
                    validationSchema={RequestFormSchema}
                    onSubmit={newActivity}
                    >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
                        <DialogTitle>Add Activity</DialogTitle>
                        <DialogContent>
                            <Grid container item spacing={4}>
                            <Grid item xs>
                                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                                    <DemoContainer components={['DatePicker']} >
                                        <DatePicker label="Follow Up Date"
                                            margin="dense"
                                            id="followupDate"
                                            name="followupDate"
                                            fullWidth
                                            value={values.followupDate}
                                            onChange={(value) => {setFieldValue("followupDate", value, true)}}
                                            minDate={dayjs(currentDate)}
                                            maxDate={dayjs().add(75, 'year')}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {touched.followupDate && errors.followupDate ? <div className='error'>{errors.followupDate}</div> : null}
                                </Grid>
                                <Grid item xs>
                                    <InputMask
                                        mask='99:99:99'
                                        label="Follow Up Time*"
                                        maskChar={null}
                                        name='followupTime'
                                        fullWidth
                                        margin="dense"
                                        // size="small"
                                        id='followupTime'
                                        value={values.followupTime}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        >
                                        {(inputProps) =>
                                            <TextField
                                                label="Follow Up Time*" variant="outlined"
                                                {...inputProps}
                                            />
                                        }
                                    </InputMask>
                                    
                                    {touched.followupTime && errors.followupTime ? <div className='error'>{errors.followupTime}</div> : null}
                                </Grid>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                <FormLabel>Description*</FormLabel>
                                <FormControl fullWidth size="small">
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={5}
                                        placeholder="Enter Description"
                                        fullWidth
                                        name="note"
                                        id="note"
                                        value={values.note}
                                        onChange={handleChange}
                                    />
                                     {touched.note && errors.note ? <div className='error'>{errors.note}</div> : null}
                                </FormControl>
                               
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color='success' variant="contained" onClick={handleSubmit}>Submit</Button>
                            <Button onClick={handleClose} variant="contained">Close</Button>
                        </DialogActions>
                    </>
                    )}
                    </Formik>
                </Dialog>
            </>
        )
    }
    return (
        
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
                >
                <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default CheckBankDetailsFollowup;