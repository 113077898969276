import React, { useMemo, useState, useEffect } from 'react';
import AuthUser from "../Auth/AuthUser";
import {
	Box, Table, TableBody, TableCell, Collapse,
	Grid, TableContainer, TableHead, TableRow, Paper, Button, Tooltip, Alert, Badge
} from '@mui/material'; import MaterialReactTable from 'material-react-table';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useNavigate, Link } from 'react-router-dom';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import formatPhoneNumber from '../Formats/PhoneNumberValidation';
import config from '../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import swal from "sweetalert";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { fetchData, postData } from '../../redux/apiSlice';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Yup from 'yup';
import CheckIcon from '@mui/icons-material/CheckBoxOutlined';
import { Typography } from '@mui/joy';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Formik } from 'formik';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Loader/Loader';

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		top: 13,
		padding: '0 4px',
	},
}));
function AllCobrands(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { http, user } = AuthUser();
	const [aidlist, setAidList] = useState([]);
	const [openSuccess, setOpenSuccess] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [disable, setDisable] = useState(false);
	let success = localStorage.getItem('success');
	let error = localStorage.getItem('error');
	const [rowSelection, setRowSelection] = useState({});
	const [columnVisibility, setColumnVisibility] = React.useState({});
	const [open, setOpen] = React.useState(false);
	const [id, setId] = React.useState();
	const theme = useTheme();
	const [fullWidth, setFullWidth] = React.useState(true);
	const [maxWidth, setMaxWidth] = React.useState('sm');
	const [freez, setFreez] = React.useState(true);
	const [openDeletepopup, setDeleteOpen] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
	const [deleteEntryId, setDeleteEntryId] = useState('');

	const handleFreezStop = () => {
		setFreez(false);
	};
	const handleFreezStart = () => {
		setFreez(true);
	};
	const handleClickOpen = (id) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id >= 3) {
			navigate('/login');
			return false;
		}
		setOpen(true);
		setId(id);

	};
	const handlePlanClickOpen = async (id, pid, value) => {
		swal({
			title: "Info",
			text: 'Are you sure you want to ' + value + ' this plan?',
			icon: "info",
			buttons: {
				confirm: {
					text: "Ok",
					className: "swal-button--green-text",
				},
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					className: "swal-button--white-text", // Add this class for red color
				},// Add a cancel button
			},
		}).then(async (ok) => {
			if (ok) {
				handleFreezStart();
				const notes = '';
				const apiUrl = '/saveCobrandDisable';
				const args = {
					id: id,
					note: notes,
					pid: pid,
			statusValue: value,
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res.success === true) {
			await fetchCorandList();
			localStorage.setItem('success', res.message);
			setOpenSuccess(true);
			
			handleFreezStop();
			setOpen(false);
			setTimeout(() => {
				setOpenSuccess(false);
				localStorage.removeItem('success');
			}, 3000);
		} else {
			await
				fetchCorandList();
			localStorage.setItem('error', res.message);
			setOpenError(true);
			handleFreezStop();
			setTimeout(() => {
				setOpenError(false);
				localStorage.removeItem('error');
			}, 5000);
		}


	}
});

	};

	const handleClose = () => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id >= 3) {
			navigate('/login');
			return false;
		}
		setOpen(false);
	};
	const tablePreferencesArray = {
	}

	const fetchCorandList = async () => {
		const apiUrl = '/cobrandingDetails/all'; // Replace with your API endpoint
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if (res) {
			setIsLoading(false);
			handleFreezStop();
			
			setAidList(res.data);
		}
	}

	const closeDeletePlan = () => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id >= 3) {
			navigate('/login');
			return false;
		}
		setDeleteOpen(false)
	}
	useEffect(() => {
		fetchCorandList();
		if (success) {
			setOpenSuccess(true);
			setTimeout(() => {
				setOpenSuccess(false);
				localStorage.removeItem('success');
			}, 5000);
		}
		if (error) {
			setOpenError(true);
			setTimeout(() => {
				setOpenError(false);
				localStorage.removeItem('error');
			}, 5000);
		}
	}, [])

	const sendRequest = async (id) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id >= 3) {
			window.location.href = config.BASE_URL + 'login';
			return false;
		} else {
			setDisable(true)
			handleFreezStart();
			const apiUrl = '/cobrandRequest/sendEmail/' + id;
			const res = await dispatch(fetchData(apiUrl)).unwrap();
			if (res) {
				if (res.success) {
					localStorage.setItem('success', res.message);
					setOpenSuccess(true);
					fetchCorandList();
					setDisable(false);
					setTimeout(() => {
						setOpenSuccess(false);
						localStorage.removeItem('success');
					}, 5000);
				} else {
					// setIsLoading(false);
					handleFreezStop();
					localStorage.setItem('errorMsg', res.message);
					setOpenError(true);
					setDisable(false);
					setTimeout(() => {
						setOpenError(false);
						localStorage.removeItem('error');
					}, 5000);
				}
			}
		}

	}
	useEffect(() => {
	}, [rowSelection]);
	////////////////////////
	useEffect(() => {
		if (Object.values(columnVisibility).length > 0) {
			setColumnVisibility(columnVisibility);
		}
		//do something when the row selection changes...
		
	}, [rowSelection]);

	useEffect(() => {
		tablePreferences();
	}, [rowSelection]);
	////////////////////////

	const checkColumnVisibility = async () => {
		if (Object.values(columnVisibility).length > 0) {
			const apiUrl = '/saveTablePreferences';
			const args = {
				columnArray: columnVisibility,
				userId: user.id,
				tableId: 'cobrandpanel'
			}
			const res = await dispatch(postData({ apiUrl, args })).unwrap();
		}
	}

	useEffect(() => {
		checkColumnVisibility();
	}, [columnVisibility])

	const tablePreferences = async () => {
		const apiUrl = '/fetchTablePreferences/cobrandpanel';
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if (res) {
			if (Object.values(res).length == 0) {
				setColumnVisibility(tablePreferencesArray);
			} else {
				setColumnVisibility((JSON.parse(res.preferences)));
			}
		}
	}
	const disableContactLog = Yup.object().shape({
		note: Yup.string()
			.required('disable reason required.'),

	});
	const deleteLog=async ()=>{
		setOpenConfirmationBox(false)
	  	handleFreezStart(true)
		const apiUrl = '/cobrand/delete';
		const args = {
			id: deleteEntryId,
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
	  	if(res) 
		{
			fetchCorandList()
			handleFreezStop(false)
			if (res.success === true) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					button: "Ok",
				})
			} else {
				handleFreezStop(false)
				swal({
					title: "error",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
			}
		}
	}
	const customSortFunction = (rowA, rowB) => {
		const flagPriority = {
			0: 0,
			1: 1,
		};
		const flagA = rowA.original.cobrand_details?.is_active || 0;
		const flagB = rowB.original.cobrand_details?.is_active || 0;

		
		const priorityA = flagPriority[flagA];
		const priorityB = flagPriority[flagB];
		return priorityA - priorityB;
	};
	
	const columns = useMemo(
		//column definitions...
		() => [
			{
				accessorKey: 'aidName',
				accessorFn: (row) => `${row.aid ? row.aid.trim() : ''}`,
				header: 'AID',
				size: 50,
				enablePinning: true,
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.aid ? row.original.aid.trim() : 'N/A'}

					</>
				),
			},
			{
				accessorKey: 'companyName',
				accessorFn: (row) => `${row.aid_company ? row.aid_company.company_name.trim() : ''}`,
				header: 'Company Name',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.aid_company ? row.original.aid_company.company_name.trim() : 'N/A'}

					</>
				),
			},
			{
				accessorKey: 'email',
				accessorFn: (row) => `${row.cobrand_details ? row.cobrand_details.email ? row.cobrand_details.email.trim() : '' : ''}`,
				header: 'Email',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.cobrand_details ? row.original.cobrand_details.email ? row.original.cobrand_details.email.trim() : 'N/A' : 'N/A'}
						{row.original.cobrand_details ? row.original.cobrand_details.bounce_email ?
							<Tooltip title="Emails are not getting delivered to this address. Please update the email" arrow><ErrorOutlineIcon color={'error'} /></Tooltip>
							: '' : ''}

					</>
				),
			},
			{
				accessorKey: 'phone',
				accessorFn: (row) => `${row.cobrand_details ? row.cobrand_details.phone ? formatPhoneNumber(row.cobrand_details.phone.trim()) : 'N/A' : 'N/A'}`,
				header: 'Phone No',
				size: 50,
			},
			
			{
				accessorFn: (row) => `${row.cobrand_details}`,
				accessorKey: 'cobrandingStatusfirst',
				header: 'cobrandingStatusfirst',
				filterVariant: 'Cobranding Status',
				header: 'Cobranding Status',
				size: 50,

				//custom conditional format and styling
				sortingFn: customSortFunction,
				Cell: ({ row }) => (
					<>
					<Box sx={{textAlign:"center"}}>
						{
							row.original.cobrand_details
								?
								row.original.cobrand_details.is_active === 1
									?
									<Tooltip title="Enabled" arrow><CheckIcon color="success" /></Tooltip>
									:
									<Tooltip title="Not Active" arrow><CheckIcon color="disabled" /></Tooltip>
								:
								<Tooltip title="Not Active" arrow><CheckIcon color="disabled" /></Tooltip>
						}
					</Box>
					</>
				),
			},
			{
				accessorFn: (row) =>
					<Box>
						{
							user.roles_id===13?
							(row.cobrand_details?
							<Tooltip title="Delete" arrow >
							<Button> <img src={require('../../assets/icons/deleteIcon2.png')}  onClick={()=>{setDeleteEntryId(row.id);setOpenConfirmationBox(true)}}/></Button>
						   </Tooltip>:null):
							row.cobrand_details
								?
								row.cobrand_details.status == 'Requested' || row.cobrand_details.status == 'Updated' || row.cobrand_details.status == 'PlanUpdated'
									?
									user.roles_id === 3 || user.roles_id === 4 || user.roles_id === 5
										?
										<>
											<Tooltip title="View Details" arrow>
												<Link className='btn btn-success text-white' to={"/cobrand-details/show/" + row.id} ><img src={require('../../assets/images/details.png')} /></Link>
											</Tooltip>
										</>
										:
										row.cobrand_details.status == 'Requested' || row.cobrand_details.status == 'PlanUpdated'
											?
											<>
												<Tooltip title="Verify" arrow>
													<Link className='btn btn-success text-white' to={{ pathname: "/cobrand-verify/" + row.id }}  ><img src={require('../../assets/icons/VerifiedIcon.png')} /></Link>
												</Tooltip>
												<Tooltip title="Delete" arrow>
													<Link className='btn btn-success text-white' onClick={() => deleteCobrand(row.id)}  ><img src={require('../../assets/images/delete.png')} /></Link>
												</Tooltip>
											</>
											:
											<>
												<Tooltip title="Update" arrow>
													<Link className='btn btn-success text-white' to={"/cobrand-verify/" + row.id + "?updateFlag=1"}  ><img src={require('../../assets/icons/Update.png')} /></Link>
												</Tooltip>

											</>
									:
									row.cobrand_details.status == 'Verified'
										?
										user.roles_id === 3 || user.roles_id === 4 || user.roles_id === 5
											?
											<>
												<Tooltip title="View Details" arrow>
													<Link className='btn btn-success text-white' to={"/cobrand-details/show/" + row.id} ><img src={require('../../assets/images/details.png')} /></Link>
												</Tooltip>
											</>
											:
											<>
												<Tooltip title="Set PID" arrow>
													<Link className='btn btn-success text-white' to={{ pathname: "/updatePid/" + row.id }} ><img src={require('../../assets/icons/SetPid.png')} /></Link>
												</Tooltip>
												<Tooltip title="Delete" arrow>
													<Link className='btn btn-success text-white' onClick={() => deleteCobrand(row.id)}  ><img src={require('../../assets/images/delete.png')} /></Link>
												</Tooltip>
											</>
										:
										row.cobrand_details.status === 'Completed' && row.cobrand_details.is_active === 1
											?

											<>
												{user.roles_id != 5
													?
													<>
														<Tooltip title="Edit" arrow>
															<Link className='btn btn-success text-white' to={"/cobrand-request/" + row.id + "?updateFlag=1"} ><img src={require('../../assets/icons/edit.png')} /></Link>
														</Tooltip>
													</>
													: null}
												<>
													<Tooltip title="View Details" arrow>
														<Link className='btn btn-success text-white' to={"/cobrand-details/show/" + row.id} ><img src={require('../../assets/images/details.png')} /></Link>
													</Tooltip>
												</>



												{user.roles_id === 1 || user.roles_id === 2
													?
													<>
														<Tooltip title="Disable" arrow>
															{/* <Link className='btn btn-success text-white' to={"/cobrand-details/show/" + row.id} ><img src={require('../../../assets/images/details.png')} /></Link> */}
															<img src={require('../../assets/icons/Disable.png')} onClick={() => handleClickOpen(row.id)} />
														</Tooltip>
													</>
													: null
												}
											</>
											:
											<>
												{user.roles_id === 3 || user.roles_id === 4 || user.roles_id === 5
													?
													<></>
													:
													row.cobrand_details.request_email_sent === 1
														?
														<>

															<Tooltip title="Resend" arrow>
																<Link onClick={() => { sendRequest(row.id) }} disabled={disable}>
																	<StyledBadge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} badgeContent={row.cobrand_details.resend_email_count} color="primary">
																		<img src={require('../../assets/icons/Refresh.png')} />
																	</StyledBadge>
																</Link>
															</Tooltip>

														</>
														:
														<>
															<Tooltip title="Request" arrow>
																<Link onClick={() => { sendRequest(row.id) }} disabled={disable} ><img src={require('../../assets/icons/Request.png')} /></Link>
															</Tooltip>
														</>

												}
												<>
													{
														user.roles_id === 1 || user.roles_id === 2 || user.roles_id === 3 || user.roles_id === 4 ?
															<>
																<Tooltip title="Fill Form" arrow>
																	<Link className='btn btn-success text-white' to={{ pathname: "/cobrand-request/" + row.id }} ><img src={require('../../assets/icons/FillForm.png')} /></Link>
																</Tooltip>
															</> : ''
													}

												</>
											</>
								:
								<>
									{
										user.roles_id === 1 || user.roles_id === 2
											?
											<>
												<Tooltip title="Request" arrow>
													<Link onClick={() => { sendRequest(row.id) }} disabled={disable} ><img src={require('../../assets/icons/Request.png')} /></Link>
												</Tooltip>

												<>
													<Tooltip title="Fill Form" arrow>
														<Link className='btn btn-success text-white' to={{ pathname: "/cobrand-request/" + row.id }} ><img src={require('../../assets/icons/FillForm.png')} /></Link>
													</Tooltip>
												</>
											</>
											:
											user.roles_id === 3 || user.roles_id === 4
												?
												<>
													<Tooltip title="Fill Form" arrow>
														<Link className='btn btn-success text-white' to={{ pathname: "/cobrand-request/" + row.id }} ><img src={require('../../assets/icons/FillForm.png')} /></Link>
													</Tooltip>
												</> : ''
									}
								</>
						}
					</Box>,
				accessorKey: 'Actions',
				id: 'actions',
				header: 'Actions',

			},
		]
	)
	const deleteCobrand = async (id) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id >= 3) {
			window.location.href = config.BASE_URL + 'login';
			return false;
		}
		setDeleteId(id)
		setDeleteOpen(true);
	}
	const deleteCobrandPlan = async () => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if (loginUser.roles_id >= 3) {
			navigate('/login');
			return false;
		}
		handleFreezStart();
		setDeleteOpen(false);
		const apiUrl = '/cobrand-Delete/' + deleteId;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if (res) {
			if (res.success) {
				await fetchCorandList();
				handleFreezStop();
				localStorage.setItem('success', res.message);
				setOpenSuccess(true);
				setTimeout(() => {
					setOpenSuccess(false);
					localStorage.removeItem('success');
				}, 3000);
			}
		}
	}
	//csv start here
	const csvOptions = {
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		fileName: 'Cobrand-Panel',
		headers: ['AID', 'Company Name', 'Email', 'Phone No', 'Cobranding Status']
	};
	const csvExporter = new ExportToCsv(csvOptions);
	const handleExportData = (aidlist) => {
		if (localStorage.getItem("admin") != null) {
			navigate('/login');
			return false;
		}
		const exportedData = [];
		aidlist.forEach((row) => {
			const mainTableRow = {
				aidName: row.original.aid || 'N/A',
				companyName: row.original.aid_company ? row.original.aid_company.company_name : 'N/A',
				email: row.original.cobrand_details ? (row.original.cobrand_details.email ? row.original.cobrand_details.email : 'N/A') : 'N/A',
				phone: row.original.cobrand_details ? (row.original.cobrand_details.phone ? formatPhoneNumber(row.original.cobrand_details.phone) : 'N/A') : 'N/A',
				cobrandingStatusfirst: getCobrandingStatus(row),
			}
			exportedData.push(mainTableRow);
			// Add subheaders
			const subheader = {
				'PID': 'PID', // Subheader 1
				'Base PID': 'Base PID', // Subheader 2
				'Plan Selected': 'Plan Selected', // Subheader 3
				'Sponsored?': 'Sponsored?', // Subheader 4
				'Sponsored Code': 'Sponsored Code', // Subheader 5
				'Status': 'Status', // Subheader 6
			};
			exportedData.push(subheader);
			if (row.original.cobrand_details && row.original.cobrand_details.cobrand_plan) {
				row.original.cobrand_details.cobrand_plan.forEach((child) => {
					const subRow = {
						pid: child.pid ? child.pid : 'N/A',
						base_pid: child.base_plan ? (child.base_plan.base_pid ? child.base_plan.base_pid : 'N/A') : 'N/A',
						plan_name: child.base_plan ? (child.base_plan.plan_name ? child.base_plan.plan_name : 'N/A') : 'N/A',
						is_sponsored: child.is_sponsored && child.is_sponsored === 1 ? 'Yes' : 'No',
						sponsored_code: child.sponsored_code ? child.sponsored_code : 'N/A',
						status: !child.pid ? row.original.cobrand_details.status : 'Completed',
					};
					exportedData.push(subRow);
				});
			} else {
				// If no subrows available, add an empty row for subrows
				exportedData.push({});
			}
			exportedData.push({});
		});
		const csvContent = convertToCSV(exportedData);
		// Create a Blob object from the CSV data
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
		// Create a download link element
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = csvOptions.fileName + '.csv';

		// Append the link to the document body
		document.body.appendChild(link);

		// Programmatically click the link to trigger the file download
		link.click();

		// Clean up the temporary URL and remove the link from the document
		URL.revokeObjectURL(link.href);
		link.remove();
	};

	const convertToCSV = (data) => {
		const headers = csvOptions.headers.join(',') + '\n';

		const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				}

				return value;
			}).join(',');
		}).join('\n');

		return headers + rows;
	};
	const escapeValue = (value) => {
		// If the value contains commas or double quotes, wrap it in double quotes
		if (/,|"/.test(value)) {
			value = '"' + value.replace(/"/g, '""') + '"';
		}
		return value;
	};

	const getCobrandingStatus = (row) => {
		if (row.cobrand_details) {
			if (row.cobrand_details.is_active === 1) {
				return 'Enabled';
			} else {
				return 'Not Active';
			}
		}
		return 'Not Active';
	};
	//csv end here
	return (
		<>
			<Box sx={{ mt: 1.5 }} >
				<Grid >
					{
						success
							?
							<Collapse in={openSuccess}>
								<Alert fullWidth onClose={() => { localStorage.removeItem('success'); setOpenSuccess(false) }}>{success}</Alert>
							</Collapse>
							: ''
					}
				</Grid>
				<Grid >
					{
						error
							?
							<Collapse in={openError}>
								<Alert severity='error' fullWidth onClose={() => { localStorage.removeItem('error'); setOpenError(false) }}>{error}</Alert>
							</Collapse>
							: ''
					}
				</Grid>
				<Grid mt={3}>
					<MaterialReactTable
						columns={columns}
						data={aidlist}
						enableColumnFilterModes
						enablePinning
						filterFns={{
							customSearchFilterFn: (row, id, filterValue) =>
								row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
						}}
						globalFilterFn="customSearchFilterFn" //exact serach filter functionality
						enableColumnFilters={config.DatatableColumnFilter}
						initialState={{ showColumnFilters: false, density: 'compact',
							columnPinning: { left: ['mrt-row-expand','aidName'] }
						 }}
						positionToolbarAlertBanner="bottom"
						enableDensityToggle={false}
						onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
						state={{ columnVisibility, rowSelection, isLoading: isLoading }}
						onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
						muiTableHeadCellProps={{
							//simple styling with the `sx` prop, works just like a style prop in this example
							sx: {
								backgroundColor: "#F6FAFF",
							},
						}}
						renderDetailPanel={({ row }) => (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-around',
									alignItems: 'center',
								}}
							>

								<TableContainer component={Paper}>
									<Table className='back'>
										{
											(row.original.cobrand_details)
												?
												<>
													<TableHead>
														<TableRow>
															<TableCell align="center" sx={{ fontWeight: "bold" }}>PID</TableCell>
															<TableCell align="center" sx={{ fontWeight: "bold" }}>Base PID</TableCell>
															<TableCell align="center" sx={{ fontWeight: "bold" }}>Plan Selected</TableCell>
															<TableCell align="center" sx={{ fontWeight: "bold" }}>Sponsored?</TableCell>
															<TableCell align="center" sx={{ fontWeight: "bold" }}>Sponsored Code</TableCell>
															<TableCell align="center" sx={{ fontWeight: "bold" }}>Status</TableCell>
															{user.roles_id === 1 || user.roles_id === 2 ? <>
																<TableCell align="center" sx={{ fontWeight: "bold" }}>Action</TableCell></>
																: ''}
														</TableRow>
													</TableHead>
													<TableBody>
														{(row.original.cobrand_details.cobrand_plan).map((child) => (
															<TableRow
																key={`${child.id}-${child.name}`} //Ensure that child.name is indeed unique for each item in data
																sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
															>
																<TableCell align="center">{child.pid ? child.pid : 'N/A'}</TableCell>
																<TableCell align="center">{(child.base_plan) ? (child.base_plan.base_pid) ? child.base_plan.base_pid : 'N/A' : 'N/A'}</TableCell>
																<TableCell align="center">{(child.base_plan) ? (child.base_plan.plan_name) ? child.base_plan.plan_name : 'N/A' : 'N/A'}</TableCell>
																
																<TableCell align="center">{child.is_sponsored && child.is_sponsored == 1 ? 'Yes' : 'No'}</TableCell>
																<TableCell align="center">{child.sponsored_code ? child.sponsored_code : 'N/A'}</TableCell>
																<TableCell align="center">{!child.pid ? row.original.cobrand_details.status : 'Completed'}</TableCell>
																{user.roles_id === 1 || user.roles_id === 2 ? 
																	<>
																		<TableCell align="center"> {child.pid != '' && (child.is_disabled == 0 && child.status == 1) ? <>
																			<Tooltip title="Disable" arrow>
																				<img src={require('../../assets/icons/Disable.png')} onClick={() => handlePlanClickOpen(child.id, child.pid, 'Disable')} />
																			</Tooltip></>
																			: child.pid != '' && (child.is_disabled == 1 && child.status == 1) ? <>
																				<Tooltip title="Enable" arrow>
																					<img src={require('../../assets/icons/Enable.png')} onClick={() => handlePlanClickOpen(child.id, child.pid, 'Enable')} />
																				</Tooltip></>
																				: ''}
																		</TableCell>
																	</> 
																	: 
																''}
															</TableRow>
														))}
													</TableBody>
												</>
												:
												<TableRow>
													<TableCell sx={{ fontWeight: "bold" }}>Cobrand details not available</TableCell>
												</TableRow>

										}
									</Table>
								</TableContainer>
							</Box>
						)}
						//top csv option
						renderTopToolbarCustomActions={({ table }) => (
							<Box
								sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
							>


								<Button
									sx={{ bgColor: "#2A68B3" }}
									disabled={table.getPrePaginationRowModel().rows.length === 0}
									//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() =>
										handleExportData(table.getPrePaginationRowModel().rows)
									}
									//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
									startIcon={<FileDownloadIcon />}
									variant="contained"
									className='export'
								>
									CSV
								</Button>
							</Box>
						)
						}
					/>
				</Grid>
				<Dialog
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={open}
					onClose={handleClose}
					aria-labelledby="responsive-dialog-title"
				>
					<Formik
						initialValues={{
							note: '',
						}}

						validationSchema={disableContactLog}
						onSubmit={async (values, errors) => {
							const loginUser = JSON.parse(localStorage.getItem('user'));
							if (loginUser.roles_id >= 3) {
								navigate('/login');
								return false;
							}
							setOpen(false);
							handleFreezStart();
							const apiUrl = '/saveCobrandDisable';
							const args = {
								note: values.note,
								id: id
							}
							const res = await dispatch(postData({ apiUrl, args })).unwrap();
							if (res) {
								if (res.success === true) {
									await fetchCorandList();
									localStorage.setItem('success', res.message);
									setOpenSuccess(true);
									
									handleFreezStop();
									setOpen(false);
									setTimeout(() => {
										setOpenSuccess(false);
										localStorage.removeItem('success');
									}, 3000);
								}
							}
						}}
					>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
							<>
								<DialogTitle id="responsive-dialog-title" sx={{ backgroundColor: "#F6FAFF", }}>
									Disable Cobranding
								</DialogTitle>
								<DialogContent mt={2}>
									<DialogContentText>
										<Typography level="h6">Disable reason:*</Typography>
									</DialogContentText>
									<Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
										<TextareaAutosize
											style={{ width: "100%" }}
											required
											margin="dense"
											aria-label="minimum height"
											minRows={6}
											placeholder="Enter disable reason*"
											name="note"
											onChange={handleChange('note')}
										/>
										{touched.note && errors.note ? <div className='error'>{errors.note}</div> : null}
									</Grid>
								</DialogContent>
								<DialogActions sx={{ display: "flex", justifyContent: "center" }}>

									<Button variant='contained' onClick={handleClose} sx={{ minWidth: 250 }}>
										Cancel
									</Button>
									<Button onClick={handleSubmit} variant="contained" sx={{ minWidth: 150 }} color="success" type="submit">
										SAVE
									</Button>

								

								</DialogActions>
							</>
						)}
					</Formik>
				</Dialog>

				{/* Step 4: Display the confirmation dialog */}
				<Dialog
					open={openDeletepopup}
					onClose={(_, reason) => {
						if (reason !== "backdropClick") {
							setDeleteOpen(false)
						}
					}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Delete Plan</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to delete this Plan?
						</DialogContentText>
					</DialogContent>
					<DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closeDeletePlan} >
							Cancel
						</Button>
						<Button onClick={deleteCobrandPlan} autoFocus style={{ backgroundColor: 'red', color: 'white', minWidth: 100 }}>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
                                fullWidth
                                maxWidth={'sm'}
                                open={openConfirmationBox}
                                // onClose={handleClose}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        setOpenConfirmationBox(false);
                                    }
                                }}
                            >
                 
                                <DialogContent>
                                    <Box mt={1} sx={{textAlign:'center'}}>
                                        <Typography level='h4'>Are you sure you want to delete this cobrand details?</Typography>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ margin: 'auto' }} >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={()=>{deleteLog()}} color="success" sx={{ minWidth: 250 }} >
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={() => setOpenConfirmationBox(false)} color="primary" sx={{ minWidth: 250 }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </DialogActions>
                    </Dialog>
			</Box>
		{/* loader code start */}
		<div>
			<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={freez}
			onClick={handleFreezStop}
			>
			<Freez />
			</Backdrop>
      </div>
      {/* loader code end */}
		</>
	);

}


export default AllCobrands;