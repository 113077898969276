import React, { useState, useEffect, useRef } from "react";
import RegisterFooter from '../../Auth/RegisterFooter';
import LoginHeader from "../../../Components/LoginHeader";
import { Box, Stepper, Step, Tooltip, IconButton, StepLabel, Grid, TextField, DialogContentText, InputAdornment, FormControl, InputLabel, Select, OutlinedInput, MenuItem, FormHelperText, Dialog, DialogTitle, DialogContent } from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import {  useNavigate } from "react-router-dom";
import { Typography, Card, CardContent, Button } from '@mui/joy';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TermsAndConditions from '../../FooterPages/TermsAndConditions';
import CancelPolicy from '../../FooterPages/CancelPolicy';
import PrivacyPolicy from '../../FooterPages/PrivacyPolicy';
import RefundPolicy from '../../FooterPages/RefundPolicy';
import { Formik, Form,Field } from 'formik';
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import AuthUser from "../../../Components/Auth/AuthUser";
import dayjs from 'dayjs';
import swal from "sweetalert";
import * as Yup from 'yup';
import config from '../../../AppConfig';
import InputMask from 'react-input-mask';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import { parse, isBefore, subYears } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
let mobilenumbertext = "";
let mobile = "";
let ssn = "";
const steps = [
    'Step 1',
    'Step 2',
];
const today = dayjs();
let minDate = dayjs(today.subtract(110, 'year').toDate());
let maxDate = dayjs(today.subtract(18, 'year').toDate());
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    return formattedDate;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
let formikValues = [];
function EnrollStep2(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { http, getToken } = AuthUser();
    const [states, setStates] = useState([]);
    const inputRef1 = useRef();
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
   
    const [error, setError] = useState(false);
    const [otp, setOTP] = useState('');
    const [errormsg, setErrorMsg] = useState('');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [sentotp, setSentotp] = React.useState('');
    const [resendButtonDisable, setResendButtonDisable] = React.useState(false);
    const [resendCounter, setResendCounter] = React.useState(0);
    const [buttonText, setButtonText] = useState('Resend Link');
    const [isValidFormat, setIsValidFormat] = useState(true);
    const [dateFormatErrorMsg, setDateFormatErrorMsg] = useState("");
    const [isDateValid, setDateValid] = useState(true);
    const [lessthanErrorMsg, setLessthanErrorMsg] = useState("");
    const [tc, settc] = useState(false);
    const [tcflag, settcflag] = useState(false);
    const [value, setValue] = useState('');
    const [aid, setAID] = useState(localStorage.SnapShot_aid);

    
    const touchedDateOfBirth1 = true; 
  const errorsDateOfBirth1 = false;
    

    
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const toggleDrawer1 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen1(open);
    };
    const toggleDrawer2 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen2(open);
    };
    const toggleDrawer3 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen3(open);
    };
    const toggleDrawer4 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen4(open);
    };
    //loader function and variable end
    //handle OTP Change start
    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        setValue(inputValue);
        if (inputValue === e.target.value) {
            setError(false);
        } else {
            setError(true);
        }
        setOTP(e.target.value);
    };
    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleSubmitOtp = () => {
       
        
        SnapShotErollStep3();
        handleCloseDialog();
    };
    //handle OTP Change end
    const fetchState = () => {
        http.get("/fetchStates").then((res) => {
            setStates(res.data.data);
        });
    }
    useEffect(() => {
        fetchState();
    }, [])

    
    //get Snapshot AID function and variable ends
   

    //Step3 Function starts
    const SnapShotErollStep3 = (values) => {
        handleFreezStart();
        http.post("/verifyCRSUser", {
            aid: localStorage.getItem('SnapShot_aid'),
            userId: localStorage.getItem('UserId'),
            streetAddress: localStorage.getItem('streetAddress'),
            streetAddress1: localStorage.getItem('streetAddress1'),
            city: localStorage.getItem('city'),
            state: localStorage.getItem('state'),
            mobile: localStorage.getItem('mobile'),
            dob: localStorage.getItem('dob'),
            ssn: localStorage.getItem('ssn'),
            zip: localStorage.getItem('zip'),
            firstName: localStorage.getItem('SnapShot_firstName'),
            lastName: localStorage.getItem('SnapShot_lastName'),
            email: localStorage.getItem('SnapShot_email'),
            password: localStorage.getItem('SnapShot_password'),
            source: "default",
            dtoken: localStorage.getItem('Dtoken'),
            utoken: localStorage.getItem('utoken'),
            smfaToken: localStorage.getItem('smfaToken'),
            url: config.BASE_URL,
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    localStorage.setItem("ScoreData", JSON.stringify(res.data.data));
                    // Get the base URL
                    const baseUrl = window.location.origin;
                    // Append "/score" to the base URL
                    const updatedUrl = config.BASE_URL + `score/` + res.data.data.userId;
                    // Navigate to the updated URL
                    window.location.href = updatedUrl;
                } else {
                    if (res.data.data.step === "VerifyPopup") {
                        setButtonText("Resend Link");
                        setResendButtonDisable(false);
                        setSentotp(res.data.data.code);
                        handleOpenDialog();
                        alert("Oops! Encountered some error. Please try again later. \n**" + res.data.data.errorByStichCredit);
                    } else {
                        handleFreezStop();
                        swal({
                            title: "Failed",
                            text: "Oops! Encountered some error. Please try again later. \n**" + res.data.data.errorByStichCredit,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                }
            })
            .catch((error) => {
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    //Step3 Function ends

    //Step2 Function starts
    const SnapShotErollStep2 = (values) => {

        if (values.DateofBirth1 == "" || values.DateofBirth1 == undefined || values.DateofBirth1 == null) {
            swal({
                title: "Alert",
                text: "Date of Birth is required",
                icon: "error",
                button: "Ok",
            }).then((ok) => {
                if (ok) {
                    inputRef1.current?.focus();
                }
            });

            return null;
        }
       
   
        if (isDateValid == false || isValidFormat == false) {
            return false;
        }
        mobile = values.MobileNumber.replace(/[^0-9]/g, '');
        ssn = values.SocialSecurityNumber.replace(/[^0-9]/g, '');
        handleFreezStart();
        http.post("/creditSnapshotEnrollStep2", {
            aid: localStorage.getItem('SnapShot_aid'),
            userId: localStorage.getItem('UserId'),
            streetAddress1: values.StreetAddress,
            streetAddress2: values.StreetAddress2,
            city: values.City,
            state: values.State,
            mobile: mobile,
            dob: formatDate(values.DateofBirth1),
            ssn: ssn,
            zip: values.ZipCode,
            isConfirmedTerms: tc,
            firstName: localStorage.getItem('SnapShot_firstName'),
            lastName: localStorage.getItem('SnapShot_lastName'),
            email: localStorage.getItem('SnapShot_email'),
            password: localStorage.getItem('SnapShot_password'),
            source: "default",
            dtoken: localStorage.getItem('Dtoken'),
            rtoken: localStorage.getItem('rtoken'),
            paToken: localStorage.getItem('paToken')
        })
            .then((res) => {
                mobilenumbertext = values.MobileNumber;
                console.log("step",res);
                if (res.data.success === true) {
                    console.log("step1",res);
                    handleFreezStop();
                    
                    if (res.data.data.step === "VerifyPopup") {
                        console.log("step2",res.data);
                        localStorage.setItem("smfaToken", res.data.data.smfaToken);
                        localStorage.setItem("utoken", res.data.data.utoken);
                        formikValues = values;
                        setButtonText("Resend Link");
                        setResendButtonDisable(false);
                        handleOpenDialog();
                    }

                } else {
                    handleFreezStop();
                    setErrorMsg(res.data.data.errorByStichCredit);
                }
            })
            .catch((error) => {
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    //Step2 Function ends

    // resend Otp function
    const resendOtp = () => {
        console.log(formikValues);
        setResendButtonDisable(true);
        setButtonText("Processing");
        const counter = resendCounter;
        setResendCounter(counter);
        http.post("/creditSnapshotEnrollStep2", {
            aid: localStorage.getItem('SnapShot_aid'),
            userId: localStorage.getItem('UserId'),
            streetAddress1: formikValues.StreetAddress,
            streetAddress2: formikValues.StreetAddress2,
            city: formikValues.City,
            state: formikValues.State,
            mobile: formikValues.MobileNumber.replace(/[^0-9]/g, ''),
            dob: formatDate(formikValues.DateofBirth1),
            ssn: formikValues.SocialSecurityNumber.replace(/[^0-9]/g, ''),
            zip: formikValues.ZipCode,
            firstName: localStorage.getItem('SnapShot_firstName'),
            lastName: localStorage.getItem('SnapShot_lastName'),
            email: localStorage.getItem('SnapShot_email'),
            password: localStorage.getItem('SnapShot_password'),
            source: "default",
            counter: counter,
            dtoken: localStorage.getItem('Dtoken'),
            rtoken: localStorage.getItem('rtoken'),
            paToken: localStorage.getItem('paToken')
        })
            .then((res) => {
                if (res.data.success === true) {
                    localStorage.setItem("smfaToken", res.data.data.smfaToken);
                    localStorage.setItem("utoken", res.data.data.utoken);
                    alert("Link Resent Successfully");

                } else {
                    alert("Link Resent Failed");
                }
                setButtonText("Resend Link");
                setResendButtonDisable(false);
            })
            .catch((error) => {
                setButtonText("Resend Link");
                setResendButtonDisable(false);
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    const validationSchema = Yup.object().shape({

        StreetAddress: Yup.string()
            .min(1, 'Too Short!')
            .matches(
                /^(?=[A-z0-9\-\. ]*$)/,
                'Please enter a valid Street Adress'
            )
            .required('Street address is required'),

        StreetAddress2: Yup.string()
            .min(1, 'Too Short!')
            .matches(
                /^(?=[A-z0-9\-\. ]*$)/,
                'Please enter a valid Street Adress'
            ),

        City: Yup.string()
            .required('City is required'),

        State: Yup.string()
            .required('Please select state'),

        ZipCode: Yup.string()
            .min(5, 'ZipCode must be of 5 digit!')
            .max(5, 'ZipCode must be of maximum 5 digit!')
            
            .required('Zipcode is required'),

        MobileNumber: Yup.string()
            .min(14, 'Phone number must be of 10 digit!')
            .max(14, 'Phone number must be of maximum 10 digit!')
            .required('Mobile number is required'),

        SocialSecurityNumber: Yup.string()
            .min(11, 'Social security number must be of 9 digit!')
            .max(11, 'Social security number must be of maximum 9 digit!')
            .required('Social security number is required'),
        DateofBirth1: Yup.string()
            .required('Please enter Date of Birth'),
            tc: Yup.boolean()
            .oneOf([true], 'Please accept the terms and conditions.'),
    });
    const handleDateChange = (event) => {
        const { value } = event.target;
        // setInputDate(value);

        //check date format
        const regexPattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        const checkformat = regexPattern.test(value);
        if (checkformat) {

            const [month, day, year] = value.split('/');
            const parsedDate = new Date(`${year}-${month}-${day}`);

            // Check if the parsed date is valid
            const isValidDate = !isNaN(parsedDate) && parsedDate.toISOString().slice(0, 10) === `${year}-${month}-${day}`;
            setIsValidFormat(isValidDate);
            if (!isValidDate) {
                setDateFormatErrorMsg("The birth date must be formatted as MM/DD/YYYY");
                return;
            }

        } else {
            setIsValidFormat(false);
            setDateFormatErrorMsg("The birth date must be formatted as MM/DD/YYYY");
            return;
        }
        //check less than 18 years old
        const inputDateParsed = parse(value, 'MM/dd/yyyy', new Date());
        const eighteenYearsAgo = subYears(new Date(), 18);
        const isValid = isBefore(inputDateParsed, eighteenYearsAgo);

        setDateValid(isValid);
        if (!isValid) {
            setLessthanErrorMsg("You must be at least 18 to be a member");
        } else {
            setLessthanErrorMsg("");
        }
    };
    //fetch city and state from zipcode
    const fetchLocationInfo = async (zipcode, setFieldValue) => {
        const apiUrl = "/zipcode/"+zipcode;
        setFieldValue('City','');
        setFieldValue('State','');
        if(zipcode.length == 5)
        {
            setFieldValue('City','...');
            setFieldValue('State','...');
            handleFreezStart();
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res.success)
            {
                setFieldValue('City', res.data.places[0]['place name']);
                setFieldValue('State', res.data.places[0]['state abbreviation']);
                handleFreezStop();
            }
            else
            {
                setFieldValue('City','');
                setFieldValue('State','');
                handleFreezStop();
            }
        }
    };
    return (
        <>
            <Box>
                <LoginHeader aid={aid} logoName={'creditSnapShot'} />
            </Box>
            <Box mt={3} mb={3} sx={{ paddingLeft: "5%", paddingRight: "5%" }}>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stepper activeStep={1} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                    {/* <Grid item lg={8} md={6} sm={12} xs={12} > */}
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "right" }}> */}
                            {/* <PhoneInTalkOutlinedIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography>
                                Call a Credit Specialist Now at {formatPhoneNumber(localStorage.getItem('SnapShot_phone'))} We can help!
                            </Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                           
                        {/* </Grid> */}
                    {/* </Grid> */}
                </Grid>
                <Card variant="outlined" p={2}>
                    <CardContent>
                        <Formik
                            initialValues={{
                                StreetAddress: '',
                                StreetAddress2: '',
                                City: '',
                                State: '',
                                ZipCode: '',
                                MobileNumber: '',
                                DateofBirth: '',
                                DateofBirth1: '',
                                SocialSecurityNumber: '',
                                tc: false
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                // Handle form submission logic here
                                setSubmitting(false);
                                SnapShotErollStep2(values);
                            }}
                        >

                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                <p>
                                                    <span style={{ fontWeight: 600, fontSize: "1.1rem" }}>You’re just a few steps away! Equifax needs your date of birth and social security number to access your score and credit summary.</span>
                                                </p>
                                                <Typography level="body1">Your data is secure. Checking your own score will not hurt your credit.</Typography>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                   
                                                    <FormControl variant="outlined" fullWidth
                                                        margin="dense" size="small">
                                                        <InputLabel>Street Address*</InputLabel>
                                                        <OutlinedInput
                                                            id="StreetAddress"
                                                            name="StreetAddress"
                                                            label="Street Address*"
                                                            error={touched.StreetAddress && !!errors.StreetAddress}
                                                            helperText={touched.StreetAddress && errors.StreetAddress}
                                                            value={values.StreetAddress}
                                                            autoComplete="StreetAddress"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onKeyDown={()=>setErrorMsg('')}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                    >
                                                                        {touched.StreetAddress && !errors.StreetAddress ? (
                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            touched.StreetAddress && errors.StreetAddress ? (
                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }

                                                        />
                                                        <FormHelperText style={{ color: 'red' }}>
                                                            {touched.StreetAddress && errors.StreetAddress}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <TextField
                                                        id="StreetAddress2"
                                                        name="StreetAddress2"
                                                        label="Street Address line 2 (Optional)"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        autoComplete="StreetAddress2"
                                                        error={touched.StreetAddress2 && !!errors.StreetAddress2}
                                                        helperText={touched.StreetAddress2 && errors.StreetAddress2}
                                                        value={values.StreetAddress2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyDown={()=>setErrorMsg('')}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <InputMask
                                                        mask='(999) 999-9999'
                                                        maskChar={null}
                                                        name='MobileNumber'
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        id='MobileNumber'
                                                        autoComplete="MobileNumber"
                                                        error={touched.MobileNumber && !!errors.MobileNumber}
                                                        value={values.MobileNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyDown={()=>setErrorMsg('')}
                                                    >
                                                        {(inputProps) =>
                                                            <div>
                                                                <TextField
                                                                    label="Phone Number*" variant="outlined"
                                                                    {...inputProps}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton edge="end">
                                                                                    {touched.MobileNumber && !errors.MobileNumber ? (
                                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                                    ) : (
                                                                                        touched.MobileNumber && errors.MobileNumber ? (
                                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                                        ) : null
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <FormHelperText style={{ color: 'red' }}>
                                                                    {touched.MobileNumber && errors.MobileNumber}
                                                                </FormHelperText>
                                                            </div>
                                                        }
                                                    </InputMask>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                   
                                                    <InputMask
                                                        mask='99999'
                                                        maskChar={null}
                                                        id="ZipCode"
                                                        name="ZipCode"
                                                        label="Zip Code*"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        autoComplete="ZipCode"
                                                        error={touched.ZipCode && !!errors.ZipCode}
                                                        helperText={touched.ZipCode && errors.ZipCode}
                                                        value={values.ZipCode}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            fetchLocationInfo(e.target.value, setFieldValue);
                                                        }}
                                                        onKeyDown={()=>setErrorMsg('')}
                                                        onBlur={handleBlur}
                                                    >
                                                    {(inputProps) =>
                                                       <TextField
                                                       label="Zip Code*" variant="outlined"
                                                            {...inputProps}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end">
                                                                        {touched.ZipCode && !errors.ZipCode ? (
                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            touched.ZipCode && errors.ZipCode ? (
                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                       />
                                                    }
                                                </InputMask>
                                                    
                                                </Grid>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                  
                                                    <FormControl variant="outlined" fullWidth
                                                        margin="dense" size="small">
                                                        <InputLabel>City*</InputLabel>
                                                        <OutlinedInput
                                                            id="City"
                                                            name="City"
                                                            label="City*"
                                                            autoComplete="City"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        edge="end"
                                                                    >
                                                                        {touched.City && !errors.City ? (
                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                        ) : (
                                                                            touched.City && errors.City ? (
                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                            ) : null
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            error={touched.City && !!errors.City}
                                                            helperText={touched.City && errors.City}
                                                            value={values.City}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onKeyDown={()=>setErrorMsg('')}
                                                        />
                                                        <FormHelperText style={{ color: 'red' }}>
                                                            {touched.City && errors.City}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <FormControl fullWidth size='small' margin="dense"
                                                        error={touched.State && errors.State ? errors.State : null}
                                                    >
                                                        <InputLabel id="demo-multiple-name-label">State*</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            labelId="demo-simple-select-label"
                                                            name="State"
                                                            autoComplete="State"
                                                            error={touched.State && !!errors.State}
                                                            helperText={touched.State && errors.State}
                                                            value={values.State}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onClick={()=>setErrorMsg('')}
                                                            id="State"
                                                            MenuProps={MenuProps}
                                                            input={<OutlinedInput label="State*" />}
                                                        >
                                                            {states.map((state, id) => {
                                                                return (
                                                                    <MenuItem value={state.state_code}>{state.state_name}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                        {touched.State && errors.State ? <FormHelperText style={{ color: 'red' }}>Please select State.</FormHelperText> : null}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    {/* <Grid > */}
                                                        <InputMask
                                                            mask='999-99-9999'
                                                            maskChar={null}
                                                            placeholder="555-55-5555"
                                                            name='SocialSecurityNumber'
                                                            id='SocialSecurityNumber'
                                                            autoComplete="SocialSecurityNumber"
                                                            error={touched.SocialSecurityNumber && !!errors.SocialSecurityNumber}
                                                            helperText={touched.SocialSecurityNumber && errors.SocialSecurityNumber}
                                                            value={values.SocialSecurityNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onKeyDown={()=>setErrorMsg('')}
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                        >
                                                            {(inputProps) =>
                                                            <div>
                                                                <TextField
                                                                    label="Social Security Number - Full*" 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    margin="dense"
                                                                    size="small"
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                    <IconButton edge="end">
                                                                                        {touched.SocialSecurityNumber && !errors.SocialSecurityNumber ? (
                                                                                            <CheckIcon style={{ color: 'green' }} />
                                                                                        ) : (
                                                                                            touched.SocialSecurityNumber && errors.SocialSecurityNumber ? (
                                                                                                <CloseIcon style={{ color: 'red' }} />
                                                                                            ) : null
                                                                                        )}
                                                                                    </IconButton>
                                                                                    <Tooltip
                                                                                        placement='top'
                                                                                        title="Your social security number is needed to confirm your identity and retrieve your credit report and score." arrow>
                                                                                        <IconButton
                                                                                          edge="end" 
                                                                                        >
                                                                                            <img alt="#" src={require("../../../assets/images/i_icon.png")} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                            </InputAdornment>
                                                                        ),
                                                                        ...inputProps,
                                                                    }}
                                                                />
                                                                <FormHelperText style={{ color: 'red' }}>
                                                                    {touched.SocialSecurityNumber && errors.SocialSecurityNumber}
                                                                </FormHelperText>
                                                            </div>
                                                            }
                                                        </InputMask>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <FormControl fullWidth size='small'>
                                                        <InputMask
                                                            mask='99/99/9999'
                                                            maskChar={null}
                                                            name="DateofBirth1"
                                                            id='DateofBirth1'
                                                            autoComplete="DateofBirth1"
                                                            inputRef={inputRef1}
                                                            value={values.DateofBirth1}
                                                            maxDate={maxDate}
                                                            minDate={minDate}
                                                            label="Date of Birth*"
                                                            onChange={(e) => {
                                                                handleDateChange(e);
                                                                handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            onKeyDown={()=>setErrorMsg('')}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            margin="dense"
                                                            placeholder="MM/DD/YYYY"
                                                            error={touched.DateofBirth1 && !!errors.DateofBirth1}
                                                        >
                                                            {(inputProps) =>
                                                            <div>
                                                                <TextField
                                                                    label="Date of Birth*" variant="outlined"
                                                                    {...inputProps}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton edge="end">
                                                                                {touched.DateofBirth1 && !errors.DateofBirth1  && isValidFormat && isDateValid ?  (
                                                                                        <CheckIcon style={{ color: 'green' }} />
                                                                                    ) : (
                                                                                        (touched.DateofBirth1 && errors.DateofBirth1  || !isValidFormat || !isDateValid) ?  (
                                                                                            <CloseIcon style={{ color: 'red' }} />
                                                                                        ) : null
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <FormHelperText style={{ color: 'red' }}>
                                                                {touched.DateofBirth1 && errors.DateofBirth1}
                                                            </FormHelperText>
                                                        </div>
                                                            }
                                                        </InputMask>

                                                    </FormControl>
                                                    <FormHelperText sx={{ color: "#d32f2f" }}>{!isValidFormat ? dateFormatErrorMsg : !isDateValid ? lessthanErrorMsg : ''}</FormHelperText>
                                                   
                                                </Grid>
                                            </Grid>
                                            {errormsg ? <>
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <Grid item lg={11} md={11} sm={11} xs={11}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <p className="error" style={{ fontSize: "1.0rem" }}><b>Oops! Encountered some error. Please try again later.</b></p>
                                                    <p className="error"  style={{ fontSize: "0.9rem" }}><b>**{errormsg}</b></p>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            </>
                                            : null}
                                               
                                                
                                            <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                <Grid sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Tooltip title={<div><b>Why do we need</b><br /><p>We communicate directly with the credit bureaus to obtain your most accurate and up to date credit report. In order for them to locate your inforamtion, they use a combination of your SSN, DOB and personal information to make sure we are showing you the correct credit report.</p><p>We also use this information to make sure we're not showing your information to anyone that's not you.</p></div>} placement="top" arrow>
                                                        <span><img alt="#" style={{ height: "25px" }} src={require("../../../assets/images/Blue_question_mark.jpg")} /><span style={{ fontSize: "1.2rem" }} ><b>Why do we need this information?</b></span>
                                                        </span></Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                               
                                                <div className="form-group">
                                                    <div className=" pull-right input-group checkbox">
                                                        <label htmlFor="isConfirmedTerms">
                                                        <Field type="checkbox"
                                                checked={tc}
                                                name="tc"
                                                onClick={() => {
                                                    settc(!tc);
                                                    settcflag(tc);
                                                }}/>
                                                           
                                                            &nbsp;By checking this box: I acknowledge that I have read, understand, agree to and accept the&nbsp;
                                                            <a href="#" onClick={toggleDrawer1(true)} style={{ cursor: "pointer" }}>Terms and Conditions</a>
                                                            <SwipeableDrawer
                                                                anchor={'bottom'}
                                                                open={open1}
                                                                onClose={toggleDrawer1(false)}
                                                                onOpen={toggleDrawer1(true)}
                                                            >
                                                                <TermsAndConditions closeVal={toggleDrawer1(false)} />
                                                            </SwipeableDrawer>
                                                            ,&nbsp;
                                                            <a href="#" onClick={toggleDrawer2(true)} style={{ cursor: "pointer" }}>
                                                                Cancellation Policy
                                                            </a>
                                                            <SwipeableDrawer
                                                                anchor={'bottom'}
                                                                open={open2}
                                                                onClose={toggleDrawer2(false)}
                                                                onOpen={toggleDrawer2(true)}
                                                            >
                                                                <CancelPolicy closeVal={toggleDrawer2(false)} />
                                                            </SwipeableDrawer>
                                                            ,&nbsp;
                                                            <a href="#" onClick={toggleDrawer3(true)} style={{ cursor: "pointer" }}>
                                                                Privacy Policy
                                                            </a>
                                                            <SwipeableDrawer
                                                                anchor={'bottom'}
                                                                open={open3}
                                                                onClose={toggleDrawer3(false)}
                                                                onOpen={toggleDrawer3(true)}
                                                            >
                                                                <PrivacyPolicy closeVal={toggleDrawer3(false)} />
                                                            </SwipeableDrawer>,and&nbsp;
                                                            <a href="#" onClick={toggleDrawer4(true)} style={{ cursor: "pointer" }}>
                                                                Refund Policy
                                                            </a>
                                                            <SwipeableDrawer
                                                                anchor={'bottom'}
                                                                open={open4}
                                                                onClose={toggleDrawer4(false)}
                                                                onOpen={toggleDrawer4(true)}
                                                            >
                                                                <RefundPolicy closeVal={toggleDrawer4(false)} />
                                                            </SwipeableDrawer>
                                                            .
                                                        </label>
                                                    </div>
                                                </div>
                                            </Grid>
                                            
                                            {errors.tc && errors.tc && (
                                <FormHelperText style={{color: "#d32f2f",backgroundColor: '#ffffffad',paddingLeft:'3px'}}>{errors.tc}</FormHelperText>
                            )}
                                            
                                            <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ display: "flex", justifyContent: "right" }}>
                                                <Button color="warning" type="submit" sx={{ backgroundColor: "#ED7E2E", color: "white", minWidth: 200, borderRadius: "0%" }} >Next</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>

                            )}

                        </Formik>
                    </CardContent>
                </Card>
            </Box>
            <Box>
                <RegisterFooter />
            </Box>


            {/* OTP Dialog Start */}
            <Dialog open={open}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseDialog();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}>
                <DialogTitle sx={{ bgcolor: "#F6FAFF" }}>
                    <Grid container item xs sx={{ justifyContent: "space-between" }}>
                        <Grid item xs>
                            <Typography level="h4" color="primary">Authenticate Identity</Typography>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseDialog}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* For security purposes, we have sent an authentication link to your mobile number({mobilenumbertext}). Please check your phone and click on the autentication link once you authorized by that link click here, */}
                        You should have received a text on your mobile with an authentication link to verify your identity. 
                        Please click on the link to authenticate yourself.
                        Once done, please click the button below to continue to your credit score.
                    </DialogContentText>
                    
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent : "center" }}>
                        <Button color="primary" sx={{ minWidth: 100 }} onClick={handleSubmitOtp}>
                            Continue To View My FREE Credit Score
                        </Button>
                    </Grid>

                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} sx={{ justifyContent: "right" }}>

                        <Typography>Haven't received the authorization link yet?</Typography>&nbsp;&nbsp;
                        <Button disabled={resendButtonDisable} onClick={resendOtp} color="success" sx={{ bgcolor: "#76C044", color: "white", minWidth: 100 }}>{buttonText}</Button>
                    </Grid>
                </DialogContent>
                {/* </DialogActions> */}
            </Dialog>
            {/* OTP Dialog End */}

            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default EnrollStep2;