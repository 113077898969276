import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import {Box,Grid,Card,CardContent,Dialog, DialogActions,DialogContent, DialogTitle,DialogContentText} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginHeader from '../../../Components/LoginHeader';
import { Typography,Button } from '@mui/joy';
import RegisterFooter from '../../../views/Auth/RegisterFooter';
import { useDispatch } from 'react-redux';
import { fetchData ,postData} from '../../../redux/apiSlice';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
function OfferThankyou() {
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);

    const getFlag = searchParams.get('flag'); 
    const getId = searchParams.get('id');
    const [accepted,setOfferAccepted] = useState('');
    const [offername,setOfferName] = useState('');
    const [id,setId]=useState(atob(getId));
    const [flag,setFlag]=useState(getFlag);
    const [disable, setDisable] = useState(0)
    const location = useLocation();
    const navigate = useNavigate();
    const [acceptedFlag , setAcceptedFlag] = useState(0);
    // const [acceptedFlagStatus, setAceptedFlagStatus] = useState(0);

    const [freez, setFreez] = React.useState(true);
    const handleFreezStop = () => {
      setFreez(false);
    };
    const handleFreezStart = () => {
      setFreez(true);
    };

    useEffect(() => {
        if(flag == 0)
        {
            fetchMemberDetails(id);
            // setAcceptedFlag(1);
        }else{
            fetchMemberDetails(id);
            handleAccept();
        }
    }, []);


    const fetchMemberDetails = async(id) => {
        handleFreezStart();
        const apiUrl = `/getmember/`+ id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res)
        {
            let offer = '';
            if(res.data['offer_send']){
                offer = res.data['offer_send'] == 'A' ? "FREE MONTH & $19.95/mo" : 'FREE MONTH';
            }
            setOfferName(offer);
            setOfferAccepted(res.data['offer_accepted']);
            if(flag == 0 && res.data['offer_accepted'] == 0 ||res.data['offer_accepted'] == null)
            {
                setShowPopup(true);
            }  
            handleFreezStop();
        } 
    }

    const handleClose = () => {
        setDisable(1);
        setShowPopup(false);
    };

    const handleAccept = async () => {
        const ReactButton = 1;
        const apiUrl = `/offerAccepted/`+id+`/`+ReactButton+'/'+acceptedFlag;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        setShowPopup(false);
     }

    return (
        <>
        <LoginHeader />
        <Box className="back" sx={{ padding: "2%" }}>
            <Card sx={{padding:" 0% 0% 4% 4% "}}>
                <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4}>
                        <Grid item lg={4} md={6} sm={12} xs={12} >   
                            <img src={require("../../../assets/images/thankyou.png")} style={{display:"block",marginLeft:"auto",marginRight:"auto"}} className="images"/>
                            {/* <Button id="submitbtn" onClick={()=>navigate('/login')} >Login Now</Button> */}
                        </Grid>
                        <Grid item lg={8} md={6} sm={12} xs={12}>
                        {
                        !showPopup ? (
                            <>
                                {disable === 0 ? (
                                    accepted === 0 || accepted === null ? (
                                        <Typography level='h3'>
                                            Thank you for redeeming the offer. <br />
                                            One of our Customer Support Specialists will call to help you avail the offer. <br />
                                            For any questions contact us at 1-888-548-2008.
                                        </Typography>
                                    ) : (
                                        <Typography level='h3'>
                                            Thank you, You have already redeemed your offer.<br />
                                            For any questions contact us at 1-888-548-2008.
                                        </Typography>
                                    )
                                ) : (
                                    <Typography level='h3'>
                                        Thank you.
                                    </Typography>
                                )}
                            </>
                        ) : null
                    }
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card>
        </Box>
        <RegisterFooter />

         {/* loader code start */}
         <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={freez}
            // onClick={handleFreezStop}
          >
            <Freez />
          </Backdrop>
        </div>
        {/* loader code end */}

        {/* Popup */}
        <Dialog
                open={showPopup}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Congratulations!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your exclusive offer: {offername} is ready to be redeemed.<br />
                        Please confirm.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAccept} color="primary"> 
                        Accept
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
        </Dialog>

        

        </> 
    );
}
export default OfferThankyou;