import React, { useState, useEffect } from 'react';
import OneBHeader from './1BHeader';
import { Box, Button, Grid, Alert, Tabs, Tab, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, Table,  } from '@mui/joy';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTab-root': {
        color: '#C11F2B', // Set the default text color
      },
      '& .Mui-selected': {
        backgroundColor: '#C11F2B !important', // Set the background color for the active tab
        color: '#FFFFFF !important', // Set the text color for the active tab
      },
      '& .MuiTabs-indicator':{
        backgroundColor: 'transparent !important',
      },
    },
  }));
function TabPanel(props) {
    
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function OneBreportView() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const currentYear = new Date().getFullYear();
    return (
        <>
            <OneBHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} p={4}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Typography level="h4"><span><img alt="#" src={require("../../../assets/images/equifax.png")} className="equi" width="12%" height="auto" />
                            </span> Credit Report with Vantage<span style={{ color: "blue" }}> 3.0</span>
                                <span> <Tooltip title="Vantage 3.0 uses the same 300-850 range as base FICO" placement="top" arrow><img alt="#" src={require("../../../assets/images/i_icon.png")} /></Tooltip></span> <span> Score</span></Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12} sx={{ textAlign: "right" }} className='copy'>
                            <span> <Button variant='contained' size="medium">Back</Button> &nbsp;&nbsp;&nbsp;<LocalPrintshopIcon color="primary" size="large" /></span>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <Alert icon={false} severity="warning" onClose={() => { }}>Your 1-Bureau Report is 227 days old</Alert>
                    </Grid>
                </Grid>
                <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: 2, borderColor: '#C11F2B'}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        centered
                        className={classes.root}>
                        <Tab label="Personal Information" {...a11yProps(0)} />
                        <Tab label="Employment History" {...a11yProps(1)} />
                        <Tab label="Account History" {...a11yProps(2)} />
                        <Tab label="Collections"  {...a11yProps(3)} />
                        <Tab label="Inquiries"  {...a11yProps(4)} />
                        <Tab label="Public Records"  {...a11yProps(5)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Box>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Table aria-label="basic table" size='sm' sx={{paddingLeft:"2%",paddingRight:"2%"}}>
                                <thead>
                                    <tr>
                                        <th colSpan={2} style={{ backgroundColor: "#c11f2b", textAlign: "center" }}><Typography level='h4' sx={{ color: "white" }}>Personal Information</Typography></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Typography level="h6">Provider</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>EFX</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ID</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>1BR:d47a9113ef89--97a4-d04b3a1d-0eea829f</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">REPORT GENERATED</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>2022-07-17</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">REPORT TYPE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>US_EFX</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">NAME</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>ELVIA J FKNKDLGP</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">CURRENT ADDRESS</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>22 WEKIVA POINTE CIR<br />
                                            APOPKA 32712 FL USA</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">PREVIOUS ADDRESS</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>
                                            1) 11 PATMORE ASH WY APOPKA 32703 FL USA
                                            First Reported Date :2020-03-31
                                            Last Reported Date :2020-03-27<br />
                                            2) 866 COMMONWEALTH CT CASSELBERRY 32707 FL USA
                                            First Reported Date :2019-02-28
                                            Last Reported Date :2019-02-28<br />
                                            3) 617 MAJESTIC OAK DR APOPKA 32712 FL USA
                                            First Reported Date :2016-09-30
                                            Last Reported Date :2016-09-27
                                        </Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">SSN</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>xxxxx 8397</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">DATE OF BIRTH</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>1984-06-21</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">FREEZE STATUS</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>No</Typography></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Grid>

                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Table aria-label="basic table" size='sm' sx={{paddingLeft:"2%",paddingRight:"2%"}}>
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#c11f2b", textAlign: "center" }}><Typography level='h4' sx={{ color: "white" }}>Employer Name</Typography></th>
                                        <th style={{ backgroundColor: "#c11f2b", textAlign: "center" }}><Typography level='h4' sx={{ color: "white" }}>RELIANCE INTERIO</Typography></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Typography level="h6">EMPLOYER TITLE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>ADMINS ASSIT</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">CURRENT EMPLOYER</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>Yes</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ORDINAL</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>1</Typography></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box >
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{paddingLeft:"2%",paddingRight:"2%"}}>
                            <Table aria-label="basic table" size='sm'>
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: "#c11f2b", textAlign: "center" }}><Typography level='h4' sx={{ color: "white" }}>Revolving Account</Typography></th>
                                        <th style={{ backgroundColor: "#c11f2b", textAlign: "center" }}><Typography level='h4' sx={{ color: "white" }}>BANK OF AMERICA</Typography></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Typography level="h6">PROVIDER ID</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>115308901</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ACCOUNT OPEN</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>No</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ACCOUNT NAME</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>BANK OF AMERICA</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">CONTACT INFORMATION</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>BANK OF AMERICA</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">CODE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>USA</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ADDRESS</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>4060 OGLETOWN/STANTON RD DE5-019-03-07</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">CITY</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>NEWARK</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">STATE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>DE</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ZIP</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>19713</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">MOBILE NUMBER</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>+1(800) 421-2110</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ACCOUNT NUMBER</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>xxxxxxxx 8000</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ACCOUNT STATUS</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>CHARGE_OFF</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">PAYMENT RESPONSIBLITY</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>INDIVIDUAL</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ACCOUNT TYPE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>REVOLVING</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">TERM DURATION MONTHS</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>0</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">TERM FREQUENCY</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>MONTHLY</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">DATE OPENED</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>2011-03-31</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">BALANCE AMOUNT</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>$5761</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">MONTHLY PAYMENT</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>$0</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ACTUAL PAYMENT</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>$0</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">REPORTED DATE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>1139</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">LAST PAYMENT DATE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>2020-06-30</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">MAJOR DELINQUENCY FIRST REPORT DATE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>2021-01-31</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">DELINQUENCY FIRST REPORT DATE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>2020-08-31</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">REVIEWED</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>34</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">CREDITOR CLASSIFICATION</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>UNKNOWN</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">DESIGNATOR</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>CLOSED</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">CHARGE OFF AMOUNT</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>4427</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">CURRENCY</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>USD</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">LOAN TYPE CODE</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>CREDIT_CARD</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">LOAN TYPE DESCRIPTION</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>Credit Card</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">ACCOUNT CONDITION</Typography></td>
                                        <td><Typography level='h6' sx={{ fontWeight: 400 }}>Negative</Typography></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table aria-label="basic table" size='sm'>
                                <thead>
                                    <tr>
                                        <th colSpan={4} style={{ textAlign: "center" }}><Typography level='h6' ><b>Comments</b></Typography></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Typography level="h6">Code</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>DB</Typography></td>
                                        <td><Typography level="h6" >Description</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>Charged off account</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6">Code</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>DB</Typography></td>
                                        <td><Typography level="h6" >Description</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>Charged off account</Typography></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Table aria-label="basic table" size='sm'>
                                <thead>
                                    <tr>
                                        <th colSpan={12} style={{ textAlign: "center" }}><Typography level='h6' ><b>Payment History</b></Typography></th>
                                    </tr>
                                    <tr>
                                        <th colSpan={12} style={{ textAlign: "center" }}><Typography level='h6' ><b>2021</b></Typography></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Typography level="h6">JAN</Typography></td>
                                        <td><Typography level="h6">FEB</Typography></td>
                                        <td><Typography level="h6">MAR</Typography></td>
                                        <td><Typography level="h6">APR</Typography></td>
                                        <td><Typography level="h6">MAY</Typography></td>
                                        <td><Typography level="h6">JUN</Typography></td>
                                        <td><Typography level="h6">JUL</Typography></td>
                                        <td><Typography level="h6">AUG</Typography></td>
                                        <td><Typography level="h6">SEP</Typography></td>
                                        <td><Typography level="h6">OCT</Typography></td>
                                        <td><Typography level="h6">NOV</Typography></td>
                                        <td><Typography level="h6">DEC</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>120</Typography></td>
                                        <td><Typography level="h6" sx={{ fontWeight: 400 }}>N/A</Typography></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Grid>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Box >
                    <Table aria-label="basic table" size='sm' sx={{paddingLeft:"2%",paddingRight:"2%"}}>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{ backgroundColor: "#c11f2b", textAlign: "center" }}><Typography level='h4' sx={{ color: "white" }}>Collections</Typography></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td sx={{ textAlign: "center" }}><Typography level="h6">None Reported</Typography></td>
                            </tr>
                        </tbody>
                    </Table>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Box >
                    <Table aria-label="basic table" size='sm' sx={{paddingLeft:"2%",paddingRight:"2%"}}>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{ backgroundColor: "#c11f2b", textAlign: "center" }}><Typography level='h4' sx={{ color: "white" }}>Inquiry</Typography></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><Typography level="h6">TYPE</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>HARD</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">PREFIX CODE</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>CR</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">PREFIX DESCRIPTION</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>Credit Report (Including End User)</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">INQUIRIES REPORTED DATE</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>2022-05-27</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">INQUIRIES CONTACT NAME</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>CHASE AUTO FIN/NATL ACCTS</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">INQUIRIES ADDRESS</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>900 STEWART AVE 6TH FL</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">INQUIRIES CITY</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>GARDEN CITY</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">INQUIRIES STATE</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>NY</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">INQUIRIES ZIP</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>11530</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">INQUIRIES COUNTRY CODE</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>USA</Typography></td>
                            </tr>
                            <tr>
                                <td><Typography level="h6">INQUIRIES MOBILE NO</Typography></td>
                                <td><Typography level="h6" sx={{ fontWeight: 400 }}>+1(516) 745-3687</Typography></td>
                            </tr>
                        </tbody>
                    </Table>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <Box >
                    <Table aria-label="basic table" size='sm' sx={{paddingLeft:"2%",paddingRight:"2%"}}>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{ backgroundColor: "#c11f2b", textAlign: "center" }}><Typography level='h4' sx={{ color: "white" }}>Public Records</Typography></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td sx={{ textAlign: "center" }}><Typography level="h6">None Reported</Typography></td>
                            </tr>
                        </tbody>
                    </Table>
                    </Box>
                </TabPanel>
                <Grid container item lg={12} md={12} sm={12} xs={12} p={3} id="bannerdata">
                <Card variant="outlined" className="card-4" mt={1}>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                            <Grid item lg={2} md={2} sm={4} xs={4} className="padd ">
                                <img alt="#" src={require('../../../assets/images/mainLogo.jpg')} className='logo' />
                            </Grid>
                            <Grid item lg={1} md={1} sm={4} xs={4}>
                                <img alt="#" src={require('../../../assets/images/arrowb.png')} className="arrowb" style={{width:"auto",height:"100%"}}/>
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4} sx={{textAlign:"center"}}>
                                <Grid item xs>
                                    <Typography sx={{ color: "#5288db"}} level="h6">SCORES CAN VARY SIGNIFICANTLY BY BUREAU!</Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography level="h6" sx={{ color: "#27d027c4" }}>Check Yours from all 3 Credit Bureaus Now</Typography>
                                </Grid>
                                <Grid item xs>
                                    <img alt="#" src={require("../../../assets/images/Logogroup.png")} style={{width:"15vw",height:"auto"}}/>
                                </Grid>
                            </Grid>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <Table variant="outlined" sx={{ textAlign: "center" }} size='sm'>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "center" }}><Typography level='body1'>7-day trial ends Jun 12, 2023</Typography></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography level="h6">
                                                    $1.00 non-refundable member activation fee charged at time of order.
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography level="h6">
                                                    $24.97 per month charged automatically thereafter.
                                                </Typography>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Grid>
                            <Grid item lg={2} md={2} sm={12} xs={12} >
                                <Button variant='contained' size="large" color="success" sx={{ backgroundColor: "#5cb85c", color: "white" }}>GET IT NOW</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"none"}} id="mobileBanner">
                <img alt="#" src={require('../../../assets/images/mobilebanner.png')} style={{width:"100%"}}/>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Typography >Powered by MyFreeScoreNow Inc. Copyright © {currentYear}. All Rights Reserved</Typography>
                </Grid>
            </Box>
        </>

    );
}

export default OneBreportView;
