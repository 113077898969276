import React, { useMemo, useState, useEffect } from 'react';
//MRT Imports
import MaterialReactTable from 'material-react-table';
import Moment from 'moment';
import AuthUser from '../Auth/AuthUser';
import config from '../../AppConfig';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import { Alert, Grid, Button, Dialog, DialogActions, FormControl, Box, InputLabel, Select, MenuItem, DialogContent, DialogTitle, TextField, Typography, Tooltip } from '@mui/material';
import AiFillPhone from '@mui/icons-material/Phone';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Loader/Loader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import DialogContentText from '@mui/material/DialogContentText';
import swal from "sweetalert";
import {openOrFocusWindow } from  '../CommonFormula';
import { MenuProps } from "../SuspendedMembers/utils";


function Accepted(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {  user } = AuthUser();
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [contactLogPopup, setcontactLogPopup] = useState(false);
  const [DispositionList, setDisposition] = useState([]);
  const [reasonList, setReason] = useState([]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [memberId, setMemberId] = useState();
  const [emailId, setemailId] = useState();
  const [OpenAssignToMe, setOpenAssignToMe] = useState(false);
  const [noteError, setNoteError] = useState();
  const [OpenReasonPopup, setOpenReasonPopup] = useState(false);
  const [offerAccepted, setOfferAccepted] = useState();
  const [errors1, setErrors] = useState({});
  const [reasonVal, setReasonVal] = useState(0);
  const [contactTypeList, setCallTypeList] = useState([]);
  
  const [freez, setFreez] = React.useState(false);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };

  const RequestabortFirstStep = {
     callType: 'Member retention list',
     disposition: '',
     note: '',
     reason: '',
     custMemberId:'',
     callSource:'',
}

const fetchCallTypeList = async () => {
  const apiUrl = '/fetchContactLogCallType'; // Replace with your API endpoint
  const res = await dispatch(fetchData(apiUrl)).unwrap();
  if (res) {
    setCallTypeList(res);
  }
}

  const tablePreferencesArray = {
    plan: false,
    phone_no: false,
    SubscriptionDate:false,
  }

  useEffect(() => {
    if (props.tableData && props.tableData.length > 0) {
      setData(props.tableData);
    }
    else{
      setData([]);
    }
  
    setIsLoading(false);
  }, [props.tableData])

    
    const RequestassignToMe = {
        assignReason: '',
    }
    const validationassignToMe = Yup.object().shape({
        assignReason: Yup.string()
          .required('Please enter reason'),
    
      });

    const requestReason = {
      reason: '',
    }
    const validatioReason = Yup.object().shape({
      reason: Yup.string()
        .required('Please enter reason'),
  
    });

  



  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
    handlecallType();
  }, [rowSelection]);

  useEffect(()=>{  
    saveTablePreferences();
  },[columnVisibility])

  useEffect(()=>{  
    fetchCallTypeList();
  },[])

  const tablePreferences = async() => {
    const apiUrl = '/fetchTablePreferences/retainedMemberlist' ;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if(res) {
          if (Object.values(res).length == 0) {
            setColumnVisibility(tablePreferencesArray);
          } else {
            setColumnVisibility((JSON.parse(res.preferences)));
          }
    }
  }

  const saveTablePreferences = async() => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
          columnArray: columnVisibility,
          userId: user.id,
          tableId: 'retainedMemberlist'
      }
      const res = await dispatch(postData({apiUrl,args})).unwrap();
    } 
  }

  const checkAssignTo = async (memberId, email, acceptedOffer) => {
    handleFreezStart();
    setMemberId(memberId);
    setemailId(email);
    setOfferAccepted(acceptedOffer);
    const apiUrl = '/checkAgentAssinged';
    const args = {
        memberId: memberId,
        email: email,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res.success == true) {
        handleFreezStop();
        setcontactLogPopup(true)
        
    }else{
        handleFreezStop();
        setErrorMessage(res.message);
        setTimeout(function () {
        setErrorMessage('');
      }, 7000);

    }
   
  };
  const handlecallType = async () => {
   const callTypes = 62;
    const apiUrl = '/dispositionList';
    const args = {
      callType: callTypes,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      setDisposition(res.disposition);
    }

  }
  const handledisposition = async (event) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setReasonVal(0);
    const apiUrl = '/getContactReason';
    const args = {
      dispositionId: event,
      offerAccepted:offerAccepted,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();

   
      setReason(res.reason);
    
    
  };
  const RequestFormSchemaabortFirstStep = Yup.object().shape({
    callSource: Yup.string().required('Contact Type required'),
    disposition: Yup.string()
      .required('Please enter disposition'),
    note: Yup.string()
      .required('Please enter note'),
    reason: Yup.string()
      .required('Please enter reason'), 
  })

  const contactLogClose = async () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    setcontactLogPopup(false);
    setOpenReasonPopup(true);
    
    
    
  };

  const handleCloseReason = async (values) =>{
    setOpenReasonPopup(false);

  }
  const reasonSubmit = async (values) => {
    setOpenReasonPopup(false);
      const apiUrl = '/assignedToNull';
      const args = {
        memberId: memberId,
        notes: values.reason,
      };
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      props.getData();
   
  };

  const acceptedSubmit = async (values) => {
    const callTypes = 62;
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      navigate('/login');
      return false;
    }
    if(reasonVal == 0){
      errors1.reason = 'Please enter reason';
      return false;
    }else{
      errors1.reason = '';
    }
   
    setcontactLogPopup(false);
    handleFreezStart();

    const apiUrl = '/addContactLogData';
    const args = {
      calltype: callTypes,
      disposition: values.disposition,
      reason: values.reason,
      notes: values.note,
      memberId:memberId,
      emailId:emailId,
      callSource:values.callSource,
    }
    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if(res.success == true){
        handleFreezStop();
        props.getData();
        setSuccessMessage(res.message);
        setTimeout(function () {
        setSuccessMessage('');
        
      }, 8000);

    }else{
        handleFreezStop();
        setErrorMessage(res.message);
        setTimeout(function () {
        setErrorMessage('');
      }, 7000);
    }
  };

  const handleReason = (event) =>{
    setReasonVal(1);
    if(event == ''){
      errors1.reason = 'Please enter reason';

    }else{
      errors1.reason = '';
    }
  }
    
  const handleClickOpen = (memberId,emailId) => {
      setMemberId(memberId);
      setemailId(emailId);
      setOpenAssignToMe(true);
  };
  const handleCloseAssignToMe = () => {
      setOpenAssignToMe(false);
      
    };
    
    const assignedToMeMembers = async (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
          navigate('/login');
          return false;
        }
        setOpenAssignToMe(false);
        handleFreezStart();
        const apiUrl = '/assignedToMePendingClose';
        const args = {
            memberId:memberId,
            emailId:emailId,
            reason: values.assignReason,
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
          if(res.success == true){
            handleFreezStop();
            swal({
              title: "Success",
              text: res.message,
              icon: "success",
              button: "Ok",
            })     
            props.getData();       
          }else{
            handleFreezStop();
            swal({
              title: "error",
              text: res.message,
              icon: "error",
              button: "Ok",
            })

          }
    
    };
  
  const handleClick = (phone_no) => {
    window.open(`${config.CallHippoDialer}${phone_no}`, 'CallHippo Dialer');
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : ''}`,
        accessorKey: 'member_id',
        header: 'Member ID',
        enablePinning: true,
        width: 100,
        enableClickToCopy: false,
        Cell: ({ renderedCellValue, row }) => (row.original.member_id ? row.original.member_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        accessorKey: 'name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Name',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Email',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorKey: 'PID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'PID',
        width: 100,
      },
      {
        accessorFn: (data) => `${data.AID ? data.AID.trim() : ''}`,
        accessorKey: 'AID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'AID',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.plan ? data.plan.trim() : ''}`,
        accessorKey: 'plan', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Plan',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.plan ? row.original.plan.trim() : "N/A"),
      },
      {
				accessorKey: 'phone_no',
				accessorFn: (row) => `${row.phone_no}`,
				filterVariant: 'range',
				id:'phoneNo',
				header: 'Phone no.',
				size: 50,
				Cell: ({ renderedCellValue,row }) => (
          row.original.phone_no ?
					<div>
						{row.original.phone_no ? formatPhoneNumber(row.original.phone_no.trim()) : "N/A"}
						&nbsp;&nbsp;
				<span>
					{ row.original.current_hours != '' ? 
							row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
							<Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
						:''
					}
				</span>
          <Tooltip title="Dial" arrow>
            <a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_no); }} >
              <AiFillPhone color="primary" />
            </a>
          </Tooltip>
					</div>: 'N/A'
				),
			},
      {
        accessorFn: (data) => `${data.offer_send ? data.offer_send.trim() : ''}`,
        accessorKey: 'offer accepted', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Offer Accepted',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {
                row.original.offer_send == 'A' ? "FREE MONTH & $19.95/mo" : 'FREE MONTH'
            }
          </div>
        ),
      },
      {
        accessorFn: (data) => `${data.accepted_offer_date ? data.accepted_offer_date.trim() : ''}`,
        accessorKey: 'Offer Accepted Date', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Offer Accepted Date',
        width: 100,
        Cell:({renderedCellValue,row}) => (
          <div style={{textAlign:"center"}}>
          {row.original.accepted_offer_date?Moment(row.original.accepted_offer_date).format('MM-DD-YYYY ') :"N/A"}
          </div>          
          ),
      },
      {
        accessorFn: (data) =>`${data.close_date ? data.close_date : '0000-00-00'}`,
        enableClickToCopy: false,
        header: 'Close Date',
        width: 100,
        id:'Date',
        Cell:({renderedCellValue,row}) => (
          <div style={{textAlign:"center"}}>
          {row.original.close_date?Moment(row.original.close_date).format('MM-DD-YYYY ') :"N/A"}
          </div>          
          ),
      },
      
      {
        accessorFn: (data) => `${data.user ? data.user.first_name.trim() + " " + data.user.last_name.trim() : ''}`,
        enableClickToCopy: false,
        header: 'Assigned To',
        width: 100,
        Cell:({renderedCellValue,row}) => (
          <>
            {row.original.user ? row.original.user.first_name.trim() + " " + row.original.user.last_name.trim() : 'N/A'}
           </>          
         ),
      },
      {
        accessorFn: (data) => <>
            <Button onClick={() => { checkAssignTo(data.member_id, data.email,data.offer_send) }}><Tooltip title="Action" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip></Button>
            {data.assign_to != null ? 
            user.id != data.assign_to ?
              <><Button  onClick={() => { handleClickOpen(data.member_id, data.email) }} variant="contained" color="success" sx={{ minWidth: 100 }} >
                Assign to Me
            </Button></>
            :'' :''}
        </>,
        id: 'actions',
        header: 'Actions',
        enableSorting: false,
        enableColumnFilter: false,
      },

    ],
    [],
  );

  const columns2 = useMemo(
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : ''}`,
        accessorKey: 'member_id',
        header: 'Member ID',
        width: 100,
        enablePinning: true,
        enableClickToCopy: false,
        Cell: ({ renderedCellValue, row }) => (row.original.member_id ? row.original.member_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        accessorKey: 'name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Name',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Email',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorKey: 'PID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'PID',
        width: 100,
      },
      {
        accessorFn: (data) => `${data.AID ? data.AID.trim() : ''}`,
        accessorKey: 'AID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'AID',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.plan ? data.plan.trim() : ''}`,
        accessorKey: 'plan', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Plan',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.plan ? row.original.plan.trim() : "N/A"),
      },
      {
				accessorKey: 'phone_no',
				accessorFn: (row) => `${row.phone_no}`,
				filterVariant: 'range',
				id:'phoneNo',
				header: 'Phone no.',
				size: 50,
				Cell: ({ renderedCellValue,row }) => (
					<div>
						{row.original.phone_no ? formatPhoneNumber(row.original.phone_no.trim()) : "N/A"}
						&nbsp;&nbsp;
				<span>
					{ row.original.current_hours != '' ? 
							row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
							<Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
						:''
					}
				</span>
					</div>
				),
			},
      {
        accessorFn: (data) =>`${data.subscription_date ? data.subscription_date : '0000-00-00 00:00:00'}`,
        enableClickToCopy: false,
        header: 'Subscription Date',
        width: 100,
        Cell:({renderedCellValue,row}) => (
          <div style={{textAlign:"center"}}>
          {row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YYYY H:mm') + " Hrs":"N/A"}
          </div>          
          ),
      },
      {
        accessorFn: (data) =>`${data.retained_date ? (data.retained_date != '0000-00-00 00:00:00' ? data.retained_date : 'N/A') : 'N/A'}`,
        enableClickToCopy: false,
        header: 'Retention Date',
        width: 100,
        Cell:({renderedCellValue,row}) => (
          <div style={{textAlign:"center"}}>
          {row.original.retained_date?(row.original.retained_date !='0000-00-00 00:00:00'? Moment(row.original.retained_date).format('MM-DD-YYYY hh:mm') + " Hrs" : "N/A"): "N/A"}
          </div>          
          ),
      },
    ],
    [],
  );
  const handleExportData = (rows) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3){
      navigate('/login');
      return false;
    }

    let exportedData;
    let exportedColumns;

    if (user.roles_id < 3) {
      // Export data and columns for columns
      exportedData = rows.map((row) => {
        return {
          member_id: row.original.member_id || "N/A",
          name: row.original.name || "N/A",
          email: row.original.email || "N/A",
          PID: row.original.PID || "N/A",
          AID: row.original.AID || "N/A",
          plan: row.original.plan || "N/A",
          phone_no: formatPhoneNumber(row.original.phone_no) || "N/A",
          offer_send: row.original.offer_send == 'A' ? "FREE MONTH & $19.95/mo" : 'FREE MONTH',
          subscription_date: `${row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YY hh:mm') + " Hrs" : ''}`,
          retention_date:  `${row.original.retained_date ? (row.original.close_date != '0000-00-00 00:00:00' ? Moment(row.original.close_date).format('MM-DD-YY hh:mm') + " Hrs" : 'N/A') : 'N/A'}`,
          assignedTo: `${row.original.user ? row.original.user.first_name + " " + row.original.user.last_name : 'N/A'}`,
        };
      });

      exportedColumns = columns;
    } else {
      // Export data and columns for columns2
      exportedData = rows.map((row) => {
        return {
          member_id: row.original.member_id || "N/A",
          name: row.original.name || "N/A",
          email: row.original.email || "N/A",
          PID: row.original.PID || "N/A",
          AID: row.original.AID || "N/A",
          plan: row.original.plan || "N/A",
          phone_no: formatPhoneNumber(row.original.phone_no) || "N/A",
          subscription_date: `${row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YY hh:mm') + " Hrs" : ''}`,
          retention_date: `${row.original.retained_date ? (row.original.retained_date != '0000-00-00 00:00:00' ? Moment(row.original.retained_date).format('MM-DD-YY hh:mm') + " Hrs" : 'N/A') : 'N/A'}`,
          
        };
      });

      exportedColumns = columns2;
    }

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Accepted panel',
      headers: exportedColumns.map((column) => column.header),
    };

    // Convert data to CSV format
  const csvContent = convertToCSV(exportedData, csvOptions);

  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a download link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = csvOptions.fileName + '.csv';

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the file download
  link.click();

  // Clean up the temporary URL and remove the link from the document
  URL.revokeObjectURL(link.href);
  link.remove();
};

const convertToCSV = (data, csvOptions) => {
  const headers = csvOptions.headers.join(',') + '\n';

  const rows = data.map((row) => {
    return Object.values(row).map((value) => {
      // Handle any necessary formatting or escaping of values
      // For simplicity, we assume all values are already properly formatted
      return value;
    }).join(',');
  }).join('\n');

  return headers + rows;
  };
  return (
    <>
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
      {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
      <Dialog
        open={contactLogPopup}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
             contactLogClose();
          }
        }}  
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >
        <Formik
          initialValues={RequestabortFirstStep}
          validationSchema={RequestFormSchemaabortFirstStep}
          onSubmit={acceptedSubmit}  
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">Abandoned Registrant Process</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
          {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
          <DialogContent>
            
          <Grid container item lg={12} md={12} sm={12} xs={12} id='ContactType' mt={2}>

          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Contact Type</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="callSource"
                name="callSource"
                label="Contact Type"
                onChange={handleChange}
                value={values.callSource}
                MenuProps={MenuProps}
            >
                
                {contactTypeList.map((call) => (
                    <MenuItem
                        value={call.call_type}
                    >
                        {call.call_type}
                    </MenuItem>
                ))}
            </Select>

            </FormControl>
              {touched.callSource && errors.callSource ? <div className='error'>{errors.callSource}</div> : null}
          </Grid> 

           <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <TextField
                name='callType'
                id='callType'
                label="CallType"
                value={values.callType}
                variant="outlined"
                size="small" fullWidth
                InputProps={{
                  readOnly: true,
                }}
             />
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth size='small' margin='dense'>
                <InputLabel id="demo-simple-select-label">Disposition:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="disposition"
                  name="disposition"
                  value={values.disposition}
                  label="Disposition:"
                  onChange={(e) => {
                    handledisposition(e.target.value);
                    handleChange(e);
                  }}
                >
                  {DispositionList.map((disposition) => (
                    <MenuItem
                      value={disposition.id}
                    >
                      {disposition.disposition}
                    </MenuItem>
                  ))}
                </Select>
                {touched.disposition && errors.disposition ? <div className='error'>{errors.disposition}</div> : null}
              </FormControl>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth size='small' margin='dense'>
                <InputLabel id="demo-simple-select-label">Outcome:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="reason"
                  name="reason"
                  value={values.reason}
                  label="Outcome:"
                  onChange={(e) => {
                    handleReason(e.target.value);
                    handleChange(e);
                }}
                > 
                  {reasonList.map((reason) => (
                    <MenuItem
                      value={reason.id}
                      disabled={reason.id !== 295 && reason.id !== 331 && reason.id !== 297}
                    >
                      {reason.reason}
                    </MenuItem>
                  ))}
                </Select>
                {touched.reason && errors.reason ? <div className='error'>{errors.reason}</div> : null}
                {errors1.reason && <div className="error">{errors1.reason}</div>}

              </FormControl>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <TextareaAutosize
                  style={{ width: "100%" }}
                  required
                  margin="dense"
                  aria-label="minimum height"
                  minRows={6}
                  placeholder="Enter Note"
                  name="note"
                  id="note"
                  value={values.note}
                  onChange={handleChange}
                />
                {noteError || (touched.note && errors.note) ? (
            <div className='error'>{noteError ? noteError : errors.note}</div>
          ) : null}
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={contactLogClose} sx={{ minWidth: 250 }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 250 }}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>

        )}
        </Formik>
      </Dialog>

      <Dialog
      open={OpenAssignToMe}
      onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleCloseAssignToMe();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >   
        <Formik
          initialValues={RequestassignToMe}
          validationSchema={validationassignToMe}
          
          onSubmit={assignedToMeMembers}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">	Assign To Me </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
          <DialogContentText>
              <Typography level="h6">Assign To Me reason:*</Typography>
          </DialogContentText>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <TextareaAutosize
                  style={{ width: "100%" }}
                  required
                  margin="dense"
                  aria-label="minimum height"
                  minRows={6}
                  placeholder="Enter Note"
                  name="assignReason"
                  id="assignReason"
                  value={values.assignReason}
                  onChange={handleChange}
                />
                {touched.assignReason && errors.assignReason ? <div className='error'>{errors.assignReason}</div> : null}
              </FormControl>
            </Grid>
            
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleCloseAssignToMe} sx={{ minWidth: 250 }}>
                Cancel
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 250 }}>
                 Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
        )}
        </Formik>
      </Dialog>

      <Dialog
      open={OpenReasonPopup}
      onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleCloseReason();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="responsive-dialog-title"
        fullWidth={fullWidth}
        maxWidth={'sm'}
      >   
        <Formik
          initialValues={requestReason}
          validationSchema={validatioReason}
          
          onSubmit={reasonSubmit}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
              <Grid item lg={9} md={9} sm={9} xs={9} >
                <Typography variant="h5">	Assign To Me </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
          <DialogContentText>
              <Typography level="h6">reason:*</Typography>
          </DialogContentText>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <TextareaAutosize
                  style={{ width: "100%" }}
                  required
                  margin="dense"
                  aria-label="minimum height"
                  minRows={6}
                  placeholder="Enter Note"
                  name="reason"
                  id="reason"
                  value={values.reason}
                  onChange={handleChange}
                />
                {touched.reason && errors.reason ? <div className='error'>{errors.reason}</div> : null}
              </FormControl>
            </Grid>
            
          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleCloseReason} sx={{ minWidth: 250 }}>
                Cancel
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 250 }}>
                 Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
        )}
        </Formik>
      </Dialog>

      

      <MaterialReactTable
        columns={user.roles_id < 3 ? columns : ''}
        data={data}
        enableColumnFilterModes
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
        }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          columnPinning: { left: ['mrt-row-expand','member_id'] }
        }}
        enablePinning
        enableColumnFilters={config.DatatableColumnFilter}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
                color="primary"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                CSV
              </Button>
          </Box>
        )}
      />
         {/* loader code start */}
         <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </>
  );
}



export default Accepted;