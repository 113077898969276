import React, {useMemo, useState, useEffect} from 'react'
import AuthUser from '../../../Components/Auth/AuthUser';
import DefaultLayout from '../../../Components/DefaultLayout';
import {TextField, Box, Grid, Typography,Paper, FormControl, InputLabel, Select,OutlinedInput, MenuItem, Card, Button, Dialog, DialogTitle, DialogContent} from "@mui/material";
import {DateRangePicker,createStaticRanges} from "react-date-range";
import MaterialReactTable from 'material-react-table';
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import swal from "sweetalert";
import { postData } from '../../../redux/apiSlice';
import 'react-calendar/dist/Calendar.css';
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from 'react-router-dom';
import {calendarFilter, useDateSelectionRange,getInitialSelectionRange} from '../../../Components/CommonFormula';
import { ExportToCsv } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Moment from 'moment';

const PerformanceDashboard = () => {
  const dispatch = useDispatch();
  const { user } = AuthUser();
  const navigate = useNavigate();
  const [registered, setRegistered] = useState([]);
  const [enrollments, setEnrollments] = useState([]);
  const [commission , setCommission] = useState([]);
  const [referral , setReferral] = useState([]);
  const [totalCompanies , settotalCompanies] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState({ title: '', records: [] });
  const colorSeries = [{ color: '#8E2EBC' },{ color: '#3FAC19' },{ color: '#2E67BC' },{ color: '#BC2E7B' }];
  const colorSeries1 = [{ color: '#F7E115' },{ color: '#EE8A51' },{ color: '#FF0808' },{ color: '#AC8C19' }];
  const [line, setLine] = useState({ title: '', records: [] });
  const [graph, setGraph] = useState();
  const [selectedData, setSelectedData] = useState(graph);
  const [line1, setLine1] = useState({ title: '', records: [] });
  const [graph1, setGraph1] = useState();
  const [selectedData1, setSelectedData1] = useState(graph1);
  const [thresholdMin, setThresholdMin] = useState(100);
  // const [thresholdMax, setThresholdMax] = useState(1000);
  const [errors, setErrors] = useState({ min: '', max: '' });
  const [topCompanies, setTopCompanies] = useState({
    enrollments: [],
    referrals: [],
    retentionData: [],
    profitabilityData : [],
  });
  const [poorCompanies, setPoorCompanies] = useState({
    enrollments: [],
    referrals: [],
    retentionData: [],
    profitabilityData : [],
  });
  const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } =useDateSelectionRange(getInitialSelectionRange('performanceDashboard'));
  const { selectionRange: selectionRange1, setSelectionRange: setSelectionRange1, startDate: startDate1, endDate: endDate1, clearFilter: clearFilter1 } = useDateSelectionRange(getInitialSelectionRange('performance'));
  const sideBar = calendarFilter();
  const staticRanges = [...createStaticRanges(sideBar)];
  const [freez, setFreez] = React.useState(false);

  const customSortFunction = (rowA, rowB) => {
    const a = rowA.original.company_name || ''; // Fallback to an empty string if null or undefined
    const b = rowB.original.company_name || '';
    return a.localeCompare(b);
  };

  const handleFreezStop = () => {
      setFreez(false);
  };
  const handleFreezStart = () => {
      setFreez(true);
  };

  const handleSeeAll = (metric) => {
    setSelectedMetric(metric);
    setOpenModal(true);  // Open modal
  };

  // Function to close the modal
  const handleClose = () => {
    setOpenModal(false);
  };

const columns = useMemo(() => {
  const baseColumns = [
    {
      accessorKey: 'srNo',
      header: 'Sr. No',
      size: 50,
      Cell: ({ row }) => <Typography>{row.index + 1}</Typography>,
      enableSorting: true,
    },
    {
      accessorFn: (data) => `${data.company_name ? data.company_name : 'N/A'}`,
      id: 'company_name',
      header: 'Company Name',
      sortingFn: customSortFunction,
      enableSorting: true,
      size: 50,
      Cell: ({ renderedCellValue, row }) => (
        <Typography>{row.original.company_name ? row.original.company_name.trim() : 'N/A'}</Typography>
      ),
    },
    {
      accessorFn: (data) => `${data.registered_date ? data.registered_date : 'N/A'}`,
      id: 'registeredOn',
      header: 'Registered On',
      size: 50,
      Cell: ({ renderedCellValue, row }) => (
        <Typography>{row.original.registered_date ? Moment( row.original.registered_date).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}</Typography>
      ),
    },
  ];
  // Conditionally add the 'total' column if selectedMetric.title is 'Enrollments'
  if (selectedMetric.title === 'Enrollments') {
    baseColumns.push(
      {
        accessorFn: (data) =>(data.total !== undefined && data.total !== null ? data.total : 'N/A'),
        id: 'total',
        header: 'Active Enrollments',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{row.original.total !== undefined && row.original.total !== null ? row.original.total : 'N/A'}</Typography>
        ),
      },
      {
        accessorFn: (data) => (data.totalEnrollements !== undefined && data.totalEnrollements !== null ? data.totalEnrollements : 'N/A'),
        id: 'totalEnrollements',
        header: 'Total Enrollments',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{row.original.totalEnrollements !== undefined && row.original.totalEnrollements !== null ? row.original.totalEnrollements : 'N/A'}</Typography>
        ),
      }
    );
  }else if(selectedMetric.title === 'Referrals'){
    baseColumns.push(
      {
        accessorFn: (data) => (data.total !== undefined && data.total !== null ? data.total : 'N/A'),
        id: 'total',
        header: 'Total Referrals',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{row.original.total !== undefined && row.original.total !== null ? row.original.total : 'N/A'}</Typography>
        ),
      },
    );
  }else{
    baseColumns.push(
      {
        accessorFn: (data) => (data.total !== undefined && data.total !== null ? data.total : 'N/A'),
        id: 'total',
        header: 'Total %',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Typography>{row.original.total !== undefined && row.original.total !== null ? `${row.original.total} %` : 'N/A'}</Typography>
        ),
      },
    );
  }
  baseColumns.push(
    {
      accessorFn: (data) => (data.difference !== undefined && data.difference !== null ? data.difference : 'N/A'),
      id: 'Growth',
      header: 'Growth %',
      size: 50,
      Cell: ({ renderedCellValue, row }) => (
        <Typography>{row.original.difference !== undefined && row.original.difference !== null ? `${row.original.difference} %` : 'N/A'}</Typography>
      ),
    },
  );
  return baseColumns;
}, [selectedMetric]);

//  csv code start here

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    fileName: 'Performance',
    headers: selectedMetric.title === 'Enrollments' ? ['Company Name', 'Registered On', 'Active Enrollments', 'Total Enrollments','Growth %']
             : selectedMetric.title === 'Referrals' ? ['Company Name', 'Registered On', 'Total Referrals','Growth %']
             : ['Company Name', 'Registered On', 'Total %','Growth %'],
  };
  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows  = (rows) => {
    const exportedData = rows.map((row) => {
      const data = {
        company_name: row.company_name || "N/A",
        registeredOn: row.registered_date || "N/A",
      };
      if (selectedMetric.title === 'Enrollments') {
        data.activeEnrollements = row.total || "N/A";
        data.total=row.totalEnrollements || "N/A";
      }else if (selectedMetric.title === 'Referrals')
      {
        data.total=row.total || "N/A";
      }
      else{
        data.total= `${row.total} %` || "N/A";
      }
      data.growth = `${row.difference} %` || "N/A";
      return data;
    });

    // Convert data to CSV format
    const csvContent = convertToCSV(exportedData);

    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';
    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }
        return value;
      }).join(',');
    }).join('\n');
    return headers + rows;
  };

// csv code end here


  
const createChartOptions = (colorSeries) => ({
  hAxis: { title: 'Date' },
  legend: { position: 'none' },
  series: Array.isArray(colorSeries) 
    ? Object.assign({}, ...colorSeries.map((color, index) => ({ [index]: color }))) 
    : { 0: { color: colorSeries } }
});

// Use the function to create options
const options = createChartOptions(colorSeries);
const options1 = createChartOptions(colorSeries1);

const [chartOptions, setChartOptions] = useState(options);
const [chartOptions1, setChartOptions1] = useState(options1);
  const fetchRecords=async()=>{
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
        navigate('/login');
        return false;
    }
    if (errors.min || errors.max) {
      swal({
        title: "Validation Error",
        text: "Please fix the threshold errors before submitting.",
        icon: "error",
        button: "Ok",
      });
      return;
    }
    handleFreezStart()
      const apiUrl = '/performanceDashboard';
      const args = {
          fromDate: startDate1,
          toDate: endDate1,
          registerdFromDate:startDate,
          registerdToDate: endDate,
          threshold:[thresholdMin]
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if (res) {
          if (res.data) {
              setRegistered(res.data.totalCompanies);
              setEnrollments(res.data.totalEnrollements);
              setCommission(res.data.totalCommissionPaid);
              setReferral(res.data.totalReferralBonus);
              settotalCompanies(res.data.allCompanies);

              setTopCompanies({
                enrollments: res.data.topEnrollementCompanies || [],
                referrals: res.data.topReferralCompanies || [],
                retentionData: res.data.retentionData || [],
                profitabilityData: res.data.topprofitabilitydata || [],
              });
              
              setPoorCompanies({
                enrollments: res.data.bottomEnrolllementCompanies || [],
                referrals: res.data.poorReferrals || [],
                retentionData: res.data.poorRetentionData || [],
                profitabilityData: res.data.poorprofitabilitydata || [],
              });

              handleLine({ title: "Enrollments", records: res.data.topEnrollementCompanies || [] });
              handleLine1({ title: "Enrollments", records: res.data.bottomEnrolllementCompanies || [] });
              handleFreezStop()
          }
          else {
            handleFreezStop();
            swal({
                title: "Failed",
                text: res.message,
                icon: "error",
                button: "Ok",
            })
          }
    }else {
      handleFreezStop()
      swal({
        title: "Failed",
        text: "Something Went Wrong.",
        icon: "error",
        button: "Ok",
    })
    }

  }

  const kpis = [
    `Registered Companies: ${registered}`,
    `Enrollments: ${enrollments}`,
    `Commission Paid: $${commission.toLocaleString()}`, // Format commission with commas
    `Referral Bonus: $${referral.toLocaleString()}` // Format referral with commas
  ];

  const topMetrics = [
    { title: "Enrollments", records: topCompanies.enrollments },
    { title: "Referrals", records: topCompanies.referrals },
    { title: "Retentions", records: topCompanies.retentionData },
    { title: "Profitability", records: topCompanies.profitabilityData}
  ];

  const poorMetrics = [
    { title: "Enrollments", records: poorCompanies.enrollments },
    { title: "Referrals", records: poorCompanies.referrals },
    { title: "Retentions", records: poorCompanies.retentionData },
    { title: "Profitability", records: poorCompanies.profitabilityData}
  ];

  const handleLine = (metric) => {
    setSelectedData(null);
    processMetricData(metric, setLine, setGraph);
  };
  
  const handleLine1 = (metric) => {
    setSelectedData1(null);
    processMetricData(metric, setLine1, setGraph1);
  };

  const processMetricData = (metric, setLine, setGraph) => {
    setLine(metric);
    const limitedRecords = metric?.records?.slice(0, 4);
  
    if (limitedRecords) {
      const companyNames = limitedRecords.map(company => company.company_name);
      const monthlyData = {};
  
      limitedRecords.forEach(company => {
        const enrollments = company.graphData;
        for (const month in enrollments) {
          if (!monthlyData[month]) {
            monthlyData[month] = {};
          }
          monthlyData[month][company.company_name] = enrollments[month];
        }
      });
  
      const finalData = [['Date', ...companyNames]];
      for (const month in monthlyData) {
        const row = [month];
        companyNames.forEach(name => {
          row.push(monthlyData[month][name] || 0); // Fill with 0 if no data
        });
        finalData.push(row);
      }
      setGraph(finalData);
    }
  };

  const handleTitleClick = (name, line, graph, colorSeries, setSelectedData, setChartOptions) => {
    if (line.records && line.records.length > 0) {
      const filteredData = [['Date', name]]; 
      for (let i = 1; i < graph.length; i++) { 
        const row = graph[i];
        const companyDataIndex = graph[0].indexOf(name); 
        if (companyDataIndex !== -1) {
            filteredData.push([row[0], row[companyDataIndex]]);
        }
      }
      const companyIndex = line.records.findIndex(record => record.company_name === name);
      const companyColor = companyIndex !== -1 ? colorSeries[companyIndex].color : '#000000';
      const singleCompanyOptions = {
        ...options,
        series: { 0: { color: companyColor } } // Apply color to only the first series
      };
      setSelectedData(filteredData);
      setChartOptions(singleCompanyOptions); 
    }
  };
  
  useEffect(() => {
    if (user.roles_id < 3) {
      fetchRecords();

    } else {
        navigate('/login');
    }
}, []);

  const ContentLayout =
  <>
  <Box sx={{ padding: 2 }}>
      {/* Title Section */}
      <Typography variant="h4" gutterBottom>
          Performance Dashboard ({totalCompanies} Companies)
      </Typography>

      {/* Filters Section */}
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 3, width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-name-label">Company Registration Date</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value="date"
                input={<OutlinedInput label="Company Registration Date" />}
              >
                <MenuItem value="date">
                  {startDate ? (
                    <>
                      {startDate} To {endDate}
                    </>
                  ) : ''}
                </MenuItem>
                <DateRangePicker
                  className='custom-date-picker'
                  onChange={(item) => setSelectionRange([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectionRange}
                  direction="horizontal"
                  maxDate={new Date()}
                  staticRanges={staticRanges}
                />
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-name-label">Company Performance Period</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value="date"
                input={<OutlinedInput label="Company Performance Period" />}
              >
                <MenuItem value="date">
                  {startDate1 ? (
                    <>
                      {startDate1} To {endDate1}
                    </>
                  ) : ''}
                </MenuItem>
                <DateRangePicker
                  className='custom-date-picker'
                  onChange={(item) => setSelectionRange1([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectionRange1}
                  direction="horizontal"
                  maxDate={new Date()}
                  staticRanges={staticRanges}
                />
              </Select>
            </FormControl>
          </Grid>

          {/* Threshold Fields */}
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Threshold Min"
              size="small"
              variant="outlined"
              value={thresholdMin}
              onChange={(e) => {
                const value = e.target.value;
                setThresholdMin(value);
                setErrors((prev) => ({
                  ...prev,
                  // min: value && thresholdMax && parseFloat(value) > parseFloat(thresholdMax)
                  //   ? 'Min value should not be greater than Max value'
                  //   : value && thresholdMax && parseFloat(value) === parseFloat(thresholdMax)
                  //   ? 'Min value should not be equal to Max value'
                  //   : ''
                }));
              }}
              onBlur={(e) => {
                const value = e.target.value;
                setThresholdMin(value);
                setErrors((prev) => ({
                  ...prev,
                  // min: value && thresholdMax && parseFloat(value) > parseFloat(0)
                  //   ? 'Min value should not be greater than Max value'
                  //   : value && thresholdMax && parseFloat(value) === parseFloat(0)
                  //   ? 'Min value should not be equal to Max value'
                  //   : ''
                }));
              }}
              error={!!errors.min}
              helperText={errors.min}
            />
          </Grid>

          {/* <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Threshold Max"
              size="small"
              variant="outlined"
              value={thresholdMax}
              onChange={(e) => {
                const value = e.target.value;
                setThresholdMax(value);
                setErrors((prev) => ({
                  ...prev,
                  max: value && thresholdMin && parseFloat(value) < parseFloat(thresholdMin)
                    ? 'Max value should not be less than Min value'
                    : value && thresholdMin && parseFloat(value) === parseFloat(thresholdMin)
                    ? 'Max value should not be equal to Min value'
                    : ''
                }));
              }}
              onBlur={(e) => {
                const value = e.target.value;
                setThresholdMax(value);
                setErrors((prev) => ({
                  ...prev,
                  max: value && thresholdMin && parseFloat(value) < parseFloat(thresholdMin)
                    ? 'Max value should not be less than Min value'
                    : value && thresholdMin && parseFloat(value) === parseFloat(thresholdMin)
                    ? 'Max value should not be equal to Min value'
                    : ''
                }));
              }}
              error={!!errors.max}
              helperText={errors.max}
            />
          </Grid> */}

          <Grid item xs={12} md={2}>
            <Button color="primary" variant="contained" onClick={fetchRecords} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* KPIs Section */}
      <Grid container spacing={2} sx={{ marginBottom: 3 }}>
      {kpis.map((kpi, index) => { const backgroundColors = ['#D5ECF5', '#D8F2D8', '#D5ECF5', '#D8F2D8']; return (
          <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ padding: 2, backgroundColor: backgroundColors[index] }}>
                  <Typography variant="h6">{kpi.split(":")[0]}</Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{kpi.split(":")[1]}</Typography>
              </Card>
          </Grid>
          ); })}
      </Grid>

      <Paper sx={{ padding: 3, boxShadow: 3, marginBottom: 3 }}>
            <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                Top Performing Companies
            </Typography>
            <Grid container spacing={2}>
                  {topMetrics.map((combine, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card sx={{ padding: 1, bgcolor: '#2E67BC', position: 'relative', zIndex: 1 }}>
                            <Typography variant="h6" sx={{ textAlign: 'center', color: 'white',cursor: 'pointer' }} 
                            onClick={() => handleLine(combine)}
                            >
                                {combine.title}
                            </Typography>
                        </Card>
                        {combine.records.slice(0, 4).map((record, companyIndex) => (
                            <Box 
                                key={companyIndex} 
                                sx={{ padding: 1, bgcolor: companyIndex % 2 === 0 ? '#FFFFFF' : '#EBF4FF', textAlign: 'center' }}
                            >
                                <Typography variant="body2" sx={{ color: '#000000' }}>
                                    {record.showCompany}
                                </Typography>
                            </Box>
                        ))}
                        {combine.records.length > 4 && (
                            <Box sx={{ padding: 1, textAlign: 'right', marginTop: 1 }}>
                            <Typography 
                                variant="body2" 
                                sx={{ color: '#2E67BC', fontWeight: 'bold', cursor: 'pointer' }} 
                                onClick={() => handleSeeAll(combine)}
                            >
                                See All
                            </Typography>
                        </Box>
                        )}
                    </Grid>
                ))}
            </Grid>
      </Paper>

      {/* Graph Section */}
      <Grid container spacing={2}>

          <Grid item xs={12} md={8}>
              <Paper sx={{ padding: 2, marginBottom: 3 }}>
                  <Typography variant="h6" gutterBottom>{line.title}</Typography>
                  {line.records && line.records.length > 0 ? (
                    <Chart chartType="LineChart" width="100%" height="400px" data={selectedData ? selectedData : graph} options={selectedData ? chartOptions:options} />
                  ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', color: 'red' }}>
                      No records found
                    </Typography>
                  )}
              </Paper>
          </Grid>


          <Grid item xs={12} md={4}>
              <Paper sx={{ padding: 3, marginBottom: 3 }}>
                  <Grid container spacing={1}>
                    {line?.records?.slice(0, 4).map((combine, index) => (
                      <Grid item xs={12} key={index}>
                          <Paper sx={{ display: 'flex', alignItems: 'center', padding: 3, color: '#00695c', marginBottom: 2, }}>
                              <Box key={index}  sx={{ width: 20, height: 20, bgcolor: colorSeries[index].color, marginRight: 1 }} />
                              <Typography variant="body1" sx={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => handleTitleClick(combine.company_name,line, graph, colorSeries, setSelectedData, setChartOptions)} >{combine.company_name}</Typography>
                          </Paper>
                          
                      </Grid>
                      ))}
                  </Grid>
              </Paper>
          </Grid>
      </Grid>

      <Paper sx={{ padding: 3, boxShadow: 3, marginBottom: 3 }}>
          <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
              Under Performing Companies
          </Typography>
          <Grid container spacing={2}>
                {poorMetrics.map((combine, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card sx={{ padding: 1, bgcolor: '#2E67BC', position: 'relative', zIndex: 1 }}>
                            <Typography variant="h6" sx={{ textAlign: 'center', color: 'white',cursor: 'pointer' }} 
                            onClick={() => handleLine1(combine)}
                            >
                                {combine.title}
                            </Typography>
                        </Card>
                        {combine.records.slice(0, 4).map((record, companyIndex) => (
                            <Box 
                                key={companyIndex} 
                                sx={{ padding: 1, bgcolor: companyIndex % 2 === 0 ? '#FFFFFF' : '#EBF4FF', textAlign: 'center' }}
                            >
                                <Typography variant="body2" sx={{ color: '#000000' }}>
                                    {record.showCompany}
                                </Typography>
                            </Box>
                        ))}
                        {combine.records.length > 4 && (
                            <Box sx={{ padding: 1, textAlign: 'right', marginTop: 1 }}>
                            <Typography 
                                variant="body2" 
                                sx={{ color: '#2E67BC', fontWeight: 'bold', cursor: 'pointer' }} 
                                onClick={() => handleSeeAll(combine)}
                            >
                                See All
                            </Typography>
                        </Box>
                        )}
                    </Grid>
                ))}
          </Grid>
      </Paper>

      {/* Graph Section */}
      <Grid container spacing={2}>

          <Grid item xs={12} md={4}>
              <Paper sx={{ padding: 3, marginBottom: 3 }}>
                  <Grid container spacing={1}>
                      {line1?.records?.slice(0, 4).map((combine, index) => (
                      <Grid item xs={12} key={index}>
                          <Paper sx={{ display: 'flex', alignItems: 'center', padding: 3, color: '#00695c', marginBottom: 2, }}>

                              <Box sx={{ width: 20, height: 20, bgcolor: colorSeries1[index].color, marginRight: 1 }} />
                              <Typography variant="body1" sx={{ textAlign: 'left',cursor: 'pointer' }} onClick={() => handleTitleClick(combine.company_name,line1, graph1, colorSeries1, setSelectedData1, setChartOptions1)} >{combine.company_name}</Typography>
                          </Paper>
                      </Grid>
                      ))}
                  </Grid>
              </Paper>
          </Grid>

          {/* Chart Section */}
          <Grid item xs={12} md={8}>
              <Paper sx={{ padding: 2, marginBottom: 3 }}>
                  <Typography variant="h6" gutterBottom>{line1.title}</Typography>
                  {line1.records && line1.records.length > 0 ? (
                    <Chart chartType="LineChart" width="100%" height="400px" data={selectedData1 ? selectedData1 : graph1} options={selectedData1 ? chartOptions1 : options1} />
                  ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', color: 'red' }}>
                      No records found
                    </Typography>
                  )}
              </Paper>
          </Grid>
      </Grid>
  </Box>

  
  <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth="lg">
  <DialogTitle>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h6">
        Insight Of {selectedMetric.title} From {startDate1} To {endDate1}
      </Typography>
      <IconButton onClick={handleClose} aria-label="close">
        <CloseIcon />
      </IconButton>
    </Box>
  </DialogTitle>
  <DialogContent dividers>
    <MaterialReactTable
      columns={columns}
      data={selectedMetric.records.slice(0, 10)}
      enableColumnFilters={false}
      initialState={{ density: 'compact' }}
      positionToolbarAlertBanner="bottom"
      enableDensityToggle={false}
      enableSorting={true}
      renderTopToolbarCustomActions={({table}) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <Button
            sx={{ bgColor: "#2A68B3" }}
            disabled={selectedMetric.records.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(selectedMetric.records)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
            className='export'
          >
            CSV
          </Button>
        </Box>
      )}
    />
  </DialogContent>
</Dialog>
  </>
      

  return (
      <div>
          <DefaultLayout content={ContentLayout} />
          {/* loader code start */}
          <div>
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={freez}
              >
                  <Freez />
              </Backdrop>
          </div>
          {/* loader code end */}
      </div>
  );
};

export default PerformanceDashboard;