import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination, button } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import config from '../../../AppConfig';
import Sidebar from '../../../Components/Blogs/Sidebar';
function CreditBureaus() {
    return (
        <>
            <LoginHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={2} md={2} sm={12} xs={12} >
                       <Sidebar/>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12} sx={{padding:"2%"}}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <img src={require("../../../assets/images/mfsn_banner_4.jpg")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className='responsiveImg' />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h4">Credit Bureaus</Typography>
                        </Grid>
                        <p>Credit bureaus, also known as consumer reporting agencies, are private companies that compile and maintain personal and financial information on individual consumers. Most creditors voluntarily report to one or more of the three national credit bureaus or to a local bureau that is usually affiliated with one of the national bureaus. The credit bureaus sell the information to creditors, insurers, employers, landlords and other businesses that have a legitimate interest in your credit history.</p>
                        <p>There are three main credit bureaus in the United States:</p>
                        <ul>
                            <li>Equifax Credit Information Services, Inc.<br/>
                                P.O. Box 740241<br/>
                                Atlanta, GA 30374<br/>
                                (800) 203-7843
                            </li>
                            <li>
                            TransUnion Corporation<br/>
                            P.O. Box 34012<br/>
                            Fullerton, CA 92834<br/>
                            (800) 916-8800 (Press 3 to speak with a representative.)
                            </li>
                            <li>
                            Experian Information Solutions, Inc.<br/>
                            P.O. Box 2002<br/>
                            Allen, TX 75013<br/>
                            (888) 397-3742<br/>
                            (714) 830-7000
                            </li>
                        </ul>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Are you monitoring your credit?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={4}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' style={{textDecoration:"none"}}><Typography level='title-md' sx={{color:"#1976d2"}}>Get your free credit score and score report now and protect your credit.</Typography></a>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' className='blog'>Get your free credit score and score report now and protect your credit.</a>
                        </Grid> */}
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <img src={require("../../../assets/images/lt_banner_5.png")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default CreditBureaus;