import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import {Box,Grid,Card,CardContent,Typography,Button} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginHeader from '../../Components/LoginHeader';
import RegisterFooter from '../../views/Auth/RegisterFooter';
import Loader from '../../Components/Loader/Loader';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import config from '../../AppConfig';

function Thankyou(props) {
    const navigate = useNavigate();
    const location = useLocation();  
    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(2);
    // const { pdfBlob, pdfBase64 } = location.state || {};
    const downloadPDF = () => {
        if (location.state.businessName) {
        //     // Create an anchor element
        //     const link = document.createElement('a');
        //     link.href = location.state.pdfUrl; 
        //    // Assuming this is a URL to the PDF file
        //     link.download = 'Affiliate Agreement.pdf'; // Set default download filename
        //     // link.click(); // Trigger the download
        //     window.open(config.API_URL+businessName); 

            const apiUrl = `/get-pdf/${location.state.businessName}`;
            window.open(config.API_BASE_URL+apiUrl); 
        }
    };

    
    const openDocument=()=>{
        setIsLoading(true)
        if(location.state.url){
            window.location.href=location.state.url
        }
    }
 
      
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 24, // Adjust spacing between steps here
            left: 'calc(-50% + 20px)',
            right: 'calc(50% + 20px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                // backgroundColor: 'black',
                borderStyle: 'dotted',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                // backgroundColor: 'black',
                borderStyle: 'dashed',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 2,
            border: 0,
            // backgroundColor: 'black',
            borderRadius: 1,
          
            width: '59%',
            margin: 'auto',
            borderImage: 'repeating-linear-gradient(to right, gray 0, gray 1px, transparent 1px, transparent 3px) 1',
            // border: '4px dotted gray',
        },
        [`& .${stepConnectorClasses.line}::before`]: {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            height: 1, // Adjust height of the dots
            backgroundColor: 'transparent',
            borderBottom: '4px dashed gray', // Dotted border style
            width: '70%',
            transform: 'translateY(-50%)', // Center the dotted line vertically
            margin: 'auto',
          },
        
        [`& .halfLine`]: {
            borderLeftWidth: '40%', // Half width
            borderTopWidth: 3,
            borderStyle: 'dotted',
            borderColor: 'black', // Adjust as needed
            position: 'absolute',
            top: 0,
            bottom: 0,
        },
    }));
    
    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: ownerState.completed ? '#6CC641' : ownerState.active ? '#2E67BC' : '#ACB1B7', // Green for completed, Blue for active, Gray for pending
        zIndex: 1,
        color: '#fff',
        width: 55,
        height: 55,
        display: 'flex',
        borderRadius: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
    }));
    
    function ColorlibStepIcon(props) {
        const { active, completed, className, icon } = props;
        const icons = {
            1: <img src={require("../../assets/images/registerVector.png")} style={{ width: "50%", height: "auto" }} />,
            2: <img src={require("../../assets/images/registerVector1.png")} style={{ width: "50%", height: "auto" }} />,
            3: <img src={require("../../assets/images/registerVector2.png")} style={{ width: "50%", height: "auto" }} />,
            4: <img src={require("../../assets/images/registerVector3.png")} style={{ width: "50%", height: "auto" }} />,
            5: <img src={require("../../assets/images/registerVector4.png")} style={{ width: "50%", height: "auto"}} />
        };
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(icon)]}
            </ColorlibStepIconRoot>
        );
    }
    
    ColorlibStepIcon.propTypes = {
        active: PropTypes.bool,
        className: PropTypes.string,
        completed: PropTypes.bool,
        icon: PropTypes.node,
    };
        
        const steps = ['Registration Completed', 'Email Verified', 'Open & Sign the Agreement','Receive link for login','Get a call from our representative'];      
    
    useEffect(()=>{
        setActiveStep(location.state.active)
    },[])
        return (
        <>
         {isLoading && <Loader />}
       <>
       <LoginHeader />
        <Box className="back" sx={{ padding: "2%" }}>
     
            <Card sx={{padding:"0% 0% 4% 4% ",textAlign:'center'}}>
                <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">

                        <Grid item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
                        <img
                        style={{width:'15%'}}
                            alt="#"
                            src={require('../../assets/images/pandadoc_thankyou.png')}
                            // className="pandadoc-img"
                        />
                        </Grid>
                     <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                        {location.state.active==2 &&
                     <Typography variant='h6' sx={{fontWeight:'bold',fontSize:'1.5rem'}}>
                        You Are Almost There!!!
                        </Typography>
                        }
                        {location.state.active==3?
                        <Typography variant='h6' sx={{fontWeight:'bold',fontSize:'1rem'}} mt={2}>
                             The Document Has Been Completed By All Participants. By Clicking On "Download Agreement" You Can Download It.
                             </Typography>:
                             <Typography variant='h6' sx={{fontWeight:'bold',fontSize:'1rem'}} mt={2}>
                                 Affiliate program agreement: Click "View Agreement" to sign and let’s make it official!
                             </Typography>
                        }
                       
                     </Grid>

                        <Grid  item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center" mt={4} className='overflow-scroll'>
                            <Stack sx={{ width: '100%' }} spacing={4}>
                                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                    ))}
                                </Stepper>
                            </Stack>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center" mt={3}>
                            <Button variant='contained' sx={{textTransform:'capitalize'}} onClick={location.state.active==3?downloadPDF:openDocument}>{location.state.active==3?"Download Agreement":"View Agreement"}</Button>
                        </Grid>
                        {location.state.active==2 &&
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Typography variant='h6'><span>..............</span><span style={{fontWeight:'bold',fontSize:'1rem'}}>Or</span><span>..............</span></Typography>
                            <Typography variant='h6' sx={{color:'#6CC641',fontWeight:'bold',fontSize:'1rem'}}>Please check your email!</Typography>
                            <Typography variant='h6' sx={{fontWeight:'bold',fontSize:'1rem'}}>You will find the same agreement document in your email for easy reference.</Typography>
                        </Grid>
                        }
                        {/* <Grid item lg={4} md={6} sm={12} xs={12} >   
                            <img src={require("../../assets/images/thankyou.png")} style={{display:"block",marginLeft:"auto",marginRight:"auto"}} className="images"/>
                           
                        </Grid>
                        <Grid  item lg={8} md={6} sm={12} xs={12}>
                            <Typography>
                                Hi {location.state.name}
                            </Typography>
                            <ul>
                                <li>Complete Sign up Form </li>
                                <li>Email Verified </li>
                                <li>Open and Complete Affiliate Program Document</li>
                                <li>Receive Affiliate Link and Log In </li>
                                <li>Whats going to happen Next " You will receive a phone call by a representative from 888xxxxxxx to provide you with a free benefits</li>
                                <li>Cobrand/ Snapshot recommended next steps</li>
                            </ul>
                           
                            <Typography>
                            <span style={{color:"#30BE2D",fontWeight:"bold"}}>So let’s get started!</span>
                            </Typography>
                            <Typography>
                            <span style={{color:"#ffa500",fontWeight:"bold"}}> Next Step ➡ Please check your email!</span>
                            </Typography>
                            <Typography level="h6">
                            Your personalized MyFreeScoreNow affiliate program document is ready for your review.
                            Don’t see it? Please check spam/junk folders.<span style={{color:"#30BE2D",fontWeight:"bold",fontSize:"20px"}}>Thank you!</span> 
                            </Typography>
                            <br/>
                            <Typography sx={{fontWeight:'bold'}}>
                            Please Click “Open The Document” To Sign And Complete Your Affiliate Program Form.
                            </Typography><br/>
                            
                            <Button onClick={openDocument}>OPEN THE DOCUMENT</Button>
                        </Grid> */}
                        
                    </Grid>
                </CardContent>
            </Card>
        </Box>
        <RegisterFooter />
        </>
        </> 
    );
}
export default Thankyou;