import React,{useState,useEffect} from 'react';
import { TextField,Grid, Button,Box,InputLabel ,InputAdornment ,OutlinedInput ,Alert,FormControlLabel ,FormControl ,Checkbox, } from '@mui/material';
import { Typography, Card, CardOverflow, CardContent } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import { useNavigate, useParams } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../Components/Auth/AuthUser';
import IconButton from '@mui/material/IconButton';
import { fetchData, postData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';




let price = '$29.95';

function UpdatePid(props) {
    const navigate = useNavigate();
    const { http, user } = AuthUser();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [pidObj, setPidObj] = React.useState({});
    const isMobile = useMediaQuery('(max-width:600px)');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [freez, setFreez] = React.useState(false);
  
    const [checked, setChecked] = React.useState(false);
    const [formikValues, setFormikValues] = useState({});

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const editPidManager = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        }
        handleFreezStart();
        const apiUrl = '/admin/pid/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            if(res.success == true){
                setPidObj(res.data);
                console.log(res.data.pid);
                if(res.data.hasFee === 'TRUE'){
                    setChecked(true);
                }else{
                    setChecked(false);
                }

                const initialValues = {
                    pid: res.data.pid ? res.data.pid : '',
                    Price: res.data.price ? res.data.price : '',
                    trialLength: res.data.days ? res.data.days : '',
                    lastName: res.data.test_last_name ? res.data.test_last_name : '',
                    description: res.data.description ? res.data.description : '',
                }
                setFormikValues(initialValues);
                handleFreezStop();
            }
           
        }
    }

    useEffect(()=>{
        if(user.roles_id < 2) {
            editPidManager();
        }else{
            navigate('/login');
        }
        
    },[]);

    const handleFee = (event) => {
        setChecked(event.target.checked);
    };
    const setValue = (values) =>{
        setFormikValues(values);  
    }

    const EditPidData = async (values) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        }
        handleFreezStart();
        const apiUrl = '/admin/editPID';     
        const args = {
            pid: values.pid,
            price: values.Price,
            days: values.trialLength,
            testLastName: values.lastName,
            description: values.description,
            hasFee: checked,
            id:id,
          }  
          const res = await dispatch(postData({ apiUrl, args })).unwrap();
          if(res){
            if(res.success == true){
                editPidManager();
                handleFreezStop();
                setSuccessMessage(res.message);
                setTimeout(() => {
                    setSuccessMessage('')
                }, 3000);
            }else{
                editPidManager();
                handleFreezStop();
                setErrorMessage(res.message);
                setTimeout(() => {
                    setErrorMessage('')
                }, 3000);
            }

          }
    };

    const editPid = (id) => {
       
          const loginUser = JSON.parse(localStorage.getItem('user'));
          if(loginUser.roles_id > 1 ) {
                navigate('/login');
                return false;
            }
            navigate('/edit/pidManager/' + id);
          
    }

    const deletePid = async() => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 1 ) {
            navigate('/login');
            return false;
        }
        handleFreezStart();

        const apiUrl = '/admin/deletePID/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res.success === true) {
            handleFreezStop();
            setSuccessMessage(res.message);
            setTimeout(function () {
                setSuccessMessage('')  
                navigate('/PidManager');      
            }, 3000);
        } else {
            handleFreezStop();
        }

    }



    const pidValidate = Yup.object().shape({
        pid: Yup.string()
            .matches(/^[0-9]+$/, 'PID must contain only numbers')
            .min(2,'Too SHort!')
            .max(5, 'PID must be 5 digits')
            .required('Please Enter PID to set.'),
        Price: Yup.string()
            .matches(/^\$?[0-9]+(\.[0-9]{1,2})?$/, 'Please enter a valid Price')
            .required('Please Enter Price.'),    
        trialLength: Yup.string()
            .matches(/^[0-9]+$/, 'Days must contain only numbers')
            .required('Please Enter TrialLength.'),
        lastName: Yup.string()
            .required('Please Enter LastName.'), 
    })

  


    const ContentLayout=
  
        <>
        <Box>
            <Typography level="h3" color="warning">**This PID Functionality is for Management Team Only** </Typography>
            <Grid xs={12} p={20} sx={{padding:"50px 50px"}}>
                <Card variant='outlined' >
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',                            
                        }}
                    >
                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''} 
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''} 
                        <Typography level="h4" color="primary">Editing PID {pidObj.pid} </Typography>

                    </CardOverflow>
                    <CardContent >
                   
                          	
                        <Formik
                            initialValues={formikValues}
                            validationSchema={pidValidate}
                            onSubmit={EditPidData}
                            enableReinitialize={true}
                        >
                            {({values, errors,touched,isSubmitting, handleChange, handleBlur, handleSubmit}) => (
                                <Box p={5}>
                                    

                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="PID"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='pid'
                                            value={values.pid}
                                            id='pid'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                maxLength: 5,
                                                pattern: '[0-9]*', // Enforce numeric input
                                              }}
                                              InputLabelProps={{
                                                shrink: Boolean(values.pid)
                                            }}
                                            helperText={touched.pid && errors.pid ? errors.pid : ''}
                                            error={touched.pid && errors.pid ? errors.pid : ''}
                                        />

                                    </Grid>
                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="Price"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='Price'
                                            value={values.Price}
                                            placeholder={price}
                                            id='Price'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputLabelProps={{
                                                shrink: Boolean(values.Price)
                                            }}
                                            helperText={touched.Price && errors.Price ? errors.Price : ''}
                                            error={touched.Price && errors.Price ? errors.Price : ''}
                                        />

                                    </Grid>
                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                      

                                        <FormControl  variant="outlined" fullWidth
                                            size='small' margin='dense'>
                                            <InputLabel htmlFor="outlined-adornment-password" shrink= {Boolean(values.trialLength)}>Trial Length</InputLabel>
                                            <OutlinedInput
                                                label="Trial Length"
                                                id="trialLength" 
                                                fullWidth
                                                size='small'
                                                type="text"
                                                margin='dense'
                                                name='trialLength'
                                                value={values.trialLength}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                inputProps={{
                                                    maxLength: 5,
                                                    pattern: '[0-9]*', // Enforce numeric input
                                                  }}
                                                  notched={Boolean(values.trialLength)}
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    >
                                                    <Typography>days</Typography>
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                                
                                                helperText={touched.trialLength && errors.trialLength ? errors.trialLength : ''}
                                                error={touched.trialLength && errors.trialLength ? errors.trialLength : ''}
                                            />
                                             {touched.trialLength && errors.trialLength ?  <div className='error'>{errors.trialLength}</div> : null}
                                        </FormControl>
                                    </Grid>
                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="Test Last Name"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='lastName'
                                            value={values.lastName}
                                            id='lastName'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputLabelProps={{
                                                shrink: Boolean(values.lastName)
                                            }}
                                            helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                                            error={touched.lastName && errors.lastName ? errors.lastName : ''}
                                        />

                                    </Grid>

                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                    <Grid item xs>
                                        
                                        <FormControlLabel label="Has $1 Fee"  control={<Checkbox  checked={checked} />}
                                            onChange={(e) => {
                                                handleFee(e);
                                                setValue(values);
                                            }}
                                        />
                                       
                                     
                                         {/* /> */}
                                    </Grid>
                                    </Grid>

                               

                                    <Grid container item lg={12} md={12} xs={12} sm={12} py={2}>                            
                                        <TextField
                                            label="Description"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            name='description'
                                            value={values.description}
                                            id='description'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputLabelProps={{
                                                shrink: Boolean(values.description)
                                            }}
                                            helperText={touched.description && errors.description ? errors.description : ''}
                                            error={touched.description && errors.description ? errors.description : ''}
                                        />

                                    </Grid>

                                    
                                    <Grid container  item lg={12} md={12} sm={12} xs={12}  mt={2}>                                       
                                        <Grid item lg={4} md={4} sm={12} xs={12} textAlign="center">
                                            <Button variant="contained" sx={{minWidth: isMobile ? 200 : 250}} color="success" type="submit" size='lg' onClick={handleSubmit}>Edit PID</Button>
                                        </Grid>  
                                        <Grid item lg={4} md={4} sm={12} xs={12} textAlign="center" sx={{ marginTop: isMobile ? "2%" : 0}}>
                                                <Button variant="contained" sx={{ minWidth: isMobile ? 200 : 250 , backgroundColor : "red"}}  onClick={() => deletePid()} >
                                                    Delete
                                                </Button>
                                        </Grid>     
                                        <Grid item lg={4} md={4} sm={12} xs={12} textAlign="center" sx={{ marginTop: isMobile ? "2%" : 0}}>
                                            <Button variant="contained" sx={{ minWidth: isMobile ? 200 : 250}} color="primary" type="submit" size='lg' onClick={()=>{navigate('/PidManager')}}>CLOSE</Button>
                                        </Grid>                       
                                    </Grid>
                                </Box>
                            )}
                        </Formik>
                    </CardContent>                 
                </Card>
            </Grid>
        </Box>

      

         {/* loader code start */}
         <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
   
     
    return(
        <div>
           <DefaultLayout content={ContentLayout } />
        </div>
    );
}
    
export default UpdatePid;