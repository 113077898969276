import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box, Grid, Alert } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/joy';
import AuthUser from '../../../Components/Auth/AuthUser';
import Loader from '../../../Components/Loader/Loader';
import SuspendedMembersList from '../../../Components/SuspendedMembers/SuspendedMembersList';
import {useNavigate} from 'react-router-dom';

function SuspendedMembers(props) {
    const [reportOption, setReportoption] = useState(false);
    const { http, user } = AuthUser();
    const [data, setData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const navigate = useNavigate();
  

    //Fetch reports
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(user.roles_id <= 5) {
            
        }else{
            navigate('/login');
        }
    }, [])

    //Start Request Add Missing Member
    //Schema for formik form
    //Submit Request Add Missing Member Form
    function ContentLayout() {
        return (
            <>
            
                <Box>
                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
                        <Typography level="h4" color="primary">Suspended Members</Typography>
                    </Grid>
                    {isLoading ? <><Loader /></> : ''}
                    <SuspendedMembersList data={data} />
                    {/* add missing member report popup */}
                </Box>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default SuspendedMembers;