import * as React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Typography } from '@mui/joy';
import LoginHeader from "../../../Components/LoginHeader";
import RegisterFooter from "../../Auth/RegisterFooter";
import '../../../Css/Custom.css';
function DashboardVerification(props) {
    return (
        <>
            <LoginHeader />
            <Box mt={3}>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Typography level="h3" className="font-3"> TROUBLE ENROLLING ONLINE ?</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Typography level="h4" className="font-2" > Let’s fix it:</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <img className="imgCenter" src={require("../../../assets/images/IDVERIFICATION.png")} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h3" className="font-1">WE CAN AUTHENTICATE YOU MANUALLY !</Typography>
                            <Typography sx={{ fontWeight: "bold !important", fontSize: "1.5em !important" }}>Email:
                                <span style={{ fontWeight: "bold", fontSize: "1em" }} className='dashboardverify'><u>customersupport@myfreescorenow.com</u></span>
                            </Typography>
                            <Typography sx={{fontWeight: "bold", fontSize:" 1.2em"}}>Please include two of the documents below</Typography>
                            <br/>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <ul>
                                <li>one from section (A)</li>
                                <li>one from section (B)</li>
                            </ul>
                            <Typography  sx={{fontWeight: "bold", fontSize: "1.2em"}}>You can scan email or send photos from your phone
                            - as long as the documents are legible</Typography>
                            <Typography  sx={{color:"blue", fontSize:"1.2em", fontWeight: "bold"}}>(A) Identity Document</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <ul>
                                <li>Copy of government issued photo ID</li>
                                <li>Copy of birth certificate</li>
                                <li>Copy of previous month’s bank statement</li>
                            </ul>
                            
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography sx={{color:"blue", fontSize:"1.2em", fontWeight: "bold"}}>(B) Proof of Social Security Number</Typography>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <ul>
                                <li>Copy of Social Security Card</li>
                                <li>Copy of W-2 Form</li>
                                <li>Copy of tax return</li>
                                <li>Copy of credit report</li>
                            </ul>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography sx={{color:"blue", fontSize:"1.2em", fontWeight: "bold"}}>
                            Questions? Call us at <u>1-888-548-2008</u>
                        </Typography> 
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>

    );
}
export default DashboardVerification;
