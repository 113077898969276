import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import PropTypes from 'prop-types';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import {
    Box, Collapse, Grid, Divider, Table, TableCell, FormControl, Dialog, DialogActions, Alert,  DialogTitle, TableRow, InputLabel, Button,
    DialogContent, MenuItem, FormLabel,  FormControlLabel,  Radio, RadioGroup, FormHelperText,  Select,  Tooltip,Stack,TextField,TableBody
} from '@mui/material';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Typography, Card, CardContent, CardOverflow, } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/HighlightOff';
import AuthUser from '../../../Components/Auth/AuthUser';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import Moment from 'moment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InputMask from 'react-input-mask';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';
//boostrap dialog 
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function MissingMemberFollowup(props) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [formatedCurrentDate, setFormatedCurrentDate] = useState("");
    const handleClickOpen = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        handleFreezStart();
        setOpen(true);
        handleFreezStop();


        const newDate = new Date();
        const myDate = new Date();
        newDate.setDate(newDate.getDate() + 1);
        myDate.setDate(myDate.getDate());
        var todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        // console.log("cDate :"+cDate)
        var todaysTime = Moment(newDate).tz('America/Los_Angeles').format('hh:mm:ss');
        setFollowUpDate(todaysDate);
        setFollowUpTime(todaysTime);
        setCurrentDate(cDate);


    };

    const handleClose = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 ) {
            navigate('/login');
            return false;
        }
        setOpen(false);
    };

    const { http, user } = AuthUser();
    const [disable, setDisable] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [followup, setFollowup] = useState([]);
    const [followdate, setFollowdate] = useState([]);
    const theme = useTheme();
    const [currentDate, setCurrentDate] = useState("");

    const [currentFollowUpDate, setFollowUpDate] = useState("");
    const [currentFollowUpTime, setFollowUpTime] = useState("");

            //loader function and variable start
            const [freez, setFreez] = React.useState(false);
            const handleFreezStop = () => {
                setFreez(false);
            };
            const handleFreezStart = () => {
                setFreez(true);
            };
            //loader function and variable end

    //Fetch reports
    const fetchReport = async () => {
        handleFreezStart();
        setErrorMessage("")
        setIsLoading(true)
        http.get('/missingMemberDetails/' + id).then((res) => {
            handleFreezStop();
            // if(res.data.success === true){
            setData(res.data.data)
            setFollowup(res.data.followup)
            setFollowdate(res.data.followdate)
            setIsLoading(false)
            // }else{
            //     setErrorMessage(res.data.message)
            //     setIsLoading(false)
            //     setTimeout(function () {
            //         setErrorMessage('')
            //         setDisable(false)
            //     }, 3000);
            //     setDisable(false);
            // }

        }).catch((error) => {
            handleFreezStop();
            setErrorMessage("Something went wrong")
            setIsLoading(false)
            setTimeout(function () {
                setErrorMessage('')
                setDisable(false)
            }, 3000);
            setDisable(false);
        });
    }



    useEffect(() => {
        if(user.roles_id < 3){
            fetchReport();
        }else{
            navigate('/login');
        }
        const myDate = new Date();
        myDate.setDate(myDate.getDate());
        var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        var CurrentFormatedDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YYYY'); 
        setCurrentDate(cDate);
        setFormatedCurrentDate(CurrentFormatedDate)
    }, []);



    const [editorData, handleChangeEditor] = useState('');

    function followupChange(val,setFieldValue) {
        console.log(val);
        if (val === 'No') {
            const element = document.getElementById("noFollowup");
            element.style.display = "none";
            // Set default value for followupDate to the current date
            setFieldValue("followDate", dayjs(currentFollowUpDate));
        } else {
            const element = document.getElementById("noFollowup");
            element.style.display = "block";
        }
    }

    const handleCallType = (event) => {
        if (event === 'Email') {
            const element = document.getElementById("message");
            element.style.display = "block";
        } else {
            const element = document.getElementById("message");
            element.style.display = "none";
        }
    };
    const handleCallTypestatus = (event) => {
    };


    const activitySchema = Yup.object().shape({
        callType: Yup.string()
            .required('Please select Type'),
        status: Yup.string()
            .required('Please select status'),
        notes: Yup.string()
            .required('Please Note The Activity'),
        followupRequired: Yup.string().required('Please enter reason to disable.'),
        followTime: Yup.string().when("followupRequired",{
            is: (followupRequired) => followupRequired == 'Yes',
            then: () => Yup.string().test(
                      'is-valid-time',
                      'Invalid time format. Please enter a valid HH:mm:ss time.',
                      (value) => {
                        const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
                        return regex.test(value);
                      }
                    ),
        }),
        followDate: Yup.string().when("followupRequired",{
            is: (followupRequired) => followupRequired == 'Yes',
            then: () => Yup.string().test(
                'is-valid-year',
                      'Invalid Follow Up Date',
                      function (value) {
                        const date = new Date(value);
                        let formatedData1=Moment(date).format('MM-DD-YYYY');
                        const currentDate = new Date();
                        const year = date.getFullYear();
                        const formattedCurrentDate = Moment(currentDate).format('MM-DD-YYYY');
                        const splitYear = formatedCurrentDate.split('-')[2];
                        return Moment(formatedData1, 'MM-DD-YYYY').isSameOrAfter(formattedCurrentDate, 'day');
                        
                      }
                    ),
        }),
    });

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    function ContentLayout() {
        return (
            <>
                <Box>
                    {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    <Grid container direction="row" sx={{ justifyContent: 'space-between' }}>
                        <Grid item lg={5} md={5} sm={12} xs={12} mt={1}><Typography level="h4" color="primary">Missing Member Follow Up</Typography></Grid>
                        <Grid item container lg={7} md={7} sm={12} xs={12}>
                            <Grid item xs={11}>
                                <Card variant="outlined" size="sm" >
                                    <CardContent>
                                        <Grid container textAlign={'center'} item md={12}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <Typography level="h5">Next Follow up:
                                                    <Typography level='body1'>{followdate ? followdate.add_next_followup != "0000-00-00 00:00:00" ? followdate.add_next_followup ? Moment(followdate.add_next_followup).format('MM-DD-YYYY hh:mm')+"Hrs" : 'N/A' : 'N/A': 'N/A'} </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={1} textAlign="right" sx={{ justifyContent: "center" }}>
                                <Tooltip title="Close" >
                                    <BackIcon fontSize="large" sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={2} pt={2}>
                        <Grid item lg={5} md={5} sm={12} xs={12}>
                            <Card variant="outlined" size="lg">
                                <CardOverflow
                                    sx={{
                                        bgcolor: '#F6FAFF',
                                        borderColor: '#F6FAFF',
                                        padding: '1%',
                                    }}
                                >
                                    <Typography level="h5" sx={{ mb: 0.5 }} p={1}>Missing Member Details</Typography>
                                </CardOverflow>
                                <CardContent>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>First Name</TableCell>
                                                <TableCell>{data.first_name ? data.first_name : 'N/A'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Last Name</TableCell>
                                                <TableCell>{data.last_name ? data.last_name : 'N/A'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>User Name:</TableCell>
                                                <TableCell>{data.event_by_user ? data.event_by_user.name : 'N/A'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Email:</TableCell>
                                                <TableCell>{data.email ? data.email : 'N/A'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
                                                <TableCell>{data.event_by_user ? data.event_by_user.phone_no? data.event_by_user.phone_no : 'N/A': 'N/A'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>AID:</TableCell>
                                                <TableCell>{data.aids ? data.aids.aid?data.aids.aid : 'N/A': 'N/A'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>User Type:</TableCell>
                                                <TableCell>{data.event_by_user ?data.event_by_user.role? data.event_by_user.role.role_name?data.event_by_user.role.role_name : "N/A": "N/A": "N/A"}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item lg={7} md={7} sm={12} xs={12}>
                            <Collapse>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                ></Alert>
                            </Collapse>
                            <Card variant="plain" size="sm" sx={{ boxShadow: 'none' }}>
                                <Grid container item md={12} sx={{ justifyContent: 'space-between' }}>
                                    <Typography level="h6" color="primary">ACTIVITY LOG</Typography>
                                    <Button variant="contained" color='success' onClick={handleClickOpen}>New Activity</Button>
                                </Grid>
                            </Card>
                            {followup ? followup.map((row, idx, array) => (
                                <div key={idx}>
                                    <Card variant="outlined" size="lg" sx={{ marginTop: 2 }} >
                                        <CardOverflow
                                            sx={{
                                                bgcolor: '#F6FAFF',
                                                borderColor: '#F6FAFF',
                                                padding: '1%',
                                            }}
                                        >
                                            <Grid container item md={12} sx={{ justifyContent: 'space-between' }} p={2}>
                                                <Typography>
                                                    <Typography level="h6" sx={{ mb: 0.5 }}>{row.type ? row.type : 'Staff'} {array.length - idx}: &nbsp;</Typography>
                                                    <Typography >{row.user_id?row.user_id.name ? row.user_id.name : 'N/A': 'N/A'} </Typography>

                                                </Typography>
                                                <Typography >{row.created_at ? Moment(row.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm') + 'Hrs' : 'N/A'} </Typography>
                                            </Grid>
                                        </CardOverflow>
                                        <CardContent>
                                            <Grid container item md={12}>
                                                <Grid item xs={6}>
                                                    <Typography level="h6">Status: &nbsp;
                                                        <Typography level="body1">{row.status ? row.status : 'N/A'}</Typography>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid container item md={12} pt={2}>
                                                <Typography level="h6">Note:</Typography>
                                            </Grid>
                                            <Grid container item md={12} pt={1}>
                                                <Typography level="body1">{row.notes ? row.notes : 'N/A'}</Typography>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </div>
                            )) : ''}

                        </Grid>
                    </Grid>
                    {/* new activity start */}
                    <Formik
                        initialValues={{
                            callType: '',
                            status: '',
                            followupRequired: 'Yes',
                            missingMemberId: id,
                            notes: '',
                            message: editorData,
                            // followDate: dayjs().add(1, 'day'),
                            // followTime: dayjs(),
                            followDate: dayjs(currentFollowUpDate),
                            followTime: (currentFollowUpTime),
                        }}
                        validationSchema={activitySchema}
                        onSubmit={async(values, errors) => {
                            // const loginUser = JSON.parse(localStorage.getItem('user'));
                            // if(loginUser.roles_id >= 3 ) {
                            //     navigate('/login');
                            //     return false;
                            // }
                            // setIsLoading(true)
                            // setDisable(true)
                            const date = new Date(values.followDate);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            var mydate = year+"/"+month+"/"+day;
                            var todaysTime = values.followTime;
                            const apiUrl =  '/saveMissingMemberActivity';
                            const args = {
                                type: values.callType,
                                status: values.status,
                                followupRequired: values.followupRequired,
                                missingMemberId: values.missingMemberId,
                                notes: values.notes,
                                message: values.message,
                                followDate: mydate,
                                followTime: values.followTime,
                            }
                            const res = await dispatch(postData({apiUrl,args})).unwrap();
                            if(res) {
                                if(res.success === true){
                                    setOpen(false);
                                    fetchReport();                                                            
                                }else{
                                    swal({
                                        title: "Failed",
                                        text: res.message,
                                        icon: "error",
                                        button: "Ok",
                                    })
                                    if(res.message == "This action is unauthorized.") {
                                        navigate('/login');
                                    }
                                    
                                }
                            }
                            // }).then((res) => {
                            //     setIsLoading(false);
                            //     setDisable(false)
                            //     if (res.data.success === true) {
                            //         setOpen(false);
                            //         setSuccessMessage(res.data.message)
                            //         setIsLoading(false)
                            //         setTimeout(function () {
                            //             setSuccessMessage('')
                            //         }, 3000);
                            //         setDisable(false);
                            //         fetchReport();
                            //     } else {
                            //         setErrorMessage(res.data.message)
                            //         setIsLoading(false)
                            //         setTimeout(function () {
                            //             setErrorMessage('')
                            //         }, 3000);
                            //         setDisable(false);
                            //     }
                            // })
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                            <Dialog
                                maxWidth='sm'
                                disableEscapeKeyDown={true}
                                fullScreen={fullScreen}
                                open={open}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        handleClose();
                                    }
                                }}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
                                    {/* <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} color='primary'> */}
                                  New Follow Up
                                    {/* </Grid> */}
                                </DialogTitle>
                                <Form>
                                    <DialogContent>
                                        <Box mt={1}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <FormControl fullWidth size="small" 
                                                    error={Boolean(touched.callType && errors.callType)}
                                                       >
                                                        <InputLabel>Select Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="callType"
                                                            name="callType"
                                                            value={values.callType}
                                                            label="Select Type *"
                                                            onBlur={handleBlur('callType')}
                                                            onChange={(e) => {
                                                                handleCallType(e.target.value);
                                                                handleChange(e);
                                                            }}
                                                             >
                                                            <MenuItem value="Call">Call</MenuItem>
                                                            <MenuItem value="Email">Email</MenuItem>
                                                            <MenuItem value="Text">Text</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{touched.callType && errors.callType ? <div className='error'>{errors.callType}</div> : null}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <FormControl fullWidth size="small" 
                                                     error={Boolean(touched.status && errors.status)}
                                                        margin="dense">
                                                        <InputLabel>Select Satus</InputLabel>
                                                        <Select 
                                                            labelId="demo-simple-select-label"
                                                            id="status"
                                                            name="status"
                                                            value={values.status}
                                                            label="Select Type *"
                                                            onBlur={handleBlur('status')}
                                                            onChange={(e) => {
                                                                handleCallTypestatus(e.target.value);
                                                                handleChange(e);
                                                            }} 
                                                        >
                                                            <MenuItem value="Requested">Requested</MenuItem>
                                                            <MenuItem value="Assigned">Assigned</MenuItem>
                                                            <MenuItem value="Not Assigned">Not Assigned</MenuItem>
                                                        </Select>
                                                        <FormHelperText>{touched.status && errors.status ? <div className='error'>{errors.status}</div> : null}</FormHelperText>
                                                       
                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} mt={1} id="message" sx={{ display: 'none' }}>
                                                    <InputLabel>Message</InputLabel>
                                                    <FormControl fullWidth size="small">
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            required
                                                            name="message"
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                values.message = data;
                                                                handleChange('message');
                                                            }}
                                                            onBlur={(event, editor) => {
                                                                handleBlur('message');
                                                            }}
                                                            data={values.message}
                                                            value={values.message}
                                                            error={touched.message && errors.message ? errors.message : ''}
                                                        />
                                                       <FormHelperText>{touched.message && errors.message ? <div className='error'>{ errors.message}</div> : null}</FormHelperText>

                                                    </FormControl>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                                    <FormControl fullWidth size="small">
                                                        <TextareaAutosize
                                                            style={{ width: "100%" }}
                                                            required
                                                            margin="dense"
                                                            aria-label="minimum height"
                                                            minRows={6}
                                                            placeholder="Enter Note*"
                                                            name="notes"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.notes}
                                                            error={touched.notes && errors.notes ? errors.notes : ''}
                                                        />
                                                        <FormHelperText>{touched.notes && errors.notes ? <div className='error'>{errors.notes}</div> : null}</FormHelperText>

                                                    </FormControl>
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                    {/* <Grid item lg={7} md={7} sm={12} xs={12}> */}
                                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                            {/* <Stack direction="row"> */}
                                                            <FormControl>
                                                                <FormLabel id="">Followup Required :</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="followupRequired"
                                                                    value={values.followupRequired}
                                                                    onChange={(e) => {
                                                                        followupChange(e.target.value,setFieldValue)
                                                                        handleChange(e)
                                                                    }}
                                                                >
                                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                    <FormHelperText>{touched.followupRequired && errors.followupRequired ? errors.followupRequired : ''}</FormHelperText>
                                                                </RadioGroup>
                                                            </FormControl>
                                                            {/* </Stack> */}
                                                        </Grid>
                                                    {/* </Grid> */}
                                                </Grid>
                                                <Grid container lg={12} md={12} sm={12} xs={12} item id="noFollowup">
                                                    <Stack direction="row" spacing={2}>
                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['DatePicker']}>
                                                                    <DatePicker label="Follow Up Date*"
                                                                        fullWidth size="small"
                                                                        // onChange={handleChange} 
                                                                        onChange={(value) => { setFieldValue("followDate", value, true) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.followDate}
                                                                        name="followDate"
                                                                        // disablePast={true}
                                                                        minDate={dayjs(currentDate)}
                                                                        maxDate={dayjs().add(75, 'year')}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                            <FormHelperText style={{ color: touched.followDate && errors.followDate ? 'red' : 'inherit' }}>
                                                                {touched.followDate && errors.followDate ? errors.followDate : ''}
                                                            </FormHelperText>
                                                            {/* <FormHelperText>{touched.followDate && errors.followDate ? errors.followDate : ''}</FormHelperText> */}
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                <InputMask
                                                                    mask='99:99:99'
                                                                    label="Follow Up Time*"
                                                                    maskChar={null}
                                                                    name='followTime'
                                                                    fullWidth
                                                                    margin="dense"
                                                                    // size="small"
                                                                    id='followTime'
                                                                    value={values.followTime}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    >
                                                                    {(inputProps) =>
                                                                        <TextField
                                                                            label="Follow Up Time*" variant="outlined"
                                                                            {...inputProps}
                                                                        />
                                                                    }
                                                                </InputMask>
                                                                {
                                                                touched.followTime && errors.followTime ? (
                                                                    <div className='error'>{errors.followTime}</div>
                                                                ) : (
                                                                    ''
                                                                )
                                                                }
                                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['TimePicker']}>
                                                                    <TimePicker label="Follow Up Time*"
                                                                        format="hh:mm:ss"
                                                                        fullWidth
                                                                        size="small"
                                                                        value={values.followTime}
                                                                        name="followTime"
                                                                        onChange={(value) => { setFieldValue("followTime", value, true) }}
                                                                        // onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                            <FormHelperText>{touched.followTime && errors.followTime ? errors.followTime : ''}</FormHelperText> */}
                                                        </Grid>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Button autoFocus variant="contained" sx={{ minWidth: 150 }} onClick={handleClose}>
                                                    CLOSE
                                                </Button>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Button variant="contained" sx={{ minWidth: 150 }} color="success" type="submit" onClick={disable === false ? handleSubmit : ''}>
                                                    SAVE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </Form>
                            </Dialog>
                        )}
                    </Formik>
                    {/* new activity end */}
                </Box>
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={freez}
                            onClick={handleFreezStop}
                        >
                            <Freez />
                        </Backdrop>
                    </div>
            {/* loader code end */}
            </div>
    );
}


export default MissingMemberFollowup;