
import React from "react";
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardOverflow } from '@mui/joy';

function AffiAgreement1(affiliateDetails, status) {
    const theme = useTheme();
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">

                    <CardContent>
                        <Grid item mb={3}>
                            <img alt="#" src={require('../../assets/images/mainLogo.jpg')} />
                        </Grid>

                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            Dear {affiliateDetails.affiliateDetails.first_name ? affiliateDetails.affiliateDetails.first_name.charAt(0).toUpperCase() + affiliateDetails.affiliateDetails.first_name.slice(1).toLowerCase() : ''}, <br /><br />
                            Become a MyFreeScoreNow Credit Monitoring Affiliate today! There’s no cost to join. Get your affiliate links in minutes!
                        </Typography><br />
                        <Typography variant="h6">
                            <span style={{ fontWeight: 'bold' }}><u>Earn higher commissions! From $7.00 to $16.00</u></span> per customer per month! Commissions are paid out monthly.
                        </Typography><br />
                        <Typography variant="h6">
                            Get 3 Bureau reports and scores for your clients – reports automatically import into your software.
                        </Typography><br />
                        <Typography variant="h6">
                            Our <span style={{ fontWeight: 'bold' }}>plans start as low as $19.95 per month </span> and can be offered to your client with or without a 7-Day trial period.
                        </Typography><br />

                        <Typography variant="h6">
                            Each plan includes a 3 Bureau report upfront and refresh every 30 days.</Typography><br />
                        <Typography variant="h6">
                            <span style={{ fontWeight: 'bold' }}>Referral Program.</span> &nbsp;<u>Earn MORE cash</u> by referring other companies to join our affiliate program.</Typography><br />
                        <Typography variant="h6">
                            <span style={{ fontWeight: 'bold' }}>Live agent customer support</span> for you and your clients <span style={{ fontWeight: 'bold' }}>- We’re open 7 days/week!</span></Typography><br />
                        <Typography variant="h6">
                            <span style={{ fontWeight: 'bold' }}><u>FREE</u> Co-branded Site –</span>  your own personalized credit monitoring link branded to your company with your logo, and colors. PLUS: - Special alerts emailed to you about any significant changes to your client’s scores - And, Member credentials passed directly to you after enrollment.</Typography><br />
                        <Typography variant="h6"><b>Mobile App</b> allowing your clients to track their credit on the go!  Clients can download app and see it <b>branded to your company</b> when you have a co-brand site.</Typography><br />
                        <Typography variant="h6">
                            <span style={{ fontWeight: 'bold' }}><u>FREE</u> Lead Generation tool -</span> Credit Snapshot. - a special site <span style={{ fontWeight: 'bold' }}>created for you at no cost </span> and branded to your
                            company to provide a FREE credit report summary and score to your prospects. Convert more visitors to clients</Typography><br />

                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>FREE MyFreeScoreNow memberships for you!*</Typography><br />

                        <Typography variant="h6">
                            <span style={{ fontWeight: 'bold' }}>Powerful website</span> tools help you advise your clients on how they can boost their scores</Typography><br />

                        <Typography variant="h6">
                            <span style={{ fontWeight: 'bold' }}>Affiliate Portal.</span> Log-in to your customized dashboard to see all of your clients, activity, status <span style={{ fontWeight: 'bold' }}>- and your
                                commissions!</span></Typography><br />

                        <Typography variant="h6">
                            Ready to get started? <span style={{ fontWeight: 'bold' }}>Complete and submit the Affiliate Agreement now,</span> it only takes a few minutes!</Typography><br />
                        <Typography variant="h6">
                            Within minutes after returning your document we’ll send your Welcome Email containing your unique affiliate
                            links and the instructions for making MyFreeScoreNow the Primary Report Provider in your credit repair software</Typography><br />

                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            Call us: 1-888-548-2008 or Email us: info@myfreescorenow.com</Typography><br />

                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                         <i>*Terms and conditions apply for Free Membership.</i></Typography><br />

                    </CardContent>
                </Card>
            </Grid >
        </>
    );
}

export default AffiAgreement1;