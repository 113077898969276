import * as React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Typography } from '@mui/joy';
import LoginHeader from "../../../Components/LoginHeader";
import RegisterFooter from "../../Auth/RegisterFooter";
import '../../../Css/Custom.css';
function MembershipQuestion(props) {
    return (
        <>
            <LoginHeader />
            <Box mt={1} mb={3}>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center !important", padding: "1%" }}>
                    <Typography level="h2" className="font-3" sx={{ textAlign: "center" }}><u>WHAT AM I REGISTERING FOR? </u></Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <img className="imgCenter" src={require("../../../assets/images/MEMBERSHIPCROPPED.png")} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.1em" }} mt={2}>
                                - You are being charged $1 to enroll in a 7 day trial of a
                                MyFreeScoreNow credit monitoring membership.
                            </Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.1em" }} mt={2}> - Upon enrollment you will receive a copy of your 3 Bureau Report and
                                Scores.</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.1em" }} mt={2}>- Cancel your membership anytime within the trial period
                                to not receive any further charges.</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.1em" }} mt={2}>- If you do not cancel your membership within the trial period
                                you will be converted to a monthly paid membership and
                                charged $xxx every month until you cancel.</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.1em" }} mt={2}> <a href="https://member.myfreescorenow.com/login" style={{ textDecorationStyle: "none" }}>Click Here</a> to Login</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>

    );
}
export default MembershipQuestion;