import React,{useMemo,useState,useEffect} from 'react';
import {useParams, useNavigate, Link,useLocation} from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import {Box,Tab,Grid,Tabs, Button, Stack,Backdrop,InputBase,TextField,Paper,ListItem,List } from '@mui/material';
import Typography from '@mui/joy/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { messaging, FCMServerKey, FCMSendUrl } from '../../../firbase/firstore';
import { getToken } from 'firebase/messaging';
import swal from 'sweetalert';
import AllCobrands from '../../../Components/Cobrand/AllCobrands';
import VerifyCobrands from '../../../Components/Cobrand/VerifyCobrands';
import AuthUser from "../../../Components/Auth/AuthUser";
// import AdvancedCBReview from '../../../Components/Cobrand/AdvancedCBReview';
function Index(props) {
    const dispatch = useDispatch();
    const {user } = AuthUser();
	const { tab } = useParams();
    const [value, setValue] = React.useState(tab);
  	const [freez, setFreez] = React.useState(false);
	const navigate = useNavigate();

      const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const ContentLayout = 
    <>
    <Box sx={{ width: '100%', typography: 'body1' }}>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography level="h4" color="primary">Cobranding</Typography>
              </Grid>
              <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
							<TabList onChange={handleChange} aria-label="lab API tabs example" >
                                <Tab label="All" component={Link} to="/cobrand/all" value="all" sx={{color:'black'}} />
                                {user.roles_id!=13? <Tab label="Initial Cobrand" component={Link} to="/cobrand/pending" value="pending" sx={{color:'black'}} />:null}
                               
                            </TabList>
                        </Grid>
                    </Box>
                    <TabPanel value="all"><AllCobrands tab={tab} /></TabPanel>
                    <TabPanel value="pending"><VerifyCobrands tab={tab} /></TabPanel>
                </TabContext>
    </Box>
        </>
  
     
    return(
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                  >
                    <Freez />
                  </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}
    
export default Index;