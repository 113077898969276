import React, { useMemo,useState,useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
//MRT Imports
import MaterialReactTable from 'material-react-table';
//Material-UI Imports
import {
  Box,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
function CompanyData() {
     //Neccessary variables declearation start
     const {http,user} = AuthUser();
     const [companylist, setCompanyList] = useState([]);
     const [loader,setLoader]=useState('');
     const [filterCompany, setFilterCompany]=useState([]);
     const [search, setSearch]=useState("");
     const [totalRows, setTotalRows] = useState(0);
     const [perPage, setPerPage] = useState(50);
     const q = search==''?'null':search;
     //Neccessary variables declearation end
     //fetchcompany list function start
     const fetchCompanyList = async page =>{
     setLoader(<CircularProgress/>)
             http.get('/fetchCompanyList/'+q+'/'+perPage+'/1/list?page='+page)
                       .then((res)=>{
                           setCompanyList(res.data.data);
                           setFilterCompany(res.data.data)
                           setTotalRows(res.data.total);
                           setLoader('');
                       })
                   }  
             const handlePageChange = page => {
             fetchCompanyList(page);
             console.log(companylist);
         };
     //fetchcompany list function end
     //handlePerRowsChange list function start
     const handlePerRowsChange = async (newPerPage, page) => {
     setLoader(<CircularProgress/>)
     http.get('/fetchCompanyList/'+q+'/'+newPerPage+'/1/list?page='+page)
     .then((res)=>{
         setCompanyList(res.data.data);
         setFilterCompany(res.data.data); 
         setLoader('');
         setPerPage(newPerPage);
     })
 };
 //handlePerRowsChange list function end
 useEffect(()=>{
   fetchCompanyList(1);
 },[])  
 useEffect(()=>{
 const result = fetchCompanyList()
 setFilterCompany(result);
 },[search])
     const columns = useMemo(
         () => [
           {
             id: 'Company_Details', //id used to define `group` column
             header: 'Company Details',
             columns: [
               {
                 accessorFn: (row) => `${row.company_id}`, //accessorFn used to join multiple data into a single cell
                 id: 'company_id', //id is still required when using accessorFn instead of accessorKey
                 header: 'Company ID',
                 size: 100,
               },
               {
                 accessorFn: (row) => `${row.company_name}`, //accessorFn used to join multiple data into a single cell
                 id: 'company_name', //id is still required when using accessorFn instead of accessorKey
                 header: 'Company Name',
                 size: 100,
               },
             ],
           },
           {
             id: 'Banking_Details',
             header: 'Banking Details',
             columns: [
               {
                 accessorFn: (row) =>`${row.account_active_flag} ${row.account_flag}`, 
                 id: 'account_flag',
                 header: 'Submitted',
                 size: 100,
                 Cell: ({ row }) => (
                    <Box>              
                        <img src={require("../../../assets/icons/companyicon.png")}/>
                    </Box>
                  ),
                 //custom conditional format and styling
               },
               {
                 accessorKey: 'ach_info_added_to_bank', //hey a simple column for once
                 accessorFn: (row) =>`${row.ach_info_added_to_bank}`, 
                 header: 'ACH Added',
                 size: 100,
                 Cell: ({ row }) => (
                    <Box>              
                        <img src={require("../../../assets/icons/companyicon.png")}/>
                    </Box>
                  ),
               },
               {
                 accessorFn: (row) => `${row.account_active_flag} ${row.account_flag} ${row.ach_info_added_to_bank}`, 
                 accessorKey: 'ach_info_added_to_bank', //hey a simple column for once
                 id: 'actions',
                 header: 'Actions',
                 size: 100,
                 Cell: ({ row }) => (
                    <Box>              
                        <img src={require("../../../assets/icons/companyedit.png")}/>
                    </Box>
                  ),
               },
             ],
           },
           {
             id: 'Programe_Details',
             header: 'Program Details',
             columns: [
               {
                 accessorFn: (row) =>`${row.referral_flag}`,
                 accessorKey: 'referral_flag', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                 header: 'Referral Programe',
                 size: 100,
                 Cell: ({ row }) => (
                    <Box>              
                        <img src={require("../../../assets/icons/companyicon.png")}/>
                    </Box>
                  ),
               },
 
               {
                 accessorFn: (row) =>`${row.snapshot_flag}`,
                 accessorKey: 'snapshot_flag', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                 header: 'Credit Snap Shot',
                 size: 100,
                 Cell: ({ row }) => (
                    <Box>              
                        <img src={require("../../../assets/icons/companyicon.png")}/>
                    </Box>
                  ),
               },
               {
                 accessorFn: (row) => `${row.account_active_flag} ${row.account_flag} ${row.ach_info_added_to_bank}`, 
                 accessorKey: 'ach_info_added_to_bank', //hey a simple column for once
                 id: 'actions',
                 header: 'Actions',
                 size: 100,
                 Cell: ({ row }) => (
                    <Box>              
                        <img src={require("../../../assets/icons/companyaction.png")}/>
                    </Box>
                  ),
               },
             ],
           },
         ],
         [],
       );
    function ContentLayout() {
        if(fetchCompanyList){
            return (
            <>
                <MaterialReactTable 
                    columns={columns}
                    data={filterCompany}
                    enableDensityToggle={false}
                    initialState={{ density: 'compact' }}
                    enableHiding= {false}
                    enableFullScreenToggle={false}
                    enableColumnActions={false}
                    muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                          backgroundColor:"#F6FAFF",
                        },
                      }} 
                />
            </>
            );
        }else{
            return(
                <>Loading...</>
            )
        }
    }
        return(
            <div>
                <DefaultLayout content={<ContentLayout />} />
            </div>
        );
    }
    
    
    export default CompanyData;