import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup';
import { Formik } from 'formik';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box,  ClickAwayListener, Tooltip, Grid, Button, TextField, Divider, InputLabel, FormControl, OutlinedInput, InputAdornment,
    FormControlLabel, FormLabel, RadioGroup, Radio, IconButton,
} from '@mui/material';
import { Typography, Card, CardContent, CardOverflow } from '@mui/joy';
import AuthUser from '../../../Components/Auth/AuthUser';
import swal from "sweetalert";
import Moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import CloseIcon from '@mui/icons-material/Close';
let Backupdata = [];
let htmlData ='';

function AddBankDetailsForm(props) {
    const dispatch = useDispatch();
    const { user } = AuthUser();
    const { id } = useParams();
    const inputRef = useRef();
    const [statusMessage, setStatusMesage] = useState("");
    const [loader, setLoader] = useState();
    const [value, setValue] = useState();
    const navigate = useNavigate();
    const [bank_details, setBankDetails] = useState([]);
    const [operation, setOperation] = useState("Update");
    const [btntext, setBtntext] = useState("Update");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paymentsNavigation = queryParams.get('payments');
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end

    //report issue functions and variable start
    var [subject,setSubject] = useState("MFSN Bank details Incorrect (ACH Issue)");

   
   
    const [mailBody,setMailBody] = useState();
    //report issue functions and variable end

    //history table variable start

    const [ filterhistory,setFilterHistory] = useState([]);

    //history table variable end

    //modal variable and function start
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleTooltipClose1 = () => {
      setOpen1(false);
    };
  
    const handleTooltipOpen1 = () => {
      setOpen1(true);
    };
    const handleTooltipClose2 = () => {
        setOpen2(false);
      };
    
      const handleTooltipOpen2 = () => {
        setOpen2(true);
      };


    const changeACH = (values1) => {
        // Update the value of the object at the specified index
        Backupdata.bank_name = values1.BankName;
        Backupdata.name_on_account = values1.NameonAccount
        Backupdata.routing_number = values1.BankRouting
        Backupdata.account_number = values1.AccountNumber
        Backupdata.account_type = values1.AccountType
        Backupdata.account_category = values1.AccountCategory
        Backupdata.ach_info_added_to_bank = values1.ACHinfoaddedtobank
        Backupdata.email_to_notify = values1.EmailAddresstonotifyofpaymenttransfers
       
    }

   
    //modal variable and function end
    const [firstlable, setFirstLable] = useState(false);
    //Fetch Company Bank Details
    const fetchCompanyBankDetails = async() => {
        handleFreezStart();
        const apiUrl = '/fetch-company-bank-details/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            handleFreezStop();
            console.log(res)
            if (res.company_master_id != null) {
                setBankDetails(res);
                Backupdata.bank_name = res.bank_name;
                Backupdata.name_on_account = res.name_on_account
                Backupdata.routing_number = res.routing_number
                Backupdata.account_number = res.account_number
                Backupdata.account_type = res.account_type
                Backupdata.account_category = res.account_category
                Backupdata.ach_info_added_to_bank = res.ach_info_added_to_bank
                Backupdata.isFormDirty = true
                // LastACHupdatedate:dayjs(ACHdate),
                Backupdata.email_to_notify = res.email_to_notify
                
                htmlData = `
                <p>Dear ${ res.company_name },</p>
                <p>The bank details you provided could not be verified with your bank. Please login to your affiliate account and update your account information in bank details.</p>
                <p>If you have any questions or if there is anything we can do to help you, please feel free to contact us:</p>
                <p>info@myfreescorenow.com</p>
                <p888-548-2008</p>
                <p>Thank You!</p>`;
                setMailBody(htmlData);

                setValue(bank_details.last_ach_updated);
                fetchCompanyBankDetailsHistory(res.id);
                if (res.report_issue == 1) {
                    setBtntext("Update And Resolve")
                }else{
                    setBtntext("Update")
                }

                if (res.bank_name == null || res.bank_name == undefined) {
                    setFirstLable(true);
                }

            } else {
                setOperation("create");
                setBtntext("Submit")
            }
        }

    };

    const fetchCompanyBankDetailsHistory = async(bankhistoryid) => {
        handleFreezStart();
        const apiUrl = '/fetch-company-bank-details-history';
        const args = {
            id:bankhistoryid
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            console.log(Object.values(res))
            handleFreezStop();
            setFilterHistory(Object.values(res));
            
        }

    };

    //Call Function if load this page
    useEffect(() => {
       
        fetchCompanyBankDetails();
    }, [])

    //Create function start
    const CreateBankDetails = async(values) => {

        if (values.BankName == values.NameonAccount) {

            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            }).then((ok) => {
                if (ok) {
                    inputRef.current?.focus();
                }
            });


        } 
        else {
            const apiUrl = '/add-company-bank-details';
            const args = {
                    company_master_id: id,
                    bank_name: values.BankName,
                    name_on_account: values.NameonAccount,
                    routing_number: values.BankRouting,
                    account_number: values.AccountNumber,
                    account_type: values.AccountType,
                    account_category: values.AccountCategory,
                    email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                    
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                  if(res.success === true){
                    swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                    }).then((ok)=>{
                    if(ok){
                        fetchCompanyBankDetails();
                    }
                    });
                  }else{
                    swal({
                      title: "Failed",
                      text: res.message,
                      icon: "error",
                      button: "Ok",
                  })
                  }
            }
        }
    }
    //Create function end





    //Update function start

    const UpdateBankDetails = async(values) => {


        if (values.BankName == values.NameonAccount) {

            swal({
                title: "Alert",
                text: "Bank Name & Name on Account must be different",
                icon: "error",
                button: "Ok",
            }).then((ok) => {
                if (ok) {
                    inputRef.current?.focus();
                }
            });


        } else {
                const apiUrl = '/update-company-bank-details';
                const args = {
                    id: id,
                    bank_name: values.BankName,
                    name_on_account: values.NameonAccount,
                    routing_number: values.BankRouting,
                    account_number: values.AccountNumber,
                    account_type: values.AccountType,
                    account_category: values.AccountCategory,
                    ach_info_added_to_bank: values.ACHinfoaddedtobank,
                    email_to_notify: values.EmailAddresstonotifyofpaymenttransfers,
                    
                }
                const res =  await dispatch(postData({apiUrl,args})).unwrap();
                if(res) {
                        if (res.success === true) {
                        swal({
                            title: "Success",
                            text: res.message,
                            icon: "success",
                            button: "Ok",
                        }).then((ok) => {
                            if (ok) {
                                fetchCompanyBankDetails();
                            }
                        });
                    } else {
                        swal({
                            title: "Failed",
                            text: res.message,
                            icon: "error",
                            button: "Ok",
                        });
                    }
                
                }else {
                    setLoader('');
                    document.getElementById("createbtn").classList.remove('d-none');
                    setStatusMesage('');
                    setLoader('');
                    swal({
                        title: "Failed",
                        text: res.message,
                        icon: "error",
                        button: "Ok",
                    });
                }
        }
    }
    //Update function end


    //Bank validation Schema start


    const BankSchema = Yup.object().shape({
        

        NameonAccount: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Please enter your Name On Account.'),

        BankRouting: Yup.string()
            .matches(/^\d+$/, 'Invalid Format')
            .min(9, 'At least 9 digit required')
            .max(9, 'maximum 9 digit')
            .required('Please enter your Bank Routing number.'),

        AccountNumber: Yup.string()
            .required('Please enter your Account Number.'),

        AccountType: Yup.string()

            .required('Please select Account Type.'),


        AccountCategory: Yup.string()

            .required('Please select Is Account.'),

        EmailAddresstonotifyofpaymenttransfers: Yup.string()
            .email('Invalid email format.')
            .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .required('Please enter your email address to notify payment transfers.'),

    });


    //Bank validation Schema end
    //history table column array start


    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => `${row.id}`, //accessorFn used to join multiple data into a single cell
                id: 'id', //id is still required when using accessorFn instead of accessorKey
                header: 'ID',
                numeric: true,
                size: 150,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <span>{row.original.id}</span>
                    </Box>
                ),
            },
            {

                id: 'updated_by', //id is still required when using accessorFn instead of accessorKey
                header: 'Updated By',
                size: 150,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <span>{row.original.name}</span>
                    </Box>
                ),
            },
            {
                id: 'user_role', //id is still required when using accessorFn instead of accessorKey
                header: 'User Role',
                size: 150,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <span>{row.original.role_name}</span>
                    </Box>
                ),
            },
            {
                id: 'updated_fields', //id is still required when using accessorFn instead of accessorKey
                header: 'Updated Fields',
                size: 150,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <span>{row.original.Updated_fields ? row.original.Updated_fields : "No fields has been updated in this operation."}</span>
                    </Box>
                ),
            },
            {
                id: 'updated_date', //id is still required when using accessorFn instead of accessorKey
                header: 'Updated Date',
                size: 150,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <span>{Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YY')}</span>
                    </Box>
                ),
            },




        ],
        [],
    );
    //history table column array end
    
    let updateFieldCount= 0;
    let accountNoCount= 0;
    let bankNameCount= 0;
    let routingNoCount= 0;
    let accountNameCount= 0;
    let emailCount= 0;
    let accountTypeCount= 0;
    let accountCatCount= 0;

    function ContentLayout() {
        return (
            <>
                <Box  sx={{mt:1.5}}>
                    <Formik
                        initialValues={{
                            BankName: Backupdata.bank_name,
                            NameonAccount: Backupdata.name_on_account,
                            BankRouting: Backupdata.routing_number,
                            AccountNumber: Backupdata.account_number,
                            AccountType: Backupdata.account_type,
                            AccountCategory: Backupdata.account_category,
                            ACHinfoaddedtobank: Backupdata.ach_info_added_to_bank,
                            isFormDirty : true,
                            achCheckValue : false,

                            EmailAddresstonotifyofpaymenttransfers: Backupdata.email_to_notify
                        }}
                        validationSchema={BankSchema}
                        onSubmit={(values, errors) => {
                            operation == "create" ? CreateBankDetails(values) : UpdateBankDetails(values);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                            <Box>
                                <Card variant="outlined" size="lg">
                                    <CardOverflow
                                        sx={{
                                            bgcolor: '#F6FAFF',
                                            borderColor: '#F6FAFF',
                                            padding: '1%',
                                        }}
                                    >
                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                                            <Typography level="h4" color="primary">
                                                Bank Details ({bank_details.company_name})
                                            </Typography >
                                            <Typography level="h4" color="primary">Last updated: {Moment(bank_details.updated_at).tz('America/Los_Angeles').format('MM-DD-YY')}</Typography>

                                        </Grid>
                                    </CardOverflow>
                                    <CardContent>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={1}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <TextField
                                                    label="Bank Name*"
                                                    margin="dense"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    name='BankName'
                                                    disabled={user.roles_id > 3 ? true : false}
                                                    id='BankName'
                                                    onChange={(e)=>{
                                                        handleChange(e)
                                                        if(e.target.value == bank_details.bank_name){
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount == 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }                                                                
                                                        }else{                                          
                                                            setFieldValue('isFormDirty',false) 
                                                            if(bankNameCount == 0){
                                                                bankNameCount = bankNameCount + 1;
                                                                updateFieldCount = updateFieldCount + 1;                                                        
                                                            }
                                                        }
                                                    }}
                                                    value={values.BankName}
                                                    onBlur={handleBlur('BankName')}
                                                />
                                                {touched.BankName && errors.BankName ? <div className='error'>{errors.BankName}</div> : null}
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                               
                                                <FormControl fullWidth size="small" variant="outlined" >
                                                    <InputLabel htmlFor="outlined-adornment-password"
                                                    sx={{ color: user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.routing_number_new != bank_details.bank_details_history.routing_number_old ? "#ed6c02" : 'black' : 'black' : 'black' }} 
                                                    >Bank Routing (ABA / ACH) Number*</InputLabel>
                                                    <OutlinedInput
                                                        label="Bank Routing (ABA / ACH) Number*"                                                        
                                                        size="small"
                                                        margin="dense"
                                                        fullWidth
                                                        name='BankRouting'
                                                        id='BankRouting'
                                                        disabled={user.roles_id > 3 ? true : false}
                                                        
                                                        color={user.roles_id < 3 ? (bank_details.bank_details_history?bank_details.bank_details_history.routing_number_new != bank_details.bank_details_history.routing_number_old ? "warning" : '' : '') : ''}
                                                        focused={user.roles_id < 3 ? (bank_details.bank_details_history?bank_details.bank_details_history.routing_number_new != bank_details.bank_details_history.routing_number_old ? true : false : false) : false}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            if(e.target.value == bank_details.routing_number){
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount == 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(routingNoCount == 0){
                                                                    routingNoCount = routingNoCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        value={values.BankRouting}
                                                        onBlur={handleBlur('BankRouting')}
                                                        endAdornment={
                                                            <InputAdornment>
                                                            <>
                                                            <ClickAwayListener onClickAway={handleTooltipClose1}>
                                                                <Tooltip 
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                  }}
                                                                  onClose={handleTooltipClose1}
                                                                  open={open1}
                                                                  disableFocusListener
                                                                  disableHoverListener
                                                                  disableTouchListener
                                                                  position="left"
                                                                    title={<div style={{ maxWidth: '700px' }}><img alt="#" src={require("../../../assets/images/tooltip.png")}
                                                                        style={{ width: '100%', height: 'auto' }} />
                                                                        <IconButton
                                                                            aria-label="close"
                                                                            size="small"
                                                                            onClick={handleTooltipClose1}
                                                                            style={{ position: 'absolute', top: 0, left: 0 }}
                                                                        >
                                                                            <CloseIcon fontSize="inherit" sx={{backgroundColor:"black", color: "white" }} />
                                                                        </IconButton></div>} >
                                                                    <IconButton
                                                                       onClick={handleTooltipOpen1}
                                                                       style={{ position: 'absolute', top: 0, right: 0 }}
                                                                    >
                                                                         
                                                                        <img alt="#" src={require("../../../assets/images/i_icon.png")} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                </ClickAwayListener>
                                                            </>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                                {touched.BankRouting && errors.BankRouting ? <div className='error'>{errors.BankRouting}</div> : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                            <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                                <TextField
                                                    label="Name on Account*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin="dense"
                                                    inputRef={inputRef}
                                                    name='NameonAccount'
                                                    disabled={user.roles_id > 3 ? true : false}
                                                    id='NameonAccount'
                                                    onChange={(e)=>{
                                                        handleChange(e)
                                                        if(e.target.value == bank_details.name_on_account){
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount == 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }                                                                
                                                        }else{                                          
                                                            setFieldValue('isFormDirty',false) 
                                                            if(accountNameCount == 0){
                                                                accountNameCount = accountNameCount + 1;
                                                                updateFieldCount = updateFieldCount + 1;                                                        
                                                            }
                                                        }
                                                    }}
                                                    value={values.NameonAccount}
                                                    onBlur={handleBlur('NameonAccount')}
                                                    color={user.roles_id < 3 ? (bank_details.bank_details_history?bank_details.bank_details_history.name_on_account_new != bank_details.bank_details_history.name_on_account_old ? "warning" : '' : '') : ''}
                                                    focused={user.roles_id < 3 ? (bank_details.bank_details_history?bank_details.bank_details_history.name_on_account_new != bank_details.bank_details_history.name_on_account_old ? "warning" : '' : '') : ''}      
                                                />
                                                {touched.NameonAccount && errors.NameonAccount ? <div className='error'>{errors.NameonAccount}</div> : null}
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                               
                                                <FormControl fullWidth size="small" variant="outlined">
                                                    <InputLabel htmlFor="outlined-adornment-password"
                                                    sx={{ color: user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_number_new != bank_details.bank_details_history.account_number_old ? "#ed6c02" : 'black' : 'black' : 'black' }} 
                                                    >Account Number*</InputLabel>
                                                    <OutlinedInput
                                                        label="Account Number*"
                                                        type="text"
                                                        size="small"
                                                        fullWidth
                                                        name='AccountNumber'
                                                        margin="dense"
                                                        id='AccountNumber'
                                                        disabled={user.roles_id > 3 ? true : false}
                                                        onChange={(e)=>{
                                                            handleChange(e)
                                                            console.log("before count : " + updateFieldCount);
                                                            if(e.target.value == bank_details.account_number){
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                console.log("after count : " + updateFieldCount);
                                                                if(updateFieldCount == 0){
                                                                    console.log(e.target.value);
                                                                    console.log(bank_details.account_number);
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{                                          
                                                                setFieldValue('isFormDirty',false) 
                                                                if(accountNoCount == 0){
                                                                    accountNoCount = accountNoCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                        
                                                                }
                                                            }
                                                        }}
                                                        value={values.AccountNumber}
                                                        onBlur={handleBlur('AccountNumber')}
                                                        
                                                        color={user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_number_new != bank_details.bank_details_history.account_number_old ? "warning" : '' : '' : ''}
                                                        focused={user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_number_new != bank_details.bank_details_history.account_number_old ? "warning" : '' : '' : ''}      
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                 <>
                                                            <ClickAwayListener onClickAway={handleTooltipClose1}>
                                                                <Tooltip
                                                                 PopperProps={{
                                                                    disablePortal: true,
                                                                  }}
                                                                  onClose={handleTooltipClose2}
                                                                  open={open2}
                                                                  disableFocusListener
                                                                  disableHoverListener
                                                                  disableTouchListener
                                                                  position="left"
                                                                    title={<div style={{ maxWidth: '400px' }}><img alt="#" src={require("../../../assets/images/tooltip.png")}
                                                                        style={{ width: '100%', height: 'auto' }} />
                                                                         <IconButton
                                                                            aria-label="close"
                                                                            size="small"
                                                                            onClick={handleTooltipClose2}
                                                                            style={{ position: 'absolute', top: 0, left: 0 }}
                                                                        >
                                                                            <CloseIcon fontSize="inherit" sx={{backgroundColor:"black", color: "white" }} />
                                                                        </IconButton>
                                                                        </div>}>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        edge="end"
                                                                        onClick={handleTooltipOpen2}
                                                                    >
                                                                        <img alt="#" src={require("../../../assets/images/i_icon.png")} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                </ClickAwayListener>
                                                                </>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                                {touched.AccountNumber && errors.AccountNumber ? <div className='error'>{errors.AccountNumber}</div> : null}
                                            </Grid>
                                        </Grid>
                                        <Typography variant="subtitle1" gutterBottom className="font2" color="blue">
                                            Enter the name of the Account held with the Bank.(Do not enter the Name of the Bank Here)
                                        </Typography>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4} >
                                            <Grid item lg={6} md={6} sm={12} xs={12} mt={2}>
                                                <TextField
                                                    label="Email Address to notify of payment transfers*"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    disabled={user.roles_id > 3 ? true : false}
                                                    name='EmailAddresstonotifyofpaymenttransfers'
                                                    // onChange={handleChange('EmailAddresstonotifyofpaymenttransfers')}
                                                    onChange={(e)=>{
                                                        handleChange(e)
                                                        if(e.target.value == bank_details.email_to_notify){
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount == 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }                                                                
                                                        }else{                                          
                                                            setFieldValue('isFormDirty',false) 
                                                            if(emailCount == 0){
                                                                emailCount = emailCount + 1;
                                                                updateFieldCount = updateFieldCount + 1;
                                                            }
                                                        }
                                                    }}
                                                    value={values.EmailAddresstonotifyofpaymenttransfers}
                                                    onBlur={handleBlur('EmailAddresstonotifyofpaymenttransfers')}
                                                    color={user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.email_to_notify_new != bank_details.bank_details_history.email_to_notify_old ? "warning" : '' : '' : ''}
                                                    focused={user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.email_to_notify_new != bank_details.bank_details_history.email_to_notify_old ? "warning" : '' : '' : ''} 
                                                />
                                                {touched.EmailAddresstonotifyofpaymenttransfers && errors.EmailAddresstonotifyofpaymenttransfers ? <div className='error'>{errors.EmailAddresstonotifyofpaymenttransfers}</div> : null}
                                                {firstlable ? <Typography variant="subtitle1" gutterBottom className="font2" color="blue">
                                                    This is email address with which {bank_details.company_name} has been registered with us. You can change this if you want another email for banking purpose.
                                                </Typography> : null}
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12} mt={2}>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} >
                                                    <Grid container item lg={6} md={6} sm={12} xs={12} >
                                                        <Card variant="outlined">
                                                            <FormControl>
                                                                <FormLabel sx={{ color: user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_type_new != bank_details.bank_details_history.account_type_old ? "#ed6c02" : 'black' : 'black' : 'black' }} 
                                                                >Type of Account*</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    name='AccountType'
                                                                    id='AccountType'
                                                                    disabled={user.roles_id > 3 ? true : false}
                                                                    onChange={(e)=>{
                                                                        handleChange(e)
                                                                        if(e.target.value == bank_details.account_type){
                                                                            updateFieldCount = updateFieldCount - 1; 
                                                                            if(updateFieldCount == 0){
                                                                                setFieldValue('isFormDirty',true)
                                                                            }                                                                
                                                                        }else{                                          
                                                                            setFieldValue('isFormDirty',false) 
                                                                            if(accountTypeCount == 0){
                                                                                accountTypeCount = accountTypeCount + 1;
                                                                                updateFieldCount = updateFieldCount + 1;
                                                                            }
                                                                        }
                                                                    }}
                                                                    value={values.AccountType}
                                                                    onBlur={handleBlur('AccountType')}
                                                                    color={user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_type_new != bank_details.bank_details_history.account_type_old ? "warning" : '' : '' : ''}
                                                                    focused={user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_type_new != bank_details.bank_details_history.account_type_old ? "warning" : '' : '' : ''} 
                                                                >
                                                                    <FormControlLabel value="checking" control={<Radio />} label="Checking" sx={{ fontWeight: "bold" }} />
                                                                    <FormControlLabel value="savings" control={<Radio />} label="Savings" sx={{ fontWeight: "bold" }} />
                                                                </RadioGroup>
                                                                {touched.AccountType && errors.AccountType ? <div className='error'>{errors.AccountType}</div> : null}
                                                            </FormControl>
                                                        </Card>
                                                    </Grid>
                                                    <Grid container item lg={6} md={6} sm={12} xs={12} className='buttonmargin'>
                                                        <Card variant="outlined">
                                                            <FormControl>
                                                                <FormLabel sx={{ color: user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_type_new != bank_details.bank_details_history.account_type_old ? "#ed6c02" : 'black' : 'black' : 'black' }}>And Account is*</FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="AccountCategory"
                                                                    id='AccountCategory
                                                                    '
                                                                    disabled={user.roles_id > 3 ? true : false}
                                                                    onChange={(e)=>{
                                                                        handleChange(e)
                                                                        if(e.target.value == bank_details.account_category){
                                                                            updateFieldCount = updateFieldCount - 1; 
                                                                            if(updateFieldCount == 0){
                                                                                setFieldValue('isFormDirty',true)
                                                                            }                                                                
                                                                        }else{                                          
                                                                            setFieldValue('isFormDirty',false) 
                                                                            if(accountCatCount == 0){
                                                                                accountCatCount = accountCatCount + 1;
                                                                                updateFieldCount = updateFieldCount + 1;
                                                                            }
                                                                        }
                                                                    }}
                                                                    value={values.AccountCategory}
                                                                    onBlur={handleBlur('AccountCategory')}
                                                                    color={user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_category_new != bank_details.bank_details_history.account_category_old ? "warning" : '' : '' : ''}
                                                                    focused={user.roles_id < 3 ? bank_details.bank_details_history?bank_details.bank_details_history.account_category_new != bank_details.bank_details_history.account_category_old ? "warning" : '' : '' : ''} 
                                                                >
                                                                    <FormControlLabel value="personal" control={<Radio />} label="Personal" sx={{ fontWeight: "bold" }} />
                                                                    <FormControlLabel value="business" control={<Radio />} label="Business" sx={{ fontWeight: "bold" }} />
                                                                </RadioGroup>
                                                                {touched.AccountCategory && errors.AccountCategory ? <div className='error'>{errors.AccountCategory}</div> : null}
                                                            </FormControl>
                                                        </Card>
                                                    </Grid>
                                                    </Grid>
                                            </Grid>
                                        </Grid>

                                        <Divider sx={{mt:2}}/>
                                        
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                                            <Grid item>
                                                {user.roles_id<=3?
                                                <Button variant="contained" sx={{ minWidth: 200, maxWidth: "100%" }} color="success" disabled={values.isFormDirty} onClick={handleSubmit}>
                                                    {btntext}
                                                </Button>
                                    :null   }
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="primary" sx={{ minWidth: 200, maxWidth: "100%" }} onClick={() => {
                                                    paymentsNavigation ? navigate('/commission-payments')
                                                    :
                                                    navigate('/companypanel')
                                                }}
                                                >
                                                    CLOSE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>

                        )}

                    </Formik>
                </Box>



                



            </>
        );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default AddBankDetailsForm;