import React, { useState, useEffect } from 'react';
import { useMediaQuery, Box, Grid, Card, CardContent } from '@mui/material';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Typography, Button } from '@mui/joy';
import RegisterFooter from '../../views/Auth/RegisterFooter';
import thankyouImg from "../../assets/images/thankyou.png"
import Loader from '../Loader/Loader';

function AffiAgreementThankyou(props) {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const location = useLocation();
    const { pdfBlob, pdfBase64 } = location.state || {};
    const downloadPDF = () => {
        if (pdfBlob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(pdfBlob);
            link.download = 'Affiliate Agreement.pdf';
            link.click();
        }
        // Alternatively, if you're passing a base64 string
        else if (pdfBase64) {
            const link = document.createElement('a');
            link.href = pdfBase64;
            link.download = 'Affiliate Agreement.pdf';
            link.click();
        }
    };

    useEffect(() => {
        if (thankyouImg) {
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }

    }, [])
    return (
        <>
            {isLoading && <Loader />}
            <Box id="primaryColor" sx={{ flexGrow: 1, boxShadow: 0, justifyContent: "center", alignItems: "center", borderBottomWidth: '3px', borderBottomStyle: 'solid', borderBottomColor: "#30BE2D" }}>
                <Grid container sx={{ padding: "0 2%", margin: "0" }} mb={2}>
                    <Grid container direction="row" justifyContent={isMobile ? "center" : "space-between"} alignItems="center" spacing={4}>
                        <Grid item>
                            <Link to='/'>
                                <img alt="#" src={require('../../assets/images/mainLogo.jpg')} />
                            </Link>
                        </Grid>
                        {/* <Grid item sx={{ display: 'flex' }} columnGap={2}>
                            <Grid>
                                <Stack id="userwayBtn" tabIndex="0" direction="row" className="userwayLogin">
                                    <LanguageIcon />
                                    <Typography>en/esp/fr/...</Typography>
                                </Stack>
                            </Grid>
                            <Grid>
                                <>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                        sx={{
                                            border: '1px solid gray',
                                            padding: '0.5% 12px', color: 'black', fontWeight: 'bold', textTransform: 'capitalize'
                                        }}
                                    >
                                        Actions
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleClose}><DownloadIcon />&nbsp;&nbsp;Download</MenuItem>
                                        <MenuItem onClick={handleClose}><ContentPasteOffIcon />&nbsp;&nbsp;Decline</MenuItem>
                                    </Menu>
                                </>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Box>
            <Box className="back" sx={{ padding: "2%" }}>
                <Card sx={{ padding: " 0% 0% 4% 4% " }}>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4}>
                            {/* <Grid item lg={12} md={1} sm={12} xs={12} >    */}
                            <Grid item lg={6} md={1} sm={6} xs={6}>
                                <img src={thankyouImg} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className="images" />
                            </Grid>
                            <Grid item lg={6} md={1} sm={6} xs={6} sx={{ alignSelf: 'center' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    The Document Has Been Completed By All Participants. By Clicking On "Download The Document" You Can Download It.
                                </Typography><br />
                                <Button onClick={downloadPDF}>DOWNLOAD THE DOCUMENT</Button>
                            </Grid>
                            {/* </Grid> */}

                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <RegisterFooter />
        </>
    );
}
export default AffiAgreementThankyou;