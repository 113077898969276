import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { fetchData, postData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import config from '../../../AppConfig';
import axios from "axios";
function CreditScore() {
    const dispatch = useDispatch();
    const { name } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    // const lastPage = 3;
    const perPage = 5; // Number of items per page
    // const [title, setTitle] = useState("");
    const [blogs, setBlogs] = useState([]);
    console.log(blogs);
      const fetchBlogData = async page => {
        const apiUrl = '/blog/category/' + name + '?page=' + page;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            console.log(res);
            setBlogs(res.posts.data);
            setLastPage(res.posts.last_page);
            
        }
    }
    useEffect(() => {
        fetchBlogData(currentPage);
    }, [name, currentPage])
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
      };
    return (
        <>
            <LoginHeader />
            <Box>
           
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={9} md={9} sm={12} xs={12} sx={{ padding: "0% 5% 0%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h3" color='primary'>Latest {name} Articles</Typography>
                        </Grid>
                        {blogs.map((blog) => (
                            <div key={blog.id}>
                        <Divider />
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                           <a href={"/blog/"+ blog.url} style={{textDecoration:"none"}}> <Typography level="h4" color='primary' >{blog.title}</Typography></a>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="title-md">Posted on {new Date(blog.created_at).toLocaleString()}</Typography>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12} > */}
                       
                            <p dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                        
                        {/* </Grid> */}
                        </div>
                         ))}
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mb={3} mt={1}>
                            <Pagination count={lastPage} // Replace this with the actual total number of pages received from the API
                                page={currentPage}
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange} />
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <img src={require("../../../assets/images/books2.png")} className="book" />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h4">Learning Center</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <ul style={{ listStyleType: "none", textAlign: "left", marginLeft: "-30px" }}>
                                <li><a href='/credit-info' className='blog'>ABC's of Credit</a></li>
                                <li><a href='/credit-bureaus' className='blog'>Bureau Basics</a></li>
                                <li><a href='/credit-report' className='blog'>Credit Reports</a>
                                    <ul style={{ listStyleType: "none" }}><li><a href='/Blogs/Category/Credit Reports' className='blog'>Articles & News</a></li></ul>
                                </li>
                                <li><a href='/credit-scores' className='blog'>Credit Scores</a>
                                    <ul style={{ listStyleType: "none" }}><li><a href='/Blogs/Category/Credit Scores' className='blog'>Articles & News</a></li></ul>
                                </li>
                                <li><a href='/credit-monitoring' className='blog'>Credit Monitoring</a>
                                    <ul style={{ listStyleType: "none" }}><li><a href='/Blogs/Category/Credit Monitoring' className='blog'>Articles & News</a></li></ul>
                                </li>
                                <li><a href='/identity-theft' className='blog'>Identity Theft</a>
                                    <ul style={{ listStyleType: "none" }}><li><a href='/Blogs/Category/Identity Theft' className='blog'>Articles & News</a></li></ul>
                                </li>
                                <li><a href='/credit-glossary' className='blog'>Credit Glossary</a></li>
                            </ul>

                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank'>
                                <Button color="error"variant="contained" sx={{ backgroundColor: "#ED7E2E", color: "white" }}>
                                    See Your 3<br /> Scores Now
                                </Button>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Box>
            <RegisterFooter />
        </>
    );
}

export default CreditScore;