import React from 'react';
import Typography from '@mui/joy/Typography';
import {  Grid } from '@mui/material';
import Button from '@mui/material/Button';
import config from '../../AppConfig';
function Sidebar(props) {
    return (
        <>
         <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                            <img src={require("../../assets/images/books2.png")} />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                            <Typography level="h4">Learning Center</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                            <ul style={{ listStyleType: "none" }}>
                                <li><a href='/credit-info' className='blog'>ABC's of Credit</a></li>
                                <li><a href='/credit-bureaus' className='blog'>Bureau Basics</a></li>
                                <li><a href='/credit-report' className='blog'>Credit Reports</a>
                                    <ul style={{ listStyleType: "none" }}><li><a href='/Blogs/Category/Credit Reports' className='blog'>Articles & News</a></li></ul>
                                </li>
                                <li><a href='/credit-scores' className='blog'>Credit Scores</a>
                                    <ul style={{ listStyleType: "none" }}><li><a href='/Blogs/Category/Credit Scores' className='blog'>Articles & News</a></li></ul>
                                </li>
                                <li><a href='/credit-monitoring' className='blog'>Credit Monitoring</a>
                                    <ul style={{ listStyleType: "none" }}><li><a href='/Blogs/Category/Credit Monitoring' className='blog'>Articles & News</a></li></ul>
                                </li>
                                <li><a href='/identity-theft' className='blog'>Identity Theft</a>
                                    <ul style={{ listStyleType: "none" }}><li><a href='/Blogs/Category/Identity Theft' className='blog'>Articles & News</a></li></ul>
                                </li>
                                <li><a href='/credit-glossary' className='blog'>Credit Glossary</a></li>
                            </ul>

                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' >
                                <Button color="error" variant="contained" sx={{ backgroundColor: "#ED7E2E", color: "white" }}>
                                    See Your 3<br /> Scores Now
                                </Button>
                            </a>
                        </Grid>
        </>
            );
        
    }
    
    
    export default Sidebar;