
import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import DefaultLayout from '../../../Components/DefaultLayout';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Box, Grid, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Dialog, DialogActions, DialogContent, TextField, Stack, TableContainer, Paper,
  TableHead, TableRow, TableCell, TableBody, FormControlLabel, FormLabel, Checkbox, FormGroup, FormHelperText, Tooltip
} from '@mui/material';

import { Typography, Card, CardContent, CardOverflow, Table } from '@mui/joy';


import AuthUser from "../../../Components/Auth/AuthUser";
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MaterialReactTable from 'material-react-table';
import dayjs from 'dayjs';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Loader from '../../../Components/Loader/Loader';
import Moment from 'moment';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CheckIcon from '@mui/icons-material/CheckBoxOutlined';
import config from '../../../AppConfig';
import swal from "sweetalert";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {currencyFormat} from '../../../Components/CommonFormula';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


let RequestFormInitialValues = {
  paymentDetail: '',
  totalCapturePayment: 0,
  additionalPayment: 0,
  paymentDate: dayjs(),
};

let editPaymentValues = {
  id: '',
  amount: '',
  paymentDetails: '',
  paymentDate: '',
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const captureSchema = Yup.object().shape({
  // totalCapturePayment: Yup.number()
  // .moreThan(0, 'additional commision should not be less than zero'),

  // paymentDetail: Yup.string()
  //   .required('Please enter payment details'),

  paymentDate: Yup.string()
    .required('Please enter Commission'),

  additionalPayment: Yup.number()
  .test('is-decimal', 'Must have up to 2 decimal places', value => {
    if (value === undefined || value === null) return true;
      return /^-?\d+(\.\d{1,2})?$/.test(value.toString());
  }),
    // .moreThan(-1, 'additional commision should not be less than zero'),

})

const editPaymentSchema = Yup.object().shape({
  paymentDetails: Yup.string()
    .required('Please enter payment details'),

  paymentDate: Yup.string()
    .required('Please enter payment date'),
})

function getStyles(name, personName, theme) {

}

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];

// let totalCapturePayment = 0;

// let captureMonthYear = [];


function SoftwarePaymentDetails(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const { state } = useLocation();
  const { id } = useParams();
  const {  user } = AuthUser();
  const [isLoading, setIsLoading] = useState(true);
  const [totalPaid, setTotalPaid] = useState();
  const [achAddedToBank, setAchAddedToBank] = useState();
  const [bankDetails, setBankDetails] = useState();
  const [payments, setPayments] = useState([]);
  const [paymentDetail, setPaymentDetails] = useState();
  const [softwareId, setSoftwareId] = useState();
  const [softwareName, setSoftwareName] = useState();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page');
  const pageSize = queryParams.get('pageSize');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [logPopupMaxWidth, setLogPopupMaxWidth] = React.useState('m');
  const navigate = useNavigate();
 
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );

    setLogPopupMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleExportData = (payments) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id >= 3 ) {
      swal({
          title: "Failed",
          text: "This action is unauthorized.",
          icon: "error",
          button: "Ok",
      })
      navigate('/login');
      return false;
    }
    const exportedData = payments.map((row) => {

      return {
        month: `${row.original.month}`,
        year: `${row.original.year}`,
        referral_commission: `${row.original.referral_commission}`,
        bonus: `${row.original.bonus}`,
        total_commission: `${row.original.total_commission}`,
        payment_date: `${row.original.payment_date ? Moment(row.original.payment_date).format('MM-DD-YYYY') : 'N/A'}`,
        captured_date: `${Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}`,
        captured_by: `${row.original.captured_by ? row.original.captured_by.name : 'N/A'}`,
      };
    });

    const convertToCSV = (data) => {
      const headers = csvOptions.headers.join(',') + '\n';
  
      const rows = data.map((row) => {
        return Object.values(row).map((value) => {
          // Handle any necessary formatting or escaping of values
          // For simplicity, we assume all values are already properly formatted
      
          // Wrap the value with double quotes if it contains a comma
          if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
          }
      
          return value;
        }).join(',');
        }).join('\n');
  
      return headers + rows;
    };

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Software Payment Details',
      headers: ['Month', 'Year', 'Referral Commission', 'Bonus', 'Total Commission', 'Payment Date', 'Captured Date', 'Captured By'],
    };

    const csvContent = convertToCSV(exportedData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const fetchPaymentViewDetails = async page => {
    const apiUrl = '/software-payment-details';
    const args = {
      softwareId: id,
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === true) {
        setTotalPaid(res.totalPaid);
        setPayments(res.payments ? res.payments : '');
        setSoftwareName(res.softwareName);
        setBankDetails(res.statusArray.bankStatus);
        setAchAddedToBank(res.statusArray.achAddedToBank);
        setSoftwareId(res.softwareId);
       
        setIsLoading(false);

      } else {
        setIsLoading(false);
      }
    }
  }

  
  useEffect(() => {
    if(user.roles_id < 3) {
      fetchPaymentViewDetails();
    }else{
      navigate('/login');
    }
  }, []);


  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.month}`,
        id: 'month',
        header: 'Month',
        size: 50,

      },
      {
        accessorFn: (data) => `${data.year}`,  //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        id: 'year',
        header: 'Year',
        size: 100,
      },
      {
        accessorFn: (data) => parseFloat(`${data.referral_commission}`).toFixed(2),
        filterVariant: 'range',
        id: 'referralCommission',
        header: 'Referral Commission ($)',
        size: 200,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.referral_commission))),
      },
      {
        accessorFn: (data) => parseFloat(`${data.bonus}`).toFixed(2),
        filterVariant: 'range',
        id: 'bonus',
        header: 'Referral Bonus ($)',
        size: 200,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.bonus))),
      },
      {
        accessorFn: (data) => parseFloat(`${data.total_commission}`).toFixed(2),
        filterVariant: 'range',
        id: 'totalCommission',
        header: 'Total Commission ($)',
        size: 200,
        Cell:({renderedCellValue,row}) =>(currencyFormat(parseFloat(row.original.total_commission))),
      },
      {
        accessorFn: (data) => `${data.paymentDate}`,
        filterVariant: 'range',
        id: 'payment_date',
        header: 'Payment Date',
        size: 100,
        Cell:({renderedCellValue,row}) => (
          <div >
          {Moment(row.original.payment_date).format('MM-DD-YYYY')}
          </div>          
          ),
        //custom conditional format and styling
      },
      {
        accessorFn: (data) => `${data.created_at}`,
        id: 'captured_date',
        header: 'Captured Date',
        size: 50,
        Cell:({renderedCellValue,row}) => (
          <div >
          {Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}
          </div>          
          ),
      },
      {
        accessorFn: (data) => `${data.capturedBy}`,
        filterVariant: 'range',
        id: 'captured_by',
        header: 'Captured By',
        size: 100,
        Cell:({renderedCellValue,row}) => (
          <div >
          {row.original.captured_by ? row.original.captured_by.name : 'N/A'}
          </div>          
          ),
        //custom conditional format and styling
      },
    ]
  )


  const ContentLayout=
      <>
        {isLoading ? <Loader /> : <></>}
        <Box>
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
            <Typography level="h3" color="primary">Payment Details ( Software : {softwareName ? softwareName : ''} )</Typography>
            <CancelOutlinedIcon onClick={() => navigate('/software-commission-payments')} />
          </Grid>

          <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
            <Table>
              <tr>
                <td className="borderStyle" rowSpan={2}><b>Summary</b></td>
                <td className="borderStyle">Total Paid</td>
                <td className="borderStyle">ACH Info Added to Bank:</td>
                <td className="borderStyle">Banking Details</td>
              </tr>
              <tr>
                <td className="borderStyle"><b>$ {parseFloat(totalPaid).toFixed(2)}</b></td>
                <td className="borderStyle"><b>{achAddedToBank}</b></td>
                <td className="borderStyle"><b>{bankDetails}</b></td>
              </tr>
            </Table>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>

            <Typography level="h3" mb={2} align="center">
               Payment Details
            </Typography >
            <MaterialReactTable
              columns={columns}
              data={payments ? payments : []}
              filterFns={{
                customSearchFilterFn: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }}   
            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
              enableColumnFilterModes
              enableGrouping={false}
              enablePinning={false}
              initialState={{ showColumnFilters: false, density: 'compact' }}
              enableColumnFilters={config.DatatableColumnFilter}
              positionToolbarAlertBanner="bottom"
              enableDensityToggle={false}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  backgroundColor: "#F6FAFF",
                },
              }}
              renderDetailPanel={({ row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>Referral Commission ($)</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Referral Bonus ($)</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Total Commission ($)</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Payment Date</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Captured By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          <TableRow
                            key={row.original.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>{currencyFormat(parseFloat(row.original.referral_commission))}</TableCell>
                            <TableCell>{currencyFormat(parseFloat(row.original.bonus))}</TableCell>
                            <TableCell>{currencyFormat(parseFloat(row.original.total_commission))}</TableCell>
                            <TableCell>{Moment(row.original.payment_date).format('MM-DD-YYYY')}</TableCell>
                            <TableCell>{row.original.captured_by ? row.original.captured_by.name : 'N/A'}</TableCell>
                          </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              top csv option
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
              <Button
              color="primary"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() =>
                  handleExportData(table.getPrePaginationRowModel().rows)
                }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              CSV
            </Button>
                </Box>
              )
              }
            />


          </Grid>
        </Box>
      </>
 

  return (
    <div>
      <DefaultLayout content={ContentLayout } />
    </div>
  );
}

export default SoftwarePaymentDetails;