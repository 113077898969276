import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';

function HomeTab() {
	const [value, setValue] = React.useState('one');

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

  return (
    <>
		<Tabs
			value={value}
			onChange={handleChange}
			textColor="inherit"
			indicatorColor='primary'
			aria-label="secondary tabs example"
			variant="scrollable"
			scrollButtons
			allowScrollButtonsMobile
		>
			
			<Tab sx={{visibility: 'hidden', width: 0, padding: 0, minWidth: 0}} component={Link} to="#" value="one" label="Home" />
			<Tab sx={{color:'black',fontWeight:'bold'}} component={Link} to="/login" value="two" label="Affiliate Login" />
				<Tab sx={{color:'black',fontWeight:'bold'}} component={Link} to="https://member.myfreescorenow.com/login/" value="three" label="Member login" />
			
				<Tab sx={{color:'black',fontWeight:'bold'}} component={Link} to="/contact" value="four" label="Contact" />
		</Tabs>
	</>
  );
}
export default HomeTab;