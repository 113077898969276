import React from 'react';
import { Chart } from "react-google-charts";
import { Typography } from '@mui/joy';
import {Grid } from '@mui/material';

const CallDurationAnalysis = ({ callLogs }) => {
  const durations = callLogs.map(log => {
    const [hours, minutes] = log.callDuration.split(':').map(Number);
    return hours * 60 + minutes;
  });

  const histogramData = [['Duration (minutes)', 'Frequency']];

  for (let i = 0; i <= 100; i += 10) {
    const count = durations.filter(duration => duration >= i && duration < i + 10).length;
    histogramData.push([`${i}-${i+9}`, count]);
  }

  return (
    <div>
      <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography level="h4" color="primary">Call Duration</Typography>
        </Grid>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="300px"
        data={histogramData}
        options={{
          title: 'Call Duration ',
          hAxis: {
            title: 'Duration (minutes)',
          },
          vAxis: {
            title: 'Frequency',
          },
          legend: { position: 'none' },
          chartArea: {
            width: '80%',
          },
        }}
      />
    </div>
  );
};

export default CallDurationAnalysis;
