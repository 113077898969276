import React, { useState,useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Typography, Card, CardContent, CardOverflow, } from '@mui/joy';
import { Button,TextField, Grid, RadioGroup, FormControlLabel, FormControl, FormLabel, Radio, Alert, AlertTitle} from '@mui/material';
import { Formik  } from 'formik';
import {useParams,useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import AuthUser from '../../../Components/Auth/AuthUser';
import Disposition from '../../../Components/ContactTypeConfigure/Disposition';
import Loader from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';

let des= [];
let dispositionList =[];
function ContactEdit(props) {
    const navigate = useNavigate();
    const [fieldSets, setFieldSets] = useState([{ disposition: "", reasonData: [{ reason: "", savable: false, aid: false, mid: false }] }]);
    const dispatch = useDispatch();
    const {http,user} = AuthUser();
    const { id } = useParams();
    const [contactlist, setContactList] = useState([]);
    const [disable,setDisable]= useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    const fetchCallTypeList = async () =>{
        const apiUrl = "/editCallType/" + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            setContactList(res.contactType);
            dispositionList = res.disposition
            setDispositionArray(res.disposition);    
            setIsLoading(false);            
        }
    }


    const handleData = (data) => {
        des = data;
    }

    const setDispositionArray = (array) =>{
        console.log(array)
        const updatedFieldSets = array.map((item,index) => (
            { 
                dispositionId : item.id,
                disposition: item.disposition, 
                reasonData: item.call_type_reason.map((subitem,index) => (
                    { 
                        reasonId: subitem.id,
                        reason: subitem.reason, 
                        savable: subitem.savable, 
                        aid: subitem.required_field == "both" || subitem.required_field == "aid" ? true : false, 
                        mid: subitem.required_field == "both" || subitem.required_field == "mid" ? true : false,  
                        subField5: subitem.required_field 
                    }
                )) 
            }
        ));
        setFieldSets(updatedFieldSets);
    }

        //set Disposition array function and variable start
    useEffect(()=>{
        if(user.roles_id < 2) {
            fetchCallTypeList();       
        }else{
            navigate('/login');
        }
        
    }, []) 

    const RequestFormSchema = Yup.object().shape({
        callType:Yup.string().required('Please enter Contact Type'),
    })
    
    const submitRequest =async (values, { resetForm }) =>{
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 2 ) {
            navigate('/login');
            return false;
        }
        let errorFlag = 0;
        let errorMsg = '';
        setDisable(true);
        setIsLoading(true);

        des.map((row)=>{
            console.log(row);
            if(errorFlag == 0){
                if(row.disposition == '' ){
                    errorFlag = 1;
                    errorMsg = "Please fill disposition first"
                }
                row.reasonData.map((res)=>{
                    if(res.reason == '' && errorFlag == 0){
                        errorFlag = 1;
                        errorMsg = "Please fill reason first"
                    }
                })
            }
        })

        if(errorFlag == 1){
            alert(errorMsg);
            setDisable(false);
            setIsLoading(false);
        }else{
            const apiUrl = '/contactTypeConfig/update/'+id;
            const args = {
                userType:values.userType,
                callType:values.callType,
                desposition:des,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if(res)
            {
                if(res.success === false){   
                    setDisable(false);            
                    setErrorMessage(res.message);
                    setIsLoading(false);
                    setTimeout(function () {
                        setErrorMessage('')
                    }, 3000);
                }else{
                    setDisable(false); 
                    fetchCallTypeList();
                    setSuccessMessage(res.message);
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 3000);
                }                
            }else {
                setDisable(false); 
                setErrorMessage("Something went wrong.")
                setIsLoading(false)
                setTimeout(function () {
                    setErrorMessage('')
                }, 3000);
            }
        }
    }

    function ContentLayout() {
        return (
            <>
                {successMessage?<Alert severity="success"><AlertTitle>Success</AlertTitle>{successMessage}</Alert>:null}
                {errorMessage?<Alert severity="error"><AlertTitle>Error</AlertTitle>{errorMessage}</Alert>:null}
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Formik
                        initialValues={{
                            callType:contactlist.call_type,
                            userType:contactlist.contact_type,
                            
                        }}
                        validationSchema={RequestFormSchema}
                        onSubmit={submitRequest}
                        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit,setFieldValue })=> (<>
                        <Card sx={{ width: "100%" }} >
                            <CardOverflow sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '1%',
                            }}>
                                <Typography level="h4" color='primary'>Editing Contact Type Configuration</Typography>
                            </CardOverflow>
                            <CardContent sx={{ padding: "1%" }}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label"><Typography level="h6">Configuration For</Typography></FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="userType"
                                            id="userType"
                                            value={values.userType}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="affiliate" control={<Radio />} label="Affiliate" />
                                            <FormControlLabel value="member" control={<Radio />} label="Member" />
                                            <FormControlLabel value="both" control={<Radio />} label="Both" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mb={1}>
                                    <FormControl fullWidth size="small" margin='dense'>
                                    <TextField
                                        label="Contact Reason"
                                        variant="outlined"
                                        margin='dense'
                                        size="small"
                                        id="callType"
                                        name= "callType"
                                        value={values.callType}
                                        onChange={handleChange}
                                        helperText={touched.callType && errors.callType ? errors.callType : null}
                                        error={touched.callType && errors.callType ? errors.callType : null}
                                        
                                 />
                                    </FormControl>
                                </Grid>
                                <Disposition fields={fieldSets} onChangeData={handleData} />
        
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }} mt={2}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Button variant="contained" sx={{ minWidth: 250 }} onClick={()=>{navigate(-1)}}> 
                                            CLOSE
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} className='buttonmargin'>
                                        <Button variant="contained" sx={{ minWidth: 250 }} color="success" type="submit" onClick={handleSubmit} disabled={disable}  >
                                            SAVE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </>)}</Formik>    
                </Grid>
            </>
        );
    }
    return (
        <div>
            {isLoading?<Loader/>:<></>} 
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default ContactEdit;