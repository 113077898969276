import React, { useState, useEffect } from "react";
import Drawer from '@mui/material/Drawer';
import { Button, Box, ListItemIcon, InputAdornment, TextField, Collapse, List, ListItem, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

const drawerWidth = 300;
function Sidebar() {
    const [searchTerm, setSearchTerm] = useState("");
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const [expanded, setExpanded] = React.useState([]);
    // const [selected, setSelected] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    // const handleSelect = (event, nodeIds) => {
    //     setSelected(nodeIds);
    // };

    const handleExpandClick = () => {
        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? ['1', '2', '6', '10', '11', '12', '15', '18', '19', '21', '23', '27', '28', '30', '32'] : [],
        );
    };

    // const handleSelectClick = () => {
    //     setSelected((oldSelected) =>
    //         oldSelected.length === 0 ? ['1', '2', '3', '4', '5', '6', '7', '8', '9'] : [],
    //     );
    // };

    return (
        <>
            <div id="app" style={({ height: "100vh" }, { display: "flex" })}>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            padding: "2%",
                        },
                    }}
                    variant="permanent"
                    anchor="left">
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={{ mt: 1 }}>
                        <Button>
                            GO To Top
                        </Button>
                    </Box>
                    <Box sx={{ mb: 1 }}>

                        <Button onClick={handleExpandClick}>
                            {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
                        </Button>
                        {/* <Button onClick={handleSelectClick}>
                        {selected.length === 0 ? 'Select all' : 'Unselect all'}
                    </Button> */}
                    </Box>
                    <TreeView
                        aria-label="controlled"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={expanded}
                        // selected={selected}
                        onNodeToggle={handleToggle}
                        // onNodeSelect={handleSelect}
                        multiSelect>
                        <TreeItem label="Personal Information">
                      
                        </TreeItem>
                        <TreeItem label="Report Summary">
                        </TreeItem>
                        <TreeItem label="Account Summary">
                        </TreeItem>
                        <TreeItem nodeId="1" label="Positive Accounts(16)">
                            <TreeItem nodeId="2" label="open" >
                                <TreeItem nodeId="3" label="Automative" color='secondary'/>
                                <TreeItem nodeId="4" label="Educational" />
                                <TreeItem nodeId="5" label="Others" />
                            </TreeItem>
                            <TreeItem nodeId="6" label="Closed" >
                                <TreeItem nodeId="7" label="Mortgage" />
                                <TreeItem nodeId="8" label="educational" />
                                <TreeItem nodeId="9" label="Others" />
                            </TreeItem>
                        </TreeItem>
                        <TreeItem nodeId="10" label="Negative Accounts(23)">
                            <TreeItem nodeId="11" label="open(7)" >
                                <TreeItem nodeId="12" label="Educational (3)">
                                    <TreeItem nodeId="13" label="Derogatory(1)" />
                                    <TreeItem nodeId="14" label="Delinquent(2)" />
                                </TreeItem>
                                <TreeItem nodeId="15" label="Others (4)">
                                    <TreeItem nodeId="16" label="coll/chargeOff(3)" />
                                    <TreeItem nodeId="17" label="Delinquent(1)" />
                                </TreeItem>
                            </TreeItem>
                            <TreeItem nodeId="18" label="Closed(13)">
                                <TreeItem nodeId="19" label="Mortgage">
                                    <TreeItem nodeId="20" label="Coll/ChargeOff (1)" />
                                </TreeItem>
                                <TreeItem nodeId="21" label="Automotive">
                                    <TreeItem nodeId="22" label="Coll/ChargeOff (1)"></TreeItem>
                                </TreeItem>
                                <TreeItem nodeId="23" label="Others (10)">
                                    <TreeItem nodeId="24" label="Coll/ChargeOff (8)" />
                                    <TreeItem nodeId="25" label="Derogatory(1)" />
                                    <TreeItem nodeId="26" label="Delinquent(1)" />
                                </TreeItem>
                            </TreeItem>
                            <TreeItem nodeId="27" label="Mixed(3)">
                                <TreeItem nodeId="28" label="Medical(2)">
                                    <TreeItem nodeId="29" label="Coll/ChargeOff (8)" />
                                </TreeItem>
                                <TreeItem nodeId="30" label="Others(1)">
                                    <TreeItem nodeId="31" label="Delinquent(1)" />
                                </TreeItem>
                            </TreeItem>
                        </TreeItem>
                        <TreeItem label="Public Records">
                        </TreeItem>
                        <TreeItem nodeId="32" label="Inquiries(18)">
                            <TreeItem nodeId="33" label="Bad(17)" />
                            <TreeItem nodeId="34" label="Good(1)" />
                        </TreeItem>
                        <TreeItem label="Creditor Contacts">
                        </TreeItem>
                    </TreeView>
                    <Box sx={{ mb: 1 }}>
                    <Button color="success" sx={{ backgroundColor: "#76C044", color: "white",minWidth:100,maxWidth:350 }}>
                        Show Negatives Only
                    </Button>
                    </Box>
                    <Box sx={{ mb: 1 }}>
                    <Button color="primary" sx={{ backgroundColor: "#2A68B3", color: "white",minWidth:100,maxWidth:350}}>
                        View Comments
                    </Button>
                    </Box>
                </Drawer>
                <main>
        
      </main>
            </div>
        </>
    );
}
export default Sidebar;