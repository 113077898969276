import React, { useState, useEffect, useRef } from "react";
import Loader from '../Loader/Loader';
import { useMediaQuery, Grid, Stack, Button, Avatar, Tooltip, Box, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';
import AffiAgreement1 from "./AffiAgreement1";
import AffiAgreement2 from "./AffiAgreement2";
import AffiAgreement3 from "./AffiAgreement3";
import AffiAgreement4 from "./AffiAgreement4";
import AffiAgreement5 from "./AffiAgreement5";
import AffiAgreement6 from "./AffiAgreement6";
import AffiAgreement7 from "./AffiAgreement7";
import AffiAgreement8 from "./AffiAgreement8";
import AffiAgreement9 from "./AffiAgreement9";
import AffiAgreement10 from "./AffiAgreement10";
import AffiAgreement11 from "./AffiAgreement11";
import AffiAgreement12 from "./AffiAgreement12";
import AffiAgreement13 from "./AffiAgreement13";
import AffiAgreement14 from "./AffiAgreement14";
import AffAgreementCertificate from "./AffAgreementCertificate";
import AuthUser from '../Auth/AuthUser';
import { useDispatch } from 'react-redux';
import { fetchData, postData, postFormData } from '../../redux/apiSlice';
import swal from "sweetalert";
import * as Yup from 'yup';
import Moment from 'moment';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { RefreshIndicator } from "material-ui";
import config from '../../AppConfig';
let previousButtonPosition = { top: null, left: null };
let ipAdd;
function AffiAgreementIndex() {
    const dispatch = useDispatch();
    const { http, user } = AuthUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [signature1, setSignature1] = React.useState("");
    const [signed, setSigned] = React.useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [affiliateDetails, setAffiliateDetails] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [disabledNext, setDisabledNext] = useState(false);
    const [sameBankName, setSameBankName] = useState(false);

    const [showButton, setShowButton] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
    const [formData, setFormData] = useState({
        BusinessName1: "",
        FirstName1: "",
        LastName1: "",
        FirstName3: "",
        LastName3: "",
        StreetAddress1: "",
        ZipCode1: "",
        State1: "",
        City1: "",
        StreetAddress2: "",
        ZipCode2: "",
        State2: "",
        City2: "",
        Email1: "",
        Phone1: "",
        date1: "",
        date2: "",
        // NameonAccount: "", BankRouting: "", AccountNumber: "", BankName: "",
        // AccountType: "",
        // AccountCategory: "",
        // Email4: "",
        Title: "",
        planCheckbox: false,
        signature1: "",
        signature2: "",
        referenceId: "",
        signedDate: "",
        secondSignerDate: "",
        sentDate: ""
        // signature3: "",
    });
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    const pandadocId = parts[2];
    const pandadocRole = atob(parts[3]);
    const navigate = useNavigate();
    const [name, setName] = useState("")
    const [errors, setErrors] = useState({});
    const [unfilledCount, setUnfilledCount] = useState(0);
    const [status, setStatus] = useState(0);
    const [initialTopValue, setInitialTopValue] = useState(null);
    const [ip, setIp] = useState();
    const [refId, setRefId] = useState();
    const [staticPageImages, setStaticPageImages] = useState([]);

    useEffect(() => {
        setStatus(parts[4])
        fetchRecord();
    }, [])
    const fetchRecord = () => {
        setIsLoading(true)
        http.get('/get-affiliate-detail/' + pandadocId).then((res) => {
            setAffiliateDetails(res.data?.data);
            const myDate = new Date();
            var cDate = Moment(myDate).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm');
            setCurrentDate(cDate);
            setName(res.data.data.first_name + " " + res.data.data.last_name)
            setFormData({
                BusinessName1: res.data ? res.data.data ? res.data.data.company ? res.data.data.company.company_name ? res.data.data.company.company_name : "" : "" : "" : "",
                FirstName1: res.data ? res.data.data ? res.data.data.first_name ? res.data.data.first_name : "" : "" : "",
                LastName1: res.data ? res.data.data ? res.data.data.last_name ? res.data.data.last_name : "" : "" : "",
                FirstName3: res.data ? res.data.data ? res.data.data.first_name ? res.data.data.first_name : "" : "" : "",
                LastName3: res.data ? res.data.data ? res.data.data.last_name ? res.data.data.last_name : "" : "" : "",
                StreetAddress1: res.data ? res.data.data ? res.data.data.address ? res.data.data.address : "" : "" : "",
                ZipCode1: res.data ? res.data.data ? res.data.data.zip_code ? res.data.data.zip_code : "" : "" : "",
                State1: res.data ? res.data.data ? res.data.data.state_code ? res.data.data.state_code : "" : "" : "",
                City1: res.data ? res.data.data ? res.data.data.city ? res.data.data.city : "" : "" : "",
                StreetAddress2: res.data ? res.data.data ? res.data.data.device_details ? res.data.data.device_details.street_address ? res.data.data.device_details.street_address : "" : "" : "" : "",
                ZipCode2: res.data ? res.data.data ? res.data.data.device_details ? res.data.data.device_details.zip_code ? res.data.data.device_details.zip_code : "" : "" : "" : "",
                State2: res.data ? res.data.data ? res.data.data.device_details ? res.data.data.device_details.state ? res.data.data.device_details.state : "" : "" : "" : "",
                City2: res.data ? res.data.data ? res.data.data.device_details ? res.data.data.device_details.city ? res.data.data.device_details.city : "" : "" : "" : "",
                Email1: res.data ? res.data.data ? res.data.data.email ? res.data.data.email : "" : "" : "",
                Phone1: res.data ? res.data.data ? res.data.data.phone_no ? (!(res.data.data.phone_no.includes("(")) ? "(" + res.data.data.phone_no.substring(0, 4) + ") " + res.data.data.phone_no.substring(4, 7) + "-" + res.data.data.phone_no.substring(7) : res.data.data.phone_no) : "" : "" : "",
                Phone3: res.data ? res.data.data ? res.data.data.phone_no ? res.data.data.phone_no : "" : "" : "",
                date1: res.data ? res.data.data ? res.data.data.second_signer_date ? dayjs(res.data.data.second_signer_date) : dayjs(cDate) : dayjs(cDate) : dayjs(cDate),
                date2: res.data ? res.data.data ? res.data.data.first_signer_date ? dayjs(res.data.data.first_signer_date) : "" : "" : "",
                // NameonAccount: res.data ? res.data.data ? res.data.data.company_bank_detail?.name_on_account ? res.data.data.company_bank_detail.name_on_account : "" : "" : "",
                // BankRouting: res.data ? res.data.data ? res.data.data.company_bank_detail?.routing_number ? res.data.data.company_bank_detail.routing_number : "" : "" : "",
                // BankName: res.data ? res.data.data ? res.data.data.company_bank_detail?.bank_name ? res.data.data.company_bank_detail.bank_name : "" : "" : "",
                // AccountNumber: res.data ? res.data.data ? res.data.data.company_bank_detail?.account_number ? res.data.data.company_bank_detail.account_number : "" : "" : "",
                // AccountType: res.data ? res.data.data ? res.data.data.company_bank_detail?.account_type ? res.data.data.company_bank_detail.account_type : "" : "" : "",
                // AccountCategory: res.data ? res.data.data ? res.data.data.company_bank_detail?.account_category ? res.data.data.company_bank_detail.account_category : "" : "" : "",
                // Email4: res.data ? res.data.data ? res.data.data.email ? res.data.data.email : "" : "" : "",
                Title: res.data ? res.data.data ? res.data.data.company ? res.data.data.company.title ? res.data.data.company.title : "" : "" : "" : "",
                planCheckbox: res.data ? res.data.data ? res.data.data.pandadoc_terms ? true : false : false : false,
                signature1: res.data ? res.data.data ? res.data.data.sign_image ? res.data.data.sign_image : "" : "" : "",
                signature2: res.data ? res.data.data ? res.data.data.executive ? res.data.data.executive : "" : "" : "",
                referenceId: res.data ? res.data.data ? res.data.data.pandadoc_id ? res.data.data.pandadoc_id : "" : "" : "",
                signedDate: res.data ? res.data.data ? res.data.data.first_signer_date ? res.data.data.first_signer_date : "" : "" : "",
                secondSignerDate: res.data ? res.data.data ? res.data.data.second_signer_date ? res.data.data.second_signer_date : "" : "" : "",
                sentDate: res.data ? res.data.data ? res.data.data.created_at ? res.data.data.created_at : "" : "" : "",
            });
            if (res.data.data.pandadoc_terms == 1 && parts[4] != 2) {
                setStatus(1)
            }
            setSignature1(res.data ? res.data.data ? res.data.data.sign_image ? res.data.data.sign_image : "" : "" : "")
            setSigned(res.data ? res.data.data ? res.data.data.sign_image ? res.data.data.sign_image : "" : "" : "")
            setIsLoading(false)
        });
    };

    const validationSchema = Yup.object().shape({
        planCheckbox: Yup.boolean()
            .oneOf([true], 'You need to accept plans to proceed further'),

        BusinessName1: Yup.string()
            .trim()
            .required('Please enter your Business Name.')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),
        FirstName1: Yup.string()
            .required('Please enter your first Name.')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),

        LastName1: Yup.string()
            .required('Please enter your last Name.')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),

        FirstName3: Yup.string()
            .required('Please enter your first Name.')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),

        LastName3: Yup.string()
            .required('Please enter your last Name.')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),

        StreetAddress1: Yup.string()
            .required('Please enter your Street Address.')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),

        ZipCode1: Yup.string()
            .required('Please enter pin code.')
            .min(5, 'Must be in 5 digits.')
            .max(5, 'Wrong pin code.'),

        State1: Yup.string()
            .required('Please select your State.')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),
        City1: Yup.string()
            .required('Please enter your City.')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),



        StreetAddress2: Yup.string()
            .test('StreetAddress2', 'Too Short!', value => !value || value.length >= 2)
            .test('StreetAddress2', 'Too Long!', value => !value || value.length <= 50),

        ZipCode2: Yup.string()
            .test('ZipCode2', 'Must be 5 digits', value => !value || value.length === 5),

        State2: Yup.string()
            .test('State2', 'Too Short!', value => !value || value.length >= 2)
            .test('State2', 'Too Long!', value => !value || value.length <= 50),

        City2: Yup.string()
            .test('City2', 'Too Short!', value => !value || value.length >= 2)
            .test('City2', 'Too Long!', value => !value || value.length <= 50),


        Email1: Yup.string()
            .required('Please enter your email address.')
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format."),

        Phone1: Yup.string()
            .min(14, 'Must be 10 digits.')
            .max(14, 'Must be 10 digits.')
            .required('Please enter your mobile number.'),
        date2: Yup.string()
            .required('Please select Date'),
        signature1: Yup.string()
            .required('Please add signature'),

        // // AccountType: Yup.string()
        // //     .notRequired(),
        // // AccountCategory: Yup.string()
        // //     .notRequired(),
        // // BankRouting: Yup.string()
        // //     .test('BankRouting', 'At least 9 digits required', value => !value || value.length >= 9)
        // //     .test('BankRouting', 'Maximum 9 digits allowed', value => !value || value.length <= 9),
        // // BankName: Yup.string()
        // //     .test('BankName', 'Too Short!', value => !value || value.length >= 2)
        // //     .test('BankName', 'Too Long!', value => !value || value.length <= 100),

        // // NameonAccount: Yup.string()
        // //     .test('NameonAccount', 'Too Short!', value => !value || value.length >= 2)
        // //     .test('NameonAccount', 'Too Long!', value => !value || value.length <= 50)
        // //     .test('NameonAccount', 'NameonAccount name cannot be the same as Name on Account', function (value) {
        // //         const BankName = this.resolve(Yup.ref('BankName')); // Use Yup.ref() to reference the sibling field
        // //         if (value && BankName) {
        // //             return value !== BankName; // Return false if they are the same
        // //         }
        // //         return true; // Skip the check if one of the fields is empty
        // //     }),
        // // AccountNumber: Yup.string()
        // //     .notRequired(),

        // Email4: Yup.string()
        //     // .required('Please enter your email address to notify payment transfers.')
        //     .matches(/^(?:[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format."),
    });

    const handleFormChange = (name, value) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
        if (name == 'signature1') {
            handleBlur(name, value)
        }

        if (name == 'date2') {
            handleBlur(name, value)
        }
        // setFormData(prevData => {
        //     const newData = { ...prevData, [name]: value };

        //     // Check if 'BankName' and 'NameonAccount' are equal
        //     if (newData.BankName === newData.NameonAccount) {
        //         setSameBankName(true);
        //         handleBlur(name, value)
        //     } else {
        //         setSameBankName(false); // Optional: Reset state if they are not equal
        //         handleBlur(name, value)
        //     }

        //     return newData;
        // });
        // if (name == 'signature1') {
        //     handleBlur(name, value)
        // }

        // if (name == 'date2') {
        //     handleBlur(name, value)
        // }
    };

    useEffect(() => {
        // Pre-render static pages
        const renderStaticPages = async () => {
            setTimeout(async () => {
                const staticPageClasses = ['pdf-page5', 'pdf-page6', 'pdf-page7', 'pdf-page8', 'pdf-page9', 'pdf-page10', 'pdf-page11', 'pdf-page12', 'pdf-page13'];
                const staticPageElements = staticPageClasses.map(cls => document.querySelector(`.${cls}`));
                const targetWidth = 900; // Set a standard width for consistent canvas size
                const staticCanvases = await Promise.all(
                    staticPageElements.map(page => {
                        page.style.width = `${targetWidth}px`;
                        page.style.height = 'auto'; 
                        return html2canvas(page, { scale: 1.3, useCORS: true }).then(canvas => {
                            page.style.width = '';
                            page.style.height = '';
                            return canvas;
                        });
                    })
                );
                const staticImages = staticCanvases.map(canvas => canvas.toDataURL('image/jpeg', 0.8));
                setStaticPageImages(staticImages);
            }, 1000)
        };

        renderStaticPages();
    }, []);

    const downloadPDF = async () => {
        const apiUrl = `/get-pdf/${formData.BusinessName1}`;
        window.open(config.API_BASE_URL+apiUrl); 
        // const res = await dispatch(fetchData(apiUrl)).unwrap();
        // if (res) {
        //     if (res.success) {
        //         // window.open(config.BACKEND_URL + res.data.pdfUrl);
              
        //     } else {
        //         swal({
        //             title: "error",
        //             text: res.message,
        //             icon: "error",
        //             button: "Ok",
        //         })
        //     }
        // }
    };

    const findFirstEmptyField = async () => {
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            return null; // No errors, so no empty fields
        } catch (err) {
            if (err.inner && err.inner.length > 0) {
                return err.inner[0].path; // The first empty field
            }
        }
    };

    useEffect(() => {
        if (initialTopValue === null) { // Check if the value has already been set
            // const pdfPageContainer = document.getElementById('content-to-pdf');
            const gridElement = document.getElementById('content-to-pdf');
            if (gridElement) {
                const rect = gridElement.getBoundingClientRect();
                setInitialTopValue(rect.left - 40 + window.scrollX);
            }
        }
    }, [initialTopValue]);
    const handleStart = async () => {
        setButtonClicked(true);

        // Find the first invalid field
        const firstEmptyField = await findFirstEmptyField();

        if (firstEmptyField) {
            const element = document.querySelector(`[name="${firstEmptyField}"]`);
            if (element) {
                //  alert(`The first empty field is: ${firstEmptyField}`);
                setShowButton(true);
                // Scroll to the first invalid field and focus on it
                
                element.focus();
                
                const rect = element.getBoundingClientRect();

                const pdfPageContainer = document.getElementById('content-to-pdf');
                if (pdfPageContainer) {
                    const rect1 = pdfPageContainer.getBoundingClientRect();
                    const buttonWidth = 100;

                    let newTop = rect.top + window.scrollY;
                    let newLeft;


                    if (rect.top === 0 && previousButtonPosition.top !== null) {
                        // Adjust based on previous position if current top is 0
                        newTop = previousButtonPosition.top;
                        newLeft = initialTopValue;
                    }

                    setButtonPosition({
                        top: newTop,
                        left: initialTopValue,
                    });

                    // Update the previousButtonPosition with the new values
                    previousButtonPosition = { top: newTop, left: newLeft };
                }

            }

            // Set the errors for this field only
            try {
                await validationSchema.validateAt(firstEmptyField, formData);
            } catch (validationError) {
                setErrors({ [firstEmptyField]: validationError.message });
            }

        } else {
            // No empty fields, scroll to the Finish button
            const finishButton = document.querySelector('#finishButton');
            if (finishButton) {
                setShowButton(false)
                setDisabledNext(true)
                finishButton.scrollIntoView({ behavior: 'smooth' });
                finishButton.focus();
            }
        }
    };

    const handleFinish = async () => {
        try {
            // Validate form data
            await validationSchema.validate(formData, { abortEarly: false });
            setIsLoading(true);
            setErrors({});

            const date = new Date(formData.date2);
            var todaysTime = Moment().tz('America/Los_Angeles').format('hh:mm');
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            var newDate = year + "-" + month + "-" + day + " " + todaysTime;
            // Prepare API request
            const apiUrl = '/create-pandadoc';
            const args = {
                type: pandadocRole,
                business_name: formData.BusinessName1,
                first_name: formData.FirstName1,
                last_name: formData.LastName1,
                street_address: formData.StreetAddress1,
                zip_code: formData.ZipCode1,
                city: formData.City1,
                street_address1: formData.StreetAddress2,
                zip_code1: formData.ZipCode2,
                city1: formData.City2,
                state_code1: formData.State2,
                state_code: formData.State1,
                email: formData.Email1,
                phone_no: formData.Phone1,
                admin_sign_date: newDate,
                affiliate_sign_date: newDate,
                title: formData.Title,
                pandadoc_id: pandadocId,
                sign_image: formData.signature1,
                account_name: formData.NameonAccount,
                routing: formData.BankRouting,
                bankName: formData.BankName,
                account_number: formData.AccountNumber,
                account_type: formData.AccountType,
                account_category: formData.AccountCategory,
                checkbox: formData.planCheckbox ? 1 : 0
            };

            const res = await dispatch(postData({ apiUrl, args })).unwrap();

            if (res && res.success === true) {
                setIp(res.data.ip);
                setRefId(res.data.reference_number)
                setTimeout(async () => {
                    // Initialize jsPDF
                    const pdf = new jsPDF('p', 'px', 'a4');
                    const pages = document.querySelectorAll('.pdf-page');
                    const dynamicPages1 = document.querySelectorAll('.pdf-page1, .pdf-page2, .pdf-page3, .pdf-page4');
                    const cards = document.querySelectorAll('.css-19fvvp1-JoyCard-root');
                    const labels = document.querySelectorAll('.label-to-hide');
                    const certificatePage = document.querySelector('.pdf-page15');
                    const fieldStyle = document.querySelectorAll('.outerPandadoc-view input');

                    if (certificatePage) certificatePage.style.display = 'block';

                    // Remove borders and hide labels before generating the PDF
                    cards.forEach(card => card.classList.add('no-border'));
                    labels.forEach(label => label.style.display = 'none');
                    fieldStyle.forEach(field => field.style.position = 'relative')
                    fieldStyle.forEach(field => field.style.bottom = '3px')
                    // Convert pages to canvases and add to PDF
                    const targetWidth = 900; 
                    const width = pdf.internal.pageSize.getWidth();
                    const height = pdf.internal.pageSize.getHeight();
                    const dynamicCanvases1 = await Promise.all(
                        Array.from(dynamicPages1).map(page => {
                            page.style.width = `${targetWidth}px`;
                            page.style.height = 'auto'; 
                            return html2canvas(page, { scale: 1.3, useCORS: true }).then(canvas => {
                                page.style.width = '';
                                page.style.height = '';
                                return canvas;
                            });
                        })
                    );

                    dynamicCanvases1.forEach((canvas, index) => {
                        const imgData = canvas.toDataURL('image/jpeg', 0.8);
                        if (index > 0) {
                            pdf.addPage();
                        }
                        pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                    });

                    // Add static pages 5 to 12
                    staticPageImages.forEach(imgData => {
                        pdf.addPage();
                        pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                    });

                    // Capture dynamic pages 13 to 15
                    const dynamicPages2 = document.querySelectorAll('.pdf-page13, .pdf-page14, .pdf-page15');
                    
                    const dynamicCanvases2 = await Promise.all(
                        Array.from(dynamicPages2).map(page => {
                            page.style.width = `${targetWidth}px`;
                            page.style.height = 'auto'; 
                            return html2canvas(page, { scale: 1.3, useCORS: true }).then(canvas => {
                                page.style.width = '';
                                page.style.height = '';
                                return canvas;
                            });
                        })
                    );

                    dynamicCanvases2.forEach((canvas, index) => {
                        const imgData = canvas.toDataURL('image/jpeg', 0.8);
                        if (index > 0) {
                            pdf.addPage();
                        }
                        pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                    });

                    // Restore labels and reset certificate page visibility
                    labels.forEach(label => label.style.display = '');
                    if (certificatePage) certificatePage.style.display = 'none';
                    fieldStyle.forEach(field => field.style.position = '')
                    fieldStyle.forEach(field => field.style.bottom = '')
                    // Output PDF and navigate
                    const pdfBlob = pdf.output('blob');
                    const pdfBase64 = pdf.output('datauristring');
                    const argsData = new FormData();
                    argsData.append('pdf', pdfBlob, 'agreement.pdf');
                    argsData.append('filename', formData.BusinessName1);
                    const uploadRes = await dispatch(postFormData({
                        apiUrl: '/upload-pdf', // API endpoint
                        formData: argsData,    // FormData object
                    })).unwrap();
                    if (uploadRes && uploadRes.success) {
                        const pdfUrl = uploadRes.data.pdfUrl; // Get the URL of the uploaded PDF from the response
                        navigate('/thankyou', { state: { businessName: formData.BusinessName1, active: 3 } });
                    }
                }, 2000)
            } else {
                setIsLoading(false);
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                });
            }
        } catch (validationErrors) {
            if (validationErrors.inner) {
                const errorObject = validationErrors.inner.reduce((acc, currentError) => {
                    if (!acc[currentError.path] || currentError.message.includes('Please enter your Business Name.')) {
                        acc[currentError.path] = currentError.message;
                    }
                    return acc;
                }, {});
                setErrors(errorObject);
                handleStart();
            }
        }
    };

    const handleBlur = async (name, value) => {
        try {
            await Yup.reach(validationSchema, name).validate(value);
            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        } catch (error) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
        }
    };

    useEffect(() => {
        const calculateUnfilledCount = async () => {
            try {
                // Run validation and collect all errors
                await validationSchema.validate(formData, { abortEarly: false });
                setUnfilledCount(0);

                // setStatus(1)
            } catch (err) {
                // Check if the error contains validation details
                if (err.inner) {
                    // Count the number of unique fields with errors
                    const uniqueErrorFields = new Set(err.inner.map(error => error.path));
                    setUnfilledCount(uniqueErrorFields.size);

                }
            }
        };

        calculateUnfilledCount();
    }, [formData, errors]);

    return (
        <>
            {isLoading && <Loader />}
            <Box id="primaryColor" sx={{ flexGrow: 1, boxShadow: 0, justifyContent: "center", alignItems: "center" }} >
                <Grid container item lg={12} md={12} sm={12} xs={12} mb={2} className="pandadoc-header" >
                    <Grid container direction="row" justifyContent={isMobile ? "center" : "space-between"} alignItems="center" spacing={4}>
                        <Grid item className="pandadoc-header-wrapper" >
                            <Grid item >
                                <Link to='/'>
                                    <img
                                        alt="#"
                                        src={require('../../assets/images/mainLogo.jpg')}
                                        className="pandadoc-img"
                                    />
                                </Link>
                            </Grid>

                            <Grid item >
                                <Box sx={{ marginLeft: '-80px' }} className="header-title-text">
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '14px', lineHeight: '1.5' }}>
                                        MyFreeScoreNow Affiliate Program Document
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px', lineHeight: '1.5', color: 'gray' }}>
                                        by MyFreeScoreNow
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ display: 'flex' }} columnGap={2} className="header-rightDownload">
                            {status === 1 &&
                                <Grid className="download-btn">
                                    <Button variant="contained" sx={{ minWidth: "20%" }} size="sm" color="success" onClick={downloadPDF} >
                                        Download
                                    </Button>
                                </Grid>
                            }
                            <Grid sx={{ display: 'flex' }} columnGap={2}>
                                <Grid >
                                    <Stack direction="row" alignItems="center" className="header-profile"
                                        onContextMenu={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <Tooltip
                                            title={<div style={{ textAlign: 'center' }}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                                    {name} <span style={{ fontWeight: 'bold', color: 'red', paddingLeft: '2px' }}>{signed ? "Signed" : "Signer"} </span>
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontSize: '0.8rem' }}>
                                                    {formData.Email1}
                                                </Typography>
                                            </div>
                                            } componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        backgroundColor: 'white',
                                                        color: 'black',
                                                        boxShadow: 1,
                                                        border: '1px solid #dadde9',
                                                    },
                                                },
                                            }}>
                                            <Avatar className="header-profile-sub1">{name.split(' ').map(word => word[0]).join('')}</Avatar></Tooltip>
                                        <Tooltip title={
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                                    Bruce J Cornelius <span style={{ fontWeight: 'bold', color: 'red', paddingLeft: '2px' }}>Signed</span>
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontSize: '0.8rem' }}>
                                                    info@myfreescorenow.com
                                                </Typography>
                                            </div>
                                        } componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    boxShadow: 1,
                                                    border: '1px solid #dadde9',
                                                },
                                            },
                                        }}><Avatar className="header-profile-sub2">B</Avatar></Tooltip>
                                    </Stack>
                                </Grid>
                                <Grid sx={{ margin: 'auto' }}>
                                    <Stack id="userwayBtn" tabIndex="0" direction="row" className="userwayLogin">
                                        <LanguageIcon />
                                        <Typography>en/esp/fr/...</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                {status != 1 && status != 2 &&
                    <Grid item lg={12} md={12} sm={12} xs={12} className="pandadoc-subHeader" sx={{ height: '50px' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ width: '900px', margin: 'auto', justifyContent: unfilledCount ? 'space-between !important' : 'end', alignItems: 'center' }}>
                            {unfilledCount ? <Typography>Please fill in {unfilledCount} required fields.</Typography> : null}
                            {isMobile ? <Button variant="contained" className="padadoc-btn" onClick={handleStart} disabled={disabledNext ? true : false}>{!buttonClicked ? 'START' : 'NEXT'}</Button> :
                                <Button variant="contained" className="padadoc-btn" onClick={handleStart} disabled={buttonClicked && !isMobile ? true : false}>START</Button>
                            }

                        </Grid>
                    </Grid>}
            </Box>

            <Grid container sx={{ height: status != 1 ? 'calc(100vh - 116px)' : 'calc(100vh - 66px)', overflow: 'auto', margin: 'auto', display: 'block' }} className="outerPandadoc-view">
                <Grid sx={{ width: '900px', margin: 'auto', textAlign: 'justify' }} id="content-to-pdf">
                    <Typography variant="h5" p={2} sx={{ fontWeight: 'bold', fontSize: '15px' }}>MyFreeScoreNow Affiliate Program Document</Typography>
                    <div className={status == 2 ? 'pdf-page1 pointerEventNone pdf-page-dynamic ' : "pdf-page1 pdf-page-dynamic"}><AffiAgreement1 affiliateDetails={affiliateDetails} status={status} /></div>
                    <div className={status == 2 ? 'pdf-page2 pointerEventNone pdf-page-dynamic' : "pdf-page2 pdf-page-dynamic"}><AffiAgreement2 formData={formData} handleFormChange={handleFormChange} errors={errors} handleFinish={handleFinish} handleBlur={handleBlur} validationSchema1={validationSchema} name={name} status={status} /></div>
                    <div className={status == 2 ? 'pdf-page3 pointerEventNone pdf-page-dynamic' : "pdf-page3 pdf-page-dynamic "}><AffiAgreement3 formData={formData} handleFormChange={handleFormChange} errors={errors} handleFinish={handleFinish} handleBlur={handleBlur} validationSchema1={validationSchema} signature={formData.signature1} name={name} status={status} /></div>
                    <div className={status == 2 ? 'pdf-page4 pointerEventNone pdf-page-dynamic' : "pdf-page4 pdf-page-dynamic"}><AffiAgreement4 formData={formData} handleFormChange={handleFormChange} errors={errors} handleFinish={handleFinish} handleBlur={handleBlur} validationSchema1={validationSchema} name={name} status={status} /></div>
                    <div className={status == 2 ? 'pdf-page5 pointerEventNone pdf-page-static' : "pdf-page5 pdf-page-static"}><AffiAgreement5 /></div>
                    <div className={status == 2 ? 'pdf-page6 pointerEventNone pdf-page-static' : "pdf-page6 pdf-page-static"}><AffiAgreement6 /></div>
                    <div className={status == 2 ? 'pdf-page7 pointerEventNone pdf-page-static' : "pdf-page7 pdf-page-static"}><AffiAgreement7 /></div>
                    <div className={status == 2 ? 'pdf-page8 pointerEventNone pdf-page-static' : "pdf-page8 pdf-page-static"}><AffiAgreement8 /></div>
                    <div className={status == 2 ? 'pdf-page9 pointerEventNone pdf-page-static' : "pdf-page9 pdf-page-static"}><AffiAgreement9 /></div>
                    <div className={status == 2 ? 'pdf-page10 pointerEventNone pdf-page-static' : "pdf-page10 pdf-page-static"}><AffiAgreement10 /></div>
                    <div className={status == 2 ? 'pdf-page11 pointerEventNone pdf-page-static' : "pdf-page11 pdf-page-static"}><AffiAgreement11 /></div>
                    <div className={status == 2 ? 'pdf-page12 pointerEventNone pdf-page-static' : "pdf-page12 pdf-page-static"}><AffiAgreement12 /></div>
                    <div className={status == 2 ? 'pdf-page13 pointerEventNone pdf-page-dynamic' : "pdf-page13 pdf-page-dynamic"}><AffiAgreement13 formData={formData} handleFormChange={handleFormChange} errors={errors} handleFinish={handleFinish} handleBlur={handleBlur} validationSchema1={validationSchema} adminSignature={formData.signature2} signature1={signature1} name={name} status={status} currentDate1={currentDate} /></div>
                    {/* <div className={status == 2 ? 'pdf-page14 pointerEventNone pdf-page-dynamic' : "pdf-page14 pdf-page-dynamic"}><AffiAgreement14 formData={formData} handleFormChange={handleFormChange} errors={errors} handleFinish={handleFinish} handleBlur={handleBlur} validationSchema={validationSchema} name={name} status={status} signature1={signature1} sameBankName1={sameBankName} /></div> */}

                    <div className="pdf-page15 pdf-page-dynamic" style={{ display: 'none' }}> <AffAgreementCertificate formData={formData} currentDate1={currentDate} ipAddress={ip} referenceId={refId} />
                    </div>

                    {status != 1 && status != 2 &&
                        <Grid item lg={12} md={12} sm={12} xs={12} mb={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button id="finishButton" variant="contained" onClick={handleFinish} className="padadoc-btn">FINISH</Button>
                        </Grid>
                    }
                </Grid>
                {showButton && (
                    <Button
                        className="blinking "
                        variant="contained"
                        onClick={handleStart}
                        style={{
                            position: 'absolute',
                            top: `${buttonPosition.top}px`,
                            left: `${buttonPosition.left}px`,
                            visibility: `${isMobile ? 'hidden' : 'visible'}`
                        }}
                    >
                        Next
                    </Button>
                )}

            </Grid>
        </>
    );
}

export default AffiAgreementIndex;