import React, { useState } from 'react'
import { Grid,Paper, Avatar, TextField, Button, IconButton, InputAdornment } from '@mui/material'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Formik } from 'formik';
import * as Yup from 'yup';
import config from '../../../AppConfig';
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function EpicProReportLogin() {
    // const paperStyle={padding :20,height:'auto',width:580, margin:"20px auto"}
    const paperStyle={padding :30,height:'auto',margin:"20px auto", }
    const avatarStyle={backgroundColor:'#1bbd7e'}

    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const handleClickPasswordVisibility = () => setPasswordVisibility(!passwordVisibility);

    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const SignupSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid Email Format.')
            .required('Email Address is required.'),
        password: Yup.string()
            .required('Password is required.'),
    })

    return(
        <>
        <LoginHeader />
        <Grid alignItems='center' className='epicProLoginBanner'>
            <h1 style={{textAlign:'center',marginTop:0,paddingTop:'2%',color:'white'}}>Welcome to the Epic Pro Portal</h1>
            <div style={{display:'flex',flexDirection: 'column',alignItems: 'center',width:'100%',height:'100%'}}>
                <Paper elevation={10} style={paperStyle}>
                    <Grid align='center'>
                        <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
                        <h2>Login to View Epic Pro Report</h2>
                    </Grid>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, errors) => {
                            handleFreezStart();
                            // Create a form element
                            const form = document.createElement('form');
                            form.method = 'post';
                            form.action = config.API_URL+'/api/member/epicproreport';
                        
                            // Create input fields for parameters
                            const emailInput = document.createElement('input');
                            emailInput.type = 'hidden';
                            emailInput.name = 'username';
                            emailInput.value = btoa(values.email);
                        
                            const passwordInput = document.createElement('input');
                            passwordInput.type = 'hidden';
                            passwordInput.name = 'password';
                            passwordInput.value = btoa(values.password);

                            const companyIdInput = document.createElement('input');
                            companyIdInput.type = 'hidden';
                            companyIdInput.name = 'companyId';
                            companyIdInput.value = null;
                            
                            // Append input fields to the form
                            form.appendChild(emailInput);
                            form.appendChild(passwordInput);
                            form.appendChild(companyIdInput);

                        
                            // Append form to the document body and submit it
                            document.body.appendChild(form);
                            handleFreezStop();
                            form.submit();
                        
                            // Remove the form from the document body
                            document.body.removeChild(form);
                            
                            // window.location.href = config.API_URL+'/member/epicproreport/null/'+btoa(values.email)+'/'+btoa(values.password);

                        }}
                    >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <>
                            <Grid item alignItems='center' spacing={2}>
                                <TextField
                                    variant='standard'
                                    required
                                    autoComplete="email"
                                    name="email"
                                    onChange={handleChange('email')}
                                    onBlur={handleBlur('email')}
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                    id="email"
                                    label="Enter Your Email Address"
                                    helperText={touched.email && errors.email ? errors.email : null}
                                    error={touched.email && errors.email ? errors.email : null}
                                />
                                <TextField
                                    variant='standard'
                                    required
                                    autoComplete="password"
                                    name="password"
                                    type={passwordVisibility ? "text" : "password"}
                                    onChange={handleChange('password')}
                                    onBlur={handleBlur('password')}
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                    id="password"
                                    label="Enter Your Password"
                                    helperText={touched.password && errors.password ? errors.password : null}
                                    error={touched.password && errors.password ? errors.password : null}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end"> {
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickPasswordVisibility}>
                                                    {passwordVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            }
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item alignItems='center' spacing={2} sx={{ marginTop: 4, marginBottom: 6 }}>
                                <Button type='submit' color='primary' variant="contained"  fullWidth onClick={handleSubmit}>Submit</Button>
                            </Grid>
                        </>
                    )}
                    </Formik>
                </Paper>
            </div>
        </Grid>
        <div className='footer-block'> <RegisterFooter  /></div>
        {/* loader code start */}
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
                onClick={handleFreezStop}
            >
                <Freez />
            </Backdrop>
        </div>
        {/* loader code end */}
        </>
    )
}

export default EpicProReportLogin;