import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Box, RadioGroup, FormControlLabel, FormLabel, Radio, Alert, AlertTitle,
    TextField, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, Grid, Button,TextareaAutosize
} from '@mui/material';
import { Typography,  Table } from '@mui/joy';
import { Link } from 'react-router-dom';
import Disposition from '../../../Components/ContactTypeConfigure/Disposition';
//MRT Imports
import MaterialReactTable from 'material-react-table';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Formik } from 'formik';
import swal from "sweetalert";
import * as Yup from 'yup';
import Loader from '../../../Components/Loader/Loader';
import { FormHelperText } from '@material-ui/core';
import config from '../../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../../redux/apiSlice';

// import ContactEdit from './ContactEdit';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const RequestFormInitialValues = {
    userType: 'both',
    callType: '',
    desposition: '',
    reason: '',
    dispositionArray: [],

};
let RequestInitialCommentValues = {
    comments: ''
};
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];

let contactError = "";

function ContactTypeConfig(props) {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleClickOpen = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id !==1 && loginUser.roles_id !==13 ) {
            navigate('/login');
            return false;
        }
        setReasonError('');
        setOpen(true);
    };

    const handleClose = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id !==1 && loginUser.roles_id !==13 ) {
            navigate('/login');
            return false;
        }
        setOpen(false);
        setIsLoading(false);
    };

    //dialog content end
    const { http, user } = AuthUser();
    const [contactlist, setContactList] = useState([]);
    const [disable, setDisable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [reasonError, setReasonError] = useState('');
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [openConfrimationBox, setOpenConfrimationBox] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    
    const navigate = useNavigate();
    
    let des = [];
    const tablePreferencesArray = {
    }
    const validationFormSchema = Yup.object().shape({
        comments: Yup.string()
            .required('Please enter the reason'),
    });
    const fetchCallTypeList = async page => {
        const apiUrl = "/callTypeList";
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res)
        {
            setContactList(res);
            setIsLoading(false);
        }
    }
    useEffect(() => {
        if(user.roles_id ==1 ||user.roles_id ==13 ) {
            fetchCallTypeList();
        }else{
            navigate('/login');
        }
    }, [])

    useEffect(() => {
    }, [rowSelection]);
    ////////////////////////
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
    }, [rowSelection]);

    useEffect(() => {
        tablePreferences();
    }, [rowSelection]);
    ////////////////////////

    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            http.post('/saveTablePreferences', {
                columnArray: columnVisibility,
                userId: user.id,
                tableId: 'contacttypeconfig'
            }).then((result) => {
            })
        }
    }, [columnVisibility])
    const tablePreferences = () => {
        http.get('/fetchTablePreferences/contacttypeconfig')
            .then((res) => {
                if (Object.values(res.data).length == 0) {
                    setColumnVisibility(tablePreferencesArray);
                } else {
                    setColumnVisibility((JSON.parse(res.data.preferences)));
                }
            });
    }
    const deleteContactType =async (id) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id !==1 && loginUser.roles_id !==13) {
           window.location.href= config.BASE_URL + 'login' ;
            return false;
        }
        setIsLoading(true);
        const apiUrl = 'DeleteContactType/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res) {
            if (res.success) {
                fetchCallTypeList();
                setSuccessMessage(res.message);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 4000);
            } else {
                setErrorMessage(res.message);
                setTimeout(() => {
                    setErrorMessage('');
                }, 4000);
            }
        }
    };
    const reActiveContactType =async (id) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id !==1 && loginUser.roles_id !==13 ) {
           window.location.href= config.BASE_URL + 'login' ;
            return false;
        }
        setIsLoading(true);
        const apiUrl = 'reactiveContactType/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            if (res.success) {
                fetchCallTypeList();
                setSuccessMessage(res.message);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 4000);
            } else {
                setErrorMessage(res.message);
                setTimeout(() => {
                    setErrorMessage('');
                }, 4000);
            }
        }
    };
    const handleData = (data) => {
        des = data;
    }
    const RequestFormSchema = Yup.object().shape({
        callType: Yup.string().required('Please enter Contact Type')
    })
    const submitRequest =async (values, { resetForm }) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id !==1 && loginUser.roles_id !==13) {
            navigate('/login');
            return false;
        }
        if(contactError.html == "Contact Reason already exists.") {
            return false;
        }
        
        let errorFlag = 0;
        let errorMsg = '';
        setErrorMessage("")
        setIsLoading(true)
        setDisable(true);
        console.log(des);

        des.map((row) => {
            console.log(row);
            if (errorFlag == 0) {
                if (row.disposition == '') {
                    errorFlag = 1;
                    errorMsg = "Please fill disposition first"
                }
                row.reasonData.map((res) => {
                    if (res.reason == '' && errorFlag == 0) {
                        errorFlag = 1;
                        errorMsg = "Please fill reason first"
                    }
                })
            }
        })
        if (errorFlag == 1) {
            alert(errorMsg);
            setDisable(false);
        } else {
            const apiUrl = "/createCallType";
            const args = {
                userType: values.userType,
                callType: values.callType,
                desposition: des,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if(res) 
            {
                if (res.success === false) {
                    setDisable(false);
                    setIsLoading(false)
                    swal({
                        title: "Failed",
                        text: res.message,
                        icon: "error",
                        button: "Ok",
                    }).then((ok) => {
                        if (ok) {
                           
                        }
                    });
                   
                   
                } else {
                    setDisable(false);
                    handleClose();
                    fetchCallTypeList();
                    setIsLoading(false);
                    swal({
                        title: "Success",
                        text: res.message,
                        icon: "success",
                        button: "Ok",
                    }).then((ok) => {
                        if (ok) {
                          
                        }
                    });
                   
                    
                }
            }else{
                setDisable(false);
                setIsLoading(false);
                swal({
                    title: "Failed",
                    text: "Something went wrong.",
                    icon: "error",
                    button: "Ok",
                }).then((ok) => {
                    if (ok) {
                       
                    }
                });
               
            };
        }
    }
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorKey: 'call_type',
                accessorFn: (row) => `${row.call_type}`,
                header: 'Contact Type',
                size: 50,
            },
            {
                accessorFn: (row) => 
                <>
                {user.roles_id==13?
                    <Tooltip title="Delete Configuration" arrow>
                     <img src={require('../../../assets/icons/deleteIcon2.png')}  onClick={()=>{setDeleteId(row.id);setOpenConfrimationBox(true)}}/>
                     </Tooltip>:
                
                  <>
                   <Tooltip title="Edit Configuration" arrow>
                        <Link className='btn btn-success text-white' to={{ pathname: "/contactTypeConfig/edit/" + row.id }}  >
                            <img src={require('../../../assets/icons/editIcon.png')} />
                        </Link>
                    </Tooltip>
                    {
                        row.is_active === 1
                            ?
                            <Tooltip title="Deactivate Configuration" arrow>
                                <Link className='btn btn-danger text-white' onClick={() => deleteContactType(row.id)} >
                                    <img src={require('../../../assets/icons/deactiveIcon.png')} />
                                </Link>
                            </Tooltip>
                            :
                            <Tooltip title="Reactivate" arrow>
                                <Link className='btn btn-success text-white' onClick={() => reActiveContactType(row.id)} >
                                    <img src={require('../../../assets/icons/reactiveIcon.png')} />
                                </Link>
                            </Tooltip>
                    }</>
                }
                </>,
                id: 'actions',
                header: 'Actions',
            },
        ]
    )
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Contact Type Configuration',
        headers: ['Contact Type'],
      };
    
      const csvExporter = new ExportToCsv(csvOptions);
      const handleExportData = (contactlist) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id !==1 && loginUser.roles_id !==13 ) {
            navigate('/login');
            return false;
        }
        const exportedData = [];
       contactlist.forEach((row) => {
            
        const mainTableRow = {
            call_type : row.original.call_type || "N/A",
            
          };
          exportedData.push(mainTableRow);
			// Add subheaders
			const subheader = {
				'Disposition': 'Disposition', // Subheader 1
				'Reason': 'Reason', // Subheader 2
				
			};
			exportedData.push(subheader);
			if (row.original.call_type_disposition_data) {
                row.original.call_type_disposition_data.map((dispositionItem) => {
                  const subRow = {
                    disposition: dispositionItem.disposition ? dispositionItem.disposition : '',
                    reason: dispositionItem.call_type_reason.map((reason) => reason.reason).join(','),
                  };
                  exportedData.push(subRow);
                });
              } else {
                exportedData.push({});
              }
              exportedData.push({});
        });
        // csvExporter.generateCsv(exportedData);
          // Convert data to CSV format
      const csvContent = convertToCSV(exportedData);
    
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
    
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Programmatically click the link to trigger the file download
      link.click();
    
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
    };
    
    const convertToCSV = (data) => {
      const headers = csvOptions.headers.join(',') + '\n';
    
      const rows = data.map((row) => {
        return Object.values(row).map((value) => {
            // Handle any necessary formatting or escaping of values
            // For simplicity, we assume all values are already properly formatted

            // Wrap the value with double quotes if it contains a comma
            if (typeof value === 'string' && value.includes(',')) {
                return `"${value}"`;
            }

            return value;
        }).join(',');
    }).join('\n');
    
      return headers + rows;
      };
    const handleCheck =async (value) => {
        const apiUrl = '/contactTypeConfig/checkContactType?contactReason=' + value;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res)
        {
            if (res.success) {
                contactError = document.getElementById("contactError");
                contactError.html = res.message;
                contactError.style.display = "block";

            } else {
                const contactError = document.getElementById("contactError");
                contactError.style.display = "none";
                contactError.html = "";
            }
        }
    }
const deleteConfiguration=async (values)=>{
        setOpenConfrimationBox(false)
        setIsLoading(true)
        const apiUrl = "/contactTypeDelete";
        const args = {
            callTypeId : deleteId,
            notes: values.comments
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res)
        {
            fetchCallTypeList()
            setIsLoading(false)
            if (res.success === true) {
                swal({
                    title: "Success",
                    text: 'Configuration has been deleted successfully',
                    icon: "success",
                    button: "Ok",
                })
            } else {
                alert("something went wrong !");
            }
        }

    
}
    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} xs={12}>
                    <Typography color="primary" level="h4">Contact Type Configuration</Typography>
                </Grid>
                <Grid container item lg={12} md={12} xs={12} mt={2} mb={2}>
                    <Button color="success" sx={{ minWidth: 150 }} variant="contained" onClick={handleClickOpen}>ADD NEW</Button>
                </Grid>
                {successMessage ? <Alert severity="success"><AlertTitle>Success</AlertTitle>{successMessage}</Alert> : null}
                <MaterialReactTable
                    columns={columns}
                    data={contactlist}
                    filterFns={{
                        customSearchFilterFn: (row, id, filterValue) =>
                        row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                       }} 
                    globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                    enableColumnFilterModes
                    enableRowSelection={false}
                    onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                    state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                    initialState={{ showColumnFilters: false, density: 'compact' }}
                    onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                    enableColumnFilters={config.DatatableColumnFilter}
                    positionToolbarAlertBanner="bottom"
                    enableDensityToggle={false}
                    muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                            backgroundColor: "#F6FAFF",
                        },
                        
                    }}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                        >
                          <Button
                            color="primary"
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                            onClick={() =>
                                handleExportData(table.getPrePaginationRowModel().rows)
                            }
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                          >
                            CSV
                          </Button>
                          
                          
                        </Box>
                      )}
                    renderDetailPanel={({ row }) => (
                        <Box >
                            <Table  borderAxis="both">
                                <thead>
                                    <tr>
                                        <th sx={{ fontWeight: "bold" }}>Disposition</th>
                                        <th sx={{ fontWeight: "bold" }} align="center">Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {row.original.call_type_disposition_data.map((dispositionItem) => (
                                        <tr key={dispositionItem.id}>
                                            <td >
                                                {dispositionItem.disposition ? dispositionItem.disposition : ''}
                                            </td>
                                            <td >
                                                {dispositionItem.call_type_reason.map((reason,index) => (
                                                    
                                                        <p key={index}>{reason.reason}</p>
                                                    
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </Box>
                    )}
                />
                <Dialog
                    open={open}
                    maxWidth={'md'}
                    fullWidth={true}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleClose();
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Formik
                        initialValues={RequestFormInitialValues}
                        validationSchema={RequestFormSchema}
                        onSubmit={submitRequest}
                    >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
                        <DialogTitle id="alert-dialog-title" sx={{
                            bgcolor: '#F6FAFF'
                        }}>
                            <Typography level="h4" color='primary'>Select Data</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <FormControl >
                                    <FormLabel id="demo-row-radio-buttons-group-label"><Typography level="h6">Configuration For</Typography></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        id="userType"
                                        name="userType"
                                        value={values.userType}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="affiliate" control={<Radio />} label="Affiliate" />
                                        <FormControlLabel value="member" control={<Radio />} label="Member" />
                                        <FormControlLabel value="both" control={<Radio />} label="Both" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                <FormControl margin='dense' fullWidth>
                                    <TextField
                                        label="Contact Reason"
                                        variant="outlined"
                                        margin='dense'
                                        size='small'
                                        id="callType"
                                        name="callType"
                                        value={values.callType}
                                        onBlur={handleBlur('callType')}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleCheck(e.target.value)
                                        }}
                                        helperText={touched.callType && errors.callType ? errors.callType : null}
                                        error={touched.callType && errors.callType ? errors.callType : null}
                                    />
                                    <Grid id="contactError" sx={{ display: "none" }}>
                                        <FormHelperText error={true}>Contact Reason already exists.</FormHelperText>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Disposition onChangeData={handleData} />
                        </DialogContent>
                        <DialogActions>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" sx={{ minWidth: 250 }} onClick={handleClose}>
                                        CLOSE
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" sx={{ minWidth: 250 }} color="success" type="submit" onClick={handleSubmit} disabled={disable} >
                                        SAVE
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>)}</Formik>
                </Dialog>
                <Dialog
                                fullWidth
                                maxWidth={'sm'}
                                open={openConfrimationBox}
                                // onClose={handleClose}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        setOpenConfrimationBox(false);
                                    }
                                }}
                            >
                    <Formik
                        initialValues={RequestInitialCommentValues}
                        validationSchema={validationFormSchema}
                        onSubmit={deleteConfiguration}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                         <>
                                <DialogContent>
                                    <Box mt={1}>
                                        <Typography level='h4'>Enter the reason to delete the Configuration</Typography>

                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                                            <FormControl fullWidth size="small">
                                                <TextareaAutosize
                                                    style={{ width: "100%" }}
                                                    required
                                                    margin="dense"
                                                    aria-label="minimum height"
                                                    minRows={6}
                                                    placeholder="Enter Note*"
                                                    name="comments"
                                                    id="comments"
                                                    value={values.comments}
                                                    onChange={handleChange('comments')}
                                                    onBlur={handleBlur('comments')}
                                                />
                                                <div className='error'>{touched.comments && errors.comments ? errors.comments : ''}</div>
                                            </FormControl>
                                        </Grid>

                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ margin: 'auto' }} >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={()=>{handleSubmit()}} color="success" sx={{ minWidth: 250 }} >
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={() => setOpenConfrimationBox(false)} color="primary" sx={{ minWidth: 250 }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </DialogActions>
                                </>
                        )}

                    </Formik>
                    </Dialog>
            </>
        );
    }
    return (
        <div>
            {isLoading ? <Loader /> : <></>}
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default ContactTypeConfig;
