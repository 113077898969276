
import React,{useRef,useState} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {Formik} from 'formik';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box,Stack,Grid,Button,TextField,Divider ,FormGroup,InputLabel,FormControl,OutlinedInput,
    FormControlLabel,FormLabel,RadioGroup,Radio,Checkbox,} from '@mui/material';
import { Typography,CardOverflow } from '@mui/joy';
import AuthUser from '../../../Components/Auth/AuthUser';
import swal from "sweetalert";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
function BankDetailsForm(props) {


    const { http } = AuthUser();
    const { id } = useParams();
    const inputRef  = useRef();
    const inputRef1  = useRef();
  
   
    const navigate = useNavigate();
    const [bank_details, setBankDetails] = useState([]);

    const [btntext, setBtntext] = useState("Update");

    

  //Fetch Company Bank Details


 
 

  //Call Function if load this page
 
  



//Create function start

const CreateBankDetails = (values) => {

   if(values.BankName == values.NameonAccount){
   
    swal({
        title: "Alert",
        text: "Bank Name & Name on Account must be different",
        icon: "error",
        button: "Ok",
    }).then((ok)=>{
        if(ok){
            inputRef.current?.focus();
        }
      });
   
    
   }else if(values.ACHinfoaddedtobank == 1 && values.ACHdate1 == null ){
   

    swal({
        title: "Alert",
        text: "If ACH is added, then ACH add date is required",
        icon: "error",
        button: "Ok",
    }).then((ok)=>{
        if(ok){
            inputRef1.current?.focus();
        }
      });

   }else{

                http.post('/add-company-bank-details',{
                    company_master_id:id,
                    bank_name:values.BankName,
                    name_on_account:values.NameonAccount,
                    routing_number:values.BankRouting,
                    account_number:values.AccountNumber,
                    account_type:values.AccountType,
                    account_category:values.AccountCategory,
                    email_to_notify:values.EmailAddresstonotifyofpaymenttransfers,
                    
            
                    ach_info_added_to_bank:values.ACHinfoaddedtobank,
                    last_ach_update:values.ACHdate1
            
                }).then((res)=>{
                    if(res.data.success === true){
                    
                        
                        swal({
                        title: "Success",
                        text: res.data.message,
                        icon: "success",
                        button: "Ok",
                        }).then((ok)=>{
                        if(ok){
                            navigate('/companypanel');
                        }
                        });
                    }else{
                    
                    swal({
                        title: "Failed",
                        text: res.data.message,
                        icon: "error",
                        button: "Ok",
                    });
                    }
                }).catch(error => {
                
                    swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                    });
                });
        }
}
//Create function end


//update ACH date start 




//Bank validation Schema start


  const BankSchema = Yup.object().shape({
        BankName: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Please enter your Bank Name.'),

        NameonAccount: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Please enter your Name On Account.'),

        BankRouting: Yup.string()
        .min(9, 'At least 9 digit required')
        .max(9, 'maximum 9 digit')
        .required('Please enter your Bank Routing number.'),

        AccountNumber: Yup.string()
        .required('Please enter your Account Number.'),

        AccountType: Yup.string()
       
        .required('Please select Account Type.'),

        
        AccountCategory: Yup.string()
      
        .required('Please select Account Category.'),
        
        EmailAddresstonotifyofpaymenttransfers: Yup.string()
        .email('Invalid email format.')
        .required('Please enter your email address to notify payment transfers.'),
    
});


//Bank validation Schema end



    
    function ContentLayout() {
    return (
        <>
<Box>
<Formik 
                                initialValues={{
                                            BankName:"jack",
                                            NameonAccount:"",
                                            BankRouting:"",
                                            AccountNumber:"",
                                            AccountType:"",
                                            AccountCategory:"",
                                            ACHinfoaddedtobank:"",
                                           
                                            EmailAddresstonotifyofpaymenttransfers:""
                                            
                                                  
                                }}
                                validationSchema={BankSchema}
                                
                                
                               
                                onSubmit={(values,errors) => {

                                    alert("test");

                                   
                                   
                                }}
                                
                            >
                                
                                {({values,errors,touched,handleChange,handleBlur,setFieldTouched,handleSubmit,setFieldValue,setFieldError}) =>(    
                
            <Box>
                                          
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',
                        }}
                    >
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:'space-between'}} >
                            <Typography level="h3">
                                Bank Details Panel (Company)
                            </Typography >
                            <Typography level="h3">Last updated: 02-03-2023</Typography>
                        </Grid>
                    </CardOverflow>
                    
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} mt={2}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                     label="Bank Name"
                                     type="text"
                                     size="small"
                                     margin="dense"
                                     fullWidth
                                     name='BankName'
                                     onChange={handleChange('BankName')}
                                     value={values.BankName}
                                     onBlur={handleBlur('BankName')}
                                    />
                                     {touched.BankName && errors.BankName ? <div className='error'>{errors.BankName}</div> : null}
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} direction="row">
                                <FormControl variant="outlined" fullWidth
                                        size="small" margin="dense">
                                    <InputLabel htmlFor="outlined-adornment-password">Bank Routing (ABA/ACH) Number*</InputLabel>
                                    <OutlinedInput
                                         label="Bank Routing (ABA / ACH) Number"
                                         type="number"
                                         size="small"
                                         fullWidth
                                         name='BankRouting'
                                         id='BankRouting'
                                         onChange={handleChange('BankRouting')}
                                         value={values.BankRouting}
                                         onBlur={handleBlur('BankRouting')}
                                    />
                                    </FormControl>
                                    {touched.BankRouting && errors.BankRouting ? <div className='error'>{errors.BankRouting}</div> : null} 
                            </Grid>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3} >
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    label="Name on Account"
                                    type="text"
                                    size="small"
                                    fullWidth
                                    margin="dense"
                                    inputRef={inputRef}
                                    name='NameonAccount'
                                    id='NameonAccount'
                                    onChange={handleChange('NameonAccount')}
                                    value={values.NameonAccount}
                                    onBlur={handleBlur('NameonAccount')}
                                    />
                                    {touched.NameonAccount && errors.NameonAccount ? <div className='error'>{errors.NameonAccount}</div> : null}
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                            <FormControl variant="outlined" fullWidth
                                        size="small" margin="dense">
                                    <InputLabel htmlFor="outlined-adornment-password">Bank Account Number *</InputLabel>
                                    <OutlinedInput
                                         label="Account Number"
                                         type="text"
                                         size="small"
                                         fullWidth
                                         name='AccountNumber'
                                         id='AccountNumber'
                                         onChange={handleChange('AccountNumber')}
                                         value={values.AccountNumber}
                                         onBlur={handleBlur('AccountNumber')}
                                    />
                                    </FormControl>
                                    {touched.AccountNumber && errors.AccountNumber ? <div className='error'>{errors.AccountNumber}</div> : null} 
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle1" gutterBottom mt={1}>
                        Enter the name of the Account held with the Bank.(Do not enter the Name of the Bank Here)
                        </Typography>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Stack direction="row" spacing={4}>
                                <FormControl>
                                    <FormLabel sx={{color:"black"}} >Type of Account*</FormLabel>
                                    <RadioGroup
                                       
                                        name='AccountType'
                                        id='AccountType'
                                        onChange={handleChange('AccountType')}
                                        value={values.AccountType}
                                        onBlur={handleBlur('AccountType')}
                                    >
                                        <FormControlLabel value="checking" control={<Radio />} label="Checking" sx={{fontWeight:"bold"}} />
                                        <FormControlLabel value="savings" control={<Radio />} label="Savings" sx={{fontWeight:"bold"}}/>
                                        
                                    </RadioGroup>
                                </FormControl>
                                {touched.AccountType && errors.AccountType ? <div className='error'>{errors.AccountType}</div> : null} 
                                <FormControl>
                                    <FormLabel sx={{color:"black"}}>And is Account*</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="AccountCategory"
                                        id='AccountCategory'
                                        onChange={handleChange('AccountCategory')}
                                        value={values.AccountCategory}
                                        onBlur={handleBlur('AccountCategory')}
                                    >
                                        <FormControlLabel value="personal" control={<Radio />} label="Personal" sx={{fontWeight:"bold"}} />
                                        <FormControlLabel value="business" control={<Radio />} label="Business" sx={{fontWeight:"bold"}}/>
                                    </RadioGroup>
                                </FormControl>
                                {touched.AccountCategory && errors.AccountCategory ? <div className='error'>{errors.AccountCategory}</div> : null} 
                            </Stack>
                        </Grid>
                        <Divider />
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level="h5" color="primary" mt={2}>BACK OFFICE USE ONLY DO NOT ENTER ANY INFORMATION BELOW</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Have you also updated ACH details in the Bank?" />
                            </FormGroup>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label"  sx={{color:"black"}}>ACH Info Added to Bank</FormLabel>
                                <RadioGroup
                                     aria-labelledby="demo-row-radio-buttons-group-label"
                                     name="ACHinfoaddedtobank"
                                     id='ACHinfoaddedtobank'

                                     onChange={(e) => {handleChange(e)}}
                                     
                                     value={values.ACHinfoaddedtobank}
                                     onBlur={handleBlur('ACHinfoaddedtobank')}
                                >
                                    <FormControlLabel value="1" control={<Radio />} label="Yes" sx={{fontWeight:"bold"}} />
                                    <FormControlLabel value="0" control={<Radio />} label="No" sx={{fontWeight:"bold"}} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid container item lg={7} md={7} sm={12} xs={12} mt={2}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['DatePicker']}>
                                                                    <DatePicker label="Basic date picker" 
                                                                    defaultValue={dayjs(bank_details.last_ach_updated)}
                                                                    name="LastACHupdatedate"
                                                                    id='LastACHupdatedate'
                                                                    
                                                                    onChange={(value) => {setFieldValue("ACHdate1", value, true)}}
                                                                  
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                       
                                                                          label="ACHdate1"
                                                                          margin="normal"
                                                                          name="ACHdate1"
                                                                          variant="standard"
                                                                          
                                                                          fullWidth
                                                                          {...params}
                                                                         />
                                                                         )}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                            <Grid item>
                                <Button variant="contained" sx={{minWidth:200,maxWidth:"100%"}} color="success" onClick={handleSubmit}>
                                   {btntext}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" sx={{minWidth:200,maxWidth:"100%"}} onClick={()=> navigate(-1)}>
                                    CLOSE
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="warning" sx={{minWidth:200,maxWidth:"100%"}}>
                                    Report Issue
                                </Button>
                            </Grid>
                        </Grid>
                    
                
            </Box> 

)}
                                                
</Formik>
</Box>


        </>
        );
    }
     
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}
    
export default BankDetailsForm;