import React,{useMemo,useState,useEffect} from 'react';
import {useParams, useNavigate, Link,useLocation} from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid,InputLabel,MenuItem, FormControl, Select,Box,Button,Tab,Tooltip } from '@mui/material';
import { Typography,Table, Option, } from '@mui/joy';
import { Stack } from '@mui/system';
import { styled } from '@mui/material/styles';
import AffiliateCommissionView from '../../../Components/CommissionSummary/AffiliateCommissionView';
import ReferralCommissionView from '../../../Components/CommissionSummary/ReferralCommissionView';
import AuthUser from "../../../Components/Auth/AuthUser";
import { useTheme } from '@mui/material/styles';
import Loader from '../../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

function AffiliateCommissionViewDetails(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [personName, setPersonName] = React.useState([]); 
    const {state} = useLocation();
    const { http, user } = AuthUser();
    const { month,year} = state; 
    const [totalRows, setTotalRows] = useState(0);
    const [itemList, setItemList]=useState([]);
    const [commission, setCommission]=useState("");
    const [bonus, setBonus]=useState("");
    const [referralCommission, setReferralCommission]=useState("");
    const [targetBonus, setTargetBonus]=useState("");
    const [totalCommission, setTotalCommission]=useState("");
    const [value, setValue] = React.useState('1');
    const[referralCommissionList, setReferralCommissionList] = React.useState([]);
    const[bonusCommissionList, setBonusCommissionList] = React.useState([]);
    const[enrollmentTargetLabel, setEnrollmentTargetLabel] = React.useState('0');
    const[enrollmentAchievedLabel, setEnrollmentAchievedLabel] = React.useState('0');
    const[totalEnrollmentTargetLabel, setTotalEnrollmentTargetLabel] = React.useState('0');
    const[totalEnrollmentAchievedLabel, setTotalEnrollmentAchievedLabel] = React.useState('0');
    const[paymentAmountLabel, setPaymentAmountLabelLabel] = React.useState('N/A');
    
    const fetchCommissionRateDetails = async() =>{
            const apiUrl = '/affiliate/commission-view-details';
            const args = {
                month : month,
                year : year,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                if(res.success === true){
                    setItemList(res.data ? res.data.details?  res.data.details : [] : []); 
                    setCommission(res.data.commission);
                    setReferralCommissionList(res.data.referredCompanyCommissionDetails ? res.data.referredCompanyCommissionDetails : []);
                    setBonusCommissionList(res.data.bonusDetails ? res.data.bonusDetails : []);
                    setBonus(res.data.bonusAmount ? res.data.bonusAmount : 0);
                    setTargetBonus(res.data.targetBonusAmount ? res.data.targetBonusAmount : 0);
                    setReferralCommission(res.data.referralCommissionAmount ? res.data.referralCommissionAmount : 0);
                    setTotalCommission(res.data.finalTotalCommission ? res.data.finalTotalCommission : 0);
                    setEnrollmentTargetLabel(res.data.enrollmentTarget ? res.data.enrollmentTarget : 0);
                    setEnrollmentAchievedLabel(res.data.enrollmentTarget ? res.data.enrollmentAchieved : '0');
                    setTotalEnrollmentTargetLabel(res.data.totalEnrollmentTarget ? res.data.totalEnrollmentTarget : '0');
                    setTotalEnrollmentAchievedLabel(res.data.totalEnrollmentAchieved ? res.data.totalEnrollmentAchieved : '0');
                    setPaymentAmountLabelLabel(res.data.paymentAmount ? res.data.paymentAmount : '0');
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                }
            }             
      }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchCommissionRateDetails();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };
    
    function ContentLayout() {
    return (
        <>
            {isLoading?<Loader/>:<></>}
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"space-between"}}>
                <Grid item lg={6} md={6} sm={10} xs={10}>
                    <Typography level="h3" color="primary">Commission View Details : {month} {year}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={10} xs={10} sx={{textAlign:"right"}}>
                    <Tooltip title="Close" >
                        <CancelOutlinedIcon onClick={() => navigate(-1)} />
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                <Grid container item lg={4} md={4} sm={6} xs={6} >
                    <Typography level="h4"> Commission : <b>{commission ? "$" + parseFloat(commission).toFixed(2) : '$0.00'} </b></Typography>
                </Grid>
                <Grid container item lg={4} md={4} sm={6} xs={6} >
                    <Typography level="h4">Referral Commission: <b>{referralCommission ? "$" + parseFloat(referralCommission).toFixed(2) : '$0.00'}</b></Typography>
                </Grid>
                <Grid container item lg={4} md={4} sm={6} xs={6} >
                    <Typography level="h4">Referral Bonus : <b>{bonus ? "$" + parseFloat(bonus).toFixed(2) : '$0.00'}</b></Typography>
                </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                {
                 targetBonus > 0 ?
                 <>
                  <Grid container item lg={4} md={4} sm={6} xs={6} >
                    <Typography level="h4">Target Incentive : <b>{targetBonus ? "$" + parseFloat(targetBonus).toFixed(2) : '$0.00'}</b></Typography>
                    </Grid>
                 </> : ''
                
               }
               {
                    enrollmentTargetLabel != 0 ?
                    <>
                        <Grid container item lg={4} md={4} sm={6} xs={6} >
                            <Typography level="h4"> {totalEnrollmentTargetLabel != 0 ? 'Total' : ''} Target Enrollment : <b>{totalEnrollmentTargetLabel != 0 ? totalEnrollmentTargetLabel : enrollmentTargetLabel} </b></Typography>
                        </Grid>
                        <Grid container item lg={4} md={4} sm={6} xs={6} >
                            <Typography level="h4"> {totalEnrollmentTargetLabel != 0 ? 'Total' : ''} Enrollment Achieved : <b>{totalEnrollmentTargetLabel != 0 ? totalEnrollmentAchievedLabel : enrollmentAchievedLabel} </b></Typography>
                        </Grid>
                        {
                            targetBonus == 0  && enrollmentTargetLabel != 0 ?
                            <>
                                <Grid container item lg={4} md={4} sm={6} xs={6}>
                                    <Typography level="h4"> Total Commission : <b>{totalCommission ? "$" + parseFloat(totalCommission).toFixed(2) : '$0.00'} </b></Typography>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                {
                                    paymentAmountLabel != "N/A" ?
                                    <Grid container item lg={4} md={4} sm={6} xs={6}>
                                        <Typography level="h4"> Payment Amount : <b>${parseFloat(paymentAmountLabel).toFixed(2)} </b></Typography>
                                    </Grid>
                                    :''
                                }
                                </Grid>
                            </>
                           : ''
                        }
                    </>
                    :
                    <>
                        <Grid container item lg={4} md={4} sm={6} xs={6}>
                            <Typography level="h4"> Total Commission : <b>{totalCommission ? "$" + parseFloat(totalCommission).toFixed(2) : '$0.00'} </b></Typography>
                        </Grid>
                        {
                            paymentAmountLabel != "N/A" ?
                            <Grid container item lg={4} md={4} sm={6} xs={6}>
                                <Typography level="h4"> Payment Amount : <b>${parseFloat(paymentAmountLabel).toFixed(2)} </b></Typography>
                            </Grid>
                            :''
                        }
                    </>
                  
                    
                   
               }
               
               {
                targetBonus > 0  && enrollmentTargetLabel != 0 ?
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid container item lg={4} md={4} sm={6} xs={6} >
                        <Typography level="h4"> Total Commission : <b>{totalCommission ? "$" + parseFloat(totalCommission).toFixed(2) : '$0.00'} </b></Typography>
                    </Grid>
                    {
                        paymentAmountLabel != "N/A" ?
                        <Grid container item lg={4} md={4} sm={6} xs={6}>
                            <Typography level="h4"> Payment Amount : <b>${parseFloat(paymentAmountLabel).toFixed(2)} </b></Typography>
                        </Grid>
                        :''
                    }
                   
                </Grid>
                : ''
               }
              
                
            </Grid>
                {/* <AffiliateCommissionView itemList={itemList} month={month} year={year}/>  */}
                {/* approve and send popup start */}
               
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Commission" value="1" />
                                    <Tab label="Refeeral Commission & Bonus" value="2" />
                                </TabList>
                        </Grid>
                    </Box>
                <TabPanel value="1"><AffiliateCommissionView  itemList={itemList} month={month} year={year}/></TabPanel>
                <TabPanel value="2"><ReferralCommissionView  bonusCommissionList={bonusCommissionList} referralCommissionList={referralCommissionList} /></TabPanel>
                </TabContext>
        </>
        );
    }
     
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}
    
export default AffiliateCommissionViewDetails;