import React, { useEffect, useState, useRef, } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Step, Stepper, StepButton, StepLabel, DialogContent, Divider, OutlinedInput, Button, InputLabel, FormGroup, FormControlLabel, Checkbox, FormControl, FormLabel, Tooltip, RadioGroup, Radio, Table, FormHelperText, Box, Paper, Dialog, DialogActions, DialogTitle, Slide, Select, MenuItem, ListSubheader } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Stack, AspectRatio } from '@mui/joy';
import DefaultLayout from '../DefaultLayout';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import CobrandColorPicker from "../../Components/CobrandCustomization/CobrandColorPicker";
import AuthUser from '../../Components/Auth/AuthUser';
import swal from "sweetalert";
import Freez from '../../Components/Loader/Loader'
import { fetchData, postData } from '../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import Step1 from './CustomSteps/Step1';
import Step2 from './CustomSteps/Step2';
import Step4 from './CustomSteps/Step4';
import config from '../../AppConfig'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import CustomizeEnrollIndex from '../../Components/CobrandCustomization/CustomizeEnrollIndex'
import Backdrop from '@mui/material/Backdrop';
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas';
import { FaBold, FaItalic } from 'react-icons/fa';
import { debounce } from 'lodash';
import { MenuProps} from "../SuspendedMembers/utils";
import fontJson from '../CobrandCustomization/fontFamily.json'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const initialTextStyle = {
    bold: false,
    italic: false,
    fontSize: 16,
    fontColor: '#000000',
    backgroundColor: '#ffffff00',
    fontFamily: 'Arial',
    position: { x: 20, y: 30 },
    text: '',
    placeholder: 'Edit Text Here ...',
};
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 60,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "black",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        // width: 7,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'black',
        borderRadius: 4,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'grey',
    border: "2px solid black",
    zIndex: 1,
    color: 'black',
    width: 115,
    height: 115,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: "white",
        border: "7px solid black"
    }),
}));

function ColorlibStepIcon(props) {
    const { active, className } = props;

    const icons = {
        1: <img src={require("../../assets/images/Step1.jpg")} style={{ width: "75px", height: "auto" }} />,
        2: <img src={require("../../assets/images/Step2.jpg")} style={{ width: "75px", height: "auto" }} />,
        3: <img src={require("../../assets/images/Step3.jpg")} style={{ width: "75px", height: "auto", opacity: 0.3 }} />,
        4: <img src={require("../../assets/images/Step4.jpg")} style={{ width: "75px", height: "auto" }} />,
        5: <img src={require("../../assets/images/Step5.jpg")} style={{ width: "75px", height: "auto", opacity: 0.3 }} />
    };
    const customStyles = {
        3: {
            backgroundColor: '#e0e0e0',
            border: '2px solid #e0e0e0',
        },
        5: {
            backgroundColor: '#e0e0e0',
            border: '2px solid #e0e0e0',
        }
    };

    return (
        <ColorlibStepIconRoot ownerState={{ active }} className={className} style={customStyles[props.icon]} sx={{ cursor: 'pointer' }}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    // completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

// Styled Stepper component

let defaultCustomColor = [{ bodyBackground: '#FFFFFF', primaryColor: '#3470B1', hyperlinkColor: '#3470B1' }]
let defaultBannerImg = require("../../assets/images/img.png")
let defaultBanner = [
    { step: 1, url: '', flag: 0 },
    { step: 2, url: '', flag: 0 },
    { step: 4, url: '', flag: 0 },
];

let bannerImg1 = require("../../assets/images/defaultImg1.png")
let bannerImg2 = require("../../assets/images/defaultImg2.png")
let bannerImg3 = require("../../assets/images/defaultImg3.png")
let defaultCustomBanner1 = [
    { url: bannerImg1 },
    { url: bannerImg2 },
    { url: bannerImg3 },
];
let imageAspectRatio = 3 / 2;
function AdvancedCobrandSetup(props) {
    const { http, user } = AuthUser();
    const navigate = useNavigate();
    const [customColorArrays, setCustomColorArrays] = useState(defaultCustomColor);
    const steps = ['Configure Identity Information', 'Configure Address Information', 'Step-3', 'Configure Payment Details', 'Step-5'];
    const [openColorPicker, setOpenColorPicker] = useState(null);
    const [textColor, setTextColor] = useState('black')
    const [publishBannerBtn, setPublishBannerBtn] = useState(false)
    const [freez, setFreez] = React.useState(false);
    const [croppedImages, setCroppedImages] = useState(defaultBanner);
    const [stepcount, setStepCount] = useState(1);
    const [openImgPopup, setOpenImgPopup] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState("");
    const [originalImgsArray, setOriginalImgsArray] = useState([])
    const [completedCrop, setCompletedCrop] = useState([])
    const [tempImg, setTemImg] = useState()
    const aidUserObj = localStorage.getItem('aidUser');
    const aidUser = aidUserObj ? JSON.parse(aidUserObj) : '';
    const [openPreviewPopup, setOpenPreviewPopup] = useState(false)
    const [templateValue, setTemplateValue] = useState(1)
    const [openResetPopup, setOpenResetPopup] = useState(false)
    const [originalCroppedImgs, setOriginalCroppedImgs] = useState([])
    const [openPublishPopup, setOpenPublishPopup] = useState(false)
    const [deleteUploadImgPopup, setDeleteUploadImgPopup] = useState(false);
    const [btnTextColor, setBtnTextColor] = useState('white')
    const [deleteFileName, setDeleteFileName] = useState(false);
    const [thumbnilClick, setThumbnilClick] = useState(false);
    const [originalImgFileName, setOriginalImgFileName] = useState()
    const [selectedImgPoup, setSelectedImgPoup] = useState(false)
    const [textElementsByStep, setTextElementsByStep] = useState({});
    const [focusedTextElementIndex, setFocusedTextElementIndex] = useState(null);
    const imageContainerRef = useRef(null);
    const imageRef = useRef(null);
    const [showText, setShowText] = useState(false);
    const [savedImage, setSavedImage] = useState(defaultBanner);
    const textElementRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(null);
    const fontSizes = [12, 14, 16, 18, 20, 24, 28, 32];
    const [editingIdx, setEditingIdx] = useState(null);
    const [dragging, setDragging] = useState(false);
    // Array for font families
    const [fontFamilies, setFontFamilies] = useState([]);
    const [loadingIndex, setLoadingIndex] = useState(0);
    const [textStyle, setTextStyle] = useState({  bold: false,
        italic: false,
        fontSize: 16,
        fontColor: '#000000',
        backgroundColor: '#ffffff00',
        fontFamily: '',
        position: { x: 20, y: 30 },
        text: '',
        placeholder: 'Edit Text Here ...',});

    const [crop, setCrop] = useState({
        unit: 'px',
        x: 0,
        y: 0,
        width: 100,
        height: 100
    });
    const imgRef = useRef(null)
    const dispatch = useDispatch();
    const steps1 = ['Register', 'Verify Identity', 'Complete'];

    const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 20px)',
            right: 'calc(50% + 20px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: customColorArrays[0].primaryColor,
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: customColorArrays[0].primaryColor,
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#d3d3d3',
            borderTopWidth: 3,
            borderRadius: 1,
            width: 'auto'
        },
        // Additional styling for half connector
        [`& .halfLine`]: {
            borderLeftWidth: '40%', // Half width
            borderTopWidth: 3,
            borderStyle: 'solid',
            borderColor: customColorArrays[0].primaryColor, // Adjust as needed
            position: 'absolute',
            top: 0,
            bottom: 0,
        },
    }));

    const handleBannerImgChange = (event) => {
        setThumbnilClick(false);
        const file = event.target.files[0]; // Get the first selected file
        // Check if a file is selected
        if (file) {
            // Check if the selected file is an image
            if (file.type.startsWith('image/')) {
                const reader = new FileReader(); // Create a file reader
                reader.onload = () => {
                    const img = new Image();
                    img.onload = function () {
                        // Check dimensions
                        if (img.width < 600 || img.height < 400) {
                            alert("Image must be 600px in width and 400px in height.");
                            return false;
                        }
                        // Update the banner image with the data URL of the selected file
                        if (file.size > 1 * 1024 * 1024) {          //check image size greater than is 300KB
                            alert("File size should be less than 1 MB.")
                            return false;
                        }
                        setImgSrc(reader.result);
                        setSelectedImgPoup(false);
                        setOpenImgPopup(true);
                        setError("");
                    };
                    img.src = reader.result;
                }
                // Read the selected file as a data URL
                reader.readAsDataURL(file);
            } else {
                // Show an error alert if the selected file is not an image
                setError("Please select an image file.");
                setImgSrc('');
            }
        }
    };
    const handleValue = (colorArray) => {
        let loginValue = checkIsLogIn()
        if (loginValue == true) {
            setCustomColorArrays(colorArray);
            getContrastText(colorArray[0].primaryColor);
        }
        else {
            return false
        }
    }
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const toggleColorPicker = (blockName) => {
        setOpenColorPicker((prevBlockName) => (prevBlockName === blockName ? null : blockName));
    };
    const setTextColorValue = (e) => {
        let loginValue = checkIsLogIn()
        if (loginValue == true) {
            setTextColor(e.target.value)
        }
        else {
            return false;
        }
    }
    const handleStepClick = (e, step) => {
        let loginValue = checkIsLogIn()
        if (loginValue == true) {
            if (e.target.tagName.toLowerCase() === 'img' || e.target.tagName.toLowerCase() === 'div') {
                setActiveStep(step);
                setStepCount(step + 1)
            }
        }
        else {
            return false;
        }
    };
    const onSubmitStyleChanges =async (value) => {
        let loginValue = checkIsLogIn()
        if (loginValue == true) {
            const filteredJson = {};
            Object.keys(textElementsByStep).forEach(step => {
                filteredJson[step] = textElementsByStep[step].filter(
                    element => element.text !== null && element.text !== ""
                );
            });
            // Now update the state
            setTextElementsByStep(filteredJson);
            handleFreezStart();
            let flag = croppedImages[0].url == "" &&
                croppedImages[1].url == "" &&
                croppedImages[2].url == "" &&
                customColorArrays[0].bodyBackground == "#FFFFFF" &&
                customColorArrays[0].primaryColor == "#3470B1" &&
                customColorArrays[0].hyperlinkColor == "#3470B1" &&
                textColor == "black" ? true : false;

            const args = {
                companyId: user.company_master_id,
                defaultCustomBanner: croppedImages,
                defaultCustomColor: customColorArrays,
                textColor: textColor,
                textposition: filteredJson,
                bannerImgWithText: savedImage,
                reset: flag
            };

            const apiUrl = '/createCobrandCustomize';
            
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    if (value == 1) {
                        publishCobrandDetails()
                    }
                    else {
                        handleFreezStop()
                        swal({
                            title: "Success",
                            text: 'Details Saved As Draft',
                            icon: "success",
                            button: "Ok",
                        })
                    }
                } else {
                    alert("something went wrong !");
                    handleFreezStop()
                }
            }
        }
        else {
            return false
        }
    };
    const publishCobrandDetails = async () => {
        const apiUrl = '/publishCobrandCustomize/' + user.company_master_id + '/' + aidUser.id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success === true) {
                getCobrandSubmitedDetails()
                handleFreezStop()
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
                setPublishBannerBtn(false)
            }
            else {
                alert("something went wrong !");
            }
        }
    }
    useEffect(() => {
        getCobrandSubmitedDetails()
        checkIsLogIn()
    }, [])
    const getCobrandSubmitedDetails = async () => {
        handleFreezStart()
        const apiUrl = '/cobrandCustomizeDetails';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.data) {
                if (res.data.originalImagesLog && res.data.originalImagesLog.length > 0) {
                    const modifiedImagesLog = res.data.originalImagesLog.map(item => item);
                    // Create a Set to store unique records
                    const uniqueRecords = new Set();
                    // Filter out duplicates and add to the set
                    modifiedImagesLog.forEach(item => uniqueRecords.add(JSON.stringify(item)));
                    // Convert back to array of objects
                    const uniqueRecordsArray = Array.from(uniqueRecords).map(item => JSON.parse(item));
                    setOriginalCroppedImgs(uniqueRecordsArray);
                }
                else {
                    setOriginalCroppedImgs([]);
                }
                let banner1 = res.data.default_image_base64_1 && res.data.default_setting == 0 ? res.data.default_image_base64_1 : '';
                let banner2 = res.data.default_image_base64_2 && res.data.default_setting == 0 ? res.data.default_image_base64_2 : '';
                let banner3 = res.data.default_image_base64_3 && res.data.default_setting == 0 ? res.data.default_image_base64_3 : ''

                let bannerImgWithText1 = res.data.hero_image_1 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_1 : '';
                let bannerImgWithText2 = res.data.hero_image_2 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_2 : '';
                let bannerImgWithText3 = res.data.hero_image_3 ? config.BACKEND_URL + "images/bannerImages/" + res.data.hero_image_3 : ''
                if (!imgSrc) {
                    setCroppedImages(
                        [
                            { step: 1, url: banner1, },
                            { step: 2, url: banner2, },
                            { step: 4, url: banner3, }
                        ]
                    );
                }
                // img with text iamge
                setSavedImage([
                    { step: 1, url: bannerImgWithText1, },
                    { step: 2, url: bannerImgWithText2, },
                    { step: 4, url: bannerImgWithText3, }
                ])
                setOriginalImgsArray([
                    { step: 1, url: banner1, },
                    { step: 2, url: banner2, },
                    { step: 4, url: banner3, }])


                if (res.data.text_position) {
                    setTextElementsByStep(res.data.text_position);
                }
                else {
                    setTextElementsByStep({});
                }
                setCustomColorArrays(
                    [
                        { bodyBackground: res.data.body_background_color && res.data.default_setting == 0 ? res.data.body_background_color : customColorArrays[0].bodyBackground, primaryColor: res.data.primary_color && res.data.default_setting == 0 ? res.data.primary_color : customColorArrays[0].primaryColor, hyperlinkColor: res.data.hyper_link_color && res.data.default_setting == 0 ? res.data.hyper_link_color : customColorArrays[0].hyperlinkColor },
                    ])
                setTextColor(res.data.text_color && res.data.default_setting == 0 ? res.data.text_color : 'black')
                getContrastText(res.data.primary_color && res.data.default_setting == 0 ? res.data.primary_color : customColorArrays[0].primaryColor)
                handleFreezStop()
            }
            else {
                handleFreezStop()
                setCustomColorArrays(defaultCustomColor)
                setCroppedImages(defaultBanner)
                setSavedImage(defaultBanner)
                setOriginalImgsArray(defaultBanner)
                setTextColor('black')
            }
        }
    }
    useEffect(() => {
        const indexToUpdate = stepcount === 4 ? stepcount - 2 : stepcount - 1;
        setTemImg(originalImgsArray[indexToUpdate])
        if (completedCrop && imgRef.current) {
            const canvas = document.createElement('canvas');
            const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
            const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
            canvas.width = completedCrop.width * scaleX;
            canvas.height = completedCrop.height * scaleY;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
                imgRef.current,
                completedCrop.x * scaleX,
                completedCrop.y * scaleY,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY,
                0,
                0,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY
            );
            let croppedImageUrl = canvas.toDataURL('image/webp');
            const updatedCroppedImagesArrays = [...croppedImages];
            const updatedSavedImagesArrays = [...savedImage];
            const updatedCroppedImageArray = { step: stepcount, url: croppedImageUrl, flag: 1 };
            updatedCroppedImagesArrays[indexToUpdate] = updatedCroppedImageArray;
            updatedSavedImagesArrays[indexToUpdate] = updatedCroppedImageArray;
            setCroppedImages(updatedCroppedImagesArrays);
            setSavedImage(updatedSavedImagesArrays)
        }
    }, [completedCrop]);
    const getPopupClosed = () => {
        setOpenImgPopup(false);
        const indexToUpdate = stepcount === 4 ? stepcount - 2 : stepcount - 1;
        // Make a copy of the current cropped images array
        const updatedCroppedImagesArrays1 = [...originalImgsArray];

        // Replace the newly added image at the specified index with the original image
        updatedCroppedImagesArrays1[indexToUpdate] = tempImg;

        // Update the state with the modified array of cropped images
        setCroppedImages(updatedCroppedImagesArrays1);
        setSavedImage(updatedCroppedImagesArrays1)
    }
    const getCroppedImg = () => {
        let thumbnilImgFlag = thumbnilClick == true ? true : false
        const indexToUpdate = stepcount === 4 ? stepcount - 2 : stepcount - 1;
        setOpenImgPopup(false)
        // Map over croppedImages array to add originalImg property to each object
        const updatedNewImages = croppedImages.map((image, index) => {

            if (index === indexToUpdate) {
                // Add originalImg property only to the currently active step
                return {
                    ...image,
                    originalImg: imgSrc,// Assuming imgSrc is defined somewhere in your code
                    thumbnilImgFlag: thumbnilImgFlag,
                    originalFileName: originalImgFileName
                };
            } else {
                // For other steps, keep the image object unchanged
                return image;
            }
        });
        setThumbnilClick(false)
        setCroppedImages(updatedNewImages);
        setOriginalImgsArray(updatedNewImages)

    }
    const onImageLoad = () => {
        const imageWidth = imgRef.current.width;
        const imageHeight = imgRef.current.height;
        let cropWidth, cropHeight, cropX, cropY;
        // Calculate crop dimensions based on aspect ratio
        if (imageWidth / imageHeight > imageAspectRatio) {
            cropHeight = imageHeight;
            cropWidth = cropHeight * imageAspectRatio;
        } else {
            cropWidth = imageWidth;
            cropHeight = cropWidth / imageAspectRatio;
        }
        // Calculate initial position to center the crop area
        cropX = (imageWidth - cropWidth) / 2;
        cropY = (imageHeight - cropHeight) / 2;

        // Set crop and completedCrop state
        setCrop({
            unit: 'px',
            x: cropX,
            y: cropY,
            width: cropWidth,
            height: cropHeight
        });

        setCompletedCrop({
            unit: 'px',
            x: cropX,
            y: cropY,
            width: cropWidth,
            height: cropHeight
        });
    }
    const previewNextBtn = () => {
        setTemplateValue(templateValue + 1)
    }
    const previewPreviousBtn = () => {
        setTemplateValue(templateValue - 1)
    }
    const getPreviwPopup = () => {
        let loginValue = checkIsLogIn()
        if (loginValue == true) {
            setOpenPreviewPopup(true);
            setTemplateValue(1)
        } else {
            return false
        }
    }
    const resetToDefault =async () => {
        let loginValue = checkIsLogIn()
        if (loginValue == true) {
            setSavedImage(defaultBanner)
            setTextElementsByStep({})
            setOpenResetPopup(false)
            setCroppedImages(defaultBanner)
            setOriginalImgsArray(defaultBanner)
            setCustomColorArrays(defaultCustomColor)
            setTextColor('black')
            const args = {
                companyId: user.company_master_id,
                defaultCustomBanner: defaultBanner,
                defaultCustomColor: defaultCustomColor,
                textColor: 'black',
                textposition: {},
                bannerImgWithText: defaultBanner,
                reset: true,
            };

            const apiUrl = '/createCobrandCustomize';
            
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    handleFreezStop()
                    swal({
                        title: "Success",
                        text: 'Default setting has been restored successfully',
                        icon: "success",
                        button: "Ok",
                    })
                } else {
                    alert("something went wrong !");
                    handleFreezStop()
                }
            }
        }
        else {
            return false
        }

    }
    const deleteUploadImg =async () => {
        setDeleteUploadImgPopup(false)
        handleFreezStart()
        const apiUrl = '/deleteCustomizeImage';
        const args = {
			companyId: user.company_master_id,
            fileName: deleteFileName
		}
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            getCobrandSubmitedDetails()
            handleFreezStop()
            if (res.success === true) {
                swal({
                    title: "Success",
                    text: 'Image deleted successfully',
                    icon: "success",
                    button: "Ok",
                })
            } else {
                alert("something went wrong !");
            }
        }
    }
    const getContrastText = (color) => {
        // Convert background color to RGB
        const rgb = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
        const r = parseInt(rgb[1], 16);
        const g = parseInt(rgb[2], 16);
        const b = parseInt(rgb[3], 16);

        // Calculate brightness using the formula (r * 299 + g * 587 + b * 114) / 1000
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        // If brightness is greater than 125, return black, else return white
        brightness > 125 ? setBtnTextColor('black') : setBtnTextColor('white');

    };
    const checkIsLogIn = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser) {
            if (loginUser.roles_id != 3) {
                navigate('/login');
                return false;
            }
            else {
                return true
            }
        }
        else {
            navigate('/login');
            return false;
        }
    }

    useEffect(() => {
        const sortedFonts = [...fontJson.fonts].sort((a, b) => a.localeCompare(b));
        setFontFamilies(sortedFonts);

        // Dynamically load Google Fonts
        sortedFonts.forEach(font => {
            const link = document.createElement('link');
            link.href = `https://fonts.googleapis.com/css2?family=${font.replace(' ', '+')}&display=swap`;
            link.rel = 'stylesheet';
            document.head.appendChild(link);
        });

        // Clean up function to remove the dynamically added <link> elements when component unmounts
        return () => {
            sortedFonts.forEach(font => {
                const link = document.querySelector(`link[href*="${font.replace(' ', '+')}"]`);
                if (link) {
                    document.head.removeChild(link);
                }
            });
        };
    }, []);

    useEffect(() => {
        // Example of updating text style when fontFamilies changes
        if (fontFamilies.length > 0) {
            setTextStyle(prevStyle => ({
                ...prevStyle,
                fontFamily: fontFamilies[0] // Set the initial font style
            }));
        }
    }, [fontFamilies]);




    const addText = () => {
        setShowText(true)
        const currentStepTextElements = textElementsByStep[stepcount] || [];
        const imageHeight = imageRef.current.clientHeight; // Height of the image container
        const imageWidth = imageRef.current.clientWidth; // Width of the image container

        const textElementHeight = 20; // Height of each text element, adjust as necessary
        const textElementWidth = 100; // Width of each text element, adjust as necessary
        const initialSpacing = 20; // Initial spacing at the beginning, adjust as necessary

        // Calculate positions of all text elements in a 2D array
        const rows = Math.floor((imageHeight - initialSpacing) / textElementHeight);
        const cols = Math.floor(imageWidth / textElementWidth);
        const positions = Array.from({ length: rows }, () => Array.from({ length: cols }, () => false));

        // Mark the occupied positions
        currentStepTextElements.forEach(textElement => {
            const row = Math.floor((textElement.position.y - initialSpacing) / textElementHeight);
            const col = Math.floor(textElement.position.x / textElementWidth);
            if (row >= 0 && row < rows && col >= 0 && col < cols) {
                positions[row][col] = true;
            }
        });

        // Find the next available position
        let newPosition = null;
        for (let col = 0; col < cols; col++) {
            for (let row = 0; row < rows; row++) {
                if (!positions[row][col]) {
                    newPosition = { x: col * textElementWidth, y: initialSpacing + row * textElementHeight };
                    break;
                }
            }
            if (newPosition) break;
        }

        // If a new position is found, add the new text element
        if (newPosition) {
            const newTextElement = {
                ...textStyle,
                id: Date.now(),
                position: newPosition,
            };

            setTextElementsByStep({
                ...textElementsByStep,
                [stepcount]: [...currentStepTextElements, newTextElement],
            });
            setFocusedTextElementIndex(currentStepTextElements.length);
            setShowText(true);
        } else {
            // Handle the case where there is no more space available
            console.warn('No space available to add a new text element.');
        }
    };
    const handleTextChange = (index, newText) => {
        // Save the current selection range
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            setCursorPosition({
                startContainer: range.startContainer,
                startOffset: range.startOffset,
                endContainer: range.endContainer,
                endOffset: range.endOffset
            });
        }

        // Update the text elements
        const currentStepTextElements = [...(textElementsByStep[stepcount] || [])];
        currentStepTextElements[index].text = newText;

        // Update the state with new text
        setTextElementsByStep({
            ...textElementsByStep,
            [stepcount]: currentStepTextElements,
        });
    };
    useEffect(() => {
        // Restore cursor position after render
        if (cursorPosition) {
            const { startContainer, startOffset, endContainer, endOffset } = cursorPosition;
            const selection = window.getSelection();
            if (selection) {
                const range = document.createRange();
                range.setStart(startContainer, startOffset);
                range.setEnd(endContainer, endOffset);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }, [cursorPosition]);
    const calculateMaxWidth = (x, y) => {
        const imageWidth = imageRef.current?.offsetWidth;
        const remainingWidth = imageWidth - x; // Adjust for the 10px gap
        return `${remainingWidth}px`; // Set the maxWidth to the remaining width
    };
    const calculateMaxHeight = (y) => {
        const imageHeight = imageRef.current?.offsetHeight;
        const remainingHeight = imageHeight - y - 20; // Adjust for the 10px gap
        return `${remainingHeight}px`; // Set the maxHeight to the remaining height
    };
    const loadFontChange = (font) => {
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css2?family=${font.replace(/ /g, '+')}&display=swap`;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    };
    const groupFontsByLetter = (fonts) => {
        return fonts.reduce((acc, font) => {
            const letter = font[0].toUpperCase();
            if (!acc[letter]) acc[letter] = [];
            acc[letter].push(font);
            return acc;
        }, {});
    };

    const groupedFonts = groupFontsByLetter(fontFamilies);
    let debounceTime;
    const debouncedHandleStyleChange = debounce((styleProp, value) => {
        debounceTime = (styleProp !== 'fontColor' && styleProp !== 'backgroundColor') ? 0 : 100;
        if (styleProp === 'fontFamily') {
            loadFontChange(value);
        }

        if (focusedTextElementIndex !== null) {
            const currentStepTextElements = textElementsByStep[stepcount] || [];
            currentStepTextElements[focusedTextElementIndex][styleProp] = value;
            setTextElementsByStep({
                ...textElementsByStep,
                [stepcount]: [...currentStepTextElements],
            });
        }
    }, debounceTime);
    const handleStyleChange = (styleProp, value) => {
        debouncedHandleStyleChange(styleProp, value);
    };
    const handleStart = (e, data) => {
        if (!data || !data.node) {
            return; // Ensure data and data.node are defined
        }
        setDragging(true);
        const node = data.node;
        const rect = node.getBoundingClientRect();
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const rightBottomCornerX = rect.right - 20;
        const rightBottomCornerY = rect.bottom - 20;

        if (mouseX >= rightBottomCornerX && mouseY >= rightBottomCornerY) {
            return false; // Prevent dragging
        } else {
            return true; // Allow dragging
        }
    };
    const handleDrag = (index, e, data) => {
        const currentStepTextElements = [...(textElementsByStep[stepcount] || [])];
        const updatedElement = { ...currentStepTextElements[index], position: { x: data.x, y: data.y } };
        currentStepTextElements[index] = updatedElement;

        setTextElementsByStep({
            ...textElementsByStep,
            [stepcount]: currentStepTextElements
        });
    };
    const handleFocusText = (index) => {
        setShowText(true)
        if (!dragging) {
            setEditingIdx(index);
        }

        setFocusedTextElementIndex(index);
    };
    const handleBlurText = () => {
        setEditingIdx(null);
    };
    const handleTouchStart = (idx) => {
        setEditingIdx(idx);
        setDragging(false);
    };

    const handleStop = () => {
        setDragging(false);
    };

    const removeTextElement = (indexToRemove) => {
        const currentStepTextElements = textElementsByStep[stepcount] || [];
        // Check if the text at indexToRemove is empty and set flag accordingly
        let flag = false;
        if (currentStepTextElements[indexToRemove]?.text.trim() === '') {
            flag = true;
        }
        const updatedTextElements = currentStepTextElements.filter((_, idx) => idx !== indexToRemove);
        setTextElementsByStep({
            ...textElementsByStep,
            [stepcount]: updatedTextElements,
        });
        if (flag == false) {
            setTimeout(() => {
                saveImage(indexToRemove);

            }, 1000);
        }
    };
    const saveImage = (value) => {
        setShowText(false)
        const indexToUpdate = stepcount === 4 ? stepcount - 2 : stepcount - 1;

        // Temporarily hide the cross icon during capture
        const crossIcons = document.querySelectorAll('.remove-icon');
        crossIcons.forEach(icon => icon.classList.add('no-icon'));
        // Temporarily remove the borders of text elements
        const textElements = document.querySelectorAll('.draggable-text');
        textElements.forEach(element => element.classList.add('no-border'));
        // Iterate over each text container and check if its corresponding text element has empty text
        const textContainers = document.querySelectorAll('.text-element-container');
        const emptyContainers = [];
        textContainers.forEach(container => {
            const textElement = container.querySelector('.draggable-text');
            if (textElement && textElement.innerText.trim() === '') {
                emptyContainers.push(container);
                container.style.display = 'none'; // Hide only the text container with empty text
            }
        });
        // Capture the image using html2canvas
        html2canvas(imageContainerRef.current).then(canvas => {
            const imgData = canvas.toDataURL('image/webp');
            const updatedCroppedImages = [...savedImage];
            const updatedCroppedImage = { step: stepcount, url: imgData, flag: 1 };
            updatedCroppedImages[indexToUpdate] = updatedCroppedImage;
            setSavedImage(updatedCroppedImages);
            crossIcons.forEach(icon => icon.classList.remove('no-icon'));
            textElements.forEach(element => element.classList.remove('no-border'));
            if (value !== null) {
                emptyContainers.forEach(container => {
                    container.style.display = '';
                });
            }
        });
    };
    const currentStepTextElements = textElementsByStep[stepcount] || [];
    const ContentLayout =
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Typography level="h4" color="primary" pb={2}>Select Screen to Customize</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={4} className='max-content-width'>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'block' }}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} >
                            {steps.map((label, index) => (
                                <Step key={label} sx={{ display: index === 2 || index === 4 ? 'none' : 'block' }}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon} icon={index + 1} onClick={(e) => { handleStepClick(e, index) }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                    </Grid>

                    <Grid container item lg={6} md={6} sm={12} xs={12} mt={2}>
                        {showText && focusedTextElementIndex !== null && currentStepTextElements[focusedTextElementIndex] && (
                            <Grid container item lg={12} md={12} sm={12} xs={12} className='text-formater' >
                                <Grid item lg={9} md={9} sm={9} xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <button
                                        onClick={() => handleStyleChange('bold', !currentStepTextElements[focusedTextElementIndex].bold)}
                                        title="Bold"
                                        style={{ background: currentStepTextElements[focusedTextElementIndex].bold ? '#e1dcdc' : 'white', margin: '3px', width: '2vw', height: '2vw' }}
                                    >
                                        <FaBold />
                                    </button>
                                    <button
                                        onClick={() => handleStyleChange('italic', !currentStepTextElements[focusedTextElementIndex].italic)}
                                        title="Italic"
                                        style={{ background: currentStepTextElements[focusedTextElementIndex].italic ? '#e1dcdc' : 'white', margin: '3px', width: '2vw', height: '2vw' }}
                                    >
                                        <FaItalic />
                                    </button>
                                    <button title="Font Size" className='select-editable' style={{ margin: '3px' }}>
                                    <Select
                                            className='textFormat-dropdown-border1'
                                            id="font-size-dropdown"
                                            value={currentStepTextElements[focusedTextElementIndex].fontSize}
                                            onChange={(e) => handleStyleChange('fontSize', parseInt(e.target.value))}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 320, 
                                                        maxWidth:50
                                                    },
                                                },
                                            }}
                                        >
                                            {fontSizes.map((size, index) => (
                                                <MenuItem key={index} value={size}>{size}</MenuItem>
                                            ))}
                                        </Select>
                                        <input
                                            type="number"
                                            value={currentStepTextElements[focusedTextElementIndex].fontSize}
                                            onChange={(e) => handleStyleChange('fontSize', parseInt(e.target.value))}
                                        />
                                    </button>
                                    <button title="Font Family" className=' select-editable1' style={{ margin: '3px', background: 'white', height: '2vw' }}>

                                        <Select
                                            labelId="fontFamily-dropdown-label"
                                            id="fontFamily-dropdown"
                                            value={currentStepTextElements[focusedTextElementIndex].fontFamily || ''}
                                            onChange={(e) => handleStyleChange('fontFamily', e.target.value)}
                                            input={<OutlinedInput label="Font Family" />}
                                            MenuProps={MenuProps}
                                            className='textFormat-dropdown-border0'
                                        >
                                            {Object.keys(groupedFonts).map((letter) => [
                                                <ListSubheader key={`${letter}-header`} className='fontFamilyTitle'>{letter}</ListSubheader>,
                                                ...groupedFonts[letter].map((family, index) => (
                                                    <MenuItem key={`${letter}-${index}`} value={family} style={{ fontFamily: family }}>
                                                        {family}
                                                    </MenuItem>
                                                ))
                                            ])}
                                        </Select>
                                        <input
                                            type="text"
                                            value={currentStepTextElements[focusedTextElementIndex].fontFamily}
                                            onChange={(e) => handleStyleChange('fontFamily', e.target.value)}
                                        />
                                    </button>
                                    <input
                                        title="Text Color"
                                        type="color"
                                        value={currentStepTextElements[focusedTextElementIndex].fontColor}
                                        onChange={(e) => handleStyleChange('fontColor', e.target.value)}
                                        className='color-picker-css'
                                    />
                                    <input
                                        title="Background Color"
                                        type="color"
                                        value={currentStepTextElements[focusedTextElementIndex].backgroundColor}
                                        onChange={(e) => handleStyleChange('backgroundColor', e.target.value)}
                                        className='color-picker-css'
                                    />
                                </Grid>

                                <Grid item lg={3} md={3} sm={3} xs={3}>  <Button onClick={() => { saveImage(null) }} title='Apply Changes' variant="contained" color="success" style={{ marginLeft: '10px' }}>Apply</Button></Grid>

                            </Grid>
                        )}
                    </Grid>
                    <Card sx={{ width: '100%', border: 20, borderColor: "grey", borderRadius: 6, background: `${customColorArrays[0].bodyBackground}` }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ alignItems: 'center', width: '100%', position: 'relative', margin: 'auto',display:'flex',justifyContent:'center' }}
                            >
                                {
                                    croppedImages.map((item, index) => {
                                        if (item.step == stepcount && item.url) {
                                            // If step matches and URL is present, render the image
                                            return (
                                                    <div className='preview-main-image' key={index} style={{ position: 'relative' }}>
                                                        <div style={{display:'flex'}}>
                                                            <div style={{ display: 'block', zIndex: 1 }} ref={imageContainerRef}>
                                                                <img ref={imageRef} src={item.url} alt="Image" style={{ pointerEvents: 'none',width: '100%',height: '100%' }} />
                                                                {currentStepTextElements.map((textElement, idx) => (
                                                                    <Draggable bounds="parent" disabled={editingIdx === idx} key={textElement.id} position={textElement.position} onDrag={() => { setEditingIdx(null); handleStart() }} onStop={(e, data) => { handleDrag(idx, e, data); handleStop() }}>
                                                                        <div
                                                                            ref={textElementRef}
                                                                            className="text-element-container"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: 0,
                                                                                left: 0,
                                                                                maxWidth: calculateMaxWidth(textElement.position.x, textElement.position.y),
                                                                                maxHeight: calculateMaxHeight(textElement.position.y),
                                                                                wordWrap: 'break-word',
                                                                                cursor: 'move',
                                                                                padding: '10px'
                                                                            }}>
                                                                            <div
                                                                                className="draggable-text"
                                                                                contentEditable
                                                                                suppressContentEditableWarning={true}
                                                                                onInput={(e) => handleTextChange(idx, e.target.innerText)}
                                                                                onClick={() => { handleFocusText(idx) }}
                                                                                data-placeholder={textElement.placeholder}
                                                                                onTouchStart={() => handleTouchStart(idx)}
                                                                                onFocus={() => handleTouchStart(idx)}
                                                                                onBlur={handleBlurText}
                                                                                style={{
                                                                                    cursor: editingIdx === idx ? 'text' : 'move',
                                                                                    padding: '5px',
                                                                                    fontWeight: textElement.bold ? 'bold' : 'normal',
                                                                                    fontStyle: textElement.italic ? 'italic' : 'normal',
                                                                                    fontSize: `${textElement.fontSize}px`,
                                                                                    color: textElement.fontColor,
                                                                                    fontFamily: textElement.fontFamily,
                                                                                    backgroundColor: textElement.backgroundColor,
                                                                                }}
                                                                                title='Drag or Edit text'
                                                                            >
                                                                                {textElement.text || ''}
                                                                            </div>
                                                                            <div className="remove-icon" style={{
                                                                                position: 'absolute',
                                                                                top: -2,
                                                                                right: -1,
                                                                                cursor: 'pointer'
                                                                            }} onClick={(e) => {
                                                                                removeTextElement(idx);
                                                                            }}
                                                                                onTouchStart={(e) => {
                                                                                    e.stopPropagation(); // Prevent triggering drag
                                                                                    removeTextElement(idx);
                                                                                }}>❌</div>
                                                                        </div>
                                                                    </Draggable>
                                                                ))}
                                                            </div>
                                                            <div className='editor-wrapper'>
                                                                <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }} >

                                                                    <Grid><Button title='Change Banner' onClick={() => { setSelectedImgPoup(true) }}><img width="24" height="24" src={require("../../assets/icons/gallery-icon.png")} alt="picture" /></Button></Grid>
                                                                    <Grid item lg={1} md={1} sm={12} xs={12}
                                                                        container
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                    >
                                                                        <Divider orientation="vertical" flexItem style={{ width: "2px", background: 'gray', margin: '3px 0px' }} />
                                                                    </Grid>
                                                                    <Grid><Button title='Add Text' onClick={addText}><img width="24" height="24" src={require("../../assets/icons/text-icon.png")} alt="text" /></Button></Grid>

                                                                </Grid>
                                                            </div>
                                                        </div>

                                                    </div>
                                            );
                                        } else if (item.step == stepcount) {
                                            return (
                                                <div style={{ borderRadius: '5px' }} className='preview-wrapper-image1' key={index} onClick={() => { setSelectedImgPoup(true) }}>
                                                    <Grid
                                                        sx={{ margin: 'auto', cursor: 'pointer' }}
                                                    >
                                                        <Grid item container lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mb={2}
                                                        >
                                                            <Card sx={{ borderStyle: "dashed", width: "100%", opacity: 0.5 }} >
                                                                <img src={defaultBannerImg} />
                                                            </Card>
                                                        </Grid>
                                                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center", position: 'absolute', top: '31%' }} className='imgUploadText'>
                                                            <Typography sx={{ fontWeight: "bold", fontSize: "1rem", textAlign: 'center' }} >Click to Upload <br /> Image {stepcount === 4 ? stepcount - 1 : stepcount}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            );
                                        } else { return null }
                                    })
                                }
                            </Grid>
                            <Grid item lg={1} md={1} sm={12} xs={12}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Divider orientation="vertical" flexItem style={{ width: "3px", background: `${customColorArrays[0].primaryColor}` }} />
                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {activeStep === 0 && <Step1 btnTextColor={btnTextColor} primaryColor={customColorArrays[0].primaryColor} textColorProp={textColor} hyperlinkColor={customColorArrays[0].hyperlinkColor} />}
                                    {activeStep === 1 && <Step2 btnTextColor={btnTextColor} primaryColor={customColorArrays[0].primaryColor} textColorProp={textColor} hyperlinkColor={customColorArrays[0].hyperlinkColor} />}
                                    {activeStep === 3 && <Step4 btnTextColor={btnTextColor} primaryColor={customColorArrays[0].primaryColor} textColorProp={textColor} hyperlinkColor={customColorArrays[0].hyperlinkColor} />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12} sx={{ height: 'auto' }}>
                    <Card sx={{ boxShadow: '0px 0px 10px #888888', height: '100%' }}>
                        <CardOverflow sx={{ bgcolor: '#F6FAFF', borderColor: '#F6FAFF', padding: '1% !imoportant', }}>
                            <Typography gutterBottom color="primary" variant='h5'><b>Set up universal colors</b></Typography>
                        </CardOverflow>
                        <CardContent>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'block', width: '100%' }} pt={3} pb={2}>

                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    {customColorArrays?.map((colorArray, index) => (
                                        <div key={index}>
                                            <CobrandColorPicker
                                                title="Background Color"
                                                blockId="bodyBackground"
                                                blockName="bodyBackground"
                                                value={colorArray.bodyBackground}
                                                customColorArray={colorArray}
                                                handleValue={(colorArray) => handleValue(colorArray)}
                                                openColorPicker={openColorPicker}
                                                toggleColorPicker={toggleColorPicker}
                                            />

                                            <CobrandColorPicker
                                                title="Primary Color"
                                                blockId="primaryColor"
                                                blockName="primaryColor"
                                                value={colorArray.primaryColor}
                                                customColorArray={colorArray}
                                                handleValue={(colorArray) => handleValue(colorArray)}
                                                openColorPicker={openColorPicker}
                                                toggleColorPicker={toggleColorPicker}
                                            />

                                            <CobrandColorPicker
                                                title="Hyperlink Color"
                                                blockId="hyperlinkColor"
                                                blockName="hyperlinkColor"
                                                value={colorArray.hyperlinkColor}
                                                customColorArray={colorArray}
                                                handleValue={(colorArray) => handleValue(colorArray)}
                                                openColorPicker={openColorPicker}
                                                toggleColorPicker={toggleColorPicker}
                                            />
                                        </div>
                                    ))}
                                </Grid>

                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={7} md={7} sm={7} xs={7}>
                                        <label htmlFor='textColor1' style={{ fontWeight: "bold", marginRight: "10px" }}>
                                            Text Color
                                        </label>
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={5} xs={5}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            id="textColor1"
                                            // name="textColor"
                                            value={textColor}
                                            onChange={setTextColorValue}
                                        >
                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <FormControlLabel value="black" control={<Radio />} label={
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ width: 13, height: 13, backgroundColor: 'black', marginRight: 5 }} className='color-indicator-box'></div>

                                                        </div>
                                                    } />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <FormControlLabel value="white" control={<Radio />} label={
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ width: 13, height: 13, backgroundColor: 'white', marginRight: 5, border: '1px solid #aaa' }} className='color-indicator-box'></div>

                                                        </div>
                                                    } />
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }} mt={4} mb={3}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" color="primary"
                                            // disabled={!publishBannerBtn}
                                            onClick={getPreviwPopup}
                                            sx={{ minWidth: '120px' }}
                                            className='cobrand-btn'>
                                            Preview
                                        </Button>

                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: 'end' }}>
                                        <Button variant="contained" color="success"
                                            onClick={() => setOpenPublishPopup(true)}
                                            sx={{ minWidth: '120px' }}
                                            className='cobrand-btn'>
                                            Publish
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" color="warning"
                                            onClick={() => setOpenResetPopup(true)}
                                            sx={{ minWidth: '120px' }}
                                            className='cobrand-btn'>
                                            RESET
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ textAlign: 'end' }}>
                                        <Button type="submit" color="primary" variant="contained" onClick={() => { onSubmitStyleChanges(0) }} sx={{
                                            borderRadius: "5px", minWidth: '120px'
                                        }} className='cobrand-btn'>Save Draft</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {
                imgSrc &&
                <Dialog
                    open={openImgPopup}
                    fullWidth
                    maxWidth={'sm'}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            setOpenImgPopup(false);
                            setThumbnilClick(false)
                        }
                    }}
                >
                    <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                        <Typography level="h4" sx={{ textAlign: "center" }}>Crop the selected Image</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={getPopupClosed}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent >
                        <div style={{ display: 'flex', justifyContent: 'center' }} className='react-crop-img'>
                            {imgSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(crop, percentCrop) => setCrop(crop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    aspect={imageAspectRatio}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            )}
                        </div>
                        <div style={{ width: '100%', marginTop: '3%' }}>
                            {originalCroppedImgs.length > 0 ?
                                <Card sx={{ justifyContent: 'center', width: ' 100%', overflowX: 'auto', overflowY: 'hidden', display: 'flex' }}>
                                    <CardOverflow sx={{ bgcolor: '#F6FAFF', borderColor: '#F6FAFF', padding: '1% !imoportant', }}>
                                        <Typography gutterBottom color="primary" variant='h4'><b>Your Uploads</b></Typography>
                                    </CardOverflow>
                                    <CardContent sx={{ width: 'fit-content' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2%' }}>
                                            {originalCroppedImgs.map((item, index) => (
                                                <div style={{ position: 'relative' }}>
                                                    <Paper onClick={() => { setImgSrc(item.base64); setThumbnilClick(true); setOriginalImgFileName(item.fileName) }} key={index} elevation={3} sx={{ width: '90px', height: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '25px' }}>
                                                        <img src={item.base64} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                    </Paper>
                                                    <div className='upload-img-deleticon'>
                                                        <img src={require("../../assets/icons/delete.png")} onClick={() => { setDeleteUploadImgPopup(true); setDeleteFileName(item.fileName) }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CardContent>
                                </Card> : null}


                            <Card sx={{ marginTop: '5%' }}>
                                <CardOverflow sx={{ bgcolor: '#F6FAFF', borderColor: '#F6FAFF', padding: '1% !imoportant', }}>
                                    <Typography gutterBottom color="primary" variant='h4'><b>Default Images</b></Typography>
                                </CardOverflow>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        {defaultCustomBanner1.map((item, index) => (
                                            <Paper onClick={() => { setImgSrc(item.url) }} key={index} elevation={3} sx={{ width: '90px', height: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '25px' }}>
                                                <img src={item.url} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                            </Paper>
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </DialogContent>

                    <DialogActions sx={{ margin: 'auto' }}>

                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Button variant="contained" onClick={getCroppedImg} color="success" sx={{ minWidth: 250 }}>
                                Apply
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            }
            <Dialog
                fullScreen
                open={openPreviewPopup}
                TransitionComponent={Transition}
            >
                <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'center' }} className='preview-box-wrapper'>
                        <Grid >
                            <Button variant="contained" onClick={previewPreviousBtn} disabled={templateValue == 1 ? true : false} color="success" sx={{ minWidth: 250 }}>
                                Previous
                            </Button>
                        </Grid>
                        <Typography level="h4" sx={{ textAlign: "center", padding: '0% 4%' }}>Page Preview</Typography>
                        <Grid  >
                            <Button variant="contained" onClick={previewNextBtn} disabled={templateValue == 5 ? true : false} color="success" sx={{ minWidth: 250 }}>
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                    <Button variant='contained' color="primary" onClick={() => setOpenPreviewPopup(false)} className='preview-close-btn'>Close</Button>
                </DialogTitle>
                <DialogContent sx={{ margin: 'auto', marginTop: '10px' }} >
                    <CustomizeEnrollIndex templateValue={templateValue} cobrandFlag={openPreviewPopup ? true : false} croppedImages={savedImage} customColorArrays={customColorArrays} textColor={textColor} btnTextColor={btnTextColor} />
                </DialogContent>
                <DialogActions sx={{ margin: 'auto' }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} columnSpacing={3}>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openResetPopup}
                fullWidth
                maxWidth={'sm'}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setOpenResetPopup(false);
                    }
                }}
            >
                <DialogTitle >
                    <Typography level="h4" sx={{ textAlign: "center" }}>Do you want to restore the default settings?</Typography>

                </DialogTitle>

                <DialogActions sx={{ margin: 'auto' }} >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Button variant="contained" onClick={resetToDefault} color="success" sx={{ minWidth: 250 }} >
                            Yes
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Button variant="contained" onClick={() => setOpenResetPopup(false)} color="primary" sx={{ minWidth: 250 }}>
                            Cancel
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openPublishPopup}
                fullWidth
                maxWidth={'xl'}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setOpenPublishPopup(false);
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                    <Typography level="h4" sx={{ textAlign: "center" }}>Page Preview</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenPublishPopup(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ margin: 'auto', marginTop: '10px' }} >
                    {
                        savedImage.map((items, index) => {
                            let activeStepPopupIndex;
                            if (index === 0 || index === 1) {
                                activeStepPopupIndex = 0; // For the first and second card, show the first step button active
                            } else {
                                activeStepPopupIndex = 2; // For the third card, show the third step button active
                            }
                            return (
                                <Card className='cardstyle cobrand-card-wrapper'  key={index} style={{ background: `${customColorArrays[0].bodyBackground}`, padding: '0px', marginBottom: '10px', borderRadius: '0px' }} id="bodyBackground">
                                    <CardContent>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} className='row-reverse1'>
                                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: 'flex' }} className="CobrandImgBanner-wrapper">
                                                <img key={items.step} src={items.url ? items.url : defaultCustomBanner1[index].url} className="CobrandImgBanner" />
                                            </Grid>

                                            <Divider orientation="vertical" flexItem style={{ width: "3px", background: `${customColorArrays[0].primaryColor}` }} />

                                            <Grid item lg={5} md={5} sm={12} xs={12} sx={{ padding: '0% 1%', margin: 'auto' }}>
                                                <Grid item lg={12} md={12} sm={12} xs={12} pb={2} style={{ margin: '0px', paddingTop: '2%' }} className='stepper-css cobrand-popup-wrapper'>

                                                    <Stepper
                                                        activeStep={activeStepPopupIndex}
                                                        connector={<CustomStepConnector />}

                                                    >
                                                        {steps1.map((label, index1) => (
                                                            <Step key={label}
                                                                sx={{ padding: '0px', "& .MuiStepIcon-root": { color: index1 === activeStepPopupIndex ? 'white !important' : customColorArrays[0].primaryColor }, "& .MuiStepIcon-text": { fill: index1 === activeStepPopupIndex ? 'black' : 'white' }, "& .MuiStep-root .MuiStepIcon-root": { color: index1 === activeStepPopupIndex ? 'white !important' : customColorArrays[0].primaryColor } }}

                                                            >
                                                                <StepButton
                                                                    style={{ borderColor: `${customColorArrays[0].primaryColor}`, background: index1 === activeStepPopupIndex ? customColorArrays[0].primaryColor : 'white', "& .MuiStepLabelLabel": { color: index1 === activeStepPopupIndex ? 'white !important' : 'black' } }}
                                                                    className="cobrand-stepper-popup"
                                                                    sx={{
                                                                        background: index1 === activeStepPopupIndex ? customColorArrays[0].primaryColor : 'white !important',
                                                                        borderColor: `${customColorArrays[0].primaryColor}`,
                                                                        color: index1 === activeStepPopupIndex ? 'white !important' : 'black',
                                                                        borderWidth: '2px', borderStyle: 'solid',
                                                                    }}
                                                                >
                                                                    {label}
                                                                </StepButton>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                </Grid>
                                                <Grid item lg={5} md={5} sm={12} xs={12} sx={{ display: 'contents !important' }} className='publish-popup-form'>
                                                    <Grid item lg={12} md={12} sm={12} xs={12} pb={2}>
                                                        {index === 0 && <Step1 btnTextColor={btnTextColor} primaryColor={customColorArrays[0].primaryColor} popupFlage={openPublishPopup == true ? true : false} textColorProp={textColor} hyperlinkColor={customColorArrays[0].hyperlinkColor} />}
                                                        {index === 1 && <Step2 btnTextColor={btnTextColor} primaryColor={customColorArrays[0].primaryColor} popupFlage={openPublishPopup == true ? true : false} textColorProp={textColor} hyperlinkColor={customColorArrays[0].hyperlinkColor} />}
                                                        {index === 2 && <Step4 btnTextColor={btnTextColor} primaryColor={customColorArrays[0].primaryColor} popupFlage={openPublishPopup == true ? true : false} textColorProp={textColor} hyperlinkColor={customColorArrays[0].hyperlinkColor} />}
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            );
                        })

                    }
                </DialogContent>
                <DialogActions sx={{ margin: 'auto', display: 'block' }} className='publish-popup-btn-wrapper'>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="h4" sx={{ textAlign: "center", margin: 'auto' }}>Do you want to publish latest changes?</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} m={0} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item lg={6} md={6} sm={6} xs={6} m={2}>
                            <Button variant="contained" onClick={() => { setOpenPublishPopup(false); setPublishBannerBtn(true); onSubmitStyleChanges(1) }} disabled={publishBannerBtn} color="success" sx={{ minWidth: 250 }}>
                                Yes
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6} m={2} >
                            <Button variant="contained" onClick={() => setOpenPublishPopup(false)} color="primary" sx={{ minWidth: 250 }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>


            <Dialog
                open={deleteUploadImgPopup}
                fullWidth
                maxWidth={'sm'}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setDeleteUploadImgPopup(false);
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography level="h4" sx={{ textAlign: "center" }}>Do you want to delete Uploaded image</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setDeleteUploadImgPopup(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogActions sx={{ margin: 'auto', display: 'block' }} className='publish-popup-btn-wrapper'>
                    <Grid item lg={12} md={12} sm={12} xs={12} m={0} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item lg={6} md={6} sm={6} xs={6} m={2}>
                            <Button variant="contained" onClick={deleteUploadImg} color="success" sx={{ minWidth: 250 }}>
                                Yes
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6} m={2} >
                            <Button variant="contained" onClick={() => setDeleteUploadImgPopup(false)} color="primary" sx={{ minWidth: 250 }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog
                open={selectedImgPoup}
                fullWidth
                maxWidth={'sm'}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        setSelectedImgPoup(false);
                    }
                }}
            >
                <DialogTitle id="responsive-dialog-title" sx={{ borderBottom: "1px solid green" }}>
                    <Typography level="h4" sx={{ textAlign: "center" }}>Select Banner Image From</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setSelectedImgPoup(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogActions sx={{ margin: 'auto', display: 'block' }} className='publish-popup-btn-wrapper'>
                    <Grid item lg={12} md={12} sm={12} xs={12} m={0} sx={{ display: 'flex', justifyContent: 'center' }} className='upload-secton-btns'>
                        <Grid item lg={6} md={6} sm={6} xs={6} m={2}>

                            {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center", position: 'absolute', top: '31%' }} className='imgUploadText' > */}
                            <Button variant="contained" onClick={() => { document.querySelector('.upload_file').click() }} sx={{ minWidth: 250, background: 'transparent', color: 'black', display: 'block' }}>
                                <Box><img src={require("../../assets/icons/galleryImg.png")} /></Box>
                                <Typography>Browse Gallery</Typography>
                            </Button>
                            {/* </Grid> */}
                            {error && <span style={{ color: 'red', textAlign: "center" }}>{error}</span>}
                            <input accept="image/*" type="file" className="upload_file" onClick={(e) => e.target.value = null} onChange={handleBannerImgChange} style={{ display: 'none' }} />
                        </Grid>
                        {originalCroppedImgs.length > 0 ?
                            <Grid item lg={6} md={6} sm={6} xs={6} m={2} >
                                <Button variant="contained" onClick={() => { setImgSrc(originalCroppedImgs[0].base64); setOpenImgPopup(true); setSelectedImgPoup(false); }} sx={{ minWidth: 250, background: 'transparent', color: 'black', display: 'block' }}>
                                    <Box><img src={require("../../assets/icons/uploadImg.png")} /></Box>
                                    <Typography>Your uploads</Typography>
                                </Button>
                            </Grid> : null}
                    </Grid>
                </DialogActions>
            </Dialog>
        </>

    return (
        <>
            <DefaultLayout content={ContentLayout} />
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div></>
    );
}
export default AdvancedCobrandSetup;