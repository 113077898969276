import React, { useMemo, useState, useEffect } from 'react'
import { Box, Grid, Card, TextField, Checkbox, Button, CardContent, Tooltip, FormControlLabel, Collapse, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import DefaultLayout from '../../../Components/DefaultLayout';
import { styled } from '@mui/material/styles';
import Typography from '@mui/joy/Typography';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CardOverflow } from '@mui/joy';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import AuthUser from '../../../Components/Auth/AuthUser';
import config from '../../../AppConfig';
import MaterialReactTable from 'material-react-table';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { fetchData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Moment from 'moment';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
let RequestFormInitialValues = {
    Tittle: '',
    Description: '',
    Url: '',
    Category: '',
    message: '',
};
function BlogDashboard(props) {
    const [disabled, setDisabled] = useState(true);
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(100000);
    const [search, setSearch] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [blogList, setBlogList] = useState([]);
    const [category, setCategoryList] = useState([]);
    const q = search == '' ? 'null' : search;
    const navigate = useNavigate();
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const { http } = AuthUser();
    //Blog validation Schema start
    const BlogDashboard = Yup.object().shape({
        Tittle: Yup.string()
            .required('The Title field is required.'),

        Url: Yup.string()
            .required('The Url field is required.'),

        Category: Yup.string()
            .required('The Category field is required.'),
    });
    //Blog validation Schema end
    const Blog = (values, { resetForm }) => {
        // alert(JSON.stringify(values));
        http.post("admin/createBlog", {
            title: values.Tittle,
            url: values.Url,
            category: values.Category,
            description: values.Description,
            content: values.message,
        })
            .then((res) => {
                if (res.data.success === true) {
                    swal({
                        title: "Success",
                        text: res.data.message,
                        icon: "success",
                        button: "Ok",
                    }).then((ok) => {
                        if (ok) {
                            // Reload the current page
                            window.location.reload();
                        }
                    });
                }
            })
            .catch((error) => {
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
        resetForm();
    }
    
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
    }, [rowSelection]);

    //fetch data blog start here
    const fetchBlogData = async () => {
        const apiUrl = '/admin/blog';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setBlogList(res.posts);
            setCategoryList(res.categories);
            setTotalRows(res.total);
            console.log(res.posts);
        }
    }
    useEffect(() => {
        fetchBlogData();
    }, [])
    //fetch data blog end here

    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (row) => `${row.created_at ? Moment(row.created_at).format('MM-DD-YYYY hh:mm') : 'N/A'}`,
                header: 'Date',
                size: 20,
                id: 'created_at',
            },
            {
                accessorFn: (row) => `${row.title}`,
                header: 'Title',
                size: 10,
                id: 'title',
            },
            {
                accessorFn: (row) =>
                    <>
                        <Box className="Blogs">
                            <Typography>{row.description}</Typography>
                        </Box>
                    </>,
                id: 'description',
                header: 'Description',
            },
            {
                accessorFn: (row) => `${(row.category) ? (row.category.category) : 'N/A'}`,
                header: 'Category',
                size: 50,
                id: 'category',
            },
            {
                accessorFn: (row) => `${row.id}`,
                header: 'Edit',
                size: 50,
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        <>
                            <Tooltip title="Edit Blog" arrow><EditOutlinedIcon onClick={() => window.location.href = '/blogdashboardedit/' + renderedCellValue} /></Tooltip>
                        </>
                    </Box>
                ),
            },
        ]
    );

    function ContentLayout() {
        return (
            <>
                <Card>
                    <CardOverflow sx={{ backgroundColor: "#2A68B3", padding: "1%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid item xs sx={{ justifyContent: "space-between" }}>
                                <Typography level="h4" sx={{ color: "white" }}>New Blog Post</Typography>
                            </Grid>
                            <Grid item xs sx={{ textAlign: "right" }}>
                                <ExpandMore
                                    expand={expanded}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more">
                                    <AddIcon sx={{ color: "white" }} />
                                </ExpandMore>
                            </Grid>
                        </Grid>
                    </CardOverflow>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent sx={{ padding: "0% 8% 0%", justifyContent: "center" }}>
                            <Formik
                                initialValues={RequestFormInitialValues}
                                validationSchema={BlogDashboard}
                                onSubmit={Blog}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError }) => (
                                    <div>
                                        <Grid container item lg={8} md={8} sm={12} xs={12} mt={2}>
                                            <TextField label="Tittle*" variant="outlined" margin='dense' size='small' fullWidth
                                                name='Tittle'
                                                id='Tittle'
                                                onChange={(e) => { handleChange(e); }}
                                                value={values.Tittle}
                                                onBlur={handleBlur('Tittle')} />
                                            {touched.Tittle && errors.Tittle ? <div className='error'>{errors.Tittle}</div> : null}
                                        </Grid>
                                        <Grid container item lg={8} md={8} sm={12} xs={12} mt={1}>
                                            <TextField label="Description" variant="outlined" margin='dense' size='small' fullWidth
                                                id='Description'
                                                onChange={(e) => { handleChange(e); }}
                                                value={values.Description}
                                                onBlur={handleBlur('Description')}
                                            />
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                                            <Grid item lg={8} md={8} sm={8} xs={8} mt={1}>
                                                <TextField label="URL Slug*" variant="outlined" margin='dense' size='small' fullWidth
                                                    name='Url'
                                                    id='Url'
                                                    onChange={(e) => { handleChange(e); }}
                                                    value={values.Url}
                                                    onBlur={handleBlur('Url')}
                                                    disabled={disabled}
                                                />
                                                {touched.Url && errors.Url ? <div className='error'>{errors.Url}</div> : null}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={4} xs={4} mt={1}>
                                                <FormControlLabel control={<Checkbox
                                                    checked={!disabled}
                                                    onClick={() => setDisabled(!disabled)}
                                                />} label="Edit" />
                                            </Grid>
                                        </Grid>
                                        <Grid container item lg={8} md={8} sm={12} xs={12} mt={1}>
                                            <FormControl fullWidth margin='dense' size='small'>
                                                <InputLabel id="demo-simple-select-label">Category*</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    name='Category'
                                                    id='Category'
                                                    onChange={(e) => { handleChange(e); }}
                                                    value={values.Category}
                                                    onBlur={handleBlur('Category')}
                                                    label="Category"
                                                    MenuProps={MenuProps}
                                                >
                                                    {category.map((categoryId) => (
                                                        <MenuItem
                                                            value={categoryId.id}
                                                        >
                                                            {categoryId.category}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.Category && errors.Category ? <div className='error'>{errors.Category}</div> : null}
                                            </FormControl>
                                        </Grid>
                                        <Grid container item lg={8} md={8} sm={12} xs={12} mt={1}>
                                            <FormControl fullWidth size="small" margin='dense'>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    required
                                                    name="message"
                                                    row="4"
                                                    type="text"
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        values.message = data;
                                                        handleChange('message');
                                                    }}
                                                    onBlur={(event, editor) => {
                                                        handleBlur('message');
                                                    }}
                                                    data={values.message}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container item lg={8} md={8} sm={12} xs={12} sx={{ justifyContent: "right" }} mt={1}>
                                            <Button variant="contained" sx={{ minWidth: 200 }} onClick={handleSubmit}>New Post</Button>
                                        </Grid>
                                    </div>
                                )}
                            </Formik>
                        </CardContent>
                    </Collapse>
                </Card>
                <MaterialReactTable
                    columns={columns}
                    data={blogList}
                    enableColumnFilterModes
                    enableColumnFilters={config.DatatableColumnFilter}
                    //connect internal row selection state to your own
                    state={{ columnVisibility }} //pass our managed row selection state to the table to use
                    initialState={{
                        showColumnFilters: false,
                        density: 'compact',
                    }}
                    onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                    positionToolbarAlertBanner="bottom"
                    enableDensityToggle={false}
                />
            </>
        );
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}


export default BlogDashboard;