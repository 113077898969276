import React, { useState, useEffect } from "react";
import { Grid, RadioGroup, FormControlLabel, InputAdornment, FormLabel, OutlinedInput, FormControl, Checkbox, InputLabel,IconButton } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
const DynamicFieldsForm = (props) => {
    const [fieldSets, setFieldSets] = useState(props.fields ? props.fields : [{ disposition: "", reasonData: [{ reason: "", savable: false, aid: false, mid: false }] }]);

    const handleAddFieldSet = (fieldSetIndex) => {
        const lastElement = fieldSets[[fieldSets.length] - 1];
        if (lastElement.disposition == "") {
            alert("Please fill previous disposition first")
        } else {
            setFieldSets([...fieldSets, { disposition: "", reasonData: [{ reason: "", savable: false, aid: false, mid: false }] }]);
        }
    };

    const handleRemoveFieldSet = (index) => {
        const newFieldSets = [...fieldSets];
        newFieldSets.splice(index, 1);
        setFieldSets(newFieldSets);
    };

    const handleAddSubField = (fieldSetIndex) => {
        const newFieldSets = [...fieldSets];
        const lastElement = fieldSets[fieldSetIndex]['reasonData'][fieldSets[fieldSetIndex]['reasonData'].length - 1];
        if (lastElement.reason == "") {
            alert("Please fill previous reason first")
        } else {
            newFieldSets[fieldSetIndex].reasonData.push({ reason: "", savable: false, aid: false, mid: false });
            setFieldSets(newFieldSets);
        }
    };

    const handleRemoveSubField = (fieldSetIndex, subFieldIndex) => {
        const newFieldSets = [...fieldSets];
        newFieldSets[fieldSetIndex].reasonData.splice(subFieldIndex, 1);
        setFieldSets(newFieldSets);
    };

    const handleFieldChange = (fieldSetIndex, field, event) => {
        const newFieldSets = [...fieldSets];
        newFieldSets[fieldSetIndex][field] = event.target.value;
        setFieldSets(newFieldSets);
    };

    const handleSubFieldChange = (fieldSetIndex, subFieldIndex, field, event) => {
        let fieldVal = field == 'reason' ? event.target.value : event.target.checked;
        const newFieldSets = [...fieldSets];
        newFieldSets[fieldSetIndex].reasonData[subFieldIndex][field] = fieldVal;
        setFieldSets(newFieldSets);
    };
    useEffect(() => {
        props.onChangeData(fieldSets);
    }, [fieldSets])


    return (
        <form>
            {fieldSets.map((fieldSet, fieldSetIndex) => (
                <Grid container item lg={12} md={12} sm={12} xs={12} key={fieldSetIndex} spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl variant="outlined" margin='dense' size='small' fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Disposition</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                margin='dense'
                                size='small'
                                label="Disposition"
                                value={fieldSet.disposition}
                                onChange={(event) => handleFieldChange(fieldSetIndex, "disposition", event)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Add field set"
                                            onClick={() => { handleAddFieldSet(fieldSetIndex) }}
                                        >
                                            <ControlPointIcon />
                                        </IconButton>
                                        {fieldSetIndex > 0 && (
                                            <IconButton
                                                aria-label="Remove field set"
                                                onClick={() => handleRemoveFieldSet(fieldSetIndex)}
                                            >
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>

                    {fieldSet.reasonData.map((subField, subFieldIndex) => (
                        <>
                            {
                                subFieldIndex > 0
                                    ?
                                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ justifyContent: "right !important" }}></Grid>
                                    :
                                    null
                            }
                            <>
                                <Grid item lg={6} md={6} sm={12} xs={12} key={subFieldIndex} sx={{ justifyContent: "right !important" }}>
                                    <FormControl variant="outlined" margin='dense' size='small' fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password">Outcome Reason</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            label="Outcome Reason"
                                            margin='dense'
                                            size='small'
                                            value={subField.reason}
                                            onChange={(event) => handleSubFieldChange(fieldSetIndex, subFieldIndex, "reason", event)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="Add sub-field"
                                                        onClick={() => handleAddSubField(fieldSetIndex)}
                                                        edge="end"
                                                    >
                                                        <ControlPointIcon />
                                                    </IconButton>
                                                    {subFieldIndex > 0 && (
                                                        <IconButton
                                                            aria-label="Remove sub-field"
                                                            onClick={() => handleRemoveSubField(fieldSetIndex, subFieldIndex)}
                                                            edge="end"
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={6} md={6} sm={12} xs={12} >
                                            <FormControlLabel control={<Checkbox defaultChecked={subField.savable == 1 ? true : false} />} label="Savable (only in case of retention)"
                                                value={subField.savable == 1 ? true : false}
                                                onChange={(event) => handleSubFieldChange(fieldSetIndex, subFieldIndex, "savable", event)}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} >
                                            <FormControl row>
                                                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "black" }}>Required : </FormLabel>
                                                <RadioGroup
                                                    row>
                                                    <FormControlLabel control={<Checkbox defaultChecked={subField.aid} />} label="AID"
                                                        value={subField.aid}
                                                        onChange={(event) => handleSubFieldChange(fieldSetIndex, subFieldIndex, "aid", event)}
                                                    />
                                                    <FormControlLabel control={<Checkbox defaultChecked={subField.mid} />} label="MID"
                                                        value={subField.mid}
                                                        onChange={(event) => handleSubFieldChange(fieldSetIndex, subFieldIndex, "mid", event)}
                                                    />
                                                </RadioGroup>
                                            </FormControl>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </>

                        </>
                    ))}
                </Grid>
            ))}
        </form>
    );
};

export default DynamicFieldsForm;

