import React, { useRef, useState, useEffect } from 'react';
import {
    Button,  FormLabel,  Stack,
    Grid, FormControlLabel, FormControl, RadioGroup, Radio, TextField, Select, MenuItem, InputLabel, OutlinedInput,useMediaQuery
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../Components/Loader/Loader'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { Formik } from 'formik';
import * as Yup from 'yup';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import {useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};


let whoC = "Member";
let CallTypeDataListLET = [];
let callTypeId = '';
let disposition = '';
// let defaultAidVal = 0;


function ContactLog({ ModalClose, fetchUserListData }) {
    const dispatch = useDispatch();
    const [callTypeList, setCallTypeDataList] = useState([]);
    const [dispositionTypeList, setDispositionList] = useState([]);
    const [outcomeList, setOutcomeList] = useState([]);
    const [disable, setDisable] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [WhoContactedVal, setWhoContactedVal] = useState(false);
    const [defaultAidVal, setAidVal] = useState(false);
    const [midVal, setMidVal] = useState(false);
    const [dispositionVal, setdisVal] = useState(false);
    const [dublicateAid, setAidDublicate] = useState(false);
    const [contactTypeList, setCallTypeList] = useState([]);


    const navigate = useNavigate();
    const [errors1, setErrors] = useState({});

    // function usestate variables
    const [open, setOpen] = React.useState(false);

    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };


    //change who contact function

    const fetchCallTypeList = async () => {
        const apiUrl = '/fetchContactLogCallType'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
          setCallTypeList(res);
        }
      }

    const handledContactType = async(event) => {
        formikRef.current.setValues({
            MobileNo: '',
            aid: '',
            WhoContacted: event,
            calltype: '',
            notes: '',
            MemberId: '',
            CallSource: '',
            disposition: '',
            outcome: '',
            defaultAidVal: defaultAidVal,
            walkthrough_offred: '',
            walkthrough_conducted: '',
        });



        setWhoContactedVal(event);

        whoC = event;
        if (event == 'Member' || event == 'Affiliate') {
            handleFreezStart();

            const apiUrl = '/fetchCallTypeList';
            const args = {
                contactTypes: whoC,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res) {
                handleFreezStop();
                setCallTypeDataList(res);
            }
        }
        
        if (event == 'Other') {
            const element2 = document.getElementById("MemberId");
            element2.style.display = "none";
            const element4 = document.getElementById("ContactType");
            element4.style.display = "none";
           

            const element6 = document.getElementById("ConatctReason");
            element6.style.display = "none";
            setdisVal(false);
            setAidVal(false);
            setMidVal(false);
        } else {
            const element3 = document.getElementById("MemberId");
            element3.style.display = "block";
            const element5 = document.getElementById("ContactType");

            element5.style.display = "block";
            const element6 = document.getElementById("ConatctReason");

            element6.style.display = "block";
            setdisVal(false);
            setAidVal(false);
            setMidVal(false);
        }
        if (event == 'Affiliate') {
            setAidVal(true);
            setdisVal(false);
            errors1.MemberId = '';
            setErrors(errors1);

        } else {
            setAidVal(false);
            setdisVal(false);
            errors1.MemberId = '';
            setErrors(errors1);
        }
        if (event == 'Member') {
            setMidVal(true);
            setdisVal(false);
            errors1.aid = '';
            setErrors(errors1);
        } else {
            setMidVal(false);
            setdisVal(false);
            errors1.aid = '';
            setErrors(errors1);

        }



    };



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        handleClickOpen()
        fetchCallTypeList();
    }, open)

    //start Validation 

    const ContactSchema = Yup.object().shape({
      

        WhoContacted: Yup.string()
            .required('Please Choose One.'),

        CallSource: Yup.string().when("WhoContacted", {
            is: (WhoContacted) => ['Member', 'Affiliate'].includes(WhoContacted),
            then: (schema) => schema
                .required(' Contact Type required')

        }),

        calltype: Yup.string().when("WhoContacted", {
            is: (WhoContacted) => ['Member', 'Affiliate'].includes(WhoContacted),
            then: (schema) => schema
                .required('Contact Reason is required.')

        }),

        disposition: Yup.string().when("WhoContacted", {
            is: (WhoContacted) => ['Member', 'Affiliate'].includes(WhoContacted),
            then: (schema) => schema
                .required('Disposition is required.')

        }),

        outcome: Yup.string().when("WhoContacted", {
            is: (WhoContacted) => ['Member', 'Affiliate'].includes(WhoContacted),
            then: (schema) => schema
                .required('Outcome is required.')

        }),



        notes: Yup.string().when("WhoContacted", {
            is: (WhoContacted) => ['Other'].includes(WhoContacted),
            then: (schema) => schema
                .required('Please enter  notes.')

        }),


    });
    // validation
    //get Disposition
    const handleCallType = async(event) => {
        setdisVal(false);
        callTypeId = event;
        handleFreezStart();

        const apiUrl = '/fetchCallType';
        const args = {
            callType: callTypeId,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            setDispositionList(res);
            handleFreezStop();
        }
    };
    //end Disposition
    //get Outcome
    const getOutcome = async(id, disposition) => {
        if (disposition == 'Cancelled' || disposition == 'Saved') {
            setdisVal(true);

        } else {
            setdisVal(false);
        }

        disposition = id;
        handleFreezStart();
        errors1.aid = '';
        setErrors(errors1);
    
        const apiUrl = '/getoutcome';
        const args = {
            disposition: disposition,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            setOutcomeList(res);
            handleFreezStop();
        }
    };
    const getOutComeDetails = async(id) => {

        const apiUrl = '/getOutcomesRequired';
        const args = {
            WhoContactedVal: WhoContactedVal,
            id: id,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if (WhoContactedVal == 'Member') {
                if (res == 'aid') {
                    setAidVal(true);
                } else {
                    setAidVal(false);
                }
            } else if (WhoContactedVal == 'Affiliate') {
                if (res == 'mid') {
                    setMidVal(true);
                } else {
                    setMidVal(false);

                }

            }
            handleFreezStop();
        }
    };
    const handleCheckAid = async(aid) => {
        const apiUrl = '/checkDublicateAid';
        const args = {
            aid: aid,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res) {
            if(res.success == false){
                errors1.aid = 'Aid does not exists.';
                setErrors(errors1);
                setAidDublicate(true);
            }else{
                errors1.aid = '';
                setErrors(errors1);
                setAidDublicate(false);
            }
          
        }

    };

    const handledAidType = (event) => {
        if (event == '') {
            errors1.aid = 'Aid  is required.';
            setErrors(errors1);
        } else {
            errors1.aid = '';
            setErrors(errors1);
        }
    };
    const handledMidType = (event) => {
        if (event == '') {
            errors1.MemberId = 'Please enter member id';
            setErrors(errors1);
        } else {
            errors1.MemberId = '';
            setErrors(errors1);
        }
    };
    const getwalkthrough_conducted = (event) => {
        if (event == '') {
            errors1.walkthrough_conducted = 'walkthrough conducted  is required.';
            setErrors(errors1);
        } else {
            errors1.walkthrough_conducted = '';
            setErrors(errors1);
        }
    };
    const getwalkthrough_offredDetails = (event) => {
        if (event == '') {
            errors1.walkthrough_offred = 'walkthrough offred  is required.';
            setErrors(errors1);
        } else {
            errors1.walkthrough_offred = '';
            setErrors(errors1);
        }
    };
    const handledMobileNoType = (event) => {
        if (event == '') {
            errors1.MobileNo = 'Please enter Mobile No.';
            setErrors(errors1);
        } else {
            errors1.MobileNo = '';
            setErrors(errors1);
        }
    };




    const formikRef = useRef();




    //end

    return (
        <>
            <Formik
                initialValues={{

                    MobileNo: '',
                    aid: '',
                    WhoContacted: '',
                    calltype: '',
                    notes: '',
                    MemberId: '',
                    CallSource: '',
                    disposition: '',
                    outcome: '',
                    defaultAidVal: defaultAidVal,
                    walkthrough_offred: '',
                    walkthrough_conducted: '',




                }}
                innerRef={formikRef}

                validationSchema={ContactSchema}
                onSubmit={async(values, errors) => {
                    const loginUser = JSON.parse(localStorage.getItem('user'));
                    if(loginUser.roles_id >= 3 ) {
                    navigate('/login');
                    return false;
                    }
                    const errors1 = {};
                    if (defaultAidVal == true && values.aid == '') {
                        errors1.aid = 'Aid  is required.';
                        setErrors(errors1);
                        return false;
                    }

                    if (midVal == true && values.MemberId == '') {
                        errors1.MemberId = 'Please enter member id';
                        setErrors(errors1);
                        return false;
                    } else if (midVal == true && values.MemberId.length != 7) {
                        errors1.MemberId = 'Min & Max 7 digits required.';
                        setErrors(errors1);
                        return false;
                    }
                    if (dispositionVal == true && values.walkthrough_offred == '') {
                        errors1.walkthrough_offred = 'walkthrough offred  is required.';
                        setErrors(errors1);
                        return false;
                    }
                    if (dispositionVal == true && values.walkthrough_conducted == '') {
                        errors1.walkthrough_conducted = 'walkthrough conducted  is required.';
                        setErrors(errors1);
                        return false;
                    }
                    if (values.WhoContacted == 'Other') {
                        if (values.MobileNo == '') {
                            errors1.MobileNo = 'Please enter Mobile No';
                            setErrors(errors1);
                            return false;
                        } else if (values.MobileNo.length != 10) {
                            errors1.MobileNo = 'Min & Max 10 digits required.';
                            setErrors(errors1);
                            return false;
                        }
                    } else if (values.MobileNo != '') {
                        if (values.MobileNo.length != 10) {
                            errors1.MobileNo = 'Min & Max 10 digits required.';
                            setErrors(errors1);
                            return false;
                        }

                    }
                    if(dublicateAid == true){
                        return false; 
                    }
                    if (defaultAidVal == true && (values.disposition == 141 || values.disposition == 146 || values.disposition == 134 || values.disposition == 138)) {
                        handleFreezStart();
                        const apiUrl = '/checkAidDublicate';
                        const args = {
                            aid: values.aid,
                            disposition: values.disposition,
                        }
                        const res = await dispatch(postData({apiUrl,args})).unwrap();
                        if(res.success  == true){
                            handleFreezStop();
                        }else{
                            handleFreezStop();
                            errors1.aid = res.message;
                            setErrors(errors1);
                            return false;

                        }

                    }
                    setDisable(true);
                    const apiUrl = '/contactLogCreate';
                    const args = {
                        WhoContacted: values.WhoContacted,
                        MemberId: values.MemberId,
                        MobileNo: values.MobileNo,
                        aid: values.aid,
                        callSource: values.CallSource,
                        calltype: values.calltype,
                        disposition: values.disposition,
                        outcome: values.outcome,
                        notes: values.notes,
                        walkthrough_offred: values.walkthrough_offred,
                        walkthrough_conducted: values.walkthrough_conducted,
                    }
                    const res = await dispatch(postData({apiUrl,args})).unwrap();
                    if (res.success === true) {
                        setDisable(false)
                        swal({
                            title: "Success",
                            text: "Contact Log Added Successfully.",
                            icon: "success",
                            button: "Ok",
                        })
                        fetchUserListData();
                        ModalClose();
                    } else {
                        if (res.success === false) {
                            swal({
                                title: "Error",
                                text:res.message, 
                                icon: "error",
                                button: "Ok",
                            })
                            if(res.message == "This action is unauthorized.") {
                                navigate('/login');
                            }
                        }else{
                            swal({
                                title: "Error",
                                text: "Contact Log Not Added Successfully.",
                                icon: "error",
                                button: "Ok",
                            })
                        }
                        
                        
                        ModalClose();
                    }                   
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Who Contacted?</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="WhoContacted"
                                    id="WhoContacted"
                                    value={values.WhoContacted}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handledContactType(e.target.value);
                                    }}
                                >
                                    <FormControlLabel value="Member" control={<Radio />} label="Member" />
                                    <FormControlLabel value="Affiliate" control={<Radio />} label="Affiliate" />
                                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                </RadioGroup>
                                {touched.WhoContacted && errors.WhoContacted ? <div className='error'>{errors.WhoContacted}</div> : null}
                            </FormControl>

                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                            <Grid item lg={4} md={4} sm={12} xs={12} id='MemberId'>
                                <FormControl fullWidth size='small'>
                                    <TextField
                                        name='MemberId'
                                        id='MemberId'
                                        label="Member ID"
                                        // inputProps={{ type: "number" }}
                                        value={values.MemberId}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={(e) => {
                                            handleChange(e);
                                            handledMidType(e.target.value);

                                        }}
                                    />
                                    {errors1.MemberId && <div className="error">{errors1.MemberId}</div>}
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <FormControl fullWidth size='small'>
                                    <TextField
                                        name='MobileNo'
                                        id='MobileNo'

                                        label="Mobile No"
                                        variant="outlined"
                                        value={values.MobileNo}
                                        size="small"
                                        fullWidth
                                        onChange={(e) => {
                                            handleChange(e);
                                            handledMobileNoType(e.target.value);

                                        }}
                                    />
                                    {errors1.MobileNo && <div className="error">{errors1.MobileNo}</div>}

                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                {
                                    defaultAidVal
                                        ?
                                        <>
                                            <TextField
                                                name='aid'
                                                id='aid'
                                                label="aid"
                                                value={values.aid}
                                                variant="outlined"
                                                size="small" fullWidth
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handledAidType(e.target.value);
                                                    handleCheckAid(e.target.value);

                                                }}
                                                onBlur={handleBlur('aid')}
                                            />
                                            {errors1.aid && <div className="error">{errors1.aid}</div>}
                                        </>
                                        :
                                        null
                                }
                                <FormControl fullWidth size='small' id='AIDdiv' sx={{ display: "none" }}>
                                    <TextField
                                        name='aid'
                                        id='aid'
                                        label="AID"
                                        value={values.aid}
                                        variant="outlined"
                                        size="small" fullWidth
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleCheckAid(e.target.value);

                                        }}
                                    />
                                    {touched.aid && errors.aid ? <div className='error'>{errors.aid}</div> : null}
                                </FormControl>
                            </Grid>

                        </Grid>   
                        <Grid container item lg={12} md={12} sm={12} xs={12} id='ContactType' mt={2}>

                            <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Contact Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="CallSource"
                                            name="CallSource"
                                            label="Contact Type"
                                            onChange={handleChange('CallSource')}
                                            value={values.CallSource}
                                            
                                            MenuProps={MenuProps}

                                        >
                                           
                                            {contactTypeList.map((array) => (
                                                <MenuItem
                                                    value={array.call_type}
                                                >
                                                    {array.call_type}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                </FormControl>
                                {touched.CallSource && errors.CallSource ? <div className='error'>{errors.CallSource}</div> : null}
                           
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} id="ConatctReason" >
                            <Stack direction="row" spacing={2} style={{ width: '100%' }} sx={{ marginTop: isMobile ? "2%" : "2%" }} >
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Contact Reason</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id='calltype'
                                            name='calltype'
                                            label="Contact Reason"
                                            value={values.calltype}
                                            onChange={(e) => {
                                                handleCallType(e.target.value);
                                                handleChange(e);
                                            }}
                                           
                                            MenuProps={MenuProps}

                                        >
                                           
                                            {callTypeList.map((array) => (
                                                <MenuItem
                                                    value={array.id}
                                                >
                                                    {array.call_type}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                    {touched.calltype && errors.calltype ? <div className='error'>{errors.calltype}</div> : null}
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}   sx={{ marginTop: isMobile ? "1%" : "0%" }}>
                                    <FormControl fullWidth size="small" id="DispositionDiv">
                                        <InputLabel id="demo-simple-select-label">Disposition</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="disposition"
                                            name='disposition'
                                            value={values.disposition}
                                            input={<OutlinedInput label="Disposition" />}
                                            onChange={(e) => {
                                                const selectedOption = dispositionTypeList.find((option) => option.id === e.target.value);
                                                getOutcome(selectedOption.id, selectedOption.disposition);
                                                handleChange(e);
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {dispositionTypeList.map((dispositionlist) => (
                                                <MenuItem
                                                    key={dispositionlist.id}
                                                    value={dispositionlist.id}
                                                >
                                                    {dispositionlist.disposition}
                                                </MenuItem>

                                            ))}
                                        </Select>
                                        {touched.disposition && errors.disposition ? <div className='error'>{errors.disposition}</div> : null}
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}  sx={{ marginTop: isMobile ? "1%" : "0%" }}>
                                    <FormControl fullWidth size="small" id="OutcomeDiv">
                                        <InputLabel id="demo-simple-select-label">Outcome</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="outcome"
                                            name="outcome"
                                            value={values.outcome}
                                            input={<OutlinedInput label="Outcome" />}
                                            
                                            onChange={(e) => {
                                                getOutComeDetails(e.target.value);
                                                handleChange(e);
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                           
                                            
                                           
                                           {outcomeList.map((outcome) => (
                                            outcome.reason === '' ? (
                                                <MenuItem  value={outcome.id}>No OutCome available</MenuItem>
                                            ) : (
                                                <MenuItem value={outcome.id} >
                                                {outcome.reason}
                                                </MenuItem>
                                            )
                                            ))}
                                            
                                        </Select>
                                        {touched.outcome && errors.outcome ? <div className='error'>{errors.outcome}</div> : null}
                                    </FormControl>
                                </Grid>
                            </Stack>
                        </Grid>

                        <Grid id='dispo' container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={2}>
                            {

                                dispositionVal
                                    ?
                                    <>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormControl>
                                                <FormLabel sx={{ color: "black" }} >Was Member Walk Through offered ?*</FormLabel>
                                                <RadioGroup
                                                    row
                                                    name='walkthrough_offred'
                                                    id='walkthrough_offred'
                                                    value={values.walkthrough_offred}
                                                    input={<OutlinedInput label="Was Member Walk Through offered ?*" />}
                                                   
                                                    onChange={(e) => {
                                                        getwalkthrough_offredDetails(e.target.value);
                                                        handleChange(e);
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" sx={{ fontWeight: "bold" }} />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" sx={{ fontWeight: "bold" }} />
                                                </RadioGroup>
                                                {errors1.walkthrough_offred && <div className="error">{errors1.walkthrough_offred}</div>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormControl>
                                                <FormLabel sx={{ color: "black" }} >Was Member Walk Through conducted ?*</FormLabel>
                                                <RadioGroup
                                                    row
                                                    name='walkthrough_conducted'
                                                    id='walkthrough_conducted'
                                                    value={values.walkthrough_conducted}
                                                    input={<OutlinedInput label=" Was Member Walk Through conducted ?*" />}
                                                   
                                                    onChange={(e) => {
                                                        getwalkthrough_conducted(e.target.value);
                                                        handleChange(e);
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" sx={{ fontWeight: "bold" }} />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" sx={{ fontWeight: "bold" }} />
                                                    <FormControlLabel value="Scheduled" control={<Radio />} label="Scheduled" sx={{ fontWeight: "bold" }} />
                                                </RadioGroup>
                                                {errors1.walkthrough_conducted && <div className="error">{errors1.walkthrough_conducted}</div>}
                                            </FormControl>
                                        </Grid>
                                        
                                    </>
                                    :
                                    null
                            }

                        </Grid>



                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}   sx={{ marginTop: isMobile ? "2%" : "0%" }}>
                            <FormControl fullWidth size="small">
                                <TextareaAutosize
                                    style={{width: "100% " ,height:isMobile ? "98%" : "100%" }}
                                    required
                                    margin="dense"
                                    aria-label="minimum height"
                                    minRows={6}
                                    placeholder="Enter Note"
                                    name="notes"
                                    id="notes"
                                    value={values.notes}
                                    onChange={handleChange('notes')}

                                />
                                {touched.notes && errors.notes ? <div className='error'>{errors.notes}</div> : null}
                            </FormControl>
                        </Grid>


                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%",marginTop: isMobile ? "2%" : "1%"  }} spacing={2} >
                            <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                                <Button autoFocus variant="contained" sx={{ minWidth:isMobile ? 200 : 250 }} onClick={() => { ModalClose() }}>
                                    CLOSE
                                </Button>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button onClick={disable === false ? handleSubmit : ''} variant="contained" sx={{ minWidth: isMobile ? 200 : 250 }} color="success" type="submit">
                                    SAVE
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Formik>



            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}

        </>
    );
}


export default ContactLog;