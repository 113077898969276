import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    OutlinedInput, Grid, InputLabel , FormControl , Container ,IconButton,InputAdornment
} from '@mui/material';
import Password from '../../../Components/Passwords/Password';
function Repassword() {
    
    function ContentLayout() {
        return (
           
           <>
           <Password />
           </>
           
            )
    }
    return (
        // ContentLayout()
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}
export default Repassword;