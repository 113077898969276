import React, { useEffect, useState, useRef, } from 'react';
import { Grid, Step, Stepper, StepLabel, DialogContent, Divider, DialogContentText, Button, TextField, FormGroup, FormControlLabel, Checkbox, FormControl, FormLabel, Tooltip, RadioGroup, Radio, Table, FormHelperText, Box, Paper } from '@mui/material';
import SecureWebsite from "../../../Components/Enrollment/SecureWebsite";
const Step2 = ({btnTextColor,primaryColor,popupFlage,textColorProp,hyperlinkColor}) => {
    return (
    <>
     <div style={{ pointerEvents: 'none' }}>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
            <TextField size='small' label="Street Address*" margin='dense' fullWidth sx={{
             label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}/>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
            <TextField size='small' label="Zip Code*" margin='dense' fullWidth sx={{
               label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}` ,backgroundColor:'white'}, // Styles the fieldset
                    }}/>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
            <TextField size='small' label="City*" margin='dense' fullWidth sx={{
           label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}/>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
            <TextField size='small' label="State*" margin='dense' fullWidth sx={{
               label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}/>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
            <TextField size='small' label="Phone Number*" margin='dense' fullWidth sx={{
              label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}/>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
            <TextField size='small' label="Last Four Digits of SSN*" margin='dense' fullWidth sx={{
              label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}/>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
            <TextField size='small' label="Date of Birth*" margin='dense' fullWidth sx={{
               label: { color: `${primaryColor}` },
                        "& .MuiOutlinedInput-notchedOutline": { borderWidth: '2px', borderStyle: 'solid', borderColor: `${primaryColor}`,backgroundColor:'white' }, // Styles the fieldset
                    }}/>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={1} sx={{ justifyContent: "right", display: "flex" }}>
                <Button variant='contained' size="lg" sx={{ minWidth: "60%", backgroundColor: `${primaryColor}`,color: `${btnTextColor}`,
                        '&:hover': {
                            backgroundColor: `${primaryColor}`
                        } 
                    }} className='popup-next-btn'>NEXT STEP</Button>
            </Grid>
            <SecureWebsite textColor={textColorProp} hyperlinkColor={hyperlinkColor}/>
            </div>
    </>
    );
}
export default Step2;