import React, { useState } from 'react';
import { Box, Grid, TextField, Card, CardContent, Button, Link } from '@mui/material';
import LoginHeader from '../../Components/LoginHeader';
import RegisterFooter from './RegisterFooter';
import config from "../../AppConfig";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Typography, Table } from '@mui/joy';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useDispatch } from 'react-redux';
import { postData } from '../../redux/apiSlice';

function ForgetPassword(props) {
    const dispatch = useDispatch();
    //Error Message Variables
    const [error, setError] = useState();
    const [successMessage, setSuccessMessage] = useState('');
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [emailButton, setEmailButton] = useState(false);

    const SignupSchema = Yup.object().shape({
        email: Yup.string()
            // .email('Invalid Email Address')
            .required('Please enter your registered email address.')
            // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
    })

    const checkUsaTLD = async(email) => {
        if (email.includes('@')) {
			setEmailButton(true);
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            }
			setEmailButton(false);
        }
    }

    return (
        <>
            <LoginHeader />
            <Box className="bodycolor" >
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
                                <img alt="#" src={require("../../assets/images/forgotPassword.png")} className='imgCenter'/>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ padding: "0 5% 0 5%" }} mt={3}>
                                <Typography level='h2' sx={{ marginBottom: "5%", textAlign: "center" }} >Forgot Password?</Typography>
                                <Formik
                                    initialValues={{
                                        email: '',
                                    }}
                                    validationSchema={SignupSchema}
                                    onSubmit={async (values, errors) => {
                                        if(checkUsaTldStatus) {
                                            return false;
                                        }
                                        setSuccessMessage("Sending...");
                                        setError(null);
                                        const apiUrl = '/forgot-password';
                                        const args = {
                                            email: values.email, 
                                            url: config.BASE_URL
                                        }
                                        const res = await dispatch(postData({apiUrl,args})).unwrap();
                                        if(res) {
                                            if (res.success === true) {
                                                setSuccessMessage(res.message.message);
                                                setError(null);
                                            } else {
                                                setSuccessMessage(null);
                                                setError(res.message.error);
                                            }
                                        }
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue }) => (
                                        <div >
                                            <TextField
                                                required
                                                id="outlined-textarea"
                                                label="Email Address"
                                                // onChange={handleChange('email')}
                                                onChange={(e)=>{
                                                    checkUsaTLD(e.target.value);   
                                                    handleChange('email')
                                                    setFieldValue('email', e.target.value, true)
                                                }}
                                                onBlur={handleBlur('email')}
                                                value={values.email}
                                                placeholder="Enter Your Email Address"
                                                fullWidth
                                                size="small"
                                                // helperText={touched.email && errors.email ? errors.email : null}
                                                error={touched.email && errors.email ? errors.email : null}
                                            />
                                            <div style={{ color: '#d32f2f', fontFamily: "Roboto", fontWeight: 400, fontSize: "0.90rem" }}>{ touched.email && errors.email ? "Invalid email format." : checkUsaTldStatus ? "Invalid email format." : ''}</div>
                                            <Typography color={'success'}>{successMessage}</Typography>
                                            <Typography color={'danger'}>{error}</Typography>
                                            <Grid lg={12} md={12} sm={12} xs={12} mt={2} sx={{ textAlign: "center" }}>
                                                <Button disabled={isSubmitting} type='submit' variant="contained" onClick={emailButton == false ? handleSubmit : ''} sx={{minWidth:300}} >Request A Reset Link</Button>
                                            </Grid>
                                            <Grid lg={12} md={12} sm={12} xs={12} mt={1} sx={{ textAlign: "center" }}>
                                                <Link underline="always" href="/login">Back to Login</Link>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"center"}}>
                                                <Box className="margins marginTops">
                                                    <Grid container lg={12} md={12} sm={12} xs={12}>
                                                        <Typography level="h3" color={'success'}>Need Help?</Typography>
                                                    </Grid>
                                                    <Table borderAxis="none" sx={{ display: "block", justifyContent: "center" }} >
                                                        <tr>
                                                            <td style={{ width: "5%", }}>
                                                                <AccessTimeIcon />
                                                            </td>
                                                            <td style={{ width: "95%" }}>
                                                                <Typography level="body1" fontSize="lg">
                                                                    We’re available 7days a week
                                                                    <br />
                                                                    Mon-Fri (8am-9pm ET)
                                                                    <br />
                                                                    Sat-Sun (9am-7pm ET)
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <WifiCalling3Icon />
                                                            </td>
                                                            <td>
                                                                <Typography level="body1" fontSize="lg">1-888-548-2008</Typography>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <EmailOutlinedIcon />
                                                            </td>
                                                            <td>
                                                                <Typography level="body1" fontSize="lg">info@myfreescorenow.com</Typography>
                                                            </td>
                                                        </tr>
                                                    </Table>
                                                </Box>
                                            </Grid>


                                        </div>
                                    )}

                                </Formik>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default ForgetPassword;