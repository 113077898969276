import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { Box, Tab, Grid, Backdrop } from '@mui/material';
import Typography from '@mui/joy/Typography';
import Freez from '../../../Components/Loader/Loader';
import ApiDetails from '../../../Components/Automations/ApiDetails';
import ApiUser from '../../../Components/Automations/ApiUser';
import AuthUser from '../../../Components/Auth/AuthUser';
import ApiDocumentation from '../../../Components/Automations/ApiDocumentation';
function Index(props) {
    const { tab } = useParams();
    const [value, setValue] = React.useState(tab);
    const [freez, setFreez] = React.useState(false);
    const { user } = AuthUser();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const ContentLayout =
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Typography level="h4" color="primary">{user.roles_id == 3 ? "API Integration" : ''}</Typography>
                </Grid>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                {user.roles_id == 3 ?<Tab label="API Details" component={Link} to="/automations/api-details" value="api-details" sx={{ color: 'black' }} /> : ''}
                                <Tab label="API User" component={Link} to="/automations/api-user" value="api-user" sx={{ color: 'black' }} />
                                {/* <Tab label="API Documentation" component={Link} to="/automations/api-documentation" value="api-documentation" sx={{ color: 'black' }} /> */}
                            </TabList>
                        </Grid>
                    </Box>
                    <TabPanel value="api-details"><ApiDetails tab={tab} /></TabPanel>
                    <TabPanel value="api-user"><ApiUser tab={tab} /></TabPanel>
                    {/* <TabPanel value="api-documentation"><ApiDocumentation tab={tab} /></TabPanel> */}
                </TabContext>
            </Box>
        </>
    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default Index;