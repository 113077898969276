import React, { useState, useEffect } from 'react';
import LoginHeader from '../../../Components/LoginHeader';
import RegisterFooter from '../../Auth/RegisterFooter';
import { Box, Grid, Pagination, button } from '@mui/material';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import config from '../../../AppConfig';
import Sidebar from '../../../Components/Blogs/Sidebar';
function IdentityTheft() {
    const [showMoreStates, setShowMoreStates] = useState({});

    const toggleText = (id) => (showMoreStates[id] ? 'Close' : 'Read more');

    const handleToggle = (id) => {
        setShowMoreStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };
    return (
        <>
            <LoginHeader />
            <Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Grid item lg={2} md={2} sm={12} xs={12} >
                        <Sidebar />
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12} sx={{ padding: "2%" }}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <img src={require("../../../assets/images/mfsn_banner_4.jpg")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} className='responsiveImg' />
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='h4'>Identity Theft</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Identity theft (or ID theft) is a crime in which someone uses your personal information to assume your identity for personal, and often financial, gain. Identity theft is the fastest growing crime in America. Since 2000, it has topped the annual list of consumer complaints filed with the Federal Trade Commission. Even the most careful consumer can become a victim.</Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Introduction to Identity Theft</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Identity theft strikes victims from all walks of life. It has been compared to an epidemic, striking hundreds of thousands of new victims each year. What sets identify theft apart from other crimes is that it is a silent attacker, often going undetected until an enormous amount of damage has been done.</Typography>
                            {showMoreStates[1] && (
                                <div>
                                    <p>Identity theft a serious crime! When someone uses your personal information (such as your name, address or Social Security number) to impersonate you for the purpose of committing fraud or criminal activity, you become an identity theft victim. ID theft takes an enormous toll on its victims, many who have worked a lifetime to establish and maintain a good credit record.</p>
                                    <p>Even the most careful person can become a victim of identity theft. In fact, if you become a victim, you will have something in common with Oprah Winfrey, Tiger Woods, Ted Turner, Michael Jordan and Martha Stewart - all victims of identity theft. It pays to be alert to signs of identity theft so you can take swift action to stop it if you should become a victim.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(1)}>
                                {toggleText(1)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>How Identity Theft Happens</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Thieves use a variety of methods to obtain the tools of their trade (personal information), and they work quickly to avoid detection. The goal of most identity theft scams is to use your credit card or bank account information for personal gain for as long as they can get away with it before moving on to a new victim.</Typography>
                            {showMoreStates[2] && (
                                <div>
                                    <p>Identity theft can happen in a number of ways - both high and low tech. Below are just some of the ways criminals work to steal identities:</p>
                                    <p><b>Internet. </b>Online "phishing" has become very popular. Thieves create fake web sites that look just like the real one. They then lure consumers to these sites by sending emails asking for confirmation of account details in order to "keep your account in good standing." Consumers who provide their personal information have just handed over their identity.</p>
                                    <p><b>Paper Records.</b>"Dumpster diving," which is sifting through trash, is another way thieves can gain access to your records.</p>
                                    <p><b>Mail. </b>ID thieves often steal mail, including bank and credit card statements, credit card offers, checks, tax information and more. They may even steal the mail you leave for the postman if your mailbox isn’t secure. They may fill out a change of address form in your name - an easy way to divert your mail and give them more time to use your identity.</p>
                                    <p><b>ATMs.</b>ID thieves have been "shoulder surfing" for years. They can look over your shoulder - sometimes from a distance through high-powered binoculars - to capture your PIN number. A more recent scam is for thieves to place a device on an ATM machine to collect your data while you go about the business of withdrawing money.</p>
                                    <p><b>Old-Fashioned Theft. </b>Some things don’t change. Identity thieves are still stealing wallets and purses from houses, cars and people. Sadly, in a significant number of cases, the thief is someone known to the victim (family member, neighbor or co-worker), so the theft may go undetected for awhile.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(2)}>
                                {toggleText(2)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Identity Theft and Your Credit Report</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>Credit reports are often the first place where evidence of identity theft shows up, so pay close attention to your credit report. Studies show that the faster identity theft is spotted, the less damage is done. Put time on your side!</Typography>
                            {showMoreStates[3] && (
                                <div>
                                    <p>Credit monitoring is one of the most effective things you can do to keep tabs on your credit report around the clock. With credit monitoring, you are notified within 24 hours (usually by email) whenever there are significant changes to your credit report.</p>
                                    <p>Get your free credit score with MyFreeScoreNow and start a free trial of our credit monitoring service. There is no obligation to continue.</p>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(3)}>
                                {toggleText(3)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Guarding Your Identity Footprint</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Typography level='title-md'>There’s a lot of talk these days about reducing your carbon footprint. What about your identity footprint? Are you leaving a trail that makes it easy for someone to steal your identity? With nearly nine million identity theft victims each year, it makes sense to think about how you can minimize your risk of becoming an identity theft victim by reducing your credit footprint.</Typography>
                            {showMoreStates[4] && (
                                <div>
                                    <p><b>What Is Your Identity Footprint?</b></p>
                                    <p>Your identity footprint is anything that could be used by someone to steal your identity. It could be credit information or personal information. Really, it doesn’t take much for someone to the able to create an identity.</p>
                                    <p>Some parts of your identity footprint are beyond your control. Employers, co-workers and bank employees are just a few of those who may have access to your personal information. Concentrate on the things you can control.</p>
                                    <ul>
                                        <li><b>Shred anything and everything</b>with personal information before you throw it away. Dumpster diving is still one way thieves get information.</li>
                                        <li><b>Carry fewer cards.</b>The less you carry, the less a thief will have access to if your wallet or purse is stolen.</li>
                                        <li><b>Pay attention to your bills.</b>If your statements don’t arrive on time, call the issuer. Check your accounts online between statements.</li>
                                        <li><b>Guard your Social Security number.</b>Your Social Security number is like gold to identity thieves. Don’t carry your card, and don’t give your number out when you don’t have to.</li>
                                        <li><b>Don’t have loose lips on the phone. </b>People aren’t always who they say they are. Unless you made the phone call and are sure of the number you are calling, don’t give personal information.</li>
                                        <li><b>Don’t conduct personal business in public.</b>You never know who is looking or listening. Guard your privacy.</li>
                                    </ul>
                                </div>
                            )}
                            <Button onClick={() => handleToggle(4)}>
                                {toggleText(4)}
                            </Button>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography><b>Are you monitoring your credit?</b></Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mb={4}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' style={{ textDecoration: "none" }}><Typography level='title-md' sx={{ color: "#1976d2" }}>Get your free credit score and score report now and protect your credit.</Typography></a>
                        </Grid>
                        {/* <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <a href={config.BASE_URL+'enroll?pid=00017&aid=seo&sid=&tid=&adid='} target='_blank' className='blog'>Get your free credit score and score report now and protect your credit.</a>
                        </Grid> */}
                    </Grid>
                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <img src={require("../../../assets/images/lt_banner_5.png")} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                    </Grid>
                </Grid>
            </Box>
            <RegisterFooter />
        </>
    );
}

export default IdentityTheft;