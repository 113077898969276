import React, { useState, useEffect } from "react";
import { Grid, Button, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, Stack,Table,TableBody,TableCell,TableRow} from '@mui/material';
import { Typography,  Sheet,  Card} from '@mui/joy';
import AuthUser from "../../../Components/Auth/AuthUser";
import { useNavigate, useParams } from 'react-router-dom';
import swal from "sweetalert";
import config from '../../../AppConfig';
import { useDispatch } from "react-redux";
import { fetchData, } from "../../../redux/apiSlice";
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import LogoutIcon from '@mui/icons-material/Logout';
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import GaugeComponent from 'react-gauge-component';
import LanguageIcon from '@mui/icons-material/Language';

let newDateStr = '';
function SnapshotScore(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { http } = AuthUser();
  const { userId } = useParams();
  const [freez, setFreez] = React.useState(false);
  const [creditScore, setCreditScore] = useState(0);
  const [logourl, setlogourl] = useState('');
  const [open, setOpen] = React.useState(false);
  const [BlackboxObj, setBlackboxObj] = useState(null);
  //added gauge 

const getColorForPointer = (score) => {
    if (score >= 300 && score < 599) {
      return '#df240c'; // Red for Poor
    } else if (score >= 600 && score < 660) {
      return '#fe992b'; // Orange for Fair
    } else if (score >= 661 && score < 715) {
      return '#eff307'; // Yellow for Good
    } else if (score >= 716 && score < 747) {
      return '#c9ff38'; // Green for Very Good
    } else if (score >= 748 && score <= 850) {
      return '#01c201'; // Dark Green for Excellent
    } else {
      return '#000000'; // Default to black if score is invalid
    }
  };
const getCreditScoreLabel = (score) => {
    if (score >= 300 && score < 599) {
      return 'Poor';
    } else if (score >= 600 && score < 660) {
      return 'Fair';
    } else if (score >= 661 && score < 715) {
      return 'Good';
    } else if (score >= 716 && score < 747) {
      return 'Very Good';
    } else if (score >= 748 && score <= 850) {
      return 'Excellent';
    } else {
      return 'Invalid Score';
    }
  };
  const formatValueWithLabel = (value) => {
    return `${value}\n${getCreditScoreLabel(value)}`;
  };
  //added gauge
  // var BlackboxObj;
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  const [score, setScore] = useState('');
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const suffix = getNumberSuffix(day);
    return `${month} ${day}${suffix} ${date.getFullYear()}`;
  }

  function getNumberSuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  const formattedDate = formatDate(score ? score.reportDate : '');
  const Upgradeto3B = () => {
    handleFreezStart();
    localStorage.setItem('aid', score ? score.aid : 'seo');
    localStorage.setItem('pid', score ? score.pid : '00017');
    http.post("/registerEnrollment", {
      ugradeToCD: true,
      userId: userId,
      blackboxCode: BlackboxObj ? BlackboxObj.blackbox : "",
      step: 1
    })
      .then((res) => {
        handleFreezStop();
        if (res.data.success === true) {
          localStorage.setItem("trackingToken", res.data.data.trackingToken);
          localStorage.setItem('customerToken', res.data.data.customerToken);
          localStorage.setItem('Step', res.data.data.nextstep);
          localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
          localStorage.setItem("UpgradeTo3B", true);
          localStorage.setItem('Main_firstName', score ? score.userObj.first_name : '');
          localStorage.setItem('Main_lastName', score ? score.userObj.last_name : '');
          localStorage.setItem('Main_email', score ? score.email : '');
          window.location.href = config.BASE_URL + `enroll?pid=` + localStorage.getItem('pid') + `&aid=` + localStorage.getItem('aid') + `&sid=&tid=&adid=&from=crsleads`;
        } else {
          handleFreezStop();
          const errorObj = res.data.data.errorByCD;
          swal({
            title: "Failed",
            text: errorObj[0] && errorObj[0].message ? errorObj[0].message : res.data.data.errorByCD,
            icon: "error",
            button: "Ok",
          })
        }
      })
  }
  const getScoreData = async () => {
    handleFreezStart();
    const apiUrl = '/creditSnapshotInternalLogin/' + userId;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (res.success === true) {
        setScore(res.data);
        setlogourl(res.data ? config.BACKEND_URL + "images/companyLogo/" + res.data.affCobrandingLogo : '')
        setCreditScore(res.data.creditScore)
        handleFreezStop();
        setTimeout(function () {
          handleFreezStop();
        }, 8000);
      } else {
        navigate(-1);
      }
    }
  }
  useEffect(() => {
   
    getScoreData();
    if (score) {
      const dateStr = score ? score.reportDate : '';
      const date = new Date(dateStr);
      date.setDate(date.getDate() + score.offerDetails[0].days);
      newDateStr = date.toISOString().slice(0, 10);
    }
  }, [])

  useEffect(() => {
    const checkAndSetBlackbox = () => {
      if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
        const BlackboxObj1 = window.IGLOO.getBlackbox();
        setBlackboxObj(BlackboxObj1);
      }
    };

    if (document.readyState !== 'loading') {
      checkAndSetBlackbox();
    } else {
      document.addEventListener('DOMContentLoaded', checkAndSetBlackbox);
      return () => document.removeEventListener('DOMContentLoaded', checkAndSetBlackbox);
    }

    // Check for IGLOO object periodically in case it's loaded asynchronously
    const intervalId = setInterval(() => {
      if (BlackboxObj === null) {
        checkAndSetBlackbox();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [BlackboxObj]);

  //dialog box start here
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleLogout = () => {
    

    // Get the base URL
    const baseUrl = window.location.origin;

    // Append "/score" to the base URL
    const updatedUrl = config.BASE_URL + `snapshotlogin`;

    // Navigate to the updated URL
    window.location.href = updatedUrl;

  }
  const handleClose = () => {
    setOpen(false);
  };

  //dialog box end here
  return (
    <>
      <Grid className="paddss">
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "right" }} className="logouts">
          <Button onClick={handleClickOpen}><Typography><span><LogoutIcon /></span>Logout</Typography></Button>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <Grid item lg={7} md={7} sm={12} xs={12}>
            <Card variant="outlined" className="card-BorderRadius" sx={{ borderColor: 'grey.500' }}>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                <img alt="#" src={require("../../../assets/images/equifax.png")} style={{ marginLeft: "auto", marginRight: "auto", height: "100%" }} />
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                <Typography level="h4">Your Credit Report Summary</Typography>
              </Grid>
              <Grid container item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={6} md={6} sm={12} xs={12} mt={2}>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    
                    <GaugeComponent
                        value={creditScore}
                        type="radial"
                        minValue={300}
                        maxValue={850}
                        labels={{
                            valueLabel: {
                            fontSize: 80,
                            formatTextValue: (value) => `${value} :  ${getCreditScoreLabel(value)}` ,
                            style:{
                                fill: getColorForPointer(creditScore),
                                fontSize: "50px",
                                textShadow:"0px 0px",
                                fontWeight:"bold"
                            }
                            },
                            tickLabels: {
                            type: "outer",
                            ticks: [
                                { value: 300 },
                                { value: 362 },
                                { value: 424 },
                                { value: 484 },
                                { value: 548 },
                                { value: 610 },
                                { value: 672 },
                                { value: 734 },
                                { value: 796 },
                                { value: 850 }
                            ],
                            
                            }
                        }}
                        arc={{
                            colorArray: ["#df240c", "#df240c", "#df240c", "#df240c", "#df240c", "#fe992b", "#eff307", "#c9ff38", "#01c201"],
                            subArcs: [
                            { limit: 300, color: '#df240c', showTick: true },
                            { limit: 362, color: '#df240c', showTick: true },
                            { limit: 424, color: '#df240c', showTick: true },
                            { limit: 484, color: '#df240c', showTick: true },
                            { limit: 548, color: '#df240c', showTick: true },
                            { limit: 610, color: '#fe992b', showTick: true },
                            { limit: 672, color: '#eff307', showTick: true },
                            { limit: 734, color: '#c9ff38', showTick: true },
                            { limit: 796, color: '#01c201', showTick: true },
                            
                            ],
                            padding: 0,
                            cornerRadius: 1,
                            width: 0.3,
                            valueConfig: {
                            formatTextValue: (value) => `${value} :  ${getCreditScoreLabel(value)}`,
                            style:{
                                fill: getColorForPointer(creditScore),
                                fontSize: "50px",
                                textShadow:"0px 0px",
                                fontWeight:"bold"
                            } // Corrected formatTextValue usage
                            }
                        }}
                        pointer={{
                            elastic: true,
                            animationDelay: 0,
                             
                        }}
                        
                        />
                  </Grid>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    <Typography level="h4"><b>Vantage Score</b> <span style={{ color: "#5288db", fontSize: "0.8em" }}><b>3.0</b> </span>
                      <Tooltip title="Vantage 3.0 uses the same 300-850 range as base FICO.">
                        <span><img alt="#" src={require("../../../assets/images/info-icon.png")} /></span>
                      </Tooltip></Typography>
                  </Grid>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} >
                    <Typography level="body1">
                      As of {formattedDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={2}>
                    <Typography level="h5"><b>Total Negative Items : </b> <span style={{ fontSize: "1em" }}><b>{score ? score.totalNeagtiveItems : ''}</b> </span>
                      <Tooltip title="These categories are the reasons for why your score is not higher.">
                        <span><img alt="#" src={require("../../../assets/images/info-icon.png")} /></span></Tooltip></Typography>
                  </Grid>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                    <Sheet variant="outlined">
                      <Table  sx={{ width: "auto", minWidth: "300px" }} className="tables-credit">
                        <TableBody>
                          <TableRow>
                          <TableCell style={{ width: "80%" }}>Collections</TableCell>
                          <TableCell style={{ width: "20%", textAlign: "center" }}>{score ? score.collections : ''}</TableCell>
                          </TableRow>
                        <TableRow>
                          <TableCell>Public Record</TableCell>
                          <TableCell style={{ textAlign: "center" }}>{score ? score.publicRecord : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Charge Offs</TableCell>
                          <TableCell style={{ textAlign: "center" }}>{score ? score.chargeOffs : ''}</TableCell>
                        </TableRow>
                        </TableBody>
                      </Table>
                    </Sheet>
                  </Grid>
                  <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
                    <Sheet variant="outlined">
                      <Table  sx={{ width: "auto", minWidth: "300px" }} className="tables-credit">
                      <TableBody>
                          <TableRow>
                          <TableCell style={{ width: "80%" }}>Credit Available</TableCell>
                          <TableCell style={{ width: "20%", textAlign: "center" }}>{score ? score.creditAvailable : 0}%</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Inquiries</TableCell>
                          <TableCell style={{ textAlign: "center" }}>{score ? score.inquiries : ''}</TableCell>
                        </TableRow>
                        </TableBody>
                      </Table>
                    </Sheet>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid item lg={10} md={10} sm={12} xs={12} sx={{ textAlign: "center" }}>
                <img alt="#" src={logourl} className="marginScore" />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }} className="logout">
                <Button onClick={handleClickOpen}><Typography><span><LogoutIcon /></span>Logout</Typography></Button>
								<Grid container item lg={6} md={6} sm={12} xs={12} direction="row" className="userwayLoginJustify" >
									<Stack id="userwayBtn" tabIndex="0" direction="row" className="userwayLogin" >                                
										<LanguageIcon />
										<Typography>en/esp/fr/...</Typography>                                
									</Stack> 
								</Grid>
              </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} p={3}>
              <Typography level="h4" className="textCenter"><b>NEED HELP IMPROVING YOUR CREDIT?</b></Typography>
            </Grid>
            {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={3} className="marginScore">
              <span><img alt="#" src={require("../../../assets/images/scorephone.png")} /></span><Typography sx={{ color: "#5288db" }} level="h2" className="textCenter fontUs">Call us now! {formatPhoneNumber(score ? score.referralPhone : '')}</Typography>
            </Grid> */}
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={3} className="marginScore">
              <Typography sx={{ color: "#5288db;",textAlign:"center" }} level="h4" className="textCenter fontUs"><b>YOU CAN IMPROVE YOUR SCORES</b></Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
              <Typography sx={{ color: "#5288db;" }} level="h4" className="fontUs"><u><i><b>WE CAN HELP!</b></i></u></Typography>
            </Grid>
            {/* <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={3} className="marginScore">
              <Typography level="h4" className="textCenter fontUs" sx={{textAlign:"center"}}>Call for a consultation with a credit specialist</Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Card sx={{ backgroundColor: "#5cb85c", borderRadius: 0, marginTop: 1 }}>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={5} md={5} sm={12} xs={12} sx={{textAlign:"center"}}>
              <Typography level="h2" sx={{ color: "white" }} className="textCenter">There is much more to see!<br /><span style={{fontWeight:700}}> SCORES CAN VARY </span><br /><span style={{fontWeight:700}}>SIGNIFICANTLY BY BUREAU</span></Typography>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12} sx={{ justifyContent: "center" }}>
              <Card sx={{ borderRadius: 0, backgroundColor: "white" }} >
                <Grid container item lg={12} md={12} xs={12} sm={12}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                        <Typography level="h5" sx={{ color: "#5cb85c", textAlign: "center" }}>Get Access to your </Typography>
                        </Grid>
                        <Grid>
                        <Typography level="h3" sx={{ color: "#5cb85c", textAlign: "center" }}>
                            Three Bureau Reports and Scores
                        </Typography>
                        </Grid>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                        <img alt="#" src={require('../../../assets/images/bureau-logos.png')} className="bureau-logos" />
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}  className="marginScoress" mt={3}>
                            {score.upgradeTo3bFlag == 0 ?
                            <Button variant="contained"
                            size="large"
                            color="warning"
                            sx={{ backgroundColor: "#FF9400", borderColor: "#FF9400", minWidth: 150, minHeight: 40,fontSize:"20px",fontWeight:"bold",
                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                            onClick={() => { Upgradeto3B() }}
                            >
                            Take me to my <br/>scores now
                            </Button>
                            :
                            <>
                                <form method="POST" action={config.API_URL+'/api/member/view3B'} target="_blank">
                                    <input type="hidden" name="username" value={(score?btoa(score.userObj.email):'')} />
                                    <input type="hidden" name="password" value={score?score.userObj?score.userObj.password:'':''} />  
                                    <Button variant="contained"
                                      size="large"
                                      color="warning"
                                      sx={{ backgroundColor: "#FF9400", borderColor: "#FF9400", minWidth: 150, minHeight: 40 ,fontSize:"20px",fontWeight:"bold",
                                      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                                      // href={config.API_URL + '/member/view3B/' + btoa(score ? score.userObj.email : '') + '/' + (score ? score.userObj ? score.userObj.password : '' : '')}
                                      // target="_blank"
                                      type="submit"
                                      >
                                      Take me to my <br/> scores now
                                      </Button>
                                    {/* <Tooltip title="View 3B" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></button></Tooltip> */}
                                </form>
                            </>
                            
                        }
           
                        </Grid>
                    </Grid>
                </Grid>
                
              </Card>
            </Grid>
            
           
          </Grid>
        </Card>
       
        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} mt={1}>
          <Typography level="h5" sx={{ fontWeight: "bold" }} className="textCenter">With Enrollment in 7/24 Credit Monitoring</Typography>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={freez}
          >
            <Freez />
          </Backdrop>
        </div>
        <Grid>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
              <Typography level="h2"><span style={{ color: "red" }}><i>WAIT!</i></span> READY TO SEE IT ALL? </Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
              <Typography level="h5" sx={{ color: "#76C044" }}>You've taken the first step to staying on top of your credit - DON'T STOP HERE!</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
              <Typography level="h4" sx={{ color: "#2D6DB4", fontWeight: 900 }}>SCORES CAN VERY BY BUREAU. CHECK YOUR SCORES FROM <u>ALL 3 BUREAUS NOW!</u></Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container item mt={3} spacing={3}>
            <Grid item xs sx={{ textAlign: "center" }}>
              {score.upgradeTo3bFlag == 0 ?
                <Button variant="contained"
                  size="large"
                  color="warning"
                  sx={{ backgroundColor: "#FF9400", borderColor: "#FF9400", minWidth: 300, minHeight: 40 }}
                  onClick={() => { Upgradeto3B() }}
                >
                  Take me to my scores now
                </Button>
                :
                <>
                  <form method="POST" action={config.API_URL+'/api/member/view3B'} target="_blank">
                      <input type="hidden" name="username" value={btoa(score?score.userObj.email:'')} />
                      <input type="hidden" name="password" value={score?score.userObj?score.userObj.password:'':''} />  
                      <Button variant="contained"
                        size="large"
                        color="warning"
                        sx={{ backgroundColor: "#FF9400", borderColor: "#FF9400", minWidth: 300, minHeight: 40 }}
                        // href={config.API_URL + '/member/view3B/' + btoa(score ? score.userObj.email : '') + '/' + (score ? score.userObj ? score.userObj.password : '' : '')}
                        // target="_blank"
                        type="submit"
                      >
                        Take me to my scores now
                      </Button>
                      {/* <Tooltip title="View 3B" arrow><button type='submit' style={{border:"none",background:"none",borderRadius:"0%",padding:'0px'}}><img src={require('../../assets/icons/view3B.png')} /></button></Tooltip> */}
                  </form>
              </>
                
              }
            </Grid>
            <Grid item xs sx={{ textAlign: "center" }}>
              <Button onClick={handleLogout} variant="contained" size="large" color="success" sx={{ minWidth: 250, minHeight: 40, backgroundColor: "#76C044", borderColor: "#76C044" }}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

        {/* loader code start */}
        <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </>
  )
}
export default SnapshotScore;