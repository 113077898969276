import React from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { CardOverflow, Card, CardContent,Typography } from '@mui/joy';
import {  Grid, TextField, Button } from '@mui/material';
function EditingUser(props) {
    function ContentLayout() {
        return (
            <>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                <Grid item lg={9} md={9} sm={9} xs={9}>
                    <Typography level='h4' color="primary">Editing user : testpartneruser11 testdas</Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3} sx={{textAlign:"right"}}>
                    <img src={require('../../../assets/images/BackIcon.png')} />
                </Grid>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                <Typography>User type: partner_user</Typography>
            </Grid>
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                <Typography>Leave password field blank to leave it unchanged</Typography>
            </Grid>
            <Card variant='outlined' sx={{marginTop:"1%"}}>
                <CardOverflow
                sx={{bgcolor: '#F6FAFF',
                borderColor: '#F6FAFF',
                padding: "1%"}}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography level="h5">Editing user</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6} sx={{textAlign:"right"}}>
                        <img src={require('../../../assets/icons/delete.png')} />
                        </Grid>
                    </Grid>
                </CardOverflow>
                <CardContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12}  spacing={2} mt={1}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField id="outlined-basic" label="First Name" variant="outlined" fullWidth size="small" margin='dense' />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField id="outlined-basic" label="Last Name" variant="outlined" fullWidth size="small" margin='dense' />
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12}  spacing={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField id="outlined-basic" label="E-mail Address" variant="outlined" fullWidth size="small" margin='dense' />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField id="outlined-basic" label="Phone" variant="outlined" fullWidth size="small" margin='dense' />
                        </Grid>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField id="outlined-basic" label="Password" variant="outlined" fullWidth size="small" margin='dense' />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Grid container item lg={12} md={12} sm={12} xs={12}  sx={{ justifyContent: "space-between" }}>
                                    <Grid item lg={6} md={6} sm={6} xs={12} mt={1}>
                                        <Button variant='contained' color='success' margin="dense" className="buttons" sx={{ backgroundColor: "#76C044", color: "white", minWidth: 300 }}>Submit</Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{ textAlign: "right" }} mt={1}>
                                        <Button variant='contained' color='primary' margin="dense" className="buttons" sx={{ backgroundColor: "#2A68B3", color: "white", minWidth: 300 }}>Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                </CardContent>
            </Card>
            </>
            )
    }
    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
            
        </div>
    );

}

export default EditingUser;