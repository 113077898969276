import React, { useEffect,useState } from "react";
import { useDispatch } from 'react-redux';
import { Typography,Box, Grid, Button,IconButton } from "@mui/material";
import Moment from 'moment';
import { fetchData, postData } from '../../../../redux/apiSlice';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MaterialReactTable from 'material-react-table';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";
import UpdateBankForm from "../../CommonComponent/BankDetail/UpdateBankForm";

let htmlData ='';
const BankDetailsTab = ({fetchCompanyDetails,company_details,bank_details,user,checkUserRole,filterhistory,fetchCompanyBankDetails,handleFreezStart,handleFreezStop}) => {
    const dispatch = useDispatch();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [open, setOpen] = useState(false); 
    const [updatedAttributes, setUpdatedAttributes] = useState([]);
    const [operation, setOperation] = useState("Update");
    const [btntext, setBtntext] = useState("Update");
    const [bankName, setBankName] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [routingNumberError, setRoutingNumberError] = useState(true);
    const [routingNumberPreviousValue, setRoutingNumberPreviousValue] = useState(false);
    const [viewBankHistory, setViewBankHistory] = useState([]);
    const [openbank, setOpenbank] = useState(false);
    const handleClose = () => setOpen(false);
    const handlebankClose = () => setOpenbank(false);
    const handlebankOpen = () => setOpenbank(true);
    const [currentDate, setCurrentDate] = useState("");
    const handleOpenUpdate = () => {
        setOpen(true);
    };
    useEffect(() => {
        const myDate = new Date();
        let cDate = Moment(myDate).tz("America/Los_Angeles").format("MM-DD-YY");
        setCurrentDate(cDate); 
    }, []); 
    useEffect(() => {
        const fetchBankDetails = async () => {
            if (bank_details) {
                setUpdatedAttributes(bank_details.updated_attributes);
                htmlData = `
                <p>Dear ${bank_details.company_name},</p>
                <p>The bank details you provided could not be verified with your bank. Please login to your affiliate account and update your account information in bank details.</p>
                <p>If you have any questions or if there is anything we can do to help you, please feel free to contact us:</p>
                <p>info@myfreescorenow.com</p>
                <p>888-548-2008</p>
                <p>Thank You!</p>`;
                setBtntext(bank_details.report_issue == 1 ? (user.roles_id < 3 ? "Update And Resolve" : "Update") : "Update");
                setOperation("Update");
                if (bank_details.routing_number) {
                    let routingNumberOriginal = bank_details.routing_number;
                    let routingNumberUpdated = bank_details.routing_number_updated;
                    let bankNameOriginal = bank_details.bank_name;
                    const apiUrl = `/bankname/${routingNumberOriginal}`;
                    if (routingNumberOriginal.length === 9) {
                        try {
                            const res = await dispatch(fetchData(apiUrl)).unwrap();
                            if (res.success) {
                                setBankName(res.data);
                                setRoutingNumberError(true);
                                if(routingNumberUpdated == 1) {
                                    setRoutingNumberError(true);
                                    setRoutingNumber(routingNumberOriginal);
                                    setBankName(bankNameOriginal);
                                    setRoutingNumberPreviousValue(true);
                                }
                            } else {
                                setBankName(bankNameOriginal);
                                if (routingNumberUpdated == 1) {
                                    setRoutingNumberError(true);
                                    setRoutingNumber(routingNumberOriginal);
                                    setRoutingNumberPreviousValue(true);
                                } else {
                                    setRoutingNumberError(false);
                                }
                            }
                        } catch (error) {
                            console.error("Error fetching bank details:", error);
                        }
                    }
                }
            } else {
                setOperation("Create");
                setBtntext("Submit");
            }
        };

        fetchBankDetails();
    }, [bank_details, user, dispatch]);

    const columns = [
        {
            accessorFn: (row) => `${row.attributes}`,
            id: 'updated_fields', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated Fields',
            size: 250,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{
                        row.original.attributes ?
                            row.original.new_values ?
                                row.original.new_values.split(',').length != row.original.attributes.split(',').length ?
                                    <>
                                    {row.original.attributes.replace(",ACH Date", '') } &nbsp;
                                    { (row.original.new_values && row.original.new_values !== '1') && row.original.bank_attribute_id ? <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span> : ''}
                                    </>
                                :
                                <>
                                { row.original.attributes}&nbsp;
                                { (row.original.attributes != "Ach Info Added To Bank") && row.original.bank_attribute_id ? <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span>: ''}
                                </> 
                            :
                            <>
                            { row.original.attributes }&nbsp;
                            { (row.original.attributes != "Ach Info Added To Bank") && row.original.bank_attribute_id ?  <span style={{ position: 'relative', top: '2px' }}><InfoIcon sx={{ fontSize: '18px' }} color="primary"  onClick={() => { fetchHistoryDetails(`${row.original.bank_attribute_id}`);  }}/></span>: ''}
                            </> 
                            
                        : "No fields has been updated in this operation."}</span>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.name}`,
            id: 'updated_by', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated By',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.name}</span>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.role_name}`,
            id: 'user_role', //id is still required when using accessorFn instead of accessorKey
            header: 'User Role',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.role_name}</span>
                </Box>
            ),
        },
        
        {
            accessorFn: (row) => `${row.attributes ? row.attributes.includes("ACH Date") ? (row.new_values != null ? row.new_values.split(',').length == row.attributes.split(',').length ?  row.new_values.split(',')[row.new_values.split(',').length - 2] != 0 ? (row.new_values.split(',')[row.new_values.split(',').length - 1]) :'' :'' :''): '': ''}`,
            id: 'last_ach_updated', //id is still required when using accessorFn instead of accessorKey
            header: 'Added ACH Date',
            size: 100,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.attributes ? row.original.attributes.includes("ACH Date") ? (row.original.new_values != null ? row.original.new_values.split(',').length == row.original.attributes.split(',').length ?  row.original.new_values.split(',')[row.original.new_values.split(',').length - 2] != 0 ? Moment(row.original.new_values.split(',')[row.original.new_values.split(',').length - 1]).format('MM-DD-YYYY') :'' :'' :''): '': '' }</span>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.created_at}`,
            id: 'updated_date', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated Date',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.created_at?Moment(row.original.created_at).format('MM-DD-YYYY'):'N/A'}</span>
                </Box>
            ),
        },
    ];

    const fetchHistoryDetails = async (bank_attribute_id) => {
        const apiUrl = "/fetch-bank-history-details/"+ bank_attribute_id;
        handleFreezStart();
        const args = {}
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res.success)
        {
            handleFreezStop();
            setViewBankHistory(res.data);
            handlebankOpen();
        }
        else
        {
            handleFreezStop();
            setViewBankHistory([]);
        }
    };
    const filterHistoryColumns =  [
        {
            accessorFn: (row) => `${row.id}`, 
            id: 'id', 
            header: 'Attribute Name',
            numeric: true,
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.attribute_name ? row.original.attribute_name.display_name  : ''}</span>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.attribute_old_value}`,
            id: 'attribute_old_value', //id is still required when using accessorFn instead of accessorKey
            header: 'Previous Value',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.attribute_name.attribute_name == "name_on_account" ? row.original.attribute_old_value !== null ? row.original.attribute_old_value.indexOf(':')  >= 0 ? row.original.attribute_old_value.replace(/:/g, " ") : row.original.attribute_old_value :  row.original.attribute_old_value :  row.original.attribute_old_value}</span>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.attribute_new_value}`,
            id: 'attribute_new_value', //id is still required when using accessorFn instead of accessorKey
            header: 'Updated Value',
            size: 150,
            Cell: ({ renderedCellValue, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <span>{row.original.attribute_name.attribute_name == "name_on_account" ? row.original.attribute_new_value !== null ? row.original.attribute_new_value.indexOf(':')  >= 0 ? row.original.attribute_new_value.replace(/:/g, " ") : row.original.attribute_new_value : row.original.attribute_new_value : row.original.attribute_new_value}</span>
                </Box>
            ),
        },
    ];

    return(
        <>
            <Box className="common-container">
                {/* Row 1 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Bank Name :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.bank_name ? bank_details.bank_name : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Account Number :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.account_number ? bank_details.account_number : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Name on Account :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.name_on_account ? bank_details.name_on_account.replace(':', ' ') : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2">Bank Routing Number :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.routing_number ? bank_details.routing_number : "N/A"}</Typography>
                    </Grid>
                </Grid>
                {/* Row 2 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2" >ACH Added :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.ach_info_added_to_bank ? bank_details.ach_info_added_to_bank == 1 ? "Added" : "Not Added" : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >ACH Added On :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.last_ach_updated ? Moment(bank_details.last_ach_updated).format('MM-DD-YYYY') : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >ACH Updated On :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.last_ach_updated ? Moment(bank_details.last_ach_updated).format('MM-DD-YYYY') : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2">Email Address to Notify : </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.email_to_notify ? bank_details.email_to_notify : "N/A"}</Typography>
                    </Grid>
                </Grid>
                {/* Row 3 */}
                <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2">Type Of Account : </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.account_type ? bank_details.account_type : "N/A"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" >Is Account :</Typography>
                        <Typography variant="subtitle1" fontWeight="bold" className="common-value">{bank_details.account_category ? bank_details.account_category : "N/A"}</Typography>
                    </Grid>
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {user.roles_id <= 3 ?
                        <Button variant="contained" className="create-button" onClick={handleOpenUpdate}>
                            Update
                        </Button>
                    : ""}
                </Box>
            </Box>

            <UpdateBankForm open1={open} handleClose={handleClose} bankDetails={bank_details} updatedAttributes={updatedAttributes} filterhistory={filterhistory} fetchCompanyBankDetails={fetchCompanyBankDetails} btntext={btntext} htmlData={htmlData} currentDate={currentDate} companyName={bank_details.company_name} operation={operation} bankName={bankName} routingNumberErr={routingNumberError} routingNumberOriginal={routingNumber} routingNumberPreviousValue={routingNumberPreviousValue} handleFreezStart={handleFreezStart} handleFreezStop={handleFreezStop} fetchCompanyDetails={fetchCompanyDetails}/>

            {user.roles_id < 3 && (
                <>
                    <Grid  item lg={12} md={12} sm={12} xs={12} mt={4} mb={2}>
                        <Grid container item >
                            <Typography variant="h6" fontWeight="bold">Bank Details Update History</Typography>
                        </Grid>
                    </Grid>
                    <MaterialReactTable
                        columns={columns}
                        data={filterhistory}
                        enablePagination
                        enableColumnFilters
                        enableSorting
                        enableGlobalFilter
                        enableRowSelection={false}
                        muiTablePaperProps={{
                            sx: { backgroundColor: "white" },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                backgroundColor: "#2A68B3",
                                color: "white",
                                fontWeight: "bold",
                            },
                        }}
                        muiTableBodyRowProps={({ index }) => ({
                            sx: { backgroundColor: index % 2 === 0 ? "white" : "#F6FAFF" },
                        })}
                    />
                </>
            )}
            {/* open Last updated field */}
            <Dialog
                open={openbank}
                onClose={handlebankClose}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                PaperProps={{
                    sx: {
                        borderRadius: "12px",
                        padding: "2px"
                    }
                }}
            >
                <DialogTitle className="dialog-title" >
                    <Typography variant="h6" fontWeight="bold">Bank Details Update History </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" fontWeight="bold">
                            Updated Date: {viewBankHistory[0]?.created_at ? 
                                Moment(viewBankHistory[0].created_at).tz('America/Los_Angeles').format('MM-DD-YYYY') : 'N/A'}
                        </Typography>
                        <IconButton onClick={handlebankClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <MaterialReactTable
                        columns={filterHistoryColumns}
                        data={viewBankHistory}
                        enablePagination
                        enableColumnFilters
                        enableSorting
                        enableGlobalFilter
                        enableRowSelection={false}
                        muiTablePaperProps={{
                            sx: { backgroundColor: "white" },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                backgroundColor: "#2A68B3",
                                color: "white",
                                fontWeight: "bold",
                            },
                        }}
                        muiTableBodyRowProps={({ index }) => ({
                            sx: { backgroundColor: index % 2 === 0 ? "white" : "#F6FAFF" },
                        })}
                    />
                </DialogContent>
            </Dialog>
        </>
       
    );
}

export default BankDetailsTab;