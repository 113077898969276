import React, { useState } from "react";
import {
    TextField,
    Grid,
    InputLabel,Typography
} from "@mui/material";
import {Box,Button  } from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEffect } from "react";

const OperationalHours = ({ onValueChange, props }) => {

    const [shiftTimings, setShiftTimings] = useState(props?(JSON.parse(JSON.stringify(props))):([{ label: "Monday-Friday(Weekdays)", timings: [{ start: "09:00", end: "18:00" }] },
    { label: "Saturday-Sunday(Weekends)", timings: [{ start: "09:00", end: "18:00" }] }]));
    const [btnText,setBtnText] = useState(shiftTimings.length == 2 ? "Close On Weekend" : "Open On Weekend");
    const handleLabelChange = (index) => (event) => {
        const newTimings = [...shiftTimings];
        newTimings[index].label = event.target.value;
        setShiftTimings(newTimings);
        onValueChange(newTimings);
    };
    const handleStartChange = (labelIndex, timingIndex) => (event) => {
        const newTimings = [...shiftTimings];
        const newStartTime = event.target.value;
        const endTime = newTimings[labelIndex].timings[timingIndex].end;
        const [startHours, startMinutes, startAMPM] = parseTime(newStartTime);
        const [endHours, endMinutes, endAMPM] = parseTime(endTime);

        if (newStartTime && endTime) {
            if (
                (startHours > endHours || (startHours === endHours && startMinutes >= endMinutes ))
            ) {
                alert("Start Time must be less than End Time");
            } else {
                newTimings[labelIndex].timings[timingIndex].start = event.target.value;
                setShiftTimings(newTimings);
                onValueChange(newTimings);
            }
        } else {
            newTimings[labelIndex].timings[timingIndex].start = event.target.value;
            setShiftTimings(newTimings);
            onValueChange(newTimings);
        }
    };

    const handleEndChange = (labelIndex, timingIndex) => (event) => {
        const newTimings = [...shiftTimings];
        const newEndTime = event.target.value;
        const startTime = newTimings[labelIndex].timings[timingIndex].start;
        const [startHours, startMinutes, startAMPM] = parseTime(startTime);
        const [endHours, endMinutes, endAMPM] = parseTime(newEndTime);
     
        if (newEndTime && startTime) {
            if
                (endHours < startHours || ((endHours === startHours && endMinutes <= startMinutes))
            ) {
                alert("End Time must be greater than Start Time");
            } else {
                newTimings[labelIndex].timings[timingIndex].end = event.target.value;
                setShiftTimings(newTimings);
                onValueChange(newTimings);
            }
        } else {
            newTimings[labelIndex].timings[timingIndex].end = event.target.value;
            setShiftTimings(newTimings);
            onValueChange(newTimings);
        }
    };
    const parseTime = (timeString) => {
        const [time, ampm] = timeString.split(' ');
        const [hours, minutes] = time.split(':').map(Number);
        return [hours, minutes, ampm];
    };
    // function compareTimes(time1, time2) {
    //     const [hours1, minutes1] = time1.split(":").map(Number);
    //     const [hours2, minutes2] = time2.split(":").map(Number);

    //     if (hours1 < hours2) {
    //         return -1;
    //     } else if (hours1 > hours2) {
    //         return 1;
    //     } else {
    //         if (minutes1 < minutes2) {
    //             return -1;
    //         } else if (minutes1 > minutes2) {
    //             return 1;
    //         } else {
    //             return 0;
    //         }
    //     }
    // }
    const handleAddTiming = (index) => () => {
        const newTimings = [...shiftTimings];
        const lastElement = shiftTimings[index]['timings'][shiftTimings[index]['timings'].length - 1];
        if (lastElement.start === "" || lastElement.end === "") {
            alert("Please fill previous shift first")
        }
        else {
            newTimings[index].timings.push({ start: "", end: "" });
            setShiftTimings(newTimings);
            onValueChange(newTimings);
        }
    };

    const handleRemoveTiming = (labelIndex, timingIndex) => () => {
        const newTimings = [...shiftTimings];
        newTimings[labelIndex].timings.splice(timingIndex, 1);
        setShiftTimings(newTimings);
        onValueChange(newTimings)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission here
    };

    const removeOrAddWeekendDays  = () => {
        if(btnText == "Close On Weekend") {
            var temp = shiftTimings;
            const newItems = temp.slice(0, -1);   
            setShiftTimings(newItems);
            onValueChange(newItems);
            setBtnText("Open On Weekend");
        }else {
            var temp = shiftTimings;
            var temp2 = { label: "Saturday-Sunday(Weekends)", timings: [{ start: "09:00", end: "18:00" }] };
            temp.push(temp2);
            setShiftTimings(temp);
            onValueChange(temp);
            setBtnText("Close On Weekend");
        }
       
    };

    return (
        <Grid item md={12} container >          
            <Grid container item md={12} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid container item md={12} sx={{display: "flex",justifyContent: "flex-end"}} className="operationalTime-btn">
                    <Button  variant="contained" sx={{minWidth:"20%"}} size="sm" color="success" onClick={removeOrAddWeekendDays} >
                        {btnText}
                    </Button>
                </Grid>
                {shiftTimings.map((shiftTiming, labelIndex) => (
                    <Grid item md={6} container key={labelIndex} spacing={1} mt={2} sx={{ alignSelf: "flex-start" }}>
                        <Grid container item md={12}>
                            <InputLabel id="demo-simple-select-label" sx={{ fontWeight: 'bold' }}>{shiftTiming.label}</InputLabel>
                        </Grid>
                        {shiftTiming.timings.map((timing, timingIndex) => (
                            <Grid container item md={12} spacing={2}  key={`${labelIndex}-${timingIndex}`}>
                                <Grid item xs={5}>
                                    <TextField
                                        id={`start-${labelIndex}-${timingIndex}`}
                                        label="Start Time"
                                        type="time"
                                        value={timing.start}
                                        onChange={handleStartChange(labelIndex, timingIndex)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField
                                        id={`end-${labelIndex}-${timingIndex}`}
                                        label="End Time"
                                        type="time"
                                        value={timing.end}
                                        onChange={handleEndChange(labelIndex, timingIndex)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    {timingIndex < 4 && labelIndex == 0 &&
                                        <AddCircleOutlineIcon fontSize="medium" onClick={handleAddTiming(labelIndex)} sx={{ cursor: 'pointer' }} />
                                    }
                                    {timingIndex < 1 && labelIndex == 1 &&
                                        <AddCircleOutlineIcon fontSize="medium"
                                            onClick={handleAddTiming(labelIndex)}
                                            sx={{ cursor: 'pointer' }}
                                        />
                                    }
                                    {timingIndex > 0 && (
                                        <DeleteOutline fontSize="medium" onClick={handleRemoveTiming(labelIndex, timingIndex)} sx={{ cursor: 'pointer' }} />
                                    )}
                                </Grid>
                                
                            </Grid>
                        ))}
                       
                    </Grid>
                ))}
                {
                    shiftTimings.length == 1 ?
                    <>
                        <Grid item md={6} sx={{display: "flex", justifyContent: "center" }}>
                        <Typography level="h4" sx={{ fontWeight: "bold"}}>
                            Closed On Weekend
                        </Typography>
                    
                        </Grid>
                    </>
                
                : ""
                }
            </Grid>
        </Grid>
    )
}

export default OperationalHours;
