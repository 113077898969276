import React, { useMemo, useState, useEffect } from 'react'
import AuthUser from '../../../Components/Auth/AuthUser';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Box, Stack, Grid, MenuItem, OutlinedInput, Select, TableHead,FormControl, InputLabel, Button, Card, TableBody, Table, TableRow, TableCell, } from "@mui/material";
import MaterialReactTable from 'material-react-table';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import { Typography } from '@mui/joy';
import { Chart } from "react-google-charts";
import { CardOverflow } from '@mui/joy';
import { format, subDays, isAfter } from 'date-fns';
import Backdrop from '@mui/material/Backdrop';
import { postData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import Freez from '../../../Components/Loader/Loader';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import config from '../../../AppConfig';
import { useNavigate } from 'react-router-dom';
import { MenuProps, useStyles } from "../../../Components/SuspendedMembers/utils";
import Moment from 'moment';
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';

export const options2 = {
    // chart: {
    //  title: "Commission & Enrollment ",
    //  subtitle: "Commissions, Enrollments: 0-100",
    colors: ["#76C044", "#2A68B3"],
    // hAxis: { title: "Commissions"},
    // vAxis: { title: "Enrollments"},
    // },
};
export const data1 = [
    ["City", "2010 Population", "2000 Population"],
    ["New York City, NY", 8175000, 8008000],
    ["Los Angeles, CA", 3792000, 3694000],
    ["Chicago, IL", 2695000, 2896000],
    ["Houston, TX", 2099000, 1953000],
    ["Philadelphia, PA", 1526000, 1517000],
];

let totalData = '';
let totalRetention = '';
let source = 'all';
function RetentionDashboard(props) {
    const dispatch = useDispatch();
    const { user } = AuthUser();
    const navigate = useNavigate();
    const [retentionData, setRetentionData] = useState([]);
    const [retentionGraph, setGraphData] = useState([]);
    const [AbandonedGraphData, setAbandonedGraphData] = useState([]);
    const [abandonedData, setAbandonedData] = useState([]);
    const [AbandonedLifeTime , setAbandonedLifeTime] = useState([]);
    const [Abandonedtotalpayment, setAbandonedtotalpayment] = useState('');
    const [AbandonedtotaltotalIdVerification, setAbandonedtotaltotalIdVerification] = useState('');
    // const[source,setAssignedToSource]= useState('all');
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (Object.values(columnVisibility).length > 0) {
            setColumnVisibility(columnVisibility);
        }
        //do something when the row selection changes...
        // console.info({ rowSelection });
    }, [rowSelection]);
    useEffect(() => {
        if (user.roles_id < 3) {
            getRetentionDashboard();
        } else {
            navigate('/login');
        }
    }, []);
   
    const options1 = {
        colors: ["#76C044", "#2A68B3"],
        seriesType: "bars",
        hAxis: {
            slantedText: true,
            slantedTextAngle: -20,
            textStyle: {
                fontSize: 9 // Adjust the font size as needed
            },
            format: '####'
        },
        focusTarget: 'category',
        tooltip: { isHtml: true },
        bar: retentionGraph.length <= 2 ? { groupWidth: "10%" } : { groupWidth: "50%" },
        chartArea: {
            width: "80%", // Adjust as needed
        },
    }

    const currentDate = new Date();
    const maxDate = isAfter(currentDate, selectionRange[0].endDate) ? currentDate : selectionRange[0].endDate;

    const getRetentionDashboard = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
        handleFreezStart();
        const apiUrl = '/retentionDashboardData';
        const args = {
            fromDate: startDate,
            toDate: endDate,
            source: source,
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        setIsLoading(false);
        handleFreezStop();
        totalData = res.data.abandonedtotal;
        totalRetention = res.data.total;
        // setAbandonedTotalData(res.data.abandonedtotal);
        setRetentionData(res.data.agentData);
        setAbandonedData(res.data.abandonedagentData);
        setAbandonedtotalpayment(res.data.abandonedtotal.totalpayment);
        setAbandonedtotaltotalIdVerification(res.data.abandonedtotal.totalIdVerification);
        setGraphData(res.data.graphData);
        setAbandonedGraphData(res.data.abandonedgraphData);
        setAbandonedLifeTime(res.data.abandonedMemberLifeTime);


    }

    const sideBar = calendarFilter();

    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];

    const handleSourceChange = (event) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
        let sourceValue = event.target.value;
        source = sourceValue;
        // setAssignedToSource(sourceValue);
        getRetentionDashboard();
    };

    // const getRetentionDashboard = async () =>{
    //     handleFreezStart();
    //     http.post('/retentionDashboardData', {
    //       fromDate: startDate,
    //       toDate: endDate
    //     }).then((res) => {
    //         handleFreezStop();
    //         console.log(res.data.data)
    //         setRetentionData(res.data.data.agentData);
    //         setGraphData(res.data.data.graphData);
    //         // 
    //     })

    // } totalRetention


    const columns = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column
                // header: 'Employee',
                columns: [
                    {
                        accessorFn: (row) => `${row.agent_name}`, //accessorFn used to join multiple data into a single cell
                        id: 'name', //id is still required when using accessorFn instead of accessorKey
                        header: 'Agent Name',
                        size: 250,
                        Footer: () => (
                            <> Total:</>
                        ),
                    },
                    {
                        accessorFn: (row) => `${row.savable}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        header: 'Savable',
                        size: 300,
                        Footer: () => (
                            <>
                            <Box color="text.primary">{totalRetention ? totalRetention.savable : 0}</Box>
                            </>
                        ),
                    },
                    {
                        accessorFn: (row) => `${row.saves}`, //accessorFn used to join multiple data into a single cell
                        id: 'Saves', //id is still required when using accessorFn instead of accessorKey
                        header: 'Saves',
                        size: 250,
                        Footer: () => (
                            <>
                            <Box color="text.primary">{totalRetention ? totalRetention.saves : 0}</Box>
                            </>
                        ),
                    },
                    {
                        accessorFn: (row) => `${row.cancelled}`, //accessorFn used to join multiple data into a single cell
                        id: 'Cancels', //id is still required when using accessorFn instead of accessorKey
                        header: 'Cancels',
                        size: 250,
                        Footer: () => (
                            <>
                            <Box color="text.primary">{totalRetention ? totalRetention.cancels : 0}</Box>
                            </>
                        ),
                    },
                    {
                        accessorFn: (row) => `${row.percentage}%`,  //accessorFn used to join multiple data into a single cell
                        id: 'Save Percent', //id is still required when using accessorFn instead of accessorKey
                        header: 'Save Percent',
                        size: 250,
                        Footer: () => (
                            <>
                            <Box color="text.primary">{totalRetention ? totalRetention.percentage : 0}%</Box>
                            </>
                        ),
                    },
                ],
            },
        ],
        [],
    );

    const columns2 = useMemo(
        () => [
            {
                id: 'employee', //id used to define `group` column
                // header: 'Employee',

                columns: [
                    {
                        accessorFn: (row) => `${row.agent_name}`, //accessorFn used to join multiple data into a single cell
                        id: 'name', //id is still required when using accessorFn instead of accessorKey
                        header: 'Agent Name',
                        size: 100,
                        muiTableHeadCellProps: () => ({
                            sx: {
                                backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                borderRight: 1, borderRightColor: 'grey.500'
                            },
                        }),
                        Footer: () => (
                            <Stack sx={{ color: "text.primary", borderRight: 1, borderRightColor: 'grey.500' }}>Total</Stack>
                        ),
                        muiTableHeadCellProps: () => ({
                            sx: {
                                borderRight: 1, borderRightColor: 'grey.500'
                            },
                        }),
                        muiTableBodyCellProps: () => ({
                            sx: {
                                borderRight: 1, borderRightColor: 'grey.500'
                            },
                        }),
                    },

                    {
                        // accessorFn: (row) => `${row.totalcalls}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        header: 'Calls',
                        muiTableHeadCellProps: () => ({
                            sx: {
                                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                                borderRight: 1, borderRightColor: 'grey.500'
                            },
                        }),
                        size: 150,

                        columns: [

                            {
                                accessorFn: (row) => `${row.totalIdVerification}`, //accessorFn used to join multiple data into a single cell
                                id: 'ID Verifications', //id is still required when using accessorFn instead of accessorKey
                                header: 'ID Verification',
                                size: 50,
                                Footer: () => (
                                    <>
                                    <Box color="text.primary" >{totalData ? totalData.totalIdVerification : 0}</Box>
                                    </>
                                ),
                                muiTableHeadCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                    },
                                }),
                                muiTableBodyCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                    },
                                }),
                            },
                            {
                                accessorFn: (row) => `${row.totalpayment}`, //accessorFn used to join multiple data into a single cell
                                id: 'Payments', //id is still required when using accessorFn instead of accessorKey
                                header: 'Payment',
                                size: 50,
                                Footer: (row) => (
                                    <>
                                    <Box color="text.primary">{totalData ? totalData.totalpayment : 0}</Box>
                                    </>
                                ),
                                muiTableHeadCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                    },
                                }),
                                muiTableBodyCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                    },
                                }),
                            },
                            {
                                accessorFn: (row) => `${row.totalcalls}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                                enableClickToCopy: true,
                                header: 'Total',
                                id: 'Total call', //id is still required when using accessorFn instead of accessorKey
                                size: 50,
                                Footer: () => (
                                    <Stack className='retention-table' sx={{ color: "text.primary", borderRight: 1, borderRightColor: 'grey.500' }}>{totalData ? totalData.abandonedotalcalls : 0}</Stack>
                                ),
                                muiTableHeadCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                        borderRight: 1, borderRightColor: 'grey.500'
                                    },
                                }),
                                muiTableBodyCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                        borderRight: 1, borderRightColor: 'grey.500'
                                    },
                                }),
                            },


                        ]
                    },
                    {
                        // accessorFn: (row) => `${row.saves}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        header: 'Contacted',
                        size: 150,
                        muiTableHeadCellProps: () => ({
                            sx: {
                                backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                borderRight: 1, borderRightColor: 'grey.500'
                            },
                        }),
                        columns: [

                            {
                                accessorFn: (row) => `${row.contactIdVerification}`, //accessorFn used to join multiple data into a single cell
                                id: 'Contact ID Verification', //id is still required when using accessorFn instead of accessorKey
                                header: ' ID Verification',
                                size: 50,

                                style: {
                                    backgroundColor: 'red',
                                },
                                Footer: () => (
                                    <>
                                    <Box color="text.primary">{totalData ? totalData.totalcontactIdVerification : 0}</Box>
                                    </>
                                ),

                            },
                            {
                                accessorFn: (row) => `${row.contactPayment}`, //accessorFn used to join multiple data into a single cell
                                id: ' Contact Payment', //id is still required when using accessorFn instead of accessorKey
                                header: 'Payment',
                                size: 50,
                                Footer: () => (
                                    <>
                                    <Box color="text.primary">{totalData ? totalData.totalcontactpayment : 0}</Box>
                                    </>
                                ),

                            },
                            {
                                accessorFn: (row) => `${row.contactCallTotal}`, //accessorFn used to join multiple data into a single cell
                                id: ' Contact Total', //id is still required when using accessorFn instead of accessorKey
                                header: 'Total',
                                size: 50,
                                Footer: () => (
                                    <Stack sx={{ color: "text.primary", borderRight: 1, borderRightColor: 'grey.500' }}>{totalData ? totalData.abandonedotalcontactcalls : 0}</Stack>
                                ),
                                muiTableHeadCellProps: () => ({
                                    sx: {
                                        borderRight: 1, borderRightColor: 'grey.500'
                                    },
                                }),
                                muiTableBodyCellProps: () => ({
                                    sx: {
                                        borderRight: 1, borderRightColor: 'grey.500'
                                    },
                                }),
                            },

                        ]
                    },
                    {
                        // accessorFn: (row) => `${row.saves}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                        enableClickToCopy: true,
                        header: 'Saved',
                        size: 150,
                        muiTableHeadCellProps: () => ({
                            sx: {
                                backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                borderRight: 1, borderRightColor: 'grey.500'
                            },
                        }),
                        columns: [

                            {
                                accessorFn: (row) => `${row.totalsaveIdVerification}`, //accessorFn used to join multiple data into a single cell
                                id: 'ID Verification', //id is still required when using accessorFn instead of accessorKey
                                header: 'ID Verification',
                                size: 50,
                                Footer: () => (
                                    <>
                                    <Box color="text.primary">{totalData ? totalData.totalsavesIdVerification : 0}</Box>
                                    </>
                                ),
                                muiTableHeadCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                    },
                                }),
                                muiTableBodyCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                    },
                                }),
                            },
                            {
                                accessorFn: (row) => `${row.totalsavepayment}`, //accessorFn used to join multiple data into a single cell
                                id: 'Payment', //id is still required when using accessorFn instead of accessorKey
                                header: 'Payment',
                                size: 50,
                                Footer: () => (
                                    <>
                                    <Box color="text.primary">{totalData ? totalData.totalsavespayment : 0}</Box>
                                    </>
                                ),
                                muiTableHeadCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                    },
                                }),
                                muiTableBodyCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                    },
                                }),
                            },
                            {
                                accessorFn: (row) => `${row.saves}`, //accessorFn used to join multiple data into a single cell
                                id: 'Total', //id is still required when using accessorFn instead of accessorKey
                                header: 'Total',
                                size: 50,
                                Footer: () => (
                                    <Stack sx={{ color: "text.primary", borderRight: 1, borderRightColor: 'grey.500' }}>{totalData ? totalData.abandonedotalsavecalls : 0}</Stack>
                                ),
                                muiTableHeadCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                        borderRight: 1, borderRightColor: 'grey.500'
                                    },
                                }),
                                muiTableBodyCellProps: () => ({
                                    sx: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                                        borderRight: 1, borderRightColor: 'grey.500'
                                    },
                                }),
                            },

                        ]
                    },


                    {
                        // accessorFn: (row) => `${row.percentage}%`,  //accessorFn used to join multiple data into a single cell
                        id: 'Saved Percent', //id is still required when using accessorFn instead of accessorKey
                        header: 'Saved Percent',
                        size: 150,
                        columns: [
                            {
                                accessorFn: (row) => `${row.IdVerificationpercentage}%`, //accessorFn used to join multiple data into a single cell
                                id: 'ID Verification percentage', //id is still required when using accessorFn instead of accessorKey
                                header: 'ID Verification',
                                size: 50,
                                Footer: () => (
                                    <>
                                    <Box color="text.primary">{totalData ? totalData.IdVerificationpercentagepercentage : 0}%</Box>
                                    </>
                                ),

                            },
                            {
                                accessorFn: (row) => `${row.paymentpercentage}%`, //accessorFn used to join multiple data into a single cell
                                id: 'Payment percentage', //id is still required when using accessorFn instead of accessorKey
                                header: 'Payment',
                                size: 50,
                                Footer: () => (
                                    <>
                                    <Box color="text.primary">{totalData ? totalData.paymentpercentagepercentage : 0}%</Box>
                                    </>
                                ),

                            },
                            {
                                accessorFn: (row) => `${row.percentage}%`, //accessorFn used to join multiple data into a single cell
                                id: 'Total percentage', //id is still required when using accessorFn instead of accessorKey
                                header: 'Total',
                                size: 50,
                                Footer: () => (
                                    <>
                                    <Box color="text.primary">{totalData ? totalData.abandonedpercentage : 0}%</Box>
                                    </>
                                ),

                            },
                        ]
                    },

                ],
            },
        ],
        [],
    );

    const columns3 = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: (row) => `${row.parentInfo ? (row.parentInfo.agentName ?row.parentInfo.agentName: ''):''}`, 
                accessorKey: 'Agent',
                header: 'Agent',
                size: 50,
            },
            {
                accessorKey: 'Total Members',
                accessorFn: (row) => `${row.parentInfo.totalMember}`,
                header: 'Total Members',
                size: 50,
               
            },
            // {
            //     accessorKey: 'Days',
            //     accessorFn: (row) => `${row.parentInfo.totalDays}`,
            //     header: 'Days',
            //     size: 50,
            // },
            {
                accessorKey: 'Average LifeSpan',
                accessorFn: (row) => `${row.parentInfo.totalaverage}`,
                header: 'Average LifeSpan',
                size: 50,
                
            },
            {
                accessorKey: 'Total Payment',
                accessorFn: (row) => `${(row.parentInfo.totalPayment).toFixed(2)}`,
                header: 'Total Payment',
                size: 50,
            },
            {
                accessorKey: 'Billing Count',
                accessorFn: (row) => `${row.parentInfo.totalpaymentRecored}`,
                header: 'Billing Count',
                size: 50,
                
            },
            {
                accessorKey: 'Average Billing',
                accessorFn: (row) => `${row.parentInfo.averageBilling}`,
                header: 'Average Billing',
                size: 50,
                
            },

        ]
    )

    const columns4 = useMemo(
        () => [  
            {
                
                enableClickToCopy: true, //accessorFn used to join multiple data into a single cell
                id: 'name', //id is still required when using accessorFn instead of accessorKey
                header: 'Agent Name',
                size: 150,
                muiTableHeadCellProps: () => ({
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                        borderRight: 1, borderRightColor: 'grey.500'
                    },
                }),
                accessorFn: (row) => `${row.agent_name}`,
                Footer: () => (
                    <Stack sx={{ color: "text.primary", borderRightColor: 'grey.500' }}>Total</Stack>
                ),
                
            },

            {
                // accessorFn: (row) => `${row.totalcalls}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Total Assigned',
                muiTableHeadCellProps: () => ({
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                        borderRight: 1, borderRightColor: 'grey.500'
                    },
                }),
                size: 150,
                accessorFn: (row) => `${row.memberCount}`,
                Footer: () => (
                    <Stack className='retention-table' sx={{ color: "text.primary",  borderRightColor: 'grey.500' }}>{totalData ? totalData.totalMemberCount : 0}</Stack>
                ),
                
            },
            {
                // accessorFn: (row) => `${row.saves}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Total Call',
                size: 150,
               
                muiTableHeadCellProps: () => ({
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                        borderRight: 1, borderRightColor: 'grey.500'
                    },
                }),
                accessorFn: (row) => `${row.totalcalls}`,
                Footer: () => (
                    <Stack className='retention-table' sx={{ color: "text.primary",  borderRightColor: 'grey.500' }}>{totalData ? totalData.abandonedotalcalls : 0}</Stack>
                ),
            },
            {
                // accessorFn: (row) => `${row.saves}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                header: 'Total Contacted',
                size: 150,
                
                muiTableHeadCellProps: () => ({
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                        borderRight: 1, borderRightColor: 'grey.500'
                    },
                }),
                accessorFn: (row) => `${row.contactCallTotal}`,
                Footer: () => (
                    <Stack sx={{ color: "text.primary",  borderRightColor: 'grey.500' }}>{totalData ? totalData.abandonedotalcontactcalls : 0}</Stack>
                ),
                
            },
            {
                enableClickToCopy: true,
                header: 'Call Logged',
                size: 150,
                muiTableHeadCellProps: () => ({
                    sx: {
                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                        borderRight: 1, borderRightColor: 'grey.500'
                    },
                }),
                accessorFn: (row) => (`${row.totalcalls}`/`${row.memberCount}`*100).toFixed(2) + '%',
            },      
        ],
        [],
    );



    //csv start here
    const retentionCombinedData = [...retentionData];
    const abandonedCombinedData = [...abandonedData];
    const abandonedLifeTimeData = [...AbandonedLifeTime];
    const abandonedMemberAssignment = [...abandonedData];
    // ... (Retention CSV options start)
    const retentionCsvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Retention dashboard Data.csv', // Change the file name
        headers: ['Agent Name', 'Savable', 'Saves', 'Cancels', 'Save Percent']
    };
    // ... (Retention CSV options end)
    // ... (Abandoned CSV options start)
    const mainHeaders = ['Agent Name', 'Calls', 'Contacted', 'Saved', 'Saved Percent'];
    //   const subHeaders = [
    //     ['Agent Name'],
    //     ['ID Verification', 'Payment', 'Total'],
    //     ['ID Verification', 'Payment', 'Total'],
    //     ['ID Verification', 'Payment', 'Total'],
    //     ['ID Verification percentage', 'Payment percentage', 'Total percentage'],
    //   ];
    const abandonedCsvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Abandoned Members Dashboard Data.csv', // Change the file name
        headers: ['Agent Name',
            'Calls - ID Verification',
            'Calls - Payment',
            'Calls - Total',
            'Contacted - ID Verification',
            'Contacted - Payment',
            'Contacted - Total',
            'Saved - ID Verification',
            'Saved - Payment',
            'Saved - Total',
            'Saved Percent - ID Verification',
            'Saved Percent - Payment',
            'Saved Percent - Total',],
    };
    const abandonedLifeTimeCsvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Abandoned Life Time Dashboard Data.csv', // Change the file name
        headers: ['Agent',
            'Total Members',
            'Average LifeSpan',
            'Total Payment',
            'Billing Count',
            'Average Billing',
            ],
    };
    const abandonedMemberCsvOptions ={
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        fileName: 'Abandoned Member Assignment.csv', // Change the file name
        headers: ['Agent Name',
            'Total Assigned',
            'Total Call',
            'Total Contacted',
            'Call Logged',
            ],
    }
    // ... (Abandoned CSV options end)
    // Define the convertToCSV function start
    const convertToCSV = (data, headers) => {
        const csvContent = headers.join(',') + '\n' +
            data.map((row) => (
                Object.values(row).map((value) => (
                    typeof value === 'string' && value.includes(',') ? `"${value}"` : value
                )).join(',')
            )).join('\n');

        return csvContent;
    };
    
    // Define the convertToCSV function end
    // Define export functions for  Retension start
    const handleExportRetentionData = (retentionCombinedData) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
        const exportedData = retentionCombinedData.map((row) => ({
            name: `${row.original.agent_name}` || 'N/A',
            savable: `${row.original.savable}` || 'N/A',
            saves: `${row.original.saves}` || 'N/A',
            cancels: `${row.original.cancelled}` || 'N/A',
            save_percent: `${row.original.percentage}%` || 'N/A',
        }));

        const footerData = {
            name: 'Total', // Customize as needed
            savable: `${totalRetention.savable}` || 'N/A',
            saves: `${totalRetention.saves}` || 'N/A',
            cancels: `${totalRetention.cancels}` || 'N/A',
            save_percent: `${totalRetention.percentage}%` || 'N/A',
        };

        // Concatenate subheader rows to the main exportedData array
        const allRows = [...exportedData, footerData];

        // Generate CSV content
        const csvContent = convertToCSV(allRows, retentionCsvOptions.headers);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = retentionCsvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };
    // Define export functions for  Retention end

    // Define export functions for  Abandoned start
    const handleExportAbandonedData = (abandonedCombinedData) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
        const exportedData = abandonedCombinedData.map((row) => ({
            name: `${row.original.agent_name}` || 'N/A',
            calls_id_verification: `${row.original.totalIdVerification}` || 'N/A',
            calls_payment: `${row.original.totalpayment}` || 'N/A',
            calls_total: `${row.original.totalcalls}` || 'N/A',
            contacted_id_verification: `${row.original.contactIdVerification}` || 'N/A',
            contacted_payment: `${row.original.contactPayment}` || 'N/A',
            contacted_total: `${row.original.contactCallTotal}` || 'N/A',
            saved_id_verification: `${row.original.totalsaveIdVerification}` || 'N/A',
            saved_payment: `${row.original.totalsavepayment}` || 'N/A',
            saved_total: `${row.original.saves}` || 'N/A',
            saved_percent_id_verification: `${row.original.IdVerificationpercentage}` || 'N/A',
            saved_percent_payment: `${row.original.paymentpercentage}` || 'N/A',
            saved_percent_total: `${row.original.percentage}` || 'N/A',
        }));


        // Modify footerData to match the structure of the exported data
        const footerData = {
            'Agent Name': 'Total', // Customize as needed
            'Calls - ID Verification': `${totalData.totalIdVerification}` || 0,
            'Calls - Payment': `${totalData.totalpayment}` || 0,
            'Calls - Total': `${totalData.abandonedotalcalls}` || 0,
            'Contacted - ID Verification': `${totalData.totalcontactIdVerification}` || 0,
            'Contacted - Payment': `${totalData.totalcontactpayment}` || 0,
            'Contacted - Total': `${totalData.abandonedotalcontactcalls}` || 0,
            'Saved - ID Verification': `${totalData.totalsavesIdVerification}` || 0,
            'Saved - Payment': `${totalData.totalsavespayment}` || 0,
            'Saved - Total': `${totalData.abandonedotalsavecalls}` || 0,
            'Saved Percent - ID Verification': `${totalData.IdVerificationpercentagepercentage}%` || 0,
            'Saved Percent - Payment': `${totalData.paymentpercentagepercentage}%` || 0,
            'Saved Percent - Total': `${totalData.abandonedpercentage}%` || 0,
        };

        // Concatenate subheader rows to the main exportedData array
        const allRows = [...exportedData, Object.values(footerData)];

        // Generate CSV content
        const csvContent = convertToCSV(allRows, abandonedCsvOptions.headers);

        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = abandonedCsvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };
    const handleExportAbandonedLifeTime = (abandonedLifeTimeData) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
        
        const exportedData = abandonedLifeTimeData.map((row) => ({
            agent: `${(row.original.parentInfo && row.original.parentInfo.agentName && row.original.parentInfo.agentName) ||'N/A'}`,
            total_member: `${row.original.parentInfo.totalMember}` || 'N/A',
            average_lifespan:`${row.original.parentInfo.totalaverage}` || "N/A",
            total_payment:`${row.original.parentInfo.totalPayment}`||"N/A",
            billing_count:`${row.original.parentInfo.totalpaymentRecored}`||"N/A",
            average_billing: `${row.original.parentInfo.averageBilling}`||"N/A"
        }));

        // Concatenate subheader rows to the main exportedData array
        const allRows = [...exportedData];

        // Generate CSV content
        const csvContent = convertToCSV(allRows, abandonedLifeTimeCsvOptions.headers);

        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = abandonedLifeTimeCsvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };
    // Define export functions for  Abandoned end
    //Define export functions for member assignment start here
    const handleExportAbandonedMember = (abandonedMemberAssignment) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
        }
        

    
        const exportedData = abandonedMemberAssignment.map((row) => ({
            name: `${row.original.agent_name}` || 'N/A',
            memberCount: `${row.original.memberCount}` || 'N/A',
            totalcalls: `${row.original.totalcalls}` || 'N/A',
            contactCallTotal: `${row.original.contactCallTotal}` || 'N/A',
            callLogged:(row.original.memberCount !== 0) ? ((row.original.totalcalls / row.original.memberCount) * 100).toFixed(2) + '%' : 'N/A'
        }));

        const footerData = {
            name: 'Total', // Customize as needed
            TotalAssigned: `${totalData ? totalData.totalMemberCount : 0}` || 'N/A',
            Totalcell: `${totalData ? totalData.abandonedotalcalls : 0}` || 'N/A',
            TotalContacted: `${totalData ? totalData.abandonedotalcontactcalls : 0}` || 'N/A',
        };

        // Concatenate subheader rows to the main exportedData array
        const allRows = [...exportedData, footerData];

        // Generate CSV content
        const csvContent = convertToCSV(allRows, abandonedMemberCsvOptions.headers);
        // Create a Blob object from the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = abandonedMemberCsvOptions.fileName + '.csv';

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the file download
        link.click();

        // Clean up the temporary URL and remove the link from the document
        URL.revokeObjectURL(link.href);
        link.remove();
    };

    //Define export functions for member assignment end here
    const ContentLayout = <>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Typography level="h4" color="primary">Welcome To The Retention Dashboard</Typography>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} spacing={2}>
            <Grid item lg={4} md={4} sm={4} xs={4}>
                <FormControl fullWidth size='small'>
                    <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value="date"
                        input={<OutlinedInput label="Select Date Range" />}>
                        <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                        <DateRangePicker
                            className='custom-date-picker'
                            onChange={item => setSelectionRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={selectionRange}
                            maxDate={new Date()}
                            direction="horizontal"
                            staticRanges={staticRanges}
                        />
                        {/* <div style={{display:"flex",justifyContent:"right"}}>
                            <Button className="btn btn-transparent text-danger">
                                Close
                            </Button>
                        </div> */}
                    </Select>
                    {/* <Button onClick={clearFilter} >Clear Dates</Button> */}
                </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
                <Button variant='contained' color='primary' onClick={getRetentionDashboard}>Submit</Button>
            </Grid>
        </Grid>
        <MaterialReactTable
            className="retentiona-table"
            columns={columns}
            data={retentionData}
            filterFns={{
                customSearchFilterFn: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }}   
            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
            // enableColumnOrdering
            enableColumnFilterModes
            enableColumnFilters={config.DatatableColumnFilter}
            enableRowSelection={false}
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
            initialState={{
                showColumnFilters: false,
                density: 'compact',
                sorting: [
                    {
                        id: 'name',
                        desc: false
                    },
                ]
            }}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
            renderTopToolbarCustomActions={({ table }) => (
                <>
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        sx={{ bgColor: "#2A68B3" }}
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportRetentionData(table.getPrePaginationRowModel().rows)
                        }
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        // onClick={handleExportRetentionData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        CSV
                    </Button>
                </Box>
                </>
            )}
        />

        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card className='cardstyle' sx={{ minHeight: 430, maxHeight: 300 }}>
                <CardOverflow
                    sx={{
                        bgcolor: '#F6FAFF',
                        borderColor: '#F6FAFF',
                        padding: '2%',
                    }}>
                </CardOverflow>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className="borderStyle" sx={{ padding: "2% 2% 5% 2%" }}>
                                {retentionGraph.length > 1 ?                              
                                (  
                                        <Chart
                                        chartType="ComboChart"
                                        width="100%"
                                        height="300px"
                                        data={retentionGraph}
                                        options={options1}
                                    />
                                ): (
                                    <div>No data available</div>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        </Grid>

        <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography level="h4" color="primary">Abandoned Members Data</Typography>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12}>

            <Grid item lg={3} md={3} sm={12} xs={12}>
                <FormControl fullWidth size='small' margin='dense'>
                    <InputLabel id="demo-multiple-name-label">Source</InputLabel>
                    <Select
                        id="assignedToSource"
                        value={source}
                        onChange={handleSourceChange}
                        MenuProps={MenuProps}
                        input={<OutlinedInput label="Source" />}
                    >
                        <MenuItem key="Action" value="all" >
                            All
                        </MenuItem>
                        <MenuItem key="MFSN" value="MFSN">
                            MFSN
                        </MenuItem>
                        <MenuItem key="CD" value="CD">
                            CD
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>



        {/* <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px',marginBottom: '20px' }}>
                    <Typography level="h4" color="primary">Abandoned Members Data</Typography>
                </Grid> */}

        <MaterialReactTable
            // muiTableBodyProps={{
            //     sx: {
            //         '& td:nth-child(even)': {
            //         backgroundColor: 'red',
            //         },
            //         },
            //     }}
            className="retentiona-table"
            columns={columns2}
            data={abandonedData}
            filterFns={{
                customSearchFilterFn2: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }}   
            globalFilterFn="customSearchFilterFn2" //exact serach filter functionality
            // enableColumnOrdering
            enableColumnFilterModes
            enableColumnFilters={config.DatatableColumnFilter}
            enableRowSelection={false}
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
            initialState={{
                showColumnFilters: false,
                density: 'compact',
                sorting: [
                    {
                        id: 'name',
                        desc: false
                    },
                ]
            }}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
            renderTopToolbarCustomActions={({ table }) => (
                <>
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        sx={{ bgColor: "#2A68B3" }}
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportAbandonedData(table.getPrePaginationRowModel().rows)
                        }
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        // onClick={handleExportAbandonedData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        CSV
                    </Button>
                </Box>
                </>
            )}
        />
        <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography level="h4" color="primary">Abandoned Life Time</Typography>
        </Grid>
        <MaterialReactTable
            columns={columns3}
            data={AbandonedLifeTime}
            filterFns={{
                customSearchFilterFn3: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }}   
            globalFilterFn="customSearchFilterFn3" //exact serach filter functionality
            enableColumnFilterModes
            enableRowSelection={false}
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
            initialState={{ showColumnFilters: false, density: 'compact' }}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            enableColumnFilters={config.DatatableColumnFilter}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
            muiTableHeadCellProps={{
                sx: {
                    backgroundColor: "#F6FAFF",
                },
            }}
            renderTopToolbarCustomActions={({ table }) => (
                <>
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                   <Button
                        sx={{ bgColor: "#2A68B3" }}
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportAbandonedLifeTime(table.getPrePaginationRowModel().rows)
                        }
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        // onClick={handleExportAbandonedData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        CSV
                    </Button>
                </Box>
                </>
            )
            }
            renderDetailPanel={({ row }) => (
                <>
                <Box>
                <Table  borderAxis="both">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>EmailId</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>MemberId</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Saved Date</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Current Status</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Status Change Date</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Active Days</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Total Payment</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Billing Count</TableCell>
                        
                    </TableRow>
                </TableHead>
                <TableBody>
                {Object.values(row.original.parentInfo.childInfo).map((child,index) => (
                        
                        <TableRow key={index}>
                            {Object.values(child.data).map((option,innerIndex) => (
                                <React.Fragment  key={innerIndex}>
                                    <TableCell>{option.email || ''}</TableCell>
                                    <TableCell>{option.member_id || ''}</TableCell>
                                    <TableCell>
                                    {option.created_at
                                        ? Moment(option.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY ')
                                        : ''}
                                    </TableCell>
                                    <TableCell>
                                    {option.customer_details_data ? option.customer_details_data.account_status : ''}
                                    </TableCell>
                                    <TableCell>
                                    {/* {option.customer_details_status_change.map((customerDetails) => ( 
                                        <>
                                            {customerDetails.account_status == 'CLOSED' ?
                                            <>
                                              
                                                    {customerDetails? Moment(customerDetails.date_range_filter).format('MM-DD-YYYY ')  : ''}
                                               
                                            </> : ''}
                                        </>
                                     ))} */}
                                       {option.customer_details_data ? option.customer_details_data.account_status ? option.customer_details_data.account_status != 'ACTIVE' ?
                                            <>
                                              
                                        {option.customer_details_data ? option.customer_details_data.date_range_filter ?  Moment(option.customer_details_data.date_range_filter).format('MM-DD-YYYY ') : '' : ''}
                                        </> : '' : '' : ''}
                                    </TableCell> 

                                </React.Fragment>
                            ))}
                            <TableCell>
                                {child.total ? child.total : ''}
                            </TableCell>
                            <TableCell>
                                {child.amountSum ? (child.amountSum).toFixed(2) : ''}
                            </TableCell>
                            <TableCell>
                                {child.totalRecord ? child.totalRecord : ''}
                            </TableCell>
                        </TableRow>
                      
                    ))}
                                           
                        </TableBody>
            </Table>
            </Box>
            </>
            )}
        />


        <Grid container item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Typography level="h4" color="primary">Abandoned Members Assignment</Typography>
        </Grid>


        <MaterialReactTable
            className="retentiona-table"
            columns={columns4}
            data={abandonedData}
            filterFns={{
                customSearchFilterFn4: (row, id, filterValue) =>
                row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }}   
            globalFilterFn="customSearchFilterFn4" //exact serach filter functionality
            enableColumnFilterModes
            enableColumnFilters={config.DatatableColumnFilter}
            enableRowSelection={false}
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
            initialState={{
                showColumnFilters: false,
                density: 'compact',
                sorting: [
                    {
                        id: 'name',
                        desc: false
                    },
                ]
            }}
            renderTopToolbarCustomActions={({ table }) => (
                <>
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                   <Button
                        sx={{ bgColor: "#2A68B3" }}
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportAbandonedMember(table.getPrePaginationRowModel().rows)
                        }
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        // onClick={handleExportAbandonedData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        CSV
                    </Button>
                </Box>
                </>
            )
            }
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
        />
        

        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className='cardstyle' sx={{ minHeight: 430, maxHeight: 300 }}>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                                padding: '2%',
                            }}>
                        </CardOverflow>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="borderStyle" sx={{ padding: "2% 2% 5% 2%" }}>
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={AbandonedGraphData}
                                            options={options2}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Card>
                </Grid> */}
        {/* loader code start */}
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
                onClick={handleFreezStop}
            >
                <Freez />
            </Backdrop>
        </div>
        {/* loader code end */}
    </>

    return (
        <>
            <DefaultLayout content={ContentLayout} />
        </>
    );
}


export default RetentionDashboard;