import { Box, Button, Grid, Stack, Typography, } from '@mui/material';
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
function Header(props) {

    return (
        <Box sx={{ flexGrow: 1, boxShadow: 0, justifyContent: "center", alignItems: "center", borderBottom: "3px #30BE2D solid" }}>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
                        <Grid item xs>
                            <Link >
                                <img src={require('../../../assets/images/epic_pro_logo.png')} style={{ width: "100%", height: "auto" }} />
                            </Link>
                        </Grid>
                        <Grid item xs>
                           <Typography>From <span><img src={require('../../../assets/images/mainLogo.jpg')} /></span></Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "right" }}>
                {/* <Grid container item lg={12} md={12} sm={12} xs={12} > */}
                    <Typography>Partner With<span><img src={require('../../../assets/images/mainLogo.jpg')}/></span></Typography>
                    
                {/* </Grid> */}
                </Grid>
            </Grid>
        </Box>
    );
}
export default Header;