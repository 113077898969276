import React, { useMemo, useState, useEffect } from 'react';
import {  Grid, Tooltip, Button, FormControl, MenuItem, InputLabel, Select, OutlinedInput } from '@mui/material';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import MaterialReactTable from 'material-react-table';
import {  format, subDays } from 'date-fns';
import { Link } from 'react-router-dom';
import { calendarFilter,useDateSelectionRange } from '../CommonFormula';
let data = [];
function RegisteredAffiliates(props) {
    //Date range select function start
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
    //Clear date ranges start
    const sideBar = calendarFilter();
  const staticRanges = [
      // ...defaultStaticRanges,
      ...createStaticRanges(sideBar)
  ];

    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorFn: () => ``,
                id: 'name',
                header: 'Name',
                size: 50,
            },
            {
                accessorFn: () => ``,  //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                id: 'email',
                header: 'Email ID',
                size: 50,
            },
            {
                accessorFn: () => ``,
                filterVariant: 'range',
                id: 'phoneNo',
                header: 'Phone no.',
                size: 50,
                //custom conditional format and styling
            },

            {
                accessorFn: () => <>
                    <Tooltip title="Refresh" arrow><Link ><img src={require('../../assets/icons/Refresh.png')} /></Link></Tooltip>
                </>,
                id: 'actions',
                header: 'Actions',
            },
        ]
    )


    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                <Grid item lg={4} md={4} sm={6} xs={6}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value="date"
                            input={<OutlinedInput label="Select Date Range" />}
                        >
                            <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                            <DateRangePicker
                                className='custom-date-picker'
                                onChange={item => setSelectionRange([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={selectionRange}
                                direction="horizontal"
                                maxDate={new Date()}
                                staticRanges={staticRanges}
                            />
                        </Select>
                        <Button onClick={clearFilter} >Clear Dates</Button>
                    </FormControl>
                </Grid>
                <Grid item lg={8} md={8} sm={6} xs={6}>
                    <Button variant="contained" color="success" sx={{ minWidth: 200 }}  > Submit</Button>
                </Grid>
            </Grid>
            <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilterModes

                enableGrouping={false}
                enablePinning={false}
                enableRowSelection={false}
                getRowId={(row) => row.id} //give each row a more useful id


                initialState={{
                    showColumnFilters: false,
                    density: 'compact',
                }}

                positionToolbarAlertBanner="bottom"
                enableDensityToggle={false}
                muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                        backgroundColor: "#F6FAFF",
                    },
                }}

           
            />


        </>
    );

}


export default RegisteredAffiliates;