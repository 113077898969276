
import React,{useMemo,useState,useEffect} from 'react';
import { useNavigate} from 'react-router-dom';

import DefaultLayout from '../../../Components/DefaultLayout';
import {Box,Grid,Dialog,DialogTitle,DialogContent,DialogActions,FormHelperText,
 Button,Tooltip,TextField} from '@mui/material';
import { Typography } from '@mui/joy';
import { useTheme } from '@mui/material/styles';
import AuthUser from '../../../Components/Auth/AuthUser';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import { useDispatch } from 'react-redux';
import { fetchData,postData} from '../../../redux/apiSlice';
import MaterialReactTable from 'material-react-table';
import config from '../../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Moment from 'moment';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogContentText from '@mui/material/DialogContentText';
import { Formik } from 'formik';
import * as Yup from 'yup';
import swal from "sweetalert";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


function Holidays(props) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const {user } = AuthUser();
    const [freez, setFreez] = useState(true);
    const [columnVisibility, setColumnVisibility] = useState({});
    const navigate = useNavigate();
    const [fullWidth, setFullWidth] = useState(false);
    const [maxWidth, setMaxWidth] = useState('sm');
    const [open, setOpen] = useState(false);
    const [requestFormInitialValues, setRequestFormInitialValues] = useState({});
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [currentDate, setCurrentDate] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [openDeletepopup, setDeleteOpen] = useState(false);
    const [todaysDate, setTodaysDate] = useState("");
    
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };

    const fetchHolidaysList = async() => {
      setFreez(true);
      const apiUrl = '/holidays-list';
      const res = await dispatch(fetchData(apiUrl)).unwrap();
      if(res) {
          if(res.success == true) {
              setData(res.data);
              
          }
          handleFreezStop();
      }
                  
    } 

    useEffect(() => {      
      if(user.roles_id < 2) {
        const newDate = new Date();
        newDate.setDate(newDate.getDate());
        var myDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        setTodaysDate(myDate);
        fetchHolidaysList();
      }else{
        navigate('/login');
      }
      
     
    }, []);

    const handleClickOpen =() => {
      const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id > 2 ) {
            swal({
                title: "Failed",
                text: "This action is unauthorized.",
                icon: "error",
                button: "Ok",
            })
            navigate('/login');
            return false;
        }
        const newDate = new Date();
        newDate.setDate(newDate.getDate());
        var myDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY'); 
        setCurrentDate(myDate);

        setOpen(true);
        setRequestFormInitialValues({
          name : '',
          holidayDate: dayjs(),
        })
    }

    const deleteHoliday=(id) => {
      const loginUser = JSON.parse(localStorage.getItem('user'));
      if(loginUser.roles_id != 1 ) {
          window.location.href= config.BASE_URL + 'login' ;
          return false;
      }
      setDeleteId(id);
      setDeleteOpen(true);
    }

    const removeHoliday = async() => {
      const loginUser = JSON.parse(localStorage.getItem('user'));
      if(loginUser.roles_id != 1 ) {
          window.location.href= config.BASE_URL + 'login' ;
          return false;
      }
      handleFreezStart();
      setDeleteOpen(false);
      const apiUrl = '/delete-holiday/' + deleteId;
      const res = await dispatch(fetchData(apiUrl)).unwrap();
      if (res) {
        if (res.success) {
          handleFreezStop();
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            button: "Ok",
          })
        }else{
          swal({
            title: "Failed",
            text: "Something went wrong please try again",
            icon: "error",
            button: "Ok",
          })
        }
      }
      fetchHolidaysList();
    }

    const closeDeletePlan = () => {
      const loginUser = JSON.parse(localStorage.getItem('user'));
          if(loginUser.roles_id != 1 ) {
              navigate('/login');
              return false;
          }
      setDeleteOpen(false);
    }

    const handleClose = () => {
      const loginUser = JSON.parse(localStorage.getItem('user'));
      if(loginUser.roles_id >= 3 ) {
        navigate('/login');
        return false;
      }
     setOpen(false);
    };

    const holidaySchema = Yup.object().shape({

       name: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Please Enter Your Holiday Name.'),

      holidayDate: Yup.string()
        .required('Please enter Holiday Date'),
  
      
    })

    const columns = useMemo(
      //column definitions...
      () => [
        {
          accessorFn: (row) => `${row.name ? row.name : ''}`,
          id:'name',
          header: 'Name',
          size: 50,
          Cell:({renderedCellValue,row}) =>(row.original.name ),
        },
        {
          accessorKey: 'holiday_date',
          accessorFn: (data) => `${data.holiday_date }`,
          enableClickToCopy: true,
          id: 'holiday_date',
          header: 'Holiday Date',
          size: 100,
          Cell:({renderedCellValue,row}) => (
            <div>
                {row.original.holiday_date != null ? Moment(row.original.holiday_date).format('MM-DD-YYYY') : 'N/A'}
             </div>          
           ),
        },
        {
          accessorKey: 'created_by',
          accessorFn: (data) => (`${data.created_by ? data.created_by.first_name + " " +data.created_by.last_name : 'N/A'}`),
          enableClickToCopy: true,
          id: 'created_by',
          header: 'Created By',
          size: 100,
          Cell:({renderedCellValue,row}) => (
            <div>
                {row.original.created_by ? row.original.created_by.first_name + " " + row.original.created_by.last_name : 'N/A'}
             </div>          
           ),
        },
        {
          accessorKey: 'created_at',
          accessorFn: (data) => (`${data.created_at ? Moment(`${data.created_at}`).format('MM-DD-YYYY') : 'N/A'}`),
          enableClickToCopy: true,
          id: 'created_at',
          header: 'Created At',
          size: 100,
          Cell:({renderedCellValue,row}) => (
            <div>
                {row.original.created_by ?  Moment(`${row.original.created_at}`).tz('America/Los_Angeles').format('MM-DD-YYYY') : 'N/A'}
             </div>          
           ),
        },
        {
          accessorFn: (data) => `${data}`,
          id: 'action',
          header: 'Action',
          enableSorting: false,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <Box>
              <>
              {
                new Date(row.original.holiday_date).getTime() > new Date(todaysDate).getTime()
                 ?
                 <Tooltip title="Details" arrow><DeleteIcon sx={{color : 'red'}} onClick={() => { deleteHoliday(`${row.original.id}`);  }}></DeleteIcon ></Tooltip>
                 :
                 ''
              }
              </>
            </Box>
          ),
  
        },
      ]);

    

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Holidays',
      headers: ['Name', 'Holiday Date', 'Created By','Created At'],
    };

    const handleExportData = (data) => {
      const exportedData = data.map((row) => {
        return {
          companyName: `${row.original.name }` || 'N/A',
          holidayDate:`${row.original.holiday_date ? Moment(row.original.holiday_date).format('MM-DD-YYYY')  :'N/A'}`,
          createdBy:`${row.original.created_by ? row.original.created_by.first_name + " " + row.original.created_by.last_name  :'N/A'}`,
          createdAt:`${row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY')  :'N/A'}`,
        };
      });
    
      const csvContent = convertToCSV(exportedData);
      // Create a Blob object from the CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
      // Create a download link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = csvOptions.fileName + '.csv';
    
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Programmatically click the link to trigger the file download
      link.click();
    
      // Clean up the temporary URL and remove the link from the document
      URL.revokeObjectURL(link.href);
      link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				}

				return value;
			}).join(',');
		}).join('\n');

    return headers + rows;
  };
    
    function ContentLayout() {
    return (
        <>
            <Box>
                    <Grid container item xs={12} mb={3} sx={{justifyContent:'space-between'}}>
                        <Grid item xs>
                            <Typography level="h4" color="primary">
                            Holidays
                            </Typography>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={handleClickOpen}>Add Holiday</Button>
                        </Grid>
                    </Grid>
                
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableColumnFilterModes
                        filterFns={{
                          customSearchFilterFn: (row, id, filterValue) =>
                          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
                        }} 
                        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
                        enableRowSelection={false}
                        positionToolbarAlertBanner="bottom"
                        enableDensityToggle={false}
                        enableColumnFilters={config.DatatableColumnFilter}
                        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                        state={{ columnVisibility, rowSelection }} //pass our managed row selection state to the table to use
                        initialState={{
                          showColumnFilters: false,
                          density: 'compact',
                        }}
                        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
                        renderTopToolbarCustomActions={({ table }) => (
                          <Box
                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                          >
                            <Button
                              color="primary"
                              disabled={table.getPrePaginationRowModel().rows.length === 0}
                              //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                  handleExportData(table.getPrePaginationRowModel().rows)
                                }
                              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                              startIcon={<FileDownloadIcon />}
                              variant="contained"
                            >
                              CSV
                            </Button>
                          </Box>
                        )
                        }
                      />
        <Formik
        initialValues={requestFormInitialValues}
        validationSchema={holidaySchema}
        onSubmit={async(values, { resetForm }) => {
          // capturePayment(values);
          handleClose();
          handleFreezStart();
          const apiUrl = '/add-holiday';

          const date = new Date(values.holidayDate);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          var mydate = year+"/"+month+"/"+day;

          const args = {
            name: values.name,
            date: mydate,
          }
          const res = await dispatch(postData({ apiUrl, args })).unwrap();
          if(res) {
            if(res.success === true) {
              swal({
                title: "Success",
                text: res.message,
                icon: "success",
                button: "Ok",
              })
            }else{
              swal({
                title: "Failed",
                text: "Something went wrong please try again",
                icon: "error",
                button: "Ok",
              })
            }
          }
          handleFreezStop();
          fetchHolidaysList();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue, setFieldError,resetForm  }) => (
          <>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={fullWidth}
              maxWidth={maxWidth}
            >
            <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
              <Typography variant='h2' color='primary'>Add Holiday</Typography>
            </DialogTitle>
              <DialogContent>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                    <TextField
                    name='name'
                    id='name'
                    label="Holiday Name"
                    value={values.name}
                    variant="outlined"
                    size="small" fullWidth
                    onChange={handleChange('name')}
                  />
                  <FormHelperText style={{ color: 'red'}}>{touched.name && errors.name ? errors.name : ''}</FormHelperText>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer components={['DatePicker']} margin="dense" size="small" fullWidth>
                      <DatePicker label="Holiday Date:*" name="holidayDate" id="holidayDate" 
                      defaultValue={dayjs(currentDate)}
                        onChange={(value) => { setFieldValue("holidayDate", value, true) }} />
                    </DemoContainer>
                  </LocalizationProvider>
                  <FormHelperText style={{ color: 'red'}}>{touched.holidayDate && errors.holidayDate ? errors.holidayDate : ''}</FormHelperText>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" color="primary" 
                onClick={(e)=>{
                  handleClose(resetForm);
                }}
                sx={{ minWidth: 200 }}>CLOSE</Button>
                <Button disabled={isSubmitEnabled} variant="contained" color="success" onClick={handleSubmit} sx={{ minWidth: 200 }}>Submit</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>

      <Dialog
          open={openDeletepopup}
          onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                  setDeleteOpen(false)
              }
              }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">Delete Holiday</DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Holiday?
          </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button autoFocus variant="contained" sx={{ minWidth: 100 }} onClick={closeDeletePlan} >
              Cancel
          </Button>
          <Button onClick={removeHoliday}  autoFocus style={{ backgroundColor: 'red', color: 'white',minWidth: 100 }}>
              Delete
          </Button>
          </DialogActions>
      </Dialog>
            </Box>
        </>
        );
    }
     
    
    return(
        <div>
            <DefaultLayout content={<ContentLayout />} />
            {/* loader code start */}
      <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={freez}
              onClick={handleFreezStop}
            >
              <Freez />
            </Backdrop>
          </div>
          {/* loader code end */}
        </div>
    );
}
    
export default Holidays;