import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DefaultLayout from '../../../Components/DefaultLayout';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { Box, Tab, Grid, FormControl, Button, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import Enrolled from '../../../Components/MyLeads/AdminMyLeads/Enrolled';
import Upgraded from '../../../Components/MyLeads/AdminMyLeads/Upgraded';
import { DateRangePicker,createStaticRanges } from 'react-date-range';
import { format, subDays } from 'date-fns';
import { calendarFilter,useDateSelectionRange } from '../../../Components/CommonFormula';
function Index(props) {
    const { tab } = useParams();
    const [value, setValue] = React.useState(tab);
    const { selectionRange, setSelectionRange, startDate, endDate,clearFilter } = useDateSelectionRange();
    useEffect(() => {
        setValue(tab);
    })
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const sideBar = calendarFilter();
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];
    //Get referal info function start
    function ContentLayout() {

        return (
            <>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }}>
                                <Grid item lg={7} md={7} xs={6} sm={6}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                        <Link to="/myleads/adminmyleads/enrolled" value="enrolled" style={{ textDecoration: 'none' }} >
                                            <Tab label="Enrolled" value="enrolled" sx={{ color: 'black' }} />
                                        </Link>
                                        <Link to="/myleads/adminmyleads/upgraded" value="upgraded" style={{ textDecoration: 'none' }} >
                                            <Tab label="Upgraded" value="upgraded" sx={{ color: 'black' }} />
                                        </Link>
                                    </TabList>
                                </Grid>
                                <Grid item lg={5} md={5} xs={6} sm={6}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} >
                                        <Grid item lg={6} md={6} sm={8} xs={8}>
                                            <FormControl fullWidth size='small'>
                                                <InputLabel id="demo-multiple-name-label">Select Date Range</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    value="date"
                                                    input={<OutlinedInput label="Select Date Range" />}
                                                >
                                                    <MenuItem value="date" >{startDate ? <>{startDate} To {endDate}</> : ''}</MenuItem>
                                                    <DateRangePicker
                                                        className='custom-date-picker'
                                                        onChange={item => setSelectionRange([item.selection])}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={false}
                                                        months={2}
                                                        ranges={selectionRange}
                                                        direction="horizontal"
                                                        maxDate={new Date()}
                                                        staticRanges={staticRanges}
                                                    />
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={4} xs={4}>
                                            <Button variant="contained" color="success" sx={{ minWidth: 150 }} > Submit</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <TabPanel value="enrolled"><Enrolled tab={tab} /></TabPanel>
                        <TabPanel value="upgraded"><Upgraded tab={tab} /></TabPanel>

                    </TabContext>
                </Box>
            </>
        );
    }

    return (
        <div>
            <DefaultLayout content={<ContentLayout />} />
        </div>
    );
}

export default Index;