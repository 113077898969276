
import React, { useState, useRef } from "react";
import { Grid, Typography } from '@mui/material';
import { Card, CardContent, CardOverflow } from '@mui/joy';

function AffiAgreement7() {

    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h6">2. ​​​Duties of Referrer. Referrer agrees as follows:
                                    (a) That it will use its commercially reasonable efforts to market and promote the Services to
                                    Visitors, and will provide MFSN with content integration wherein MFSN’s Services will be offered to Visitors on Referrer’s website, thru integration with third-party software such as Credit Repair Cloud that Referrer is under license to and has obtained the necessary rights to use, by within Referrer’s email solicitation and through any other marketing avenues at Referrer’s discretion; (b) Referrer shall market the Services solely in the name of the Trademark; (c) Referrer shall only use the Trademark in marketing and promoting the Services, subject to the terms of this Agreement; (d) Referrer shall market the Services to Visitors using only marketing information, documentation and materials provided by MFSN or specifically approved by MFSN in writing prior to implementation. Referrer agrees to cooperate with MFSN in its efforts to ensure compliance with the foregoing requirements, including removing or discontinuing use of any marketing materials that MFSN does not approve; and (e) Referrer shall offer MFSN’s Services through Referrer’s email and/or website as part of Referrer’s overall offering of services to Visitors.<br /><br />
                                    3. ​​​Duties of MFSN. MFSN agrees as follows:
                                    (a) MFSN shall offer the Services as defined herein;
                                    (b) MFSN shall provide the Services to the Visitor pursuant to MFSN’s Terms & Conditions, as they may change from time to time; MFSN is under no obligation to Referrer to notify Referrer of changes to its Terms & Conditions, (c) MFSN shall provide marketing information, documentation and/or materials for use by Referrer to market and promote the Services to the Visitor. Any changes or modifications to such information documentation and/or materials used by Referrer to market or promote the Services must be approved in advance by MFSN, in writing; (d) MFSN shall keep record of Conversions as a result of a Visitor subscribing for one of MFSN’s products through Referrers Link; (e) MFSN shall compensate Referrer for all Conversions that occur as a result of a Visitor clicking on Referrers Links and converting into MFSN services, at the rate set forth in the Insertion Order<br /><br />
                                    4. Compensation. All payments shall be made to Referrer by MFSN as follows: Payment Terms: Net 45 for Conversions from the prior month.  Accrued Sales Commissions less than $100 will be paid out in the commission cycle month that the accrued balance reaches $100.<br/><br/>
                                    See Schedule A for Banking ACH Payment Information <br/>
                                    All statistics and calculations utilized to determine Sales Commissions paid to Referrer will be compiled and calculated by MFSN. MFSN’s calculations will be the only statistics utilized to determine Referrer’s Sales Commissions. Referrer agrees to accept MFSN’s calculations as true and correct. If Referrer has a reasonable basis for claiming that MFSN’s calculations are materially in error, it shall have the right to audit MFSN’s reporting at Referrer’s expense with 30-day written notice. Referrer acknowledges and agrees that at no time will it be entitled to interest related to Sales Commissions which are held in MFSN’s possession.
                                Excessive Chargebacks. MFSN reserves the right to Void Sales Commissions, and “pass-thru” any fines, fees and/or other chargebacks to Referrer which are incurred by MFSN as a sole result of traffic or Acquisitions from a Referrer
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement7;