import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import {
    Grid, Alert, Button, Stack,
} from '@mui/material';
import { useNavigate,Link } from 'react-router-dom';
import { Typography, Card, CardContent, CardOverflow, } from '@mui/joy';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader'
import { fetchData ,postData } from '../../../redux/apiSlice';
import { useDispatch } from 'react-redux';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import swal from "sweetalert";

function MyProfile() {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [myProfile, setMyProfile] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [freez, setFreez] = React.useState(false);
    const [notificationFlag, setnotificationFlag] = useState('');
    const [NotificationOpen, setNotificationOpen] = useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const handleFreezStop = () => {
        setFreez(false);
      };
      const handleFreezStart = () => {
        setFreez(true);
      };

    const handleChangeSwitch = () => {
        setNotificationOpen(true);
        /////////after discussion make it true
    }
    const NotificationHandleClose = () => {
        setNotificationOpen(false);
    };
    const changeNotificationStaus = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        console.log(loginUser.company_master_id);
        handleFreezStart();
        setnotificationFlag(!notificationFlag);
        const apiUrl = "/saveNotificationStatus";
        const args = {
            companyId: loginUser.company_master_id,
            status: !notificationFlag
        }
        const res = dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            handleFreezStop();
            swal({
                title: "Success",
                text: `Notifications about new features and offers updated successfully`,
                icon: "success",
                button: "Ok",
            })
            if (res.success === true) {
                setSuccessMessage(res.message);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            }
        }
        setNotificationOpen(false);
    }
    const getMyProfile = async() =>{
        handleFreezStart();
        const apiUrl = '/profile'; // Replace with your API endpoint
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setMyProfile(res.data);
            setnotificationFlag(res.data.company ? res.data.company.notification_flag : '');
        }
        handleFreezStop();
    }

    useEffect(() => {
          getMyProfile();
      }, []);

    function ContentLayout() {
        return (
            <>
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "right" }}>
                   <Link onClick={()=>{navigate(-1)}}><img src={require("../../../assets/images/BackIcon.png")} /></Link>
                </Grid>
                {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                <Card variant="outlined" mt={2}>
                    <CardOverflow
                        sx={{
                            bgcolor: '#F6FAFF',
                            borderColor: '#F6FAFF',
                        }}
                    >
                        <Grid container item xs sx={{ justifyContent: "space-between" }}>
                            <Grid item lg={6} md={6} sm={4} xs={4}>
                                <Typography level="h4" sx={{ fontWeight: 600 }}> My Profile</Typography>
                            </Grid>
                            <Grid item lg={6} md={6} sm={8} xs={8} sx={{ textAlign: "right" }}>
                                <Typography level="h4" sx={{ fontWeight: 600 }}>
                                    {myProfile.role ? <>Your role : <Typography sx={{ fontWeight: 600 }}>{myProfile.role.role_name}</Typography></> : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardOverflow>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Grid item xs={4}>
                                <Grid item xs mt={2}><Typography level='h5'>First Name:</Typography></Grid>
                                <Grid item xs mt={2}><Typography level='h5'>Last Name:</Typography></Grid>
                                <Grid item xs mt={2}><Typography level='h5'>Email:</Typography></Grid>
                                <Grid item xs mt={2}><Typography level='h5'>Phone:</Typography></Grid>
                                {myProfile.company ? <Grid item xs mt={2}><Typography level='h5'>Company Name:</Typography></Grid> : ''}
                                {myProfile.userAids ? myProfile.userAids.length > 0 ?<Grid item xs mt={2}><Typography level='h5'>AID's:</Typography></Grid> : '' : ''}
                                {myProfile.roles_id === 3 ? myProfile.company ? <Grid item xs mt={2}><Typography level='h5'>Notifications about new features and offers:</Typography></Grid> : '' : ''}
                            </Grid>
                            <Grid item xs={8}>
                                <Grid item xs mt={2}><Typography level='h5' sx={{ fontWeight: 600 }}>{myProfile.first_name ? myProfile.first_name : 'N/A'}</Typography></Grid>
                                <Grid item xs mt={2} ><Typography level='h5' sx={{ fontWeight: 600 }}>{myProfile.last_name ? myProfile.last_name : 'N/A'}</Typography></Grid>
                                <Grid item xs mt={2} ><Typography level='h5' sx={{ fontWeight: 600 }}>{myProfile.email ? myProfile.email : 'N/A'}</Typography></Grid>
                                <Grid item xs mt={2} ><Typography level='h5' sx={{ fontWeight: 600 }}>{myProfile.phone_no ? formatPhoneNumber(myProfile.phone_no) : 'N/A'}</Typography></Grid>
                                {myProfile.company ? <Grid item xs mt={2} ><Typography level='h5' sx={{ fontWeight: 600 }}>{myProfile.company ? myProfile.company.company_name : 'N/A'}</Typography></Grid> : ''}
                                { myProfile.userAids ? myProfile.userAids.length > 0 ? (
                                <>
                                <Grid item xs mt={2}><Typography level='h5' sx={{ fontWeight: 600 }}>
                                                {myProfile.userAids.join(', ')}
                                            </Typography>
                                </Grid>
                                </> ) : '' : ''}
                                {myProfile.roles_id === 3 ? myProfile.company ? 
                                <Grid item xs mt={2}>
                                <Stack direction="row" alignItems="center">
                                    <Typography variant="subtitle1" gutterBottom><b>Off</b></Typography>
                                    <Switch checked={notificationFlag}
                                        onChange={handleChangeSwitch}
                                        inputProps={{ 'aria-label': 'Switch A' }}
                                    />
                                    <Typography variant="subtitle1" gutterBottom><b>On</b></Typography>
                                </Stack>
                                </Grid>
                                : '' : '' }
                                </Grid>
                            
                        </Grid>
                        <Grid container item xs mt={2}>
                        <Grid item xs ><Button variant="contained" onClick={() =>{navigate("/Repassword")}}>Change Password</Button></Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Dialog
                    open={NotificationOpen}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            NotificationHandleClose();
                        }
                    }}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                >
                    <DialogTitle variant="h6" color='primary'>Change Notification Status</DialogTitle>
                    <DialogContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            {
                                notificationFlag ? 
                                <>
                                    If you disable this, you will not receive notifications about features and offers.
                                </>
                                : 
                                <>
                                Continue to receive notifications about exciting features and offers.
                                </>
                               
                            }

                            <br/><br/>
                            Are you sure, you want continue ?

                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                            
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" onClick={NotificationHandleClose} sx={{ minWidth: 200, backgroundColor: "#2A68B3", color: "white" }} color="primary">
                                        No
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" onClick={changeNotificationStaus} sx={{ minWidth: 200, backgroundColor: "#76C044", color: "white" }} color="success">
                                        Yes
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
    return (
        <div>
        <DefaultLayout content={<ContentLayout />} />
        {/* loader code start */}
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={freez}
            onClick={handleFreezStop}
          >
            <Freez />
          </Backdrop>
        </div>
        {/* loader code end */}
      </div>
    );
}
export default MyProfile;