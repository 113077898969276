import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store } from './redux/store';

import { saveState } from './redux/storePersist';

store.subscribe(()=>{
	saveState(store.getState());
});

// Register the custom service worker
if ('serviceWorker' in navigator) {
	// if (navigator.serviceWorker.controller || !window.privateBrowsingEnabled) {
	// 	navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
	// 		console.log('ServiceWorker registered:', registration);
	// 	}).catch(error => {
	// 		console.error('Service worker registration failed:', error);
	// 	});
	// }
	// else {
	// 	console.log('Service workers are disabled in private browsing mode.');
	//   }
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
			// console.log('ServiceWorker registered:', registration);
		}).catch(error => {
			console.error('Service worker registration failed:', error);
		});
	});
}else {
//   console.log('Service workers are not supported.');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
