import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Box,  Typography as MuiTypography,Grid,CardActions,DialogContent,Dialog,CardContent } from '@mui/material';
import Typography from '@mui/joy/Typography';
import { Card, CardOverflow } from '@mui/joy';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';

const SignatureModal = ({ onSave, onClose,firstName }) => {
  const sigCanvas = useRef({});
  const [activeTab, setActiveTab] = useState('draw');
  const [color, setColor] = useState('#000000');
  const [selectedFont, setSelectedFont] = useState('');
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [value, setValue] = React.useState(0);
  const clear = () => sigCanvas.current.clear();
  const [open, setOpen] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const save = () => {
    let signatureDataUrl = null;
    // if (activeTab === 'draw') {
    //   if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
    //     const trimmedCanvas = sigCanvas.current.getTrimmedCanvas();
    //     const dynamicWidth = trimmedCanvas.width;
    //     const canvas = document.createElement('canvas');
    //     canvas.width = dynamicWidth; 
    //     canvas.height = 100;
    //     // canvas.width = dynamicWidth>=600?400:dynamicWidth; 
    //     // canvas.height = 100;
    //     canvas.getContext('2d').drawImage(sigCanvas.current.getTrimmedCanvas(), 0, 0, canvas.width, canvas.height);
    //     signatureDataUrl = canvas.toDataURL('image/png');
    //   }
    // }
     if (selectedFont) {
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCtx.font = `50px ${selectedFont}`;
      
      const textWidth = tempCtx.measureText(`${firstName}`).width;
      const canvas = document.createElement('canvas');

      canvas.width = textWidth + 20; 
      canvas.height = 100;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = color;
      ctx.font = `50px ${selectedFont}`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${firstName} `, canvas.width / 2, canvas.height / 2);
      signatureDataUrl = canvas.toDataURL('image/png');
    }
    onSave( signatureDataUrl);
  };

  const renderFontCards = () => {
    const fonts = ['Dancing Script',
    'Pacifico',
    'Great Vibes',
    'Satisfy',
    'Allura',
    'Lobster'];
  return (
      <Grid container spacing={2} item lg={12} md={12} sm={12} xs={12}>
        {fonts.map((font) => (
          <Grid item lg={4} md={4} sm={12} xs={12} key={font}>
            <Card
              style={{
                margin: '10px',
                padding: '10px',
                fontFamily: font,
                cursor: 'pointer',
                // minHeight: 50,
                // maxHeight: 100,
                border: selectedFont === font ? '2px solid #000' : 'none',
                textAlign:'center',
                overflowWrap: 'break-word'
              }}
              onClick={() => setSelectedFont(font)}
            >
              <MuiTypography variant="h6" style={{ fontFamily: font, color }}>
                {`${firstName}`}
              </MuiTypography>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className='signature-modal'
    >
     
      <Card>
      <CardOverflow sx={{
              bgcolor: '#F6FAFF',
              borderColor: '#F6FAFF',
              padding: '1%',
          }}
          >
          <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"space-between"}}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      {/* <Tab label="Draw" className={activeTab === 'draw' ? 'active' : ''} onClick={() => setActiveTab('draw')} sx={{color:"blue",fontWeight:"bold"}}/> */}
                      <Tab label="Type" className={activeTab === 'type' ? 'active' : ''} onClick={() => setActiveTab('type')}  sx={{color:"blue",fontWeight:"bold"}}/>
                  </Tabs>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6} sx={{textAlign:"right"}}>
                  <IconButton
                      aria-label="close"
                      onClick={onClose}
                      sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                      }}
                      >
                      <HighlightOffOutlinedIcon sx={{color:"blue"}} />
                  </IconButton>
              </Grid>
          </Grid>
          </CardOverflow>
          <CardContent>
            {/* {activeTab === 'draw' && (
             <>
            
                      <Card sx={{ bgcolor: '#F6FAFF',borderRadius:"4%",minHeight:200,maxHeight:300,boxShadow:"8%"}}>
                      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{borderBottom:"1px solid grey"}}>
                        <SignatureCanvas
                          penColor={color}
                          canvasProps={{ className: 'sigCanvas' }}
                          ref={sigCanvas}
                        />
                        </Grid>
                         <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        
                          <Button variant="contained" onClick={clear} >Clear</Button>
                       
                      </Grid>
                      </Card>
                
                  <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Typography >By Electronically Signing This Document, 
                          I Agree That My Signature And Initials Are The Equivalent Of My Handwritten Signature And Are Considered Originals On All Documents, 
                          Including Legally Binding Contracts. 
                          I Also Agree To The <a href="#"> Terms Of Use </a> And <a href="#">Privacy Policy</a>.</Typography>
                  </Grid>
             </>
              
            )}
            {activeTab === 'type' && ( */}
             <>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                  
                    {renderFontCards()}
                
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography>By Electronically Signing This Document, 
                        I Agree That My Signature And Initials Are The Equivalent Of My Handwritten Signature And Are Considered Originals On All Documents, 
                        Including Legally Binding Contracts. 
                        I Also Agree To The <a href="/termsandconditions" target="_blank"> Terms Of Use </a> And <a href="/privacypolicy" target="_blank">Privacy Policy</a>.</Typography>
                </Grid>
                </>
            {/* )} */}
          </CardContent>
          <CardActions>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"flex-end"}} className='signatureModal-btns'>
                  <Button variant="outlined"  sx={{ minWidth: 200,fontWeight:700 }} onClick={onClose} className='signature-btns-cancel'>Cancel</Button> &nbsp; &nbsp;
                  <Button variant="contained"  sx={{ minWidth: 200,fontWeight:700 }} onClick={save} >Accept & Sign</Button>
              </Grid>
          </CardActions>
      </Card>
     
      
  </Dialog>




 
  );
};

export default SignatureModal;