
import React, { useState, useRef } from "react";
import { Grid, Typography } from '@mui/material';
import { Card, CardContent } from '@mui/joy';

function AffiAgreement6() {
    return (
        <>
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={4} className="padadoc-block">
                <Card variant="outlined" size="md" className="padadoc-card">
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h6">Based on the Membership and Commission Program Selected by the Referrer or as otherwise agreed to by MFSN in writing, a “Sales Commission” will be paid each month to the Referrer if the following qualifying conditions are met: (1) it is a qualifying Conversion as defined below; (2) that the MFSN customer was referred by Referrer through the Referrer’s unique link (the unique MFSN URL that was provided to Referrer by MFSN); (3) that the customer’s account is in Active status on the MFSN system; (4) that the customer has been successfully billed the then current MFSN membership fee for the applicable month of the Sales Commission; (5) the customer’s account is in good standing including, but not limited to, the account is not Closed, that no refund requests or chargebacks have been initiated by the customer for any fees for the products and services<br />
                                    associated with their membership, nor have they been in placed in Suspended status due to any declination of the credit or debit card used to enroll and/or that is on file with MFSN on the first or subsequent billings and (6) the Referrer has not induced, directed, communicated to, or advised the customer by any means or thru any media that the customer should cancel their MFSN account. MyFreeScoreNow reserves the right to remove, change or amend the Sales Commissions and Membership Programs or add new Membership Programs and Commissions at any time with or without written notice to Referrer.<br /><br />
                                    “Sales Commissions on 3-Bureau Report Purchases” If the specific Referral Program chosen by the Referrer on Schedule C does not provide the member a (free) 3-bureau / 3 -Score credit report as an included (free) benefit of their membership, rather it is offered for purchase on an “A la carte” basis, the Referrer may be eligible for additional Sales Commission in accordance with this Agreement and at a rate defined by MFSN in its sole discretion. All Purchases by customers of
                                    MyFreeScoreNow.com of A La Carte 3-Bureau / 3- Score Credit Reports are subject to MFSN’s Refund Policy and are Non-Refundable. Any Sales Commission to the Referrer will be Voided if the customer rescinds their purchase after ordering, or demands a refund or initiates a chargeback or a dispute of the purchase with their credit or debit card issuer.
                                    <br />
                                    “Services” shall mean and refer to the Services provided to customers by MFSN on its website https://member.myfreescorenow.com or www.myfreescorenow.com, or other website URLs of MFSN or third-party services that may be added from time to time, including but not limited to credit reports, credit scores, credit monitoring and other related credit services, such as loans or lending, credit cards, which MFSN may from time to time, in MFSN’s sole discretion, enhance or be added to.<br /><br />
                                    “Tracking Link” shall mean the unique tracking link (URL) issued to Referrer by MFSN that must be used to enroll prospective customers in order for any customer referred by Referrer to be eligible for a Sales Commission<br /><br />
                                    “Trademark” shall refer to the trademark “Myfreescorenow.com.”<br />
                                    "Visitor" means any person and/or user that clicks on a Link from Referrer’s website and/or email solicitation, or any other means by which Referrer is approved to advertise.<br /><br />
                                    "Void" shall mean to reverse a Sales Commission in accordance with this Agreement.</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default AffiAgreement6;